"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebIceCreamIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16.9,4.02a5.008,5.008,0,0,0-9.8,0,5.506,5.506,0,0,0-4.325,8.257L9.019,22.333a3.521,3.521,0,0,0,5.96,0L21.24,12.253A5.507,5.507,0,0,0,16.9,4.02ZM20,9.5a3.331,3.331,0,0,1-.056.573,34.663,34.663,0,0,1-6.624.884C11.329,5.647,19.593,3.842,20,9.5ZM12,2a3,3,0,0,1,2.893,2.249A5.52,5.52,0,0,0,12,6.341,5.52,5.52,0,0,0,9.107,4.249,3,3,0,0,1,12,2ZM7.5,6a3.517,3.517,0,0,1,3.181,4.955,35.968,35.968,0,0,1-6.624-.88A3.5,3.5,0,0,1,7.5,6Zm5.778,15.286a1.54,1.54,0,0,1-2.558,0L5.17,12.346a37.938,37.938,0,0,0,13.656,0Z" })));
exports.default = TruxwebIceCreamIcon;
