import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  clickable: {
    cursor: 'pointer',
  },
  primaryFont: {
    color: `${theme.palette.primary.light} !important`,
  },
  resendButton: {
    background: 'none!important',
    border: 'none',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '0!important',
  },
  resendFont: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
  },
  submitButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: theme.spacing(5),
    padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px !important`,
  },
}));
