"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebShipIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,20a1,1,0,0,0-1,1c0,.344-.682,1-1.75,1a2.023,2.023,0,0,1-1.593-.689,1.932,1.932,0,0,1,.128-.184,12.152,12.152,0,0,0,3.156-6.183A3,3,0,0,0,20,11.584V9a4,4,0,0,0-4-4V4A4,4,0,0,0,8,4V5A4,4,0,0,0,4,9v2.571a3,3,0,0,0-1.972,3.373,12.188,12.188,0,0,0,3.187,6.183,1.973,1.973,0,0,1,.133.189A2.005,2.005,0,0,1,3.75,22C2.661,22,2,21.306,2,21a1,1,0,0,0-2,0c0,1.626,1.718,3,3.75,3a4.212,4.212,0,0,0,2.763-1A4.295,4.295,0,0,0,12,23.016,4.317,4.317,0,0,0,17.5,23a4.208,4.208,0,0,0,2.746,1C22.282,24,24,22.626,24,21A1,1,0,0,0,23,20ZM10,4a2,2,0,0,1,4,0V5H10ZM8,7h8a2,2,0,0,1,2,2v1.92L12.948,9.262a3.026,3.026,0,0,0-1.891,0L6,10.913V9A2,2,0,0,1,8,7ZM9.25,22a1.938,1.938,0,0,1-1.711-.849,5.113,5.113,0,0,0-.848-1.372A10.253,10.253,0,0,1,4,14.607a1.018,1.018,0,0,1,.662-1.153L11,11.383V21C11,21.344,10.318,22,9.25,22Zm5.5,0C13.661,22,13,21.306,13,21V11.384l6.305,2.069a1.021,1.021,0,0,1,.666,1.155,10.2,10.2,0,0,1-2.662,5.171,4.991,4.991,0,0,0-.848,1.378A1.929,1.929,0,0,1,14.75,22Z" })));
exports.default = TruxwebShipIcon;
