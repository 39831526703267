"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginMFA = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const qrcode_react_1 = require("qrcode.react");
const react_hook_form_1 = require("react-hook-form");
const LoginMFA = ({ emailAddress, handleSubmitMfa, isSaving, mfaCode, mfaSite, mfaStep, t, }) => {
    const { control, handleSubmit, reset } = (0, react_hook_form_1.useForm)();
    const mfaRef = (0, react_1.useRef)(null);
    let content = null;
    (0, react_1.useEffect)(() => {
        if (mfaRef && mfaRef.current) {
            mfaRef.current.focus();
        }
    }, [mfaRef]);
    const formSubmit = (0, react_1.useCallback)((data) => {
        handleSubmitMfa(data);
        reset();
    }, [handleSubmitMfa, reset]);
    // FIXME: This is used to determine the logo should in the MFA
    // authenticator app. Currently `Truxweb` logo is not found.
    // so we use a generic search term to find the logo
    const mfaIssuer = 'Truxweb jason';
    switch (mfaStep) {
        case 'mfaSetup':
            content = (react_1.default.createElement(ux_1.Box, { mt: 4 },
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Typography, { variant: "h2" }, t('common:setUpMfaTitle'))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
                            react_1.default.createElement(ux_1.Typography, null, t('common:setUpMfa')))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(qrcode_react_1.QRCodeSVG, { value: `otpauth://totp/${mfaSite}:${emailAddress}?secret=${mfaCode}&issuer=${mfaIssuer}` })),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(__1.FormField, { control: control, defaultValue: '', id: 'mfaChallenge', inputProps: { ref: mfaRef }, label: t('common:enterMfa'), t: t, type: "text" }))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(__1.SaveButton, { buttonText: t('common:confirm'), isSaving: isSaving, t: t }))))));
            break;
        case 'mfaChallenge':
            content = (react_1.default.createElement(ux_1.Box, { mt: 4 },
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Typography, { variant: "h2" }, t('common:enterMfaTitle'))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(__1.FormField, { control: control, defaultValue: '', id: 'mfaChallenge', inputProps: { ref: mfaRef }, label: t('common:enterMfa'), t: t, type: "text" }))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mt: 3 },
                            react_1.default.createElement(__1.SaveButton, { buttonText: t('common:confirm'), isSaving: isSaving, t: t }))))));
            break;
        default:
            content = null;
    }
    return react_1.default.createElement("form", { onSubmit: handleSubmit(formSubmit) }, content);
};
exports.LoginMFA = LoginMFA;
