"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        backgroundColor: '#E8F4FB',
        borderRadius: theme.spacing(1.25),
    },
    content: {
        padding: '82px 91px',
    },
    menu: {
        backgroundColor: 'white',
        borderRadius: '10px 0 0 10px',
        width: 112,
    },
}));
