import { Grid, Typography } from '@truxweb/ux';
import { useStyles } from './FooterCompanyInfo.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const FooterCompanyInfo = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography className={classes.companyName}>{t('common:truxweb')}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.info}>{t('common:companyAddressLineOne')}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.info}>{t('common:companyAddressLineTwo')}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.info}>{t('common:companyAddressLineThree')}</Typography>
      </Grid>
    </Grid>
  );
};
