import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>(() => ({
  container: {
    backgroundColor: 'lightgrey',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: '42px',
  },
}));
