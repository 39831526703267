// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentChargeTypeV1 = void 0;
var ShipmentChargeTypeV1;
(function (ShipmentChargeTypeV1) {
    ShipmentChargeTypeV1["GST"] = "GST";
    ShipmentChargeTypeV1["PST"] = "PST";
    ShipmentChargeTypeV1["QST"] = "QST";
    ShipmentChargeTypeV1["HST"] = "HST";
    ShipmentChargeTypeV1["VAT"] = "VAT";
    ShipmentChargeTypeV1["RST"] = "RST";
    ShipmentChargeTypeV1["TRUXWEB"] = "TRUXWEB";
    ShipmentChargeTypeV1["ADDITIONAL"] = "ADDITIONAL";
    ShipmentChargeTypeV1["CARRIER"] = "CARRIER";
    ShipmentChargeTypeV1["EQUIPMENT"] = "EQUIPMENT";
    ShipmentChargeTypeV1["UNKNOWN"] = "UNKNOWN";
    ShipmentChargeTypeV1["ACCESSORIAL"] = "ACCESSORIAL";
    ShipmentChargeTypeV1["TRUXWEB_CARRIER"] = "TRUXWEB_CARRIER";
    ShipmentChargeTypeV1["TRUXWEB_SHIPPER"] = "TRUXWEB_SHIPPER";
    ShipmentChargeTypeV1["CREDIT_CARD_SURCHARGE"] = "CREDIT_CARD_SURCHARGE";
    ShipmentChargeTypeV1["FUEL_SURCHARGE"] = "FUEL_SURCHARGE";
    ShipmentChargeTypeV1["TRUXWEB_FEE"] = "TRUXWEB_FEE";
    ShipmentChargeTypeV1["SUBSCRIPTION"] = "SUBSCRIPTION";
})(ShipmentChargeTypeV1 || (exports.ShipmentChargeTypeV1 = ShipmentChargeTypeV1 = {}));
