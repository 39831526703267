import {
  Badge,
  Box,
  Grid,
  HighlightOffIcon,
  IconButton,
  Menu,
  NotificationsOutlinedIcon,
  Typography,
  useTheme,
} from '@truxweb/ux';
import { ELanguageV1, TInAppNotificationV1 } from '@truxweb/schemas';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInterval, useNotifications } from '../../hooks';
import classnames from 'classnames';
import { NotificationList } from '../NotificationList';
import { PageLink } from '..';
import { useRouter } from 'next/router';
import { useStyles } from './NotificationsButton.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

const NOTIFICATIONS_REFRESH_INTERVAL_MS = 45 * 1000;

type TNotificationsButtonProps = {
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

export const NotificationsButton = ({
  className,
  size,
}: TNotificationsButtonProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const theme = useTheme();
  const { locale } = useRouter();

  // STEP -- Fetch in app notifications addressed to the user from a common context
  const [shouldRefetchNotifications, setShouldRefetchNotifications] = useState(false);
  const [beNotificationList, hasLoaded, isLoading, hasError] = useNotifications(
    locale.toUpperCase() as ELanguageV1,
    shouldRefetchNotifications
  );

  useEffect(() => {
    if (shouldRefetchNotifications) setShouldRefetchNotifications(false);
  }, [shouldRefetchNotifications, setShouldRefetchNotifications]);

  // Periodic refresh
  useInterval(() => {
    if (!isLoading) setShouldRefetchNotifications(true);
  }, NOTIFICATIONS_REFRESH_INTERVAL_MS);

  const unreadNotificationList = useMemo(() => {
    if (!isLoading && hasLoaded && !hasError) {
      return beNotificationList?.filter((elt: TInAppNotificationV1) => {
        return elt.dateRead === null || elt.dateRead === undefined;
      });
    }
    return [];
  }, [beNotificationList, isLoading, hasLoaded, hasError]);

  const unreadNotificationCount = unreadNotificationList?.length || 0;

  // STEP -- Popover menu logic...
  const anchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNotificationsIconToggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, [setIsMenuOpen]);

  const handlePopoverClose = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  return (
    <>
      <IconButton disabled={isLoading} onClick={handleNotificationsIconToggle} ref={anchorRef}>
        <Badge badgeContent={unreadNotificationCount} color="primary" overlap="rectangular">
          <NotificationsOutlinedIcon
            className={classnames(className, classes.icon)}
            fontSize={size ? size : 'medium'}
          />
        </Badge>
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            borderRadius: 2,
            marginTop: '85px',
            minWidth: '470px',
            padding: theme.spacing(1),
          },
        }}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id={isMenuOpen ? 'unreadNotifications-menu' : undefined}
        keepMounted
        onClose={handlePopoverClose}
        open={isMenuOpen}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        {/* Top-right x button */}
        <IconButton className={classes.closeMenuBtn} onClick={handlePopoverClose}>
          <HighlightOffIcon />
        </IconButton>

        {/* 3 lastest unread notifications */}
        <NotificationList
          noNotificationsToDisplayMsg={t('common:noNotificationsToDisplay-unread')}
          notifications={unreadNotificationList?.slice(0, 3)}
          shouldDisplayLink={false}
        />

        <Grid container justifyContent="center">
          {unreadNotificationCount > 3 && (
            <Grid item>
              <Typography variant="h6">...</Typography>
            </Grid>
          )}
          <Grid item>
            <Box mt={2}>
              <PageLink pageId={'account/notifications'}>
                <Typography color="primary" variant="subtitle1">
                  {t('common:seeNotifications')}
                </Typography>
              </PageLink>
            </Box>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};
