import { LegacyLayout, WebAppLayout } from '..';
import { LEGACY_LAYOUT_PAGES } from '../../config';
import React from 'react';
import { TPageId } from '../../types';

type TBasePageProps = {
  children?: React.ReactNode;
  isHeaderTransparent?: boolean;
  footerRef?: React.Ref<any>;
  onScroll?: (isScrolled: boolean) => void;
  pageId: TPageId;
  pageStyle?: 'centered' | 'fullWidth';
};

export const BasePage = (props: TBasePageProps): JSX.Element => {
  if (LEGACY_LAYOUT_PAGES.includes(props.pageId)) return <LegacyLayout {...props} />;
  return <WebAppLayout {...props} />;
};
