"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebEnvelopeDownloadIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M14.73,7.7a1,1,0,1,1,1.43-1.4L18,8.181V1a1,1,0,0,1,2,0V8.181L21.84,6.3a1,1,0,0,1,1.43,1.4L20.755,10.27A2.478,2.478,0,0,1,19.012,11a.04.04,0,0,1-.019,0,2.456,2.456,0,0,1-1.74-.722ZM23,11a1,1,0,0,0-1,1v7a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9.071l6.465,6.465a5.025,5.025,0,0,0,7.06.01l1.882-1.829a1,1,0,1,0-1.394-1.434l-1.892,1.839a3.074,3.074,0,0,1-4.242,0L2.361,6.6A2.99,2.99,0,0,1,5,5h7a1,1,0,0,0,0-2H5A5.006,5.006,0,0,0,0,8V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V12A1,1,0,0,0,23,11Z" })));
exports.default = TruxwebEnvelopeDownloadIcon;
