"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDangerousGoodsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", fill: "#000000", height: 24, id: "Layer_1", viewBox: "0 0 16 17", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M8.00016 16.4575C7.56243 16.4581 7.12888 16.3723 6.72436 16.2051C6.31985 16.0378 5.95231 15.7924 5.64283 15.4828L1.02283 10.8621C0.397926 10.237 0.046875 9.38934 0.046875 8.50546C0.046875 7.62158 0.397926 6.77389 1.02283 6.1488L5.64283 1.52813C6.26867 0.904121 7.11639 0.553711 8.00016 0.553711C8.88394 0.553711 9.73166 0.904121 10.3575 1.52813L14.9775 6.1488C15.6024 6.77389 15.9535 7.62158 15.9535 8.50546C15.9535 9.38934 15.6024 10.237 14.9775 10.8621L10.3575 15.4828C10.048 15.7924 9.68048 16.0378 9.27596 16.2051C8.87145 16.3723 8.4379 16.4581 8.00016 16.4575ZM8.00016 1.88613C7.73748 1.88563 7.47728 1.93702 7.23451 2.03736C6.99175 2.13769 6.77119 2.28499 6.5855 2.4708L1.9655 7.09146C1.59055 7.46652 1.37992 7.97513 1.37992 8.50546C1.37992 9.03579 1.59055 9.54441 1.9655 9.91946L6.5855 14.5401C6.77123 14.726 6.99177 14.8734 7.2345 14.974C7.47724 15.0746 7.73741 15.1264 8.00016 15.1264C8.26292 15.1264 8.52309 15.0746 8.76583 14.974C9.00856 14.8734 9.2291 14.726 9.41483 14.5401L14.0348 9.91946C14.4098 9.54441 14.6204 9.03579 14.6204 8.50546C14.6204 7.97513 14.4098 7.46652 14.0348 7.09146L9.41483 2.4708C9.22914 2.28499 9.00858 2.13769 8.76581 2.03736C8.52305 1.93702 8.26285 1.88563 8.00016 1.88613Z" }),
    React.createElement("path", { d: "M7.67741 9.63045C7.75535 9.7084 7.86108 9.75219 7.97131 9.75219C8.08155 9.75219 8.18727 9.7084 8.26522 9.63045C8.34317 9.5525 8.38696 9.44678 8.38696 9.33654V6.01135C8.38696 5.90112 8.34317 5.79539 8.26522 5.71744C8.18727 5.63949 8.08155 5.5957 7.97131 5.5957C7.86108 5.5957 7.75535 5.63949 7.67741 5.71744C7.59946 5.79539 7.55566 5.90112 7.55566 6.01135V9.33654C7.55566 9.44678 7.59946 9.5525 7.67741 9.63045Z" }),
    React.createElement("path", { d: "M7.74039 10.6535C7.80874 10.6079 7.88911 10.5835 7.97131 10.5835C8.08155 10.5835 8.18727 10.6273 8.26522 10.7052C8.34317 10.7832 8.38696 10.8889 8.38696 10.9991C8.38696 11.0813 8.36258 11.1617 8.31691 11.2301C8.27124 11.2984 8.20633 11.3517 8.13038 11.3831C8.05443 11.4146 7.97085 11.4228 7.89022 11.4068C7.8096 11.3908 7.73553 11.3512 7.67741 11.293C7.61928 11.2349 7.57969 11.1609 7.56365 11.0802C7.54761 10.9996 7.55584 10.916 7.5873 10.8401C7.61876 10.7641 7.67204 10.6992 7.74039 10.6535Z" }),
    React.createElement("path", { d: "M7.766 1.31596C7.92235 1.21209 8.12574 1.21209 8.28209 1.31596L11.3912 3.38149C11.7758 3.63701 11.5949 4.23622 11.1332 4.23622H4.91494C4.45318 4.23622 4.27227 3.63702 4.6569 3.38149L7.766 1.31596Z" }),
    React.createElement("path", { d: "M8.28185 15.6953C8.1255 15.7991 7.92211 15.7991 7.76576 15.6953L4.65666 13.6297C4.27203 13.3742 4.45293 12.775 4.9147 12.775H11.1329C11.5947 12.775 11.7756 13.3742 11.391 13.6297L8.28185 15.6953Z" })));
exports.default = TruxwebDangerousGoodsIcon;
