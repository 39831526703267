"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    mapElement: {
        "& path[name='title']": {
            fill: '#224882',
        },
        '&:hover': {
            "& path[name='title']": {
                fill: '#fff',
            },
            fill: '#8a9cb3',
        },
        cursor: 'pointer',
        fill: '#D3D3D3',
    },
    selectedMapElement: {
        "& path[name='title']": {
            fill: '#fff',
        },
        '&:hover': {
            fill: '#8a9cb3',
        },
        cursor: 'pointer',
        fill: theme.palette.primary.main,
    },
}));
