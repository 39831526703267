"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGolfIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m12 10a20.294 20.294 0 0 0 -4.016.4l-.006-2.285 5.009-2.076a2 2 0 0 0 .271-3.4.838.838 0 0 0 -.1-.065l-4.258-2.362a2 2 0 0 0 -2.9 1.788v8.914c-3.609 1.195-6 3.446-6 6.086 0 3.925 5.271 7 12 7s12-3.075 12-7-5.271-7-12-7zm.073-5.745-4.1 1.7-.01-3.971zm-.073 17.745c-5.42 0-10-2.29-10-5 0-1.594 1.591-3.039 4-3.962v3.962a1 1 0 0 0 2 0l-.011-4.56a18.243 18.243 0 0 1 4.011-.44c5.42 0 10 2.29 10 5s-4.58 5-10 5zm5-5a2 2 0 1 1 -2-2 2 2 0 0 1 2 2z" })));
exports.default = TruxwebGolfIcon;
