"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    collapseButton: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        fill: '#dadada',
    },
}));
