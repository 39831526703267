"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTurkeyIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19.85,10.755a5.038,5.038,0,0,0-.17-3.03c.038-.012,1.482-1.481,1.513-1.5a1.5,1.5,0,1,0,.649-2.063,1.5,1.5,0,1,0-2.063.649c-.015.022-1.5,1.482-1.5,1.507-1.424-.764-4.375,0-6.134.964C6.193,5.8.029,9.471,0,16v2.568a5.026,5.026,0,0,0,4.3,4.951C10.447,24.5,23.753,24.34,24,18,24,15.553,22.448,12.867,19.85,10.755ZM14.643,8.388c1.435-.481,2.635-.516,3.06-.092.965.965-.563,5.139-2.181,6.757C12.461,18,8,13.538,10.947,10.478A10.574,10.574,0,0,1,14.643,8.388ZM9,22a36.975,36.975,0,0,1-4.419-.461A3.015,3.015,0,0,1,2,18.568V16A6.956,6.956,0,0,1,9.571,9.03c-5.795,6.95,5.914,14.078,9.632,3.8C26.5,20.17,18.3,21.918,9,22ZM7,3V1A1,1,0,0,1,9,1V3A1,1,0,0,1,7,3Zm4,0V1a1,1,0,0,1,2,0V3A1,1,0,0,1,11,3ZM3,3V1A1,1,0,0,1,5,1V3A1,1,0,0,1,3,3Z" })));
exports.default = TruxwebTurkeyIcon;
