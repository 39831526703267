// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EldPackagesV1 = void 0;
var EldPackagesV1;
(function (EldPackagesV1) {
    EldPackagesV1["Verizon"] = "Verizon";
    EldPackagesV1["Trimble"] = "Trimble";
    EldPackagesV1["Omnitracs"] = "Omnitracs";
    EldPackagesV1["Lytx"] = "Lytx";
    EldPackagesV1["Geotab"] = "Geotab";
    EldPackagesV1["ORBCOMM"] = "ORBCOMM";
    EldPackagesV1["Zonar"] = "Zonar";
    EldPackagesV1["FleetComplete"] = "FleetComplete";
    EldPackagesV1["TeletracNavman"] = "TeletracNavman";
    EldPackagesV1["KeepTruckin"] = "KeepTruckin";
    EldPackagesV1["ISAAC"] = "ISAAC";
    EldPackagesV1["None"] = "None";
})(EldPackagesV1 || (exports.EldPackagesV1 = EldPackagesV1 = {}));
