import {
  addSearchToLocalStorage,
  clearBookingQuery,
  clearBookings,
  clearSearchForm,
  getMinimumShipmentDate,
  getRecentSeachesByLocationType,
  getRouteProperties,
  restoreBookingQuery,
  restoreSearchForm,
  saveBookingQuery,
  saveSearchForm,
  serializeBookings,
} from '../../utils';
import { Box, PersonIcon } from '@truxweb/ux';
import { CarrierProfileSidebarWrapper, TruxiiEmptyGrid } from '../../components';
import { clearLoad, setSearchFormData } from '../../stores';
import { convertFormDataToBookingQueryV1, transformI18nLocaleToLanguage } from '@truxweb/utils';
import {
  EAccessorialOptionV1,
  type TBookingSearchResultV1,
  type TQuoteSearchFormDataV1,
} from '@truxweb/schemas';
import { GOOGLE_MAPS_API_KEY, QUOTE_MAX_SELECTION } from '../../config';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAlerts,
  useCompanyLocations,
  useReduxSearchResults,
  useRefresh,
  useSavedSearchFormData,
  useSearchFormParams,
  useShipmentCredits,
  useUserData,
} from '../../hooks';
import { SearchResults } from '@truxweb/common-components';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './ResultsPage.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'search', 'loading', 'bookingListItem', 'select'];

export const ResultsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const existingFormData = restoreSearchForm();
  const searchQuery = restoreBookingQuery();

  const [selectedCarrierProfile, setSelectedCarrierProfile] = useState<null | string>(null);
  const [selections, setSelections] = useState<TBookingSearchResultV1[]>([]);
  const [queryParams, setQueryParam] = useState(searchQuery);
  const { handleRefresh, shouldRefresh } = useRefresh();
  const [hasCreditStateBeenApplied, setHasCreditStateBeenApplied] = useState(false);

  const shipmentCredits = useShipmentCredits(shouldRefresh);
  const {
    data: searchResults,
    error,
    hasLoaded: haveResultsLoaded,
    isLoading: areResultsLoading,
  } = useReduxSearchResults(queryParams, hasCreditStateBeenApplied, shouldRefresh);

  const { data: companyLocations, hasLoaded: haveCompanyLocationsLoaded } = useCompanyLocations();
  const { data: searchParams, hasLoaded: haveSearchParamsLoaded } = useSearchFormParams();
  const { data: savedFormData } = useSavedSearchFormData();

  const router = useRouter();
  const { locale } = router;
  const { addAlert } = useAlerts();
  const { companyData, userData } = useUserData();

  const { route: searchRoute } = getRouteProperties(locale, 'search');
  const { route: reservationRoute } = getRouteProperties(locale, 'reservation');

  const { t } = useTranslation([...REQUIRED_NAMESPACES]);
  const classes = useStyles();

  const onSubmitSuccess = useCallback(
    (data: TQuoteSearchFormDataV1) => {
      if (!searchParams) {
        addAlert({
          message: t('common:unableToSubmitSearch'),
          severity: 'error',
        });
      }
      setSelections([]);
      // Save the search params to local storages
      const query = convertFormDataToBookingQueryV1(
        data,
        searchParams,
        Boolean(shipmentCredits.data?.length)
      );

      saveSearchForm(data);
      addSearchToLocalStorage(data);

      saveBookingQuery(query);
      dispatch(setSearchFormData({ data, shouldForceRefresh: true }));
      setQueryParam(query);
      handleRefresh();
    },

    [dispatch, searchParams, t, addAlert, setSelections, shipmentCredits, handleRefresh]
  );

  const handleNavigateToSearch = useCallback(
    (shouldClearBookings: boolean, isNewSearch: boolean) => {
      if (shouldClearBookings) {
        clearBookings();
      }
      if (isNewSearch) {
        setQueryParam(null);
        clearBookingQuery();
        dispatch(setSearchFormData({ data: null }));
        clearSearchForm();
        dispatch(clearLoad());
      }
      router.push({ pathname: searchRoute });
    },
    [router, searchRoute, dispatch]
  );

  const companyLocationData = useMemo(() => {
    if (!haveCompanyLocationsLoaded) return [];
    return companyLocations.map(({ data }) => data);
  }, [companyLocations, haveCompanyLocationsLoaded]);

  const trackingAccessorialId = useMemo(() => {
    return searchParams?.accessorials[EAccessorialOptionV1.TRACKING]?.id;
  }, [searchParams]);

  useEffect(() => {
    // This accounts for the case where a user has been issued credits while they are
    // viewing/using the results page. This will update the stored query params
    // to automatically redeem/exclude a shipment credit
    if (shipmentCredits.hasLoaded && !hasCreditStateBeenApplied) {
      setQueryParam((value) => {
        return { ...value, shouldExcludeTruxwebPricing: Boolean(shipmentCredits.data?.length) };
      });
      setHasCreditStateBeenApplied(true);
    }
  }, [shipmentCredits, setQueryParam, hasCreditStateBeenApplied, setHasCreditStateBeenApplied]);

  useEffect(() => {
    if (!savedFormData && existingFormData) {
      dispatch(setSearchFormData({ data: existingFormData, shouldForceRefresh: true }));
    }
  }, [dispatch, existingFormData, savedFormData]);

  return (
    <Box p={6.5}>
      <SearchResults
        addAlert={addAlert}
        areExtendedSearchParamsOpen={queryParams === null}
        carrierSidebarProfile={
          selectedCarrierProfile !== null ? (
            <CarrierProfileSidebarWrapper
              companyCode={selectedCarrierProfile}
              handleCloseSideBar={() => setSelectedCarrierProfile(null)}
            />
          ) : null
        }
        companySubscription={companyData.subscription}
        customCompleteValues={companyLocationData}
        emptyGridDisplay={<TruxiiEmptyGrid handleNavigateToSearch={handleNavigateToSearch} />}
        existingFormData={savedFormData || existingFormData}
        getRecentSeachesByLocationType={getRecentSeachesByLocationType}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        handleCloseCarrierProfile={() => {
          setSelectedCarrierProfile(null);
        }}
        handleNavigateToReservation={(selectedBookings) => {
          serializeBookings(selectedBookings);
          router.push(reservationRoute);
        }}
        handleNavigateToSearch={handleNavigateToSearch}
        haveResultsLoaded={haveResultsLoaded}
        haveSearchParamsLoaded={haveSearchParamsLoaded}
        isLoading={!haveResultsLoaded || areResultsLoading}
        language={transformI18nLocaleToLanguage(locale)}
        minDate={getMinimumShipmentDate}
        onSubmitSuccess={onSubmitSuccess}
        quoteMaxSelection={QUOTE_MAX_SELECTION}
        renderCompanyProfileLink={(companyCode, _carrierId, manageOverlayState) => {
          if (selectedCarrierProfile !== null) return null;
          return (
            <button
              className={classes.carrierProfile}
              onClick={() => {
                manageOverlayState(false);
                setSelectedCarrierProfile(companyCode);
              }}
            >
              <Box className={classes.carrierProfileIcon} component="span">
                <PersonIcon />
              </Box>
              <Box className={classes.carrierProfileText} component="span">
                {t('common:carrierProfile')}
              </Box>
            </button>
          );
        }}
        searchParams={searchParams}
        searchQuery={searchQuery}
        searchResults={searchResults}
        searchResultsError={error ? new Error(error) : null}
        selections={selections}
        setSelections={setSelections}
        shipmentCredits={shipmentCredits?.data || []}
        shouldSuppressProfileLink={Boolean(selectedCarrierProfile)}
        t={t}
        trackingAccessorialId={trackingAccessorialId}
        userData={userData}
      />
    </Box>
  );
};
export default ResultsPage;
