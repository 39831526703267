"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHotdogIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.044,12.9c3.077-3.35-1.8-8.01-5.046-4.851-2.533,2.553-9.457,2.541-11.982.015-4.236-3.652-8.827,2.888-3.811,5.9A4.426,4.426,0,0,0,1.256,18.5C2.434,21.775,7.683,22.9,12,23c4.328-.107,9.553-1.218,10.744-4.5a4.428,4.428,0,0,0-.949-4.53A9.828,9.828,0,0,0,23.044,12.9Zm-2.186,4.927a2.489,2.489,0,0,1-1.278,1.424A16.307,16.307,0,0,1,12,21c-3.693-.071-10.371-1.25-8.755-5.079A2.506,2.506,0,0,1,6.58,14.745,11.36,11.36,0,0,0,12,16a11.36,11.36,0,0,0,5.42-1.255A2.516,2.516,0,0,1,20.858,17.831Zm.731-6.3a9.377,9.377,0,0,1-1.578,1.234C16.962,11.714,15.56,14.24,12,14c-3.557.241-4.966-2.286-8.012-1.234A9.347,9.347,0,0,1,2.41,11.531,1.492,1.492,0,0,1,2,10.458a1.51,1.51,0,0,1,2.594-.984c3.43,3.416,11.62,3.224,14.819-.007A1.5,1.5,0,0,1,21.589,11.532ZM11,5V2a1,1,0,0,1,2,0V5A1,1,0,0,1,11,5Zm4-1V2a1,1,0,0,1,2,0V4A1,1,0,0,1,15,4ZM7,3.989V2A1,1,0,0,1,9,2V3.989A1,1,0,0,1,7,3.989Z" })));
exports.default = TruxwebHotdogIcon;
