"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        borderRadius: ({ hasTitle }) => hasTitle ? `0px 0px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px` : theme.spacing(1.25),
        padding: `${theme.spacing(3)}px ${theme.spacing(4.25)}px`,
        position: 'relative',
        width: '100%',
    },
    primary: {
        borderTop: `14px solid ${theme.palette.primary.main}`,
    },
    secondary: {
        borderTop: `14px solid ${theme.palette.secondary.main}`,
    },
}));
