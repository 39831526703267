"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRadishIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.335,8c4.95-4.354-1.972-11.273-6.322-6.322C14.128-1.3,9.263-.006,9.035,3.507c-.027,3.1,1.881,4,1.956,7.6C8.06,8.621,4.266,8.083,1.464,10.949c-2,1.916-1.292,6.491-1.449,9.035a4.019,4.019,0,0,0,4,4.016c2.539-.156,7.146.567,9.062-1.428,2.649-2.652,2.547-6.225-.169-9.546,3.561.068,4.559,2,7.6,1.958C24.013,14.776,25.322,9.878,22.335,8Zm-2.82-6a2.517,2.517,0,0,1,1.768,4.267c-.325.326-1.649,1.094-2.135,1.438-1.591.875-3.662,1.8-5.335,2.5.7-1.672,1.621-3.741,2.495-5.333C17.1,3.428,17.9,2,19.515,2Zm-6.494.006A1.844,1.844,0,0,1,14.69,3.673a49.6,49.6,0,0,0-2.176,4.472c-.392-1.7-1.533-2.958-1.487-4.463A1.844,1.844,0,0,1,13.021,2.007ZM11.663,21.158c-1.175,1.264-5.883.712-7.649.842-2.674-.163-1.886-3-1.98-5.088,0-1.908.005-3.71.844-4.549,2.466-2.448,5.334-1.29,7.705,1.064C11.906,14.751,14.66,18.156,11.663,21.158Zm9.921-8.817c-1.7,1.717-3.639-.432-5.711-.838a49.517,49.517,0,0,0,4.468-2.175A1.85,1.85,0,0,1,21.584,12.341Z" })));
exports.default = TruxwebRadishIcon;
