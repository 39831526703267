import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierAccessorialV1 } from '@truxweb/schemas';

export const updateAccessorials = async (
  accessorials: TCarrierAccessorialV1[]
): Promise<TCarrierAccessorialV1[]> => {
  const results = await makeRequestToApiGateway(
    'carrierPatchV1AccessorialCarrier',
    null,
    {
      'Content-Type': 'application/json',
    },
    accessorials
  );
  return results as TCarrierAccessorialV1[];
};
