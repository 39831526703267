// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentTrackingLocationStatusV1 = void 0;
var ShipmentTrackingLocationStatusV1;
(function (ShipmentTrackingLocationStatusV1) {
    ShipmentTrackingLocationStatusV1["ARRIVED"] = "ARRIVED";
    ShipmentTrackingLocationStatusV1["DEPARTED"] = "DEPARTED";
    ShipmentTrackingLocationStatusV1["EN_ROUTE"] = "EN_ROUTE";
    ShipmentTrackingLocationStatusV1["UNKNOWN"] = "UNKNOWN";
})(ShipmentTrackingLocationStatusV1 || (exports.ShipmentTrackingLocationStatusV1 = ShipmentTrackingLocationStatusV1 = {}));
