"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPhoneCrossIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,16.653l-2.34-1.8a3.1,3.1,0,0,0-4.281.006L14.91,16.027A12.781,12.781,0,0,1,7.979,9.082L9.137,7.626a3.1,3.1,0,0,0,.007-4.282L7.346,1a.989.989,0,0,0-.086-.1A3.081,3.081,0,0,0,2.934.859l-1.15,1c-7.719,8.21,12.2,28.145,20.4,20.3l.91-1.049a3.1,3.1,0,0,0,0-4.378A1.125,1.125,0,0,0,23,16.653Zm-1.364,3.1-.911,1.05C14.788,26.418-2.5,10.114,3.147,3.325l1.15-1a1.1,1.1,0,0,1,1.51-.039L7.593,4.609a.9.9,0,0,0,.086.1,1.1,1.1,0,0,1,0,1.549A.849.849,0,0,0,7.6,6.34L6.044,8.3a1,1,0,0,0-.145,1,15.009,15.009,0,0,0,8.822,8.811,1,1,0,0,0,.979-.152L17.66,16.4a.994.994,0,0,0,.084-.076,1.128,1.128,0,0,1,1.647.086l2.326,1.787A1.121,1.121,0,0,1,21.632,19.754ZM23.707,6.293a1,1,0,0,1-1.414,1.414L20,5.414,17.707,7.707a1,1,0,0,1-1.414-1.414L18.586,4,16.293,1.707A1,1,0,0,1,17.707.293L20,2.586,22.293.293a1,1,0,0,1,1.414,1.414L21.414,4Z" })));
exports.default = TruxwebPhoneCrossIcon;
