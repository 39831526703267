import { Box, RightHandSidebar } from '@truxweb/ux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyAddresses } from '@truxweb/common-components';
import { CompanyLocationSidebar } from '..';
import { getLocalizedRoute } from '../../utils';
import { type TCompanyLocation } from '../../stores';
import { useCompanyLocations } from '../../hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TAccountAddressesProps = {
  companyId: number;
  companyExtId: string;
};
export const AccountAddresses = ({
  companyExtId,
  companyId,
}: TAccountAddressesProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const [shouldLocationsRefetch, setLocationRefetch] = useState(true);
  const [locationEditId, setLocationId] = useState<null | number>(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const { data: companyLocations, hasLoaded: haveLocationsLoaded } =
    useCompanyLocations(shouldLocationsRefetch);

  const onSuccess = useCallback(() => {
    setLocationRefetch(true);
    if (locationEditId === 0) {
      setSidebarOpen(false);
    }
  }, [setLocationRefetch, setSidebarOpen, locationEditId]);

  const handleEditAddress = useCallback(
    (locationId: number | null) => {
      const route = getLocalizedRoute(router.locale, 'account/address');
      if (locationId) {
        router.push(`${route}?selectedLocationId=${locationId}`);
      } else {
        router.push(route);
      }
    },
    [router]
  );

  useEffect(() => {
    if (router.query && router.query.selectedLocationId) {
      setLocationId(parseInt(router.query.selectedLocationId as string, 10));
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [router, setLocationId, setSidebarOpen]);

  useEffect(() => {
    if (shouldLocationsRefetch) {
      setLocationRefetch(false);
    }
  }, [shouldLocationsRefetch, setLocationRefetch]);

  const onDeleteSuccess = useCallback(() => {
    setLocationId(null);
    setLocationRefetch(true);
    setSidebarOpen(false);
  }, [setLocationRefetch]);

  const companyLocationData = useMemo(() => {
    return (companyLocations || []).map((location: TCompanyLocation) => {
      return location.data;
    });
  }, [companyLocations]);

  return (
    <>
      <RightHandSidebar
        handleClose={() => {
          handleEditAddress(null);
        }}
        isOpen={isSidebarOpen}
      >
        {isSidebarOpen && (
          <CompanyLocationSidebar
            companyExtId={companyExtId}
            companyId={companyId}
            locationEditId={locationEditId}
            onDeleteSuccess={onDeleteSuccess}
            onSuccess={onSuccess}
          />
        )}
      </RightHandSidebar>
      <Box mt={10} style={{ position: 'relative' }}>
        <CompanyAddresses
          companyLocations={companyLocationData}
          handleAddLocation={() => {
            setLocationId(0);
            setSidebarOpen(true);
          }}
          handleEditAddress={handleEditAddress}
          haveLocationsLoaded={haveLocationsLoaded}
          t={t}
        />
      </Box>
    </>
  );
};
