"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMicrophoneAltIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18.757,16a5.214,5.214,0,0,0-3.707,1.535A15.14,15.14,0,0,1,4.272,22H3.122a1.121,1.121,0,0,1-.794-1.914l.693-.693a7.768,7.768,0,0,0,5.1-2.272L12.447,12.8a6.01,6.01,0,0,0,1.292.2Q13.868,13,14,13a6.018,6.018,0,0,0,4.246-1.758l1-1a6.01,6.01,0,0,0,1.751-4.5A5.946,5.946,0,0,0,18.848,1.4a6.179,6.179,0,0,0-8.274.544l-.633.634A6.33,6.33,0,0,0,8.247,8.511L3.879,12.879a7.759,7.759,0,0,0-2.272,5.1l-.693.693A3.121,3.121,0,0,0,3.122,24h1.15a17.13,17.13,0,0,0,12.192-5.05A3.243,3.243,0,0,1,22,21.242V23a1,1,0,0,0,2,0V21.242A5.249,5.249,0,0,0,18.757,16Zm-7.4-12.012.633-.634a4.413,4.413,0,0,1,3.1-1.3,3.815,3.815,0,0,1,2.471.877A3.971,3.971,0,0,1,19,5.826a4.013,4.013,0,0,1-1.167,3l-1,1a4,4,0,0,1-5.9-.266A4.169,4.169,0,0,1,11.355,3.988ZM5.293,14.293l3.836-3.837c.091.13.164.27.267.393a6.119,6.119,0,0,0,1.131,1.038l-3.82,3.82a5.789,5.789,0,0,1-3.018,1.6A5.789,5.789,0,0,1,5.293,14.293Z" })));
exports.default = TruxwebMicrophoneAltIcon;
