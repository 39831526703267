"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useYellowButtonStyles = exports.useGreenButtonStyles = exports.useRedButtonStyles = exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    actionsContainer: {
        height: '100%',
    },
    carrierButton: {
        '&:hover': {
            color: theme.palette.primary.light,
        },
        background: 'transparent',
        border: 0,
        color: '#636363',
        fontSize: '15px',
    },
    fullHeight: {
        height: '100%',
    },
    halfHeight: {
        height: '50%',
    },
    requestContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    segmentContainer: {
        height: '100%',
        lineHeight: 'unset',
        marginRight: theme.spacing(1),
    },
    segmentsContainer: {
        height: '100%',
        padding: `${theme.spacing(1)}px 0`,
    },
}));
exports.useRedButtonStyles = (0, __1.makeStyles)((theme) => ({
    selected: {
        backgroundColor: `${theme.palette.error.light} !important`,
    },
}));
exports.useGreenButtonStyles = (0, __1.makeStyles)((theme) => ({
    selected: {
        backgroundColor: `${theme.palette.success.light} !important`,
    },
}));
exports.useYellowButtonStyles = (0, __1.makeStyles)((theme) => ({
    selected: {
        backgroundColor: `${theme.palette.warning.light} !important`,
    },
}));
