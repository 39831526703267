import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { OnboardingTile, PageLink } from '../../components';
import { Trans, useTranslation } from 'next-i18next';
import Boxes from '../../public/assets/images/registration/boxes.svg';
import classnames from 'classnames';
import { EUserTypeV1 } from '@truxweb/schemas';
import React from 'react';
import Truck from '../../public/assets/images/registration/TruckParcels.svg';
import { useStyles } from './SignUpPageUserTypeSelect.styles';

const REQUIRED_NAMESPACES = ['common', 'select'];

export const SignUpPageUserTypeSelect = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.largeMobile));
  const classes = useStyles();

  return (
    <Grid className={classes.container} container direction="column" justifyContent="space-between">
      <Typography className={classes.primaryFont} variant="h3">
        {isMobile ? t('common:profile') : t('select:persona')}
      </Typography>

      <Typography variant="body1">{t('common:profileChoiceExplained')}</Typography>

      <OnboardingTile
        pageId="signup/shipper"
        tileIcon={
          <Box className={classes.boxesWrapper}>
            <Boxes display="flex" width={isMobile ? '140' : '100'} />
          </Box>
        }
        userType={EUserTypeV1.SHIPPER}
      />
      <OnboardingTile
        pageId="signup/carrier"
        tileIcon={
          <Box className={classes.truckWrapper}>
            <Truck width={isMobile ? '200' : '150'} />
          </Box>
        }
        userType={EUserTypeV1.CARRIER}
      />

      <Typography variant="body1">
        <Trans i18nKey="common:contactUsPrompt">
          <PageLink
            className={classnames(classes.primaryFont, classes.clickable, classes.roboto)}
            pageId="contact-us"
            target={'_blank'}
          >
            {t('common:contactUsPrompt')}
          </PageLink>
        </Trans>
      </Typography>
    </Grid>
  );
};
