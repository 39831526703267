"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const VisaUnselectedIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 53 32", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "#F3F3F3", height: 32, rx: 6.5, width: 52, x: 0.0742188 }),
    React.createElement("g", { style: {
            mixBlendMode: 'luminosity',
        } },
        React.createElement("path", { d: "M22.2486 11.0475L18.0839 20.9822H15.367L13.3178 13.0536C13.1935 12.5649 13.0859 12.386 12.7065 12.1806C12.0886 11.8459 11.0681 11.5312 10.1719 11.3357L10.2332 11.0475H14.6066C15.1632 11.0475 15.6652 11.4185 15.7911 12.0613L16.8729 17.8098L19.5483 11.0475H22.2486ZM32.894 17.7385C32.9056 15.1161 29.2676 14.972 29.2925 13.8008C29.3008 13.4446 29.6404 13.0652 30.3825 12.9691C30.7503 12.9211 31.7658 12.8846 32.9155 13.4131L33.3661 11.3059C32.7498 11.0806 31.9547 10.8652 30.964 10.8652C28.4244 10.8652 26.6353 12.2154 26.6204 14.1486C26.6038 15.5783 27.896 16.3768 28.8701 16.8522C29.8723 17.3393 30.2086 17.6524 30.2036 18.0864C30.197 18.7524 29.4051 19.0472 28.6646 19.0588C27.3725 19.0787 26.6237 18.7093 26.0257 18.431L25.5601 20.6078C26.1598 20.8828 27.2698 21.123 28.4178 21.1362C31.1197 21.1379 32.8857 19.8043 32.894 17.7385ZM39.6016 20.9822H41.9788L39.9048 11.0475H37.7114C37.2177 11.0475 36.8019 11.3341 36.618 11.7764L32.7614 20.9822H35.4601L35.9951 19.4978H39.2935L39.6016 20.9822ZM36.734 17.4619L38.0875 13.7312L38.8661 17.4619H36.734ZM25.9213 11.0475L23.7959 20.9822H21.2248L23.3519 11.0475H25.9213Z", fill: "#1434CB" }))));
exports.default = VisaUnselectedIcon;
