import {
  type AppThunk,
  setQuoteCommentError,
  setQuoteCommentsData,
  setQuoteCommentsLoading,
} from '../../stores';
import { EUserTypeV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const getQuoteCommentsById =
  (userType: EUserTypeV1, quoteId: number, shouldMarkAsRead?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setQuoteCommentsLoading({ id: quoteId }));
      let results: any;
      if (userType === EUserTypeV1.CARRIER) {
        results = await getCarrierQuoteCommentsByIdAction(quoteId);
      } else {
        results = await getShipperQuoteCommentsByIdAction(quoteId);
      }
      dispatch(
        setQuoteCommentsData({
          data: results,
          id: quoteId,
        })
      );
      if (shouldMarkAsRead) {
        await markCommentsAsReadAction(userType, quoteId);
      }
    } catch (error) {
      dispatch(setQuoteCommentError({ error: error.message, id: quoteId }));
    }
  };

export const getCarrierQuoteCommentsByIdAction = async (quoteId: number): Promise<any> => {
  return await makeRequestToApiGateway(
    'shipmentGetV1CarrierQuoteRequestById',
    { id: quoteId },
    {
      'Content-Type': 'application/json',
    },
    null,
    {
      carrierRequestId: quoteId,
      shouldReturnComments: true,
    }
  );
};

export const getShipperQuoteCommentsByIdAction = async (quoteId: number): Promise<any> => {
  return await makeRequestToApiGateway(
    'shipmentGetV1ShipperQuoteById',
    { id: quoteId },
    {
      'Content-Type': 'application/json',
    },
    null,
    {
      carrierRequestId: quoteId,
      shouldReturnComments: true,
    }
  );
};

export const markCommentsAsReadAction = async (
  userType: EUserTypeV1,
  quoteId: number
): Promise<any> => {
  if (userType === EUserTypeV1.SHIPPER) {
    await makeRequestToApiGateway(
      'shipmentGetV1ShipperQuoteById',
      { id: quoteId },
      {
        'Content-Type': 'application/json',
      },
      null,
      {
        carrierRequestId: quoteId,
        shouldMarkAsRead: true,
      }
    );
    return;
  }

  await makeRequestToApiGateway(
    'shipmentGetV1CarrierQuoteRequestById',
    { id: quoteId },
    {
      'Content-Type': 'application/json',
    },
    null,
    {
      carrierRequestId: quoteId,
      shouldMarkAsRead: true,
    }
  );
};
