"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTractorIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M11,17h-.1a4.952,4.952,0,0,0-.729-1.756l.073-.073a1,1,0,0,0-1.414-1.414l-.073.073A4.952,4.952,0,0,0,7,13.1V13a1,1,0,0,0-2,0v.1a4.952,4.952,0,0,0-1.756.729l-.073-.073a1,1,0,0,0-1.414,1.414l.073.073A4.952,4.952,0,0,0,1.1,17H1a1,1,0,0,0,0,2h.1a4.952,4.952,0,0,0,.729,1.756l-.073.073a1,1,0,0,0,1.414,1.414l.073-.073A4.952,4.952,0,0,0,5,22.9V23a1,1,0,0,0,2,0v-.1a4.952,4.952,0,0,0,1.756-.729l.073.073a1,1,0,0,0,1.414-1.414l-.073-.073A4.952,4.952,0,0,0,10.9,19H11A1,1,0,0,0,11,17ZM6,21a3,3,0,0,1,0-6A3,3,0,0,1,6,21Zm18-9a4,4,0,0,0-4-4V6a1,1,0,0,0-2,0V8H15.554L12.023,2.35A4.971,4.971,0,0,0,7.783,0H6A3,3,0,0,0,3,3V8a3,3,0,0,0-3,3,1,1,0,0,0,2,0,1,1,0,0,1,1-1H20a2.012,2.012,0,0,1,1.6,3.2l-1.555,2.073c-6.729-2-8.559,8.3-1.545,8.727a4.522,4.522,0,0,0,3.226-7.634L23.2,14.4A4.022,4.022,0,0,0,24,12ZM5,3A1,1,0,0,1,6,2H7.783a2.983,2.983,0,0,1,2.544,1.41L13.2,8H5ZM18.5,22a2.5,2.5,0,0,1,0-5A2.5,2.5,0,0,1,18.5,22Z" })));
exports.default = TruxwebTractorIcon;
