"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebStarfighterIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,6a1,1,0,0,0-1,1v7H15.983C15.7-4.73,8.3-4.671,8.017,14H2V7A1,1,0,0,0,0,7V20a1,1,0,0,0,2,0v-.586l3.878,3.878A2.433,2.433,0,0,0,9.965,22h4.07a2.433,2.433,0,0,0,4.086,1.293L22,19.414V20a1,1,0,0,0,2,0V7A1,1,0,0,0,23,6ZM10,15a53.685,53.685,0,0,1,.536-7.473,2.274,2.274,0,0,1,2.928,0A53.685,53.685,0,0,1,14,15v5H10Zm3.046-9.849a4.379,4.379,0,0,0-2.092,0A8.567,8.567,0,0,1,12,2.093,8.567,8.567,0,0,1,13.046,5.151ZM8,21.586a.43.43,0,0,1-.707.293L2,16.586V16H8Zm8.706.293A.43.43,0,0,1,16,21.586V16h6v.586Z" })));
exports.default = TruxwebStarfighterIcon;
