"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    circleCountdown: {
        // Need to account for the border
        margin: `-${theme.spacing(1.5)}px 0 0 -${theme.spacing(1.5)}px`,
    },
    countdown: {
        backgroundColor: theme.palette.common.white,
        borderColor: 'transparent',
        borderRadius: '100%',
        borderStyle: 'solid',
        borderWidth: ({ isSkinny }) => (isSkinny ? theme.spacing(1) : theme.spacing(1.5)),
        height: theme.spacing(9.25),
        width: theme.spacing(9.25),
    },
    countdownAlternateContent: {
        height: '100%',
    },
    countdownConfirmed: {
        borderColor: theme.palette.success.main,
        fill: theme.palette.success.main,
        stroke: theme.palette.success.main,
        strokeWidth: '1px',
    },
    countdownContainer: {
        position: 'absolute',
        right: 0,
        zIndex: 1,
    },
    countdownExpired: {
        borderColor: theme.palette.grey[600],
        fill: theme.palette.grey[600],
        stroke: theme.palette.grey[600],
        strokeWidth: '1px',
    },
    countdownRefused: {
        borderColor: theme.palette.error.main,
        fill: theme.palette.error.main,
        stroke: theme.palette.error.main,
        strokeWidth: '1px',
    },
}));
