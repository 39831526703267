"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebOlivesIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18.074,7.1c.2-1.442.98-4.1,3.926-4.1a1,1,0,0,0,1-1c-.225-2.068-3.847-.488-4.529.167C16.687.74,13.411.134,10,0,7.847.022,2.077.783,2,3.5,1.965,5.083,3.7,5.609,4.9,6.088c-3.152,1.775-5.61,6.5-4.736,9.941.951,4.772,6.889,6.427,10.6,3.429C14.17,27.378,24.189,24.205,24,15.5,24,11.1,21.517,7.694,18.074,7.1Zm-4.142.71a6.038,6.038,0,0,0-.925-1.071,21,21,0,0,0,3.247-.782,9.4,9.4,0,0,0-.2,1.121A6.2,6.2,0,0,0,13.932,7.811ZM4.006,3.5c3.4-2.275,10.318-1.53,12.988.008C14.315,5.03,7.415,5.78,4.006,3.5Zm.01,14.825c-6.355-4.482,3.7-16.064,8.329-9.263-1.848,1.835-2.752,5.392-2.184,8.316C8.2,19.115,5.861,19.489,4.016,18.321ZM17,22c-6.63.029-6.628-13.03,0-13C23.63,8.971,23.628,22.03,17,22Zm1-3a1,1,0,0,1-1,1c-1.738,0-3-1.683-3-4a1,1,0,0,1,2,0c0,1.1.449,2,1,2A1,1,0,0,1,18,19ZM6.681,11.24c1.263,1.136-1.417,2.354-.43,3.58a1,1,0,0,1-1.3,1.52c-1.5-1.281-.94-3.573.32-4.99A1,1,0,0,1,6.681,11.24Z" })));
exports.default = TruxwebOlivesIcon;
