import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    background: theme.palette.common.white,
  },
  menu: {
    boxShadow: '2px 0px 8px rgba(139, 149, 150, 0.15)',
  },
}));
