import { ELanguageV1, EUserTypeV1, type TCarrierQuoteDataGridRowV1 } from '@truxweb/schemas';
import { type GridFilterModel, type GridSortModel } from '@truxweb/ux';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuoteRequestListData } from '../../../actions';
import { selectCarrierQuoteListData } from '../../../selectors';
import { type TFunction } from 'next-i18next';
import { type TReduxListResponse } from '../../../types';
import { useEffect } from 'react';
import { useUserData } from '../..';

type TCarrierShipmentListData = {
  filter?: GridFilterModel | null;
  language: ELanguageV1;
  t: TFunction;
  userType: EUserTypeV1;
  limit: number;
  pageSize?: number;
  page?: number;
  sort?: GridSortModel;
};

export const useReduxCarrierQuoteList = (
  request: TCarrierShipmentListData,
  shouldFetch: boolean,
  shouldRefetch?: boolean
): TReduxListResponse<TCarrierQuoteDataGridRowV1[]> => {
  const { filter, limit, page, pageSize, sort, userType } = request;
  const dispatch = useDispatch();
  const shipmentList = useSelector(selectCarrierQuoteListData({ ...request }));
  const { userData } = useUserData();

  useEffect(() => {
    if (
      shouldFetch &&
      ((!shipmentList.hasLoaded && !shipmentList.isLoading) ||
        (!shipmentList.isLoading && shouldRefetch)) &&
      userType
    ) {
      dispatch(
        fetchQuoteRequestListData({
          filter,
          language: userData?.language || ELanguageV1.EN_CA,
          limit,
          page,
          pageSize,
          shouldLoadAllData: false,
          sort,
          userType,
        })
      );
    }
  }, [
    shipmentList,
    dispatch,
    shouldRefetch,
    userType,
    limit,
    shouldFetch,
    page,
    pageSize,
    sort,
    userData,
    filter,
  ]);

  return shipmentList;
};
