import { Box, Grid, MonetizationOnOutlinedIcon, Typography, useTheme } from '@truxweb/ux';
import { LandingPageBodyTextContent, LandingValuePropCard } from '..';
import React, { useCallback, useState } from 'react';
import { useAlerts, useErrorHandling, useUserData } from '../../hooks';
import { ETicketType } from '../../types';
import { SaveButton } from '@truxweb/common-components';
import { submitTicket } from '../../actions';
import { useStyles } from './SubscriptionMarketing.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TSubscriptionMarketing = {
  onSuccess?: () => void;
};

export const SubscriptionMarketing = ({ onSuccess }: TSubscriptionMarketing): JSX.Element => {
  const componentClasses = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const [isLoading, setIsLoading] = useState(false);
  const userData = useUserData();
  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();
  const theme = useTheme();

  const hasSubmittedSubscription =
    typeof window !== 'undefined'
      ? localStorage.getItem(`SUBSCRIPTION-SUBMISSION-CONFIRMATION`) === 'true'
      : false;

  const handleSubmitTicket = useCallback(async () => {
    try {
      setIsLoading(true);
      await submitTicket({
        companyName: userData.companyData.name,
        formType: ETicketType.subscription,
        submission: {
          form: 'SubscriptionMarketing',
          request: 'subscription',
        },
        userData: userData.userData,
      });
      addAlert({
        message: t('common:subscriptionRequestSubmitted'),
        severity: 'success',
      });

      if (typeof window !== 'undefined') {
        localStorage.setItem(`SUBSCRIPTION-SUBMISSION-CONFIRMATION`, 'true');
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  }, [userData, addAlert, t, errorHandler, setIsLoading, onSuccess]);

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item>
        <LandingPageBodyTextContent
          content={{
            ctaEnabled: false,
            p1: t('common:subscriptionsP1'),
            p2: t('common:subscriptionsP2'),
            subtitle: t('common:subscriptionsPrompt'),
            title: '',
          }}
        />
      </Grid>
      <Grid item>
        <Box mt={8}>
          <LandingValuePropCard
            cardImage={
              <MonetizationOnOutlinedIcon
                color="primary"
                style={{
                  fill: theme.palette.primary.main,
                  fontSize: '8.5rem',
                }}
              />
            }
            className={componentClasses.card}
            subtitle={t(`common:subscriptionsDesc-subtitle`)}
            title={t(`common:subscriptionsDesc-title`)}
            valueProp1={t(`common:subscriptionsDesc-vp1`)}
            valueProp2={t(`common:subscriptionsDesc-vp2`)}
            valueProp3={
              hasSubmittedSubscription ? (
                <Typography>{t('common:thanksForYourSubscriptionInterest')}</Typography>
              ) : (
                <SaveButton
                  className={componentClasses.button}
                  color="primaryLight"
                  customAction={handleSubmitTicket}
                  isSaving={isLoading}
                  t={t}
                >
                  {t('common:subscriptionInfo')}
                </SaveButton>
              )
            }
            variant={'light'}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
