"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)(() => ({
    st0: { fill: '#1F376B' },
    st1: { fill: '0#C4D93' },
    st2: { fill: '#2873AC' },
    st3: { fill: '#D1E9F9' },
    st4: { fill: '#AACEED' },
    st5: { fill: '#76B5E3' },
    st6: { fill: '#10497E' },
    st7: { fill: '#4292CF' },
    st8: { fill: '#1887C9' },
    st9: { opacity: '0.3' },
}));
