"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const DangerousGoodsIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "white", height: 16.0708, stroke: "#224882", strokeWidth: 0.9, transform: "rotate(44.9233 11.9852 0.636396)", width: 16.0708, x: 11.9852, y: 0.636396 }),
    React.createElement("rect", { fill: "white", height: 14.5811, stroke: "#224882", strokeWidth: 0.25, transform: "rotate(44.9233 11.9846 1.67482)", width: 14.5811, x: 11.9846, y: 1.67482 }),
    React.createElement("path", { d: "M11.9957 1.5L17.0001 6.74107H7.1001L11.9957 1.5Z", fill: "#224882" }),
    React.createElement("path", { d: "M11.9845 22.4703L7.11589 17.6018H16.853L11.9845 22.4703Z", fill: "#224882" }),
    React.createElement("circle", { cx: 12.0217, cy: 15.542, fill: "#224882", r: 0.561758 }),
    React.createElement("path", { d: "M11.6099 8.61364C11.6099 8.40681 11.7775 8.23914 11.9844 8.23914C12.1912 8.23914 12.3589 8.40681 12.3589 8.61364V9.73716V11.2352V13.8567C12.3589 14.0635 12.1912 14.2312 11.9844 14.2312C11.7775 14.2312 11.6099 14.0635 11.6099 13.8567V8.61364Z", fill: "#224882" })));
exports.default = DangerousGoodsIcon;
