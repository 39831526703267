"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCampingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M5.005,24H19a5,5,0,0,0,4.522-7.132L16.535,2.9a5,5,0,0,0-9.057-.026l-7,14L.47,16.9A5,5,0,0,0,5.005,24Zm3.4-2,2.717-5.438A.958.958,0,0,1,12,16a.973.973,0,0,1,.893.588L15.6,22ZM2.278,17.748l7-14.012a3,3,0,0,1,5.454.027l6.988,13.973A3,3,0,0,1,19,22H17.836L14.7,15.72a2.971,2.971,0,0,0-5.377-.027L6.168,22H5.005a3,3,0,0,1-2.727-4.252Z" })));
exports.default = TruxwebCampingIcon;
