"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBackpackIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9,11a1,1,0,0,1,1-1h4a1,1,0,0,1,0,2H10A1,1,0,0,1,9,11Zm15,7v1a5.006,5.006,0,0,1-5,5H5a5.006,5.006,0,0,1-5-5V18a5.009,5.009,0,0,1,4-4.9V12A8,8,0,0,1,8.015,5.073C8.013,5.048,8,5.026,8,5V4a4,4,0,0,1,8,0V5c0,.026-.013.048-.015.073A8,8,0,0,1,20,12v1.1A5.009,5.009,0,0,1,24,18ZM10,4.263a7.736,7.736,0,0,1,4,0V4a2,2,0,0,0-4,0ZM6,16.535a8.368,8.368,0,0,1,12,0V12A6,6,0,0,0,6,12ZM5,22h.026A4.948,4.948,0,0,1,4,19V15.184A3,3,0,0,0,2,18v1A3,3,0,0,0,5,22Zm10,0a3,3,0,0,0,2.874-2.188,6.432,6.432,0,0,0-11.748,0A3,3,0,0,0,9,22Zm7-4a3,3,0,0,0-2-2.816V19a4.948,4.948,0,0,1-1.026,3H19a3,3,0,0,0,3-3Z" })));
exports.default = TruxwebBackpackIcon;
