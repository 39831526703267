import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  headerText: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(4),
    fontWeight: 500,
    lineHeight: `${theme.spacing(5.5)}px`,
  },
}));
