"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStepStyles = exports.useStepLabelStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        marginTop: theme.spacing(4.5),
        padding: 0,
        position: 'relative',
    },
    countdown: {
        top: `${theme.spacing(9)}px`,
    },
    header: {
        color: theme.palette.primary.light,
        fontSize: theme.spacing(2.5),
        fontWeight: 600,
        marginBottom: theme.spacing(3),
        position: 'relative',
        zIndex: 1,
    },
    stepOneContainer: {
        display: ({ activeStep }) => (activeStep === 0 ? 'block' : 'none'),
    },
    stepThreeContainer: {
        display: ({ activeStep }) => (activeStep === 2 ? 'block' : 'none'),
    },
    stepTwoContainer: {
        display: ({ activeStep }) => (activeStep === 1 ? 'block' : 'none'),
    },
}));
exports.useStepLabelStyles = (0, ux_1.makeStyles)((theme) => ({
    label: {
        '&.MuiStepLabel-active': {
            color: `${theme.palette.primary.light} !important`,
            fontWeight: 600,
        },
        color: `${theme.palette.grey[600]} !important`,
        fontSize: '10px',
    },
}));
exports.useStepStyles = (0, ux_1.makeStyles)((theme) => ({
    label: {
        '&.MuiStepLabel-active': {
            color: `${theme.palette.primary.light} !important`,
            fontWeight: 600,
        },
        color: `${theme.palette.grey[600]} !important`,
        fontSize: '10px',
    },
}));
