"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarTiltIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M1.673,13.937a9.876,9.876,0,0,0,1,3.2l.533,1.03A3.986,3.986,0,0,0,8.122,19.8l.145.239a1.987,1.987,0,1,0,3.405-2.05l-.066-.111,7.01-3.929.193.321a1.988,1.988,0,1,0,3.406-2.051l-.223-.37a3.957,3.957,0,0,0,.965-1.771,3.9,3.9,0,0,0-.422-2.955L22,6.091A7.241,7.241,0,0,0,19.49,3.509L15.2.688A4.955,4.955,0,0,0,10.26.74L3.388,4.357A4.984,4.984,0,0,0,.98,8.733C.981,8.77,1.673,13.937,1.673,13.937ZM20.79,8.094a1.982,1.982,0,0,1-.663,2.713l-12.49,7a1.973,1.973,0,0,1-1.5.226,1.946,1.946,0,0,1-1.187-.843l-.533-1.03a3.605,3.605,0,0,1-.253-.526l1.663-.947.5.808a1,1,0,1,0,1.7-1.047L7.565,13.7l8.968-5.107.56.929a1,1,0,0,0,1.713-1.032L18.272,7.6l1.7-.968c.1.139.2.283.286.431ZM4.369,6.1l6.872-3.617A2.96,2.96,0,0,1,14.166,2.4l4.27,2.81L3.649,13.626l-.67-4.99A2.962,2.962,0,0,1,4.369,6.1ZM24,23a1,1,0,0,1-1,1H1a1,1,0,0,1,0-2H23A1,1,0,0,1,24,23Z" })));
exports.default = TruxwebCarTiltIcon;
