// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentLocationTypeV1 = void 0;
var ShipmentLocationTypeV1;
(function (ShipmentLocationTypeV1) {
    ShipmentLocationTypeV1["ORIGIN"] = "ORIGIN";
    ShipmentLocationTypeV1["DESTINATION"] = "DESTINATION";
})(ShipmentLocationTypeV1 || (exports.ShipmentLocationTypeV1 = ShipmentLocationTypeV1 = {}));
