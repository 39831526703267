"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    fieldset: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: theme.spacing(1),
        },
    },
    label: {
        background: theme.palette.common.white,
        marginLeft: '-0.3em',
        padding: '0 0.4em',
    },
    selectRoot: {
        padding: '11px !important',
    },
    text: {
        color: theme.palette.grey[600],
    },
}));
