import { PageBackground, UserNotifications } from '../../components';
import { AuthorizedPage } from '../../pageTemplates';
import React from 'react';
import { TTruxwebPageContentProps } from '../../types';

export const NotificationPage = ({ pageId }: TTruxwebPageContentProps): JSX.Element => {
  return (
    <>
      <AuthorizedPage pageId={pageId}>
        <PageBackground pageId={pageId}>
          <UserNotifications />
        </PageBackground>
      </AuthorizedPage>
    </>
  );
};
