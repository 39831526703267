"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSkewerIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.7.293a1,1,0,0,0-1.414,0L19.5,3.086,17.7,1.293a1,1,0,0,0-1.413,1.414L18.084,4.5,16.5,6.086,14.706,4.293a1,1,0,0,0-1.413,1.414L15.085,7.5,13.873,8.713c-3.54-4.385-6.308.58-4.07,4.071L8.514,14.073,6.722,12.267A1,1,0,1,0,5.3,13.675l1.8,1.812c-1.016.982-1.235,1.563-3.241,1.538a3.5,3.5,0,1,0,2.117,5.949A1,1,0,0,0,4.563,21.56a1.506,1.506,0,0,1-2.376-1.789c.108-.184.44-.746,1.672-.746,2.5.024,3.333-.753,4.649-2.118l1.783,1.8A1,1,0,1,0,11.71,17.3l-1.788-1.8,1.287-1.288c3.517,2.236,8.453-.544,4.078-4.078L16.5,8.914l1.792,1.793A1,1,0,0,0,19.7,9.293L17.912,7.5,19.5,5.914,21.29,7.707A1,1,0,0,0,22.7,6.293L20.911,4.5,23.7,1.707A1,1,0,0,0,23.7.293ZM14.986,12.776a2.892,2.892,0,0,1-3.762-3.764.132.132,0,0,1,.144.023l3.6,3.6A.126.126,0,0,1,14.986,12.776Z" })));
exports.default = TruxwebSkewerIcon;
