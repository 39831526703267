import { makeStyles, Theme } from '@truxweb/ux';

export const useResultsStyles = makeStyles<Theme>((theme) => ({
  list: {
    '& li:nth-child(even)': {
      background: theme.palette.primary.main,
      color: '#fff',
    },
    '& li:nth-child(odd)': {
      background: theme.palette.primary.dark,
      color: '#fff',
    },
  },
}));
