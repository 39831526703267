"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformMapShapesToCarrierZoneV1 = exports.transformLocationFormDataToCarrierLocationV1 = exports.convertFormDataToCarrierLaneEquipmentV1 = exports.convertLaneEquipment = exports.convertFormDataToCarrierLaneV1 = void 0;
const schemas_1 = require("@truxweb/schemas");
const convertFormDataToCarrierLaneV1 = (formData, carrierId) => __awaiter(void 0, void 0, void 0, function* () {
    const { active, bidirectionalService, comments, destination, equipment, origin, schedule } = formData;
    return {
        active,
        bidirectionalService,
        carrierId,
        carrierReference: formData.carrierReference,
        comments,
        destination,
        equipment: (0, exports.convertLaneEquipment)(equipment),
        id: formData.recordId ? parseInt(formData.recordId, 10) : undefined,
        origin,
        rateSheets: (formData.rateSheets || []).map((record) => {
            // We clear out the rate sheet data as all we need to handle these on the BE
            // is a reference to the ID
            return Object.assign(Object.assign({}, record), { rateSheet: null });
        }),
        serviceSchedule: schedule,
    };
});
exports.convertFormDataToCarrierLaneV1 = convertFormDataToCarrierLaneV1;
const convertLaneEquipment = (equipment) => {
    return equipment
        .filter((equip) => {
        return equip.active === true;
    })
        .map((equip) => {
        // Acting upon this value in a form will convert it to a string
        equip.minimumFlatRate.value = parseFloat(`${equip.minimumFlatRate.value}`);
        equip.ratePerDistance.value = parseFloat(`${equip.ratePerDistance.value}`);
        return equip;
    });
};
exports.convertLaneEquipment = convertLaneEquipment;
const convertFormDataToCarrierLaneEquipmentV1 = (formData) => {
    return {
        active: formData.active,
        carrierEquipmentId: parseInt(formData.carrierEquipmentId, 10),
        carrierLaneId: parseInt(formData.laneId, 10),
        comments: formData.comments || null,
        equipmentCode: formData.equipmentCode,
        equipmentId: parseInt(formData.equipmentId, 10),
        id: formData.recordId ? parseInt(formData.recordId, 10) : undefined,
        minimumFlatRate: {
            currency: formData.currency,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: formData.minimumFlatRate,
        },
        ratePerDistance: {
            currency: formData.currency,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: formData.ratePerDistance,
        },
    };
};
exports.convertFormDataToCarrierLaneEquipmentV1 = convertFormDataToCarrierLaneEquipmentV1;
const transformLocationFormDataToCarrierLocationV1 = ({ address, carrierId, centerCoords, locationName, shapes, shouldIgnoreZones, }) => {
    if (!address || !centerCoords) {
        const missingProps = [];
        if (!address)
            missingProps.push('address');
        if (!centerCoords)
            missingProps.push('centerCoords');
        throw new Error(`Cannot to Carrier Location: ${missingProps.join(',')} are missing`);
    }
    return {
        carrierId,
        city: address.city,
        country: address.country,
        name: locationName,
        postalCode: address.postalCode,
        province: address.province,
        shouldIgnoreZones,
        // If we are ignoring zones, we still need to store the center point
        // for this location, so we store it as a circle of zero size with
        // the coordinates of the center
        zones: shouldIgnoreZones
            ? [
                {
                    carrierId,
                    centerCoords,
                    name: `${address === null || address === void 0 ? void 0 : address.province}, ${address.country}`,
                    radiusInMeters: 0,
                    shape: schemas_1.EZoneShapeTypeV1.CIRCLE,
                    zoneCoordinates: [],
                },
            ]
            : (0, exports.transformMapShapesToCarrierZoneV1)({
                carrierId,
                centerCoords,
                city: address.city,
                overlays: shapes,
                province: address.province,
            }),
    };
};
exports.transformLocationFormDataToCarrierLocationV1 = transformLocationFormDataToCarrierLocationV1;
const transformMapShapesToCarrierZoneV1 = ({ carrierId, centerCoords, city, overlays, province, }) => {
    return overlays
        .filter((shape) => {
        return shape.overlay.getMap() !== null;
    })
        .map((shape, index) => {
        let zone = null;
        switch (shape.type) {
            case 'circle':
                zone = {
                    carrierId,
                    centerCoords,
                    name: `${city}, ${province} Zone ${index + 1}`,
                    radiusInMeters: shape.overlay.getRadius(),
                    shape: schemas_1.EZoneShapeTypeV1.CIRCLE,
                    zoneCoordinates: [
                        {
                            coords: {
                                latitude: shape.overlay.getCenter().lat(),
                                longitude: shape.overlay.getCenter().lng(),
                            },
                        },
                    ],
                };
                return zone;
            case 'polygon':
                zone = {
                    // FIXME: This assumes that google maps will be available whenever this function is
                    // called. For the time being this is a safe assumption, but this feels fragile
                    areaInMetersSquared: window.google.maps.geometry.spherical.computeArea(shape.overlay.getPath()),
                    carrierId,
                    centerCoords,
                    name: `${city}, ${province} Zone ${index + 1}`,
                    shape: schemas_1.EZoneShapeTypeV1.POLYGON,
                    zoneCoordinates: shape.overlay
                        .getPath()
                        .getArray()
                        .map((pathArray) => {
                        return {
                            coords: {
                                latitude: pathArray.lat(),
                                longitude: pathArray.lng(),
                            },
                        };
                    }),
                };
                return zone;
            default:
                return zone;
        }
    })
        .filter((zone) => {
        return zone !== null;
    });
};
exports.transformMapShapesToCarrierZoneV1 = transformMapShapesToCarrierZoneV1;
