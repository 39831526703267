"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const BondedCargoIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 24 17", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_645_495)" },
        React.createElement("path", { d: "M1.84619 0.672363H7.38389V16.3284H1.84619V0.672363ZM18.4623 0.672363H24V16.3284H18.4654L18.4623 0.672363ZM9.04428 8.23739L8.61007 8.38417L10.6129 10.1424C10.7658 10.5919 10.561 10.7234 10.4295 10.9619L12.6005 10.6867L12.5485 12.873L13.0011 12.8608L12.9002 10.6898L15.0773 10.9497C14.9428 10.6653 14.8235 10.5155 14.9459 10.0629L16.9457 8.3964L16.5971 8.26797C16.3096 8.04781 16.7194 7.20691 16.7805 6.67485C16.7805 6.67485 15.6155 7.07542 15.5391 6.86443L15.2394 6.29262L14.1783 7.46071C14.0621 7.48823 14.0132 7.44236 13.9857 7.34451L14.475 4.90438L13.6983 5.34164C13.6341 5.37222 13.5698 5.34776 13.527 5.27131L12.7779 3.77299L12.0073 5.32941C11.9492 5.38445 11.8911 5.39057 11.8422 5.35388L11.1022 4.93801L11.5486 7.35674C11.5119 7.45459 11.4263 7.47905 11.3285 7.42707L10.3102 6.27428C10.1787 6.48832 10.0901 6.83386 9.9127 6.91336C9.7384 6.98675 9.14825 6.76658 8.75379 6.68097C8.88833 7.16716 9.31031 7.97442 9.04428 8.24045V8.23739Z", fill: "#224882" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_645_495" },
            React.createElement("rect", { fill: "white", height: 15.656, transform: "translate(0 0.672363)", width: 24 })))));
exports.default = BondedCargoIcon;
