"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMakeupBrushIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m23.229.771a2.637 2.637 0 0 0 -3.591-.12l-14.166 12.482c-2.335.44-5.472 1.741-5.472 4.467a6.407 6.407 0 0 0 6.4 6.4c2.725 0 4.027-3.136 4.467-5.471l12.485-14.171a2.634 2.634 0 0 0 -.123-3.587zm-13.088 15.556-2.468-2.468 2.005-1.767 2.23 2.23zm-3.741 5.673a4.405 4.405 0 0 1 -4.4-4.4c0-1.508 2.639-2.287 4.049-2.537l2.888 2.887c-.251 1.411-1.029 4.05-2.537 4.05zm15.449-18.96-8.616 9.778-2.052-2.051 9.775-8.613a.631.631 0 0 1 .893.886z" })));
exports.default = TruxwebMakeupBrushIcon;
