import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  item: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    width: theme.spacing(23.25),
  },
  logout: {
    padding: 0,
  },
  popper: {
    zIndex: 2,
  },
  subhead: {
    position: 'relative',
  },
}));
