"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TarpsIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "white", height: 4.03781, stroke: "#224882", strokeWidth: 0.5, width: 13.6176, x: 1.66211, y: 14.0655 }),
    React.createElement("path", { d: "M1.41835 12.0003H15.6643L16.3766 16.5381H0.706055L1.41835 12.0003Z", fill: "#224882" }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 1.51162, x2: 1.51162, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 2.92373, x2: 2.92373, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 4.33535, x2: 4.33535, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 5.74697, x2: 5.74697, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 7.15908, x2: 7.15908, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 8.5707, x2: 8.5707, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 9.98232, x2: 9.98232, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 11.3939, x2: 11.3939, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 12.8056, x2: 12.8056, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 14.2177, x2: 14.2177, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "white", strokeWidth: 0.2, x1: 15.6293, x2: 15.6293, y1: 12.0003, y2: 16.5381 }),
    React.createElement("line", { stroke: "#224882", x2: 17.647, y1: 18.5592, y2: 18.5592 }),
    React.createElement("circle", { cx: 4.23581, cy: 19.0592, fill: "white", r: 1.36764, stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("circle", { cx: 19.059, cy: 19.0592, fill: "white", r: 1.36764, stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("path", { clipRule: "evenodd", d: "M21.2913 20.118C21.4438 19.7971 21.5291 19.4381 21.5291 19.0592C21.5291 17.6947 20.4229 16.5886 19.0585 16.5886C18.16 16.5886 17.3736 17.0682 16.9412 17.7853V17.6474V11.6474H20.8248L24 15.5411V20.118H21.2913ZM18 12.3533H20.4706L22.9412 15.5298H18V14.118V12.3533Z", fill: "#224882", fillRule: "evenodd" }),
    React.createElement("path", { d: "M8.82365 10.4709L8.82365 4.3533M8.82365 10.4709L6.52954 8.17682M8.82365 10.4709L11.1178 8.17682", stroke: "#224882", strokeLinecap: "round", strokeLinejoin: "round" })));
exports.default = TarpsIcon;
