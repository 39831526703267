import { makeStyles, Theme } from '@truxweb/ux';

type TLandingPageButtonType = {
  type?: 'standard' | 'bookNow' | 'mediumOutlineWhite' | 'mediumStandard';
  variant?: 'midnight';
  width?: number;
};

export const useStyles = makeStyles<Theme, TLandingPageButtonType>((theme: Theme) => ({
  bookNow: {
    '&:active,&:hover': {
      background: 'transparent',
      border: '2px solid',
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,
    },
    backgroundColor: theme.palette.common.white,
    border: '2px solid',
    borderColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    minWidth: 110,
    [theme.breakpoints.down('md')]: {
      '& span': {
        fontSize: '15px',
      },
      height: theme.spacing(5),
      padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      '& span': {
        fontSize: '18px',
      },
      height: theme.spacing(7),
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.up('xl')]: {
      '& span': {
        fontSize: '18px',
      },
      height: theme.spacing(8),
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
  },
  icon: { color: theme.palette.common.white },
  mediumOutlineWhite: {
    '& span': {
      fontSize: '18px',
      height: '48px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: '28px',
        whiteSpace: 'nowrap',
      },
    },
    '&:active,&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    background: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    height: '40px !important',
    maxHeight: '48px',
    minWidth: 110,
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    width: ({ width }) => {
      return width ? width : 'auto';
    },
  },
  mediumStandard: {
    '& span': {
      fontSize: '18px',
      height: '48px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: '28px',
        whiteSpace: 'nowrap',
      },
    },
    '&:active,&:hover': {
      background: 'transparent',
      border: ({ variant }) => {
        return variant === 'midnight'
          ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
          : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
      },
      color: ({ variant }) => {
        return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
      },
    },
    background: ({ variant }) => {
      return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
    },
    border: ({ variant }) => {
      return variant === 'midnight'
        ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
        : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
    },
    color: theme.palette.common.white,
    height: '40px !important',
    lineHeight: '28px',
    maxHeight: '48px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
  },
  outlineWhite: {
    '& span': {
      fontSize: '18px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
    '&:active,&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    background: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    width: ({ width }) => {
      return width ? width : 'auto';
    },
  },
  standard: {
    '&:active,&:hover': {
      background: 'transparent',
      border: ({ variant }) => {
        return variant === 'midnight'
          ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
          : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
      },
      color: ({ variant }) => {
        return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
      },
    },
    background: ({ variant }) => {
      return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
    },
    border: ({ variant }) => {
      return variant === 'midnight'
        ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
        : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
    },
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      '& span': {
        fontSize: '15px',
        height: theme.spacing(5),
        whiteSpace: 'nowrap',
      },
      padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      '& span': {
        fontSize: '18px',
        height: theme.spacing(8),
        whiteSpace: 'nowrap',
      },
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
  },
}));

export const landingPageButton = makeStyles<Theme>((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      height: theme.spacing(5),
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
      height: theme.spacing(8),
      whiteSpace: 'nowrap',
    },
  },
}));
