import { Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import classnames from 'classnames';
import { LandingPageButton } from '..';
import React from 'react';
import TruxiBoxes from '../../public/assets/images/landingPage/landing-page-truxi-boxes.svg';
import { useLandingPageMarginStyles } from '../../styles';
import { useStyles } from './LandingPageLetsTalk.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const LandingPageLetsTalk = (): JSX.Element => {
  const classes = useStyles();
  const margins = useLandingPageMarginStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const is1920 = useMediaQuery(theme.breakpoints.up('xl'));

  const link = (
    <a
      className={classes.link}
      href="https://meetings.hubspot.com/max-gravel/demo-general-calendar"
    >
      {t('common:letsTalk')}
    </a>
  );
  return (
    <Grid container direction="column">
      <Grid className={classes.primarybg} container item>
        {isMobile && (
          <>
            <Grid container direction="column" item>
              <Grid
                alignItems="flex-start"
                className={classnames(margins.landingPageMargins, classes.mobileWrap)}
                container
                direction="column"
                item
                xs
              >
                <Grid item>
                  <Typography className={classes.text} variant={is1920 ? 'h2' : 'h3'}>
                    {t('common:notSureIfTruxweb')}
                  </Typography>
                </Grid>
                <Grid item>
                  <LandingPageButton text={link} type="standard" variant="midnight" />
                </Grid>
              </Grid>
              <Grid
                alignItems="flex-end"
                className={classes.mobileTruxiWrap}
                container
                item
                justifyContent="flex-end"
              >
                <Grid className={classes.truxi} item>
                  <TruxiBoxes height={'100%'} />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {!isMobile && (
          <>
            <Grid
              alignItems="flex-end"
              className={classes.fullHeight}
              container
              item
              justifyContent="center"
              xs={5}
            >
              <Grid className={classes.truxi} item>
                <TruxiBoxes height={'100%'} width={'100%'} />
              </Grid>
            </Grid>
            <Grid
              className={classes.columnWrap}
              container
              direction="column"
              item
              justifyContent="center"
              xs
            >
              <Grid item>
                <Typography className={classes.text} variant={is1920 ? 'h2' : 'h3'}>
                  {t('common:notSureIfTruxweb')}
                </Typography>
              </Grid>
              <Grid item>
                <LandingPageButton text={link} type="standard" variant="midnight" />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {!is1920 && <Grid className={classes.darkBlueTrim} item />}
    </Grid>
  );
};
