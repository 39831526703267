import { Box, Grid, useMediaQuery, useTheme } from '@truxweb/ux';
import { LanguageSwitcher, PageLink } from '..';
import Image from 'next/image';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './MobileHeader.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TMobileHeaderProps = {
  isScrolled: boolean;
  isTransparent: boolean;
  pageId?: TPageId;
};
export const MobileHeader = ({
  isScrolled,
  isTransparent,
  pageId,
}: TMobileHeaderProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.largeMobile)
  );

  const headerClass = isTransparent && !isScrolled ? classes.container : classes.containerFilled;

  return (
    <Box className={headerClass} component="header" data-testid="MobileHeader">
      <Grid alignItems="center" container direction="row" justifyContent="space-between">
        <Grid item>
          <PageLink pageId={'home'}>
            <Image
              alt={t('common:appTitle')}
              height={isMobileScreen ? 24 : 78}
              objectFit="scale-down"
              src={
                isMobileScreen
                  ? `/assets/images/logos/truxweb-wings.svg`
                  : `/assets/images/logos/Truxweb_logo_Noir.svg`
              }
              width={isMobileScreen ? 40 : 232}
            />
          </PageLink>
        </Grid>
        <Grid item>
          <LanguageSwitcher pageId={pageId} />
        </Grid>
      </Grid>
    </Box>
  );
};
