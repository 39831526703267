"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaneEditForm = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("../../utils");
const utils_2 = require("@truxweb/utils");
const LaneEditForm_styles_1 = require("./LaneEditForm.styles");
const LaneEditForm = ({ carrier, carrierLaneEquipment, clearErrors, control, fields, handleSubmit, isCreating, isDirty, isSaving, lane, onSubmitSuccess, register, renderLocationDetails, setError, setValue, t, }) => {
    const classes = (0, LaneEditForm_styles_1.useStyles)();
    const { destination, origin } = lane;
    const onSubmit = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        clearErrors();
        let hasErrors = false;
        // Require at least one active Equipment
        const equipmentActive = (data.equipment || []).map(({ active }) => {
            return active;
        });
        if (!equipmentActive.includes(true)) {
            // NOTE: Equipment Error is managed outside of react hook form
            // due to complexities with resetting the error state
            hasErrors = true;
        }
        if (isCreating) {
            data.active = true;
        }
        (data.equipment || []).forEach((equipment, index) => {
            if (equipment.active &&
                !equipment.minimumFlatRate.value &&
                !equipment.ratePerDistance.value &&
                // FIMXE: this is a fragile way to check if an equipment can have 0 pricing
                !equipment.equipmentCode.includes('LTL')) {
                setError(`equipment.${index}.ratePerDistance.value`, { type: 'manual' });
                setError(`equipment.${index}.minimumFlatRate.value`, { type: 'manual' });
                hasErrors = true;
            }
        });
        if (hasErrors) {
            return;
        }
        const sourceLaneData = Object.assign(Object.assign({}, data), { destination: data.destination || destination, origin: data.origin || origin, schedule: data.serviceSchedule });
        const lane = yield (0, utils_1.convertFormDataToCarrierLaneV1)(sourceLaneData, carrier.id);
        onSubmitSuccess(lane);
    }), [onSubmitSuccess, destination, origin, setError, clearErrors, carrier, isCreating]);
    return (react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmit) },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mb: 3 },
                    react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.carrierReference) || ' ', id: `carrierReference`, inputProps: {
                            maxLength: 15,
                            required: false,
                        }, isDisabled: false, isRequired: false, label: t('common:referenceNumber'), shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:locations'))),
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", item: true },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 },
                    react_1.default.createElement(ux_1.Box, { className: classes.container, pb: 1, pt: 1 },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", className: classes.container, container: true, direction: "column" },
                            react_1.default.createElement(ux_1.Grid, { className: classes.dot, item: true }),
                            react_1.default.createElement(ux_1.Grid, { className: classes.connector, item: true }),
                            react_1.default.createElement(ux_1.Grid, { className: classes.dot, item: true })))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: 11 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", justifyContent: "space-between", style: { minHeight: 75 } },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { pt: 0.5 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, (0, utils_2.getCarrierLocationName)(origin)),
                                renderLocationDetails !== undefined &&
                                    renderLocationDetails(schemas_1.EShipmentLocationTypeV1.ORIGIN, origin))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { pb: 0.5 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, (0, utils_2.getCarrierLocationName)(destination)),
                                renderLocationDetails !== undefined &&
                                    renderLocationDetails(schemas_1.EShipmentLocationTypeV1.DESTINATION, destination)))))),
            !isCreating && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: "active", render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Switch, { checked: Boolean(value), color: "primary", disabled: false, onChange: onChange }), label: value ? t('common:active') : t('common:inactive'), labelPlacement: "start" }));
                        } })))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: "bidirectionalService", render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Switch, { checked: Boolean(value), color: "primary", disabled: false, onChange: onChange }), label: t('common:laneDirectionDescription'), labelPlacement: "start" }));
                        } }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.serviceSchedule) || [], name: "serviceSchedule", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                            return (react_1.default.createElement(react_1.default.Fragment, null,
                                react_1.default.createElement(ux_1.Box, { mb: 2 },
                                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                                        react_1.default.createElement(ux_1.Grid, { item: true },
                                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:schedule'))),
                                        react_1.default.createElement(ux_1.Box, { ml: 2 },
                                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true }, Boolean(error) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:pleaseAddSchedule') || '' },
                                                react_1.default.createElement(ux_1.ErrorOutlinedIcon, { style: { color: 'red' } }))))))),
                                react_1.default.createElement(__1.ScheduleToggle, { onChange: (newSchedule) => {
                                        onChange(newSchedule);
                                    }, shouldUseSmallVariant: true, t: t, value: value })));
                        }, rules: { required: true } }))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(__1.CarrierLaneEquipmentManagementForm, { carrier: carrier, control: control, equipmentData: carrierLaneEquipment, equipmentPrefix: schemas_1.EUserTypeV1.CARRIER, fields: fields, isFormDisabled: false, isOnlyActiveShown: false, register: register, setValue: setValue, t: t }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:internalNotes')),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.comments) || '', name: "comments", render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.GreyTextArea, { fullWidth: true, id: "comments", multiline: true, onChange: onChange, rows: 5, value: value }));
                        } }))),
            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(__1.SaveButton, { isDisabled: !isDirty, isSaving: isSaving, t: t }))))));
};
exports.LaneEditForm = LaneEditForm;
