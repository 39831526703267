"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudShowersHeavyIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M14,24a.979.979,0,0,1-.394-.081,1,1,0,0,1-.525-1.313l3-7a1,1,0,1,1,1.838.788l-3,7A1,1,0,0,1,14,24Zm-4,0a.979.979,0,0,1-.394-.081,1,1,0,0,1-.525-1.313l3-7a1,1,0,0,1,1.838.788l-3,7A1,1,0,0,1,10,24ZM6,24a.979.979,0,0,1-.394-.081,1,1,0,0,1-.525-1.313l3-7a1,1,0,0,1,1.838.788l-3,7A1,1,0,0,1,6,24ZM4,19.744a.992.992,0,0,1-.333-.057A5.54,5.54,0,0,1,1.8,10.43a1,1,0,0,0,.345-.9,8.147,8.147,0,0,1-.033-2.889A7.945,7.945,0,0,1,8.5.138a8.051,8.051,0,0,1,8.734,4.438,1.039,1.039,0,0,0,.743.57c6.526,1.219,8.22,10.177,2.565,13.673a1,1,0,1,1-1.078-1.684c4.144-2.515,2.884-9.179-1.878-10.029a3.009,3.009,0,0,1-2.158-1.672A6,6,0,0,0,4.086,6.967a6.136,6.136,0,0,0,.024,2.18,3,3,0,0,1-.964,2.763A3.526,3.526,0,0,0,4.333,17.8,1,1,0,0,1,4,19.744Z" })));
exports.default = TruxwebCloudShowersHeavyIcon;
