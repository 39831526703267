"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetLocationDetails = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const RateSheetLocationDetails = ({ errorHandler, googleMapsApiKey, handleArchiveLocation, handleCreateAnother, handleSuccess, isSaving, language, rateSheetLocation, rateSheetLocations, setSavingState, shouldAllowLocationArchive, submitFormData, t, }) => {
    var _a, _b, _c;
    const { clearErrors, control, formState: { errors }, handleSubmit, setError, setValue, watch, } = (0, react_hook_form_1.useForm)();
    const findRateSheet = (0, react_1.useCallback)((matchCity, matchProvince, matchCountry) => {
        return (rateSheetLocations || []).find(({ city, country, province }) => {
            return matchCity === city && matchCountry === country && matchProvince === province;
        });
    }, [rateSheetLocations]);
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { location, location: { address }, } = data;
            const locationData = Object.assign(Object.assign({}, address), { latitude: location.lat, longitude: location.lng });
            // Need to check if this already exists as a city
            const match = findRateSheet(locationData.city, locationData.province, locationData.country);
            if (match) {
                setError('location', new Error('Location Already Exists'));
                return;
            }
            setSavingState(true);
            yield submitFormData(Object.assign({}, locationData));
            handleSuccess();
            if (data.shouldCreateAnother) {
                setTimeout(() => {
                    handleCreateAnother();
                }, 150);
            }
            return;
        }
        catch (err) {
            errorHandler(err);
        }
        finally {
            setSavingState(false);
        }
    }), [
        setError,
        errorHandler,
        handleSuccess,
        setSavingState,
        submitFormData,
        handleCreateAnother,
        findRateSheet,
    ]);
    const locationChange = watch('location');
    (0, react_1.useEffect)(() => {
        if (locationChange) {
            const match = findRateSheet(locationChange.city, locationChange.province, locationChange.country);
            if (match) {
                setError('location', new Error('Location Already Exists'));
            }
            else {
                clearErrors(['location']);
            }
        }
    }, [locationChange, findRateSheet, setError, clearErrors]);
    let content;
    if (Boolean(rateSheetLocation)) {
        content = (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
                react_1.default.createElement(__1.SimpleList, { items: [
                        { header: t('common:city'), value: rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.city },
                        { header: t('common:province'), value: rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.province },
                        { header: t('common:country'), value: rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.country },
                        { header: t('common:regionCount'), value: (rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.regionCount) || 0 },
                    ] })),
            shouldAllowLocationArchive && (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                react_1.default.createElement(__1.SaveButton, { color: (rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.isActive) ? 'error' : 'success', customAction: () => __awaiter(void 0, void 0, void 0, function* () {
                        try {
                            setSavingState(true);
                            yield handleArchiveLocation(rateSheetLocation, (rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.isActive) ? false : true);
                            handleSuccess();
                        }
                        catch (err) {
                            errorHandler(err);
                        }
                        finally {
                            setSavingState(false);
                        }
                    }), isSaving: isSaving, t: t }, (rateSheetLocation === null || rateSheetLocation === void 0 ? void 0 : rateSheetLocation.isActive) ? t('common:archive') : t('common:activate'))))));
    }
    else {
        content = (react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "location", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                    return (react_1.default.createElement(__1.LocationAutocomplete, { InputLabelProps: {
                            shrink: true,
                        }, areFormValuesRequired: true, customCompleteValues: [], favorites: [], googleMapsApiKey: googleMapsApiKey, handleError: () => {
                            setValue('location', null);
                        }, hasError: Boolean(error), label: t('common:locationToAdd'), language: language, locationTypes: [], onChange: (place, geoCode) => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                clearErrors(['location']);
                                const location = yield (0, utils_1.convertGooglePlaceDataToShipperBookingV1)(place, geoCode, {});
                                onChange(location);
                            }
                            catch (err) {
                                setValue('location', null);
                            }
                        }), onClear: () => {
                            setValue('location', null);
                        }, placeholder: t('search:enterAddress'), value: value || '' }));
                }, rules: { required: true } }),
            Boolean(locationChange) && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(__1.SimpleList, { items: [
                            { header: 'City', value: (_a = locationChange === null || locationChange === void 0 ? void 0 : locationChange.address) === null || _a === void 0 ? void 0 : _a.city },
                            { header: 'Province', value: (_b = locationChange === null || locationChange === void 0 ? void 0 : locationChange.address) === null || _b === void 0 ? void 0 : _b.province },
                            { header: 'Country', value: (_c = locationChange === null || locationChange === void 0 ? void 0 : locationChange.address) === null || _c === void 0 ? void 0 : _c.country },
                        ] })),
                Boolean(errors.location) && (react_1.default.createElement(ux_1.Typography, { color: "error", fontStyle: "semibold", variant: "bodyMedium" }, t('common:locationAlreadyExists'))),
                react_1.default.createElement(ux_1.Box, { mt: 6 },
                    react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", style: { width: '100%' }, xs: true },
                        react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }))),
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "shouldCreateAnother", render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primary", onChange: onChange }), label: t('common:createAnotherLane') }));
                    } })))));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, Boolean(rateSheetLocation)
                    ? t('common:manageRateSheetLocation')
                    : t('common:addRateSheetLocation')))),
        react_1.default.createElement(ux_1.FlatCard, null, content)));
};
exports.RateSheetLocationDetails = RateSheetLocationDetails;
