import { Box, Button, Grid } from '@truxweb/ux';
import { cancelCreateShipmentQuoteRequest, setQuoteCreateLoadingState } from '../../actions';
import { Loading as CommonLoader } from '@truxweb/common-components';
import LoadingCircle from '../../public/assets/images/logos/LoadingCircle.svg';
import LoadingWings from '../../public/assets/images/logos/truxweb-loader.svg';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Wings from '../../public/assets/images/logos/truxweb-wings.svg';

const REQUIRED_NAMESPACES = ['common'];

type LoadingPropsType = {
  className?: string;
  isLoading: boolean;
  loadingPhrases?: string[];
  isGlobalLoader?: boolean;
  isActionLoading?: boolean;
  staticText?: string;
  actionType?: string;
  actionId?: number;
};
export const Loading = ({
  actionId,
  actionType,
  className,
  isActionLoading,
  isGlobalLoader,
  isLoading,
  loadingPhrases,
  staticText,
}: LoadingPropsType): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const dispatch = useDispatch();
  let loader = null;
  if (isGlobalLoader) {
    loader = <LoadingWings height="176px" />;
  }

  // NOTE: The cancel button on loading only supports certain action
  // types, when this needs to be extended a handler must be added
  const cancelButton = Boolean(isActionLoading) &&
    Boolean(actionId) &&
    Boolean(actionType) &&
    ['QUOTE_CREATE'].includes(actionType) && (
      <Box mt={14}>
        <Button
          color="primary"
          onClick={async () => {
            await cancelCreateShipmentQuoteRequest(actionId);
            dispatch(setQuoteCreateLoadingState(null, false));
          }}
          style={{ background: '#FFF' }}
          variant="outlined"
        >
          {t('common:cancel')}
        </Button>
      </Box>
    );

  if (isActionLoading) {
    loader = (
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        style={{ height: 350, position: 'relative', width: 350 }}
      >
        <Grid item style={{ left: 0, position: 'absolute', top: 0 }}>
          <LoadingCircle />
        </Grid>
        <Grid item>
          <Box mt={0}>
            <Wings height="136px" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <CommonLoader
      actionButton={cancelButton}
      className={className}
      isLoading={isLoading}
      loadingPhrases={loadingPhrases}
      staticText={staticText}
      t={t}
    >
      {loader}
    </CommonLoader>
  );
};
