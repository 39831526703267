import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { getAuth } from '../actions';
import { selectAuthData } from '../selectors';
import { setUnauthedState } from '../stores';
import { TCognitoUserData } from '../types';

export type TAuthContext = {
  authUser?: TCognitoUserData;
  hasAuthEvaluated?: boolean;
  refetchAuthData?: () => Promise<TCognitoUserData>;
};
export const AuthContext = createContext<TAuthContext>({});

type TAuthProviderProps = {
  children: JSX.Element;
  defaultAuthUser: TCognitoUserData;
  hasAuthEvaluated: boolean;
};

export const AuthProvider = ({
  children,
  defaultAuthUser,
  hasAuthEvaluated,
}: TAuthProviderProps): JSX.Element => {
  const [cognitoUser, setAuthUser] = useState<TCognitoUserData>(defaultAuthUser);
  const dispatch = useDispatch();
  const authState = useSelector(selectAuthData());

  useEffect(() => {
    if (defaultAuthUser && !cognitoUser) {
      setAuthUser(defaultAuthUser);
    }
  }, [defaultAuthUser, setAuthUser, cognitoUser]);

  useEffect(() => {
    if (
      !authState.hasLoaded &&
      !authState.isLoading &&
      defaultAuthUser &&
      hasAuthEvaluated &&
      !authState.hasLoggedOut
    ) {
      dispatch(getAuth(defaultAuthUser, true));
    } else if (
      !defaultAuthUser &&
      !authState.hasLoaded &&
      !authState.isLoading &&
      hasAuthEvaluated
    ) {
      dispatch(setUnauthedState({ hasLoggedOut: authState.hasLoggedOut }));
    }
  }, [defaultAuthUser, hasAuthEvaluated, dispatch, authState]);

  const refetchAuthData = useCallback(async (): Promise<TCognitoUserData> => {
    const user: { attributes: TCognitoUserData } = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    setAuthUser(user.attributes);
    return user.attributes;
  }, [setAuthUser]);

  return (
    <AuthContext.Provider value={{ authUser: cognitoUser, hasAuthEvaluated, refetchAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};
