import { captureException, init } from '@sentry/browser';
import { IS_SENTRY_RELEASE_ENABLED, SENTRY_DSN } from '../../config';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { UserFeedback } from '../../components';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class PageError extends Component<Props, State> {
  constructor(props: Record<string, any>) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (IS_SENTRY_RELEASE_ENABLED) {
      init({
        dsn: SENTRY_DSN,
      });

      captureException(error);
    }

    // You can use your own error logging service here
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <UserFeedback />;
    }

    // Return children components in case of no error

    return this.props.children;
  }
  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
}

export default PageError;
