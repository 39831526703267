// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactOptionsV1 = void 0;
var ContactOptionsV1;
(function (ContactOptionsV1) {
    ContactOptionsV1["OPENING_CARRIER"] = "OPENING_CARRIER";
    ContactOptionsV1["OPENING_SHIPPER"] = "OPENING_SHIPPER";
    ContactOptionsV1["PAYMENT_TERMS"] = "PAYMENT_TERMS";
    ContactOptionsV1["PRICING"] = "PRICING";
    ContactOptionsV1["GENERAL_INQUIRY"] = "GENERAL_INQUIRY";
    ContactOptionsV1["FEATURE_REQUEST"] = "FEATURE_REQUEST";
    ContactOptionsV1["BUG_REPORT"] = "BUG_REPORT";
})(ContactOptionsV1 || (exports.ContactOptionsV1 = ContactOptionsV1 = {}));
