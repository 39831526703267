"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCoffeePotIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.113,3.157A4.963,4.963,0,0,0,17.463,0H3a1.008,1.008,0,0,0-.929,1.372L3.88,5.893A30.518,30.518,0,0,0,.027,18.47,5.01,5.01,0,0,0,5,24H16a5.043,5.043,0,0,0,4.976-5.53A30.107,30.107,0,0,0,17,5.7V2a3.093,3.093,0,0,1,3.252,1.889A25.235,25.235,0,0,1,22,13a1,1,0,0,0,2,0A27.247,27.247,0,0,0,22.113,3.157Zm-3.89,17.85A2.964,2.964,0,0,1,16,22H5c-4.4-.266-2.719-5.4-2.176-8H15a1,1,0,0,0,0-2H3.387A24.194,24.194,0,0,1,5.55,7h9.9a29.109,29.109,0,0,1,3.533,11.677A3.02,3.02,0,0,1,18.223,21.007ZM15,5H5.677l-1.2-3H15Z" })));
exports.default = TruxwebCoffeePotIcon;
