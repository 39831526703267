"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSnowboardingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.432,20.4a1,1,0,0,0-1.334.469,2.01,2.01,0,0,1-2.695.926L17,20.727V15.878a3.008,3.008,0,0,0-.968-2.207l-1.954-1.8c-.026-.022-.038-.062-.065-.082l2.161-2.947,6.289,4a1,1,0,0,0,1.074-1.687L13.519,4.782A5,5,0,0,0,10.835,4H9.657a2.982,2.982,0,0,1-2.122-.878L5.707,1.293A1,1,0,0,0,4.293,2.708L6.121,4.536A4.968,4.968,0,0,0,9.657,6h1.156L8.621,8.852A3.012,3.012,0,0,0,8,10.681v2.084a.994.994,0,0,1-.553.894l-2.9,1.447a.877.877,0,0,0-.09.071L3.16,14.6a2,2,0,0,1-.975-2.656A1,1,0,0,0,.371,11.1a4.009,4.009,0,0,0,1.963,5.318l16.231,7.185A4,4,0,0,0,23.9,21.73,1,1,0,0,0,23.432,20.4ZM8.341,15.448A2.983,2.983,0,0,0,10,12.765V10.681a1,1,0,0,1,.207-.61L12.8,6.7l1.682,1.07-2.07,2.824a2,2,0,0,0,.341,2.782l1.923,1.771a1,1,0,0,1,.323.735v3.963L6.808,16.215ZM14.911,2.5a2.5,2.5,0,1,1,2.5,2.5A2.5,2.5,0,0,1,14.911,2.5Z" })));
exports.default = TruxwebSnowboardingIcon;
