"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestCarrierDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const quote_utils_1 = require("@truxweb/quote-utils");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const tableColumns_1 = require("../../tableColumns");
const hooks_1 = require("@truxweb/hooks");
const QuoteRequestCarrierDataGrid_styles_1 = require("./QuoteRequestCarrierDataGrid.styles");
const DEFAULT_ROW_HEIGHT = 115;
const DEFAULT_PAGE_SIZE = 10;
const QuoteRequestCarrierDataGrid = ({ accessorial, areFiltersEnabled, availableTags, availableTruxwebTags, columnKeys, currentSort, data, defaultSort, equipment, excludeColumnKeys, filterMode, handleColumnVisibilityChange, handleExport, handleFilterChange, handleRouteToShipment, handleSortingChange, handleToggleAutoRefresh, isExportEnabled, isHeaderShown, isLazyLoading, isLoading, isServerSideExport, language, lastLoadedDate, onPageChange, onPageSizeChange, onSelectQuote, pageSize, paginationMode, rowCount, selectedRows, sortingMode, t, viewingUserType, }) => {
    const theme = (0, ux_1.useTheme)();
    const classes = (0, QuoteRequestCarrierDataGrid_styles_1.useStyles)(theme);
    const dynamicPageSize = (0, hooks_1.useDynamicTableRowCount)({
        defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
        isLoading: Boolean(isLoading),
        onPageSizeChange,
        useMediaQuery: ux_1.useMediaQuery,
    });
    //Column definitions
    const [displayCols, activeSort, gridHeight] = (0, react_1.useMemo)(() => {
        const basicColumnDef = {
            classes,
            language,
            t,
        };
        const columns = [
            {
                align: 'left',
                field: 'isViewed',
                headerClassName: 'datagrid-header',
                headerName: t('common:isViewed'),
                renderCell: ({ value }) => {
                    return react_1.default.createElement(ux_1.DataGridUnreadIndicator, { hasBeenRead: Boolean(value) });
                },
                renderHeader: () => {
                    return ' ';
                },
                type: 'boolean',
                width: 25,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'quoteRef',
                headerAlign: 'center',
                headerName: t(`shipments:ref`, { shipmentRef: '' }),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, spacing: 1, style: { lineHeight: 0 } },
                            react_1.default.createElement(ux_1.Grid, { item: true, title: t('common:referenceNumber') },
                                react_1.default.createElement(ux_1.TruxwebWingsIcon, null)),
                            react_1.default.createElement(ux_1.Grid, { item: true, style: { maxWidth: '162px', minWidth: '162px', textAlign: 'left' } },
                                react_1.default.createElement(ux_1.DataGridColumnText, { text: row.quoteRef }))),
                        Boolean(row.carrierQuoteRef) && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, spacing: 1, style: { lineHeight: 0 } },
                            react_1.default.createElement(ux_1.Grid, { item: true, title: t('common:carrierReferenceNumber') },
                                react_1.default.createElement(ux_1.TruxwebTrailerIcon, null)),
                            react_1.default.createElement(ux_1.Grid, { item: true, style: { maxWidth: '162px', minWidth: '162px', textAlign: 'left' } },
                                react_1.default.createElement(ux_1.DataGridColumnText, { className: classes.elideText, text: row.carrierQuoteRef }))))));
                },
                sortable: true,
                width: 210,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'carrierQuoteRef',
                headerAlign: 'center',
                headerName: t(`common:carrierQuoteRef`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { fallbackText: t('common:na'), text: row.carrierQuoteRef });
                },
                width: 175,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'actions',
                filterable: false,
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`common:actionsCellHeader`),
                renderCell: ({ row }) => {
                    if (row.isAccepted) {
                        return (react_1.default.createElement(ux_1.DataGridActionIconButton, { className: classes.shipmentButton, count: 0, isActive: true, onClick: (e) => {
                                e.stopPropagation();
                                handleRouteToShipment(row.shipmentRef);
                            } },
                            react_1.default.createElement(ux_1.TruckDrivingIcon, { className: classes.shipmentIcon })));
                    }
                    return (react_1.default.createElement(ux_1.DataGridActionIconButton, { className: classes.shipmentButton, count: 0, isActive: true, onClick: (e) => {
                            e.stopPropagation();
                            // FIXME: This needs to open the chat by default
                            onSelectQuote(row.id);
                        } },
                        react_1.default.createElement(ux_1.TruxwebDocumentIcon, { style: { fill: 'inherit' } })));
                },
                sortable: false,
                valueGetter: () => {
                    return '';
                },
                width: 148,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'timer',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`shipments:timeRemaining`),
                renderCell: ({ row }) => {
                    let shouldShowNonCountdownState = false;
                    const nonCountdownStates = {
                        isAccepted: false,
                        isExpired: false,
                        isRefused: false,
                    };
                    if (row.isShipperRefused) {
                        nonCountdownStates.isRefused = true;
                        shouldShowNonCountdownState = true;
                    }
                    else if (row.isSoftExpired ||
                        row.isCarrierExpired ||
                        row.isShipperExpired ||
                        row.isCarrierRefused ||
                        row.isCancelled) {
                        nonCountdownStates.isExpired = true;
                        shouldShowNonCountdownState = true;
                    }
                    if (row.isAccepted) {
                        shouldShowNonCountdownState = true;
                        nonCountdownStates.isAccepted = true;
                    }
                    if (shouldShowNonCountdownState) {
                        return (react_1.default.createElement(ux_1.Box, { style: {
                                height: '100%',
                                lineHeight: '64px',
                                marginTop: '36px',
                                position: 'relative',
                                width: '100%',
                            } },
                            react_1.default.createElement(__1.QuoteRequestSidebarCountdown, Object.assign({ carrierRequests: [row], isSkinny: true }, nonCountdownStates))));
                    }
                    // Otherwise show the countdown
                    return (react_1.default.createElement(ux_1.DataGridColumnTimer, { maxTime: row.countdownTimerMaxTime, timeRemainingInSeconds: row.countdownTimerTimeRemaining }));
                },
                type: 'number',
                valueGetter: ({ row }) => {
                    if (row.isPending || row.isInReview) {
                        return row.countdownTimerTimeRemaining;
                    }
                    return 0;
                },
                width: 148,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'carrierQuoteStatus',
                headerAlign: 'center',
                headerName: t(`common:status`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(__1.QuoteRequestCarrierStatus, { quoteData: row._source, t: t });
                },
                sortable: true,
                type: 'singleSelect',
                valueGetter: ({ row }) => {
                    if (row.isPending) {
                        return t('common:CARRIER-carrierQuoteStatus-PENDING');
                    }
                    if (row.isCancelled) {
                        return t('common:quoteStatusBar-CANCELLED');
                    }
                    if (row.isInReview) {
                        return t('common:inReviewByClient');
                    }
                    if (row.isAccepted) {
                        return t('common:confirmed');
                    }
                    if (row.isCarrierRefused) {
                        return t('common:youDeclined');
                    }
                    if (row.isShipperRefused) {
                        return t('common:shipperDeclined');
                    }
                    if (row.isCarrierExpired) {
                        return t('common:youExpired');
                    }
                    if (row.isShipperExpired) {
                        return t('common:shipperExpired');
                    }
                    if (row.isExpired || row.isSoftExpired) {
                        return t('common:expired');
                    }
                    return t('common:na');
                },
                valueOptions: Object.values(schemas_1.EShipmentCarrierQuoteStatusV1).map((code) => t(`common:shipmentQuoteRequestDisplayStatus-${code}`)),
                width: 176,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'totalCompetitors',
                headerAlign: 'center',
                headerName: t(`shipments:competition`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.DataGridColumnChip, { classNameAvatar: classes[row.chipStyle], classNameWrapper: classes.chipWrapperNoBackground, data: row.totalCompetitors, label: t('shipments:competitors') }));
                },
                type: 'number',
                width: 175,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'shipperName',
                headerAlign: 'center',
                headerName: t(`common:shipper`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { text: row.shipperName });
                },
                width: 175,
            },
            viewingUserType === schemas_1.EUserTypeV1.TRUXWEB
                ? {
                    align: 'center',
                    cellClassName: classes.cellPointer,
                    field: 'carrierName',
                    headerAlign: 'center',
                    headerName: t(`common:carrier`),
                    renderCell: ({ row }) => {
                        return react_1.default.createElement(ux_1.DataGridColumnText, { text: row.carrierName });
                    },
                    width: 175,
                }
                : undefined,
            {
                align: 'left',
                cellClassName: classes.cellPointer,
                field: 'chargesModified',
                headerAlign: 'center',
                headerName: t(`common:price`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.DataGridColumnModified, { hasBeenModified: row.chargesOriginal !== row.chargesModified ? true : false, modifiedValue: row.chargesModified, originalValue: row.chargesOriginal === '$0.00' ? t('common:tbd') : row.chargesOriginal }));
                },
                type: 'number',
                valueGetter: ({ row }) => {
                    return row.chargesModifiedValue;
                },
                width: 200,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'bookingDateModifiedDate',
                headerAlign: 'center',
                headerName: t(`shipments:pickUpDate`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.DataGridColumnModified, { hasBeenModified: row.bookingDateOriginal !== row.bookingDateModified ? true : false, modifiedValue: row.bookingDateModified, originalValue: row.bookingDateOriginal }));
                },
                type: 'dateTime',
                width: 200,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'estimatedDeliveryDate',
                headerAlign: 'center',
                headerName: t(`common:estimatedDeliveryDate`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.DataGridColumnModified, { hasBeenModified: row.deliveryDateOriginalDate !== row.deliveryDateModifiedDate ? true : false, modifiedValue: (0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(language, new Date(row.deliveryDateModifiedDate)), originalValue: (0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(language, new Date(row.deliveryDateOriginalDate)) }));
                },
                type: 'dateTime',
                valueGetter: ({ row }) => {
                    return row.deliveryDateModifiedDate;
                },
                width: 200,
            },
            {
                align: 'left',
                cellClassName: classes.cellPointer,
                field: 'locationOrigin',
                headerName: t(`shipments:origin`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnAddress, { address: row.locationOrigin });
                },
                valueGetter: ({ row }) => {
                    return (0, utils_1.addressToDisplayString)(row.locationOrigin);
                },
                width: 350,
            },
            {
                align: 'left',
                cellClassName: classes.cellPointer,
                field: 'locationDestination',
                headerName: t(`shipments:consignee`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnAddress, { address: row.locationDestination });
                },
                valueGetter: ({ row }) => {
                    return (0, utils_1.addressToDisplayString)(row.locationDestination);
                },
                width: 350,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'shipmentType',
                headerAlign: 'center',
                headerName: t('common:shipmentType'),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { text: row.shipmentType });
                },
                type: 'singleSelect',
                valueOptions: Object.values(schemas_1.ETruckloadTypeV1).map((type) => type),
                width: 175,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'cargo',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t('common:packagingType'),
                renderCell: ({ value }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { fallbackText: '', text: value });
                },
                sortable: true,
                type: 'singleSelect',
                valueGetter: ({ row, value }) => {
                    let content = t('common:na');
                    if (row.shipmentType !== schemas_1.ETruckloadTypeV1.FTL) {
                        // If this isn't and FTL shipment we need to look at the cargo data
                        //  - If there is more than one pallet return mixed
                        const palletTypes = new Set();
                        (value || []).forEach(({ packagingType }) => {
                            palletTypes.add(packagingType);
                        });
                        if (palletTypes.size !== 1) {
                            content = t('common:mixed');
                        }
                        else if (palletTypes.size) {
                            const palletType = Array.from(palletTypes)[0];
                            content = t(`select:${palletType}`);
                        }
                    }
                    return content;
                },
                valueOptions: [
                    ...Object.values(schemas_1.ELtlPackagingV1).map((value) => t(`select:${value}`)),
                    t('common:na'),
                ],
                width: 120,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'equipmentCodes',
                headerAlign: 'center',
                headerName: t(`shipments:equipment`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnList, { items: row.equipmentCodes });
                },
                type: 'singleSelect',
                valueGetter: ({ row }) => {
                    return row.equipmentCodes.join(',');
                },
                valueOptions: equipment.map(({ code }) => t(`common:SHIPPER-equipment-${code}`)),
                width: 250,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'accessorialCodes',
                headerAlign: 'center',
                headerName: t(`shipments:additionalServices`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnList, { items: row.accessorialCodes });
                },
                type: 'singleSelect',
                valueGetter: ({ row }) => {
                    return row.accessorialCodes.join(',');
                },
                valueOptions: accessorial.map(({ code }) => t(`common:SHIPPER-accessorial-${code}`)),
                width: 350,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'shipperNote',
                headerAlign: 'center',
                headerName: t(`shipments:specialInstructions`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { fallbackText: t('common:na'), text: row.shipperNote });
                },
                width: 350,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'refusedReason',
                headerAlign: 'center',
                headerName: t(`shipments:refusedReason`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { fallbackText: t('common:na'), text: row.refusalReason });
                },
                sortable: false,
                width: 175,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'dateCreated',
                headerAlign: 'center',
                headerName: t(`common:dateCreated`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(__1.QuoteDetailsDate, { className: classes.date, date: {
                            modified: row.dateCreated,
                            original: row.dateCreated,
                        }, icon: react_1.default.createElement(react_1.default.Fragment, null), locale: language }));
                },
                type: 'dateTime',
                width: 200,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'dateUpdated',
                headerAlign: 'center',
                headerName: t(`common:dateUpdated`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(__1.QuoteDetailsDate, { className: classes.date, date: {
                            modified: row.dateUpdated,
                            original: row.dateUpdated,
                        }, icon: react_1.default.createElement(react_1.default.Fragment, null), locale: language }));
                },
                type: 'dateTime',
                width: 200,
            },
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags, field: 'tags', width: 150 })),
            viewingUserType === schemas_1.EUserTypeV1.TRUXWEB
                ? (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags: availableTruxwebTags, field: 'truxwebTags', width: 150 }))
                : undefined,
        ];
        const fullColumns = columns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        const availableCols = [];
        const displayCols = fullColumns.map((col) => {
            availableCols.push(col.field);
            if (!columnKeys && !excludeColumnKeys) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            const shouldInclude = !columnKeys ? true : columnKeys.includes(col.field);
            const shouldExclude = !excludeColumnKeys ? false : excludeColumnKeys.includes(col.field);
            if (shouldInclude && !shouldExclude) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            return Object.assign(Object.assign({}, col), { hide: true });
        });
        let activeSort = currentSort;
        if ((activeSort === null || activeSort === void 0 ? void 0 : activeSort.length) && !availableCols.includes(activeSort[0].field)) {
            activeSort = defaultSort;
        }
        const gridHeight = ((dynamicPageSize || DEFAULT_PAGE_SIZE) + 1.85) * DEFAULT_ROW_HEIGHT;
        return [displayCols, activeSort, gridHeight];
    }, [
        availableTags,
        availableTruxwebTags,
        equipment,
        accessorial,
        onSelectQuote,
        language,
        dynamicPageSize,
        t,
        viewingUserType,
        classes,
        handleRouteToShipment,
        columnKeys,
        excludeColumnKeys,
        currentSort,
        defaultSort,
    ]);
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { areFiltersEnabled: areFiltersEnabled, handleExport: handleExport, handleToggleAutoRefresh: handleToggleAutoRefresh, isExportEnabled: isExportEnabled, isHeaderShown: isHeaderShown, isLazyLoading: isLazyLoading, isLoading: isLoading, isServerSideExport: isServerSideExport, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, t: t }));
    };
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, currentSort: activeSort, data: data || [], defaultSort: defaultSort, filterMode: filterMode, gridHeight: gridHeight, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleRowClick: ({ row }) => {
            onSelectQuote(row.id);
        }, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isActionsOverlayEnabled: false, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: dynamicPageSize || DEFAULT_PAGE_SIZE, paginationMode: paginationMode, rowCount: rowCount, rowHeight: 115, selectionModel: selectedRows, shouldDisableMultipleSelection: true, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.QuoteRequestCarrierDataGrid = QuoteRequestCarrierDataGrid;
