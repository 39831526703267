"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,18.079a12.978,12.978,0,0,0-.757-4.373l-.853-2.388A5.01,5.01,0,0,0,17.681,8H13.319A5.01,5.01,0,0,0,8.61,11.318l-.853,2.388C6.9,16.223,6.209,20,9,21.5V22a2,2,0,0,0,4,0h5a2,2,0,0,0,4,0v-.5A3.923,3.923,0,0,0,24,18.079ZM13.319,10h4.362a3.005,3.005,0,0,1,2.825,1.991L21.224,14H9.776l.718-2.009A3.005,3.005,0,0,1,13.319,10Zm6.76,10H10.921C8.749,19.949,8.84,17.569,9.2,16H11v1a1,1,0,0,0,2,0V16h5v1a1,1,0,0,0,2,0V16h1.8C22.16,17.569,22.25,19.95,20.079,20ZM4,9V8H2.2c-.358,1.569-.448,3.95,1.723,4H5a1,1,0,0,1,1,1v1a2,2,0,0,1-4,0v-.5C-.792,11.994-.1,8.225.757,5.706L1.61,3.318A5.01,5.01,0,0,1,6.319,0h4.362A5.01,5.01,0,0,1,15.39,3.318l.481,1.346a1,1,0,1,1-1.884.672l-.481-1.345A3.005,3.005,0,0,0,10.681,2H6.319A3.005,3.005,0,0,0,3.494,3.991L2.776,6H8A1,1,0,0,1,8,8H6V9A1,1,0,0,1,4,9Z" })));
exports.default = TruxwebCarsIcon;
