"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGrinTearsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M.071,10.685c1.849-14.163,22.014-14.153,23.858,0a1,1,0,1,1-1.989.217C20.4-.9,3.6-.889,2.06,10.9a1,1,0,0,1-1.989-.217ZM18.7,19.421a10.049,10.049,0,0,1-13.4,0,1,1,0,1,0-1.341,1.485,12.063,12.063,0,0,0,16.086,0,1,1,0,0,0-1.34-1.485ZM7,11c0-1.054.679-2,1-2s1,.946,1,2a1,1,0,0,0,2,0c0-1.892-1.232-4-3-4S5,9.108,5,11A1,1,0,0,0,7,11Zm12,0c-.248-5.285-5.753-5.282-6,0a1,1,0,0,0,2,0c0-1.054.68-2,1-2s1,.946,1,2A1,1,0,0,0,19,11Zm-7,8a6.827,6.827,0,0,0,5.842-3.458,1,1,0,0,0-1.053-1.518A15.4,15.4,0,0,1,12,15,15.4,15.4,0,0,1,7.2,14.024a1,1,0,0,0-1.052,1.518A6.836,6.836,0,0,0,12,19Zm11.38-4.613A6.955,6.955,0,0,0,19.694,13a.637.637,0,0,0-.686.691c.125,2.138,1.7,5.845,4.372,3.687A2.115,2.115,0,0,0,23.38,14.387ZM4.306,13C2.2,13.1-1.549,14.736.62,17.38c2.673,2.159,4.247-1.552,4.372-3.687A.637.637,0,0,0,4.306,13Z" })));
exports.default = TruxwebGrinTearsIcon;
