import { Box, FacebookIcon, Grid, LinkedInIcon, TwitterIcon } from '@truxweb/ux';
import React from 'react';
import { useStyles } from './FooterSocials.styles';

export const FooterSocials = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid alignItems="center" container direction="row">
      <Grid item>
        <Box className={classes.fakeCircle} mr={1}>
          <a
            className={classes.link}
            href="http://www.facebook.com/Truxweb"
            target="truxweb-facebook"
          >
            <FacebookIcon className={classes.icon} />
          </a>
        </Box>
      </Grid>

      <Grid item>
        <Box className={classes.fakeCircle} mr={1}>
          <a
            className={classes.link}
            href="http://www.linkedin.com/company/truxweb"
            target="truxweb-linkedin"
          >
            <LinkedInIcon className={classes.icon} />
          </a>
        </Box>
      </Grid>
      <Grid item>
        <Box className={classes.fakeCircle} style={{ borderWidth: 18 }}>
          <a
            className={classes.link}
            href="http://www.twitter.com/Truxweb1"
            target="truxweb-twitter"
          >
            <TwitterIcon
              style={{
                color: 'white',
                left: `-14px`,
                position: 'absolute',
                top: `-14px`,
              }}
            />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};
