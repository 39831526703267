// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTypeV1 = void 0;
var NotificationTypeV1;
(function (NotificationTypeV1) {
    NotificationTypeV1["ACCOUNT"] = "ACCOUNT";
    NotificationTypeV1["COMPANY"] = "COMPANY";
    NotificationTypeV1["FINANCE"] = "FINANCE";
    NotificationTypeV1["GENERIC"] = "GENERIC";
    NotificationTypeV1["QUOTE"] = "QUOTE";
    NotificationTypeV1["SHIPMENT_UPDATE"] = "SHIPMENT_UPDATE";
    NotificationTypeV1["SHIPMENT_DOCUMENT"] = "SHIPMENT_DOCUMENT";
    NotificationTypeV1["SHIPMENT_COMMENT"] = "SHIPMENT_COMMENT";
})(NotificationTypeV1 || (exports.NotificationTypeV1 = NotificationTypeV1 = {}));
