"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConfirmationCodeStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useConfirmationCodeStyles = (0, ux_1.makeStyles)((theme) => ({
    character: {
        borderBottom: `2px solid #C4C4C4`,
        fontSize: theme.spacing(3),
        height: theme.spacing(5),
        marginRight: theme.spacing(1),
        width: theme.spacing(5),
    },
    characterInactive: {
        borderBottom: `2px solid #C4C4C4`,
    },
    characterSelected: {
        borderBottom: `2px solid #0087CE`,
    },
    container: {
        alignItems: 'flex-end',
        height: theme.spacing(5),
    },
}));
