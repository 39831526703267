import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  link: {
    '&:hover': {
      color: theme.palette.success.main,
    },
    color: theme.palette.success.main,
    fontFamily: theme.typography.body1.fontFamily,
  },
}));
