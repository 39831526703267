// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierFeatureV1 = void 0;
var CarrierFeatureV1;
(function (CarrierFeatureV1) {
    CarrierFeatureV1["CarbonNeutral"] = "CarbonNeutral";
    CarrierFeatureV1["ElectricFleet"] = "ElectricFleet";
})(CarrierFeatureV1 || (exports.CarrierFeatureV1 = CarrierFeatureV1 = {}));
