"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetRegionDetails = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const RateSheetRegionDetails = ({ errorHandler, handleCancel, handleCreateAnother, handleSuccess, isSaving, rateSheetLocations, rateSheetRegion, rateSheetRegions, setSavingState, shouldSuppressHeader, submitFormData, t, }) => {
    const rateSheetRegionDetailsFormControls = (0, react_hook_form_1.useForm)({
        defaultValues: rateSheetRegion,
    });
    const { clearErrors, control, handleSubmit, setError, watch } = rateSheetRegionDetailsFormControls;
    const findRateSheetRegion = (0, react_1.useCallback)((matchName) => {
        return (rateSheetRegions || [])
            .filter(({ name }) => {
            if (!rateSheetRegion)
                return true;
            return name !== rateSheetRegion.name;
        })
            .find(({ name }) => {
            return matchName === name;
        });
    }, [rateSheetRegions, rateSheetRegion]);
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Need to check if this already exists as a city
            const match = findRateSheetRegion(data.name);
            if (match) {
                setError('name', new Error('Regions Already Exists'));
                return;
            }
            setSavingState(true);
            yield submitFormData(data);
            handleSuccess();
            if (data.shouldCreateAnother && handleCreateAnother) {
                setTimeout(() => {
                    handleCreateAnother();
                }, 150);
            }
            return;
        }
        catch (err) {
            errorHandler(err);
        }
        finally {
            setSavingState(false);
        }
    }), [
        setSavingState,
        handleSuccess,
        handleCreateAnother,
        submitFormData,
        setError,
        errorHandler,
        findRateSheetRegion,
    ]);
    const nameChange = watch('name');
    (0, react_1.useEffect)(() => {
        if (nameChange) {
            const match = findRateSheetRegion(nameChange);
            if (match) {
                setError('name', new Error('Region Already Exists'));
            }
            else {
                clearErrors(['name']);
            }
        }
    }, [nameChange, setError, clearErrors, findRateSheetRegion]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !shouldSuppressHeader && (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, t('common:addRateSheetRegion'))))),
        react_1.default.createElement(ux_1.FlatCard, null,
            react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: '', id: 'name', label: t('common:name'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "text" }),
                react_1.default.createElement(__1.RateSheetRegionLocationSelection, { formControls: rateSheetRegionDetailsFormControls, rateSheetLocations: rateSheetLocations, rateSheetRegion: rateSheetRegion, t: t }),
                Boolean(rateSheetRegion) && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "isActive", render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Switch, { checked: value, color: "primary", onChange: onChange }), label: t('common:active') }));
                        } }))),
                react_1.default.createElement(ux_1.Box, { mt: 6 },
                    react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "space-between", style: { width: '100%' }, xs: true },
                        react_1.default.createElement(ux_1.Grid, { item: true }, handleCancel !== undefined && (react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:cancel')))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t })))),
                Boolean(handleCreateAnother) && (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "shouldCreateAnother", render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primary", onChange: onChange }), label: t('common:createAnotherLane') }));
                    } }))))));
};
exports.RateSheetRegionDetails = RateSheetRegionDetails;
