import { makeStyles, Theme } from '@truxweb/ux';

type TResponsivePageStylesProps = {
  isScrollTriggerDisabled: boolean;
};

export const useStyles = makeStyles<Theme, TResponsivePageStylesProps>((theme) => ({
  backgroundWhite: {
    background: theme.palette.common.white,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: ({ isScrollTriggerDisabled }) => (isScrollTriggerDisabled ? theme.spacing(9) : 0),
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: ({ isScrollTriggerDisabled }) => (isScrollTriggerDisabled ? theme.spacing(10) : 0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: ({ isScrollTriggerDisabled }) =>
        isScrollTriggerDisabled ? theme.spacing(13.5) : 0,
    },
  },
  content: {
    background: 'transparent',
    display: 'flex',
    flexGrow: 1,
    maxWidth: '1920px',
    minHeight: '100%',
    position: 'relative',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      // FIXME: this is a temporary step until we get the proper design
      minWidth: '100%',
    },
  },
  landingPage: {
    background: 'url(/assets/images/pageBackgrounds/clouds.svg) repeat center',
    backgroundColor: '#EDF8FF',
  },
  pageWrapper: {
    maxWidth: '100vw',
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  unsupportedBrowser: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
}));
