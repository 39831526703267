"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTemperatureUpIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M7,24c-6.079.117-9.334-7.638-5-11.89V5c.211-6.609,9.791-6.6,10,0v7.11C16.335,16.363,13.077,24.117,7,24ZM7,2A3,3,0,0,0,4,5v7.537a1,1,0,0,1-.332.744A5.018,5.018,0,0,0,7,22a5.018,5.018,0,0,0,3.332-8.719A1,1,0,0,1,10,12.537V5A3,3,0,0,0,7,2ZM7,20a3.007,3.007,0,0,1-1-5.829V4.89a1,1,0,0,1,2,0v9.281A3.007,3.007,0,0,1,7,20Zm0-4a1,1,0,0,0,0,2A1,1,0,0,0,7,16Zm15.7-12.707-3-3a1,1,0,0,0-1.414,0l-3,3A1,1,0,0,0,16.7,4.708L18,3.415V11a1,1,0,0,0,2,0V3.415l1.3,1.293A1,1,0,0,0,22.71,3.293h-.005Z" })));
exports.default = TruxwebTemperatureUpIcon;
