import { makeStyles, Theme } from '@truxweb/ux';

type TMobileFooterProps = {
  footerPalette: 'dark' | 'light';
};

export const useStyles = makeStyles<Theme, TMobileFooterProps>((theme: Theme) => ({
  siteMapLink: {
    '& p': {
      color: ({ footerPalette }) => {
        return footerPalette === 'light' ? theme.palette.grey[600] : theme.palette.common.white;
      },
    },
  },
  socialMedia: {
    width: '40%',
  },
  subscribe: {
    color: ({ footerPalette }) => {
      return footerPalette === 'dark' ? theme.palette.common.white : theme.palette.primary.main;
    },
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
}));
