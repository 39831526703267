// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceTypeV1 = exports.RangeUnitOfMeasureV1 = exports.CurrencyV1 = exports.StatusGenericV1 = exports.DayOfWeekV1 = exports.CountryV1 = void 0;
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var StatusGenericV1;
(function (StatusGenericV1) {
    StatusGenericV1["ACTIVE"] = "ACTIVE";
    StatusGenericV1["INACTIVE"] = "INACTIVE";
    StatusGenericV1["ARCHIVED"] = "ARCHIVED";
    StatusGenericV1["PENDING"] = "PENDING";
    StatusGenericV1["REVISE"] = "REVISE";
    StatusGenericV1["REGISTERED"] = "REGISTERED";
    StatusGenericV1["BANNED"] = "BANNED";
})(StatusGenericV1 || (exports.StatusGenericV1 = StatusGenericV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var RangeUnitOfMeasureV1;
(function (RangeUnitOfMeasureV1) {
    RangeUnitOfMeasureV1["mi"] = "mi";
    RangeUnitOfMeasureV1["km"] = "km";
})(RangeUnitOfMeasureV1 || (exports.RangeUnitOfMeasureV1 = RangeUnitOfMeasureV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
