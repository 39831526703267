"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudSunRainIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18.986,24a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v3A1,1,0,0,1,18.986,24Zm-4,0a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v3A1,1,0,0,1,14.986,24Zm-4,0a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v3A1,1,0,0,1,10.986,24ZM8,22.487a.992.992,0,0,1-.6-.2,3.45,3.45,0,0,1-1.281-1.882,3.5,3.5,0,0,1,2-4.142,6.349,6.349,0,0,1,.056-2.732,6,6,0,0,1,11.368-.854c.136.324.286.358.587.426l.178.043a5.012,5.012,0,0,1,3.557,6A4.9,4.9,0,0,1,22.748,21.3a1,1,0,0,1-1.5-1.328,2.9,2.9,0,0,0,.664-1.272,3.021,3.021,0,0,0-2.132-3.621A2.647,2.647,0,0,1,17.7,13.446,4,4,0,0,0,10.087,15.8,2.016,2.016,0,0,1,8.908,18.1a1.494,1.494,0,0,0-.852,1.8,1.46,1.46,0,0,0,.541.787,1,1,0,0,1-.6,1.8ZM2.073,14a1,1,0,0,1-.5-1.866l2.248-1.3A5.477,5.477,0,0,1,3.6,10H1A1,1,0,0,1,1,8H3.589c.022-.128.05-.256.081-.384s.077-.286.124-.426L1.552,5.915A1,1,0,0,1,2.54,4.177L4.779,5.449A5.525,5.525,0,0,1,5.422,4.8L4.134,2.572a1,1,0,0,1,1.732-1l1.29,2.235c.148-.052.3-.1.451-.135.131-.032.262-.06.393-.083V1a1,1,0,0,1,2,0V3.6a5.516,5.516,0,0,1,.8.212l1.282-2.255a1,1,0,0,1,1.738.988l-1.28,2.253a5.582,5.582,0,0,1,.615.606l2.273-1.267a1,1,0,0,1,.994,1.736L14.167,7.16a2.017,2.017,0,0,1-2.521-.451,3.5,3.5,0,0,0-3.557-1.1A3.434,3.434,0,0,0,5.611,8.1a3.512,3.512,0,0,0,1.056,3.514A1,1,0,0,1,5.333,13.1a5.5,5.5,0,0,1-.517-.53l-2.244,1.3A.992.992,0,0,1,2.073,14Z" })));
exports.default = TruxwebCloudSunRainIcon;
