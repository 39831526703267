// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YesNoV1 = void 0;
var YesNoV1;
(function (YesNoV1) {
    YesNoV1["YES"] = "YES";
    YesNoV1["NO"] = "NO";
})(YesNoV1 || (exports.YesNoV1 = YesNoV1 = {}));
