"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebChocolateIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.547,5.666,18.355,1.474a4.994,4.994,0,0,0-7.065,0L1.473,11.291a5.019,5.019,0,0,0,0,7.064l4.192,4.192a5.019,5.019,0,0,0,7.065,0l9.817-9.817A5.018,5.018,0,0,0,22.547,5.666ZM14.823,2.009c1.433-.115,2.557,1.33,3.467,2.225L15.747,6.776,12.28,3.309A3.334,3.334,0,0,1,14.823,2.009Zm2.109,13.51-3.551-3.551L15.747,9.6,19.3,13.153Zm-3.753,3.753L9.628,15.721l2.34-2.34,3.551,3.551ZM7.088,8.5l3.467,3.467-2.34,2.34L4.748,10.842Zm4.88,2.054L8.5,7.088l2.366-2.366,3.467,3.467ZM2.009,14.823a3.368,3.368,0,0,1,1.326-2.568L6.8,15.721,4.233,18.289C3.335,17.376,1.9,16.26,2.009,14.823Zm5.069,6.311L5.646,19.7l2.569-2.568,3.551,3.551A3.163,3.163,0,0,1,7.078,21.134Zm14.056-9.817-.423.423L17.16,8.189,19.7,5.647l1.432,1.431A3.011,3.011,0,0,1,21.134,11.317Z" })));
exports.default = TruxwebChocolateIcon;
