"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebNetworkCloudIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M490.667,426.667H316.075c-6.454-18.068-20.673-32.287-38.741-38.741v-88.789c28.672,0,53.76-0.256,66.027-0.491  c7.657-0.144,15.278-1.081,22.741-2.795c39.201-9.122,69.811-39.732,78.933-78.933c13.398-56.167-20.211-112.828-75.925-128  l-3.669-0.811c-6.4-1.429-9.621-2.133-12.544-9.152C325.823,13.652,250.937-17.34,185.634,9.733  c-37.55,15.567-65.473,48.045-75.234,87.504c-4.619,19.111-5.034,38.996-1.216,58.283c-37.88,16.299-55.374,60.219-39.076,98.098  c8.946,20.792,26.841,36.403,48.654,42.446c5.805,1.542,11.767,2.416,17.771,2.603c13.973,0.384,54.059,0.555,95.872,0.555h2.261  v88.725c-18.063,6.449-32.282,20.66-38.741,38.72H21.333C9.551,426.667,0,436.218,0,448c0,11.782,9.551,21.333,21.333,21.333  h174.592c11.559,33.178,47.826,50.704,81.004,39.146c18.34-6.389,32.756-20.806,39.145-39.146h174.592  c11.782,0,21.333-9.551,21.333-21.333C512,436.218,502.449,426.667,490.667,426.667z M137.728,256  c-2.739-0.098-5.459-0.506-8.107-1.216c-10.546-2.959-18.791-11.195-21.76-21.739c-4.54-15.55,3.256-32.04,18.155-38.4  c19.159-8.109,29.761-28.774,25.173-49.067c-9.726-46.114,19.773-91.381,65.887-101.106c1.539-0.325,3.087-0.607,4.641-0.846  c4.161-0.615,8.36-0.936,12.565-0.96c34.665-0.12,65.98,20.676,79.317,52.672l0,0c7.531,18.714,24.483,31.981,44.459,34.795  c33.514,9.281,53.635,43.463,45.483,77.269c-5.617,23.167-23.746,41.23-46.933,46.763c-4.604,1.079-9.309,1.666-14.037,1.749  C327.787,256.192,153.835,256.427,137.728,256z M256,469.333c-11.782,0-21.333-9.551-21.333-21.333  c0-11.782,9.551-21.333,21.333-21.333c11.782,0,21.333,9.551,21.333,21.333C277.333,459.782,267.782,469.333,256,469.333z" })));
exports.default = TruxwebNetworkCloudIcon;
