"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchEquipment = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const utils_1 = require("../../utils");
const __1 = require("..");
const SearchEquipment_styles_1 = require("./SearchEquipment.styles");
const MAXIMUM_DISPLAY_CARDS = 4;
const SearchEquipment = ({ onChange, searchEquipment, t, value, }) => {
    const classes = (0, SearchEquipment_styles_1.useStyles)();
    const [cardHorizontalPosition, setCardHorizontalPosition] = (0, react_1.useState)(0);
    const [primarySelection, setPrimarySelection] = (0, react_1.useState)(null);
    const [equipmentCategory, setEquipmentCategory] = (0, react_1.useState)(null);
    const [primaryEquipment, equipmentVariants, variantParents, activeVariants] = (0, react_1.useMemo)(() => {
        const primaryEquipment = Object.keys(searchEquipment).filter((key) => {
            return searchEquipment[key].isParentVariant;
        });
        const equipmentVariants = {};
        const variantParents = {};
        primaryEquipment.forEach((code) => {
            const variants = searchEquipment[code].hasVariants ? searchEquipment[code].variants : [];
            equipmentVariants[code] = variants;
            variants.forEach((variant) => {
                variantParents[variant] = code;
            });
        });
        const sortingArray = equipmentCategory ? utils_1.EQUIPMENT_CATEGORY_SORTING[equipmentCategory] : [];
        const activeVariants = (primarySelection ? equipmentVariants[primarySelection] || [] : [])
            .slice()
            .sort((a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b));
        return [primaryEquipment, equipmentVariants, variantParents, activeVariants];
    }, [searchEquipment, primarySelection, equipmentCategory]);
    const onSelectEquipment = (0, react_1.useCallback)((equipmentCode) => {
        const newPrimarySelection = primaryEquipment.includes(equipmentCode)
            ? equipmentCode
            : variantParents[equipmentCode];
        setEquipmentCategory(searchEquipment[equipmentCode].category);
        onChange(searchEquipment[equipmentCode].equipment);
        if (primarySelection !== newPrimarySelection) {
            setPrimarySelection(newPrimarySelection);
            setCardHorizontalPosition(0);
        }
    }, [
        primarySelection,
        onChange,
        searchEquipment,
        primaryEquipment,
        variantParents,
        setPrimarySelection,
        setEquipmentCategory,
        setCardHorizontalPosition,
    ]);
    const handleChange = (0, react_1.useCallback)((event) => {
        event.preventDefault();
    }, []);
    const glideBack = (0, react_1.useCallback)(() => {
        //if cardposition is 0, do nothing
        if (cardHorizontalPosition < 0)
            setCardHorizontalPosition(cardHorizontalPosition + 100);
    }, [setCardHorizontalPosition, cardHorizontalPosition]);
    const glideForward = (0, react_1.useCallback)(() => {
        if ((activeVariants.length - MAXIMUM_DISPLAY_CARDS) * -100 < cardHorizontalPosition)
            setCardHorizontalPosition(cardHorizontalPosition - 100);
    }, [activeVariants, setCardHorizontalPosition, cardHorizontalPosition]);
    const renderCard = (0, react_1.useCallback)((code, isSecondary, isChecked) => {
        if (!searchEquipment[code])
            return null;
        const equipment = searchEquipment[code].equipment;
        return (react_1.default.createElement(__1.EquipmentSelectCard, { category: searchEquipment[code].category, checkboxName: code, className: isChecked ? classes.selected : '', color: isSecondary ? 'primary' : 'grey', equipment: equipment, handleChange: handleChange, handleClick: () => {
                onSelectEquipment(equipment.code);
            }, isChecked: isChecked, isSecondary: isSecondary, key: code, t: t }));
    }, [t, searchEquipment, classes, onSelectEquipment, handleChange]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (primaryEquipment.includes(value.code)) {
            setPrimarySelection(value.code);
        }
        else {
            // If we have selected a variant (or if the equipment has no variants)
            // we need to determine what the primary selection is
            setPrimarySelection(variantParents[value.code]);
        }
        setEquipmentCategory((_a = searchEquipment[value.code]) === null || _a === void 0 ? void 0 : _a.category);
    }, [
        searchEquipment,
        primaryEquipment,
        equipmentVariants,
        variantParents,
        setPrimarySelection,
        value,
        setEquipmentCategory,
        primarySelection,
    ]);
    return (react_1.default.createElement(ux_1.Grid, null,
        react_1.default.createElement(ux_1.Container, { className: classes.flexContainer }, primaryEquipment.map((code, index) => {
            const isChecked = primarySelection === code;
            return (react_1.default.createElement(ux_1.Grow, { in: true, key: code, timeout: index * 1000 },
                react_1.default.createElement(ux_1.Box, null, renderCard(code, false, isChecked))));
        })),
        react_1.default.createElement(ux_1.Container, { className: classes.flexContainer },
            react_1.default.createElement(ux_1.Container, { className: classes.flexContainerSecondary },
                react_1.default.createElement(ux_1.Box, { mt: 3.75 },
                    activeVariants.length !== 0 && (react_1.default.createElement(ux_1.Typography, { variant: "body1" }, t(`search:${equipmentCategory}_equipment_header`))),
                    react_1.default.createElement(ux_1.Container, { className: classes.flexContainer }, activeVariants.map((code, index) => {
                        const isChecked = value.code === code;
                        return (react_1.default.createElement(ux_1.Grow, { in: true, key: code, timeout: index * 500 },
                            react_1.default.createElement(ux_1.Box, { className: classes.glide, style: { transform: `translateX(${cardHorizontalPosition}%)` } }, renderCard(code, true, isChecked))));
                    })),
                    react_1.default.createElement(ux_1.Container, { className: classes.buttonWrapper },
                        cardHorizontalPosition < 0 && (react_1.default.createElement(ux_1.Box, { className: classes.buttonBack },
                            react_1.default.createElement(ux_1.IconButton, { "aria-label": "Move Forward", color: "inherit", component: "label", disableRipple: true, onClick: glideBack },
                                react_1.default.createElement(ux_1.ArrowBackIosNewIcon, { className: classes.carouselButton })))),
                        (activeVariants.length - MAXIMUM_DISPLAY_CARDS) * -100 < cardHorizontalPosition && (react_1.default.createElement(ux_1.Box, { className: classes.buttonForward },
                            react_1.default.createElement(ux_1.IconButton, { "aria-label": "Move Forward", color: "inherit", component: "label", disableRipple: true, onClick: glideForward },
                                react_1.default.createElement(ux_1.ArrowForwardIosIcon, { className: classes.carouselButton }))))))))));
};
exports.SearchEquipment = SearchEquipment;
