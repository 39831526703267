"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierEquipmentManagementForm = void 0;
const __1 = require("..");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const ux_1 = require("@truxweb/ux");
const CarrierEquipmentManagementForm = ({ carrier, carrierEquipmentData, handleCancel, handleSubmitSuccess, isFormDisabled, isLoading, isSaving, t, }) => {
    const [hasPendingUpdates, setHasPendingUpdates] = (0, react_1.useState)(false);
    const sortedEquipment = (0, react_1.useMemo)(() => {
        const sortingArray = [
            '53_DRY_FTL',
            'DRY_VAN_TRIPLE_AXLE',
            '53_REEFER_FTL',
            'FLATBED_DUAL_AXLE',
            'FLATBED_TRIPLE_AXLE',
            'FLATBED_QUAD_AXLE',
            'FLATBED_B_TRAIN',
            'STEP_DECK',
            'FLATBED_TRIPLE_AXLE_DROP_DECK',
            'DOUBLE_DROP_DECK',
            'FLATBED_RGN',
        ];
        return carrierEquipmentData
            .slice()
            .sort((a, b) => sortingArray.indexOf(a.equipmentCode || '') - sortingArray.indexOf(b.equipmentCode || ''));
    }, [carrierEquipmentData]);
    const { control, formState: { isDirty }, handleSubmit, register, reset, setValue, } = (0, react_hook_form_1.useForm)({
        defaultValues: { equipment: sortedEquipment },
    });
    (0, react_1.useEffect)(() => {
        setValue('equipment', sortedEquipment);
        const hasPendingUpdates = sortedEquipment
            .map((field) => {
            return field.pendingUpdate;
        })
            .includes(true);
        setHasPendingUpdates(hasPendingUpdates);
    }, [sortedEquipment, setValue, setHasPendingUpdates]);
    const { fields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'equipment',
    });
    return (react_1.default.createElement("form", { onSubmit: handleSubmit(handleSubmitSuccess) },
        react_1.default.createElement(__1.CarrierManagementWrapper, { additionalActions: react_1.default.createElement(react_1.default.Fragment, null, handleCancel !== undefined && (react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:cancel')))), areButtonsDisabled: !isDirty, hasPendingUpdates: hasPendingUpdates, isCancelDeactivated: true, isLoading: isLoading, isSaving: isSaving, onCancel: () => {
                reset({
                    equipment: sortedEquipment,
                });
            }, pendingUpdateMessage: t('common:pendingEquipmentUpdates'), t: t },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(__1.CarrierEquipmentManagement, { control: control, equipmentData: sortedEquipment || [], fields: fields, isCarrierActive: carrier === null || carrier === void 0 ? void 0 : carrier.active, isFormDisabled: isFormDisabled, register: register, t: t })))));
};
exports.CarrierEquipmentManagementForm = CarrierEquipmentManagementForm;
