"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSoupIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M13.861,24H10.139a8.922,8.922,0,0,1-6.268-2.534A14.759,14.759,0,0,1,.164,15.15,4.011,4.011,0,0,1,4,10H20a4.012,4.012,0,0,1,3.838,5.15,14.741,14.741,0,0,1-3.708,6.316A8.918,8.918,0,0,1,13.861,24ZM4,12a1.981,1.981,0,0,0-1.6.8,2.019,2.019,0,0,0-.322,1.791,12.933,12.933,0,0,0,3.2,5.46A6.907,6.907,0,0,0,10.139,22h3.722a6.9,6.9,0,0,0,4.856-1.951,12.919,12.919,0,0,0,3.2-5.46,2.019,2.019,0,0,0-.322-1.791A1.978,1.978,0,0,0,20,12ZM16,6a4.381,4.381,0,0,0-1.293-3.121A2.4,2.4,0,0,1,14,1.168V1A1,1,0,1,0,12,1v.168a4.385,4.385,0,0,0,1.293,3.122A2.4,2.4,0,0,1,14,6a1,1,0,0,0,2,0ZM12,7V6.692a4.387,4.387,0,0,0-1.292-3.121A2.4,2.4,0,0,1,10,1.864V1A1,1,0,0,0,8,1v.864A4.386,4.386,0,0,0,9.293,4.985,2.4,2.4,0,0,1,10,6.692V7a1,1,0,0,0,2,0Z" })));
exports.default = TruxwebSoupIcon;
