import { Box, Button, CheckCircleOutlineIcon, Divider, Grid, List, Typography } from '@truxweb/ux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InAppNotificationListItem } from '../NotificationListItem';
import { markUserNotificationsAsReadByIds } from '../../actions';
import { Pagination } from '..';
import { TInAppNotificationV1 } from '@truxweb/schemas';
import { useAlerts } from '../../hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'notifications'];

type TNotificationListProps = {
  notifications: TInAppNotificationV1[];
  shouldDisplayLink: boolean;
  onMarkAsReadSuccess?: () => Promise<void>;
  noNotificationsToDisplayMsg: string;
};

const PAGE_SIZE = 16;

export const NotificationList = ({
  noNotificationsToDisplayMsg,
  notifications,
  onMarkAsReadSuccess,
  shouldDisplayLink,
}: TNotificationListProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const currentPage = parseInt((router.query?.page as string) || '1', 10);
  const [totalPages, setTotalPages] = useState(0);

  const { addAlert } = useAlerts();
  const [hasErrorDisplayed, setHasErrorDisplayed] = useState(false);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

  useEffect(() => {
    if (notifications.length > 0) {
      // Setting the total pages
      const totalPages = Math.ceil(notifications.length / PAGE_SIZE);
      setTotalPages(totalPages);
    }
  }, [setTotalPages, notifications]);

  useEffect(() => {
    if (hasErrorDisplayed) setHasErrorDisplayed(false);
  }, [hasErrorDisplayed, setHasErrorDisplayed]);

  const currentlyVisibleItems = useMemo(() => {
    if (currentPage > 0 && totalPages > 0 && notifications.length > 0) {
      return notifications.slice(PAGE_SIZE * (currentPage - 1), PAGE_SIZE * currentPage);
    }
    return [];
  }, [currentPage, totalPages, notifications]);

  const markNotificationsAsRead = useCallback(
    async (deliveryRecipientIds: number[]): Promise<void> => {
      if (onMarkAsReadSuccess && deliveryRecipientIds?.length > 0) {
        // isLoading => disabled state
        setAreButtonsDisabled(true);
        try {
          await markUserNotificationsAsReadByIds(deliveryRecipientIds);
          await onMarkAsReadSuccess();
        } catch (err) {
          addAlert({
            message: err.message,
            severity: 'error',
          });
          setHasErrorDisplayed(true);
        } finally {
          setAreButtonsDisabled(false);
        }
      }
    },
    [onMarkAsReadSuccess, addAlert, setHasErrorDisplayed, setAreButtonsDisabled]
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: '1rem', width: '100%' }}
      >
        <Grid item>
          <Typography variant="h5">{t('common:notifications')}</Typography>
        </Grid>
        <Grid item>
          {notifications.length > 0 && onMarkAsReadSuccess && (
            <Button
              disabled={areButtonsDisabled}
              onClick={() =>
                markNotificationsAsRead(
                  notifications
                    ?.filter((elt) => elt.dateRead === null || elt.dateRead === undefined)
                    ?.map((notification) => notification.notificationRecipientDeliveryId)
                )
              }
            >
              <Grid alignContent="center" container>
                <CheckCircleOutlineIcon color="primary" fontSize="small" />
                <Box ml={1}>
                  <Typography color="primary">{t('notifications:markAllAsRead')}</Typography>
                </Box>
              </Grid>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs>
        {notifications.length === 0 && <Typography>{noNotificationsToDisplayMsg}</Typography>}
        {notifications.length > 0 && (
          <List>
            {currentlyVisibleItems?.map((notification: TInAppNotificationV1) => {
              return (
                <Box key={notification.notificationRecipientDeliveryId} mb={3}>
                  <InAppNotificationListItem
                    isLinkDisabled={areButtonsDisabled}
                    markAsRead={
                      onMarkAsReadSuccess
                        ? (recipientDeliveryId: number) =>
                            markNotificationsAsRead([recipientDeliveryId])
                        : undefined
                    }
                    notification={notification}
                    shouldDisplayLink={shouldDisplayLink}
                  />
                  <Divider />
                </Box>
              );
            })}
          </List>
        )}
      </Grid>
      {totalPages > 1 && (
        <Grid item xs>
          <Grid container justifyContent="center">
            <Grid item>
              <Pagination
                currentPage={currentPage}
                handleChangePage={(pageNumber) => {
                  if (pageNumber !== currentPage) {
                    const query: Record<string, any> = {
                      page: pageNumber,
                    };
                    router.push({
                      pathname: router.pathname,
                      query,
                    });
                  }
                }}
                totalPages={totalPages}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
