"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPhoneSlashIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.7.307a1,1,0,0,0-1.413,0L10.093,12.494A14.192,14.192,0,0,1,7.981,9.085L9.139,7.631a3.1,3.1,0,0,0,.007-4.277S7.294.95,7.264.92A3.08,3.08,0,0,0,2.94.873l-1.149,1C-2.225,6.321,1.153,12.587,5.34,17.244L.3,22.28a1,1,0,0,0,1.413,1.413L23.7,1.72A1,1,0,0,0,23.7.307ZM2.007,6.245A4.116,4.116,0,0,1,3.153,3.336l1.149-1a1.1,1.1,0,0,1,1.509-.039S7.652,4.686,7.682,4.716a1.094,1.094,0,0,1,0,1.547C7.656,6.289,6.048,8.3,6.048,8.3A1,1,0,0,0,5.9,9.3a16.393,16.393,0,0,0,2.766,4.618L6.752,15.833C3.809,12.674,2.007,9.087,2.007,6.245ZM23.085,16.733a3.1,3.1,0,0,1,0,4.373l-.91,1.048C18.3,25.681,13.6,23.618,9.2,20.645a1,1,0,0,1,1.2-1.6c3.509,2.315,7.332,4.342,10.32,1.749l.911-1.049a1.118,1.118,0,0,0,.085-1.558s-2.392-1.84-2.422-1.87a1.1,1.1,0,0,0-1.548,0c-.027.027-2.043,1.633-2.043,1.633a.994.994,0,0,1-.964.157,15.425,15.425,0,0,1-1.912-.881,1,1,0,0,1,.932-1.763c.371.2.756.391,1.156.561l1.462-1.163a3.1,3.1,0,0,1,4.278-.006S23.054,16.7,23.085,16.733Z" })));
exports.default = TruxwebPhoneSlashIcon;
