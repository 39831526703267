import { useAlerts, useCompanyDocuments, useUserData } from '../../hooks';
import { CompanyDocuments } from '@truxweb/common-components';
import { downloadCompanyDocument } from '../../actions';
import React from 'react';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const CompanyDocumentsWrapper = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const companyDocumentData = useCompanyDocuments();
  const userData = useUserData();
  const { addAlert } = useAlerts();

  return (
    <CompanyDocuments
      data={companyDocumentData?.data || []}
      handleDocumentDownload={async (documentId) => {
        return await downloadCompanyDocument(documentId);
      }}
      isLoading={companyDocumentData.isLoading}
      language={userData.userData?.language}
      onDownloadError={(error) => {
        addAlert({
          message: error,
          severity: 'error',
        });
      }}
      onDownloadSuccess={() => {
        addAlert({
          message: t('common:success'),
          severity: 'success',
        });
      }}
      t={t}
    />
  );
};
