"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyDocuments = void 0;
const ux_1 = require("@truxweb/ux");
const utils_1 = require("@truxweb/utils");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const quote_utils_1 = require("@truxweb/quote-utils");
const CompanyDocuments = ({ columnKeys, data, defaultSort, excludeColumnKeys, handleDocumentDownload, isActionsOverlayEnabled, isExportEnabled, isLoading, language, onDownloadError, onDownloadSuccess, pageSize, renderActionsContent, selectedRows, t, }) => {
    const onDownloadFile = (0, react_1.useCallback)((documentId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const strFile = yield handleDocumentDownload(documentId);
            if (strFile) {
                const link = document.createElement('a');
                link.href = strFile;
                link.setAttribute(`target`, `_blank`);
                link.setAttribute(`rel`, `noopener noreferrer`);
                document.body.appendChild(link);
                link.click();
                (_a = link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
                onDownloadSuccess();
            }
        }
        catch (err) {
            onDownloadError(err.message);
        }
    }), [handleDocumentDownload, onDownloadSuccess, onDownloadError]);
    //Column definitions
    const columns = [
        {
            align: 'left',
            field: 'hasBeenRead',
            headerClassName: 'datagrid-header',
            headerName: '',
            renderCell: ({ value }) => {
                return react_1.default.createElement(ux_1.DataGridUnreadIndicator, { hasBeenRead: Boolean(value) });
            },
            renderHeader: () => {
                return ' ';
            },
            width: 25,
        },
        {
            align: 'center',
            field: 'documentType',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:documentType`),
            renderCell: ({ value }) => {
                return react_1.default.createElement(ux_1.DataGridColumnText, { text: value });
            },
            type: 'dateTime',
        },
        {
            align: 'center',
            field: 'invoiceDate',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:invoiceDate`),
            renderCell: ({ row }) => {
                var _a, _b;
                return (react_1.default.createElement(ux_1.DataGridColumnText, { text: ((_a = row.metadata) === null || _a === void 0 ? void 0 : _a.invoiceDate)
                        ? (0, utils_1.formatLocalizedDate)((_b = row.metadata) === null || _b === void 0 ? void 0 : _b.invoiceDate, language)
                        : '' }));
            },
            type: 'dateTime',
        },
        {
            align: 'center',
            field: 'invoiceAmount',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:amount`),
            renderCell: ({ row }) => {
                var _a, _b;
                const amountField = ((_a = row.metadata) === null || _a === void 0 ? void 0 : _a.transactionAmount) || ((_b = row.metadata) === null || _b === void 0 ? void 0 : _b.invoiceAmount);
                const priceDisplay = amountField
                    ? (0, utils_1.defaultPriceFormat)({
                        currency: schemas_1.ECurrencyV1.CAD,
                        isBillable: true,
                        type: schemas_1.EPriceTypeV1.CURRENCY,
                        value: amountField,
                    }, language)
                    : '';
                return (react_1.default.createElement(ux_1.DataGridColumnModified, { hasBeenModified: false, modifiedValue: `${priceDisplay}`, originalValue: `${priceDisplay}` }));
            },
            type: 'dateTime',
        },
        {
            align: 'center',
            field: 'id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:actions`),
            renderCell: ({ value }) => {
                return (react_1.default.createElement(ux_1.DataGridActionIconButton, { count: 0, isActive: true, onClick: () => {
                        onDownloadFile(value);
                    } },
                    react_1.default.createElement(ux_1.TruxwebDownloadIcon, { style: { fill: 'inherit' } })));
            },
            sortable: false,
        },
    ];
    //Filter the Column definition to display only the columns that are needed
    const displayCols = columns.filter((col) => {
        if (!columnKeys && !excludeColumnKeys)
            return true;
        const shouldInclude = !columnKeys ? true : columnKeys.includes(col.field);
        const shouldExclude = !excludeColumnKeys ? false : excludeColumnKeys.includes(col.field);
        return shouldInclude && !shouldExclude;
    });
    let toolbar;
    if (isExportEnabled) {
        toolbar = () => {
            return (react_1.default.createElement(ux_1.GridToolbarContainer, null,
                react_1.default.createElement(ux_1.GridToolbarExport, null)));
        };
    }
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, data: data || [], defaultSort: defaultSort, gridHeight: ((pageSize || 10) + 1.35) * 115, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, isActionsOverlayEnabled: isActionsOverlayEnabled, isLoading: isLoading, pageSize: pageSize || 10, renderActionsContent: renderActionsContent, rowHeight: 115, selectionModel: selectedRows, shouldDisableMultipleSelection: true, t: t, toolbar: toolbar }));
};
exports.CompanyDocuments = CompanyDocuments;
