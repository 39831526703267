import { TUseActionResponse, useAction } from './useAction';
import { getCarrierLaneData } from '../actions';
import { TCarrierLaneV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierLaneData = (
  shouldRefetch?: boolean
): TUseActionResponse<TCarrierLaneV1[]> => {
  const action = useCallback(() => {
    return getCarrierLaneData();
  }, []);

  return useAction<TCarrierLaneV1[]>(action, true, shouldRefetch);
};
