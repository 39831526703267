"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    checkCircle: {
        fill: '#4EAC2D',
    },
    container: {
        '& div': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
        },
        '& img': {
            marginRight: '10px',
        },
    },
    description: {
        color: theme.palette.primary.dark,
    },
    descriptionName: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        lineHeight: '1rem',
        textAlign: 'left',
        width: '138px',
    },
    descriptionRow: {
        display: 'flex',
        padding: theme.spacing(0.5),
        width: '100%',
    },
    descriptionValue: {
        color: theme.palette.primary.light,
        fontSize: '12px',
        lineHeight: '1rem',
        textAlign: 'right',
        width: theme.spacing(12),
    },
    descriptionWrapper: {
        borderTop: '1px solid rgba(0, 135, 206, 0.2)',
        paddingTop: theme.spacing(2),
    },
    descriptionv2: {
        color: theme.palette.grey[600],
        fontSize: '0.75rem',
        lineHeight: '1rem',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
    },
    detailsHeader: {
        minHeight: theme.spacing(2),
    },
    imageWrap: {
        height: theme.spacing(3),
        marginRight: theme.spacing(2),
        maxHeight: theme.spacing(3),
        maxWidth: theme.spacing(2.5),
        minHeight: theme.spacing(3),
        minWidth: theme.spacing(2.5),
        width: theme.spacing(2.5),
    },
}));
exports.useAccordionStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        '& .MuiAccordionDetails-root': {
            display: 'block',
            padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
        },
        '& .MuiAccordionSummary-content': {
            display: 'block',
            flexGrow: 'inherit',
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '0',
        },
        '& .MuiIconButton-root': {
            color: theme.palette.primary.light,
            padding: '0',
        },
        backgroundColor: 'transparent',
        border: 'none',
    },
}));
