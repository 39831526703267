import { type AppState, baseLoadingState } from '../../stores';
import {
  TAccessorialV1,
  type TCompanyLocationV1,
  TEquipmentV1,
  TLoadParametersDataRow,
  type TLoadParametersV1,
} from '@truxweb/schemas';
import { type TFunction } from 'next-i18next';
import { type TReducerLoading } from '../../types';

export const selectCompanyLoadParameters = (
  t: TFunction
): ((state: AppState) => TReducerLoading & { data: TLoadParametersDataRow[] }) => {
  return (state: AppState): TReducerLoading & { data: TLoadParametersDataRow[] } => {
    if (!state.loadParameters.loadParametersList) return { ...baseLoadingState };
    if (!state.loadParameters.loadParametersList.hasLoaded) {
      return { ...state.loadParameters.loadParametersList, data: [] };
    }
    if (
      !state.companyLocations.companyLocationList.hasLoaded ||
      !state.search.searchFormParams.hasLoaded
    ) {
      return { ...state.loadParameters.loadParametersList, data: [], isLoading: true };
    }

    const source: TLoadParametersV1[] = Object.values(state.loadParameters.loadParameters).map(
      ({ data }) => data
    );
    const companyLocationLookup: Record<number, TCompanyLocationV1> = {};
    Object.values(state.companyLocations.companyLocations).forEach(({ data }) => {
      companyLocationLookup[data.id] = data;
    });

    const equipmentData = state.search.searchFormParams.data;

    const equipmentLookup = {};
    const accessorialLookup = {};
    Object.values(equipmentData.equipment).forEach((record: TEquipmentV1) => {
      equipmentLookup[record.id] = record;
    });
    Object.values(equipmentData.accessorials).forEach((record: TAccessorialV1) => {
      accessorialLookup[record.id] = record;
    });

    const data: TLoadParametersDataRow[] = source
      .map((record) => {
        const equipmentCodes = [
          t(`common:SHIPPER-equipment-${equipmentLookup[record.loadDefinition.equipmentId].code}`),
        ];
        let accessorialCodes = ['N/A'];
        const accessorialSelections = [];
        if (record.loadDefinition.accessorialIds.length) {
          accessorialCodes = [];
          record.loadDefinition.accessorialIds.forEach((id: number) => {
            accessorialCodes.push(t(`common:SHIPPER-accessorial-${accessorialLookup[id].code}`));
            accessorialSelections.push(accessorialLookup[id]);
          });
        }

        const accessorialMetadataSelections = [];
        if (record.loadDefinition.accessorialMetadata?.length) {
          record.loadDefinition.accessorialMetadata.forEach((selection: Record<string, any>) => {
            accessorialMetadataSelections.push({
              ...selection,
              code: accessorialLookup[selection.accessorialId].code,
            });
          });
        }
        return {
          _source: {
            ...record,
            loadDefinition: {
              ...record.loadDefinition,
              accessorialMetadataSelections,
              accessorialSelections,
            },
          },
          accessorialCodes,
          dateCreated: record.dateCreated,
          destination: companyLocationLookup[record.loadDefinition.destinationId],
          equipmentCodes,
          id: record.id,
          name: record.loadDefinition.name,
          origin: companyLocationLookup[record.loadDefinition.originId],
          shipmentType: record.loadDefinition.truckloadType,
          tags: record.metadata?.tags?.SHIPPER || [],
        };
      })
      .filter(({ destination, origin }) => {
        return Boolean(origin && destination);
      });

    return { ...state.loadParameters.loadParametersList, data };
  };
};
