import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  companyName: {
    color: '#636363',
    fontSize: 15,
    fontWeight: 800,
  },
  info: {
    color: '#636363',
  },
  phone: {
    color: '#636363',
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
}));
