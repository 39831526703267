"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSkatingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9.707,16.06a1,1,0,0,1,0,1.414L5.685,21.5l.2.2a1,1,0,0,0,1.415.014,1,1,0,0,1,1.4,1.429A3.007,3.007,0,0,1,4.457,23.1l-2.8-2.857a1,1,0,1,1,1.427-1.4l1.2,1.225L8.293,16.06A1,1,0,0,1,9.707,16.06ZM20,21a3,3,0,0,1-3,3H13a1,1,0,0,1,0-2h2V18.274l-4.94-4.693A3.35,3.35,0,0,1,10,8.784L12.781,6H5A1,1,0,0,1,5,4h8.144A5.007,5.007,0,0,1,16.35,5.163l.671.56a3,3,0,0,1,.1,4.337h0l-3.831,3.83,3.4,3.229a1,1,0,0,1,.311.725V22a1,1,0,0,0,1-1,1,1,0,0,1,2,0Zm-8.983-9.831a1.337,1.337,0,0,0,.42.961l.4.383,3.865-3.865v0a1,1,0,0,0-.016-1.432L15.068,6.7c-.029-.024-.063-.041-.092-.064L11.411,10.2A1.345,1.345,0,0,0,11.017,11.169ZM19.5-.045a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,19.5-.045Z" })));
exports.default = TruxwebSkatingIcon;
