import { makeRequestToApiGateway } from '../../apiUtils';
import { type TCompanyToValidate } from '../../types';

export const validateCompanyDataV2 = async (company: TCompanyToValidate): Promise<boolean> => {
  const results = await makeRequestToApiGateway(
    'userPostV2CompanyValidate',
    null,
    {
      'Content-Type': 'application/json',
    },
    company
  );

  return results as boolean;
};
