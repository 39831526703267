// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetBoundaryTypeV1 = void 0;
var RateSheetBoundaryTypeV1;
(function (RateSheetBoundaryTypeV1) {
    RateSheetBoundaryTypeV1["CITY"] = "CITY";
    RateSheetBoundaryTypeV1["REGION"] = "REGION";
    RateSheetBoundaryTypeV1["GENERIC"] = "GENERIC";
})(RateSheetBoundaryTypeV1 || (exports.RateSheetBoundaryTypeV1 = RateSheetBoundaryTypeV1 = {}));
