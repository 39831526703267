import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.spacing(21),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(25),
    },
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.8),
    },
  },
  value: {
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(5),
    },
  },
}));
