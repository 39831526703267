import { Box, Grid, TextField, Typography } from '@truxweb/ux';
import { helperTextStyles, useStyles } from './LoginForm.styles';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useAlerts, useAuth, useQuoteCounts } from '../../hooks';
import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import { EMAIL_REGEX } from '@truxweb/utils';
import { getAuth } from '../../actions';
import { PageLink } from '..';
import { SaveButton } from '@truxweb/common-components';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const REQUIRED_NAMESPACES = ['common', 'signup'];

type TLoginFormProps = {
  onForgotPassword: () => void;
  onResetPassword: (user: any) => void;
  successRedirect?: string;
};
export const LoginForm = ({
  onForgotPassword,
  onResetPassword,
  successRedirect,
}: TLoginFormProps): JSX.Element => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const { addAlert } = useAlerts();
  const { handleRefetchQuotes } = useQuoteCounts();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [isCombinationException, setIsCombinationException] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldBlockLogin, setShouldBlockLogin] = useState(false);
  const { isLoading: isAuthLoading } = useAuth();

  const classes = useStyles();
  const helperTestClasses = helperTextStyles();

  const handleError = useCallback(
    (error) => {
      if (error.name === 'NotAuthorizedException' && error.message === 'User is disabled.') {
        addAlert({ message: t('common:accountDisabled'), severity: 'error' });
        setShouldBlockLogin(true);
      } else if (['NotAuthorizedException', 'UserNotFoundException'].includes(error.name)) {
        setIsCombinationException(true);
      } else {
        addAlert({ message: t('common:pleaseContactAdmin'), severity: 'error' });
      }
    },
    [addAlert, setIsCombinationException, t, setShouldBlockLogin]
  );
  const handleSignIn = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        setIsCombinationException(false);
        const user = await Auth.signIn(data.email, data.password);
        const { challengeName } = user;
        if (challengeName && challengeName === 'NEW_PASSWORD_REQUIRED') {
          onResetPassword(user);
          setIsLoading(false);
        } else {
          dispatch(getAuth(user.attributes, false, successRedirect));
          handleRefetchQuotes();
        }
      } catch (err) {
        handleError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      onResetPassword,
      setIsCombinationException,
      handleError,
      dispatch,
      successRedirect,
      handleRefetchQuotes,
    ]
  );

  return (
    <form onSubmit={handleSubmit(handleSignIn)} style={{ width: '100%' }}>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography className={classes.primaryFont} variant="h3">
          {t('common:loginWelcome')}
        </Typography>

        <Grid container direction="column" item justifyContent="space-between">
          <Box pb={2} pt={2}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              className={classes.fitContainer}
              error={Boolean(errors?.email) || false}
              helperText={[errors.email && t('common:invalidEmailAddress')]}
              label={t('common:email')}
              type="email"
              variant="outlined"
              {...register('email', {
                pattern: {
                  message: 'invalid email address',
                  value: EMAIL_REGEX,
                },
                required: true,
              })}
            />
          </Box>
          <Box pb={2} pt={2}>
            <TextField
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              className={classes.fitContainer}
              error={Boolean(errors?.password) || false}
              helperText={
                errors.password && errors.password.type === 'required'
                  ? t('common:invalidPassword')
                  : isCombinationException === true && t('common:passwordCombination')
              }
              label={t('common:password')}
              type="password"
              variant="outlined"
              {...register('password', {
                required: true,
              })}
            />
          </Box>
          <Grid alignItems="center" container direction="row" justifyContent="flex-end">
            <Grid item>
              <Box className={classes.clickable} onClick={onForgotPassword}>
                <Typography className={classnames(classes.linkFont, classes.primaryFont)}>
                  {t('common:forgotPassword')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Typography style={{ fontSize: '12px' }} variant={'body1'}>
          <Trans i18nKey="common:termsAndConditionsPrompt">
            <PageLink
              className={classnames(classes.linkFont, classes.primaryFont)}
              pageId={'terms-and-conditions'}
              target={'_blank'}
            >
              {t('common:termsAndConditionsPrompt')}
            </PageLink>
            <PageLink
              className={classnames(classes.linkFont, classes.primaryFont)}
              pageId={'privacy-policy'}
              target={'_blank'}
            >
              {t('common:termsAndConditionsPrompt')}
            </PageLink>
          </Trans>
        </Typography>
        <SaveButton
          className={classes.submitButton}
          isDisabled={shouldBlockLogin}
          isFullWidth
          isSaving={isLoading || isAuthLoading}
          name="submitLoginForm"
          t={t}
        >
          {t('common:logIn')}
        </SaveButton>

        <Typography variant="body1">
          <Trans i18nKey="common:registerPrompt">
            <PageLink
              className={classnames(classes.linkFont, classes.primaryFont)}
              pageId={'signup'}
            >
              <Typography>{t('common:registerPrompt')}</Typography>
            </PageLink>
          </Trans>
        </Typography>
      </Grid>
    </form>
  );
};
