import { localizedRoutes } from '../../config';
import { TPageId } from '../../types/pageIdType';

export const getPageIdFromRoute = (route: string, locale: string): TPageId => {
  if (route.charAt(0) === '/') {
    route = route.substring(1);
  }
  const routes = localizedRoutes[locale];

  const matched = Object.keys(routes).find((key) => {
    const pageProps = routes[key];
    return pageProps.url === route;
  });

  return matched as TPageId;
};
