import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import classnames from 'classnames';
import { useStyles } from './SignupCarousel.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);
type TTile = {
  image: JSX.Element;
  title: string;
};
type TSignupCarouselProps = {
  isAnimated?: boolean;
  onSkip?: () => void;
  slideCollection: TTile[];
};

export const SignupCarousel = ({
  isAnimated,
  onSkip,
  slideCollection,
}: TSignupCarouselProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation([...REQUIRED_NAMESPACES]);
  const [swiper, setSwiper] = useState<null | any>(null);
  const isVerticalTablet = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)
  );
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.largeMobile));
  const isLargestScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box className={classnames(classes.fullHeight, classes.swiperContainer)}>
      <Grid className={classes.fullHeight}>
        <Swiper
          autoplay={isAnimated && { delay: 3000 }}
          className={classes.swiper}
          onSwiper={(swiperInstance) => {
            setSwiper(swiperInstance);
          }}
          pagination={true}
          slidesPerView={1}
        >
          {slideCollection.map((tile, index) => {
            return (
              <SwiperSlide className={classes.slideWrap} key={`logos-${index}`}>
                <Grid
                  alignItems="center"
                  className={classes.slideContentContainer}
                  container
                  direction={isVerticalTablet ? 'row' : 'column'}
                  wrap="nowrap"
                  xl
                >
                  <Grid className={classes.titleItem} item>
                    <Typography className={classes.titleFont} variant="bodyMedium">
                      {tile.title}
                    </Typography>
                  </Grid>
                  <Grid className={classes.imageItem} item xl={!isLargestScreen}>
                    <Box className={classes.imageContainer}>{tile.image}</Box>
                  </Grid>
                  {/*this grid item acts as a placeholder for spacing the pagination bullets*/}
                  {!isVerticalTablet && <Grid item />}
                </Grid>
              </SwiperSlide>
            );
          })}
          {isMobile && (
            <Grid className={classes.mobileButtonsWrapper} direction="row">
              <Grid item>
                <button className={classes.swiperButton} onClick={onSkip}>
                  <Typography className={classes.whiteFont} variant="bodyMedium">
                    {t('common:skip')}
                  </Typography>
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes.swiperButton}
                  onClick={() => {
                    swiper.slideNext();
                  }}
                >
                  <Typography
                    className={classes.whiteFont}
                    fontStyle="semibold"
                    variant="bodyMedium"
                  >
                    {t('common:next')}
                  </Typography>
                </button>
              </Grid>
            </Grid>
          )}
        </Swiper>
      </Grid>
    </Box>
  );
};
