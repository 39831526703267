"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBowlingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16,16a1,1,0,1,1-1-1A1,1,0,0,1,16,16Zm3-1a1,1,0,1,0,1,1A1,1,0,0,0,19,15Zm-2-3a1,1,0,1,0,1,1A1,1,0,0,0,17,12Zm7,4a7.99,7.99,0,0,1-13.194,6.073A4.005,4.005,0,0,1,7.39,24H4.61A4,4,0,0,1,.986,21.7,11.08,11.08,0,0,1,0,17a11.781,11.781,0,0,1,1.736-5.463A8.441,8.441,0,0,0,3,8a11.618,11.618,0,0,0-.554-2.175A7.168,7.168,0,0,1,2,4a4,4,0,0,1,8,0,7.168,7.168,0,0,1-.446,1.825A11.618,11.618,0,0,0,9,8a6.7,6.7,0,0,0,.907,2.839A7.987,7.987,0,0,1,24,16ZM4,4c.016.15.211.772.354,1.226A10.708,10.708,0,0,1,5,8H7a10.708,10.708,0,0,1,.646-2.774A12,12,0,0,0,8,3.973,2,2,0,0,0,4,4ZM9.2,20.856A9.084,9.084,0,0,0,10,17a10.1,10.1,0,0,0-1.512-4.542A18.393,18.393,0,0,1,7.368,10H4.632a18.393,18.393,0,0,1-1.12,2.458A10.1,10.1,0,0,0,2,17a9.092,9.092,0,0,0,.8,3.857A2,2,0,0,0,4.61,22H7.39A2,2,0,0,0,9.2,20.856ZM22,16a5.991,5.991,0,0,0-11.084-3.163A9.656,9.656,0,0,1,12,17a11.654,11.654,0,0,1-.408,3.059A5.993,5.993,0,0,0,22,16Z" })));
exports.default = TruxwebBowlingIcon;
