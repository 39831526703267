// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElasticSearchGeoShapeV1 = void 0;
var ElasticSearchGeoShapeV1;
(function (ElasticSearchGeoShapeV1) {
    ElasticSearchGeoShapeV1["circle"] = "circle";
    ElasticSearchGeoShapeV1["polygon"] = "polygon";
    ElasticSearchGeoShapeV1["point"] = "point";
})(ElasticSearchGeoShapeV1 || (exports.ElasticSearchGeoShapeV1 = ElasticSearchGeoShapeV1 = {}));
