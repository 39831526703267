import { Box, Grid, Typography, UnreadCount } from '@truxweb/ux';
import { PageLink } from '..';
import React from 'react';
import { TPageId } from '../../types';
import { useQuoteCounts } from '../../hooks';
import { useTranslation } from 'next-i18next';

type TQuoteRequestShipperMenuProps = {
  linkClassName: string;
  selectedLinkClassName: string;
  pageId: TPageId;
};

enum QuoteRequestPages {
  REQUEST_AWAIT = 'requests',
  REQUEST_CONFIRMED = 'requests/confirmed',
  REQUEST_REFUSED = 'requests/refused',
}

const REQUIRED_NAMESPACES = ['common', 'shipments'];

export const QuoteRequestShipperMenu = ({
  linkClassName,
  pageId,
  selectedLinkClassName,
}: TQuoteRequestShipperMenuProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const { quoteCounts } = useQuoteCounts();

  return (
    <Grid container direction="row">
      <Grid item>
        <PageLink pageId={QuoteRequestPages.REQUEST_AWAIT}>
          <Grid alignItems="center" container>
            <Grid item>
              <Grid alignItems="center" container>
                <Grid item>
                  <Typography
                    className={
                      pageId === QuoteRequestPages.REQUEST_AWAIT
                        ? selectedLinkClassName
                        : linkClassName
                    }
                    fontStyle="semibold"
                    variant="bodyLarge"
                  >
                    {t('common:activeRequests')}
                  </Typography>
                </Grid>
                {quoteCounts?.responseRequired !== 0 && (
                  <Grid item>
                    <Box ml={1}>
                      <UnreadCount content={quoteCounts?.responseRequired || 0} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </PageLink>
      </Grid>
      <Grid item>
        <PageLink pageId={QuoteRequestPages.REQUEST_CONFIRMED}>
          <Typography
            className={
              pageId === QuoteRequestPages.REQUEST_CONFIRMED ? selectedLinkClassName : linkClassName
            }
            fontStyle="semibold"
            variant="bodyLarge"
          >
            {t('common:confirmed')}
          </Typography>
        </PageLink>
      </Grid>
      <Grid item>
        <PageLink pageId={QuoteRequestPages.REQUEST_REFUSED}>
          <Grid alignItems="center" container>
            <Grid item>
              <Typography
                className={
                  pageId === QuoteRequestPages.REQUEST_REFUSED
                    ? selectedLinkClassName
                    : linkClassName
                }
                fontStyle="semibold"
                variant="bodyLarge"
              >
                {t('common:refused')}
              </Typography>
            </Grid>
            <Grid item>
              <Box ml={1}>
                <UnreadCount content={quoteCounts?.refused || 0} />
              </Box>
            </Grid>
          </Grid>
        </PageLink>
      </Grid>
    </Grid>
  );
};
