"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSmokeIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16,24a5.017,5.017,0,0,1-2.336-.578,6.271,6.271,0,0,1-5.536-.152.586.586,0,0,0-.571.027A5.028,5.028,0,0,1,.134,17.829a4.947,4.947,0,0,1,3.677-3.69,5.07,5.07,0,0,1,2.375,0,.276.276,0,0,0,.282-.074,6.034,6.034,0,0,1,8.8-.288c.065.067.217.22.734.22a4.984,4.984,0,0,1,3.9,1.874.4.4,0,0,0,.193.149,3.62,3.62,0,0,1,1.437.128,3.445,3.445,0,0,1,2.349,2.435,3.5,3.5,0,0,1-4.343,4.279c-.275-.077-.331-.03-.356-.01A4.929,4.929,0,0,1,16,24Zm-2.386-2.591a2.1,2.1,0,0,1,.988.246,3.045,3.045,0,0,0,3.291-.328,2.274,2.274,0,0,1,2.193-.386,1.5,1.5,0,0,0,1.86-1.856h0a1.457,1.457,0,0,0-.979-1.015,1.647,1.647,0,0,0-.643-.059,2.231,2.231,0,0,1-1.982-.885c-1.041-1.6-3.364-.661-4.5-1.939a4.023,4.023,0,0,0-5.865.194,2.272,2.272,0,0,1-2.265.705,3.139,3.139,0,0,0-1.449,0,3,3,0,0,0-1.613,4.781,3.069,3.069,0,0,0,3.882.712,2.582,2.582,0,0,1,2.554-.064,4.055,4.055,0,0,0,3.639.094A2.061,2.061,0,0,1,13.614,21.409ZM22.875,15a1.033,1.033,0,0,1-.251-.031,1,1,0,0,1-.718-1.219,3.073,3.073,0,0,0,.039-1.338,3.021,3.021,0,0,0-1.919-2.224,2.885,2.885,0,0,0-1.808-.077,2.041,2.041,0,0,1-2.153-.657,3.99,3.99,0,0,0-6.522.567,2.034,2.034,0,0,1-2.176,1.016,1.843,1.843,0,0,0-.887.037,2.066,2.066,0,0,0-1.368,1.267,1,1,0,0,1-1.881-.682A4.05,4.05,0,0,1,5.938,9.148a3.864,3.864,0,0,1,1.828-.07,6.012,6.012,0,0,1,9.842-.895,5.007,5.007,0,0,1,6.234,6.067A1,1,0,0,1,22.875,15ZM1.337,10a1,1,0,0,1-.9-.57A4.491,4.491,0,0,1,3.066,3.241,4.346,4.346,0,0,1,5.54,3.128c-.01,0,.026-.03.052-.083a5.51,5.51,0,0,1,9.35-.767,4.572,4.572,0,0,1,5.131,1.5c.187.239.294.251.4.264.031,0,.132.019.163.025a3.007,3.007,0,0,1,1.974,1.45,1,1,0,0,1-1.738.99,1,1,0,0,0-.657-.485A2.431,2.431,0,0,1,18.5,5.011a2.543,2.543,0,0,0-2.884-.848,1.92,1.92,0,0,1-2.263-.672,3.5,3.5,0,0,0-5.969.447A2.056,2.056,0,0,1,5.058,5.069a2.35,2.35,0,0,0-1.351.067A2.489,2.489,0,0,0,2.24,8.57a1,1,0,0,1-.9,1.43Z" })));
exports.default = TruxwebSmokeIcon;
