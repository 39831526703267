import { EPermissionV1 } from '@truxweb/schemas';
import { selectAuthedUserPermissionsEvaluation } from '../selectors';
import { useAuth } from '.';
import { useSelector } from 'react-redux';

export const usePermissions = (permissions: EPermissionV1[]): boolean[] => {
  const auth = useAuth();
  const { hasRequiredPermissions, havePermissionsEvaluated } = useSelector(
    selectAuthedUserPermissionsEvaluation(permissions)
  );
  if (!auth.hasLoaded) return [false, true];
  if (!auth.data) return [false, true];

  return [hasRequiredPermissions, havePermissionsEvaluated];
};
