import {
  AccountCarrierCompany as BaseAccountCarrierCompany,
  CarrierProfileDescription,
  Loading,
} from '@truxweb/common-components';
import { Box, Grid, Rating, Typography } from '@truxweb/ux';
import { CARRIER_ONBOARDING_BUCKET, CARRIER_PROFILE_BUCKET } from '../../config';
import {
  createCarrierProfile,
  updateBasicCompanyInfo,
  updateCarrier,
  updateCarrierProfile,
} from '../../actions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useActionController,
  useAlerts,
  useCarrierData,
  useCarrierProfileData,
  useCompanyData,
  useUserData,
} from '../../hooks';
import Image from 'next/image';
import { PageLink } from '..';
import { STAR_VALUE } from '@truxweb/utils';
import { TS3Response } from '../../types';
import { uploadFileToBucket } from '../../apiUtils';
import { useStyles } from './AccountCarrierCompany.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const AccountCarrierCompany = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { addAlert } = useAlerts();
  const classes = useStyles();

  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldCompanyDataRefetch, setShouldCompanyDataRefetch] = useState(false);
  const [carrierData, hasCarrierDataLoaded] = useCarrierData(shouldCompanyDataRefetch);
  const [companyData, hasCompanyDataLoaded] = useCompanyData(shouldCompanyDataRefetch);
  const [shouldCarrierProfileRefetch, setShouldCarrierProfileRefetch] = useState(false);
  const [carrierProfileData, hasCarrierProfileDataLoaded] = useCarrierProfileData(
    shouldCarrierProfileRefetch
  );
  const [hasFormDataLoaded, setFormDataLoaded] = useState(false);
  const iconEN = (
    <Image height={13} src={'/assets/images/icons/flag_UK.svg'} unoptimized width={16} />
  );
  const iconFR = (
    <Image height={13} src={'/assets/images/icons/flag_FR.svg'} unoptimized width={16} />
  );

  const isRegistrationComplete = useMemo(() => {
    if (!carrierData) return true;

    return Boolean(
      carrierData.insuranceCertificateUrl && carrierData.currency && carrierData.unitOfDistance
    );
  }, [carrierData]);

  const onProfileSubmission = useCallback(
    async ({ isUpdate, payload }) => {
      setShouldCarrierProfileRefetch(true);
      const action = isUpdate ? updateCarrierProfile : createCarrierProfile;
      await action(payload);
    },
    [setShouldCarrierProfileRefetch]
  );

  const onUpdateCarrierData = useCallback(
    async (data: Record<string, any>) => {
      /* eslint-disable camelcase  */
      const {
        companyName,
        companyPhone,
        companyPhone_ext,
        companySize,
        companyWebsite,
        financeEmail,
        insuranceCertificateName,
        insuranceCertificateUrl,
        language,
        ...others
      } = data;

      // STEP -- PATCH Generic Company Info
      await updateBasicCompanyInfo({
        ...companyData,
        companyName,
        companyPhone,
        companyPhone_ext,
        companySize,
        companyWebsite,
        financeEmail,
        language,
      });
      /* eslint-enablecamelcase  */

      // STEP -- PATCH THE CARRIER DATE HERE...
      await updateCarrier({
        ...carrierData,
        insuranceCertificateName,
        insuranceCertificateUrl,
        ...others,
      });

      setShouldCompanyDataRefetch(true);
    },
    [carrierData, companyData]
  );

  const handleUploadSuccess = useCallback(() => {
    addAlert({ message: t('common:uploadSuccess'), severity: 'success' });
  }, [t, addAlert]);

  const handleUploadFailure = useCallback(
    (message?: string) => {
      addAlert({ message: message || t('common:uploadFailed'), severity: 'error' });
    },
    [t, addAlert]
  );

  const handleProfileUpload = useCallback(
    async (postFileName: string, file: File): Promise<TS3Response> => {
      return await uploadFileToBucket(postFileName, file, CARRIER_PROFILE_BUCKET);
    },
    []
  );

  const handleCompanyDataUpload = useCallback(async (postFileName: string, file: File) => {
    return await uploadFileToBucket(
      postFileName,
      file,
      CARRIER_ONBOARDING_BUCKET,
      'application/pdf'
    );
  }, []);

  const { action: handleUpdateCarrierData } = useActionController({
    action: onUpdateCarrierData,
    successMessage: t('common:companyInfoUpdateSuccess'),
  });

  const { action: handleProfileSubmission } = useActionController({
    action: onProfileSubmission,
    successMessage: t('common:companyInfoUpdateSuccess'),
  });
  const renderProfileLink = useCallback(
    (content: JSX.Element): JSX.Element => {
      if (!carrierProfileData?.length) return <></>;
      return (
        <Grid alignItems="center" container>
          <PageLink pageId={'account/profile'}>{content}</PageLink>
        </Grid>
      );
    },
    [carrierProfileData]
  );

  const hasPendingProfileUpdate = useMemo(() => {
    return carrierProfileData?.length > 2;
  }, [carrierProfileData]);

  const incompleteProfileNotice = isRegistrationComplete ? null : (
    <Box className={classes.incomplete} mb={6} p={3}>
      <Typography fontStyle="semibold">{t('common:carrierAccountStatusDisclaimer')}</Typography>
      <ul style={{ marginBottom: 0 }}>
        {!carrierData.insuranceCertificateUrl && (
          <li>
            <Typography>{t('common:carrierAccountInsuranceRequired')}</Typography>
          </li>
        )}

        {!carrierData.uniqueIdentifier && (
          <li>
            <Typography>{t('common:carrierAccountUniqueIdentifier')}</Typography>
          </li>
        )}
      </ul>
    </Box>
  );

  useEffect(() => {
    if (shouldCompanyDataRefetch) {
      setShouldCompanyDataRefetch(false);
    }
  }, [shouldCompanyDataRefetch, setShouldCompanyDataRefetch]);

  useEffect(() => {
    if (shouldCarrierProfileRefetch) {
      setShouldCarrierProfileRefetch(false);
    }
  }, [shouldCarrierProfileRefetch, setShouldCarrierProfileRefetch]);

  useEffect(() => {
    if (
      hasCarrierDataLoaded &&
      userData &&
      hasCompanyDataLoaded &&
      companyData &&
      hasCarrierProfileDataLoaded
    ) {
      setFormDataLoaded(true);
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    hasCarrierProfileDataLoaded,
    hasCarrierDataLoaded,
    userData,
    setFormDataLoaded,
    hasCompanyDataLoaded,
    companyData,
  ]);

  return (
    <Box ml={1}>
      <Loading isLoading={!hasFormDataLoaded || isLoading} t={t} />
      {hasFormDataLoaded && (
        <>
          {incompleteProfileNotice}
          <Box mb={3}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
              {t(`common:companyInformation`)}
            </Typography>
          </Box>

          <Grid container>
            <Grid item xs>
              <BaseAccountCarrierCompany
                canEditReferralFee={false}
                carrier={carrierData}
                company={companyData}
                fieldStates={{
                  companyName: {
                    isDisabled: true,
                    isRequired: true,
                  },
                  currency: {
                    isDisabled: Boolean(carrierData.currency),
                    isRequired: true,
                  },
                  paymentTerms: {
                    isDisabled: true,
                    isRequired: false,
                  },
                  unitOfDistance: {
                    isDisabled: !(!isRegistrationComplete && !carrierData.unitOfDistance),
                    isRequired: true,
                  },
                }}
                handleUpload={handleCompanyDataUpload}
                handleUploadFailure={handleUploadFailure}
                handleUploadSuccess={handleUploadSuccess}
                t={t}
                updateCarrierCompanyData={handleUpdateCarrierData}
                user={userData}
              />
            </Grid>

            <Grid item xs={4}>
              <Box mb={3}>
                <Typography fontStyle="semibold" variant="bodyMedium">
                  {t('common:truxwebRating')}
                </Typography>
                <Box mt={2}>
                  <Rating precision={0.5} readOnly value={carrierData.rating / STAR_VALUE} />
                </Box>
              </Box>
              {hasPendingProfileUpdate && (
                <Typography color="secondary" style={{ fontStyle: 'italic' }}>
                  {t('common:profilePendingApproval')}
                </Typography>
              )}
              <CarrierProfileDescription
                carrierId={carrierData?.id || 0}
                carrierProfileData={carrierProfileData}
                handleProfileSubmission={handleProfileSubmission}
                handleUpload={handleProfileUpload}
                handleUploadFailure={handleUploadFailure}
                handleUploadSuccess={handleUploadSuccess}
                iconEN={iconEN}
                iconFR={iconFR}
                renderPreviewLink={renderProfileLink}
                t={t}
                user={userData}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
