"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebReflectIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,13H1a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2ZM18.934,7.126A2.427,2.427,0,0,0,17.65,4.395L9.668.428A3.3,3.3,0,0,0,6.4.433,2.809,2.809,0,0,0,5,2.914V6A3,3,0,0,0,8,9h8.318A2.538,2.538,0,0,0,18.934,7.126ZM8.737,2.2,16.749,6.18a.421.421,0,0,1,.24.477c-.074.308-.494.341-.671.341H8A1,1,0,0,1,7,6V2.914a.825.825,0,0,1,.418-.758A1.168,1.168,0,0,1,8.011,2,1.5,1.5,0,0,1,8.737,2.2Zm.891,21.394L17.661,19.6a2.425,2.425,0,0,0,1.273-2.726A2.538,2.538,0,0,0,16.318,15H8a3,3,0,0,0-3,3v3.085a2.808,2.808,0,0,0,1.4,2.481A3.212,3.212,0,0,0,8.032,24,3.287,3.287,0,0,0,9.628,23.592ZM16.318,17c.177,0,.6.033.671.341a.417.417,0,0,1-.229.472L8.7,21.822a1.313,1.313,0,0,1-1.279.02A.824.824,0,0,1,7,21.084V18a1,1,0,0,1,1-1Z" })));
exports.default = TruxwebReflectIcon;
