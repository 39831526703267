// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoyonDeliveryTypeV1 = exports.LtlPackagingV1 = void 0;
/**
 * How the good is packaged for shipping
 */
var LtlPackagingV1;
(function (LtlPackagingV1) {
    LtlPackagingV1["STANDARD_PALLET"] = "STANDARD_PALLET";
    LtlPackagingV1["NON_STANDARD_PALLET"] = "NON_STANDARD_PALLET";
    LtlPackagingV1["CRATE"] = "CRATE";
    LtlPackagingV1["LOOSE"] = "LOOSE";
})(LtlPackagingV1 || (exports.LtlPackagingV1 = LtlPackagingV1 = {}));
var DoyonDeliveryTypeV1;
(function (DoyonDeliveryTypeV1) {
    DoyonDeliveryTypeV1["RESIDENTIAL"] = "RESIDENTIAL";
    DoyonDeliveryTypeV1["COMMERCIAL"] = "COMMERCIAL";
})(DoyonDeliveryTypeV1 || (exports.DoyonDeliveryTypeV1 = DoyonDeliveryTypeV1 = {}));
