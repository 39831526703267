"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebEclipseAltIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,11H19.938a7.956,7.956,0,0,0-.575-2.129l2.655-1.546A1,1,0,0,0,21.012,5.6L18.354,7.145a8.1,8.1,0,0,0-1.507-1.5l1.541-2.648a1,1,0,0,0-1.729-1.006L15.12,4.633A7.971,7.971,0,0,0,13,4.062V1a1,1,0,0,0-2,0V4.062a7.965,7.965,0,0,0-2.108.566L7.355,1.986A1,1,0,1,0,5.627,2.992L7.163,5.633A8.046,8.046,0,0,0,5.651,7.139L3,5.6A1,1,0,0,0,2,7.325L4.64,8.865A7.955,7.955,0,0,0,4.062,11H1a1,1,0,0,0,0,2H4.062a7.957,7.957,0,0,0,.576,2.129L2,16.662A1,1,0,0,0,3.01,18.391l2.637-1.535a8.083,8.083,0,0,0,1.5,1.5L5.6,21A1,1,0,0,0,7.33,22.007l1.538-2.646A7.943,7.943,0,0,0,11,19.938V23a1,1,0,0,0,2,0V19.938a7.934,7.934,0,0,0,2.143-.582l1.543,2.651A1,1,0,0,0,18.414,21l-1.546-2.657a8.076,8.076,0,0,0,1.49-1.494l2.647,1.541a1,1,0,0,0,1.006-1.729l-2.646-1.54A7.941,7.941,0,0,0,19.938,13H23A1,1,0,0,0,23,11ZM12,18C4.356,17.906,3.792,6.32,12,6,19.929,6.252,19.928,17.749,12,18Zm1.455-2.229C7.889,17.5,5.64,9.3,11.361,8.051a4.393,4.393,0,0,1,1.683.068.544.544,0,0,1,.243.918,3.7,3.7,0,0,0,.319,5.793A.545.545,0,0,1,13.455,15.771Z" })));
exports.default = TruxwebEclipseAltIcon;
