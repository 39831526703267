import {
  type AppThunk,
  setLoadParametersData,
  setLoadParametersListError,
  setLoadParametersListLoaded,
  setLoadParametersListLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const fetchLoadParameters =
  (shouldOverwrite?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoadParametersListLoading());
      const results = await makeRequestToApiGateway('shipmentGetV1ShipperLoad', null, {
        'Content-Type': 'application/json',
      });

      dispatch(
        setLoadParametersData({ items: results, shouldOverwriteList: Boolean(shouldOverwrite) })
      );
      dispatch(setLoadParametersListLoaded({ isProgressiveLoading: false }));
    } catch (error) {
      dispatch(setLoadParametersListError({ error: error.message }));
    }
  };
