"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMapMarkerCrossIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M177.854,284.209l48.776-48.776l-48.776-48.776c-8.45-8.161-8.685-21.628-0.523-30.078  c8.161-8.45,21.628-8.685,30.078-0.523c0.177,0.171,0.352,0.346,0.523,0.523l48.776,48.776l48.776-48.776  c8.162-8.45,21.628-8.684,30.078-0.522c8.45,8.162,8.684,21.628,0.522,30.078c-0.171,0.177-0.345,0.351-0.522,0.522l-48.776,48.776  l48.776,48.776c8.45,8.162,8.684,21.628,0.522,30.078c-8.162,8.45-21.628,8.684-30.078,0.522c-0.177-0.171-0.351-0.345-0.522-0.522  l-48.776-48.776l-48.776,48.776c-8.45,8.162-21.916,7.928-30.078-0.522C169.892,305.522,169.892,292.453,177.854,284.209  L177.854,284.209z M91.853,401.458C-0.62,311.19-2.408,163.048,87.861,70.574s238.411-94.261,330.884-3.992  c1.149,1.122,2.287,2.256,3.413,3.401c91.373,91.377,91.373,239.524,0,330.901l-84.916,78.471  c-45.059,43.64-116.675,43.461-161.515-0.404L91.853,401.458z M65.264,235.434c-0.139,50.802,20.052,99.548,56.072,135.372  l83.704,77.343c28.737,27.463,73.939,27.641,102.891,0.404l84.746-78.322c74.605-74.929,74.344-196.15-0.585-270.755  s-196.15-74.344-270.755,0.585C85.315,135.885,65.125,184.632,65.264,235.434z" })));
exports.default = TruxwebMapMarkerCrossIcon;
