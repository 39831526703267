import { type AppState, baseLoadingState } from '../../stores';
import { EPermissionV1 } from '@truxweb/schemas';

type TUserPermissionsEvaluation = {
  havePermissionsEvaluated: boolean;
  hasRequiredPermissions: boolean;
};

export const selectAuthedUserPermissionsEvaluation = (
  permissions: EPermissionV1[]
): ((state: AppState) => TUserPermissionsEvaluation) => {
  return (state: AppState) => {
    const userPermissions = state?.auth?.userPermissions || baseLoadingState;

    if (!userPermissions.hasLoaded || !userPermissions.data)
      return { hasRequiredPermissions: false, havePermissionsEvaluated: false };

    const hasRequiredPermissions = permissions.map((permission) => {
      return userPermissions.data[permission] || false;
    });

    return {
      hasRequiredPermissions: !hasRequiredPermissions.includes(false),
      havePermissionsEvaluated: true,
    };
  };
};
