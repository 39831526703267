// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchParamsV1 = void 0;
var SearchParamsV1;
(function (SearchParamsV1) {
    SearchParamsV1["accessorials"] = "accessorials";
    SearchParamsV1["destination"] = "destination";
    SearchParamsV1["equipment"] = "equipment";
    SearchParamsV1["origin"] = "origin";
    SearchParamsV1["shipmentDate"] = "shipmentDate";
})(SearchParamsV1 || (exports.SearchParamsV1 = SearchParamsV1 = {}));
