// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentQuoteRequestStatusV1 = exports.CurrencyV1 = exports.PriceTypeV1 = exports.ShipmentChargeTypeV1 = exports.ShipmentLocationTypeV1 = exports.CountryV1 = exports.TruckloadTypeV1 = exports.CarrierServiceTypeV1 = void 0;
var CarrierServiceTypeV1;
(function (CarrierServiceTypeV1) {
    CarrierServiceTypeV1["lane"] = "lane";
})(CarrierServiceTypeV1 || (exports.CarrierServiceTypeV1 = CarrierServiceTypeV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var ShipmentLocationTypeV1;
(function (ShipmentLocationTypeV1) {
    ShipmentLocationTypeV1["ORIGIN"] = "ORIGIN";
    ShipmentLocationTypeV1["DESTINATION"] = "DESTINATION";
})(ShipmentLocationTypeV1 || (exports.ShipmentLocationTypeV1 = ShipmentLocationTypeV1 = {}));
var ShipmentChargeTypeV1;
(function (ShipmentChargeTypeV1) {
    ShipmentChargeTypeV1["GST"] = "GST";
    ShipmentChargeTypeV1["PST"] = "PST";
    ShipmentChargeTypeV1["QST"] = "QST";
    ShipmentChargeTypeV1["HST"] = "HST";
    ShipmentChargeTypeV1["VAT"] = "VAT";
    ShipmentChargeTypeV1["RST"] = "RST";
    ShipmentChargeTypeV1["TRUXWEB"] = "TRUXWEB";
    ShipmentChargeTypeV1["ADDITIONAL"] = "ADDITIONAL";
    ShipmentChargeTypeV1["CARRIER"] = "CARRIER";
    ShipmentChargeTypeV1["EQUIPMENT"] = "EQUIPMENT";
    ShipmentChargeTypeV1["UNKNOWN"] = "UNKNOWN";
    ShipmentChargeTypeV1["ACCESSORIAL"] = "ACCESSORIAL";
    ShipmentChargeTypeV1["TRUXWEB_CARRIER"] = "TRUXWEB_CARRIER";
    ShipmentChargeTypeV1["TRUXWEB_SHIPPER"] = "TRUXWEB_SHIPPER";
    ShipmentChargeTypeV1["CREDIT_CARD_SURCHARGE"] = "CREDIT_CARD_SURCHARGE";
    ShipmentChargeTypeV1["FUEL_SURCHARGE"] = "FUEL_SURCHARGE";
    ShipmentChargeTypeV1["TRUXWEB_FEE"] = "TRUXWEB_FEE";
    ShipmentChargeTypeV1["SUBSCRIPTION"] = "SUBSCRIPTION";
})(ShipmentChargeTypeV1 || (exports.ShipmentChargeTypeV1 = ShipmentChargeTypeV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var ShipmentQuoteRequestStatusV1;
(function (ShipmentQuoteRequestStatusV1) {
    ShipmentQuoteRequestStatusV1["PENDING"] = "PENDING";
    ShipmentQuoteRequestStatusV1["ACCEPTED"] = "ACCEPTED";
    ShipmentQuoteRequestStatusV1["MODIFIED"] = "MODIFIED";
    ShipmentQuoteRequestStatusV1["REFUSED"] = "REFUSED";
    ShipmentQuoteRequestStatusV1["EXPIRED"] = "EXPIRED";
    ShipmentQuoteRequestStatusV1["CANCELLED"] = "CANCELLED";
})(ShipmentQuoteRequestStatusV1 || (exports.ShipmentQuoteRequestStatusV1 = ShipmentQuoteRequestStatusV1 = {}));
