"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        margin: `-${theme.spacing(0.5)}px 0 0 0`,
        // FIXME: I am horrible at CSS... i'm so so so sorry TRB 07/21/2021
        padding: `0 !important`,
    },
}));
