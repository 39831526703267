"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierAccessorialsManagementForm = void 0;
const __1 = require("..");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const ux_1 = require("@truxweb/ux");
const CarrierAccessorialsManagementForm = ({ accessorialData, arePricesRequired, carrier, handleCancel, handleSubmitSuccess, isFormDisabled, isLoading, isSaving, t, }) => {
    const [hasPendingUpdates, setHasPendingUpdates] = (0, react_1.useState)(false);
    const { control, formState: { isDirty }, handleSubmit, register, reset, setValue, } = (0, react_hook_form_1.useForm)({
        defaultValues: { accessorial: accessorialData || [] },
    });
    const { fields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'accessorial',
    });
    (0, react_1.useEffect)(() => {
        setValue('accessorial', accessorialData);
        const hasPendingUpdates = accessorialData
            .map((field) => {
            return field.pendingUpdate;
        })
            .includes(true);
        setHasPendingUpdates(hasPendingUpdates);
    }, [accessorialData, setHasPendingUpdates, setValue]);
    return (react_1.default.createElement("form", { onSubmit: handleSubmit(handleSubmitSuccess) },
        react_1.default.createElement(__1.CarrierManagementWrapper, { additionalActions: react_1.default.createElement(react_1.default.Fragment, null, handleCancel !== undefined && (react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:cancel')))), areButtonsDisabled: !isDirty, hasPendingUpdates: hasPendingUpdates, isCancelDeactivated: true, isLoading: isLoading, isSaving: isSaving, onCancel: () => {
                reset({
                    accessorial: accessorialData,
                });
            }, pendingUpdateMessage: t('common:pendingAccessorialUpdates'), t: t },
            react_1.default.createElement(__1.CarrierAccessorialsManagement, { accessorialData: accessorialData || [], areEnhancedDescriptionsShown: true, arePricesRequired: arePricesRequired, control: control, fields: fields, isCarrierActive: carrier === null || carrier === void 0 ? void 0 : carrier.active, isFormDisabled: isFormDisabled, register: register, setValue: setValue, t: t }))));
};
exports.CarrierAccessorialsManagementForm = CarrierAccessorialsManagementForm;
