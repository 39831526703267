import { Box, Grid, Typography } from '@truxweb/ux';
import React, { useCallback } from 'react';
import { updateBasicCompanyInfo, updateCognitoCompanyAttributes } from '../../actions';
import { useActionController, useCompanyData, useUserData } from '../../hooks';
import { AccountShipperCompany as BaseAccountShipperCompany } from '@truxweb/common-components';
import { Loading } from '..';
import { type TUpdateUserCompany } from '../../types';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'account'];

export const AccountShipperCompany = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { userData } = useUserData();
  const [companyData, hasCompanyDataLoaded, isCompanyDataLoading] = useCompanyData();

  const onUpdateShipperCompanyData = useCallback(
    async (data: TUpdateUserCompany) => {
      await updateBasicCompanyInfo({ ...companyData, ...data });
      await updateCognitoCompanyAttributes(data);
    },
    [companyData]
  );

  const { action: handleUpdateShipperCompanyData } = useActionController({
    action: onUpdateShipperCompanyData,
    successMessage: t('common:companyInfoUpdateSuccess'),
  });

  return (
    <Box ml={1}>
      <Loading isLoading={!hasCompanyDataLoaded || isCompanyDataLoading || !userData} />
      <Grid container direction="column">
        <Grid item xs>
          <Box mb={3}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
              {t(`common:companyInformation`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          {hasCompanyDataLoaded && userData && (
            <BaseAccountShipperCompany
              company={companyData}
              fieldStates={{
                companyName: {
                  isDisabled: false,
                  isRequired: true,
                },
              }}
              t={t}
              updateShipperCompanyData={handleUpdateShipperCompanyData}
              user={userData}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
