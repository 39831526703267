import { TCognitoUserData, TUpdateUserCompany } from '../../types';
import { Auth } from 'aws-amplify';

export const updateCognitoCompanyAttributes = async (
  companyData: TUpdateUserCompany
): Promise<void> => {
  const userAttributes: Partial<TCognitoUserData> = {
    'custom:companyName': companyData.companyName,
    'custom:companyPhone': companyData.companyPhone,
    'custom:companyPhoneExt': companyData.companyPhone_ext,
  };
  const user = await Auth.currentAuthenticatedUser();

  await Auth.updateUserAttributes(user, userAttributes);
};
