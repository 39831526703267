// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquipmentCodeV1 = void 0;
var EquipmentCodeV1;
(function (EquipmentCodeV1) {
    EquipmentCodeV1["DRY_FTL"] = "53_DRY_FTL";
    EquipmentCodeV1["REEFER_FTL"] = "53_REEFER_FTL";
    EquipmentCodeV1["FLATBED_DUAL_AXLE"] = "FLATBED_DUAL_AXLE";
    EquipmentCodeV1["TWENTY_FOOT_CONTAINER"] = "20_CONTAINER";
    EquipmentCodeV1["FORTY_FOOT_CONTAINER"] = "40_CONTAINER";
    EquipmentCodeV1["FLATBED_TRIPLE_AXLE"] = "FLATBED_TRIPLE_AXLE";
    EquipmentCodeV1["FLATBED_QUAD_AXLE"] = "FLATBED_QUAD_AXLE";
    EquipmentCodeV1["STEP_DECK"] = "STEP_DECK";
    EquipmentCodeV1["DOUBLE_DROP_DECK"] = "DOUBLE_DROP_DECK";
    EquipmentCodeV1["DRY_VAN_TRIPLE_AXLE"] = "DRY_VAN_TRIPLE_AXLE";
    EquipmentCodeV1["FLATBED_RGN"] = "FLATBED_RGN";
    EquipmentCodeV1["FLATBED_B_TRAIN"] = "FLATBED_B_TRAIN";
    EquipmentCodeV1["FLATBED_TRIPLE_AXLE_DROP_DECK"] = "FLATBED_TRIPLE_AXLE_DROP_DECK";
    EquipmentCodeV1["DRY_LTL"] = "DRY_LTL";
    EquipmentCodeV1["REEFER_LTL"] = "REEFER_LTL";
})(EquipmentCodeV1 || (exports.EquipmentCodeV1 = EquipmentCodeV1 = {}));
