"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSkateboardIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m21.835 2.165a7.389 7.389 0 0 0 -10.451 0l-9.219 9.219a7.39 7.39 0 1 0 10.451 10.451l9.219-9.219a7.389 7.389 0 0 0 0-10.451zm-1.414 9.035-9.221 9.221a5.39 5.39 0 1 1 -7.621-7.621l9.221-9.221a5.39 5.39 0 1 1 7.621 7.621zm-.714-2.909a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1 -1.414-1.414l.793-.793-2.586-2.584-.793.793a1 1 0 0 1 -1.414-1.414l3-3a1 1 0 1 1 1.414 1.414l-.793.793 2.586 2.586.793-.793a1 1 0 0 1 1.414 0zm-7 7a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1 -1.414-1.414l.793-.793-2.586-2.584-.793.793a1 1 0 0 1 -1.414-1.414l3-3a1 1 0 0 1 1.414 1.414l-.793.793 2.586 2.586.793-.793a1 1 0 0 1 1.414 0z" })));
exports.default = TruxwebSkateboardIcon;
