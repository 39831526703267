import {
  AccountCircleOutlinedIcon,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Popper,
} from '@truxweb/ux';
import { AccountHeaderLanguageSwitch, AccountHeaderLogout, PageLink } from '..';
import React, { useCallback, useRef, useState } from 'react';
import { useAuthBoolean } from '../../hooks';
import { useStyles } from './AccountHeaderItem.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TAccountHeaderItem = {
  linkClass: string;
};
export const AccountHeaderItem = ({ linkClass }: TAccountHeaderItem): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const anchorRef = useRef(null);
  const classes = useStyles();
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);
  const handleAccountMenuClose = useCallback(() => {
    setAccountMenuOpen(false);
  }, [setAccountMenuOpen]);

  const handleAccountMenuToggle = useCallback(() => {
    setAccountMenuOpen(!isAccountMenuOpen);
  }, [isAccountMenuOpen, setAccountMenuOpen]);
  const isAuthed = useAuthBoolean();

  return (
    <>
      {isAuthed && (
        <IconButton
          classes={{ label: linkClass }}
          data-testid={`AccountHeaderItem-AccountButton`}
          onClick={handleAccountMenuToggle}
          ref={anchorRef}
        >
          <AccountCircleOutlinedIcon fontSize="large" />
        </IconButton>
      )}
      <Popper
        anchorEl={anchorRef.current}
        className={classes.popper}
        disablePortal
        open={isAccountMenuOpen}
      >
        <ClickAwayListener onClickAway={handleAccountMenuClose}>
          <Paper>
            <List className={classes.list} disablePadding>
              <ListSubheader className={classes.subhead}>{t('common:myProfile')}</ListSubheader>
              <ListItem className={classes.item}>
                <PageLink data-testid={`AccountHeaderItem-YourAccount`} pageId={'account'}>
                  {t('common:yourAccount')}
                </PageLink>
              </ListItem>
              <ListItem className={classes.item}>
                <PageLink data-testid={`AccountHeaderItem-Company`} pageId={'account/company'}>
                  {t('common:company')}
                </PageLink>
              </ListItem>
              <ListItem className={classes.item}>
                <PageLink
                  data-testid={`AccountHeaderItem-PaymentDetails`}
                  pageId={'account/payment'}
                >
                  {t('common:paymentDetails')}
                </PageLink>
              </ListItem>
              <ListItem className={classes.item}>
                <PageLink data-testid={`AccountHeaderItem-Addresses`} pageId={'account/address'}>
                  {t('common:addresses')}
                </PageLink>
              </ListItem>
              <Divider />
              <ListItem className={classes.logout} disableGutters>
                <AccountHeaderLanguageSwitch />
              </ListItem>
              <ListItem className={classes.logout} disableGutters>
                <AccountHeaderLogout />
              </ListItem>
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
