// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeV1 = exports.StatusGenericV1 = exports.DayOfWeekV1 = exports.CountryV1 = void 0;
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var StatusGenericV1;
(function (StatusGenericV1) {
    StatusGenericV1["ACTIVE"] = "ACTIVE";
    StatusGenericV1["INACTIVE"] = "INACTIVE";
    StatusGenericV1["ARCHIVED"] = "ARCHIVED";
    StatusGenericV1["PENDING"] = "PENDING";
    StatusGenericV1["REVISE"] = "REVISE";
    StatusGenericV1["REGISTERED"] = "REGISTERED";
    StatusGenericV1["BANNED"] = "BANNED";
})(StatusGenericV1 || (exports.StatusGenericV1 = StatusGenericV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
