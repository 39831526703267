"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    activeTab: {
        '& a > svg': {
            color: 'inherit',
            fill: 'inherit',
        },
        backgroundColor: '#E8F4FB',
        borderBottom: `2px solid #f8f8f8`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        borderRight: 0,
        borderTop: `2px solid #f8f8f8`,
        color: theme.palette.primary.main,
        fontSize: 40,
        height: 106,
        width: '100%',
    },
    tab: {
        '& a > svg': {
            color: 'inherit',
            fill: 'inherit',
        },
        '&:hover': {
            background: '#F8F8F8',
        },
        '&:hover  a > svg': {
            color: 'inherit',
            fill: 'inherit',
        },
        background: 'transparent',
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fill: theme.palette.primary.main,
        fontSize: 40,
        height: 106,
        width: '100%',
    },
}));
