import { AddressDisplay } from '@truxweb/common-components';
import React from 'react';
import { useCompanyLocationById } from '../../hooks';
import { useStyles } from './CompanyLocationCardWrapper.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TCompanyLocationCardWrapperProps = {
  companyLocationId: number;
};

export const CompanyLocationCardWrapper = ({
  companyLocationId,
}: TCompanyLocationCardWrapperProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const companyLocation = useCompanyLocationById(companyLocationId);
  if (!companyLocation.data) return null;
  return (
    <AddressDisplay
      className={classes.card}
      isLoading={false}
      location={companyLocation.data}
      onEditAddress={(id: number) => {
        window.open(`/account/address?selectedLocationId=${id}`, `companyLocation`);
      }}
      t={t}
    />
  );
};
