// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CspUserRoleV1 = void 0;
var CspUserRoleV1;
(function (CspUserRoleV1) {
    CspUserRoleV1["ADMIN"] = "ADMIN";
    CspUserRoleV1["DEVELOPMENT"] = "DEVELOPMENT";
    CspUserRoleV1["FINANCE"] = "FINANCE";
    CspUserRoleV1["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
})(CspUserRoleV1 || (exports.CspUserRoleV1 = CspUserRoleV1 = {}));
