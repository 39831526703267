import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { Controller, useForm } from 'react-hook-form';
import {
  EHubspotCaptureSourceV1,
  ELanguageV1,
  EPermissionV1,
  THubspotContactV1,
} from '@truxweb/schemas';
import { FooterCompanyInfo, FooterLegal, FooterSocials, SiteMap } from '..';
import React, { useCallback, useState } from 'react';
import { useAuthBoolean, usePermissions } from '../../hooks';
import { CONTACT_EMAIL_ADDRESS } from '../../config';
import Image from 'next/image';
import { submitNewsletterSignup } from '../../actions';
import { useRouter } from 'next/router';
import { useStyles } from './Footer.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TFooterProps = {
  footerRef?: React.Ref<any>;
};

// FIXME: Some of the complexity can be abstracted out
// eslint-disable-next-line complexity
export const Footer = ({ footerRef }: TFooterProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const isAuthed = useAuthBoolean();

  const [shipperPermission, haveShipperPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_SHIPPER_PAGE,
  ]);

  const [carrierPermission, haveCarrierPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_CARRIER_PAGE,
  ]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { locale } = useRouter();

  const [isSignupSuccessful, setSignupSucces] = useState(false);
  const { control, handleSubmit } = useForm();

  const onSubmitSuccess = useCallback(
    async (data: Record<string, any>) => {
      try {
        data.source = EHubspotCaptureSourceV1.footer_newsletter;
        data.language = locale === 'en' ? ELanguageV1.EN_CA : ELanguageV1.FR_CA;
        await submitNewsletterSignup(data as THubspotContactV1);
        setSignupSucces(true);
      } catch (err) {
        setSignupSucces(false);
      }
    },
    [setSignupSucces, locale]
  );

  const newsletterContent = (
    <Box className={classes.newsletterSignup}>
      {isSignupSuccessful && (
        /* Thank You! / Merci */
        <Typography className={classes.newsletterCta}>{t('common:signupSuccess')}</Typography>
      )}
      {!isSignupSuccessful && (
        <form onSubmit={handleSubmit(onSubmitSuccess)}>
          <Grid container direction="row">
            <Grid className={classes.emailContainer} item>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => {
                  return (
                    <input
                      className={classes.emailInput}
                      id="email"
                      onChange={onChange}
                      placeholder={t('common:email')}
                      type="email"
                      value={value || ''}
                    />
                  );
                }}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item>
              <Button
                className={classes.submitButton}
                color="primary"
                style={{
                  borderRadius: '0 36px 36px 0',
                  height: 52,
                }}
                type="submit"
                variant="contained"
              >
                {t('common:submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );

  const basicFooter = (
    <>
      {/* SHIPPER */}
      <Grid item>
        <Typography className={classes.siteMapHeader} variant="h6">
          {t('common:shippersFooter')}
        </Typography>
        <SiteMap linkClass={classes.siteMapLink} pages={['signup/shipper', 'login']} />
      </Grid>

      {/* CARRIER */}
      <Grid item>
        <Typography className={classes.siteMapHeader} variant="h6">
          {t('common:carriersFooter')}
        </Typography>
        <SiteMap linkClass={classes.siteMapLink} pages={['signup/carrier', 'login']} />
      </Grid>

      {/* ABOUT US */}
      <Grid item>
        <Typography className={classes.siteMapHeader} variant="h6">
          {t('common:aboutUsFooter')}
        </Typography>
        <SiteMap linkClass={classes.siteMapLink} pages={['contact-us', 'about', 'investors']} />
      </Grid>
    </>
  );

  let siteMapContent = isAuthed ? null : basicFooter;

  if (isAuthed && carrierPermission && haveCarrierPermissionsEvaluated) {
    siteMapContent = (
      <>
        {/* SHIPMENTS */}
        <Grid item>
          <Typography className={classes.siteMapHeader} variant="h6">
            {t('common:shipmentsFooter')}
          </Typography>
          <SiteMap linkClass={classes.siteMapLink} pages={['shipments']} />
        </Grid>

        {/* EQUIPMENT */}
        <Grid item>
          <Typography className={classes.siteMapHeader} variant="h6">
            {t('common:equipmentFooter')}
          </Typography>
          <SiteMap
            linkClass={classes.siteMapLink}
            pages={['fleet/equipment', 'fleet/lanes', 'fleet/accessorials']}
          />
        </Grid>

        {/* ACCOUNT */}
        <Grid item>
          <Typography className={classes.siteMapHeader} variant="h6">
            {t('common:accountFooter')}
          </Typography>
          <SiteMap
            linkClass={classes.siteMapLink}
            pages={[
              'account',
              'account/company',
              'account/payment',
              'account/address',
              'account/notifications',
            ]}
          />
        </Grid>
      </>
    );
  } else if (isAuthed && shipperPermission && haveShipperPermissionsEvaluated) {
    siteMapContent = (
      <>
        {/* SEARC  */}
        <Grid item>
          <Typography className={classes.siteMapHeader} variant="h6">
            {t('common:searchFooter')}
          </Typography>
          <SiteMap
            linkClass={classes.siteMapLink}
            // FIXME: results should only be present when there is a saved search
            // available
            pages={['search', 'results']}
          />
        </Grid>

        {/* SHIPMENTS */}
        <Grid item>
          <Typography className={classes.siteMapHeader} variant="h6">
            {t('common:shipmentsFooter')}
          </Typography>
          <SiteMap linkClass={classes.siteMapLink} pages={['shipments']} />
        </Grid>

        {/* ACCOUNT */}
        <Grid item>
          <Typography className={classes.siteMapHeader} variant="h6">
            {t('common:aboutUsFooter')}
          </Typography>
          <SiteMap
            linkClass={classes.siteMapLink}
            pages={[
              'account',
              'account/company',
              'account/payment',
              'account/address',
              'account/notifications',
            ]}
          />
        </Grid>
      </>
    );
  } else if (isAuthed && haveCarrierPermissionsEvaluated && haveShipperPermissionsEvaluated) {
    // IF a user is authenticated, but is neither a shipper or carrier
    // show them the logged out footer
    siteMapContent = basicFooter;
  }

  return (
    <footer className={classes.footer} data-testid="Footer" ref={footerRef}>
      <Grid container direction="column">
        <Grid item>
          {/* Site Map */}
          <Grid
            alignItems="flex-start"
            className={classes.inner}
            container
            data-testid="DesktopFooter"
            direction={isMobile ? 'column' : 'row'}
            justifyContent="space-around"
          >
            {/* Company Details */}
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Box mb={8}>
                    <Image
                      alt={t('common:appTitle')}
                      data-testid="Footer-Logo"
                      height={44}
                      objectFit="scale-down"
                      src={`/assets/images/logos/Truxweb_logo_Noir.svg`}
                      unoptimized
                      width={240}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={3}>
                    <FooterCompanyInfo />
                  </Box>
                </Grid>

                <Grid item>
                  <a className={classes.infoLink} href={`mailto:${CONTACT_EMAIL_ADDRESS}`}>
                    {CONTACT_EMAIL_ADDRESS}
                  </a>
                </Grid>
                <Grid item>
                  <Typography className={classes.infoLink}>{t(`common:tollFreeNumber`)}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {siteMapContent}

            {/* NEWSLETTER AND SOCIALS */}
            <Grid item>
              <Grid container direction="column">
                {!isAuthed && (
                  <Grid item>
                    <Typography className={classes.siteMapHeader} variant="h6">
                      {t('common:newsletter')}
                    </Typography>
                    {newsletterContent}
                  </Grid>
                )}
                {isAuthed && (
                  <Grid item>
                    <Box mb={11}>
                      <Typography className={classes.siteMapHeader} variant="h6">
                        {t('common:helpFooter')}
                      </Typography>
                      <SiteMap linkClass={classes.siteMapLink} pages={['support']} />
                    </Box>
                  </Grid>
                )}
                <Grid item>
                  <Grid container direction="row">
                    <Grid item>
                      <Box mr={2}>
                        <Typography className={classes.siteMapHeader} variant="h6">
                          {t('common:socials')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <FooterSocials />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.legalFooter} item>
            {/* LEGAL */}
            <FooterLegal legalClass={classes.legalHeaderClass} linkClass={classes.legalLinkClass} />
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};
