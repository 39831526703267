"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const MastercardIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 53 32", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "#F3F3F3", height: 32, rx: 6.5, width: 52, x: 0.107422 }),
    React.createElement("path", { d: "M29.6221 21.7386H22.5898V9.20508H29.6221V21.7386Z", fill: "#FF5F00" }),
    React.createElement("path", { d: "M23.0414 15.4703C23.0414 12.9279 24.2417 10.6631 26.111 9.20357C24.744 8.1363 23.0189 7.49928 21.144 7.49928C16.7054 7.49928 13.1074 11.068 13.1074 15.4703C13.1074 19.8727 16.7054 23.4414 21.144 23.4414C23.0189 23.4414 24.744 22.8044 26.111 21.7371C24.2417 20.2776 23.0414 18.0128 23.0414 15.4703Z", fill: "#EB001B" }),
    React.createElement("path", { d: "M39.1076 15.4703C39.1076 19.8727 35.5096 23.4414 31.071 23.4414C29.1961 23.4414 27.471 22.8044 26.1035 21.7371C27.9733 20.2776 29.1736 18.0128 29.1736 15.4703C29.1736 12.9279 27.9733 10.6631 26.1035 9.20357C27.471 8.1363 29.1961 7.49928 31.071 7.49928C35.5096 7.49928 39.1076 11.068 39.1076 15.4703Z", fill: "#F79E1B" })));
exports.default = MastercardIcon;
