// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentChargeStatusV1 = void 0;
var ShipmentChargeStatusV1;
(function (ShipmentChargeStatusV1) {
    ShipmentChargeStatusV1["PENDING"] = "PENDING";
    ShipmentChargeStatusV1["PREAUTHED"] = "PREAUTHED";
    ShipmentChargeStatusV1["PAID"] = "PAID";
    ShipmentChargeStatusV1["REFUNDED"] = "REFUNDED";
})(ShipmentChargeStatusV1 || (exports.ShipmentChargeStatusV1 = ShipmentChargeStatusV1 = {}));
