import { EStatusGenericV1, EUserTypeV1 } from '@truxweb/schemas';
import { getRouteProperties } from '../../utils';
import Router from 'next/router';

export const loginSuccess = async (
  userType: EUserTypeV1,
  companyStatus: EStatusGenericV1,
  successRedirect?: string
): Promise<void> => {
  if (successRedirect) {
    Router.push(successRedirect);
  } else {
    const { locale } = Router;
    const { route: accountRoute } = getRouteProperties(locale, 'account/company');
    const { route: shipmentsRoute } = getRouteProperties(locale, 'shipments');
    const { route: searchRoute } = getRouteProperties(locale, 'search');
    // NOTE: Reports are currently disabled,
    // When reports not not disabled, shippers should be redirected to reports on login
    // const { route: reportRoute } = getRouteProperties(locale, 'report');

    switch (userType) {
      case EUserTypeV1.CARRIER:
        // Carriers are directed to different pages based upon
        // if they are registering or loggin in
        if (companyStatus === EStatusGenericV1.ACTIVE) {
          Router.push(shipmentsRoute);
        } else {
          Router.push(accountRoute);
        }

        break;
      case EUserTypeV1.SHIPPER:
        Router.push(searchRoute);
        break;
      default:
        // This line forces a redraw of the page to ensure that
        // the logged in state is picked up
        Router.push(Router.asPath);
    }
  }
};
