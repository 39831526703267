import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:hover': {
      border: '2px solid white',
    },
  },
  card: {
    maxWidth: '564px !important',
    minWidth: '388px !important',
    position: 'relative',
    zIndex: 2,
  },
  container: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    maxWidth: '100vw',
    position: 'relative',
    width: '100%',
    zIndex: -1,
  },
}));
