"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBarberShopIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M11.828,16.462a53.471,53.471,0,0,1-3.685-5.776C9.665,7.709,11,4.189,11,1A1,1,0,0,0,9,1,18.683,18.683,0,0,1,7.005,8.431,18.694,18.694,0,0,1,5,1,1,1,0,0,0,3,1c0,3.174,1.342,6.7,2.868,9.685a50.636,50.636,0,0,1-3.695,5.777A3.986,3.986,0,1,0,7,22.618a3.985,3.985,0,1,0,4.828-6.156ZM4,22a2,2,0,1,1,2-2A2,2,0,0,1,4,22Zm.883-5.895c.585-.844,1.337-1.991,2.122-3.342.784,1.35,1.534,2.5,2.117,3.341A3.984,3.984,0,0,0,7,17.382,3.989,3.989,0,0,0,4.883,16.105ZM10,22a2,2,0,1,1,2-2A2,2,0,0,1,10,22ZM18,6V8h5a1,1,0,0,1,0,2H18v2h5a1,1,0,0,1,0,2H18v2h5a1,1,0,0,1,0,2H18v5a1,1,0,0,1-2,0V5a5.006,5.006,0,0,1,5-5h2a1,1,0,0,1,0,2H21a3,3,0,0,0-2.816,2H23a1,1,0,0,1,0,2Z" })));
exports.default = TruxwebBarberShopIcon;
