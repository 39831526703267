"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTacoIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18,15a1,1,0,0,1-2,0A1,1,0,0,1,18,15Zm-3,2a1,1,0,0,0,0,2A1,1,0,0,0,15,17Zm3,1a1,1,0,0,0,2,0A1,1,0,0,0,18,18Zm4.988-7.732c1.257,2.14,1,5.337,1.012,7.732a5.006,5.006,0,0,1-5,5H3.5A3.5,3.5,0,0,1,0,19.5c.026-3.511-.523-8.3,2.063-11.123A1,1,0,0,1,3.626,9.624,7.486,7.486,0,0,0,2,14.274V19.5a1.5,1.5,0,0,0,3,0c.05-2.675-.358-6.812,1.044-9.169C4.647,7.907,4.177,3.9,8.257,3.063a3.315,3.315,0,0,1,3.8-1.947,3.23,3.23,0,0,1,4.918-.011,3.308,3.308,0,0,1,3.768,1.958C24.76,3.891,24.387,7.8,22.988,10.268ZM7.356,8.351a9.461,9.461,0,0,1,14.316-.08C22.353,6.658,21.983,5.073,20,5a1,1,0,0,1-.98-.8A1.464,1.464,0,0,0,17.688,3a1.739,1.739,0,0,0-.583.2,1,1,0,0,1-1.241-.317c-.981-1.261-1.815-1.153-2.7.025a1,1,0,0,1-1.242.317A2.031,2.031,0,0,0,11.337,3,1.493,1.493,0,0,0,9.98,4.2,1,1,0,0,1,9,5C6.968,5.073,6.652,6.729,7.356,8.351ZM22,14.5C21.623,4.461,7.23,4.612,7,14.791c-.088,1.112.267,5.25-.338,6.209H19a3,3,0,0,0,3-3Z" })));
exports.default = TruxwebTacoIcon;
