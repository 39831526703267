import { makeStyles, Theme } from '@truxweb/ux';

export const useLandingPageMarginStyles = makeStyles<Theme>((theme: Theme) => ({
  landingPageDemoContainerMargins: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(11),
      paddingRight: theme.spacing(11),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(30.5),
      paddingRight: theme.spacing(30.5),
    },
  },
  landingPageDemoTileMargins: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(7.5),
    },
  },
  landingPageLeftMargins: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(11),
    },
  },
  landingPageMargins: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(11),
      paddingRight: theme.spacing(11),
    },
  },
  landingPageRightMargins: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(9),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(11),
    },
  },
}));
