"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPicnicIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.283,9.034A3.008,3.008,0,0,0,20.93,8C19.5-2.6,4.492-2.591,3.07,8A3.018,3.018,0,0,0,.046,11.425l1.059,7.424A6.024,6.024,0,0,0,7.037,24h9.957a6.025,6.025,0,0,0,5.932-5.151l1.059-7.424A3,3,0,0,0,23.283,9.034ZM12,2a7,7,0,0,1,6.911,6H5.089A7,7,0,0,1,12,2Zm6.2,8L14.35,13.857a.5.5,0,0,1-.706,0L9.792,10Zm2.746,8.565A4.018,4.018,0,0,1,16.994,22H7.037a4.018,4.018,0,0,1-3.955-3.435L2.023,11.142A1,1,0,0,1,3.012,10H6.967l5.265,5.271a2.493,2.493,0,0,0,3.531,0L21.026,10a1,1,0,0,1,.982,1.14Z" })));
exports.default = TruxwebPicnicIcon;
