"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSausageIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.83,3.36C21.816,2.73,22.8.207,21,0a1,1,0,0,0-1,1,1,1,0,0,1-2,0,1,1,0,0,0-1-1c-1.8.207-.817,2.723.165,3.356A4.925,4.925,0,0,0,14,7.905c-.056,2.2-3.616,5.929-6.081,6.111a5.008,5.008,0,0,0-4.566,3.148C2.723,16.182.206,15.2,0,17a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1c.208,1.8,2.727.816,3.359-.169,1.955,4.906,9.267,3.965,15.51-1.946C24.83,12.63,25.724,5.333,20.83,3.36ZM17.457,17.469C13.155,21.761,8.82,22,8,22a3,3,0,1,1,.073-5.989c3.386-.181,7.908-4.877,7.928-8.055a3,3,0,0,1,6,.073C22,8.849,21.781,13.156,17.457,17.469Z" })));
exports.default = TruxwebSausageIcon;
