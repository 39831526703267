import { Box, Grid, Typography } from '@truxweb/ux';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useActionController, useErrorHandling } from '../../hooks';
import { Auth } from 'aws-amplify';
import { getRouteProperties } from '../../utils';
import { RegisterConfirmationCode } from '../../components';
import { useRouter } from 'next/router';
import { useStyles } from './SignUpConfirmation.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const SignUpConfirmation = (): JSX.Element => {
  const router = useRouter();
  const {
    locale,
    query: { confirm },
  } = router;
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const errorHandler = useErrorHandling();

  const verificationEmail = useMemo(() => {
    if (!confirm) return '';
    return decodeURIComponent(confirm as string);
  }, [confirm]);

  useEffect(() => {
    if (!verificationEmail) {
      const { route } = getRouteProperties(locale, `signup`);
      router.push(route);
    }
  });

  const handleError = useCallback(
    (err: Error) => {
      errorHandler(err);
    },
    [errorHandler]
  );

  const onConfirmationSuccess = useCallback(
    async (data: Record<string, any>) => {
      await Auth.confirmSignUp(verificationEmail, data.confirmationCode);
      const { route } = getRouteProperties(locale, `login`);
      router.push(route);
    },
    [verificationEmail, locale, router]
  );

  const { action: handleConfirmationSuccess, isLoading } = useActionController({
    action: onConfirmationSuccess,
  });

  const handleResendConfirmationCode = useCallback(async () => {
    try {
      await Auth.resendSignUp(verificationEmail);
    } catch (err) {
      handleError(err);
    }
  }, [handleError, verificationEmail]);

  return (
    <Box>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography color="primaryLight" fontStyle="semibold" variant="h3">
            {t('common:openAccount')}
          </Typography>
        </Grid>
        <Grid item>
          <Box mt={6}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
              {t('common:accountConfirmation')}
            </Typography>
          </Box>
        </Grid>
        <RegisterConfirmationCode
          confirmationSource={verificationEmail}
          isLoading={isLoading}
          onResendConfirmationCode={handleResendConfirmationCode}
          onSubmitSuccess={handleConfirmationSuccess}
        />
      </Grid>
    </Box>
  );
};
