import { TUseActionResponse, useAction } from './useAction';
import { getCarrierLaneById } from '../actions';
import { TCarrierLaneV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierLaneByLaneId = (
  laneId: number | null,
  shouldForceRefetch?: boolean
): TUseActionResponse<TCarrierLaneV1 | null> => {
  const action = useCallback(() => {
    if (!laneId) return null;
    return getCarrierLaneById(laneId);
  }, [laneId]);

  return useAction<TCarrierLaneV1 | null>(action, true, shouldForceRefetch);
};
