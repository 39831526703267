import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  icon: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    color: theme.palette.common.white,
  },
  info: {
    '&:after': {
      border: '20px solid transparent',
      borderBottom: 0,
      borderTopColor: theme.palette.secondary.main,
      bottom: 0,
      content: '""',
      height: 0,
      left: '15%',
      marginBottom: '-20px',
      marginLeft: '-20px',
      position: 'absolute',
      width: 0,
      [theme.breakpoints.down('sm')]: {
        left: '85%',
      },
    },
    background: theme.palette.secondary.main,
    marginLeft: `-${theme.spacing(3)}px`,
    padding: theme.spacing(2),
    position: 'absolute',
    top: `-${theme.spacing(2.75)}px`,
    width: theme.spacing(40),
    [theme.breakpoints.down('sm')]: {
      right: `${theme.spacing(15.5)}px`,
      top: `-${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down('xs')]: {
      right: -20,
    },
  },
}));
