// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvsV1 = exports.CreditCardTypeV1 = exports.StatusGenericV1 = exports.CreditCardTransactionTypeV1 = exports.PaymentTransactionStatusV1 = void 0;
var PaymentTransactionStatusV1;
(function (PaymentTransactionStatusV1) {
    PaymentTransactionStatusV1["Failed"] = "Failed";
    PaymentTransactionStatusV1["Success"] = "Success";
    PaymentTransactionStatusV1["Pending"] = "Pending";
    PaymentTransactionStatusV1["Hold"] = "Hold";
    PaymentTransactionStatusV1["Cancelled"] = "Cancelled";
})(PaymentTransactionStatusV1 || (exports.PaymentTransactionStatusV1 = PaymentTransactionStatusV1 = {}));
var CreditCardTransactionTypeV1;
(function (CreditCardTransactionTypeV1) {
    CreditCardTransactionTypeV1["Capture"] = "Capture";
    CreditCardTransactionTypeV1["PreAuth"] = "PreAuth";
    CreditCardTransactionTypeV1["Refund"] = "Refund";
    CreditCardTransactionTypeV1["Void"] = "Void";
})(CreditCardTransactionTypeV1 || (exports.CreditCardTransactionTypeV1 = CreditCardTransactionTypeV1 = {}));
var StatusGenericV1;
(function (StatusGenericV1) {
    StatusGenericV1["ACTIVE"] = "ACTIVE";
    StatusGenericV1["INACTIVE"] = "INACTIVE";
    StatusGenericV1["ARCHIVED"] = "ARCHIVED";
    StatusGenericV1["PENDING"] = "PENDING";
    StatusGenericV1["REVISE"] = "REVISE";
    StatusGenericV1["REGISTERED"] = "REGISTERED";
    StatusGenericV1["BANNED"] = "BANNED";
})(StatusGenericV1 || (exports.StatusGenericV1 = StatusGenericV1 = {}));
var CreditCardTypeV1;
(function (CreditCardTypeV1) {
    CreditCardTypeV1["AmericanExpress"] = "American Express";
    CreditCardTypeV1["Discover"] = "Discover";
    CreditCardTypeV1["Mastercard"] = "Mastercard";
    CreditCardTypeV1["Visa"] = "Visa";
    CreditCardTypeV1["Unknown"] = "Unknown";
})(CreditCardTypeV1 || (exports.CreditCardTypeV1 = CreditCardTypeV1 = {}));
var AvsV1;
(function (AvsV1) {
    AvsV1["Match"] = "Match";
    AvsV1["AddressOnly"] = "AddressOnly";
    AvsV1["ZipOnly"] = "ZipOnly";
    AvsV1["NoMatch"] = "NoMatch";
    AvsV1["NA"] = "NA";
})(AvsV1 || (exports.AvsV1 = AvsV1 = {}));
