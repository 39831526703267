"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        height: '100%',
        minWidth: '1000px',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(3),
        width: ({ isLeftTextMenuOpen }) => !isLeftTextMenuOpen ? `calc(100vw - 152px)` : `calc(100vw - 412px)`,
    },
}));
