import { useEffect, useMemo } from 'react';
import { AuthorizedPage } from '../../pageTemplates';
import { EShipmentStatusGroupV1 } from '@truxweb/schemas';
import { getRouteProperties } from '../../utils';
import { Loading } from '../../components';
import type { TPageId } from '../../types';
import { useCompanyData } from '../../hooks';
import { useRouter } from 'next/router';

type TShipmentRedirectPageProps = {
  pageId: TPageId;
};

export const ShipmentRedirectPage = ({ pageId }: TShipmentRedirectPageProps): JSX.Element => {
  const router = useRouter();
  const [companyData] = useCompanyData();
  const shipmentRef = router.query.shipmentRef ? router.query.shipmentRef.toLocaleString() : null;

  useEffect(() => {
    const { locale } = router;
    if (!companyData) return;
    const companyType = companyData?.type;

    let filterSet: null | EShipmentStatusGroupV1[] = null;

    switch (pageId) {
      case 'shipments/active':
        filterSet = [EShipmentStatusGroupV1.IN_PROGRESS];
        break;
      case 'shipments/booked':
        filterSet = [EShipmentStatusGroupV1.UPCOMING];
        break;
      case 'shipments/delivered':
        filterSet = [EShipmentStatusGroupV1.DELIVERED];
        break;
      case 'shipments/historical':
        filterSet = [EShipmentStatusGroupV1.COMPLETED];
        break;
      default:
        filterSet = null;
    }

    if (filterSet) {
      const filterKey = `TRUXWEB_${companyType}_SHIPMENT_TABLE_FILTERS`;
      localStorage.setItem(filterKey, JSON.stringify(filterSet));
    }

    const { route } = getRouteProperties(locale, `shipments`);
    if (shipmentRef) {
      router.push(`${route}?shipmentRef=${shipmentRef}`);
    } else {
      router.push(`${route}`);
    }
  }, [router, shipmentRef, pageId, companyData]);

  const authRedirect = useMemo(() => {
    const { locale } = router;
    const { route } = getRouteProperties(locale, pageId, [shipmentRef]);
    return route;
  }, [router, shipmentRef, pageId]);

  return (
    <AuthorizedPage
      pageId={pageId}
      redirectPageId={'login'}
      redirectPageQueryParams={`dest=${authRedirect}`}
    >
      <Loading isGlobalLoader isLoading />
    </AuthorizedPage>
  );
};
