// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeV1 = exports.ShipmentDocumentTypeV1 = void 0;
var ShipmentDocumentTypeV1;
(function (ShipmentDocumentTypeV1) {
    ShipmentDocumentTypeV1["COMMERCIAL_INVOICE"] = "COMMERCIAL_INVOICE";
    ShipmentDocumentTypeV1["MSDS"] = "MSDS";
    ShipmentDocumentTypeV1["PACKING_LIST"] = "PACKING_LIST";
    ShipmentDocumentTypeV1["PROOF_OF_DELIVERY"] = "PROOF_OF_DELIVERY";
    ShipmentDocumentTypeV1["BILL_OF_LADING"] = "BILL_OF_LADING";
    ShipmentDocumentTypeV1["PURCHASE_ORDER"] = "PURCHASE_ORDER";
    ShipmentDocumentTypeV1["REVISED_PURCHASE_ORDER"] = "REVISED_PURCHASE_ORDER";
    ShipmentDocumentTypeV1["INVOICE"] = "INVOICE";
    ShipmentDocumentTypeV1["RECEIPT"] = "RECEIPT";
    ShipmentDocumentTypeV1["CARRIER_INVOICE"] = "CARRIER_INVOICE";
})(ShipmentDocumentTypeV1 || (exports.ShipmentDocumentTypeV1 = ShipmentDocumentTypeV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
