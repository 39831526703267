"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geocodeByPlaceId = exports.geocodeByAddress = exports.reverseGeocode = exports.enableMapDrawingTools = exports.showRouteOnMap = exports.getCenterPointOfPolygon = exports.checkIfPoygonContainsPoint = exports.getDistanceDisplay = exports.squareMetersToSquareMiles = exports.squareMetersToSquareKilometers = exports.metersToKilometers = exports.metersToMiles = exports.validateLatLng = void 0;
const schemas_1 = require("@truxweb/schemas");
const utils_1 = require("@truxweb/utils");
const MILES_TO_METERS = 1609.34;
const METERS_TO_KILOMETERS = 1000;
const SQUARE_METERS_TO_SQUARE_KILOMETERS = 1000000;
const SQUARE_METERS_TO_SQUARE_MILES = 0.0000003861;
const validateLatLng = (coords) => {
    return coords.latitude && coords.longitude ? true : false;
};
exports.validateLatLng = validateLatLng;
const metersToMiles = (valueInMeters) => {
    return valueInMeters / MILES_TO_METERS;
};
exports.metersToMiles = metersToMiles;
const metersToKilometers = (valueInMeters) => {
    return valueInMeters / METERS_TO_KILOMETERS;
};
exports.metersToKilometers = metersToKilometers;
const squareMetersToSquareKilometers = (valueInMeters) => {
    return valueInMeters / SQUARE_METERS_TO_SQUARE_KILOMETERS;
};
exports.squareMetersToSquareKilometers = squareMetersToSquareKilometers;
const squareMetersToSquareMiles = (valueInMeters) => {
    return valueInMeters * SQUARE_METERS_TO_SQUARE_MILES;
};
exports.squareMetersToSquareMiles = squareMetersToSquareMiles;
//eslint-disable-next-line complexity
const getDistanceDisplay = (laneLocation, unitOfDistance) => {
    let areaProp;
    let areaCalc = null;
    switch (laneLocation === null || laneLocation === void 0 ? void 0 : laneLocation.zones[0].shape) {
        case 'CIRCLE':
            areaProp = 'radiusInMeters';
            areaCalc =
                unitOfDistance === schemas_1.ERangeUnitOfMeasureV1.mi
                    ? (0, exports.metersToMiles)(laneLocation.zones[0].radiusInMeters || 0)
                    : (0, exports.metersToKilometers)(laneLocation.zones[0].radiusInMeters || 0);
            break;
        case 'POLYGON':
            areaProp = 'areaInMetersSquared';
            areaCalc =
                unitOfDistance === schemas_1.ERangeUnitOfMeasureV1.mi
                    ? (0, exports.squareMetersToSquareMiles)(laneLocation.zones[0].areaInMetersSquared || 0)
                    : (0, exports.squareMetersToSquareKilometers)(laneLocation.zones[0].areaInMetersSquared || 0);
            break;
        default:
            areaProp = null;
    }
    if (!areaProp)
        return null;
    return Math.round(areaCalc || 0).toLocaleString();
};
exports.getDistanceDisplay = getDistanceDisplay;
const checkIfPoygonContainsPoint = (mapsApiReference, polygon, point) => {
    if (!point || point.latitude === null || !point.latitude === null)
        return false;
    const bounds = new mapsApiReference.LatLngBounds();
    polygon.overlay
        .getPath()
        .getArray()
        .map((pathArray) => {
        bounds.extend(new mapsApiReference.LatLng({
            lat: pathArray.lat(),
            lng: pathArray.lng(),
        }));
    });
    return bounds.contains({
        lat: point.latitude,
        lng: point.longitude,
    });
};
exports.checkIfPoygonContainsPoint = checkIfPoygonContainsPoint;
const getCenterPointOfPolygon = (mapsApiReference, points) => {
    const bounds = new mapsApiReference.LatLngBounds();
    points.forEach((coords) => {
        bounds.extend(new mapsApiReference.LatLng(coords.latitude, coords.longitude));
    });
    const centerPoint = bounds.getCenter();
    return {
        latitude: centerPoint.lat(),
        longitude: centerPoint.lng(),
    };
};
exports.getCenterPointOfPolygon = getCenterPointOfPolygon;
const showRouteOnMap = ({ destination, mapReference, mapsApiReference, origin, }) => {
    if (!origin || !destination)
        return null;
    // get center
    const bounds = new mapsApiReference.LatLngBounds();
    [origin, destination].forEach((coords) => {
        bounds.extend(new mapsApiReference.LatLng(coords === null || coords === void 0 ? void 0 : coords.latitude, coords === null || coords === void 0 ? void 0 : coords.longitude));
    });
    const line = new mapsApiReference.Polyline({
        geodesic: true,
        icons: [
            {
                icon: { path: mapsApiReference.SymbolPath.FORWARD_CLOSED_ARROW },
                offset: '50%',
            },
        ],
        path: [
            {
                lat: origin === null || origin === void 0 ? void 0 : origin.latitude,
                lng: origin === null || origin === void 0 ? void 0 : origin.longitude,
            },
            {
                lat: destination === null || destination === void 0 ? void 0 : destination.latitude,
                lng: destination === null || destination === void 0 ? void 0 : destination.longitude,
            },
        ],
        strokeColor: 'rgb(51, 159, 215)',
        strokeOpacity: 1.0,
        strokeWeight: 4,
    });
    line.setMap(mapReference);
    mapReference.setCenter(bounds.getCenter());
    mapReference.fitBounds(bounds);
    return line;
};
exports.showRouteOnMap = showRouteOnMap;
const enableMapDrawingTools = (mapReference, mapsApiReference, locationType, applicationTheme, isEnabledByDefault
//eslint-disable-next-line max-params
) => {
    const yellow = applicationTheme.palette.secondary.main;
    const blue = applicationTheme.palette.primary.light;
    const drawingManager = new mapsApiReference.drawing.DrawingManager({
        circleOptions: {
            clickable: true,
            draggable: false,
            editable: true,
            fillColor: locationType === 'origin' ? blue : yellow,
            fillOpacity: 0.6,
            strokeWeight: 0,
            zIndex: 1,
        },
        drawingControl: false,
        drawingControlOptions: {
            drawingModes: [
                mapsApiReference.drawing.OverlayType.MARKER,
                mapsApiReference.drawing.OverlayType.CIRCLE,
                mapsApiReference.drawing.OverlayType.POLYGON,
            ],
            position: mapsApiReference.ControlPosition.TOP_CENTER,
        },
        markerOptions: {
            draggable: false,
            editable: true,
            icon: '/assets/images/icons/pinBlue.png',
            zIndex: 1,
        },
        polygonOptions: {
            clickable: true,
            draggable: false,
            editable: true,
            fillColor: locationType === 'origin' ? blue : yellow,
            fillOpacity: 0.6,
            strokeWeight: 0,
            zIndex: 1,
        },
    });
    if (isEnabledByDefault) {
        drawingManager.setMap(mapReference);
    }
    return drawingManager;
};
exports.enableMapDrawingTools = enableMapDrawingTools;
const reverseGeocode = (coords) => {
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;
    return new Promise((resolve, reject) => {
        geocoder.geocode({ location: { lat: coords.latitude, lng: coords.longitude } }, (results, status) => {
            var _a;
            if (status !== OK) {
                return reject(status);
            }
            results = results || [];
            const match = results.sort((a, b) => {
                return b.types.length - a.types.length;
            })[0];
            const address = (0, utils_1.convertGeocodeResultToAddress)(match);
            return resolve([(_a = results[0]) === null || _a === void 0 ? void 0 : _a.formatted_address, address]);
        });
    });
};
exports.reverseGeocode = reverseGeocode;
const geocodeByAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;
    return new Promise((resolve, reject) => {
        geocoder.geocode({
            address,
        }, (results, status) => {
            if (status !== OK) {
                return reject(status);
            }
            return resolve(results || []);
        });
    });
};
exports.geocodeByAddress = geocodeByAddress;
const geocodeByPlaceId = (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;
    return new Promise((resolve, reject) => {
        geocoder.geocode({ placeId }, (results, status) => {
            if (status !== OK) {
                return reject(status);
            }
            return resolve(results || []);
        });
    });
};
exports.geocodeByPlaceId = geocodeByPlaceId;
