import { Box, Grid, Typography } from '@truxweb/ux';
import { localizedRoutes } from '../../config';
import { pageIdToLocaleString } from '../../utils';
import { PageLink } from '..';
import React from 'react';
import { TPageId } from '../../types';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TSiteMapProps = {
  isMobile?: boolean;
  linkClass?: string;
  pages?: TPageId[];
};

export const SiteMap = ({ isMobile, linkClass, pages }: TSiteMapProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { locale } = useRouter();
  const routes = pages || Object.keys(localizedRoutes[locale]);

  const siteMap = routes.map((key: TPageId) => {
    const [localeString, formattedPageId] = pageIdToLocaleString(key);
    const pageName = t(localeString);

    if (pageName === formattedPageId)
      console.warn(`${formattedPageId} does not have valid localization`);
    return (
      <Grid item key={key}>
        <Box mb={0.5}>
          <PageLink className={linkClass} pageId={key}>
            <Typography>{pageName}</Typography>
          </PageLink>
        </Box>
      </Grid>
    );
  });

  return (
    <Grid
      alignItems={isMobile ? 'center' : 'flex-start'}
      container
      direction="column"
      justifyContent="space-between"
    >
      {siteMap}
    </Grid>
  );
};
