import {
  RAW_SEARCH_FORM_DATA,
  SEARCH_LOCAL_STORAGE,
  SEARCH_MINIMUM_DATE_RESTRICTION,
} from '../../config';

import { getMinimumShipmentDate as coreGetMimiumShipmentDate } from '@truxweb/shipment-utils';
import { hasWindow } from '..';
import { TBookingSearchRequestV1 } from '@truxweb/schemas';

/// FIXME: currently we are storing data for the search in multiple
// ways. One is the formatted query submitted to the API - `*BookingQuery` -
// and the other is the data required to populate the form, `*SearchForm`
//
// Ideally there would only be a single stored item to drive both.
//
//
const SEARCH_PARAMS_VERSION = 'v1';

type TStoredQuery = {
  searchQuery: TBookingSearchRequestV1;
  version: string;
};

type TStoreSearchForm = {
  searchParams: Record<string, any>;
  version: string;
};

export const saveBookingQuery = (formData: TBookingSearchRequestV1): void => {
  const paramsToStore: TStoredQuery = {
    searchQuery: formData,
    version: SEARCH_PARAMS_VERSION,
  };
  localStorage.setItem(SEARCH_LOCAL_STORAGE, JSON.stringify(paramsToStore));
};

export const restoreBookingQuery = (): null | TBookingSearchRequestV1 => {
  const searchQuery = localStorage.getItem(SEARCH_LOCAL_STORAGE);
  if (!searchQuery) return null;
  const storedParams: TStoredQuery = JSON.parse(searchQuery);
  if (!storedParams.version || storedParams.version !== SEARCH_PARAMS_VERSION) {
    clearBookingQuery();
    return null;
  }
  return storedParams.searchQuery;
};

export const clearBookingQuery = (): void => {
  localStorage.removeItem(SEARCH_LOCAL_STORAGE);
};

export const saveSearchForm = (formData: Record<string, any>): void => {
  const paramsToStore: TStoreSearchForm = {
    searchParams: formData,
    version: SEARCH_PARAMS_VERSION,
  };
  localStorage.setItem(RAW_SEARCH_FORM_DATA, JSON.stringify(paramsToStore));
};

export const getMinimumShipmentDate = coreGetMimiumShipmentDate(SEARCH_MINIMUM_DATE_RESTRICTION);

export const restoreSearchForm = (): Record<string, any> | null => {
  const formParams = hasWindow() ? localStorage.getItem(RAW_SEARCH_FORM_DATA) : null;
  if (!formParams) return null;
  const storedForm: TStoreSearchForm = JSON.parse(formParams);

  if (!storedForm.version || storedForm.version !== SEARCH_PARAMS_VERSION) {
    clearSearchForm();
    return null;
  }

  // convert the stored date to be a date object
  if (storedForm.searchParams.shipmentDate) {
    const shipmentDate = new Date(storedForm.searchParams.shipmentDate);

    storedForm.searchParams.shipmentDate = (
      shipmentDate < getMinimumShipmentDate ? getMinimumShipmentDate : shipmentDate
    ).toISOString();
  }

  return storedForm.searchParams;
};

export const clearSearchForm = (): void => {
  localStorage.removeItem(RAW_SEARCH_FORM_DATA);
};
