// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentEquipmentIdentifierTypeV1 = void 0;
var ShipmentEquipmentIdentifierTypeV1;
(function (ShipmentEquipmentIdentifierTypeV1) {
    ShipmentEquipmentIdentifierTypeV1["MOBILE_PHONE_NUMBER"] = "MOBILE_PHONE_NUMBER";
    ShipmentEquipmentIdentifierTypeV1["LICENSE_PLATE"] = "LICENSE_PLATE";
    ShipmentEquipmentIdentifierTypeV1["SENSITECH_DEVICE_ID"] = "SENSITECH_DEVICE_ID";
    ShipmentEquipmentIdentifierTypeV1["TIVE_DEVICE_ID"] = "TIVE_DEVICE_ID";
    ShipmentEquipmentIdentifierTypeV1["VEHICLE_ID"] = "VEHICLE_ID";
})(ShipmentEquipmentIdentifierTypeV1 || (exports.ShipmentEquipmentIdentifierTypeV1 = ShipmentEquipmentIdentifierTypeV1 = {}));
