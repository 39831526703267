// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllInFScV1 = void 0;
var AllInFScV1;
(function (AllInFScV1) {
    AllInFScV1["ALL_IN"] = "ALL_IN";
    AllInFScV1["FSC"] = "FSC";
})(AllInFScV1 || (exports.AllInFScV1 = AllInFScV1 = {}));
