import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Grid,
  IconButton,
  Typography,
} from '@truxweb/ux';
import classnames from 'classnames';
import React from 'react';
import { useStyles } from './Pagination.styles';

type TPaginationProps = {
  currentPage: number;
  totalPages: number;
  handleChangePage: (pageNumber: number) => void;
};

export const Pagination = ({
  currentPage,
  handleChangePage,
  totalPages,
}: TPaginationProps): JSX.Element => {
  const classes = useStyles();

  const buttons = [];
  for (let i = 1; i <= totalPages; i++) {
    buttons.push(
      <Grid item key={i}>
        <Button
          className={classnames(classes.button, i === currentPage ? classes.selected : null)}
          onClick={() => {
            handleChangePage(i);
          }}
        >
          <Typography className={classes.buttonText}>{i}</Typography>
        </Button>
      </Grid>
    );
  }
  if (!buttons.length) return null;
  return (
    <Grid alignItems="center" container direction="row">
      <Grid item>
        <IconButton
          disabled={currentPage === 1}
          onClick={() => {
            handleChangePage(currentPage - 1);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      {buttons}
      <Grid item>
        <IconButton
          disabled={currentPage === totalPages}
          onClick={() => {
            handleChangePage(currentPage + 1);
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
