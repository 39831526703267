import { makeRequestToApiGateway } from '../../apiUtils';
import { TCompanyLocationV1 } from '@truxweb/schemas';

export const addUserCompanyLocation = async (
  data: TCompanyLocationV1 & {
    phone_ext?: string;
  }
): Promise<any> => {
  const location = {
    ...data,
  };
  return await makeRequestToApiGateway(
    'userPostV1CompanyLocation',
    null,
    {
      'Content-Type': 'application/json',
    },
    location
  );
};
