"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarWashIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.077,13.12,20.02,9.52A5.011,5.011,0,0,0,15.679,7H8.321A5.011,5.011,0,0,0,3.98,9.52l-2.057,3.6A7.007,7.007,0,0,0,1,16.594V18a4,4,0,0,0,2,3.463V22a2,2,0,0,0,4,0H17a2,2,0,0,0,4,0v-.537A4,4,0,0,0,23,18V16.594A7.007,7.007,0,0,0,22.077,13.12ZM5.717,10.512A3.006,3.006,0,0,1,8.321,9h7.358a3.006,3.006,0,0,1,2.6,1.512L20.277,14H3.723ZM21,18a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V16.594A5.119,5.119,0,0,1,3.036,16H5v1a1,1,0,0,0,2,0V16H17v1a1,1,0,0,0,2,0V16h1.964a5.119,5.119,0,0,1,.036.594ZM10.586,4.414a2,2,0,0,1,0-2.828l.8-.9a.817.817,0,0,1,1.222,0l.8.9A2.013,2.013,0,0,1,12,5,1.99,1.99,0,0,1,10.586,4.414Zm5.985,0a2,2,0,0,1,0-2.828l.8-.9a.818.818,0,0,1,1.223,0l.8.9A2.013,2.013,0,0,1,17.985,5,1.992,1.992,0,0,1,16.571,4.414Zm-12,0a2,2,0,0,1,0-2.828l.8-.9A.818.818,0,0,1,6.6.686l.8.9A2.013,2.013,0,0,1,5.985,5,1.992,1.992,0,0,1,4.571,4.414Z" })));
exports.default = TruxwebCarWashIcon;
