import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  alignSelf: {
    alignSelf: 'center',
  },
  flexHeight: {
    height: '100%',
    minHeight: theme.spacing(54),
  },
  flexHeightMax: {
    height: '100%',
    minHeight: theme.spacing(70),
  },
  formHeight: {
    height: `${theme.spacing(175)}px !important`,
  },
  leftHandContent: {
    minHeight: theme.spacing(25),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6.5),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  maxWidth: {
    maxWidth: theme.spacing(62),
    width: '100%',
  },
  minHeight: {
    minHeight: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      minHeight: 'auto',
    },
  },
  overflow: {
    display: 'flex',
    overflow: 'visible',
  },
  pageContainer: {
    alignContent: 'stretch',
    display: 'flex',
    height: 'auto',
    minHeight: '100vh',
    overflow: 'none',
  },
  pane: {
    height: 'inherit',
  },
  rightHandContent: {
    alignContent: 'center',
    background: theme.palette.primary.light,
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: theme.spacing(37),
      marginTop: theme.spacing(6),
    },
  },
}));
