"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)(() => ({
    d: {
        fill: '#aaceed',
    },
    e: {
        fill: '#735a87',
    },
    f: {
        fill: '#4292cf',
    },
    g: {
        fill: '#0c4d93',
    },
    h: {
        fill: '#2873ac',
    },
    i: {
        fill: '#aed1b9',
    },
    j: {
        fill: '#1f376b',
    },
    k: {
        fill: '#76b5e3',
    },
    l: {
        fill: '#1887c9',
    },
}));
