"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    specialInstructionContainer: {
        background: theme.palette.primary.dark,
    },
    specialInstructionHeader: {
        color: theme.palette.primary.light,
        fontWeight: 600,
    },
    specialInstructionText: {
        color: theme.palette.common.white,
        width: '100%',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
    },
}));
