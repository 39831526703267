import { makeRequestToApiGateway } from '../../../apiUtils';

export const deactivateCreditCardByIdV2 = async (cardId: number): Promise<void> => {
  await makeRequestToApiGateway(
    'billingPatchV2CardByIdDeactivate',
    { id: cardId },
    {
      'Content-Type': 'application/json',
    },
    {
      cardId,
    }
  );
  return;
};
