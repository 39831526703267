"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruckDrivingIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 80 80", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "#0087CE", height: 80, rx: 40, width: 80 }),
    React.createElement("g", { clipPath: "url(#clip0_69_632)" },
        React.createElement("path", { d: "M50.2109 43.6811C47.4297 43.6811 45.1719 45.8867 45.1719 48.5867C45.1719 51.2867 47.4375 53.4923 50.2109 53.4923C52.9922 53.4923 55.25 51.2867 55.25 48.5867C55.25 45.8867 52.9922 43.6811 50.2109 43.6811ZM50.2109 51.0433C48.8203 51.0433 47.6875 49.9405 47.6875 48.5867C47.6875 47.2329 48.8203 46.1301 50.2109 46.1301C51.6016 46.1301 52.7344 47.2329 52.7344 48.5867C52.7344 49.9405 51.6016 51.0433 50.2109 51.0433Z", fill: "white" }),
        React.createElement("path", { d: "M32.9844 43.6811C30.2031 43.6811 27.9453 45.8867 27.9453 48.5867C27.9453 51.2867 30.2109 53.4923 32.9844 53.4923C35.7578 53.4923 38.0234 51.2867 38.0234 48.5867C38.0234 45.8867 35.7656 43.6811 32.9844 43.6811ZM32.9844 51.0433C31.5937 51.0433 30.4609 49.9405 30.4609 48.5867C30.4609 47.2329 31.5937 46.1301 32.9844 46.1301C34.375 46.1301 35.5078 47.2329 35.5078 48.5867C35.5078 49.9405 34.375 51.0433 32.9844 51.0433Z", fill: "white" }),
        React.createElement("path", { d: "M53.6016 29.6336C53.3906 29.2229 52.9531 28.9567 52.4766 28.9567H45.8359V31.4133H51.6953L55.125 38.0606L57.375 36.9578L53.6016 29.6336Z", fill: "white" }),
        React.createElement("path", { d: "M46.4297 47.4003H36.8906V49.8569H46.4297V47.4003Z", fill: "white" }),
        React.createElement("path", { d: "M29.2031 47.4003H24.8281C24.1328 47.4003 23.5703 47.9479 23.5703 48.6248C23.5703 49.3017 24.1328 49.8493 24.8281 49.8493H29.1953C29.8906 49.8493 30.4531 49.3017 30.4531 48.6248C30.4609 47.9479 29.8984 47.4003 29.2031 47.4003Z", fill: "white" }),
        React.createElement("path", { d: "M59.7344 39.9011L57.2578 36.7904C57.0234 36.4938 56.6484 36.3189 56.2656 36.3189H47.1016V27.7245C47.1016 27.0476 46.5391 26.5 45.8437 26.5H24.8281C24.1328 26.5 23.5703 27.0476 23.5703 27.7245C23.5703 28.4014 24.1328 28.949 24.8281 28.949H44.5781V37.5358C44.5781 38.2127 45.1406 38.7603 45.8359 38.7603H55.6406L57.4766 41.0648V47.3927H53.9922C53.2969 47.3927 52.7344 47.9403 52.7344 48.6172C52.7344 49.2941 53.2969 49.8417 53.9922 49.8417H58.7422C59.4375 49.8417 60 49.2941 60 48.6172V40.6541C60 40.3803 59.9062 40.1141 59.7344 39.9011Z", fill: "white" }),
        React.createElement("path", { d: "M29.1172 41.1865H23.3203C22.625 41.1865 22.0625 41.7341 22.0625 42.411C22.0625 43.0879 22.625 43.6355 23.3203 43.6355H29.1172C29.8125 43.6355 30.375 43.0879 30.375 42.411C30.375 41.7341 29.8125 41.1865 29.1172 41.1865Z", fill: "white" }),
        React.createElement("path", { d: "M32.0156 36.357H21.2578C20.5625 36.357 20 36.9046 20 37.5815C20 38.2584 20.5625 38.806 21.2578 38.806H32.0156C32.7109 38.806 33.2734 38.2584 33.2734 37.5815C33.2734 36.9046 32.7109 36.357 32.0156 36.357Z", fill: "white" }),
        React.createElement("path", { d: "M34.0781 31.5349H23.3203C22.625 31.5349 22.0625 32.0825 22.0625 32.7594C22.0625 33.4363 22.625 33.9839 23.3203 33.9839H34.0781C34.7734 33.9839 35.3359 33.4363 35.3359 32.7594C35.3359 32.0825 34.7734 31.5349 34.0781 31.5349Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_69_632" },
            React.createElement("rect", { fill: "white", height: 27, transform: "translate(20 26.5)", width: 40 })))));
exports.default = TruckDrivingIcon;
