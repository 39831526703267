"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        background: 'rgb(237, 237, 237)',
        borderRadius: theme.spacing(2),
        boxShadow: 'unset',
    },
}));
