"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManageLaneRateSheets = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const ManageLaneRateSheets = ({ handleAssignRateSheet, handleCloseRateSheetAssignmentSidebar, isSaving, lane, laneId, rateSheetLocations, rateSheetRegions, rateSheets, t, usedRateSheets, }) => {
    const rateSheetAssignmentFormControls = (0, react_hook_form_1.useForm)();
    const [isRateSheetLocationIndependent, setRateSheetLocationIndependent] = (0, react_1.useState)(true);
    const sortedRateSheetLocations = (0, react_1.useMemo)(() => {
        const completeList = [];
        (rateSheetRegions || []).forEach((record) => {
            completeList.push(Object.assign(Object.assign({}, record), { boundaryType: schemas_1.ERateSheetBoundaryTypeV1.REGION }));
        });
        (rateSheetLocations || []).forEach((record) => {
            completeList.push(Object.assign(Object.assign({}, record), { boundaryType: schemas_1.ERateSheetBoundaryTypeV1.CITY }));
        });
        return (completeList || []).slice().sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }, [rateSheetLocations, rateSheetRegions]);
    const locationType = rateSheetAssignmentFormControls.watch('locationType');
    const onSubmitRateSheetAssignment = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleAssignRateSheet)
            yield handleAssignRateSheet(Object.assign(Object.assign({}, data), { laneId }));
    }), [handleAssignRateSheet, laneId]);
    const [availableRateSheets, canSetLocationIndependent] = (0, react_1.useMemo)(() => {
        var _a;
        if (!((_a = usedRateSheets === null || usedRateSheets === void 0 ? void 0 : usedRateSheets[locationType]) === null || _a === void 0 ? void 0 : _a.length) || !(rateSheets === null || rateSheets === void 0 ? void 0 : rateSheets.length))
            return [rateSheets || [], true];
        const usedIds = [];
        let canSetLocationIndependent = true;
        usedRateSheets[locationType].forEach(({ rateSheetId, rateSheetLocationId }) => {
            if (rateSheetLocationId === null) {
                canSetLocationIndependent = false;
            }
            usedIds.push(rateSheetId);
        });
        const filteredRateSheets = rateSheets.filter(({ id }) => {
            return !usedIds.includes(id);
        });
        return [filteredRateSheets, canSetLocationIndependent];
    }, [usedRateSheets, rateSheets, locationType]);
    return (react_1.default.createElement("form", { onSubmit: rateSheetAssignmentFormControls.handleSubmit(onSubmitRateSheetAssignment) },
        react_1.default.createElement(react_1.default.Fragment, null, (lane === null || lane === void 0 ? void 0 : lane.bidirectionalService) && (react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement(react_hook_form_1.Controller, { control: rateSheetAssignmentFormControls.control, defaultValue: schemas_1.EShipmentLocationTypeV1.ORIGIN, name: "locationType", render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(__1.ExplainedString, { explanation: t('common:rateSheetLocationTypeExplained'), fontStyle: "semibold", isTooltip: true }, t('common:locationType')),
                        react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", onChange: onChange, sourceEnum: schemas_1.EShipmentLocationTypeV1, t: t, value: value })));
                } })))),
        react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement(react_hook_form_1.Controller, { control: rateSheetAssignmentFormControls.control, defaultValue: '', name: "carrierRateSheetId", render: ({ field: { onChange, value } }) => {
                    if (!availableRateSheets.length) {
                        return (react_1.default.createElement(__1.ExplainedString, { explanation: t('common:noRateSheetsAvailableExplained'), fontStyle: "semibold", isTooltip: true }, t('common:noRateSheetsAvailable')));
                    }
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(__1.ExplainedString, { explanation: t('common:laneRateSheetExplained'), fontStyle: "semibold", isTooltip: true }, t('common:rateSheet')),
                        react_1.default.createElement(ux_1.TruxwebSelect, { fullWidth: true, onChange: onChange, value: value }, (availableRateSheets || []).map((record) => {
                            return (react_1.default.createElement(ux_1.MenuItem, { key: record.id, value: record.id }, record.name));
                        }))));
                }, rules: { required: true } })),
        canSetLocationIndependent && (react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement(react_hook_form_1.Controller, { control: rateSheetAssignmentFormControls.control, defaultValue: true, name: "isLocationIndependent", render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primary", onChange: (event) => {
                                rateSheetAssignmentFormControls.setValue('rateSheetLocationId', null);
                                setRateSheetLocationIndependent(event.target.checked);
                                onChange(event);
                            } }), label: react_1.default.createElement(ux_1.Box, { ml: 1 },
                            react_1.default.createElement(__1.ExplainedString, { explanation: t('common:isLocationIndependentExplained'), isTooltip: true }, t('common:isLocationIndependent'))) }));
                } }))),
        react_1.default.createElement(react_1.default.Fragment, null, !isRateSheetLocationIndependent && (react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement(react_hook_form_1.Controller, { control: rateSheetAssignmentFormControls.control, name: "rateSheetLocationId", render: () => {
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(__1.ExplainedString, { explanation: t('common:laneRateSheetLocationExplained'), fontStyle: "semibold", isTooltip: true }, t('common:location')),
                        react_1.default.createElement(ux_1.Autocomplete, { autoComplete: true, fullWidth: true, getOptionLabel: ({ name }) => name, onChange: (_event, newValue) => {
                                rateSheetAssignmentFormControls.setValue('boundaryType', newValue === null || newValue === void 0 ? void 0 : newValue.boundaryType);
                                if ((newValue === null || newValue === void 0 ? void 0 : newValue.boundaryType) === schemas_1.ERateSheetBoundaryTypeV1.REGION) {
                                    rateSheetAssignmentFormControls.setValue('rateSheetLocationId', 0);
                                    rateSheetAssignmentFormControls.setValue('rateSheetRegionId', newValue === null || newValue === void 0 ? void 0 : newValue.id);
                                }
                                else if ((newValue === null || newValue === void 0 ? void 0 : newValue.boundaryType) === schemas_1.ERateSheetBoundaryTypeV1.CITY) {
                                    rateSheetAssignmentFormControls.setValue('rateSheetRegionId', 0);
                                    rateSheetAssignmentFormControls.setValue('rateSheetLocationId', newValue === null || newValue === void 0 ? void 0 : newValue.id);
                                }
                            }, options: sortedRateSheetLocations, renderInput: (params) => {
                                return react_1.default.createElement(ux_1.TextField, Object.assign({}, params, { variant: "outlined" }));
                            }, renderOption: ({ boundaryType, name }) => {
                                const icon = boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION ? (react_1.default.createElement("span", { title: t('common:region') },
                                    react_1.default.createElement(ux_1.TruxwebGlobeAltIcon, null))) : (react_1.default.createElement("span", { title: t('common:location') },
                                    react_1.default.createElement(ux_1.TruxwebBuildingIcon, null)));
                                return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "flex-start" },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Box, { mr: 1 }, icon)),
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, name))));
                            } })));
                }, rules: { required: true } })))),
        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.StandardButton, { onClick: handleCloseRateSheetAssignmentSidebar }, t('common:cancel'))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t })))));
};
exports.ManageLaneRateSheets = ManageLaneRateSheets;
