"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRingsWeddingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16,8a7.974,7.974,0,0,0-5.974,2.7,6.882,6.882,0,0,0-1.495-.524L9.9,7.447a1,1,0,0,0-.043-.971A3.4,3.4,0,0,0,7,5,3.4,3.4,0,0,0,4.148,6.476a1,1,0,0,0-.043.971l1.364,2.727a7,7,0,1,0,5.866,12.312A8,8,0,1,0,16,8ZM11.234,19.63a5.946,5.946,0,0,1-.678-6.137,4.946,4.946,0,0,1,.678,6.137ZM2,17a4.987,4.987,0,0,1,6.879-4.627,7.957,7.957,0,0,0,.966,8.73A4.99,4.99,0,0,1,2,17Zm14,5a5.961,5.961,0,0,1-3.278-.981A6.963,6.963,0,0,0,11.7,11.826,6,6,0,1,1,16,22Z" }),
    React.createElement("path", { d: "M10.553,4.895A1,1,0,0,0,11.9,4.447l1-2a1,1,0,1,0-1.79-.894l-1,2A1,1,0,0,0,10.553,4.895Z" }),
    React.createElement("path", { d: "M2.105,4.447A1,1,0,1,0,3.9,3.553l-1-2a1,1,0,0,0-1.79.894Z" }),
    React.createElement("path", { d: "M7,4A1,1,0,0,0,8,3V1A1,1,0,0,0,6,1V3A1,1,0,0,0,7,4Z" })));
exports.default = TruxwebRingsWeddingIcon;
