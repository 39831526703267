// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcommUserRoleV1 = void 0;
var EcommUserRoleV1;
(function (EcommUserRoleV1) {
    EcommUserRoleV1["AUTHORIZED_SHIPPER"] = "AUTHORIZED-SHIPPER";
    EcommUserRoleV1["CARRIER"] = "CARRIER";
    EcommUserRoleV1["CARRIER_ADMIN"] = "CARRIER-ADMIN";
    EcommUserRoleV1["CONFIRMED_SHIPPER"] = "CONFIRMED-SHIPPER";
    EcommUserRoleV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
    EcommUserRoleV1["SHIPPER"] = "SHIPPER";
    EcommUserRoleV1["SHIPPER_ADMIN"] = "SHIPPER-ADMIN";
    EcommUserRoleV1["SYSTEM_ADMIN"] = "SYSTEM-ADMIN";
    EcommUserRoleV1["SHIPPER_BROKER"] = "SHIPPER_BROKER";
})(EcommUserRoleV1 || (exports.EcommUserRoleV1 = EcommUserRoleV1 = {}));
