import {
  ARE_MOBILE_MENUS_DARK,
  Box,
  Button,
  Grid,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@truxweb/ux';
import {
  HeaderButton,
  LanguageSwitcher,
  MobileDropDownMenuHeader,
  PageLink,
  ResponsiveHeaderLogo,
  SocialMediaIconLinks,
} from '..';
import Hamburger from 'hamburger-react';
import { TPageId } from '../../types';
import { useAuthBoolean } from '../../hooks';
import { useState } from 'react';
import { useStyles } from './MobileHeaderMenu.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TMobileHeaderMenuProps = {
  hasCarrierPermission: boolean;
  handleClick: () => void;
  areCarrierPermissionsEvaluated: boolean;
  areShipperPermissionsEvaluated: boolean;
  isMobileMenuOpen: boolean;
  pageId: TPageId;
  hasShipperPermission: boolean;
  toggleDrawer: (isOpen: boolean) => any;
};

// eslint-disable-next-line complexity
export const MobileHeaderMenu = ({
  areCarrierPermissionsEvaluated,
  areShipperPermissionsEvaluated,
  handleClick,
  hasCarrierPermission,
  hasShipperPermission,
  isMobileMenuOpen,
  pageId,
  toggleDrawer,
}: TMobileHeaderMenuProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeDisplay = useMediaQuery(theme.breakpoints.up('lg'));
  const [isActive, setIsActive] = useState('');
  const classes = useStyles({ isMobile });
  const linkVariant = isMobile ? 'h6' : 'h3';
  const variant = isMobile ? 'h5' : 'h3';

  const isAuthed = useAuthBoolean();

  let siteMapContent = null;

  // We don't want to render the mobile menu if we are viewing the desktop
  // version of the site
  if (isLargeDisplay) return null;

  if (isAuthed && hasCarrierPermission && areCarrierPermissionsEvaluated) {
    siteMapContent = (
      <>
        <Grid className={classes.authedMenuItem} item>
          {/* SHIPMENTS */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:shipmentsFooter')}
            pages={['shipments']}
            setIsActive={setIsActive}
            text={t('common:shipmentsFooter')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
        <Grid className={classes.authedMenuItem} item>
          {/* EQUIPMENT */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:equipmentFooter')}
            pages={['fleet/equipment', 'fleet/lanes', 'fleet/accessorials']}
            setIsActive={setIsActive}
            text={t('common:equipmentFooter')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
        <Grid className={classes.authedMenuItem} item>
          {/* ACCOUNT */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:accountFooter')}
            pages={[
              'account/company',
              'account/payment',
              'account/address',
              'account/notifications',
              'account/profile',
            ]}
            setIsActive={setIsActive}
            text={t('common:accountFooter')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
      </>
    );
  } else if (isAuthed && hasShipperPermission && areShipperPermissionsEvaluated) {
    siteMapContent = (
      <>
        <Grid className={classes.authedMenuItem} item>
          {/* SEARCH  */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:searchFooter')}
            pages={['search', 'results']}
            setIsActive={setIsActive}
            text={t('common:searchFooter')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
        <Grid className={classes.authedMenuItem} item>
          {/* SHIPMENTS */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:shipmentsFooter')}
            pages={['shipments']}
            setIsActive={setIsActive}
            text={t('common:shipmentsFooter')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
        <Grid className={classes.authedMenuItem} item>
          {/* ACCOUNT */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:accountFooter')}
            pages={[
              'account',
              'account/company',
              'account/payment',
              'account/address',
              'account/notifications',
            ]}
            setIsActive={setIsActive}
            text={t('common:accountFooter')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
        <Grid className={classes.authedMenuItem} item>
          {/* HELP */}
          <MobileDropDownMenuHeader
            hasChevron
            isItemOpen={isActive === t('common:help')}
            pages={['contact-us']}
            setIsActive={setIsActive}
            text={t('common:help')}
            variant={variant}
          ></MobileDropDownMenuHeader>
        </Grid>
      </>
    );
  }

  return (
    <>
      <SwipeableDrawer
        ModalProps={{ BackdropProps: { invisible: true } }}
        PaperProps={{ className: classes.popover }}
        anchor={'right'}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        open={isMobileMenuOpen}
      >
        <Grid
          alignItems="center"
          className={classes.list}
          container
          data-testid={`MobileHeaderMenu-MobileHeaderMenu`}
          direction="column"
          justifyContent="space-between"
        >
          <Grid className={classes.drawer} item>
            <Box mb={2}>
              <Grid container justifyContent="space-between">
                <Box className={classes.logoWrap} mb={2} mt={3}>
                  {!isMobile && <ResponsiveHeaderLogo isDark={ARE_MOBILE_MENUS_DARK} />}
                </Box>

                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  name="mobileHamburger"
                  onClick={handleClick}
                >
                  <Hamburger color="white" size={24} toggled={isMobileMenuOpen} />
                </Button>
              </Grid>
            </Box>
            <Grid alignItems="center" container direction="column" spacing={2}>
              {isMobile && (
                <Grid item>
                  <Box className={classes.logoWrap} mb={2} mt={3}>
                    <ResponsiveHeaderLogo isDark={ARE_MOBILE_MENUS_DARK} />
                  </Box>
                </Grid>
              )}
              {isAuthed && siteMapContent}
              {!isAuthed && (
                <>
                  <Grid item>
                    <a
                      href="https://truxweb.breezy.hr/"
                      style={{ textDecoration: 'none' }}
                      target="carreers"
                    >
                      <Typography className={classes.link} variant={linkVariant}>
                        {t('common:carreers')}
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item>
                    <PageLink pageId={'contact-us'}>
                      <Typography className={classes.link} variant={linkVariant}>
                        {t('common:contactUs')}
                      </Typography>
                    </PageLink>
                  </Grid>
                  <Grid item>
                    <Box mt={8}>
                      <Grid
                        alignItems="center"
                        className={classes.buttonWrap}
                        container
                        direction={isMobile ? 'column' : 'row'}
                        justifyContent="center"
                        spacing={2}
                      >
                        <Grid item>
                          <PageLink pageId={'signup'}>
                            <HeaderButton text={t('common:signUp')} type="standard" />
                          </PageLink>
                        </Grid>
                        <Grid item>
                          <PageLink pageId={'login'}>
                            <HeaderButton text={t('common:logIn')} type="login" />
                          </PageLink>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Box className={classes.socialsWrap} mb={6.5}>
            <Grid alignItems="center" container justifyContent="space-between">
              <Grid className={classes.socialsWidth} item xs={6}>
                <SocialMediaIconLinks
                  isDark={ARE_MOBILE_MENUS_DARK}
                  screenSize={isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet'}
                />
              </Grid>
              <Grid item>
                <LanguageSwitcher pageId={pageId} textClassName={classes.languageSwitcherText} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};
