// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentTrackingStatusV1 = exports.ShipmentTrackingLocationStatusV1 = exports.ShipmentTrackingLocationArrivalStatusV1 = exports.ShipmentIdentifierTypeV1 = exports.ShipmentLocationTypeV1 = exports.DayOfWeekV1 = exports.CountryV1 = exports.ShipmentEquipmentIdentifierTypeV1 = exports.CarrierIdentifierTypeV1 = void 0;
var CarrierIdentifierTypeV1;
(function (CarrierIdentifierTypeV1) {
    CarrierIdentifierTypeV1["DOT_NUMBER"] = "DOT_NUMBER";
    CarrierIdentifierTypeV1["MC_NUMBER"] = "MC_NUMBER";
    CarrierIdentifierTypeV1["P44_GLOBAL"] = "P44_GLOBAL";
    CarrierIdentifierTypeV1["P44_EU"] = "P44_EU";
    CarrierIdentifierTypeV1["SCAC"] = "SCAC";
    CarrierIdentifierTypeV1["SYSTEM"] = "SYSTEM";
})(CarrierIdentifierTypeV1 || (exports.CarrierIdentifierTypeV1 = CarrierIdentifierTypeV1 = {}));
var ShipmentEquipmentIdentifierTypeV1;
(function (ShipmentEquipmentIdentifierTypeV1) {
    ShipmentEquipmentIdentifierTypeV1["MOBILE_PHONE_NUMBER"] = "MOBILE_PHONE_NUMBER";
    ShipmentEquipmentIdentifierTypeV1["LICENSE_PLATE"] = "LICENSE_PLATE";
    ShipmentEquipmentIdentifierTypeV1["SENSITECH_DEVICE_ID"] = "SENSITECH_DEVICE_ID";
    ShipmentEquipmentIdentifierTypeV1["TIVE_DEVICE_ID"] = "TIVE_DEVICE_ID";
    ShipmentEquipmentIdentifierTypeV1["VEHICLE_ID"] = "VEHICLE_ID";
})(ShipmentEquipmentIdentifierTypeV1 || (exports.ShipmentEquipmentIdentifierTypeV1 = ShipmentEquipmentIdentifierTypeV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var ShipmentLocationTypeV1;
(function (ShipmentLocationTypeV1) {
    ShipmentLocationTypeV1["ORIGIN"] = "ORIGIN";
    ShipmentLocationTypeV1["DESTINATION"] = "DESTINATION";
})(ShipmentLocationTypeV1 || (exports.ShipmentLocationTypeV1 = ShipmentLocationTypeV1 = {}));
var ShipmentIdentifierTypeV1;
(function (ShipmentIdentifierTypeV1) {
    ShipmentIdentifierTypeV1["BILL_OF_LADING"] = "BILL_OF_LADING";
    ShipmentIdentifierTypeV1["ORDER"] = "ORDER";
})(ShipmentIdentifierTypeV1 || (exports.ShipmentIdentifierTypeV1 = ShipmentIdentifierTypeV1 = {}));
var ShipmentTrackingLocationArrivalStatusV1;
(function (ShipmentTrackingLocationArrivalStatusV1) {
    ShipmentTrackingLocationArrivalStatusV1["EARLY"] = "EARLY";
    ShipmentTrackingLocationArrivalStatusV1["LATE"] = "LATE";
    ShipmentTrackingLocationArrivalStatusV1["ON_TIME"] = "ON_TIME";
    ShipmentTrackingLocationArrivalStatusV1["UNKNOWN"] = "UNKNOWN";
})(ShipmentTrackingLocationArrivalStatusV1 || (exports.ShipmentTrackingLocationArrivalStatusV1 = ShipmentTrackingLocationArrivalStatusV1 = {}));
var ShipmentTrackingLocationStatusV1;
(function (ShipmentTrackingLocationStatusV1) {
    ShipmentTrackingLocationStatusV1["ARRIVED"] = "ARRIVED";
    ShipmentTrackingLocationStatusV1["DEPARTED"] = "DEPARTED";
    ShipmentTrackingLocationStatusV1["EN_ROUTE"] = "EN_ROUTE";
    ShipmentTrackingLocationStatusV1["UNKNOWN"] = "UNKNOWN";
})(ShipmentTrackingLocationStatusV1 || (exports.ShipmentTrackingLocationStatusV1 = ShipmentTrackingLocationStatusV1 = {}));
var ShipmentTrackingStatusV1;
(function (ShipmentTrackingStatusV1) {
    ShipmentTrackingStatusV1["AT_STOP"] = "AT_STOP";
    ShipmentTrackingStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentTrackingStatusV1["DISPATCHED"] = "DISPATCHED";
    ShipmentTrackingStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentTrackingStatusV1["INFO"] = "INFO";
    ShipmentTrackingStatusV1["TRACKING_FAILED"] = "TRACKING_FAILED";
})(ShipmentTrackingStatusV1 || (exports.ShipmentTrackingStatusV1 = ShipmentTrackingStatusV1 = {}));
