import { makeStyles, Theme } from '@truxweb/ux';

export const useFormIconStyles = makeStyles<Theme>((theme: Theme) => ({
  completeFieldIcon: {
    color: theme.palette.primary.main,
  },
  errorFieldIcon: {
    color: theme.palette.error.main,
  },
  fileUploadError: {
    // TODO: get rid of `!important` shame on me!
    borderColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.main} !important`,
  },
  highlightFieldIcon: {
    color: theme.palette.primary.light,
  },
  incompleteFieldIcon: {
    color: '#C4C4C4',
  },
}));
