"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRugbyHelmetIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.983,21.926a32.651,32.651,0,0,0-.608-5.545A3,3,0,0,0,20.439,14h-2.1l-.3-3.635.391-.042A3.007,3.007,0,0,0,20.72,6.085C19.084,2.559,14.786,0,10.5,0A10.512,10.512,0,0,0,0,10.5a39.043,39.043,0,0,0,.587,5.155,4.99,4.99,0,0,0,3.457,3.989c.514.161,1.02.311,1.519.458a25.17,25.17,0,0,1,4.1,1.467A4.072,4.072,0,0,0,11.479,22a3.975,3.975,0,0,0,2.777-1.123l1.1-.877h1.475l.071.855a2.978,2.978,0,0,0,2.137,2.631A25.208,25.208,0,0,0,21.979,24a2.013,2.013,0,0,0,2-2.074ZM20.439,16a.994.994,0,0,1,.977.783c.061.3.138.709.215,1.217H18.667L18.5,16Zm-4.11-2H13.305a2.888,2.888,0,0,1,.035-1.237,2.853,2.853,0,0,1,2.338-2.142l.367-.04Zm-3.445,5.422a2.016,2.016,0,0,1-2.329.357A26.63,26.63,0,0,0,6.13,18.185c-.487-.145-.983-.291-1.485-.45a3,3,0,0,1-2.082-2.394A38.713,38.713,0,0,1,2,10.5,8.51,8.51,0,0,1,10.5,2c3.489,0,7.1,2.118,8.406,4.927a1.009,1.009,0,0,1-.732,1.412l-2.747.3a4.874,4.874,0,0,0-3.564,7.181l1.692,3.058S12.91,19.4,12.884,19.422ZM15.355,18l-1.106-2H16.5l.166,2ZM19.6,21.567a.99.99,0,0,1-.707-.878L18.833,20h3.034c.053.608.094,1.277.113,2A11.842,11.842,0,0,1,19.6,21.567ZM10,16a1,1,0,1,1-1-1A1,1,0,0,1,10,16Z" })));
exports.default = TruxwebRugbyHelmetIcon;
