import { CarrierQuotesPage, ShipperQuotesPages } from '..';
import React, { useEffect } from 'react';
import { useApplicationLoadingState, useAuthBoolean, useUserData } from '../../hooks';
import { AuthorizedPage } from '../../pageTemplates';
import { EUserTypeV1 } from '@truxweb/schemas';
import { TTruxwebPageContentProps } from '../../types';
import { updateApplicationLoadingState } from '../../stores';
import { useDispatch } from 'react-redux';

export const ShipmentRequestPage = ({
  ipAddress,
  pageId,
}: TTruxwebPageContentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { userData, userType } = useUserData();
  const applicationLoadingState = useApplicationLoadingState();
  const isAuthed = useAuthBoolean();

  useEffect(() => {
    if (!applicationLoadingState.isApplicationLoading && userData === null) {
      dispatch(
        updateApplicationLoadingState({ ...applicationLoadingState, isApplicationLoading: true })
      );
    }
    if (applicationLoadingState.isApplicationLoading && userData !== null) {
      dispatch(
        updateApplicationLoadingState({ ...applicationLoadingState, isApplicationLoading: false })
      );
    }
  }, [applicationLoadingState, dispatch, userData]);

  return (
    <>
      {userType === EUserTypeV1.CARRIER && <CarrierQuotesPage pageId={pageId} />}
      {userType === EUserTypeV1.SHIPPER && (
        <ShipperQuotesPages ipAddress={ipAddress} pageId={pageId} />
      )}
      {!isAuthed && <AuthorizedPage pageId={pageId} redirectPageId={'login'} />}
    </>
  );
};
