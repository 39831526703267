"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierLanes = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const CarrierLanes = ({ canManageCarrierData, data, handleRouteToLane, handleSelectedRows, handleToggleLaneActiveState, isLoading, pageSize, selectedRows, shouldHideActiveColumn, t, }) => {
    const columns = (0, react_1.useMemo)(() => {
        const activeCell = shouldHideActiveColumn
            ? null
            : {
                field: 'active',
                headerName: t('common:activeCellHeader'),
                maxWidth: 25,
                renderCell: ({ row }) => {
                    if (!row.id)
                        return null;
                    if (!handleToggleLaneActiveState) {
                        // eslint-disable-next-line no-console
                        console.error(`Active column enabled without action to handle state change`);
                        return null;
                    }
                    return (react_1.default.createElement(ux_1.Switch, { checked: row === null || row === void 0 ? void 0 : row.active, color: "primary", disabled: !canManageCarrierData, onClick: () => {
                            handleToggleLaneActiveState(row.id, row.active);
                        } }));
                },
            };
        const columns = [
            activeCell,
            {
                field: 'pendingUpdate',
                flex: 1,
                headerName: t('common:pendingUpdatesCellHeader'),
                minWidth: 75,
                type: 'boolean',
            },
            {
                field: 'carrierReference',
                flex: 1,
                headerName: t('common:carrierReferenceCellHeader'),
                renderCell: (data) => {
                    const display = data.value
                        ? data.value
                        : `${(0, utils_1.getCarrierLocationName)(data.row.origin)} → ${(0, utils_1.getCarrierLocationName)(data.row.destination)}`;
                    return (react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", style: { cursor: 'pointer' } }, display));
                },
            },
            {
                field: 'origin',
                headerName: t('common:originCellHeader'),
                valueGetter: ({ value }) => {
                    return (0, utils_1.getCarrierLocationName)(value);
                },
                width: 250,
            },
            {
                field: 'destination',
                headerName: t('common:destinationCellHeader'),
                valueGetter: ({ value }) => {
                    return (0, utils_1.getCarrierLocationName)(value);
                },
                width: 250,
            },
            {
                field: 'bidirectionalService',
                flex: 1,
                headerName: t('common:bidirectionalCellHeader'),
                minWidth: 75,
                type: 'boolean',
            },
            {
                field: 'dateUpdated',
                flex: 1,
                headerName: t('common:lastModifiedDateCellHeader'),
                minWidth: 250,
                type: 'dateTime',
                valueGetter: ({ value }) => value && new Date(value),
            },
        ];
        return columns;
    }, [t, canManageCarrierData, handleToggleLaneActiveState, shouldHideActiveColumn]);
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: columns.filter((col) => col), data: data, gridHeight: ((pageSize || 5) + 1) * 96, handleCellClick: ({ field, row }) => {
            if (!['active', 'carrierReference'].includes(field)) {
                handleSelectedRows(row.id);
            }
            else if (field === 'carrierReference') {
                handleRouteToLane(row.id);
            }
        }, isLoading: isLoading, pageSize: pageSize || 5, selectionModel: selectedRows, t: t }));
};
exports.CarrierLanes = CarrierLanes;
