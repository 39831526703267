import { makeStyles, Theme } from '@truxweb/ux';

type LandingPageValuePropSelectorItemProps = {
  isActive: boolean;
};
export const useStyles = makeStyles<Theme, LandingPageValuePropSelectorItemProps>(
  (theme: Theme) => ({
    color: {
      color: ({ isActive }) => {
        return isActive ? theme.palette.common.white : theme.palette.primary.main;
      },
    },
    container: {
      '&:hover': { cursor: 'pointer' },
      alignItems: 'center',
      background: ({ isActive }) => {
        return isActive ? theme.palette.primary.main : theme.palette.common.white;
      },
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '10px',
      display: 'flex',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(5),
      },
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(7),
      },
      [theme.breakpoints.up('lg')]: {
        border: `4px solid ${theme.palette.primary.main}`,
        height: theme.spacing(10),
      },
    },
  })
);
