"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    carrierLineItem: {
        minWidth: theme.spacing(63),
    },
    lineItemText: {
        fontWeight: 600,
    },
    priceHeader: {
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    summaryLineItem: {
        marginBottom: theme.spacing(1),
    },
}));
