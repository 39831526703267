"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTruckLoadingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20,16V5a3,3,0,0,1,3-3,1,1,0,0,0,0-2,5.006,5.006,0,0,0-5,5V16.279l-2.734.912a3,3,0,0,0-.156-1l-1.553-5.1A3.007,3.007,0,0,0,9.788,9.142l-6.7,2.13A3.013,3.013,0,0,0,1.129,15l1.634,5.373a2.966,2.966,0,0,0,.457.831l-2.536.845a1,1,0,0,0,.632,1.9L16.1,19.023A4.017,4.017,0,0,0,19.974,24C25.232,23.864,25.255,16.169,20,16ZM4.662,19.748,3.043,14.421A1.006,1.006,0,0,1,3.7,13.178l6.7-2.13a1,1,0,0,1,1.252.638L13.2,16.792l.011.031a1,1,0,0,1-.508,1.221l-6.888,2.3A1,1,0,0,1,4.662,19.748ZM19.974,22a2,2,0,0,1,0-4A2,2,0,0,1,19.974,22ZM10.05,14A1,1,0,0,1,9.4,15.256l-2.464.785a1,1,0,0,1-.606-1.907l2.465-.784A1,1,0,0,1,10.05,14Z" })));
exports.default = TruxwebTruckLoadingIcon;
