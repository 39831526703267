import { EShipmentQuoteRequestStatusV1, TShipperShipmentQuoteRequestV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const updateQuoteRequestStatusForShipper = async (
  carrierQuoteRequestId: number,
  quoteRequestStatus: EShipmentQuoteRequestStatusV1,
  statusChangeReason?: string
): Promise<TShipperShipmentQuoteRequestV1> => {
  const results = await makeRequestToApiGateway(
    'shipmentPatchV1ShipperQuoteRequestByIdByStatus',
    {
      id: carrierQuoteRequestId,
      status: quoteRequestStatus,
    },
    { 'Content-Type': 'application/json' },
    { statusChangeReason }
  );
  const { quoteRequest } = results as Record<string, any>;
  return quoteRequest as TShipperShipmentQuoteRequestV1;
};
