"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    additionalTopPadding: {
        borderTop: `14px solid ${theme.palette.common.white}`,
    },
    button: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        width: '100%',
    },
    card: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        textAlign: 'center',
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
}));
