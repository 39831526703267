// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvsV1 = void 0;
var AvsV1;
(function (AvsV1) {
    AvsV1["Match"] = "Match";
    AvsV1["AddressOnly"] = "AddressOnly";
    AvsV1["ZipOnly"] = "ZipOnly";
    AvsV1["NoMatch"] = "NoMatch";
    AvsV1["NA"] = "NA";
})(AvsV1 || (exports.AvsV1 = AvsV1 = {}));
