// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchQuoteFormStepsV1 = void 0;
var SearchQuoteFormStepsV1;
(function (SearchQuoteFormStepsV1) {
    SearchQuoteFormStepsV1["search"] = "search";
    SearchQuoteFormStepsV1["results"] = "results";
    SearchQuoteFormStepsV1["reservation"] = "reservation";
})(SearchQuoteFormStepsV1 || (exports.SearchQuoteFormStepsV1 = SearchQuoteFormStepsV1 = {}));
