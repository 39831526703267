import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  CARRIERicon: {
    '& .CheckmarkIconWhiteFill_svg__target': {
      fill: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('xs')]: { height: theme.spacing(2), width: theme.spacing(2) },
    [theme.breakpoints.between('sm', 'lg')]: {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
    [theme.breakpoints.up('lg')]: { height: theme.spacing(4), width: theme.spacing(4) },
  },
  SHIPPERicon: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: { height: theme.spacing(2), width: theme.spacing(2) },
    [theme.breakpoints.between('sm', 'lg')]: {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
    [theme.breakpoints.up('lg')]: { height: theme.spacing(4), width: theme.spacing(4) },
  },
  bgCARRIER: {
    backgroundColor: 'transparent',
    height: '100%',
    minHeight: theme.spacing(43),
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('largeMobile')]: {
      maxWidth: '550px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(57),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(81),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(109),
    },
  },
  bgOverlayCARRIER: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  bgOverlaySHIPPER: {
    backgroundColor: theme.palette.primary.dark,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -2,
  },
  bgSHIPPER: {
    backgroundColor: 'transparent',
    height: '100%',
    minHeight: theme.spacing(43),
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('largeMobile')]: {
      maxWidth: '550px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(57),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(81),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(109),
    },
  },
  buttonWrap: {
    position: 'relative',
    zIndex: 8,
    [theme.breakpoints.down('xs')]: { minWidth: '190px' },
  },
  centerRow: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
    },
  },
  chevron: { color: theme.palette.common.white },
  container: {
    height: '100%',
    minHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: theme.spacing(35),
      minHeight: `calc(${theme.spacing(43)}px - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm - 120)]: {
      maxHeight: theme.spacing(38),
      minHeight: `calc(${theme.spacing(45)}px - ${theme.spacing(12)}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(${theme.spacing(45)}px - ${theme.spacing(15)}px)`,
    },
  },
  containerBox: {
    height: '100%',
    minHeight: theme.spacing(43),
    paddingTop: theme.spacing(4),
    zIndex: 3,
    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(46),
      paddingBottom: theme.spacing(7),
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(7.5),
      paddingTop: theme.spacing(7.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(81),
      paddingBottom: theme.spacing(13),
      paddingTop: theme.spacing(16),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(109),
      paddingBottom: theme.spacing(18),
      paddingTop: theme.spacing(21),
    },
  },
  iconWrap: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(0.25),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(3.5),
    },
  },
  imageColumn: { height: '100%' },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  linkWrap: {
    [theme.breakpoints.down('xs')]: { minWidth: '90px' },
  },
  overlayCARRIER: {
    background: 'url(assets/images/landingPage/split-bg-carrier.png) top no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'cover',
    filter: 'grayscale(100%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  overlayColorCARRIER: {
    background: 'linear-gradient(290.05deg, #0087CE 7.07%, rgba(0, 108, 165, 0.05) 61.13%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  overlayColorSHIPPER: {
    background: 'linear-gradient(301.15deg, #061946 -0.9%, rgba(6, 25, 70, 0) 55.71%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  overlaySHIPPER: {
    background: 'url(assets/images/landingPage/shipper-bg.png) top no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'cover',
    filter: 'grayscale(100%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  svgimageCARRIER: {
    direction: 'rtl',
    overflow: 'hidden',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      transform: 'scale(2.8) translateY(-15%) translateX(-27%)',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm - 120)]: {
      transform: 'scale(2.8) translateY(-15%) translateX(-20%)',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      transform: 'scale(2.7) translateY(-15%) translateX(-30%)',
    },
    [theme.breakpoints.up('sm')]: { transform: 'scale(2.7) translateY(-13%) translateX(-20%)' },
    [theme.breakpoints.up('md')]: { transform: 'scale(3) translateY(-13%) translateX(-16%)' },
    [theme.breakpoints.up('lg')]: {},
  },
  svgimageSHIPPER: {
    direction: 'rtl',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1) translateY(0%) translateX(-10%)',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm - 120)]: {
      transform: 'scale(1) translateY(0%) translateX(0%)',
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1) translateY(0%) translateX(0%)',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm + 120)]: {
      transform: 'scale(1.3) translateX(0%)',
    },
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1.5) translateX(-10%)',
    },
    [theme.breakpoints.up('lg')]: {
      transform: ' translateX(-10%)',
    },
    [theme.breakpoints.up('xl')]: {
      transform: ' translateX(0%)',
    },
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(1.75),
      marginTop: theme.spacing(1.75),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(1.75),
      marginTop: theme.spacing(1.75),
    },
  },
  textHeader: {
    color: theme.palette.common.white,
  },
}));
