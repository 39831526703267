import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { Card, LandingPageButton } from '..';
import classnames from 'classnames';
import React from 'react';
import { useLandingPageMarginStyles } from '../../styles';
import { useStyles } from './LandingPageBookADemo.styles';
import { useTranslation } from 'next-i18next';
import Wings from '../../public/assets/images/logos/truxweb-wings-landingpage.svg';

const REQUIRED_NAMESPACES = ['common'];

type TLandingPageBookADemoContent = {
  cta: string;
  link: React.ReactNode;
  linkAction?: () => void;
  isSaving?: boolean;
  prompt: React.ReactNode;
};
type TLandingPageBookADemoProps = {
  content?: TLandingPageBookADemoContent;
  className?: string;
};

export const LandingPageBookADemo = ({
  className,
  content,
}: TLandingPageBookADemoProps): JSX.Element => {
  const classes = useStyles();
  const margins = useLandingPageMarginStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.up('xl'));

  content = content || {
    cta: t('common:readyToTakeControl'),
    link: (
      <a
        className={classes.link}
        href="https://meetings.hubspot.com/max-gravel/demo-general-calendar"
      >
        {t('common:bookADemo')}
      </a>
    ),
    prompt: '',
  };

  return (
    <Card className={classnames(classes.container, margins.landingPageDemoTileMargins, className)}>
      <Wings className={classes.wings} />
      <Grid
        alignItems="center"
        className={classes.wrapper}
        container
        justifyContent={'space-between'}
        wrap={isSmallMobile ? 'wrap' : 'nowrap'}
      >
        <Grid className={classes.textWrap} item>
          <Grid container direction="column">
            <Grid item>
              <Typography className={classes.text} variant={isLarge ? 'h2' : 'h3'}>
                {content.cta}
              </Typography>
            </Grid>
            <Grid item>
              {Boolean(content.prompt) && (
                <Box mt={3}>
                  <Typography
                    className={classes.text}
                    fontStyle="semibold"
                    variant={isLarge ? 'bodyLarge' : 'bodyMedium'}
                  >
                    {content.prompt}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.button} item>
          <Box className={classes.button}>
            <LandingPageButton
              isSaving={content.isSaving}
              onClick={content.linkAction}
              text={content.link}
              type="bookNow"
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
