// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCardTransactionTypeV1 = exports.PaymentTransactionStatusV1 = void 0;
var PaymentTransactionStatusV1;
(function (PaymentTransactionStatusV1) {
    PaymentTransactionStatusV1["Failed"] = "Failed";
    PaymentTransactionStatusV1["Success"] = "Success";
    PaymentTransactionStatusV1["Pending"] = "Pending";
    PaymentTransactionStatusV1["Hold"] = "Hold";
    PaymentTransactionStatusV1["Cancelled"] = "Cancelled";
})(PaymentTransactionStatusV1 || (exports.PaymentTransactionStatusV1 = PaymentTransactionStatusV1 = {}));
var CreditCardTransactionTypeV1;
(function (CreditCardTransactionTypeV1) {
    CreditCardTransactionTypeV1["Capture"] = "Capture";
    CreditCardTransactionTypeV1["PreAuth"] = "PreAuth";
    CreditCardTransactionTypeV1["Refund"] = "Refund";
    CreditCardTransactionTypeV1["Void"] = "Void";
})(CreditCardTransactionTypeV1 || (exports.CreditCardTransactionTypeV1 = CreditCardTransactionTypeV1 = {}));
