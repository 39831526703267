// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CspUserStatusV1 = void 0;
var CspUserStatusV1;
(function (CspUserStatusV1) {
    CspUserStatusV1["Active"] = "ACTIVE";
    CspUserStatusV1["Inactive"] = "INACTIVE";
})(CspUserStatusV1 || (exports.CspUserStatusV1 = CspUserStatusV1 = {}));
