import {
  CarrierEquipmentManagementForm as BaseCarrierEquipmentManagementForm,
  Loading,
} from '@truxweb/common-components';
import { mapEquipmentDataForManagement, objectArrayDiff } from '@truxweb/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAlerts,
  useCarrierData,
  useCarrierEquipmentData,
  useReduxEquipmentData,
} from '../../hooks';
import { DEFAULT_CURRENCY } from '../../config';
import { TCarrierEquipmentV1 } from '@truxweb/schemas';
import { updateEquipment } from '../../actions';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'account'];

type TFormValues = {
  equipment: TCarrierEquipmentV1[];
};

export const CarrierEquipmentManagementForm = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [shouldCarrierEquipmentRefetch, setCarrierEquipmentRefecth] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { data: equipments, hasLoaded: hasEquipmentFetched } = useReduxEquipmentData();
  const [carrierData, hasCarrierDataFetched] = useCarrierData();
  const [carrierEquipment, hasCarrierEquipmentFetched] = useCarrierEquipmentData(
    shouldCarrierEquipmentRefetch
  );

  const { addAlert } = useAlerts();

  const equipmentData: TCarrierEquipmentV1[] = useMemo(() => {
    if (!hasCarrierEquipmentFetched || !hasEquipmentFetched || !hasCarrierDataFetched) return [];

    return mapEquipmentDataForManagement(
      equipments,
      carrierEquipment,
      DEFAULT_CURRENCY,
      carrierData
    );
  }, [
    equipments,
    carrierEquipment,
    hasEquipmentFetched,
    hasCarrierEquipmentFetched,
    carrierData,
    hasCarrierDataFetched,
  ]);

  useEffect(() => {
    if (shouldCarrierEquipmentRefetch) {
      setCarrierEquipmentRefecth(false);
    }
  }, [shouldCarrierEquipmentRefetch, setCarrierEquipmentRefecth]);

  const handleSubmitSuccess = useCallback(
    async (data: TFormValues) => {
      try {
        setIsSaving(true);
        // Force carrier to have at least one item active
        const activeStates = data.equipment.map(({ active }) => active);
        if (!activeStates.includes(true)) {
          addAlert({
            message: t('common:mustHaveAtLeastOneActiveEquipment'),
            severity: 'error',
          });
          return;
        }

        await updateEquipment(
          objectArrayDiff(data.equipment, equipmentData) as TCarrierEquipmentV1[]
        );
        addAlert({
          message: t('common:updateSuccess'),
          severity: 'success',
        });
        setCarrierEquipmentRefecth(true);
      } catch (err) {
        addAlert({
          message: t('common:updateFailed'),
          severity: 'error',
        });
      } finally {
        setIsSaving(false);
      }
      return;
    },
    [addAlert, t, equipmentData, setCarrierEquipmentRefecth, setIsSaving]
  );

  return (
    <>
      <Loading isLoading={!hasCarrierEquipmentFetched} t={t} />

      {hasCarrierEquipmentFetched && (
        <BaseCarrierEquipmentManagementForm
          carrier={carrierData}
          carrierEquipmentData={equipmentData || []}
          handleSubmitSuccess={handleSubmitSuccess}
          isLoading={!hasCarrierEquipmentFetched}
          isSaving={isSaving}
          t={t}
        />
      )}
    </>
  );
};
