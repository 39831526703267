"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGolfClubIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M12.014,24H6a6.006,6.006,0,0,1-6-6V15.162A6,6,0,0,1,7.236,9.291L20.01,11.98,21.881.835a1,1,0,1,1,1.973.331L21.4,15.8a9.335,9.335,0,0,1-.667,2.511A9.518,9.518,0,0,1,12.014,24ZM5.994,11.161a4,4,0,0,0-3.994,4V18a4,4,0,0,0,4,4h6.014A7.518,7.518,0,0,0,18.9,17.508a7.515,7.515,0,0,0,.521-2.01l.259-1.544L6.824,11.248A4.035,4.035,0,0,0,5.994,11.161Z" }),
    React.createElement("path", { d: "M14.517,17.058a1.029,1.029,0,0,1-.206-.021L5.8,15.252A1,1,0,1,1,6.21,13.3l8.511,1.785a1,1,0,0,1-.2,1.978Z" }),
    React.createElement("path", { d: "M10,20.279a.987.987,0,0,1-.208-.022L4.83,19.207a1,1,0,0,1,.414-1.957l4.963,1.05A1,1,0,0,1,10,20.279Z" })));
exports.default = TruxwebGolfClubIcon;
