import { type AppThunk, setShipperQuoteLoading, setShipperQuotesData } from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { type TShipperShipmentQuoteRequestV1 } from '@truxweb/schemas';

export const fetchReduxShipperQuoteRequestById =
  (quoteId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setShipperQuoteLoading({ id: quoteId }));

      const results = await makeRequestToApiGateway('shipmentGetV1ShipperQuoteById', {
        id: quoteId,
      });
      const { quoteRequest } = results as { quoteRequest: TShipperShipmentQuoteRequestV1 };
      dispatch(setShipperQuotesData({ item: quoteRequest }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
