import { Box, Grid, useMediaQuery, useTheme } from '@truxweb/ux';
import { Header, MobileHeader, SignupCarousel } from '../../components';
import React, { useState } from 'react';
import Image from 'next/image';
import { SplitPage } from '../../pageTemplates';
import { TPageId } from '../../types';
import { useRouter } from 'next/router';
import { useStyles } from './SignUp.styles';
import { useTranslation } from 'next-i18next';

type TSignUpProps = {
  children?: React.ReactElement;
  pageId: TPageId;
};

const REQUIRED_NAMESPACES = ['signup'];

export const SignUp = ({ children, pageId }: TSignUpProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { locale } = useRouter();
  const isImageBottom = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)
  );
  const imagePrefix = isImageBottom ? `SmallPreview` : `SitePreview`;
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.largeMobile));
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const [isCarouselActive, setIsCarouselActive] = useState(true);
  const slides = [
    {
      image: `/assets/images/registration/${imagePrefix}ShipperResults-${locale}.png`,
      position: 'top left',
      title: t('signup:tileShipperResults'),
    },
    {
      image: `/assets/images/registration/${imagePrefix}ShipperActive-${locale}.png`,
      position: 'top left',
      title: t('signup:tileActiveRequest'),
    },
    {
      image: `/assets/images/registration/${imagePrefix}ShipperSidePanel-${locale}.png`,
      position: 'top right',
      title: t('signup:tileSidePanel'),
    },
  ].map((slide) => {
    return {
      image: (
        <Box
          borderRadius={theme.spacing(1)}
          className={classes.shadowFilter}
          height={'100%'}
          overflow={'hidden'}
          position="relative"
          width={'100%'}
        >
          <Image
            key={`client-logo-1`}
            layout="fill"
            objectFit="cover"
            objectPosition={slide.position}
            src={slide.image}
            unoptimized
          />
        </Box>
      ),
      title: slide.title,
    };
  });

  if (isMobile) {
    return (
      <>
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            top: '0px',
            width: '100%',
          }}
        >
          <Header isScrolled={false} isTransparent={true} pageId={pageId} shouldHideAppBar />
          <Box
            sx={{
              padding: theme.spacing(3),
            }}
          >
            <MobileHeader isScrolled={false} isTransparent={true} pageId={pageId} />
          </Box>

          {isCarouselActive && (
            <Box bgcolor={theme.palette.primary.light}>
              <SignupCarousel
                isAnimated
                onSkip={() => {
                  setIsCarouselActive(false);
                }}
                slideCollection={slides}
              />
            </Box>
          )}
          {!isCarouselActive && (
            <Grid alignItems="center" container direction="column">
              {children}
            </Grid>
          )}
        </Box>
      </>
    );
  }
  return (
    <>
      <SplitPage
        leftHandSize={8}
        pageId={pageId}
        rightHandContent={
          <Box
            sx={{
              height: isImageBottom ? '130%' : '100%',
              position: 'relative',
              top: isImageBottom && '-90px',
              width: '100%',
            }}
          >
            <SignupCarousel isAnimated slideCollection={slides}></SignupCarousel>
          </Box>
        }
        rightHandSize={4}
      >
        <Grid alignItems="center" container direction="column">
          {children}
        </Grid>
      </SplitPage>
    </>
  );
};
