"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebChartConnectedIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,11.983a3.994,3.994,0,0,0-2.607-3.735l-.038-.091a10.07,10.07,0,0,0-5.294-5.419l-.329-.144a3.981,3.981,0,0,0-7.462,0l-.418.188A10.19,10.19,0,0,0,2.617,8.245a3.98,3.98,0,0,0-.03,7.468l.128.3a10.1,10.1,0,0,0,5.162,5.228l.393.176a3.982,3.982,0,0,0,7.46,0l.367-.163a10.193,10.193,0,0,0,5.309-5.534A4,4,0,0,0,24,11.983ZM12,2a2,2,0,1,1-2,2A2,2,0,0,1,12,2ZM3.988,9.985l.082.031.01-.025a1.912,1.912,0,1,1-.092-.006ZM12,22a2,2,0,1,1,2-2A2,2,0,0,1,12,22Zm3.893-2.886a3.991,3.991,0,0,0-7.787,0A8.093,8.093,0,0,1,4.875,15.88,3.991,3.991,0,0,0,4.9,8.1a8.19,8.19,0,0,1,3.206-3.2,3.991,3.991,0,0,0,7.785,0A8.225,8.225,0,0,1,19.1,8.093a3.991,3.991,0,0,0,.015,7.785A8.207,8.207,0,0,1,15.893,19.114ZM20,13.983a2,2,0,1,1,2-2A2,2,0,0,1,20,13.983Z" })));
exports.default = TruxwebChartConnectedIcon;
