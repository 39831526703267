// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentShipperQuoteStatusV1 = void 0;
var ShipmentShipperQuoteStatusV1;
(function (ShipmentShipperQuoteStatusV1) {
    ShipmentShipperQuoteStatusV1["DECLINED"] = "DECLINED";
    ShipmentShipperQuoteStatusV1["CARRIER_DECLINED"] = "CARRIER_DECLINED";
    ShipmentShipperQuoteStatusV1["CANCELLED"] = "CANCELLED";
    ShipmentShipperQuoteStatusV1["CONFIRMED"] = "CONFIRMED";
    ShipmentShipperQuoteStatusV1["RESPONSE_REQUIRED"] = "RESPONSE_REQUIRED";
    ShipmentShipperQuoteStatusV1["EXPIRED"] = "EXPIRED";
    ShipmentShipperQuoteStatusV1["WAITING_FOR_RESPONSES"] = "WAITING_FOR_RESPONSES";
    ShipmentShipperQuoteStatusV1["NO_CARRIER_RESPONSE"] = "NO_CARRIER_RESPONSE";
})(ShipmentShipperQuoteStatusV1 || (exports.ShipmentShipperQuoteStatusV1 = ShipmentShipperQuoteStatusV1 = {}));
