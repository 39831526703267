// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionBillingFrequencyV1 = void 0;
var SubscriptionBillingFrequencyV1;
(function (SubscriptionBillingFrequencyV1) {
    SubscriptionBillingFrequencyV1["MONTHLY"] = "MONTHLY";
    SubscriptionBillingFrequencyV1["QUARTERLY"] = "QUARTERLY";
    SubscriptionBillingFrequencyV1["BIANNUALLY"] = "BIANNUALLY";
    SubscriptionBillingFrequencyV1["ANNUALLY"] = "ANNUALLY";
})(SubscriptionBillingFrequencyV1 || (exports.SubscriptionBillingFrequencyV1 = SubscriptionBillingFrequencyV1 = {}));
