"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipperAccessorialManagement = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const ShipperAccessorialManagement = ({ accessorialLookup, accessorialMetadataControls, accessorialsControls, availableAccessorials, control, shouldDisplayMetadata, t, }) => {
    const accessorialMetadataFieldLookup = (0, react_1.useMemo)(() => {
        const lookup = {};
        accessorialMetadataControls.fields.forEach((field, index) => {
            const accessorialField = accessorialLookup[field.code];
            lookup[field.code] = {
                component: (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: field, id: `loadDefinition.accessorialMetadataSelections.${index}`, isRequired: true, label: t('common:packagingType'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }, (onChange, value) => {
                        var _a;
                        return (react_1.default.createElement(__1.QuoteRequestAccessorialMetadata, { accessorial: field, customMetadataHandlers: [schemas_1.EAccessorialOptionV1.DANGEROUS_GOODS], isDisabled: false, localizationPrefix: "common:", metadataType: (_a = accessorialField === null || accessorialField === void 0 ? void 0 : accessorialField.metadata) === null || _a === void 0 ? void 0 : _a[0].metadataType, onChange: (event) => {
                                onChange(Object.assign(Object.assign({}, field), { value: event.target.value }));
                            }, t: t, value: value.value, variant: 'outlined' }));
                    }))),
                index,
            };
        });
        return lookup;
    }, [accessorialMetadataControls, control, t, accessorialLookup]);
    const onAdd = (0, react_1.useCallback)(() => {
        var _a, _b, _c;
        const accessorialToAdd = availableAccessorials[0];
        accessorialsControls.append(accessorialToAdd);
        if ((_a = accessorialToAdd.metadata) === null || _a === void 0 ? void 0 : _a.length) {
            if (((_b = accessorialToAdd.metadata) === null || _b === void 0 ? void 0 : _b.length) &&
                !accessorialMetadataFieldLookup[accessorialToAdd.code]) {
                accessorialMetadataControls.append({
                    accessorialId: accessorialToAdd.id,
                    code: accessorialToAdd.code,
                    value: '',
                });
            }
            else if (!((_c = accessorialToAdd.metadata) === null || _c === void 0 ? void 0 : _c.length) &&
                accessorialMetadataFieldLookup[accessorialToAdd.code]) {
                accessorialMetadataControls.remove(accessorialMetadataFieldLookup[accessorialToAdd.code].index);
            }
        }
    }, [
        accessorialsControls,
        availableAccessorials,
        accessorialMetadataControls,
        accessorialMetadataFieldLookup,
    ]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", spacing: 2 },
        accessorialsControls.fields.map((field, index) => {
            return (react_1.default.createElement(__1.ShipperAccessorialManagementItem, { accessorialLookup: accessorialLookup, accessorialMetadataControls: accessorialMetadataControls, accessorialMetadataFieldLookup: accessorialMetadataFieldLookup, accessorialsControls: accessorialsControls, availableAccessorials: availableAccessorials, control: control, field: field, index: index, key: field.id, shouldDisplayMetadata: shouldDisplayMetadata, t: t }));
        }),
        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.StandardButton, { onClick: onAdd },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                        react_1.default.createElement(ux_1.TruxwebAddIcon, null),
                        "\u00A0",
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", style: { fontSize: 16 }, variant: "bodyMedium" }, t('search:addAccessorial'))))))));
};
exports.ShipperAccessorialManagement = ShipperAccessorialManagement;
