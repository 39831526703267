import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  bgContainer: {
    [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(3), width: '100vw' },
  },
  container: {
    marginTop: '4em',
    [theme.breakpoints.up('sm')]: { height: 248, maxWidth: '100%', width: '100%' },
    [theme.breakpoints.up('md')]: { height: 336, maxWidth: '100%', width: '100%' },
    [theme.breakpoints.up('lg')]: { height: 552, maxWidth: '100%', width: '100%' },
    [theme.breakpoints.up('xl')]: { height: 568, maxWidth: '100%', width: '100%' },
  },
  testimonialEric: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      marginTop: '-24px',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-31px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '-36px',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: theme.spacing(13),
      top: theme.spacing(10),
    },
  },
  testimonialMary: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 120)]: {
      display: 'inline',
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      bottom: theme.spacing(9),
      display: 'inline',
      position: 'absolute',
      right: theme.spacing(41),
    },
  },
  testimonialWesley: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: `-24px`,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.up('lg')]: { left: '-117px', position: 'absolute', top: theme.spacing(38) },
    [theme.breakpoints.up('xl')]: { left: '-200px', position: 'absolute', top: theme.spacing(18) },
  },
  testimonialWrapper: {
    background: 'url(/assets/images/landingPage/water-road-bg.png)  no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    maxWidth: `${theme.spacing(101)}px`,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      background: 'url(/assets/images/landingPage/water-road-bg-mobile.png)  no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      marginLeft: '31px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '36px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '117px',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '200px',
    },
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      paddingLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '7%',
      //   marginRight: '5%',
      //   width: '33%',
    },
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: '6%',
    //   marginRight: '2%',
    // },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '6.25%',
      //   marginRight: '2%',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '16.75%',
    },
  },
  textWrap: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '289px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '242px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '358px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '472px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '417px',
    },
  },
}));
