// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserUpdateSubjectV1 = void 0;
var UserUpdateSubjectV1;
(function (UserUpdateSubjectV1) {
    UserUpdateSubjectV1["CarrierSignupComplete"] = "CarrierSignupComplete";
    UserUpdateSubjectV1["CarrierSignupPending"] = "CarrierSignupPending";
    UserUpdateSubjectV1["HubspotCarrierDelinquent"] = "HubspotCarrierDelinquent";
    UserUpdateSubjectV1["HubspotCompanyCreate"] = "HubspotCompanyCreate";
    UserUpdateSubjectV1["HubspotCompanyTicket"] = "HubspotCompanyTicket";
    UserUpdateSubjectV1["HubspotCompanyUpdate"] = "HubspotCompanyUpdate";
    UserUpdateSubjectV1["HubspotContactUpdate"] = "HubspotContactUpdate";
    UserUpdateSubjectV1["HubspotDocumentUpload"] = "HubspotDocumentUpload";
    UserUpdateSubjectV1["HubspotInternalUserCreation"] = "HubspotInternalUserCreation";
    UserUpdateSubjectV1["HubspotP44Delinquent"] = "HubspotP44Delinquent";
    UserUpdateSubjectV1["HubspotRegistration"] = "HubspotRegistration";
    UserUpdateSubjectV1["HubspotShipmentPossiblyDelinquent"] = "HubspotShipmentPossiblyDelinquent";
    UserUpdateSubjectV1["HubspotShipperDelinquent"] = "HubspotShipperDelinquent";
    UserUpdateSubjectV1["HubspotStatusUpdate"] = "HubspotStatusUpdate";
    UserUpdateSubjectV1["ShipperSignupComplete"] = "ShipperSignupComplete";
    UserUpdateSubjectV1["ShipperSignupPending"] = "ShipperSignupPending";
    UserUpdateSubjectV1["ShipperPaymentTermsSubmission"] = "ShipperPaymentTermsSubmission";
    UserUpdateSubjectV1["FUEL_SURCHARGE_ERROR"] = "FUEL_SURCHARGE_ERROR";
    UserUpdateSubjectV1["PAYMENT_PROCESSING_FAILURE"] = "PAYMENT_PROCESSING_FAILURE";
})(UserUpdateSubjectV1 || (exports.UserUpdateSubjectV1 = UserUpdateSubjectV1 = {}));
