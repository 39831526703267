// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentTruxwebAdditionalChargeTypeV1 = void 0;
var ShipmentTruxwebAdditionalChargeTypeV1;
(function (ShipmentTruxwebAdditionalChargeTypeV1) {
    ShipmentTruxwebAdditionalChargeTypeV1["OTHER"] = "OTHER";
    ShipmentTruxwebAdditionalChargeTypeV1["CONCIERGE"] = "CONCIERGE";
})(ShipmentTruxwebAdditionalChargeTypeV1 || (exports.ShipmentTruxwebAdditionalChargeTypeV1 = ShipmentTruxwebAdditionalChargeTypeV1 = {}));
