"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRugbyIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m22.1 1.9c-3.436-3.431-12.728-1.967-17.448 2.752s-6.183 14.012-2.752 17.448a8.106 8.106 0 0 0 5.767 1.88 17.434 17.434 0 0 0 11.681-4.632c4.718-4.719 6.183-14.012 2.752-17.448zm-5.767.1a6.232 6.232 0 0 1 4.351 1.314c1.016 1.017 1.427 2.986 1.275 5.231l-6.5-6.5c.295-.019.591-.045.874-.045zm-13.017 18.684c-1.016-1.015-1.427-2.984-1.275-5.229l6.5 6.5c-2.241.155-4.21-.255-5.225-1.271zm14.618-2.75a14.685 14.685 0 0 1 -6.929 3.657l-8.6-8.6a14.674 14.674 0 0 1 3.661-6.925 14.645 14.645 0 0 1 6.94-3.646l8.586 8.586a14.674 14.674 0 0 1 -3.658 6.928zm-.227-6.227a1 1 0 0 1 -1.414 0l-1.293-1.293-1.586 1.586 1.293 1.293a1 1 0 1 1 -1.414 1.414l-1.293-1.293-1.586 1.586 1.293 1.293a1 1 0 1 1 -1.414 1.414l-4-4a1 1 0 0 1 1.414-1.414l1.293 1.293 1.586-1.586-1.293-1.293a1 1 0 0 1 1.414-1.414l1.293 1.293 1.586-1.586-1.293-1.293a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414z" })));
exports.default = TruxwebRugbyIcon;
