import { TUseActionResponse, useAction } from './useAction';
import { getCarrierProfile } from '../actions';
import { TCarrierProfileRecordV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierProfileData = (
  forceRefetch?: boolean
): TUseActionResponse<TCarrierProfileRecordV1[]> => {
  const action = useCallback(() => {
    return getCarrierProfile();
  }, []);
  return useAction<TCarrierProfileRecordV1[]>(action, true, forceRefetch || false);
};
