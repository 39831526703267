import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  email: {
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
    backgroundColor: theme.palette.common.white,
  },
  explanationButton: {
    position: 'absolute',
    right: theme.spacing(18.5),
    top: theme.spacing(15.5),
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(18.5),
    },
    [theme.breakpoints.down('xs')]: {
      right: 0,
      top: theme.spacing(18.25),
    },
  },
  inputContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '80%',
  },

  label: {
    color: theme.palette.common.white,
  },
  newsletterContainer: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
  },
  newsletterCta: {
    fontSize: theme.spacing(2),
  },
  newsletterSignup: {
    position: 'relative',
  },
  newsletterTitle: {
    fontSize: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(3.5),
    },
  },
  selectContainer: {
    display: 'flex',
    flexGrow: 1,
    marginRight: theme.spacing(2),
    maxWidth: '50%',
  },
  selectInput: {
    width: '100%',
  },
}));
