"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        marginTop: theme.spacing(4.5),
        padding: 0,
        position: 'relative',
    },
    carrierAcceptedText: { color: theme.palette.success.main },
    carrierExpiredText: { color: theme.palette.grey[600] },
    carrierPendingText: { color: theme.palette.secondary.main },
    carrierRefusedText: { color: theme.palette.error.main },
    characterCount: {
        color: theme.palette.primary.light,
    },
    circleCountdown: {
        // Need to account for the border
        margin: `-${theme.spacing(1.5)}px 0 0 -${theme.spacing(1.5)}px`,
    },
    comment: {
        border: 0,
        width: '100%',
    },
    confirmed: {
        color: theme.palette.success.main,
    },
    countdown: {
        backgroundColor: theme.palette.common.white,
        borderColor: 'transparent',
        borderRadius: '100%',
        borderStyle: 'solid',
        borderWidth: theme.spacing(1.5),
        height: theme.spacing(9.5),
        width: theme.spacing(9.5),
    },
    countdownAlternateContent: {
        height: '100%',
    },
    countdownConfirmed: {
        borderColor: theme.palette.success.main,
        fill: theme.palette.success.main,
        stroke: theme.palette.success.main,
        strokeWidth: '1px',
    },
    countdownContainer: {
        position: 'absolute',
        right: 0,
        zIndex: 1,
    },
    countdownRefused: {
        borderColor: theme.palette.error.main,
        fill: theme.palette.error.main,
        stroke: theme.palette.error.main,
        strokeWidth: '1px',
    },
    equipmentImage: {
        width: theme.spacing(9),
    },
    equipmentParams: {
        color: theme.palette.primary.light,
    },
    header: {
        fontWeight: 600,
    },
    otherRequestCount: {
        background: theme.palette.primary.light,
        borderRadius: '100%',
        color: theme.palette.common.white,
        fontWeight: 600,
        height: theme.spacing(3),
        lineHeight: `${theme.spacing(3)}px`,
        textAlign: 'center',
        width: theme.spacing(3),
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    refused: {
        color: theme.palette.error.main,
    },
    shipperPendingText: { color: theme.palette.primary.light },
}));
