"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListItemStyles = exports.useListStyles = void 0;
const __1 = require("..");
exports.useListStyles = (0, __1.makeStyles)((theme) => ({
    root: {
        '& :hover': {
            backgroundColor: 'transparent  !important',
            color: `${theme.palette.grey[600]} !important`,
        },
        margin: 0,
        padding: 0,
    },
}));
exports.useListItemStyles = (0, __1.makeStyles)(() => ({
    root: {
        margin: 0,
        padding: 0,
        textAlign: 'center',
    },
}));
