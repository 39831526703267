"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebChessPieceIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m20.025 22.026h-1v-1.655a4.014 4.014 0 0 0 -1.225-2.871c-1.924-1.859-2.709-3.989-2.766-7.476h.988a1 1 0 0 0 0-2h-.556a3.949 3.949 0 0 0 .556-2c0-1.375-1.162-3.717-1.893-4.87a2.5 2.5 0 0 0 -4.212 0c-.732 1.154-1.894 3.5-1.894 4.871a3.959 3.959 0 0 0 .556 2h-.553a1 1 0 0 0 0 2h.989c-.058 3.487-.844 5.616-2.766 7.475a4.019 4.019 0 0 0 -1.223 2.87v1.655h-1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2zm-8.417-19.8a.5.5 0 0 1 .835 0 10.752 10.752 0 0 1 1.582 3.8 2 2 0 1 1 -4 0 10.763 10.763 0 0 1 1.583-3.799zm1.43 7.8c.054 3.516.8 5.951 2.522 8h-7.069c1.72-2.049 2.468-4.485 2.523-8zm-6.012 10.345a1.905 1.905 0 0 1 .036-.345h9.928a2 2 0 0 1 .035.345v1.655h-10z" })));
exports.default = TruxwebChessPieceIcon;
