import { makeStyles, Theme } from '@truxweb/ux';

type TStyleVars = {
  isPasswordEdit?: boolean;
};

export const useStyles = makeStyles<Theme, TStyleVars>((theme: Theme) => ({
  dialogContainer: {
    maxWidth: theme.spacing(125),
    width: '100%',
  },
  passwordResetForm: {
    background: ({ isPasswordEdit }) => {
      if (!isPasswordEdit) return 'transparent';
      return theme.palette.grey[100];
    },
    border: ({ isPasswordEdit }) => {
      if (!isPasswordEdit) return 'unset';
      return `2px solid ${theme.palette.primary.light}`;
    },
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
  submitButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: theme.spacing(5),
    padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px !important`,
  },
  ul: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
  verifiedIcon: {
    display: 'flex',
    fill: theme.palette.success.main,
  },
  verifyButton: {
    background: 'none!important',
    color: `${theme.palette.primary.light} !important`,
    padding: `${theme.spacing(0)} !important`,
  },
  verifyFont: {
    color: `${theme.palette.primary.light} !important`,
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));
