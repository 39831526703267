"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebIncognitoIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16.5,17a3.5,3.5,0,0,0-3.235,2.174,4.685,4.685,0,0,0-2.53,0,3.494,3.494,0,1,0,.193,2.033A3.52,3.52,0,0,1,12,21a3.514,3.514,0,0,1,1.072.207A3.5,3.5,0,1,0,16.5,17Zm-9,5A1.5,1.5,0,1,1,9,20.5,1.5,1.5,0,0,1,7.5,22Zm9,0A1.5,1.5,0,1,1,18,20.5,1.5,1.5,0,0,1,16.5,22ZM19.975,8.675C19.709.612,15.3.026,15.105.006A.924.924,0,0,0,15,0a4.509,4.509,0,0,0-2.416.818,1.018,1.018,0,0,1-1.167,0A4.514,4.514,0,0,0,9,0,.924.924,0,0,0,8.9.006c-.194.02-4.6.606-4.87,8.669C2.693,9.122,0,10.243,0,12c0,3.69,6.194,4,12,4s12-.31,12-4C24,10.243,21.307,9.122,19.975,8.675ZM9.055,2.005a2.632,2.632,0,0,1,1.215.452,3.026,3.026,0,0,0,3.461,0,2.64,2.64,0,0,1,1.206-.452c.445.106,2.909.964,3.056,6.858A13.207,13.207,0,0,1,12,10,13.217,13.217,0,0,1,6.007,8.864C6.155,2.92,8.658,2.1,9.055,2.005ZM12,14C3.735,14,2,12.912,1.988,12.082a6.04,6.04,0,0,1,2.86-1.574A14.557,14.557,0,0,0,12,12a14.557,14.557,0,0,0,7.152-1.492A6.829,6.829,0,0,1,22,12C22,12.912,20.265,14,12,14Z" })));
exports.default = TruxwebIncognitoIcon;
