import {
  EPriceTypeV1,
  EZoneShapeTypeV1,
  TAddressV1,
  TCarrierLaneEquipmentV1,
  TCarrierLaneV1,
  TCarrierLocationV1,
  TCarrierZoneV1,
  TLatLngV1,
  TOverlayItemV1,
} from '@truxweb/schemas';

type TLocationFormData = {
  address: TAddressV1;
  carrierId: number;
  centerCoords: TLatLngV1;
  locationName: string;
  shapes: TOverlayItemV1[];
  shouldIgnoreZones: boolean;
};

type TMapShapeToZone = {
  carrierId: number;
  centerCoords: TLatLngV1;
  city: string;
  overlays: TOverlayItemV1[];
  province: string;
};

export const convertFormDataToCarrierLaneV1 = async (
  formData: Record<string, any>,
  carrierId: number
): Promise<TCarrierLaneV1> => {
  const { active, bidirectionalService, comments, destination, equipment, origin, schedule } =
    formData;

  return {
    active,
    bidirectionalService,
    carrierId,
    carrierReference: formData.carrierReference,
    comments,
    destination,
    equipment: convertLaneEquipment(equipment),
    id: formData.recordId ? parseInt(formData.recordId, 10) : null,
    origin,
    serviceSchedule: schedule,
  };
};

export const convertLaneEquipment = (
  equipment: TCarrierLaneEquipmentV1[]
): TCarrierLaneEquipmentV1[] => {
  return equipment
    .filter((equip) => {
      return equip.active === true;
    })
    .map((equip) => {
      // Acting upon this value in a form will convert it to a string
      equip.minimumFlatRate.value = parseFloat(`${equip.minimumFlatRate.value}`);
      equip.ratePerDistance.value = parseFloat(`${equip.ratePerDistance.value}`);
      return equip;
    });
};

export const convertFormDataToCarrierLaneEquipmentV1 = (
  formData: Record<string, any>
): TCarrierLaneEquipmentV1 => {
  return {
    active: formData.active,
    carrierEquipmentId: parseInt(formData.carrierEquipmentId, 10),
    carrierLaneId: parseInt(formData.laneId, 10),
    comments: formData.comments || null,
    equipmentCode: formData.equipmentCode,
    equipmentId: parseInt(formData.equipmentId, 10),
    id: formData.recordId ? parseInt(formData.recordId, 10) : null,
    minimumFlatRate: {
      currency: formData.currency,
      isBillable: true,
      type: EPriceTypeV1.CURRENCY,
      value: formData.minimumFlatRate,
    },
    ratePerDistance: {
      currency: formData.currency,
      isBillable: true,
      type: EPriceTypeV1.CURRENCY,
      value: formData.ratePerDistance,
    },
  };
};
export const transformLocationFormDataToCarrierLocationV1 = ({
  address,
  carrierId,
  centerCoords,
  locationName,
  shapes,
  shouldIgnoreZones,
}: TLocationFormData): TCarrierLocationV1 => {
  return {
    carrierId,
    city: address.city,
    country: address.country,
    name: locationName,
    postalCode: address.postalCode,
    province: address.province,
    shouldIgnoreZones,
    // If we are ignoring zones, we still need to store the center point
    // for this location, so we store it as a circle of zero size with
    // the coordinates of the center
    zones: shouldIgnoreZones
      ? [
          {
            carrierId,
            centerCoords,
            name: `${address.province}, ${address.country}`,
            radiusInMeters: 0,
            shape: EZoneShapeTypeV1.CIRCLE,
            zoneCoordinates: [],
          },
        ]
      : transformMapShapesToCarrierZoneV1({
          carrierId,
          centerCoords,
          city: address.city,
          overlays: shapes,
          province: address.province,
        }),
  };
};

export const transformMapShapesToCarrierZoneV1 = ({
  carrierId,
  centerCoords,
  city,
  overlays,
  province,
}: TMapShapeToZone): TCarrierZoneV1[] => {
  return overlays
    .filter((shape: TOverlayItemV1) => {
      return shape.overlay.getMap() !== null;
    })
    .map((shape: TOverlayItemV1, index: number) => {
      let zone: TCarrierZoneV1 | null = null;
      switch (shape.type) {
        case 'circle':
          zone = {
            carrierId,
            centerCoords,
            name: `${city}, ${province} Zone ${index + 1}`,
            radiusInMeters: shape.overlay.getRadius(),
            shape: EZoneShapeTypeV1.CIRCLE,
            zoneCoordinates: [
              {
                coords: {
                  latitude: shape.overlay.getCenter().lat(),
                  longitude: shape.overlay.getCenter().lng(),
                },
              },
            ],
          };
          return zone;
        case 'polygon':
          zone = {
            // FIXME: This assumes that google maps will be available whenever this function is
            // called. For the time being this is a safe assumption, but this feels fragile
            areaInMetersSquared: window.google.maps.geometry.spherical.computeArea(
              shape.overlay.getPath()
            ),
            carrierId,
            centerCoords,
            name: `${city}, ${province} Zone ${index + 1}`,
            shape: EZoneShapeTypeV1.POLYGON,
            zoneCoordinates: shape.overlay
              .getPath()
              .getArray()
              .map((pathArray: { lat: () => number; lng: () => number }) => {
                return {
                  coords: {
                    latitude: pathArray.lat(),
                    longitude: pathArray.lng(),
                  },
                };
              }),
          };
          return zone;
        default:
          return zone;
      }
    })
    .filter((zone) => {
      return zone !== null;
    });
};
