"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateDefaultChargeForRecipient = void 0;
const schemas_1 = require("@truxweb/schemas");
const DEFAULT_ADDITIONAL_CHARGE = {
    charge: {
        //NOTE: The charge's currency is determined on the BE
        type: schemas_1.EPriceTypeV1.CURRENCY,
        value: 0,
    },
    chargeReference: schemas_1.EShipmentAdditionalChargeTypeV1.ADDITIONAL_APPOINTMENTS,
    quantity: 1,
    recipient: schemas_1.EUserTypeV1.CARRIER,
};
const generateDefaultChargeForRecipient = (recipient) => {
    return Object.assign(Object.assign({}, DEFAULT_ADDITIONAL_CHARGE), { chargeReference: recipient === schemas_1.EUserTypeV1.TRUXWEB
            ? schemas_1.EShipmentTruxwebAdditionalChargeTypeV1.CONCIERGE
            : DEFAULT_ADDITIONAL_CHARGE.chargeReference, recipient });
};
exports.generateDefaultChargeForRecipient = generateDefaultChargeForRecipient;
