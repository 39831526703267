"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
}));
