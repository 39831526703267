import { type AppState, baseLoadingState, type TCompanyLocation } from '../../stores';
import { type TReducerLoading } from '../../types';

export const selectCompanyLocations = (): ((
  state: AppState
) => TReducerLoading & { data: TCompanyLocation[] }) => {
  return (state: AppState) => {
    const listLoadingState = state?.companyLocations?.companyLocationList || baseLoadingState;
    if (!listLoadingState.hasLoaded) return { ...listLoadingState, data: [] };
    const data = Object.values(state?.companyLocations?.companyLocations);
    return { ...listLoadingState, data };
  };
};
