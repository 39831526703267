"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestLocations = void 0;
const utils_1 = require("@truxweb/utils");
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const QuoteRequestLocations_styles_1 = require("./QuoteRequestLocations.styles");
const QuoteRequestLocations = ({ companyLocationState, destination, handleRouteToLocation, origin, t, userData, }) => {
    const classes = (0, QuoteRequestLocations_styles_1.useStyles)();
    const originAddress = (0, utils_1.addressFromShipmentQuoteLocation)(userData, origin);
    const [isAddressBookInformationShown, setAddressBookInformationShown] = (0, react_1.useState)(false);
    const destinationAddress = (0, utils_1.addressFromShipmentQuoteLocation)(userData, destination);
    let originDisplay = react_1.default.createElement(ux_1.Typography, null, (0, utils_1.addressToDisplayString)(originAddress));
    let destinationDisplay = react_1.default.createElement(ux_1.Typography, null, (0, utils_1.addressToDisplayString)(destinationAddress));
    const newLocations = Object.values(companyLocationState || {});
    const newLocationDisplay = newLocations.includes(true) ? (react_1.default.createElement(ux_1.Box, { mb: 1, p: 2, style: {
            background: '#eaeaea',
            borderRadius: 16,
        } },
        react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, justifyContent: "flex-start" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mr: 2 },
                    react_1.default.createElement("button", { onClick: () => {
                            setAddressBookInformationShown(!isAddressBookInformationShown);
                        }, style: {
                            background: 'transparent',
                            border: 0,
                            display: 'inline',
                        } },
                        react_1.default.createElement(ux_1.TruxwebInfoIcon, null)))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", component: "span", fontStyle: "semibold" }, t('common:newCompanyLocationsOne')))),
        react_1.default.createElement(ux_1.Collapse, { in: isAddressBookInformationShown },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Typography, null, t('common:newCompanyLocationsTwo')),
                react_1.default.createElement("br", null),
                react_1.default.createElement(ux_1.Typography, null, t('common:newCompanyLocationsThree')))))) : null;
    const newDot = (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { style: {
                backgroundColor: 'green',
                borderRadius: 8,
                display: 'inline-block',
                height: 8,
                width: 8,
            } }),
        "\u00A0"));
    if (handleRouteToLocation) {
        if (origin.sourceLocationId) {
            originDisplay = (react_1.default.createElement("button", { className: classes.locationButton, onClick: () => handleRouteToLocation(origin), title: t('common:manageInAddressBook') },
                (companyLocationState === null || companyLocationState === void 0 ? void 0 : companyLocationState[schemas_1.EShipmentLocationTypeV1.ORIGIN]) ? newDot : null,
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", component: "span" }, (0, utils_1.addressToDisplayString)(originAddress))));
        }
        if (destination.sourceLocationId) {
            destinationDisplay = (react_1.default.createElement("button", { className: classes.locationButton, onClick: () => handleRouteToLocation(destination), title: t('common:manageInAddressBook') },
                (companyLocationState === null || companyLocationState === void 0 ? void 0 : companyLocationState[schemas_1.EShipmentLocationTypeV1.DESTINATION]) ? newDot : null,
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", component: "span" }, (0, utils_1.addressToDisplayString)(destinationAddress))));
        }
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Box, { mb: 1 },
            react_1.default.createElement(ux_1.Typography, { className: classes.header }, t('common:route'))),
        newLocationDisplay,
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 },
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", className: classes.locationContainer, container: true, direction: "column", justifyContent: "flex-start" },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { className: classes.locationDot, mt: 0.5 })),
                    react_1.default.createElement(ux_1.Grid, { className: classes.locationConnector, item: true, xs: true }),
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column", item: true, justifyContent: "flex-start" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { className: classes.locationDot, mb: 0.5 }))))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 11 },
                react_1.default.createElement(ux_1.Box, { ml: 3, pr: 1 },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "column", justifyContent: "flex-start" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mb: 1.5 }, originDisplay)),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mt: 1.5 }, destinationDisplay))))))));
};
exports.QuoteRequestLocations = QuoteRequestLocations;
