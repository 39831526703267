"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaneMap = void 0;
/* eslint-disable max-lines */
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const mapUtils_1 = require("../../mapUtils");
const constants_1 = require("../../constants");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
// FIXME: try to clean up and/or abstract some of the logic here
// eslint-disable-next-line complexity
const LaneMap = ({ addresses, addressNames, applicationTheme, destination, geocodeByAddress, geocodeByPlaceId, googleMapsApiKey, handleCancelEdits, handleError, handleHideShapes, handleSetAddress, handleSetAddressNames, handleSetLocationCoords, handleSetLocationMode, handleSetLocationShapes, handleSetLocationZone, hasDestinationError, hasOriginError, height, isInEditMode, laneId, language, locationCoords, locationModes, locationShapes, mapTheme, origin, setDirty, setShouldReset, shouldReset, t, width, zones, }) => {
    const theme = (0, ux_1.useTheme)();
    const [hasMapInitialized, setMapInitialized] = (0, react_1.useState)(false);
    const [isFullscreen, setFullscreen] = (0, react_1.useState)(false);
    const [shouldRestoreMapState, setShouldRestoreMapState] = (0, react_1.useState)(false);
    const [haveCoordsChanged, setHaveCoordsChanged] = (0, react_1.useState)([
        false,
        null,
    ]);
    const [controlIndex, setControlIndex] = (0, react_1.useState)(null);
    const [locationType, setLocationType] = (0, react_1.useState)('origin');
    const [mapCenter, setMapCenter] = (0, react_1.useState)(constants_1.DEFAULT_MAP_CENTER);
    const [directionArrow, setDirectionArrow] = (0, react_1.useState)(null);
    const [drawingManager, setDrawingManager] = (0, react_1.useState)(null);
    const [drawingMode, setDrawingMode] = (0, react_1.useState)(null);
    const [mapZoom, setMapZoom] = (0, react_1.useState)(constants_1.DEFAULT_MAP_ZOOM);
    const [googleMapsApiReference, setGoogleMapsApiReference] = (0, react_1.useState)({
        mapReference: null,
        mapsApiReference: null,
    });
    const handleShowRoute = (0, react_1.useCallback)((originCoords, destinationCoords) => {
        if (destinationCoords &&
            originCoords &&
            (0, mapUtils_1.validateLatLng)(destinationCoords) &&
            (0, mapUtils_1.validateLatLng)(originCoords)) {
            if (directionArrow) {
                directionArrow.setMap(null);
            }
            const arrow = (0, mapUtils_1.showRouteOnMap)(Object.assign({ destination: destinationCoords, origin: originCoords }, googleMapsApiReference));
            setDirectionArrow(arrow);
        }
    }, [googleMapsApiReference, directionArrow, setDirectionArrow]);
    const handleSetCenter = (0, react_1.useCallback)((event, locationType) => {
        if (event.type === 'polygon') {
            const coords = event.overlay
                .getPath()
                .getArray()
                .map((pathArray) => {
                return {
                    latitude: pathArray.lat(),
                    longitude: pathArray.lng(),
                };
            });
            if (googleMapsApiReference.mapsApiReference) {
                const center = (0, mapUtils_1.getCenterPointOfPolygon)(googleMapsApiReference.mapsApiReference, coords);
                handleSetLocationCoords(Object.assign(Object.assign({}, locationCoords), { [locationType]: center, mapTheme }));
            }
        }
    }, [googleMapsApiReference, locationCoords, handleSetLocationCoords, mapTheme]);
    const handleCenterChange = (0, react_1.useCallback)((coords) => {
        if (coords) {
            setMapCenter(coords);
            setMapZoom(8);
        }
    }, [setMapCenter, setMapZoom]);
    const getAddressFromCoordinates = (0, react_1.useCallback)((coords, locationType) => __awaiter(void 0, void 0, void 0, function* () {
        if (locationType) {
            const [addressName, address] = yield (0, mapUtils_1.reverseGeocode)(coords);
            handleSetAddressNames(Object.assign(Object.assign({}, addressNames), { [locationType]: addressName }));
            handleSetAddress(Object.assign(Object.assign({}, addresses), { [locationType]: address }));
        }
    }), [handleSetAddress, addresses, handleSetAddressNames, addressNames]);
    const handleMarkerEvent = (0, react_1.useCallback)((event, locationType) => {
        const newCoords = {
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng(),
        };
        handleSetLocationCoords(Object.assign(Object.assign({}, locationCoords), { [locationType]: newCoords, mapTheme }));
        handleCenterChange(newCoords);
        getAddressFromCoordinates(newCoords, locationType);
        setHaveCoordsChanged([true, locationType]);
    }, [
        mapTheme,
        locationCoords,
        handleSetLocationCoords,
        setHaveCoordsChanged,
        handleCenterChange,
        getAddressFromCoordinates,
    ]);
    const attachEventToPolygonPath = (0, react_1.useCallback)((path) => {
        var _a;
        (_a = googleMapsApiReference.mapsApiReference) === null || _a === void 0 ? void 0 : _a.event.clearInstanceListeners(path);
        path.addListener('remove_at', () => {
            setDirty();
        });
        path.addListener('insert_at', () => {
            setDirty();
        });
        path.addListener('set_at', () => {
            setDirty();
        });
    }, [setDirty, googleMapsApiReference]);
    const attachEventsToMapShapes = (0, react_1.useCallback)((shapes) => {
        shapes.forEach((shape) => {
            var _a;
            (_a = googleMapsApiReference.mapsApiReference) === null || _a === void 0 ? void 0 : _a.event.clearInstanceListeners(shape.overlay);
            switch (shape.type) {
                case 'circle':
                    shape.overlay.addListener('radius_changed', () => {
                        setDirty();
                    });
                    shape.overlay.addListener('center_changed', () => {
                        setDirty();
                    });
                    shape.overlay.addListener('dragend', () => {
                        setDirty();
                    });
                    shape.overlay.addListener('radius_changed', () => {
                        setDirty();
                    });
                    break;
                case 'polygon':
                    shape.overlay.getPaths().forEach(attachEventToPolygonPath);
                    break;
                case 'marker':
                    shape.overlay.addListener('dragend', (event) => {
                        handleMarkerEvent(event, shape.locationType);
                        setDirty();
                    });
                    break;
                default:
                    break;
            }
        });
    }, [attachEventToPolygonPath, setDirty, handleMarkerEvent, googleMapsApiReference]);
    const handleMapShapes = (0, react_1.useCallback)((event) => {
        var _a;
        if (!locationType)
            return;
        const shapes = locationShapes[locationType].slice();
        const centerCoords = locationCoords[locationType];
        shapes.push(Object.assign(Object.assign({}, event), { index: shapes.length, locationType }));
        if (!centerCoords) {
            handleSetCenter(event, locationType);
        }
        // NOTE: This is the equivalent of setting the center point
        // of a custom regions
        if (event.type === 'marker') {
            event.overlay.setOptions({
                draggable: true,
                editable: true,
            });
            const newPosition = {
                latitude: event.overlay.position.lat(),
                longitude: event.overlay.position.lng(),
            };
            getAddressFromCoordinates(newPosition, locationType);
            handleShowRoute(locationCoords.origin, locationCoords.destination);
            handleSetLocationCoords(Object.assign(Object.assign({}, locationCoords), { [locationType]: newPosition, mapTheme }));
            setMapInitialized(false);
        }
        handleSetLocationShapes(Object.assign(Object.assign({}, locationShapes), { [locationType]: shapes }));
        attachEventsToMapShapes(shapes);
        // Whenever a shape is completed on the map
        // disable the drawing tools so that the shape
        // can be manipulated
        setDrawingMode(null);
        (_a = googleMapsApiReference.mapReference) === null || _a === void 0 ? void 0 : _a.setOptions({ draggableCursor: '' });
    }, [
        mapTheme,
        attachEventsToMapShapes,
        locationCoords,
        handleSetLocationCoords,
        getAddressFromCoordinates,
        googleMapsApiReference,
        handleShowRoute,
        handleSetCenter,
        setMapInitialized,
        locationType,
        locationShapes,
        handleSetLocationShapes,
    ]);
    const handleSectionExpansion = (0, react_1.useCallback)(
    // eslint-disable-next-line complexity
    (section, locationMode) => {
        if (!section)
            return;
        const { mapReference } = googleMapsApiReference;
        const shapes = locationShapes[section];
        const cursor = section === 'origin' ? 'pinBlueCursor' : 'pinYellowCursor';
        const fillColor = section === 'origin'
            ? applicationTheme.palette.primary.light
            : applicationTheme.palette.secondary.main;
        const coords = locationCoords[section];
        if (section) {
            if (coords) {
                setMapCenter(coords);
                mapReference === null || mapReference === void 0 ? void 0 : mapReference.setOptions({ draggableCursor: '' });
                const boundsShapes = shapes.filter((shape) => {
                    return shape.type !== 'bounds';
                }).length;
                setDrawingMode(locationMode === 'custom' && !boundsShapes
                    ? google.maps.drawing.OverlayType.CIRCLE
                    : null);
            }
            else if (locationMode === 'custom') {
                mapReference === null || mapReference === void 0 ? void 0 : mapReference.setOptions({
                    draggableCursor: `url(/assets/images/icons/${cursor}.png) 8 24, auto`,
                });
                setDrawingMode(google.maps.drawing.OverlayType.MARKER);
            }
        }
        else {
            setDrawingMode(null);
            mapReference === null || mapReference === void 0 ? void 0 : mapReference.setOptions({ draggableCursor: '' });
        }
        setLocationType(section);
        drawingManager === null || drawingManager === void 0 ? void 0 : drawingManager.setOptions({ circleOptions: { fillColor } });
        drawingManager === null || drawingManager === void 0 ? void 0 : drawingManager.setOptions({ polygonOptions: { fillColor } });
        drawingManager === null || drawingManager === void 0 ? void 0 : drawingManager.setOptions({
            markerOptions: {
                icon: `/assets/images/icons/${cursor}.png`,
            },
        });
    }, [
        applicationTheme,
        setMapCenter,
        setDrawingMode,
        drawingManager,
        googleMapsApiReference,
        locationCoords,
        locationShapes,
    ]);
    const handleCreateNewMarker = (0, react_1.useCallback)((coords) => {
        const cursor = locationType === 'origin' ? 'pinBlueCursor' : 'pinYellowCursor';
        const { mapReference, mapsApiReference } = googleMapsApiReference;
        if (!mapsApiReference)
            return null;
        return new mapsApiReference.Marker({
            animation: mapsApiReference.Animation.DROP,
            draggable: false,
            icon: `/assets/images/icons/${cursor}.png`,
            map: mapReference,
            position: { lat: coords.latitude, lng: coords.longitude },
            zIndex: 3,
        });
    }, [googleMapsApiReference, locationType]);
    const handleRestoreMapShapes = (0, react_1.useCallback)(() => {
        const { mapReference, mapsApiReference } = googleMapsApiReference;
        const handleRestoreShape = (zone, index, color, locationType) => {
            let shape = null;
            if (!(zone === null || zone === void 0 ? void 0 : zone.shape))
                return null;
            if (zone.shape === schemas_1.EZoneShapeTypeV1.CIRCLE && mapsApiReference) {
                shape = new mapsApiReference.Circle({
                    center: {
                        lat: zone.centerCoords.latitude,
                        lng: zone.centerCoords.longitude,
                    },
                    fillColor: color,
                    map: mapReference,
                    radius: zone.radiusInMeters || 0,
                    strokeWeight: 0,
                });
            }
            else if (zone.shape === schemas_1.EZoneShapeTypeV1.POLYGON && mapsApiReference) {
                shape = new mapsApiReference.Polygon({
                    fillColor: color,
                    map: mapReference,
                    paths: zone.zoneCoordinates.map((coord) => {
                        return {
                            lat: coord.coords.latitude,
                            lng: coord.coords.longitude,
                        };
                    }),
                    strokeWeight: 0,
                });
            }
            return {
                index,
                locationType,
                overlay: shape,
                type: zone.shape.toLowerCase(),
            };
        };
        const restoreLocation = (location, locationType) => {
            var _a;
            const locationCursor = locationType === 'origin' ? 'pinBlueCursor' : 'pinYellowCursor';
            const locationColor = locationType === 'origin'
                ? applicationTheme.palette.primary.light
                : applicationTheme.palette.secondary.main;
            const locationShapes = !(location === null || location === void 0 ? void 0 : location.shouldIgnoreZones)
                ? location.zones
                    .map((zone, index) => {
                    return handleRestoreShape(zone, index, locationColor, locationType);
                })
                    .filter((item) => {
                    return item !== null;
                })
                : [];
            const shapes = [...locationShapes];
            const markerCoords = (_a = location.zones[0]) === null || _a === void 0 ? void 0 : _a.centerCoords;
            if (markerCoords && (0, mapUtils_1.validateLatLng)(markerCoords)) {
                if (mapsApiReference) {
                    const marker = {
                        index: location === null || location === void 0 ? void 0 : location.zones.length,
                        locationType,
                        overlay: new mapsApiReference.Marker({
                            animation: mapsApiReference.Animation.DROP,
                            draggable: false,
                            icon: `/assets/images/icons/${locationCursor}.png`,
                            map: mapReference,
                            position: { lat: markerCoords.latitude, lng: markerCoords.longitude },
                            zIndex: 3,
                        }),
                        type: 'marker',
                    };
                    marker.overlay.addListener('dragend', (event) => {
                        handleMarkerEvent(event, locationType);
                        setDirty();
                    });
                    shapes.push(marker);
                }
            }
            attachEventsToMapShapes(shapes);
            return shapes;
        };
        let originShapes = [];
        let destinationShapes = [];
        if (origin) {
            originShapes = restoreLocation(origin, 'origin');
        }
        if (destination) {
            destinationShapes = restoreLocation(destination, 'destination');
        }
        handleSetLocationShapes({
            destination: [...destinationShapes],
            origin: [...originShapes],
        });
    }, [
        applicationTheme,
        attachEventsToMapShapes,
        setDirty,
        handleMarkerEvent,
        handleSetLocationShapes,
        googleMapsApiReference,
        origin,
        destination,
    ]);
    // eslint-disable-next-line complexity
    const handleRestoreMapState = (0, react_1.useCallback)(() => {
        if (shouldRestoreMapState) {
            handleRestoreMapShapes();
            // Restore map settings
            const originLocationMode = (origin === null || origin === void 0 ? void 0 : origin.shouldIgnoreZones) ? 'geopolitical' : 'custom';
            const destinationLocationMode = (destination === null || destination === void 0 ? void 0 : destination.shouldIgnoreZones) ? 'geopolitical' : 'custom';
            handleSetLocationMode({ destination: destinationLocationMode, origin: originLocationMode });
            handleSetAddressNames({
                destination: (destination === null || destination === void 0 ? void 0 : destination.name) || '',
                origin: (origin === null || origin === void 0 ? void 0 : origin.name) || '',
            });
            handleSetAddress({
                destination: {
                    addressLineOne: '',
                    city: (destination === null || destination === void 0 ? void 0 : destination.city) || '',
                    country: destination === null || destination === void 0 ? void 0 : destination.country,
                    email: '',
                    name: '',
                    phone: '',
                    postalCode: (destination === null || destination === void 0 ? void 0 : destination.postalCode) || '',
                    province: (destination === null || destination === void 0 ? void 0 : destination.province) || '',
                },
                origin: {
                    addressLineOne: '',
                    city: (origin === null || origin === void 0 ? void 0 : origin.city) || '',
                    country: origin === null || origin === void 0 ? void 0 : origin.country,
                    email: '',
                    name: '',
                    phone: '',
                    postalCode: (origin === null || origin === void 0 ? void 0 : origin.postalCode) || '',
                    province: (origin === null || origin === void 0 ? void 0 : origin.province) || '',
                },
            });
            handleSetLocationCoords({
                destination: (destination === null || destination === void 0 ? void 0 : destination.zones.length) ? destination === null || destination === void 0 ? void 0 : destination.zones[0].centerCoords : null,
                mapTheme,
                origin: (origin === null || origin === void 0 ? void 0 : origin.zones.length) ? origin === null || origin === void 0 ? void 0 : origin.zones[0].centerCoords : null,
            });
            if ((origin === null || origin === void 0 ? void 0 : origin.zones.length) && (destination === null || destination === void 0 ? void 0 : destination.zones.length)) {
                handleShowRoute(origin === null || origin === void 0 ? void 0 : origin.zones[0].centerCoords, destination === null || destination === void 0 ? void 0 : destination.zones[0].centerCoords);
            }
            setShouldRestoreMapState(false);
        }
    }, [
        handleSetAddressNames,
        setShouldRestoreMapState,
        shouldRestoreMapState,
        handleRestoreMapShapes,
        handleShowRoute,
        origin,
        handleSetAddress,
        destination,
        handleSetLocationMode,
        handleSetLocationCoords,
        mapTheme,
    ]);
    (0, react_1.useEffect)(() => {
        if (!hasMapInitialized) {
            const { mapReference, mapsApiReference } = googleMapsApiReference;
            if (mapsApiReference && mapReference) {
                if (!drawingManager) {
                    const drawingManagerInstance = (0, mapUtils_1.enableMapDrawingTools)(mapReference, mapsApiReference, locationType ? locationType : 'origin', applicationTheme);
                    setDrawingManager(drawingManagerInstance);
                }
                setMapInitialized(true);
                setShouldRestoreMapState(true);
            }
        }
    }, [
        applicationTheme,
        handleShowRoute,
        setShouldRestoreMapState,
        googleMapsApiReference,
        drawingManager,
        setDrawingManager,
        locationType,
        setMapInitialized,
        hasMapInitialized,
    ]);
    // If there is existing data to be shown on the map, restore ti
    (0, react_1.useEffect)(() => {
        if ((origin || destination) && hasMapInitialized) {
            handleRestoreMapState();
        }
    }, [origin, destination, hasMapInitialized, handleRestoreMapState]);
    // Attaches the events for the drawing manager
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (drawingManager) {
            if (locationType && drawingMode) {
                (_a = googleMapsApiReference.mapsApiReference) === null || _a === void 0 ? void 0 : _a.event.clearInstanceListeners(drawingManager);
                drawingManager.setMap(googleMapsApiReference.mapReference);
                (_b = googleMapsApiReference.mapsApiReference) === null || _b === void 0 ? void 0 : _b.event.addListener(drawingManager, 'overlaycomplete', handleMapShapes);
            }
            else {
                drawingManager.setMap(null);
            }
        }
    }, [drawingMode, locationType, drawingManager, googleMapsApiReference, handleMapShapes]);
    // When the origin or destination coordinates are changed, update the route
    // to reflect it
    (0, react_1.useEffect)(() => {
        // When a marker is changed, see if there are any circle shapes attached to
        // this location and move it to be centered on the new location,
        // if there are any polygon shapes, hide them
        let haveCustomShapesBeenHidden = false;
        const handleShapeChanges = (shape, coords) => {
            if (shape.type === 'circle') {
                shape.overlay.setCenter({
                    lat: coords.latitude,
                    lng: coords.longitude,
                });
            }
            else if (shape.type !== 'marker') {
                if (googleMapsApiReference.mapsApiReference &&
                    !(0, mapUtils_1.checkIfPoygonContainsPoint)(googleMapsApiReference.mapsApiReference, shape, coords)) {
                    if (shape.overlay.getMap()) {
                        haveCustomShapesBeenHidden = true;
                    }
                    shape.overlay.setMap(null);
                }
                else {
                    shape.overlay.setMap(googleMapsApiReference.mapReference);
                }
            }
        };
        if (haveCoordsChanged[0] && haveCoordsChanged[1]) {
            handleShowRoute(locationCoords.origin, locationCoords.destination);
            locationShapes[haveCoordsChanged[1]].forEach((shape) => {
                if (locationCoords.origin)
                    handleShapeChanges(shape, locationCoords.origin);
            });
            setHaveCoordsChanged([false, null]);
            if (haveCustomShapesBeenHidden) {
                handleHideShapes();
            }
        }
    }, [
        googleMapsApiReference,
        handleHideShapes,
        t,
        setHaveCoordsChanged,
        haveCoordsChanged,
        handleShowRoute,
        locationShapes,
        locationCoords,
    ]);
    (0, react_1.useEffect)(() => {
        if (drawingManager) {
            drawingManager.setDrawingMode(drawingMode);
            if (drawingMode === null) {
                drawingManager.setMap(null);
            }
            else {
                drawingManager.setMap(googleMapsApiReference.mapReference);
            }
        }
    }, [drawingMode, drawingManager, googleMapsApiReference]);
    // Handle edit mode changes
    (0, react_1.useEffect)(() => {
        Object.keys(locationShapes).forEach((shapeSection) => {
            locationShapes[shapeSection].forEach((shape) => {
                shape.overlay.setOptions({
                    draggable: isInEditMode,
                    editable: isInEditMode,
                });
            });
        });
    }, [isInEditMode, locationShapes]);
    // Handle Reset
    (0, react_1.useEffect)(() => {
        if (shouldReset) {
            setShouldReset(false);
            if (directionArrow) {
                directionArrow.setMap(null);
                setDirectionArrow(null);
            }
            setMapCenter(constants_1.DEFAULT_MAP_CENTER);
            setLocationType('origin');
            setMapZoom(constants_1.DEFAULT_MAP_ZOOM);
        }
    }, [shouldReset, setShouldReset, setMapCenter, setMapZoom, directionArrow, setDirectionArrow]);
    // Attach the event to detect if we are in fullscreen mode
    // This is to enable showing the Google Maps control panel in
    // fullscreen mode
    (0, react_1.useLayoutEffect)(() => {
        document.onfullscreenchange = () => {
            setFullscreen(!isFullscreen);
        };
        return () => {
            document.onfullscreenchange = null;
        };
    }, [isFullscreen, setFullscreen, googleMapsApiReference]);
    const locations = [origin, destination].map((_location, index) => {
        const locationTypeOption = index === 0 ? 'origin' : 'destination';
        const hasError = locationTypeOption === 'origin' ? hasOriginError : hasDestinationError;
        return (react_1.default.createElement(__1.LaneMapLocation, { applicationTheme: applicationTheme, drawingMode: drawingMode, focusOnLocation: (coords) => {
                setMapCenter(coords);
                setMapZoom(10);
            }, geocodeByAddress: geocodeByAddress, geocodeByPlaceId: geocodeByPlaceId, googleMapsApiKey: googleMapsApiKey, handleAddLocationShape: (shape) => {
                const shapes = locationShapes[locationTypeOption];
                handleSetLocationShapes(Object.assign(Object.assign({}, locationShapes), { [locationTypeOption]: [...shapes, shape] }));
            }, handleChangeLocationMode: (locationMode) => {
                handleSetLocationMode(Object.assign(Object.assign({}, locationModes), { [locationTypeOption]: locationMode }));
            }, handleDeleteLocationShape: (shapesToRemove) => {
                const indices = [];
                shapesToRemove.forEach((shape) => {
                    indices.push(shape.index);
                    shape.overlay.setMap(null);
                });
                const shapes = locationShapes[locationTypeOption];
                const newShapes = shapes.filter((sourceShape) => {
                    return !indices.includes(sourceShape.index);
                });
                handleSetLocationShapes(Object.assign(Object.assign({}, locationShapes), { [locationTypeOption]: newShapes }));
                if (newShapes.length === 0) {
                    handleSetLocationZone(null, locationTypeOption);
                }
            }, handleError: handleError, handleExpansion: handleSectionExpansion, handleSetAddress: (addressName, address) => {
                handleSetAddressNames(Object.assign(Object.assign({}, addressNames), { [locationTypeOption]: addressName }));
                handleSetAddress(Object.assign(Object.assign({}, addresses), { [locationTypeOption]: address }));
                setHaveCoordsChanged([true, locationTypeOption]);
                setDirty();
            }, handleSetCenter: (coords) => {
                const shapes = locationShapes[locationTypeOption];
                handleSetLocationCoords(Object.assign(Object.assign({}, locationCoords), { [locationTypeOption]: coords, mapTheme }));
                if (coords) {
                    // When setting the center, we need to look in the shapes for a marker
                    // if there is one: update its coordinates; else create a new one
                    const marker = (shapes || []).find((shape) => {
                        return shape.type === 'marker';
                    });
                    if (marker) {
                        marker.overlay.setPosition({ lat: coords.latitude, lng: coords.longitude });
                    }
                    else {
                        const newMarker = handleCreateNewMarker(coords);
                        setDrawingMode(null);
                        if (newMarker) {
                            // THis will attach the event to the marker
                            handleMapShapes({ overlay: newMarker, type: 'marker' });
                        }
                    }
                }
                handleCenterChange(coords);
            }, handleSetDrawingMode: (drawingMode) => {
                setDrawingMode(drawingMode);
            }, handleSetLocationZone: (zone, locationTypeOption) => {
                Object.keys(locationShapes).forEach((shapeSection) => {
                    locationShapes[shapeSection].forEach((shape) => {
                        shape.overlay.setMap(null);
                    });
                });
                getAddressFromCoordinates(zone.centerCoords, locationTypeOption);
                if (locationTypeOption) {
                    handleSetLocationZone(zone, locationTypeOption);
                }
                setShouldRestoreMapState(true);
            }, handleToggleLocationShape: (shape, force) => {
                if (force) {
                    if (force === 'show') {
                        shape.overlay.setMap(googleMapsApiReference.mapReference);
                    }
                    else {
                        shape.overlay.setMap(null);
                    }
                }
                else if (shape.overlay.getMap() === null) {
                    shape.overlay.setMap(googleMapsApiReference.mapReference);
                }
                else {
                    shape.overlay.setMap(null);
                }
                const existingShapes = locationShapes[locationTypeOption]
                    .slice(0)
                    .filter((existingShape) => {
                    return existingShape.index !== shape.index;
                });
                handleSetLocationShapes(Object.assign(Object.assign({}, locationShapes), { [locationTypeOption]: [...existingShapes, shape] }));
            }, hasError: hasError, key: locationTypeOption, language: language, locationAddress: addresses[locationTypeOption], locationAddressName: addressNames[locationTypeOption], locationCoords: locationCoords[locationTypeOption], locationMode: locationModes[locationTypeOption], locationShapes: locationShapes[locationTypeOption], locationType: locationTypeOption, mapReference: googleMapsApiReference.mapReference, selectedLocationType: locationType, shouldUseNativeSelect: isFullscreen, t: t, zones: zones }));
    });
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
            react_1.default.createElement(ux_1.Grid, { item: true, style: {
                    transition: theme.transitions.create('all', {
                        duration: theme.transitions.duration.leavingScreen,
                        easing: theme.transitions.easing.sharp,
                    }),
                }, xs: isInEditMode ? 5 : undefined }, isInEditMode && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                        react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t('common:locations'))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" }, laneId && (react_1.default.createElement(__1.CancelButton, { handleCancel: () => {
                                Object.keys(locationShapes).forEach((shapeSection) => {
                                    locationShapes[shapeSection].forEach((shape) => {
                                        shape.overlay.setMap(null);
                                    });
                                });
                                setShouldRestoreMapState(true);
                                handleCancelEdits();
                            }, isDisabled: false, t: t }))))),
                react_1.default.createElement(ux_1.Box, { mr: 3, mt: 3 }, locations)))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: isInEditMode ? 7 : 12 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(__1.TruxwebGoogleMap, { googleApiKey: googleMapsApiKey, height: height || 828, language: language, mapCenter: mapCenter, mapTheme: mapTheme, mapZoom: mapZoom, onRegisterMapsReference: (map) => {
                                setGoogleMapsApiReference(map);
                            }, width: Boolean(width) ? width : isInEditMode ? 624 : 1072 }, isInEditMode && isFullscreen && (react_1.default.createElement(__1.TruxwebGoogleMapControl, { api: googleMapsApiReference.mapsApiReference, index: controlIndex, map: googleMapsApiReference.mapReference, setIndex: setControlIndex },
                            react_1.default.createElement(ux_1.Box, { style: { background: 'white', height: window.innerHeight, width: 450 } }, locations))))))))));
};
exports.LaneMap = LaneMap;
