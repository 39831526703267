/* eslint-disable max-lines */
import { Box, Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@truxweb/ux';
import { Controller, useForm } from 'react-hook-form';
import {
  displayPasswordSchema,
  getRouteProperties,
  paramValidation,
  passwordSchema,
} from '../../utils';
import { ELanguageV1, EStatusGenericV1, EUserTypeV1 } from '@truxweb/schemas';
import {
  formatPhoneNumberFromCountryName,
  POSTAL_CODE_REGEX,
  ZIP_CODE_REGEX,
} from '@truxweb/utils';
import { FormField, RegionSelect, SaveButton, TabButtons } from '@truxweb/common-components';
import React, { useCallback, useEffect, useState } from 'react';
import { TCognitoUserData, type TCompanyToValidate } from '../../types';
import { Trans, useTranslation } from 'next-i18next';
import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import { COMPANY_VERSION } from '../../config';
import { PageLink } from '..';
import { useErrorHandling } from '../../hooks';
import { useRouter } from 'next/router';
import { useStyles } from './SignUpForm.styles';
import { validateCompanyDataForVersion } from '../../actions';

const REQUIRED_NAMESPACES = ['common', 'signup'];

type TSignUpFormProps = {
  userType: EUserTypeV1;
};
export const SignUpForm = ({ userType }: TSignUpFormProps): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
  } = useForm({ mode: 'onBlur' });
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const router = useRouter();
  const { locale } = router;
  const errorHandler = useErrorHandling();

  const [passwordSchemaValidation, setPasswordSchemaValidation] = useState(
    (): Record<string, boolean> => {
      const initialState = {};
      displayPasswordSchema.forEach((args) => {
        initialState[args] = false;
      });
      return initialState;
    }
  );

  const country = watch('country');
  const [postalCodeRegex, setPostalCodeRegex] = useState(POSTAL_CODE_REGEX);

  useEffect(() => {
    if (country === 'US') {
      setPostalCodeRegex(ZIP_CODE_REGEX);
    } else {
      setPostalCodeRegex(POSTAL_CODE_REGEX);
    }
  }, [country, setPostalCodeRegex]);

  const upToDatePassword = watch('password');
  useEffect(() => {
    const schemaViolations = passwordSchema.validate(upToDatePassword, { list: true });

    displayPasswordSchema.forEach((schemaParam) => {
      let containsParamViolation = false;
      if (Array.isArray(schemaViolations)) {
        containsParamViolation = schemaViolations.includes(schemaParam);
      }
      const isValidationParamChecked = upToDatePassword
        ? paramValidation(schemaParam, upToDatePassword)
        : false;

      const isValid = !containsParamViolation && isValidationParamChecked;
      if (passwordSchemaValidation[schemaParam] !== isValid) {
        setPasswordSchemaValidation((prev) => {
          return { ...prev, [schemaParam]: isValid };
        });
      }
    });
  }, [passwordSchemaValidation, upToDatePassword]);

  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    { label: t('common:fr-CA'), value: ELanguageV1.FR_CA },
    { label: t('common:en-CA'), value: ELanguageV1.EN_CA },
  ];
  const handleError = useCallback(
    (err: unknown) => {
      const { code } = err as any;

      if (code === 'UsernameExistsException') {
        errorHandler(err, t('common:usernameExistsException'));
      } else {
        errorHandler(err, t('common:registrationFailed'));
      }
    },
    [errorHandler, t]
  );

  const handleRegistration = useCallback(
    async (data: { [key: string]: any }) => {
      setIsLoading(true);
      try {
        const companyToValidate: TCompanyToValidate = {
          code: ' ',
          id: 0,
          language: data.languagePreference,
          name: data.companyName,
          phone: data.companyPhone,
          postalCode: data.postalCode,
          status: EStatusGenericV1.ACTIVE,
          type: userType,
          version: COMPANY_VERSION,
        };

        const isValid = await validateCompanyDataForVersion(
          companyToValidate,
          setError,
          t,
          setIsLoading
        );

        if (!isValid) return;
        const formattedPhoneNumber = formatPhoneNumberFromCountryName(
          data.directLine,
          data.country
        );
        const formattedCompanyPhone = formatPhoneNumberFromCountryName(
          data.companyPhone,
          data.country
        );

        /* eslint-disable camelcase */
        const cognitoAttributes: Omit<
          TCognitoUserData,
          'sub' | 'phone_number_verified' | 'email_verified' | 'custom:referralCode'
        > = {
          'custom:companyAddressOne': data.address,
          'custom:companyAddressTwo': data.addressSuite,
          'custom:companyCity': data.city,
          'custom:companyCountry': data.country,
          'custom:companyName': data.companyName,
          'custom:companyPhone': formattedCompanyPhone,
          'custom:companyPhoneExt': data.companyPhone_ext,
          'custom:companyPostalCode': data.postalCode,
          'custom:companyProvince': data.province,
          'custom:jobTitle': data.jobTitle,
          'custom:newsletterSignup': data.keepMeUpdated.toString() || 'false',
          'custom:phoneExt': data.directLine_ext,
          'custom:registrationVersion': `${COMPANY_VERSION}`,
          'custom:userType': userType,
          email: data.email,
          family_name: data.lastName,
          given_name: data.firstName,
          locale: data.languagePreference,
          phone_number: formattedPhoneNumber,
          zoneinfo: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        /* eslint-enable camelcase */
        const registrationData = {
          attributes: cognitoAttributes,
          password: data.password,
          username: data.email,
        };
        const postSuccess = async (): Promise<void> => {
          const { route } = getRouteProperties(
            locale,
            userType === EUserTypeV1.CARRIER ? `signup/carrier/confirm` : `signup/shipper/confirm`
          );
          router.push(`${route}?confirm=${encodeURIComponent(data.email)}`);
        };
        await Auth.signUp(registrationData);
        await postSuccess();
        return;
      } catch (err) {
        setIsLoading(false);
        handleError(err);
      }
    },
    [userType, t, locale, router, handleError, setError]
  );

  return (
    <form onSubmit={handleSubmit(handleRegistration)} style={{ width: '100%' }}>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography color="primaryLight" fontStyle="semibold" variant="h3">
            {t('common:openAccount')}
          </Typography>
        </Grid>
        <Grid container direction="column" item justifyContent="space-between">
          <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
            {t('common:languagePreference')}
          </Typography>
        </Grid>
        <Grid item>
          <Box mb={1}>
            <Controller
              control={control}
              defaultValue={ELanguageV1.FR_CA}
              name={'languagePreference'}
              render={({ field: { onChange, value } }) => {
                return (
                  <TabButtons
                    onTabChange={(_event: React.ChangeEvent<any>, value: string) => {
                      onChange(value);
                    }}
                    tabs={tabs}
                    value={value}
                  />
                );
              }}
            />
          </Box>
        </Grid>
        <Grid container direction="column" item justifyContent="space-between">
          <Grid item>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
              {t('common:companyInfo')}
            </Typography>
          </Grid>
          <Grid item xs>
            <Box mb={3} mt={3}>
              <FormField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.itemBox,
                  },
                }}
                control={control}
                defaultValue=""
                id="companyName"
                isRequired={true}
                label={t('common:companyName')}
                labelClassName={classes.fitContainer}
                shouldErrorIconBeSuppressed
                shouldOverrideLabelStyles
                t={t}
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box mb={3}>
              <FormField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.itemBox,
                  },
                }}
                control={control}
                defaultValue=""
                id="companyPhone"
                isRequired={true}
                label={t('common:companyPhone')}
                labelClassName={classes.halfContainer}
                shouldErrorIconBeSuppressed
                shouldIconsBeSuppressed
                shouldOverrideLabelStyles
                t={t}
                type="phone"
              />
            </Box>
          </Grid>
          <Box pb={1} pt={2}>
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              control={control}
              defaultValue=""
              id="address"
              isRequired={true}
              label={t('common:address')}
              labelClassName={classes.fitContainer}
              shouldErrorIconBeSuppressed
              shouldOverrideLabelStyles
              t={t}
              type="text"
            />
          </Box>
          <Box pb={1} pt={2}>
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              control={control}
              defaultValue=""
              id="addressSuite"
              label={t('common:addressSuite')}
              labelClassName={classes.fitContainer}
              shouldErrorIconBeSuppressed
              shouldOverrideLabelStyles
              t={t}
              type="text"
            />
          </Box>
          <Grid
            className={classes.gapContainer}
            container
            direction="row"
            item
            justifyContent="space-between"
          >
            <Grid item xs>
              <Box pb={1} pt={2}>
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.itemBox,
                    },
                  }}
                  control={control}
                  defaultValue=""
                  id="city"
                  isRequired={true}
                  label={t('common:city')}
                  labelClassName={classes.fitContainer}
                  shouldErrorIconBeSuppressed
                  shouldOverrideLabelStyles
                  t={t}
                  type="text"
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box pb={1} pt={2}>
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.itemBox,
                    },
                  }}
                  control={control}
                  defaultValue=""
                  id="postalCode"
                  isRequired={true}
                  label={t('common:postalCode')}
                  labelClassName={classes.fitContainer}
                  shouldErrorIconBeSuppressed
                  shouldOverrideLabelStyles
                  t={t}
                  type="text"
                  validationRules={{
                    pattern: postalCodeRegex,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            className={classes.gapContainer}
            container
            direction="row"
            item
            justifyContent="space-between"
          >
            <Grid item xs>
              <Box pb={1} pt={2}>
                <Controller
                  control={control}
                  name={'province'}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <RegionSelect
                        country={country || 'CA'}
                        hasError={Boolean(errors.province)}
                        onChange={onChange}
                        t={t}
                        value={value}
                        variant={'outlined'}
                      />
                    );
                  }}
                  rules={{ required: true }}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box pb={1} pt={2}>
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.itemBox,
                    },
                  }}
                  control={control}
                  defaultValue=""
                  id="country"
                  isRequired={true}
                  label={t('common:country')}
                  labelClassName={classes.fitContainer}
                  shouldErrorIconBeSuppressed
                  shouldOverrideLabelStyles
                  t={t}
                  type="country"
                />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Grid
              alignItems="center"
              className={classes.fitContainer}
              container
              direction="row"
              item
              wrap="nowrap"
            >
              <Controller
                control={control}
                defaultValue={false}
                name={`keepMeUpdated`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControl>
                      <FormControlLabel
                        className={classes.noMargins}
                        control={
                          <Checkbox
                            checked={value}
                            className={classes.checkbox}
                            color="primaryLight"
                            onChange={onChange}
                          />
                        }
                        id={`keepMeUpdated`}
                        label={
                          <Box pl={2}>
                            <Typography color="primaryLight" variant="bodyMedium">
                              {t('common:keepMeUpdated')}
                            </Typography>
                          </Box>
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box mt={3}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
              {t('common:generalInformation')}
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography className={classes.greyFont} variant="bodyMedium">
              {t('common:accountAddressPrompt')}
            </Typography>
          </Box>
          <Grid
            className={classes.gapContainer}
            container
            direction="row"
            item
            justifyContent="space-between"
          >
            <Grid item xs>
              <Box pb={1} pt={2}>
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.itemBox,
                    },
                  }}
                  control={control}
                  defaultValue=""
                  id="firstName"
                  isRequired={true}
                  label={t('common:firstName')}
                  labelClassName={classes.fitContainer}
                  shouldErrorIconBeSuppressed
                  shouldOverrideLabelStyles
                  t={t}
                  type="text"
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box pb={1} pt={2}>
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.itemBox,
                    },
                  }}
                  control={control}
                  defaultValue=""
                  id="lastName"
                  isRequired={true}
                  label={t('common:lastName')}
                  labelClassName={classes.fitContainer}
                  shouldErrorIconBeSuppressed
                  shouldOverrideLabelStyles
                  t={t}
                  type="text"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs>
            <Box pb={1} pt={2}>
              <FormField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.itemBox,
                  },
                }}
                control={control}
                defaultValue=""
                id="jobTitle"
                isRequired={true}
                label={t('common:jobTitle')}
                labelClassName={classes.fitContainer}
                shouldErrorIconBeSuppressed
                shouldOverrideLabelStyles
                t={t}
                type="text"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box pb={1} pt={2}>
              <FormField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.itemBox,
                  },
                }}
                control={control}
                defaultValue=""
                id="directLine"
                isRequired={true}
                label={t('common:directLine')}
                labelClassName={classes.fitContainer}
                shouldErrorIconBeSuppressed
                shouldIconsBeSuppressed
                shouldOverrideLabelStyles
                t={t}
                type="phone"
              />
            </Box>
          </Grid>
          <Box pb={1} pt={2}>
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              control={control}
              defaultValue=""
              id="email"
              isRequired={true}
              label={t('common:workEmail')}
              labelClassName={classes.fitContainer}
              shouldErrorIconBeSuppressed
              shouldIconsBeSuppressed
              shouldOverrideLabelStyles
              t={t}
              type="email"
            />
          </Box>
          <Box pt={2}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyMedium">
              {t('common:password')}
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography className={classes.greyFont} variant="bodyMedium">
              {t('common:passwordLengthPrompt')}
            </Typography>
          </Box>

          <Box pb={1} pt={2}>
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              control={control}
              defaultValue=""
              id="password"
              isRequired={true}
              label={t('common:password')}
              labelClassName={classes.fitContainer}
              shouldErrorIconBeSuppressed
              shouldOverrideLabelStyles
              t={t}
              type="password"
              validationRules={{
                validate: (value: string) => {
                  const violation = passwordSchema.validate(value, { list: true });
                  return Array.isArray(violation) && violation.length;
                },
              }}
            />
          </Box>
          <Box pb={1} pt={2}>
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              control={control}
              defaultValue=""
              id="passwordConfirm"
              isRequired={true}
              label={t('common:passwordConfirm')}
              labelClassName={classes.fitContainer}
              shouldErrorIconBeSuppressed
              shouldOverrideLabelStyles
              t={t}
              type="passwordConfirm"
              validationRules={{
                validate: (value: any) => {
                  if (upToDatePassword && upToDatePassword !== value) {
                    return 'Passwords must match!';
                  }
                  return true;
                },
              }}
            />
          </Box>
          <Box pb={2} pt={2}>
            <Typography style={{ fontSize: '12px' }} variant={'body1'}>
              <Trans i18nKey="common:termsAndConditionsSignupPrompt">
                <PageLink
                  className={classnames(classes.linkFont, classes.primaryFont)}
                  pageId={'terms-and-conditions'}
                  target={'_blank'}
                >
                  {t('common:termsAndConditionsSignupPrompt')}
                </PageLink>
                <PageLink
                  className={classnames(classes.linkFont, classes.primaryFont)}
                  pageId={'privacy-policy'}
                  target={'_blank'}
                >
                  {t('common:termsAndConditionsSignupPrompt')}
                </PageLink>
              </Trans>
            </Typography>
          </Box>

          <Box className={classes.submitContainer}>
            <SaveButton className={classes.submitButton} isFullWidth isSaving={isLoading} t={t}>
              {t('common:next')}
            </SaveButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
