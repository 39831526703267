import { EPaymentMethodV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

type TGetCarrierQuoteRequestChargesForShipper = {
  carrierQuoteRequestId: number;
  isTaxExempt: boolean;
  shouldRedeemCredit: boolean;
  paymentMethod: EPaymentMethodV1;
  creditCardType?: string;
};

export const getCarrierQuoteRequestChargesForShipper = async (
  request: TGetCarrierQuoteRequestChargesForShipper
): Promise<any> => {
  const { carrierQuoteRequestId, creditCardType, isTaxExempt, paymentMethod, shouldRedeemCredit } =
    request;
  return await makeRequestToApiGateway(
    'shipmentGetV1ShipperQuoteRequestByIdCharges',
    {
      id: carrierQuoteRequestId,
    },
    null,
    null,
    { creditCardType, isTaxExempt, paymentMethod, shouldRedeemCredit }
  );
};
