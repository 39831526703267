"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteDetailsCarrierList = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const quote_utils_1 = require("@truxweb/quote-utils");
const __1 = require("..");
const QuoteDetailsCarrierList_styles_1 = require("./QuoteDetailsCarrierList.styles");
const QuoteDetailsCarrierList = ({ canSetQuoteTaxExemptStatus, carrierFields, control, equipmentCode, header, isSelectionDisabled, language, queryParams, shipmentDate, shouldDisplayHeaderRow, t, testId, }) => {
    const classes = (0, QuoteDetailsCarrierList_styles_1.useStyles)();
    const isCrossBorder = (0, react_1.useMemo)(() => {
        return queryParams.originAddress.country !== queryParams.destinationAddress.country;
    }, [queryParams]);
    return (react_1.default.createElement(ux_1.Box, { mb: 6.5, mt: 6.5 },
        react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
            react_1.default.createElement(ux_1.Typography, { className: classes.primaryLight, fontStyle: "semibold", variant: "bodyLarge" }, header || `1. ${t('common:selectedCarriers')}`)),
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "flex-start" },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                react_1.default.createElement(ux_1.Box, { mr: 3 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        react_1.default.createElement(ux_1.Grid, { className: classes.summaryLineItem, container: true, direction: "row", item: true, xs: true },
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-start", xs: 6 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:shipmentType'))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: 6 },
                                react_1.default.createElement(ux_1.Typography, { variant: "bodyMedium" }, t(`common:${queryParams.shipmentType}`)))),
                        react_1.default.createElement(ux_1.Grid, { className: classes.summaryLineItem, container: true, direction: "row", item: true, xs: true },
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-start", xs: 6 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:equipment'))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: 6 },
                                react_1.default.createElement(ux_1.Typography, { variant: "bodyMedium" }, t(`search:${equipmentCode}_TITLE`)))),
                        react_1.default.createElement(ux_1.Grid, { className: classes.summaryLineItem, container: true, direction: "row", item: true, xs: true },
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-start", xs: 6 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:pickUpDate'))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: 6 },
                                react_1.default.createElement(ux_1.Typography, { variant: "bodyMedium" }, (0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(language, new Date(shipmentDate))))),
                        react_1.default.createElement(ux_1.Grid, { className: classes.summaryLineItem, container: true, item: true, justifyContent: "flex-end", xs: true },
                            react_1.default.createElement(ux_1.Box, { mr: 1 },
                                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Box, { ml: 1 },
                                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: true, name: `isBookingDateFlexible`, render: ({ field: { onChange, value } }) => {
                                                return (react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", onClick: onChange }));
                                            } })), label: t('common:isBookingDateFlexible'), labelPlacement: "start" }))),
                        !isCrossBorder && canSetQuoteTaxExemptStatus && (react_1.default.createElement(ux_1.Grid, { className: classes.summaryLineItem, container: true, item: true, justifyContent: "flex-end", xs: true },
                            react_1.default.createElement(ux_1.Box, { mr: 1 },
                                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Box, { ml: 1 },
                                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: false, name: `isTaxExempt`, render: ({ field: { onChange, value } }) => {
                                                return (react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", onClick: onChange }));
                                            } })), label: t('common:isTaxExempt'), labelPlacement: "start" }))))))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                shouldDisplayHeaderRow && (react_1.default.createElement(ux_1.LineItemHeader, { className: classes.carrierLineItem },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
                        !isSelectionDisabled && (react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 }, "\u00A0")),
                        react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-start", xs: true },
                            react_1.default.createElement(ux_1.Box, { ml: isSelectionDisabled ? 0 : -2.75 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('common:carrier')))),
                        react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center", xs: 2 },
                            react_1.default.createElement(ux_1.Box, { pr: 8 },
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, t('search:priceHeader'))))))),
                carrierFields.map((booking, index) => {
                    return (react_1.default.createElement(ux_1.LineItem, { className: classes.carrierLineItem, key: booking.id },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", className: classes.content, container: true, "data-carriername": booking.carrierName, "data-testid": `${testId}-QuoteDetailsCarrierList-BookingRow`, justifyContent: "space-between" },
                            !isSelectionDisabled && (react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 },
                                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: booking.isSelected, name: `carrier.${index}.isSelected`, render: ({ field: { onChange, value } }) => {
                                        return (react_1.default.createElement(ux_1.GreenCheckbox, { checked: value, "data-testid": `${testId}-QuoteDetailsCarrierList-Checkbox`, onClick: onChange }));
                                    } }))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { ml: isSelectionDisabled ? 4 : 0 },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.lineItemText }, booking.carrierName))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center", xs: 2 },
                                react_1.default.createElement(ux_1.Box, { pr: 8 },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.lineItemText }, Boolean(booking.price) ? (react_1.default.createElement(__1.PriceDisplay, { language: language, price: booking.price })) : (react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:tbd')))))))));
                })))));
};
exports.QuoteDetailsCarrierList = QuoteDetailsCarrierList;
