import {
  AccountAddresses,
  AccountCarrierSettings,
  AccountCompany,
  AccountDetails,
  AccountPayment,
  ComingSoon,
  CompanyDocumentsWrapper,
} from '../../components';
import { ARE_COMPANY_DOCUMENTS_ENABLED, IS_FUEL_SURCHARGE_ACTIVE } from '../../config';
import React, { useEffect } from 'react';
import { useApplicationLoadingState, usePermissions, useUserData } from '../../hooks';
import { AuthorizedPage } from '../../pageTemplates';
import { Box } from '@truxweb/ux';
import { EPermissionV1 } from '@truxweb/schemas';
import { type TTruxwebPageContentProps } from '../../types';
import { updateApplicationLoadingState } from '../../stores';
import { useDispatch } from 'react-redux';

type TAccountPageProps = TTruxwebPageContentProps & {
  children?: React.ReactNode;
};

export const AccountPage = ({ children, pageId }: TAccountPageProps): JSX.Element => {
  const { userData, userType } = useUserData();

  const [canManageCarrierData] = usePermissions([EPermissionV1.MANAGE_CARRIER_DATA]);
  const dispatch = useDispatch();
  const applicationLoadingState = useApplicationLoadingState();

  useEffect(() => {
    if (userData === null && !applicationLoadingState.isApplicationLoading) {
      dispatch(
        updateApplicationLoadingState({ ...applicationLoadingState, isApplicationLoading: true })
      );
    }
    if (userData !== null && applicationLoadingState.isApplicationLoading) {
      dispatch(
        updateApplicationLoadingState({ ...applicationLoadingState, isApplicationLoading: false })
      );
    }
  }, [userData, dispatch, applicationLoadingState]);

  let content = null;

  switch (pageId) {
    case 'account/company':
      content = <AccountCompany userType={userType} />;
      break;
    case 'account/address':
      content = (
        <AccountAddresses
          companyExtId={userData?.companyExtId || ''}
          companyId={userData?.companyId || 0}
        />
      );
      break;
    case 'account/payment':
      content = <AccountPayment userType={userType} />;
      break;
    case 'account/document':
      content = ARE_COMPANY_DOCUMENTS_ENABLED ? <CompanyDocumentsWrapper /> : <ComingSoon />;
      break;
    case 'account/settings':
      content =
        IS_FUEL_SURCHARGE_ACTIVE && canManageCarrierData ? (
          <AccountCarrierSettings />
        ) : (
          <ComingSoon />
        );
      break;
    case 'account':
    // falls through
    default:
      content = <AccountDetails />;
      break;
  }

  return (
    <AuthorizedPage pageId={pageId}>
      <Box style={{ width: '100%' }}>
        {content}
        {children}
      </Box>
    </AuthorizedPage>
  );
};
