"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)(() => ({
    container: {
        maxWidth: 'inherit',
        minWidth: 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
    },
}));
