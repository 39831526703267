"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetDetailsMapping = void 0;
const utils_1 = require("@truxweb/utils");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const ux_1 = require("@truxweb/ux");
const utils_2 = require("../../utils");
const __1 = require("..");
const RateSheetDetailsMapping = ({ entriesToFix, handleCancel, onSubmitSuccess, t, }) => {
    const [hasLoadedCityDetails, setHasLoadedCityDetails] = (0, react_1.useState)(false);
    const [aliasedAddress, setAliasedAddress] = (0, react_1.useState)(null);
    const [shouldShowMappingConfirmation, setShowMappingConfirmation] = (0, react_1.useState)(false);
    const [isLoading, setLoading] = (0, react_1.useState)(false);
    const load = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, e_1, _b, _c;
        var _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (hasLoadedCityDetails)
            return;
        const addressAliasMap = {};
        try {
            for (var _o = true, entriesToFix_1 = __asyncValues(entriesToFix), entriesToFix_1_1; entriesToFix_1_1 = yield entriesToFix_1.next(), _a = entriesToFix_1_1.done, !_a; _o = true) {
                _c = entriesToFix_1_1.value;
                _o = false;
                const record = _c;
                if (record.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION)
                    continue;
                let details, sourceAddress;
                const errorAddressKey = (0, utils_1.formatRateSheetCityLookupKey)(Object.assign({}, record.cityDetails));
                try {
                    details = yield (0, utils_1.geocodeByAddress)(record.name);
                    sourceAddress = (0, utils_1.convertGeocodeResultToAddress)(details[0], [
                        'administrative_area_level_4',
                        'administrative_area_level_3',
                        'administrative_area_level_2',
                    ]);
                }
                catch (err) {
                    // If we cannot find this address, we need to display it as an error
                    addressAliasMap[errorAddressKey] = {
                        aliases: [record.cityDetails],
                        boundaryType: schemas_1.ERateSheetBoundaryTypeV1.CITY,
                        city: (_d = record.cityDetails) === null || _d === void 0 ? void 0 : _d.city,
                        country: (_e = record.cityDetails) === null || _e === void 0 ? void 0 : _e.country,
                        isUnkownAddress: true,
                        latitude: null,
                        longitude: null,
                        name: sourceAddress ? (0, utils_2.formatCityDetailsString)(sourceAddress) : record.cityDetails,
                        province: (_f = record.cityDetails) === null || _f === void 0 ? void 0 : _f.province,
                        shouldFixManually: true,
                    };
                    continue;
                }
                if (!(sourceAddress === null || sourceAddress === void 0 ? void 0 : sourceAddress.city)) {
                    // If an incomplete result was returned from Geo Coding
                    // attempt to look this up in the google places api
                    try {
                        const place = yield (0, utils_1.getAutocompleteResult)(record.name);
                        details = yield (0, utils_1.geocodeByAddress)(place.description);
                        sourceAddress = (0, utils_1.convertGeocodeResultToAddress)(details[0], [
                            'administrative_area_level_4',
                            'administrative_area_level_3',
                            'administrative_area_level_2',
                        ]);
                        if (!sourceAddress.city) {
                            addressAliasMap[errorAddressKey] = {
                                aliases: [record.cityDetails],
                                boundaryType: schemas_1.ERateSheetBoundaryTypeV1.CITY,
                                city: (_g = record.cityDetails) === null || _g === void 0 ? void 0 : _g.city,
                                country: (_h = record.cityDetails) === null || _h === void 0 ? void 0 : _h.country,
                                isUnkownAddress: true,
                                latitude: null,
                                longitude: null,
                                name: (0, utils_2.formatCityDetailsString)(sourceAddress),
                                province: (_j = record.cityDetails) === null || _j === void 0 ? void 0 : _j.province,
                                shouldFixManually: true,
                            };
                            continue;
                        }
                    }
                    catch (err) {
                        addressAliasMap[errorAddressKey] = {
                            aliases: [record.cityDetails],
                            boundaryType: schemas_1.ERateSheetBoundaryTypeV1.CITY,
                            city: (_k = record.cityDetails) === null || _k === void 0 ? void 0 : _k.city,
                            country: (_l = record.cityDetails) === null || _l === void 0 ? void 0 : _l.country,
                            isUnkownAddress: true,
                            latitude: null,
                            longitude: null,
                            name: (0, utils_2.formatCityDetailsString)(sourceAddress),
                            province: (_m = record.cityDetails) === null || _m === void 0 ? void 0 : _m.province,
                            shouldFixManually: true,
                        };
                        continue;
                    }
                }
                if (!sourceAddress || !details)
                    continue;
                const addressString = (0, utils_1.formatRateSheetCityLookupKey)({
                    city: sourceAddress.city,
                    country: sourceAddress.country,
                    province: sourceAddress.province,
                });
                if (!addressAliasMap[addressString]) {
                    addressAliasMap[addressString] = {
                        aliases: [],
                        boundaryType: schemas_1.ERateSheetBoundaryTypeV1.CITY,
                        city: sourceAddress.city,
                        country: sourceAddress.country,
                        latitude: details[0].geometry.location.lat(),
                        longitude: details[0].geometry.location.lng(),
                        name: (0, utils_2.formatCityDetailsString)(sourceAddress),
                        province: sourceAddress.province,
                        shouldFixManually: false,
                    };
                }
                addressAliasMap[addressString].aliases.push(record.cityDetails);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_o && !_a && (_b = entriesToFix_1.return)) yield _b.call(entriesToFix_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // await Promise.all(promises);
        let shouldDuplicateAddressBeShown = false;
        // If there are any mappings taking place
        // between the lookup and the aliases, we
        // will need to inform the user of the
        // actions which will be taken
        Object.values(addressAliasMap).forEach((record) => {
            const parentKey = (0, utils_1.formatRateSheetCityLookupKey)(record);
            record.aliases.forEach((dupe) => {
                if ((0, utils_1.formatRateSheetCityLookupKey)(dupe) !== parentKey) {
                    shouldDuplicateAddressBeShown = true;
                }
            });
        });
        setAliasedAddress(addressAliasMap);
        setShowMappingConfirmation(shouldDuplicateAddressBeShown);
        setHasLoadedCityDetails(true);
    }), [
        setHasLoadedCityDetails,
        hasLoadedCityDetails,
        entriesToFix,
        setAliasedAddress,
        setShowMappingConfirmation,
    ]);
    (0, react_1.useEffect)(() => {
        if (hasLoadedCityDetails || isLoading)
            return;
        setLoading(true);
        load();
    }, [setHasLoadedCityDetails, hasLoadedCityDetails, isLoading, setLoading, load]);
    (0, react_1.useEffect)(() => {
        if (hasLoadedCityDetails && !shouldShowMappingConfirmation) {
            onSubmitSuccess({ confirmMappings: Object.values(aliasedAddress) });
        }
    }, [hasLoadedCityDetails, aliasedAddress, shouldShowMappingConfirmation, onSubmitSuccess]);
    if (!Boolean(shouldShowMappingConfirmation))
        return react_1.default.createElement(ux_1.CircularProgress, null);
    return (react_1.default.createElement(__1.RateSheetDetailsConfirmMapping, { aliasedAddress: aliasedAddress, handleCancel: handleCancel, onSubmitSuccess: onSubmitSuccess, t: t }));
};
exports.RateSheetDetailsMapping = RateSheetDetailsMapping;
