import { Storage } from 'aws-amplify';
import { TS3Response } from '../types';
/*
 * SAMPLE UPLOAD HANDLER
  const onFileUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        // FIXME: we need to rename this file before it is uploaded to ensure
        // it is corrrectly namespaced for the carrier
        await uploadFileToBucket(event.target.files[0].name, event.target.files[0]);
        addAlert({ message: t('common:uploadSuccess'), severity: 'success' });
      } catch (err) {
        addAlert({ message: t('common:uploadSuccess'), severity: 'error' });
      }
    },
    [addAlert, t]
  );
*/

export const uploadFileToBucket = async (
  fileName: string,
  file: File,
  bucketName?: string,
  contentType?: string
): Promise<TS3Response> => {
  const params: Record<string, string> = {};
  if (bucketName) {
    params.bucket = bucketName;
  }
  if (contentType) {
    params.contentType = contentType;
  }
  return await Storage.put(fileName, file, params);
};
