import { Box, FlatButton, Grid } from '@truxweb/ux';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { SaveButton } from '@truxweb/common-components';
import { selectHasRequestedServiceOnEmptyResults } from '../../selectors';
import { submitShipperTicketEmptySearch } from '../../actions';
import { useCallback } from 'react';
import { useStyles } from './TruxiiEmptyGrid.styles';
import { useTranslation } from 'next-i18next';
import { useUserData } from '../../hooks';

const REQUIRED_NAMESPACES = ['common'];

type TTruxiiEmptyGridProps = {
  handleNavigateToSearch: (shouldClearBookings: boolean, isNewSearch: boolean) => void;
};

export const TruxiiEmptyGrid = ({ handleNavigateToSearch }: TTruxiiEmptyGridProps): JSX.Element => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { userData } = useUserData();

  const hasRequestedServiceRedux = useSelector(selectHasRequestedServiceOnEmptyResults);
  const hasRequestedWithSuccess =
    hasRequestedServiceRedux.hasLoaded && hasRequestedServiceRedux.data;

  const handleRequestServiceOnEmptyResults = useCallback(() => {
    dispatch(submitShipperTicketEmptySearch(userData?.companyName, userData?.email));
  }, [dispatch, userData]);

  return (
    <Box className={classes.wrapper} mt={12}>
      <Grid
        alignItems="center"
        className={classes.container}
        container
        direction="column"
        justifyContent="center"
      >
        <Grid item>
          <Image
            alt={`truxii ${hasRequestedWithSuccess ? 'smiling' : 'sad'}`}
            height={114}
            src={`/assets/images/truxii/truxii-${hasRequestedWithSuccess ? 'smiling' : 'sad'}.svg`}
            unoptimized
            width={81}
          />
        </Grid>

        <Grid item>
          <Grid alignItems="center" container direction="column" justifyContent="center">
            <Grid item>
              <Box className={classes.primaryMessage}>
                {hasRequestedWithSuccess
                  ? t('search:noCarriersFoundRequestSent1')
                  : t('search:noCarriersFound1')}
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.primaryMessage}>
                {hasRequestedWithSuccess
                  ? t('search:noCarriersFoundRequestSent2')
                  : t('search:noCarriersFound2')}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box mt={2}>
            <Grid alignItems="center" container direction="row">
              <Grid item>
                {hasRequestedWithSuccess ? (
                  <FlatButton
                    className={classes.button}
                    color={'primaryLight'}
                    onClick={() => handleNavigateToSearch(true, true)}
                    variant="contained"
                  >
                    {t('search:newSearch')}
                  </FlatButton>
                ) : (
                  <SaveButton
                    className={classes.button}
                    color={'primaryLight'}
                    customAction={handleRequestServiceOnEmptyResults}
                    isDisabled={hasRequestedWithSuccess}
                    isSaving={hasRequestedServiceRedux.isLoading}
                    t={t}
                  >
                    {t('search:noCarriersFoundRequestRouteBtn')}
                  </SaveButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
