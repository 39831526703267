import {
  addAlert,
  type AppThunk,
  setAuthData,
  setAuthError,
  setAuthLoading,
} from '../../../stores';
import { type TCompanyV1, type TUserV1 } from '@truxweb/schemas';
import { loginSuccess } from '../..';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const getAuthedUserData =
  (shouldSkipSuccess?: boolean, successRedirect?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAuthLoading());
      const data: { company: TCompanyV1; user: TUserV1 } = await makeRequestToApiGateway(
        'userGetV1User',
        null,
        {
          'Content-Type': 'application/json',
        }
      );

      dispatch(setAuthData(data));
      if (!shouldSkipSuccess) {
        await loginSuccess(data.company.type, data?.company.status, successRedirect);
      }
    } catch (err) {
      dispatch(addAlert({ message: err.message, severity: 'error' }));
      dispatch(setAuthError({ error: err }));
    }
  };
