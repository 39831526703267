import { makeRequestToApiGateway } from '../../apiUtils';
import { type TShipmentCommentV1 } from '@truxweb/schemas';
import { type TSubmitAddCommentPayload } from '../../types';

export const addCarrierComment = async (
  request: TSubmitAddCommentPayload
): Promise<TShipmentCommentV1> => {
  const { shipmentRef } = request;
  return await makeRequestToApiGateway<TShipmentCommentV1>(
    'shipmentPostV1CarrierCommentByShipmentRef',
    { shipmentRef },
    {
      'Content-Type': 'application/json',
    },
    request
  );
};
