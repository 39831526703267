"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLemonIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18,13a1,1,0,0,1-2,0A1,1,0,0,1,18,13ZM15,9a1,1,0,0,0,0,2A1,1,0,0,0,15,9Zm4,0a1,1,0,0,0,0,2A1,1,0,0,0,19,9Zm4.533-3.1C26.189,17.546,17.542,26.191,5.9,23.533c-.951-.382-1.9.51-2.9.467A3.016,3.016,0,0,1,.378,19.545a2.131,2.131,0,0,0,.1-1.476c-.9-4.563-.882-9.434,3.4-14.195C8.634-.4,13.505-.421,18.068.477a2.138,2.138,0,0,0,1.478-.1A3.017,3.017,0,0,1,24,3C24.043,4,23.152,4.941,23.533,5.9Zm-1.655-2.42A1.006,1.006,0,0,0,21,2c-1.1.337-2.075.829-3.41.419C9.416.477,1.9,5.229,2.005,14.013c-.23,2.092,1.22,4.6.119,6.507a1,1,0,0,0,1.354,1.358C5.4,20.8,7.865,22.239,9.987,22c2.059-.027,5.93-.49,8.724-3.284S21.968,12.046,22,9.987C22.23,7.855,20.8,5.4,21.878,3.478Z" })));
exports.default = TruxwebLemonIcon;
