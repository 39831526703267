import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  backgroundLightblue: {
    background: '#E4F5FF',
  },
  backgroundOne: {
    background: 'url(/assets/images/pageBackgrounds/Truxweb_BGImage_1.png) no-repeat top center',
    backgroundSize: 'contain',
    [theme.breakpoints.up('xl')]: {
      backgroundSize: 'cover',
    },
  },

  backgroundTwo: {
    background: 'url(/assets/images/pageBackgrounds/Truxweb_BGImage_2.png) no-repeat top center',
    backgroundSize: 'contain',
    [theme.breakpoints.up('xl')]: {
      backgroundSize: 'cover',
    },
  },
  backgroundWhite: {
    background: theme.palette.common.white,
  },
  blueGradient: {
    background: 'url(/assets/images/pageBackgrounds/blueGradient.png) no-repeat top center',
    backgroundColor: 'rgba(0, 108, 165, 0.05)',
    backgroundSize: 'contain',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // overflow: 'hidden',
  },
  content: {
    background: 'transparent',
    maxWidth: '1440px',
    // FIXME: this is a temporary step until we get the proper design
    minWidth: '1440px',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      // FIXME: this is a temporary step until we get the proper design
      minWidth: '100%',
    },
  },
  forest: {
    background: 'url(/assets/images/pageBackgrounds/forest.png) no-repeat top center',
    backgroundSize: 'contain',
    [theme.breakpoints.up('xl')]: {
      backgroundSize: 'cover',
    },
  },
  forestBanner: {
    background: 'url(/assets/images/pageBackgrounds/forestBanner.png) no-repeat top center',
    backgroundSize: 'contain',
  },
  forestBannerWithBlueBg: {
    background: 'url(/assets/images/pageBackgrounds/forestBanner.png) no-repeat top center',
    backgroundColor: '#E4F5FF',
    backgroundSize: 'contain',
  },
  scrolledContent: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(15.75),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10.5),
    },
  },
  shipmentsBackground: {
    background: 'linear-gradient(180deg, #061946 0%, #224882 25.52%, #0087CE 80.21%)',
  },
  unsupportedBrowser: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
}));
