"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDashboardCSPProgress = void 0;
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const ux_1 = require("@truxweb/ux");
const shipment_utils_1 = require("@truxweb/shipment-utils");
const ShipmentDashboardCSPProgress_styles_1 = require("./ShipmentDashboardCSPProgress.styles");
const ShipmentDashboardCSPProgress = ({ shipmentDetails, t, }) => {
    const stepperClases = (0, ShipmentDashboardCSPProgress_styles_1.useStepperStyles)();
    const [activeStep, steps] = (0, react_1.useMemo)(() => {
        const shipmentStatus = shipmentDetails.shipment.status;
        const steps = shipment_utils_1.totalShipmentProgressHierarchy.map((status) => {
            if (shipmentStatus === schemas_1.EShipmentStatusV1.CANCELLED &&
                status === schemas_1.EShipmentStatusV1.COMPLETED) {
                status = schemas_1.EShipmentStatusV1.CANCELLED;
            }
            return { label: t(`shipments:shipmentStatus-${status}`) };
        });
        let activeStep = shipment_utils_1.totalShipmentProgressHierarchy.indexOf(shipmentStatus || schemas_1.EShipmentStatusV1.REQUIRES_DOCUMENTS);
        if (shipmentStatus === schemas_1.EShipmentStatusV1.CANCELLED) {
            activeStep = shipment_utils_1.totalShipmentProgressHierarchy.length;
        }
        return [activeStep, steps];
    }, [shipmentDetails, t]);
    return (react_1.default.createElement("div", { className: stepperClases.root },
        react_1.default.createElement(ux_1.Stepper, { activeStep: activeStep, orientation: "vertical", style: { background: 'transparent' } }, steps.map((step, index) => {
            return (react_1.default.createElement(ux_1.Step, { key: index },
                react_1.default.createElement(ux_1.StepLabel, null, step.label),
                ";"));
        }))));
};
exports.ShipmentDashboardCSPProgress = ShipmentDashboardCSPProgress;
