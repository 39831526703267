// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDelayedStatusV1 = void 0;
var ShipmentDelayedStatusV1;
(function (ShipmentDelayedStatusV1) {
    ShipmentDelayedStatusV1["ON_TIME"] = "ON_TIME";
    ShipmentDelayedStatusV1["DELAYED"] = "DELAYED";
})(ShipmentDelayedStatusV1 || (exports.ShipmentDelayedStatusV1 = ShipmentDelayedStatusV1 = {}));
