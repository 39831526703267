import { Box, Button, Grid, TMuiVariant, Typography } from '@truxweb/ux';
import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';
import { useRouter } from 'next/router';
import { useStyles } from './ComingSoon.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TComingSoonProps = {
  isFullPageBlur?: boolean;
  children?: JSX.Element;
  comingSoonOneClass?: string;
  comingSoonTwoClass?: string;
  containerClass?: string;
  isCompact?: boolean;
  shouldOnlyShowChildren?: boolean;
};

export const ComingSoon = ({
  children,
  comingSoonOneClass,
  comingSoonTwoClass,
  containerClass,
  isCompact,
  isFullPageBlur,
  shouldOnlyShowChildren,
}: TComingSoonProps): JSX.Element => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  let truxiWidth = 196;
  let comingSoonOneVariant: TMuiVariant = 'h3';
  let comingSoonTwoVariant: TMuiVariant = 'h3';
  if (isCompact) {
    truxiWidth = 90;
    comingSoonOneVariant = 'h4';
    comingSoonTwoVariant = 'h6';
  }

  const truxiHeight = (truxiWidth * 250) / 196;

  const button = (
    <Box mt={3} pb={3}>
      <Button
        color="primary"
        onClick={() => {
          router.back();
        }}
        style={{
          width: 216,
        }}
        variant={'contained'}
      >
        &larr; &nbsp;{t('common:goBack')}
      </Button>
    </Box>
  );
  const comingSoon = (
    <>
      <Grid
        alignItems="center"
        className={classnames(classes.container, containerClass)}
        container
        direction="column"
        justifyContent="center"
      >
        <Grid item>
          <Image
            height={truxiHeight}
            src={`/assets/images/truxii/truxii-construction.svg`}
            unoptimized
            width={truxiWidth}
          />
        </Grid>

        {shouldOnlyShowChildren && Boolean(children) && children}
        {!shouldOnlyShowChildren && (
          <>
            <Grid item>
              <Grid alignItems="center" container direction="column" justifyContent="center">
                <Typography className={comingSoonOneClass} variant={comingSoonOneVariant}>
                  {t('common:comingSoonPromptOne')}
                </Typography>
                <Typography className={comingSoonTwoClass} variant={comingSoonTwoVariant}>
                  {t('common:comingSoonPromptTwo')}
                </Typography>
              </Grid>
            </Grid>
            {!isCompact && (
              <Grid item>
                <Box mt={3} pb={3}>
                  <Typography className={classes.comingSoonDescriptionText}>
                    {t('common:comingSoonDescriptionOne')}
                    {t('common:comingSoonDescriptionTwo')}
                  </Typography>
                </Box>
              </Grid>
            )}
            {!children && !isCompact && button}

            {Boolean(children) && (
              <Grid alignItems="center" container direction="column" justifyContent="center">
                {children}

                <Grid item>
                  <Box pb={5}>{button}</Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );

  if (!isFullPageBlur) return comingSoon;

  return <Box className={classes.pageBlur}>{comingSoon}</Box>;
};
