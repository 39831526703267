"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarBusIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,19a15.921,15.921,0,0,0-1.23-6.153l-.321-.77A4.988,4.988,0,0,0,17.833,9H14.167a4.988,4.988,0,0,0-4.616,3.077l-.32.769c-.9,2.642-2.7,7.576.769,8.982V22a2,2,0,0,0,4,0h4a2,2,0,0,0,4,0v-.172A3,3,0,0,0,24,19ZM11.4,12.846A2.994,2.994,0,0,1,14.167,11h3.666a2.994,2.994,0,0,1,2.77,1.846l.32.77c.054.127.1.255.154.384H10.924c.049-.129.1-.257.153-.385ZM21,20H11c-1.757-.17-.77-2.869-.676-4H12v1a1,1,0,0,0,2,0V16h4v1a1,1,0,0,0,2,0V16h1.676C21.769,17.129,22.757,19.831,21,20ZM6.5,11H4V6.265c0-.089,0-.177.009-.265H9V7a1,1,0,0,0,2,0V6h4.991c.005.088.009.176.009.265V7a1,1,0,0,0,2,0V6.265A5.946,5.946,0,0,0,13.668.5,13.737,13.737,0,0,0,6.332.5,5.946,5.946,0,0,0,2,6.265V9a2,2,0,0,0,0,4v3a3.006,3.006,0,0,0,2,2.829V20a1,1,0,0,0,2,0V18a1,1,0,0,0-1-1,1,1,0,0,1-1-1V13H6.5A1,1,0,0,0,6.5,11Zm.355-8.568a11.723,11.723,0,0,1,6.29,0A3.826,3.826,0,0,1,15.313,4H4.687A3.826,3.826,0,0,1,6.855,2.432Z" })));
exports.default = TruxwebCarBusIcon;
