"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTomatoIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16.686,5.1c.952-.99,2.3-3.786.314-4.1a1,1,0,0,0-1,1c0,1.235-1.127,2.546-3,2.9V1a1,1,0,0,0-2,0V4.9C9.127,4.546,8,3.235,8,2A1,1,0,0,0,7,1c-1.983.312-.642,3.106.31,4.1A8.854,8.854,0,0,0,0,14c0,4.721,4.276,10,10,10h4C25.992,24.128,27.457,6.975,16.686,5.1ZM14,22H10a8.322,8.322,0,0,1-8-8c-.052-4.611,4.3-8.172,8.5-6.648A4.471,4.471,0,0,1,7.757,9.03a1,1,0,0,0-.727,1.212c.741,2.132,4.745-.975,4.968-1.565.209.579,4.237,3.7,4.972,1.565a1,1,0,0,0-.728-1.212A4.472,4.472,0,0,1,13.5,7.352C23.917,4.589,25.406,21.99,14,22Z" })));
exports.default = TruxwebTomatoIcon;
