"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        alignContent: 'center',
        display: 'flex',
        height: theme.spacing(4),
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        marginTop: ({ index }) => (index !== 0 ? theme.spacing(3) : 0),
        position: 'relative',
    },
    date: {
        background: 'white',
        border: '1px solid #DCDCDC',
        borderRadius: theme.spacing(5),
        height: theme.spacing(4),
        lineHeight: `${theme.spacing(4)}px`,
        padding: `0px ${theme.spacing(2)}px`,
        position: 'relative',
        width: 'fit-content',
    },
    divider: {
        left: 0,
        position: 'absolute',
        right: 0,
        top: `${theme.spacing(2)}px`,
    },
    text: {
        color: '#636363',
        lineHeight: 'inherit',
    },
}));
