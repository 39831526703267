"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M3,21.123a1,1,0,0,1-.567-.177,5.37,5.37,0,0,1,.51-9.3,7.648,7.648,0,0,1-.8-5.179,8,8,0,0,1,15.49-.841,1.085,1.085,0,0,0,.722.733,7.982,7.982,0,0,1,5.312,5.354,1,1,0,0,1-1.916.572,5.988,5.988,0,0,0-3.983-4.014A3.1,3.1,0,0,1,15.724,6.22a6,6,0,1,0-11.06,4.512,1.994,1.994,0,0,1-.792,2.687,3.37,3.37,0,0,0-.3,5.881A1,1,0,0,1,3,21.123ZM19.1,24a5.322,5.322,0,0,0,1.062-.158,4.946,4.946,0,0,0,3.7-3.7,5.012,5.012,0,0,0-3.557-6s-.629-.145-.766-.471h0a6,6,0,0,0-11.367.856,6.38,6.38,0,0,0-.057,2.732,3.5,3.5,0,0,0,.451,6.589A3.8,3.8,0,0,0,9.4,24Zm-1.4-9.556v0a2.647,2.647,0,0,0,2.088,1.633,3.02,3.02,0,0,1,2.132,3.62,2.978,2.978,0,0,1-2.2,2.194,3.27,3.27,0,0,1-.655.082c-1.665.033-8.271.038-9.6,0a1.709,1.709,0,0,1-.379-.055A1.471,1.471,0,0,1,8.057,20.9a1.493,1.493,0,0,1,.85-1.795,2.017,2.017,0,0,0,1.18-2.305,3.991,3.991,0,0,1,.031-1.792,4,4,0,0,1,7.577-.562Z" })));
exports.default = TruxwebCloudsIcon;
