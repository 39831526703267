"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudSnowIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10.493,23.6a1,1,0,0,1-.868-.5L9,22.01,8.375,23.1a1,1,0,1,1-1.734-1L7.274,21H6a1,1,0,0,1,0-2H7.274L6.641,17.9a1,1,0,1,1,1.734-1L9,17.99l.625-1.09a1,1,0,1,1,1.734,1L10.726,19H12a1,1,0,0,1,0,2H10.726l.633,1.105a1,1,0,0,1-.866,1.5ZM16.5,20H16a1,1,0,0,1,0-2h.5c6.59-.1,7.509-9.506,1.083-10.894a3.011,3.011,0,0,1-2.158-1.672A6,6,0,0,0,4.086,6.967a6.159,6.159,0,0,0,.023,2.18,3,3,0,0,1-.962,2.762A3.522,3.522,0,0,0,3.3,17.223a1,1,0,1,1-1.258,1.554A5.533,5.533,0,0,1,1.8,10.43a1,1,0,0,0,.345-.9,8.146,8.146,0,0,1-.033-2.889A7.946,7.946,0,0,1,8.5.137a8.056,8.056,0,0,1,8.734,4.44,1.04,1.04,0,0,0,.743.569C26.759,7.092,25.505,19.818,16.5,20Zm-1.007-4.4a1,1,0,0,1-.868-.5L14,14.01l-.625,1.09a1,1,0,1,1-1.734-.995L12.274,13H11a1,1,0,0,1,0-2h1.274L11.641,9.9A1,1,0,1,1,13.375,8.9L14,9.99l.625-1.09a1,1,0,1,1,1.734.995L15.726,11H17a1,1,0,0,1,0,2H15.726l.633,1.105a1,1,0,0,1-.866,1.5Z" })));
exports.default = TruxwebCloudSnowIcon;
