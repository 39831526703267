import { makeRequestToApiGateway } from '../../apiUtils';
import { TShipmentDocumentToUploadV1 } from '@truxweb/schemas';

export const getShipmentDocumentTypes = async (): Promise<TShipmentDocumentToUploadV1[]> => {
  const results = await makeRequestToApiGateway('shipmentGetV1DocumentTypes', null, {
    'Content-Type': 'application/json',
  });

  return results as TShipmentDocumentToUploadV1[];
};
