import {
  type AppState,
  type AppThunk,
  setCarrierQuotesListError,
  setCarrierQuotesListLoaded,
  setCarrierQuotesListLoading,
  setShipperQuotesListError,
  setShipperQuotesListLoaded,
  setShipperQuotesListLoading,
} from '../../../stores';
import { ELanguageV1, EShipmentQuoteRequestStatusV1, EUserTypeV1 } from '@truxweb/schemas';
import { type GridFilterModel, type GridSortModel } from '@truxweb/ux';
import { availableEndpoints } from '../../../config';
import { type Dispatch } from '@reduxjs/toolkit';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { progressiveLoad } from '@truxweb/utils';

type TFetchQuoteRequestListData = {
  filter?: GridFilterModel | null;
  userType: EUserTypeV1;
  limit: number;
  pageSize?: number;
  page?: number;
  sort?: GridSortModel;
  language: ELanguageV1;
  shouldLoadAllData: boolean;
};

export const fetchQuoteRequestListData =
  (request: TFetchQuoteRequestListData): AppThunk =>
  async (dispatch, getState) => {
    const { filter, language, limit, page, pageSize, shouldLoadAllData, sort, userType } = request;
    const state = getState() as unknown as AppState;
    const status = state.ux.quoteTableStatuses;

    const endpoint =
      userType === EUserTypeV1.CARRIER ? 'shipmentGetV1CarrierQuote' : 'shipmentGetV1ShipperQuote';

    const loadingAction =
      userType === EUserTypeV1.CARRIER ? setCarrierQuotesListLoading : setShipperQuotesListLoading;
    const loadedAction =
      userType === EUserTypeV1.CARRIER ? setCarrierQuotesListLoaded : setShipperQuotesListLoaded;
    const errorAction =
      userType === EUserTypeV1.CARRIER ? setCarrierQuotesListError : setShipperQuotesListError;

    dispatch(loadingAction());
    const fetchRequest = {
      dispatch,
      errorAction,
      filter,

      language,
      loadedAction,
      page,
      pageSize,
      shouldLoadAllData,
      sort,
      status,
    };

    // After fetching this data set, we want to store the filters used on the last load so that they can
    // be restored when the app refreshes
    const filterKey = `TRUXWEB_${userType}_QUOTE_TABLE_FILTERS`;
    localStorage.setItem(filterKey, JSON.stringify(status));

    const resultsCount = await fetchQuoteRequestListSegment(endpoint, limit, 0, fetchRequest);

    if (shouldLoadAllData) {
      await progressiveLoad(resultsCount || 1, limit, async (limit, offset) => {
        // If we are progressively loading data, we do not want to treat this as a refresh,
        // as a refresh will clear out the existing data from the list
        return await fetchQuoteRequestListSegment(endpoint, limit, offset, {
          ...fetchRequest,
        });
      });
    }
  };

type TFetchQuoteListSegmentRequest = {
  loadedAction: any;
  errorAction: any;
  dispatch: Dispatch;
  page?: number;
  pageSize?: number;
  language: ELanguageV1;
  status?: EShipmentQuoteRequestStatusV1[];
  sort?: GridSortModel;
  filter?: GridFilterModel | null;
  shouldLoadAllData: boolean;
  isRefreshing?: boolean;
};

const fetchQuoteRequestListSegment = async (
  endpoint: keyof typeof availableEndpoints,
  limit: number,
  offset: number,
  request: TFetchQuoteListSegmentRequest
): Promise<any> => {
  const {
    dispatch,
    errorAction,
    filter,
    language,
    loadedAction,
    page,
    pageSize,
    shouldLoadAllData,
    sort,
    status,
  } = request;
  try {
    const { results, resultsCount } = await makeRequestToApiGateway<any>(
      endpoint,
      null,
      {
        'Content-Type': 'application/json',
      },
      null,
      {
        filter: filter ? JSON.stringify(filter) : false,
        language,
        limit: pageSize ? pageSize : limit,
        list: true,
        offset: pageSize && page ? pageSize * page : offset,
        sort: sort ? JSON.stringify(sort) : undefined,
        status,
      }
    );

    dispatch(
      loadedAction({
        isProgressiveLoading: false,
        items: results || [],
        rowCount: resultsCount,
        shouldLoadAllData,
      })
    );
    return resultsCount;
  } catch (error) {
    dispatch(errorAction({ error: error.message }));
    return 0;
  }
};
