import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    '&:hover,&:active': {
      background: theme.palette.primary.dark,
      border: '3px solid',
      borderColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexBasis: '21%',
    padding: '0 0 0 0',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '34%',
      height: theme.spacing(17),
      maxWidth: theme.spacing(17),
      minWidth: theme.spacing(17),
      padding: theme.spacing(1),
      width: theme.spacing(17),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(17),
      maxWidth: theme.spacing(38),
      minWidth: theme.spacing(38),
      width: theme.spacing(38),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(17),
      maxWidth: theme.spacing(26),
      minWidth: theme.spacing(26),
      width: theme.spacing(26),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(18),
      maxWidth: theme.spacing(30),
      minWidth: theme.spacing(30),
      width: theme.spacing(30),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(18),
      maxWidth: theme.spacing(35),
      minWidth: theme.spacing(35),
      width: theme.spacing(35),
    },
  },
  gridWrapper: {
    height: '100%',
    width: '100%',
  },
  link: {
    textDecoration: 'none',
  },
  text: {
    color: theme.palette.common.white,
  },
}));
