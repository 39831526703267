// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterOperationV1 = void 0;
var FilterOperationV1;
(function (FilterOperationV1) {
    FilterOperationV1["IN"] = "IN";
    FilterOperationV1["NOT_IN"] = "NOT_IN";
    FilterOperationV1["BETWEEN"] = "BETWEEN";
    FilterOperationV1["NOT_BETWEEN"] = "NOT_BETWEEN";
    FilterOperationV1["GREATER_THAN"] = "GREATER_THAN";
    FilterOperationV1["LESS_THAN"] = "LESS_THAN";
    FilterOperationV1["CONTAINS"] = "CONTAINS";
    FilterOperationV1["DOES_NOT_CONTAIN"] = "DOES_NOT_CONTAIN";
})(FilterOperationV1 || (exports.FilterOperationV1 = FilterOperationV1 = {}));
