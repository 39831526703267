import { ETicketType } from '../../types';
import { makeRequestToApiGateway } from '../../apiUtils';
import { type TUserV1 } from '@truxweb/schemas';

type TSubmitTicket = {
  formType: ETicketType;
  userData: TUserV1;
  companyName: string;
  submission: Record<string, any>;
};
export const submitTicket = async (request: TSubmitTicket): Promise<any> => {
  return await makeRequestToApiGateway(
    'hubspotPostV1FormByFormType',
    { formType: 'submit' },
    {
      'Content-Type': 'application/json',
    },
    request
  );
};
