import { EPermissionV1 } from '@truxweb/schemas';
import { useEffect } from 'react';
import { usePermissions } from '../../hooks';

type TPermissionGuardProps = {
  children?: React.ReactNode;
  fallbackComponent?: React.ReactNode;
  onPermissionsEvaluated?: () => void;
  permissions?: EPermissionV1[];
};

export const PermissionGuard = ({
  children,
  fallbackComponent,
  onPermissionsEvaluated,
  permissions,
}: TPermissionGuardProps): JSX.Element => {
  const [arePermissionsAllowed] = usePermissions(permissions);

  useEffect(() => onPermissionsEvaluated && onPermissionsEvaluated(), [onPermissionsEvaluated]);

  if (!arePermissionsAllowed) return fallbackComponent ? <>{fallbackComponent}</> : null;

  return <>{children}</>;
};
