"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        alignItems: 'center',
        background: '#fff',
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        padding: '0.5rem',
        width: '100%',
    },
    input: {
        border: 0,
        borderRadius: theme.spacing(0.5),
        fontSize: 'inherit',
        lineHeight: 'inherit',
        outline: 0,
        width: '50%',
    },
}));
