"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebJpgIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m19 0h-14a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h11.343a4.968 4.968 0 0 0 3.535-1.465l2.658-2.656a4.968 4.968 0 0 0 1.464-3.536v-11.343a5.006 5.006 0 0 0 -5-5zm-17 19v-14a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10h-4a3 3 0 0 0 -3 3v4h-10a3 3 0 0 1 -3-3zm16.464 2.121a3.02 3.02 0 0 1 -1.464.8v-3.921a1 1 0 0 1 1-1h3.922a2.978 2.978 0 0 1 -.8 1.465zm-4.464-12.037v-2.1a1.991 1.991 0 0 1 2-1.984 2.022 2.022 0 0 1 1.772 1.078.626.626 0 1 1 -1.11.58.779.779 0 0 0 -.662-.408.742.742 0 0 0 -.749.75v2.068a.742.742 0 0 0 .749.751.759.759 0 0 0 .75-.735v-.084h-.25a.5.5 0 0 1 0-1h.75a.75.75 0 0 1 .75.75v.334a2 2 0 0 1 -2 1.984 1.991 1.991 0 0 1 -2-1.984zm-6-3.434v3.356a2.069 2.069 0 0 1 -2.079 2.063 2.109 2.109 0 0 1 -1.849-1.169.651.651 0 1 1 1.162-.587.818.818 0 0 0 .687.459.771.771 0 0 0 .779-.783v-3.34a.65.65 0 0 1 1.3 0zm3.09-.65h-1.09a1 1 0 0 0 -1 1v4.444a.625.625 0 1 0 1.25 0v-1.221h.84a2.11 2.11 0 1 0 0-4.223zm0 2.969h-.832l-.006-1.719h.838a.86.86 0 1 1 0 1.719z" })));
exports.default = TruxwebJpgIcon;
