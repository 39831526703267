// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentCarrierQuoteStatusV1 = void 0;
var ShipmentCarrierQuoteStatusV1;
(function (ShipmentCarrierQuoteStatusV1) {
    ShipmentCarrierQuoteStatusV1["CANCELLED"] = "CANCELLED";
    ShipmentCarrierQuoteStatusV1["SHIPPER_DECLINED"] = "SHIPPER_DECLINED";
    ShipmentCarrierQuoteStatusV1["RESPONSE_REQUIRED"] = "RESPONSE_REQUIRED";
    ShipmentCarrierQuoteStatusV1["CONFIRMED"] = "CONFIRMED";
    ShipmentCarrierQuoteStatusV1["DECLINED"] = "DECLINED";
    ShipmentCarrierQuoteStatusV1["UNDER_CLIENT_REVIEW"] = "UNDER_CLIENT_REVIEW";
    ShipmentCarrierQuoteStatusV1["NO_SHIPPER_RESPONSE"] = "NO_SHIPPER_RESPONSE";
    ShipmentCarrierQuoteStatusV1["EXPIRED"] = "EXPIRED";
})(ShipmentCarrierQuoteStatusV1 || (exports.ShipmentCarrierQuoteStatusV1 = ShipmentCarrierQuoteStatusV1 = {}));
