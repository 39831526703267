import { type ReactHookFormSetError, type TCompanyToValidate } from '../../types';
import { type TFunction } from 'next-i18next';
import { validateCompanyDataV2 } from './validateCompanyNamePostalCode';

// eslint-disable-next-line complexity
export const validateCompanyDataForVersion = async (
  company: TCompanyToValidate,
  setError?: ReactHookFormSetError,
  t?: TFunction,
  setIsLoading?: (isLoading: boolean) => void
): Promise<boolean> => {
  const isValid = await validateCompanyDataV2(company);

  if (isValid) return true;

  // If error then we decide what err msg to display...
  switch (company.version) {
    case 2:
      if (!isValid && setError && t && setIsLoading) {
        setError('companyName', {
          message: t('common:companyNameInUse'),
          type: 'manual',
        });
        setIsLoading(false);
      }
      break;
    case 1:
      if (!isValid && setError && t && setIsLoading) {
        setError('companyName', { message: t('common:companyNameInUse'), type: 'manual' });
        setIsLoading(false);
      }
      break;
    default:
      // NOTE: This should never occur
      if (setIsLoading) setIsLoading(false);
      throw new Error(`Invalid company version`);
  }

  return isValid;
};
