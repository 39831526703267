import { type AppState } from '../../stores';
import { TShipmentCommentV1 } from '@truxweb/schemas';

export const selectCommentsByShipmentRef = (
  shipmentRef: string
): ((state: AppState) => TShipmentCommentV1[]) => {
  return (state: AppState) => {
    const comments = state?.shipmentComments?.comments?.[shipmentRef] || [];
    return comments.slice().sort((commentA, commentB) => {
      return new Date(commentB.dateCreated).getTime() - new Date(commentA.dateCreated).getTime();
    });
  };
};
