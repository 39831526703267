"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    toggle: {
        background: 'white',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: 0,
        cursor: 'pointer',
        padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    toggleActive: {
        '&:disabled': {
            cursor: 'unset',
        },
        background: '#EDF8FF',
    },
    toggles: {
        '& > *:last-child > button': {
            borderRadius: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
        },
        '& > *:nth-child(3) > button': {
            borderRadius: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px `,
        },
    },
}));
