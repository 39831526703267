import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  comingSoonDescription: {
    color: '#636363',
    fontSize: '15px',
  },
  comingSoonPrompt: {
    color: theme.palette.primary.light,
    fontSize: '20px',
    fontWeight: 600,
    paddingBottom: '6px',
  },
  comingSoonText: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    background: '#EDF8FF',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    height: '272px',
    justifyContent: 'center',
    padding: '40px 30px',
    width: '634px',
  },
  title: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
