"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountShipperCompany = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const AccountShipperCompany = ({ company, defaultCountryCode, fieldStates, t, updateShipperCompanyData, user, }) => {
    const { control, formState: { isDirty }, handleSubmit, reset, setValue, } = (0, react_hook_form_1.useForm)();
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            yield updateShipperCompanyData(data);
            reset();
            Object.keys(data).forEach((key) => {
                setValue(key, data[key], { shouldDirty: false });
            });
        }
        finally {
            setIsSaving(false);
        }
    }), [setIsSaving, updateShipperCompanyData, reset, setValue]);
    return (react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 8 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 3 },
                                    react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (company === null || company === void 0 ? void 0 : company.name) || '', id: 'companyName', isDisabled: fieldStates.companyName.isDisabled, isRequired: fieldStates.companyName.isRequired, label: t('common:companyName'), shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 3 },
                                    react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (company === null || company === void 0 ? void 0 : company.phone) || '', id: 'companyPhone', label: t('common:companyPhone'), phoneNumberExtension: (company === null || company === void 0 ? void 0 : company.phoneExt) || '', shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: 'phone' }))),
                            (!user || (user === null || user === void 0 ? void 0 : user.isDefault)) && (react_1.default.createElement(react_1.default.Fragment, null,
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                    react_1.default.createElement(ux_1.Box, { mb: 3 },
                                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (company === null || company === void 0 ? void 0 : company.language) || Object.values(schemas_1.ELanguageV1)[0], name: "language", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                                                return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), label: t('common:documentationLanguage'), localizationPrefix: "common:", onChange: onChange, placeholder: t('common:documentationLanguage'), shouldUseModernVariant: true, sourceEnum: schemas_1.ELanguageV1, t: t, value: value || Object.values(schemas_1.ELanguageV1)[0], variant: "outlined" }));
                                            }, rules: { required: false } }))),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                    react_1.default.createElement(ux_1.Box, { mb: 3 },
                                        react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (company === null || company === void 0 ? void 0 : company.financeEmail) || '', id: 'financeEmail', label: t('common:optionalFormField', {
                                                fieldName: t('common:financeEmail'),
                                            }), shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }))))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 3 },
                                    react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (company === null || company === void 0 ? void 0 : company.companyWebsite) || '', id: 'companyWebsite', isRequired: false, label: t('common:optionalFormField', {
                                            fieldName: t('common:companyWebsite'),
                                        }), shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: 'url' }))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 3 },
                                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (company === null || company === void 0 ? void 0 : company.companySize) || Object.values(schemas_1.ECompanySizeV1)[0], name: "companySize", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), label: t('common:optionalFormField', {
                                                    fieldName: t('common:companySize'),
                                                }), onChange: onChange, placeholder: t('common:optionalFormField', {
                                                    fieldName: t('common:companySize'),
                                                }), shouldUseModernVariant: true, sourceEnum: schemas_1.ECompanySizeV1, t: t, value: value || Object.values(schemas_1.ECompanySizeV1)[0], variant: "outlined" }));
                                        }, rules: { required: false } }))),
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
                                    react_1.default.createElement(__1.SaveButton, { buttonText: t('common:submitChanges'), isDisabled: !isDirty, isSaving: isSaving, t: t })))))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true })))));
};
exports.AccountShipperCompany = AccountShipperCompany;
