"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataGridShipperQuoteRowOverlay = void 0;
const __1 = require("..");
const quote_utils_1 = require("@truxweb/quote-utils");
const react_1 = __importStar(require("react"));
const DataGridShipperQuoteRowOverlay_styles_1 = require("./DataGridShipperQuoteRowOverlay.styles");
const REQUEST_SEGMENT_SIZE = 2;
const DataGridShipperQuoteRowOverlay = ({ defaultFilter, handleRouteToQuote, quoteId, quoteRequests, t, timeRemainingInSeconds, }) => {
    const classes = (0, DataGridShipperQuoteRowOverlay_styles_1.useStyles)();
    const redButtonClasses = (0, DataGridShipperQuoteRowOverlay_styles_1.useRedButtonStyles)();
    const greenButtonClasses = (0, DataGridShipperQuoteRowOverlay_styles_1.useGreenButtonStyles)();
    const yellowButtonClasses = (0, DataGridShipperQuoteRowOverlay_styles_1.useYellowButtonStyles)();
    const [filteredQuotes, setFilteredQuotes] = (0, react_1.useState)(quoteRequests);
    const [quoteFilter, setQuoteFilter] = (0, react_1.useState)(defaultFilter);
    const handleFilterQuotes = (0, react_1.useCallback)((_event, newFilter) => {
        setQuoteFilter(newFilter);
        let filterValueMax = 0;
        let filterValueMin = 1800;
        if (newFilter === 'quoteFilter10To30') {
            filterValueMin = 600;
            filterValueMax = 1799;
        }
        else if (newFilter === 'quoteFilterLessThan10') {
            filterValueMin = 0;
            filterValueMax = 599;
        }
        const filteredQuotes = quoteRequests.filter((request) => {
            const filterSeconds = (0, quote_utils_1.getShipperQuoteCountdownValueInSeconds)([request]);
            if (filterValueMin && filterValueMax) {
                return filterSeconds > filterValueMin && filterSeconds < filterValueMax;
            }
            if (filterValueMin && !filterValueMax) {
                return filterSeconds > filterValueMin;
            }
            if (!filterValueMin && filterValueMax) {
                return filterSeconds < filterValueMax;
            }
            return false;
        });
        setFilteredQuotes(filteredQuotes);
    }, [quoteRequests, setFilteredQuotes, setQuoteFilter]);
    const quoteSegments = (0, react_1.useMemo)(() => {
        const segments = Math.round(filteredQuotes.length / REQUEST_SEGMENT_SIZE);
        const segmentedQuotes = [];
        for (let i = 0; i < segments; i++) {
            const sliceStart = REQUEST_SEGMENT_SIZE * i;
            const sliceEnd = sliceStart + REQUEST_SEGMENT_SIZE;
            const segment = filteredQuotes.slice(sliceStart, sliceEnd);
            segmentedQuotes.push(segment);
        }
        return segmentedQuotes;
    }, [filteredQuotes]);
    (0, react_1.useEffect)(() => {
        // If there is no filter being set, determine which
        // filter to select by the time remaining value selected
        if (!defaultFilter && !quoteFilter && timeRemainingInSeconds) {
            let filter = null;
            if (timeRemainingInSeconds > 1800) {
                filter = 'quoteFilterMoreThan30';
            }
            else if (timeRemainingInSeconds > 600) {
                filter = 'quoteFilter10To30';
            }
            else {
                filter = 'quoteFilterLessThan10';
            }
            handleFilterQuotes(null, filter);
        }
    }, [defaultFilter, quoteFilter, timeRemainingInSeconds, handleFilterQuotes]);
    return (react_1.default.createElement(__1.Grid, { alignItems: "center", className: classes.actionsContainer, container: true, direction: "row" },
        react_1.default.createElement(__1.Grid, { item: true },
            react_1.default.createElement(__1.Box, { mr: 3 },
                react_1.default.createElement(__1.ToggleButtonGroup, { exclusive: true, onChange: handleFilterQuotes, size: "small", value: quoteFilter },
                    react_1.default.createElement(__1.ToggleButton, { classes: redButtonClasses, value: "quoteFilterLessThan10" }, t('common:quoteFilterLessThan10')),
                    react_1.default.createElement(__1.ToggleButton, { classes: yellowButtonClasses, value: "quoteFilter10To30" }, t('common:quoteFilter10To30')),
                    react_1.default.createElement(__1.ToggleButton, { classes: greenButtonClasses, value: "quoteFilterMoreThan30" }, t('common:quoteFilterMoreThan30'))))),
        quoteSegments.length === 0 && (react_1.default.createElement(__1.Grid, { item: true },
            react_1.default.createElement(__1.Typography, null, t('common:noResults')))),
        react_1.default.createElement(__1.Grid, { className: classes.segmentsContainer, item: true },
            react_1.default.createElement(__1.Grid, { className: classes.fullHeight, container: true, direction: "row" }, quoteSegments.map((segment, index) => {
                return (react_1.default.createElement(__1.Grid, { className: classes.segmentContainer, item: true, key: index },
                    react_1.default.createElement(__1.Grid, { className: classes.halfHeight, item: true }, segment.map((request) => {
                        const countdownSeconds = (0, quote_utils_1.getShipperQuoteCountdownValueInSeconds)([request]);
                        const countdownMax = (0, quote_utils_1.getShipperQuoteCountdownMaximumInSeconds)([request]);
                        return (react_1.default.createElement("div", { className: classes.requestContainer, key: request.id },
                            react_1.default.createElement("div", null,
                                react_1.default.createElement(__1.NumberCountdown, { countdownId: request.id, countdownSeconds: countdownSeconds, timerMax: countdownMax[0] })),
                            react_1.default.createElement("div", null,
                                react_1.default.createElement("button", { className: classes.carrierButton, onClick: () => {
                                        handleRouteToQuote(quoteId, request.id);
                                    } },
                                    react_1.default.createElement(__1.Grid, { container: true },
                                        react_1.default.createElement(__1.Grid, { item: true },
                                            react_1.default.createElement(__1.Box, { ml: 1, mr: 1 }, request.carrierName)),
                                        react_1.default.createElement(__1.Grid, { item: true }, ">"))))));
                    }))));
            })),
            react_1.default.createElement(__1.Grid, { alignItems: "flex-start", container: true, direction: "row" }))));
};
exports.DataGridShipperQuoteRowOverlay = DataGridShipperQuoteRowOverlay;
