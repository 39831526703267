import { browserSupport, IS_BROWSER_DETECTION_ENABLED } from '../../config';
import { Footer, Header } from '../../components';
import { Grid, useMediaQuery, useScrollTrigger, useTheme } from '@truxweb/ux';
import Bowser from 'bowser';
import classnames from 'classnames';
import { getPageBackground } from '../../utils';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './LegacyLayout.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TLegacyLayoutProps = {
  children?: React.ReactNode;
  isHeaderTransparent?: boolean;
  footerRef?: React.Ref<any>;
  onScroll?: (isScrolled: boolean) => void;
  pageId?: TPageId;
};

type AppScrollType = {
  // eslint-disable-next-line react/no-unused-prop-types
  isScrolled: boolean;
};

type AppBarScrollPropsType = {
  onScroll?: (isScrolled: boolean) => void;
  children: (props: AppScrollType) => React.ReactElement;
  window?: () => Window;
};

export const AppBarScroll = (props: AppBarScrollPropsType): JSX.Element => {
  const { children, onScroll, window } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
    threshold: isMobile ? 24 : 156,
  });

  if (onScroll) {
    onScroll(trigger);
  }

  return children({
    isScrolled: trigger,
  });
};

export const LegacyLayout = ({
  children,
  footerRef,
  isHeaderTransparent,
  onScroll,
  pageId,
}: TLegacyLayoutProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  if (IS_BROWSER_DETECTION_ENABLED && typeof window !== 'undefined') {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.satisfies(browserSupport)) {
      return <h1 className={classes.unsupportedBrowser}>{t('common:unsupportedBrowser')}</h1>;
    }
  }

  const backgroundClass: string | null = getPageBackground(pageId, classes);

  return (
    <AppBarScroll onScroll={onScroll}>
      {({ isScrolled }: AppScrollType): JSX.Element => {
        return (
          <Grid container direction="column" justifyContent="space-between">
            <Grid item>
              <Header
                isScrolled={isScrolled}
                isTransparent={isHeaderTransparent || false}
                pageId={pageId}
              />
            </Grid>
            <Grid
              className={classnames(backgroundClass, classes.container)}
              container
              item
              justifyContent="flex-start"
              style={{ flexGrow: 1 }}
            >
              <main className={classes.content} role="main">
                {children}
              </main>
            </Grid>
            <Grid item>
              <Footer footerRef={footerRef} />
            </Grid>
          </Grid>
        );
      }}
    </AppBarScroll>
  );
};
