import { useDispatch, useSelector } from 'react-redux';
import { getSearchResults } from '../../../actions';
import { selectSearchResults } from '../../../selectors';
import { type TBookingSearchRequestV1 } from '@truxweb/schemas';
import { type TSearchFormParams } from '../../../stores';
import { useEffect } from 'react';

export const useReduxSearchResults = (
  query: TBookingSearchRequestV1 | null,
  isReady: boolean,
  shouldRefetch?: boolean
): TSearchFormParams => {
  const dispatch = useDispatch();
  const searchResults = useSelector(selectSearchResults);

  useEffect(() => {
    if (
      isReady &&
      ((!searchResults.hasLoaded && !searchResults.isLoading && query !== null) ||
        (!searchResults.isLoading && shouldRefetch && query !== null))
    ) {
      dispatch(getSearchResults(query));
    }
  }, [searchResults, dispatch, shouldRefetch, query, isReady]);

  return searchResults;
};
