import { ELanguageV1, TInAppNotificationV1 } from '@truxweb/schemas';
import { TUseActionResponse, useAction } from './useAction';
import { useCallback, useEffect, useState } from 'react';
import { getUserNotifications } from '../actions';
import { useUserData } from './useUserData';

export const useNotifications = (
  language: ELanguageV1,
  shouldForceRefetch?: boolean
): TUseActionResponse<TInAppNotificationV1[]> => {
  const { userData } = useUserData();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (userData) {
      setIsReady(true);
    }
  }, [setIsReady, userData]);

  const action = useCallback(async () => {
    return getUserNotifications(language);
  }, [language]);

  return useAction<TInAppNotificationV1[]>(action, isReady, shouldForceRefetch);
};
