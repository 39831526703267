import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>(() => ({
  container: {
    background: 'orange',
    position: 'relative',
  },
  hideBannerButton: {
    background: 'transparent',
    border: 0,
    color: 'white',
    cursor: 'pointer',
    position: 'absolute',
    right: '80px',
    top: '40%',
  },
}));
