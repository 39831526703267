"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    unreadDot: {
        background: theme.palette.primary.light,
        borderRadius: '100%',
        height: theme.spacing(1),
        width: theme.spacing(1),
    },
}));
