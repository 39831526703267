export enum ERegistrationFormSteps {
  'selectUserType' = 'selectUserType',
  'register' = 'register',
  'registerConfirmation' = 'registerConfirmation',
  'loginToCompleteRegistration' = 'loginToCompleteRegistration',
  'companyInfo' = 'companyInfo',
  'equipment' = 'equipment',
  'lanes' = 'lanes',
  'recap' = 'recap',
  'complete' = 'complete',
}
