"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudRainIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16,24a2.978,2.978,0,0,1-2.121-.879c-1.942-1.813-.137-4.486,1-5.639a1.557,1.557,0,0,1,2.208-.041C17.561,17.953,19,19.613,19,21A3.015,3.015,0,0,1,16,24Zm0-4.759c-.73,1.054-1.39,1.669-.706,2.466A1.01,1.01,0,0,0,17,21,4.473,4.473,0,0,0,16,19.241ZM8,24a2.98,2.98,0,0,1-2.122-.879c-1.941-1.812-.136-4.486,1-5.639a1.573,1.573,0,0,1,2.208-.04c1.171,1.169,2.987,3.847,1.037,5.679h0A2.983,2.983,0,0,1,8,24Zm0-4.759A4.6,4.6,0,0,0,7,21a1.01,1.01,0,0,0,1.707.707h0C9.4,20.915,8.708,20.246,8,19.241Zm-5,0a.99.99,0,0,1-.555-.169A5.535,5.535,0,0,1,1.8,10.43a1,1,0,0,0,.345-.9,8.147,8.147,0,0,1-.033-2.889A7.946,7.946,0,0,1,8.5.137a8.056,8.056,0,0,1,8.734,4.44,1.04,1.04,0,0,0,.743.569c5.824,1.035,8.091,8.769,3.718,12.766a1,1,0,1,1-1.384-1.444,5.538,5.538,0,0,0-2.725-9.362,3.011,3.011,0,0,1-2.158-1.672A6,6,0,0,0,4.086,6.967,6.142,6.142,0,0,0,4.11,9.148a3.005,3.005,0,0,1-.964,2.762,3.523,3.523,0,0,0,.41,5.5A1,1,0,0,1,3,19.242ZM12,17a2.978,2.978,0,0,1-2.121-.879c-1.942-1.812-.137-4.486,1-5.639a1.573,1.573,0,0,1,2.209-.04C13.561,10.953,15,12.613,15,14A3.014,3.014,0,0,1,12,17Zm0-4.759c-.732,1.054-1.39,1.669-.708,2.466A1.01,1.01,0,0,0,13,14,4.487,4.487,0,0,0,12,12.241Z" })));
exports.default = TruxwebCloudRainIcon;
