import PasswordValidator from 'password-validator';

export const paramValidation = (paramName: string, value: string): boolean => {
  switch (paramName) {
    case 'min':
      return value.length >= 8;
    case 'uppercase':
      return /[A-Z]/.test(value);
    case 'lowercase':
      return /[a-z]/.test(value);
    case 'digits':
      return /[0-9]/.test(value);
    default:
      return false;
  }
};

export const passwordSchema = new PasswordValidator()
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits();

export const displayPasswordSchema = ['min', 'uppercase', 'lowercase', 'digits'];
