"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSandwichIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21,18H3a3,3,0,0,0,0,6H21A3,3,0,0,0,21,18Zm0,4H3a1,1,0,0,1,0-2H21A1,1,0,0,1,21,22ZM22.8,5.374,19.687,1.747a4.97,4.97,0,0,0-5.8-1.328L3,5.184A5,5,0,0,0,0,9.765,3.024,3.024,0,0,0,3,13H21a3,3,0,0,0,3-3A6.169,6.169,0,0,0,22.8,5.374ZM14.688,2.251a2.987,2.987,0,0,1,3.48.8c.092.126,3.395,3.909,3.341,3.952H3.834ZM22,10a1,1,0,0,1-1,1H3C1.949,11,1.861,9.776,2.105,9H22Zm2,6a.99.99,0,0,1-1.584.79l-.011.014c-.386-.255-1.284-1.224-1.96-.638a4.011,4.011,0,0,1-4.889,0,.944.944,0,0,0-1.111,0,4.061,4.061,0,0,1-4.889,0,.942.942,0,0,0-1.111,0,4.061,4.061,0,0,1-4.889,0c-.67-.589-1.589.389-1.961.637l-.011-.014A.982.982,0,0,1,.416,15.21L.405,15.2s.573-.423.816-.612a2.946,2.946,0,0,1,3.558,0,2.031,2.031,0,0,0,2.443,0,2.946,2.946,0,0,1,3.557,0,2.031,2.031,0,0,0,2.443,0,2.946,2.946,0,0,1,3.557,0,2.031,2.031,0,0,0,2.443,0,2.946,2.946,0,0,1,3.557,0c.243.189.815.611.816.612l-.011.014A.982.982,0,0,1,24,16Z" })));
exports.default = TruxwebSandwichIcon;
