"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetConfirmation = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const react_hook_form_1 = require("react-hook-form");
const RateSheetConfirmation = ({ confirmationData, fileName, handleCancel, isGlobal, isSaving, onSubmitSuccess, rateSheetName, rateSheetType, shouldAllowOverPalletCount, shouldAllowOverWeight, t, }) => {
    const rateSheetFormControls = (0, react_hook_form_1.useForm)();
    const [fixedCities, fixedRegions] = (0, react_1.useMemo)(() => {
        const defaultResponse = [[], []];
        if (!confirmationData)
            return defaultResponse;
        const { entriesToFix, regionsToConfirm } = confirmationData;
        if (!entriesToFix && !regionsToConfirm)
            return defaultResponse;
        const cities = [];
        const regions = [];
        (entriesToFix || []).forEach((record) => {
            if (record.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.CITY) {
                cities.push(record);
            }
            if (record.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION) {
                regions.push(record);
            }
        });
        (regionsToConfirm || []).forEach((record) => {
            if (!record.shouldUseGlobalRegion) {
                if (record.boundaryDefinition.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.CITY) {
                    cities.push(record);
                }
                if (record.boundaryDefinition.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION) {
                    regions.push(record);
                }
            }
        });
        return [cities, regions];
    }, [confirmationData]);
    return (react_1.default.createElement("form", { onSubmit: rateSheetFormControls.handleSubmit(onSubmitSuccess) },
        react_1.default.createElement(ux_1.Box, { mb: 3 },
            react_1.default.createElement(__1.SimpleList, { items: [
                    { header: t('common:name'), value: rateSheetName },
                    { header: t('common:fileName'), value: fileName },
                    fixedCities.length !== 0
                        ? { header: t('common:citiesToAdd'), value: fixedCities.length }
                        : null,
                    fixedRegions.length !== 0
                        ? { header: t('common:regionsToAdd'), value: fixedRegions.length }
                        : null,
                    rateSheetType === schemas_1.ERateSheetTypeV1.PALLET_WEIGHT
                        ? {
                            header: t('common:shouldAllowOverWeight'),
                            value: (shouldAllowOverWeight || false).toString(),
                        }
                        : null,
                    rateSheetType === schemas_1.ERateSheetTypeV1.PALLET_WEIGHT
                        ? {
                            header: t('common:shouldAllowOverPalletCount'),
                            value: (shouldAllowOverPalletCount || false).toString(),
                        }
                        : null,
                    { header: t('common:shouldSetAsGlobal'), value: (isGlobal || false).toString() },
                ] })),
        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:back'))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }, t('common:save'))))));
};
exports.RateSheetConfirmation = RateSheetConfirmation;
