"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebProtractorIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.977,11.264h0C21.613,5.724,16.474,1.041,10.731,1A2,2,0,0,0,9,0H7A4,4,0,0,0,3,4V20a4,4,0,0,0,4,4H9a2,2,0,0,0,1.731-1H11a11.041,11.041,0,0,0,8.028-3.481A10.915,10.915,0,0,0,21.977,11.264Zm-4.409,6.887A8.911,8.911,0,0,1,11,21H10a1,1,0,0,0-1,1H7a2,2,0,0,1-2-2V19H6a1,1,0,0,0,0-2H5V15H6a1,1,0,0,0,0-2H5V11H6A1,1,0,0,0,6,9H5V7H6A1,1,0,0,0,6,5H5V4A2,2,0,0,1,7,2H9a1,1,0,0,0,1,1h.656c4.757,0,9.027,3.844,9.324,8.394A8.925,8.925,0,0,1,17.568,18.151ZM11,7A2,2,0,0,0,9,9v6a2.013,2.013,0,0,0,1.976,1.989,5.09,5.09,0,0,0,5-4.5A5,5,0,0,0,11,7Zm0,8V9a3,3,0,0,1,2.986,3.3A3.089,3.089,0,0,1,11,15Z" })));
exports.default = TruxwebProtractorIcon;
