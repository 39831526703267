"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPineappleIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.4,10.625c-.715-2.048-2.239-3.7-5.343-4.335A4.461,4.461,0,0,1,17.447,2.9a1,1,0,0,0-.894-1.79A6.53,6.53,0,0,0,13,6.037V1a1,1,0,0,0-2,0V6.037A6.53,6.53,0,0,0,7.447,1.105,1,1,0,1,0,6.553,2.9a4.461,4.461,0,0,1,2.393,3.4C3.794,7.345,3,11.2,3,15c0,3.456.661,6.963,4.665,8.361a.973.973,0,0,0,.226.077C16.4,25.439,21.33,22.247,21,15A12.1,12.1,0,0,0,20.4,10.625ZM16.2,8.7a4.114,4.114,0,0,1,2.149,2.128l-2.8,2.8-2.138-2.138Zm-2.066,6.343L12,17.18,9.859,15.041,12,12.9ZM12,8a15.689,15.689,0,0,1,1.96.112L12,10.075,10.035,8.112A15.773,15.773,0,0,1,12,8Zm-4.206.7,2.79,2.79L8.445,13.627l-2.8-2.8A4.11,4.11,0,0,1,7.794,8.7ZM5.1,16.972a19.249,19.249,0,0,1-.008-3.87l1.938,1.939Zm2.753,4.352a4.12,4.12,0,0,1-2.181-2.1l2.772-2.773,2.138,2.139ZM12,22a15.884,15.884,0,0,1-1.889-.105L12,20.008,13.884,21.9A15.809,15.809,0,0,1,12,22Zm4.143-.675-2.732-2.731,2.139-2.139,2.775,2.776A4.119,4.119,0,0,1,16.143,21.325ZM18.9,16.976l-1.935-1.935L18.907,13.1A19.329,19.329,0,0,1,18.9,16.976Z" })));
exports.default = TruxwebPineappleIcon;
