import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierAccessorialV1 } from '@truxweb/schemas';

export const getCarrierAccessorialData = async (): Promise<TCarrierAccessorialV1[]> => {
  const results = await makeRequestToApiGateway('carrierGetV1AccessorialCarrier', null, {
    'Content-Type': 'application/json',
  });

  return results as TCarrierAccessorialV1[];
};
