import { deleteFromLocalStorage, getItemFromLocalStorageAsType, saveToLocalStorage } from '..';
import { BOOKING_CONFIRMATION } from '../../config';
import { TBookingSearchResultV1 } from '@truxweb/schemas';

export const serializeBookings = (selectedBookings: TBookingSearchResultV1[]): void => {
  saveToLocalStorage(BOOKING_CONFIRMATION, selectedBookings);
};
export const restoreBookings = (): TBookingSearchResultV1[] => {
  return getItemFromLocalStorageAsType<TBookingSearchResultV1[]>(BOOKING_CONFIRMATION);
};

export const clearBookings = () => {
  return deleteFromLocalStorage(BOOKING_CONFIRMATION);
};

export * from './formManagement';
