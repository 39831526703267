"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebThunderstormMoonIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15,24a1,1,0,0,1-.849-1.527L15.684,20H13.615a1.614,1.614,0,0,1-1.546-2.082,1.014,1.014,0,0,1,.106-.235l1.973-3.207a1,1,0,1,1,1.7,1.048L14.328,18H16.4a1.6,1.6,0,0,1,1.336,2.489L15.85,23.527A1,1,0,0,1,15,24Zm5-1a1,1,0,0,1-.222-1.975,3.089,3.089,0,0,0,.007-5.921A2.646,2.646,0,0,1,17.7,13.471a4,4,0,0,0-7.609,2.351,2.018,2.018,0,0,1-1.179,2.306,1.5,1.5,0,0,0,.168,2.816A1.656,1.656,0,0,0,9.458,21H11a1,1,0,0,1,0,2H9.429a3.812,3.812,0,0,1-.86-.121,3.5,3.5,0,0,1-.45-6.589,6.345,6.345,0,0,1,.056-2.731A6,6,0,0,1,19.543,12.7c.136.326.286.359.587.427l.176.042a5.088,5.088,0,0,1-.083,9.8A1.019,1.019,0,0,1,20,23Zm-15.5-6a1,1,0,0,1-.576-.184A9.3,9.3,0,0,1,.051,8.272,9.351,9.351,0,0,1,7.778.117a10,10,0,0,1,3.875.158,2.124,2.124,0,0,1,.943,3.6,8.029,8.029,0,0,0-2.539,4.283A1,1,0,0,1,8.08,7.847a9.8,9.8,0,0,1,3.147-5.436.094.094,0,0,0,.025-.1.119.119,0,0,0-.087-.092,8.051,8.051,0,0,0-3.072-.123A7.32,7.32,0,0,0,2.041,8.478a7.2,7.2,0,0,0,3.031,6.706A1,1,0,0,1,4.494,17ZM15,6a1,1,0,0,0,2,0A1,1,0,0,0,15,6Zm4-3a1,1,0,0,0,2,0A1,1,0,0,0,19,3Zm2,6a1,1,0,0,0,2,0A1,1,0,0,0,21,9Z" })));
exports.default = TruxwebThunderstormMoonIcon;
