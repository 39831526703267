"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
    },
    timerText: {
        fontSize: '0.75em',
    },
    timerTextWrapper: {
        marginTop: theme.spacing(1.5),
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '185px',
    },
}));
