import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    button: {
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.spacing(1.5),
      },
    },
  };
});
