/* eslint-disable complexity */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import React, { useState } from 'react';
import { LandingPageValuePropSelectorItem } from '..';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const LandingPageValuePropSelector = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargeTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const content = [
    t('common:valuePropSelector1'),
    t('common:valuePropSelector2'),
    t('common:valuePropSelector3'),
  ];
  return (
    <>
      {isMobile && (
        <Grid container direction="column">
          <LandingPageValuePropSelectorItem
            activeIndex={activeIndex}
            index={0}
            setActive={setActiveIndex}
          />
          {activeIndex === 0 && (
            <Box mt={2}>
              <Typography variant="body2"> {content[activeIndex]}</Typography>
            </Box>
          )}
          <Box mb={2} mt={2}>
            <LandingPageValuePropSelectorItem
              activeIndex={activeIndex}
              index={1}
              setActive={setActiveIndex}
            />
            {activeIndex === 1 && (
              <Box mt={2}>
                <Typography variant="body2"> {content[activeIndex]}</Typography>
              </Box>
            )}
          </Box>
          <LandingPageValuePropSelectorItem
            activeIndex={activeIndex}
            index={2}
            setActive={setActiveIndex}
          />
          {activeIndex === 2 && (
            <Box mt={2}>
              <Typography variant="body2"> {content[activeIndex]}</Typography>
            </Box>
          )}
        </Grid>
      )}
      {!isMobile && (
        <Grid container spacing={2}>
          <Grid container direction="column" item justifyContent="center" xs={6}>
            <LandingPageValuePropSelectorItem
              activeIndex={activeIndex}
              index={0}
              setActive={setActiveIndex}
            />
            <Box mb={2} mt={2}>
              <LandingPageValuePropSelectorItem
                activeIndex={activeIndex}
                index={1}
                setActive={setActiveIndex}
              />
            </Box>
            <LandingPageValuePropSelectorItem
              activeIndex={activeIndex}
              index={2}
              setActive={setActiveIndex}
            />
          </Grid>
          {!isTablet ? <Grid item xs={1} /> : <Box ml={isDesktop ? 12 : isLargeTablet ? 4 : 2} />}
          <Grid alignItems="center" container item justifyContent="flex-start" xs>
            <Typography variant="body2"> {content[activeIndex]}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
