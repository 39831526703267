// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAcctTransactionTypeV1 = void 0;
var BankAcctTransactionTypeV1;
(function (BankAcctTransactionTypeV1) {
    BankAcctTransactionTypeV1["ACH"] = "ACH";
    BankAcctTransactionTypeV1["EFT"] = "EFT";
})(BankAcctTransactionTypeV1 || (exports.BankAcctTransactionTypeV1 = BankAcctTransactionTypeV1 = {}));
