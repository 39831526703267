"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    statusText: {
        background: ({ state }) => {
            if (state === 'positive')
                return '#D5F2D6';
            if (state === 'warning')
                return '#FFF6C7';
            if (state === 'negative')
                return '#FED4D4';
            if (state === 'acknowledged')
                return '#EDF8FF';
            if (state === 'pending')
                return theme.palette.secondary.dark;
            if (state === 'ready')
                return theme.palette.primary.light;
            if (state === 'approved')
                return '#4CAF50';
            return '#DCDCDC';
        },
        borderRadius: theme.spacing(0.5),
        color: ({ state }) => {
            if (state === 'positive')
                return '#4CAF50';
            if (state === 'warning')
                return theme.palette.secondary.dark;
            if (state === 'negative')
                return '#E94141';
            if (state === 'acknowledged')
                return theme.palette.primary.light;
            if (state === 'pending')
                return '#FFF6C7';
            if (state === 'ready')
                return '#EDF8FF';
            if (state === 'approved')
                return '#D5F2D6';
            return theme.palette.grey[600];
        },
        height: `${theme.spacing(3)}px`,
        lineHeight: `${theme.spacing(3)}px`,
        padding: `0 ${theme.spacing(1.5)}px`,
        textAlign: 'center',
    },
}));
