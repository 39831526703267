import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyLocations } from '../../../actions';
import { selectCompanyLocations } from '../../../selectors';
import type { TCompanyLocation } from '../../../stores';
import { type TReducerLoading } from '../../../types';
import { useEffect } from 'react';

export const useCompanyLocations = (
  shouldRefetch?: boolean
): TReducerLoading & { data: TCompanyLocation[] } => {
  const dispatch = useDispatch();
  const companyLocationList = useSelector(selectCompanyLocations());

  useEffect(() => {
    if (
      (!companyLocationList.hasLoaded && !companyLocationList.isLoading) ||
      (!companyLocationList.isLoading && shouldRefetch)
    ) {
      // NOTE: Always force a fresh list of locations when refetching
      dispatch(fetchCompanyLocations(true));
    }
  }, [companyLocationList, dispatch, shouldRefetch]);

  return companyLocationList;
};
