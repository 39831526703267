"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMenuStyles = exports.usePrimaryLightFilledStyles = exports.usePrimaryLightStyles = exports.useGrey600FilledStyles = exports.useGrey600Styles = void 0;
const __1 = require("..");
exports.useGrey600Styles = (0, __1.makeStyles)((theme) => ({
    deleteIcon: {
        position: 'relative',
    },
    label: {
        border: 'none',
        height: theme.spacing(6),
    },
    root: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: '6.5px 6.5px',
        color: theme.palette.grey[600],
        height: '75px',
        justifyContent: 'left',
        minWidth: `${theme.spacing(41.5)}px`,
        paddingRight: theme.spacing(2),
        textAlign: 'left',
    },
}));
exports.useGrey600FilledStyles = (0, __1.makeStyles)((theme) => ({
    filled: {
        '& .MuiFilledInput-input': {
            padding: '12px',
        },
        '& :focus ,:hover': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '6.5px 6.5px',
            boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.15)',
        },
        backgroundColor: theme.palette.common.white,
        borderRadius: '6.5px 6.5px',
        boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.15)',
        transition: 'none',
    },
}));
exports.usePrimaryLightStyles = (0, __1.makeStyles)((theme) => ({
    deleteIcon: {
        color: theme.palette.primary.light,
        position: 'relative',
    },
    label: {
        border: 'none',
        height: theme.spacing(6),
    },
    root: {
        backgroundColor: 'rgba(198, 231, 249, 0.6)',
        borderRadius: '6.5px 6.5px',
        color: theme.palette.primary.light,
        height: '75px',
        justifyContent: 'left',
        minWidth: `${theme.spacing(39)}px`,
        paddingRight: theme.spacing(2),
        textAlign: 'left',
    },
}));
exports.usePrimaryLightFilledStyles = (0, __1.makeStyles)((theme) => ({
    filled: {
        '& .MuiFilledInput-input': {
            padding: '12px',
        },
        '& :focus ,:hover': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '6.5px 6.5px',
            boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.15)',
        },
        backgroundColor: theme.palette.common.white,
        borderRadius: '6.5px 6.5px',
        boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.15)',
        transition: 'none',
    },
}));
exports.useMenuStyles = (0, __1.makeStyles)((theme) => ({
    root: {
        color: theme.palette.grey[600],
    },
}));
