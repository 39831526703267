/* eslint-disable complexity */
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { Box, Grid, TMuiVariant, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import classnames from 'classnames';
import Cloud from '../../public/assets/images/landingPage/cloud.svg';
import Logo1 from '../../public/assets/images/landingPage/caroussel-logo-AIExpress-colour.svg';
import Logo2 from '../../public/assets/images/landingPage/caroussel-logo-morneau-colour.svg';
import Logo3 from '../../public/assets/images/landingPage/caroussel-logo-TYT-colour.svg';
import Logo4 from '../../public/assets/images/landingPage/caroussel-logo-routhier-colour.svg';
import Logo5 from '../../public/assets/images/landingPage/caroussel-logo-breault-colour.svg';
import Logo6 from '../../public/assets/images/landingPage/caroussel-logo-energy-colour.svg';
import Logo7 from '../../public/assets/images/landingPage/caroussel-logo-contrans-colour.svg';
import Logo8 from '../../public/assets/images/landingPage/caroussel-logo-vitesse-colour.svg';
import NetworkSize from '../../public/assets/images/landingPage/NetworkSize.svg';
import React from 'react';
import StrongNetwork from '../../public/assets/images/landingPage/landing-page-strong-network.svg';
import StrongNetworkMobile from '../../public/assets/images/landingPage/landing-page-strong-network-mobile.svg';
import { useLandingPageMarginStyles } from '../../styles/LandingPageMargins.styles';
import { useStyles } from './LandingPageStrongNetwork.styles';
import { useTranslation } from 'next-i18next';

SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);

const REQUIRED_NAMESPACES = ['common'];

export const LandingPageStrongNetwork = (): JSX.Element => {
  const classes = useStyles();
  const margins = useLandingPageMarginStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isLargeMobile = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.xs + 200));
  const notMobile = useMediaQuery(theme.breakpoints.between('sm', 'xl'));
  const isPhone = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs + 100));
  const isSmallTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isWide = useMediaQuery(theme.breakpoints.up('xl'));

  const logos = [
    <Logo1
      className={classes.logoCarrier}
      key={`client-logo-1`}
      width={isMobile ? '70%' : '100%'}
    />,
    <Logo2 className={classes.logoCarrier} height={'100%'} key={`client-logo-2`} width={'100%'} />,
    <Logo3 className={classes.logoCarrier} height={'100%'} key={`client-logo-3`} width={'100%'} />,
    <Logo4 className={classes.logoCarrier} height={'100%'} key={`client-logo-4`} width={'70%'} />,
    <Logo5
      className={classes.logoCarrier}
      height={isMobile ? '130%' : '100%'}
      key={`client-logo-5`}
    />,
    <Logo6 className={classes.logoCarrier} height={'100%'} key={`client-logo-6`} width={'100%'} />,
    <Logo7
      className={classes.logoCarrier}
      height={'100%'}
      key={`client-logo-7`}
      width={isMobile ? '70%' : '100%'}
    />,
    <Logo8
      className={classnames(classes.logoCarrier, classes.logoCarrier8)}
      height={isMobile ? '120%' : '100%'}
      key={`client-logo-8`}
      width={'100%'}
    />,
  ];
  let variant: TMuiVariant = 'h1';
  let truckVariant: TMuiVariant = 'body2';
  if (isSmallTablet || isMobile) {
    truckVariant = 'body1';
    variant = 'body2';
  } else if (isTablet) {
    variant = 'h3';
  } else if (isDesktop) {
    truckVariant = 'h3';
  } else if (isWide) {
    truckVariant = 'h2';
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.image}>
          {isPhone ? (
            <StrongNetworkMobile height={'100%'} width={'100%'} />
          ) : (
            <StrongNetwork height={'100%'} width={'100%'} />
          )}
        </Box>
        {!isSmallTablet && (
          <Box className={classes.cloud}>
            <Cloud height={'100%'} width={'100%'} />
          </Box>
        )}
        <Box
          className={classnames(classes.textWrapper, margins.landingPageMarginRight)}
          p={isMobile ? 2 : isLargeMobile ? 5 : 6}
        >
          <Grid
            alignItems="flex-end"
            className={classes.gridContainer}
            container
            direction="column"
          >
            <Typography className={classes.network} variant={variant}>
              {t('common:strongNetwork')}
            </Typography>
            <Box
              className={classes.number}
              mb={isSmallTablet || isMobile ? 1 : 2}
              mt={isSmallTablet || isMobile ? 2 : 4}
            >
              <NetworkSize height={'100%'} width={'100%'} />
            </Box>
            <Typography className={classes.truckAccessible} variant={truckVariant}>
              {t('common:trucksAccessible')}
            </Typography>
          </Grid>
        </Box>
      </Box>
      <Grid
        alignItems="center"
        className={classes.trustedPartnerWrapper}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography
          align="center"
          className={classes.trustedPartners}
          variant={isSmallTablet ? 'h3' : 'h2'}
        >
          {t('common:ourTrustedPartners')}
        </Typography>
        <Grid
          alignItems="center"
          className={classes.logos}
          container
          item
          justifyContent="space-evenly"
          wrap="nowrap"
        >
          {!notMobile && (
            <Swiper
              autoplay={{ delay: 3000 }}
              centeredSlides={true}
              className={classes.swiper}
              coverflowEffect={{
                depth: 100,
                modifier: 1,
                rotate: 50,
                slideShadows: false,
                stretch: 0,
              }}
              grabCursor={true}
              pagination={true}
              slidesPerView={'auto'}
            >
              {logos.map((logo, index) => {
                return (
                  <SwiperSlide className={classes.logoWrap} key={`swiper${index}`}>
                    {logo}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          {notMobile && (
            <Swiper
              autoplay={{ delay: 3000 }}
              className={classes.swiper}
              pagination={true}
              slidesPerView={'auto'}
            >
              <SwiperSlide className={classes.logoWrap}>
                <Grid alignItems="center" container wrap="nowrap">
                  {logos.map((logo, index) => {
                    if (index < 4) {
                      return (
                        <Grid className={classes.logo} item key={`logos-${index}`} xs={3}>
                          {logo}
                        </Grid>
                      );
                    }
                    return <Box key={`null-${index}`}></Box>;
                  })}
                </Grid>
              </SwiperSlide>
              <SwiperSlide className={classes.logoWrap}>
                <Grid alignItems="center" container wrap="nowrap">
                  {logos.map((logo, index) => {
                    if (index >= 4) {
                      return (
                        <Grid className={classes.logo} item key={`logos-${index}`} xs={3}>
                          {logo}
                        </Grid>
                      );
                    }
                    return <Box key={`null${index}`}></Box>;
                  })}
                </Grid>
              </SwiperSlide>
            </Swiper>
          )}
        </Grid>
      </Grid>
    </>
  );
};
