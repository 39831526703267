import { makeRequestToApiGateway } from '../../../apiUtils';
import { TBankAccountResponseV2 } from '@truxweb/schemas';

export const fetchBankAccountDetailsV2ById = async (
  accountId: string
): Promise<TBankAccountResponseV2> => {
  const results = await makeRequestToApiGateway(
    'billingGetV2BankacctById',
    { id: accountId },
    {
      'Content-Type': 'application/json',
    }
  );
  return results as TBankAccountResponseV2;
};
