// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CspUserStatusV1 = exports.CspUserRoleV1 = exports.LanguageV1 = void 0;
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var CspUserRoleV1;
(function (CspUserRoleV1) {
    CspUserRoleV1["ADMIN"] = "ADMIN";
    CspUserRoleV1["DEVELOPMENT"] = "DEVELOPMENT";
    CspUserRoleV1["FINANCE"] = "FINANCE";
    CspUserRoleV1["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
})(CspUserRoleV1 || (exports.CspUserRoleV1 = CspUserRoleV1 = {}));
var CspUserStatusV1;
(function (CspUserStatusV1) {
    CspUserStatusV1["Active"] = "ACTIVE";
    CspUserStatusV1["Inactive"] = "INACTIVE";
})(CspUserStatusV1 || (exports.CspUserStatusV1 = CspUserStatusV1 = {}));
