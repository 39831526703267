"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentStatusDropdown = void 0;
const __1 = require("..");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const CAUTIONED_STATUS_CHANGE = [schemas_1.EShipmentStatusV1.CANCELLED];
const ShipmentStatusDropdown = ({ handleStatusChange, hasPermissionToAdministerShipment, hasPermissionToChangeShpimentStatus, shipment, t, }) => {
    const { status } = shipment;
    const [isDialogShown, setIsDialogShown] = (0, react_1.useState)(false);
    const [dialogStatus, setDialogStatus] = (0, react_1.useState)(null);
    const shipmentStatusOptionFilter = (0, react_1.useCallback)((enumKey) => {
        if (hasPermissionToAdministerShipment) {
            if (shipment.status === schemas_1.EShipmentStatusV1.PROCESSING_FAILURE) {
                return ![
                    schemas_1.EShipmentStatusV1.AWAITING_CARRIER_INVOICE,
                    schemas_1.EShipmentStatusV1.PENDING_APPROVAL,
                    schemas_1.EShipmentStatusV1.PROCESSING_FAILURE,
                    schemas_1.EShipmentStatusV1.COMPLETED,
                ].includes(enumKey);
            }
            return ![
                schemas_1.EShipmentStatusV1.AWAITING_CARRIER_INVOICE,
                schemas_1.EShipmentStatusV1.PENDING_APPROVAL,
                schemas_1.EShipmentStatusV1.PROCESSING_FAILURE,
                schemas_1.EShipmentStatusV1.COMPLETED,
                schemas_1.EShipmentStatusV1.READY_FOR_PROCESSING,
            ].includes(enumKey);
        }
        return [
            schemas_1.EShipmentStatusV1.PICKUP_READY,
            schemas_1.EShipmentStatusV1.IN_TRANSIT,
            schemas_1.EShipmentStatusV1.DELIVERED,
        ].includes(enumKey);
    }, [hasPermissionToAdministerShipment, shipment]);
    const shipmentStatusRollup = (0, react_1.useCallback)((status) => {
        if (hasPermissionToAdministerShipment)
            return status;
        if ([schemas_1.EShipmentStatusV1.DELIVERED].includes(status)) {
            return schemas_1.EShipmentStatusV1.IN_TRANSIT;
        }
        return schemas_1.EShipmentStatusV1.PICKUP_READY;
    }, [hasPermissionToAdministerShipment]);
    const handleDialogClose = (0, react_1.useCallback)(() => {
        setIsDialogShown(false);
    }, [setIsDialogShown]);
    const handleDialogConfirm = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        handleDialogClose();
        if (dialogStatus) {
            yield handleStatusChange(dialogStatus);
        }
    }), [handleStatusChange, handleDialogClose, dialogStatus]);
    if (!hasPermissionToChangeShpimentStatus)
        return null;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.ShipmentStatusChangeConfirmationDialog, { isDialogShown: isDialogShown, onClose: handleDialogClose, onConfirm: handleDialogConfirm, status: dialogStatus, t: t }),
        react_1.default.createElement(__1.EnumSelect, { localizationPrefix: 'shipments:shipmentStatus-', onChange: (event) => {
                if (CAUTIONED_STATUS_CHANGE.includes(event.target.value)) {
                    // If we are setting this shipment to in dispute
                    setDialogStatus(event.target.value);
                    setIsDialogShown(true);
                }
                else {
                    handleStatusChange(event.target.value);
                }
            }, optionsFilter: shipmentStatusOptionFilter, sourceEnum: schemas_1.EShipmentStatusV1, t: t, value: shipmentStatusRollup(status || schemas_1.EShipmentStatusV1.REQUIRES_DOCUMENTS) })));
};
exports.ShipmentStatusDropdown = ShipmentStatusDropdown;
