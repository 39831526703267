import { Box, Grid, useMediaQuery, useTheme } from '@truxweb/ux';
import { DesktopFooter, MobileFooter, ResponsiveFooterLegal } from '..';
import { useFooterPalette, usePermissions } from '../../hooks';
import { EPermissionV1 } from '@truxweb/schemas';
import React from 'react';
import TruxwebWings from '../../public/assets/images/logos/truxweb-wings.svg';
import { useStyles } from './ResponsiveFooter.styles';

type TResponsiveFooterProps = {
  setModalEmail?: (arg: string) => void;
};

export const ResponsiveFooter = ({ setModalEmail }: TResponsiveFooterProps): JSX.Element => {
  const footerPalette = useFooterPalette();
  const classes = useStyles({ footerPalette });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [shipperPermission, haveShipperPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_SHIPPER_PAGE,
  ]);

  const [carrierPermission, haveCarrierPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_CARRIER_PAGE,
  ]);

  return (
    <>
      <Grid alignItems="center" container direction="column" item xs>
        <Grid alignItems="center" className={classes.wrapper} container direction="column">
          <Grid container justifyContent="flex-start">
            <Box mb={3} mt={6}>
              <TruxwebWings
                height={theme.spacing(isMobile ? 4 : 5)}
                width={theme.spacing(isMobile ? 7 : 9)}
              />
            </Box>
          </Grid>
          {isMobile && <MobileFooter setModalEmail={setModalEmail} />}
          {!isMobile && (
            <DesktopFooter
              areCarrierPermissionsEvaluated={haveCarrierPermissionsEvaluated}
              areShipperPermissionsEvaluated={haveShipperPermissionsEvaluated}
              hasCarrierPermission={carrierPermission}
              hasShipperPermission={shipperPermission}
              setModalEmail={setModalEmail}
            />
          )}
          <ResponsiveFooterLegal />
        </Grid>
      </Grid>
    </>
  );
};
