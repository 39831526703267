"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCakeWeddingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,22.01H22V17a5,5,0,0,0-3-4.576V12a4.98,4.98,0,0,0-2-3.975L16.161,3.8A1,1,0,0,0,15.18,3h-.36a1,1,0,0,0-.981.8L13.2,7H10.25L11,4a1,1,0,0,0-1-1H8A1,1,0,0,0,7,4l.873,3.493A4.993,4.993,0,0,0,5,12v.424A5,5,0,0,0,2,17v5.01H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2ZM10,9h4a3,3,0,0,1,3,3H7A3,3,0,0,1,10,9ZM7,14H17a3,3,0,0,1,3,3v.98c-.936-.1-1.5-.7-1.5-.98a1,1,0,0,0-2,0c0,.344-.682,1-1.75,1C13.661,18,13,17.306,13,17a1,1,0,0,0-2,0c0,.344-.682,1-1.75,1-1.089,0-1.75-.694-1.75-1a1,1,0,0,0-2,0c0,.316-.579.888-1.5.981V17A3,3,0,0,1,7,14ZM4,19.979A4.156,4.156,0,0,0,6.5,19a4.309,4.309,0,0,0,5.5.015A4.309,4.309,0,0,0,17.5,19a4.156,4.156,0,0,0,2.5.978V22.01H4Z" }),
    React.createElement("circle", { cx: 9, cy: 1, r: 1 }),
    React.createElement("circle", { cx: 15, cy: 1, r: 1 })));
exports.default = TruxwebCakeWeddingIcon;
