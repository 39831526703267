import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  fullHeight: {
    height: '100%',
  },
  imageContainer: {
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(78),
    },
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(65),
    },
    [theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)]:
      {
        height: theme.spacing(31),
      },
  },
  imageItem: {
    alignSelf: 'flex-start',
    maxHeight: '90%',
    paddingLeft: '',
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(12),
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(7),
    },
    [theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)]:
      {
        paddingLeft: theme.spacing(0),
        width: '50%',
      },
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      padding: theme.spacing(5),
    },
  },
  mobileButtonsWrapper: {
    display: 'flex',
    height: theme.spacing(4),
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    position: 'relative',
    width: '100%',
    zIndex: 11,
  },
  slideContentContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'hidden',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)]:
      {
        display: 'flex',
        flexDirection: 'row',
      },
  },
  slideWrap: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      minHeight: theme.spacing(90),
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: theme.spacing(76),
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      minHeight: '100%',
    },
  },
  spacingItem: {
    height: '100px',
    width: '100%',
  },
  swiper: {
    '& .swiper-pagination ': {
      [theme.breakpoints.between(
        theme.breakpoints.values.largeMobile,
        theme.breakpoints.values.sm
      )]: {
        width: 'auto',
      },
    },
    '& .swiper-pagination .swiper-pagination-bullet': {
      backgroundColor: theme.palette.common.white,
      height: theme.spacing(2),
      margin: `0 ${theme.spacing(1)}px`,
      opacity: 1,
      width: theme.spacing(2),

      [theme.breakpoints.between(
        theme.breakpoints.values.largeMobile,
        theme.breakpoints.values.sm
      )]: {
        height: theme.spacing(1.5),
        width: theme.spacing(1.5),
      },
      [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
        height: theme.spacing(1),
        margin: `0 ${theme.spacing(0.5)}px`,
        width: theme.spacing(1),
      },
    },
    '& .swiper-pagination .swiper-pagination-bullet-active': {
      backgroundColor: theme.palette.secondary.main,
    },

    height: '100%',
  },
  swiperButton: {
    background: 'none!important',
    border: 'none',
    cursor: 'pointer',
    padding: '0!important',
  },
  swiperContainer: {
    [theme.breakpoints.down('xl')]: {
      padding: `${theme.spacing(13)}px 0 ${theme.spacing(13)}px 0`,
    },
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(8)}px 0 ${theme.spacing(5)}px 0`,
    },
    [theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)]:
      {
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(12)}px ${theme.spacing(6)}px`,
      },
    width: '100%',
  },
  titleFont: {
    color: theme.palette.common.white,
  },
  titleItem: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(59),
    },
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(46),
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(43),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(35),
    },
    [theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)]:
      {
        position: 'relative',
        textAlign: 'start',
        top: '30px',
        width: '40%',
      },
  },
  whiteFont: {
    color: theme.palette.common.white,
  },
}));
