"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaneMapLocation = void 0;
/* eslint-disable max-lines */
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const geopoliticalCapitals_1 = require("./geopoliticalCapitals");
// eslint-disable-next-line complexity
const LaneMapLocation = ({ applicationTheme, drawingMode, focusOnLocation, geocodeByAddress, geocodeByPlaceId, googleMapsApiKey, handleAddLocationShape, handleChangeLocationMode, handleDeleteLocationShape, handleError, handleExpansion, handleSetAddress, handleSetCenter, handleSetDrawingMode, handleSetLocationZone, handleToggleLocationShape, hasError, language, locationAddress, locationAddressName, locationCoords, locationMode, locationShapes, locationType, mapReference, selectedLocationType, shouldUseNativeSelect, t, zones, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const boundsShapes = (0, react_1.useMemo)(() => {
        return locationShapes.filter((shape) => {
            return shape.type !== 'marker';
        }).length;
    }, [locationShapes]);
    const buttons = locationType === 'origin' ? (react_1.default.createElement(ux_1.Box, { mt: 1 },
        react_1.default.createElement(ux_1.Button, { color: "primary", onClick: () => {
                handleExpansion('destination', locationMode);
            }, variant: "contained" },
            t('common:forwardToDestination'),
            " \u2192"))) : (react_1.default.createElement(ux_1.Box, { mt: 1 },
        react_1.default.createElement(ux_1.Button, { color: "primary", onClick: () => {
                handleExpansion('origin', locationMode);
            }, variant: "contained" },
            "\u2190 ",
            t('common:backToOrigin'))));
    const handleGeopoliticalAddress = (0, react_1.useCallback)((country, province) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        if (country && province) {
            const [result] = yield geocodeByAddress(`${geopoliticalCapitals_1.geopoliticalCapitals[country][province]}, ${province}, ${country}`);
            handleSetCenter({
                latitude: result.geometry.location.lat(),
                longitude: result.geometry.location.lng(),
            });
            const address = (0, utils_1.convertGeocodeResultToAddress)(result);
            handleSetAddress(result.formatted_address, address);
        }
        else {
            handleSetAddress(country, {
                addressLineOne: '',
                city: '',
                contact: '',
                country: country,
                email: '',
                name: '',
                phone: '',
                postalCode: '',
                province,
            });
        }
        setIsLoading(false);
    }), [handleSetAddress, handleSetCenter, setIsLoading, geocodeByAddress]);
    return (react_1.default.createElement(ux_1.Accordion, { expanded: locationType === selectedLocationType, onChange: () => {
            const newLocationType = locationType === selectedLocationType ? null : locationType;
            handleExpansion(newLocationType, locationMode);
        } },
        react_1.default.createElement(ux_1.AccordionSummary, { expandIcon: react_1.default.createElement(ux_1.ExpandMoreIcon, null), id: locationType || undefined },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement("img", { alt: locationType || '', height: 48, src: locationType === 'origin'
                            ? '/assets/images/icons/pinBlue.png'
                            : '/assets/images/icons/pinYellow.png', width: 48 })),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { ml: 1 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t(`common:${locationType}`))),
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Box, { ml: 2 }, hasError && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:pleaseCheckLocationDefinition') || '' },
                                    react_1.default.createElement(ux_1.ErrorOutlinedIcon, { style: { color: 'red' } })))))))))),
        react_1.default.createElement(ux_1.AccordionDetails, null,
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                !zones ||
                    (zones.length !== 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, null, t('common:selectExistingZone')),
                            react_1.default.createElement(__1.ZoneSelect, { onChange: (zone) => {
                                    handleSetLocationZone(zone, locationType);
                                }, shouldUseNativeSelect: shouldUseNativeSelect, t: t, zones: zones || [] })),
                        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                            react_1.default.createElement(ux_1.Box, { mt: 1 },
                                react_1.default.createElement(ux_1.Typography, { style: { fontWeight: 800, textTransform: 'uppercase' } }, t('common:or'))))))),
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                    react_1.default.createElement(ux_1.Box, { mt: 1 },
                        react_1.default.createElement(ux_1.Typography, null, t('common:defineNewZone')))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                        react_1.default.createElement(ux_1.Grid, { item: true, style: { marginLeft: -16 } }, locationCoords !== null && (react_1.default.createElement(ux_1.IconButton, { onClick: () => {
                                focusOnLocation(locationCoords);
                            } },
                            react_1.default.createElement(ux_1.CenterFocusWeakIcon, null)))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.RadioGroup, { onChange: (_event, newValue) => __awaiter(void 0, void 0, void 0, function* () {
                                    if (newValue === 'geopolitical') {
                                        handleChangeLocationMode(newValue);
                                        // When changing to geopolitical, hide all
                                        // non-marker shapes
                                        // as they will not be used in this mode
                                        locationShapes.forEach((shape) => {
                                            if (shape.type !== 'marker') {
                                                handleToggleLocationShape(shape, 'hide');
                                            }
                                            shape.overlay.setOptions({
                                                draggable: false,
                                            });
                                        });
                                        // When changing to geopolitical,
                                        // we recenter on the capital
                                        if ((locationAddress === null || locationAddress === void 0 ? void 0 : locationAddress.country) && (locationAddress === null || locationAddress === void 0 ? void 0 : locationAddress.province)) {
                                            yield handleGeopoliticalAddress(locationAddress === null || locationAddress === void 0 ? void 0 : locationAddress.country, locationAddress === null || locationAddress === void 0 ? void 0 : locationAddress.province);
                                        }
                                    }
                                    else {
                                        // Otherwise show all the shapes (even if they
                                        // were previously hidden), this is just to
                                        // reinforce the difference between the two modes
                                        locationShapes.forEach((shape) => {
                                            handleToggleLocationShape(shape, 'show');
                                            shape.overlay.setOptions({
                                                draggable: true,
                                            });
                                        });
                                        handleChangeLocationMode(newValue);
                                    }
                                    handleExpansion(locationType, newValue);
                                }), row: true, value: locationMode },
                                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Radio, { color: "primary", disabled: isLoading }), label: t(`common:entireProvinceState`), value: 'geopolitical' }),
                                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Radio, { color: "primary", disabled: isLoading }), label: t(`common:newCustomZone`), value: 'custom' }))))),
                locationMode === 'geopolitical' && (react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.CountryRegionSelect, { onChange: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                    yield handleGeopoliticalAddress(value[0] || '', value[1] || '');
                                }), selectedCountry: (locationAddress === null || locationAddress === void 0 ? void 0 : locationAddress.country) || '', selectedRegion: (locationAddress === null || locationAddress === void 0 ? void 0 : locationAddress.province) || '', shouldUseNativeSelect: shouldUseNativeSelect, t: t })),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { variant: "body1" }, t('common:captialShownAsExample'))),
                        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" }, buttons)))),
                locationMode === 'custom' && (react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                            react_1.default.createElement(ux_1.Box, { mb: 1 },
                                react_1.default.createElement(ux_1.Typography, null, t('common:startByAddressSearch')))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mb: 1 },
                                react_1.default.createElement(__1.GooglePlacesAutocomplete, { areFormValuesRequired: !Boolean(locationCoords === null || locationCoords === void 0 ? void 0 : locationCoords.latitude) && !Boolean(locationCoords === null || locationCoords === void 0 ? void 0 : locationCoords.longitude), googleMapsApiKey: googleMapsApiKey, handleError: handleError, language: language, locationTypes: [
                                        'neighborhood',
                                        'locality',
                                        'sublocality',
                                        'administrative_area_level_1',
                                        'administrative_area_level_2',
                                    ], onChange: (place, isGeoCode, data) => __awaiter(void 0, void 0, void 0, function* () {
                                        if (!place) {
                                            handleSetCenter(null);
                                            handleSetAddress(null, null);
                                            handleDeleteLocationShape(locationShapes);
                                        }
                                        else {
                                            handleSetCenter(null);
                                            let geoCode = null;
                                            if (!isGeoCode) {
                                                [geoCode] = yield geocodeByPlaceId(place.place_id);
                                            }
                                            else {
                                                geoCode = data || null;
                                            }
                                            if (geoCode) {
                                                const coords = {
                                                    latitude: geoCode.geometry.location.lat(),
                                                    longitude: geoCode.geometry.location.lng(),
                                                };
                                                handleSetCenter(coords);
                                                const address = (0, utils_1.convertGeocodeResultToAddress)(geoCode);
                                                handleSetAddress(geoCode.place_id, address);
                                                locationShapes.forEach((shape) => {
                                                    if (shape.type === 'circle') {
                                                        shape.overlay.setCenter({
                                                            lat: coords.latitude,
                                                            lng: coords.longitude,
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                    }), placeholder: locationAddressName || '', shouldFallbackToGeoCode: false, shouldUseNativeInput: shouldUseNativeSelect }))),
                        react_1.default.createElement(ux_1.Grid, { item: true }, boundsShapes === 0 && locationCoords !== null && (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                                react_1.default.createElement(ux_1.Box, { mb: 1, mt: 1 },
                                    react_1.default.createElement(ux_1.Typography, null, t('common:whichZoneType')))),
                            react_1.default.createElement(ux_1.RadioGroup, { onChange: (_event, newValue) => {
                                    if (!['circle', 'polygon'].includes(newValue)) {
                                        throw new Error(`invalid shape ${newValue} provided`);
                                    }
                                    handleSetDrawingMode(newValue);
                                }, row: false, value: drawingMode },
                                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Radio, { color: "primary" }), disabled: boundsShapes !== 0, label: react_1.default.createElement(react_1.default.Fragment, null,
                                        react_1.default.createElement(ux_1.Typography, null, t(`common:circleWithRadius`)),
                                        react_1.default.createElement(ux_1.TextField, { InputProps: {
                                                endAdornment: react_1.default.createElement(ux_1.InputAdornment, { position: "end" }, "km"),
                                            }, disabled: boundsShapes !== 0 || drawingMode !== 'circle' || !locationCoords, onChange: (event) => {
                                                // Try and find a circle
                                                let circle = locationShapes.find((shape) => {
                                                    return shape.type === 'circle';
                                                });
                                                if (!circle && mapReference !== null) {
                                                    const shape = new window.google.maps.Circle({
                                                        center: {
                                                            lat: locationCoords.latitude,
                                                            lng: locationCoords.longitude,
                                                        },
                                                        fillColor: locationType === 'origin'
                                                            ? applicationTheme.palette.primary.light
                                                            : applicationTheme.palette.secondary.main,
                                                        map: mapReference,
                                                        strokeWeight: 0,
                                                    });
                                                    if (locationType) {
                                                        circle = {
                                                            index: locationShapes.length,
                                                            locationType,
                                                            overlay: shape,
                                                            type: 'circle',
                                                        };
                                                        handleAddLocationShape(circle);
                                                    }
                                                }
                                                if (circle) {
                                                    circle.overlay.setRadius(event.target.value * 1000);
                                                    circle.overlay.addListener('radius_changed', () => {
                                                        if (circle) {
                                                            // when the radius is changed, we need to ensure that the radius is update in the UI
                                                            handleToggleLocationShape(circle, 'show');
                                                        }
                                                    });
                                                }
                                                handleSetDrawingMode(null);
                                            }, type: "number", variant: "outlined" })), value: 'circle' }),
                                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Radio, { color: "primary" }), disabled: boundsShapes !== 0, label: t(`common:drawCustomZone`), value: 'polygon' }))))),
                        locationShapes.map((shape) => {
                            if (shape.type === 'marker')
                                return null;
                            const label = shape.type === 'circle' ? `${t('common:radius')}` : t('common:customZone');
                            return (react_1.default.createElement(ux_1.Grid, { item: true, key: `${locationType}-shape-${shape.index}` },
                                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                                    react_1.default.createElement(ux_1.Grid, { item: true, xs: shape.type === 'circle' ? 5 : 6 },
                                        react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: shape.overlay.getMap() !== null, color: "primary", onChange: () => {
                                                    handleToggleLocationShape(shape);
                                                } }), label: label })),
                                    react_1.default.createElement(ux_1.Grid, { item: true, xs: shape.type === 'circle' ? 5 : 4 }, shape.type === 'circle' && (react_1.default.createElement(ux_1.TextField, { InputProps: {
                                            endAdornment: react_1.default.createElement(ux_1.InputAdornment, { position: "end" }, "km"),
                                        }, onChange: (event) => {
                                            shape.overlay.setRadius(event.target.value * 1000);
                                            handleToggleLocationShape(shape, 'show');
                                        }, type: "number", value: (shape.overlay.getRadius() / 1000).toFixed(2), variant: "outlined" }))),
                                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 },
                                        react_1.default.createElement(ux_1.IconButton, { onClick: () => {
                                                handleDeleteLocationShape([shape]);
                                            } },
                                            react_1.default.createElement(ux_1.DeleteIcon, null))))));
                        }),
                        boundsShapes !== 0 && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { style: { fontSize: 12 } }, t('common:singleShapeAllowed'))))),
                    react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" }, buttons)))))));
};
exports.LaneMapLocation = LaneMapLocation;
