"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCroissantIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.375,3.785a13.448,13.448,0,0,0-4.847-1.639A4.013,4.013,0,0,0,16.473.862,3.966,3.966,0,0,0,13.082.109,17.955,17.955,0,0,0,9.463,1.45,4.666,4.666,0,0,0,4.4,2.141c-.367.315-.736.644-1.1.983-.382.359-.771.767-1.159,1.21a4.655,4.655,0,0,0-1.03,4.12,4.753,4.753,0,0,0,.341.984A18.173,18.173,0,0,0,.1,13.082a3.958,3.958,0,0,0,.753,3.391,4,4,0,0,0,1.3,1.062,13.45,13.45,0,0,0,1.638,4.84,3.168,3.168,0,0,0,3.431,1.557,3.259,3.259,0,0,0,2.61-2.876A16.488,16.488,0,0,1,10.84,17.3s.545-.336.794-.539a3.717,3.717,0,0,0,1.216-1.941,3.141,3.141,0,0,0,1.228-.755,3.176,3.176,0,0,0,.744-1.211,3.715,3.715,0,0,0,1.934-1.215c.2-.249.54-.795.54-.795a15.346,15.346,0,0,1,3.76-1.013,3.259,3.259,0,0,0,2.876-2.61A3.161,3.161,0,0,0,22.375,3.785ZM7.839,20.833a1.274,1.274,0,0,1-1.027,1.14,1.169,1.169,0,0,1-1.291-.59A11.5,11.5,0,0,1,4.244,18h3.9c.109,0,.215-.022.323-.028C8.181,18.855,7.839,20.829,7.839,20.833Zm2.53-5.626A3.525,3.525,0,0,1,8.146,16H4a2,2,0,0,1-1.579-.767,1.973,1.973,0,0,1-.377-1.689,14.866,14.866,0,0,1,.8-2.359,4.652,4.652,0,0,0,1.006.6l6.677,2.924c.062.029.128.04.191.064A1.567,1.567,0,0,1,10.369,15.207Zm2.285-2.552a1.151,1.151,0,0,1-1.308.234L4.653,9.958A2.75,2.75,0,0,1,3.068,8.017a2.675,2.675,0,0,1,.586-2.368C4,5.255,4.342,4.895,4.677,4.58s.682-.625,1.027-.92a2.685,2.685,0,0,1,4.226.957l2.964,6.69v0A1.222,1.222,0,0,1,12.654,12.655ZM16,8.146a3.524,3.524,0,0,1-.792,2.223,1.585,1.585,0,0,1-.411.339c-.026-.07-.045-.142-.075-.211L11.759,3.808a4.811,4.811,0,0,0-.562-.957,14.914,14.914,0,0,1,2.347-.8,1.971,1.971,0,0,1,1.689.377A2,2,0,0,1,16,4.011Zm5.973-1.334a1.274,1.274,0,0,1-1.14,1.027s-1.978.342-2.861.63c.007-.108.028-.214.028-.323v-3.9a11.5,11.5,0,0,1,3.383,1.277A1.172,1.172,0,0,1,21.973,6.812Z" })));
exports.default = TruxwebCroissantIcon;
