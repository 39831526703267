// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotCaptureSourceV1 = void 0;
var HubspotCaptureSourceV1;
(function (HubspotCaptureSourceV1) {
    HubspotCaptureSourceV1["contact_page"] = "contact_page";
    HubspotCaptureSourceV1["landing_page_main_capture"] = "landing_page_main_capture";
    HubspotCaptureSourceV1["footer_newsletter"] = "footer_newsletter";
    HubspotCaptureSourceV1["registration"] = "registration";
    HubspotCaptureSourceV1["other"] = "other";
})(HubspotCaptureSourceV1 || (exports.HubspotCaptureSourceV1 = HubspotCaptureSourceV1 = {}));
