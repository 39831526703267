// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceTypeV1 = exports.CurrencyV1 = exports.LtlPackagingV1 = exports.ShipmentDisplayStatusV1 = exports.ShipmentTrackerV1 = exports.PaymentMethodV1 = exports.TruckloadTypeV1 = exports.ShipmentStatusV1 = void 0;
var ShipmentStatusV1;
(function (ShipmentStatusV1) {
    ShipmentStatusV1["REQUIRES_DOCUMENTS"] = "REQUIRES_DOCUMENTS";
    ShipmentStatusV1["PICKUP_READY"] = "PICKUP_READY";
    ShipmentStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentStatusV1["DELIVERED"] = "DELIVERED";
    ShipmentStatusV1["AWAITING_CARRIER_INVOICE"] = "AWAITING_CARRIER_INVOICE";
    ShipmentStatusV1["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    ShipmentStatusV1["READY_FOR_PROCESSING"] = "READY_FOR_PROCESSING";
    ShipmentStatusV1["PROCESSING_FAILURE"] = "PROCESSING_FAILURE";
    ShipmentStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentStatusV1["CANCELLED"] = "CANCELLED";
})(ShipmentStatusV1 || (exports.ShipmentStatusV1 = ShipmentStatusV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
var ShipmentTrackerV1;
(function (ShipmentTrackerV1) {
    ShipmentTrackerV1["P44"] = "P44";
})(ShipmentTrackerV1 || (exports.ShipmentTrackerV1 = ShipmentTrackerV1 = {}));
var ShipmentDisplayStatusV1;
(function (ShipmentDisplayStatusV1) {
    ShipmentDisplayStatusV1["PICKUP_READY"] = "PICKUP_READY";
    ShipmentDisplayStatusV1["DELIVERED"] = "DELIVERED";
    ShipmentDisplayStatusV1["CANCELLED"] = "CANCELLED";
    ShipmentDisplayStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentDisplayStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentDisplayStatusV1["PENDING_PAYMENT"] = "PENDING_PAYMENT";
})(ShipmentDisplayStatusV1 || (exports.ShipmentDisplayStatusV1 = ShipmentDisplayStatusV1 = {}));
/**
 * How the good is packaged for shipping
 */
var LtlPackagingV1;
(function (LtlPackagingV1) {
    LtlPackagingV1["STANDARD_PALLET"] = "STANDARD_PALLET";
    LtlPackagingV1["NON_STANDARD_PALLET"] = "NON_STANDARD_PALLET";
    LtlPackagingV1["CRATE"] = "CRATE";
    LtlPackagingV1["LOOSE"] = "LOOSE";
})(LtlPackagingV1 || (exports.LtlPackagingV1 = LtlPackagingV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
