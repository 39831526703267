// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotLeadStatusV1 = void 0;
var HubspotLeadStatusV1;
(function (HubspotLeadStatusV1) {
    HubspotLeadStatusV1["VALIDATED"] = "Validated";
    HubspotLeadStatusV1["UNVERIFIED"] = "UNVERIFIED";
    HubspotLeadStatusV1["REGISTERED"] = "Registered";
    HubspotLeadStatusV1["PENDING_REVIEW"] = "Pending review";
    HubspotLeadStatusV1["ONBOARDED"] = "ONBOARDED";
    HubspotLeadStatusV1["OPEN"] = "OPEN";
    HubspotLeadStatusV1["UNQAULIFIED"] = "UNQAULIFIED";
    HubspotLeadStatusV1["ATTEMPTED_TO_CONTACT"] = "ATTEMPTED_TO_CONTACT";
    HubspotLeadStatusV1["IN_PROGRESS"] = "IN_PROGRESS";
    HubspotLeadStatusV1["OPEN_DEAL"] = "OPEN_DEAL";
    HubspotLeadStatusV1["VERIFIED"] = "VERIFIED";
})(HubspotLeadStatusV1 || (exports.HubspotLeadStatusV1 = HubspotLeadStatusV1 = {}));
