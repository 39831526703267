import { addCarrierComment, addShipperComment } from '.';
import { EUserTypeV1, type TShipmentCommentV1 } from '@truxweb/schemas';
import { type TSubmitAddCommentPayload } from '../../types';

export const addComment = async (
  request: TSubmitAddCommentPayload
): Promise<TShipmentCommentV1> => {
  const action =
    request.companyType === EUserTypeV1.CARRIER ? addCarrierComment : addShipperComment;
  return await action(request);
};
