import { Box, Grid, Typography } from '@truxweb/ux';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'next-i18next';
import classnames from 'classnames';
import { LoginVerification } from '..';
import React from 'react';
import { SaveButton } from '@truxweb/common-components';
import { useStyles } from './RegisterConfirmationCode.styles';

const REQUIRED_NAMESPACES = ['common', 'signup'];

type RegisterConfirmationCodeProps = {
  isLoading: boolean;
  confirmationSource?: string;
  onSubmitSuccess: (formData: { [key: string]: any }) => void;
  onResendConfirmationCode: () => Promise<void>;
};

export const RegisterConfirmationCode = ({
  confirmationSource,
  isLoading,
  onResendConfirmationCode,
  onSubmitSuccess,
}: RegisterConfirmationCodeProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmitSuccess)}>
      <Grid container direction="column">
        <Grid item style={{ width: '100%' }} xs>
          <Grid container direction="column">
            <Grid item xs>
              <Box mt={2}>
                {Boolean(confirmationSource) && (
                  <Typography>
                    {t('common:confirmationCodePrompt', {
                      confirmationSource,
                    })}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Controller
              control={control}
              name="confirmationCode"
              render={({ field: { onChange, value } }) => {
                return <LoginVerification onChange={onChange} value={value} />;
              }}
              rules={{ required: true }}
            />
            <Grid item>
              <Box>
                <Typography>
                  <Trans i18nKey="common:verificationCodeResend">
                    <button
                      className={classnames(
                        classes.primaryFont,
                        classes.resendButton,
                        classes.clickable
                      )}
                      onClick={onResendConfirmationCode}
                      type="button"
                    >
                      <Typography className={classes.resendFont}>
                        {t('common:verificationCodeResend')}
                      </Typography>
                    </button>
                  </Trans>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box mt={6}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Grid item style={{ width: '100%' }}>
                <SaveButton
                  className={classes.submitButton}
                  isDisabled={!isDirty}
                  isSaving={isLoading}
                  t={t}
                >
                  {t('common:verify')}
                </SaveButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
