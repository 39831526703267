// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
function __export(m) {
  for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
const {DbAccessorialV1} = require("./v1/DbAccessorialType.js");
const {DbAccessorialInsertV1} = require("./v1/DbAccessorialInsertType.js");
const {DbEquipmentV1} = require("./v1/DbEquipmentType.js");
const {DbEquipmentInsertV1} = require("./v1/DbEquipmentInsertType.js");
const {BankAccountProfileV2} = require("./v2/BankAccountProfileType.js");
const {BankAccountTransactionV2} = require("./v2/BankAccountTransactionType.js");
const {BankAccountTransactionHistoryV2} = require("./v2/BankAccountTransactionHistoryType.js");
const {CreditCardProfileV2} = require("./v2/CreditCardProfileType.js");
const {CreditCardTransactionV2} = require("./v2/CreditCardTransactionType.js");
const {CreditCardTransactionHistoryV2} = require("./v2/CreditCardTransactionHistoryType.js");
const {SESEmailTemplateV1} = require("./v1/SESEmailTemplateType.js");
const {AccessorialV1} = require("./v1/AccessorialType.js");
const {AccessorialListItemV1} = require("./v1/AccessorialListItemType.js");
const {AccessorialMetadataEntryV1} = require("./v1/AccessorialMetadataEntryType.js");
const {AdditionalShipmentChargeV1} = require("./v1/AdditionalShipmentChargeType.js");
const {AddressV1} = require("./v1/AddressType.js");
const {AdminShipmentV1} = require("./v1/AdminShipmentType.js");
const {AlertV1} = require("./v1/AlertType.js");
const {BatchNotificationQueueMessageV1} = require("./v1/BatchNotificationQueueMessageType.js");
const {BookingLocationV1} = require("./v1/BookingLocationType.js");
const {BookingSearchDocumentV1} = require("./v1/BookingSearchDocumentType.js");
const {BookingSearchResultV1} = require("./v1/BookingSearchResultType.js");
const {BookingSearchSummaryV1} = require("./v1/BookingSearchSummaryType.js");
const {CarrierV1} = require("./v1/CarrierType.js");
const {CarrierAccessorialV1} = require("./v1/CarrierAccessorialType.js");
const {CarrierAccessorialMapV1} = require("./v1/CarrierAccessorialMapType.js");
const {CarrierEquipmentV1} = require("./v1/CarrierEquipmentType.js");
const {CarrierFuelSurchargeV1} = require("./v1/CarrierFuelSurchargeType.js");
const {CarrierLaneV1} = require("./v1/CarrierLaneType.js");
const {CarrierLaneEquipmentV1} = require("./v1/CarrierLaneEquipmentType.js");
const {CarrierLaneLocationV1} = require("./v1/CarrierLaneLocationType.js");
const {CarrierLocationV1} = require("./v1/CarrierLocationType.js");
const {CarrierLocationZoneV1} = require("./v1/CarrierLocationZoneType.js");
const {CarrierProfileDisplayV1} = require("./v1/CarrierProfileDisplayType.js");
const {CarrierProfileRecordV1} = require("./v1/CarrierProfileRecordType.js");
const {CarrierPublicProfileV1} = require("./v1/CarrierPublicProfileType.js");
const {CarrierQuoteDataGridRowV1} = require("./v1/CarrierQuoteDataGridRowType.js");
const {CarrierRegistrationV1} = require("./v1/CarrierRegistrationType.js");
const {CarrierShipmentQuoteRequestV1} = require("./v1/CarrierShipmentQuoteRequestType.js");
const {CarrierZoneV1} = require("./v1/CarrierZoneType.js");
const {CarrierZoneCoordinateV1} = require("./v1/CarrierZoneCoordinateType.js");
const {ChargeV1} = require("./v1/ChargeType.js");
const {ChargeDescriptionV1} = require("./v1/ChargeDescriptionType.js");
const {CompanyV1} = require("./v1/CompanyType.js");
const {CompanyDocumentV1} = require("./v1/CompanyDocumentType.js");
const {CompanyLocationV1} = require("./v1/CompanyLocationType.js");
const {CompanyShipmentFeeV1} = require("./v1/CompanyShipmentFeeType.js");
const {CompanySubscriptionV1} = require("./v1/CompanySubscriptionType.js");
const {CostDetailV1} = require("./v1/CostDetailType.js");
const {CspPermissionRecordV1} = require("./v1/CspPermissionRecordType.js");
const {CspUserV1} = require("./v1/CspUserType.js");
const {DoyonLoadDefinitionV1} = require("./v1/DoyonLoadDefinitionType.js");
const {DoyonLtlCargoDefinitionV1} = require("./v1/DoyonLtlCargoDefinitionType.js");
const {ElasticSearchLocationV1} = require("./v1/ElasticSearchLocationType.js");
const {ElementHeightWidthV1} = require("./v1/ElementHeightWidthType.js");
const {EntityMetadataV1} = require("./v1/EntityMetadataType.js");
const {EquipmentV1} = require("./v1/EquipmentType.js");
const {EquipmentOnProfileV1} = require("./v1/EquipmentOnProfileType.js");
const {EquipmentPricingDataV1} = require("./v1/EquipmentPricingDataType.js");
const {ExportRequestV1} = require("./v1/ExportRequestType.js");
const {FeeDetailV1} = require("./v1/FeeDetailType.js");
const {FuelSurchargeV1} = require("./v1/FuelSurchargeType.js");
const {GeolocationV1} = require("./v1/GeolocationType.js");
const {GooglePlaceV1} = require("./v1/GooglePlaceType.js");
const {HubspotCompanyV1} = require("./v1/HubspotCompanyType.js");
const {HubspotContactV1} = require("./v1/HubspotContactType.js");
const {HubspotTicketV1} = require("./v1/HubspotTicketType.js");
const {LatLngV1} = require("./v1/LatLngType.js");
const {ListItemAccessorialV1} = require("./v1/ListItemAccessorialType.js");
const {LoadDefinitionV1} = require("./v1/LoadDefinitionType.js");
const {LoadParametersV1} = require("./v1/LoadParametersType.js");
const {LocationScheduleV1} = require("./v1/LocationScheduleType.js");
const {LtlCargoDefinitionV1} = require("./v1/LtlCargoDefinitionType.js");
const {LtlPackageDimensionsV1} = require("./v1/LtlPackageDimensionsType.js");
const {PaymentMessageBodyV1} = require("./v1/PaymentMessageBodyType.js");
const {PaymentTermsAdjustmentV1} = require("./v1/PaymentTermsAdjustmentType.js");
const {PersistentNotificationRecipientV1} = require("./v1/PersistentNotificationRecipientType.js");
const {PersistentNotificationRecipientDeliveryV1} = require("./v1/PersistentNotificationRecipientDeliveryType.js");
const {PriceV1} = require("./v1/PriceType.js");
const {QuoteCommentV1} = require("./v1/QuoteCommentType.js");
const {QuoteCommentAttachmentV1} = require("./v1/QuoteCommentAttachmentType.js");
const {QuoteSearchFormDataV1} = require("./v1/QuoteSearchFormDataType.js");
const {RatePerDistanceV1} = require("./v1/RatePerDistanceType.js");
const {RateSheetLocationV1} = require("./v1/RateSheetLocationType.js");
const {RateSheetRegionV1} = require("./v1/RateSheetRegionType.js");
const {RatingV1} = require("./v1/RatingType.js");
const {ServiceRangeV1} = require("./v1/ServiceRangeType.js");
const {ShipmentV1} = require("./v1/ShipmentType.js");
const {ShipmentAccessorialV1} = require("./v1/ShipmentAccessorialType.js");
const {ShipmentAccessorialMetadataV1} = require("./v1/ShipmentAccessorialMetadataType.js");
const {ShipmentAccessorialMetadataInputV1} = require("./v1/ShipmentAccessorialMetadataInputType.js");
const {ShipmentCommentV1} = require("./v1/ShipmentCommentType.js");
const {ShipmentCommentAttachmentV1} = require("./v1/ShipmentCommentAttachmentType.js");
const {ShipmentDetailsV1} = require("./v1/ShipmentDetailsType.js");
const {ShipmentDocumentV1} = require("./v1/ShipmentDocumentType.js");
const {ShipmentDocumentToUploadV1} = require("./v1/ShipmentDocumentToUploadType.js");
const {ShipmentEquipmentV1} = require("./v1/ShipmentEquipmentType.js");
const {ShipmentFeeTypeV1} = require("./v1/ShipmentFeeTypeType.js");
const {ShipmentLocationV1} = require("./v1/ShipmentLocationType.js");
const {ShipmentPaymentTermsV1} = require("./v1/ShipmentPaymentTermsType.js");
const {ShipmentPendingTransactionSettlementV1} = require("./v1/ShipmentPendingTransactionSettlementType.js");
const {ShipmentQuoteAccessorialV1} = require("./v1/ShipmentQuoteAccessorialType.js");
const {ShipmentQuoteEquipmentV1} = require("./v1/ShipmentQuoteEquipmentType.js");
const {ShipmentQuoteRequestV1} = require("./v1/ShipmentQuoteRequestType.js");
const {ShipmentQuoteRequestBookingDateV1} = require("./v1/ShipmentQuoteRequestBookingDateType.js");
const {ShipmentQuoteRequestChargeV1} = require("./v1/ShipmentQuoteRequestChargeType.js");
const {ShipmentQuoteRequestLocationV1} = require("./v1/ShipmentQuoteRequestLocationType.js");
const {ShipmentRequiredDocumentV1} = require("./v1/ShipmentRequiredDocumentType.js");
const {ShipmentRequiringAttentionV1} = require("./v1/ShipmentRequiringAttentionType.js");
const {ShipmentStatusLogEntryV1} = require("./v1/ShipmentStatusLogEntryType.js");
const {ShipmentSummaryItemV1} = require("./v1/ShipmentSummaryItemType.js");
const {ShipmentTrackingCarrierIdentiferV1} = require("./v1/ShipmentTrackingCarrierIdentiferType.js");
const {ShipmentTrackingEquipmentIdentiferV1} = require("./v1/ShipmentTrackingEquipmentIdentiferType.js");
const {ShipmentTrackingLocationV1} = require("./v1/ShipmentTrackingLocationType.js");
const {ShipmentTrackingShipmentIdentiferV1} = require("./v1/ShipmentTrackingShipmentIdentiferType.js");
const {ShipmentTrackingStatusUpdateV1} = require("./v1/ShipmentTrackingStatusUpdateType.js");
const {ShipmentTrackingTruckloadV1} = require("./v1/ShipmentTrackingTruckloadType.js");
const {ShipmentTransactionV1} = require("./v1/ShipmentTransactionType.js");
const {ShipmentTransactionUpdateV1} = require("./v1/ShipmentTransactionUpdateType.js");
const {ShipmentTypeV1} = require("./v1/ShipmentTypeType.js");
const {ShipperPaymentTermsV1} = require("./v1/ShipperPaymentTermsType.js");
const {ShipperQuoteDataGridRowV1} = require("./v1/ShipperQuoteDataGridRowType.js");
const {ShipperShipmentQuoteRequestV1} = require("./v1/ShipperShipmentQuoteRequestType.js");
const {StandardInvoiceTransactionV1} = require("./v1/StandardInvoiceTransactionType.js");
const {StandardInvoiceTransactionHistoryV1} = require("./v1/StandardInvoiceTransactionHistoryType.js");
const {SubscriptionPlanV1} = require("./v1/SubscriptionPlanType.js");
const {TableFilterV1} = require("./v1/TableFilterType.js");
const {TaxCostDetailV1} = require("./v1/TaxCostDetailType.js");
const {TruxwebMarginDetailsV1} = require("./v1/TruxwebMarginDetailsType.js");
const {UserV1} = require("./v1/UserType.js");
const {UserBillingProfileV1} = require("./v1/UserBillingProfileType.js");
const {UserRegistrationV1} = require("./v1/UserRegistrationType.js");
const {UserRegistrationFormV1} = require("./v1/UserRegistrationFormType.js");
const {UserRoleV1} = require("./v1/UserRoleType.js");
const {UserShipmentV1} = require("./v1/UserShipmentType.js");
const {AddBankAcctRequestV1} = require("./v1/AddBankAcctRequestType.js");
const {AddCardRequestV1} = require("./v1/AddCardRequestType.js");
const {AddCarrierShipmentDocumentV1} = require("./v1/AddCarrierShipmentDocumentType.js");
const {AddUserToCompanyV1} = require("./v1/AddUserToCompanyType.js");
const {AdjustShipperTransactionV1} = require("./v1/AdjustShipperTransactionType.js");
const {AreBankTransactionsWithProfileForShipmentRequestV1} = require("./v1/AreBankTransactionsWithProfileForShipmentRequestType.js");
const {BankAcctPaymentRequestV1} = require("./v1/BankAcctPaymentRequestType.js");
const {BookingRequestV1} = require("./v1/BookingRequestType.js");
const {BookingSearchRequestV1} = require("./v1/BookingSearchRequestType.js");
const {CalculatePriceRequestV1} = require("./v1/CalculatePriceRequestType.js");
const {CompleteQuoteToShipmentV1} = require("./v1/CompleteQuoteToShipmentType.js");
const {CompleteShipmentRequestV1} = require("./v1/CompleteShipmentRequestType.js");
const {ConfirmationRequestV1} = require("./v1/ConfirmationRequestType.js");
const {ContactFormRequestV1} = require("./v1/ContactFormRequestType.js");
const {CreateEcommUserV1} = require("./v1/CreateEcommUserType.js");
const {CreateShipmentQuoteRequestV1} = require("./v1/CreateShipmentQuoteRequestType.js");
const {CreateShipperTicketForEmptySearchResultsRequestV1} = require("./v1/CreateShipperTicketForEmptySearchResultsRequestType.js");
const {CreditCardPaymentByProfileRequestV1} = require("./v1/CreditCardPaymentByProfileRequestType.js");
const {CreditCardPaymentByTokenRequestV1} = require("./v1/CreditCardPaymentByTokenRequestType.js");
const {CreditCardPaymentRequestV1} = require("./v1/CreditCardPaymentRequestType.js");
const {DoyonBookingSearchRequestV1} = require("./v1/DoyonBookingSearchRequestType.js");
const {DoyonDespresFetchQuoteRequestV1} = require("./v1/DoyonDespresFetchQuoteRequestType.js");
const {DoyonFetchSearchResultsRequestV1} = require("./v1/DoyonFetchSearchResultsRequestType.js");
const {FetchSearchResultsRequestV1} = require("./v1/FetchSearchResultsRequestType.js");
const {GeneratePdfRequestV1} = require("./v1/GeneratePdfRequestType.js");
const {GetCompanyShipmentSummaryRequestV1} = require("./v1/GetCompanyShipmentSummaryRequestType.js");
const {GetShipmentRefsForCompanyRequestV1} = require("./v1/GetShipmentRefsForCompanyRequestType.js");
const {GetUserBankAccountsRequestV1} = require("./v1/GetUserBankAccountsRequestType.js");
const {SaveUserRequestV1} = require("./v1/SaveUserRequestType.js");
const {SendEmailNotificationRequestV1} = require("./v1/SendEmailNotificationRequestType.js");
const {SendEmailRequestV1} = require("./v1/SendEmailRequestType.js");
const {SendNotificationsRequestV1} = require("./v1/SendNotificationsRequestType.js");
const {ShipmentPaymentMethodUpdateV1} = require("./v1/ShipmentPaymentMethodUpdateType.js");
const {ShipperPaymentTermsRequestV1} = require("./v1/ShipperPaymentTermsRequestType.js");
const {TransactionChangeRequestV1} = require("./v1/TransactionChangeRequestType.js");
const {UpdateCarrierQuoteRequestV1} = require("./v1/UpdateCarrierQuoteRequestType.js");
const {UpdateCompanyDetailsRequestV1} = require("./v1/UpdateCompanyDetailsRequestType.js");
const {UpdateTransactionAmountAndSettlementDateV1} = require("./v1/UpdateTransactionAmountAndSettlementDateType.js");
const {UpdateUserRequestV1} = require("./v1/UpdateUserRequestType.js");
const {UserPaymentTermsRequestV1} = require("./v1/UserPaymentTermsRequestType.js");
const {AddBankAccountRequestV2} = require("./v2/AddBankAccountRequestType.js");
const {AddCardRequestV2} = require("./v2/AddCardRequestType.js");
const {BankAccountHoldRequestV2} = require("./v2/BankAccountHoldRequestType.js");
const {BankAccountPaymentRequestV2} = require("./v2/BankAccountPaymentRequestType.js");
const {CreditCardPaymentByProfileRequestV2} = require("./v2/CreditCardPaymentByProfileRequestType.js");
const {AreBankTransactionsWithProfileForShipmentResponseV1} = require("./v1/AreBankTransactionsWithProfileForShipmentResponseType.js");
const {BankAcctPaymentTransactionResponseV1} = require("./v1/BankAcctPaymentTransactionResponseType.js");
const {BankAcctResponseV1} = require("./v1/BankAcctResponseType.js");
const {BookingSearchResponseV1} = require("./v1/BookingSearchResponseType.js");
const {CalculatePriceResponseV1} = require("./v1/CalculatePriceResponseType.js");
const {CreditCardPaymentTransactionResponseV1} = require("./v1/CreditCardPaymentTransactionResponseType.js");
const {CreditCardResponseV1} = require("./v1/CreditCardResponseType.js");
const {DoyonDespresFetchQuoteResponseV1} = require("./v1/DoyonDespresFetchQuoteResponseType.js");
const {FetchCompanyDetailsResponseV1} = require("./v1/FetchCompanyDetailsResponseType.js");
const {GenericErrorResponseV1} = require("./v1/GenericErrorResponseType.js");
const {GetShipmentDetailsResponseV1} = require("./v1/GetShipmentDetailsResponseType.js");
const {GetShipmentRefsForCompanyResponseV1} = require("./v1/GetShipmentRefsForCompanyResponseType.js");
const {InAppNotificationV1} = require("./v1/InAppNotificationType.js");
const {ListCarrierRecordV1} = require("./v1/ListCarrierRecordType.js");
const {ListShipmentsResponseV1} = require("./v1/ListShipmentsResponseType.js");
const {ListShipmentsResponseWrapperV1} = require("./v1/ListShipmentsResponseWrapperType.js");
const {ListUserSummaryRecordV1} = require("./v1/ListUserSummaryRecordType.js");
const {ParseCarrierAvailabilityV1} = require("./v1/ParseCarrierAvailabilityType.js");
const {ProcessBankAccountPaymentResponseV1} = require("./v1/ProcessBankAccountPaymentResponseType.js");
const {ProcessCreditCardProfileResponseV1} = require("./v1/ProcessCreditCardProfileResponseType.js");
const {QuoteStatusCountV1} = require("./v1/QuoteStatusCountType.js");
const {SearchEquipmentV1} = require("./v1/SearchEquipmentType.js");
const {ShipmentSummaryResponseV1} = require("./v1/ShipmentSummaryResponseType.js");
const {UserBillingProfilesResponseV1} = require("./v1/UserBillingProfilesResponseType.js");
const {UserShipmentSummaryDataV1} = require("./v1/UserShipmentSummaryDataType.js");
const {ValidateUserPermissionsV1} = require("./v1/ValidateUserPermissionsType.js");
const {ValidationErrorResponseV1} = require("./v1/ValidationErrorResponseType.js");
const {BankAccountPaymentTransactionResponseV2} = require("./v2/BankAccountPaymentTransactionResponseType.js");
const {BankAccountResponseV2} = require("./v2/BankAccountResponseType.js");
const {CreditCardResponseV2} = require("./v2/CreditCardResponseType.js");
const {CarrierAccessorialPriceCalculationV1} = require("./v1/CarrierAccessorialPriceCalculationType.js");
const {CarrierAccessorialPricingV1} = require("./v1/CarrierAccessorialPricingType.js");
const {CarrierEquipmentPriceCalculationV1} = require("./v1/CarrierEquipmentPriceCalculationType.js");
const {CarrierEquipmentPricingV1} = require("./v1/CarrierEquipmentPricingType.js");
const {CommonMetadataV1} = require("./v1/CommonMetadataType.js");
const {EmailV1} = require("./v1/EmailType.js");
const {FirstNameV1} = require("./v1/FirstNameType.js");
const {FullNameV1} = require("./v1/FullNameType.js");
const {IdV1} = require("./v1/IdType.js");
const {LastNameV1} = require("./v1/LastNameType.js");
const {LatitudeV1} = require("./v1/LatitudeType.js");
const {LongitudeV1} = require("./v1/LongitudeType.js");
const {NotificationLanguageMessageV1} = require("./v1/NotificationLanguageMessageType.js");
const {PercentDetailsV1} = require("./v1/PercentDetailsType.js");
const {PersistentNotificationMessageDataV1} = require("./v1/PersistentNotificationMessageDataType.js");
const {PersistentNotificationMessageDataDeliveryV1} = require("./v1/PersistentNotificationMessageDataDeliveryType.js");
const {PersistentNotificationMessageDataRecipientV1} = require("./v1/PersistentNotificationMessageDataRecipientType.js");
const {PhoneV1} = require("./v1/PhoneType.js");
const {PriceAdjustmentV1} = require("./v1/PriceAdjustmentType.js");
const {ShipperPaymentTermsPropertiesV1} = require("./v1/ShipperPaymentTermsPropertiesType.js");
const {SnsMessageDetailsV1} = require("./v1/SnsMessageDetailsType.js");
const {TagsV1} = require("./v1/TagsType.js");
const {TaxCalculationV1} = require("./v1/TaxCalculationType.js");
const {TaxDetailV1} = require("./v1/TaxDetailType.js");
const {TaxRateDetailV1} = require("./v1/TaxRateDetailType.js");
const {GetShipperPaymentDateForShipmentV1} = require("./v1/GetShipperPaymentDateForShipmentType.js");
const {SnsBodyV1} = require("./v1/SnsBodyType.js");
const {SnsEventV1} = require("./v1/SnsEventType.js");
const {SnsMsgHubspotManageCompanyV1} = require("./v1/SnsMsgHubspotManageCompanyType.js");
const {SnsMsgHubspotManageContactV1} = require("./v1/SnsMsgHubspotManageContactType.js");
const {SnsMsgHubspotManageInternalUserCreationV1} = require("./v1/SnsMsgHubspotManageInternalUserCreationType.js");
const {SnsMsgHubspotPaymentTermsV1} = require("./v1/SnsMsgHubspotPaymentTermsType.js");
const {SnsMsgHubspotUpdateV1} = require("./v1/SnsMsgHubspotUpdateType.js");
const {SnsMsgUserUpdateV1} = require("./v1/SnsMsgUserUpdateType.js");
const {SnsRecordV1} = require("./v1/SnsRecordType.js");
const {UpdateShipmentStatusV1} = require("./v1/UpdateShipmentStatusType.js");
const {AddAmountToCarrierTransactionByOrderRefRequestV2} = require("./v2/AddAmountToCarrierTransactionByOrderRefRequestType.js");
const {AdditionalTruxwebShipperChargesRequestV2} = require("./v2/AdditionalTruxwebShipperChargesRequestType.js");
const {PaymentMessageBodyV2} = require("./v2/PaymentMessageBodyType.js");
const {AccessorialDurationOptionV1} = require("./v1/AccessorialDurationOptionEnum.js");
const {AccessorialOptionV1} = require("./v1/AccessorialOptionEnum.js");
const {AccessorialTimeOptionV1} = require("./v1/AccessorialTimeOptionEnum.js");
const {AllInFScV1} = require("./v1/AllInFScEnum.js");
const {AvsV1} = require("./v1/AvsEnum.js");
const {BankAcctReturnTypeV1} = require("./v1/BankAcctReturnTypeEnum.js");
const {BankAcctTransactionTypeV1} = require("./v1/BankAcctTransactionTypeEnum.js");
const {BankAcctTransferTypeV1} = require("./v1/BankAcctTransferTypeEnum.js");
const {BankAcctTypeV1} = require("./v1/BankAcctTypeEnum.js");
const {BillingTransactionMessageActionTypesV1} = require("./v1/BillingTransactionMessageActionTypesEnum.js");
const {CarrierFeatureV1} = require("./v1/CarrierFeatureEnum.js");
const {CarrierIdentifierTypeV1} = require("./v1/CarrierIdentifierTypeEnum.js");
const {CarrierServiceTypeV1} = require("./v1/CarrierServiceTypeEnum.js");
const {CarrierTruckTypeV1} = require("./v1/CarrierTruckTypeEnum.js");
const {CompanySizeV1} = require("./v1/CompanySizeEnum.js");
const {ContactOptionsV1} = require("./v1/ContactOptionsEnum.js");
const {CountryV1} = require("./v1/CountryEnum.js");
const {CreditCardTransactionTypeV1} = require("./v1/CreditCardTransactionTypeEnum.js");
const {CreditCardTypeV1} = require("./v1/CreditCardTypeEnum.js");
const {CspUserRoleV1} = require("./v1/CspUserRoleEnum.js");
const {CspUserStatusV1} = require("./v1/CspUserStatusEnum.js");
const {CurrencyV1} = require("./v1/CurrencyEnum.js");
const {DangerousGoodsV1} = require("./v1/DangerousGoodsEnum.js");
const {DatabaseSpecialColumnTypeV1} = require("./v1/DatabaseSpecialColumnTypeEnum.js");
const {DayOfWeekV1} = require("./v1/DayOfWeekEnum.js");
const {DoyonDeliveryTypeV1} = require("./v1/DoyonDeliveryTypeEnum.js");
const {EcommCompanyRoleV1} = require("./v1/EcommCompanyRoleEnum.js");
const {EcommUserRoleV1} = require("./v1/EcommUserRoleEnum.js");
const {ElasticSearchGeoShapeV1} = require("./v1/ElasticSearchGeoShapeEnum.js");
const {EldPackagesV1} = require("./v1/EldPackagesEnum.js");
const {EmailTemplatesV1} = require("./v1/EmailTemplatesEnum.js");
const {EquipmentCodeV1} = require("./v1/EquipmentCodeEnum.js");
const {ExternalEmailAggregatorsV1} = require("./v1/ExternalEmailAggregatorsEnum.js");
const {FilterOperationV1} = require("./v1/FilterOperationEnum.js");
const {HubspotCaptureSourceV1} = require("./v1/HubspotCaptureSourceEnum.js");
const {HubspotCompanyTypeV1} = require("./v1/HubspotCompanyTypeEnum.js");
const {HubspotLeadStatusV1} = require("./v1/HubspotLeadStatusEnum.js");
const {HubspotLifecycleStatusV1} = require("./v1/HubspotLifecycleStatusEnum.js");
const {HubspotPersonaV1} = require("./v1/HubspotPersonaEnum.js");
const {HubspotWantToHelpUsV1} = require("./v1/HubspotWantToHelpUsEnum.js");
const {InternalUserPermissionV1} = require("./v1/InternalUserPermissionEnum.js");
const {JoinTheRevolutionOptionV1} = require("./v1/JoinTheRevolutionOptionEnum.js");
const {LanguageV1} = require("./v1/LanguageEnum.js");
const {LocationZoneTypeV1} = require("./v1/LocationZoneTypeEnum.js");
const {LtlPackagingV1} = require("./v1/LtlPackagingEnum.js");
const {MetadataTypeV1} = require("./v1/MetadataTypeEnum.js");
const {NotificationMessageTypeV1} = require("./v1/NotificationMessageTypeEnum.js");
const {NotificationMethodV1} = require("./v1/NotificationMethodEnum.js");
const {NotificationTypeV1} = require("./v1/NotificationTypeEnum.js");
const {PageAlertStatusV1} = require("./v1/PageAlertStatusEnum.js");
const {PaymentMethodV1} = require("./v1/PaymentMethodEnum.js");
const {PaymentTermsStatusV1} = require("./v1/PaymentTermsStatusEnum.js");
const {PaymentTermsTypeV1} = require("./v1/PaymentTermsTypeEnum.js");
const {PaymentTransactionStatusV1} = require("./v1/PaymentTransactionStatusEnum.js");
const {PermissionV1} = require("./v1/PermissionEnum.js");
const {PriceTypeV1} = require("./v1/PriceTypeEnum.js");
const {PropertyTypeV1} = require("./v1/PropertyTypeEnum.js");
const {QuoteCarrierRefusalReasonV1} = require("./v1/QuoteCarrierRefusalReasonEnum.js");
const {QuoteShipperAcceptanceReasonV1} = require("./v1/QuoteShipperAcceptanceReasonEnum.js");
const {QuoteShipperCancellationReasonV1} = require("./v1/QuoteShipperCancellationReasonEnum.js");
const {QuoteShipperRefusalReasonV1} = require("./v1/QuoteShipperRefusalReasonEnum.js");
const {RangeUnitOfMeasureV1} = require("./v1/RangeUnitOfMeasureEnum.js");
const {RateSheetBoundaryTypeV1} = require("./v1/RateSheetBoundaryTypeEnum.js");
const {RateSheetTypeV1} = require("./v1/RateSheetTypeEnum.js");
const {RequiredOptionalV1} = require("./v1/RequiredOptionalEnum.js");
const {RequiredTruckFeatureV1} = require("./v1/RequiredTruckFeatureEnum.js");
const {SearchParamsV1} = require("./v1/SearchParamsEnum.js");
const {SearchQuoteFormStepsV1} = require("./v1/SearchQuoteFormStepsEnum.js");
const {ServiceDirectionV1} = require("./v1/ServiceDirectionEnum.js");
const {ShipmentAdditionalChargeTypeV1} = require("./v1/ShipmentAdditionalChargeTypeEnum.js");
const {ShipmentCarrierQuoteStatusV1} = require("./v1/ShipmentCarrierQuoteStatusEnum.js");
const {ShipmentChargeStatusV1} = require("./v1/ShipmentChargeStatusEnum.js");
const {ShipmentChargeTypeV1} = require("./v1/ShipmentChargeTypeEnum.js");
const {ShipmentDelayedStatusV1} = require("./v1/ShipmentDelayedStatusEnum.js");
const {ShipmentDisplayStatusV1} = require("./v1/ShipmentDisplayStatusEnum.js");
const {ShipmentDocumentTypeV1} = require("./v1/ShipmentDocumentTypeEnum.js");
const {ShipmentEquipmentIdentifierTypeV1} = require("./v1/ShipmentEquipmentIdentifierTypeEnum.js");
const {ShipmentFeeTypeEndpointsV1} = require("./v1/ShipmentFeeTypeEndpointsEnum.js");
const {ShipmentIdentifierTypeV1} = require("./v1/ShipmentIdentifierTypeEnum.js");
const {ShipmentLocationTypeV1} = require("./v1/ShipmentLocationTypeEnum.js");
const {ShipmentQuoteRequestStatusV1} = require("./v1/ShipmentQuoteRequestStatusEnum.js");
const {ShipmentShipperQuoteStatusV1} = require("./v1/ShipmentShipperQuoteStatusEnum.js");
const {ShipmentStatusV1} = require("./v1/ShipmentStatusEnum.js");
const {ShipmentStatusGroupV1} = require("./v1/ShipmentStatusGroupEnum.js");
const {ShipmentTrackerV1} = require("./v1/ShipmentTrackerEnum.js");
const {ShipmentTrackingLocationArrivalStatusV1} = require("./v1/ShipmentTrackingLocationArrivalStatusEnum.js");
const {ShipmentTrackingLocationStatusV1} = require("./v1/ShipmentTrackingLocationStatusEnum.js");
const {ShipmentTrackingStatusV1} = require("./v1/ShipmentTrackingStatusEnum.js");
const {ShipmentTransactionNotificationActionV1} = require("./v1/ShipmentTransactionNotificationActionEnum.js");
const {ShipmentTruxwebAdditionalChargeTypeV1} = require("./v1/ShipmentTruxwebAdditionalChargeTypeEnum.js");
const {StandardInvoiceTransactionStatusV1} = require("./v1/StandardInvoiceTransactionStatusEnum.js");
const {StatusGenericV1} = require("./v1/StatusGenericEnum.js");
const {SubscriptionV1} = require("./v1/SubscriptionEnum.js");
const {SubscriptionBillingFrequencyV1} = require("./v1/SubscriptionBillingFrequencyEnum.js");
const {SubscriptionFeaturesV1} = require("./v1/SubscriptionFeaturesEnum.js");
const {TmsPackagesV1} = require("./v1/TmsPackagesEnum.js");
const {TruckloadTypeV1} = require("./v1/TruckloadTypeEnum.js");
const {UniqueIdentifierTypeV1} = require("./v1/UniqueIdentifierTypeEnum.js");
const {UserStatusV1} = require("./v1/UserStatusEnum.js");
const {UserTypeV1} = require("./v1/UserTypeEnum.js");
const {UserUpdateSubjectV1} = require("./v1/UserUpdateSubjectEnum.js");
const {YesNoV1} = require("./v1/YesNoEnum.js");
const {ZoneShapeTypeV1} = require("./v1/ZoneShapeTypeEnum.js");
const  DbAccessorialV1Schema = require("./v1/dbAccessorial.json");
const  DbAccessorialInsertV1Schema = require("./v1/dbAccessorialInsert.json");
const  DbEquipmentV1Schema = require("./v1/dbEquipment.json");
const  DbEquipmentInsertV1Schema = require("./v1/dbEquipmentInsert.json");
const  BankAccountProfileV2Schema = require("./v2/bankAccountProfile.json");
const  BankAccountTransactionV2Schema = require("./v2/bankAccountTransaction.json");
const  BankAccountTransactionHistoryV2Schema = require("./v2/bankAccountTransactionHistory.json");
const  CreditCardProfileV2Schema = require("./v2/creditCardProfile.json");
const  CreditCardTransactionV2Schema = require("./v2/creditCardTransaction.json");
const  CreditCardTransactionHistoryV2Schema = require("./v2/creditCardTransactionHistory.json");
const  SESEmailTemplateV1Schema = require("./v1/SESEmailTemplate.json");
const  AccessorialV1Schema = require("./v1/accessorial.json");
const  AccessorialListItemV1Schema = require("./v1/accessorialListItem.json");
const  AccessorialMetadataEntryV1Schema = require("./v1/accessorialMetadataEntry.json");
const  AdditionalShipmentChargeV1Schema = require("./v1/additionalShipmentCharge.json");
const  AddressV1Schema = require("./v1/address.json");
const  AdminShipmentV1Schema = require("./v1/adminShipment.json");
const  AlertV1Schema = require("./v1/alert.json");
const  BatchNotificationQueueMessageV1Schema = require("./v1/batchNotificationQueueMessage.json");
const  BookingLocationV1Schema = require("./v1/bookingLocation.json");
const  BookingSearchDocumentV1Schema = require("./v1/bookingSearchDocument.json");
const  BookingSearchResultV1Schema = require("./v1/bookingSearchResult.json");
const  BookingSearchSummaryV1Schema = require("./v1/bookingSearchSummary.json");
const  CarrierV1Schema = require("./v1/carrier.json");
const  CarrierAccessorialV1Schema = require("./v1/carrierAccessorial.json");
const  CarrierAccessorialMapV1Schema = require("./v1/carrierAccessorialMap.json");
const  CarrierEquipmentV1Schema = require("./v1/carrierEquipment.json");
const  CarrierFuelSurchargeV1Schema = require("./v1/carrierFuelSurcharge.json");
const  CarrierLaneV1Schema = require("./v1/carrierLane.json");
const  CarrierLaneEquipmentV1Schema = require("./v1/carrierLaneEquipment.json");
const  CarrierLaneLocationV1Schema = require("./v1/carrierLaneLocation.json");
const  CarrierLocationV1Schema = require("./v1/carrierLocation.json");
const  CarrierLocationZoneV1Schema = require("./v1/carrierLocationZone.json");
const  CarrierProfileDisplayV1Schema = require("./v1/carrierProfileDisplay.json");
const  CarrierProfileRecordV1Schema = require("./v1/carrierProfileRecord.json");
const  CarrierPublicProfileV1Schema = require("./v1/carrierPublicProfile.json");
const  CarrierQuoteDataGridRowV1Schema = require("./v1/carrierQuoteDataGridRow.json");
const  CarrierRegistrationV1Schema = require("./v1/carrierRegistration.json");
const  CarrierShipmentQuoteRequestV1Schema = require("./v1/carrierShipmentQuoteRequest.json");
const  CarrierZoneV1Schema = require("./v1/carrierZone.json");
const  CarrierZoneCoordinateV1Schema = require("./v1/carrierZoneCoordinate.json");
const  ChargeV1Schema = require("./v1/charge.json");
const  ChargeDescriptionV1Schema = require("./v1/chargeDescription.json");
const  CompanyV1Schema = require("./v1/company.json");
const  CompanyDocumentV1Schema = require("./v1/companyDocument.json");
const  CompanyLocationV1Schema = require("./v1/companyLocation.json");
const  CompanyShipmentFeeV1Schema = require("./v1/companyShipmentFee.json");
const  CompanySubscriptionV1Schema = require("./v1/companySubscription.json");
const  CostDetailV1Schema = require("./v1/costDetail.json");
const  CspPermissionRecordV1Schema = require("./v1/cspPermissionRecord.json");
const  CspUserV1Schema = require("./v1/cspUser.json");
const  DoyonLoadDefinitionV1Schema = require("./v1/doyonLoadDefinition.json");
const  DoyonLtlCargoDefinitionV1Schema = require("./v1/doyonLtlCargoDefinition.json");
const  ElasticSearchLocationV1Schema = require("./v1/elasticSearchLocation.json");
const  ElementHeightWidthV1Schema = require("./v1/elementHeightWidth.json");
const  EntityMetadataV1Schema = require("./v1/entityMetadata.json");
const  EquipmentV1Schema = require("./v1/equipment.json");
const  EquipmentOnProfileV1Schema = require("./v1/equipmentOnProfile.json");
const  EquipmentPricingDataV1Schema = require("./v1/equipmentPricingData.json");
const  ExportRequestV1Schema = require("./v1/exportRequest.json");
const  FeeDetailV1Schema = require("./v1/feeDetail.json");
const  FuelSurchargeV1Schema = require("./v1/fuelSurcharge.json");
const  GeolocationV1Schema = require("./v1/geolocation.json");
const  GooglePlaceV1Schema = require("./v1/googlePlace.json");
const  HubspotCompanyV1Schema = require("./v1/hubspotCompany.json");
const  HubspotContactV1Schema = require("./v1/hubspotContact.json");
const  HubspotTicketV1Schema = require("./v1/hubspotTicket.json");
const  LatLngV1Schema = require("./v1/latLng.json");
const  ListItemAccessorialV1Schema = require("./v1/listItemAccessorial.json");
const  LoadDefinitionV1Schema = require("./v1/loadDefinition.json");
const  LoadParametersV1Schema = require("./v1/loadParameters.json");
const  LocationScheduleV1Schema = require("./v1/locationSchedule.json");
const  LtlCargoDefinitionV1Schema = require("./v1/ltlCargoDefinition.json");
const  LtlPackageDimensionsV1Schema = require("./v1/ltlPackageDimensions.json");
const  PaymentMessageBodyV1Schema = require("./v1/paymentMessageBody.json");
const  PaymentTermsAdjustmentV1Schema = require("./v1/paymentTermsAdjustment.json");
const  PersistentNotificationRecipientV1Schema = require("./v1/persistentNotificationRecipient.json");
const  PersistentNotificationRecipientDeliveryV1Schema = require("./v1/persistentNotificationRecipientDelivery.json");
const  PriceV1Schema = require("./v1/price.json");
const  QuoteCommentV1Schema = require("./v1/quoteComment.json");
const  QuoteCommentAttachmentV1Schema = require("./v1/quoteCommentAttachment.json");
const  QuoteSearchFormDataV1Schema = require("./v1/quoteSearchFormData.json");
const  RatePerDistanceV1Schema = require("./v1/ratePerDistance.json");
const  RateSheetLocationV1Schema = require("./v1/rateSheetLocation.json");
const  RateSheetRegionV1Schema = require("./v1/rateSheetRegion.json");
const  RatingV1Schema = require("./v1/rating.json");
const  ServiceRangeV1Schema = require("./v1/serviceRange.json");
const  ShipmentV1Schema = require("./v1/shipment.json");
const  ShipmentAccessorialV1Schema = require("./v1/shipmentAccessorial.json");
const  ShipmentAccessorialMetadataV1Schema = require("./v1/shipmentAccessorialMetadata.json");
const  ShipmentAccessorialMetadataInputV1Schema = require("./v1/shipmentAccessorialMetadataInput.json");
const  ShipmentCommentV1Schema = require("./v1/shipmentComment.json");
const  ShipmentCommentAttachmentV1Schema = require("./v1/shipmentCommentAttachment.json");
const  ShipmentDetailsV1Schema = require("./v1/shipmentDetails.json");
const  ShipmentDocumentV1Schema = require("./v1/shipmentDocument.json");
const  ShipmentDocumentToUploadV1Schema = require("./v1/shipmentDocumentToUpload.json");
const  ShipmentEquipmentV1Schema = require("./v1/shipmentEquipment.json");
const  ShipmentFeeTypeV1Schema = require("./v1/shipmentFeeType.json");
const  ShipmentLocationV1Schema = require("./v1/shipmentLocation.json");
const  ShipmentPaymentTermsV1Schema = require("./v1/shipmentPaymentTerms.json");
const  ShipmentPendingTransactionSettlementV1Schema = require("./v1/shipmentPendingTransactionSettlement.json");
const  ShipmentQuoteAccessorialV1Schema = require("./v1/shipmentQuoteAccessorial.json");
const  ShipmentQuoteEquipmentV1Schema = require("./v1/shipmentQuoteEquipment.json");
const  ShipmentQuoteRequestV1Schema = require("./v1/shipmentQuoteRequest.json");
const  ShipmentQuoteRequestBookingDateV1Schema = require("./v1/shipmentQuoteRequestBookingDate.json");
const  ShipmentQuoteRequestChargeV1Schema = require("./v1/shipmentQuoteRequestCharge.json");
const  ShipmentQuoteRequestLocationV1Schema = require("./v1/shipmentQuoteRequestLocation.json");
const  ShipmentRequiredDocumentV1Schema = require("./v1/shipmentRequiredDocument.json");
const  ShipmentRequiringAttentionV1Schema = require("./v1/shipmentRequiringAttention.json");
const  ShipmentStatusLogEntryV1Schema = require("./v1/shipmentStatusLogEntry.json");
const  ShipmentSummaryItemV1Schema = require("./v1/shipmentSummaryItem.json");
const  ShipmentTrackingCarrierIdentiferV1Schema = require("./v1/shipmentTrackingCarrierIdentifer.json");
const  ShipmentTrackingEquipmentIdentiferV1Schema = require("./v1/shipmentTrackingEquipmentIdentifer.json");
const  ShipmentTrackingLocationV1Schema = require("./v1/shipmentTrackingLocation.json");
const  ShipmentTrackingShipmentIdentiferV1Schema = require("./v1/shipmentTrackingShipmentIdentifer.json");
const  ShipmentTrackingStatusUpdateV1Schema = require("./v1/shipmentTrackingStatusUpdate.json");
const  ShipmentTrackingTruckloadV1Schema = require("./v1/shipmentTrackingTruckload.json");
const  ShipmentTransactionV1Schema = require("./v1/shipmentTransaction.json");
const  ShipmentTransactionUpdateV1Schema = require("./v1/shipmentTransactionUpdate.json");
const  ShipmentTypeV1Schema = require("./v1/shipmentType.json");
const  ShipperPaymentTermsV1Schema = require("./v1/shipperPaymentTerms.json");
const  ShipperQuoteDataGridRowV1Schema = require("./v1/shipperQuoteDataGridRow.json");
const  ShipperShipmentQuoteRequestV1Schema = require("./v1/shipperShipmentQuoteRequest.json");
const  StandardInvoiceTransactionV1Schema = require("./v1/standardInvoiceTransaction.json");
const  StandardInvoiceTransactionHistoryV1Schema = require("./v1/standardInvoiceTransactionHistory.json");
const  SubscriptionPlanV1Schema = require("./v1/subscriptionPlan.json");
const  TableFilterV1Schema = require("./v1/tableFilter.json");
const  TaxCostDetailV1Schema = require("./v1/taxCostDetail.json");
const  TruxwebMarginDetailsV1Schema = require("./v1/truxwebMarginDetails.json");
const  UserV1Schema = require("./v1/user.json");
const  UserBillingProfileV1Schema = require("./v1/userBillingProfile.json");
const  UserRegistrationV1Schema = require("./v1/userRegistration.json");
const  UserRegistrationFormV1Schema = require("./v1/userRegistrationForm.json");
const  UserRoleV1Schema = require("./v1/userRole.json");
const  UserShipmentV1Schema = require("./v1/userShipment.json");
const  AddBankAcctRequestV1Schema = require("./v1/addBankAcctRequest.json");
const  AddCardRequestV1Schema = require("./v1/addCardRequest.json");
const  AddCarrierShipmentDocumentV1Schema = require("./v1/addCarrierShipmentDocument.json");
const  AddUserToCompanyV1Schema = require("./v1/addUserToCompany.json");
const  AdjustShipperTransactionV1Schema = require("./v1/adjustShipperTransaction.json");
const  AreBankTransactionsWithProfileForShipmentRequestV1Schema = require("./v1/areBankTransactionsWithProfileForShipmentRequest.json");
const  BankAcctPaymentRequestV1Schema = require("./v1/bankAcctPaymentRequest.json");
const  BookingRequestV1Schema = require("./v1/bookingRequest.json");
const  BookingSearchRequestV1Schema = require("./v1/bookingSearchRequest.json");
const  CalculatePriceRequestV1Schema = require("./v1/calculatePriceRequest.json");
const  CompleteQuoteToShipmentV1Schema = require("./v1/completeQuoteToShipment.json");
const  CompleteShipmentRequestV1Schema = require("./v1/completeShipmentRequest.json");
const  ConfirmationRequestV1Schema = require("./v1/confirmationRequest.json");
const  ContactFormRequestV1Schema = require("./v1/contactFormRequest.json");
const  CreateEcommUserV1Schema = require("./v1/createEcommUser.json");
const  CreateShipmentQuoteRequestV1Schema = require("./v1/createShipmentQuoteRequest.json");
const  CreateShipperTicketForEmptySearchResultsRequestV1Schema = require("./v1/createShipperTicketForEmptySearchResultsRequest.json");
const  CreditCardPaymentByProfileRequestV1Schema = require("./v1/creditCardPaymentByProfileRequest.json");
const  CreditCardPaymentByTokenRequestV1Schema = require("./v1/creditCardPaymentByTokenRequest.json");
const  CreditCardPaymentRequestV1Schema = require("./v1/creditCardPaymentRequest.json");
const  DoyonBookingSearchRequestV1Schema = require("./v1/doyonBookingSearchRequest.json");
const  DoyonDespresFetchQuoteRequestV1Schema = require("./v1/doyonDespresFetchQuoteRequest.json");
const  DoyonFetchSearchResultsRequestV1Schema = require("./v1/doyonFetchSearchResultsRequest.json");
const  FetchSearchResultsRequestV1Schema = require("./v1/fetchSearchResultsRequest.json");
const  GeneratePdfRequestV1Schema = require("./v1/generatePdfRequest.json");
const  GetCompanyShipmentSummaryRequestV1Schema = require("./v1/getCompanyShipmentSummaryRequest.json");
const  GetShipmentRefsForCompanyRequestV1Schema = require("./v1/getShipmentRefsForCompanyRequest.json");
const  GetUserBankAccountsRequestV1Schema = require("./v1/getUserBankAccountsRequest.json");
const  SaveUserRequestV1Schema = require("./v1/saveUserRequest.json");
const  SendEmailNotificationRequestV1Schema = require("./v1/sendEmailNotificationRequest.json");
const  SendEmailRequestV1Schema = require("./v1/sendEmailRequest.json");
const  SendNotificationsRequestV1Schema = require("./v1/sendNotificationsRequest.json");
const  ShipmentPaymentMethodUpdateV1Schema = require("./v1/shipmentPaymentMethodUpdate.json");
const  ShipperPaymentTermsRequestV1Schema = require("./v1/shipperPaymentTermsRequest.json");
const  TransactionChangeRequestV1Schema = require("./v1/transactionChangeRequest.json");
const  UpdateCarrierQuoteRequestV1Schema = require("./v1/updateCarrierQuoteRequest.json");
const  UpdateCompanyDetailsRequestV1Schema = require("./v1/updateCompanyDetailsRequest.json");
const  UpdateTransactionAmountAndSettlementDateV1Schema = require("./v1/updateTransactionAmountAndSettlementDate.json");
const  UpdateUserRequestV1Schema = require("./v1/updateUserRequest.json");
const  UserPaymentTermsRequestV1Schema = require("./v1/userPaymentTermsRequest.json");
const  AddBankAccountRequestV2Schema = require("./v2/addBankAccountRequest.json");
const  AddCardRequestV2Schema = require("./v2/addCardRequest.json");
const  BankAccountHoldRequestV2Schema = require("./v2/bankAccountHoldRequest.json");
const  BankAccountPaymentRequestV2Schema = require("./v2/bankAccountPaymentRequest.json");
const  CreditCardPaymentByProfileRequestV2Schema = require("./v2/creditCardPaymentByProfileRequest.json");
const  AreBankTransactionsWithProfileForShipmentResponseV1Schema = require("./v1/areBankTransactionsWithProfileForShipmentResponse.json");
const  BankAcctPaymentTransactionResponseV1Schema = require("./v1/bankAcctPaymentTransactionResponse.json");
const  BankAcctResponseV1Schema = require("./v1/bankAcctResponse.json");
const  BookingSearchResponseV1Schema = require("./v1/bookingSearchResponse.json");
const  CalculatePriceResponseV1Schema = require("./v1/calculatePriceResponse.json");
const  CreditCardPaymentTransactionResponseV1Schema = require("./v1/creditCardPaymentTransactionResponse.json");
const  CreditCardResponseV1Schema = require("./v1/creditCardResponse.json");
const  DoyonDespresFetchQuoteResponseV1Schema = require("./v1/doyonDespresFetchQuoteResponse.json");
const  FetchCompanyDetailsResponseV1Schema = require("./v1/fetchCompanyDetailsResponse.json");
const  GenericErrorResponseV1Schema = require("./v1/genericErrorResponse.json");
const  GetShipmentDetailsResponseV1Schema = require("./v1/getShipmentDetailsResponse.json");
const  GetShipmentRefsForCompanyResponseV1Schema = require("./v1/getShipmentRefsForCompanyResponse.json");
const  InAppNotificationV1Schema = require("./v1/inAppNotification.json");
const  ListCarrierRecordV1Schema = require("./v1/listCarrierRecord.json");
const  ListShipmentsResponseV1Schema = require("./v1/listShipmentsResponse.json");
const  ListShipmentsResponseWrapperV1Schema = require("./v1/listShipmentsResponseWrapper.json");
const  ListUserSummaryRecordV1Schema = require("./v1/listUserSummaryRecord.json");
const  ParseCarrierAvailabilityV1Schema = require("./v1/parseCarrierAvailability.json");
const  ProcessBankAccountPaymentResponseV1Schema = require("./v1/processBankAccountPaymentResponse.json");
const  ProcessCreditCardProfileResponseV1Schema = require("./v1/processCreditCardProfileResponse.json");
const  QuoteStatusCountV1Schema = require("./v1/quoteStatusCount.json");
const  SearchEquipmentV1Schema = require("./v1/searchEquipment.json");
const  ShipmentSummaryResponseV1Schema = require("./v1/shipmentSummaryResponse.json");
const  UserBillingProfilesResponseV1Schema = require("./v1/userBillingProfilesResponse.json");
const  UserShipmentSummaryDataV1Schema = require("./v1/userShipmentSummaryData.json");
const  ValidateUserPermissionsV1Schema = require("./v1/validateUserPermissions.json");
const  ValidationErrorResponseV1Schema = require("./v1/validationErrorResponse.json");
const  BankAccountPaymentTransactionResponseV2Schema = require("./v2/bankAccountPaymentTransactionResponse.json");
const  BankAccountResponseV2Schema = require("./v2/bankAccountResponse.json");
const  CreditCardResponseV2Schema = require("./v2/creditCardResponse.json");
const  CarrierAccessorialPriceCalculationV1Schema = require("./v1/carrierAccessorialPriceCalculation.json");
const  CarrierAccessorialPricingV1Schema = require("./v1/carrierAccessorialPricing.json");
const  CarrierEquipmentPriceCalculationV1Schema = require("./v1/carrierEquipmentPriceCalculation.json");
const  CarrierEquipmentPricingV1Schema = require("./v1/carrierEquipmentPricing.json");
const  CommonMetadataV1Schema = require("./v1/commonMetadata.json");
const  EmailV1Schema = require("./v1/email.json");
const  FirstNameV1Schema = require("./v1/firstName.json");
const  FullNameV1Schema = require("./v1/fullName.json");
const  IdV1Schema = require("./v1/id.json");
const  LastNameV1Schema = require("./v1/lastName.json");
const  LatitudeV1Schema = require("./v1/latitude.json");
const  LongitudeV1Schema = require("./v1/longitude.json");
const  NotificationLanguageMessageV1Schema = require("./v1/notificationLanguageMessage.json");
const  PercentDetailsV1Schema = require("./v1/percentDetails.json");
const  PersistentNotificationMessageDataV1Schema = require("./v1/persistentNotificationMessageData.json");
const  PersistentNotificationMessageDataDeliveryV1Schema = require("./v1/persistentNotificationMessageDataDelivery.json");
const  PersistentNotificationMessageDataRecipientV1Schema = require("./v1/persistentNotificationMessageDataRecipient.json");
const  PhoneV1Schema = require("./v1/phone.json");
const  PriceAdjustmentV1Schema = require("./v1/priceAdjustment.json");
const  ShipperPaymentTermsPropertiesV1Schema = require("./v1/shipperPaymentTermsProperties.json");
const  SnsMessageDetailsV1Schema = require("./v1/snsMessageDetails.json");
const  TagsV1Schema = require("./v1/tags.json");
const  TaxCalculationV1Schema = require("./v1/taxCalculation.json");
const  TaxDetailV1Schema = require("./v1/taxDetail.json");
const  TaxRateDetailV1Schema = require("./v1/taxRateDetail.json");
const  GetShipperPaymentDateForShipmentV1Schema = require("./v1/getShipperPaymentDateForShipment.json");
const  SnsBodyV1Schema = require("./v1/snsBody.json");
const  SnsEventV1Schema = require("./v1/snsEvent.json");
const  SnsMsgHubspotManageCompanyV1Schema = require("./v1/snsMsgHubspotManageCompany.json");
const  SnsMsgHubspotManageContactV1Schema = require("./v1/snsMsgHubspotManageContact.json");
const  SnsMsgHubspotManageInternalUserCreationV1Schema = require("./v1/snsMsgHubspotManageInternalUserCreation.json");
const  SnsMsgHubspotPaymentTermsV1Schema = require("./v1/snsMsgHubspotPaymentTerms.json");
const  SnsMsgHubspotUpdateV1Schema = require("./v1/snsMsgHubspotUpdate.json");
const  SnsMsgUserUpdateV1Schema = require("./v1/snsMsgUserUpdate.json");
const  SnsRecordV1Schema = require("./v1/snsRecord.json");
const  UpdateShipmentStatusV1Schema = require("./v1/updateShipmentStatus.json");
const  AddAmountToCarrierTransactionByOrderRefRequestV2Schema = require("./v2/addAmountToCarrierTransactionByOrderRefRequest.json");
const  AdditionalTruxwebShipperChargesRequestV2Schema = require("./v2/additionalTruxwebShipperChargesRequest.json");
const  PaymentMessageBodyV2Schema = require("./v2/paymentMessageBody.json");
const  AccessorialDurationOptionV1Schema = require("./v1/accessorialDurationOption.json");
const  AccessorialOptionV1Schema = require("./v1/accessorialOption.json");
const  AccessorialTimeOptionV1Schema = require("./v1/accessorialTimeOption.json");
const  AllInFScV1Schema = require("./v1/allInFSc.json");
const  AvsV1Schema = require("./v1/avs.json");
const  BankAcctReturnTypeV1Schema = require("./v1/bankAcctReturnType.json");
const  BankAcctTransactionTypeV1Schema = require("./v1/bankAcctTransactionType.json");
const  BankAcctTransferTypeV1Schema = require("./v1/bankAcctTransferType.json");
const  BankAcctTypeV1Schema = require("./v1/bankAcctType.json");
const  BillingTransactionMessageActionTypesV1Schema = require("./v1/billingTransactionMessageActionTypes.json");
const  CarrierFeatureV1Schema = require("./v1/carrierFeature.json");
const  CarrierIdentifierTypeV1Schema = require("./v1/carrierIdentifierType.json");
const  CarrierServiceTypeV1Schema = require("./v1/carrierServiceType.json");
const  CarrierTruckTypeV1Schema = require("./v1/carrierTruckType.json");
const  CompanySizeV1Schema = require("./v1/companySize.json");
const  ContactOptionsV1Schema = require("./v1/contactOptions.json");
const  CountryV1Schema = require("./v1/country.json");
const  CreditCardTransactionTypeV1Schema = require("./v1/creditCardTransactionType.json");
const  CreditCardTypeV1Schema = require("./v1/creditCardType.json");
const  CspUserRoleV1Schema = require("./v1/cspUserRole.json");
const  CspUserStatusV1Schema = require("./v1/cspUserStatus.json");
const  CurrencyV1Schema = require("./v1/currency.json");
const  DangerousGoodsV1Schema = require("./v1/dangerousGoods.json");
const  DatabaseSpecialColumnTypeV1Schema = require("./v1/databaseSpecialColumnType.json");
const  DayOfWeekV1Schema = require("./v1/dayOfWeek.json");
const  DoyonDeliveryTypeV1Schema = require("./v1/doyonDeliveryType.json");
const  EcommCompanyRoleV1Schema = require("./v1/ecommCompanyRole.json");
const  EcommUserRoleV1Schema = require("./v1/ecommUserRole.json");
const  ElasticSearchGeoShapeV1Schema = require("./v1/elasticSearchGeoShape.json");
const  EldPackagesV1Schema = require("./v1/eldPackages.json");
const  EmailTemplatesV1Schema = require("./v1/emailTemplates.json");
const  EquipmentCodeV1Schema = require("./v1/equipmentCode.json");
const  ExternalEmailAggregatorsV1Schema = require("./v1/externalEmailAggregators.json");
const  FilterOperationV1Schema = require("./v1/filterOperation.json");
const  HubspotCaptureSourceV1Schema = require("./v1/hubspotCaptureSource.json");
const  HubspotCompanyTypeV1Schema = require("./v1/hubspotCompanyType.json");
const  HubspotLeadStatusV1Schema = require("./v1/hubspotLeadStatus.json");
const  HubspotLifecycleStatusV1Schema = require("./v1/hubspotLifecycleStatus.json");
const  HubspotPersonaV1Schema = require("./v1/hubspotPersona.json");
const  HubspotWantToHelpUsV1Schema = require("./v1/hubspotWantToHelpUs.json");
const  InternalUserPermissionV1Schema = require("./v1/internalUserPermission.json");
const  JoinTheRevolutionOptionV1Schema = require("./v1/joinTheRevolutionOption.json");
const  LanguageV1Schema = require("./v1/language.json");
const  LocationZoneTypeV1Schema = require("./v1/locationZoneType.json");
const  LtlPackagingV1Schema = require("./v1/ltlPackaging.json");
const  MetadataTypeV1Schema = require("./v1/metadataType.json");
const  NotificationMessageTypeV1Schema = require("./v1/notificationMessageType.json");
const  NotificationMethodV1Schema = require("./v1/notificationMethod.json");
const  NotificationTypeV1Schema = require("./v1/notificationType.json");
const  PageAlertStatusV1Schema = require("./v1/pageAlertStatus.json");
const  PaymentMethodV1Schema = require("./v1/paymentMethod.json");
const  PaymentTermsStatusV1Schema = require("./v1/paymentTermsStatus.json");
const  PaymentTermsTypeV1Schema = require("./v1/paymentTermsType.json");
const  PaymentTransactionStatusV1Schema = require("./v1/paymentTransactionStatus.json");
const  PermissionV1Schema = require("./v1/permission.json");
const  PriceTypeV1Schema = require("./v1/priceType.json");
const  PropertyTypeV1Schema = require("./v1/propertyType.json");
const  QuoteCarrierRefusalReasonV1Schema = require("./v1/quoteCarrierRefusalReason.json");
const  QuoteShipperAcceptanceReasonV1Schema = require("./v1/quoteShipperAcceptanceReason.json");
const  QuoteShipperCancellationReasonV1Schema = require("./v1/quoteShipperCancellationReason.json");
const  QuoteShipperRefusalReasonV1Schema = require("./v1/quoteShipperRefusalReason.json");
const  RangeUnitOfMeasureV1Schema = require("./v1/rangeUnitOfMeasure.json");
const  RateSheetBoundaryTypeV1Schema = require("./v1/rateSheetBoundaryType.json");
const  RateSheetTypeV1Schema = require("./v1/rateSheetType.json");
const  RequiredOptionalV1Schema = require("./v1/requiredOptional.json");
const  RequiredTruckFeatureV1Schema = require("./v1/requiredTruckFeature.json");
const  SearchParamsV1Schema = require("./v1/searchParams.json");
const  SearchQuoteFormStepsV1Schema = require("./v1/searchQuoteFormSteps.json");
const  ServiceDirectionV1Schema = require("./v1/serviceDirection.json");
const  ShipmentAdditionalChargeTypeV1Schema = require("./v1/shipmentAdditionalChargeType.json");
const  ShipmentCarrierQuoteStatusV1Schema = require("./v1/shipmentCarrierQuoteStatus.json");
const  ShipmentChargeStatusV1Schema = require("./v1/shipmentChargeStatus.json");
const  ShipmentChargeTypeV1Schema = require("./v1/shipmentChargeType.json");
const  ShipmentDelayedStatusV1Schema = require("./v1/shipmentDelayedStatus.json");
const  ShipmentDisplayStatusV1Schema = require("./v1/shipmentDisplayStatus.json");
const  ShipmentDocumentTypeV1Schema = require("./v1/shipmentDocumentType.json");
const  ShipmentEquipmentIdentifierTypeV1Schema = require("./v1/shipmentEquipmentIdentifierType.json");
const  ShipmentFeeTypeEndpointsV1Schema = require("./v1/shipmentFeeTypeEndpoints.json");
const  ShipmentIdentifierTypeV1Schema = require("./v1/shipmentIdentifierType.json");
const  ShipmentLocationTypeV1Schema = require("./v1/shipmentLocationType.json");
const  ShipmentQuoteRequestStatusV1Schema = require("./v1/shipmentQuoteRequestStatus.json");
const  ShipmentShipperQuoteStatusV1Schema = require("./v1/shipmentShipperQuoteStatus.json");
const  ShipmentStatusV1Schema = require("./v1/shipmentStatus.json");
const  ShipmentStatusGroupV1Schema = require("./v1/shipmentStatusGroup.json");
const  ShipmentTrackerV1Schema = require("./v1/shipmentTracker.json");
const  ShipmentTrackingLocationArrivalStatusV1Schema = require("./v1/shipmentTrackingLocationArrivalStatus.json");
const  ShipmentTrackingLocationStatusV1Schema = require("./v1/shipmentTrackingLocationStatus.json");
const  ShipmentTrackingStatusV1Schema = require("./v1/shipmentTrackingStatus.json");
const  ShipmentTransactionNotificationActionV1Schema = require("./v1/shipmentTransactionNotificationAction.json");
const  ShipmentTruxwebAdditionalChargeTypeV1Schema = require("./v1/shipmentTruxwebAdditionalChargeType.json");
const  StandardInvoiceTransactionStatusV1Schema = require("./v1/standardInvoiceTransactionStatus.json");
const  StatusGenericV1Schema = require("./v1/statusGeneric.json");
const  SubscriptionV1Schema = require("./v1/subscription.json");
const  SubscriptionBillingFrequencyV1Schema = require("./v1/subscriptionBillingFrequency.json");
const  SubscriptionFeaturesV1Schema = require("./v1/subscriptionFeatures.json");
const  TmsPackagesV1Schema = require("./v1/tmsPackages.json");
const  TruckloadTypeV1Schema = require("./v1/truckloadType.json");
const  UniqueIdentifierTypeV1Schema = require("./v1/uniqueIdentifierType.json");
const  UserStatusV1Schema = require("./v1/userStatus.json");
const  UserTypeV1Schema = require("./v1/userType.json");
const  UserUpdateSubjectV1Schema = require("./v1/userUpdateSubject.json");
const  YesNoV1Schema = require("./v1/yesNo.json");
const  ZoneShapeTypeV1Schema = require("./v1/zoneShapeType.json");
module.exports = {
'TDbAccessorialV1':  DbAccessorialV1,
'TDbAccessorialInsertV1':  DbAccessorialInsertV1,
'TDbEquipmentV1':  DbEquipmentV1,
'TDbEquipmentInsertV1':  DbEquipmentInsertV1,
'TBankAccountProfileV2':  BankAccountProfileV2,
'TBankAccountTransactionV2':  BankAccountTransactionV2,
'TBankAccountTransactionHistoryV2':  BankAccountTransactionHistoryV2,
'TCreditCardProfileV2':  CreditCardProfileV2,
'TCreditCardTransactionV2':  CreditCardTransactionV2,
'TCreditCardTransactionHistoryV2':  CreditCardTransactionHistoryV2,
'TSESEmailTemplateV1':  SESEmailTemplateV1,
'TAccessorialV1':  AccessorialV1,
'TAccessorialListItemV1':  AccessorialListItemV1,
'TAccessorialMetadataEntryV1':  AccessorialMetadataEntryV1,
'TAdditionalShipmentChargeV1':  AdditionalShipmentChargeV1,
'TAddressV1':  AddressV1,
'TAdminShipmentV1':  AdminShipmentV1,
'TAlertV1':  AlertV1,
'TBatchNotificationQueueMessageV1':  BatchNotificationQueueMessageV1,
'TBookingLocationV1':  BookingLocationV1,
'TBookingSearchDocumentV1':  BookingSearchDocumentV1,
'TBookingSearchResultV1':  BookingSearchResultV1,
'TBookingSearchSummaryV1':  BookingSearchSummaryV1,
'TCarrierV1':  CarrierV1,
'TCarrierAccessorialV1':  CarrierAccessorialV1,
'TCarrierAccessorialMapV1':  CarrierAccessorialMapV1,
'TCarrierEquipmentV1':  CarrierEquipmentV1,
'TCarrierFuelSurchargeV1':  CarrierFuelSurchargeV1,
'TCarrierLaneV1':  CarrierLaneV1,
'TCarrierLaneEquipmentV1':  CarrierLaneEquipmentV1,
'TCarrierLaneLocationV1':  CarrierLaneLocationV1,
'TCarrierLocationV1':  CarrierLocationV1,
'TCarrierLocationZoneV1':  CarrierLocationZoneV1,
'TCarrierProfileDisplayV1':  CarrierProfileDisplayV1,
'TCarrierProfileRecordV1':  CarrierProfileRecordV1,
'TCarrierPublicProfileV1':  CarrierPublicProfileV1,
'TCarrierQuoteDataGridRowV1':  CarrierQuoteDataGridRowV1,
'TCarrierRegistrationV1':  CarrierRegistrationV1,
'TCarrierShipmentQuoteRequestV1':  CarrierShipmentQuoteRequestV1,
'TCarrierZoneV1':  CarrierZoneV1,
'TCarrierZoneCoordinateV1':  CarrierZoneCoordinateV1,
'TChargeV1':  ChargeV1,
'TChargeDescriptionV1':  ChargeDescriptionV1,
'TCompanyV1':  CompanyV1,
'TCompanyDocumentV1':  CompanyDocumentV1,
'TCompanyLocationV1':  CompanyLocationV1,
'TCompanyShipmentFeeV1':  CompanyShipmentFeeV1,
'TCompanySubscriptionV1':  CompanySubscriptionV1,
'TCostDetailV1':  CostDetailV1,
'TCspPermissionRecordV1':  CspPermissionRecordV1,
'TCspUserV1':  CspUserV1,
'TDoyonLoadDefinitionV1':  DoyonLoadDefinitionV1,
'TDoyonLtlCargoDefinitionV1':  DoyonLtlCargoDefinitionV1,
'TElasticSearchLocationV1':  ElasticSearchLocationV1,
'TElementHeightWidthV1':  ElementHeightWidthV1,
'TEntityMetadataV1':  EntityMetadataV1,
'TEquipmentV1':  EquipmentV1,
'TEquipmentOnProfileV1':  EquipmentOnProfileV1,
'TEquipmentPricingDataV1':  EquipmentPricingDataV1,
'TExportRequestV1':  ExportRequestV1,
'TFeeDetailV1':  FeeDetailV1,
'TFuelSurchargeV1':  FuelSurchargeV1,
'TGeolocationV1':  GeolocationV1,
'TGooglePlaceV1':  GooglePlaceV1,
'THubspotCompanyV1':  HubspotCompanyV1,
'THubspotContactV1':  HubspotContactV1,
'THubspotTicketV1':  HubspotTicketV1,
'TLatLngV1':  LatLngV1,
'TListItemAccessorialV1':  ListItemAccessorialV1,
'TLoadDefinitionV1':  LoadDefinitionV1,
'TLoadParametersV1':  LoadParametersV1,
'TLocationScheduleV1':  LocationScheduleV1,
'TLtlCargoDefinitionV1':  LtlCargoDefinitionV1,
'TLtlPackageDimensionsV1':  LtlPackageDimensionsV1,
'TPaymentMessageBodyV1':  PaymentMessageBodyV1,
'TPaymentTermsAdjustmentV1':  PaymentTermsAdjustmentV1,
'TPersistentNotificationRecipientV1':  PersistentNotificationRecipientV1,
'TPersistentNotificationRecipientDeliveryV1':  PersistentNotificationRecipientDeliveryV1,
'TPriceV1':  PriceV1,
'TQuoteCommentV1':  QuoteCommentV1,
'TQuoteCommentAttachmentV1':  QuoteCommentAttachmentV1,
'TQuoteSearchFormDataV1':  QuoteSearchFormDataV1,
'TRatePerDistanceV1':  RatePerDistanceV1,
'TRateSheetLocationV1':  RateSheetLocationV1,
'TRateSheetRegionV1':  RateSheetRegionV1,
'TRatingV1':  RatingV1,
'TServiceRangeV1':  ServiceRangeV1,
'TShipmentV1':  ShipmentV1,
'TShipmentAccessorialV1':  ShipmentAccessorialV1,
'TShipmentAccessorialMetadataV1':  ShipmentAccessorialMetadataV1,
'TShipmentAccessorialMetadataInputV1':  ShipmentAccessorialMetadataInputV1,
'TShipmentCommentV1':  ShipmentCommentV1,
'TShipmentCommentAttachmentV1':  ShipmentCommentAttachmentV1,
'TShipmentDetailsV1':  ShipmentDetailsV1,
'TShipmentDocumentV1':  ShipmentDocumentV1,
'TShipmentDocumentToUploadV1':  ShipmentDocumentToUploadV1,
'TShipmentEquipmentV1':  ShipmentEquipmentV1,
'TShipmentFeeTypeV1':  ShipmentFeeTypeV1,
'TShipmentLocationV1':  ShipmentLocationV1,
'TShipmentPaymentTermsV1':  ShipmentPaymentTermsV1,
'TShipmentPendingTransactionSettlementV1':  ShipmentPendingTransactionSettlementV1,
'TShipmentQuoteAccessorialV1':  ShipmentQuoteAccessorialV1,
'TShipmentQuoteEquipmentV1':  ShipmentQuoteEquipmentV1,
'TShipmentQuoteRequestV1':  ShipmentQuoteRequestV1,
'TShipmentQuoteRequestBookingDateV1':  ShipmentQuoteRequestBookingDateV1,
'TShipmentQuoteRequestChargeV1':  ShipmentQuoteRequestChargeV1,
'TShipmentQuoteRequestLocationV1':  ShipmentQuoteRequestLocationV1,
'TShipmentRequiredDocumentV1':  ShipmentRequiredDocumentV1,
'TShipmentRequiringAttentionV1':  ShipmentRequiringAttentionV1,
'TShipmentStatusLogEntryV1':  ShipmentStatusLogEntryV1,
'TShipmentSummaryItemV1':  ShipmentSummaryItemV1,
'TShipmentTrackingCarrierIdentiferV1':  ShipmentTrackingCarrierIdentiferV1,
'TShipmentTrackingEquipmentIdentiferV1':  ShipmentTrackingEquipmentIdentiferV1,
'TShipmentTrackingLocationV1':  ShipmentTrackingLocationV1,
'TShipmentTrackingShipmentIdentiferV1':  ShipmentTrackingShipmentIdentiferV1,
'TShipmentTrackingStatusUpdateV1':  ShipmentTrackingStatusUpdateV1,
'TShipmentTrackingTruckloadV1':  ShipmentTrackingTruckloadV1,
'TShipmentTransactionV1':  ShipmentTransactionV1,
'TShipmentTransactionUpdateV1':  ShipmentTransactionUpdateV1,
'TShipmentTypeV1':  ShipmentTypeV1,
'TShipperPaymentTermsV1':  ShipperPaymentTermsV1,
'TShipperQuoteDataGridRowV1':  ShipperQuoteDataGridRowV1,
'TShipperShipmentQuoteRequestV1':  ShipperShipmentQuoteRequestV1,
'TStandardInvoiceTransactionV1':  StandardInvoiceTransactionV1,
'TStandardInvoiceTransactionHistoryV1':  StandardInvoiceTransactionHistoryV1,
'TSubscriptionPlanV1':  SubscriptionPlanV1,
'TTableFilterV1':  TableFilterV1,
'TTaxCostDetailV1':  TaxCostDetailV1,
'TTruxwebMarginDetailsV1':  TruxwebMarginDetailsV1,
'TUserV1':  UserV1,
'TUserBillingProfileV1':  UserBillingProfileV1,
'TUserRegistrationV1':  UserRegistrationV1,
'TUserRegistrationFormV1':  UserRegistrationFormV1,
'TUserRoleV1':  UserRoleV1,
'TUserShipmentV1':  UserShipmentV1,
'TAddBankAcctRequestV1':  AddBankAcctRequestV1,
'TAddCardRequestV1':  AddCardRequestV1,
'TAddCarrierShipmentDocumentV1':  AddCarrierShipmentDocumentV1,
'TAddUserToCompanyV1':  AddUserToCompanyV1,
'TAdjustShipperTransactionV1':  AdjustShipperTransactionV1,
'TAreBankTransactionsWithProfileForShipmentRequestV1':  AreBankTransactionsWithProfileForShipmentRequestV1,
'TBankAcctPaymentRequestV1':  BankAcctPaymentRequestV1,
'TBookingRequestV1':  BookingRequestV1,
'TBookingSearchRequestV1':  BookingSearchRequestV1,
'TCalculatePriceRequestV1':  CalculatePriceRequestV1,
'TCompleteQuoteToShipmentV1':  CompleteQuoteToShipmentV1,
'TCompleteShipmentRequestV1':  CompleteShipmentRequestV1,
'TConfirmationRequestV1':  ConfirmationRequestV1,
'TContactFormRequestV1':  ContactFormRequestV1,
'TCreateEcommUserV1':  CreateEcommUserV1,
'TCreateShipmentQuoteRequestV1':  CreateShipmentQuoteRequestV1,
'TCreateShipperTicketForEmptySearchResultsRequestV1':  CreateShipperTicketForEmptySearchResultsRequestV1,
'TCreditCardPaymentByProfileRequestV1':  CreditCardPaymentByProfileRequestV1,
'TCreditCardPaymentByTokenRequestV1':  CreditCardPaymentByTokenRequestV1,
'TCreditCardPaymentRequestV1':  CreditCardPaymentRequestV1,
'TDoyonBookingSearchRequestV1':  DoyonBookingSearchRequestV1,
'TDoyonDespresFetchQuoteRequestV1':  DoyonDespresFetchQuoteRequestV1,
'TDoyonFetchSearchResultsRequestV1':  DoyonFetchSearchResultsRequestV1,
'TFetchSearchResultsRequestV1':  FetchSearchResultsRequestV1,
'TGeneratePdfRequestV1':  GeneratePdfRequestV1,
'TGetCompanyShipmentSummaryRequestV1':  GetCompanyShipmentSummaryRequestV1,
'TGetShipmentRefsForCompanyRequestV1':  GetShipmentRefsForCompanyRequestV1,
'TGetUserBankAccountsRequestV1':  GetUserBankAccountsRequestV1,
'TSaveUserRequestV1':  SaveUserRequestV1,
'TSendEmailNotificationRequestV1':  SendEmailNotificationRequestV1,
'TSendEmailRequestV1':  SendEmailRequestV1,
'TSendNotificationsRequestV1':  SendNotificationsRequestV1,
'TShipmentPaymentMethodUpdateV1':  ShipmentPaymentMethodUpdateV1,
'TShipperPaymentTermsRequestV1':  ShipperPaymentTermsRequestV1,
'TTransactionChangeRequestV1':  TransactionChangeRequestV1,
'TUpdateCarrierQuoteRequestV1':  UpdateCarrierQuoteRequestV1,
'TUpdateCompanyDetailsRequestV1':  UpdateCompanyDetailsRequestV1,
'TUpdateTransactionAmountAndSettlementDateV1':  UpdateTransactionAmountAndSettlementDateV1,
'TUpdateUserRequestV1':  UpdateUserRequestV1,
'TUserPaymentTermsRequestV1':  UserPaymentTermsRequestV1,
'TAddBankAccountRequestV2':  AddBankAccountRequestV2,
'TAddCardRequestV2':  AddCardRequestV2,
'TBankAccountHoldRequestV2':  BankAccountHoldRequestV2,
'TBankAccountPaymentRequestV2':  BankAccountPaymentRequestV2,
'TCreditCardPaymentByProfileRequestV2':  CreditCardPaymentByProfileRequestV2,
'TAreBankTransactionsWithProfileForShipmentResponseV1':  AreBankTransactionsWithProfileForShipmentResponseV1,
'TBankAcctPaymentTransactionResponseV1':  BankAcctPaymentTransactionResponseV1,
'TBankAcctResponseV1':  BankAcctResponseV1,
'TBookingSearchResponseV1':  BookingSearchResponseV1,
'TCalculatePriceResponseV1':  CalculatePriceResponseV1,
'TCreditCardPaymentTransactionResponseV1':  CreditCardPaymentTransactionResponseV1,
'TCreditCardResponseV1':  CreditCardResponseV1,
'TDoyonDespresFetchQuoteResponseV1':  DoyonDespresFetchQuoteResponseV1,
'TFetchCompanyDetailsResponseV1':  FetchCompanyDetailsResponseV1,
'TGenericErrorResponseV1':  GenericErrorResponseV1,
'TGetShipmentDetailsResponseV1':  GetShipmentDetailsResponseV1,
'TGetShipmentRefsForCompanyResponseV1':  GetShipmentRefsForCompanyResponseV1,
'TInAppNotificationV1':  InAppNotificationV1,
'TListCarrierRecordV1':  ListCarrierRecordV1,
'TListShipmentsResponseV1':  ListShipmentsResponseV1,
'TListShipmentsResponseWrapperV1':  ListShipmentsResponseWrapperV1,
'TListUserSummaryRecordV1':  ListUserSummaryRecordV1,
'TParseCarrierAvailabilityV1':  ParseCarrierAvailabilityV1,
'TProcessBankAccountPaymentResponseV1':  ProcessBankAccountPaymentResponseV1,
'TProcessCreditCardProfileResponseV1':  ProcessCreditCardProfileResponseV1,
'TQuoteStatusCountV1':  QuoteStatusCountV1,
'TSearchEquipmentV1':  SearchEquipmentV1,
'TShipmentSummaryResponseV1':  ShipmentSummaryResponseV1,
'TUserBillingProfilesResponseV1':  UserBillingProfilesResponseV1,
'TUserShipmentSummaryDataV1':  UserShipmentSummaryDataV1,
'TValidateUserPermissionsV1':  ValidateUserPermissionsV1,
'TValidationErrorResponseV1':  ValidationErrorResponseV1,
'TBankAccountPaymentTransactionResponseV2':  BankAccountPaymentTransactionResponseV2,
'TBankAccountResponseV2':  BankAccountResponseV2,
'TCreditCardResponseV2':  CreditCardResponseV2,
'TCarrierAccessorialPriceCalculationV1':  CarrierAccessorialPriceCalculationV1,
'TCarrierAccessorialPricingV1':  CarrierAccessorialPricingV1,
'TCarrierEquipmentPriceCalculationV1':  CarrierEquipmentPriceCalculationV1,
'TCarrierEquipmentPricingV1':  CarrierEquipmentPricingV1,
'TCommonMetadataV1':  CommonMetadataV1,
'TEmailV1':  EmailV1,
'TFirstNameV1':  FirstNameV1,
'TFullNameV1':  FullNameV1,
'TIdV1':  IdV1,
'TLastNameV1':  LastNameV1,
'TLatitudeV1':  LatitudeV1,
'TLongitudeV1':  LongitudeV1,
'TNotificationLanguageMessageV1':  NotificationLanguageMessageV1,
'TPercentDetailsV1':  PercentDetailsV1,
'TPersistentNotificationMessageDataV1':  PersistentNotificationMessageDataV1,
'TPersistentNotificationMessageDataDeliveryV1':  PersistentNotificationMessageDataDeliveryV1,
'TPersistentNotificationMessageDataRecipientV1':  PersistentNotificationMessageDataRecipientV1,
'TPhoneV1':  PhoneV1,
'TPriceAdjustmentV1':  PriceAdjustmentV1,
'TShipperPaymentTermsPropertiesV1':  ShipperPaymentTermsPropertiesV1,
'TSnsMessageDetailsV1':  SnsMessageDetailsV1,
'TTagsV1':  TagsV1,
'TTaxCalculationV1':  TaxCalculationV1,
'TTaxDetailV1':  TaxDetailV1,
'TTaxRateDetailV1':  TaxRateDetailV1,
'TGetShipperPaymentDateForShipmentV1':  GetShipperPaymentDateForShipmentV1,
'TSnsBodyV1':  SnsBodyV1,
'TSnsEventV1':  SnsEventV1,
'TSnsMsgHubspotManageCompanyV1':  SnsMsgHubspotManageCompanyV1,
'TSnsMsgHubspotManageContactV1':  SnsMsgHubspotManageContactV1,
'TSnsMsgHubspotManageInternalUserCreationV1':  SnsMsgHubspotManageInternalUserCreationV1,
'TSnsMsgHubspotPaymentTermsV1':  SnsMsgHubspotPaymentTermsV1,
'TSnsMsgHubspotUpdateV1':  SnsMsgHubspotUpdateV1,
'TSnsMsgUserUpdateV1':  SnsMsgUserUpdateV1,
'TSnsRecordV1':  SnsRecordV1,
'TUpdateShipmentStatusV1':  UpdateShipmentStatusV1,
'TAddAmountToCarrierTransactionByOrderRefRequestV2':  AddAmountToCarrierTransactionByOrderRefRequestV2,
'TAdditionalTruxwebShipperChargesRequestV2':  AdditionalTruxwebShipperChargesRequestV2,
'TPaymentMessageBodyV2':  PaymentMessageBodyV2,
'EAccessorialDurationOptionV1': AccessorialDurationOptionV1,
'EAccessorialOptionV1': AccessorialOptionV1,
'EAccessorialTimeOptionV1': AccessorialTimeOptionV1,
'EAllInFScV1': AllInFScV1,
'EAvsV1': AvsV1,
'EBankAcctReturnTypeV1': BankAcctReturnTypeV1,
'EBankAcctTransactionTypeV1': BankAcctTransactionTypeV1,
'EBankAcctTransferTypeV1': BankAcctTransferTypeV1,
'EBankAcctTypeV1': BankAcctTypeV1,
'EBillingTransactionMessageActionTypesV1': BillingTransactionMessageActionTypesV1,
'ECarrierFeatureV1': CarrierFeatureV1,
'ECarrierIdentifierTypeV1': CarrierIdentifierTypeV1,
'ECarrierServiceTypeV1': CarrierServiceTypeV1,
'ECarrierTruckTypeV1': CarrierTruckTypeV1,
'ECompanySizeV1': CompanySizeV1,
'EContactOptionsV1': ContactOptionsV1,
'ECountryV1': CountryV1,
'ECreditCardTransactionTypeV1': CreditCardTransactionTypeV1,
'ECreditCardTypeV1': CreditCardTypeV1,
'ECspUserRoleV1': CspUserRoleV1,
'ECspUserStatusV1': CspUserStatusV1,
'ECurrencyV1': CurrencyV1,
'EDangerousGoodsV1': DangerousGoodsV1,
'EDatabaseSpecialColumnTypeV1': DatabaseSpecialColumnTypeV1,
'EDayOfWeekV1': DayOfWeekV1,
'EDoyonDeliveryTypeV1': DoyonDeliveryTypeV1,
'EEcommCompanyRoleV1': EcommCompanyRoleV1,
'EEcommUserRoleV1': EcommUserRoleV1,
'EElasticSearchGeoShapeV1': ElasticSearchGeoShapeV1,
'EEldPackagesV1': EldPackagesV1,
'EEmailTemplatesV1': EmailTemplatesV1,
'EEquipmentCodeV1': EquipmentCodeV1,
'EExternalEmailAggregatorsV1': ExternalEmailAggregatorsV1,
'EFilterOperationV1': FilterOperationV1,
'EHubspotCaptureSourceV1': HubspotCaptureSourceV1,
'EHubspotCompanyTypeV1': HubspotCompanyTypeV1,
'EHubspotLeadStatusV1': HubspotLeadStatusV1,
'EHubspotLifecycleStatusV1': HubspotLifecycleStatusV1,
'EHubspotPersonaV1': HubspotPersonaV1,
'EHubspotWantToHelpUsV1': HubspotWantToHelpUsV1,
'EInternalUserPermissionV1': InternalUserPermissionV1,
'EJoinTheRevolutionOptionV1': JoinTheRevolutionOptionV1,
'ELanguageV1': LanguageV1,
'ELocationZoneTypeV1': LocationZoneTypeV1,
'ELtlPackagingV1': LtlPackagingV1,
'EMetadataTypeV1': MetadataTypeV1,
'ENotificationMessageTypeV1': NotificationMessageTypeV1,
'ENotificationMethodV1': NotificationMethodV1,
'ENotificationTypeV1': NotificationTypeV1,
'EPageAlertStatusV1': PageAlertStatusV1,
'EPaymentMethodV1': PaymentMethodV1,
'EPaymentTermsStatusV1': PaymentTermsStatusV1,
'EPaymentTermsTypeV1': PaymentTermsTypeV1,
'EPaymentTransactionStatusV1': PaymentTransactionStatusV1,
'EPermissionV1': PermissionV1,
'EPriceTypeV1': PriceTypeV1,
'EPropertyTypeV1': PropertyTypeV1,
'EQuoteCarrierRefusalReasonV1': QuoteCarrierRefusalReasonV1,
'EQuoteShipperAcceptanceReasonV1': QuoteShipperAcceptanceReasonV1,
'EQuoteShipperCancellationReasonV1': QuoteShipperCancellationReasonV1,
'EQuoteShipperRefusalReasonV1': QuoteShipperRefusalReasonV1,
'ERangeUnitOfMeasureV1': RangeUnitOfMeasureV1,
'ERateSheetBoundaryTypeV1': RateSheetBoundaryTypeV1,
'ERateSheetTypeV1': RateSheetTypeV1,
'ERequiredOptionalV1': RequiredOptionalV1,
'ERequiredTruckFeatureV1': RequiredTruckFeatureV1,
'ESearchParamsV1': SearchParamsV1,
'ESearchQuoteFormStepsV1': SearchQuoteFormStepsV1,
'EServiceDirectionV1': ServiceDirectionV1,
'EShipmentAdditionalChargeTypeV1': ShipmentAdditionalChargeTypeV1,
'EShipmentCarrierQuoteStatusV1': ShipmentCarrierQuoteStatusV1,
'EShipmentChargeStatusV1': ShipmentChargeStatusV1,
'EShipmentChargeTypeV1': ShipmentChargeTypeV1,
'EShipmentDelayedStatusV1': ShipmentDelayedStatusV1,
'EShipmentDisplayStatusV1': ShipmentDisplayStatusV1,
'EShipmentDocumentTypeV1': ShipmentDocumentTypeV1,
'EShipmentEquipmentIdentifierTypeV1': ShipmentEquipmentIdentifierTypeV1,
'EShipmentFeeTypeEndpointsV1': ShipmentFeeTypeEndpointsV1,
'EShipmentIdentifierTypeV1': ShipmentIdentifierTypeV1,
'EShipmentLocationTypeV1': ShipmentLocationTypeV1,
'EShipmentQuoteRequestStatusV1': ShipmentQuoteRequestStatusV1,
'EShipmentShipperQuoteStatusV1': ShipmentShipperQuoteStatusV1,
'EShipmentStatusV1': ShipmentStatusV1,
'EShipmentStatusGroupV1': ShipmentStatusGroupV1,
'EShipmentTrackerV1': ShipmentTrackerV1,
'EShipmentTrackingLocationArrivalStatusV1': ShipmentTrackingLocationArrivalStatusV1,
'EShipmentTrackingLocationStatusV1': ShipmentTrackingLocationStatusV1,
'EShipmentTrackingStatusV1': ShipmentTrackingStatusV1,
'EShipmentTransactionNotificationActionV1': ShipmentTransactionNotificationActionV1,
'EShipmentTruxwebAdditionalChargeTypeV1': ShipmentTruxwebAdditionalChargeTypeV1,
'EStandardInvoiceTransactionStatusV1': StandardInvoiceTransactionStatusV1,
'EStatusGenericV1': StatusGenericV1,
'ESubscriptionV1': SubscriptionV1,
'ESubscriptionBillingFrequencyV1': SubscriptionBillingFrequencyV1,
'ESubscriptionFeaturesV1': SubscriptionFeaturesV1,
'ETmsPackagesV1': TmsPackagesV1,
'ETruckloadTypeV1': TruckloadTypeV1,
'EUniqueIdentifierTypeV1': UniqueIdentifierTypeV1,
'EUserStatusV1': UserStatusV1,
'EUserTypeV1': UserTypeV1,
'EUserUpdateSubjectV1': UserUpdateSubjectV1,
'EYesNoV1': YesNoV1,
'EZoneShapeTypeV1': ZoneShapeTypeV1,
DbAccessorialV1Schema, 
DbAccessorialInsertV1Schema, 
DbEquipmentV1Schema, 
DbEquipmentInsertV1Schema, 
BankAccountProfileV2Schema, 
BankAccountTransactionV2Schema, 
BankAccountTransactionHistoryV2Schema, 
CreditCardProfileV2Schema, 
CreditCardTransactionV2Schema, 
CreditCardTransactionHistoryV2Schema, 
SESEmailTemplateV1Schema, 
AccessorialV1Schema, 
AccessorialListItemV1Schema, 
AccessorialMetadataEntryV1Schema, 
AdditionalShipmentChargeV1Schema, 
AddressV1Schema, 
AdminShipmentV1Schema, 
AlertV1Schema, 
BatchNotificationQueueMessageV1Schema, 
BookingLocationV1Schema, 
BookingSearchDocumentV1Schema, 
BookingSearchResultV1Schema, 
BookingSearchSummaryV1Schema, 
CarrierV1Schema, 
CarrierAccessorialV1Schema, 
CarrierAccessorialMapV1Schema, 
CarrierEquipmentV1Schema, 
CarrierFuelSurchargeV1Schema, 
CarrierLaneV1Schema, 
CarrierLaneEquipmentV1Schema, 
CarrierLaneLocationV1Schema, 
CarrierLocationV1Schema, 
CarrierLocationZoneV1Schema, 
CarrierProfileDisplayV1Schema, 
CarrierProfileRecordV1Schema, 
CarrierPublicProfileV1Schema, 
CarrierQuoteDataGridRowV1Schema, 
CarrierRegistrationV1Schema, 
CarrierShipmentQuoteRequestV1Schema, 
CarrierZoneV1Schema, 
CarrierZoneCoordinateV1Schema, 
ChargeV1Schema, 
ChargeDescriptionV1Schema, 
CompanyV1Schema, 
CompanyDocumentV1Schema, 
CompanyLocationV1Schema, 
CompanyShipmentFeeV1Schema, 
CompanySubscriptionV1Schema, 
CostDetailV1Schema, 
CspPermissionRecordV1Schema, 
CspUserV1Schema, 
DoyonLoadDefinitionV1Schema, 
DoyonLtlCargoDefinitionV1Schema, 
ElasticSearchLocationV1Schema, 
ElementHeightWidthV1Schema, 
EntityMetadataV1Schema, 
EquipmentV1Schema, 
EquipmentOnProfileV1Schema, 
EquipmentPricingDataV1Schema, 
ExportRequestV1Schema, 
FeeDetailV1Schema, 
FuelSurchargeV1Schema, 
GeolocationV1Schema, 
GooglePlaceV1Schema, 
HubspotCompanyV1Schema, 
HubspotContactV1Schema, 
HubspotTicketV1Schema, 
LatLngV1Schema, 
ListItemAccessorialV1Schema, 
LoadDefinitionV1Schema, 
LoadParametersV1Schema, 
LocationScheduleV1Schema, 
LtlCargoDefinitionV1Schema, 
LtlPackageDimensionsV1Schema, 
PaymentMessageBodyV1Schema, 
PaymentTermsAdjustmentV1Schema, 
PersistentNotificationRecipientV1Schema, 
PersistentNotificationRecipientDeliveryV1Schema, 
PriceV1Schema, 
QuoteCommentV1Schema, 
QuoteCommentAttachmentV1Schema, 
QuoteSearchFormDataV1Schema, 
RatePerDistanceV1Schema, 
RateSheetLocationV1Schema, 
RateSheetRegionV1Schema, 
RatingV1Schema, 
ServiceRangeV1Schema, 
ShipmentV1Schema, 
ShipmentAccessorialV1Schema, 
ShipmentAccessorialMetadataV1Schema, 
ShipmentAccessorialMetadataInputV1Schema, 
ShipmentCommentV1Schema, 
ShipmentCommentAttachmentV1Schema, 
ShipmentDetailsV1Schema, 
ShipmentDocumentV1Schema, 
ShipmentDocumentToUploadV1Schema, 
ShipmentEquipmentV1Schema, 
ShipmentFeeTypeV1Schema, 
ShipmentLocationV1Schema, 
ShipmentPaymentTermsV1Schema, 
ShipmentPendingTransactionSettlementV1Schema, 
ShipmentQuoteAccessorialV1Schema, 
ShipmentQuoteEquipmentV1Schema, 
ShipmentQuoteRequestV1Schema, 
ShipmentQuoteRequestBookingDateV1Schema, 
ShipmentQuoteRequestChargeV1Schema, 
ShipmentQuoteRequestLocationV1Schema, 
ShipmentRequiredDocumentV1Schema, 
ShipmentRequiringAttentionV1Schema, 
ShipmentStatusLogEntryV1Schema, 
ShipmentSummaryItemV1Schema, 
ShipmentTrackingCarrierIdentiferV1Schema, 
ShipmentTrackingEquipmentIdentiferV1Schema, 
ShipmentTrackingLocationV1Schema, 
ShipmentTrackingShipmentIdentiferV1Schema, 
ShipmentTrackingStatusUpdateV1Schema, 
ShipmentTrackingTruckloadV1Schema, 
ShipmentTransactionV1Schema, 
ShipmentTransactionUpdateV1Schema, 
ShipmentTypeV1Schema, 
ShipperPaymentTermsV1Schema, 
ShipperQuoteDataGridRowV1Schema, 
ShipperShipmentQuoteRequestV1Schema, 
StandardInvoiceTransactionV1Schema, 
StandardInvoiceTransactionHistoryV1Schema, 
SubscriptionPlanV1Schema, 
TableFilterV1Schema, 
TaxCostDetailV1Schema, 
TruxwebMarginDetailsV1Schema, 
UserV1Schema, 
UserBillingProfileV1Schema, 
UserRegistrationV1Schema, 
UserRegistrationFormV1Schema, 
UserRoleV1Schema, 
UserShipmentV1Schema, 
AddBankAcctRequestV1Schema, 
AddCardRequestV1Schema, 
AddCarrierShipmentDocumentV1Schema, 
AddUserToCompanyV1Schema, 
AdjustShipperTransactionV1Schema, 
AreBankTransactionsWithProfileForShipmentRequestV1Schema, 
BankAcctPaymentRequestV1Schema, 
BookingRequestV1Schema, 
BookingSearchRequestV1Schema, 
CalculatePriceRequestV1Schema, 
CompleteQuoteToShipmentV1Schema, 
CompleteShipmentRequestV1Schema, 
ConfirmationRequestV1Schema, 
ContactFormRequestV1Schema, 
CreateEcommUserV1Schema, 
CreateShipmentQuoteRequestV1Schema, 
CreateShipperTicketForEmptySearchResultsRequestV1Schema, 
CreditCardPaymentByProfileRequestV1Schema, 
CreditCardPaymentByTokenRequestV1Schema, 
CreditCardPaymentRequestV1Schema, 
DoyonBookingSearchRequestV1Schema, 
DoyonDespresFetchQuoteRequestV1Schema, 
DoyonFetchSearchResultsRequestV1Schema, 
FetchSearchResultsRequestV1Schema, 
GeneratePdfRequestV1Schema, 
GetCompanyShipmentSummaryRequestV1Schema, 
GetShipmentRefsForCompanyRequestV1Schema, 
GetUserBankAccountsRequestV1Schema, 
SaveUserRequestV1Schema, 
SendEmailNotificationRequestV1Schema, 
SendEmailRequestV1Schema, 
SendNotificationsRequestV1Schema, 
ShipmentPaymentMethodUpdateV1Schema, 
ShipperPaymentTermsRequestV1Schema, 
TransactionChangeRequestV1Schema, 
UpdateCarrierQuoteRequestV1Schema, 
UpdateCompanyDetailsRequestV1Schema, 
UpdateTransactionAmountAndSettlementDateV1Schema, 
UpdateUserRequestV1Schema, 
UserPaymentTermsRequestV1Schema, 
AddBankAccountRequestV2Schema, 
AddCardRequestV2Schema, 
BankAccountHoldRequestV2Schema, 
BankAccountPaymentRequestV2Schema, 
CreditCardPaymentByProfileRequestV2Schema, 
AreBankTransactionsWithProfileForShipmentResponseV1Schema, 
BankAcctPaymentTransactionResponseV1Schema, 
BankAcctResponseV1Schema, 
BookingSearchResponseV1Schema, 
CalculatePriceResponseV1Schema, 
CreditCardPaymentTransactionResponseV1Schema, 
CreditCardResponseV1Schema, 
DoyonDespresFetchQuoteResponseV1Schema, 
FetchCompanyDetailsResponseV1Schema, 
GenericErrorResponseV1Schema, 
GetShipmentDetailsResponseV1Schema, 
GetShipmentRefsForCompanyResponseV1Schema, 
InAppNotificationV1Schema, 
ListCarrierRecordV1Schema, 
ListShipmentsResponseV1Schema, 
ListShipmentsResponseWrapperV1Schema, 
ListUserSummaryRecordV1Schema, 
ParseCarrierAvailabilityV1Schema, 
ProcessBankAccountPaymentResponseV1Schema, 
ProcessCreditCardProfileResponseV1Schema, 
QuoteStatusCountV1Schema, 
SearchEquipmentV1Schema, 
ShipmentSummaryResponseV1Schema, 
UserBillingProfilesResponseV1Schema, 
UserShipmentSummaryDataV1Schema, 
ValidateUserPermissionsV1Schema, 
ValidationErrorResponseV1Schema, 
BankAccountPaymentTransactionResponseV2Schema, 
BankAccountResponseV2Schema, 
CreditCardResponseV2Schema, 
CarrierAccessorialPriceCalculationV1Schema, 
CarrierAccessorialPricingV1Schema, 
CarrierEquipmentPriceCalculationV1Schema, 
CarrierEquipmentPricingV1Schema, 
CommonMetadataV1Schema, 
EmailV1Schema, 
FirstNameV1Schema, 
FullNameV1Schema, 
IdV1Schema, 
LastNameV1Schema, 
LatitudeV1Schema, 
LongitudeV1Schema, 
NotificationLanguageMessageV1Schema, 
PercentDetailsV1Schema, 
PersistentNotificationMessageDataV1Schema, 
PersistentNotificationMessageDataDeliveryV1Schema, 
PersistentNotificationMessageDataRecipientV1Schema, 
PhoneV1Schema, 
PriceAdjustmentV1Schema, 
ShipperPaymentTermsPropertiesV1Schema, 
SnsMessageDetailsV1Schema, 
TagsV1Schema, 
TaxCalculationV1Schema, 
TaxDetailV1Schema, 
TaxRateDetailV1Schema, 
GetShipperPaymentDateForShipmentV1Schema, 
SnsBodyV1Schema, 
SnsEventV1Schema, 
SnsMsgHubspotManageCompanyV1Schema, 
SnsMsgHubspotManageContactV1Schema, 
SnsMsgHubspotManageInternalUserCreationV1Schema, 
SnsMsgHubspotPaymentTermsV1Schema, 
SnsMsgHubspotUpdateV1Schema, 
SnsMsgUserUpdateV1Schema, 
SnsRecordV1Schema, 
UpdateShipmentStatusV1Schema, 
AddAmountToCarrierTransactionByOrderRefRequestV2Schema, 
AdditionalTruxwebShipperChargesRequestV2Schema, 
PaymentMessageBodyV2Schema, 
AccessorialDurationOptionV1Schema, 
AccessorialOptionV1Schema, 
AccessorialTimeOptionV1Schema, 
AllInFScV1Schema, 
AvsV1Schema, 
BankAcctReturnTypeV1Schema, 
BankAcctTransactionTypeV1Schema, 
BankAcctTransferTypeV1Schema, 
BankAcctTypeV1Schema, 
BillingTransactionMessageActionTypesV1Schema, 
CarrierFeatureV1Schema, 
CarrierIdentifierTypeV1Schema, 
CarrierServiceTypeV1Schema, 
CarrierTruckTypeV1Schema, 
CompanySizeV1Schema, 
ContactOptionsV1Schema, 
CountryV1Schema, 
CreditCardTransactionTypeV1Schema, 
CreditCardTypeV1Schema, 
CspUserRoleV1Schema, 
CspUserStatusV1Schema, 
CurrencyV1Schema, 
DangerousGoodsV1Schema, 
DatabaseSpecialColumnTypeV1Schema, 
DayOfWeekV1Schema, 
DoyonDeliveryTypeV1Schema, 
EcommCompanyRoleV1Schema, 
EcommUserRoleV1Schema, 
ElasticSearchGeoShapeV1Schema, 
EldPackagesV1Schema, 
EmailTemplatesV1Schema, 
EquipmentCodeV1Schema, 
ExternalEmailAggregatorsV1Schema, 
FilterOperationV1Schema, 
HubspotCaptureSourceV1Schema, 
HubspotCompanyTypeV1Schema, 
HubspotLeadStatusV1Schema, 
HubspotLifecycleStatusV1Schema, 
HubspotPersonaV1Schema, 
HubspotWantToHelpUsV1Schema, 
InternalUserPermissionV1Schema, 
JoinTheRevolutionOptionV1Schema, 
LanguageV1Schema, 
LocationZoneTypeV1Schema, 
LtlPackagingV1Schema, 
MetadataTypeV1Schema, 
NotificationMessageTypeV1Schema, 
NotificationMethodV1Schema, 
NotificationTypeV1Schema, 
PageAlertStatusV1Schema, 
PaymentMethodV1Schema, 
PaymentTermsStatusV1Schema, 
PaymentTermsTypeV1Schema, 
PaymentTransactionStatusV1Schema, 
PermissionV1Schema, 
PriceTypeV1Schema, 
PropertyTypeV1Schema, 
QuoteCarrierRefusalReasonV1Schema, 
QuoteShipperAcceptanceReasonV1Schema, 
QuoteShipperCancellationReasonV1Schema, 
QuoteShipperRefusalReasonV1Schema, 
RangeUnitOfMeasureV1Schema, 
RateSheetBoundaryTypeV1Schema, 
RateSheetTypeV1Schema, 
RequiredOptionalV1Schema, 
RequiredTruckFeatureV1Schema, 
SearchParamsV1Schema, 
SearchQuoteFormStepsV1Schema, 
ServiceDirectionV1Schema, 
ShipmentAdditionalChargeTypeV1Schema, 
ShipmentCarrierQuoteStatusV1Schema, 
ShipmentChargeStatusV1Schema, 
ShipmentChargeTypeV1Schema, 
ShipmentDelayedStatusV1Schema, 
ShipmentDisplayStatusV1Schema, 
ShipmentDocumentTypeV1Schema, 
ShipmentEquipmentIdentifierTypeV1Schema, 
ShipmentFeeTypeEndpointsV1Schema, 
ShipmentIdentifierTypeV1Schema, 
ShipmentLocationTypeV1Schema, 
ShipmentQuoteRequestStatusV1Schema, 
ShipmentShipperQuoteStatusV1Schema, 
ShipmentStatusV1Schema, 
ShipmentStatusGroupV1Schema, 
ShipmentTrackerV1Schema, 
ShipmentTrackingLocationArrivalStatusV1Schema, 
ShipmentTrackingLocationStatusV1Schema, 
ShipmentTrackingStatusV1Schema, 
ShipmentTransactionNotificationActionV1Schema, 
ShipmentTruxwebAdditionalChargeTypeV1Schema, 
StandardInvoiceTransactionStatusV1Schema, 
StatusGenericV1Schema, 
SubscriptionV1Schema, 
SubscriptionBillingFrequencyV1Schema, 
SubscriptionFeaturesV1Schema, 
TmsPackagesV1Schema, 
TruckloadTypeV1Schema, 
UniqueIdentifierTypeV1Schema, 
UserStatusV1Schema, 
UserTypeV1Schema, 
UserUpdateSubjectV1Schema, 
YesNoV1Schema, 
ZoneShapeTypeV1Schema, 
}