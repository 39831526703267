"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        background: `#f3f3f3`,
        borderRadius: theme.spacing(2),
        minWidth: theme.spacing(56),
        padding: theme.spacing(4),
    },
    editButton: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
    },
}));
