"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCupcakeIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.679,11.408a12.057,12.057,0,0,0-8.968-7.144,2.994,2.994,0,1,0-5.422,0A12.058,12.058,0,0,0,.324,11.408a4.04,4.04,0,0,0,.353,3.811,3.989,3.989,0,0,0,1.4,1.284l.6,3.372A4.993,4.993,0,0,0,7.6,24H16.4a4.991,4.991,0,0,0,4.922-4.125l.6-3.371a3.975,3.975,0,0,0,1.4-1.284A4.043,4.043,0,0,0,23.679,11.408ZM12,2a1,1,0,0,1,0,2A1,1,0,0,1,12,2Zm7.357,17.525A3,3,0,0,1,16.4,22H7.6a3,3,0,0,1-2.954-2.475l-.371-2.08A3,3,0,0,0,8,17.22a2.99,2.99,0,0,0,4,0,2.99,2.99,0,0,0,4,0,3,3,0,0,0,3.728.225Zm2.3-5.413A1.956,1.956,0,0,1,20,15a1.645,1.645,0,0,0-1.22.625,1.01,1.01,0,0,1-1.56,0,1.529,1.529,0,0,0-2.44,0,1.01,1.01,0,0,1-1.56,0,1.529,1.529,0,0,0-2.44,0,1.01,1.01,0,0,1-1.56,0,1.529,1.529,0,0,0-2.44,0,1.01,1.01,0,0,1-1.56,0A1.643,1.643,0,0,0,4,15a1.955,1.955,0,0,1-1.656-.888,2.016,2.016,0,0,1-.177-1.928c3.267-8.2,16.4-8.2,19.668,0A2.016,2.016,0,0,1,21.658,14.112Z" })));
exports.default = TruxwebCupcakeIcon;
