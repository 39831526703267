import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  comingSoonDescriptionText: {
    fontSize: '20px',
  },
  container: {
    background:
      'radial-gradient(100% 248.19% at 0% 3.61%, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.86) 100%)',
    borderRadius: theme.spacing(4),
    height: 609,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    width: 960,
  },
  pageBlur: {
    alignItems: 'center',
    backdropFilter: `blur(12px)`,
    background:
      'radial-gradient(100% 248.19% at 0% 3.61%, rgba(34, 72, 130, 0.42) 0%, rgba(182, 205, 241, 0.06) 100%)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1302,
  },
}));
