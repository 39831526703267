import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    width: '100%',
    // [theme.breakpoints.down('xs')]: {
    //   width: theme.spacing(73),
    // },
    // [theme.breakpoints.up('sm')]: {
    //   width: '100%',
    // },
    // [theme.breakpoints.up('md')]: {
    //   width: theme.spacing(80),
    // },
    // [theme.breakpoints.up('lg')]: {
    //   marginLeft: theme.spacing(4.5),
    //   width: theme.spacing(124),
    // },
    // [theme.breakpoints.up('xs')]: {
    //   width: theme.spacing(124),
    // },
  },
  smallHeader: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },
  title: {
    fontWeight: 600,
  },
}));
