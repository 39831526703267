import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  incomplete: {
    background: `#EDF8FF`,
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(2),
  },
}));

export const useDateStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
  },
}));
