import { makeRequestToApiGateway } from '../../../apiUtils';

export const activateBankAccountByIdV2 = async (accountId: number): Promise<void> => {
  await makeRequestToApiGateway(
    'billingPatchV2BankacctByIdActivate',
    { id: accountId },
    {
      'Content-Type': 'application/json',
    },
    { accountId }
  );
};
