"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebAmbulanceIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.052,11.654,21.233,7.563A6,6,0,0,0,15.75,4H14.4l-.66-2.642a1.794,1.794,0,0,0-3.48,0L9.6,4H5A5.006,5.006,0,0,0,0,9v8a2.994,2.994,0,0,0,2.071,2.838A3.4,3.4,0,0,0,2,20.5a3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5h6.082a3.465,3.465,0,0,0-.041.5,3.5,3.5,0,0,0,7,0,3.4,3.4,0,0,0-.071-.662A2.994,2.994,0,0,0,24,17v-.878A10.93,10.93,0,0,0,23.052,11.654ZM19.406,8.376,20.573,11H17a1,1,0,0,1-1-1V6.018A4,4,0,0,1,19.406,8.376ZM7,20.5a1.5,1.5,0,0,1-3,0,1.418,1.418,0,0,1,.093-.5H6.907A1.418,1.418,0,0,1,7,20.5ZM18.5,22A1.5,1.5,0,0,1,17,20.5a1.418,1.418,0,0,1,.093-.5h2.814a1.418,1.418,0,0,1,.093.5A1.5,1.5,0,0,1,18.5,22ZM22,17a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V9A3,3,0,0,1,5,6h9v4a3,3,0,0,0,3,3h4.429A8.916,8.916,0,0,1,22,16.122ZM11,12a1,1,0,0,1-1,1H9v1a1,1,0,0,1-2,0V13H6a1,1,0,0,1,0-2H7V10a1,1,0,0,1,2,0v1h1A1,1,0,0,1,11,12Z" })));
exports.default = TruxwebAmbulanceIcon;
