"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBaconIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.088,4.751,19.153.816h0A2.764,2.764,0,0,0,14.472,2.3a2.54,2.54,0,0,1-2.186,2.187A3.286,3.286,0,0,0,9.479,7.292,2.542,2.542,0,0,1,7.292,9.479a3.286,3.286,0,0,0-2.806,2.807,2.509,2.509,0,0,1-.721,1.465,2.546,2.546,0,0,1-1.51.727A2.581,2.581,0,0,0,.147,16.263a2.805,2.805,0,0,0,.668,2.889L4.761,23.1c1.334,1.366,3.25,1.035,4.651-.033a5.619,5.619,0,0,0,2.424-1.243A4.488,4.488,0,0,0,13.127,19.2,1.277,1.277,0,0,1,14.2,18.12a4.546,4.546,0,0,0,3.916-3.915A1.278,1.278,0,0,1,19.2,13.127a4.578,4.578,0,0,0,3.846-3.642,2.945,2.945,0,0,1,.251-.5A3.173,3.173,0,0,0,23.088,4.751ZM2.044,16.9a.614.614,0,0,1,.509-.44,4.5,4.5,0,0,0,2.627-1.29A4.491,4.491,0,0,0,6.469,12.54a1.279,1.279,0,0,1,1.077-1.078,4.542,4.542,0,0,0,3.916-3.916A1.279,1.279,0,0,1,12.54,6.469a4.543,4.543,0,0,0,3.918-3.931.75.75,0,0,1,1.282-.307l1.291,1.291c-1.356.881-.918,2.546-1.938,3.585a2.511,2.511,0,0,1-1.466.72,3.286,3.286,0,0,0-2.806,2.807,2.533,2.533,0,0,1-2.188,2.186,3.289,3.289,0,0,0-2.806,2.807,2.506,2.506,0,0,1-.721,1.466C6.071,18.11,4.4,17.68,3.522,19.031L2.23,17.739A.822.822,0,0,1,2.044,16.9ZM21.717,7.759a6.63,6.63,0,0,0-.585,1.14,2.573,2.573,0,0,1-2.189,2.245,3.289,3.289,0,0,0-2.807,2.806,2.539,2.539,0,0,1-2.186,2.187,3.284,3.284,0,0,0-2.806,2.806,2.516,2.516,0,0,1-.722,1.466c-.661.638-1.9.643-2.643,1.31-1.185.936-2.066-.586-2.824-1.255A1.28,1.28,0,0,1,5.9,19.8a4.544,4.544,0,0,0,3.916-3.915A1.278,1.278,0,0,1,10.888,14.8a4.533,4.533,0,0,0,3.917-3.916,1.276,1.276,0,0,1,1.076-1.077A4.547,4.547,0,0,0,19.8,5.9a1.275,1.275,0,0,1,.666-.94C21.128,5.708,22.64,6.587,21.717,7.759Z" })));
exports.default = TruxwebBaconIcon;
