"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFileEpsIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M148.523,124.117c7.364,0,13.333-5.97,13.333-13.333s-5.97-13.333-13.333-13.333h-46.933  c-7.364,0-13.333,5.97-13.333,13.333v111.232c0,7.364,5.97,13.333,13.333,13.333h46.933c7.364,0,13.333-5.97,13.333-13.333  c0-7.364-5.97-13.333-13.333-13.333h-33.515v-28.949h30.059c7.364,0,13.333-5.97,13.333-13.333c0-7.364-5.97-13.333-13.333-13.333  h-30.059v-28.949H148.523z" }),
    React.createElement("path", { d: "M188.821,110.272v112.256c0,7.364,5.97,13.333,13.333,13.333c7.364,0,13.333-5.97,13.333-13.333v-35.477h17.899  c24.86,0,45.013-20.153,45.013-45.013s-20.153-45.013-45.013-45.013l-0.021-0.085h-31.232c-0.775-0.021-6.198-0.076-10.095,4.32  C188.757,104.96,188.79,109.298,188.821,110.272z M233.365,160.299h-17.749V123.52h17.856c10.133,0,18.347,8.214,18.347,18.347  c0,10.133-8.214,18.347-18.347,18.347L233.365,160.299z" }),
    React.createElement("path", { d: "M405.333,0H106.667C47.786,0.071,0.071,47.786,0,106.667v298.667C0.071,464.214,47.786,511.93,106.667,512h241.984  c28.307,0.081,55.47-11.165,75.435-31.232l56.661-56.704c20.069-19.956,31.323-47.111,31.253-75.413V106.667  C511.93,47.786,464.214,0.071,405.333,0z M42.667,405.333V106.667c0-35.346,28.654-64,64-64h298.667c35.346,0,64,28.654,64,64V320  H384c-35.346,0-64,28.654-64,64v85.333H106.667C71.32,469.333,42.667,440.68,42.667,405.333z M393.92,450.603  c-8.576,8.555-19.42,14.477-31.253,17.067V384c0-11.782,9.551-21.333,21.333-21.333h83.733  c-2.639,11.808-8.554,22.633-17.067,31.232L393.92,450.603z" }),
    React.createElement("path", { d: "M359.616,154.773c-5.824-1.984-11.861-3.072-17.813-4.629c-4.723-0.953-9.101-3.164-12.672-6.4  c-3.566-3.382-4.833-8.533-3.243-13.184c2.023-4.008,5.998-6.663,10.475-6.997c4.401-0.274,8.812,0.373,12.949,1.899  c5.948,1.854,11.782,4.055,17.472,6.592c6.293,2.752,13.675,4.395,18.752-1.579c3.73-4.494,4.009-10.922,0.683-15.723  c-2.212-3.213-5.198-5.818-8.683-7.573c-15.617-9.169-34.252-11.712-51.755-7.061c-9.245,2.926-17.056,9.214-21.888,17.621  c-2.266,3.988-3.764,8.366-4.416,12.907c-1.19,8.167,0.581,16.491,4.992,23.467c4.978,7.288,12.164,12.784,20.501,15.68  c8.725,3.584,17.429,7.061,26.283,10.304c3.812,1.199,7.319,3.207,10.283,5.888c3.342,3.166,4.584,7.962,3.2,12.352  c-1.799,4.203-5.37,7.391-9.749,8.704c-6.66,2.504-13.956,2.766-20.779,0.747c-6.64-2.403-12.774-6.02-18.091-10.667  c-0.555-0.448-2.368-1.813-2.709-2.133c-4.971-3.554-11.762-3.099-16.213,1.088c-5.455,4.947-5.867,13.379-0.921,18.834  c0.001,0.001,0.002,0.002,0.003,0.004c12.728,13.515,30.503,21.127,49.067,21.013c22.001,0.967,41.551-13.924,46.464-35.392  c2.071-12.991-2.781-26.121-12.8-34.645C373.343,160.936,366.752,157.158,359.616,154.773z" })));
exports.default = TruxwebFileEpsIcon;
