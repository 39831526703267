/* eslint-disable complexity */
/* eslint-disable max-lines */
import {
  Backdrop,
  Box,
  CheckCircleOutlineIcon,
  ChevronRightIcon,
  Grid,
  KeyboardArrowDownIcon,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@truxweb/ux';
import {
  EHubspotCaptureSourceV1,
  EJoinTheRevolutionOptionV1,
  ELanguageV1,
  EUserTypeV1,
} from '@truxweb/schemas';
import { EnumRadioGroup, FormField } from '@truxweb/common-components';
import { LandingPageButton, LandingPageMobileSwipeableDrawer } from '..';
import React, { useCallback, useState } from 'react';
import Boxes from '../../public/assets/images/landingPage/landingpage-boxesblue.svg';
import { getRouteProperties } from '../../utils';
import { IS_REGISTRATION_ENABLED } from '../../config';
import { submitContactForm } from '../../actions';
import Truck from '../../public/assets/images/landingPage/landingpage-truckparcels.svg';
import TruxiSmiling from '../../public/assets/images/truxii/truxii-smiling-large.svg';
import TruxiStars from '../../public/assets/images/truxii/truxii-stars-large.svg';
import { useAlerts } from '../../hooks';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useStyles } from './JoinTheRevolutionModal.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TJoinTheRevolutionModalProps = {
  email?: string;
  isOpen: boolean;
  setModalEmail: (arg: string | null) => void;
  setOpen: (isOpen: boolean) => void;
};

export const JoinTheRevolutionModal = ({
  email,
  isOpen,
  setModalEmail,
  setOpen,
}: TJoinTheRevolutionModalProps): JSX.Element => {
  const { addAlert } = useAlerts();
  const classes = useStyles({ isOpen });
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [userType, setUserType] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState(null);
  const { control, handleSubmit } = useForm();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const router = useRouter();
  const { locale } = router;

  const onSubmitSuccess = useCallback(
    async (data) => {
      try {
        setIsSending(true);
        data.source = EHubspotCaptureSourceV1.landing_page_main_capture;
        data.language = locale === 'en-CA' ? ELanguageV1.EN_CA : ELanguageV1.FR_CA;
        const { ...others } = data;
        const submission = {
          contact: others,
          skipUpdate: true,
          submission: {
            subject: `${t('common:contactFormSubmission')} ${data.email}`,
          },
        };
        await submitContactForm(submission);
        setFormData(data);
        setSubmitSuccess(true);
      } catch (err) {
        addAlert(t('contactUs:contactFormFail'));
        setSubmitSuccess(false);
      } finally {
        setIsSending(false);
      }
    },
    [setSubmitSuccess, locale, t, addAlert]
  );

  const handleClose = () => {
    setOpen(false);
    setUserType(null);
    setModalEmail(null);
    setSubmitSuccess(false);
  };

  const handleClick = (userType: EUserTypeV1) => {
    if (IS_REGISTRATION_ENABLED) {
      const { route } = getRouteProperties(
        locale,
        `signup/${userType.toLowerCase() as 'shipper' | 'carrier'}`
      );

      router.push(route);
    } else {
      setUserType(userType);
    }
  };

  const link = (
    <a
      className={classes.link}
      href="https://meetings.hubspot.com/max-gravel/demo-general-calendar"
    >
      {t('common:bookADemo')}
    </a>
  );

  const formFieldName = (
    <FormField
      InputProps={{
        className: classes.formField,
      }}
      control={control}
      defaultValue={''}
      id="name"
      isRequired={true}
      label={t('common:fullName')}
      labelClassName={classes.label}
      t={t}
    />
  );

  const formFieldEmail = (
    <FormField
      InputProps={{
        className: classes.formField,
      }}
      control={control}
      defaultValue={email ? email : ''}
      id={'email'}
      isRequired={true}
      label={t('common:email')}
      labelClassName={classes.label}
      t={t}
    />
  );

  const formFieldRadio = (
    <FormField
      areOnlyChildrenShown={true}
      control={control}
      defaultValue={EJoinTheRevolutionOptionV1.yesIWantTo}
      id={'join-beta'}
      isRequired
      label={''}
      t={t}
    >
      {(onChange, value) => {
        return (
          <>
            <EnumRadioGroup
              direction="row"
              onChange={(value: EJoinTheRevolutionOptionV1) => {
                onChange(value);
              }}
              sourceEnum={EJoinTheRevolutionOptionV1}
              t={t}
              value={value}
            />
          </>
        );
      }}
    </FormField>
  );

  const shipperCarrierSelector = (userType: EUserTypeV1) => {
    const image =
      userType === EUserTypeV1.CARRIER ? (
        <Truck height={'100%'} width={'100%'} />
      ) : (
        <Boxes height={'100%'} width={'100%'} />
      );
    return (
      <Box
        className={classes[`bg${userType}`]}
        onClick={() => {
          handleClick(userType);
        }}
      >
        <Box className={classes.click} />
        <Box className={classes[`overlay${userType}`]} />
        <Box className={classes[`overlayColor${userType}`]} />
        <Box className={classes.image}>{image}</Box>
        <Box className={classes.container} mr={4} p={2}>
          <Grid
            alignItems="flex-end"
            className={classes.container}
            container
            direction="column"
            justifyContent="center"
          >
            <Typography className={classes.white} variant="h5">
              {t('common:imA')}
            </Typography>
            <Typography className={classes.whiteUserType} variant="h2">
              {t(`common:${userType.toLocaleLowerCase()}`)}
            </Typography>
          </Grid>
        </Box>
      </Box>
    );
  };

  const tabletContent = (
    <>
      <Grid className={classes.container} container>
        {!userType && (
          <>
            <Grid
              alignItems="center"
              className={classes.leftColumnWrapper}
              container
              direction="column"
              item
              justifyContent="space-evenly"
            >
              <Box className={classes.truxiWrapper}>
                {!submitSuccess && <TruxiSmiling height={128} width={113} />}
              </Box>
              <Typography align="center" className={classes.bold} variant="h1">
                {t('common:joinTheRevolution')}
              </Typography>
              <Typography align="center" className={classes.launchingSoon} variant="body2">
                {t('common:signUpToday')}
              </Typography>
              <Grid alignItems="center" container direction="column" item justifyContent="center">
                <Typography className={classes.bold} variant="body2">
                  {t('common:selectYourProfile')}
                </Typography>
                <KeyboardArrowDownIcon fontSize="small" />
              </Grid>
            </Grid>
            <Box className={classes.carrierMobile} onClick={() => handleClick(EUserTypeV1.CARRIER)}>
              <Typography className={classes.whiteUserType} variant="h5">
                {t('common:imA')}
              </Typography>
              <Typography className={classes.whiteUserType} variant="h3">
                {t('common:carrier')}
              </Typography>
            </Box>
            <Box className={classes.shipperMobile} onClick={() => handleClick(EUserTypeV1.SHIPPER)}>
              <Typography className={classes.whiteUserType} variant="h5">
                {t('common:imA')}
              </Typography>
              <Typography className={classes.whiteUserType} variant="h3">
                {t('common:shipper')}
              </Typography>
            </Box>
          </>
        )}
        {userType && !submitSuccess && (
          <>
            <Box className={classes.rightColumnbg} p={4}>
              <Grid alignItems="center" container justifyContent="flex-end">
                <>
                  <Box mr={1}>
                    <Typography className={classes.whiteUserType} variant="h3">
                      {t(`common:${userType.toLocaleLowerCase()}`)}
                    </Typography>
                  </Box>
                  <CheckCircleOutlineIcon className={classes.icon} />
                </>
              </Grid>
              <Box mt={3}>
                <form onSubmit={handleSubmit(onSubmitSuccess)}>
                  <Grid container direction="column" justifyContent="space-between">
                    <Grid container item justifyContent="space-between" spacing={2}>
                      <Grid item xs={6}>
                        {formFieldName}
                      </Grid>
                      <Grid item xs={6}>
                        {formFieldEmail}
                      </Grid>
                    </Grid>
                    <Box mb={6} mt={3}>
                      <Typography className={classes.whiteUserType} variant="h4">
                        {t('common:iWantToJoinThe')}{' '}
                        <span className={classes.accent}> {t('common:betaVersion')}</span>{' '}
                        {t('common:now')}.
                      </Typography>
                      <Box mt={1}>{formFieldRadio}</Box>
                    </Box>
                    <Grid container justifyContent="space-between">
                      <LandingPageButton
                        isSubmit
                        onClick={() => {
                          setUserType(null);
                        }}
                        text={t('common:back')}
                        type="mediumOutlineWhite"
                      />
                      <LandingPageButton
                        isSaving={isSending}
                        text={t('common:submit')}
                        type="mediumStandard"
                      />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </>
        )}
        {userType && submitSuccess && (
          <>
            <Box className={classes.rightColumnbg} p={3}>
              <Grid alignItems="center" container justifyContent="flex-end">
                <>
                  <Box mr={1}>
                    <Typography className={classes.plainWhite} variant="h5">
                      {t(`common:${userType.toLocaleLowerCase()}`)}
                    </Typography>
                  </Box>
                  <CheckCircleOutlineIcon className={classes.icon} />
                </>
              </Grid>
              <Grid container direction="column">
                <Grid
                  alignItems="center"
                  container
                  direction="column"
                  justifyContent="space-evenly"
                >
                  <Box mt={1}>
                    <TruxiStars height={82} width={72} />
                  </Box>
                  <Box mb={2} mt={1}>
                    <Typography align="center" className={classes.plainWhite} variant="h3">
                      {t('common:yourInformationWasSent')}{' '}
                      <span className={classes.accent}> {t('common:successfully')}</span>!
                    </Typography>
                  </Box>
                  <Box>
                    <Grid alignItems="center" container item justifyContent="center">
                      <CheckCircleOutlineIcon className={classes.icon} />
                      <Box ml={1}>
                        <Typography className={classes.plainWhite} variant="body1">
                          {formData.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                  <Grid alignItems="center" container item justifyContent="center">
                    <CheckCircleOutlineIcon className={classes.icon} />
                    <Box ml={1}>
                      <Typography className={classes.plainWhite} variant="body1">
                        {formData.email}
                      </Typography>
                    </Box>
                  </Grid>
                  <Box className={classes.mobileGetBackToYou} mb={2} mt={2}>
                    <Typography align="center" className={classes.plainWhite} variant="h6">
                      {t('common:ourTeamWillGetBackToYou')}
                    </Typography>
                  </Box>
                  <LandingPageButton text={link} type="mediumStandard" />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Grid>
    </>
  );

  const desktopContent = (
    <>
      <Grid className={classes.container} container>
        <Grid
          alignItems="center"
          className={classes.leftColumnWrapper}
          container
          direction="column"
          item
          justifyContent="space-evenly"
        >
          <Box className={classes.blueFlash} />
          <Box className={classes.truxiWrapper}>
            {!submitSuccess && <TruxiSmiling height={232} width={206} />}
            {submitSuccess && <TruxiStars height={232} width={206} />}
          </Box>
          <Typography align="center" className={classes.bold} variant="h1">
            {t('common:joinTheRevolution')}
          </Typography>
          <Typography align="center" className={classes.launchingSoon} variant="body2">
            {t('common:signUpToday')}
          </Typography>
          <Grid alignItems="center" container item justifyContent="center">
            {!userType && (
              <>
                <Typography className={classes.bold} variant="body2">
                  {t('common:selectYourProfile')}
                </Typography>
                <ChevronRightIcon />
              </>
            )}
            {userType && (
              <>
                <Box mr={2}>
                  <Typography className={classes.bold} color="primary" variant="h3">
                    {t(`common:${userType.toLocaleLowerCase()}`)}
                  </Typography>
                </Box>
                <CheckCircleOutlineIcon className={classes.icon} />
              </>
            )}
          </Grid>
        </Grid>
        <Grid className={classes.rightColumnWrapper} container direction="column" item>
          {!userType && !submitSuccess && (
            <>
              {shipperCarrierSelector(EUserTypeV1.CARRIER)}
              {shipperCarrierSelector(EUserTypeV1.SHIPPER)}
            </>
          )}
          {submitSuccess && (
            <>
              <Box className={classes.rightColumnbg} p={8}>
                <Grid
                  alignItems="center"
                  container
                  direction="column"
                  justifyContent="space-evenly"
                >
                  <Box mb={5}>
                    <Typography align="center" className={classes.white} variant="h3">
                      {t('common:yourInformationWasSent')}{' '}
                      <span className={classes.accent}> {t('common:successfully')}</span>!
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Grid alignItems="center" container item justifyContent="center">
                      <CheckCircleOutlineIcon className={classes.icon} />
                      <Box ml={1}>
                        <Typography className={classes.white} variant="body2">
                          {formData.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                  <Grid alignItems="center" container item justifyContent="center">
                    <CheckCircleOutlineIcon className={classes.icon} />{' '}
                    <Box ml={1}>
                      <Typography className={classes.white} variant="body2">
                        {formData.email}
                      </Typography>
                    </Box>
                  </Grid>
                  <Box mb={10} mt={4}>
                    <Typography align="center" className={classes.white} variant="body2">
                      {t('common:ourTeamWillGetBackToYou')}
                    </Typography>
                  </Box>
                  <LandingPageButton text={link} type="mediumStandard" />
                </Grid>
              </Box>
            </>
          )}
          {userType && !submitSuccess && (
            <Box className={classes.rightColumnbg} p={8}>
              <form onSubmit={handleSubmit(onSubmitSuccess)}>
                <Grid container direction="column">
                  <Box mb={1}>{formFieldName}</Box>
                  {formFieldEmail}
                  <Box mb={8} mt={7}>
                    <Typography className={classes.white} variant="h5">
                      {t('common:iWantToJoinThe')}{' '}
                      <span className={classes.accent}> {t('common:betaVersion')}</span>{' '}
                      {t('common:now')}.
                    </Typography>
                    <Box mt={2}>{formFieldRadio}</Box>
                  </Box>
                  <Grid container justifyContent="space-between">
                    <LandingPageButton
                      isSubmit
                      onClick={() => {
                        setUserType(null);
                      }}
                      text={t('common:back')}
                      type="mediumOutlineWhite"
                    />
                    <LandingPageButton
                      isSaving={isSending}
                      text={t('common:submit')}
                      type="mediumStandard"
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {isMobile && (
        <LandingPageMobileSwipeableDrawer
          formData={formData}
          formFieldEmail={formFieldEmail}
          formFieldName={formFieldName}
          formFieldRadio={formFieldRadio}
          handleClick={handleClick}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isOpen={isOpen}
          isSending={isSending}
          isSubmittedSuccessfully={submitSuccess}
          link={link}
          onSubmitSuccess={onSubmitSuccess}
          setOpen={setOpen}
          setUserType={setUserType}
          userType={userType}
        />
      )}
      {!isMobile && (
        <Modal
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          aria-describedby="pop-up-modal-join-truxweb"
          aria-labelledby="join-the-revolution"
          className={classes.modal}
          closeAfterTransition
          disableScrollLock
          onClose={handleClose}
          open={isOpen}
        >
          <Box className={classes.fadeInBox}>
            {isTablet && tabletContent}
            {isDesktop && desktopContent}
          </Box>
        </Modal>
      )}
    </>
  );
};
