import { createCarrier } from '..';
import { TCarrierRegistrationV1 } from '@truxweb/schemas';

export const registerCarrier = async (
  data: TCarrierRegistrationV1,
  userEmail: string,
  companyName: string
): Promise<boolean> => {
  try {
    await createCarrier(data, userEmail, companyName);
    return true;
  } catch (err) {
    return false;
  }
};
