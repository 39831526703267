"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateNewOrderSummaryFieldForArray = exports.formatDataFromOrderSummaryForm = exports.getServiceFeeTitle = exports.getAdditionalChargeTitle = exports.getChargeAdjustLocaleString = exports.getChargeTitle = exports.groupChargesForEditableSummary = void 0;
const schemas_1 = require("@truxweb/schemas");
const _1 = require(".");
const lodash_1 = require("lodash");
const utils_1 = require("@truxweb/utils");
const cloneItem = (item) => {
    return (0, lodash_1.cloneDeep)(item);
};
const groupChargesForEditableSummary = (request) => {
    const { accessorials, areAccessorialsEditable, areAdditionalChargesEditable, charges, isEditingDisabled, shipmentDetails, } = request;
    const { equipment: shipmentEquipment } = shipmentDetails;
    let total = 0;
    let subtotal = 0;
    const isEditable = isEditingDisabled !== undefined ? !isEditingDisabled : true;
    const equipment = [];
    const fuelSurcharge = [];
    const accessorial = [];
    const additional = [];
    const truxwebCarrierCharges = [];
    const truxwebShipperCharges = [];
    const taxes = [];
    const serviceFees = [];
    const truxwebFees = [];
    const truxwebTaxes = [];
    const carrierTaxes = [];
    const carrierFreightCharges = [];
    const truxwebShipperShipmentFees = [];
    const carrierAdditionalCharges = [];
    const creditCardSurcharge = [];
    const accessorialLookup = {};
    const accessorialLookupByCode = {};
    const equipmentLookup = {};
    shipmentEquipment.forEach((record) => {
        equipmentLookup[record.equipmentId] = record;
    });
    accessorials.forEach((record) => {
        accessorialLookup[record.id] = record;
        accessorialLookupByCode[record.code] = record;
    });
    charges.forEach((charge) => {
        if (!charge.isBillable) {
            charge.charge.value = 0;
        }
        if (charge.recipient === 'CARRIER' &&
            !charge.isTaxCharge &&
            ![schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER].includes(charge.chargeType)) {
            if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.ADDITIONAL) {
                carrierAdditionalCharges.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
            else {
                carrierFreightCharges.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
        }
        if (charge.isBillable && ![schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER].includes(charge.chargeType)) {
            const addition = (0, _1.formatChargeNumberToCurrencyValue)(charge);
            total += addition;
        }
        if (!charge.isTaxCharge &&
            ![
                schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER,
                schemas_1.EShipmentChargeTypeV1.CREDIT_CARD_SURCHARGE,
            ].includes(charge.chargeType) &&
            charge.isBillable) {
            subtotal += (0, _1.formatChargeNumberToCurrencyValue)(charge);
        }
        if (charge.isTaxCharge) {
            taxes.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            if (charge.recipient === 'TRUXWEB') {
                truxwebTaxes.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
            if (charge.recipient === 'CARRIER') {
                carrierTaxes.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
        }
        if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.FUEL_SURCHARGE) {
            fuelSurcharge.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        // CC Surchage is Never editable, this is always calculated
        if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.CREDIT_CARD_SURCHARGE) {
            creditCardSurcharge.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
        }
        if (charge.recipient === 'TRUXWEB' &&
            !charge.isTaxCharge &&
            [schemas_1.EShipmentChargeTypeV1.TRUXWEB_SHIPPER].includes(charge.chargeType)) {
            truxwebShipperCharges.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        if (charge.recipient === 'TRUXWEB' &&
            !charge.isTaxCharge &&
            [schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER].includes(charge.chargeType)) {
            truxwebCarrierCharges.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        // Truxweb Fee is Never editable, this is always calculated
        if (charge.recipient === 'TRUXWEB' &&
            !charge.isTaxCharge &&
            ![
                schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER,
                schemas_1.EShipmentChargeTypeV1.TRUXWEB_SHIPPER,
                schemas_1.EShipmentChargeTypeV1.CREDIT_CARD_SURCHARGE,
                schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE,
            ].includes(charge.chargeType)) {
            serviceFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: charge.isBillable }));
            if (charge.isBillable) {
                truxwebFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
            }
        }
        if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE) {
            truxwebShipperShipmentFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
            truxwebFees.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: false }));
        }
        if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.EQUIPMENT) {
            equipment.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable }));
        }
        if ([schemas_1.EShipmentChargeTypeV1.ACCESSORIAL].includes(charge.chargeType)) {
            accessorial.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: areAccessorialsEditable }));
        }
        if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.ADDITIONAL) {
            additional.push(Object.assign(Object.assign({}, charge), { _id: charge.id, isEditable: areAdditionalChargesEditable }));
        }
    });
    // Group the truxweb taxes
    const truxwebTaxesLookup = {};
    truxwebTaxes.forEach((charge) => {
        if (!charge.isBillable)
            return;
        let lookup = `${charge.chargeType}`;
        if (charge.chargeReference === 'CARRIER_TAX') {
            lookup = `${charge.chargeType}-CARRIER`;
        }
        if (!truxwebTaxesLookup[lookup]) {
            const newChargeValue = (0, _1.formatChargeNumberToCurrencyValue)(charge);
            truxwebTaxesLookup[lookup] = cloneItem(charge);
            truxwebTaxesLookup[lookup].charge.value = newChargeValue;
        }
        else {
            truxwebTaxesLookup[lookup].charge.value += (0, _1.formatChargeNumberToCurrencyValue)(charge);
        }
    });
    const groupedTruxwebTaxes = Object.values(truxwebTaxesLookup);
    // TRUXWEB SUMMARY
    const truxwebFeeTotal = truxwebFees.length
        ? truxwebFees.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebCarrierFeeTotal = truxwebCarrierCharges.length
        ? truxwebCarrierCharges.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebShipperFeeTotal = truxwebShipperCharges.length
        ? truxwebShipperCharges.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebSubTotalFields = [
        truxwebFeeTotal,
        truxwebCarrierFeeTotal,
        truxwebShipperFeeTotal,
    ].filter((record) => record !== null && record.isBillable);
    const truxwebSubtotal = truxwebSubTotalFields.length
        ? truxwebSubTotalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebTotalFields = [truxwebSubtotal, ...groupedTruxwebTaxes].filter((record) => record !== null && record.isBillable);
    const truxwebTotal = truxwebTotalFields.length
        ? truxwebTotalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const truxwebOrderSummary = {
        truxwebCarrierFeeTotal,
        truxwebFeeTotal,
        truxwebFees,
        truxwebShipperFeeTotal,
        truxwebSubtotal,
        truxwebTaxes: groupedTruxwebTaxes,
        truxwebTotal,
    };
    // CARRIER SUMMARY
    const carrierFreightFields = carrierFreightCharges.filter((record) => record !== null);
    const carrierFrieghtTotal = carrierFreightFields.length
        ? carrierFreightFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const carrierAdditionalFields = carrierAdditionalCharges.filter((record) => record !== null);
    const carrierAdditionalChargeTotal = carrierAdditionalFields.length
        ? carrierAdditionalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const carrierSubTotalFields = [
        carrierFrieghtTotal,
        truxwebCarrierFeeTotal,
        ...(carrierAdditionalFields || []),
    ].filter((record) => record !== null);
    const carrierSubtotal = carrierSubTotalFields.length
        ? carrierSubTotalFields.map(cloneItem).reduce((acc, curr) => {
            const current = (0, _1.formatChargeNumberToCurrencyValue)(acc);
            const next = (0, _1.formatChargeNumberToCurrencyValue)(curr);
            // TRUXWEB CARRIER fees are treated as debits from the total they will recieved
            // if there is a negative carrier fee, this will increase the amount
            const nextValue = curr.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER
                ? current - next
                : current + next;
            acc.charge = Object.assign(Object.assign({}, acc.charge), { value: nextValue });
            return acc;
        })
        : null;
    const carrierTotalFields = [carrierSubtotal, ...carrierTaxes].filter((record) => record !== null);
    const carrierTotal = carrierTotalFields.length
        ? carrierTotalFields.map(cloneItem).reduce(_1.reduceCharges)
        : null;
    const carrierOrderSummary = {
        carrierAdditionalChargeTotal,
        carrierFeeTotal: truxwebCarrierFeeTotal,
        carrierFrieghtTotal,
        carrierSubtotal,
        carrierTaxes,
        carrierTotal,
    };
    const shipperTaxes = {};
    [...groupedTruxwebTaxes, ...carrierTaxes].forEach((charge) => {
        if (!charge.isBillable)
            return;
        if (!shipperTaxes[charge.chargeType]) {
            const newChargeValue = (0, _1.formatChargeNumberToCurrencyValue)(charge);
            shipperTaxes[charge.chargeType] = cloneItem(charge);
            shipperTaxes[charge.chargeType].charge.value = newChargeValue;
        }
        else {
            shipperTaxes[charge.chargeType].charge.value += (0, _1.formatChargeNumberToCurrencyValue)(charge);
        }
    });
    const shipperOrderSummary = {
        shipperSubtotal: {
            currency: schemas_1.ECurrencyV1.CAD,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: subtotal || 0,
        },
        shipperTaxes: Object.values(shipperTaxes),
        shipperTotal: {
            currency: schemas_1.ECurrencyV1.CAD,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: (0, utils_1.formatNumberToCurrencyValue)(total) || 0,
        },
    };
    return {
        accessorial,
        accessorialLookup,
        accessorialLookupByCode,
        additional,
        carrierOrderSummary,
        creditCardSurcharge,
        equipment,
        equipmentLookup,
        fuelSurcharge,
        serviceFees,
        shipperOrderSummary,
        truxwebCarrierCharges,
        truxwebOrderSummary,
        truxwebShipperCharges,
        truxwebShipperShipmentFees,
    };
};
exports.groupChargesForEditableSummary = groupChargesForEditableSummary;
const getChargeTitle = (charge, language, t) => {
    const chargeTitle = t(`common:charge-${charge.chargeType}`);
    const explanationId = charge.chargeType;
    if ([schemas_1.EShipmentChargeTypeV1.TRUXWEB_SHIPPER, schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER].includes(charge.chargeType)) {
        const chargeTitlePieces = ['additionalchargetype', charge.recipient, charge.chargeType];
        if (charge.chargeReference) {
            chargeTitlePieces.push(charge.chargeReference);
        }
        return { chargeTitle: t(`shipments:${chargeTitlePieces.join('-')}`), explanationId };
    }
    if (charge.chargeReference === 'CARRIER_CHARGE_ADJUSTMENT') {
        const localeString = charge.charge.value > 0 ? 'delayedPaymentDiscount' : 'advancedPaymentSurcharge';
        return {
            chargeTitle: t(`common:CARRIER-${localeString}`),
            chargeTitleSuffix: `(${t('common:carrier')})`,
            explanationId,
        };
    }
    if (charge.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
        return {
            chargeExplanation: t(`common:serviceFeeExplanation-adminFees-description`),
            chargeTitle: t(`common:adminFees`),
            chargeTitleSuffix: `(${t('common:shipper')})`,
            explanationId: `${explanationId}-${charge.chargeReference}`,
        };
    }
    if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE) {
        const feeDetails = charge === null || charge === void 0 ? void 0 : charge.details;
        if (!feeDetails) {
            return {
                chargeTitle: t(`common:adminFees`),
                explanationId,
            };
        }
        const chargeExplanationDescription = language === schemas_1.ELanguageV1.EN_CA ? feeDetails.feeDescriptionEn : feeDetails.feeDescriptionFr;
        const hiddenFeesExplanation = chargeExplanationDescription
            ? `. ${t('common:hiddenFeesExplanation')}`
            : t('common:hiddenFeesExplanation');
        return {
            chargeExplanation: feeDetails.isVisible === true
                ? chargeExplanationDescription
                : `${chargeExplanationDescription || ''}${hiddenFeesExplanation}`,
            chargeTitle: language === schemas_1.ELanguageV1.EN_CA
                ? feeDetails.feeLabelEn
                : feeDetails.feeLabelFr || t('common:adminFees'),
            chargeTitleSuffix: feeDetails.feeValueType === schemas_1.EPriceTypeV1.PERCENT ? `(${feeDetails.feeValue}%)` : undefined,
            explanationId: feeDetails.feeType,
        };
    }
    return { chargeTitle, explanationId };
};
exports.getChargeTitle = getChargeTitle;
const getChargeAdjustLocaleString = (charge, companyType) => {
    let localeString = '';
    if (companyType === schemas_1.EUserTypeV1.CARRIER) {
        localeString = charge.charge.value > 0 ? 'delayedPaymentDiscount' : 'advancedPaymentSurcharge';
    }
    else if (companyType === schemas_1.EUserTypeV1.SHIPPER) {
        localeString = charge.charge.value > 0 ? 'delayedPaymentSurcharge' : 'advancedPaymentDiscount';
    }
    return localeString;
};
exports.getChargeAdjustLocaleString = getChargeAdjustLocaleString;
const getAdditionalChargeTitle = (charge, companyType, additionalChargesTypes, t) => {
    return charge.chargeReference &&
        (additionalChargesTypes.includes(charge.chargeReference) ||
            [`TRUXWEB_${companyType}`].includes(charge.chargeType))
        ? t(`shipments:additionalchargetype-${charge.recipient}-${charge.chargeReference}`)
        : t('shipments:additionalCharges');
};
exports.getAdditionalChargeTitle = getAdditionalChargeTitle;
const getServiceFeeTitle = (charge, language, t) => {
    const feeExplanationReference = charge.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT' ? 'adminFees' : charge.chargeType;
    let explanationDetails;
    let explanationTitle = t(`common:serviceFeeExplanation-${feeExplanationReference}-title`);
    let isExplanationSuppressed = false;
    let chargeTitle = charge.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_SHIPPER
        ? t(`shipments:additionalchargetype-${charge.recipient}-${charge.chargeReference}`)
        : t(`common:charge-${charge.chargeType}`);
    let explanationId = charge.chargeType;
    if (charge.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
        explanationId = `${explanationId}-${charge.chargeReference}`;
        chargeTitle = t('common:adminFees');
        explanationDetails = t(`common:serviceFeeExplanation-${feeExplanationReference}-description`);
    }
    if (charge.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE) {
        const response = (0, exports.getChargeTitle)(charge, language, t);
        chargeTitle = response.chargeTitle;
        explanationId = response.explanationId;
        explanationTitle = chargeTitle;
        if (response.chargeExplanation) {
            explanationDetails = response.chargeExplanation;
        }
        else {
            isExplanationSuppressed = true;
        }
    }
    return {
        chargeTitle,
        explanationDetails,
        explanationId,
        explanationTitle,
        isExplanationSuppressed,
    };
};
exports.getServiceFeeTitle = getServiceFeeTitle;
const formatDataFromOrderSummaryForm = (data) => {
    var _a;
    const updatedServiceFees = data.serviceFees || [];
    const updatedCharges = [
        ...data.equipment,
        ...(data.accessorial || []),
        ...(data.additional || []),
        ...(data.truxwebShipperCharges || []),
        ...updatedServiceFees,
        ...(data.truxwebCarrierCharges || []),
        ...(data.truxwebShipperShipmentFees || []),
    ];
    let fuelSurchargeModifications;
    let truxwebMarginModifications;
    let legacyFeeModifications;
    let shipmentUpdate;
    if (data.fscRate) {
        shipmentUpdate = {
            fscRate: parseFloat((data.fscRate / 100).toFixed(4)),
            isFscActive: true,
        };
        const fscRate = parseFloat(data.fscRate) / 100;
        fuelSurchargeModifications = {
            fscRate,
            fscValue: parseFloat(data.fscChargeValue),
            isFscActive: Boolean(fscRate),
        };
    }
    const truxwebMargin = updatedServiceFees.find(({ chargeReference, chargeType }) => {
        return (chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB &&
            chargeReference !== 'SHIPPER_CHARGE_ADJUSTMENT');
    });
    if (truxwebMargin) {
        truxwebMarginModifications = {
            hasTruxwebMarginBeenModified: ((_a = truxwebMargin.details) === null || _a === void 0 ? void 0 : _a.hasTruxwebMarginBeenModified) || false,
            truxwebMarginValue: parseFloat(`${truxwebMargin.charge.value}`),
        };
    }
    const legacyFee = updatedServiceFees.find(({ chargeReference }) => {
        return chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT';
    });
    if (!legacyFee) {
        legacyFeeModifications = {
            hasLegacyFeeBeenRemoved: true,
        };
    }
    return {
        charges: updatedCharges,
        payload: {
            fuelSurchargeModifications,
            legacyFeeModifications,
            truxwebMarginModifications,
        },
        shipmentUpdate,
    };
};
exports.formatDataFromOrderSummaryForm = formatDataFromOrderSummaryForm;
const generateNewOrderSummaryFieldForArray = (chargeType, recipient, fieldDetails) => {
    return {
        _id: 0,
        charge: {
            currency: schemas_1.ECurrencyV1.CAD,
            isBillable: true,
            type: schemas_1.EPriceTypeV1.CURRENCY,
            value: 0,
        },
        chargeReference: '',
        chargeType,
        chargeTypeId: 0,
        details: fieldDetails,
        isBillable: true,
        isChargeActive: true,
        isEditable: true,
        isNewCharge: true,
        isPriceAdjustment: false,
        isTaxCharge: false,
        quantity: 1,
        recipient,
    };
};
exports.generateNewOrderSummaryFieldForArray = generateNewOrderSummaryFieldForArray;
