"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudHailMixedIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9.817,24a1.025,1.025,0,0,1-.244-.03,1,1,0,0,1-.727-1.212l1.5-6a1,1,0,1,1,1.94.484l-1.5,6A1,1,0,0,1,9.817,24Zm4.934-3a1.025,1.025,0,0,1-.244-.03,1,1,0,0,1-.727-1.212l1.5-6a1,1,0,1,1,1.94.484l-1.5,6A1,1,0,0,1,14.751,21Zm-8,0a1.025,1.025,0,0,1-.244-.03,1,1,0,0,1-.727-1.212l1.5-6a1,1,0,1,1,1.94.484l-1.5,6A1,1,0,0,1,6.751,21ZM19,19.5a1,1,0,0,1-.385-1.923c4.967-2.006,4.217-9.47-1.032-10.472a3.012,3.012,0,0,1-2.158-1.672A6,6,0,0,0,4.086,6.967a6.136,6.136,0,0,0,.024,2.18,3,3,0,0,1-.964,2.763,3.522,3.522,0,0,0,.411,5.5,1,1,0,1,1-1.114,1.662A5.537,5.537,0,0,1,1.8,10.43a1,1,0,0,0,.345-.9,8.147,8.147,0,0,1-.033-2.889A7.945,7.945,0,0,1,8.5.138a8.053,8.053,0,0,1,8.735,4.438,1.033,1.033,0,0,0,.742.57c7.187,1.423,8.193,11.483,1.411,14.278A.991.991,0,0,1,19,19.5ZM11,14a1,1,0,0,0,2,0A1,1,0,0,0,11,14ZM5,23a1,1,0,0,0,2,0A1,1,0,0,0,5,23Zm8,0a1,1,0,0,0,2,0A1,1,0,0,0,13,23Z" })));
exports.default = TruxwebCloudHailMixedIcon;
