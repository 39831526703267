import type { TBookingSearchRequestV1, TBookingSearchResultV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const bookingSearch = async (
  query: TBookingSearchRequestV1
): Promise<{ results: TBookingSearchResultV1[] }> => {
  const results = await makeRequestToApiGateway(
    'bookingPostV1BookingSearch',
    null,
    {
      'Content-Type': 'application/json',
    },
    query
  );
  return results as { results: TBookingSearchResultV1[] };
};
