"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentComment = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const date_fns_1 = require("date-fns");
const __1 = require("..");
const utils_1 = require("@truxweb/utils");
const ShipmentComment_styles_1 = require("./ShipmentComment.styles");
const ShipmentComment = ({ canAdministerComments, comment, handleChangeTruxwebCommentVisibility, handleDeleteComment, isOwnComment, shouldShowAvatar, shouldShowTimeStamp, t, user, viewingUserType, }) => {
    var _a;
    const [isDeleteShown, setDeleteShown] = (0, react_1.useState)(false);
    const commentDate = comment.dateCreated ? new Date(comment.dateCreated) : new Date();
    // NOTE: This is a non-ideal way of determine user type
    const commentUserType = comment.userType;
    const classes = (0, ShipmentComment_styles_1.useStyles)({ commentUserType, isOwnComment, viewingUserType });
    let userName = (user === null || user === void 0 ? void 0 : user.firstName) || comment.userName;
    let userNamePieces = [];
    if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB || commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
        userNamePieces = comment.userName.split(' ');
        userName = (0, utils_1.titleize)(userNamePieces[0] || '');
    }
    const dateString = (0, date_fns_1.format)(commentDate, 'p');
    const [imageAttachments, otherAttachments] = (0, react_1.useMemo)(() => {
        var _a;
        if (!((_a = comment.attachments) === null || _a === void 0 ? void 0 : _a.length))
            return [[], []];
        const images = [];
        const other = [];
        comment.attachments.forEach((a) => {
            if (/image/.test(a.attachmentMimeType)) {
                images.push(a);
            }
            else {
                other.push(a);
            }
        });
        return [images, other];
    }, [comment]);
    const [deleteTimeout, setDeleteTimeout] = (0, react_1.useState)(null);
    const handleShowDeleteComment = (0, react_1.useCallback)(() => {
        if (deleteTimeout) {
            clearTimeout(deleteTimeout);
            setDeleteTimeout(null);
        }
        setDeleteShown(true);
    }, [deleteTimeout, setDeleteTimeout, setDeleteShown]);
    const handleHideDeleteComment = (0, react_1.useCallback)(() => {
        if (deleteTimeout) {
            clearTimeout(deleteTimeout);
        }
        const timer = setTimeout(() => {
            setDeleteShown(false);
        }, 50);
        setDeleteTimeout(timer);
    }, [deleteTimeout, setDeleteTimeout, setDeleteShown]);
    const attachments = Boolean((_a = comment.attachments) === null || _a === void 0 ? void 0 : _a.length) && Boolean(comment._tempId) ? (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center" },
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.CircularProgress, { size: 24 })))) : (react_1.default.createElement(react_1.default.Fragment, null, (imageAttachments.length !== 0 || otherAttachments.length !== 0) && (react_1.default.createElement(ux_1.Box, { mt: 2 },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" }, imageAttachments.map((attachment) => {
            return (react_1.default.createElement(ux_1.Grid, { className: classes.attachment, item: true, key: attachment.id },
                react_1.default.createElement("a", { href: attachment.attachmentLocation, target: `${attachment.id}` },
                    /image/.test(attachment.attachmentMimeType) && (react_1.default.createElement("img", { alt: classes.attachmentImage, className: classes.attachmentImage, src: attachment.attachmentLocation })),
                    !/image/.test(attachment.attachmentMimeType) && (react_1.default.createElement(ux_1.Typography, null, attachment.attachmentName)))));
        })),
        imageAttachments.length !== 0 && otherAttachments.length !== 0 && react_1.default.createElement(ux_1.Box, { mt: 2 }),
        react_1.default.createElement("ul", null, otherAttachments.map((attachment) => {
            return (react_1.default.createElement("li", { key: attachment.id },
                react_1.default.createElement(ux_1.Typography, { className: classes.attachmentText, color: "primaryLight" },
                    react_1.default.createElement("a", { href: attachment.attachmentLocation, style: { color: 'inherit' }, target: `${attachment.id}` }, attachment.attachmentName))));
        }))))));
    let direction = isOwnComment ? 'row' : 'row-reverse';
    if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
        if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
            direction = 'center';
        }
        else {
            direction = commentUserType === schemas_1.EUserTypeV1.CARRIER ? 'row' : 'row-reverse';
        }
    }
    let userTypeBanner = (react_1.default.createElement(ux_1.Grid, { item: true },
        react_1.default.createElement(ux_1.Typography, { className: classes.userType, variant: "bodySmall" }, t(`common:${(commentUserType || '').toLowerCase()}`))));
    if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
        userTypeBanner = (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Typography, { className: classes.userType, variant: "bodySmall" },
                userName,
                " (",
                t(`common:${(commentUserType || '').toLowerCase()}`),
                ")")));
    }
    (0, react_1.useEffect)(() => {
        return () => {
            if (deleteTimeout)
                clearTimeout(deleteTimeout);
        };
    }, [deleteTimeout]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: 'flex-start' },
        react_1.default.createElement(ux_1.Grid, { item: true, style: { width: '100%' } },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", onMouseOut: handleHideDeleteComment, onMouseOver: handleShowDeleteComment },
                shouldShowAvatar && (react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: direction, justifyContent: 'flex-start' },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            user !== undefined && (react_1.default.createElement(react_1.default.Fragment, null,
                                react_1.default.createElement(ux_1.UserProfile, { isSelf: isOwnComment, isSmall: true, userData: user }))),
                            !user && viewingUserType !== schemas_1.EUserTypeV1.TRUXWEB && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center" },
                                react_1.default.createElement(ux_1.AccountCircleOutlinedIcon, { className: classes.userAvatar })))),
                        commentUserType !== schemas_1.EUserTypeV1.TRUXWEB && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { ml: 1.5, mr: 1.5 },
                                react_1.default.createElement(ux_1.Typography, { className: classes.userAvatar, fontStyle: "semibold", style: { borderRadius: '100%' }, variant: "bodyMedium" }, userName)))),
                        userTypeBanner))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mt: shouldShowAvatar ? 1.5 : 0, style: { position: 'relative' } },
                        viewingUserType === schemas_1.EUserTypeV1.TRUXWEB &&
                            commentUserType === schemas_1.EUserTypeV1.TRUXWEB &&
                            !comment.isVisibleExternally && (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-start", style: { left: '-8px', position: 'absolute', top: '-8px' } },
                            react_1.default.createElement(ux_1.Tooltip, { title: t('common:internalOnly') },
                                react_1.default.createElement(ux_1.Box, { className: classes.privateIcon },
                                    react_1.default.createElement(ux_1.TruxwebWingsIcon, null))))),
                        react_1.default.createElement(ux_1.Paper, { className: classes.itemBackground, title: dateString },
                            react_1.default.createElement(ux_1.Typography, { className: classes.comment, color: commentUserType === schemas_1.EUserTypeV1.TRUXWEB ? 'white' : undefined }, comment.comment),
                            attachments),
                        canAdministerComments && isDeleteShown && (react_1.default.createElement(__1.ShipmentCommentActionButton, { comment: comment, handleChangeTruxwebCommentVisibility: handleChangeTruxwebCommentVisibility, handleDeleteComment: handleDeleteComment, handleHideActions: () => setDeleteShown(false), handleShowCommentActions: handleShowDeleteComment, t: t })))),
                shouldShowTimeStamp && (react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: isOwnComment ? 'flex-start' : 'flex-end' },
                        react_1.default.createElement(ux_1.Box, { ml: 2, mt: 0.5 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.commentTime, variant: "bodySmall" }, dateString)))))))));
};
exports.ShipmentComment = ShipmentComment;
