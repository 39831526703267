"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpeningHours = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const date_fns_1 = require("date-fns");
const OpeningHours = ({ closeHour, control, isDisabled, isDisplayOnly, openHour, prefix, t, testId, watch, }) => {
    const [minHour, setMinHour] = (0, react_1.useState)(openHour);
    const controlPrefix = prefix || '';
    const modifiedOpenHour = watch(`${controlPrefix}openHour`);
    const timeIntervalIncrementMinutes = 30;
    (0, react_1.useEffect)(() => {
        if (modifiedOpenHour !== undefined && modifiedOpenHour !== minHour) {
            setMinHour(modifiedOpenHour);
        }
    }, [modifiedOpenHour, minHour, setMinHour]);
    const openHoursDate = (0, utils_1.convertTimeToDate)(openHour);
    const closeHoursDate = (0, utils_1.convertTimeToDate)(closeHour);
    if (isDisplayOnly) {
        return openHoursDate && closeHoursDate ? (react_1.default.createElement(ux_1.Grid, { container: true },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, null, (0, date_fns_1.format)(openHoursDate, 'p'))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mx: 1 },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:to')))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, null, (0, date_fns_1.format)(closeHoursDate, 'p'))))) : (react_1.default.createElement(react_1.default.Fragment, null));
    }
    return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, xs: true },
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: openHour, name: `${controlPrefix}openHour`, render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(ux_1.TimeDropdown, { inputProps: {
                            id: `${testId}-OpenHour`,
                        }, isDisabled: isDisabled, onChange: onChange, timeIncMinutes: timeIntervalIncrementMinutes, value: value }));
                }, rules: { required: true } })),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mx: 1 },
                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:to')))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: closeHour, name: `${controlPrefix}closeHour`, render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(ux_1.TimeDropdown, { inputProps: {
                            id: `${testId}-CloseHour`,
                        }, isDisabled: isDisabled, minHours: minHour, onChange: onChange, timeIncMinutes: timeIntervalIncrementMinutes, value: value }));
                }, rules: { required: true } }))));
};
exports.OpeningHours = OpeningHours;
