"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDiceD6Icon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.572,4.312l-6-3.6a4.987,4.987,0,0,0-5.145,0l-6,3.6A5.027,5.027,0,0,0,1,8.6v6.8a5.027,5.027,0,0,0,2.427,4.288l6,3.6a4.985,4.985,0,0,0,5.145,0l6-3.6A5.027,5.027,0,0,0,23,15.4V8.6A5.027,5.027,0,0,0,20.572,4.312ZM10.457,2.428a2.989,2.989,0,0,1,3.086,0l6,3.6a2.978,2.978,0,0,1,.46.341l-8,4.8-8-4.8a2.931,2.931,0,0,1,.46-.341Zm-6,15.545A3.015,3.015,0,0,1,3,15.4V8.6a2.881,2.881,0,0,1,.039-.475L11,12.9v8.928a3.022,3.022,0,0,1-.543-.257ZM21,15.4a3.017,3.017,0,0,1-1.457,2.573l-6,3.6a3.022,3.022,0,0,1-.543.257V12.9l7.961-4.776A2.881,2.881,0,0,1,21,8.6Z" })));
exports.default = TruxwebDiceD6Icon;
