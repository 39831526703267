// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodV1 = void 0;
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
