import FacebookLogo from '../../public/assets/images/logos/facebook-logo.svg';
import { Grid } from '@truxweb/ux';
import InstagramLogo from '../../public/assets/images/logos/instagram-logo.svg';
import LinkedInLogo from '../../public/assets/images/logos/linkedIn-logo.svg';
import React from 'react';
import TwitterLogo from '../../public/assets/images/logos/twitter-logo.svg';
import { useStyles } from './SocialMediaIconLinks.styles';

type TSocialMediaIconLinksProps = {
  isDark?: boolean;
  screenSize: 'mobile' | 'tablet' | 'desktop';
};

export const SocialMediaIconLinks = ({
  isDark,
  screenSize,
}: TSocialMediaIconLinksProps): JSX.Element => {
  const classes = useStyles({ isDark, screenSize });

  return (
    <Grid alignItems="center" container direction="row" justifyContent="space-between">
      <Grid item>
        <a
          className={classes.logo}
          href="http://www.facebook.com/Truxweb"
          target="truxweb-facebook"
        >
          <FacebookLogo className={classes.logoHeight} />
        </a>
      </Grid>

      <Grid item>
        <a
          className={classes.logo}
          href="http://www.linkedin.com/company/truxweb"
          target="truxweb-linkedin"
        >
          <LinkedInLogo className={classes.logoHeight} />
        </a>
      </Grid>
      <Grid item>
        <a className={classes.logo} href="http://www.twitter.com/Truxweb1" target="truxweb-twitter">
          <TwitterLogo className={classes.logoHeight} />
        </a>
      </Grid>
      <Grid item>
        <a
          className={classes.logo}
          href="http://www.instagram.com/truxweb"
          target="truxweb-instagram"
        >
          <InstagramLogo className={classes.logoHeight} />
        </a>
      </Grid>
    </Grid>
  );
};
