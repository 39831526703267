import { makeRequestToApiGateway } from '../../apiUtils';

export const getCarrierQuoteIdByRef = async (quoteRef: string): Promise<any> => {
  return await makeRequestToApiGateway(
    'shipmentGetV1CarrierQuoteRequestById',
    { id: quoteRef },
    {
      'Content-Type': 'application/json',
    },
    null,
    {
      carrierRequestId: quoteRef,
      fetchIdByRef: true,
    }
  );
};
