import {
  EAccessorialOptionV1,
  ECurrencyV1,
  EDayOfWeekV1,
  ERequiredTruckFeatureV1,
  ETruckloadTypeV1,
  TElementHeightWidthV1,
  TLatLngV1,
} from '@truxweb/schemas';
import { TPageId } from '../types';

export const DEFAULT_MAP_CENTER: TLatLngV1 = { latitude: 39.5, longitude: -98.35 };
export const DEFAULT_MAP_ZOOM = 4;
export const DEFAULT_MAP_SIZE: TElementHeightWidthV1 = {
  height: 500,
  width: 500,
};
export const DEFAULT_SERVICE_RANGE_UNIT = 'mi';
export const DEFAULT_COUNTRY_PHONE_CODE = '+1';

// FIXME: Quantities greater than one are not fully supported,
// specifically with regards to tracking. Increasing this will
// have unexpected sideeffects... so.. uh... don't change it
// TRB 09/03/2021
export const DEFAULT_QUANTITY = 1;
export const DEFAULT_TRUCK_TYPE = ETruckloadTypeV1.LTL;
export const DEFAULT_TRUCK_FEATURES = ERequiredTruckFeatureV1.DRY;
export const DEFAULT_TRUCK_SELECTION = 'DRY_LTL';
export const TRUCK_IMAGES = {
  '20_CONTAINER': 'DryVan',
  '40_CONTAINER': 'Refrigerated',
  '53_DRY_FTL': 'DryVan',
  '53_REEFER_FTL': 'Refrigerated',
  DOUBLE_DROP_DECK: 'DoubleDrop',
  FLATBED_DUAL_AXLE: 'FlatBed',
  FLATBED_QUAD_AXLE: 'QuadAxle',
  FLATBED_TRIPLE_AXLE: 'TripleAxle',
  STEP_DECK: 'StepDeck',
};

export const DEFAULT_CURRENCY = ECurrencyV1.CAD;

export const USER_TERMS_AND_CONDITIONS_VERSION = 1;

export const DEFAULT_SHIPMENTS_LIST_SEARCH_KEYS = [
  'shipment.shipmentRef',
  'shipment.billOfLading',
  'shipment.status',
  // booking date = shipment date
  'shipment.bookingDate',
  //origin, -- location.
  //destination, -- location.
  //TODO pickup date
  //TODO add tags (eventually when we have this)
];

export const SEARCH_MINIMUM_DATE_RESTRICTION = 0;

export const LEGACY_LAYOUT_PAGES: TPageId[] = [
  'terms-and-conditions',
  'privacy-policy',
  'about',
  'carrier-services',
  'shipper-services',
];

export const CUSTOM_METADATA_HANDLERS = [EAccessorialOptionV1.DANGEROUS_GOODS];

export const DOC_ITEM_UPLOAD_WITH_DETAILS_FORM_KEY = `onUploadWithDetailsSuccess`;

export const DEFAULT_LOCATION_SCHEDULE = [
  EDayOfWeekV1.Monday,
  EDayOfWeekV1.Tuesday,
  EDayOfWeekV1.Wednesday,
  EDayOfWeekV1.Thursday,
  EDayOfWeekV1.Friday,
];

export const DEFAULT_LOCATION_HOURS = {
  closeHour: 17,
  openHour: 9,
};
