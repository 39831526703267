import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  '@keyframes bounce': {
    '0%': {
      animationTimingFunction: 'ease-in',
      transform: 'translate3d(50px, 40px, 0px)',
    },

    '100%': {
      transform: 'translate3d(50px, 40px, 0px)',
    },
    '50%': {
      animationTimingFunction: 'ease-out',
      transform: 'translate3d(50px, 60px, 0px)',
    },
  },
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  activeDot: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: theme.palette.primary.main,
    borderRadius: '100%',
    height: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    transition: '400ms ease-in-out',
    width: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(1),
      width: theme.spacing(1),
    },
  },
  cloud: {
    animation: '$bounce 15s infinite ease-in-out',
    height: theme.spacing(8),
    left: '10%',
    position: 'absolute',
    top: '55%',
    width: '204px',
    zIndex: -1,
  },
  container: {
    maxHeight: '1420px',
    maxWidth: '1920px',
    minHeight: '340px',
    overflow: 'hidden',
    position: 'relative',
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(43),
    },
    [theme.breakpoints.down(theme.breakpoints.values.xs + 120)]: {
      minHeight: theme.spacing(50),
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 120)]: {
      minHeight: theme.spacing(75),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(100),
    },
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(140),
      width: '100vw',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(188),
    },
  },
  dot: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: theme.palette.grey[400],
    borderRadius: '100%',
    height: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    transition: '400ms ease-in-out',
    width: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(1),
      width: theme.spacing(1),
    },
  },
  gridContainer: {
    marginTop: '10%',
    [theme.breakpoints.up('md')]: {
      marginTop: '10%',
      paddingRight: theme.spacing(4),
    },
  },
  image: {
    maxHeight: '1420px',
    minHeight: '340px',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: '100%',
      right: '-5px',
      width: '118%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.xs + 40)]: {
      bottom: '4%',
      height: '120%',
      right: '-10%',
      width: '120%',
    },
    [theme.breakpoints.between(theme.breakpoints.values.xs + 40, theme.breakpoints.values.xs + 70)]:
      {
        bottom: '0%',
        height: '125%',
        right: '-30%',
        width: '160%',
      },
    [theme.breakpoints.between(
      theme.breakpoints.values.xs + 70,
      theme.breakpoints.values.xs + 100
    )]: {
      bottom: '0%',
      height: '125%',
      right: '-55%',
      width: '200%',
    },
  },
  logo: {
    '&:hover': { color: theme.palette.primary.main },
    animation: '$fade-in 0.4s ease-in-out',
    margin: theme.spacing(2),
    maxWidth: '90%',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(8),
      maxHeight: theme.spacing(8),
    },
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(8),
      maxHeight: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(10),
      maxHeight: theme.spacing(10),
    },
  },
  logoCarrier: {
    '&:hover': {
      cursor: 'pointer',
      filter: 'grayscale(0%)',
      opacity: 1,
    },
    filter: 'grayscale(100%)',
    opacity: 0.5,
    [theme.breakpoints.down('xs')]: {
      height: '',
      width: '',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      width: '100%',
    },
  },
  logoCarrier8: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-4.5%',
    },
  },
  logoWrap: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      display: 'flex',
      height: theme.spacing(8),
      justifyContent: 'center',
      maxHeight: theme.spacing(8),
    },
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(8),
      maxHeight: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(10),
      maxHeight: theme.spacing(10),
    },
  },
  logos: {
    margin: theme.spacing(2),
    maxWidth: '80%',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(10),
    },
  },
  network: {
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.xs + 200)]: {
      fontSize: '15px',
    },
    [theme.breakpoints.between(theme.breakpoints.values.xs + 200, 'md')]: {
      fontSize: '20px',
    },
  },
  number: {
    [theme.breakpoints.down(theme.breakpoints.values.xs + 100)]: {
      height: theme.spacing(5),
      width: theme.spacing(16),
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 100)]: {
      height: theme.spacing(9),
      width: theme.spacing(20),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(11),
      width: theme.spacing(36),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(18),
      width: theme.spacing(57),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(22),
      width: theme.spacing(72),
    },
  },
  swiper: {
    background: 'transparent',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(18),
    },
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(18),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(20),
    },
  },
  textWrapper: {
    position: 'absolute',
    right: 0,
    top: '10%',
    width: '100%',
    zIndex: 5,
    [theme.breakpoints.down(theme.breakpoints.values.xs + 100)]: {
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 100)]: {
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      top: '18%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '15%',
    },
  },
  truckAccessible: {
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  trustedPartnerWrapper: {
    maxWidth: '100vw',
    positon: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: `-${theme.spacing(10)}px`,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: `-${theme.spacing(30)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: `-${theme.spacing(40)}px`,
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: `-${theme.spacing(25)}px`,
    },
  },
  trustedPartners: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  whiteText: {
    color: theme.palette.common.white,
  },
}));
