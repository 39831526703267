import { Box, Button, Grid } from '@truxweb/ux';
import { buttonFontOverride, useStyles } from './ResponsiveEmailSignUp.styles';
import { Controller, useForm } from 'react-hook-form';
import React, { useCallback } from 'react';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TResponsiveEmailSignUpProps = {
  isLight: boolean;
  setModalEmail?: (arg: string) => void;
};

export const ResponsiveEmailSignUp = ({
  isLight,
  setModalEmail,
}: TResponsiveEmailSignUpProps): JSX.Element => {
  const classes = useStyles({ isLight });
  const labelStyle = buttonFontOverride();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { control, handleSubmit } = useForm();

  const onSubmitSuccess = useCallback(
    (data) => {
      setModalEmail(data.email);
    },
    [setModalEmail]
  );

  return (
    <Box className={classes.newsletterSignup}>
      <form onSubmit={handleSubmit(onSubmitSuccess)}>
        <Grid className={classes.wrapper} container direction="row">
          <Grid className={classes.emailContainer} item>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => {
                return (
                  <input
                    className={classes.emailInput}
                    id="email"
                    onChange={onChange}
                    placeholder={t('common:email')}
                    type="email"
                    value={value || ''}
                  />
                );
              }}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.submitButton}
              classes={labelStyle}
              color="primary"
              type="submit"
              variant="text"
            >
              {t('common:signUp')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
