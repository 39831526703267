// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotCompanyTypeV1 = void 0;
var HubspotCompanyTypeV1;
(function (HubspotCompanyTypeV1) {
    HubspotCompanyTypeV1["Shipper"] = "Shipper";
    HubspotCompanyTypeV1["Carrier"] = "Carrier";
})(HubspotCompanyTypeV1 || (exports.HubspotCompanyTypeV1 = HubspotCompanyTypeV1 = {}));
