"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = exports.useSelectStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useSelectStyles = (0, ux_1.makeStyles)(() => ({
    pickerComponent: {
        background: 'rgba(198, 231, 249, 0.6) !important',
        borderRadius: (vars) => {
            const { isSubselect, isSubselectActive } = vars;
            if (!isSubselect && isSubselectActive) {
                return `6.5px 0px 0px 6.5px !important`;
            }
            if (isSubselect && isSubselectActive) {
                return `0px 6.5px 6.5px 0px !important`;
            }
            return `6.5px 6.5px 6.5px 6.5px !important`;
        },
        height: '75px',
        marginTop: '20px',
        minWidth: '300px',
        textAlign: 'left',
        width: '420px',
    },
}));
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    icon: {
        marginRight: theme.spacing(1.5),
    },
    outlined: {
        background: theme.palette.primary.light,
        border: '1px solid black',
    },
    root: {
        backgroundColor: '#FFF !important',
        borderRadius: '8px !important',
        boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.15)',
        color: theme.palette.grey[700],
        justifyContent: 'center',
        lineHeight: '4px',
        margin: '0px 12px 0px 12px',
        minWidth: '300px',
        paddingRight: '12px',
        transition: 'none',
    },
}));
