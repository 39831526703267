import { Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { Card } from '..';
import React from 'react';
import { useStyles } from './LandingPageInfoCard.styles';

type TLandingPageInfoCardProps = {
  content: React.ReactNode;
  icon: JSX.Element;
  link: string;
};

export const LandingPageInfoCard = ({
  content,
  icon,
  link,
}: TLandingPageInfoCardProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Card className={classes.container}>
      <a className={classes.link} href={link}>
        <Grid
          alignItems="center"
          className={classes.gridWrapper}
          container
          direction="column"
          justifyContent="center"
        >
          <Grid item>{icon}</Grid>
          <Typography align="center" className={classes.text} variant={isTablet ? 'body1' : 'h6'}>
            {content}
          </Typography>
        </Grid>
      </a>
    </Card>
  );
};
