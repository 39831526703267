/* eslint-disable complexity */
import {
  ArrowForwardIcon,
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@truxweb/ux';
import classnames from 'classnames';
import { EUserTypeV1 } from '@truxweb/schemas';
import { PageLink } from '..';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './OnboardingTile.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TOnboardingTileProps = {
  pageId: TPageId;
  tileIcon: JSX.Element;
  userType: EUserTypeV1;
};

export const OnboardingTile = ({
  pageId,
  tileIcon,
  userType,
}: TOnboardingTileProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const isVerticalTablet = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.sm, theme.breakpoints.values.md)
  );
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.largeMobile));

  return (
    <Card className={classnames(classes.card, classes.primaryTile)} variant="outlined">
      <Box
        pb={isMobile && userType === EUserTypeV1.CARRIER ? 3 : 0}
        pr={!isMobile && 2}
        pt={isMobile && userType === EUserTypeV1.SHIPPER ? 3 : 0}
        width={'100%'}
      >
        <PageLink pageId={pageId}>
          <Grid
            alignItems="center"
            container
            direction={
              isMobile ? (userType === EUserTypeV1.SHIPPER ? 'column-reverse' : 'column') : 'row'
            }
            justifyContent="center"
          >
            <Grid item xs={isVerticalTablet ? 4 : isMobile ? 8 : 3}>
              <Box>{tileIcon}</Box>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-between"
              xs={isVerticalTablet ? 8 : isMobile ? 10 : 9}
            >
              <Grid item>
                <Typography className={classes.tileFont} fontStyle="semibold" variant="bodyLarge">
                  {userType === EUserTypeV1.CARRIER ? t('common:carrier') : t('common:shipper')}
                </Typography>
              </Grid>
              <Grid className={classes.itemHeight} item>
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </Grid>
        </PageLink>
      </Box>
    </Card>
  );
};
