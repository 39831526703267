"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCakeBirthdayIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,22H22V14a5.006,5.006,0,0,0-5-5H13V6.039A2.5,2.5,0,0,0,14.5,3.75,7.293,7.293,0,0,0,12.738.327a1,1,0,0,0-1.476,0A7.293,7.293,0,0,0,9.5,3.75,2.5,2.5,0,0,0,11,6.039V9H7a5.006,5.006,0,0,0-5,5v8H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2ZM7,11H17a3,3,0,0,1,3,3v1.98c-.936-.1-1.5-.7-1.5-.98a1,1,0,0,0-2,0c0,.343-.682,1-1.75,1C13.661,16,13,15.306,13,15a1,1,0,0,0-2,0c0,.343-.682,1-1.75,1-1.089,0-1.75-.694-1.75-1a1,1,0,0,0-2,0c0,.315-.579.888-1.5.981V14A3,3,0,0,1,7,11ZM4,17.979A4.156,4.156,0,0,0,6.5,17a4.309,4.309,0,0,0,5.5.015A4.309,4.309,0,0,0,17.5,17a4.156,4.156,0,0,0,2.5.978V22H4Z" })));
exports.default = TruxwebCakeBirthdayIcon;
