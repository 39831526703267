"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    card: {
        borderRadius: theme.spacing(2),
        boxShadow: 'unset',
        padding: `${theme.spacing(4)}px`,
        position: 'relative',
        width: '100%',
    },
}));
