"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadParametersList = void 0;
const utils_1 = require("@truxweb/utils");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const tableColumns_1 = require("../../tableColumns");
const quote_utils_1 = require("@truxweb/quote-utils");
const LoadParametersList_styles_1 = require("./LoadParametersList.styles");
const LoadParametersList = ({ availableTags, columnKeys, data, defaultSort, excludeColumnKeys, handleEditLoad, handleSearchWithLoad, isActionsOverlayEnabled, isExportEnabled, isLoading, language, lastLoadedDate, pageSize, renderActionsContent, rowCount, selectedRows, t, }) => {
    const classes = (0, LoadParametersList_styles_1.useStyles)();
    const basicColumnDef = {
        classes,
        fallbackText: '',
        t,
    };
    const [loadingSearchId, setIsLoadingSearch] = (0, react_1.useState)(null);
    //Column definitions
    const columns = [
        {
            align: 'left',
            field: 'id',
            headerClassName: 'datagrid-header',
            headerName: '',
            renderCell: ({ row }) => {
                return (react_1.default.createElement(ux_1.Grid, { container: true },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.DataGridActionIconButton, { isActive: true, onClick: () => {
                                handleSearchWithLoad(row._source);
                                setIsLoadingSearch(row.id);
                            }, testId: "LoadParametersList-UseLoad" }, loadingSearchId === row.id ? (react_1.default.createElement(ux_1.CircularProgress, { size: 16 })) : (react_1.default.createElement(ux_1.TruxwebSearchAltIcon, null)))),
                    handleEditLoad !== undefined && (react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.DataGridActionIconButton, { isActive: true, onClick: () => handleEditLoad(row._source), testId: "LoadParametersList-EditLoad" },
                            react_1.default.createElement(ux_1.TruxwebEditIcon, null))))));
            },
            renderHeader: () => {
                return ' ';
            },
            width: 160,
        },
        {
            align: 'center',
            field: 'name',
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:name`),
            renderCell: ({ row }) => {
                return react_1.default.createElement(ux_1.DataGridColumnText, { text: row.name });
            },
            width: 200,
        },
        {
            align: 'center',
            field: 'shipmentType',
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:shipmentType`),
            renderCell: ({ value }) => {
                return react_1.default.createElement(ux_1.DataGridColumnText, { text: value });
            },
            width: 100,
        },
        {
            align: 'center',
            field: 'origin',
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:origin`),
            renderCell: ({ value }) => {
                if (!value)
                    return t('common:na');
                return react_1.default.createElement(ux_1.DataGridColumnText, { text: (0, utils_1.addressToDisplayString)(value) });
            },
            width: 400,
        },
        {
            align: 'center',
            field: 'destination',
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:destination`),
            renderCell: ({ value }) => {
                if (!value)
                    return t('common:na');
                return react_1.default.createElement(ux_1.DataGridColumnText, { text: (0, utils_1.addressToDisplayString)(value) });
            },
            width: 400,
        },
        (0, tableColumns_1.shipmentEquipmentColumn)(basicColumnDef),
        (0, tableColumns_1.shipmentAccessorialsColumn)(basicColumnDef),
        {
            align: 'center',
            field: 'dateCreated',
            headerAlign: 'center',
            headerClassName: 'datagrid-header',
            headerName: t(`common:dateCreated`),
            renderCell: ({ value }) => {
                return (react_1.default.createElement(ux_1.DataGridColumnText, { text: value ? (0, utils_1.formatLocalizedDate)(value, language) : '' }));
            },
            type: 'dateTime',
            width: 100,
        },
        (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags, field: 'tags', width: 350 })),
    ];
    //Filter the Column definition to display only the columns that are needed
    const displayCols = columns.filter((col) => {
        if (!columnKeys && !excludeColumnKeys)
            return true;
        const shouldInclude = !columnKeys ? true : columnKeys.includes(col.field);
        const shouldExclude = !excludeColumnKeys ? false : excludeColumnKeys.includes(col.field);
        return shouldInclude && !shouldExclude;
    });
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { areFiltersEnabled: true, isExportEnabled: isExportEnabled, isHeaderShown: true, isLoading: false, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, t: t }));
    };
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, data: data || [], defaultSort: defaultSort, gridHeight: ((pageSize || 10) + 1.35) * 115, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isActionsOverlayEnabled: isActionsOverlayEnabled, isLoading: isLoading, pageSize: pageSize || 10, renderActionsContent: renderActionsContent, rowHeight: 115, selectionModel: selectedRows, shouldDisableMultipleSelection: true, t: t, toolbar: toolbar }));
};
exports.LoadParametersList = LoadParametersList;
