import { makeRequestToApiGateway } from '../../apiUtils';
import { THubspotContactV1 } from '@truxweb/schemas';

export const submitNewsletterSignup = async (data: THubspotContactV1): Promise<any> => {
  return await makeRequestToApiGateway(
    'hubspotPostV1FormByFormType',
    { formType: 'newsletter' },
    {
      'Content-Type': 'application/json',
    },
    data
  );
};
