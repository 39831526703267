import { Grid } from '@truxweb/ux';
import React from 'react';
import { useStyles } from './SocialMediaIconCircle.styles';

type TSocialMediaIconCircleProps = {
  icon: JSX.Element;
  onClick?: () => void;
};

export const SocialMediaIconCircle = ({
  icon,
  onClick,
}: TSocialMediaIconCircleProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid
      alignItems="center"
      className={classes.container}
      container
      justifyContent="center"
      onClick={onClick}
    >
      {icon}
    </Grid>
  );
};
