import { type AppThunk } from '../../../stores';
import { Auth } from 'aws-amplify';
import { getAuth } from '.';
import { TCognitoUserData } from '../../../types';

export const reloadUserData = (): AppThunk => async (dispatch) => {
  const user: { attributes: TCognitoUserData } = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  if (user) {
    dispatch(getAuth(user.attributes, true));
  }
};
