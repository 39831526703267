import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import classnames from 'classnames';
import React from 'react';
import { TestimonialCard } from '..';
import { useLandingPageMarginStyles } from '../../styles';
import { useStyles } from './Testimonials.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const Testimonials = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const margins = useLandingPageMarginStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallTablet = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid
        className={classes.container}
        container
        direction={isMobile ? 'column' : 'row'}
        justifyContent="center"
      >
        <Grid
          alignItems={isMobile ? 'center' : 'flex-start'}
          className={classnames(classes.textContainer, margins.landingPageLeftMargins)}
          container
          direction="column"
          item
          justifyContent="center"
          xs={isMobile ? 12 : 5}
        >
          <Box className={classes.textWrap} mb={4}>
            <Typography
              align={isMobile ? 'center' : 'left'}
              color="primary"
              variant={isSmallTablet ? 'h3' : 'h2'}
            >
              {t('common:ourClients')}
            </Typography>
          </Box>
          <Typography
            align={isMobile ? 'center' : 'left'}
            className={classes.textWrap}
            variant={'body2'}
          >
            {t('common:thanksToTruxweb')}
          </Typography>
        </Grid>
        <Grid className={classes.bgContainer} container item justifyContent={'flex-end'} xs>
          <Box className={classes.testimonialWrapper} justifyContent={'center'}>
            <Box className={classes.testimonialEric}>
              <TestimonialCard
                avatarSrc={'/assets/images/landingPage/landing-testimonial-eric.png'}
                name="Eric"
                numStars={5}
                text={t('common:testimonialEric')}
              />
            </Box>
            <Box className={classes.testimonialMary}>
              <TestimonialCard
                avatarSrc={'/assets/images/landingPage/landing-testimonial-mary.png'}
                name="Mary"
                numStars={5}
                text={t('common:testimonialMary')}
              />
            </Box>
            <Box className={classes.testimonialWesley}>
              <TestimonialCard
                avatarSrc={'/assets/images/landingPage/landing-testimonial-wesley.png'}
                name="Wesley"
                numStars={5}
                text={t('common:testimonialWesley')}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
