// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAcctTypeV1 = exports.CountryV1 = void 0;
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var BankAcctTypeV1;
(function (BankAcctTypeV1) {
    BankAcctTypeV1["Canadian"] = "Canadian";
    BankAcctTypeV1["USCorporateChequing"] = "USCorporateChequing";
    BankAcctTypeV1["USCorporateSavings"] = "USCorporateSavings";
    BankAcctTypeV1["USPersonalChequing"] = "USPersonalChequing";
    BankAcctTypeV1["USPersonalSavings"] = "USPersonalSavings";
})(BankAcctTypeV1 || (exports.BankAcctTypeV1 = BankAcctTypeV1 = {}));
