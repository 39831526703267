// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationMessageTypeV1 = void 0;
var NotificationMessageTypeV1;
(function (NotificationMessageTypeV1) {
    NotificationMessageTypeV1["SHIPMENT_DELIVERY_DATE"] = "SHIPMENT_DELIVERY_DATE";
    NotificationMessageTypeV1["SHIPMENT_PICKUP_DATE"] = "SHIPMENT_PICKUP_DATE";
    NotificationMessageTypeV1["SHIPMENT_CREATED"] = "SHIPMENT_CREATED";
    NotificationMessageTypeV1["SHIPMENT_STATUS_UPDATE"] = "SHIPMENT_STATUS_UPDATE";
    NotificationMessageTypeV1["SHIPMENT_COMPLETE"] = "SHIPMENT_COMPLETE";
    NotificationMessageTypeV1["SHIPMENT_TRACKING_DETAILS"] = "SHIPMENT_TRACKING_DETAILS";
})(NotificationMessageTypeV1 || (exports.NotificationMessageTypeV1 = NotificationMessageTypeV1 = {}));
