"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSteakIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M17.5,24a5.772,5.772,0,0,1-4.719-2.238A4.341,4.341,0,0,0,9,20c-4.794,0-9-4.673-9-10A10.011,10.011,0,0,1,10,0c9.772,0,14,11.588,14,17.5A6.508,6.508,0,0,1,17.5,24ZM10,2C-.468,2.379-.38,17.105,9,18a6.3,6.3,0,0,1,5.242,2.4C16.578,23.651,22.1,21.573,22,17.5,22,12.938,18.547,2,10,2ZM6.707,13.707l7-7a1,1,0,0,0-1.414-1.414l-7,7A1,1,0,0,0,6.707,13.707Zm4,2,6-6a1,1,0,0,0-1.414-1.414l-6,6A1,1,0,0,0,10.707,15.707Zm4,2,4-4a1,1,0,0,0-1.414-1.414l-4,4A1,1,0,0,0,14.707,17.707Zm4,2,1-1a1,1,0,0,0-1.414-1.414l-1,1A1,1,0,0,0,18.707,19.707Zm-13-11,3-3A1,1,0,0,0,7.293,4.293l-3,3A1,1,0,0,0,5.707,8.707Z" })));
exports.default = TruxwebSteakIcon;
