import {
  EBankAcctTypeV1,
  EStatusGenericV1,
  TAddBankAccountRequestV2,
  TUserV1,
} from '@truxweb/schemas';
import { addBankAccountV2 } from '..';
import { addressFromFormData } from '@truxweb/utils';

type TProofOfOwnership = {
  proofOfOwnershipUrl: string;
  proofOfOwnershipName: string;
};

type TAddBankAccountToUserProfileV2 = {
  data: Record<string, any>;
  userData: TUserV1;
  proofOfOwnership: TProofOfOwnership;
  status?: EStatusGenericV1;
};

export const addBankAccountToUserProfileV2 = async (
  request: TAddBankAccountToUserProfileV2
): Promise<void> => {
  const { data, proofOfOwnership, status, userData } = request;

  const { proofOfOwnershipName, proofOfOwnershipUrl } = proofOfOwnership;
  const address = addressFromFormData(data, userData);
  // We need to overwrite the name of the address
  // as this form... is yet again a snowflake
  address.name = data.bankName;

  let addBankAccountRequest: TAddBankAccountRequestV2 | null;
  switch (data.accountType) {
    case EBankAcctTypeV1.Canadian:
      addBankAccountRequest = {
        accountHolder: data.accountHolderName,
        accountNumber: data.accountNumber,
        accountType: data.accountType,
        address,
        branch: data.branchNumber,
        companyName: userData.companyName,
        institution: data.institutionNumber,
        isDefault: true,
        proofOfOwnershipName,
        proofOfOwnershipUrl,
        status: status || EStatusGenericV1.ACTIVE,
        userEmail: userData.email,
      };
      break;
    default:
      addBankAccountRequest = {
        accountHolder: data.accountHolderName,
        accountNumber: data.accountNumber,
        accountType: data.accountType,
        address,
        companyName: userData.companyName,
        isDefault: true,
        proofOfOwnershipName,
        proofOfOwnershipUrl,
        routing: data.routingNumber,
        status: status || EStatusGenericV1.ACTIVE,
        userEmail: userData.email,
      };
      break;
  }
  await addBankAccountV2(addBankAccountRequest);
};
