import { MobileShipmentCarrierList, ShipmentCarrierList } from '..';
import { useMediaQuery, useTheme } from '@truxweb/ux';
import React from 'react';
import { TPageId } from '../../types';

type TShipmentCarrierListWrapperProps = {
  pageId: TPageId;
};

export const ShipmentCarrierListWrapper = ({
  pageId,
}: TShipmentCarrierListWrapperProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  if (isMobile) {
    return <MobileShipmentCarrierList pageId={pageId} />;
  }
  return <ShipmentCarrierList pageId={pageId} />;
};
