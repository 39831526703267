"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    address: {
        marginBottom: theme.spacing(1),
    },
    button: {
        '&:hover': {
            color: theme.palette.primary.light,
            fill: theme.palette.primary.light,
        },
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
    },
    header: {
        color: theme.palette.grey[500],
        minWidth: 'max-content',
        width: theme.spacing(12),
    },
    icon: {
        fill: theme.palette.primary.light,
        height: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
    },
    locationTable: {
        width: '100%',
    },
    referenceNumber: {
        borderRadius: '8px',
        height: '40px',
    },
    tableHeader: {
        height: 'inherit',
        width: '96px',
    },
    tableRow: {
        height: '21px',
    },
    tableValueInner: {
        height: 'inherit',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    tableValueOuter: {
        height: '21px',
        position: 'relative',
    },
    value: {
        color: theme.palette.primary.light,
        // maxWidth: theme.spacing(22),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
    },
    wrapper: ({ isInEditMode }) => {
        if (!isInEditMode)
            return { padding: theme.spacing(1) };
        return {
            background: '#ececec',
            border: `2px solid ${theme.palette.primary.light}`,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(1),
        };
    },
}));
