"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCallHistoryIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M17.762,24C9.631,24.275-5.017,9.745,1.784,1.867l1.15-1A3.08,3.08,0,0,1,7.26.911a1,1,0,0,1,.086.1l1.8,2.339A3.108,3.108,0,0,1,9.136,7.63L7.978,9.086a12.789,12.789,0,0,0,6.931,6.946l1.464-1.165a3.17,3.17,0,0,1,4.282-.006l2.34,1.8a1.126,1.126,0,0,1,.1.085,3.11,3.11,0,0,1,0,4.378l-.911,1.05A6.154,6.154,0,0,1,17.762,24Zm-12.691-22a1.094,1.094,0,0,0-.774.32l-1.151,1C-2.5,10.108,14.777,26.423,20.72,20.808l.912-1.051a1.1,1.1,0,0,0,.085-1.559L19.39,16.411a.811.811,0,0,1-.1-.087,1.122,1.122,0,0,0-1.548,0,1.01,1.01,0,0,1-.084.076L15.7,17.96a1,1,0,0,1-.979.152A15.011,15.011,0,0,1,5.9,9.3a1,1,0,0,1,.146-1L7.6,6.344a.849.849,0,0,1,.075-.084,1.1,1.1,0,0,0,0-1.549.866.866,0,0,1-.086-.1L5.806,2.287A1.093,1.093,0,0,0,5.071,2.005ZM17.036,15.618h0Zm5.528-3.042c4.99-7.345-3.8-16.126-11.137-11.137a1,1,0,1,0,1.144,1.64c5.474-3.759,12.11,2.884,8.353,8.354a1,1,0,1,0,1.64,1.143Zm-3.858-1.865a1,1,0,0,0,0-1.414L17,7.59V5a1,1,0,0,0-2,0V8a1,1,0,0,0,.293.707l2,2a1,1,0,0,0,1.414,0Z" })));
exports.default = TruxwebCallHistoryIcon;
