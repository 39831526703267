import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  accent: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },

  freight: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  overlay: {
    background:
      'url(/assets/images/landingPage/header_bg_fullheight-transparent.png) top no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'cover',
    filter: 'grayscale(100%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  subtitle: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(34),
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(38),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(55),
    },
  },
  text: {
    display: 'flex',
    height: '100%',
    left: '10%',
    position: 'absolute',
    top: '20%',
    width: '80%',
    zIndex: 5,
  },
  truck: {
    alignItems: 'flex-end',
    display: 'flex',
    height: '101%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      top: 20,
    },
    [theme.breakpoints.down('xs')]: {
      right: '-50px',
      width: '130%',
    },
  },
  truckTruxi: {
    width: '100%',
  },
  wrapper: {
    background: theme.palette.primary.dark,
    backgroundSize: 'cover',
    paddingTop: '6em',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      minHeight: theme.spacing(62),
      minWidth: theme.breakpoints.values.xs,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: theme.spacing(65),
      minWidth: theme.breakpoints.values.sm,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(78),
      minWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(114),
      minWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(145),
      minWidth: theme.breakpoints.values.xl,
    },
  },
}));
