// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAcctTransactionTypeV1 = exports.BankAcctTransferTypeV1 = exports.PaymentTransactionStatusV1 = void 0;
var PaymentTransactionStatusV1;
(function (PaymentTransactionStatusV1) {
    PaymentTransactionStatusV1["Failed"] = "Failed";
    PaymentTransactionStatusV1["Success"] = "Success";
    PaymentTransactionStatusV1["Pending"] = "Pending";
    PaymentTransactionStatusV1["Hold"] = "Hold";
    PaymentTransactionStatusV1["Cancelled"] = "Cancelled";
})(PaymentTransactionStatusV1 || (exports.PaymentTransactionStatusV1 = PaymentTransactionStatusV1 = {}));
var BankAcctTransferTypeV1;
(function (BankAcctTransferTypeV1) {
    BankAcctTransferTypeV1["Credit"] = "Credit";
    BankAcctTransferTypeV1["Debit"] = "Debit";
})(BankAcctTransferTypeV1 || (exports.BankAcctTransferTypeV1 = BankAcctTransferTypeV1 = {}));
var BankAcctTransactionTypeV1;
(function (BankAcctTransactionTypeV1) {
    BankAcctTransactionTypeV1["ACH"] = "ACH";
    BankAcctTransactionTypeV1["EFT"] = "EFT";
})(BankAcctTransactionTypeV1 || (exports.BankAcctTransactionTypeV1 = BankAcctTransactionTypeV1 = {}));
