// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDisplayStatusV1 = void 0;
var ShipmentDisplayStatusV1;
(function (ShipmentDisplayStatusV1) {
    ShipmentDisplayStatusV1["PICKUP_READY"] = "PICKUP_READY";
    ShipmentDisplayStatusV1["DELIVERED"] = "DELIVERED";
    ShipmentDisplayStatusV1["CANCELLED"] = "CANCELLED";
    ShipmentDisplayStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentDisplayStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentDisplayStatusV1["PENDING_PAYMENT"] = "PENDING_PAYMENT";
})(ShipmentDisplayStatusV1 || (exports.ShipmentDisplayStatusV1 = ShipmentDisplayStatusV1 = {}));
