"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarBatteryIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10,12a1,1,0,0,1-1,1H5a1,1,0,0,1,0-2H9A1,1,0,0,1,10,12Zm9-1H18V10a1,1,0,0,0-2,0v1H15a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0V13h1A1,1,0,0,0,19,11Zm5-2v8a5.006,5.006,0,0,1-5,5H5a5.006,5.006,0,0,1-5-5V9A4.006,4.006,0,0,1,3,5.127V4A2,2,0,0,1,5,2H9a2,2,0,0,1,2,2V5h2V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V5.127A4.006,4.006,0,0,1,24,9ZM22,9a2,2,0,0,0-2-2,1,1,0,0,1-1-1V4H15V6a1,1,0,0,1-1,1H10A1,1,0,0,1,9,6V4H5V6A1,1,0,0,1,4,7,2,2,0,0,0,2,9v8a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3Z" })));
exports.default = TruxwebCarBatteryIcon;
