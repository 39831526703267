"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '20px 20px',
        marginBottom: `${theme.spacing(3)}px`,
        marginLeft: `${theme.spacing(10)}px`,
        maxWidth: `${theme.spacing(125)}px`,
        minWidth: `65vw`,
        padding: '48px 24px',
    },
    title: {
        color: theme.palette.primary.light,
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(3),
    },
    wrapper: {
        background: theme.palette.grey[100],
        minWidth: `${theme.spacing(125)}px`,
        width: ({ isLeftTextMenuOpen }) => (!isLeftTextMenuOpen ? `100vw` : `calc(100vw - 412px)`),
    },
}));
