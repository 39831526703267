"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDateStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    title: {
        lineHeight: theme.spacing(2),
    },
}));
exports.useDateStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        background: theme.palette.common.white,
    },
}));
