"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSkiLiftIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.733,12.654a3.971,3.971,0,0,1-2.108,2.22l-19.2,9.031a1,1,0,0,1-.852-1.81l12.514-5.886L12.813,13.54l-2.62,1.191a3,3,0,0,1-3.9-1.35L4.918,10a2.965,2.965,0,0,1,5.307-2.645l1.131,2.518a1,1,0,0,1-1.824.819L8.425,8.224A.944.944,0,0,0,7.88,7.8a.959.959,0,0,0-.74.068.969.969,0,0,0-.407,1.3L8.1,12.546a.992.992,0,0,0,1.262.364l2.619-1.191a2.009,2.009,0,0,1,2.633.96l1.28,2.679,4.895-2.3a2,2,0,0,0,1.034-2.649,1,1,0,1,1,1.826-.814A3.973,3.973,0,0,1,23.733,12.654ZM2.738,15.142a7,7,0,0,0,3.709,3.317,1,1,0,1,0,.719-1.866A4.991,4.991,0,0,1,4.532,14.26L1.924,8a1,1,0,0,0-1.848.767ZM14,10a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V9A1,1,0,0,0,14,10ZM5.5,5a2.5,2.5,0,0,0,0-5A2.5,2.5,0,0,0,5.5,5Z" })));
exports.default = TruxwebSkiLiftIcon;
