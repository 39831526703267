import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#E4F5FF',
    },
  },
  verifiedIcon: {
    color: theme.palette.success.main,
  },
}));
