import { addBankAccountToUserProfileV2, addCompanyPaymentTerms } from '../../actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useActionController, useAlerts, useBankAccounts, useUserData } from '../../hooks';
import { CarrierBankAccount as BaseCarrierBankAccount } from '@truxweb/common-components';
import { CARRIER_ONBOARDING_BUCKET } from '../../config';
import { TS3Response } from '../../types';
import { uploadFileToBucket } from '../../apiUtils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const CarrierBankAccount = (): JSX.Element => {
  const { addAlert } = useAlerts();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = useForm();
  const [shouldPaymentOptionsRefetch, setShouldPaymentOptionsRefetch] = useState(false);
  const { userData } = useUserData();
  const [bankAccounts] = useBankAccounts();

  useEffect(() => {
    if (shouldPaymentOptionsRefetch) {
      setShouldPaymentOptionsRefetch(false);
    }
  }, [shouldPaymentOptionsRefetch, setShouldPaymentOptionsRefetch]);

  const onBankAccountSubmit = useCallback(
    async (data: Record<string, any>): Promise<void> => {
      const { proofOfOwnership, ...bankAccountData } = data;
      const addBankAccountAction = addBankAccountToUserProfileV2;
      const addPaymentTermsAction = addCompanyPaymentTerms;
      await addBankAccountAction({
        data: bankAccountData,
        proofOfOwnership: {
          proofOfOwnershipName: proofOfOwnership?.localName,
          proofOfOwnershipUrl: `public/${proofOfOwnership?.remoteName}`,
        },
        userData,
      });

      await addPaymentTermsAction();
    },
    [userData]
  );

  const { action: handleBankAccountSubmit, isLoading: isSaving } = useActionController({
    action: onBankAccountSubmit,
    successMessage: t('account:bankAccountAdded'),
  });

  const handleUploadSuccess = useCallback(() => {
    addAlert({ message: t('common:uploadSuccess'), severity: 'success' });
  }, [t, addAlert]);

  const handleUploadFailure = useCallback(
    (message?: string) => {
      addAlert({ message: message || t('common:uploadFailed'), severity: 'error' });
    },
    [t, addAlert]
  );

  const handleUpload = useCallback(
    async (postFileName: string, file: File): Promise<TS3Response> => {
      return await uploadFileToBucket(
        postFileName,
        file,
        CARRIER_ONBOARDING_BUCKET,
        'application/pdf'
      );
    },
    []
  );

  const handleCancel = useCallback(() => {
    setShouldPaymentOptionsRefetch(true);
    reset();
  }, [setShouldPaymentOptionsRefetch, reset]);
  return (
    <BaseCarrierBankAccount
      bankAccounts={bankAccounts || []}
      control={control}
      handleBankAccountSubmit={handleBankAccountSubmit}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      handleUpload={handleUpload}
      handleUploadFailure={handleUploadFailure}
      handleUploadSuccess={handleUploadSuccess}
      isDirty={isDirty}
      isSaving={isSaving}
      setError={setError}
      setValue={setValue}
      t={t}
      user={userData}
      watch={watch}
    />
  );
};
