import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { hasWindow } from '../../utils';
import { LandingPageButton } from '..';
import React from 'react';
import { useRouter } from 'next/router';
import { useStyles } from './LandingPageBodyTextContent.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TLandingPageBodyText = {
  title: string;
  subtitle: string;
  p1: string;
  p2: string;
  ctaEnabled: boolean;
};
type TLandingPageBodyTextContentProps = {
  content?: TLandingPageBodyText;
};
export const LandingPageBodyTextContent = ({
  content,
}: TLandingPageBodyTextContentProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  content = content || {
    ctaEnabled: true,
    p1: isSmallTablet
      ? t('common:landingBody-shippersCanNow')
      : t('common:landingBody-aPlatformThatBringsTogether'),
    p2: t('common:landingBody-inAdditionToAutomated'),
    subtitle: isSmallTablet
      ? t('common:landingBody-greatForCompaniesText-mobile')
      : t('common:landingBody-greatForCompaniesText'),
    title: isSmallTablet
      ? t('common:landingBody-smallTitle-aPlatformThatBringsTogether')
      : t('common:landingBody-leadingTheTransformation'),
  };

  const onClick = () => {
    if (hasWindow()) {
      router.push('/contact-us');
    }
  };
  return (
    <Grid
      alignItems="flex-start"
      className={classes.container}
      container
      direction="column"
      item
      xs
    >
      <Box mt={isSmallTablet ? 0 : 6}>
        <Typography className={classes.smallHeader} variant="h5">
          {content.title}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography className={classes.title} color="primary" variant="h2">
          {content.subtitle}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2">{content.p1}</Typography>
      </Box>
      {!isSmallTablet && (
        <Box mt={2}>
          <Typography variant="body2">{content.p2}</Typography>
        </Box>
      )}
      {content.ctaEnabled && (
        <Box mb={8} mt={isMobile ? 3 : 5}>
          <LandingPageButton
            onClick={onClick}
            text={t('common:speakWithOurTeam')}
            type="standard"
          />
        </Box>
      )}
    </Grid>
  );
};
