import { addAlert, type AppThunk, setShipmentDetailsError } from '../../../stores';
import { EUserTypeV1, type TShipmentLocationV1 } from '@truxweb/schemas';
import { getErrorMessage } from '@truxweb/errors';
import { getShipmentByRef } from '..';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { type TFunction } from 'next-i18next';

export const updateShipperShipmentLocation =
  (shipmentRef: string, location: TShipmentLocationV1, property: string, t: TFunction): AppThunk =>
  async (dispatch) => {
    try {
      await makeRequestToApiGateway(
        'shipmentPatchV1ShipperShipmentByShipmentRefLocation',
        {
          shipmentRef,
        },
        {
          'Content-Type': 'application/json',
        },
        {
          location,
        }
      );
      dispatch(
        addAlert({
          message: t('common:propertyUpdate', { property }),
          severity: 'success',
        })
      );
      dispatch(getShipmentByRef(EUserTypeV1.SHIPPER, shipmentRef));
    } catch (error) {
      const errorAction = setShipmentDetailsError({
        error: error.message,
        shipmentRefs: [shipmentRef],
      });
      dispatch(errorAction);
      const errorMessage = getErrorMessage(error, t);
      dispatch(
        addAlert({
          message: errorMessage,
          severity: 'error',
        })
      );
    }
  };
