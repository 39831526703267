import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierLaneV1 } from '@truxweb/schemas';

export const updateLanes = async (lanes: TCarrierLaneV1[]): Promise<TCarrierLaneV1[]> => {
  const results = await makeRequestToApiGateway(
    'carrierPatchV1LaneCarrier',
    null,
    {
      'Content-Type': 'application/json',
    },
    lanes
  );
  return results as TCarrierLaneV1[];
};
