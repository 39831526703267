"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    cardHeader: {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '15px',
    },
    cardInputContainer: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: theme.spacing(0.5),
        borderStyle: 'solid',
        borderWidth: '1px',
        height: '14',
        marginBottom: theme.spacing(1.5),
        padding: '20px 20px 12px 20px',
    },
    text: {
        color: theme.palette.grey[600],
    },
}));
