import React from 'react';
import { Typography } from '@truxweb/ux';

type TInteriorHeaderProps = {
  className?: string;
  children: React.ReactNode;
};
export const InteriorHeader = ({ children, className }: TInteriorHeaderProps): JSX.Element => {
  return (
    <Typography className={className} variant="h2">
      {children}
    </Typography>
  );
};
