"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDialogStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    bolded: {
        fontWeight: 600,
    },
    cardContents: {
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: theme.spacing(3),
    },
    externalLinkCancel: {
        color: theme.palette.grey[600],
        fontSize: theme.spacing(2),
        textDecoration: 'underline',
    },
    externalLinkConfirm: {
        color: theme.palette.success.light,
        fontSize: theme.spacing(4),
    },
    itemBox: {
        background: theme.palette.common.white,
        borderRadius: '8px',
        color: theme.palette.grey[600],
        height: '40px',
    },
    referenceNumber: {
        borderRadius: '8px',
        height: '40px',
    },
    trackingBox: {
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    trackingButton: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
    },
    trackingCardHeader: {
        width: '100%',
    },
    trackingCardSmallHeader: {
        fontSize: 10,
    },
    viewCSPProgress: {
        background: theme.palette.grey[100],
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(1),
    },
}));
exports.useDialogStyles = (0, ux_1.makeStyles)((theme) => ({
    paper: {
        background: theme.palette.primary.light,
        borderRadius: theme.spacing(2),
        color: theme.palette.common.white,
        padding: theme.spacing(6),
    },
}));
