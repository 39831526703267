"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    modified: {
        color: ({ hasBeenModified, hasError }) => hasError
            ? theme.palette.error.main
            : hasBeenModified
                ? theme.palette.warning.light
                : theme.palette.primary.light,
        fontSize: '13.5px',
        fontWeight: 600,
    },
    original: {
        color: ({ hasBeenModified, hasError }) => hasError
            ? theme.palette.error.main
            : hasBeenModified
                ? theme.palette.grey[600]
                : theme.palette.primary.light,
        fontSize: ({ hasBeenModified }) => (hasBeenModified ? '12px' : '13.5px'),
        fontWeight: ({ hasBeenModified }) => (hasBeenModified ? 'normal' : 600),
        textDecoration: ({ hasBeenModified }) => (hasBeenModified ? 'line-through' : 'none'),
    },
}));
