"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDiceD10Icon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,12.916a5.059,5.059,0,0,0-1.048-3.24L15.1,1.469a4,4,0,0,0-6.19-.007L2.024,9.708A4.976,4.976,0,0,0,1,12.918a5.245,5.245,0,0,0,1.374,3.268l6.754,6.595A3.966,3.966,0,0,0,12.006,24h0a3.951,3.951,0,0,0,2.853-1.2l6.794-6.65A5.155,5.155,0,0,0,23,12.916Zm-2.61-1.993a3.024,3.024,0,0,1,.462.871l-4.108-.684L13.814,3.045Zm-8.38,2.827L9.194,11.644l2.812-7.717,2.8,7.718ZM10.2,3.045,7.26,11.11,3.144,11.8a2.873,2.873,0,0,1,.443-.84ZM3.808,14.787a3.019,3.019,0,0,1-.611-.973l4.546-.757L11.008,15.5v6.23a2.116,2.116,0,0,1-.459-.361Zm9.637,6.6a2.022,2.022,0,0,1-.437.343V15.5l3.25-2.443,4.552.758a2.907,2.907,0,0,1-.583.938Z" })));
exports.default = TruxwebDiceD10Icon;
