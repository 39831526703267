"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebStopwatchIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m14 13a2 2 0 1 1 -3-1.723v-4.277a1 1 0 0 1 2 0v4.277a1.994 1.994 0 0 1 1 1.723zm6.06-7.461a11 11 0 1 1 -16.12 0l-.672-.747a3.672 3.672 0 0 1 -1.268.208 1 1 0 0 1 0-2c.785 0 1-.215 1-1a1 1 0 0 1 2 0 3.513 3.513 0 0 1 -.28 1.417l.706.784a10.923 10.923 0 0 1 5.574-2.15v-1.051a1 1 0 0 1 2 0v1.051a10.923 10.923 0 0 1 5.574 2.149l.706-.784a3.513 3.513 0 0 1 -.28-1.416 1 1 0 0 1 2 0c0 .785.215 1 1 1a1 1 0 0 1 0 2 3.672 3.672 0 0 1 -1.268-.208zm.94 7.461a9 9 0 1 0 -9 9 9.01 9.01 0 0 0 9-9z" })));
exports.default = TruxwebStopwatchIcon;
