import { Box, Grid, Typography } from '@truxweb/ux';
import { LanguageSwitcher, PageLink } from '..';
import { getPageIdFromRoute } from '../../utils';
import React from 'react';
import { useRouter } from 'next/router';
import { useStyles } from './FooterLegal.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TFooterLegalProps = {
  linkClass?: string;
  legalClass?: string;
};

export const FooterLegal = ({ legalClass, linkClass }: TFooterLegalProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const legalYear = new Date().getFullYear();
  const router = useRouter();
  const { locale, pathname } = router;
  const pageId = getPageIdFromRoute(pathname, locale);
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justifyContent="center"
      style={{ height: '100%', position: 'relative' }}
    >
      <Typography
        className={legalClass}
        style={{ left: 40, position: 'absolute', top: 33 }}
        variant="body1"
      >
        {t('common:copyright', { year: legalYear })}
      </Typography>

      <Grid item>
        <Grid alignItems="center" container direction="row">
          <Grid item>
            <PageLink className={linkClass} pageId={'privacy-policy'}>
              {t('common:privacyPolicy')}
            </PageLink>
          </Grid>
          <Grid item>
            <Box ml={2} mr={2}>
              <Typography className={linkClass}>|</Typography>
            </Box>
          </Grid>
          <Grid item>
            <PageLink className={linkClass} pageId={'terms-and-conditions'}>
              {t('common:termsAndConditions')}
            </PageLink>
          </Grid>
          <Grid item>
            <Box ml={2} mr={2}>
              <Typography className={linkClass}>|</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Grid alignItems="center" container justifyContent="center">
              <LanguageSwitcher
                className={classes.button}
                localeString={locale === 'en-CA' ? t(`common:francais`) : t(`common:english`)}
                pageId={pageId}
                textClassName={classes.text}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
