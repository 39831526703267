"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSyringeIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m23.707 5.293-5-5a1 1 0 0 0 -1.414 1.414l1.793 1.793-1.486 1.483a5 5 0 0 0 -6.481.481l-6.069 6.072a6.954 6.954 0 0 0 -2.05 4.949v3.1l-2.707 2.708a1 1 0 1 0 1.414 1.414l2.707-2.707h3.1a6.954 6.954 0 0 0 4.949-2.05l6.072-6.072a4.993 4.993 0 0 0 .482-6.478l1.483-1.486 1.793 1.793a1 1 0 0 0 1.414-1.414zm-6.585 6.171-6.072 6.072a4.967 4.967 0 0 1 -3.535 1.464h-2.515v-2.515a5.11 5.11 0 0 1 .095-.976l1.2 1.2a1 1 0 1 0 1.414-1.414l-1.741-1.741a4.989 4.989 0 0 1 .5-.6l1.032-1.04 1.793 1.793a1 1 0 0 0 1.414-1.414l-1.793-1.793 1.586-1.586 1.793 1.793a1 1 0 0 0 1.414-1.414l-1.793-1.793.622-.622a3.073 3.073 0 0 1 4.242 0l.344.344a3 3 0 0 1 0 4.242z" })));
exports.default = TruxwebSyringeIcon;
