"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    fieldset: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: theme.spacing(1),
        },
    },
    select: {
        width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    selectRoot: {
        padding: '11px !important',
        width: `calc(100% - ${theme.spacing(4)}px)`,
    },
    text: {
        color: theme.palette.grey[600],
    },
}));
