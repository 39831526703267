import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  filterColumn: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(3),
    width: '20%',
  },
  filterDivider: {
    marginTop: theme.spacing(3),
  },
  filterTypeAllBtn: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: '128px',
  },
  filterTypeBtn: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: '196px',
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    padding: 0,
    width: '100%',
  },
  notificationsCard: {
    padding: 0,
  },
  notificationsColumn: {
    padding: theme.spacing(3),
    width: '80%',
  },
  primaryDarkColor: {
    color: theme.palette.primary.dark,
  },
  sortByBtn: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: '196px',
  },
}));
