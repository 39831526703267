import { useCallback, useEffect, useState } from 'react';
import { TBambora } from '../types';

export const useBambora = (): TBambora | null => {
  const [bambora, setBambora] = useState(null);
  // This will attempt to ensure the external bamboa library is
  // loaded, and if it is, will store the reference to bambora for use
  // later.
  const loadBambora = useCallback(() => {
    if (!window.customcheckout) {
      setTimeout(loadBambora, 150);
      return;
    }

    if (bambora) return;
    const bamboraInstance = window.customcheckout();
    setBambora(bamboraInstance);
    return;
  }, [bambora, setBambora]);

  useEffect(() => {
    loadBambora();
  }, [loadBambora]);

  return bambora;
};
