"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebChartSetTheoryIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M424.802,182.898c0-0.506,0-1.012,0-1.54c0-93.189-75.545-168.734-168.734-168.734S87.334,88.169,87.334,181.358  c0,0.527,0,1.033,0,1.54C5.755,227.943-23.862,330.592,21.183,412.171s147.694,111.196,229.273,66.152  c1.891-1.044,3.763-2.125,5.613-3.241c79.789,48.144,183.5,22.491,231.644-57.298c48.144-79.789,22.491-183.5-57.298-231.644  C428.564,185.023,426.693,183.942,424.802,182.898z M256.068,423.344c-22.691-20.272-37.309-48.048-41.171-78.229  c27.002,6.763,55.255,6.763,82.258,0c-3.864,30.132-18.45,57.865-41.087,78.124V423.344z M256.068,307.909  c-13.316,0.007-26.55-2.093-39.21-6.222c5.755-25.857,19.467-49.262,39.21-66.924c19.743,17.662,33.455,41.067,39.21,66.924  C282.618,305.816,269.384,307.916,256.068,307.909z M178.577,281.333c-23.344-18.079-39.644-43.741-46.085-72.555  c28.373-9.24,59.098-8.157,86.75,3.058C200.283,231.385,186.331,255.228,178.577,281.333z M292.894,211.73  c27.652-11.215,58.377-12.298,86.75-3.058c-6.419,28.853-22.721,54.556-46.085,72.661C325.82,255.19,311.868,231.309,292.894,211.73  z M256.068,54.808c63.672,0.082,117.4,47.391,125.538,110.542c-42.539-10.682-87.566-4.38-125.538,17.569  c-37.972-21.95-82.999-28.251-125.538-17.569C138.668,102.2,192.396,54.89,256.068,54.808z M45.151,329  c0.004-39.12,18.119-76.035,49.059-99.975c12.31,41.505,40.036,76.725,77.491,98.435c0,0.506,0,1.012,0,1.54  c-0.023,43.788,17.032,85.859,47.541,117.27c-64.773,26.256-138.566-4.968-164.822-69.74C48.3,361.431,45.152,345.293,45.151,329z   M340.435,455.551c-16.298,0.011-32.443-3.14-47.541-9.28c30.509-31.411,47.564-73.482,47.541-117.27c0-0.527,0-1.033,0-1.54  c37.463-21.706,65.197-56.927,77.512-98.435c55.215,42.851,65.237,122.349,22.386,177.564  C416.377,437.456,379.507,455.527,340.435,455.551z" })));
exports.default = TruxwebChartSetTheoryIcon;
