"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarBuildingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,19.732a10.957,10.957,0,0,0-.642-3.7l-.968-2.713A5.009,5.009,0,0,0,17.681,10H15.319a5.009,5.009,0,0,0-4.709,3.318l-.968,2.714c-.655,1.942-1.5,5.513,1.358,5.952V22.5a1.5,1.5,0,0,0,3,0V22h5v.5a1.5,1.5,0,0,0,3,0v-.516A2.272,2.272,0,0,0,24,19.732ZM15.319,12h2.362a3.008,3.008,0,0,1,2.826,1.991L20.867,15H12.133l.36-1.009A3.008,3.008,0,0,1,15.319,12Zm6.413,8H11.268A.268.268,0,0,1,11,19.732,8.972,8.972,0,0,1,11.425,17H13v1a1,1,0,0,0,2,0V17h3v1a1,1,0,0,0,2,0V17h1.575A8.972,8.972,0,0,1,22,19.732.268.268,0,0,1,21.732,20ZM5,2A3,3,0,0,0,2,5V23a1,1,0,0,1-2,0V5A5.006,5.006,0,0,1,5,0h6a5.006,5.006,0,0,1,5,5V7a1,1,0,0,1-2,0V5a3,3,0,0,0-3-3ZM7,5V6A1,1,0,0,1,6,7H5A1,1,0,0,1,4,6V5A1,1,0,0,1,5,4H6A1,1,0,0,1,7,5Zm0,5v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V10A1,1,0,0,1,5,9H6A1,1,0,0,1,7,10Zm0,5v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,1,1-1H6A1,1,0,0,1,7,15Zm0,5v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V20a1,1,0,0,1,1-1H6A1,1,0,0,1,7,20ZM11,4a1,1,0,0,1,1,1V6a1,1,0,0,1-1,1H10A1,1,0,0,1,9,6V5a1,1,0,0,1,1-1Z" })));
exports.default = TruxwebCarBuildingIcon;
