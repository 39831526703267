import { ECountryV1 } from '@truxweb/schemas';

export const geopoliticalCapitals = {
  [ECountryV1.CA]: {
    AB: 'Edmonton',
    BC: 'Victoria',
    MB: 'Winnipeg',
    NB: 'Fredericton',
    NL: "St. John's",
    NS: 'Halifax',
    NT: 'Yellowknife',
    NU: 'Iqaluit',
    ON: 'Toronto',
    PE: 'Charlottetown',
    QC: 'Quebec City',
    SK: 'Regina',
    YT: 'Whitehorse',
  },
  [ECountryV1.US]: {
    AK: 'Juneau',
    AL: 'Montgomery',
    AR: 'Little Rock',
    AZ: 'Phoenix',
    CA: 'Sacramento',
    CO: 'Denver',
    CT: 'Hartford',
    DC: 'Washington',
    DE: 'Dover',
    FL: 'Tallahassee',
    GA: 'Atlanta',
    HI: 'Honolulu',
    IA: 'Des Moines',
    ID: 'Boise',
    IL: 'Springfield',
    IN: 'Indianapolis',
    KS: 'Topeka',
    KY: 'Frankfort',
    LA: 'Baton Rouge',
    MA: 'Boston',
    MD: 'Annapolis',
    ME: 'Augusta',
    MI: 'Lansing',
    MN: 'Saint Paul',
    MO: 'Jefferson City',
    MS: 'Jackson',
    MT: 'Helena',
    NC: 'Raleigh',
    ND: 'Bismarck',
    NE: 'Lincoln',
    NH: 'Concord',
    NJ: 'Trenton',
    NM: 'Santa Fe',
    NV: 'Carson City',
    NY: 'Albany',
    OH: 'Columbus',
    OK: 'Oklahoma City',
    OR: 'Salem',
    PA: 'Harrisburg',
    RI: 'Providence',
    SC: 'Columbia',
    SD: 'Pierre',
    TN: 'Nashville',
    TX: 'Austin',
    UT: 'Salt Lake City',
    VA: 'Richmond',
    VT: 'Montpelier',
    WA: 'Olympia',
    WI: 'Madison',
    WV: 'Charleston',
    WY: 'Cheyenne',
  },
};
