// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinTheRevolutionOptionV1 = void 0;
var JoinTheRevolutionOptionV1;
(function (JoinTheRevolutionOptionV1) {
    JoinTheRevolutionOptionV1["yesIWantTo"] = "yesIWantTo";
    JoinTheRevolutionOptionV1["noThanks"] = "noThanks";
})(JoinTheRevolutionOptionV1 || (exports.JoinTheRevolutionOptionV1 = JoinTheRevolutionOptionV1 = {}));
