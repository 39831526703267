import { useDispatch, useSelector } from 'react-redux';
import { EUserTypeV1 } from '@truxweb/schemas';
import { getShipmentCredits } from '../../../actions';
import { selectShipmentCredits } from '../../../selectors';
import { TShipmentCreditData } from '../../../stores';
import { useEffect } from 'react';
import { useUserData } from '../..';

export const useShipmentCredits = (shouldForceRefresh: boolean): TShipmentCreditData => {
  const data = useSelector(selectShipmentCredits());
  const { companyData } = useUserData();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      companyData?.type === EUserTypeV1.SHIPPER &&
      ((!data.hasLoaded && !data.isLoading) || (!data.isLoading && shouldForceRefresh))
    ) {
      dispatch(getShipmentCredits());
    }
  }, [data, dispatch, shouldForceRefresh, companyData]);

  return data;
};
