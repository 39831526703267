import { type AppState, type IUXState } from '../../stores';

export const selectApplicationLoadingState = (
  state: AppState
): IUXState['applicationLoadingState'] => {
  return (
    state?.ux?.applicationLoadingState || {
      isActionLoading: false,
      isApplicationLoading: false,
    }
  );
};
