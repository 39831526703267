import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    background: 'transparent',
    maxWidth: '100%',
    minWidth: '100%',
    position: 'relative',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
  legalLink: {
    color: '#787878',
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1.5),
  },
  pendingAnnouncementsBottom: {
    fill: 'orange',
    filter: 'blur(3px)',
    position: 'absolute',
    stroke: 'orange',
  },
  pendingAnnouncementsBottomAck: {
    position: 'absolute',
  },
  pendingAnnouncementsTop: {
    fill: 'red',
  },
  pro: {
    background: 'rgb(255,249,233)',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    color: theme.palette.secondary.light,
  },
  proText: {
    color: theme.palette.secondary.dark,
  },
  statusBanner: {
    alignItems: 'center',
    background: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: theme.spacing(8),
    textAlign: 'center',
  },

  unsupportedBrowser: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  whiteIcon: {
    '& line': { stroke: theme.palette.common.white },
    '& path, rect': { fill: theme.palette.common.white },
  },
}));
