"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStepperConnectorStyles = exports.useStepperStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStepperStyles = (0, ux_1.makeStyles)((theme) => ({
    activeDot: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '100%',
        color: 'white',
        display: 'flex',
        height: '80px',
        justifyContent: 'center',
        marginTop: '-26px',
        position: 'relative',
        width: '80px',
        zIndex: 1,
    },
    activeStepText: {
        color: theme.palette.primary.light,
    },
    completed: {
        '& $line': {
            backgroundColor: '#0087CE',
        },
        color: 'white',
    },
    completedDot: {
        backgroundColor: theme.palette.primary.light,
        position: 'relative',
        zIndex: 2,
    },
    icon: {
        height: 'auto',
        width: '34px',
    },
    inDisputeDot: {
        backgroundColor: theme.palette.secondary.main,
    },
    inactiveDot: {
        borderRadius: '24px',
        height: '24px',
        width: '24px',
    },
    line: {
        backgroundColor: '#c4c4c4',
        height: 2,
        left: '-20px',
        position: 'relative',
    },
    statusText: {
        fontWeight: 600,
        lineHeight: '18px',
    },
    step: {
        height: '50px',
        marginBottom: '10px',
    },
    untouchedDot: {
        backgroundColor: '#c4c4c4',
    },
}));
exports.useStepperConnectorStyles = (0, ux_1.makeStyles)((theme) => ({
    active: {
        '& $line': {
            backgroundColor: '#0087CE',
        },
        color: theme.palette.primary.light,
    },
    alternativeLabel: {
        marginLeft: 0,
        top: 11,
        width: '100%',
    },
    completed: {
        '& $line': {
            backgroundColor: '#0087CE',
        },
        color: 'white',
    },
    line: {
        backgroundColor: '#c4c4c4',
        height: 2,
        left: '-20px',
        position: 'relative',
    },
    root: {
        color: 'lightgrey',
    },
}));
