"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        '&:first-child': {
            borderBottomLeftRadius: '100%',
            borderRadius: '100%',
            borderTopRightRadius: '100%',
        },
        '&:last-child': {
            borderBottomLeftRadius: '100%',
            borderRadius: '100%',
            borderTopRightRadius: '100%',
        },
        '&:not(:first-child)': {
            borderBottomLeftRadius: '100%',
            borderRadius: '100%',
            borderTopRightRadius: '100%',
            marginLeft: 0,
        },
        '&:not(:last-child)': {
            borderBottomLeftRadius: '100%',
            borderRadius: '100%',
            borderTopRightRadius: '100%',
            marginLeft: 0,
        },
        background: 'rgba(196, 196, 196, 0.3)',
        borderWidth: 0,
        color: '#636363',
        fontSize: ({ shouldUseSmallVariant }) => {
            if (!shouldUseSmallVariant)
                return theme.spacing(1.74);
            return theme.spacing(1.5);
        },
        height: ({ shouldUseSmallVariant }) => {
            if (!shouldUseSmallVariant)
                return theme.spacing(8);
            return theme.spacing(4.5);
        },
        marginLeft: 0,
        marginRight: ({ shouldUseSmallVariant }) => {
            if (!shouldUseSmallVariant)
                return theme.spacing(2);
            return theme.spacing(1);
        },
        width: ({ shouldUseSmallVariant }) => {
            if (!shouldUseSmallVariant)
                return theme.spacing(8);
            return theme.spacing(4.5);
        },
    },
    selected: {
        // FIXME: lazy baxter get rid of the damn !important
        background: `${theme.palette.primary.light} !important`,
        borderWidth: 0,
        color: `${theme.palette.common.white} !important`,
        marginLeft: 0,
    },
}));
