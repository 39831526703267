"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTireFlatIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.566,19.229c6-7.7.2-19.354-9.566-19.229C2.286-.176-3.583,11.59,2.441,19.235A2.51,2.51,0,0,0,3.5,24h17A2.513,2.513,0,0,0,21.566,19.229ZM20.5,22H3.5a.5.5,0,1,1,.053-1,1.452,1.452,0,0,0,1.416-1c.162-.638-.224-1.1-.672-1.633C-1.1,12.017,3.669,1.9,12,2c8.357-.094,13.121,10.045,7.662,16.411-.409.487-.795.947-.631,1.583A1.474,1.474,0,0,0,20.5,21,.5.5,0,0,1,20.5,22ZM12,4a8.009,8.009,0,0,0-8,8c.376,10.588,15.626,10.585,16,0A8.009,8.009,0,0,0,12,4Zm-.834,9.086a4.192,4.192,0,0,0-.144-.813,4.246,4.246,0,0,0-.3-.764A3.594,3.594,0,0,0,12,10.653a3.614,3.614,0,0,0,.581.49,3.555,3.555,0,0,0,.7.366,4.192,4.192,0,0,0-.442,1.577A4.11,4.11,0,0,0,11.166,13.086ZM17.12,8.874l-1.426.571-.5.2A1.6,1.6,0,0,1,13,8.163V6.084A6.016,6.016,0,0,1,17.12,8.874ZM11,6.084V8.163A1.6,1.6,0,0,1,8.81,9.646L6.88,8.874A6.016,6.016,0,0,1,11,6.084ZM6,12a6.031,6.031,0,0,1,.135-1.269l1.7.681a2.128,2.128,0,0,1,.917,3.249l-1.1,1.469A5.978,5.978,0,0,1,6,12Zm3.251,5.332L10.4,15.8a2.059,2.059,0,0,1,3.2,0l1.148,1.532A6.043,6.043,0,0,1,9.251,17.332Zm7.1-1.2-1.1-1.464a2.129,2.129,0,0,1,.914-3.255l1.7-.681A6.028,6.028,0,0,1,16.348,16.131Z" })));
exports.default = TruxwebTireFlatIcon;
