"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        background: '#EDF8FF',
        borderRadius: theme.spacing(0.5),
        width: 'fit-content',
    },
    icon: {
        fill: '#0087CE',
        scale: 0.8,
    },
    label: {
        color: theme.palette.primary.light,
    },
}));
