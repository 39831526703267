import { makeRequestToApiGateway } from '../../apiUtils';
import { TShipmentDocumentV1 } from '@truxweb/schemas';

export const addShipperDocumentToShipment = async (
  document: TShipmentDocumentV1
): Promise<void> => {
  await makeRequestToApiGateway(
    'shipmentPostV1ShipperDocument',
    null,
    {
      'Content-Type': 'application/json',
    },
    document
  );
};
