"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarrotIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,5H20.414l2.293-2.293a1,1,0,0,0-1.414-1.414L19,3.586V1a1,1,0,0,0-2,0V5.456A10.139,10.139,0,0,0,14.146,3.33,3.765,3.765,0,0,0,9.723,4.378C7.965,6.136,3.065,15.121.3,20.319A2.511,2.511,0,0,0,3.681,23.7c5.2-2.764,14.183-7.665,15.941-9.423a3.766,3.766,0,0,0,1.049-4.422A10.167,10.167,0,0,0,18.544,7H23A1,1,0,0,0,23,5Zm-4.792,7.863A16.072,16.072,0,0,1,15.915,14.5l-2.208-2.207a1,1,0,0,0-1.414,1.414l1.87,1.869c-2.746,1.643-6.689,3.842-11.421,6.358a.5.5,0,0,1-.676-.676c1.522-2.861,2.923-5.424,4.16-7.618l2.067,2.067a1,1,0,0,0,1.414-1.414L7.251,11.837c1.09-1.889,2.006-3.4,2.709-4.463l2.333,2.333a1,1,0,0,0,1.414-1.414L11.174,5.76c.847-.789,1.366-.922,2.183-.592a11.623,11.623,0,0,1,3.008,2.466,11.628,11.628,0,0,1,2.467,3.009C19.081,11.223,19.178,11.894,18.208,12.863Z" })));
exports.default = TruxwebCarrotIcon;
