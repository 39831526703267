import { makeStyles, Theme } from '@truxweb/ux';

type TLandingValuePropCard = {
  variant: 'light' | 'dark';
};

export const useStyles = makeStyles<Theme, TLandingValuePropCard>((theme: Theme) => ({
  container: {
    '& *': { color: theme.palette.common.white },
    backgroundColor: ({ variant }) => {
      return variant === 'light' ? theme.palette.primary.main : theme.palette.primary.dark;
    },
    borderRadius: '36px',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing(6)}px`,
    },
  },
  divider: {
    background: ({ variant }) => {
      return variant === 'light' ? theme.palette.primary.dark : theme.palette.primary.main;
    },
    height: theme.spacing(0.25),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: theme.spacing(13),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
    },
  },
  imageContainer: {
    boxShadow: '0px 0px 0px 0px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '176px',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('sm')]: {
      height: '176px',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '20px',
      height: '280px',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '12px',
    },
  },
  imageGrid: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  title: {
    lineHeight: `${theme.spacing(2)}px`,
  },
}));
