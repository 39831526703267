import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  actionButton: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
    color: theme.palette.primary.main,
  },
  header: {
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(3)}px`,
  },
  headerIcon: {
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(3),
  },
  listItem: {
    background: '#E4F5FF',
    borderRadius: 10,
    boxShadow: 'unset',
    position: 'relative',
  },
}));
