"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatBar = void 0;
const __1 = require("../..");
const react_1 = __importStar(require("react"));
const ux_1 = require("@truxweb/ux");
const StatBar = ({ colorScale, data, hasSequentialValues, height, labelRenderer, shouldSuppressLabels, width, xAxisLabel, yAxisLabel, }) => {
    const theme = (0, ux_1.useTheme)();
    const domainPaddingX = theme.spacing(2);
    const isExpandable = data.length > __1.MAX_SIZE_VISIBLE_DOMAIN;
    const minDomainSize = Math.min(data.length, Math.floor(__1.MAX_SIZE_VISIBLE_DOMAIN / 2));
    const maxDomainSize = Math.min(data.length, __1.MAX_SIZE_VISIBLE_DOMAIN);
    const effectiveColorScale = (Array.isArray(colorScale) ? colorScale[0] : colorScale) || theme.palette.info.light;
    const [zoomDomain, setZoomDomain] = (0, react_1.useState)({
        x: [0, data.length || 0],
    });
    const handleZoomDomainChangeForNumbers = (0, react_1.useCallback)(([domainStartIndex, domainEndIndex]) => {
        const domainSize = domainEndIndex - domainStartIndex;
        if (domainSize > maxDomainSize && !hasSequentialValues) {
            const newDomainStartIndex = Math.max(0, domainEndIndex - maxDomainSize);
            const newDomainEndIndex = Math.min(domainEndIndex, data.length - 1);
            return [newDomainStartIndex, newDomainEndIndex];
        }
        return [domainStartIndex, domainEndIndex];
    }, [data.length, maxDomainSize, hasSequentialValues]);
    const handleZoomDomainChange = (0, react_1.useCallback)((newDomain) => {
        if (!data.length)
            return;
        if (isNumericTuple(newDomain.x)) {
            newDomain.x = handleZoomDomainChangeForNumbers(newDomain.x);
        }
        setZoomDomain(newDomain);
    }, [data.length, setZoomDomain, handleZoomDomainChangeForNumbers]);
    (0, react_1.useEffect)(() => {
        if (data.length > 0) {
            const startXIndex = 1;
            const endXIndex = (hasSequentialValues ? data.length : Math.min(data.length, __1.MAX_SIZE_VISIBLE_DOMAIN)) + 1;
            handleZoomDomainChange({
                x: [startXIndex, endXIndex],
            });
        }
    }, [data.length, handleZoomDomainChange, hasSequentialValues]);
    return (react_1.default.createElement(__1.VictoryChart, { containerComponent: react_1.default.createElement(__1.VictoryZoomContainer, { allowPan: isExpandable, allowZoom: isExpandable, minimumZoom: { x: minDomainSize }, onZoomDomainChange: handleZoomDomainChange, responsive: true, zoomDimension: "x", zoomDomain: zoomDomain }), domainPadding: { x: [domainPaddingX, domainPaddingX] }, height: height, theme: __1.VictoryTheme.material, width: width },
        shouldSuppressLabels && react_1.default.createElement(__1.VictoryAxis, { tickFormat: () => '' }),
        shouldSuppressLabels && react_1.default.createElement(__1.VictoryAxis, { dependentAxis: true }),
        !shouldSuppressLabels &&
            (hasSequentialValues ? (react_1.default.createElement(__1.VictoryAxis, { axisLabelComponent: react_1.default.createElement(__1.VictoryLabel, { y: theme.typography.fontSize }), fixLabelOverlap: data.length > minDomainSize, label: xAxisLabel, tickLabelComponent: react_1.default.createElement(__1.VictoryLabel, { angle: -35, dx: -25, dy: -5, renderInPortal: true }) })) : (react_1.default.createElement(__1.VictoryAxis, { axisLabelComponent: react_1.default.createElement(__1.VictoryLabel, { y: theme.typography.fontSize }), label: xAxisLabel, tickLabelComponent: react_1.default.createElement(__1.VictoryLabel, { angle: -35, dx: -15, dy: -5, style: [{ fontSize: 8 }] }) }))),
        !shouldSuppressLabels && (react_1.default.createElement(__1.VictoryAxis, { axisLabelComponent: react_1.default.createElement(__1.VictoryLabel, { x: theme.typography.fontSize }), dependentAxis: true, label: yAxisLabel })),
        react_1.default.createElement(__1.VictoryBar, { alignment: "start", barRatio: 0.85, data: data, labelComponent: react_1.default.createElement(__1.VictoryTooltip, { flyoutComponent: react_1.default.createElement(SafeFlyout, null) }), labels: ({ datum }) => {
                if (labelRenderer) {
                    return labelRenderer(datum !== null && datum !== void 0 ? datum : { x: null, y: null });
                }
                return `${(datum === null || datum === void 0 ? void 0 : datum.y) || ''}`;
            }, style: { data: { fill: effectiveColorScale } } })));
};
exports.StatBar = StatBar;
const isNumericTuple = (domainTuple) => domainTuple.every((value) => typeof value === 'number');
const SafeFlyout = (props) => {
    const activeProps = Object.assign({}, props);
    const lastValidProps = (0, react_1.useRef)({ x: 0, y: 0 });
    if (typeof activeProps.x !== 'number' || isNaN(activeProps.x)) {
        // eslint-disable-next-line no-console
        console.warn('SafeFlyout: x is not valid', props);
        activeProps.x = lastValidProps.current.y;
    }
    if (typeof activeProps.y !== 'number' || isNaN(activeProps.y)) {
        // eslint-disable-next-line no-console
        console.warn('SafeFlyout: y is not valid', activeProps);
        activeProps.y = lastValidProps.current.y;
    }
    lastValidProps.current = activeProps;
    try {
        return react_1.default.createElement(__1.Flyout, Object.assign({}, activeProps));
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.warn('SafeFlyout: invalid value', props);
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
};
