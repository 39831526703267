import { TUseActionResponse, useAction } from './useAction';
import { getCarrierEquipmentData } from '../actions';
import { TCarrierEquipmentV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierEquipmentData = (
  forceRefetch?: boolean
): TUseActionResponse<TCarrierEquipmentV1[]> => {
  const action = useCallback(() => {
    return getCarrierEquipmentData();
  }, []);
  return useAction<TCarrierEquipmentV1[]>(action, true, forceRefetch);
};
