import { type AppState, baseLoadingState, TShipperQuoteRequest } from '../../stores';

export const selectShipperQuoteById = (
  quoteId: number
): ((state: AppState) => TShipperQuoteRequest) => {
  return (state: AppState) => {
    const loadingState = state?.quote?.selectedShipperQuote?.[quoteId] || { ...baseLoadingState };
    if (!loadingState.hasLoaded) return loadingState;

    return loadingState;
  };
};
