import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  emailContainer: {
    borderColor: theme.palette.primary.main,
    borderRadius: '36px 0 0 36px',
    borderStyle: 'solid',
    borderWidth: `2px 0 2px 2px`,
    paddingLeft: 40,
  },
  emailInput: {
    border: 0,
    display: 'flex',
    fontSize: 15,
    height: '100%',
    width: '140px',
  },
  footer: {
    background: theme.palette.common.white,
    bottom: 0,
    display: 'flex',
    justifyItems: 'center',
    left: 0,
    minHeight: theme.spacing(35),
    width: '100%',
  },
  hubspotNewsletterSignup: {
    '& .hs-error-msgs': {
      position: 'absolute',
    },
    '& input[type=email]': {
      background: 'transparent !important',
      border: `1px solid ${theme.palette.common.white} !important`,
      borderRadius: 0,
      color: theme.palette.common.white,
      height: `${theme.spacing(3.75)}px !important`,
      paddingLeft: theme.spacing(0.5),
      width: `calc(100% - ${theme.spacing(9)}px) !important`,
    },
    '& input[type=submit]': {
      background: `${theme.palette.secondary.main} !important`,
      borderColor: theme.palette.common.white,
      borderRadius: 0,
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 0',
      color: theme.palette.common.white,
      height: `${theme.spacing(3.75)}px !important`,
      left: `calc(100% - ${theme.spacing(9)}px) !important`,
      position: 'absolute',
      textTransform: 'uppercase',
      top: 23,
      width: theme.spacing(9),
    },
    color: theme.palette.common.white,
    marginBottom: theme.spacing(8),
    position: 'relative',
  },

  infoLink: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontWeight: 800,
    textDecoration: 'none',
  },
  inner: {
    padding: `${theme.spacing(8.5)}px ${theme.spacing(5)}px ${theme.spacing(
      13.5
    )}px ${theme.spacing(5)}px`,
  },

  legalFooter: {
    background: theme.palette.primary.dark,
    height: theme.spacing(10),
  },
  legalHeaderClass: {
    color: theme.palette.common.white,
    fontSize: 15,
    lineHeight: '21px',
  },
  legalLinkClass: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: `${theme.palette.common.white} !important`,
    fontSize: 13,
    lineHeight: '21px',
  },
  newsletterSignup: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(8),
    position: 'relative',
  },

  siteMapHeader: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  siteMapLink: {
    color: '#636363',
  },
  submitButton: {
    '&:hover': {
      boxShadow: 'unset',
    },
    borderRadius: 0,
    boxShadow: 'unset',
    lineHeight: `${theme.spacing(5.75)}px`,
  },
}));
