"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessorialPickerCard = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const AccessorialPickerCard_styles_1 = require("./AccessorialPickerCard.styles");
const AccessorialPickerCard = ({ accessorials, deleteHandler, handlePickerChange, hasError, index, name, onChangeHandler, setValue, t, testId, value, values, variant, }) => {
    var _a;
    const classes = (0, AccessorialPickerCard_styles_1.useStyles)();
    const selectedAccessorial = (0, react_1.useMemo)(() => {
        return accessorials.find(({ code }) => {
            return code === value;
        });
    }, [accessorials, value]);
    const [accessorialMetadataValue, setAccessorialMetadataValue] = (0, react_1.useState)(null);
    const doesRequiresMetadataInput = (0, react_1.useMemo)(() => {
        var _a, _b;
        let shouldInputMetadata = false;
        // For search accessorials we only show metadata input for the accessorial
        // if is to indicate whether or not the accessorial is optional for the search
        // resulsts
        if (selectedAccessorial !== undefined && Boolean((_a = selectedAccessorial === null || selectedAccessorial === void 0 ? void 0 : selectedAccessorial.metadata) === null || _a === void 0 ? void 0 : _a.length)) {
            shouldInputMetadata =
                ((_b = selectedAccessorial === null || selectedAccessorial === void 0 ? void 0 : selectedAccessorial.metadata) === null || _b === void 0 ? void 0 : _b[0].metadataType) === schemas_1.EMetadataTypeV1.OPTIONAL;
        }
        return shouldInputMetadata;
    }, [selectedAccessorial]);
    const onChange = (0, react_1.useCallback)((event) => {
        onChangeHandler(event);
    }, [onChangeHandler]);
    const handleAccessorialMetadataChange = (0, react_1.useCallback)((value) => {
        setAccessorialMetadataValue(value);
        setValue(`accessorialMetadata.${index}.value`, value);
        setValue(`accessorialMetadata.${index}.accessorialId`, selectedAccessorial === null || selectedAccessorial === void 0 ? void 0 : selectedAccessorial.id);
    }, [setAccessorialMetadataValue, setValue, selectedAccessorial, index]);
    const onAccessorialMetadataChange = (0, react_1.useCallback)((event) => {
        handleAccessorialMetadataChange(event.target.value);
    }, [handleAccessorialMetadataChange]);
    const handleBeforeOnChange = (0, react_1.useCallback)((event, index) => {
        handlePickerChange(event, index, accessorialMetadataValue);
    }, [accessorialMetadataValue, handlePickerChange]);
    (0, react_1.useEffect)(() => {
        if (selectedAccessorial && accessorialMetadataValue === null && doesRequiresMetadataInput) {
            // Check to see if this has a value
            const accessorialMetadata = values === null || values === void 0 ? void 0 : values.accessorialMetadata.filter((record) => {
                return record;
            }).find(({ accessorialId }) => {
                return accessorialId === selectedAccessorial.id;
            });
            // NOTE: Currently we are only allowing accessorials with the `OPTIONAL` metadata
            // type to be selected, which uses the ERequiredOptionalV1 enum... so we can prefill
            // that value here. If other accessorialMetadata types were supported this will
            // not work as expected, as ERequiredOptionalV1.OPTIONAL won't always be a valid selection
            // for the metadata selection
            handleAccessorialMetadataChange((accessorialMetadata === null || accessorialMetadata === void 0 ? void 0 : accessorialMetadata.value) || schemas_1.ERequiredOptionalV1.OPTIONAL);
        }
    }, [
        selectedAccessorial,
        handleAccessorialMetadataChange,
        accessorialMetadataValue,
        values,
        doesRequiresMetadataInput,
    ]);
    return (react_1.default.createElement(ux_1.Box, { className: classes.wrapper },
        react_1.default.createElement(ux_1.DeletableCard, { handleDelete: deleteHandler, testId: `${testId}-AccessorialPickerCard-${index}`, variant: variant },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center" },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(__1.AccessorialsSelect, { accessorials: accessorials, beforeOnChange: handleBeforeOnChange, hasError: hasError, index: index, name: name, onChange: onChange, t: t, testId: testId, value: value, variant: 'filled' })),
                doesRequiresMetadataInput && selectedAccessorial !== undefined && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Box, { ml: 2.5 },
                        react_1.default.createElement(__1.QuoteRequestAccessorialMetadata, { accessorial: selectedAccessorial, className: classes.accessorialSelect, customMetadataHandlers: [schemas_1.EAccessorialOptionV1.DANGEROUS_GOODS], isDisabled: false, localizationPrefix: "common:", metadataType: (_a = selectedAccessorial === null || selectedAccessorial === void 0 ? void 0 : selectedAccessorial.metadata) === null || _a === void 0 ? void 0 : _a[0].metadataType, onChange: onAccessorialMetadataChange, t: t, value: accessorialMetadataValue || '' }))))))));
};
exports.AccessorialPickerCard = AccessorialPickerCard;
