"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessorialDetailsCard = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const ShipmentPropertySummary_1 = require("../ShipmentPropertySummary");
const AccessorialDetailsCard_styles_1 = require("./AccessorialDetailsCard.styles");
const AccessorialDetailsCard = ({ AccessorialIcon, accessorialPrefix, accessorials, editIcon, t, }) => {
    const classes = (0, AccessorialDetailsCard_styles_1.useStyles)();
    const properties = (0, react_1.useMemo)(() => {
        return accessorials.map((accessorial) => {
            var _a;
            const icon = react_1.default.createElement(AccessorialIcon, { accessorialCode: accessorial.accessorialCode || '' });
            let additionalInfo = '';
            (_a = accessorial.metadata) === null || _a === void 0 ? void 0 : _a.forEach((data, index) => {
                var _a;
                additionalInfo = additionalInfo.concat(t(`common:${accessorialPrefix}-shipmentDetails-metadata-${data.value}`));
                if (index !== ((_a = accessorial.metadata) === null || _a === void 0 ? void 0 : _a.length)) {
                    additionalInfo.concat(' | ');
                }
            });
            const titleText = t(`common:${accessorialPrefix}-shipmentDetails-accessorial-${accessorial.accessorialCode}`);
            return { additionalInfo, icon, titleText };
        });
    }, [accessorials, accessorialPrefix, t, AccessorialIcon]);
    return (react_1.default.createElement(__1.Card, { className: classes.container },
        react_1.default.createElement(ux_1.Grid, { className: classes.fullHeight, container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { className: classes.header, variant: "h3" }, t(`common:accessorials`))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                !accessorials ||
                    (accessorials.length === 0 && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", className: classes.container, container: true, justifyContent: "center" },
                        react_1.default.createElement(ux_1.Box, { mb: 14, mt: 14 },
                            react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t(`common:noAccessorialsChosen`)))))),
                accessorials.length !== 0 && (react_1.default.createElement(ShipmentPropertySummary_1.ShipmentPropertySummary, { editIcon: editIcon, summary: properties }))))));
};
exports.AccessorialDetailsCard = AccessorialDetailsCard;
