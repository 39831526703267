"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDashboardEditableOrderSummaryForm = void 0;
const utils_1 = require("../../utils");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const react_hook_form_1 = require("react-hook-form");
const ShipmentDashboardEditableOrderSummaryForm = ({ accessorials, availableShipperShipmentFees, charges, handleCancel, isEditingDisabled, language, onEditShipment, shipmentDetails, shouldAllowAddingCharges, shouldDisallowSavingCharges, t, }) => {
    const [init, setInit] = (0, react_1.useState)(null);
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [hasRecalculated, setHasRecalculated] = (0, react_1.useState)(false);
    const { accessorial, additional, equipment, serviceFees, truxwebCarrierCharges, truxwebShipperCharges, truxwebShipperShipmentFees, } = (0, react_1.useMemo)(() => {
        return (0, utils_1.groupChargesForEditableSummary)({
            accessorials,
            areAccessorialsEditable: true,
            areAdditionalChargesEditable: true,
            charges,
            isEditingDisabled,
            shipmentDetails,
        });
    }, [isEditingDisabled, accessorials, shipmentDetails, charges]);
    const handleInit = (0, react_1.useCallback)((data) => {
        if (!init)
            setInit(data);
    }, [setInit, init]);
    const onFormSubmit = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        if (!init) {
            throw new Error('Form submitted without being initialized');
        }
        try {
            setIsSaving(true);
            const { charges, payload, shipmentUpdate } = (0, utils_1.formatDataFromOrderSummaryForm)(data);
            const shipmentData = Object.assign({}, shipmentDetails.shipment);
            if (shipmentUpdate) {
                shipmentData.isFscActive = shipmentUpdate.isFscActive;
                shipmentData.fscRate = shipmentUpdate.fscRate;
            }
            const editAction = hasRecalculated && !shouldDisallowSavingCharges ? 'saveCharges' : 'calculateCharges';
            const result = yield onEditShipment(editAction, shipmentData, charges, payload);
            if (result) {
                init.handleUpdatingCharges(result.charges);
                if (editAction === 'saveCharges' && !shouldDisallowSavingCharges) {
                    if (handleCancel)
                        handleCancel();
                }
                else {
                    setHasRecalculated(true);
                }
            }
        }
        finally {
            setIsSaving(false);
        }
    }), [
        hasRecalculated,
        setHasRecalculated,
        shipmentDetails,
        handleCancel,
        onEditShipment,
        setIsSaving,
        shouldDisallowSavingCharges,
        init,
    ]);
    const formControls = (0, react_hook_form_1.useForm)({
        defaultValues: {
            accessorial,
            additional,
            equipment,
            serviceFees,
            truxwebCarrierCharges,
            truxwebShipperCharges,
            truxwebShipperShipmentFees,
        },
    });
    formControls.watch(() => {
        if (hasRecalculated && !isSaving)
            setHasRecalculated(false);
    });
    return (react_1.default.createElement("form", { onSubmit: formControls.handleSubmit(onFormSubmit) },
        react_1.default.createElement(__1.ShipmentDashboardEditableOrderSummary, { accessorials: accessorials, availableShipperShipmentFees: availableShipperShipmentFees, charges: charges, formControls: formControls, handleCancel: handleCancel, handleInit: handleInit, hasInit: Boolean(init), hasRecalculated: hasRecalculated, isEditingDisabled: isEditingDisabled, isSaving: isSaving, language: language, onEditShipment: onEditShipment, setIsSaving: setIsSaving, shipmentDetails: shipmentDetails, shouldAllowAddingCharges: shouldAllowAddingCharges, shouldDisallowSavingCharges: shouldDisallowSavingCharges, t: t })));
};
exports.ShipmentDashboardEditableOrderSummaryForm = ShipmentDashboardEditableOrderSummaryForm;
