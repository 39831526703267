"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    container: {
        borderBottom: '1px solid #e0e0e0',
        position: 'relative',
        width: `100%`,
    },
}));
