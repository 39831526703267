"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    attention: {
        backgroundColor: '#FFF6C7',
        borderColor: theme.palette.secondary.dark,
        color: '#FCBE0F',
        fontWeight: 600,
    },
    defaultState: {
        backgroundColor: '#C6E7F9',
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
        fontWeight: 600,
    },
    error: {
        backgroundColor: '#FED4D4',
        borderColor: theme.palette.error.dark,
        color: '#E94141',
        fontWeight: 600,
    },
    noAction: {
        backgroundColor: 'lightgrey',
        borderColor: 'darkgrey',
        color: 'darkgrey',
        fontWeight: 600,
    },
    statusBar: {
        borderRadius: theme.spacing(0.75),
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    },
    success: {
        backgroundColor: '#D5F2D6',
        borderColor: theme.palette.success.dark,
        color: '#4CAF50',
        fontWeight: 600,
    },
}));
