import { makeRequestToApiGateway } from '../../apiUtils';
import { TCompanyLocationV1 } from '@truxweb/schemas';

export const getCompanyLocation = async (): Promise<TCompanyLocationV1[]> => {
  const results = await makeRequestToApiGateway('userGetV1CompanyLocation', null, {
    'Content-Type': 'application/json',
  });

  return results as TCompanyLocationV1[];
};
