"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    expandButton: {
        marginTop: ({ gapInterval }) => {
            if (!gapInterval)
                return 0;
            return `-${theme.spacing(gapInterval)}px`;
        },
    },
    greyButton: {
        color: theme.palette.grey[700],
        minWidth: theme.spacing(5),
        padding: `${theme.spacing(0)}px !important`,
    },
    overlay: {
        height: ({ rowHeight }) => rowHeight - 1,
        left: ({ slideDirection }) => (slideDirection === 'right' ? '0' : 'auto'),
        maxHeight: ({ rowHeight }) => rowHeight - 1,
        overflow: 'hidden',
        paddingLeft: ({ slideDirection }) => {
            if (slideDirection === 'left')
                return '10px';
            return '0';
        },
        paddingRight: ({ slideDirection }) => {
            if (slideDirection === 'left')
                return '0';
            return '16px';
        },
        position: 'absolute',
        right: ({ slideDirection }) => (slideDirection === 'left' ? '0' : 'auto'),
        width: 'fit-content',
        zIndex: 1,
    },
    overlayInner: {
        backgroundColor: theme.palette.common.white,
        boxShadow: ({ slideDirection }) => {
            if (slideDirection === 'left')
                return '-2px -4px 10px rgba(0, 0, 0, 0.15)';
            return '16px 0px 16px -16px rgba(0,0,0,0.75)';
        },
        height: '100%',
    },
}));
