"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useButtonStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
const getHoverColor = (theme, { hasBeenModified, hasError, isEditable, isInEditMode, isLocked }) => {
    if (isLocked) {
        return '#636363';
    }
    if (isInEditMode) {
        return theme.palette.common.black;
    }
    if (hasError && isEditable) {
        return theme.palette.error.dark;
    }
    if (hasError && !isEditable) {
        return theme.palette.error.main;
    }
    if (hasBeenModified && isEditable) {
        return theme.palette.warning.light;
    }
    if (hasBeenModified && !isEditable) {
        return theme.palette.warning.main;
    }
    if (isEditable) {
        return theme.palette.primary.main;
    }
    return theme.palette.primary.light;
};
const getColor = (theme, { hasBeenModified, hasError, isInEditMode, isLocked }) => {
    if (isLocked) {
        return '#636363';
    }
    if (isInEditMode) {
        return theme.palette.common.black;
    }
    if (hasError) {
        return theme.palette.error.main;
    }
    if (hasBeenModified) {
        return theme.palette.warning.light;
    }
    return theme.palette.primary.light;
};
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        '&:hover': {
            borderColor: (vars) => {
                return getHoverColor(theme, vars);
            },
            color: (vars) => {
                return getHoverColor(theme, vars);
            },
            fill: (vars) => {
                return getHoverColor(theme, vars);
            },
        },
        background: ({ isLocked }) => {
            if (isLocked) {
                return '#eeeeee';
            }
            return 'transparent';
        },
        borderColor: (vars) => {
            return getColor(theme, vars);
        },
        borderRadius: theme.spacing(0.75),
        borderStyle: 'solid',
        borderWidth: '2px',
        color: (vars) => {
            return getColor(theme, vars);
        },
        fill: (vars) => {
            return getColor(theme, vars);
        },
        padding: theme.spacing(1),
    },
    content: {
        color: (vars) => {
            return getColor(theme, vars);
        },
    },
    contentHover: {
        color: (vars) => {
            return getHoverColor(theme, vars);
        },
    },
    defaultEditInput: {
        '&:focus': {
            outline: 'none',
        },
        background: 'transparent',
        border: 0,
        fontWeight: 600,
        height: '24px',
        width: '100%',
    },
    errorMessage: {
        color: theme.palette.error.main,
        fontSize: theme.spacing(1.25),
    },
    icon: {
        fill: 'inherit',
    },
    property: {
        fontSize: theme.spacing(1.75),
    },
}));
exports.useButtonStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        padding: '0 !important',
    },
}));
