import {
  Box,
  CircularProgress,
  Grid,
  type GridFilterModel,
  type GridSortModel,
  List,
  ListItem,
  RightHandSidebar,
  ToggleButtons,
  TruxwebRefreshIcon,
  Typography,
} from '@truxweb/ux';
import { DEFAULT_PAGE_SIZE, QUOTE_TABLE_REFRESH_MS } from '../../config';
import { EShipmentStatusGroupV1, EUserTypeV1 } from '@truxweb/schemas';
import { QuickAccess, ShipmentShipperDataGrid } from '@truxweb/common-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useDataGridControls,
  useInterval,
  useLocalSidebarState,
  useRefresh,
  useSearchFormParams,
  useShipmentEntityTags,
  useShipperShipmentListData,
  useUserData,
  useUxState,
} from '../../hooks';
import classnames from 'classnames';
import { getRouteProperties } from '../../utils';
import { handleToggleShipmentTableGroup } from '../../stores';
import { ShipmentsDashboardSidebarWrapper } from '..';
import { TPageId } from '../../types';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './ShipmentShipperList.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];
const PAGE_SIZE = 10;
const GAP_INTERVAL = 1;
const DEFAULT_COLUMNS = [
  'shipperStatus',
  'price',
  'shipmentRef',
  'action',
  'shipperReferenceNumber',
  'shipmentType',
  'origin',
  'destination',
  'destinationReferenceNumber',
  'poNumber',
  'carrierName',
  'pickupDate',
  'equipmentCodes',
  'accessorialCodes',
  'dateCreated',
  'dateUpdated',
];

const DEFAULT_SORT: GridSortModel = [
  {
    field: 'pickupDate',
    sort: 'asc',
  },
];

type TShipmentShipperListProps = {
  pageId: TPageId;
};

const TABLE_ID = 'TRUXWEB_SHIPPER_SHIPMENT_TABLE';
export const ShipmentShipperList = ({ pageId }: TShipmentShipperListProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const dispatch = useDispatch();
  const router = useRouter();

  const {
    locale,
    query: { comments, documents, orderSummary, shipmentRef },
  } = router;

  const [hasInit, setInit] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableFilters, setTableFilters] = useState<null | GridFilterModel>(null);
  const { userData } = useUserData();
  const { handleRefresh, shouldRefresh } = useRefresh();
  const tags = useShipmentEntityTags(shouldRefresh);
  const { data: searchParams, hasLoaded: haveSearchParamsLoaded } = useSearchFormParams();
  const uxState = useUxState();

  const onClose = useCallback(() => {
    const { route } = getRouteProperties(locale, pageId);
    router.push(route);
  }, [router, locale, pageId]);
  const { handleSidebarClose, handleSidebarOpen, isSidebarOpen } = useLocalSidebarState(onClose);

  const [toggleTimeout, setToggleTimeout] = useState(null);
  const handleToggleRefresh = useCallback(() => {
    if (toggleTimeout) clearTimeout(toggleTimeout);
    const newTimeout = setTimeout(() => {
      handleRefresh();
      localStorage.setItem(`${TABLE_ID}_FILTERS`, JSON.stringify(uxState.shipmentTableGroup));
    }, 500);
    setToggleTimeout(newTimeout);
  }, [toggleTimeout, setToggleTimeout, handleRefresh, uxState]);

  const handleSetToggles = useCallback(
    (toggles: string[]) => {
      dispatch(
        handleToggleShipmentTableGroup({
          forceToggle: false,
          forceToggleSet: true,
          statusGroups: toggles,
        })
      );
      handleToggleRefresh();
    },
    [dispatch, handleToggleRefresh]
  );
  const handleTableInit = useCallback(() => {
    if (!hasInit) {
      const storedFilters = localStorage.getItem(`${TABLE_ID}_FILTERS`);
      if (storedFilters) {
        handleSetToggles(JSON.parse(storedFilters));
      }
    }

    setInit(true);
  }, [hasInit, setInit, handleSetToggles]);

  const { columnKeys, manageColumnKeys, manageTableSort, tableSort } = useDataGridControls({
    defaultColumns: DEFAULT_COLUMNS,
    defaultSort: DEFAULT_SORT,
    handleRefresh,
    handleTableInit,
    pageSize: PAGE_SIZE,
    tableId: TABLE_ID,
  });

  const shipmentData = useShipperShipmentListData(
    {
      filter: tableFilters,
      language: transformI18nLocaleToLanguage(locale),
      limit: DEFAULT_PAGE_SIZE,
      page: currentPage,
      pageSize: currentPageSize,
      sort: tableSort,
      t,
      userType: EUserTypeV1.SHIPPER,
    },
    hasInit,
    shouldRefresh
  );
  const { data, hasLoaded, isLoading, rowCount } = shipmentData;
  const classes = useStyles({
    gapInterval: GAP_INTERVAL,
    isLoading,
  });

  const manageTableFilters = useCallback(
    (filter: GridFilterModel) => {
      setTableFilters(filter);
      handleRefresh();
    },
    [setTableFilters, handleRefresh]
  );

  const selectedShipmentRef = useMemo(() => {
    return shipmentRef as string;
  }, [shipmentRef]);

  const selectedSidebarDefaultSection = useMemo(():
    | 'tracking'
    | 'documents'
    | 'comments'
    | 'orderSummary'
    | undefined => {
    if (parseInt(documents as string) > 0) {
      return 'documents';
    }
    if (parseInt(comments as string) > 0) {
      return 'comments';
    }
    if (parseInt(orderSummary as string) > 0) {
      return 'orderSummary';
    }
    return 'tracking';
  }, [documents, comments, orderSummary]);

  const baseRoute = useMemo(() => {
    const { route } = getRouteProperties(locale, pageId);
    return route;
  }, [locale, pageId]);

  const handleSelectShipment = useCallback(
    async (shipmentRef: string, sectionToOpen?: 'documents' | 'comments') => {
      router.push(
        `${baseRoute}?shipmentRef=${shipmentRef}${sectionToOpen ? `&${sectionToOpen}=1` : ''}`
      );
    },
    [router, baseRoute]
  );

  // This is used when single toggles are clicked
  const handleToggle = useCallback(
    (toggle: string, forceSingleToggle: boolean) => {
      dispatch(
        handleToggleShipmentTableGroup({
          forceToggle: forceSingleToggle,
          forceToggleSet: false,
          statusGroups: [toggle],
        })
      );
      handleToggleRefresh();
    },
    [dispatch, handleToggleRefresh]
  );

  // This is used to set the entire toggle set

  const [toggles, toggleExplanations] = useMemo(() => {
    const toggles = [
      {
        group: EShipmentStatusGroupV1.UPCOMING,
        label: t('common:upcoming'),
      },
      {
        group: EShipmentStatusGroupV1.IN_PROGRESS,
        label: t('common:active'),
      },
      {
        group: EShipmentStatusGroupV1.DELIVERED,
        label: t('common:delivered'),
      },
      {
        group: EShipmentStatusGroupV1.COMPLETED,
        label: t('common:completed'),
      },
    ];

    const list = (
      <List key={1}>
        {toggles.map(({ group, label }) => {
          return (
            <ListItem key={group}>
              <Typography color="white">
                <b>{label}</b>:{' '}
                {t(`common:statusGroup-${group}-${EUserTypeV1.SHIPPER}-explanation`)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );

    const statusToggleExplanation = (
      <>
        <Typography color="white" fontStyle="semibold">
          {t('common:statusToggles')}
        </Typography>
        {list}
      </>
    );

    return [toggles, statusToggleExplanation];
  }, [t]);

  useInterval(handleRefresh, QUOTE_TABLE_REFRESH_MS);

  useEffect(() => {
    if (selectedShipmentRef && !isSidebarOpen) {
      handleSidebarOpen();
    }
    if (!selectedShipmentRef && isSidebarOpen) {
      handleSidebarClose();
    }
  }, [selectedShipmentRef, isSidebarOpen, handleSidebarOpen, handleSidebarClose]);

  return (
    <Grid container direction="column">
      <Grid item xs>
        <Box mb={2}>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="space-between"
            xs
          >
            <Grid item xs>
              <Box mr={2}>
                <QuickAccess
                  handleQuickAccess={(shipmentRef: string) => {
                    handleSelectShipment(shipmentRef);
                  }}
                  placeholder={t('common:quickAccessShipment')}
                  value={''}
                />
              </Box>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={7}>
              <Grid item style={{ marginTop: '8px' }} xs>
                <ToggleButtons
                  explanation={toggleExplanations}
                  handleToggle={handleToggle}
                  isDisabled={isLoading}
                  label={t('common:statusToggles')}
                  selectedToggles={uxState.shipmentTableGroup}
                  testId={'ShipperShipmentList'}
                  toggles={toggles}
                />
              </Grid>
              <Grid item>
                {!isLoading && (
                  <Box ml={1} mt={1.5}>
                    <button className={classes.button} disabled={isLoading} onClick={handleRefresh}>
                      <TruxwebRefreshIcon
                        className={classnames(classes.icon, classes.animatedIcon)}
                      />
                    </button>
                  </Box>
                )}
                {isLoading && (
                  <Box ml={1.9} mt={1.75}>
                    <CircularProgress size={16} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid className={classes.tableContainer} item>
        {!tableSort || !haveSearchParamsLoaded ? (
          <></>
        ) : (
          <ShipmentShipperDataGrid
            accessorial={Object.values(searchParams.accessorials)}
            areFiltersEnabled
            availableTags={tags?.data || []}
            columnKeys={columnKeys}
            currentSort={tableSort}
            data={data}
            equipment={Object.values(searchParams.equipment)}
            filterMode={'server'}
            gapInterval={GAP_INTERVAL}
            handleColumnVisibilityChange={manageColumnKeys}
            handleFilterChange={manageTableFilters}
            handleRouteToShipment={handleSelectShipment}
            handleSortingChange={manageTableSort}
            isLoading={!hasLoaded}
            isLoadingPageData={isLoading}
            language={userData.language}
            lastLoadedDate={
              shipmentData?.lastLoadedDate ? new Date(shipmentData?.lastLoadedDate) : undefined
            }
            onPageChange={(page) => {
              setCurrentPage(page);
              handleRefresh();
            }}
            onPageSizeChange={(pageSize) => {
              setCurrentPageSize(pageSize);
              handleRefresh();
            }}
            pageSize={currentPageSize}
            paginationMode={'server'}
            rowCount={rowCount}
            sortingMode={'server'}
            t={t}
          />
        )}
      </Grid>
      <RightHandSidebar
        canExpand
        handleClose={handleSidebarClose}
        isLeftMenuOpen={uxState.sidebarState}
        isOpen={isSidebarOpen}
        testId="ShipmentShipperList"
      >
        <Grid item key={selectedShipmentRef}>
          <ShipmentsDashboardSidebarWrapper
            baseRoute={baseRoute}
            defaultExpandedSection={selectedSidebarDefaultSection}
            handleClose={handleSidebarClose}
            handleListRefresh={handleRefresh}
            shipmentRef={selectedShipmentRef}
          />
        </Grid>
      </RightHandSidebar>
    </Grid>
  );
};
