"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGarlicIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16,5.466V3.5C15.959,1.006,13.82-.265,11.5,0A3.506,3.506,0,0,0,8,3.5V5.466C6.251,6.681.333,11.278.006,17.934A4.726,4.726,0,0,0,4.428,23a4.972,4.972,0,0,0,3.33-1.417,5,5,0,0,0,8.508.029,4.544,4.544,0,0,0,7.728-3.678C23.667,11.278,17.749,6.681,16,5.466ZM10,3.5A1.5,1.5,0,0,1,11.5,2h1A1.5,1.5,0,0,1,14,3.5V5.64a6,6,0,0,0-.851,1.74,1.928,1.928,0,0,0-2.308.007A5.705,5.705,0,0,0,10,5.63Zm-3,15A2.521,2.521,0,0,1,4.458,21a2.687,2.687,0,0,1-2.453-2.968c.259-5.261,4.84-9.209,6.721-10.617a7.805,7.805,0,0,1,.557,2.369A20.812,20.812,0,0,0,7,18.5Zm6.927,2.8a2.975,2.975,0,0,1-2.491.646C6.565,20.732,10.079,12.087,12,8.995,13.8,11.884,16.609,18.877,13.924,21.3Zm7.274-1.06c-1.314,1.6-4.294.428-4.195-1.743a20.9,20.9,0,0,0-2.288-8.72,6.951,6.951,0,0,1,.557-2.366C17.153,8.821,21.736,12.77,22,18.031A3.062,3.062,0,0,1,21.2,20.243Z" })));
exports.default = TruxwebGarlicIcon;
