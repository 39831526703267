import { makeRequestToApiGateway } from '../../apiUtils';
import { TEquipmentV1 } from '@truxweb/schemas';

export const getEquipmentData = async (): Promise<TEquipmentV1[]> => {
  const results = await makeRequestToApiGateway('carrierGetV1Equipment', null, {
    'Content-Type': 'application/json',
  });

  return results as TEquipmentV1[];
};
