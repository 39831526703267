import React, { createContext, useCallback, useEffect, useState } from 'react';
import { fetchUserQuoteRequests } from '../actions';
import { IS_IN_MAINTENANCE } from '../config';
import { TQuoteStatusCountV1 } from '@truxweb/schemas';
import { useUserData } from '../hooks';

type TQuoteCount = TQuoteStatusCountV1 | null;
export type QuoteContextType = {
  updateQuoteCounts?: () => void;
  handleRefetchQuotes?: () => void;
  quoteCounts?: TQuoteCount;
};
export const QuoteContext = createContext<QuoteContextType>({});

type TQuoteProviderProps = {
  children: React.ReactNode;
};

export const QuoteProvider = ({ children }: TQuoteProviderProps): JSX.Element => {
  const { userType } = useUserData();
  const [isQuoteDataLoading, setQuoteDataLoading] = useState(false);
  const [quoteCounts, setQuoteCounts] = useState<TQuoteCount>(null);

  const updateQuoteCounts = useCallback(async () => {
    if (!IS_IN_MAINTENANCE) {
      try {
        setQuoteDataLoading(true);
        const updatedCount = await fetchUserQuoteRequests(userType);
        setQuoteCounts(updatedCount);
      } catch (err) {
        console.error(err);
      }
    }
  }, [setQuoteCounts, userType, setQuoteDataLoading]);

  const handleRefetchQuotes = useCallback(() => {
    setQuoteDataLoading(false);
  }, [setQuoteDataLoading]);

  useEffect(() => {
    if (!isQuoteDataLoading && userType) {
      updateQuoteCounts();
    }
  }, [isQuoteDataLoading, updateQuoteCounts, userType]);

  return (
    <QuoteContext.Provider value={{ handleRefetchQuotes, quoteCounts, updateQuoteCounts }}>
      {children}
    </QuoteContext.Provider>
  );
};
