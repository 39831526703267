"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCornIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M13,10a1,1,0,0,1-2,0A1,1,0,0,1,13,10Zm9.994,2.2C22.377,20.307,16.862,24,12,24S1.623,20.307,1.006,12.2A2.033,2.033,0,0,1,3.73,10.137a12.579,12.579,0,0,1,1.3.621c1.535-14.263,12.42-14.249,13.95,0a12.6,12.6,0,0,1,1.3-.622A2.033,2.033,0,0,1,22.994,12.2ZM10.959,21.925C10.6,20.582,8.634,14.225,2.994,12,3.534,19.066,7.861,21.491,10.959,21.925ZM12,18.861A19.625,19.625,0,0,1,14.186,15,1.007,1.007,0,1,1,14,13h1.9a14.241,14.241,0,0,1,1.126-.977c-.012-.344-.012-.688-.037-1.023H16a1,1,0,0,1,0-2h.733A14.73,14.73,0,0,0,16.2,7H14a1,1,0,0,1,0-2h1.323c-2.21-3.944-4.436-3.944-6.646,0H10a1,1,0,0,1,0,2s-2.123-.025-2.185-.037A14.865,14.865,0,0,0,7.267,9H8a1,1,0,0,1,0,2H7.012c-.025.335-.025.679-.037,1.023A14.064,14.064,0,0,1,8.1,13H10a1.007,1.007,0,1,1-.186,2A19.625,19.625,0,0,1,12,18.861Zm9-6.813c-5.635,2.182-7.6,8.535-7.959,9.877C16.139,21.492,20.465,19.072,21,12.048Z" })));
exports.default = TruxwebCornIcon;
