"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountAddresses = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const AccountAddresses = ({ companyLocations, defaultCountryCode, handleAddressSubmit, isAddAddressEnabled, isFormDisabled, isHeaderSuppressed, t, }) => {
    const [isAddAddressFormShown, setIsAddAddressFormShown] = (0, react_1.useState)(false);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true }, !isAddAddressEnabled && (react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
            react_1.default.createElement(ux_1.Button, { color: "primary", onClick: () => {
                    setIsAddAddressFormShown(!isAddAddressFormShown);
                } },
                react_1.default.createElement(ux_1.Box, { mr: 0.5 }, isAddAddressFormShown ? react_1.default.createElement(ux_1.RemoveCircleOutlineIcon, null) : react_1.default.createElement(ux_1.AddCircleOutlineIcon, null)),
                react_1.default.createElement(ux_1.Box, { ml: 0.5 }, t('common:addNewAddress')))))),
        !isHeaderSuppressed && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(__1.InteriorHeader, null, t('common:addresses')))),
        isAddAddressFormShown && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 3 },
                react_1.default.createElement(__1.AccountAddress, { defaultCountryCode: defaultCountryCode, handleAddressSubmit: handleAddressSubmit, isFormDisabled: isFormDisabled, isNewAddress: true, t: t })))),
        (companyLocations || []).map((address) => {
            return (react_1.default.createElement(ux_1.Grid, { item: true, key: address.id, xs: true },
                react_1.default.createElement(ux_1.Box, { mb: 3 },
                    react_1.default.createElement(__1.AccountAddress, { address: address, defaultCountryCode: defaultCountryCode, handleAddressSubmit: handleAddressSubmit, isFormDisabled: isFormDisabled, isNewAddress: false, t: t }))));
        })));
};
exports.AccountAddresses = AccountAddresses;
