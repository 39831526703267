export const getEndpoint = (
  requestAlias: string,
  endpoint: string,
  params?: Record<string, any>
): string => {
  const requiredParams = getRequiredParams(endpoint);
  if (requiredParams?.length && !params) {
    throw new Error(`${requestAlias} is missing required params: ${requiredParams.join()}`);
  } else if (requiredParams?.length && params) {
    endpoint = applyParams(requestAlias, endpoint, params, requiredParams);
  }
  return endpoint;
};
export const getRequiredParams = (endpoint: string): string[] => {
  return endpoint.match(/\{(.*?)\}/g);
};

export const applyParams = (
  requestAlias: string,
  endpoint: string,
  params: Record<string, any>,
  requiredParams: string[]
): string => {
  requiredParams.forEach((paramName) => {
    const formattedParam = paramName.replace('{', '').replace('}', '');
    if (params[formattedParam] === undefined) {
      throw new Error(`${requestAlias} is missing required params ${formattedParam}`);
    }
    endpoint = endpoint.replace(paramName, params[formattedParam]);
  });

  return endpoint;
};
