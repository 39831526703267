import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TCompanyLocationV1 } from '@truxweb/schemas';
import { type TReducerLoading } from '../types';

export type TCompanyLocation = TReducerLoading & {
  data: TCompanyLocationV1;
};

export type TCompanyLocationListLoading = TReducerLoading & {
  data: boolean;
};

export interface ICompanyLocationDataState {
  companyLocations: Record<string, TCompanyLocation>;
  companyLocationList: TCompanyLocationListLoading;
}

export const companyLocationSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.companyLocations,
      };
    },
  },
  initialState: {
    companyLocationList: { ...baseLoadingState, data: false },
    companyLocations: {},
  } as ICompanyLocationDataState,
  name: 'companyLocations',
  reducers: {
    setCompanyLocationData: (state, { payload: { items, shouldOverwriteList } }) => {
      const companyLocations: ICompanyLocationDataState['companyLocations'] = {};
      items.forEach((item: TCompanyLocationV1) => {
        companyLocations[item.id] = manageDomainLoaded(state.companyLocations[item.id], item);
      });

      const updatedState = shouldOverwriteList
        ? { ...companyLocations }
        : {
            ...state.companyLocations,
            ...companyLocations,
          };

      return {
        ...state,
        companyLocations: updatedState,
      };
    },
    setCompanyLocationListError: (state, { payload: { error } }) => {
      return { ...state, companyLocationList: manageDomainError(state.companyLocationList, error) };
    },
    setCompanyLocationListLoaded: (state, { payload: { isProgressiveLoading } }) => {
      return {
        ...state,
        companyLocationList: manageDomainLoaded(
          state.companyLocationList,
          true,
          isProgressiveLoading
        ),
      };
    },
    setCompanyLocationListLoading: (state) => {
      return { ...state, companyLocationList: manageDomainLoading(state.companyLocationList) };
    },
  },
});

export const {
  actions: {
    setCompanyLocationData,
    setCompanyLocationListError,
    setCompanyLocationListLoaded,
    setCompanyLocationListLoading,
  },
} = companyLocationSlice;
