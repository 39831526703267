// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteShipperCancellationReasonV1 = void 0;
var QuoteShipperCancellationReasonV1;
(function (QuoteShipperCancellationReasonV1) {
    QuoteShipperCancellationReasonV1["CREATED_IN_ERROR"] = "CREATED_IN_ERROR";
    QuoteShipperCancellationReasonV1["SHIPPER_REQUESTED_CANCELLATION"] = "SHIPPER_REQUESTED_CANCELLATION";
})(QuoteShipperCancellationReasonV1 || (exports.QuoteShipperCancellationReasonV1 = QuoteShipperCancellationReasonV1 = {}));
