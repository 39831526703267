import { type TReducerLoading } from '../types';

export const baseLoadingState: TReducerLoading = {
  data: null,
  error: null,
  hasLoaded: false,
  isLoading: false,
  isProgressiveLoading: false,
  lastLoadedDate: null,
  rowCount: 0,
};

export const manageDomainLoaded = (
  state: TReducerLoading,
  data: unknown,
  isProgressiveLoading?: boolean,
  rowCount?: number
): TReducerLoading => {
  const newState = { ...state } || { ...baseLoadingState, data: null };
  newState.data = data;
  newState.rowCount = rowCount;
  newState.isLoading = false;
  newState.isProgressiveLoading = isProgressiveLoading || false;
  newState.hasLoaded = true;
  newState.error = null;
  newState.lastLoadedDate = new Date().toISOString();

  return newState;
};

export const manageDomainLoading = (state?: TReducerLoading): TReducerLoading => {
  const newState =
    state !== undefined
      ? { ...state }
      : {
          ...baseLoadingState,
          data: null,
        };

  newState.isLoading = true;
  newState.error = null;
  return newState;
};

export const manageDomainError = (
  state: TReducerLoading,
  error: string,
  isFatal?: boolean
): TReducerLoading => {
  const newState = { ...state } || { ...baseLoadingState, data: null };
  newState.error = error;
  newState.isLoading = false;
  newState.hasLoaded = true;
  newState.lastLoadedDate = new Date().toISOString();
  if (isFatal) {
    newState.data = null;
  }
  return newState;
};
