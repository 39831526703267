import {
  EShipmentLocationTypeV1,
  type TGooglePlaceV1,
  type TQuoteSearchFormDataV1,
} from '@truxweb/schemas';

//Defaulting to 3 as this was the example from the JIRA ticket. TO DO: these constants should be promoted to environment variables
const TRUX_KEY = 'TRUX_FAVORITES';
const TOTAL_PLACES_TO_DISPLAY = 3;
const TOTAL_RESULTS_IN_STORAGE = 25;

const setLocalStorageKey = (): string => {
  return `${TRUX_KEY}`;
};

export const saveToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const deleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getItemFromLocalStorageAsType = <T>(key: string): T => {
  return JSON.parse(localStorage.getItem(key));
};

export const clearTruxwebLocalStorage = () => {
  const keys = Object.keys(localStorage).filter((key: string) => {
    return key.includes(TRUX_KEY);
  });

  keys.forEach((key) => {
    deleteFromLocalStorage(key);
  });
};

export const getRecentSeaches = (key: string): TQuoteSearchFormDataV1[] => {
  return getItemFromLocalStorageAsType<TQuoteSearchFormDataV1[]>(key);
};

//Get the 3 most recent GooglPlaces to pre-populate autocomplete options
export const getRecentSeachesByLocationType = (
  locationType: EShipmentLocationTypeV1
): TGooglePlaceV1[] => {
  //Check to see if the user has any recent search results saved to local storage
  //filter out any duplicate results or where the GooglePlace is undefined .

  const recentSearches = getRecentSeaches(setLocalStorageKey());

  const searches = (recentSearches || [])
    .map((result) => {
      if (locationType === EShipmentLocationTypeV1.DESTINATION) {
        return result.destination.place;
      }
      return result.origin.place;
    })
    .filter((item) => item)
    .slice(-TOTAL_PLACES_TO_DISPLAY);

  return Array.from(new Set(searches));
};

//Store recent searches in Local Storage for now.  This will eventually be stored in a SQL table
export const addSearchToLocalStorage = (searchQuery: TQuoteSearchFormDataV1) => {
  const storageKey = setLocalStorageKey();
  const recentSearches = getRecentSeaches(storageKey) || [];

  if (searchQuery.origin.place && searchQuery.destination.place) {
    const googlePlacesKey = `${searchQuery.origin.place.place_id}_${searchQuery.destination.place.place_id}`;
    //Avoid duplicate entries. Lets check to see if the address result already exists
    const isExistingSearchResult = recentSearches
      .map((searchResults) => {
        return searchResults.origin.place && searchResults.destination.place
          ? `${searchResults.origin.place.place_id}_${searchResults.destination.place.place_id}`
          : [];
      })
      .includes(googlePlacesKey);

    if (!isExistingSearchResult) {
      recentSearches.push(searchQuery);

      //remove legacy  search results
      if (recentSearches.length >= TOTAL_RESULTS_IN_STORAGE)
        recentSearches.splice(0, recentSearches.length - TOTAL_RESULTS_IN_STORAGE);

      //update local storage
      saveToLocalStorage(storageKey, recentSearches);
    }
  }
};
