// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessorialTimeOptionV1 = void 0;
var AccessorialTimeOptionV1;
(function (AccessorialTimeOptionV1) {
    AccessorialTimeOptionV1["AM"] = "AM";
    AccessorialTimeOptionV1["PM"] = "PM";
    AccessorialTimeOptionV1["CARRIER_CHOICE"] = "CARRIER_CHOICE";
})(AccessorialTimeOptionV1 || (exports.AccessorialTimeOptionV1 = AccessorialTimeOptionV1 = {}));
