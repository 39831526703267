"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLocationAltIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M256,341.333c9.934,0.019,19.735-2.274,28.629-6.699l42.667-21.333c21.747-10.798,35.465-33.021,35.371-57.301v-63.296  c0.075-24.259-13.641-46.453-35.371-57.237l-42.667-21.333c-18.027-8.96-39.21-8.96-57.237,0l-42.667,21.333  c-21.738,10.778-35.463,32.974-35.392,57.237V256c-0.078,24.265,13.637,46.467,35.371,57.259l42.667,21.333  C236.261,339.031,246.063,341.339,256,341.333z M192,256v-63.296c0.034-2.176,0.401-4.335,1.088-6.4l46.507,23.253  c10.328,5.162,22.483,5.162,32.811,0l46.507-23.253c0.687,2.065,1.054,4.224,1.088,6.4V256c0.034,8.094-4.543,15.502-11.797,19.093  l-42.667,21.333c-6.016,2.977-13.077,2.977-19.093,0l-42.667-21.333C196.53,271.496,191.962,264.09,192,256z M405.333,469.333  h-57.792l74.389-68.736c91.645-91.642,91.647-240.224,0.005-331.869S181.712-22.918,90.067,68.723S-1.58,308.948,90.062,400.592  c0.201,0.201,74.397,68.741,74.397,68.741h-57.792c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333  h298.667c11.782,0,21.333-9.551,21.333-21.333C426.667,478.885,417.115,469.333,405.333,469.333z M120.235,98.901  c74.816-75.152,196.389-75.424,271.541-0.608s75.424,196.389,0.608,271.541l-85.013,78.528  c-29.016,27.349-74.366,27.19-103.189-0.363l-83.947-77.568C45.37,295.402,45.371,173.931,120.235,98.901z" })));
exports.default = TruxwebLocationAltIcon;
