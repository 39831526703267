"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHockeySticksIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.974,17.963a5.524,5.524,0,0,0-4.015-4.726L22.875,3.7A2.866,2.866,0,0,0,20.133,0a2.983,2.983,0,0,0-2.87,2.125L14.8,10.162A3.972,3.972,0,0,1,12,12.865a4,4,0,0,1-2.8-2.7L6.766,2.128A2.983,2.983,0,0,0,3.9,0,2.866,2.866,0,0,0,1.152,3.7l2.9,9.539A5.459,5.459,0,0,0,1.43,22.2,5.509,5.509,0,0,0,5.5,24H9.269a10.121,10.121,0,0,0,2.743-.377l.011,0A9.969,9.969,0,0,0,14.73,24H18.5a5.5,5.5,0,0,0,5.474-6.037ZM3.2,2.351A.858.858,0,0,1,3.9,2a1,1,0,0,1,.957.709l2.439,8.034A5.989,5.989,0,0,0,8.562,13H6.067l-3-9.882A.859.859,0,0,1,3.2,2.351ZM5.5,22a3.5,3.5,0,0,1-3.483-3.847A3.634,3.634,0,0,1,5.693,15h5.275a5.965,5.965,0,0,0,5.74-4.254l2.467-8.034v0A.994.994,0,0,1,20.133,2a.867.867,0,0,1,.829,1.116L16.924,16.324A7.954,7.954,0,0,1,9.269,22Zm15.591-1.147A3.508,3.508,0,0,1,18.5,22H15.292a9.976,9.976,0,0,0,3.545-5.093l.537-1.757a3.524,3.524,0,0,1,2.609,3A3.47,3.47,0,0,1,21.091,20.853Z" })));
exports.default = TruxwebHockeySticksIcon;
