import { CircularProgress, Grid } from '@truxweb/ux';
import React, { useEffect } from 'react';
import { useAlerts, usePublicCarrierProfileData } from '../../hooks';
import { CARRIER_PROFILE_BUCKET } from '../../config';
import { CarrierProfileMobile } from '@truxweb/common-components';
import { getPublicS3BucketUrl } from '../../apiUtils';
import Image from 'next/image';
import { PageLink } from '..';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TCarrierProfileSidebarWrapperProps = {
  companyCode: string;
  handleCloseSideBar: () => void;
};

export const CarrierProfileSidebarWrapper = ({
  companyCode,
  handleCloseSideBar,
}: TCarrierProfileSidebarWrapperProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { locale } = useRouter();
  const { addAlert } = useAlerts();

  const language = transformI18nLocaleToLanguage(locale);
  const [carrierData, hasResponseLoaded] = usePublicCarrierProfileData(companyCode, language);
  const isCarrierDataValid = (carrierData?.carrierProfileRecord?.length || 0) > 0;

  useEffect(() => {
    if (hasResponseLoaded && !isCarrierDataValid) {
      if (handleCloseSideBar) handleCloseSideBar();

      addAlert({ message: t('common:errorLoadingTemporary'), severity: 'error' });
    }
  }, [hasResponseLoaded, isCarrierDataValid, handleCloseSideBar, t, addAlert]);

  if (!(hasResponseLoaded && isCarrierDataValid)) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  const profileImageUrl = getPublicS3BucketUrl(
    CARRIER_PROFILE_BUCKET,
    carrierData.carrierProfileRecord[0].logoLink
  );

  return (
    <CarrierProfileMobile
      data-testid={'carrier-profile-mobile'}
      language={language}
      profile={carrierData}
      profileImage={<Image height={48} objectFit="contain" src={profileImageUrl} width={220} />}
      renderCompanyProfileLink={() => {
        return (
          <PageLink
            pageId={'carrier/[carrier]'}
            routeParams={[companyCode]}
            shouldUseModernVariant
            target={'carrier'}
          >
            {t('common:viewFullProfile')}
          </PageLink>
        );
      }}
      t={t}
    />
  );
};
