// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCardTypeV1 = void 0;
var CreditCardTypeV1;
(function (CreditCardTypeV1) {
    CreditCardTypeV1["AmericanExpress"] = "American Express";
    CreditCardTypeV1["Discover"] = "Discover";
    CreditCardTypeV1["Mastercard"] = "Mastercard";
    CreditCardTypeV1["Visa"] = "Visa";
    CreditCardTypeV1["Unknown"] = "Unknown";
})(CreditCardTypeV1 || (exports.CreditCardTypeV1 = CreditCardTypeV1 = {}));
