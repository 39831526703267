"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShipmentDocumentRequiredStatus = exports.getShipmentDocumentReadState = void 0;
/* eslint-disable complexity */
const schemas_1 = require("@truxweb/schemas");
const getShipmentDocumentReadState = (document, viewingUserType) => {
    if (!document)
        return false;
    // If this this is a shipper viewing a shipper document, return the carrier read state
    if (document.documentOwnerType === schemas_1.EUserTypeV1.SHIPPER &&
        viewingUserType === schemas_1.EUserTypeV1.SHIPPER &&
        document.isVisibleByCarrier) {
        return document.readByCarrier || false;
    }
    // if this is a carrier viewing a carrier document, return the shipper read state
    if (document.documentOwnerType === schemas_1.EUserTypeV1.CARRIER &&
        viewingUserType === schemas_1.EUserTypeV1.CARRIER &&
        document.isVisibleByShipper) {
        return document.readByShipper || false;
    }
    // otherwise return the read state for the user
    if (viewingUserType === schemas_1.EUserTypeV1.SHIPPER)
        return document.readByShipper || false;
    if (viewingUserType === schemas_1.EUserTypeV1.CARRIER)
        return document.readByCarrier || false;
    return false;
};
exports.getShipmentDocumentReadState = getShipmentDocumentReadState;
const getShipmentDocumentRequiredStatus = (document, documentOwnerType, viewingUserType, matchingRequiredDoc) => {
    if (documentOwnerType !== viewingUserType && viewingUserType !== schemas_1.EUserTypeV1.EVIL_SUPER_ADMIN)
        return false;
    let ret = false;
    switch (documentOwnerType) {
        case schemas_1.EUserTypeV1.CARRIER:
            ret = matchingRequiredDoc
                ? Boolean(matchingRequiredDoc.isRequiredForCarrier)
                : Boolean(document.requiredForCarrier);
            break;
        case schemas_1.EUserTypeV1.SHIPPER:
            ret = matchingRequiredDoc
                ? Boolean(matchingRequiredDoc.isRequiredForShipper)
                : Boolean(document.requiredForShipper);
            break;
        default:
            break;
    }
    return ret;
};
exports.getShipmentDocumentRequiredStatus = getShipmentDocumentRequiredStatus;
