import { ELanguageV1, TInAppNotificationV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const getUserNotifications = async (
  language: ELanguageV1
): Promise<TInAppNotificationV1[]> => {
  const ret = await makeRequestToApiGateway(
    'notificationGetV1NotificationsByLanguage',
    { language },
    {
      'Content-Type': 'application/json',
    }
  );
  return (ret || []) as TInAppNotificationV1[];
};
