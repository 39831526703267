// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionV1 = void 0;
var PermissionV1;
(function (PermissionV1) {
    PermissionV1["VIEW_CARRIER_PAGE"] = "VIEW_CARRIER_PAGE";
    PermissionV1["VIEW_SHIPPER_PAGE"] = "VIEW_SHIPPER_PAGE";
    PermissionV1["MANAGE_CARRIER_DATA"] = "MANAGE_CARRIER_DATA";
    PermissionV1["MANAGE_OWN_DATA"] = "MANAGE_OWN_DATA";
    PermissionV1["MANAGE_SHIPPER_DATA"] = "MANAGE_SHIPPER_DATA";
    PermissionV1["MANAGE_OTHER_CARRIERS_DATA"] = "MANAGE_OTHER_CARRIERS_DATA";
    PermissionV1["MANAGE_OTHER_SHIPPERS_DATA"] = "MANAGE_OTHER_SHIPPERS_DATA";
    PermissionV1["BOOK_SHIPMENT"] = "BOOK_SHIPMENT";
    PermissionV1["VIEW_SEARCH_RESULTS"] = "VIEW_SEARCH_RESULTS";
    PermissionV1["CAN_SET_NON_TAXABLE_SHIPMENT"] = "CAN_SET_NON_TAXABLE_SHIPMENT";
    PermissionV1["CARRIER_DOES_NOT_COLLECT_TAX"] = "CARRIER_DOES_NOT_COLLECT_TAX";
    PermissionV1["SAVE_LOAD_FROM_SEARCH"] = "SAVE_LOAD_FROM_SEARCH";
    PermissionV1["VIEW_SAVED_LOAD"] = "VIEW_SAVED_LOAD";
    PermissionV1["EXPORT_REPORT_DATA"] = "EXPORT_REPORT_DATA";
    PermissionV1["VIEW_EXTENDED_REPORT"] = "VIEW_EXTENDED_REPORT";
    PermissionV1["VIEW_LTL_INSTANT_PRICING"] = "VIEW_LTL_INSTANT_PRICING";
    PermissionV1["VIEW_FTL_INSTANT_PRICING"] = "VIEW_FTL_INSTANT_PRICING";
    PermissionV1["CAN_SEARCH_RANK_BE_BOOSTED"] = "CAN_SEARCH_RANK_BE_BOOSTED";
    PermissionV1["SHARE_SHIPMENT"] = "SHARE_SHIPMENT";
    PermissionV1["SEARCH_FROM_SHIPMENT"] = "SEARCH_FROM_SHIPMENT";
})(PermissionV1 || (exports.PermissionV1 = PermissionV1 = {}));
