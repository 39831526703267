"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTruckPlowIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M505.365,436.248c-26.521-26.377-41.386-62.268-41.282-99.672v-76.7c-0.104-37.404,14.761-73.295,41.282-99.672  c8.235-8.237,8.235-21.59,0-29.828c-42.189-34.152-89.716,97.394-83.472,129.5v17.255h-44.868  c-6.051-23.274-21.712-42.877-43.075-53.918l-0.232-0.338L241.26,90.253c-19.719-28.292-52.023-45.157-86.509-45.164h-7.088  c-34.951,0-63.284,28.333-63.284,63.284v105.473C37.778,213.847,0,251.625,0,298.226v42.189  c0.008,15.065,8.048,28.982,21.095,36.515v16.222c0,40.776,33.055,73.831,73.831,73.831s73.831-33.055,73.831-73.831v-10.547h42.189  v10.547c0,40.776,33.055,73.831,73.831,73.831s73.831-33.055,73.831-73.831V376.93c13.047-7.532,21.087-21.45,21.095-36.515V319.32  h42.189v17.255c-0.132,48.599,19.185,95.23,53.644,129.5l1.308,1.329C497.033,486.643,524.899,455.276,505.365,436.248z   M126.568,108.374c0-11.65,9.444-21.095,21.095-21.095h7.088c20.714-0.022,40.129,10.094,51.977,27.086l69.275,99.482H126.568  V108.374z M42.189,298.226c0-23.301,18.889-42.189,42.189-42.189h210.947c23.301,0,42.189,18.889,42.189,42.189v42.189H42.189  V298.226z M126.568,393.152c0,17.475-14.167,31.642-31.642,31.642s-31.642-14.167-31.642-31.642v-10.547h63.284V393.152z   M284.778,424.794c-17.475,0-31.642-14.167-31.642-31.642v-10.547h63.284v10.547C316.42,410.627,302.253,424.794,284.778,424.794z" })));
exports.default = TruxwebTruckPlowIcon;
