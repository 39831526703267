"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTruckContainerIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M1,15H13a1,1,0,0,0,1-1V5.5A4.505,4.505,0,0,0,9.5,1h-5A4.505,4.505,0,0,0,0,5.5V14A1,1,0,0,0,1,15ZM2,5.5A2.5,2.5,0,0,1,4.5,3h5A2.5,2.5,0,0,1,12,5.5V13H2ZM6,6v4a1,1,0,0,1-2,0V6A1,1,0,0,1,6,6Zm4,0v4a1,1,0,0,1-2,0V6A1,1,0,0,1,10,6Zm13.641,4.76-.9-2.7C21.988,4.949,16.319,3.4,16,7.5V17H1a1,1,0,0,0-1,1v1.5a3.5,3.5,0,0,0,3.857,3.482,3.4,3.4,0,0,0,2.12-1.059A3.517,3.517,0,0,0,12,19.5V19h4v.5a3.5,3.5,0,0,0,7,0v-.769A2,2,0,0,0,24,17V12.974A6.989,6.989,0,0,0,23.641,10.76ZM18,17V13h4v4Zm.5-10a2.467,2.467,0,0,1,2.343,1.688L21.613,11H18V7.5A.5.5,0,0,1,18.5,7ZM3.658,20.992A1.5,1.5,0,0,1,2,19.5V19H5v.357A1.594,1.594,0,0,1,3.658,20.992ZM10,19.5A1.535,1.535,0,1,1,7,19h3ZM19.5,21A1.5,1.5,0,0,1,18,19.5V19h3v.5A1.5,1.5,0,0,1,19.5,21Z" })));
exports.default = TruxwebTruckContainerIcon;
