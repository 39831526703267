// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessorialDurationOptionV1 = void 0;
var AccessorialDurationOptionV1;
(function (AccessorialDurationOptionV1) {
    AccessorialDurationOptionV1["FOUR_HOURS"] = "4_HOURS";
    AccessorialDurationOptionV1["EIGHT_HOURS"] = "8_HOURS";
    AccessorialDurationOptionV1["NEXT_DAY"] = "NEXT_DAY";
})(AccessorialDurationOptionV1 || (exports.AccessorialDurationOptionV1 = AccessorialDurationOptionV1 = {}));
