import { makeStyles, Theme } from '@truxweb/ux';

type TMobileDropDownMenuHeaderProps = {
  footerPalette: 'light' | 'dark';
  isOpen: boolean;
};

export const useStyles = makeStyles<Theme, TMobileDropDownMenuHeaderProps>((theme: Theme) => ({
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  dropDownMenuHeader: {
    '& *': {
      overflow: 'hidden',
    },

    display: 'flex',
    height: ({ isOpen }) => {
      return isOpen ? 'auto' : 0;
    },
    justifyContent: 'center',
  },
  icon: {
    color: ({ footerPalette }) => {
      return footerPalette === 'light' ? theme.palette.primary.main : theme.palette.common.white;
    },
  },
  siteMapHeader: {
    color: ({ footerPalette }) => {
      return footerPalette === 'light' ? theme.palette.primary.main : theme.palette.common.white;
    },
    fontSize: '15px',
    lineHeight: '11px',
    paddingBottom: theme.spacing(0.25),
  },
  siteMapLink: {
    '& p': {
      '&:hover': {
        color: ({ footerPalette }) => {
          return footerPalette === 'light'
            ? theme.palette.primary.dark
            : theme.palette.primary.main;
        },
      },
      color: ({ footerPalette }) => {
        return footerPalette === 'light' ? theme.palette.primary.main : theme.palette.common.white;
      },
    },
  },
  transitionIn: {
    animation: '$fade-in 0.3s ease-in-out',
  },
}));
