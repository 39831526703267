import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { hasWindow } from '../../utils';
import { LandingPageButton } from '..';
import React from 'react';
import TruckTruxi from '../../public/assets/images/landingPage/bottom-header.svg';
import { useRouter } from 'next/router';
import { useStyles } from './ResponsiveLandingPageHero.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const ResponsiveLandingPageHero = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const router = useRouter();

  const handleClick = () => {
    if (hasWindow()) {
      router.push('/contact-us');
    }
  };

  return (
    <>
      <Box className={classes.wrapper} data-testid="ResponsiveLandingPageHero">
        <Box className={classes.overlay} />
        <Box className={classes.text}>
          <Grid container direction="column">
            <Typography className={classes.freight} variant="h1">
              {t('common:landingFreight')}{' '}
              <span className={classes.accent}>{t('common:rebooted')}</span>
            </Typography>
            <Box mb={isDesktop ? 3.5 : 2} mt={2}>
              <Typography className={classes.subtitle} variant="body2">
                {t('common:weHelpLogisticsTeams')}
              </Typography>
            </Box>
            <LandingPageButton
              isSubmit
              onClick={handleClick}
              text={t('common:speakWithOurTeam')}
              type="standard"
            ></LandingPageButton>
          </Grid>
        </Box>
        <Box className={classes.truck}>
          <TruckTruxi className={classes.truckTruxi} />
        </Box>
      </Box>
    </>
  );
};
