"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebButterflyIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.5,12.932A8.184,8.184,0,0,0,24,7.284C24,1.63,21.233,1.02,20.044,1.02c-2.968,0-5.884,2.9-8.041,5.521C9.862,3.942,6.937,1.02,3.961,1.02-.188.76-1.706,9.091,2.506,12.933c.176.174.359.337.544.49A6.554,6.554,0,0,0,1.33,20.11a4.02,4.02,0,0,0,2.3,2.574,3.914,3.914,0,0,0,3.355-.131A16.382,16.382,0,0,0,11,19.621V22a1,1,0,0,0,2,0V19.621a16.351,16.351,0,0,0,4.018,2.931,3.911,3.911,0,0,0,3.355.132,4.021,4.021,0,0,0,2.3-2.573A6.567,6.567,0,0,0,21,13.384C21.171,13.242,21.338,13.092,21.5,12.932ZM11,15.636c0,1.478-2.08,3.642-4.947,5.146a1.891,1.891,0,0,1-1.647.06,2.027,2.027,0,0,1-1.158-1.3,4.6,4.6,0,0,1,1.566-5.038A6.25,6.25,0,0,0,7.206,15,9.811,9.811,0,0,0,11,14.293Zm0-3.651A6.3,6.3,0,0,1,7.18,13a4.559,4.559,0,0,1-3.27-1.49,6.234,6.234,0,0,1-1.9-4.225c0-2.71.713-4.264,1.955-4.264C5.746,3.02,8.106,4.858,11,8.491ZM13,8.5C15.88,4.87,18.252,3.02,20.044,3.02,21.287,3.02,22,4.574,22,7.284a6.236,6.236,0,0,1-1.9,4.225A4.691,4.691,0,0,1,16.87,13,6.435,6.435,0,0,1,13,11.98Zm7.752,11.048a2.025,2.025,0,0,1-1.158,1.3,1.892,1.892,0,0,1-1.647-.061C15.08,19.278,13,17.114,13,15.636V14.291A9.812,9.812,0,0,0,16.8,15a6.094,6.094,0,0,0,2.424-.51A4.535,4.535,0,0,1,20.752,19.544ZM10,2a2,2,0,0,1,4,0A2,2,0,0,1,10,2Z" })));
exports.default = TruxwebButterflyIcon;
