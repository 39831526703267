import { TUseActionResponse, useAction } from './useAction';
import { listUserCreditCardsV2 } from '../actions';
import { TCreditCardResponseV2 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCreditCards = (
  shouldForceRefetch?: boolean
): TUseActionResponse<TCreditCardResponseV2[]> => {
  const action = useCallback(async (): Promise<TCreditCardResponseV2[]> => {
    return await listUserCreditCardsV2();
  }, []);

  return useAction<TCreditCardResponseV2[]>(action, true, shouldForceRefetch);
};
