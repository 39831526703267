import { makeStyles, Theme } from '@truxweb/ux';

type TResponsiveFooterLegalStyleProps = {
  footerPalette: 'light' | 'dark';
};

export const useStyles = makeStyles<Theme, TResponsiveFooterLegalStyleProps>((theme: Theme) => ({
  copyright: {
    color: ({ footerPalette }) => {
      return footerPalette === 'light' ? theme.palette.grey[400] : theme.palette.common.white;
    },
  },
  linkClass: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
    color: ({ footerPalette }) => {
      return footerPalette === 'light' ? theme.palette.grey[400] : theme.palette.common.white;
    },
  },
  socialLinks: {
    marginLeft: 'auto',
    maxWidth: '150px',
  },
}));

export const useDividerStyle = makeStyles<Theme, TResponsiveFooterLegalStyleProps>(
  (theme: Theme) => ({
    flexItem: {
      backgroundColor: ({ footerPalette }) => {
        return footerPalette === 'light' ? theme.palette.grey[400] : theme.palette.common.white;
      },
      height: `0.5px`,
    },
  })
);
