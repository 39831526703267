"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    header: {
        fontWeight: 600,
    },
    itemBox: {
        borderRadius: `${theme.spacing(1)}px`,
        height: '40px',
    },
}));
