import { type TShipmentCommentAttachment } from '../../types';
import { uploadFileToBucket } from '../../apiUtils';
import { uuid } from '@truxweb/uuid';

export const uploadCommentAttachments = async (
  shipmentRef: string,
  bucket: string,
  files: File[]
): Promise<TShipmentCommentAttachment[]> => {
  const uploads = files.map(async (file): Promise<TShipmentCommentAttachment> => {
    const uploadLocation = `${shipmentRef}/chat/${uuid()}/${file.name}`;

    await uploadFileToBucket(uploadLocation, file, bucket);

    return {
      attachmentLocation: uploadLocation,
      attachmentMimeType: file.type,
      attachmentName: file.name,
    };
  });

  return await Promise.all(uploads);
};
