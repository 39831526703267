"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebVectorAltIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21,16V12a8.983,8.983,0,0,0-3.356-7h2.633a1.991,1.991,0,1,0-.009-2h-4.41A3.981,3.981,0,0,0,8.142,3H3.723a1.991,1.991,0,1,0,.009,2H6.356A8.983,8.983,0,0,0,3,12v4a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3H5a3,3,0,0,0,3-3V19a3,3,0,0,0-3-3V12A7,7,0,0,1,8.521,5.935a3.963,3.963,0,0,0,6.958,0A7,7,0,0,1,19,12v4a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h2a3,3,0,0,0,3-3V19A3,3,0,0,0,21,16ZM6,19v2a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1H5A1,1,0,0,1,6,19ZM12,6a2,2,0,1,1,2-2A2,2,0,0,1,12,6ZM22,21a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" })));
exports.default = TruxwebVectorAltIcon;
