"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarGarageIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.891,6.454a1,1,0,0,1-1.346.437L13.752,2.4a4.02,4.02,0,0,0-3.517.007l-8.78,4.48a1,1,0,0,1-.91-1.782L9.339.623A6.043,6.043,0,0,1,14.648.616l8.807,4.493A1,1,0,0,1,23.891,6.454ZM22.078,13.12A7.022,7.022,0,0,1,23,16.594V18a4,4,0,0,1-2,3.463V22a2,2,0,0,1-4,0H7a2,2,0,0,1-4,0v-.537A4,4,0,0,1,1,18V16.594a7.022,7.022,0,0,1,.922-3.474L3.98,9.52A5.013,5.013,0,0,1,8.321,7h7.358A5.013,5.013,0,0,1,20.02,9.52ZM3.723,14H20.277l-1.993-3.488A3.009,3.009,0,0,0,15.679,9H8.321a3.009,3.009,0,0,0-2.6,1.512ZM21,16.594A5.119,5.119,0,0,0,20.964,16H19v1a1,1,0,0,1-2,0V16H7v1a1,1,0,0,1-2,0V16H3.036A5.119,5.119,0,0,0,3,16.594V18a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2Z" })));
exports.default = TruxwebCarGarageIcon;
