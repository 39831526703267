"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHomeLocationIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.8,5.579,14.8.855A4.979,4.979,0,0,0,9.2.855l-7,4.724A5,5,0,0,0,0,9.724V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9.724A5,5,0,0,0,21.8,5.579ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9.724A3,3,0,0,1,3.322,7.237l7-4.724a2.986,2.986,0,0,1,3.356,0l7,4.724A3,3,0,0,1,22,9.724ZM12,7.006A6,6,0,0,0,7.765,17.257l1.824,1.784a3.465,3.465,0,0,0,4.821,0l1.833-1.792A6,6,0,0,0,12,7.006Zm2.836,8.821-1.825,1.785a1.454,1.454,0,0,1-2.023,0L9.171,15.835a4,4,0,1,1,5.665-.008ZM13,13a1,1,0,1,1-1-1A1,1,0,0,1,13,13Z" })));
exports.default = TruxwebHomeLocationIcon;
