import { Box, Typography } from '@truxweb/ux';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { PageLink } from '..';
import React from 'react';
import { useStyles } from './ShipmentReportMarketing.styles';

const REQUIRED_NAMESPACES = ['common'];

type TShipmentReportMarketingProps = {
  handleClose?: () => void;
  size?: 'sm' | 'lg';
};

export const ShipmentReportMarketing = ({
  handleClose,
  size,
}: TShipmentReportMarketingProps): JSX.Element => {
  const classes = useStyles();
  let headerSize: any = 'bodyLarge';
  let bodySize: any = 'bodyMedium';
  let imageDimension = 256;
  if (size && size === 'lg') {
    headerSize = 'h2';
    bodySize = 'bodyLarge';
    imageDimension = 512;
  }
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  return (
    <>
      <Box p={3}>
        <Box style={{ float: 'right' }}>
          <PageLink pageId={'report'}>
            <Image
              height={imageDimension}
              onClick={handleClose}
              src={'/assets/images/marketing/shipmentReportsMarketing.png'}
              width={imageDimension}
            />
          </PageLink>
        </Box>
        <Typography color="primaryLight" fontStyle="semibold" variant={headerSize}>
          {t('common:shipmentReportMarketing-h1')}
        </Typography>
        <Box mt={2}>
          <Typography variant={bodySize}>{t('common:shipmentReportMarketing-p1')}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>{t('common:shipmentReportMarketing-p2')}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>{t('common:shipmentReportMarketing-p3')}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>{t('common:shipmentReportMarketing-p4')}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>
            <Trans i18nKey="common:shipmentReportMarketing-p5">
              <PageLink className={classes.link} handleClick={handleClose} pageId={'contact-us'}>
                &nbsp;
              </PageLink>
            </Trans>
          </Typography>
        </Box>
        <Box mt={2}>
          <PageLink pageId={'report'}>
            <Typography color="primaryLight" onClick={handleClose} variant="bodyLarge">
              {t('common:shipmentReportMarketing-cta2')}
            </Typography>
          </PageLink>
        </Box>
      </Box>
    </>
  );
};
