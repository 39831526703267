/* eslint-disable complexity */
import { Box, Grid, GridSize, useMediaQuery, useTheme } from '@truxweb/ux';
import { Header, MobileHeader } from '../../components';
import classnames from 'classnames';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './SplitPage.styles';

const FULL_COLUMN_WIDTH = 12;
const HALF_COLUMN_WITH = 6;

type TSplitPageProps = {
  children?: React.ReactElement;
  isFullPage?: boolean;
  leftHandSize?: GridSize;
  pageId: TPageId;
  rightHandContent?: JSX.Element;
  shouldSuppressHeader?: boolean;
  shouldSuppressLeftMaxWidth?: boolean;
  rightHandSize?: GridSize;
};

export const SplitPage = ({
  children,
  isFullPage,
  leftHandSize,
  pageId,
  rightHandContent,
  rightHandSize,
  shouldSuppressHeader,
  shouldSuppressLeftMaxWidth,
}: TSplitPageProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isImageOffset = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isVerticalTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.largeMobile));

  return (
    <Grid
      alignItems="stretch"
      className={
        isFullPage
          ? classnames(classes.flexHeightMax, classes.pageContainer)
          : classnames(classes.flexHeight, classes.pageContainer)
      }
      container
      direction="row"
      justifyContent="center"
    >
      <Grid
        className={
          isVerticalTablet
            ? isMobile
              ? isFullPage
                ? classnames(classes.leftHandContent, classes.flexHeightMax)
                : classnames(classes.leftHandContent, classes.flexHeight)
              : classes.leftHandContent
            : isFullPage
            ? classnames(classes.flexHeightMax, classes.pane, classes.leftHandContent)
            : classnames(classes.flexHeight, classes.pane, classes.leftHandContent)
        }
        container
        direction="column"
        item
        justifyContent="flex-start"
        wrap="nowrap"
        xs={
          isVerticalTablet
            ? FULL_COLUMN_WIDTH
            : leftHandSize
            ? isSmallTablet
              ? HALF_COLUMN_WITH
              : leftHandSize
            : HALF_COLUMN_WITH
        }
      >
        {!shouldSuppressHeader && (
          <>
            <Header isScrolled={false} isTransparent={true} pageId={pageId} shouldHideAppBar />
            <MobileHeader isScrolled={false} isTransparent={true} pageId={pageId} />
          </>
        )}
        <Grid
          alignItems={isFullPage ? 'flex-start' : 'center'}
          className={
            isFullPage
              ? classnames(
                  classes.flexHeightMax,
                  shouldSuppressLeftMaxWidth ? null : classes.maxWidth,
                  classes.alignSelf
                )
              : classnames(
                  classes.flexHeight,
                  shouldSuppressLeftMaxWidth ? null : classes.maxWidth,
                  classes.alignSelf
                )
          }
          container
          direction="column"
          item
          justifyContent={isMobile ? 'flex-start' : isFullPage ? 'flex-start' : 'center'}
        >
          <Box
            className={!shouldSuppressLeftMaxWidth ? classes.maxWidth : null}
            mt={isMobile ? 2 : 0}
            style={{ maxWidth: shouldSuppressLeftMaxWidth ? '100%' : 'inherit' }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid
          alignContent={isImageOffset ? 'center' : 'space-between'}
          alignItems="center"
          className={classnames(classes.minHeight, classes.rightHandContent, classes.overflow)}
          container
          item
          justifyContent={isImageOffset ? 'flex-end' : 'center'}
          xs={
            isVerticalTablet
              ? FULL_COLUMN_WIDTH
              : rightHandSize
              ? isSmallTablet
                ? HALF_COLUMN_WITH
                : rightHandSize
              : HALF_COLUMN_WITH
          }
        >
          {rightHandContent}
        </Grid>
      )}
    </Grid>
  );
};
