"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudCheckIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m11.692 23a2.98 2.98 0 0 1 -2.121-.879l-3.387-3.421a1 1 0 1 1 1.416-1.4l3.384 3.414a1.025 1.025 0 0 0 1.411 0l5.414-5.414a1 1 0 0 1 1.415 1.414l-5.415 5.414a2.976 2.976 0 0 1 -2.117.872zm-4.692-1a1 1 0 0 0 -1-1h-.317a3.81 3.81 0 0 1 -3.646-2.982 3.468 3.468 0 0 1 1.835-3.6 1.994 1.994 0 0 0 .792-2.686 6 6 0 1 1 11.06-4.513 3.1 3.1 0 0 0 2.044 2.053 5.971 5.971 0 0 1 4.213 6.211 5.845 5.845 0 0 1 -4.293 5.076 1 1 0 0 0 .624 1.9 7.858 7.858 0 0 0 .044-15.1 1.09 1.09 0 0 1 -.722-.735 8 8 0 0 0 -15.49.842 7.652 7.652 0 0 0 .8 5.18 5.443 5.443 0 0 0 -2.887 5.654 5.843 5.843 0 0 0 5.626 4.7h.317a1 1 0 0 0 1-1z" })));
exports.default = TruxwebCloudCheckIcon;
