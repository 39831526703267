// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentLocationTypeV1 = exports.DayOfWeekV1 = exports.CountryV1 = exports.UserTypeV1 = exports.MetadataTypeV1 = exports.PaymentMethodV1 = void 0;
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
var MetadataTypeV1;
(function (MetadataTypeV1) {
    MetadataTypeV1["INTEGER"] = "INTEGER";
    MetadataTypeV1["STRING"] = "STRING";
    MetadataTypeV1["ADDRESS"] = "ADDRESS";
    MetadataTypeV1["DATE"] = "DATE";
    MetadataTypeV1["WEIGHT"] = "WEIGHT";
    MetadataTypeV1["PRICE"] = "PRICE";
    MetadataTypeV1["DURATION"] = "DURATION";
    MetadataTypeV1["TIME"] = "TIME";
    MetadataTypeV1["OPTIONAL"] = "OPTIONAL";
})(MetadataTypeV1 || (exports.MetadataTypeV1 = MetadataTypeV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var ShipmentLocationTypeV1;
(function (ShipmentLocationTypeV1) {
    ShipmentLocationTypeV1["ORIGIN"] = "ORIGIN";
    ShipmentLocationTypeV1["DESTINATION"] = "DESTINATION";
})(ShipmentLocationTypeV1 || (exports.ShipmentLocationTypeV1 = ShipmentLocationTypeV1 = {}));
