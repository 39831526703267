"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    unreadCount: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.spacing(1),
        color: theme.palette.common.white,
        fontSize: '10px',
        fontWeight: 600,
        height: theme.spacing(2),
        lineHeight: `${theme.spacing(2)}px`,
        padding: `0 ${theme.spacing(1)}px`,
        textAlign: 'center',
    },
}));
