import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  boxesWrapper: {
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      height: `${theme.spacing(12.5)}px`,
      position: 'relative',
      top: `-${theme.spacing(4)}px`,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    height: theme.spacing(54),
    maxWidth: theme.spacing(75),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 1rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      height: theme.spacing(64),
    },
  },
  primaryFont: {
    color: `${theme.palette.primary.light} !important`,
  },
  roboto: {
    fontFamily: 'Roboto',
  },
  truckWrapper: {
    height: `${theme.spacing(12.5)}px`,
    left: `-${theme.spacing(6.5)}px`,
    position: 'relative',
    top: `-${theme.spacing(4)}px`,
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      left: `-${theme.spacing(4)}px`,
      top: `-${theme.spacing(7)}px`,
    },
  },
}));
