"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    requestPaymentTermsCTA: {
        color: theme.palette.warning.main,
    },
    subheader: {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '15px',
    },
}));
