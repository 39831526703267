"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveFilterButton = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const ActiveFilterButton_styles_1 = require("./ActiveFilterButton.styles");
const ActiveFilterButton = ({ filter, filterName, onRemove, t, }) => {
    const [areDetailsShown, setDetailsShown] = (0, react_1.useState)(false);
    const classes = (0, ActiveFilterButton_styles_1.useStyles)();
    // NOTE: Placeholder only, as showing details is not yet implemented
    (0, react_1.useEffect)(() => {
        if (areDetailsShown) {
            setDetailsShown(false);
        }
    }, [areDetailsShown, setDetailsShown]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", style: { position: 'relative' } },
        react_1.default.createElement(ux_1.Grid, { className: classes.container, container: true, direction: "column", item: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement("button", { className: classes.filterNameButton },
                    react_1.default.createElement(ux_1.Typography, { style: { lineHeight: '24px' }, variant: "bodySmall" }, filterName))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 },
                react_1.default.createElement("button", { className: classes.removeButton, onClick: onRemove },
                    react_1.default.createElement(ux_1.Typography, { color: "white", variant: "bodyXSmall" }, "x")))),
        areDetailsShown && (react_1.default.createElement(ux_1.Grid, { item: true, style: {
                background: '#EAEAEA',
                border: `1px solid #0087CE `,
                borderRadius: `0 0 16px 16px`,
                borderWidth: '0 1px 1px 1px',
                left: 0,
                padding: '16px 8px 8px 8px',
                position: 'absolute',
                right: 0,
                top: '14px',
                zIndex: 1,
            } },
            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyXSmall" }, t(`common:tableFilterOperation-${filter.operation}`)),
            react_1.default.createElement("ul", null, filter.value.map((value) => {
                return (react_1.default.createElement("li", { key: value },
                    react_1.default.createElement(ux_1.Typography, { variant: "bodyXSmall" }, value)));
            }))))));
};
exports.ActiveFilterButton = ActiveFilterButton;
