"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    activeStep: {
        backgroundColor: theme.palette.common.white,
        border: `3px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.light,
    },
    completedStep: {
        backgroundColor: theme.palette.primary.light,
        border: `3px solid ${theme.palette.primary.light}`,
        color: theme.palette.common.white,
    },
    completedStepIcon: {
        fill: theme.palette.common.white,
        scale: 0.75,
        stroke: '#fff',
        strokeWidth: 2,
    },
    incompleteStep: {
        backgroundColor: theme.palette.grey[400],
        border: `3px solid ${theme.palette.grey[400]}`,
        color: theme.palette.common.white,
    },
    step: {
        alignItems: 'center',
        borderRadius: '100%',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
    },
}));
