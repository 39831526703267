import { makeRequestToApiGateway } from '../../apiUtils';
import { type TCompleteQuoteToShipmentV1 } from '@truxweb/schemas';

export const acceptCarrierQuoteForShipper = async (
  carrierQuoteRequestId: number,
  acceptanceRequest: TCompleteQuoteToShipmentV1
): Promise<number> => {
  const results = await makeRequestToApiGateway(
    'shipmentPatchV1ShipperQuoteAcceptById',
    {
      id: carrierQuoteRequestId,
    },
    { 'Content-Type': 'application/json' },
    { ...acceptanceRequest }
  );
  const { messageId } = results as Record<string, any>;
  return messageId as number;
};
