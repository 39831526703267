"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyLocationSidebar = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const constants_1 = require("../../constants");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const CompanyLocationSidebar_styles_1 = require("./CompanyLocationSidebar.styles");
const CompanyLocationSidebar = ({ control, isSaving, locationEditId, onDelete, selectedLocation, t, watch, }) => {
    const classes = (0, CompanyLocationSidebar_styles_1.useStyles)();
    const [isInDelete, setInDelete] = (0, react_1.useState)(false);
    const [areDetailsShown, setDetailsShown] = (0, react_1.useState)(false);
    if (locationEditId === null)
        return react_1.default.createElement(react_1.default.Fragment, null);
    if (locationEditId !== 0 && !selectedLocation)
        return react_1.default.createElement(react_1.default.Fragment, null);
    return (react_1.default.createElement(ux_1.FlatCard, null,
        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, locationEditId === 0 ? t('common:addNewAddress') : t('common:updateAddress')),
        react_1.default.createElement(ux_1.Box, { mb: 1, mt: 1 },
            react_1.default.createElement(ux_1.Typography, null, t('common:accountAddressPrompt'))),
        react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement("button", { className: classes.explanationButton, onClick: () => setDetailsShown(!areDetailsShown), type: "button" },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight" }, t('common:clickForDetails')))),
        react_1.default.createElement(ux_1.Collapse, { in: areDetailsShown },
            react_1.default.createElement(ux_1.Box, { className: classes.explanationText, mb: 2, mt: 2, p: 3 },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight" }, t('common:addressBookExplanationOne')),
                react_1.default.createElement("br", null),
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight" }, t('common:addressBookExplanationTwo')))),
        react_1.default.createElement(ux_1.Box, { mb: 3 },
            react_1.default.createElement(__1.AddressForm, { addressName: (selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.companyLocationName) || '', areNamesDisabled: true, control: control, data: selectedLocation, isAddressNameShown: true, isFormDisabled: false, t: t, watch: watch })),
        react_1.default.createElement(react_1.default.Fragment, null, Boolean(selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.isDefault) === false && (react_1.default.createElement(ux_1.Box, { mb: 3 },
            react_1.default.createElement(ux_1.Typography, null, t('common:isCompanyDefaultAddress')),
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: Boolean(selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.isDefault) || false, name: 'isDefault', render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", onChange: (changedValue) => {
                            if (changedValue === schemas_1.EYesNoV1.NO) {
                                onChange(false);
                                return;
                            }
                            onChange(true);
                            return;
                        }, sourceEnum: schemas_1.EYesNoV1, t: t, value: value ? schemas_1.EYesNoV1.YES : schemas_1.EYesNoV1.NO }));
                } })))),
        react_1.default.createElement(ux_1.Box, { mb: 3 },
            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:openingHours')),
            react_1.default.createElement(ux_1.Box, { mt: 3.5 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.weeklySchedule) || constants_1.DEFAULT_LOCATION_SCHEDULE, name: 'weeklySchedule', render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(ux_1.Box, { mr: -2 },
                            react_1.default.createElement(__1.ScheduleToggle, { onChange: onChange, shouldUseSmallVariant: true, t: t, value: value })));
                    }, rules: { required: true } })),
            react_1.default.createElement(ux_1.Box, { mt: 3.5 },
                react_1.default.createElement(__1.OpeningHours, { closeHour: (selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.closeHour) || constants_1.DEFAULT_LOCATION_HOURS.closeHour, control: control, openHour: (selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.openHour) || constants_1.DEFAULT_LOCATION_HOURS.openHour, t: t, watch: watch }))),
        react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:contactPerson'))),
        react_1.default.createElement(__1.AddressContactForm, { control: control, data: selectedLocation, isEmailOptional: true, t: t }),
        react_1.default.createElement(ux_1.Fade, { in: isInDelete },
            react_1.default.createElement("div", null, isInDelete && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { color: "error" }, t('common:confirmDeletePrompt', { item: t('common:address') }))),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement("button", { className: classes.actionButton, onClick: () => {
                                    setInDelete(false);
                                } },
                                react_1.default.createElement(ux_1.Typography, null, t('common:cancel')))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.SaveButton, { className: classes.deleteButton, customAction: onDelete, isSaving: isSaving, t: t }, t('common:delete'))))))))),
        react_1.default.createElement(react_1.default.Fragment, null, !isInDelete && (react_1.default.createElement(ux_1.Box, { mt: 2 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true }, !(selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.isDefault) && locationEditId !== 0 && (react_1.default.createElement("button", { className: classes.actionButton, onClick: () => {
                        setInDelete(true);
                    } },
                    react_1.default.createElement(ux_1.Typography, { color: "error" }, t('common:delete'))))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }, t('common:submit')))))))));
};
exports.CompanyLocationSidebar = CompanyLocationSidebar;
