"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = exports.useInputStyles = exports.useFieldStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useFieldStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        background: theme.palette.common.white,
        borderRadius: '10px',
    },
}));
exports.useInputStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        borderRadius: '8px',
        height: '40px',
    },
}));
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    text: {
        color: '#636363',
    },
}));
