import { makeRequestToApiGateway } from '../../apiUtils';

export const markCarrierCommentsAsReadByShipper = async (shipmentRef: string): Promise<void> => {
  await makeRequestToApiGateway(
    'shipmentPostV1ShipperCommentByShipmentRefRead',
    { shipmentRef },
    {
      'Content-Type': 'application/json',
    },
    { shipmentRef }
  );
};
