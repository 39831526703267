"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLuchadorIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M17,16H15a2,2,0,0,1-2-2V13a3,3,0,0,1,3-3h1a1,1,0,0,0,1-1,1,1,0,0,1,2,0v4A3,3,0,0,1,17,16Zm-1-4a1,1,0,0,0-1,1v1h2a1,1,0,0,0,1-1V11.829A3.009,3.009,0,0,1,17,12ZM9,16H7a3,3,0,0,1-3-3V9A1,1,0,0,1,6,9a1,1,0,0,0,1,1H8a3,3,0,0,1,3,3v1A2,2,0,0,1,9,16ZM6,11.829V13a1,1,0,0,0,1,1H9V13a1,1,0,0,0-1-1H7A3,3,0,0,1,6,11.829ZM19,24H17.963a4,4,0,0,1-3.512-2.085l-.371-.681a2.37,2.37,0,0,0-4.16,0l-.371.68A4,4,0,0,1,6.037,24H5a5.006,5.006,0,0,1-5-5V12.365A12.248,12.248,0,0,1,11.775,0h0A12,12,0,0,1,24,12v7A5.006,5.006,0,0,1,19,24Zm-7-6a4.369,4.369,0,0,1,3.836,2.277l.371.681A2,2,0,0,0,17.963,22H19a3,3,0,0,0,3-3V12A10,10,0,0,0,11.812,2h0A10.238,10.238,0,0,0,2,12.365V19a3,3,0,0,0,3,3H6.037a2,2,0,0,0,1.756-1.042l.371-.681A4.369,4.369,0,0,1,12,18Z" })));
exports.default = TruxwebLuchadorIcon;
