"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    innerItem: {
        background: '#fff',
        borderRadius: 16,
        margin: 0,
        padding: 24,
    },
    item: {
        background: theme.palette.grey[100],
        borderRadius: 16,
        margin: 0,
        marginBottom: 24,
        padding: 24,
    },
}));
