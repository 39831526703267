import { type AppState, baseLoadingState } from '../../stores';
import {
  ELanguageV1,
  EShipmentQuoteRequestStatusV1,
  EUserTypeV1,
  type TShipperQuoteDataGridRowV1,
  TShipperShipmentQuoteRequestV1,
} from '@truxweb/schemas';
import { mapShipperQuoteRequestRecordToDataGridRow } from '@truxweb/quote-utils';
import { type TFunction } from 'next-i18next';
import { type TReduxListResponse } from '../../types';

type TSelectShipperQuoteList = {
  language: ELanguageV1;
  t: TFunction;
  userType: EUserTypeV1;
  shouldLoadAllData: boolean;
};
type TShipperQuoteListResponse = TReduxListResponse<TShipperQuoteDataGridRowV1[]>;

export const selectShipperQuoteListData = (
  request: TSelectShipperQuoteList
): ((state: AppState) => TShipperQuoteListResponse) => {
  return (state: AppState): TShipperQuoteListResponse => {
    const { language, shouldLoadAllData, t, userType } = request;
    const loadingState = state?.quote?.shipperQuoteList || { ...baseLoadingState };
    const statuses = state.ux.quoteTableStatuses;

    if (!loadingState.hasLoaded) return loadingState;

    let data: any[] = [];

    if (shouldLoadAllData) {
      data = Object.values(state.quote.shipperQuoteData || [])
        .filter(({ data }) => Boolean(data))
        .map(({ data }) => {
          return mapShipperQuoteRequestRecordToDataGridRow(data, language, userType, t);
        })
        .flat()
        .filter((data: TShipperQuoteDataGridRowV1) => {
          let shouldInclude = false;
          if (!statuses) return true;
          if (statuses.includes(EShipmentQuoteRequestStatusV1.PENDING)) {
            shouldInclude = data.isPending;
          }

          if (statuses.includes(EShipmentQuoteRequestStatusV1.ACCEPTED) && !shouldInclude) {
            shouldInclude = data.isAccepted;
          }

          if (statuses.includes(EShipmentQuoteRequestStatusV1.REFUSED) && !shouldInclude) {
            shouldInclude =
              data.isShipperRefused ||
              data.isShipperExpired ||
              data.isCarrierExpired ||
              data.isCarrierExpired;
          }
          return shouldInclude;
        });
    } else {
      data = (state.quote.shipperQuoteList?.data || []).map(
        (record: TShipperShipmentQuoteRequestV1) => {
          return mapShipperQuoteRequestRecordToDataGridRow(record, language, userType, t);
        }
      );
    }

    return { ...loadingState, data };
  };
};
