"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    userEmail: {
        // FIXME: Use correct colour
        color: '#696969',
        display: 'block',
        fontWeight: 400,
        lineHeight: '1rem',
        maxWidth: theme.spacing(22.5),
        overflow: 'hidden',
        paddingLeft: theme.spacing(0.5),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    userName: {
        // FIXME: Use correct colour
        color: '#696969',
        fontWeight: 600,
        maxWidth: theme.spacing(22.5),
        overflow: 'hidden',
        paddingLeft: theme.spacing(0.5),
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
    },
}));
