// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentTrackingLocationArrivalStatusV1 = void 0;
var ShipmentTrackingLocationArrivalStatusV1;
(function (ShipmentTrackingLocationArrivalStatusV1) {
    ShipmentTrackingLocationArrivalStatusV1["EARLY"] = "EARLY";
    ShipmentTrackingLocationArrivalStatusV1["LATE"] = "LATE";
    ShipmentTrackingLocationArrivalStatusV1["ON_TIME"] = "ON_TIME";
    ShipmentTrackingLocationArrivalStatusV1["UNKNOWN"] = "UNKNOWN";
})(ShipmentTrackingLocationArrivalStatusV1 || (exports.ShipmentTrackingLocationArrivalStatusV1 = ShipmentTrackingLocationArrivalStatusV1 = {}));
