"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebIceSkateIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,19a1,1,0,0,0-1,1,2,2,0,0,1-2,2H16V18h2.1A3.905,3.905,0,0,0,22,14.1a6.014,6.014,0,0,0-4.823-5.883L13.3,7.44a2,2,0,0,1-1.592-1.7L11.3,2.576A3,3,0,0,0,7.619.086L2.91,1.42A4.015,4.015,0,0,0,0,5.269V14a4,4,0,0,0,4,4H5v4H1a1,1,0,0,0,0,2H20a4,4,0,0,0,4-4A1,1,0,0,0,23,19ZM2,14V5.269A2.01,2.01,0,0,1,3.455,3.344l4.652-1.32A1.053,1.053,0,0,1,8.328,2a1,1,0,0,1,.986.848L9.466,4H7A1,1,0,0,0,7,6H9.729a3.958,3.958,0,0,0,.245.915L7.635,8.338a1,1,0,0,0,1.039,1.709l2.466-1.5a3.95,3.95,0,0,0,.866.544l-1.375,2.413a1,1,0,0,0,1.738.99l1.638-2.874,2.777.556A4.011,4.011,0,0,1,20,14.1,1.9,1.9,0,0,1,18.1,16H4A2,2,0,0,1,2,14Zm5,4h7v4H7Z" })));
exports.default = TruxwebIceSkateIcon;
