"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    formField: {
        borderRadius: '8px',
        height: '40px',
    },
    header: {
        color: theme.palette.primary.light,
        fontSize: '15px',
        fontWeight: 600,
    },
}));
