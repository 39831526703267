"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebJamIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.818,7.1A4.782,4.782,0,0,0,20,4.078V3a3,3,0,0,0-3-3H7A3,3,0,0,0,4,3V4.078c-3.776.457-5.682,5.347-2,6.79V18a6.006,6.006,0,0,0,6,6h8a6.006,6.006,0,0,0,6-6V10.87A2.91,2.91,0,0,0,23.818,7.1ZM20,18a4,4,0,0,1-4,4H8a4,4,0,0,1-4-4V10.861c.895-.068,1.658-1.313,2.556-.7a4.058,4.058,0,0,0,4.889,0,.947.947,0,0,1,1.111,0,4.058,4.058,0,0,0,4.889,0c.9-.619,1.658.627,2.555.7Zm1.831-9.346c-.37.618-1.556.281-2.052-.07a2.945,2.945,0,0,0-3.557,0,2.03,2.03,0,0,1-2.443,0,2.945,2.945,0,0,0-3.557,0,2.03,2.03,0,0,1-2.443,0,2.945,2.945,0,0,0-3.557,0c-.84.616-2.535.654-2.165-.791A2.86,2.86,0,0,1,5,6H15a1,1,0,0,0,0-2H6V3A1,1,0,0,1,7,2H17a1,1,0,0,1,1,1V5a1,1,0,0,0,1,1C21.217,6.008,22.413,7.651,21.831,8.654Z" })));
exports.default = TruxwebJamIcon;
