"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        '& .MuiStepConnector-active .MuiStepConnector-lineHorizontal, & .MuiStepConnector-completed .MuiStepConnector-lineHorizontal': {
            borderTop: `6px solid ${theme.palette.primary.light}`,
        },
        '& .MuiStepConnector-lineHorizontal': {
            borderTop: `6px solid ${theme.palette.grey[400]}`,
        },
        '& .MuiStepLabel-iconContainer': {
            marginTop: '-6px',
        },
    },
}));
