"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    deleteCard: {
        background: '#eaeaea',
        height: 'auto',
        maxWidth: '100%',
        minWidth: '100%',
        padding: theme.spacing(2),
        paddingLeft: 0,
    },
    deleteLabel: {
        height: 'auto',
    },
}));
