import { makeStyles, Theme } from '@truxweb/ux';

type TResponsiveHeaderLogoType = {
  isDark: boolean;
};

export const useStyles = makeStyles<Theme, TResponsiveHeaderLogoType>((theme: Theme) => ({
  truxwebLogo: {
    '& .TruxwebLogo_svg__target': {
      fill: ({ isDark }) => {
        return isDark ? theme.palette.common.white : theme.palette.common.black;
      },
    },
  },
}));
