import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  fakeCircle: {
    '&:hover': {
      border: `8px solid ${theme.palette.primary.dark}`,
    },
    border: `8px solid ${theme.palette.primary.main}`,
    borderRadius: '100%',
    height: 36,
    position: 'relative',
    width: 36,
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    color: theme.palette.primary.main,
    left: `-4px`,
    position: 'absolute',
    top: `-4px`,
  },

  link: {
    color: '#fff',
    display: 'flex',
    textDecoration: 'none',
  },
  linkText: {
    marginLeft: theme.spacing(1),
  },
}));
