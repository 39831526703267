import { type AppState } from '../../stores';
import type { TCreateShipperTicketForEmptySearchResultsRequestV1 } from '@truxweb/schemas';

type TSelectDataForEmptySearchTicket = Omit<
  TCreateShipperTicketForEmptySearchResultsRequestV1,
  'shipperUserEmail' | 'companyName'
>;

export const selectDataForEmptySearchTicket = (
  state: AppState
): TSelectDataForEmptySearchTicket => {
  const savedFormData = state.search?.savedFormData?.data;

  return {
    searchParams: {
      ...savedFormData,
    },
  };
};
