import { type AppState, baseLoadingState } from '../../stores';
import { type TCompanyDocumentV1 } from '@truxweb/schemas';
import { type TReducerLoading } from '../../types';

export const selectCompanyDocuments = (): ((
  state: AppState
) => TReducerLoading & { data: TCompanyDocumentV1[] }) => {
  return (state: AppState) => {
    const listLoadingState = state?.companyDocuments?.companyDocumentsList || baseLoadingState;
    if (!listLoadingState.hasLoaded) return { ...listLoadingState, data: [] };
    const data = Object.values(state?.companyDocuments?.companyDocuments).map(({ data }) => data);

    return { ...listLoadingState, data };
  };
};
