import {
  addAlert,
  type AppThunk,
  setPermissionsData,
  setPermissionsError,
  setPermissionsLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const getAuthedUserPermissions = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setPermissionsLoading());
    const data = await makeRequestToApiGateway('userGetV1Permissions', null, {
      'Content-Type': 'application/json',
    });

    dispatch(setPermissionsData({ permissions: data }));
  } catch (err) {
    dispatch(addAlert({ message: err.message, severity: 'error' }));
    dispatch(setPermissionsError({ error: err }));
  }
};
