import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TLoadParametersV1 } from '@truxweb/schemas';
import { type TReducerLoading } from '../types';

export type TLoadParameters = TReducerLoading & {
  data: TLoadParametersV1;
};

export type TLoadParametersListLoading = TReducerLoading & {
  data: boolean;
};

export interface ILoadParametersDataState {
  loadParameters: Record<string, TLoadParameters>;
  loadParametersList: TLoadParametersListLoading;
}

export const loadParametersSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.loadParameters,
      };
    },
  },
  initialState: {
    loadParameters: {},
    loadParametersList: { ...baseLoadingState, data: false },
  } as ILoadParametersDataState,
  name: 'loadParameters',
  reducers: {
    setLoadParametersData: (state, { payload: { items, shouldOverwriteList } }) => {
      const loadParameters: ILoadParametersDataState['loadParameters'] = {};
      items.forEach((item: TLoadParametersV1) => {
        loadParameters[item.id] = manageDomainLoaded(state.loadParameters[item.id], item);
      });

      const updatedState = shouldOverwriteList
        ? { ...loadParameters }
        : {
            ...state.loadParameters,
            ...loadParameters,
          };

      return {
        ...state,
        loadParameters: updatedState,
      };
    },
    setLoadParametersListError: (state, { payload: { error } }) => {
      return {
        ...state,
        loadParametersList: manageDomainError(state.loadParametersList, error),
      };
    },
    setLoadParametersListLoaded: (state, { payload: { isProgressiveLoading } }) => {
      return {
        ...state,
        loadParametersList: manageDomainLoaded(
          state.loadParametersList,
          true,
          isProgressiveLoading
        ),
      };
    },
    setLoadParametersListLoading: (state) => {
      return { ...state, loadParametersList: manageDomainLoading(state.loadParametersList) };
    },
  },
});

export const {
  actions: {
    setLoadParametersData,
    setLoadParametersListError,
    setLoadParametersListLoaded,
    setLoadParametersListLoading,
  },
} = loadParametersSlice;
