"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestCheckoutPayment = void 0;
const ux_1 = require("@truxweb/ux");
const quote_utils_1 = require("@truxweb/quote-utils");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const QuoteRequestCheckoutPayment_styles_1 = require("./QuoteRequestCheckoutPayment.styles");
const QuoteRequestCheckoutPayment = ({ ApplyForPaymentTerms, areUpdatedChargesLoading, bambora, bankAccounts, canSetQuoteTaxExemptStatus, carrierQuoteData, company, companySubscription, control, creditCards, getValues, handleRouteToSubscription, handleRouteToTermsAndConditions, handleUpdateQuoteCharges, isActive, isAddCardFormShown, isPaymentDataLoading, language, paymentTerms, quoteCharges, register, setIsAddCardFormShown, setSubmitDisabled, setValue, shipmentCredits, shipperQuoteData, shouldBlockCreditRedemption, t, validPaymentMethods, watch, }) => {
    const theme = (0, ux_1.useTheme)();
    const classes = (0, QuoteRequestCheckoutPayment_styles_1.useStyles)();
    const [isTaxExemptDeclarationShown, setsTaxExemptDeclarationShown] = (0, react_1.useState)(false);
    const onUpdateCharges = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const values = getValues();
        yield handleUpdateQuoteCharges();
        setsTaxExemptDeclarationShown(Boolean(values.isTaxExempt));
    }), [getValues, setsTaxExemptDeclarationShown, handleUpdateQuoteCharges]);
    const isCrossBorder = (0, quote_utils_1.determineQuoteCrossBorderStatus)(carrierQuoteData);
    const { destinations, origin } = (0, quote_utils_1.getOriginAndDestinationFromQuoteLocations)(carrierQuoteData.location);
    const [destination] = destinations;
    const intraCanada = origin.country === schemas_1.ECountryV1.CA && destination.country === schemas_1.ECountryV1.CA;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.QuoteRequestCheckoutOrderSummary, { carrierQuoteData: carrierQuoteData, charges: quoteCharges, companySubscription: companySubscription, control: control, handleRouteToSubscription: handleRouteToSubscription, handleToggleCreditRedemption: onUpdateCharges, isLoading: areUpdatedChargesLoading, language: language, shipmentCredits: shipmentCredits, shouldBlockCreditRedemption: shouldBlockCreditRedemption, t: t }),
        canSetQuoteTaxExemptStatus && !isCrossBorder && intraCanada && (react_1.default.createElement(ux_1.Grid, { className: classes.summaryLineItem, container: true, item: true, justifyContent: "center", xs: true },
            react_1.default.createElement(ux_1.Box, { mr: 1, mt: 3 },
                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Box, { ml: 1 },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: !shipperQuoteData.isTaxable, name: `isTaxExempt`, render: ({ field: { onChange, value } }) => {
                                return (react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", onClick: (event) => {
                                        onChange(event);
                                        onUpdateCharges();
                                    } }));
                            } })), label: t('common:isTaxExempt'), labelPlacement: "start" })))),
        react_1.default.createElement(ux_1.Box, { mb: 4, mt: 3 },
            react_1.default.createElement(__1.QuoteRequestCheckoutPaymentOptions, { ApplyForPaymentTerms: ApplyForPaymentTerms, bambora: bambora, bankAccounts: bankAccounts, control: control, creditCards: creditCards, handleUpdateQuoteCharges: handleUpdateQuoteCharges, isActive: isActive, isAddCardFormShown: isAddCardFormShown, isPaymentDataLoading: isPaymentDataLoading, paymentTerms: paymentTerms, register: register, setIsAddCardFormShown: setIsAddCardFormShown, setSubmitDisabled: setSubmitDisabled, setValue: setValue, t: t, validPaymentMethods: validPaymentMethods, watch: watch })),
        canSetQuoteTaxExemptStatus && (react_1.default.createElement(ux_1.Collapse, { in: isTaxExemptDeclarationShown },
            react_1.default.createElement(ux_1.Box, { ml: 1 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: !isTaxExemptDeclarationShown, name: "isTaxExemptDeclarationAccepted", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        const hasError = Boolean(error);
                        const errorStyles = !hasError ? {} : { color: theme.palette.error.main };
                        return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Box, { pr: 1 },
                                react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", "data-testid": `QuoteRequestCheckoutPayment-TermsAndConditions`, hasDefaultBorderColor: true, onChange: onChange })), label: react_1.default.createElement(ux_1.Box, { onClick: handleRouteToTermsAndConditions },
                                react_1.default.createElement(ux_1.Typography, { className: classes.acceptTerms, style: errorStyles }, t('common:taxExemptDeclaration', { companyName: company === null || company === void 0 ? void 0 : company.name }))) }));
                    }, rules: { required: true } })))),
        react_1.default.createElement(ux_1.Box, { ml: 1 },
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: false, name: "areTermsAndConditionsAccepted", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                    const hasError = Boolean(error);
                    const errorStyles = !hasError ? {} : { color: theme.palette.error.main };
                    return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Box, { pr: 1 },
                            react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", "data-testid": `QuoteRequestCheckoutPayment-TermsAndConditions`, hasDefaultBorderColor: true, onChange: onChange })), label: react_1.default.createElement(ux_1.Box, { onClick: handleRouteToTermsAndConditions },
                            react_1.default.createElement(ux_1.Typography, { className: classes.acceptTerms, style: errorStyles },
                                t('checkout:iAcceptTerms'),
                                t('checkout:termsAndConditionsPrompt', {
                                    companyName: t('common:truxwebName'),
                                    termsAndConditions: t('common:termsAndConditions'),
                                }))) }));
                }, rules: { required: true } }))));
};
exports.QuoteRequestCheckoutPayment = QuoteRequestCheckoutPayment;
