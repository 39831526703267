import {
  addAlert,
  type AppThunk,
  setShipmentDetailsError,
  setShipmentDetailsLoading,
} from '../../../stores';
import { EUserTypeV1, type TShipmentV1 } from '@truxweb/schemas';
import { getErrorMessage } from '@truxweb/errors';
import { getShipmentByRef } from '..';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { type TFunction } from 'next-i18next';

type TActionData = {
  t: TFunction;
  property: string;
  cb: () => void;
};

export const updateShipmentRedux =
  (userType: EUserTypeV1, shipment: TShipmentV1, actionData: TActionData): AppThunk =>
  async (dispatch) => {
    const { cb, property, t } = actionData;
    try {
      const loadingAction = setShipmentDetailsLoading({ shipmentRefs: [shipment.shipmentRef] });
      dispatch(loadingAction);

      await makeRequestToApiGateway(
        userType === EUserTypeV1.SHIPPER
          ? 'shipmentPatchV1ShipperShipmentByShipmentRef'
          : 'shipmentPatchV1CarrierShipmentByShipmentRef',
        {
          shipmentRef: shipment.shipmentRef,
        },
        {
          'Content-Type': 'application/json',
        },
        {
          shipment,
        }
      );

      dispatch(
        addAlert({
          message: t('common:propertyUpdate', { property }),
          severity: 'success',
        })
      );

      dispatch(getShipmentByRef(userType, shipment.shipmentRef));
      cb();
    } catch (error) {
      const errorAction = setShipmentDetailsError({
        error: error.message,
        shipmentRefs: [shipment.shipmentRef],
      });
      dispatch(errorAction);
      const errorMessage = getErrorMessage(error, t);
      dispatch(
        addAlert({
          message: errorMessage,
          severity: 'error',
        })
      );
    }
  };
