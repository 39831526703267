// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageAlertStatusV1 = void 0;
var PageAlertStatusV1;
(function (PageAlertStatusV1) {
    PageAlertStatusV1["Pending"] = "Pending";
    PageAlertStatusV1["Warning"] = "Warning";
})(PageAlertStatusV1 || (exports.PageAlertStatusV1 = PageAlertStatusV1 = {}));
