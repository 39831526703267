import { hasWindow } from '../utils';

export const useHeaderPalette = (palette?: 'light' | 'dark'): 'light' | 'dark' => {
  let localTheme;

  if (hasWindow()) {
    localTheme = localStorage?.getItem('headerTheme');

    if (!localTheme) {
      localStorage.setItem('headerTheme', 'dark');
      return 'dark';
    }

    if (palette && palette !== localTheme) {
      localStorage.setItem('headerTheme', palette);
      return palette;
    }
  }
  return localTheme as 'light' | 'dark';
};
