"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebChargingStationIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10.808,9.845a1.6,1.6,0,0,1-.076,1.645L8.85,14.527a1,1,0,0,1-1.7-1.054L8.683,11H6.615A1.614,1.614,0,0,1,5.07,8.917C5.1,8.835,7.148,5.476,7.148,5.476a1,1,0,1,1,1.7,1.048L7.328,9H9.4A1.6,1.6,0,0,1,10.808,9.845ZM24,3V5.5a2.5,2.5,0,0,1-2,2.45V17a4,4,0,0,1-4,4H15.582A5.008,5.008,0,0,1,11,24H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0h6a5.006,5.006,0,0,1,5,5V19h2a2,2,0,0,0,2-2V7.95A2.5,2.5,0,0,1,18,5.5V3a1,1,0,0,1,2,0V4h2V3A1,1,0,0,1,24,3ZM14,19V11H13a1,1,0,0,1,0-2h1V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V9H3a1,1,0,0,1,0,2H2v8a3,3,0,0,0,3,3h6A3,3,0,0,0,14,19Z" })));
exports.default = TruxwebChargingStationIcon;
