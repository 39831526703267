"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCurlingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19.854,10.293,19.538,9.03A3.994,3.994,0,0,0,15.657,6H7.136l.144-.65A2.983,2.983,0,0,1,10.209,3H17a1,1,0,0,0,0-2H10.209A4.973,4.973,0,0,0,5.327,4.916L4.131,10.3A6.012,6.012,0,0,0,0,16v2a6.006,6.006,0,0,0,6,6H18a6.006,6.006,0,0,0,6-6V16A6.011,6.011,0,0,0,19.854,10.293ZM15.657,8A2,2,0,0,1,17.6,9.515L17.72,10H6.247l.444-2ZM6,12H18a4,4,0,0,1,4,4H2A4,4,0,0,1,6,12ZM18,22H6a4,4,0,0,1-4-4H22A4,4,0,0,1,18,22Z" })));
exports.default = TruxwebCurlingIcon;
