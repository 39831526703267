import { makeRequestToApiGateway } from '../../apiUtils';
import { TShipperPaymentTermsRequestV1 } from '@truxweb/schemas';

export const requestShipperPaymentTerms = async (
  data: TShipperPaymentTermsRequestV1
): Promise<void> => {
  await makeRequestToApiGateway(
    'userPostV1CompanyShipperTermsRequest',
    null,
    {
      'Content-Type': 'application/json',
    },
    data
  );
};
