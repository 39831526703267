import { Button, Grid } from '@truxweb/ux';
import React from 'react';
import { useStyles } from './HeaderButton.styles';

type THeaderButtonProps = {
  onClick?: () => void;
  text?: string;
  type: 'login' | 'standard';
  variant?: 'midnight' | 'column';
};

export const HeaderButton = ({ onClick, text, type, variant }: THeaderButtonProps): JSX.Element => {
  const classes = useStyles({ variant });

  return (
    <>
      <Grid item>
        <Button
          className={classes[type]}
          data-testid={`HeaderButton-${type}Button`}
          onClick={onClick}
        >
          {text}
        </Button>
      </Grid>
    </>
  );
};
