"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    accessorials: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        maxHeight: 270,
    },
    container: {
        height: '100%',
        width: '100%',
    },
    fullHeight: { height: '100%' },
    header: {
        color: theme.palette.primary.dark,
    },
}));
