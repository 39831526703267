import {
  addCommentToCarrierQuoteRequest,
  updateQuoteRequestForCarrier,
  uploadCommentAttachments,
} from '../../actions';
import { Box, CircularProgress, Grid, Typography } from '@truxweb/ux';
import { ELanguageV1, EUserTypeV1, type TUpdateCarrierQuoteRequestV1 } from '@truxweb/schemas';
import { LaneEditSidebarWrapper, TruxiiMessage } from '..';
import {
  QUOTE_SIDEBAR_REFRESH_MS,
  SHIPMENT_DOCUMENT_UPLOAD,
  SHOULD_ALLOW_MULTIPLE_QUOTE_EDITS_FOR_CARRIER,
} from '../../config';
import {
  QuoteRequestCarrierDetails,
  type TUpdateQuoteRequestForCarrier,
} from '@truxweb/common-components';
import React, { useCallback, useState } from 'react';
import {
  useActionController,
  useAlerts,
  useCarrierQuoteComments,
  useErrorHandling,
  useInterval,
  useReduxCarrierQuoteById,
  useRefresh,
  useShipmentEntityTags,
  useUserData,
} from '../../hooks';
import { getRouteProperties } from '../../utils';
import { setCarrierQuotesData } from '../../stores';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'checkout'];

type TQuoteRequestCarrierDetailsWrapperProps = {
  onQuoteUpdate: () => void;
  handleClose: () => void;
};

export const QuoteRequestCarrierDetailsWrapper = ({
  handleClose,
  onQuoteUpdate,
}: TQuoteRequestCarrierDetailsWrapperProps): JSX.Element => {
  const dispatch = useDispatch();
  const { addAlert } = useAlerts();
  const { userData } = useUserData();
  const router = useRouter();
  const {
    query: { quoteRequestId },
  } = router;

  const {
    i18n: { language },
    t,
  } = useTranslation(REQUIRED_NAMESPACES);

  const [isPolling, setIsPolling] = useState(false);
  const [isUpdatingLanePricing, setUpdatingLanePricing] = useState(false);
  const [laneUpdateData, setLaneUpdateData] = useState<TUpdateCarrierQuoteRequestV1 | null>(null);
  const errorHandler = useErrorHandling();
  const { handleRefresh, shouldRefresh } = useRefresh();
  useShipmentEntityTags(shouldRefresh);

  const freshData = useReduxCarrierQuoteById(parseInt(quoteRequestId as string, 10), shouldRefresh);

  const handleCommentAttachments = useCallback(
    async (carrierQuoteRequestId: number, files: File[]): Promise<any[]> => {
      return await uploadCommentAttachments(
        `QUOTES/${carrierQuoteRequestId}`,
        SHIPMENT_DOCUMENT_UPLOAD,
        files
      );
    },
    []
  );

  const handleAddComment = useCallback(
    async (
      carrierQuoteRequestId: number,
      commentData: Record<string, any>,
      isVisibleExternally: boolean,
      attachments?: File[]
    ) => {
      let attachmentData = [];

      try {
        if (attachments?.length) {
          attachmentData = await handleCommentAttachments(carrierQuoteRequestId, attachments);
        }
        await addCommentToCarrierQuoteRequest(EUserTypeV1.CARRIER, carrierQuoteRequestId, {
          addCommentRequest: {
            attachments: attachmentData,
            comment: commentData.comment,
          },
          carrierQuoteRequestId,
          companyExtId: userData.companyExtId,
          overrideCommentVisbility: isVisibleExternally,
          userExtId: userData.extId,
          userType: EUserTypeV1.CARRIER,
        });
      } catch (err) {
        errorHandler(err);
      }
    },
    [handleCommentAttachments, errorHandler, userData]
  );

  const onQuoteStatusUpdateForCarrier = useCallback(
    async (request: TUpdateQuoteRequestForCarrier): Promise<number> => {
      const { quoteId, shouldAdjustLanePricing, updateQuoteRequest } = request;
      const response = await updateQuoteRequestForCarrier(quoteId, updateQuoteRequest);
      dispatch(setCarrierQuotesData({ item: response }));
      onQuoteUpdate();
      setLaneUpdateData(updateQuoteRequest);
      setUpdatingLanePricing(shouldAdjustLanePricing);
      handleRefresh();
      return response.id;
    },
    [onQuoteUpdate, setUpdatingLanePricing, setLaneUpdateData, dispatch, handleRefresh]
  );

  const { action: handleQuoteStatusUpdateForCarrier, isLoading: isSaving } = useActionController<
    number,
    TUpdateQuoteRequestForCarrier
  >({
    action: onQuoteStatusUpdateForCarrier,
    shouldSuppressSuccessMessage: true,
  });

  const handleRouteToShipmentPage = useCallback(
    (shipmentRef: string) => {
      const pageId = 'shipments';
      const { route } = getRouteProperties(router.locale, pageId, [shipmentRef]);
      router.push(`${route}/?shipmentRef=${shipmentRef}`);
    },
    [router]
  );

  const onRefresh = useCallback(() => {
    handleRefresh();
    setIsPolling(false);
  }, [handleRefresh, setIsPolling]);

  const handlePoll = useCallback(() => {
    if (!freshData?.data?.shipmentId && !isSaving && !isPolling) {
      setIsPolling(true);
      onRefresh();
    }
  }, [onRefresh, freshData, isSaving, isPolling, setIsPolling]);

  useInterval(handlePoll, QUOTE_SIDEBAR_REFRESH_MS);
  const emptyCommentDisplay = (
    <TruxiiMessage
      additionalContent={
        <Box mt={1.5} style={{ width: '100%' }}>
          <Typography>
            {t('common:chatWithYourContent', {
              userType: t(`common:shipper`),
            })}{' '}
          </Typography>
        </Box>
      }
      content={t('common:chatWithYour', {
        userType: t(`common:shipper`),
      })}
      direction="column"
    />
  );

  if (!freshData.hasLoaded) {
    return (
      <Grid alignItems="center" container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (isUpdatingLanePricing) {
    return (
      <LaneEditSidebarWrapper
        carrierId={freshData?.data?.carrierId}
        carrierLaneId={freshData?.data?.carrierServiceTypeId}
        handleClose={handleClose}
        laneUpdateFromQuote={laneUpdateData}
      />
    );
  }

  return (
    <QuoteRequestCarrierDetails
      addAlert={addAlert}
      emptyCommentDisplay={emptyCommentDisplay}
      handleRouteToShipmentPage={handleRouteToShipmentPage}
      handleSubmitComment={handleAddComment}
      key={freshData.dateUpdated}
      locale={language as ELanguageV1}
      quoteData={freshData?.data}
      shouldAllowMultipleEdits={SHOULD_ALLOW_MULTIPLE_QUOTE_EDITS_FOR_CARRIER}
      t={t}
      updateQuoteRequestForCarrier={handleQuoteStatusUpdateForCarrier}
      useQuoteComments={useCarrierQuoteComments}
      userData={userData}
      viewingUserType={EUserTypeV1.CARRIER}
    />
  );
};
