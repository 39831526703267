import { makeRequestToApiGateway } from '../../apiUtils';
import { TAccessorialV1 } from '@truxweb/schemas';

export const getAccessorialData = async (): Promise<TAccessorialV1[]> => {
  const results = await makeRequestToApiGateway('carrierGetV1Accessorial', null, {
    'Content-Type': 'application/json',
  });

  return results as TAccessorialV1[];
};
