"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebKiteIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18.76,16a5.207,5.207,0,0,0-3.707,1.535A15.143,15.143,0,0,1,4.274,22H3.123a1.121,1.121,0,0,1-.793-1.914L5.52,16.9l11.768-3.138A5.005,5.005,0,0,0,21,8.927V5a5.006,5.006,0,0,0-5-5H12.073A5.005,5.005,0,0,0,7.242,3.712L4.1,15.485.916,18.672A3.121,3.121,0,0,0,3.123,24H4.274a17.127,17.127,0,0,0,12.193-5.051A3.243,3.243,0,0,1,22,21.242V23a1,1,0,0,0,2,0V21.242A5.249,5.249,0,0,0,18.76,16Zm-1.988-4.174-8.43,2.248L14.5,7.915l3.356,3.356A2.981,2.981,0,0,1,16.772,11.826ZM19,5V8.927a2.984,2.984,0,0,1-.066.593L15.915,6.5,18.7,3.716A2.963,2.963,0,0,1,19,5ZM12.073,2H16a2.977,2.977,0,0,1,1.287.3L14.5,5.087,11.48,2.066A2.984,2.984,0,0,1,12.073,2Zm-2.9,2.228a2.981,2.981,0,0,1,.555-1.085L13.087,6.5,6.925,12.663Z" })));
exports.default = TruxwebKiteIcon;
