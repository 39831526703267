"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    wrapper: {
        '& .MuiChip-deleteIcon': {
            margin: 0,
        },
        margin: '0px',
    },
}));
