import { TPageId, TRouteDefinitionType } from '../../types';

import { localizedRoutes } from '../../config';

const authenticatedRoutes: TPageId[] = [
  'account',
  'account/company',
  'account/payment',
  'account/address',
  'carrier/[carrier]',
  'fleet',
  'fleet/lanes',
  'fleet/accessorials',
  'fleet/equipment',
  'results',
  'requests',
  'requests/confirmed',
  'requests/examination',
  'requests/refused',
  'report',
  'search',
  'shipments',
  'shipments/active',
  'shipments/historical',
  'shipments/booked',
  'shipments/[shipmentRef]',
];

const unauthenticatedOnlyRoutes: TPageId[] = [
  'login',
  'signup',
  'signup/shipper',
  'signup/shipper/confirm',
  'signup/carrier',
  'signup/carrier/confirm',
];

const deprecatedRoutes: TPageId[] = [
  'requests/confirmed',
  'requests/examination',
  'requests/refused',
  'shipments/active',
  'shipments/historical',
  'shipments/booked',
  'shipments/[shipmentRef]',
];

// When switching languages we want the pages names to be localized to avoid non-sensical routes like
// `/fr/contact-us` or `/en/contactez-nous`, this function should be invoked whenever the language changes
// FIXME: abstract out complexity
//eslint-disable-next-line complexity
export const getLocalizedRoute = (lang: string, pageId?: TPageId, routeParams?: any[]): string => {
  // FIXME this is a bug with the localization lib 04/20/2021
  if (!lang) {
    lang = 'en-CA';
  }
  if (!pageId || !localizedRoutes[lang][pageId] || !localizedRoutes[lang][pageId].url)
    return `/${lang}`;
  let route = `/${lang}/${localizedRoutes[lang][pageId].url}`;
  const requiredParams = route.match(/\[(.*?)\]/g);
  if (!requiredParams) return route;
  if (!routeParams || routeParams.length !== requiredParams.length) {
    throw new Error(
      `${route} requires params: ${(requiredParams || []).join()} got params ${(
        routeParams || []
      ).join()}`
    );
  }
  requiredParams.forEach((param, index) => {
    route = route.replace(param, routeParams[index]);
  });
  return route;
};

export const getRouteAuthenticationStatus = (pageId: TPageId): boolean => {
  return authenticatedRoutes.includes(pageId);
};

export const getRouteUnauthicatedStatus = (pageId: TPageId): boolean => {
  return unauthenticatedOnlyRoutes.includes(pageId);
};

export const getRouteProperties = (
  lang: string,
  pageId?: TPageId,
  routeParams?: any[]
): TRouteDefinitionType => {
  if (deprecatedRoutes.includes(pageId)) {
    console.warn(`Deprecated route being referenced in code ${pageId}`);
  }
  return {
    requiresAuthentication: getRouteAuthenticationStatus(pageId),
    route: getLocalizedRoute(lang, pageId, routeParams),
    unauthenticatedOnly: getRouteUnauthicatedStatus(pageId),
  };
};
