import {
  base64ToBlob,
  downloadBlob,
  getCarrierLocationName,
  mapLaneEquipmentDataForManagement,
  transformI18nLocaleToLanguage,
} from '@truxweb/utils';
import {
  CarrierLaneManagementForm as BaseCarrierLaneManagementForm,
  Loading,
  TCarrierLaneManagementForm as TBaseCarrierLaneManagementForm,
} from '@truxweb/common-components';
import { Box, TRUXWEB_THEME_ORIGINAL, Typography } from '@truxweb/ux';
import { createLanes, downloadRateSheetById, updateLanes } from '../../actions';
import { getRouteProperties, getUserTypeFromUserData } from '../../utils';
import React, { useCallback, useEffect, useState } from 'react';
import { type TCarrierLaneEquipmentV1, type TCarrierLaneV1 } from '@truxweb/schemas';
import {
  useAlerts,
  useCarrierData,
  useCarrierEquipmentData,
  useCarrierLaneByLaneId,
  useCarrierZones,
  useUserData,
} from '../../hooks';
import { GOOGLE_MAPS_API_KEY } from '../../config';
import { TruxiiMessage } from '..';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'account', 'search'];

type TCarrierLaneManagementForm = Omit<
  TBaseCarrierLaneManagementForm,
  | 'handleError'
  | 'handleSuccess'
  | 't'
  | 'handleCreateLane'
  | 'handleUpdateLane'
  | 'carrier'
  | 'handleReturnToList'
  | 'carrierLaneEquipment'
  | 'lane'
  | 'equipmentPrefix'
  | 'handleHideShapes'
  | 'applicationTheme'
  | 'locale'
  | 'mapTheme'
  | 'googleMapsApiKey'
  | 'zones'
  | 'newLanePrompt'
  | 'pendingUpdateMessage'
  | 'isSaving'
  | 'areButtonsDisabled'
  | 'isLoading'
  | 'handleCancel'
> & {
  routePrefix?: '';
};

export const CarrierLaneManagementForm = ({
  laneId,
  routePrefix,
}: TCarrierLaneManagementForm): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const { locale } = router;
  const { addAlert } = useAlerts();
  const { userData } = useUserData();
  const userType = getUserTypeFromUserData(userData);
  const [hasFormDataBeenLoaded, setHasFormDataBeenLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [laneEquipment, setLaneEquipment] = useState<TCarrierLaneEquipmentV1[]>([]);

  const handleReturnToList = useCallback(() => {
    const { route } = getRouteProperties(locale, `${routePrefix || ''}fleet/lanes`, [
      router.query.carrierId,
    ]);
    router.push(route);
  }, [router, locale, routePrefix]);

  const onCancel = useCallback(() => {
    handleReturnToList();
  }, [handleReturnToList]);

  const [carrierData, hasCarrierDataFetched] = useCarrierData();
  const [lane, hasCarrierLaneFetched] = useCarrierLaneByLaneId(laneId || null);
  const [carrierEquipment, hasCarrierEquipmentFetched] = useCarrierEquipmentData();

  const [zones, haveZonesLoaded] = useCarrierZones();

  useEffect(() => {
    if (
      haveZonesLoaded &&
      hasCarrierDataFetched &&
      hasCarrierLaneFetched &&
      hasCarrierEquipmentFetched
    ) {
      const laneEquipment = mapLaneEquipmentDataForManagement(
        carrierEquipment,
        lane ? lane?.equipment : [],
        carrierData,
        laneId
      );
      setLaneEquipment(laneEquipment);
      setHasFormDataBeenLoaded(true);
    }
  }, [
    hasCarrierEquipmentFetched,
    laneId,
    carrierEquipment,
    lane,
    setHasFormDataBeenLoaded,
    hasCarrierLaneFetched,
    hasCarrierDataFetched,
    carrierData,
    haveZonesLoaded,
  ]);

  const handleError = useCallback(
    (error: Error) => {
      addAlert({
        message: error.message,
        severity: 'error',
      });
    },
    [addAlert]
  );

  const handleSuccess = useCallback(
    (message: string) => {
      addAlert({
        message,
        severity: 'success',
      });
      handleReturnToList();
    },
    [addAlert, handleReturnToList]
  );

  const handleHideShapes = useCallback(() => {
    addAlert({
      message: t('common:customShapesHaveBeenHidden'),
      severity: 'info',
    });
  }, [addAlert, t]);

  const handleCreateLane = useCallback(
    async (lanes: TCarrierLaneV1[]) => {
      setIsSaving(true);

      await createLanes(lanes);
      setIsSaving(false);
    },
    [setIsSaving]
  );
  const handleUpdateLane = useCallback(
    async (lanes: TCarrierLaneV1[]) => {
      setIsSaving(true);

      await updateLanes(lanes);
      setIsSaving(false);
    },
    [setIsSaving]
  );

  const pendingUpdateMessageContent = (
    <Box mb={10}>
      <TruxiiMessage content={t('common:pendingLaneUpdates')} />
    </Box>
  );

  if (!hasFormDataBeenLoaded) return <Loading isLoading={true} t={t} />;
  return (
    <>
      {Boolean(lane) && (
        <Typography color="primary" variant="bodyLarge">
          {t('common:edit')}
          &nbsp;
          {lane?.carrierReference ||
            `${getCarrierLocationName(lane?.origin)} → ${getCarrierLocationName(
              lane?.destination
            )}`}
        </Typography>
      )}
      <BaseCarrierLaneManagementForm
        applicationTheme={TRUXWEB_THEME_ORIGINAL}
        areButtonsDisabled={isSaving}
        carrier={carrierData}
        carrierLaneEquipment={laneEquipment}
        equipmentPrefix={userType}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        handleCancel={onCancel}
        handleCreateLane={handleCreateLane}
        handleDownloadRateSheet={async (row) => {
          const rateSheetXlsx = await downloadRateSheetById(
            row.id as number,
            carrierData.id as number
          );

          downloadBlob(
            base64ToBlob(rateSheetXlsx as string),
            `Truxweb - ${userData.companyName} - ${row.name} - V${row.version}.xlsx`
          );
        }}
        handleError={handleError}
        handleHideShapes={handleHideShapes}
        handleSuccess={handleSuccess}
        handleUpdateLane={handleUpdateLane}
        isLoading={!hasCarrierLaneFetched}
        isSaving={isSaving}
        lane={lane}
        laneId={laneId}
        language={transformI18nLocaleToLanguage(locale)}
        newLanePrompt={<TruxiiMessage content={t('common:newLaneMessage')} />}
        pendingUpdateMessage={pendingUpdateMessageContent}
        t={t}
        zones={zones}
      />
    </>
  );
};
