// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeV1 = exports.ShipmentStatusV1 = void 0;
var ShipmentStatusV1;
(function (ShipmentStatusV1) {
    ShipmentStatusV1["REQUIRES_DOCUMENTS"] = "REQUIRES_DOCUMENTS";
    ShipmentStatusV1["PICKUP_READY"] = "PICKUP_READY";
    ShipmentStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentStatusV1["DELIVERED"] = "DELIVERED";
    ShipmentStatusV1["AWAITING_CARRIER_INVOICE"] = "AWAITING_CARRIER_INVOICE";
    ShipmentStatusV1["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    ShipmentStatusV1["READY_FOR_PROCESSING"] = "READY_FOR_PROCESSING";
    ShipmentStatusV1["PROCESSING_FAILURE"] = "PROCESSING_FAILURE";
    ShipmentStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentStatusV1["CANCELLED"] = "CANCELLED";
})(ShipmentStatusV1 || (exports.ShipmentStatusV1 = ShipmentStatusV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
