"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierRegistrationCompanyDetails = void 0;
/* eslint-disable max-lines */
const utils_1 = require("@truxweb/utils");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const CarrierRegistrationCompanyDetails_styles_1 = require("./CarrierRegistrationCompanyDetails.styles");
//FIXME: ADD US SUPPORT
// JIRA ticket logged to fix this here: https://truxweb.atlassian.net/browse/TP-1123
const SUPPORTED_CURRENCY = { CAD: schemas_1.ECurrencyV1.CAD };
// eslint-disable-next-line complexity
const CarrierRegistrationCompanyDetails = ({ addAlert, handleUploadFile, isFirstStep, isFormDisabled, isLastStep, isLoading, isTitleSuppressed, isUploading, onPreviousStep, onSubmitSuccess, registrationDetails, shouldUseStepperButtons, t, }) => {
    const { control, formState: { errors }, getValues, handleSubmit, setError, watch, } = (0, react_hook_form_1.useForm)({
        defaultValues: {
            currency: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.currency) || '',
            eldOtherType: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.eldOtherType) || '',
            eldSoftwareName: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.eldSoftwareName) || '',
            insuranceCertificateExpirationDate: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.insuranceCertificateExpirationDate) || null,
            insuranceCertificateUrl: {
                localName: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.insuranceCertificateFileName) || '',
                remoteName: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.insuranceCertificateUrl) || '',
            },
            tmsOtherType: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.tmsOtherType) || '',
            tmsSoftwareName: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.tmsSoftwareName) || '',
            uniqueIdentifier: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.uniqueIdentifier) || '',
            uniqueIdentifierType: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.uniqueIdentifierType) || '',
            unitOfDistance: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.unitOfDistance) || '',
        },
    });
    const dateClasses = (0, CarrierRegistrationCompanyDetails_styles_1.useDateStyles)();
    const [tmsCompleteState, setTmsCompleteState] = (0, react_1.useState)(false);
    const [insuranceCertCompleteState, setInsuranceCertCompleteState] = (0, react_1.useState)(false);
    const [uniqueIdentifierCompleteState, setUniqueIdentifierCompleteState] = (0, react_1.useState)(false);
    const [currencyCompleteState, setCurrencyCompleteState] = (0, react_1.useState)(false);
    const [unitOfDistanceCompleteState, setUnitOfDistanceCompleteState] = (0, react_1.useState)(false);
    const [uniqueIdentifierRegex, setUniqueIdentifierRegex] = (0, react_1.useState)(utils_1.DOT_REGEX);
    const uniqueIdentifierType = watch('uniqueIdentifierType');
    const handleSubmitSuccess = (0, react_1.useCallback)((data) => {
        const { addressLineOne, addressLineTwo, companyLocation, insuranceCertificateUrl } = data, others = __rest(data, ["addressLineOne", "addressLineTwo", "companyLocation", "insuranceCertificateUrl"]);
        const formattedData = Object.assign({ companyAddress: Object.assign({ addressLineOne,
                addressLineTwo }, companyLocation), insuranceCertificateFileName: insuranceCertificateUrl.localName, 
            // NOTE: The public prefix needs to be added here as this automatically
            // added by amazon becuase this is exposed publicly to allow for uploads
            insuranceCertificateUrl: `public/${insuranceCertificateUrl.remoteName}` }, others);
        onSubmitSuccess(formattedData);
    }, [onSubmitSuccess]);
    const handleWatchTmsData = (0, react_1.useCallback)((data) => {
        if (data.tmsSoftwareName && data.eldSoftwareName) {
            setTmsCompleteState(true);
        }
        else {
            setTmsCompleteState(false);
        }
    }, [setTmsCompleteState]);
    const handleWatchInsuranceData = (0, react_1.useCallback)((data) => {
        if (data.insuranceCertificateUrl && data.insuranceCertificateExpirationDate) {
            setInsuranceCertCompleteState(true);
        }
        else {
            setInsuranceCertCompleteState(false);
        }
    }, [setInsuranceCertCompleteState]);
    const handleWatchUniqueIdentifierData = (0, react_1.useCallback)((data) => {
        if (data.uniqueIdentifier && data.uniqueIdentifierType) {
            setUniqueIdentifierCompleteState(true);
        }
        else {
            setUniqueIdentifierCompleteState(false);
        }
    }, [setUniqueIdentifierCompleteState]);
    const handleWatchCurrencyData = (0, react_1.useCallback)((data) => {
        if (data.currency) {
            setCurrencyCompleteState(true);
        }
        else {
            setCurrencyCompleteState(false);
        }
    }, [setCurrencyCompleteState]);
    const handleWatchUnitOfDistanceData = (0, react_1.useCallback)((data) => {
        if (data.unitOfDistance) {
            setUnitOfDistanceCompleteState(true);
        }
        else {
            setUnitOfDistanceCompleteState(false);
        }
    }, [setUnitOfDistanceCompleteState]);
    watch((data) => {
        handleWatchTmsData(data);
        handleWatchInsuranceData(data);
        handleWatchUniqueIdentifierData(data);
        handleWatchCurrencyData(data);
        handleWatchUnitOfDistanceData(data);
    });
    (0, react_1.useEffect)(() => {
        if (uniqueIdentifierType === 'DOT') {
            setUniqueIdentifierRegex(utils_1.DOT_REGEX);
        }
        else if (uniqueIdentifierType === 'NIR') {
            setUniqueIdentifierRegex(utils_1.NIR_REGEX);
        }
        else {
            setUniqueIdentifierRegex(utils_1.ALPHANUMERIC_REGEX);
        }
    }, [uniqueIdentifierType, setUniqueIdentifierRegex]);
    const content = (react_1.default.createElement(react_1.default.Fragment, null,
        !isFormDisabled && !isTitleSuppressed && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: 'flex-start', style: { flexGrow: 1 } },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { color: "primary", variant: "h5" }, t('signup:carrierRegistrationDetails'))))),
        react_1.default.createElement(ux_1.Box, { mb: 6.5 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "row" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mr: 2, mt: 4.5 },
                        react_1.default.createElement(__1.FormStateIcon, { completeIcon: react_1.default.createElement(ux_1.MyLocationIcon, null), incompleteIcon: react_1.default.createElement(ux_1.MyLocationIcon, null), isInCompleteState: uniqueIdentifierCompleteState, isInErrorState: false }))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        react_1.default.createElement(ux_1.Grid, { item: true, style: { color: '#636363', height: 26 }, xs: true },
                            react_1.default.createElement(ux_1.Box, { mb: 1 },
                                react_1.default.createElement(ux_1.Typography, null,
                                    t('signup:uniqueIdentifier'),
                                    react_1.default.createElement("sup", null, "*")))),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "row", justifyContent: "space-between" },
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                                    react_1.default.createElement(ux_1.Box, { mr: 1 },
                                        react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: '', id: 'uniqueIdentifierType', isRequired: true, label: '', t: t }, (onChange, value) => {
                                            return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(errors.uniqueIdentifierType), isDisabled: isFormDisabled, isOtherIncluded: true, isRequired: true, onChange: onChange, sourceEnum: schemas_1.EUniqueIdentifierTypeV1, t: t, value: value }));
                                        }))),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                                    react_1.default.createElement(__1.FormField, { control: control, defaultValue: '', id: 'uniqueIdentifier', isDisabled: isFormDisabled, isRequired: true, label: '', t: t, validationRules: {
                                            pattern: uniqueIdentifierRegex,
                                        } })))))))),
        react_1.default.createElement(ux_1.Box, { mb: 6.5 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mr: 2, mt: 4.5 },
                        react_1.default.createElement(__1.FormStateIcon, { completeIcon: react_1.default.createElement(ux_1.SettingsIcon, null), incompleteIcon: react_1.default.createElement(ux_1.SettingsOutlinedIcon, null), isInCompleteState: tmsCompleteState, isInErrorState: false }))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: '', id: 'tmsSoftwareName', isRequired: true, label: t('signup:tmsPlaceholder'), t: t }, (onChange, value) => {
                                return (react_1.default.createElement(react_1.default.Fragment, null,
                                    react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(errors.tmsSoftwareName), isDisabled: isFormDisabled, isOtherIncluded: true, isRequired: true, onChange: onChange, sourceEnum: schemas_1.ETmsPackagesV1, t: t, value: value, variant: "outlined" }),
                                    value === 'other' && (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "tmsOtherType", render: ({ field: { onChange, value } }) => {
                                            return (react_1.default.createElement(ux_1.Box, { mt: 3 },
                                                react_1.default.createElement(ux_1.TextField, { disabled: isFormDisabled, error: Boolean(errors.tmsOtherType), fullWidth: true, helperText: errors.tmsOtherType ? t('signup:tmsOtherType') : null, id: "tmsOtherType", label: t('signup:tmsOtherType'), onChange: onChange, required: true, value: value, variant: "outlined" })));
                                        }, rules: { required: true } }))));
                            })),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mt: 4 },
                                react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: '', id: 'eldSoftwareName', isRequired: true, label: t('signup:eldPlaceholder'), t: t }, (onChange, value) => {
                                    return (react_1.default.createElement(react_1.default.Fragment, null,
                                        react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(errors.eldSoftwareName), isDisabled: isFormDisabled, isOtherIncluded: true, isRequired: true, onChange: onChange, sourceEnum: schemas_1.EEldPackagesV1, t: t, value: value, variant: "outlined" }),
                                        value === 'other' && (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "eldOtherType", render: ({ field: { onChange, value } }) => {
                                                return (react_1.default.createElement(ux_1.Box, { mt: 3 },
                                                    react_1.default.createElement(ux_1.TextField, { disabled: isFormDisabled, error: Boolean(errors.eldOtherType), fullWidth: true, helperText: errors.eldOtherType ? t('signup:eldOtherType') : null, id: "eldOtherType", label: t('signup:eldOtherType'), onChange: onChange, required: true, value: value, variant: "outlined" })));
                                            }, rules: { required: true } }))));
                                }))))))),
        react_1.default.createElement(ux_1.Box, { mb: 6.5 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mr: 2, mt: 4.5 },
                        react_1.default.createElement(__1.FormStateIcon, { completeIcon: react_1.default.createElement(ux_1.MonetizationOnIcon, null), incompleteIcon: react_1.default.createElement(ux_1.MonetizationOnOutlinedIcon, null), isInCompleteState: currencyCompleteState && unitOfDistanceCompleteState, isInErrorState: false }))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "row", justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: schemas_1.ECurrencyV1.CAD, id: 'currency', isDisabled: isFormDisabled, isRequired: true, label: t('signup:currency'), t: t }, (onChange, value) => {
                                return (react_1.default.createElement(react_1.default.Fragment, null,
                                    react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", isDisabled: isFormDisabled, onChange: (value) => {
                                            onChange(value);
                                        }, sourceEnum: SUPPORTED_CURRENCY, t: t, value: value })));
                            })),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: schemas_1.ERangeUnitOfMeasureV1.km, id: 'unitOfDistance', isDisabled: isFormDisabled, isRequired: true, label: t('signup:unitOfDistance'), t: t }, (onChange, value) => {
                                return (react_1.default.createElement(react_1.default.Fragment, null,
                                    react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", isDisabled: isFormDisabled, onChange: (value) => {
                                            onChange(value);
                                        }, sourceEnum: schemas_1.ERangeUnitOfMeasureV1, t: t, value: value })));
                            })))))),
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mr: 2, mt: 4.5 },
                    react_1.default.createElement(__1.FormStateIcon, { completeIcon: react_1.default.createElement(ux_1.NoteAddIcon, null), incompleteIcon: react_1.default.createElement(ux_1.NoteAddOutlinedIcon, null), isInCompleteState: insuranceCertCompleteState, isInErrorState: false }))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", style: { width: '100%' } },
                    react_1.default.createElement(ux_1.Grid, { item: true, style: { color: '#636363', height: 26 }, xs: true },
                        react_1.default.createElement(ux_1.Box, { mb: 1 },
                            react_1.default.createElement(ux_1.Typography, null,
                                t('signup:uploadInsuranceCertificate'),
                                react_1.default.createElement("sup", null, "*")))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: {
                                localName: '',
                                remoteName: '',
                            }, name: "insuranceCertificateUrl", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                                return (react_1.default.createElement(__1.FileListAndUpload, { hasError: Boolean(error), isFormDisabled: isFormDisabled, isUploading: isUploading, onChange: onChange, onError: (errorMessage) => {
                                        addAlert({
                                            message: errorMessage,
                                            severity: 'error',
                                        });
                                        setError('insuranceCertificateUrl', {
                                            message: errorMessage,
                                            type: 'manual',
                                        });
                                    }, onUploadFile: handleUploadFile, t: t, value: value }));
                            }, rules: {
                                required: true,
                                validate: (value) => {
                                    return value.localName !== '' && value.remoteName !== '';
                                },
                            } })),
                    react_1.default.createElement(ux_1.Grid, { item: true, style: { width: '100%' }, xs: true },
                        react_1.default.createElement(ux_1.Box, { mb: 4, mt: 4 },
                            react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: null, id: 'insuranceCertificateExpirationDate', isDisabled: isFormDisabled, isRequired: true, label: t('signup:insuranceCertificateExpirationDate'), t: t }, (onChange, value, error) => {
                                //FIXME: due to a bug with `inputVariant="outlined"` this form field does not
                                // match the rest of the design
                                return (react_1.default.createElement(ux_1.DatePickerProvider, { language: schemas_1.ELanguageV1.EN_CA },
                                    react_1.default.createElement(ux_1.DatePicker, { InputProps: {
                                            classes: dateClasses,
                                            endAdornment: (react_1.default.createElement(ux_1.InputAdornment, { position: "end" },
                                                react_1.default.createElement(ux_1.EventIcon, null))),
                                        }, disabled: isFormDisabled, error: Boolean(error), format: 'yyyy-MM-dd', fullWidth: true, minDate: new Date(), minDateMessage: t('common:insuranceCertificateHasExpired'), onChange: onChange, required: true, value: value, variant: "inline" })));
                            })))))),
        !isFormDisabled && (react_1.default.createElement(ux_1.Box, { ml: 3, mt: 4, style: { width: '100%' } },
            shouldUseStepperButtons && (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.FlatButton, { color: "primary", onClick: () => {
                            if (onPreviousStep) {
                                const formData = getValues();
                                onSubmitSuccess(formData, true);
                                onPreviousStep();
                            }
                        }, variant: "outlined" }, !isFirstStep ? t('common:back') : t('common:cancel'))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.FlatButton, { color: "primary", type: "submit", variant: "contained" }, isLastStep ? t('common:lastStep') : t('common:next'))))),
            !shouldUseStepperButtons && (react_1.default.createElement(ux_1.Button, { color: "primary", disabled: isLoading || isUploading, fullWidth: true, startIcon: isLoading ? react_1.default.createElement(ux_1.CircularProgress, null) : null, style: { height: 45 }, type: "submit", variant: "contained" }, isLastStep ? t('common:lastStep') : t('common:continue')))))));
    if (isFormDisabled)
        return content;
    return react_1.default.createElement("form", { onSubmit: handleSubmit(handleSubmitSuccess) }, content);
};
exports.CarrierRegistrationCompanyDetails = CarrierRegistrationCompanyDetails;
