var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c1ySu9SLGpHl0lN0y_5bF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, replayIntegration } from '@sentry/nextjs';
import {
  IS_SENTRY_DEBUG_ENABLED,
  IS_SENTRY_RELEASE_ENABLED,
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
} from './config';

if (IS_SENTRY_RELEASE_ENABLED === true) {
  if (IS_SENTRY_DEBUG_ENABLED) {
    console.log('SENTRY_SAMPLE_RATE', SENTRY_SAMPLE_RATE);
    console.log('SENTRY_DSN', SENTRY_DSN);
    console.log('IS_SENTRY_RELEASE_ENABLED', IS_SENTRY_RELEASE_ENABLED);
    console.log('IS_SENTRY_DEBUG_ENABLED', IS_SENTRY_DEBUG_ENABLED);
  }
  init({
    debug: IS_SENTRY_DEBUG_ENABLED,
    dsn: SENTRY_DSN,
    integrations: [replayIntegration()],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE || '0.1', 10),
  });
}
