"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRvIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.723,7H22a2,2,0,0,0,2-2,4,4,0,0,0-4-4H5A5.006,5.006,0,0,0,0,6v6.515a6.954,6.954,0,0,0,2.05,4.949l.95.95V19.5a3.5,3.5,0,0,0,7,0V19h5v.5a3.5,3.5,0,0,0,7,0V19h1a1,1,0,0,0,1-1V14.593a7.009,7.009,0,0,0-.922-3.472ZM2,7H8V9H2ZM8,19.5a1.5,1.5,0,0,1-3,0V19H8ZM13,17H4.414l-.95-.95A4.967,4.967,0,0,1,2,12.515V11H8a2,2,0,0,0,2-2V7A2,2,0,0,0,8,5H2.172A3,3,0,0,1,5,3H20a2,2,0,0,1,2,2H17a4,4,0,0,0-4,4Zm7.7-6H15V9a2,2,0,0,1,2-2h1.419ZM20,19.5a1.5,1.5,0,0,1-3,0V19h3ZM22,17H15V13h6.739A5,5,0,0,1,22,14.593Z" })));
exports.default = TruxwebRvIcon;
