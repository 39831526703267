"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFoxIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.241.435a1.983,1.983,0,0,0-1.7-.383A6.132,6.132,0,0,0,17.4,3.782,21.876,21.876,0,0,0,12,3a21.876,21.876,0,0,0-5.4.782A6.133,6.133,0,0,0,2.456.052a1.982,1.982,0,0,0-1.7.383A2,2,0,0,0,0,2.006V9.858a10.927,10.927,0,0,0,3.222,7.778l4.657,4.657a5.862,5.862,0,0,0,8.242,0l4.657-4.657A10.931,10.931,0,0,0,24,9.858V2.006A1.994,1.994,0,0,0,23.241.435ZM5.051,5.316a1,1,0,0,0,1.264.633A20.924,20.924,0,0,1,12,5a20.934,20.934,0,0,1,5.685.949,1,1,0,0,0,1.263-.633c.617-1.849,1.671-2.995,3.052-3.31V8.173c-2.428.559-9,2.811-9,10.827v.184a2.868,2.868,0,0,0-2,0V19C11,10.985,4.429,8.732,2,8.173L2,2C3.381,2.321,4.435,3.468,5.051,5.316ZM4.636,16.222a8.929,8.929,0,0,1-2.618-6A11.431,11.431,0,0,1,4,10.945V11a3.781,3.781,0,0,0,3.6,2.978A8.852,8.852,0,0,1,9,19v1.586Zm14.728,0L15,20.586V19a8.852,8.852,0,0,1,1.4-5.022A3.783,3.783,0,0,0,20,11v-.055a11.431,11.431,0,0,1,1.982-.722A8.929,8.929,0,0,1,19.364,16.222Z" })));
exports.default = TruxwebFoxIcon;
