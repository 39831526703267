"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarMechanicIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.02,9.52A5.011,5.011,0,0,0,15.679,7H8.321A5.013,5.013,0,0,0,3.98,9.52l-2.058,3.6A7.022,7.022,0,0,0,1,16.594V18a4,4,0,0,0,2,3.463V22a2,2,0,0,0,4,0H17a2,2,0,0,0,4,0v-.537A4,4,0,0,0,23,18V16.594a7.007,7.007,0,0,0-.923-3.474Zm-14.3.992A3.009,3.009,0,0,1,8.321,9h7.358a3.006,3.006,0,0,1,2.6,1.512L20.277,14H3.723ZM21,18a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V16.594A5.119,5.119,0,0,1,3.036,16H5v1a1,1,0,0,0,2,0V16H17v1a1,1,0,0,0,2,0V16h1.964a5.119,5.119,0,0,1,.036.594ZM23.493,4.649A3.015,3.015,0,0,1,18.167,4H5.845a3.015,3.015,0,0,1-5.326.649C0,3.717,1.926,4.061,2.012,4a1,1,0,0,0,0-2h-1C.339,2,.387,1.552.519,1.351A3.015,3.015,0,0,1,5.845,2H18.167a3.015,3.015,0,0,1,5.326-.649c.524.932-1.407.588-1.493.649a1,1,0,0,0,0,2h1C23.673,4,23.625,4.448,23.493,4.649Z" })));
exports.default = TruxwebCarMechanicIcon;
