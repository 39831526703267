import { CarrierPage } from '../../pageTemplates';
import { CarrierProfile } from '../../components';
import { ELanguageV1 } from '@truxweb/schemas';
import { TTruxwebPageContentProps } from '../../types';
import { useInternalCarrierProfileData } from '../../hooks';
import { useRouter } from 'next/router';

type TCarrierProfilePageProps = TTruxwebPageContentProps & { companyCode: string };
export const CompanyProfilePage = ({
  companyCode,
  pageId,
}: TCarrierProfilePageProps): JSX.Element => {
  const { locale } = useRouter();
  const [carrierData, hasResponseLoaded] = useInternalCarrierProfileData(
    companyCode.toUpperCase(),
    locale.toUpperCase() as ELanguageV1
  );

  return (
    <CarrierPage pageId={pageId}>
      <CarrierProfile
        carrierData={carrierData}
        companyCode={companyCode}
        hasResponseLoaded={hasResponseLoaded}
        isInternalProfile={true}
      />
    </CarrierPage>
  );
};

export default CompanyProfilePage;
