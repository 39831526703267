/* eslint-disable complexity */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { Card } from '..';
import Image from 'next/image';
import React from 'react';
import Star from '../../public/assets/images/icons/star.svg';
import { useStyles } from './TestimonialCard.styles';

type TTestimonialCardProps = {
  avatarSrc: string;
  name: string;
  numStars: number;
  text: string;
};

export const TestimonialCard = ({
  avatarSrc,
  name,
  numStars,
  text,
}: TTestimonialCardProps): JSX.Element => {
  const stars = [];
  const theme = useTheme();

  // up to 1440px
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ avatarSrc });

  for (let i = 0; i < numStars; i++) {
    stars.push(<Star height={isMobile ? 10 : 16} key={i} width={isMobile ? 10 : 16} />);
  }

  const avatarAndRating = (
    <Grid alignItems="center" container direction="column" item xs={isTablet ? 6 : 4}>
      <Box className={classes.avatar} mb={1}>
        <Image height={72} layout="responsive" src={`${avatarSrc}`} width={72} />
      </Box>
      <Grid className={classes.stars} container item justifyContent="space-evenly" wrap="nowrap">
        {stars.map((star) => star)}
      </Grid>
    </Grid>
  );

  const nameAndReview = (
    <Grid alignItems={isTablet ? 'center' : 'flex-start'} container direction="column" item>
      <Grid item>
        <Typography
          align={isTablet || isMobile ? 'center' : 'left'}
          className={classes.text}
          variant={'body1'}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.name}>{name}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Card className={classes.container}>
      <Grid
        alignItems="center"
        container
        direction={isTablet ? 'column' : 'row'}
        spacing={isMobile ? 0 : 2}
        wrap="nowrap"
      >
        {avatarAndRating}
        {nameAndReview}
      </Grid>
    </Card>
  );
};
