"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFileAiIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M7.515,5.008a.744.744,0,0,0-.687-.464h0a.748.748,0,0,0-.695.477L4.156,10.208a.625.625,0,0,0,.362.807.64.64,0,0,0,.222.041.626.626,0,0,0,.584-.4l.339-.889H7.974l.335.887a.625.625,0,1,0,1.17-.441ZM6.139,8.514l.684-1.8.679,1.8Z" }),
    React.createElement("path", { d: "M11.232,4.544a.626.626,0,0,0-.625.625v5.262a.625.625,0,0,0,1.25,0V5.169A.625.625,0,0,0,11.232,4.544Z" }),
    React.createElement("path", { d: "M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.968,4.968,0,0,0,3.536-1.464l2.656-2.658A4.968,4.968,0,0,0,24,16.343V5A5.006,5.006,0,0,0,19,0ZM2,19V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3V15H18a3,3,0,0,0-3,3v4H5A3,3,0,0,1,2,19Zm16.465,2.122a2.975,2.975,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.925a3.016,3.016,0,0,1-.8,1.464Z" })));
exports.default = TruxwebFileAiIcon;
