import { addAlert as addAlertToRedux, removeAlert } from '../stores';
import { Alert, AlertTitle, Snackbar } from '@truxweb/ux';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { TAlertV1 } from '@truxweb/schemas';
import { useAlertData } from '../hooks';
import { useDispatch } from 'react-redux';

export type AlertContextType = {
  addAlert?: (alertMessage: TAlertV1) => void;
};
export const AlertContext = createContext<AlertContextType>({});

type AlertProviderProps = {
  children: React.ReactNode;
};

const AUTO_DISMISS = 5000;
export const AlertProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const alerts = useAlertData();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const addAlert = useCallback(
    (alertMessage: TAlertV1) => {
      dispatch(addAlertToRedux(alertMessage));
      setIsOpen(true);
    },
    [setIsOpen, dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(removeAlert());
    setIsOpen(false);
  }, [dispatch]);

  const activeAlert = alerts.length ? alerts[0] : null;
  const alertContent = activeAlert ? (
    <>
      <Alert
        onClose={handleClose}
        severity={activeAlert?.severity || 'info'}
        style={{ maxWidth: 500 }}
      >
        {activeAlert?.title !== undefined && <AlertTitle>{activeAlert?.title}</AlertTitle>}
        {activeAlert?.message || ''}
      </Alert>
    </>
  ) : null;

  useEffect(() => {
    if (activeAlert) {
      setIsOpen(true);
    }
  }, [activeAlert, setIsOpen]);

  return (
    <AlertContext.Provider value={{ addAlert }}>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={AUTO_DISMISS}
        onClose={handleClose}
        open={isOpen}
      >
        {alertContent}
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
};
