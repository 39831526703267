import {
  type AppThunk,
  setSearchResultsData,
  setSearchResultsError,
  setSearchResultsLoading,
} from '../../../stores';
import { bookingSearch } from '.';
import { type TBookingSearchRequestV1 } from '@truxweb/schemas';

export const getSearchResults =
  (query: TBookingSearchRequestV1): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setSearchResultsLoading());
      const results = await bookingSearch(query);

      dispatch(setSearchResultsData({ data: results }));
    } catch (error) {
      dispatch(setSearchResultsError({ error: error.message }));
    }
  };
