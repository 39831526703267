// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruckloadTypeV1 = exports.AccessorialOptionV1 = exports.RequiredTruckFeatureV1 = exports.CountryV1 = exports.CarrierTruckTypeV1 = void 0;
var CarrierTruckTypeV1;
(function (CarrierTruckTypeV1) {
    CarrierTruckTypeV1["FTL53Dry"] = "53' Dry Van (FTL)";
    CarrierTruckTypeV1["FTL53Reefer"] = "53' Refrigerated Van (FTL)";
    CarrierTruckTypeV1["ContainerChassis20"] = "Container Chassis 20'";
    CarrierTruckTypeV1["ContainerChassis40"] = "Container Chassis 40'";
    CarrierTruckTypeV1["Flatbed"] = "Flat bed";
})(CarrierTruckTypeV1 || (exports.CarrierTruckTypeV1 = CarrierTruckTypeV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var RequiredTruckFeatureV1;
(function (RequiredTruckFeatureV1) {
    RequiredTruckFeatureV1["DRY"] = "DRY";
    RequiredTruckFeatureV1["FLATBED"] = "FLATBED";
    RequiredTruckFeatureV1["REFRIGERATED"] = "REFRIGERATED";
})(RequiredTruckFeatureV1 || (exports.RequiredTruckFeatureV1 = RequiredTruckFeatureV1 = {}));
var AccessorialOptionV1;
(function (AccessorialOptionV1) {
    AccessorialOptionV1["DANGEROUS_GOODS"] = "DANGEROUS_GOODS";
    AccessorialOptionV1["CARGO_INSURANCE"] = "CARGO_INSURANCE";
    AccessorialOptionV1["MULTI_STOP"] = "MULTI_STOP";
    AccessorialOptionV1["SCHEDULED_PICKUP"] = "SCHEDULED_PICKUP";
    AccessorialOptionV1["SCHEDULED_DELIVERY"] = "SCHEDULED_DELIVERY";
    AccessorialOptionV1["RESIDENTIAL_PICKUP"] = "RESIDENTIAL_PICKUP";
    AccessorialOptionV1["RESIDENTIAL_DELIVERY"] = "RESIDENTIAL_DELIVERY";
    AccessorialOptionV1["TAILGATE_PICKUP"] = "TAILGATE_PICKUP";
    AccessorialOptionV1["TAILGATE_DELIVERY"] = "TAILGATE_DELIVERY";
    AccessorialOptionV1["EXPEDITED_DELIVERY"] = "EXPEDITED_DELIVERY";
    AccessorialOptionV1["TARP"] = "TARP";
    AccessorialOptionV1["DROP_TRAILER"] = "DROP_TRAILER";
    AccessorialOptionV1["GENSET_CHARGE"] = "GENSET_CHARGE";
    AccessorialOptionV1["PRE_PULL"] = "PRE_PULL";
    AccessorialOptionV1["TRACKING"] = "TRACKING";
    AccessorialOptionV1["TRUCK_ONLY"] = "TRUCK_ONLY";
    AccessorialOptionV1["INSIDE_DELIVERY"] = "INSIDE_DELIVERY";
    AccessorialOptionV1["CURBSIDE_DELIVERY"] = "CURBSIDE_DELIVERY";
    AccessorialOptionV1["LIMITED_ACCESS_PICKUP"] = "LIMITED_ACCESS_PICKUP";
    AccessorialOptionV1["LIMITED_ACCESS_DELIVERY"] = "LIMITED_ACCESS_DELIVERY";
    AccessorialOptionV1["WHITE_GLOVE_PICKUP"] = "WHITE_GLOVE_PICKUP";
    AccessorialOptionV1["WHITE_GLOVE_DELIVERY"] = "WHITE_GLOVE_DELIVERY";
})(AccessorialOptionV1 || (exports.AccessorialOptionV1 = AccessorialOptionV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
