import { EPermissionV1, type TCompanyV1, type TUserV1 } from '@truxweb/schemas';
import { manageDomainError, manageDomainLoaded, manageDomainLoading } from './stateUtils';
import { TCognitoUserData, type TReducerLoading } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export type TAuthUser = TReducerLoading & {
  data: {
    user: TUserV1;
    company: TCompanyV1;
  };
  hasLoggedOut: boolean;
};

export type TAuthUserPermissions = TReducerLoading & {
  data: { [key in EPermissionV1]?: boolean };
};

export interface IAuthDataState {
  userData: TAuthUser;
  userPermissions: TAuthUserPermissions;
  authProviderData: TCognitoUserData;
}

export const authSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.alerts,
      };
    },
  },
  initialState: {
    authProviderData: {},
    userData: {},
    userPermissions: {},
  } as IAuthDataState,
  name: 'auth',
  reducers: {
    setAuthData: (state, { payload: { company, user } }) => {
      return {
        ...state,
        userData: { ...manageDomainLoaded(state.userData, { company, user }), hasLoggedOut: false },
      };
    },
    setAuthError: (state, { payload: { error } }) => {
      return {
        ...state,
        userData: { ...manageDomainError(state.userData, error), hasLoggedOut: false },
      };
    },
    setAuthLoading: (state) => {
      return {
        ...state,
        userData: { ...manageDomainLoading(state.userData), hasLoggedOut: false },
      };
    },
    setAuthProviderData: (state, { payload: { authProviderData } }) => {
      return {
        ...state,
        authProviderData: { ...authProviderData },
      };
    },
    setPermissionsData: (state, { payload: { permissions } }) => {
      return {
        ...state,
        userPermissions: manageDomainLoaded(state.userPermissions, permissions),
      };
    },
    setPermissionsError: (state, { payload: { error } }) => {
      return {
        ...state,
        userPermissions: manageDomainError(state.userPermissions, error),
      };
    },
    setPermissionsLoading: (state) => {
      return {
        ...state,
        userPermissions: manageDomainLoading(state.userPermissions),
      };
    },
    setUnauthedState: (state, { payload: { hasLoggedOut } }) => {
      return {
        ...state,
        userData: { ...manageDomainLoaded(state.userData, null), hasLoggedOut },
        userPermissions: manageDomainLoaded(state.userPermissions, null),
      };
    },
  },
});

export const {
  actions: {
    setAuthData,
    setAuthError,
    setAuthLoading,
    setAuthProviderData,
    setPermissionsData,
    setPermissionsError,
    setPermissionsLoading,
    setUnauthedState,
  },
} = authSlice;
