import { type AppThunk, setUnauthedState } from '../../../stores';
import { Auth } from 'aws-amplify';
import { EApplicationActions } from '../../../types';

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(setUnauthedState({ hasLoggedOut: true }));
  try {
    await Auth.signOut({ global: true });

    dispatch({
      type: EApplicationActions.LOGOUT,
    });
  } catch (err) {
    console.error('logout', err);
  }
};
