"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruxwebGoogleMapControl = void 0;
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const TruxwebGoogleMapControl = ({ api, children, index, map, setIndex, }) => {
    const [controlDiv, setControlDiv] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        let controlContainer = null;
        let newIndex = 0;
        if (!controlDiv) {
            controlContainer = document.createElement('div');
            setControlDiv(controlContainer);
        }
        const controls = (map === null || map === void 0 ? void 0 : map.controls[api === null || api === void 0 ? void 0 : api.ControlPosition.TOP_LEFT]) || [];
        newIndex = controls.length;
        if (!index) {
            controls.push(controlDiv || controlContainer);
        }
        setIndex(newIndex);
        return () => {
            controls === null || controls === void 0 ? void 0 : controls.removeAt(newIndex);
        };
    }, [map, setIndex, api, controlDiv, index, setControlDiv]);
    if (!controlDiv)
        return null;
    return (0, react_dom_1.createPortal)(children, controlDiv);
};
exports.TruxwebGoogleMapControl = TruxwebGoogleMapControl;
