"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loading = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const hooks_1 = require("@truxweb/hooks");
const Loading = ({ actionButton, children, className, isLoading, loadingPhrases, staticText, t, }) => {
    const [dynamicPhraseIndex, setDynamicPhraseIndex] = (0, react_1.useState)(0);
    const handleDynamicPhrase = (0, react_1.useCallback)(() => {
        if (!(loadingPhrases === null || loadingPhrases === void 0 ? void 0 : loadingPhrases.length))
            return;
        if (dynamicPhraseIndex >= loadingPhrases.length - 1) {
            setDynamicPhraseIndex(0);
            return;
        }
        setDynamicPhraseIndex(dynamicPhraseIndex + 1);
    }, [dynamicPhraseIndex, setDynamicPhraseIndex, loadingPhrases]);
    (0, hooks_1.useInterval)(handleDynamicPhrase, 7000);
    const content = children || (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.CircularProgress, { size: 80 }),
        react_1.default.createElement(ux_1.Typography, { color: "secondary", variant: "h4" }, t('common:loadingPromptHeader'))));
    const dynamicText = Boolean(loadingPhrases === null || loadingPhrases === void 0 ? void 0 : loadingPhrases.length) ? (react_1.default.createElement(ux_1.Typography, { color: "secondary", style: { maxWidth: 460, textAlign: 'center' }, variant: "h4" }, loadingPhrases[dynamicPhraseIndex])) : null;
    return (react_1.default.createElement(ux_1.Backdrop, { className: className, open: isLoading, style: {
            alignItems: 'center',
            backdropFilter: 'blur(10px)',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 99,
        } },
        Boolean(staticText) && (react_1.default.createElement(ux_1.Typography, { color: "white", fontStyle: "semibold", variant: "bodyLarge" }, staticText)),
        content,
        dynamicText,
        actionButton));
};
exports.Loading = Loading;
