import {
  type AppThunk,
  setAccessorialError,
  setAccessorialLoaded,
  setAccessorialLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const fetchAccessorialData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setAccessorialLoading());
    const data = await makeRequestToApiGateway('carrierGetV1Accessorial', null, {
      'Content-Type': 'application/json',
    });
    dispatch(setAccessorialLoaded({ data }));
  } catch (error) {
    dispatch(setAccessorialError({ error }));
  }
};
