import { type AppState, baseLoadingState, TAuthUser } from '../../stores';

export const selectAuthData = (): ((state: AppState) => TAuthUser) => {
  return (state: AppState) => {
    const userData = state?.auth?.userData || { ...baseLoadingState, hasLoggedOut: false };
    const userPermissions = state?.auth?.userPermissions || baseLoadingState;
    if (!userData.hasLoaded || !userPermissions.hasLoaded) userData;
    return userData;
  };
};
