"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        background: theme.palette.common.white,
        borderBottom: `1px solid #c4c4c4`,
        borderTop: `1px solid #c4c4c4`,
    },
}));
