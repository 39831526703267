import {
  Box,
  CheckCircleOutlineIcon,
  CloseIcon,
  Grid,
  KeyboardArrowDownIcon,
  SwipeableDrawer,
  Typography,
} from '@truxweb/ux';
import { EUserTypeV1 } from '@truxweb/schemas';
import { LandingPageButton } from '..';
import React from 'react';
import TruxiSmiling from '../../public/assets/images/truxii/truxii-smiling-large.svg';
import TruxiStars from '../../public/assets/images/truxii/truxii-stars-large.svg';
import { useStyles } from './LandingPageMobileSwipeableDrawer.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TLandingPageMobileSwipeableDrawerProps = {
  formData: Record<string, any>;
  formFieldEmail: JSX.Element;
  formFieldName: JSX.Element;
  formFieldRadio: JSX.Element;
  handleClick: (arg: EUserTypeV1) => void;
  handleClose: () => void;
  handleSubmit: any;
  isOpen: boolean;
  isSending: boolean;
  isSubmittedSuccessfully: boolean;
  link: JSX.Element;
  onSubmitSuccess: (data: any) => Promise<void>;
  setOpen: (arg: boolean) => void;
  setUserType: (arg: EUserTypeV1 | null) => void;
  userType: EUserTypeV1 | null;
};

export const LandingPageMobileSwipeableDrawer = ({
  formData,
  formFieldEmail,
  formFieldName,
  formFieldRadio,
  handleClick,
  handleClose,
  handleSubmit,
  isOpen,
  isSending,
  isSubmittedSuccessfully,
  link,
  onSubmitSuccess,
  setOpen,
  setUserType,
  userType,
}: TLandingPageMobileSwipeableDrawerProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <>
      <SwipeableDrawer
        ModalProps={{ BackdropProps: { invisible: true } }}
        PaperProps={{ className: classes.popover }}
        anchor={'bottom'}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        open={isOpen}
      >
        <Grid className={classes.container} container>
          {!userType && (
            <>
              <Grid
                alignItems="center"
                className={classes.leftColumnWrapper}
                container
                direction="column"
                item
                justifyContent="space-evenly"
              >
                <Box className={classes.blueFlash} />
                <Grid container justifyContent="flex-end">
                  <Box onClick={handleClose}>
                    <CloseIcon className={classes.closeIconDark} />
                  </Box>
                </Grid>
                <Box className={classes.truxiWrapper}>
                  {!isSubmittedSuccessfully && <TruxiSmiling height={128} width={113} />}
                </Box>
                <Typography align="center" className={classes.bold} variant="h1">
                  {t('common:joinTheRevolution')}
                </Typography>
                <Typography align="center" className={classes.launchingSoon} variant="body1">
                  {t('common:signUpToday')}
                </Typography>
                <Grid alignItems="center" container direction="column" item justifyContent="center">
                  <Typography className={classes.bold} variant="body1">
                    {t('common:selectYourProfile')}
                  </Typography>
                  <KeyboardArrowDownIcon fontSize="small" />
                </Grid>
              </Grid>
              <Box
                className={classes.carrierMobile}
                onClick={() => handleClick(EUserTypeV1.CARRIER)}
              >
                <Typography className={classes.whiteUserType} variant="h5">
                  {t('common:imA')}
                </Typography>
                <Typography className={classes.whiteUserType} variant="h3">
                  {t('common:carrier')}
                </Typography>
              </Box>
              <Box
                className={classes.shipperMobile}
                onClick={() => handleClick(EUserTypeV1.SHIPPER)}
              >
                <Typography className={classes.whiteUserType} variant="h5">
                  {t('common:imA')}
                </Typography>
                <Typography className={classes.whiteUserType} variant="h3">
                  {t('common:shipper')}
                </Typography>
              </Box>
            </>
          )}
          {userType && !isSubmittedSuccessfully && (
            <>
              <Box className={classes.rightColumnbg} p={4}>
                <Box mb={1}>
                  <Grid alignItems="center" container justifyContent="space-between">
                    <Box>
                      <Grid alignItems="center" container>
                        <Box mr={1}>
                          <Typography className={classes.whiteUserType} variant="h5">
                            {t(`common:${userType.toLocaleLowerCase()}`)}
                          </Typography>
                        </Box>
                        <CheckCircleOutlineIcon className={classes.icon} />
                      </Grid>
                    </Box>
                    <Box onClick={handleClose}>
                      <CloseIcon className={classes.closeIcon} />
                    </Box>
                  </Grid>
                </Box>
                <form onSubmit={handleSubmit(onSubmitSuccess)}>
                  <Grid container direction="column">
                    <Box mb={1}>{formFieldName}</Box>
                    {formFieldEmail}
                    <Box mb={5} mt={3}>
                      <Typography className={classes.whiteUserType} variant="h5">
                        {t('common:iWantToJoinThe')}{' '}
                        <span className={classes.accent}> {t('common:betaVersion')}</span>{' '}
                        {t('common:now')}.
                      </Typography>
                      <Box mt={1}>{formFieldRadio}</Box>
                    </Box>
                    <Grid container justifyContent="space-between">
                      <LandingPageButton
                        isSubmit
                        onClick={() => {
                          setUserType(null);
                        }}
                        text={t('common:back')}
                        type="outlineWhite"
                      />
                      <LandingPageButton
                        isSaving={isSending}
                        text={t('common:submit')}
                        type="standard"
                      />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </>
          )}
          {userType && isSubmittedSuccessfully && (
            <>
              <Box className={classes.rightColumnbg} p={3}>
                <Box mb={1}>
                  <Grid alignItems="center" container justifyContent="space-between">
                    <Box>
                      <Grid alignItems="center" container>
                        <Box mr={1}>
                          <Typography className={classes.whiteUserType} variant="h5">
                            {t(`common:${userType.toLocaleLowerCase()}`)}
                          </Typography>
                        </Box>
                        <CheckCircleOutlineIcon className={classes.icon} />
                      </Grid>
                    </Box>
                    <Box onClick={handleClose}>
                      <CloseIcon className={classes.closeIcon} />
                    </Box>
                  </Grid>
                </Box>
                <Grid container direction="column">
                  <Grid
                    alignItems="center"
                    container
                    direction="column"
                    justifyContent="space-evenly"
                  >
                    <Box mt={1}>
                      <TruxiStars height={82} width={72} />
                    </Box>
                    <Box mb={2} mt={1}>
                      <Typography align="center" className={classes.plainWhite} variant="h3">
                        {t('common:yourInformationWasSent')}{' '}
                        <span className={classes.accent}> {t('common:successfully')}</span>!
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Grid alignItems="center" container item justifyContent="center">
                        <CheckCircleOutlineIcon className={classes.icon} />
                        <Box ml={1}>
                          <Typography className={classes.plainWhite} variant="body1">
                            {formData.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <Grid alignItems="center" container item justifyContent="center">
                      <CheckCircleOutlineIcon className={classes.icon} />
                      <Box ml={1}>
                        <Typography className={classes.plainWhite} variant="body1">
                          {formData.email}
                        </Typography>
                      </Box>
                    </Grid>
                    <Box className={classes.mobileGetBackToYou} mb={3} mt={3}>
                      <Typography align="center" className={classes.plainWhite} variant="h6">
                        {t('common:ourTeamWillGetBackToYou')}
                      </Typography>
                    </Box>
                    <LandingPageButton text={link} type="mediumStandard" />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Grid>
      </SwipeableDrawer>
    </>
  );
};
