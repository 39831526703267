// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceTypeV1 = exports.ShipmentTruxwebAdditionalChargeTypeV1 = exports.ShipmentAdditionalChargeTypeV1 = exports.UserTypeV1 = exports.ShipmentDocumentTypeV1 = void 0;
var ShipmentDocumentTypeV1;
(function (ShipmentDocumentTypeV1) {
    ShipmentDocumentTypeV1["COMMERCIAL_INVOICE"] = "COMMERCIAL_INVOICE";
    ShipmentDocumentTypeV1["MSDS"] = "MSDS";
    ShipmentDocumentTypeV1["PACKING_LIST"] = "PACKING_LIST";
    ShipmentDocumentTypeV1["PROOF_OF_DELIVERY"] = "PROOF_OF_DELIVERY";
    ShipmentDocumentTypeV1["BILL_OF_LADING"] = "BILL_OF_LADING";
    ShipmentDocumentTypeV1["PURCHASE_ORDER"] = "PURCHASE_ORDER";
    ShipmentDocumentTypeV1["REVISED_PURCHASE_ORDER"] = "REVISED_PURCHASE_ORDER";
    ShipmentDocumentTypeV1["INVOICE"] = "INVOICE";
    ShipmentDocumentTypeV1["RECEIPT"] = "RECEIPT";
    ShipmentDocumentTypeV1["CARRIER_INVOICE"] = "CARRIER_INVOICE";
})(ShipmentDocumentTypeV1 || (exports.ShipmentDocumentTypeV1 = ShipmentDocumentTypeV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
var ShipmentAdditionalChargeTypeV1;
(function (ShipmentAdditionalChargeTypeV1) {
    ShipmentAdditionalChargeTypeV1["OTHER"] = "OTHER";
    ShipmentAdditionalChargeTypeV1["WAITING_TIME"] = "WAITING_TIME";
    ShipmentAdditionalChargeTypeV1["ADDITIONAL_APPOINTMENTS"] = "ADDITIONAL_APPOINTMENTS";
    ShipmentAdditionalChargeTypeV1["REDELIVERY"] = "REDELIVERY";
    ShipmentAdditionalChargeTypeV1["VAN_CLEANING_FEE"] = "VAN_CLEANING_FEE";
    ShipmentAdditionalChargeTypeV1["LAYOVER"] = "LAYOVER";
    ShipmentAdditionalChargeTypeV1["ADVANCE_NOTICE"] = "ADVANCE_NOTICE";
    ShipmentAdditionalChargeTypeV1["INSIDE_DELIVERY"] = "INSIDE_DELIVERY";
    ShipmentAdditionalChargeTypeV1["OVERSIZED_OVERLENGTH"] = "OVERSIZED_OVERLENGTH";
    ShipmentAdditionalChargeTypeV1["AFTER_HOUR_DELIVERIES"] = "AFTER_HOUR_DELIVERIES";
    ShipmentAdditionalChargeTypeV1["DIVERSION_MILES"] = "DIVERSION_MILES";
    ShipmentAdditionalChargeTypeV1["MISSED_PICKUP_DELIVERY_APPOINTMENT"] = "MISSED_PICKUP_DELIVERY_APPOINTMENT";
    ShipmentAdditionalChargeTypeV1["ADDITIONAL_STOPS"] = "ADDITIONAL_STOPS";
    ShipmentAdditionalChargeTypeV1["STORAGE_CHARGE"] = "STORAGE_CHARGE";
    ShipmentAdditionalChargeTypeV1["DETENTION"] = "DETENTION";
})(ShipmentAdditionalChargeTypeV1 || (exports.ShipmentAdditionalChargeTypeV1 = ShipmentAdditionalChargeTypeV1 = {}));
var ShipmentTruxwebAdditionalChargeTypeV1;
(function (ShipmentTruxwebAdditionalChargeTypeV1) {
    ShipmentTruxwebAdditionalChargeTypeV1["OTHER"] = "OTHER";
    ShipmentTruxwebAdditionalChargeTypeV1["CONCIERGE"] = "CONCIERGE";
})(ShipmentTruxwebAdditionalChargeTypeV1 || (exports.ShipmentTruxwebAdditionalChargeTypeV1 = ShipmentTruxwebAdditionalChargeTypeV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
