"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipperAccessorialManagementItem = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const ShipperAccessorialManagementItem_styles_1 = require("./ShipperAccessorialManagementItem.styles");
const ShipperAccessorialManagementItem = ({ accessorialLookup, accessorialMetadataControls, accessorialMetadataFieldLookup, accessorialsControls, availableAccessorials, control, field, index, shouldDisplayMetadata, t, }) => {
    var _a, _b;
    const [selectionValue, setSelectionValue] = (0, react_1.useState)(accessorialLookup[field.code]);
    const classes = (0, ShipperAccessorialManagementItem_styles_1.useStyles)();
    const onRemove = (0, react_1.useCallback)((index) => {
        accessorialsControls.remove(index);
    }, [accessorialsControls]);
    const selectionAccessorials = (0, react_1.useMemo)(() => {
        const selectionAccessorials = [...availableAccessorials];
        const selectionAccessorialCodes = availableAccessorials
            .map((record) => record === null || record === void 0 ? void 0 : record.code)
            .filter((code) => code);
        if (!selectionAccessorialCodes.includes(field.code)) {
            selectionAccessorials.push(accessorialLookup[field.code]);
        }
        return selectionAccessorials.filter(Boolean);
    }, [accessorialLookup, field, availableAccessorials]);
    return (react_1.default.createElement(ux_1.Grid, { item: true, key: field.id, xs: true },
        react_1.default.createElement(ux_1.DeletableCard, { handleDelete: () => {
                // When this is removed we need to check to see if it
                // has accessorial metadata and remove the selection
                // from the list of fields
                if (accessorialMetadataFieldLookup[field.code]) {
                    accessorialMetadataControls.remove(accessorialMetadataFieldLookup[field.code].index);
                }
                onRemove(index);
            }, labelClass: classes.deleteLabel, rootClass: classes.deleteCard },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", spacing: 2 },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: field, id: `loadDefinition.accessorialSelections.${index}`, isRequired: true, label: t('common:packagingType'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }, (onChange, value) => {
                        return (react_1.default.createElement(__1.AccessorialsSelect, { accessorials: selectionAccessorials, hasError: false, index: index, onChange: (event) => {
                                var _a, _b;
                                // WHEN this changes we need to see if this has accessorial metadata
                                // and add a record for it
                                const accessorial = accessorialLookup[event.target.value];
                                onChange(accessorial);
                                setSelectionValue(accessorial);
                                if (((_a = accessorial.metadata) === null || _a === void 0 ? void 0 : _a.length) &&
                                    !accessorialMetadataFieldLookup[field.code]) {
                                    accessorialMetadataControls.append({
                                        accessorialId: accessorial.id,
                                        code: accessorial.code,
                                        value: '',
                                    });
                                }
                                else if (!((_b = accessorial.metadata) === null || _b === void 0 ? void 0 : _b.length) &&
                                    accessorialMetadataFieldLookup[field.code]) {
                                    accessorialMetadataControls.remove(accessorialMetadataFieldLookup[field.code].index);
                                }
                            }, t: t, value: value.code, variant: 'outlined' }));
                    })),
                shouldDisplayMetadata && Boolean((_a = selectionValue === null || selectionValue === void 0 ? void 0 : selectionValue.metadata) === null || _a === void 0 ? void 0 : _a.length) && (react_1.default.createElement(react_1.default.Fragment, null, ((_b = accessorialMetadataFieldLookup[selectionValue.code]) === null || _b === void 0 ? void 0 : _b.component) || null))))));
};
exports.ShipperAccessorialManagementItem = ShipperAccessorialManagementItem;
