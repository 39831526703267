// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationMessageTypeV1 = exports.EmailTemplatesV1 = exports.LanguageV1 = exports.NotificationMethodV1 = exports.NotificationTypeV1 = void 0;
var NotificationTypeV1;
(function (NotificationTypeV1) {
    NotificationTypeV1["ACCOUNT"] = "ACCOUNT";
    NotificationTypeV1["COMPANY"] = "COMPANY";
    NotificationTypeV1["FINANCE"] = "FINANCE";
    NotificationTypeV1["GENERIC"] = "GENERIC";
    NotificationTypeV1["QUOTE"] = "QUOTE";
    NotificationTypeV1["SHIPMENT_UPDATE"] = "SHIPMENT_UPDATE";
    NotificationTypeV1["SHIPMENT_DOCUMENT"] = "SHIPMENT_DOCUMENT";
    NotificationTypeV1["SHIPMENT_COMMENT"] = "SHIPMENT_COMMENT";
})(NotificationTypeV1 || (exports.NotificationTypeV1 = NotificationTypeV1 = {}));
var NotificationMethodV1;
(function (NotificationMethodV1) {
    NotificationMethodV1["IN_APP"] = "IN_APP";
    NotificationMethodV1["EMAIL"] = "EMAIL";
    NotificationMethodV1["SMS"] = "SMS";
})(NotificationMethodV1 || (exports.NotificationMethodV1 = NotificationMethodV1 = {}));
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var EmailTemplatesV1;
(function (EmailTemplatesV1) {
    EmailTemplatesV1["PLAIN_TEXT"] = "PLAIN_TEXT";
    EmailTemplatesV1["BOOKED_SHIPMENT"] = "bookedShipment";
    EmailTemplatesV1["CANCELLED"] = "cancelled";
    EmailTemplatesV1["CARRIER_BOOKED_SHIPMENT"] = "carrierBookedShipment";
    EmailTemplatesV1["CARRIER_ONITSWAY"] = "carrierOnItsWay";
    EmailTemplatesV1["DELIVERED"] = "delivered";
    EmailTemplatesV1["DELIVERY_CONFIRMED"] = "deliveryConfirmed";
    EmailTemplatesV1["IN_DISPUTE"] = "inDispute";
    EmailTemplatesV1["INTERNAL_NOTIFICATION"] = "internalNotification";
    EmailTemplatesV1["PICKUP_READY"] = "pickUpReady";
    EmailTemplatesV1["PICKED_UP"] = "pickedUp";
    EmailTemplatesV1["PREAUTHDEPOSIT_DUE"] = "PreAuthDepositDue";
    EmailTemplatesV1["REQUIRES_DOCUMENTS"] = "requiresDocuments";
    EmailTemplatesV1["PAYMENT_RELEASED"] = "paymentReleased";
    EmailTemplatesV1["NOTIFY_USER_OF_SHIPMENT_MODIFICATION"] = "notifyUserOfShipmentModification";
    EmailTemplatesV1["CARRIER_QUOTE_REQUEST_SUBMITTED"] = "carrierBookingRequest";
    EmailTemplatesV1["CARRIER_QUOTE_REQUEST_MODIFIED"] = "shipperModifiedBookingRequest";
    EmailTemplatesV1["CARRIER_QUOTE_REQUEST_ACCEPTED"] = "shipperAcceptedBookingRequest";
    EmailTemplatesV1["CARRIER_QUOTE_REQUEST_REFUSAL_SUMMARY"] = "shipperExpiredBookingRequest";
    EmailTemplatesV1["CARRIER_QUOTE_REQUEST_SHIPPER_DECLINED_OFFER"] = "carrierDeclinedOffer";
    EmailTemplatesV1["CARRIER_QUOTE_REQUEST_PENDING"] = "shipperConfirmation";
    EmailTemplatesV1["PAYMENT_ISSUED_NOTIFICATION"] = "paymentNotification";
    EmailTemplatesV1["GENERIC"] = "generic";
    EmailTemplatesV1["SHIPMENT_COMMENTS"] = "shipmentComments";
    EmailTemplatesV1["SHIPMENT_UPDATE_SUMMARY"] = "shipmentUpdateSummary";
    EmailTemplatesV1["SHIPMENT_DIGEST"] = "shipmentDigest";
})(EmailTemplatesV1 || (exports.EmailTemplatesV1 = EmailTemplatesV1 = {}));
var NotificationMessageTypeV1;
(function (NotificationMessageTypeV1) {
    NotificationMessageTypeV1["SHIPMENT_DELIVERY_DATE"] = "SHIPMENT_DELIVERY_DATE";
    NotificationMessageTypeV1["SHIPMENT_PICKUP_DATE"] = "SHIPMENT_PICKUP_DATE";
    NotificationMessageTypeV1["SHIPMENT_CREATED"] = "SHIPMENT_CREATED";
    NotificationMessageTypeV1["SHIPMENT_STATUS_UPDATE"] = "SHIPMENT_STATUS_UPDATE";
    NotificationMessageTypeV1["SHIPMENT_COMPLETE"] = "SHIPMENT_COMPLETE";
    NotificationMessageTypeV1["SHIPMENT_TRACKING_DETAILS"] = "SHIPMENT_TRACKING_DETAILS";
})(NotificationMessageTypeV1 || (exports.NotificationMessageTypeV1 = NotificationMessageTypeV1 = {}));
