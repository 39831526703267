"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDiceD12Icon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.287,7.535,21.372,4.343a5.026,5.026,0,0,0-1.715-1.715L16.465.713A5,5,0,0,0,13.892,0H10.108A5,5,0,0,0,7.535.713L4.343,2.628A5.026,5.026,0,0,0,2.628,4.343L.713,7.535A5,5,0,0,0,0,10.108v3.784a5,5,0,0,0,.713,2.573l1.915,3.192a5.026,5.026,0,0,0,1.715,1.715l3.192,1.915A5,5,0,0,0,10.108,24h3.784a5,5,0,0,0,2.573-.713l3.192-1.915a5.026,5.026,0,0,0,1.715-1.715l1.915-3.192A5,5,0,0,0,24,13.892V10.108A5,5,0,0,0,23.287,7.535ZM21.572,8.564a3.2,3.2,0,0,1,.186.364l-4.02,4.823L13,11.382v-4.6l6.511-1.629c.052.071.1.145.146.22ZM8.564,2.428A3,3,0,0,1,10.108,2h3.784a3,3,0,0,1,1.544.428l1.98,1.188L12,4.97,6.584,3.616ZM4.343,5.372c.045-.075.094-.149.146-.22L11,6.78v4.6L6.262,13.751,2.242,8.928a3.2,3.2,0,0,1,.186-.364Zm0,13.256L2.428,15.436A3,3,0,0,1,2,13.892V11.763l3.123,3.748,1.934,5.157L5.372,19.657A3.016,3.016,0,0,1,4.343,18.628Zm5.339,3.341L7.253,15.492,12,13.118l4.748,2.374-2.43,6.477C14.177,21.989,9.823,21.989,9.682,21.969Zm11.89-6.533-1.915,3.192a3.016,3.016,0,0,1-1.029,1.029l-1.685,1.011,1.934-5.157L22,11.763v2.129A3,3,0,0,1,21.572,15.436Z" })));
exports.default = TruxwebDiceD12Icon;
