import { makeRequestToApiGateway } from '../../apiUtils';
import { TUpdateUserCompany } from '../../types';

export const updateBasicCompanyInfo = async (attributes: TUpdateUserCompany): Promise<void> => {
  const update = {
    ...attributes,
    name: attributes.companyName,
    phone: attributes.companyPhone,
    phoneExt: attributes.companyPhone_ext,
  };

  await makeRequestToApiGateway(
    'userPatchV1Company',
    null,
    {
      'Content-Type': 'application/json',
    },
    update
  );
};
