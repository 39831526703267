"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFeatherIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m22.827 1.174a3.968 3.968 0 0 0 -3.787-1.055 31.29 31.29 0 0 0 -13.784 7.75 7.757 7.757 0 0 0 -2.247 5.831 7.646 7.646 0 0 0 1.569 4.3l-4.285 4.293a1 1 0 1 0 1.414 1.414l4.284-4.284a7.446 7.446 0 0 0 4.598 1.577 8.394 8.394 0 0 0 5.9-2.545c4.4-4.4 6.883-11.446 7.394-13.525a3.931 3.931 0 0 0 -1.056-3.756zm-17.82 12.434a5.756 5.756 0 0 1 1.665-4.327c.428-.425.873-.824 1.328-1.214v6.519l-1.99 1.99a5.671 5.671 0 0 1 -1.003-2.968zm10.038 3.459a5.908 5.908 0 0 1 -7.618.92l2.279-2.279 2.708-2.708h5.762a25.778 25.778 0 0 1 -3.131 4.067zm6.9-12.62a29.628 29.628 0 0 1 -2.6 6.553h-4.931l3.293-3.293a1 1 0 1 0 -1.414-1.414l-5 5-1.293 1.293v-6.074a30.354 30.354 0 0 1 9.522-4.451 2.017 2.017 0 0 1 .484-.061 1.99 1.99 0 0 1 1.409.589 1.925 1.925 0 0 1 .526 1.858z" })));
exports.default = TruxwebFeatherIcon;
