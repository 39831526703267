import { makeRequestToApiGateway } from '../../apiUtils';
import { type TShipperShipmentQuoteRequestV1 } from '@truxweb/schemas';

export const updateShipperQuoteRequest = async (
  shipperQuoteRequest: TShipperShipmentQuoteRequestV1
): Promise<void> => {
  await makeRequestToApiGateway(
    'shipmentPatchV1ShipperQuote',
    null,
    {
      'Content-Type': 'application/json',
    },
    {
      action: 'CANCEL',
      ...shipperQuoteRequest,
    }
  );
};
