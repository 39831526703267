"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarCrashIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24.013,15.331,23.49,11.1a4.471,4.471,0,0,0-2.834-4.081L16.7,5.378a4.933,4.933,0,0,0-5.073.875L7.952,8.979a9.066,9.066,0,0,0-2.295,3.283s-.428.77-.445.811a3.441,3.441,0,0,0,.087,2.58,3.251,3.251,0,0,0,.726,1.06l-.033.082a2.5,2.5,0,0,0,4.6,1.964l4.8,1.925a2.5,2.5,0,0,0,4.686,1.738l.014-.035A3.007,3.007,0,0,0,23,20.5l.412-1.04A9.04,9.04,0,0,0,24.013,15.331ZM12.89,7.8a2.977,2.977,0,0,1,3.066-.568l3.955,1.64A2.541,2.541,0,0,1,21.5,11.289c0,.012.419,3.388.419,3.388L10.028,9.924ZM8.131,18.183l-.01,0,.02.008Zm9.429,3.781-.009,0,.017.006Zm3.582-2.2a1,1,0,0,1-1.288.564L7.635,15.419a1.009,1.009,0,0,1-.5-.561,1.484,1.484,0,0,1-.081-1s.421-.757.437-.8a7.236,7.236,0,0,1,.916-1.635l1.62.648-.325.8a1,1,0,0,0,1.854.752l.328-.81,6.648,2.657-.33.813a1,1,0,1,0,1.854.752l.333-.822,1.622.648a6.987,6.987,0,0,1-.455,1.851ZM4,11a1,1,0,0,1-1,1H1a1,1,0,0,1-.768-1.64L1.823,8.451a.7.7,0,0,0,0-.9L.232,5.64A1,1,0,0,1,1,4H3.3A.705.705,0,0,0,4,3.3V1A1,1,0,0,1,5.64.232L7.549,1.823a.7.7,0,0,0,.9,0L10.36.232A1,1,0,0,1,12,1V3a1,1,0,0,1-1.992.128l-.277.231a2.7,2.7,0,0,1-3.462,0L6,3.135V3.3A2.707,2.707,0,0,1,3.3,6H3.135l.224.269a2.7,2.7,0,0,1,0,3.462l-.231.277A1,1,0,0,1,4,11Z" })));
exports.default = TruxwebCarCrashIcon;
