"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTemperatureHotIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M17,24c-6.078.117-9.334-7.638-5-11.889V5c.211-6.609,9.791-6.6,10,0v7.111C26.335,16.363,23.077,24.117,17,24ZM17,2a3,3,0,0,0-3,3v7.537a1,1,0,0,1-.332.744,5,5,0,1,0,6.664,0A1,1,0,0,1,20,12.537V5A3,3,0,0,0,17,2Zm0,18a3.007,3.007,0,0,1-1-5.829V8a1,1,0,0,1,2,0v6.171A3.007,3.007,0,0,1,17,20Zm0-4a1,1,0,0,0,0,2A1,1,0,0,0,17,16ZM5,17a1,1,0,0,1-.863-1.5L5.212,13.65a6.06,6.06,0,0,1-.837-.835L2.488,13.872a1,1,0,1,1-.976-1.744l1.864-1.045A5.9,5.9,0,0,1,3.084,10H1A1,1,0,0,1,1,8H3.082a6.056,6.056,0,0,1,.275-1.051L1.5,5.864A1,1,0,1,1,2.5,4.136L4.343,5.208a5.993,5.993,0,0,1,.836-.842L4.128,2.488a1,1,0,1,1,1.744-.976L6.914,3.371A5.971,5.971,0,0,1,7.98,3.087L8,1a1,1,0,0,1,2,0V3.089A2.005,2.005,0,0,1,8.319,5.058a4.011,4.011,0,0,0-.12,7.863,1,1,0,0,1-.4,1.959,6.012,6.012,0,0,1-.851-.239L5.864,16.5A1,1,0,0,1,5,17Z" })));
exports.default = TruxwebTemperatureHotIcon;
