"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGrillIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.339,7.129A2.978,2.978,0,0,0,21,6H3c-6.654,1.04-.636,9.791,2.833,11.341L4.053,22.684a1,1,0,1,0,1.9.632L7.654,18.2A12.329,12.329,0,0,0,11,18.957V23a1,1,0,0,0,2,0V18.957a12.322,12.322,0,0,0,3.345-.758l1.706,5.117a1,1,0,1,0,1.9-.632l-1.781-5.345C21.009,15.85,25.622,10.4,23.339,7.129ZM21.972,9.244C19.488,19.5,4.506,19.5,2.026,9.244A.993.993,0,0,1,3,8H21A.994.994,0,0,1,21.972,9.244ZM11,3V1a1,1,0,0,1,2,0V3A1,1,0,0,1,11,3Zm4,0V1a1,1,0,1,1,2,0V3A1,1,0,0,1,15,3ZM7,3V1A1,1,0,1,1,9,1V3A1,1,0,0,1,7,3Z" })));
exports.default = TruxwebGrillIcon;
