import { makeStyles, Theme } from '@truxweb/ux';

type HeaderStylesType = {
  isScrolled?: boolean;
  isTransparent?: boolean;
  palette: 'light' | 'dark';
};

export const useStyles = makeStyles<Theme, HeaderStylesType>((theme: Theme) => ({
  sectionLink: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
    },
    color: ({ isScrolled, isTransparent, palette }) => {
      return isTransparent && !isScrolled
        ? theme.palette.common.white
        : palette === 'dark'
        ? theme.palette.common.white
        : theme.palette.grey[600];
    },
    position: 'relative',
  },

  selectedSectionLink: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
    },
    color: theme.palette.primary.main,
    position: 'relative',
  },
}));
