// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierTruckTypeV1 = void 0;
var CarrierTruckTypeV1;
(function (CarrierTruckTypeV1) {
    CarrierTruckTypeV1["FTL53Dry"] = "53' Dry Van (FTL)";
    CarrierTruckTypeV1["FTL53Reefer"] = "53' Refrigerated Van (FTL)";
    CarrierTruckTypeV1["ContainerChassis20"] = "Container Chassis 20'";
    CarrierTruckTypeV1["ContainerChassis40"] = "Container Chassis 40'";
    CarrierTruckTypeV1["Flatbed"] = "Flat bed";
})(CarrierTruckTypeV1 || (exports.CarrierTruckTypeV1 = CarrierTruckTypeV1 = {}));
