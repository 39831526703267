"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccoridonStyles = exports.useAccoridonContentStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        marginTop: theme.spacing(4.5),
        padding: 0,
        position: 'relative',
        width: '100%',
    },
    cardContents: {
        maxWidth: 408,
        position: 'relative',
        // width: ({ isLargeWidth }) => (isLargeWidth ? 408 : '100%'),
    },
    carrierResponseContainer: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '16px',
    },
    chatButton: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(1),
    },
    completedQuote: {
        background: '#D5F2D6',
        borderRadius: theme.spacing(1),
        color: theme.palette.grey[600],
        fontWeight: 600,
    },
    confirmed: {
        color: theme.palette.success.main,
    },
    date: {
        fontWeight: 600,
    },
    equipmentImage: {
        width: theme.spacing(9),
    },
    equipmentParams: {
        color: theme.palette.primary.light,
    },
    loader: {
        height: `${theme.spacing(2)}px !important`,
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        width: `${theme.spacing(2)}px !important`,
    },
    responsesPendingArrow: {
        color: theme.palette.common.white,
        transform: ({ arePendingResponsesExpanded }) => arePendingResponsesExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
        transformOrigin: 'center',
    },
    responsesPendingContainer: {
        background: theme.palette.primary.light,
        borderRadius: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        color: theme.palette.common.white,
        flexGrow: 1,
        fontWeight: 600,
        height: theme.spacing(6),
        lineHeight: `${theme.spacing(6)}px`,
    },
    responsesPendingCount: {
        background: theme.palette.primary.dark,
        borderRadius: theme.spacing(1.25),
        color: theme.palette.common.white,
        fontSize: '10px',
        height: theme.spacing(3),
        lineHeight: `${theme.spacing(3)}px`,
    },
    responsesPendingHeaderRow: {
        borderBottom: '1px solid #c4c4c4',
        paddingBottom: theme.spacing(1),
    },
    responsesPendingPrompt: {
        color: theme.palette.common.white,
        fontWeight: 600,
        lineHeight: `${theme.spacing(6)}px`,
    },
    responsesPendingRow: {
        '&:last-child': {
            borderBottom: '0',
        },
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        minHeight: theme.spacing(5),
    },
    responsesPendingTitle: {
        fontWeight: 600,
    },
    searchAgain: {
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.light,
        cursor: 'pointer',
    },
    selectedChatButton: {
        background: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    shipperQuoteRef: {
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(0.75),
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: theme.spacing(2),
        width: '100%',
    },
}));
exports.useAccoridonContentStyles = (0, ux_1.makeStyles)((theme) => ({
    content: {
        '& .Mui-expanded': {
            margin: `0 !important`,
        },
        borderRadius: 0,
        margin: 0,
        padding: 0,
    },
    expanded: {
        margin: `0 !important`,
        minHeight: `${theme.spacing(6)}px !important`,
    },
    root: {
        margin: 0,
        padding: 0,
    },
}));
exports.useAccoridonStyles = (0, ux_1.makeStyles)(() => ({
    expanded: {
        margin: `0 !important`,
    },
    root: {
        boxShadow: 'unset',
        margin: 0,
        padding: 0,
    },
}));
