"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    connector: {
        background: 'linear-gradient(180deg, #0087CE 0%, rgba(0, 135, 206, 0) 100%)',
        height: 'calc(100% - 24px)',
        width: '4px',
    },
    container: {
        height: '100%',
    },
    dot: {
        background: ' #0087CE ',
        borderRadius: '12px',
        height: '12px',
        width: '12px',
    },
}));
