"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    relative: {
        position: 'relative',
    },
    uploading: {
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.7)',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 2,
    },
    wrapper: {
        border: '1px solid #C4C4C4',
        borderRadius: '10px',
        boxSizing: 'border-box',
        padding: theme.spacing(2),
    },
}));
