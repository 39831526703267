// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageV1 = exports.PriceTypeV1 = exports.CurrencyV1 = void 0;
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
