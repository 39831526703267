"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHurricaneIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10.428,24H6a1,1,0,0,1,0-2,6.44,6.44,0,0,0,3.818-1.24,10.042,10.042,0,0,1-7.266-13.1A11.472,11.472,0,0,1,13.572,0H18a1,1,0,0,1,0,2,6.44,6.44,0,0,0-3.818,1.24,10.041,10.041,0,0,1,7.266,13.1A11.472,11.472,0,0,1,10.428,24ZM12.475,2.059A9.387,9.387,0,0,0,4.45,8.3a8.039,8.039,0,0,0,6.085,10.569,1.809,1.809,0,0,1,1.436,1.352,1.837,1.837,0,0,1-.446,1.725A9.393,9.393,0,0,0,19.551,15.7,8.04,8.04,0,0,0,13.466,5.135h0a1.809,1.809,0,0,1-1.437-1.352A1.834,1.834,0,0,1,12.475,2.059ZM12,15a3,3,0,0,1,0-6A3,3,0,0,1,12,15Zm0-4a1,1,0,0,0,0,2A1,1,0,0,0,12,11Z" })));
exports.default = TruxwebHurricaneIcon;
