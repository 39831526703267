"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMotorcycleIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,11a5.186,5.186,0,0,0-.6.036l-.607-1.82c.495-.087,1.018-.152,1.493-.187C19.767,10.053,21.909,10.543,22,9V7c-.1-1.574-2.293-1.033-2.747.026a17,17,0,0,0-2.02.259l-.079.016-.522-1.566A3.994,3.994,0,0,0,12.838,3H11a1,1,0,0,0,0,2h1.838a2,2,0,0,1,1.9,1.368l.553,1.659A5.987,5.987,0,0,0,14.063,9H8.825A3,3,0,0,0,6,7H3A1,1,0,0,0,3,9H6a.983.983,0,0,1,.895.553A1.017,1.017,0,0,1,7,9.948a1.579,1.579,0,0,1-.2.652l-.44.586A5.025,5.025,0,0,0,0,16c.114,6.163,8.966,6.763,9.947.726A4.016,4.016,0,0,0,11.7,15.4l3.451-4.6a9.341,9.341,0,0,1,.794-.8L16.5,11.67A5.015,5.015,0,0,0,19,21C25.608,20.79,25.606,11.209,19,11ZM5,19a3,3,0,0,1,0-6l-.6.8A2,2,0,0,0,6,17H7.828A3,3,0,0,1,5,19Zm5.1-4.8a2.01,2.01,0,0,1-1.6.8H6l2.4-3.2a3.03,3.03,0,0,0,.431-.8H12.5ZM19,19a3.013,3.013,0,0,1-1.842-5.365l.894,2.681a1,1,0,1,0,1.9-.632L19.054,13A3,3,0,0,1,19,19Z" })));
exports.default = TruxwebMotorcycleIcon;
