"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        backgroundColor: '#444',
        border: '1px solid #000',
        left: '95%',
        position: 'absolute',
        top: '40%',
    },
    buttonBack: {
        left: theme.spacing(-6),
        margin: 0,
        padding: 0,
        position: 'absolute',
    },
    buttonForward: {
        margin: '0',
        padding: 0,
        position: 'absolute',
        right: theme.spacing(-6),
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        position: 'absolute',
        top: '50%',
        width: '100%',
        zIndex: 1,
    },
    carouselButton: {
        color: theme.palette.grey[500],
        display: 'none',
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        margin: '0',
        padding: '0',
        position: 'relative',
        zIndex: 1,
    },
    flexContainerSecondary: {
        margin: '0',
        overflow: 'hidden',
        padding: '0',
        width: '940px',
    },
    flexSecondaryCard: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        margin: '0',
        padding: '0',
        position: 'relative',
    },
    glide: {
        height: 'auto',
        transition: '0.5s !important',
    },
    secondaryContainerItem: {
        alignSelf: 'flex-start',
        flexBasis: 'auto',
        flexGrow: 1,
    },
    selected: {
        border: `3px solid ${theme.palette.primary.light}`,
    },
}));
