/* eslint-disable complexity */
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  TruxwebArrowLeftIcon,
  TruxwebEyeIcon,
  Typography,
} from '@truxweb/ux';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { displayPasswordSchema, paramValidation, passwordSchema } from '../../utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useAlerts, useErrorHandling, useQuoteCounts } from '../../hooks';
import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import { EMAIL_REGEX } from '@truxweb/utils';
import { getAuth } from '../../actions';
import { LoginVerification } from '..';
import { useDispatch } from 'react-redux';
import { useStyles } from './ForgotPasswordForm.styles';

const REQUIRED_NAMESPACES = ['common'];

type TForgotPasswordForm = {
  authedUser?: any;
  isResetOnly?: boolean;
};
export const ForgotPasswordForm = ({
  authedUser,
  isResetOnly,
}: TForgotPasswordForm): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm({ mode: 'onBlur' });
  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState<string | null>(null);
  const [passwordFieldType, setPasswordFieldType] = useState<'password' | 'text'>('password');
  const { handleRefetchQuotes } = useQuoteCounts();

  const [isLoading, setIsLoading] = useState(false);
  const emailRequired = isResetOnly ? false : true;
  const [emailStep, setEmailStep] = useState(emailRequired);

  const classes = useStyles();
  const [isPasswordComponentValid, setIsPasswordComponentValid] = useState(
    (): Record<string, boolean> => {
      const initialState = {};
      displayPasswordSchema.forEach((args) => {
        initialState[args] = false;
      });
      return initialState;
    }
  );

  const handleError = useCallback(
    (error: Record<string, any>) => {
      {
        const errorMessage =
          error.name === 'CodeMismatchException'
            ? t('common:verificationCodeError')
            : t('common:pleaseContactAdmin');
        errorHandler(error, errorMessage);
      }
    },
    [t, errorHandler]
  );
  const handleSignIn = useCallback(
    async (data: Record<string, any>) => {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(emailAddress, data.password);
        dispatch(getAuth(user.attributes));
        handleRefetchQuotes();
      } catch (err) {
        handleError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [emailAddress, dispatch, handleError, handleRefetchQuotes]
  );

  const sendVerificationCode = useCallback(
    async (emailAddress: string) => {
      if (!emailAddress) {
        addAlert({
          message: t('common:emailAddressRequired'),
          severity: 'error',
        });
      } else {
        await Auth.forgotPassword(emailAddress);
      }
    },
    [addAlert, t]
  );

  const initForgotPassword = useCallback(
    async (data: Record<string, any>) => {
      setIsLoading(true);
      try {
        setEmailAddress(data.email);
        await sendVerificationCode(data.email);
        setEmailStep(false);
      } catch (err) {
        handleError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [setEmailAddress, setIsLoading, setEmailStep, sendVerificationCode, handleError]
  );

  const handleForgot = useCallback(
    async (data: Record<string, any>) => {
      setIsLoading(true);
      try {
        await Auth.forgotPasswordSubmit(emailAddress, data.code, data.password);
        await handleSignIn(data);
      } catch (err) {
        handleError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, handleSignIn, setIsLoading, emailAddress]
  );

  const handleReset = useCallback(
    async (data: Record<string, any>) => {
      setIsLoading(true);
      try {
        await Auth.completeNewPassword(authedUser, data.password);
        const cognitoUserData = await Auth.currentAuthenticatedUser();
        dispatch(getAuth(cognitoUserData.attributes));
      } catch (err) {
        handleError(err as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [authedUser, handleError, dispatch]
  );
  const upToDatePassword = useWatch({ control, name: 'password' });
  useEffect(() => {
    const schemaViolations = passwordSchema.validate(upToDatePassword, { list: true });

    displayPasswordSchema.forEach((schemaParam) => {
      let containsParamViolation = false;
      if (Array.isArray(schemaViolations)) {
        containsParamViolation = schemaViolations.includes(schemaParam);
      }

      const isValidationParamChecked = upToDatePassword
        ? paramValidation(schemaParam, upToDatePassword)
        : false;

      const isValid = !containsParamViolation && isValidationParamChecked;
      if (isPasswordComponentValid[schemaParam] !== isValid) {
        setIsPasswordComponentValid((prev) => {
          return { ...prev, [schemaParam]: isValid };
        });
      }
    });
  }, [isPasswordComponentValid, upToDatePassword]);

  if (emailStep) {
    return (
      <form onSubmit={handleSubmit(initForgotPassword)}>
        <Grid
          className={classes.container}
          container
          direction="column"
          justifyContent="space-between"
        >
          <Typography className={classes.primaryFont} variant="h3">
            {t('common:forgotPassword')}
          </Typography>

          <Box pb={5} pt={5}>
            <Typography
              className={classnames(classes.linkFont, classes.primaryFont)}
              fontStyle="semibold"
              shouldOverrideVariants={true}
              variant="body1"
            >
              {t('common:forgotPasswordPrompt')}
            </Typography>
            <Box pb={3} pt={2}>
              <Typography variant="body1">{t('common:enterEmail')}</Typography>
            </Box>

            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
              }}
              className={classes.fitContainer}
              error={Boolean(errors?.email) || false}
              helperText={[errors.email && t('common:invalidEmailAddress')]}
              label={t('common:email')}
              type="email"
              variant="outlined"
              {...register('email', {
                pattern: {
                  message: 'invalid email address',
                  value: EMAIL_REGEX,
                },
                required: true,
              })}
            />
          </Box>
          <Button
            className={classes.submitButton}
            disabled={isLoading}
            fullWidth
            type="submit"
            variant="contained"
          >
            {t('common:reset')}
          </Button>
          <Button
            className={classnames(classes.backButton, classes.resendButton, classes.clickable)}
            href={'login'}
          >
            <TruxwebArrowLeftIcon className={classes.iconMargin} />
            <Typography variant="body1">{t('common:backToLogin')}</Typography>
          </Button>
        </Grid>
      </form>
    );
  }
  return (
    <form
      onSubmit={handleSubmit((data) => {
        isResetOnly ? handleReset(data) : handleForgot(data);
      })}
    >
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography className={classes.primaryFont} variant="h3">
          {isResetOnly ? t('common:resetPasswordTitle') : t('common:forgotPasswordTitle')}
        </Typography>

        {!isResetOnly && (
          <Box pt={6}>
            <Typography className={classnames(classes.linkFont, classes.primaryFont)} variant="h6">
              {t('common:verificationCode')}
            </Typography>
            <Box pt={2}>
              <Typography variant="body1">
                {t('common:resetPasswordConfirmationCodePrompt', { emailAddress })}
              </Typography>
              <Box pb={3} pt={3}>
                <Grid className={classes.verificationCode} item>
                  <Controller
                    control={control}
                    name="confirmationCode"
                    render={({ field: { onChange, value } }) => {
                      return <LoginVerification onChange={onChange} value={value} />;
                    }}
                    rules={{ required: true }}
                    {...register('code', {
                      required: true,
                    })}
                  />
                </Grid>
              </Box>
              <Typography>
                <Trans i18nKey="common:verificationCodeResend">
                  <button
                    className={classnames(
                      classes.primaryFont,
                      classes.resendButton,
                      classes.clickable
                    )}
                    onClick={() => {
                      sendVerificationCode(emailAddress as string);
                    }}
                  >
                    <Typography className={classes.resendFont}>
                      {t('common:verificationCodeResend')}
                    </Typography>
                  </button>
                </Trans>
              </Typography>
            </Box>
          </Box>
        )}
        <Box pb={5} pt={5}>
          <Typography className={classnames(classes.linkFont, classes.primaryFont)} variant="h6">
            {t('common:password')}
          </Typography>
          <Box pb={1} pt={1}>
            <Typography variant="body1">{t('common:passwordLengthPrompt')}</Typography>
          </Box>
          <Box pt={2}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  root: classes.itemBox,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <TruxwebEyeIcon
                      className={classes.clickable}
                      fill="currentColor"
                      onClick={() => {
                        setPasswordFieldType(
                          passwordFieldType === 'password' ? 'text' : 'password'
                        );
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              className={classes.fitContainer}
              error={Boolean(errors?.password) || false}
              helperText={
                errors.password &&
                errors.password.type === 'required' &&
                t('common:invalidPassword')
              }
              label={t('common:newPassword')}
              type={passwordFieldType}
              variant="outlined"
              {...register('password', {
                required: true,
              })}
            />
            <Box pt={1}>
              <Grid container direction="row">
                {displayPasswordSchema.map((schemaParam) => {
                  const value = getValues('password') || '';
                  const isValidationParamChecked = value
                    ? paramValidation(schemaParam, value)
                    : false;
                  let color = '#C4C4C4';
                  if (isValidationParamChecked) {
                    color = '#224882';
                  }

                  return (
                    <Grid item key={schemaParam} xs={6}>
                      <Typography
                        style={{
                          color,
                          fontSize: '12px',
                        }}
                      >
                        <ul className={classes.ul}>
                          <li>{t(`common:passwordSchema-${schemaParam}`)}</li>
                        </ul>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Button
          className={classes.submitButton}
          disabled={
            isLoading ||
            !Object.values(isPasswordComponentValid).reduce(
              (prev, isComponentValid) => prev && isComponentValid,
              true
            )
          }
          fullWidth
          type="submit"
          variant="contained"
        >
          {t('common:reset')}
        </Button>
        <Button
          className={classnames(classes.backButton, classes.resendButton, classes.clickable)}
          href={'login'}
        >
          <TruxwebArrowLeftIcon className={classes.iconMargin} />
          <Typography variant="body1">{t('common:backToPreviousStep')}</Typography>
        </Button>
      </Grid>
    </form>
  );
};
