"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDashboardEditableOrderSummary = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const utils_1 = require("../../utils");
const react_1 = __importStar(require("react"));
const utils_2 = require("@truxweb/utils");
const ARE_ADDITIONAL_CHARGES_EDITABLE = true;
const ARE_ACCESSORIALS_EDITABLE = true;
const ShipmentDashboardEditableOrderSummary = ({ accessorials, availableShipperShipmentFees, charges, formControls, handleCancel, handleInit, hasInit, hasRecalculated, isEditingDisabled, isSaving, language, onEditShipment, setIsSaving, shipmentDetails, shouldAllowAddingCharges, shouldDisallowSavingCharges, t, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const [currentTruxwebSummary, setCurrentTruxwebSummary] = (0, react_1.useState)(null);
    const [currentShipperSummary, setCurrentShipperSummary] = (0, react_1.useState)(null);
    const [currentCarrierSummary, setCurrentCarrierSummary] = (0, react_1.useState)(null);
    const [hasLocalInit, setLocalInit] = (0, react_1.useState)(false);
    const [currentCreditCardSurcharge, setCurrentCreditCardSurcharge] = (0, react_1.useState)(null);
    const [consumedAccessorials, setConsumedAccessorials] = (0, react_1.useState)(null);
    const [consumedShipmentFees, setConsumedShipmentFees] = (0, react_1.useState)(null);
    const [hasTruxwebMarginBeenModified, setTruxwebMarginModified] = (0, react_1.useState)(false);
    const { accessorial, accessorialLookup, accessorialLookupByCode, additional, equipmentLookup, fuelSurcharge, truxwebCarrierCharges, truxwebShipperCharges, truxwebShipperShipmentFees, } = (0, react_1.useMemo)(() => {
        return (0, utils_1.groupChargesForEditableSummary)({
            accessorials,
            areAccessorialsEditable: ARE_ACCESSORIALS_EDITABLE,
            areAdditionalChargesEditable: ARE_ADDITIONAL_CHARGES_EDITABLE,
            charges,
            isEditingDisabled,
            shipmentDetails,
        });
    }, [isEditingDisabled, accessorials, shipmentDetails, charges]);
    const equipmentFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'equipment',
    });
    const accessorialFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'accessorial',
    });
    const additionalChargesFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'additional',
    });
    const truxwebCarrierChargeFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'truxwebCarrierCharges',
    });
    const truxwebShipperChargeFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'truxwebShipperCharges',
    });
    const truxwebShipperShipmentFeeFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'truxwebShipperShipmentFees',
    });
    const serviceFeeFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'serviceFees',
    });
    const fuelSurchargeFields = (0, react_hook_form_1.useFieldArray)({
        control: formControls.control,
        name: 'fuelSurcharge',
    });
    const handleUpdatingCharges = (0, react_1.useCallback)((charges) => {
        const grouped = (0, utils_1.groupChargesForEditableSummary)({
            accessorials,
            areAccessorialsEditable: ARE_ACCESSORIALS_EDITABLE,
            areAdditionalChargesEditable: ARE_ADDITIONAL_CHARGES_EDITABLE,
            charges,
            isEditingDisabled,
            shipmentDetails,
        });
        setCurrentCreditCardSurcharge(grouped.creditCardSurcharge);
        setCurrentCarrierSummary(grouped.carrierOrderSummary);
        setCurrentTruxwebSummary(grouped.truxwebOrderSummary);
        setCurrentShipperSummary(grouped.shipperOrderSummary);
        const updatedTruxwebFee = grouped.serviceFees.find(({ chargeType }) => chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB);
        const truxwebServiceFeeIndex = serviceFeeFields.fields.findIndex(({ chargeType }) => {
            return chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB;
        });
        if (truxwebServiceFeeIndex !== undefined && updatedTruxwebFee) {
            formControls.setValue(`serviceFees.${truxwebServiceFeeIndex}`, updatedTruxwebFee);
        }
    }, [
        accessorials,
        isEditingDisabled,
        shipmentDetails,
        setCurrentCreditCardSurcharge,
        setCurrentCarrierSummary,
        setCurrentTruxwebSummary,
        setCurrentShipperSummary,
        formControls,
        serviceFeeFields,
    ]);
    const getEquipmentCost = (0, react_1.useCallback)((data) => {
        const total = data.equipment
            .map((item) => {
            return Object.assign(Object.assign({}, item), { charge: Object.assign(Object.assign({}, item.charge), { value: parseFloat(`${item.charge.value}`) }) });
        })
            .reduce((acc, item) => {
            acc.charge.value += item.charge.value;
            return acc;
        });
        return parseFloat(`${total.charge.value}`);
    }, []);
    const getAccessorialCost = (0, react_1.useCallback)((data) => {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.accessorial) === null || _a === void 0 ? void 0 : _a.length))
            return 0;
        const total = data.accessorial
            .map((item) => {
            return Object.assign(Object.assign({}, item), { charge: Object.assign(Object.assign({}, item.charge), { value: parseFloat(`${item.charge.value}`) }) });
        })
            .reduce((acc, item) => {
            acc.charge.value += item.charge.value;
            return acc;
        });
        return parseFloat(`${total.charge.value}`);
    }, []);
    const getFuelSurchargeFee = (0, react_1.useCallback)((data) => {
        const value = parseFloat(`${data.fscChargeValue}`);
        return isNaN(value) ? 0 : value;
    }, []);
    const handleUpdatePercentageFee = (0, react_1.useCallback)((data, fieldData, index, total) => {
        if (!total) {
            const fuelSurcharge = getFuelSurchargeFee(data);
            const equipmentCost = getEquipmentCost(data);
            const accessorialCost = getAccessorialCost(data);
            total = fuelSurcharge + equipmentCost + accessorialCost;
        }
        const feePercent = parseFloat(fieldData.details.feeValue) / 100;
        const newFeeDollarAmount = parseFloat((total * feePercent).toFixed(2));
        formControls.setValue(`truxwebShipperShipmentFees.${index}.charge.value`, newFeeDollarAmount);
    }, [formControls, getFuelSurchargeFee, getEquipmentCost, getAccessorialCost]);
    const handleUpdateAllPercentageFeeFields = (0, react_1.useCallback)((data) => {
        const fuelSurcharge = getFuelSurchargeFee(data);
        const equipmentCost = getEquipmentCost(data);
        const accessorialCost = getAccessorialCost(data);
        const total = fuelSurcharge + equipmentCost + accessorialCost;
        data.truxwebShipperShipmentFees.forEach((fieldData, index) => {
            if (fieldData.details.feeValueType === schemas_1.EPriceTypeV1.PERCENT) {
                handleUpdatePercentageFee(data, fieldData, index, total);
            }
        });
    }, [handleUpdatePercentageFee, getFuelSurchargeFee, getEquipmentCost, getAccessorialCost]);
    const handleWatch = (0, react_1.useCallback)((data, field) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const newAccessorials = [];
        if (((_a = field === null || field === void 0 ? void 0 : field.name) === null || _a === void 0 ? void 0 : _a.includes('equipment')) && data.fscRate) {
            const equipmentCost = getEquipmentCost(data);
            if (equipmentCost) {
                const newFscValue = (0, utils_1.getFscValueBasedOnEquipmentPrice)(equipmentCost, data.fscRate);
                if (newFscValue !== data.fscChargeValue) {
                    formControls.setValue('fscChargeValue', newFscValue);
                }
            }
            handleUpdateAllPercentageFeeFields(data);
        }
        if (field.name === 'fscRate') {
            // Based on the equipment cost determine what $  value
            // fscChargeValue should be set to based on the percent
            // entered
            const equipmentCost = getEquipmentCost(data);
            const newFscCharge = (0, utils_1.getFscValue)(equipmentCost, data.fscRate);
            if (newFscCharge !== data.fscChargeValue) {
                formControls.setValue('fscChargeValue', newFscCharge);
            }
        }
        if (field.name === 'fscChargeValue') {
            // based upon the value entered, determine
            // what the % value should be based on the equipment code
            const equipmentCost = getEquipmentCost(data);
            const newFscRate = (0, utils_1.getFscRate)(equipmentCost, data.fscChargeValue);
            if (newFscRate !== data.fscRate) {
                formControls.setValue('fscRate', newFscRate);
            }
            handleUpdateAllPercentageFeeFields(data);
        }
        if ((_b = field === null || field === void 0 ? void 0 : field.name) === null || _b === void 0 ? void 0 : _b.includes('accessorial')) {
            (data.accessorial || []).forEach((charge) => {
                var _a;
                if (charge === null || charge === void 0 ? void 0 : charge.chargeReferenceId) {
                    newAccessorials.push((_a = accessorialLookup[charge === null || charge === void 0 ? void 0 : charge.chargeReferenceId]) === null || _a === void 0 ? void 0 : _a.code);
                }
                setConsumedAccessorials(newAccessorials);
            });
            if ((_c = field === null || field === void 0 ? void 0 : field.name) === null || _c === void 0 ? void 0 : _c.includes('charge.value')) {
                handleUpdateAllPercentageFeeFields(data);
            }
        }
        if (((_d = field === null || field === void 0 ? void 0 : field.name) === null || _d === void 0 ? void 0 : _d.includes('truxwebShipperShipmentFees')) &&
            ((_e = field === null || field === void 0 ? void 0 : field.name) === null || _e === void 0 ? void 0 : _e.includes('details.feeValue'))) {
            const index = parseInt(field.name.split('.')[1]);
            if (!isNaN(index)) {
                const fieldData = data.truxwebShipperShipmentFees[index];
                if (fieldData.details.feeValueType === schemas_1.EPriceTypeV1.PERCENT) {
                    handleUpdatePercentageFee(data, fieldData, index);
                }
            }
        }
        if (((_f = field === null || field === void 0 ? void 0 : field.name) === null || _f === void 0 ? void 0 : _f.includes('serviceFees')) && ((_g = field === null || field === void 0 ? void 0 : field.name) === null || _g === void 0 ? void 0 : _g.includes('charge.value'))) {
            const index = parseInt(field.name.split('.')[1]);
            if (!isNaN(index)) {
                const fieldData = data.serviceFees[index];
                if (fieldData.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB) {
                    const newHasTruxwebMarginBeenModified = parseFloat(fieldData.charge.value).toFixed(2) !==
                        parseFloat(((_h = fieldData.details) === null || _h === void 0 ? void 0 : _h.calculatedTruxwebMargin) || '0').toFixed(2);
                    formControls.setValue(`serviceFees.${index}.details.hasTruxwebMarginBeenModified`, newHasTruxwebMarginBeenModified);
                    setTruxwebMarginModified(newHasTruxwebMarginBeenModified);
                }
            }
        }
    }, [
        setTruxwebMarginModified,
        setConsumedAccessorials,
        accessorialLookup,
        getEquipmentCost,
        formControls,
        handleUpdatePercentageFee,
        handleUpdateAllPercentageFeeFields,
    ]);
    formControls.watch(handleWatch);
    const handleFetchChargeUpdates = (0, react_1.useCallback)((shouldUseFormData) => __awaiter(void 0, void 0, void 0, function* () {
        let chargeData = charges;
        const data = formControls.getValues();
        let payload;
        if (shouldUseFormData) {
            const formattedData = (0, utils_1.formatDataFromOrderSummaryForm)(data);
            chargeData = formattedData.charges;
            payload = formattedData.payload;
        }
        const result = yield onEditShipment('calculateCharges', shipmentDetails.shipment, chargeData, payload);
        handleUpdatingCharges(result.charges);
        if (!hasInit) {
            handleInit({
                handleUpdatingCharges: (data) => {
                    handleUpdatingCharges(data);
                },
            });
        }
    }), [
        handleUpdatingCharges,
        onEditShipment,
        charges,
        formControls,
        shipmentDetails,
        handleInit,
        hasInit,
    ]);
    const getTruxwebFeeTitle = (0, react_1.useCallback)((field) => {
        var _a, _b;
        let title = t('common:unknown');
        if (field === null || field === void 0 ? void 0 : field.details) {
            title =
                language === schemas_1.ELanguageV1.EN_CA ? (_a = field.details) === null || _a === void 0 ? void 0 : _a.feeLabelEn : (_b = field.details) === null || _b === void 0 ? void 0 : _b.feeLabelFr;
        }
        return title;
    }, [t, language]);
    const renderPriceValue = (0, react_1.useCallback)((displayValue, value) => {
        if (!parseFloat(`${value}`)) {
            return t('common:includedAbbr');
        }
        return displayValue;
    }, [t]);
    const shipperShipmentFeeSelect = (0, react_1.useCallback)(({ field, fieldType, index, valueDisplay }) => {
        var _a;
        if (index === undefined)
            return react_1.default.createElement(react_1.default.Fragment, null);
        return (react_1.default.createElement(ux_1.Box, { mb: 1, width: '100%' },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: 'column', item: true, spacing: 2, xs: true },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: ((_a = field === null || field === void 0 ? void 0 : field.details) === null || _a === void 0 ? void 0 : _a.feeType) || '', name: `${fieldType}.${index}.details.feeType`, render: ({ field: { value }, fieldState: { error } }) => {
                            return (react_1.default.createElement(__1.ShipmentOrderSummaryFeeSelect, { availableShipperShipmentFees: availableShipperShipmentFees, consumedShipmentFees: consumedShipmentFees, error: error, formControls: formControls, index: index, language: language, setConsumedShipmentFees: setConsumedShipmentFees, t: t, value: value }));
                        }, rules: { required: true } })),
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" }, valueDisplay))));
    }, [availableShipperShipmentFees, consumedShipmentFees, formControls, language, t]);
    (0, react_1.useEffect)(() => {
        if (consumedShipmentFees === null) {
            const shipmentFeeTypes = truxwebShipperShipmentFees
                .map(({ details }) => {
                if (!details)
                    return undefined;
                return details.feeType;
            })
                .filter((record) => Boolean(record));
            setConsumedShipmentFees(shipmentFeeTypes);
        }
        if (consumedAccessorials === null) {
            const existingAccessorials = accessorial
                .map((charge) => {
                var _a;
                return (_a = accessorialLookup[charge === null || charge === void 0 ? void 0 : charge.chargeReferenceId]) === null || _a === void 0 ? void 0 : _a.code;
            })
                .filter((record) => Boolean(record));
            setConsumedAccessorials(existingAccessorials);
        }
    }, [
        consumedAccessorials,
        accessorialLookup,
        setConsumedShipmentFees,
        truxwebShipperShipmentFees,
        consumedShipmentFees,
        accessorial,
        setConsumedAccessorials,
    ]);
    (0, react_1.useEffect)(() => {
        if (!hasInit && !isSaving && !hasLocalInit) {
            setIsSaving(true);
            handleFetchChargeUpdates();
            setIsSaving(false);
            setLocalInit(true);
        }
    }, [isSaving, setIsSaving, hasInit, handleFetchChargeUpdates, hasLocalInit, setLocalInit]);
    if (!hasInit) {
        return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "column", item: true, justifyContent: "center", style: { minHeight: 364 }, xs: true },
            react_1.default.createElement(ux_1.CircularProgress, null)));
    }
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(__1.ShipmentOrderSummarySectionTitle, { title: t('common:freightCost') }),
        equipmentFields.fields.map((field, index) => {
            var _a;
            const title = t(`common:CARRIER-equipment-${(_a = equipmentLookup[field.chargeReferenceId]) === null || _a === void 0 ? void 0 : _a.equipmentCode}`);
            return (react_1.default.createElement(__1.ShipmentOrderSummaryRow, { canRemove: false, field: field, fieldManagement: equipmentFields, fieldType: 'equipment', formControls: formControls, hasRecalculated: hasRecalculated, index: index, key: `equipment-${field._id}`, language: language, name: `equipment.${index}.charge.value`, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: false, t: t, title: title, valueType: schemas_1.EPriceTypeV1.CURRENCY }));
        }),
        (fuelSurcharge || []).map((field, index) => {
            if (!field.isBillable)
                return null;
            const chargeTitle = t(`common:charge-${field.chargeType}`);
            const key = `${field.recipient}-${field.chargeTypeId}-${index}`;
            return (react_1.default.createElement(__1.ShipmentOrderSummaryRow, { currencyValue: field.charge.value, currencyValueName: "fscChargeValue", field: field, fieldManagement: fuelSurchargeFields, fieldType: "fuelSurcharge", formControls: formControls, hasRecalculated: hasRecalculated, isCurrencyDisabled: true, isPercentDisabled: false, key: key, language: language, name: 'fuelSurcharge', percentValue: shipmentDetails.shipment.fscRate
                    ? parseFloat((shipmentDetails.shipment.fscRate * 100).toFixed(2))
                    : 0, percentValueName: "fscRate", renderPriceValue: renderPriceValue, shouldAllowNegativeValues: false, t: t, title: chargeTitle, valueType: schemas_1.EPriceTypeV1.PERCENT }));
        }),
        (accessorial.length !== 0 || shouldAllowAddingCharges) && (react_1.default.createElement(__1.ShipmentOrderSummaryChargeSet, { chargeType: schemas_1.EShipmentChargeTypeV1.ACCESSORIAL, fieldManagement: accessorialFields, fieldType: "accessorial", formControls: formControls, getChargeTitle: (field) => {
                var _a;
                return t(`common:SHIPPER-accessorial-${(_a = accessorialLookup[field.chargeReferenceId]) === null || _a === void 0 ? void 0 : _a.code}`);
            }, hasDescription: false, hasRecalculated: hasRecalculated, isAddingFeesDisabled: ((_a = accessorialFields === null || accessorialFields === void 0 ? void 0 : accessorialFields.fields) === null || _a === void 0 ? void 0 : _a.length) === (accessorials === null || accessorials === void 0 ? void 0 : accessorials.length), language: language, recipient: 'CARRIER', renderFieldTypeSelection: ({ error, index, onChange, value }) => {
                return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), localizationPrefix: `common:SHIPPER-accessorial-`, onChange: (event) => {
                        onChange(event);
                        formControls.setValue(`accessorial.${index}.chargeReferenceId`, accessorialLookupByCode[event.target.value].id);
                    }, optionsFilter: (option) => {
                        const source = accessorialLookupByCode[option];
                        if (!source)
                            return false;
                        return !(consumedAccessorials || []).includes(option);
                    }, sourceEnum: schemas_1.EAccessorialOptionV1, t: t, value: value }));
            }, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: false, t: t, title: t('common:additionalServicesHeader') })),
        (additional.length !== 0 || shouldAllowAddingCharges) && (react_1.default.createElement(__1.ShipmentOrderSummaryChargeSet, { chargeType: schemas_1.EShipmentChargeTypeV1.ADDITIONAL, explanation: 'These are charges which the shipper must pay to the carrier.', fieldManagement: additionalChargesFields, fieldType: "additional", formControls: formControls, getChargeTitle: (field) => {
                return t(`shipments:additionalchargetype-CARRIER-${field.chargeReference}`);
            }, hasDescription: true, hasRecalculated: hasRecalculated, language: language, recipient: 'CARRIER', renderFieldTypeSelection: ({ error, onChange, value }) => {
                return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), localizationPrefix: `shipments:additionalchargetype-CARRIER-`, onChange: onChange, sourceEnum: schemas_1.EShipmentAdditionalChargeTypeV1, t: t, value: value }));
            }, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: false, t: t, title: t('shipments:additionalCarrierCharges') })),
        (truxwebCarrierCharges.length !== 0 || shouldAllowAddingCharges) && (react_1.default.createElement(__1.ShipmentOrderSummaryChargeSet, { chargeType: schemas_1.EShipmentChargeTypeV1.TRUXWEB_CARRIER, explanation: 'These are charges which will be debitted from amount Truxweb will pay to the carrier .', fieldManagement: truxwebCarrierChargeFields, fieldType: "truxwebCarrierCharges", formControls: formControls, getChargeTitle: (field) => {
                return t(`shipments:additionalchargetype-CARRIER-${field.chargeReference}`);
            }, hasDescription: true, hasRecalculated: hasRecalculated, language: language, recipient: 'TRUXWEB', renderFieldTypeSelection: ({ error, onChange, value }) => {
                return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), localizationPrefix: `shipments:additionalchargetype-CARRIER-`, onChange: onChange, sourceEnum: schemas_1.EShipmentTruxwebAdditionalChargeTypeV1, t: t, value: value }));
            }, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: true, t: t, title: t('shipments:truxwebCarrierAdditionalCharges') })),
        (truxwebShipperCharges.length !== 0 || shouldAllowAddingCharges) && (react_1.default.createElement(__1.ShipmentOrderSummaryChargeSet, { chargeType: schemas_1.EShipmentChargeTypeV1.TRUXWEB_SHIPPER, explanation: 'These are charges which the shipper must pay to Truxweb.', fieldManagement: truxwebShipperChargeFields, fieldType: "truxwebShipperCharges", formControls: formControls, getChargeTitle: (field) => {
                return t(`shipments:additionalchargetype-CARRIER-${field.chargeReference}`);
            }, hasDescription: true, hasRecalculated: hasRecalculated, language: language, recipient: 'TRUXWEB', renderFieldTypeSelection: ({ error, onChange, value }) => {
                return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), localizationPrefix: `shipments:additionalchargetype-CARRIER-`, onChange: onChange, sourceEnum: schemas_1.EShipmentTruxwebAdditionalChargeTypeV1, t: t, value: value }));
            }, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: true, t: t, title: t('shipments:truxwebShipperAdditionalCharges') })),
        (((_b = truxwebShipperShipmentFeeFields === null || truxwebShipperShipmentFeeFields === void 0 ? void 0 : truxwebShipperShipmentFeeFields.fields) === null || _b === void 0 ? void 0 : _b.length) !== 0 || shouldAllowAddingCharges) && (react_1.default.createElement(__1.ShipmentOrderSummaryChargeSet, { advancedFieldTypeSelection: shipperShipmentFeeSelect, buildFieldDetails: () => {
                return {
                    companyExtId: shipmentDetails.shipment.shipperExtId,
                    dateCreated: '',
                    dateUpdated: '',
                    feeDescriptionEn: '',
                    feeDescriptionFr: '',
                    feeLabelEn: '',
                    feeLabelFr: '',
                    feeType: '',
                    feeValue: 0,
                    feeValueType: schemas_1.EPriceTypeV1.CURRENCY,
                    id: 0,
                    isActive: true,
                    isVisible: true,
                    shipmentFeeTypeId: 0,
                };
            }, chargeType: schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE, explanation: 'These are charges automatically applied by truxweb to every shipment placed by this shipper', fieldManagement: truxwebShipperShipmentFeeFields, fieldType: "truxwebShipperShipmentFees", formControls: formControls, getChargeTitle: getTruxwebFeeTitle, hasDescription: true, hasRecalculated: hasRecalculated, isAddingFeesDisabled: ((_c = truxwebShipperShipmentFeeFields === null || truxwebShipperShipmentFeeFields === void 0 ? void 0 : truxwebShipperShipmentFeeFields.fields) === null || _c === void 0 ? void 0 : _c.length) === (availableShipperShipmentFees === null || availableShipperShipmentFees === void 0 ? void 0 : availableShipperShipmentFees.length), language: language, manageConsumedSelections: (field) => {
                setConsumedShipmentFees((0, utils_2.toggleListValue)(consumedShipmentFees || [], field.details.feeType));
            }, recipient: 'TRUXWEB', renderPriceValue: renderPriceValue, shouldAllowNegativeValues: false, t: t, title: t('common:truxwebShipperShipmentFees') })),
        ((serviceFeeFields === null || serviceFeeFields === void 0 ? void 0 : serviceFeeFields.fields) || []).map((field, index) => {
            let chargeTitle = t(`common:charge-${field.chargeType}`);
            if (field.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
                chargeTitle = t('common:adminFees');
            }
            let canReset = false;
            let handleReset = undefined;
            if (field.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB &&
                field.chargeReference !== 'SHIPPER_CHARGE_ADJUSTMENT' &&
                hasTruxwebMarginBeenModified === true) {
                canReset = true;
                handleReset = (resetField, resetFormControls, fieldType, index) => {
                    var _a;
                    if (index !== undefined) {
                        const data = formControls.getValues();
                        const fieldData = data.serviceFees[index];
                        resetFormControls.setValue(`${fieldType}.${index}.charge.value`, parseFloat((_a = fieldData === null || fieldData === void 0 ? void 0 : fieldData.details) === null || _a === void 0 ? void 0 : _a.calculatedTruxwebMargin) || 0);
                    }
                };
            }
            const key = `${field.recipient}-${field.chargeTypeId}-${index}`;
            return (react_1.default.createElement(__1.ShipmentOrderSummaryRow, { canRemove: field.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT', canReset: canReset, field: field, fieldManagement: serviceFeeFields, fieldType: "serviceFees", formControls: formControls, handleReset: handleReset, hasRecalculated: hasRecalculated, index: index, key: key, language: language, name: `serviceFees.${index}.charge.value`, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: false, t: t, title: chargeTitle, valueType: schemas_1.EPriceTypeV1.CURRENCY }));
        }),
        currentCarrierSummary !== null && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(__1.ShipmentOrderSummarySectionBreak, { title: t(`common:carrierRecievables`) }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: (_d = currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierFrieghtTotal) === null || _d === void 0 ? void 0 : _d.charge, title: t('common:freightCost') }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: (_e = currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierAdditionalChargeTotal) === null || _e === void 0 ? void 0 : _e.charge, title: t('shipments:additionalCharges') }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: Object.assign(Object.assign({}, (_f = currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierFeeTotal) === null || _f === void 0 ? void 0 : _f.charge), { value: -(((_g = currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierFeeTotal) === null || _g === void 0 ? void 0 : _g.charge.value) || 0) }), title: 'Carrier Charges' }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: (_h = currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierSubtotal) === null || _h === void 0 ? void 0 : _h.charge, shouldBold: true, title: t('common:subtotal') }),
            react_1.default.createElement(__1.ShipmentOrderSummaryTaxes, { language: language, t: t, taxes: currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierTaxes }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isLarge: true, isValueRequired: true, language: language, mt: 2, price: (_j = currentCarrierSummary === null || currentCarrierSummary === void 0 ? void 0 : currentCarrierSummary.carrierTotal) === null || _j === void 0 ? void 0 : _j.charge, shouldBold: true, title: t('common:carrierTotal') }))),
        currentTruxwebSummary !== null &&
            Boolean((_k = currentTruxwebSummary.truxwebTotal) === null || _k === void 0 ? void 0 : _k.charge.value) && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(__1.ShipmentOrderSummarySectionBreak, { title: t(`common:truxwebMargin`) }),
            currentTruxwebSummary.truxwebFees.map((fee) => {
                let chargeTitle = t(`common:charge-${fee.chargeType}`);
                if (fee.chargeReference === 'SHIPPER_CHARGE_ADJUSTMENT') {
                    chargeTitle = t('common:adminFees');
                }
                let details;
                let hidden;
                if (fee.chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE) {
                    chargeTitle = getTruxwebFeeTitle(fee);
                    hidden = !fee.details.isVisible ? (react_1.default.createElement(ux_1.VisibilityOffOutlinedIcon, { fontSize: "small" })) : undefined;
                    if (fee.details.feeValueType === schemas_1.EPriceTypeV1.PERCENT) {
                        const value = parseFloat(`${fee.details.feeValue}`);
                        details = `(${value.toFixed(2)}%)`;
                    }
                }
                return (react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { details: details, icon: hidden, iconTitle: t('common:hiddenFeesExplanation'), isValueRequired: true, key: fee.id, language: language, mb: 0.5, price: fee.charge, title: chargeTitle }));
            }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: (_l = currentTruxwebSummary === null || currentTruxwebSummary === void 0 ? void 0 : currentTruxwebSummary.truxwebCarrierFeeTotal) === null || _l === void 0 ? void 0 : _l.charge, title: 'Carrier Charges' }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: (_m = currentTruxwebSummary === null || currentTruxwebSummary === void 0 ? void 0 : currentTruxwebSummary.truxwebShipperFeeTotal) === null || _m === void 0 ? void 0 : _m.charge, title: 'Shipper Charges' }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mt: 2, price: (_o = currentTruxwebSummary === null || currentTruxwebSummary === void 0 ? void 0 : currentTruxwebSummary.truxwebSubtotal) === null || _o === void 0 ? void 0 : _o.charge, shouldBold: true, title: t('common:subtotal') }),
            react_1.default.createElement(__1.ShipmentOrderSummaryTaxes, { getPrefix: (field) => {
                    return field.chargeReference === 'CARRIER_TAX' ? `${t(`common:CARRIER`)} ` : '';
                }, language: language, t: t, taxes: currentTruxwebSummary === null || currentTruxwebSummary === void 0 ? void 0 : currentTruxwebSummary.truxwebTaxes }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isLarge: true, isValueRequired: true, language: language, mt: 2, price: (_p = currentTruxwebSummary === null || currentTruxwebSummary === void 0 ? void 0 : currentTruxwebSummary.truxwebTotal) === null || _p === void 0 ? void 0 : _p.charge, shouldBold: true, title: t('common:truxwebTotal') }))),
        currentShipperSummary !== null && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(__1.ShipmentOrderSummarySectionBreak, { title: t(`common:shipperPayables`) }),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, mt: 2, price: currentShipperSummary === null || currentShipperSummary === void 0 ? void 0 : currentShipperSummary.shipperSubtotal, shouldBold: true, title: t(`common:subtotal`) }),
            react_1.default.createElement(__1.ShipmentOrderSummaryTaxes, { language: language, t: t, taxes: currentShipperSummary === null || currentShipperSummary === void 0 ? void 0 : currentShipperSummary.shipperTaxes }),
            currentCreditCardSurcharge !== null &&
                ((_q = currentCreditCardSurcharge[0]) === null || _q === void 0 ? void 0 : _q.isBillable) === true && (react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isValueRequired: true, language: language, mb: 0.5, price: currentCreditCardSurcharge[0].charge, title: t(`common:charge-CREDIT_CARD_SURCHARGE`) })),
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { isLarge: true, language: language, mt: 2, price: currentShipperSummary === null || currentShipperSummary === void 0 ? void 0 : currentShipperSummary.shipperTotal, shouldBold: true, title: t('common:total') }))),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true }, handleCancel !== undefined && (react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:cancel')))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    shouldDisallowSavingCharges && (react_1.default.createElement(__1.SaveButton, { customAction: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield handleFetchChargeUpdates(true);
                        }), isSaving: isSaving, t: t }, t('common:calculate'))),
                    !shouldDisallowSavingCharges && (react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }, hasRecalculated ? t('common:save') : t('common:calculate'))))))));
};
exports.ShipmentDashboardEditableOrderSummary = ShipmentDashboardEditableOrderSummary;
