"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionDetailsStyles = exports.useAccordionSummaryStyles = exports.useAccordionStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    additionalChargeContainer: {
        backgroundColor: theme.palette.grey[100],
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(2),
        margin: `${theme.spacing(3)}px 0 `,
        padding: theme.spacing(2),
    },
}));
exports.useAccordionStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'unset',
    },
}));
exports.useAccordionSummaryStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        padding: 0,
    },
}));
exports.useAccordionDetailsStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        padding: 0,
    },
}));
