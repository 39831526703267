"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        width: `100%`,
    },
    taxExemptConfirmation: {
        background: theme.palette.grey[200],
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(2),
    },
}));
