"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataGridColumnAction = void 0;
const __1 = require("..");
const react_1 = __importStar(require("react"));
const __2 = require("../..");
const DataGridColumnAction_styles_1 = require("./DataGridColumnAction.styles");
const DEFAULT_SLIDE_DIRECTION = 'right';
const DataGridColumnAction = ({ gapInterval, handleOpenActions, renderActionsContent, row, rowHeight, shouldDisplay, slideDirection, }) => {
    slideDirection = slideDirection ? slideDirection : DEFAULT_SLIDE_DIRECTION;
    const [isOverlayShown, setOverlayShown] = (0, react_1.useState)(false);
    const [overlayData, setOverlayData] = (0, react_1.useState)(null);
    //We currently only accept values of `left` || `right` when setting slideDirection
    //we assume 'left' indicates that the column is at position 0
    //right assumes position is array.length
    const classes = (0, DataGridColumnAction_styles_1.useStyles)({
        gapInterval,
        rowHeight,
        slideDirection,
    });
    const handleHideActions = (0, react_1.useCallback)(() => {
        setOverlayData(null);
        setOverlayShown(false);
    }, [setOverlayShown, setOverlayData]);
    const handleRowOverlayToggle = (0, react_1.useCallback)(() => {
        if (!isOverlayShown) {
            if (handleOpenActions) {
                handleOpenActions(row.id);
            }
            setOverlayData(row);
            setOverlayShown(true);
        }
        else {
            handleHideActions();
        }
    }, [isOverlayShown, handleHideActions, setOverlayShown, setOverlayData, row, handleOpenActions]);
    const button = (react_1.default.createElement(__1.Button, { className: classes.greyButton, disableFocusRipple: true, disableRipple: true, disableTouchRipple: true, onClick: handleRowOverlayToggle },
        react_1.default.createElement(__2.MoreVertIcon, null)));
    return (react_1.default.createElement(react_1.default.Fragment, null, shouldDisplay && (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.Slide, { direction: slideDirection, in: isOverlayShown },
            react_1.default.createElement(__1.Box, { className: classes.overlay },
                react_1.default.createElement(__1.Grid, { alignItems: "center", className: classes.overlayInner, container: true },
                    slideDirection === 'left' && (react_1.default.createElement(__1.Grid, { className: classes.expandButton, item: true, style: { height: '100%' } }, button)),
                    react_1.default.createElement(__1.Grid, { item: true, style: { height: '100%' } }, isOverlayShown && renderActionsContent(overlayData, setOverlayShown)),
                    slideDirection === 'right' && (react_1.default.createElement(__1.Grid, { className: classes.expandButton, item: true, style: { height: '100%' } }, button))))),
        button))));
};
exports.DataGridColumnAction = DataGridColumnAction;
