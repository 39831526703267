"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetDetailsFixEntries = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const RateSheetDetailsFixEntries_styles_1 = require("./RateSheetDetailsFixEntries.styles");
const MAX_REGIONS_TO_DISPLAY = 5;
const BASIC_BOUNDARY = {
    boundaryType: schemas_1.ERateSheetBoundaryTypeV1.REGION,
    boundaryTypeId: 0,
    isRequestOnly: false,
    maximum: 0,
    minimum: 0,
    name: '',
};
const RateSheetDetailsFixEntries = ({ entriesToFix, handleCancel, isSaving, onSubmitSuccess, rateSheetLocations, rateSheetRegion, rateSheetRegions, regionsToConfirm, t, }) => {
    const classes = (0, RateSheetDetailsFixEntries_styles_1.useStyles)();
    const [expandedRegions, setExpandedRegions] = (0, react_1.useState)({});
    const [confirmedRegionsConfirmationState, setConfirmedRegionsConfirmationState] = (0, react_1.useState)({});
    const [consumedRegions, setConsumedRegions] = (0, react_1.useState)([]);
    const theme = (0, ux_1.useTheme)();
    const filteredFixes = (0, react_1.useMemo)(() => {
        return entriesToFix
            .filter(({ boundaryType }) => boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION)
            .map((record) => {
            return Object.assign(Object.assign({}, record), { _source: { boundaryType: record.boundaryType, cityDetails: record.cityDetails } });
        });
    }, [entriesToFix]);
    const mappedRegions = (0, react_1.useMemo)(() => {
        return (regionsToConfirm || []).map((region) => {
            return Object.assign(Object.assign({}, region), { boundaryTypeId: region.id });
        });
    }, [regionsToConfirm]);
    const entriesToFixFormControls = (0, react_hook_form_1.useForm)({
        defaultValues: {
            regionsToConfirm: [...mappedRegions, ...filteredFixes],
        },
    });
    const toggleConsumedRegions = (0, react_1.useCallback)((regionIds) => {
        let regions = [...consumedRegions];
        regionIds.forEach((regionId) => {
            regions = (0, utils_1.toggleListValue)(regions, regionId);
        });
        setConsumedRegions(regions);
    }, [consumedRegions, setConsumedRegions]);
    const handleToggleRegionExpansion = (0, react_1.useCallback)((fieldId) => {
        const newExpansion = Object.assign(Object.assign({}, expandedRegions), { [fieldId]: !Boolean(expandedRegions[fieldId]) });
        setExpandedRegions(newExpansion);
    }, [expandedRegions, setExpandedRegions]);
    const { fields: regionFields } = (0, react_hook_form_1.useFieldArray)({
        control: entriesToFixFormControls.control,
        name: 'regionsToConfirm',
    });
    // If there are no existing regions to select, do not allow that to be an option
    const renderBoundary = (0, react_1.useCallback)((name, index, className, regionName) => {
        return (react_1.default.createElement(ux_1.Box, { className: className, key: `${name}-${index}` },
            react_1.default.createElement(ux_1.Box, { mt: 3 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: entriesToFixFormControls.control, defaultValue: regionName, id: `${name}.name`, label: t('common:name'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "text" }),
                react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(__1.RateSheetRegionLocationSelection, { baseName: name, formControls: entriesToFixFormControls, name: `${name}.locations`, rateSheetLocations: rateSheetLocations, rateSheetRegion: rateSheetRegion, t: t })))));
    }, [t, entriesToFixFormControls, rateSheetLocations, rateSheetRegion]);
    const shouldAllowExistingRegions = Boolean(rateSheetRegions === null || rateSheetRegions === void 0 ? void 0 : rateSheetRegions.length);
    return (react_1.default.createElement("form", { onSubmit: entriesToFixFormControls.handleSubmit(onSubmitSuccess) },
        Boolean(regionFields.length) && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Box, { mt: 3 }),
            react_1.default.createElement(__1.ExplainedString, { explanation: t('common:confirmRegionsExplanation'), fontStyle: "semibold", isTooltip: true }, t('common:confirmRegions')),
            regionFields.map((field, index) => {
                var _a, _b;
                const regionState = confirmedRegionsConfirmationState[field.id] || {};
                // Determine if region is an existing region
                const isExistingRegion = confirmedRegionsConfirmationState[field.id]
                    ? Boolean(regionState.regionId)
                    : Boolean(field.boundaryTypeId);
                // Determine if this exsting region is in an inactive
                const isActiveState = regionState.selectedRegion
                    ? (_a = regionState.selectedRegion) === null || _a === void 0 ? void 0 : _a.isActive
                    : field.isActive;
                const isFieldInactive = isExistingRegion && !Boolean(isActiveState);
                // Determine if this is a global region
                const carrierId = (regionState === null || regionState === void 0 ? void 0 : regionState.selectedRegion)
                    ? (_b = regionState.selectedRegion) === null || _b === void 0 ? void 0 : _b.carrierId
                    : field.carrierId;
                const wasAGlobalRegion = Boolean(field.boundaryTypeId) && !Boolean(field.carrierId);
                const isGlobalRegion = isExistingRegion && !Boolean(carrierId);
                const archivedTextColor = isFieldInactive ? 'error' : undefined;
                const archivedColor = isFieldInactive ? theme.palette.error.main : undefined;
                const defaultSelectedRegion = rateSheetRegions.find(({ id }) => {
                    return id === (field === null || field === void 0 ? void 0 : field.boundaryTypeId);
                });
                // 0) If this field is inactive, show a warning to the user that this region
                // will not be used for the rate sheet
                // Note: This allows the use of an inactive region, which can subsequently be
                // activated and picked up by a rate sheet
                const inactiveNotice = isFieldInactive ? (react_1.default.createElement(__1.ExplainedString, { explanation: t('common:archivedRegion'), isTooltip: true },
                    react_1.default.createElement(ux_1.TruxwebArchiveIcon, { style: { fill: archivedColor } }))) : null;
                // 1) If this is an existing region, allow the user to indicate that they
                // want to create a new region instead
                //
                let shouldUseExisting = isExistingRegion;
                if (regionState) {
                    shouldUseExisting = (regionState === null || regionState === void 0 ? void 0 : regionState.shouldUseExisting) || isExistingRegion;
                }
                const existingRegionToggle = shouldAllowExistingRegions ? (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Typography, null, t('common:useExistingRegion')),
                    react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", onChange: (event) => {
                            const newFieldState = Object.assign(Object.assign({}, regionState), { hasBeenConfirmed: fieldConfirmationState, regionId: event === schemas_1.EYesNoV1.YES ? regionState === null || regionState === void 0 ? void 0 : regionState.regionId : null, selectedRegion: event === schemas_1.EYesNoV1.YES ? regionState === null || regionState === void 0 ? void 0 : regionState.selectedRegion : null, shouldUseExisting: event === schemas_1.EYesNoV1.YES });
                            const newConfirmedRegions = Object.assign(Object.assign({}, confirmedRegionsConfirmationState), { [field.id]: newFieldState });
                            if (!newFieldState.regionId && (regionState === null || regionState === void 0 ? void 0 : regionState.regionId)) {
                                toggleConsumedRegions([regionState.regionId]);
                            }
                            entriesToFixFormControls.setValue(`regionsToConfirm.${index}.boundaryDefinition`, event === schemas_1.EYesNoV1.NO ? BASIC_BOUNDARY : undefined);
                            setConfirmedRegionsConfirmationState(newConfirmedRegions);
                        }, sourceEnum: schemas_1.EYesNoV1, t: t, value: shouldUseExisting ? schemas_1.EYesNoV1.YES : schemas_1.EYesNoV1.NO }))) : null;
                // 2) If this is a global region, show a message to the user that they cannot
                // change this
                let globalRegionNotice = null;
                let globalRegionIcon = null;
                let wasGlobalRegionIcon = null;
                if (isGlobalRegion) {
                    globalRegionIcon = react_1.default.createElement(ux_1.TruxwebGlobeIcon, { style: { fill: archivedColor || 'white' } });
                    globalRegionNotice = t('common:confirmGlobalRegionsExplanation');
                }
                if (wasAGlobalRegion) {
                    wasGlobalRegionIcon = react_1.default.createElement(ux_1.TruxwebGlobeIcon, null);
                }
                let fieldConfirmationState = confirmedRegionsConfirmationState[field.id] !== undefined
                    ? confirmedRegionsConfirmationState[field.id].hasBeenConfirmed
                    : true;
                let fieldUseExistingState = confirmedRegionsConfirmationState[field.id] !== undefined
                    ? confirmedRegionsConfirmationState[field.id].shouldUseExisting
                    : isExistingRegion;
                // Force the creation of new entities
                if (!shouldAllowExistingRegions) {
                    fieldUseExistingState = false;
                }
                if (!isFieldInactive) {
                    fieldConfirmationState = false;
                }
                const fieldLocations = (regionState === null || regionState === void 0 ? void 0 : regionState.selectedRegion)
                    ? regionState === null || regionState === void 0 ? void 0 : regionState.selectedRegion.locations
                    : field.locations || [];
                const hasLargeLocationList = fieldLocations.length > MAX_REGIONS_TO_DISPLAY;
                let displayLocations = fieldLocations;
                let expandableLocations = [];
                if (hasLargeLocationList) {
                    displayLocations = fieldLocations.slice(0, MAX_REGIONS_TO_DISPLAY);
                    expandableLocations = fieldLocations.slice(MAX_REGIONS_TO_DISPLAY, fieldLocations.length);
                }
                return (react_1.default.createElement(ux_1.Box, { className: classes.item, key: field.id, mb: 2 },
                    react_1.default.createElement(ux_1.Box, { mb: 2 },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "flex-start" },
                            wasGlobalRegionIcon,
                            react_1.default.createElement(ux_1.Typography, { color: archivedTextColor, fontStyle: "semibold" }, field.name),
                            inactiveNotice)),
                    wasAGlobalRegion && !regionState.selectedRegion && (react_1.default.createElement(ux_1.Box, { mb: 2 }, react_1.default.createElement(ux_1.Typography, null, globalRegionNotice))),
                    isGlobalRegion && Boolean(regionState.selectedRegion) && (react_1.default.createElement(ux_1.Box, { style: {
                            background: theme.palette.primary.light,
                            borderRadius: 16,
                            padding: 16,
                        } },
                        react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "flex-start" },
                                globalRegionIcon,
                                react_1.default.createElement(ux_1.Typography, { color: archivedTextColor || 'white', fontStyle: "semibold" }, regionState.selectedRegion.name))),
                        react_1.default.createElement(ux_1.Typography, { color: "white" }, globalRegionNotice))),
                    existingRegionToggle,
                    !fieldConfirmationState && (react_1.default.createElement(react_1.default.Fragment, null,
                        shouldAllowExistingRegions && (react_1.default.createElement(react_1.default.Fragment, null, fieldUseExistingState && (react_1.default.createElement(react_hook_form_1.Controller, { control: entriesToFixFormControls.control, defaultValue: defaultSelectedRegion, name: `regionsToConfirm.${index}.boundaryDefinition`, render: ({ field: { onChange, value } }) => {
                                return (react_1.default.createElement(ux_1.TruxwebSelect, { onChange: (event) => {
                                        const regionId = event.target.value;
                                        const selectedRegion = rateSheetRegions.find(({ id }) => {
                                            return id === regionId;
                                        });
                                        const newConfirmedRegions = Object.assign(Object.assign({}, confirmedRegionsConfirmationState), { [field.id]: Object.assign(Object.assign({}, regionState), { regionId,
                                                selectedRegion, shouldUseExisting: regionState.shouldUseExisting !== undefined
                                                    ? regionState.shouldUseExisting
                                                    : fieldUseExistingState }) });
                                        setConfirmedRegionsConfirmationState(newConfirmedRegions);
                                        const regionsToToggle = [
                                            event.target.value,
                                        ];
                                        // Remove any previous selected region
                                        if (regionState.regionId) {
                                            regionsToToggle.push(regionState.regionId);
                                        }
                                        handleToggleRegionExpansion(field.id);
                                        toggleConsumedRegions(regionsToToggle);
                                        onChange(selectedRegion);
                                    }, required: isFieldInactive, value: value === null || value === void 0 ? void 0 : value.id }, rateSheetRegions.map((item) => {
                                    if (!item.isActive)
                                        return null;
                                    return (react_1.default.createElement(ux_1.MenuItem, { disabled: consumedRegions.includes(item.id), key: item.id, value: item.id },
                                        !Boolean(item.carrierId) && react_1.default.createElement(ux_1.TruxwebGlobeIcon, null),
                                        item.name));
                                })));
                            } })))),
                        !fieldUseExistingState && (react_1.default.createElement(ux_1.Box, { mt: 2 }, renderBoundary(`regionsToConfirm.${index}.boundaryDefinition`, index, classes.innerItem, field.name))))),
                    fieldUseExistingState && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("ul", null, displayLocations.map((location) => {
                            return (react_1.default.createElement("li", { key: location.id },
                                react_1.default.createElement(ux_1.Typography, null, location.name)));
                        })),
                        hasLargeLocationList && (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(ux_1.StandardButton, { onClick: () => handleToggleRegionExpansion(field.id) }, !expandedRegions[field.id] ? t('common:viewMore') : t('common:viewLess')),
                            react_1.default.createElement(ux_1.Collapse, { in: expandedRegions[field.id] },
                                react_1.default.createElement("ul", null, expandableLocations.map((location) => {
                                    return (react_1.default.createElement("li", { key: location.id },
                                        react_1.default.createElement(ux_1.Typography, null, location.name)));
                                })))))))));
            }))),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:back'))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }, t('common:next')))))));
};
exports.RateSheetDetailsFixEntries = RateSheetDetailsFixEntries;
