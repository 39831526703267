"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestCheckoutDetails = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const QuoteRequestCheckoutDetails_styles_1 = require("./QuoteRequestCheckoutDetails.styles");
const QuoteRequestCheckoutDetails = ({ carrierQuoteData, companyLocations, control, defaultLocationHours, defaultLocationSchedule, t, watch, }) => {
    const classes = (0, QuoteRequestCheckoutDetails_styles_1.useStyles)();
    const originData = carrierQuoteData.location.find((location) => {
        return location.locationType === schemas_1.EShipmentLocationTypeV1.ORIGIN;
    });
    const destinationData = carrierQuoteData.location.find((location) => {
        return location.locationType === schemas_1.EShipmentLocationTypeV1.DESTINATION;
    });
    const sourceOrigin = (0, react_1.useMemo)(() => {
        if (!originData || !companyLocations)
            return null;
        const origin = companyLocations.find(({ id }) => {
            return originData.sourceLocationId === id;
        });
        return origin || null;
    }, [originData, companyLocations]);
    const sourceDestination = (0, react_1.useMemo)(() => {
        if (!destinationData || !companyLocations)
            return null;
        const destination = companyLocations.find(({ id }) => {
            return destinationData.sourceLocationId === id;
        });
        return destination || null;
    }, [destinationData, companyLocations]);
    if (!originData || !destinationData)
        return react_1.default.createElement(react_1.default.Fragment, null);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Box, { mb: 1.5 },
            react_1.default.createElement(ux_1.Typography, { className: classes.header }, t(`common:yourReferenceNumber`))),
        react_1.default.createElement(__1.FormField, { InputLabelProps: { shrink: true }, control: control, defaultValue: '', id: `shipperReferenceNumber`, inputProps: { maxLength: 32 }, label: '', shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "string" }),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(__1.QuoteRequestCheckoutLocationDetails, { control: control, defaultLocationHours: defaultLocationHours, defaultLocationSchedule: defaultLocationSchedule, location: originData, sourceLocation: sourceOrigin, t: t, watch: watch })),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(__1.QuoteRequestCheckoutLocationDetails, { control: control, defaultLocationHours: defaultLocationHours, defaultLocationSchedule: defaultLocationSchedule, location: destinationData, sourceLocation: sourceDestination, t: t, watch: watch })),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Box, { ml: 1 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: true, name: `shouldAddressesUpdate`, render: ({ field: { onChange, value } }) => {
                            return react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primaryLight", onClick: onChange });
                        } })), label: react_1.default.createElement(ux_1.Box, { ml: 1 },
                    react_1.default.createElement(ux_1.Typography, null, t('common:shouldAddressesUpdate'))), labelPlacement: "end" })),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Box, { mb: 1.5 },
                react_1.default.createElement(ux_1.Typography, { className: classes.header }, t(`common:specialInstructions`))),
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: `checkoutInstructions`, render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(ux_1.GreyTextArea, { className: classes.checkoutInstructions, inputProps: { maxLength: 200 }, onChange: onChange, value: value, variant: "filled" }),
                        react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                            react_1.default.createElement(ux_1.Typography, { className: classes.characterCount }, t('common:maximumCharacterCount', { count: 200 })))));
                } }))));
};
exports.QuoteRequestCheckoutDetails = QuoteRequestCheckoutDetails;
