import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  banner: {
    background: theme.palette.primary.light,
    borderRadius: '16px',
    color: 'white',
    padding: '8px',
    textAlign: 'center',
    width: '100%',
  },
  button: {
    background: theme.palette.secondary.light,
    color: 'black',
    height: '48px',
    justifyContent: 'center',
    width: '100%',
  },
  buttonText: {
    fontSize: 20,
  },
  link: {
    display: 'inline',
  },
}));
