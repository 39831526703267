import { makeRequestToApiGateway } from '../../apiUtils';

export const addCompanyPaymentTerms = async (): Promise<void> => {
  await makeRequestToApiGateway(
    'userPostV1CompanyCarrierTerms',
    null,
    {
      'Content-Type': 'application/json',
    },
    {}
  );
};
