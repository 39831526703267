"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        alignItems: 'center',
        border: `2px solid #eaeaea`,
        borderRadius: theme.spacing(2),
        boxShadow: 'unset',
        display: 'flex',
        flexDirection: 'column',
        height: ({ height }) => {
            const baseWidth = theme.spacing(32) * height;
            const spacingAdj = theme.spacing(2) * (height - 1);
            return `${baseWidth + spacingAdj}px`;
        },
        justifyContent: ({ topAlignment }) => (topAlignment ? 'flex-start' : 'center'),
        maxWidth: 'inherit',
        overflow: 'hidden',
        position: 'relative',
        width: ({ width }) => {
            const baseWidth = theme.spacing(32) * width;
            const spacingAdj = theme.spacing(2) * (width - 1);
            return `${baseWidth + spacingAdj}px`;
        },
    },
    title: {
        background: `#eaeaea`,
        borderRadius: `0`,
        fontSize: '0.7rem',
        left: 0,
        padding: theme.spacing(0.5),
        position: 'absolute',
        right: 0,
        textAlign: 'center',
        top: 0,
    },
}));
