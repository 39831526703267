import { useDispatch, useSelector } from 'react-redux';
import { EUserTypeV1 } from '@truxweb/schemas';
import { getQuoteCommentsById } from '../../../actions';
import { selectQuoteCommentsByCarrierQuoteId } from '../../../selectors';
import { type TQuoteComments } from '../../../stores';
import { useEffect } from 'react';

export const useQuoteComments = (
  userType: EUserTypeV1,
  carrierQuoteId: number,
  shouldRefetch?: boolean,
  shouldMarkAsRead?: boolean
): TQuoteComments => {
  const dispatch = useDispatch();
  const quoteComments = useSelector(selectQuoteCommentsByCarrierQuoteId(carrierQuoteId));

  useEffect(() => {
    if (
      (!quoteComments.hasLoaded && !quoteComments.isLoading) ||
      (!quoteComments.isLoading && shouldRefetch)
    ) {
      dispatch(getQuoteCommentsById(userType, carrierQuoteId, shouldMarkAsRead));
    }
  }, [quoteComments, dispatch, shouldRefetch, carrierQuoteId, userType, shouldMarkAsRead]);

  return quoteComments;
};
