import { Box, Button, FlagOutlinedIcon, Grid, Typography } from '@truxweb/ux';
import { ENotificationTypeV1, TInAppNotificationV1 } from '@truxweb/schemas';
import React, { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { formatDistanceToNow } from 'date-fns';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TNotificationListItemProps = {
  isLinkDisabled: boolean;
  shouldDisplayLink: boolean;
  notification: TInAppNotificationV1;
  markAsRead?: (recipientDeliveryId: number) => Promise<void>;
};

const ICON_SIZE_PX = '60px';
const FLAG_ICON_PRIORITY_THRESHOLD = 5;
export const InAppNotificationListItem = ({
  isLinkDisabled,
  markAsRead,
  notification,
  shouldDisplayLink,
}: TNotificationListItemProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();

  const activateNotificationLink = useCallback(async (): Promise<void> => {
    const { message, notificationRecipientDeliveryId, notificationType } = notification;

    if (markAsRead) {
      // Mark as read
      await markAsRead(notificationRecipientDeliveryId);
    }

    if (ENotificationTypeV1.GENERIC !== notificationType) {
      // Navigate wherever the link you lead to
      if (message.linkUrl) {
        router.push({ pathname: `/${message.linkUrl}` });
      }
    }
  }, [markAsRead, notification, router]);

  const { dateCreated, message, priority } = notification;
  return (
    <>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        style={{ width: '100%' }}
      >
        <Grid item>
          <Grid alignContent="center" container direction="row">
            <Grid item>
              <Box mr={4}>
                <ErrorBoundary FallbackComponent={() => <></>}>
                  <Image
                    alt="notification-icon"
                    height={ICON_SIZE_PX}
                    layout="fixed"
                    src={message.iconUrl}
                    unoptimized
                    width={ICON_SIZE_PX}
                  />
                </ErrorBoundary>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography color="textPrimary">{message.text}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {t(`common:displayTimeDistance`, {
                      // FIXME localise this... maybe in @truxweb/utils?
                      timeDistance: formatDistanceToNow(new Date(dateCreated)),
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {priority >= FLAG_ICON_PRIORITY_THRESHOLD && (
            <FlagOutlinedIcon color="secondary" fontSize="small" />
          )}
        </Grid>
        {shouldDisplayLink && (
          <Grid item>
            <Button disabled={isLinkDisabled} onClick={activateNotificationLink}>
              <Typography color="primary">{message.linkDescription}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
