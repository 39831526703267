// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoyonDeliveryTypeV1 = void 0;
var DoyonDeliveryTypeV1;
(function (DoyonDeliveryTypeV1) {
    DoyonDeliveryTypeV1["RESIDENTIAL"] = "RESIDENTIAL";
    DoyonDeliveryTypeV1["COMMERCIAL"] = "COMMERCIAL";
})(DoyonDeliveryTypeV1 || (exports.DoyonDeliveryTypeV1 = DoyonDeliveryTypeV1 = {}));
