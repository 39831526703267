"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    dashboardMenu: {
        borderBottom: '2px solid #C4C4C4',
        marginBottom: '12px',
        minWidth: 'max-content',
    },
    link: {
        '&:hover': {
            borderBottom: `4px solid ${theme.palette.primary.light}`,
            color: theme.palette.primary.light,
            marginBottom: '-2px',
        },
        color: '#C4C4C4',
        padding: '10px 20px',
        position: 'relative',
    },
    linkText: {
        color: 'inherit !important',
    },
    selectedLink: {
        borderBottom: `4px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.light,
        marginBottom: '-2px',
        padding: '10px 20px',
        position: 'relative',
    },
}));
