import { Box, Card, CircularProgress, Grid, Typography } from '@truxweb/ux';
import React, { useMemo } from 'react';
import {
  type TCarrierLaneEquipmentV1,
  type TCarrierLaneV1,
  type TUpdateCarrierQuoteRequestV1,
} from '@truxweb/schemas';
import {
  useActionController,
  useCarrierData,
  useCarrierEquipmentData,
  useCarrierLaneByLaneId,
  useCompanyData,
} from '../../hooks';
import { LaneEditSidebar } from '@truxweb/common-components';
import { mapLaneEquipmentDataForManagement } from '@truxweb/utils';
import { updateLanes } from '../../actions';
import { useStyles } from './LaneEditSidebarWrapper.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TLaneEditSidebarWrapperProps = {
  carrierLaneId: number;
  carrierId: number;
  laneUpdateFromQuote?: TUpdateCarrierQuoteRequestV1 | null;
  additionalContent?: JSX.Element;
  handleClose: () => void;
  onEditSuccess?: () => void;
};

export const LaneEditSidebarWrapper = ({
  additionalContent,
  carrierId,
  carrierLaneId,
  handleClose,
  laneUpdateFromQuote,
  onEditSuccess,
}: TLaneEditSidebarWrapperProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const laneData = useCarrierLaneByLaneId(carrierLaneId);
  const company = useCompanyData();
  const carrier = useCarrierData();
  const equipment = useCarrierEquipmentData();
  const { action: handleUpdateLane, isLoading: isSaving } = useActionController({
    action: async (lane: TCarrierLaneV1) => {
      await updateLanes([{ ...lane, id: carrierLaneId }]);
      if (onEditSuccess) {
        onEditSuccess();
      }
      handleClose();
    },
    successMessage: t('common:laneUpdatedSuccessfully'),
  });

  const laneEquipment = useMemo(() => {
    if (!laneData[1] || !company[1] || !carrier[1]) return [];
    return mapLaneEquipmentDataForManagement(
      equipment[0],
      laneData[0].equipment || [],
      carrier[0],
      carrierLaneId
    );
  }, [equipment, laneData, carrier, carrierLaneId, company]);

  const equipmentToUpdate = useMemo(() => {
    if (!laneUpdateFromQuote) return [];

    const equipmentCharge = (laneUpdateFromQuote.charges || []).find((charge) => {
      return charge.chargeType === 'EQUIPMENT';
    });
    const equipmentIdToUpdate = equipmentCharge.chargeReferenceId;
    const updateEquipmentFlatRate = equipmentCharge.charge.modified.value;

    if (!equipmentIdToUpdate || !updateEquipmentFlatRate) return [];
    const recordIndex = laneEquipment.findIndex(({ equipmentId }) => {
      return equipmentIdToUpdate === equipmentId;
    });

    if (recordIndex < 0) return [];
    const updateEquipmentRecord: TCarrierLaneEquipmentV1 = Object.assign(
      {},
      laneEquipment[recordIndex]
    );
    updateEquipmentRecord.minimumFlatRate = Object.assign(
      {},
      updateEquipmentRecord.minimumFlatRate,
      {
        value: updateEquipmentFlatRate,
      }
    );
    updateEquipmentRecord.hasPendingUpdate = true;
    return [updateEquipmentRecord];
  }, [laneUpdateFromQuote, laneEquipment]);

  if (!laneData[1] || !company[1] || !carrier[1]) {
    return (
      <Card style={{ borderRadius: '16px', boxShadow: 'unset' }}>
        <Box p={3}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      </Card>
    );
  }

  return (
    <LaneEditSidebar
      carrier={carrier[0]}
      carrierLaneEquipment={laneEquipment}
      carrierName={company[0].name}
      explanation={
        <>
          {additionalContent}
          <Box className={classes.explanation} mt={Boolean(additionalContent) && 2} p={3}>
            <Typography>{t('common:carrierLaneUpdateExplanation')}</Typography>
          </Box>
        </>
      }
      isSaving={isSaving}
      key={carrierId}
      lane={laneData[0]}
      laneEquipmentToUpdate={equipmentToUpdate}
      onSubmitSuccess={handleUpdateLane}
      prompt={t('common:carrierLaneUpdatePrompt')}
      t={t}
    />
  );
};
