"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSushiIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M4.5,11c.067,1.293,7.938,1.291,8,0C12.433,9.707,4.562,9.709,4.5,11Zm7-6c.067,1.293,7.938,1.291,8,0C19.433,3.707,11.562,3.709,11.5,5ZM24,5v8c0,2.492-2.948,4.516-7,4.922C18.238,25.678.277,25.9,0,19V11C0,8.5,2.974,6.486,7,6.079,5.76-1.679,23.723-1.9,24,5ZM9,5a1.587,1.587,0,0,0,.461,1.032A10.661,10.661,0,0,1,15.329,8c3.742.088,6.726-1.558,6.671-3C21.577,1.057,9.419,1.06,9,5ZM2,11c.424,3.944,12.581,3.939,13,0C14.576,7.056,2.419,7.061,2,11Zm13,8V14.251c-2.963,2.292-10.037,2.292-13,0V19C2.422,22.943,14.582,22.939,15,19Zm7-6V8.251a10.868,10.868,0,0,1-5.188,1.686c.357.663.127,5.183.188,5.974C20.029,15.561,22,14.209,22,13Z" })));
exports.default = TruxwebSushiIcon;
