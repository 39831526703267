import { useAlerts, useErrorHandling } from '.';
import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TGenericAction<T, K> = (payload?: K) => Promise<T>;

type TWrappedAction<T, K> = {
  action: TGenericAction<T, K>;
  isLoading: boolean;
};

type TActionController<T, K> = {
  action: TGenericAction<T, K>;
  successMessage?: string;
  shouldSuppressSuccessMessage?: boolean;
  errorMessage?: string;
  errorHandler?: (payload?: K, error?: any) => void;
};

export const useActionController = <T, K>(
  request: TActionController<T, K>
): TWrappedAction<T, K> => {
  const { action, errorHandler, errorMessage, shouldSuppressSuccessMessage, successMessage } =
    request;
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [isLoading, setIsLoading] = useState(false);
  const errorHandling = useErrorHandling();
  const { addAlert } = useAlerts();

  const wrappedAction = useCallback(
    async (payload: K): Promise<T> => {
      let response: T | undefined;
      try {
        setIsLoading(true);
        response = await action(payload);
        if (!shouldSuppressSuccessMessage) {
          addAlert({
            message: successMessage || t('common:success'),
            severity: 'success',
          });
        }
        return response;
      } catch (err) {
        if (errorHandler) {
          errorHandler(payload, err);
        }
        errorHandling(err, errorMessage);
      } finally {
        setIsLoading(false);
      }
      return response;
    },
    [
      setIsLoading,
      action,
      errorHandling,
      successMessage,
      errorMessage,
      addAlert,
      errorHandler,
      shouldSuppressSuccessMessage,
      t,
    ]
  );

  return {
    action: wrappedAction,
    isLoading,
  };
};
