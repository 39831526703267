import {
  addAlert,
  type AppThunk,
  setHasRequestedServiceOnEmptyResultsData,
  setHasRequestedServiceOnEmptyResultsError,
  setHasRequestedServiceOnEmptyResultsLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { selectDataForEmptySearchTicket } from '../../../selectors';
import { TCreateShipperTicketForEmptySearchResultsRequestV1 } from '@truxweb/schemas';

// input: TCreateShipperTicketForEmptySearchResultsRequestV1
export const submitShipperTicketEmptySearch =
  (companyName: string, shipperUserEmail: string): AppThunk =>
  async (dispatch, getState) => {
    // STEP 10 -- Composing the input from the redux state i.e. `getState?.search`
    const input: TCreateShipperTicketForEmptySearchResultsRequestV1 = {
      ...selectDataForEmptySearchTicket(getState() as any),
      companyName,
      shipperUserEmail,
    };

    // STEP 20 -- Dispatching the request...
    try {
      dispatch(setHasRequestedServiceOnEmptyResultsData({ data: false }));
      dispatch(setHasRequestedServiceOnEmptyResultsLoading());
      await makeRequestToApiGateway(
        'hubspotPostV1FormByFormType',
        { formType: 'emptysearch' },
        {
          'Content-Type': 'application/json',
        },
        input
      );

      dispatch(setHasRequestedServiceOnEmptyResultsData({ data: true }));
    } catch (error) {
      dispatch(
        addAlert({
          message: error.message,
          severity: 'error',
        })
      );
      dispatch(setHasRequestedServiceOnEmptyResultsError({ error: error.message }));
    }
  };
