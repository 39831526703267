"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebArrowsAltIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "arrow-circle-down", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.731,11.356l-3.343-3.3A.816.816,0,0,0,19,8.638V11H13V5h2.363a.816.816,0,0,0,.58-1.389L12.644.27a.9.9,0,0,0-1.288,0l-3.3,3.342A.816.816,0,0,0,8.637,5H11v6H5V8.638a.816.816,0,0,0-1.388-.581l-3.343,3.3a.906.906,0,0,0,0,1.289l3.343,3.3A.816.816,0,0,0,5,15.363V13h6v6H8.637a.816.816,0,0,0-.58,1.389l3.3,3.342a.9.9,0,0,0,1.288,0l3.3-3.342A.816.816,0,0,0,15.363,19H13V13h6v2.363a.816.816,0,0,0,1.388.581l3.343-3.3A.906.906,0,0,0,23.731,11.356Z" })));
exports.default = TruxwebArrowsAltIcon;
