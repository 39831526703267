import { makeStyles, Theme } from '@truxweb/ux';

type TStyleVars = {
  isLoading: boolean;
};

export const useStyles = makeStyles<Theme, TStyleVars>((theme: Theme) => ({
  '@keyframes loadingRotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  animatedIcon: {
    animation: ({ isLoading }) => {
      if (!isLoading) return undefined;
      return `loadingRotation 3000ms ${theme.transitions.easing.easeInOut}`;
    },
  },
  button: {
    background: ({ isLoading }) => (isLoading ? theme.palette.grey[300] : '#EDF8FF'),
    border: 0,
    borderRadius: '100%',
    cursor: 'pointer',
    height: theme.spacing(3),
    position: 'relative',
    width: theme.spacing(3),
  },
  container: {
    width: '100%',
  },
  dashboardMenu: {
    borderBottom: '2px solid #C4C4C4',
    marginBottom: '12px',
  },
  icon: {
    fill: ({ isLoading }) => (isLoading ? theme.palette.grey[600] : theme.palette.primary.light),
    marginTop: '-1px',
    width: theme.spacing(2),
  },
  link: {
    '&:hover': {
      borderBottom: `4px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      marginBottom: '-2px',
    },
    color: '#C4C4C4',
    padding: '10px 20px',
    position: 'relative',
  },
  loader: {
    height: `${theme.spacing(2)}px !important`,
    width: `${theme.spacing(2)}px !important`,
  },
  selectedLink: {
    borderBottom: `4px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light,
    marginBottom: '-2px',
    padding: '10px 20px',
    position: 'relative',
  },
  tableContainer: {
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      borderBottom: () => `${theme.spacing(1)}px solid ${theme.palette.common.white}`,
      textOverflow: 'ellipsis',
    },
    '& .MuiDataGrid-root .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: `#EDF8FF`,
      },
      backgroundColor: `#F3F3F3 `,
      display: 'flex',
      width: 'fitContent',
    },
    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
      backgroundColor: `#EDF8FF`,
    },
  },
  title: {
    lineHeight: `${theme.spacing(2)}px`,
  },
}));
