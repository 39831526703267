// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanySizeV1 = void 0;
var CompanySizeV1;
(function (CompanySizeV1) {
    CompanySizeV1[1] = "1";
    CompanySizeV1[11] = "11";
    CompanySizeV1[51] = "51";
    CompanySizeV1[101] = "101";
    CompanySizeV1[1000] = "1000";
    CompanySizeV1[1001] = "1001";
})(CompanySizeV1 || (exports.CompanySizeV1 = CompanySizeV1 = {}));
