// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcommCompanyRoleV1 = void 0;
var EcommCompanyRoleV1;
(function (EcommCompanyRoleV1) {
    EcommCompanyRoleV1["SHIPPER_BROKER"] = "SHIPPER_BROKER";
    EcommCompanyRoleV1["INTERLINING_CARRIER"] = "INTERLINING_CARRIER";
    EcommCompanyRoleV1["PREFERRED_CARRIER"] = "PREFERRED_CARRIER";
})(EcommCompanyRoleV1 || (exports.EcommCompanyRoleV1 = EcommCompanyRoleV1 = {}));
