// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierIdentifierTypeV1 = void 0;
var CarrierIdentifierTypeV1;
(function (CarrierIdentifierTypeV1) {
    CarrierIdentifierTypeV1["DOT_NUMBER"] = "DOT_NUMBER";
    CarrierIdentifierTypeV1["MC_NUMBER"] = "MC_NUMBER";
    CarrierIdentifierTypeV1["P44_GLOBAL"] = "P44_GLOBAL";
    CarrierIdentifierTypeV1["P44_EU"] = "P44_EU";
    CarrierIdentifierTypeV1["SCAC"] = "SCAC";
    CarrierIdentifierTypeV1["SYSTEM"] = "SYSTEM";
})(CarrierIdentifierTypeV1 || (exports.CarrierIdentifierTypeV1 = CarrierIdentifierTypeV1 = {}));
