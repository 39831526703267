import { makeStyles, Theme } from '@truxweb/ux';

type HeaderStylesType = {
  isScrolled?: boolean;
};

export const useStyles = makeStyles<Theme, HeaderStylesType>((theme: Theme) => ({
  actions: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  container: () => ({
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
  }),
  containerFilled: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxHeight: 110,
    minWidth: 1440,
    padding: '32px 40px',
  },
  earlyAccess: {
    backgroundColor: theme.palette.primary.light,
    padding: '0px 16px !important',
  },
  header: {
    height: 'inherit',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.light,
    },
    color: theme.palette.primary.dark,
  },
  languageSwitcher: {
    background: '#FFF',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    display: 'flex',
  },
  link: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
    color: '#636363',
    fontSize: '18px',
    fontWeight: 'lighter',
    position: 'relative',
  },

  sectionLink: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
      color: theme.palette.primary.main,
    },
    color: '#636363',
    position: 'relative',
  },

  selectedSectionLink: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
    },
    color: theme.palette.primary.main,
    position: 'relative',
  },
}));
