import { makeStyles, Theme } from '@truxweb/ux';

type TTestimonialCardProps = {
  avatarSrc: string;
};

export const useStyles = makeStyles<Theme, TTestimonialCardProps>((theme: Theme) => ({
  avatar: {
    borderRadius: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(9),
      width: theme.spacing(9),
    },
  },
  container: {
    boxShadow: '0px 3.97504px 9.93759px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(24.375),
      padding: `${theme.spacing(3)}px ${theme.spacing(1.5)}px`,
      width: theme.spacing(26.5),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(24.375),
      padding: `${theme.spacing(3)}px ${theme.spacing(1.5)}px`,
      width: theme.spacing(26.5),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(33.375),
      padding: `${theme.spacing(3)}px ${theme.spacing(2.75)}px`,
      width: theme.spacing(25.75),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(19),
      padding: theme.spacing(4),
      width: theme.spacing(51),
    },
    zIndex: 5,
  },
  name: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
  stars: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
      maxWidth: theme.spacing(6),
    },
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '0.9375rem',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0.75),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
}));
