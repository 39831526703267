import { hasWindow } from '../../utils';
import TruxwebLogo from '../../public/assets/images/logos/TruxwebLogo.svg';
import { useRouter } from 'next/router';
import { useStyles } from './ResponsiveHeaderLogo.styles';

type TResponsiveHeaderLogoProps = {
  isDark: boolean;
};

export const ResponsiveHeaderLogo = ({ isDark }: TResponsiveHeaderLogoProps): JSX.Element => {
  const classes = useStyles({ isDark });
  const router = useRouter();

  const handleClick = () => {
    if (hasWindow()) {
      router.push('/');
    }
  };

  // Dark theme has a light logo and vice versa
  return <TruxwebLogo className={classes.truxwebLogo} onClick={handleClick} />;
};
