import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  character: {
    alignItems: 'center',
    border: `2px solid #0087CE`,
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.light,
    display: 'flex',
    fontSize: theme.spacing(3),
    height: theme.spacing(6),
    justifyContent: 'center',
    marginRight: theme.spacing(0),
    width: theme.spacing(6),
  },
  characterInactive: {
    backgroundColor: '#F3F3F3',
    border: '2px solid #F3F3F3',
    color: theme.palette.primary.light,
  },
  characterSelected: {
    border: `2px solid #0087CE`,
    color: theme.palette.primary.light,
  },
  container: {
    alignItems: 'flex-end',
    height: theme.spacing(6),
  },
}));
