"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionStyles = exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    popover: {
        backgroundColor: theme.palette.primary.dark,
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        right: '0 !important',
        width: '100%',
    },
}));
exports.useAccordionStyles = (0, __1.makeStyles)(() => ({
    root: {
        background: 'transparent',
        boxShadow: 'unset',
        fontFamily: 'Roboto',
        margin: '0px 0 0 0 !important',
        width: '100%',
    },
}));
