/* eslint-disable complexity */
import { AppBar, Box, Button, Grid, MenuIcon, useMediaQuery, useTheme } from '@truxweb/ux';
import {
  AuthenticatedHeaderLinks,
  DesktopHeaderLinks,
  HeaderButton,
  HeaderMeta,
  LanguageSwitcher,
  MobileHeaderMenu,
  PageLink,
  ResponsiveHeaderLogo,
} from '..';
import { useAuth, useAuthBoolean, useHeaderPalette, usePermissions } from '../../hooks';
import { useRef, useState } from 'react';
import { EPermissionV1 } from '@truxweb/schemas';
import Head from 'next/head';
import { pageIdToLocaleString } from '../../utils';
import { TPageId } from '../../types';
import { useStyles } from './ResponsiveHeader.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TResponsiveHeaderProps = {
  isScrolled: boolean;
  isTransparent?: boolean;
  pageId: TPageId;
};

export const ResponsiveHeader = ({
  isScrolled,
  isTransparent,
  pageId,
}: TResponsiveHeaderProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const palette = useHeaderPalette();
  const isAuthed = useAuthBoolean();
  const auth = useAuth();
  const classes = useStyles({ isMobile, isMobileMenuOpen, isScrolled, isTransparent, palette });
  const anchor = useRef();

  const [shipperPermission, haveShipperPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_SHIPPER_PAGE,
  ]);

  const [carrierPermission, haveCarrierPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_CARRIER_PAGE,
  ]);

  const handleOpenMobileHeaderMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsMobileMenuOpen(isOpen);
  };

  const getIsLogoColourDark = (): boolean => {
    if (isScrolled) {
      return palette === 'dark' ? true : false;
    } else if (isTransparent) {
      return true;
    }
    return palette === 'dark' ? false : true;
  };

  const [localeString, formattedPageId] = pageIdToLocaleString(pageId);

  let pageTitlePrefix = t(localeString);

  if (pageTitlePrefix === formattedPageId) {
    pageTitlePrefix = t('common:siteMap-default');
  }

  const pageTitle = pageId
    ? t('common:appTitle', { pageTitle: `${pageTitlePrefix} - ` })
    : t('common:appTitle', { pageTitle: '' });

  const desktopHeaderContent = (
    <>
      <Box>
        <DesktopHeaderLinks isScrolled={isScrolled} isTransparent={isTransparent} pageId={pageId} />
      </Box>

      <Grid className={classes.actions} item>
        <Grid alignItems="center" container direction="row" justifyContent="space-evenly">
          <Grid item>
            <AuthenticatedHeaderLinks buttonClass={classes.linkButton} iconClass={classes.link} />
          </Grid>
          {((!isAuthed && auth.hasLoaded) || auth.hasLoggedOut) && (
            <>
              <Grid item>
                <Box mr={2}>
                  <PageLink pageId={'login'}>
                    <HeaderButton
                      text={t('common:logIn')}
                      type="login"
                      variant={
                        palette === 'dark' || (isTransparent && !isScrolled) ? null : 'midnight'
                      }
                    />
                  </PageLink>
                </Box>
              </Grid>
              <Grid item>
                <PageLink pageId={'signup'}>
                  <HeaderButton text={t('common:signUp')} type="standard" />
                </PageLink>
              </Grid>
            </>
          )}
          <Grid item>
            <LanguageSwitcher pageId={pageId} textClassName={classes.languageSwitcher} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const hamburgerMenuIcon = (
    <Button
      aria-controls="customized-menu"
      aria-haspopup="true"
      name={`${ResponsiveHeader.name}-HamburgerMenu`}
      onClick={handleOpenMobileHeaderMenu}
    >
      <MenuIcon className={classes.hamburger} fontSize={isMobile ? 'medium' : 'large'} />
    </Button>
  );

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <HeaderMeta pageId={pageId} t={t} />
      </Head>
      <AppBar position={isTransparent ? 'fixed' : 'relative'}>
        <Box className={classes.header}>
          <Grid
            alignItems="center"
            className={classes.gridContainer}
            container
            justifyContent="space-between"
            ref={anchor}
          >
            <Box className={classes.logoWrapper}>
              {!(isMobile && isMobileMenuOpen) && (
                <ResponsiveHeaderLogo isDark={getIsLogoColourDark()} />
              )}
            </Box>
            {isDesktop && desktopHeaderContent}
            {!isDesktop && hamburgerMenuIcon}
          </Grid>

          <MobileHeaderMenu
            areCarrierPermissionsEvaluated={haveCarrierPermissionsEvaluated}
            areShipperPermissionsEvaluated={haveShipperPermissionsEvaluated}
            handleClick={handleOpenMobileHeaderMenu}
            hasCarrierPermission={carrierPermission}
            hasShipperPermission={shipperPermission}
            isMobileMenuOpen={isMobileMenuOpen}
            pageId={pageId}
            toggleDrawer={toggleDrawer}
          />
        </Box>
      </AppBar>
    </>
  );
};
