"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressForm = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const utils_1 = require("@truxweb/utils");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const DEFAULT_COUNTRY = 'CA';
const DEFAULT_PROVINCE = 'QC';
// FIXME: this is largely due to the optional loading of data, perhaps always load a stub
// eslint-disable-next-line complexity
const AddressForm = ({ addressName, areAddressLinesDisabled, areNamesDisabled, control, data, defaultCountryCode, isAddressLineTwoDisabled, isAddressNameShown, isFormDisabled, prefix, t, watch, }) => {
    const country = watch('country');
    const compareCountry = (0, react_1.useMemo)(() => {
        return country || (data === null || data === void 0 ? void 0 : data.country) || DEFAULT_COUNTRY;
    }, [country, data]);
    const [postalCodeRegex, setPostalCodeRegex] = (0, react_1.useState)(utils_1.POSTAL_CODE_REGEX);
    (0, react_1.useEffect)(() => {
        // Whenver the country is update, change the postal
        // code regex to be the appropriat validation
        // NOTE: Currently assumes only CA and US
        if (compareCountry === 'US') {
            setPostalCodeRegex(utils_1.ZIP_CODE_REGEX);
        }
        else {
            setPostalCodeRegex(utils_1.POSTAL_CODE_REGEX);
        }
    }, [compareCountry, setPostalCodeRegex]);
    /// FIXME: this is due to that region cannot be prefix holisitically at the moment
    const provincePrefix = prefix ? `${prefix}-` : '';
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", style: { marginTop: 12 } },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            isAddressNameShown && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Box, { mb: 2 },
                    react_1.default.createElement(__1.FormField, { InputLabelProps: {
                            shrink: true,
                        }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: addressName || '', id: `addressName`, inputProps: {
                            required: false,
                        }, isDisabled: isFormDisabled, isRequired: true, label: t('common:companyName'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })))),
            !areNamesDisabled && (react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between", style: { width: '100%' } },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mr: 2 },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: '', id: `firstName`, inputProps: {
                                    required: false,
                                }, isDisabled: isFormDisabled, isRequired: true, label: t('common:firstName'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { ml: 2 },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: '', id: `lastName`, inputProps: {
                                    required: false,
                                }, isDisabled: isFormDisabled, isRequired: true, label: t('common:lastName'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }))))))),
        !areAddressLinesDisabled && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (data === null || data === void 0 ? void 0 : data.addressLineOne) || '', id: `addressLineOne`, inputProps: {
                        required: false,
                    }, isDisabled: isFormDisabled, isRequired: true, label: t('common:addressLineOne'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })))),
        !areAddressLinesDisabled && !isAddressLineTwoDisabled && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (data === null || data === void 0 ? void 0 : data.addressLineTwo) || '', id: `addressLineTwo`, inputProps: {
                        required: false,
                    }, isDisabled: isFormDisabled, label: t('common:addressLineTwo'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (data === null || data === void 0 ? void 0 : data.city) || '', id: "city", inputProps: {
                        required: false,
                    }, isDisabled: isFormDisabled, isRequired: true, label: t('common:city'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: 'alphanumeric-only' }))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (data === null || data === void 0 ? void 0 : data.postalCode) || '', id: `postalCode`, inputProps: {
                        required: false,
                    }, isDisabled: isFormDisabled, isRequired: true, label: t('common:postalCode'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, validationRules: {
                        pattern: postalCodeRegex,
                    } }))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (data === null || data === void 0 ? void 0 : data.province) || DEFAULT_PROVINCE, name: `${provincePrefix}province`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        if (compareCountry === 'CA' || !compareCountry) {
                            return (react_1.default.createElement(__1.RegionSelect, { country: 'CA', hasError: Boolean(error), isDisabled: isFormDisabled, onChange: onChange, t: t, value: value || DEFAULT_PROVINCE, variant: 'outlined' }));
                        }
                        return (react_1.default.createElement(__1.RegionSelect, { country: 'US', hasError: Boolean(error), isDisabled: isFormDisabled, onChange: onChange, t: t, value: value, variant: 'outlined' }));
                    } }))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                    shrink: true,
                }, control: control, defaultCountryCode: defaultCountryCode, defaultValue: (data === null || data === void 0 ? void 0 : data.country) || DEFAULT_COUNTRY, id: `country`, inputProps: {
                    required: false,
                }, isDisabled: isFormDisabled, isRequired: true, label: t('common:country'), prefix: prefix, shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "country" }))));
};
exports.AddressForm = AddressForm;
