"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDreidelIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.707.293a1,1,0,0,0-1.414,0L14.96,7.626,13.8,6.465a5.008,5.008,0,0,0-7.067-.006L1.465,11.647A4.973,4.973,0,0,0,0,15.184V19a5.006,5.006,0,0,0,5,5H8.816a4.977,4.977,0,0,0,3.542-1.47l5.177-5.258a5.006,5.006,0,0,0,0-7.071L16.374,9.04l7.333-7.333A1,1,0,0,0,23.707.293ZM2,19V15.86A6.47,6.47,0,0,0,5.442,17a8.05,8.05,0,0,0,1.732-.182A7.905,7.905,0,0,0,7,18.5,6.6,6.6,0,0,0,8.15,22H5A3,3,0,0,1,2,19Zm14.115-3.137-5.177,5.258a3.008,3.008,0,0,1-.585.457A5.044,5.044,0,0,1,9,18.5a4.386,4.386,0,0,1,1.128-3.214l2.579-2.579a1,1,0,0,0-1.414-1.414L8.714,13.872A4.552,4.552,0,0,1,5.442,15a4.849,4.849,0,0,1-3.023-1.349,3,3,0,0,1,.455-.584L8.142,7.879a3,3,0,0,1,4.243,0l3.736,3.736A3,3,0,0,1,16.115,15.863Z" })));
exports.default = TruxwebDreidelIcon;
