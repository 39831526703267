import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  titleBackground: {
    borderRadius: `10px 0 10px 0`,
    left: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,

    position: 'absolute',
    top: 0,
  },
}));
