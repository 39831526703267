"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TailGateAIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 42 28", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M8.09188 19.4557L6.80145 24.8993L0.833827 24.8993", stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("path", { d: "M41.25 12.9773V19.9318H38.8182H38.0682V20.6818C38.0682 22.9085 36.2721 24.7045 34.0455 24.7045C31.8188 24.7045 30.0227 22.9085 30.0227 20.6818V19.9318H29.2727H19.7273H18.9773V20.6818C18.9773 22.9085 17.1812 24.7045 14.9545 24.7045C12.7278 24.7045 10.9318 22.9085 10.9318 20.6818V19.9318H10.1818H7.75V3.18182C7.75 1.84603 8.84603 0.75 10.1818 0.75H31.7045V6.36364V7.11364H32.4545H36.8523L41.25 12.9773ZM37.022 8.28726L36.7968 8H36.4318H32.4545H31.7045V8.75V12.7273V13.4773H32.4545H39.55H41.091L40.1402 12.2645L37.022 8.28726ZM11.8182 20.6818C11.8182 22.4165 13.2199 23.8182 14.9545 23.8182C16.6892 23.8182 18.0909 22.4165 18.0909 20.6818C18.0909 18.9471 16.6892 17.5455 14.9545 17.5455C13.2199 17.5455 11.8182 18.9471 11.8182 20.6818ZM30.9091 20.6818C30.9091 22.4165 32.3108 23.8182 34.0455 23.8182C35.7801 23.8182 37.1818 22.4165 37.1818 20.6818C37.1818 18.9471 35.7801 17.5455 34.0455 17.5455C32.3108 17.5455 30.9091 18.9471 30.9091 20.6818Z", stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("path", { d: "M20.8877 6.37305L17.9141 15H16.1162L19.8623 5.04688H21.0107L20.8877 6.37305ZM23.376 15L20.3955 6.37305L20.2656 5.04688H21.4209L25.1807 15H23.376ZM23.2324 11.3086V12.6689H17.8184V11.3086H23.2324Z", fill: "#224882", strokeWidth: 1.5 })));
exports.default = TailGateAIcon;
