// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeUnitOfMeasureV1 = exports.CurrencyV1 = exports.HubspotCompanyTypeV1 = void 0;
var HubspotCompanyTypeV1;
(function (HubspotCompanyTypeV1) {
    HubspotCompanyTypeV1["Shipper"] = "Shipper";
    HubspotCompanyTypeV1["Carrier"] = "Carrier";
})(HubspotCompanyTypeV1 || (exports.HubspotCompanyTypeV1 = HubspotCompanyTypeV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var RangeUnitOfMeasureV1;
(function (RangeUnitOfMeasureV1) {
    RangeUnitOfMeasureV1["mi"] = "mi";
    RangeUnitOfMeasureV1["km"] = "km";
})(RangeUnitOfMeasureV1 || (exports.RangeUnitOfMeasureV1 = RangeUnitOfMeasureV1 = {}));
