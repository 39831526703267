"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTreeChristmasIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.44,16.855l-1.481-2.221a2.214,2.214,0,0,0,.619-3.072L17.487,8.427a2.234,2.234,0,0,0,.456-.58,2.21,2.21,0,0,0-.084-2.256,1.23,1.23,0,0,0-.081-.109L13.678.7a2.38,2.38,0,0,0-3.4.051L6.206,5.478a.912.912,0,0,0-.081.107,2.212,2.212,0,0,0,.38,2.853L4.422,11.562a2.214,2.214,0,0,0,.619,3.072L3.56,16.855A3.309,3.309,0,0,0,6.312,22H11v1a1,1,0,0,0,2,0V22h4.687a3.309,3.309,0,0,0,2.753-5.145Zm-1.6,2.454A1.292,1.292,0,0,1,17.687,20H6.312a1.309,1.309,0,0,1-1.088-2.035L7.5,14.555A1,1,0,0,0,6.666,13H6.261a.2.2,0,0,1-.186-.111.2.2,0,0,1,.011-.217L8.831,8.555a1,1,0,0,0,.04-1.027A1.017,1.017,0,0,0,7.979,7a.2.2,0,0,1-.186-.11.2.2,0,0,1-.011-.178l3.962-4.6a.327.327,0,0,1,.464-.052L16.2,6.713a.2.2,0,0,1-.011.178A.2.2,0,0,1,16,7a1,1,0,0,0-.832,1.555l2.745,4.117a.2.2,0,0,1,.011.217.2.2,0,0,1-.186.111h-.405a1,1,0,0,0-.832,1.555l2.274,3.41A1.289,1.289,0,0,1,18.841,19.309Z" }),
    React.createElement("circle", { cx: 9, cy: 17, r: 1 }),
    React.createElement("circle", { cx: 14, cy: 17, r: 1 }),
    React.createElement("circle", { cx: 11, cy: 13, r: 1 }),
    React.createElement("circle", { cx: 13, cy: 10, r: 1 }),
    React.createElement("circle", { cx: 11, cy: 7, r: 1 })));
exports.default = TruxwebTreeChristmasIcon;
