"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGlassCheersIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.955,21.319a1,1,0,0,0-1.252-.658l-1.3.4-.931-2.737A4.953,4.953,0,0,0,22.8,12.607L20.957,7.132A3.023,3.023,0,0,0,17.28,5.11L13.428,6.184A2.966,2.966,0,0,0,12,7.078a2.966,2.966,0,0,0-1.428-.894L6.719,5.11A3.008,3.008,0,0,0,3.052,7.1L1.185,12.657a4.956,4.956,0,0,0,2.364,5.682l-.944,2.728L1.3,20.661A1,1,0,1,0,.7,22.571l2.16.671c.015,0,.024.017.039.023s.052.006.078.013l2.181.677a1,1,0,0,0,.594-1.91L4.516,21.66l.935-2.7A4.982,4.982,0,0,0,6.01,19,5,5,0,0,0,10.8,15.392L12,11.836l1.183,3.5A5,5,0,0,0,17.99,19a4.917,4.917,0,0,0,.581-.04l.918,2.7-1.244.386a1,1,0,0,0,.594,1.91l2.175-.675c.026-.007.053,0,.079-.013s.026-.019.042-.025l2.162-.671A1,1,0,0,0,23.955,21.319ZM13.357,8.592a.989.989,0,0,1,.608-.482l3.851-1.073A1,1,0,0,1,18.08,7a1.023,1.023,0,0,1,.972.739L19.813,10H13.49l-.215-.637A1,1,0,0,1,13.357,8.592ZM5.918,7a1.007,1.007,0,0,1,.265.036L10.035,8.11a1,1,0,0,1,.7,1.223L10.51,10H4.187l.77-2.29A1.008,1.008,0,0,1,5.918,7Zm2.974,7.8a3,3,0,0,1-5.5.669A2.949,2.949,0,0,1,3.1,13.245L3.514,12H9.836Zm7.632,1.808a3.022,3.022,0,0,1-1.431-1.859L14.164,12h6.323l.4,1.194a3,3,0,0,1-4.366,3.418Z" }),
    React.createElement("path", { d: "M15.553,3.9A1,1,0,0,0,16.9,3.447l1-2a1,1,0,1,0-1.79-.894l-1,2A1,1,0,0,0,15.553,3.9Z" }),
    React.createElement("path", { d: "M7.105,3.447A1,1,0,1,0,8.9,2.553l-1-2a1,1,0,0,0-1.79.894Z" }),
    React.createElement("path", { d: "M12,4a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V3A1,1,0,0,0,12,4Z" })));
exports.default = TruxwebGlassCheersIcon;
