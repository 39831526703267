import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  '.MuiInputBase-root:has(> input:-webkit-autofill)': {
    backgroundColor: 'blue',
  },
  align: {
    alignItems: 'center',
    display: 'flex',
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    height: theme.spacing(59),
    maxWidth: theme.spacing(62),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 1rem',
    },
  },
  fitContainer: {
    width: '100%',
  },
  fitContent: {
    width: 'fit-content',
  },
  itemBox: {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    height: theme.spacing(5),
  },
  linkFont: {
    fontFamily: 'Roboto !important',
  },
  primaryFont: {
    color: `${theme.palette.primary.light} !important`,
  },
  submitButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: theme.spacing(6),
  },
}));
export const helperTextStyles = makeStyles<Theme>((theme: Theme) => ({
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  root: {
    color: theme.palette.error.main,
  },
}));
