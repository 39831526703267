"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPaintBrushIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m23.1.9a3.139 3.139 0 0 0 -4.33 0l-11.207 11.2a5.548 5.548 0 0 0 -1.058-.1 5.457 5.457 0 0 0 -3.885 1.609c-1.352 1.353-2.306 4.983-2.589 6.954a3 3 0 0 0 2.969 3.437 3.1 3.1 0 0 0 .439-.031c1.971-.283 5.6-1.237 6.954-2.589a5.494 5.494 0 0 0 1.5-4.941l11.207-11.209a3.068 3.068 0 0 0 0-4.33zm-14.123 19.066c-.725.725-3.5 1.689-5.824 2.023a1.015 1.015 0 0 1 -.859-.283 1 1 0 0 1 -.282-.859c.333-2.323 1.3-5.1 2.022-5.824a3.5 3.5 0 0 1 4.943 4.943zm12.711-16.15-10.621 10.622a5.378 5.378 0 0 0 -1.5-1.508l10.617-10.618a1.086 1.086 0 0 1 1.5 0 1.062 1.062 0 0 1 .004 1.504z" })));
exports.default = TruxwebPaintBrushIcon;
