"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountDetails = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
//FIX ME -- ADD US SUPPORT
// JIRA ticket logged to fix this here: https://truxweb.atlassian.net/browse/TP-1123
const SUPPORTED_ACCOUNT_TYPES = { Canadian: schemas_1.EBankAcctTypeV1.Canadian };
// NOTE: disabled due to boolean evals to restore form data
// eslint-disable-next-line complexity
const BankAccountDetails = ({ control, data, defaultCountryCode, handleUploadFailure, isDirty, isFormDisabled, isUploading, onUploadFile, proofOfOwnership, setError, t, }) => {
    const [bankAccountType, setBankAccountType] = (0, react_1.useState)(data ? data.type : schemas_1.EBankAcctTypeV1.Canadian);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Box, { mb: 2 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (data === null || data === void 0 ? void 0 : data.type) || schemas_1.EBankAcctTypeV1.Canadian, name: "accountType", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (react_1.default.createElement(__1.EnumSelect, { hasError: Boolean(error), helperText: error === null || error === void 0 ? void 0 : error.message, isDisabled: isFormDisabled, label: t('account:bankAccountType'), onChange: (event) => {
                                                setBankAccountType(event.target.value);
                                                onChange(event);
                                            }, placeholder: t('common:selectType'), shouldUseModernVariant: true, sourceEnum: SUPPORTED_ACCOUNT_TYPES, t: t, value: value || schemas_1.EBankAcctTypeV1.Canadian }));
                                    }, rules: { required: true } })),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 })))),
                bankAccountType === schemas_1.EBankAcctTypeV1.Canadian && (react_1.default.createElement(__1.CanadianBankAccount, { control: control, data: data, defaultCountryCode: defaultCountryCode, isFormDisabled: isFormDisabled || false, t: t })),
                bankAccountType !== schemas_1.EBankAcctTypeV1.Canadian && (react_1.default.createElement(__1.AmericanBankAccount, { control: control, data: data, defaultCountryCode: defaultCountryCode, isFormDisabled: isFormDisabled || false, t: t }))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true }, (data === null || isDirty || proofOfOwnership) && (react_1.default.createElement(ux_1.Box, { mt: 3.25 },
                react_1.default.createElement(ux_1.Box, { mb: 2 },
                    react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t('common:proofOfOwnership')),
                    react_1.default.createElement(ux_1.Typography, { variant: "body1" }, t('common:proofOfOwnerShipDesc'))),
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: proofOfOwnership || '', name: "proofOfOwnership", render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        return (react_1.default.createElement(__1.FileListAndUpload, { hasError: Boolean(error), isFormDisabled: false, isUploading: isUploading, onChange: onChange, onError: (errorMessage) => {
                                handleUploadFailure(errorMessage);
                                setError('proofOfOwnership', { message: errorMessage, type: 'manual' });
                            }, onUploadFile: onUploadFile, t: t, value: value }));
                    }, rules: { required: true } })))))));
};
exports.BankAccountDetails = BankAccountDetails;
