"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBalloonsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18.848,11.53A7.674,7.674,0,0,0,19.977,8a1,1,0,0,0-2,0,5.712,5.712,0,0,1-.825,2.47,1,1,0,0,0,1.7,1.06Z" }),
    React.createElement("path", { d: "M8.977,7a1,1,0,0,0-1,1,5.712,5.712,0,0,1-.825,2.47,1,1,0,0,0,1.7,1.06A7.674,7.674,0,0,0,9.977,8,1,1,0,0,0,8.977,7Z" }),
    React.createElement("path", { d: "M19.5,18.589c-.682-.362-1.386-.737-1.484-1.162-.046-.193-.025-.628.515-1.473C21.7,15.5,24,11.025,24,7a5.8,5.8,0,0,0-6-6,5.862,5.862,0,0,0-4.955,2.487A6.994,6.994,0,0,0,0,7c0,3.971,2.257,8.485,5.39,9.685A2.822,2.822,0,0,0,5,17.87c0,1.571,1.489,2.267,2.576,2.776.6.28,1.424.665,1.424.963a2.291,2.291,0,0,1-.428.851,1,1,0,0,0,1.684,1.08A3.745,3.745,0,0,0,11,21.609c0-1.571-1.489-2.267-2.576-2.775-.6-.281-1.424-.666-1.433-.865a5.379,5.379,0,0,1,.568-1.016c2.352-.306,4.331-2.5,5.452-5.2a7.37,7.37,0,0,0,3.413,3.892,3.381,3.381,0,0,0-.356,2.238c.306,1.31,1.519,1.955,2.494,2.474.616.328,1.313.7,1.419,1.068a1.627,1.627,0,0,1-.315,1.078,1,1,0,1,0,1.732,1,3.329,3.329,0,0,0,.506-2.627A4.166,4.166,0,0,0,19.5,18.589ZM7,15c-2.646,0-5-4.474-5-8A5,5,0,0,1,12,7C12,10.526,9.646,15,7,15Zm7-8a3.792,3.792,0,0,1,4-4,3.792,3.792,0,0,1,4,4c0,3.4-2.056,7-4,7S14,10.4,14,7Z" })));
exports.default = TruxwebBalloonsIcon;
