"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchAccessorials = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const react_hook_form_1 = require("react-hook-form");
const SearchAccessorials_styles_1 = require("./SearchAccessorials.styles");
const construcAccessorialListItem = (accessorialId, code, id) => {
    if (accessorialId && code && id)
        return {
            accessorialId,
            code,
            id,
        };
    return {
        accessorialId: null,
        code: '',
        id: null,
    };
};
const constructAccessorialMetadataListItem = (accessorialId, value) => {
    if (accessorialId)
        return {
            accessorialId,
            value: value || null,
        };
    return {
        accessorialId: null,
        value: null,
    };
};
const SearchAccessorials = ({ accessorialFormControls, accessorialRecords, availableAccessorials, control, fields, metadataFormControls, setValue, t, values, variant, }) => {
    const classes = (0, SearchAccessorials_styles_1.useStyles)();
    const [totalAccessorialPickers, setTotalAccessorialPickers] = (0, react_1.useState)(1);
    const [totalAvailableAccessorials, setTotalAvailableAccessorials] = (0, react_1.useState)((availableAccessorials === null || availableAccessorials === void 0 ? void 0 : availableAccessorials.length) || 0);
    const handleDelete = (0, react_1.useCallback)((index) => () => {
        if (((fields === null || fields === void 0 ? void 0 : fields.length) || 0) > 1) {
            accessorialFormControls.remove(index);
            metadataFormControls.remove(index);
            setTotalAccessorialPickers((prev) => prev - 1);
        }
        else if (((fields === null || fields === void 0 ? void 0 : fields.length) || 0) === 1) {
            accessorialFormControls.update(0, construcAccessorialListItem());
            metadataFormControls.update(0, constructAccessorialMetadataListItem());
        }
    }, [fields, accessorialFormControls, metadataFormControls]);
    const createAccessorialPicker = (0, react_1.useCallback)(() => {
        if (totalAccessorialPickers < totalAvailableAccessorials) {
            accessorialFormControls.append(construcAccessorialListItem());
            metadataFormControls.append(constructAccessorialMetadataListItem());
            setTotalAccessorialPickers((prev) => prev + 1);
        }
    }, [
        accessorialFormControls,
        totalAccessorialPickers,
        totalAvailableAccessorials,
        metadataFormControls,
    ]);
    const handlePickerChange = (0, react_1.useCallback)((event, index, metadataValue) => {
        const currentSelection = construcAccessorialListItem(accessorialRecords[event.target.value].id, event.target.value, event.target.name);
        const currentMetadataSelectionChange = constructAccessorialMetadataListItem(accessorialRecords[event.target.value].id, metadataValue);
        accessorialFormControls.update(index, currentSelection);
        metadataFormControls.update(index, currentMetadataSelectionChange);
    }, [accessorialRecords, accessorialFormControls, metadataFormControls]);
    //Calculate what accessorial Options to display and which fields to remove
    const [accessorialsToDisplay] = (0, react_1.useMemo)(() => {
        //Get a list of available Accessorial codes.  If selectedValue is not
        //included in this list we need to remove the accessorialPicker from the UX
        const availableAccessorialCodes = (availableAccessorials || []).map((accessorial) => {
            var _a;
            return (_a = accessorialRecords[accessorial]) === null || _a === void 0 ? void 0 : _a.code;
        });
        //List of selected Accessorials is used to determine which ListOptions to disable
        //Disable listOption from all pickers if it has already been selected
        const selectedValues = (fields === null || fields === void 0 ? void 0 : fields.length) > 0
            ? fields
                .map((accessorial) => {
                return accessorial.code;
            })
                .filter((accessorial) => accessorial)
            : [];
        //set Accessorials to Display
        const accessorialsToDisplay = (availableAccessorials || [])
            .filter((accessorial) => {
            return (accessorialRecords[accessorial] &&
                accessorialRecords[accessorial].visibleToShipper &&
                accessorial !== schemas_1.EAccessorialOptionV1.CARGO_INSURANCE);
        })
            .map((feature) => {
            return Object.assign(Object.assign({}, accessorialRecords[feature]), { disabled: selectedValues.includes(accessorialRecords[feature].code) ? true : false });
        });
        //End Accessorials to display
        //Set fields to remove
        //We cannot leverage the field.Id because the native `useFieldArray`
        //update method will mount/unmount the `field` which instantiates a new id on each change
        const fieldsToRemove = selectedValues.length > 0
            ? (fields || [])
                .map((field, index) => {
                const code = field.code;
                return { code, index };
            })
                .filter((accessorial) => {
                return accessorial.code && !availableAccessorialCodes.includes(accessorial.code);
            })
                .map((field) => {
                return field.index;
            })
            : [];
        //remove invalid fields from fieldArray.
        //if the array is emptied, a new field will be
        //instantiated after the page render
        if (fieldsToRemove.length > 0) {
            accessorialFormControls.remove(fieldsToRemove);
            metadataFormControls.remove(fieldsToRemove);
        }
        //End Fields to remove
        return [accessorialsToDisplay];
    }, [
        accessorialRecords,
        availableAccessorials,
        fields,
        accessorialFormControls,
        metadataFormControls,
    ]);
    (0, react_1.useEffect)(() => {
        setTotalAvailableAccessorials((accessorialsToDisplay === null || accessorialsToDisplay === void 0 ? void 0 : accessorialsToDisplay.length) || 0);
        if ((fields === null || fields === void 0 ? void 0 : fields.length) === 0) {
            accessorialFormControls.append(construcAccessorialListItem());
            metadataFormControls.append(constructAccessorialMetadataListItem());
        }
    }, [
        accessorialsToDisplay,
        accessorialFormControls,
        availableAccessorials,
        fields,
        totalAccessorialPickers,
        metadataFormControls,
    ]);
    return (react_1.default.createElement(ux_1.Grid, null,
        react_1.default.createElement(ux_1.Container, { className: classes.accessorialContainer },
            react_1.default.createElement(ux_1.Box, { className: classes.accessorialRow }, (fields || []).map((field, index) => (react_1.default.createElement(ux_1.Zoom, { in: true, key: index },
                react_1.default.createElement(ux_1.Box, { className: classes.accessorialItem, key: field.id },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, key: field.id, name: `accessorials.${index}.picker`, render: ({ field: { onChange } }) => {
                            return (react_1.default.createElement(__1.AccessorialPickerCard, { accessorials: accessorialsToDisplay, deleteHandler: handleDelete(index), handlePickerChange: handlePickerChange, hasError: false, index: index, name: field.id || '', onChangeHandler: onChange, setValue: setValue, t: t, testId: 'SearchAccessorials', value: field.code || '', values: values, variant: variant }));
                        } }))))))),
        react_1.default.createElement(ux_1.Container, { className: classes.accessorialPicker },
            react_1.default.createElement(ux_1.Box, null,
                react_1.default.createElement(ux_1.Button, { className: classes.button, "data-testid": `SearchAccessorials-Button`, onClick: createAccessorialPicker, startIcon: react_1.default.createElement(ux_1.AddCircleOutlineIcon, null), variant: "text" },
                    react_1.default.createElement(ux_1.Typography, { className: classes.buttonText }, t(`search:addAccessorial`)))))));
};
exports.SearchAccessorials = SearchAccessorials;
