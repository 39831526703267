// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalUserPermissionV1 = void 0;
var InternalUserPermissionV1;
(function (InternalUserPermissionV1) {
    InternalUserPermissionV1["MANAGE_CARRIERS"] = "MANAGE_CARRIERS";
    InternalUserPermissionV1["MANAGE_SHIPPERS"] = "MANAGE_SHIPPERS";
    InternalUserPermissionV1["MANAGE_SHIPMENTS"] = "MANAGE_SHIPMENTS";
    InternalUserPermissionV1["MANAGE_ACCOUNTING"] = "MANAGE_ACCOUNTING";
    InternalUserPermissionV1["MANAGE_INTERNAL_USERS"] = "MANAGE_INTERNAL_USERS";
    InternalUserPermissionV1["VIEW_CARRIERS"] = "VIEW_CARRIERS";
    InternalUserPermissionV1["VIEW_SHIPPERS"] = "VIEW_SHIPPERS";
    InternalUserPermissionV1["VIEW_SHIPMENTS"] = "VIEW_SHIPMENTS";
    InternalUserPermissionV1["VIEW_ACCOUNTING"] = "VIEW_ACCOUNTING";
    InternalUserPermissionV1["VIEW_INTERNAL_USERS"] = "VIEW_INTERNAL_USERS";
    InternalUserPermissionV1["DELETE_UNVERIFIED_USER"] = "DELETE_UNVERIFIED_USER";
    InternalUserPermissionV1["MULTIPLE_QUOTE_EDITS"] = "MULTIPLE_QUOTE_EDITS";
})(InternalUserPermissionV1 || (exports.InternalUserPermissionV1 = InternalUserPermissionV1 = {}));
