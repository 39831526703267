import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierLaneV1 } from '@truxweb/schemas';

export const getCarrierLaneData = async (): Promise<TCarrierLaneV1[]> => {
  const results = await makeRequestToApiGateway('carrierGetV1LaneCarrier', null, {
    'Content-Type': 'application/json',
  });

  return results as TCarrierLaneV1[];
};
