"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    closeIcon: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        alignItems: 'center',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '100%',
        display: ({ isOpen }) => {
            return isOpen ? 'flex' : 'none';
        },
        height: theme.spacing(4),
        justifyContent: 'center',
        left: theme.spacing(43),
        maxHeight: theme.spacing(4),
        maxWidth: theme.spacing(4),
        minWidth: theme.spacing(4),
        padding: 0,
        position: 'fixed',
        top: theme.spacing(5.25),
        width: theme.spacing(4),
        zIndex: 2,
    },
    icon: {
        color: theme.palette.common.white,
    },
    wrapper: {
        background: theme.palette.common.white,
        height: '100%',
        minHeight: '100%',
        position: 'relative',
        zIndex: 1,
    },
}));
