"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebEclipseIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M12,24a1,1,0,0,1-1-1V18.921a6.829,6.829,0,0,1-1.623-.435L7.33,22.007A1,1,0,0,1,5.6,21l2.049-3.525a7.092,7.092,0,0,1-1.128-1.13L3.01,18.391A1,1,0,0,1,2,16.662l3.51-2.043A6.922,6.922,0,0,1,5.072,13H1a1,1,0,0,1,0-2H5.072a6.922,6.922,0,0,1,.445-1.626L2,7.326A1,1,0,0,1,3,5.6L6.528,7.649A7.137,7.137,0,0,1,7.671,6.507L5.627,2.992A1,1,0,1,1,7.355,1.986L9.4,5.5a6.9,6.9,0,0,1,1.609-.431L11,1a1,1,0,0,1,2,0V5.079A2,2,0,0,1,11.29,7.05,5.019,5.019,0,0,0,7,12c0,3.538,3.728,4.87,4.289,4.95A2,2,0,0,1,13,18.921V23A1,1,0,0,1,12,24Zm6-6c-7.929-.252-7.928-11.749,0-12C25.929,6.252,25.928,17.749,18,18ZM18,8c-5.275.138-5.274,7.863,0,8C23.275,15.862,23.274,8.137,18,8Z" })));
exports.default = TruxwebEclipseIcon;
