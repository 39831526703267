import {
  type AppThunk,
  setSearchFormParamsData,
  setSearchFormParamsError,
  setSearchFormParamsLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const fetchSearchFormData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setSearchFormParamsLoading());
    const results = await makeRequestToApiGateway('carrierGetV1EquipmentParams', null, {
      'Content-Type': 'application/json',
    });

    dispatch(setSearchFormParamsData({ data: results }));
  } catch (error) {
    dispatch(setSearchFormParamsError({ error: error.message }));
  }
};
