import {
  AWS_REGION,
  CARRIER_DATA_BUCKET,
  IDENTITY_POOL_ID,
  USER_POOLS_ID,
  WEB_CLIENT_ID,
} from './env';
import { ApiServices } from './apiGateway';

export const AWS_CONFIG = {
  API: {
    endpoints: Object.keys(ApiServices).map((serviceName) => {
      return {
        endpoint: ApiServices[serviceName],
        name: serviceName,
      };
    }),
  },
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: USER_POOLS_ID,
    userPoolWebClientId: WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: CARRIER_DATA_BUCKET,
      region: AWS_REGION,
    },
  },
  ssr: true,
};
