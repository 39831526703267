import { type AppState, baseLoadingState, type ISearchDataState } from '../../stores';

export const selectSavedFormData = (state: AppState): ISearchDataState['savedFormData'] => {
  const searchFormData = state?.search?.savedFormData || {
    ...baseLoadingState,
    shouldForceRefresh: false,
  };
  const accessorials: any[] = [];
  const accessorialMetadata: any[] = [];
  (searchFormData?.data?.accessorials || []).forEach((record: any) => {
    if (record.accessorialId) {
      accessorials.push(record);
    }
  });
  (searchFormData?.data?.accessorialMetadata || []).forEach((record: any) => {
    if (record.accessorialId) {
      accessorialMetadata.push(record);
    }
  });

  if (!searchFormData?.data) return searchFormData;
  return {
    ...searchFormData,
    data: {
      ...searchFormData.data,
      accessorialMetadata,
      accessorials,
    },
  };
};
