// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoneShapeTypeV1 = void 0;
var ZoneShapeTypeV1;
(function (ZoneShapeTypeV1) {
    ZoneShapeTypeV1["CIRCLE"] = "CIRCLE";
    ZoneShapeTypeV1["POLYGON"] = "POLYGON";
})(ZoneShapeTypeV1 || (exports.ZoneShapeTypeV1 = ZoneShapeTypeV1 = {}));
