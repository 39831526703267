"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLevelUpIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "arrow-circle-down", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M17.713,5.767a1,1,0,0,1-1.414.011L13.414,2.944c-.108-.108-.255-.244-.414-.389V19a5.006,5.006,0,0,1-5,5H1a1,1,0,0,1,0-2H8a3,3,0,0,0,3-3V2.555c-.16.146-.308.284-.42.4L7.7,5.778A1,1,0,1,1,6.3,4.353L9.172,1.53c.2-.2.513-.487.84-.777a3,3,0,0,1,3.977,0c.326.289.636.572.833.769L17.7,4.353A1,1,0,0,1,17.713,5.767Z" })));
exports.default = TruxwebLevelUpIcon;
