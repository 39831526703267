"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCard = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const CreditCard_styles_1 = require("./CreditCard.styles");
const CreditCard = ({ card, handleRemoveCardSubmit, isRemoveCardEnabled, isSelected, isSelectionDisabled, onChange, shouldAllowUnconditionalSelection, }) => {
    const classes = (0, CreditCard_styles_1.useStyles)();
    let color = isSelected ? '#fff' : '#9D9B9B';
    if (card.status !== schemas_1.EStatusGenericV1.ACTIVE) {
        color = '#636363';
    }
    const [isRemoveVisible, setRemoveVisible] = (0, react_1.useState)(false);
    const { type } = card;
    const formattedType = (0, utils_1.stripSpacesAndUppercase)(type);
    const cardBackground = isSelected
        ? `selected_${formattedType}.svg`
        : `unselected_${formattedType}.svg`;
    const checked = isSelected ? react_1.default.createElement(ux_1.CheckCircleIcon, { className: classes.selectedCard }) : null;
    const mouseOutTimeout = (0, react_1.useRef)();
    const mouseOverTimeout = (0, react_1.useRef)();
    const handleMouseOver = (0, react_1.useCallback)(() => {
        if (!isRemoveCardEnabled)
            return;
        if (mouseOutTimeout === null || mouseOutTimeout === void 0 ? void 0 : mouseOutTimeout.current) {
            clearTimeout(mouseOutTimeout.current);
        }
        mouseOverTimeout.current = setTimeout(() => {
            setRemoveVisible(true);
        }, 300);
    }, [setRemoveVisible, mouseOutTimeout, mouseOverTimeout, isRemoveCardEnabled]);
    const handleMouseOut = (0, react_1.useCallback)(() => {
        if (!isRemoveCardEnabled)
            return;
        if (mouseOverTimeout === null || mouseOverTimeout === void 0 ? void 0 : mouseOverTimeout.current) {
            clearTimeout(mouseOverTimeout.current);
        }
        mouseOutTimeout.current = setTimeout(() => {
            setRemoveVisible(false);
        }, 5);
    }, [setRemoveVisible, mouseOutTimeout, isRemoveCardEnabled]);
    const handleSelection = (0, react_1.useCallback)(() => {
        if (isSelectionDisabled)
            return;
        if (shouldAllowUnconditionalSelection) {
            onChange(card);
        }
        else {
            onChange(!isSelected ? card : null);
        }
    }, [isSelected, onChange, card, isSelectionDisabled, shouldAllowUnconditionalSelection]);
    const handleDeactivateCard = (0, react_1.useCallback)(() => {
        if (isRemoveCardEnabled && handleRemoveCardSubmit) {
            handleRemoveCardSubmit(card.id);
        }
    }, [card, handleRemoveCardSubmit, isRemoveCardEnabled]);
    (0, react_1.useEffect)(() => {
        return () => {
            if (mouseOverTimeout.current) {
                clearTimeout(mouseOverTimeout.current);
            }
            if (mouseOutTimeout.current) {
                clearTimeout(mouseOutTimeout.current);
            }
        };
    }, [mouseOutTimeout, mouseOverTimeout]);
    return (react_1.default.createElement(ux_1.Box, { className: classes.card, onClick: handleSelection, onMouseOut: handleMouseOut, onMouseOver: handleMouseOver, style: {
            color,
        } },
        react_1.default.createElement("img", { alt: type, height: 139, src: `/assets/images/billing/${cardBackground}`, width: 223 }),
        card.status !== schemas_1.EStatusGenericV1.ACTIVE && (react_1.default.createElement(ux_1.Box, { style: {
                background: 'red',
                borderRadius: '100%',
                height: '16px',
                position: 'absolute',
                right: 32,
                top: 32,
                width: '16px',
            } })),
        react_1.default.createElement(ux_1.Box, { className: classes.cardNumber },
            "**** ",
            card.ccNumber.slice(card.ccNumber.length - 4, card.ccNumber.length)),
        react_1.default.createElement(ux_1.Box, { className: classes.cardExpiry },
            card.expiryMonth,
            " / ",
            card.expiryYear),
        checked,
        isRemoveVisible && (react_1.default.createElement(ux_1.IconButton, { className: classes.removeCard, onClick: handleDeactivateCard, onMouseOut: handleMouseOut, onMouseOver: handleMouseOver },
            react_1.default.createElement(ux_1.DeleteIcon, { color: "primary", fontSize: "small" })))));
};
exports.CreditCard = CreditCard;
