import {
  Box,
  CircularProgress,
  DataGridShipperQuoteRowOverlay,
  Grid,
  type GridFilterModel,
  type GridSortModel,
  List,
  ListItem,
  RightHandSidebar,
  ToggleButtons,
  TruxwebRefreshIcon,
  Typography,
} from '@truxweb/ux';
import {
  clearSearchResults,
  handleToggleQuoteTableStatues,
  setLoad,
  setSearchFormData,
  updateQuoteCompanyLocations,
} from '../../stores';
import { convertFormDataToBookingQueryV1, transformI18nLocaleToLanguage } from '@truxweb/utils';
import { DEFAULT_PAGE_SIZE, QUOTE_TABLE_REFRESH_MS } from '../../config';
import {
  EShipmentLocationTypeV1,
  EShipmentQuoteRequestStatusV1,
  EUserTypeV1,
  type TQuoteSearchFormDataV1,
  type TShipperQuoteDataGridRowV1,
} from '@truxweb/schemas';
import { getLocalizedRoute, getRouteProperties, hasWindow, saveBookingQuery } from '../../utils';
import { QuickAccess, QuoteRequestShipperDataGrid } from '@truxweb/common-components';
import { quoteToLoadDefinition, quoteToSearch } from '@truxweb/quote-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useInterval,
  useQuoteCounts,
  useReduxShipperQuoteById,
  useReduxShipperQuoteList,
  useRefresh,
  useSearchFormParams,
  useShipmentCredits,
  useShipmentEntityTags,
  useUserData,
  useUxState,
} from '../../hooks';
import classnames from 'classnames';
import { markShipperQuoteAsRead } from '../../actions';
import { QuoteRequestShipperSidebar } from '../../components';
import { ShipperPage } from '../../pageTemplates';
import { type TTruxwebPageContentProps } from '../../types';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './ShipperQuotesPage.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'shipments'];
const PAGE_SIZE = 10;
const TABLE_ID = 'TRUXWEB_SHIPPER_QUOTE_TABLE';
const DEFAULT_COLUMNS = [
  'isViewed',
  'quoteRef',
  'actions',
  'timer',
  'status',
  'numberAccepted',
  'numberRefused',
  'carrierName',
  'chargesOriginal',
  'bookingDateOriginal',
  'locationOrigin',
  'locationDestination',
  'dateCreated',
];

const DEFAULT_SORT: GridSortModel = [
  {
    field: 'dateCreated',
    sort: 'asc',
  },
];

export const ShipperQuotesPages = ({
  ipAddress,
  pageId,
}: TTruxwebPageContentProps): JSX.Element => {
  const router = useRouter();
  const [hasInit, setInit] = useState(false);
  const [columnKeys, setColumnKeys] = useState<null | string[]>(null);
  const [tableSort, setTableSort] = useState<null | GridSortModel>(null);
  const [tableFilters, setTableFilters] = useState<null | GridFilterModel>(null);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [quoteLoadingId, setQuoteLoadingId] = useState(null);
  const { userType } = useUserData();
  const { data: searchParams, hasLoaded: haveSearchParamsLoaded } = useSearchFormParams();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const shipmentCredits = useShipmentCredits(false);
  const dispatch = useDispatch();

  const { handleRefresh, shouldRefresh } = useRefresh();
  const {
    locale,
    query: { quoteRequestId },
  } = router;
  const { updateQuoteCounts } = useQuoteCounts();
  const tags = useShipmentEntityTags(shouldRefresh);

  const selectedRequestId = useMemo(() => {
    if (!quoteRequestId) return null;
    return parseInt(quoteRequestId as string);
  }, [quoteRequestId]);

  const [isRightSidebarOpen, setRightSidebarState] = useState(false);
  const [toggleTimeout, setToggleTimeout] = useState(null);
  const uxState = useUxState();

  useReduxShipperQuoteById(selectedRequestId);
  const onRefresh = useCallback(() => {
    handleRefresh();
    updateQuoteCounts();
  }, [handleRefresh, updateQuoteCounts]);

  const quoteData = useReduxShipperQuoteList(
    {
      filter: tableFilters,
      language: transformI18nLocaleToLanguage(locale),
      limit: DEFAULT_PAGE_SIZE,
      page: currentPage,
      pageSize: currentPageSize,
      shouldLoadAllData: false,
      sort: tableSort,
      t,
      userType,
    },
    hasInit,
    shouldRefresh
  );
  const {
    data: shipperQuoteData,
    hasLoaded: hasShipperQuoteDataLoaded,
    isLoading,
    rowCount,
  } = quoteData;

  const classes = useStyles({ isLoading });

  const handleClose = useCallback(() => {
    const { route } = getRouteProperties(locale, pageId);
    dispatch(
      updateQuoteCompanyLocations({
        [EShipmentLocationTypeV1.DESTINATION]: false,
        [EShipmentLocationTypeV1.ORIGIN]: false,
      })
    );
    router.push(route);
  }, [router, locale, pageId, dispatch]);

  const findQuote = useCallback(
    (quoteId: number): any | null => {
      return (shipperQuoteData || []).find((quote: TShipperQuoteDataGridRowV1) => {
        return quote.id === quoteId;
      });
    },
    [shipperQuoteData]
  );

  const manageColumnKeys = useCallback(
    (event: any) => {
      const { field } = event;
      const fieldIndex = columnKeys.indexOf(field);
      const newColumnKeys = [...columnKeys];
      if (fieldIndex > -1) {
        newColumnKeys.splice(fieldIndex, 1);
      } else {
        newColumnKeys.push(field);
      }
      setColumnKeys(newColumnKeys);

      const storedKeys = localStorage.getItem(TABLE_ID);
      let update = { columnKeys: newColumnKeys };
      if (storedKeys) {
        update = JSON.parse(storedKeys);
        update.columnKeys = newColumnKeys;
      }
      localStorage.setItem(TABLE_ID, JSON.stringify(update));
    },
    [columnKeys, setColumnKeys]
  );

  const manageTableFilters = useCallback(
    (filter: GridFilterModel) => {
      setTableFilters(filter);
      onRefresh();
    },
    [setTableFilters, onRefresh]
  );

  const manageTableSort = useCallback(
    (sort: GridSortModel | undefined) => {
      let hasSortChanges = false;
      if (sort && !tableSort) {
        hasSortChanges = true;
      } else if (!sort && tableSort) {
        hasSortChanges = true;
      } else if (sort && tableSort) {
        hasSortChanges = JSON.stringify(sort) !== JSON.stringify(tableSort);
      }
      if (hasSortChanges) {
        setTableSort(sort || null);
        const storedKeys = localStorage.getItem(TABLE_ID);
        let update = { tableSort: sort };
        if (storedKeys) {
          update = JSON.parse(storedKeys);
          update.tableSort = sort;
        }
        localStorage.setItem(TABLE_ID, JSON.stringify(update));
        onRefresh();
      }
    },
    [setTableSort, tableSort, onRefresh]
  );

  const handleSelectQuote = useCallback(
    async (quoteId: number) => {
      if (quoteId !== selectedRequestId) {
        const { route } = getRouteProperties(router.locale, pageId);
        router.push(`${route}?quoteRequestId=${quoteId}`);
        const selectedQuote = findQuote(quoteId);

        if (selectedQuote) {
          setRightSidebarState(true);
          if (!selectedQuote.hasBeenViewed) {
            await markShipperQuoteAsRead(selectedQuote.id);
            onRefresh();
          }
        }
      }
    },
    [router, selectedRequestId, findQuote, pageId, onRefresh]
  );

  const handleQuickAccess = useCallback(
    (quoteRef: string) => {
      // FIXME: This will not work for carriers... find a way to handle this
      const quoteId = quoteRef.replace('TRUXQ-1', '');
      handleSelectQuote(parseInt(quoteId, 10));
    },
    [handleSelectQuote]
  );
  const handleSearch = useCallback(
    async (quoteData: any) => {
      setQuoteLoadingId(quoteData.id);
      const searchData = await quoteToSearch({
        quote: quoteData._source,
        searchParams,
      });
      const loadDefinition = quoteToLoadDefinition({
        quote: quoteData._source,
      });
      searchData.loadDefinition = loadDefinition;

      const query = convertFormDataToBookingQueryV1(
        searchData as TQuoteSearchFormDataV1,
        searchParams,
        Boolean(shipmentCredits.data.length)
      );

      saveBookingQuery(query);
      dispatch(setSearchFormData({ data: searchData, shouldForceRefresh: false }));
      dispatch(clearSearchResults());
      dispatch(setLoad({ data: { ...loadDefinition, isExistingSearch: false } }));

      const resultsRoute = getLocalizedRoute(router.locale, 'results');
      router.push(resultsRoute);
      setQuoteLoadingId(null);
    },
    [dispatch, searchParams, router, setQuoteLoadingId, shipmentCredits]
  );

  const handleToggleRefresh = useCallback(() => {
    if (toggleTimeout) clearTimeout(toggleTimeout);
    const newTimeout = setTimeout(() => {
      onRefresh();
    }, 100);
    setToggleTimeout(newTimeout);
  }, [toggleTimeout, setToggleTimeout, onRefresh]);

  const handleRouteToShipment = useCallback(
    (shipmentRef: string) => {
      const { route } = getRouteProperties(router.locale, 'shipments');
      router.push(`${route}?shipmentRef=${shipmentRef}`);
    },
    [router]
  );

  const handleToggle = useCallback(
    (toggle: string, forceSingleToggle: boolean) => {
      dispatch(
        handleToggleQuoteTableStatues({
          forceToggle: forceSingleToggle,
          statuses: [toggle],
        })
      );
      handleToggleRefresh();
    },
    [handleToggleRefresh, dispatch]
  );
  const handleSetToggles = useCallback(
    (toggle: string[]) => {
      dispatch(
        handleToggleQuoteTableStatues({
          forceToggle: true,
          statuses: toggle,
        })
      );
      handleToggleRefresh();
    },
    [handleToggleRefresh, dispatch]
  );

  const [toggles, toggleExplanations] = useMemo(() => {
    const toggles = [
      {
        group: EShipmentQuoteRequestStatusV1.PENDING,
        label: t('common:CARRIER-carrierQuoteStatus-PENDING'),
      },
      {
        group: EShipmentQuoteRequestStatusV1.ACCEPTED,
        label: t('common:confirmed'),
      },
      {
        group: EShipmentQuoteRequestStatusV1.REFUSED,
        label: t('common:refused'),
      },
    ];

    const list = (
      <List key={1}>
        {toggles.map(({ group, label }) => {
          return (
            <ListItem key={group}>
              <Typography color="white">
                <b>{label}</b>:{' '}
                {t(`common:statusGroup-${group}-${EUserTypeV1.SHIPPER}-explanation`)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );

    const statusToggleExplanation = (
      <>
        <Typography color="white" fontStyle="semibold">
          {t('common:statusToggles')}
        </Typography>
        {list}
      </>
    );
    return [toggles, statusToggleExplanation];
  }, [t]);

  useInterval(() => {
    onRefresh();
  }, QUOTE_TABLE_REFRESH_MS);

  useEffect(() => {
    if (!selectedRequestId && isRightSidebarOpen) {
      setRightSidebarState(false);
    }
    if (selectedRequestId && !isRightSidebarOpen) {
      setRightSidebarState(true);
    }
  }, [selectedRequestId, isRightSidebarOpen]);

  useEffect(() => {
    if (hasWindow() && columnKeys === null) {
      const storedKeys = localStorage.getItem(TABLE_ID);
      if (storedKeys) {
        const parsed = JSON.parse(storedKeys);
        const newSort = parsed.tableSort || DEFAULT_SORT;
        setColumnKeys(parsed.columnKeys || DEFAULT_COLUMNS);
        setTableSort(newSort);
      } else {
        setColumnKeys(DEFAULT_COLUMNS);
        setTableSort(DEFAULT_SORT);
      }

      if (!hasInit) {
        const storedFilters = localStorage.getItem(`${TABLE_ID}_FILTERS`);
        if (storedFilters) {
          handleSetToggles(JSON.parse(storedFilters));
        }
      }

      setInit(true);
    }
  }, [columnKeys, setColumnKeys, setTableSort, hasInit, setInit, handleSetToggles]);

  return (
    <ShipperPage pageId={pageId}>
      <Grid container direction="column" style={{ position: 'relative' }}>
        <Box mb={2}>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="space-between"
            xs
          >
            <Grid item xs>
              <Box mr={2}>
                <QuickAccess
                  handleQuickAccess={handleQuickAccess}
                  placeholder={t('common:quickAccessQuote')}
                  value={''}
                />
              </Box>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={7}>
              <Grid item style={{ marginTop: '8px' }} xs>
                <ToggleButtons
                  explanation={toggleExplanations}
                  handleToggle={handleToggle}
                  isDisabled={isLoading}
                  label={t('common:statusToggles')}
                  selectedToggles={uxState.quoteTableStatuses}
                  toggles={toggles}
                />
              </Grid>
              <Grid item>
                {!isLoading && (
                  <Box ml={1} mt={1.5}>
                    <button className={classes.button} disabled={isLoading} onClick={onRefresh}>
                      <TruxwebRefreshIcon
                        className={classnames(classes.icon, classes.animatedIcon)}
                      />
                    </button>
                  </Box>
                )}
                {isLoading && (
                  <Box ml={1.9} mt={1.75}>
                    <CircularProgress size={16} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.tableContainer} container>
          {!tableSort || !haveSearchParamsLoaded ? (
            <></>
          ) : (
            <QuoteRequestShipperDataGrid
              accessorial={Object.values(searchParams.accessorials)}
              areFiltersEnabled
              availableTags={tags?.data || []}
              columnKeys={columnKeys}
              currentSort={tableSort}
              data={shipperQuoteData || []}
              equipment={Object.values(searchParams.equipment)}
              filterMode={'client'}
              handleColumnVisibilityChange={manageColumnKeys}
              handleFilterChange={manageTableFilters}
              handleRouteToShipment={handleRouteToShipment}
              handleSearch={handleSearch}
              handleSortingChange={manageTableSort}
              isActionsOverlayEnabled={false}
              isExportEnabled={false}
              isHeaderShown
              isLazyLoading={isLoading}
              isLoading={
                !hasShipperQuoteDataLoaded ||
                (isLoading && (shipperQuoteData || []).length < currentPageSize)
              }
              language={transformI18nLocaleToLanguage(locale)}
              lastLoadedDate={
                quoteData?.lastLoadedDate ? new Date(quoteData?.lastLoadedDate) : undefined
              }
              onPageChange={(page) => {
                setCurrentPage(page);
                onRefresh();
              }}
              onPageSizeChange={(pageSize) => {
                setCurrentPageSize(pageSize);
                onRefresh();
              }}
              onSelectQuote={handleSelectQuote}
              pageSize={currentPageSize}
              paginationMode={'server'}
              quoteLoadingId={quoteLoadingId}
              renderActionsContent={(row: TShipperQuoteDataGridRowV1) => {
                return (
                  <DataGridShipperQuoteRowOverlay
                    defaultFilter={null}
                    handleRouteToQuote={(quoteId, requestId) => {
                      const { route } = getRouteProperties(router.locale, pageId);
                      router.push(`${route}?quoteRequestId=${quoteId}&requestId=${requestId}`);
                    }}
                    quoteId={row.id}
                    quoteRequests={row.carrierRequests}
                    t={t}
                    timeRemainingInSeconds={row.countdownTimerTimeRemaining}
                  />
                );
              }}
              rowCount={rowCount}
              selectedRows={selectedRequestId ? [selectedRequestId] : undefined}
              sortingMode={'client'}
              t={t}
            />
          )}
        </Grid>
      </Grid>
      <RightHandSidebar
        canExpand
        handleClose={handleClose}
        isLeftMenuOpen={uxState.sidebarState}
        isOpen={isRightSidebarOpen}
        testId="ShipperQuotesPage"
      >
        <Grid item key={selectedRequestId}>
          {Boolean(selectedRequestId) && (
            <QuoteRequestShipperSidebar ipAddress={ipAddress} onQuoteUpdate={onRefresh} />
          )}
        </Grid>
      </RightHandSidebar>
    </ShipperPage>
  );
};
