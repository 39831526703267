"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCityDetailsString = void 0;
const formatCityDetailsString = (cityDetails) => {
    return [cityDetails.city, cityDetails.province, cityDetails.country]
        .filter((element) => Boolean(element))
        .map((element) => element.trim())
        .filter((element) => Boolean(element))
        .join(', ');
};
exports.formatCityDetailsString = formatCityDetailsString;
