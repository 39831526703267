"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGrinSquintTearsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10.515,18.782a1,1,0,0,1-.329-1.817,15.391,15.391,0,0,0,4.079-2.7,15.416,15.416,0,0,0,2.7-4.079,1,1,0,0,1,1.817.329A6.69,6.69,0,0,1,10.515,18.782ZM6.627,18.3c-5.954,7.53,6.93,7.556.974,0A.637.637,0,0,0,6.627,18.3ZM4.935,4.923a10.042,10.042,0,0,1,13.058-.94,1,1,0,1,0,1.2-1.6,12.006,12.006,0,0,0-16.8,16.8,1,1,0,0,0,1.6-1.2A10.043,10.043,0,0,1,4.935,4.923ZM23,11.028h0a1,1,0,0,0-1,1A10.057,10.057,0,0,1,12.025,22a1,1,0,0,0,0,2h0A12.067,12.067,0,0,0,24,12.031,1,1,0,0,0,23,11.028ZM18.307,7.6c7.559,5.954,7.52-6.929,0-.974A.637.637,0,0,0,18.307,7.6ZM12.2,3.948a1,1,0,0,0-1.176.784l-.708,3.536a1.008,1.008,0,0,0,1.177,1.177l3.536-.707a1,1,0,0,0-.393-1.961l-2.064.412.412-2.064A1,1,0,0,0,12.2,3.948ZM8.268,10.312l-3.536.708a1,1,0,1,0,.393,1.96l2.064-.412-.412,2.064a1,1,0,1,0,1.961.393l.707-3.536a1,1,0,0,0-1.177-1.177Z" })));
exports.default = TruxwebGrinSquintTearsIcon;
