"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMedicineIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m16 7-.023-1.177a2.992 2.992 0 0 0 -.977-5.823h-6a2.993 2.993 0 0 0 -1 5.816v1.184a5.006 5.006 0 0 0 -5 5v7a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5v-7a5.006 5.006 0 0 0 -5-5zm-7-5h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2zm10 17a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3-3v-7a3 3 0 0 1 3-3h1a1 1 0 0 0 1-1v-2h3.973l.027 2.014a1 1 0 0 0 1 .986h1a3 3 0 0 1 3 3zm-3-4a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1z" })));
exports.default = TruxwebMedicineIcon;
