"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    bodyLarge: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    bodyMedium: {
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '18px',
    },
    bodySmall: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '15px',
    },
    bodyXSmall: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '12px',
    },
    error: {
        color: theme.palette.error.main,
    },
    h1: {
        fontFamily: 'Poppins',
        fontSize: '3.13em',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '55px',
    },
    h2: {
        fontFamily: 'Poppins',
        fontSize: '2.5em',
        fontWeight: 500,
        lineHeight: '45px',
    },
    h3: {
        fontFamily: 'Poppins',
        fontSize: '1.88em',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '35px',
    },
    h4: {
        fontFamily: 'Poppins',
        fontSize: '1.88em',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '35px',
    },
    h5: {
        fontFamily: 'Poppins',
        fontSize: '1.56em',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '30px',
    },
    h6: {
        fontFamily: 'Poppins',
        fontSize: '1.25em',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '25px',
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    semibold: {
        fontWeight: 600,
    },
    success: {
        color: theme.palette.success.main,
    },
    white: {
        color: theme.palette.common.white,
    },
}));
