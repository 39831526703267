import { EShipmentQuoteRequestStatusV1, EUserTypeV1, TAddQuoteCommentV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const addCommentToCarrierQuoteRequest = async (
  userType: EUserTypeV1,
  carrierQuoteRequestId: number,
  commentData: TAddQuoteCommentV1
): Promise<void> => {
  if (userType === EUserTypeV1.CARRIER) {
    await addCarrierCommentToCarrierQuoteRequest(carrierQuoteRequestId, commentData);
  } else {
    await addShipperCommentToCarrierQuoteRequest(carrierQuoteRequestId, commentData);
  }
};

export const addCarrierCommentToCarrierQuoteRequest = async (
  carrierQuoteRequestId: number,
  commentData: TAddQuoteCommentV1
) => {
  await makeRequestToApiGateway(
    'shipmentPatchV1CarrierQuoteRequestById',
    {
      id: carrierQuoteRequestId,
    },
    { 'Content-Type': 'application/json' },
    commentData,
    { shouldAddComment: true }
  );
};

export const addShipperCommentToCarrierQuoteRequest = async (
  carrierQuoteRequestId: number,
  commentData: TAddQuoteCommentV1
) => {
  await makeRequestToApiGateway(
    'shipmentPatchV1ShipperQuoteRequestByIdByStatus',
    {
      id: carrierQuoteRequestId,
      status: EShipmentQuoteRequestStatusV1.PENDING,
    },
    { 'Content-Type': 'application/json' },
    commentData,
    { shouldAddComment: true }
  );
};
