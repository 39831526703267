"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatRequestPaymentTermsFormData = void 0;
const utils_1 = require("@truxweb/utils");
const formatRequestPaymentTermsFormData = (formData, userData) => {
    const { addressLineOne, city, companyLegalName, country, paymentTermsLocationId, paymentTermsType, postalCode, province, taxId, } = formData;
    const paymentLocation = (0, utils_1.companyLocationFromFormData)({
        addressLineOne,
        addressName: addressLineOne,
        city,
        contact: `${userData.firstName} ${userData.lastName}`,
        country,
        email: userData.email,
        phone: userData.phone,
        postalCode,
        province,
    }, userData.companyId, userData.companyExtId);
    // FIXME: this requires any id attribute to be cleared out if this address changes
    paymentLocation.id = paymentTermsLocationId;
    return {
        companyId: userData.companyId,
        companyLegalName,
        companyPaymentLocation: paymentLocation,
        companyTaxId: taxId,
        paymentTermsType,
    };
};
exports.formatRequestPaymentTermsFormData = formatRequestPaymentTermsFormData;
