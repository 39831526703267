// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTermsTypeV1 = void 0;
var PaymentTermsTypeV1;
(function (PaymentTermsTypeV1) {
    PaymentTermsTypeV1["DEBIT"] = "DEBIT";
    PaymentTermsTypeV1["INVOICE"] = "INVOICE";
})(PaymentTermsTypeV1 || (exports.PaymentTermsTypeV1 = PaymentTermsTypeV1 = {}));
