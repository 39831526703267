"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBroccoliIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.794,6.206a4.431,4.431,0,0,0-4.6-3.194C13.509-1.852,5.916-.445,5.083,5c-7.24.463-6.488,10.65.74,10.934a17.52,17.52,0,0,1,1.037,4.45A4.027,4.027,0,0,0,10.842,24h2.316a4.027,4.027,0,0,0,3.982-3.615,15.894,15.894,0,0,1,1.493-5.473C24.2,15.9,26.181,7.938,20.794,6.206Zm-5.643,13.96A2.027,2.027,0,0,1,13.158,22H10.842a2.027,2.027,0,0,1-1.993-1.834A20.312,20.312,0,0,0,7.936,15.9a8.082,8.082,0,0,0,2.573-.989,4.835,4.835,0,0,0,.491.309V19a1,1,0,0,0,2,0V15.927a5.219,5.219,0,0,0,3.228-.475A19.7,19.7,0,0,0,15.151,20.166ZM19.5,13a2.519,2.519,0,0,1-1.564-.565A2.72,2.72,0,0,1,17,10a1,1,0,0,0-2,0,4.7,4.7,0,0,0,.969,3.255,3.425,3.425,0,0,1-4.6-.264,1,1,0,0,0-1.33-.151A5.75,5.75,0,0,1,7,14c-2.281,0-5-.694-5-4A3,3,0,0,1,5,7a2.808,2.808,0,0,1,3,3,1,1,0,0,0,2,0A5,5,0,0,0,7.055,5.454,4,4,0,0,1,14.7,4.5a1,1,0,0,0,1.178.592,2.477,2.477,0,0,1,3.083,2.085,1,1,0,0,0,.861.862A2.493,2.493,0,0,1,19.5,13Z" })));
exports.default = TruxwebBroccoliIcon;
