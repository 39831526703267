"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTruckMonsterIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,17h-.1a4.952,4.952,0,0,0-.729-1.756l.073-.073a1,1,0,0,0-1.414-1.414l-.073.073A4.952,4.952,0,0,0,19,13.1V13a1,1,0,0,0-2,0v.1a4.952,4.952,0,0,0-1.756.729l-.073-.073a1,1,0,0,0-1.414,1.414l.073.073A4.952,4.952,0,0,0,13.1,17H10.9a4.952,4.952,0,0,0-.729-1.756l.073-.073a1,1,0,0,0-1.414-1.414l-.073.073A4.952,4.952,0,0,0,7,13.1V13a1,1,0,0,0-2,0v.1a4.952,4.952,0,0,0-1.756.729l-.073-.073a1,1,0,0,0-1.414,1.414l.073.073A4.952,4.952,0,0,0,1.1,17H1a1,1,0,0,0,0,2h.1a4.952,4.952,0,0,0,.729,1.756l-.073.073a1,1,0,0,0,1.414,1.414l.073-.073A4.952,4.952,0,0,0,5,22.9V23a1,1,0,0,0,2,0v-.1a4.952,4.952,0,0,0,1.756-.729l.073.073a1,1,0,0,0,1.414-1.414l-.073-.073A4.952,4.952,0,0,0,10.9,19h2.2a4.952,4.952,0,0,0,.729,1.756l-.073.073a1,1,0,0,0,1.414,1.414l.073-.073A4.952,4.952,0,0,0,17,22.9V23a1,1,0,0,0,2,0v-.1a4.952,4.952,0,0,0,1.756-.729l.073.073a1,1,0,0,0,1.414-1.414l-.073-.073A4.952,4.952,0,0,0,22.9,19H23A1,1,0,0,0,23,17Zm-5,4a3,3,0,0,1,0-6A3,3,0,0,1,18,21ZM6,21a3,3,0,0,1,0-6A3,3,0,0,1,6,21ZM1,12a1,1,0,0,0,1-1A2,2,0,0,1,4,9H20a2,2,0,0,1,2,2,1,1,0,0,0,2,0,4.006,4.006,0,0,0-3.493-3.968l-4.1-5.148A4.973,4.973,0,0,0,12.5,0H12A3,3,0,0,0,9,3V7H4a4,4,0,0,0-4,4A1,1,0,0,0,1,12ZM11,3a1,1,0,0,1,1-1h.5a2.984,2.984,0,0,1,2.346,1.13L17.925,7H11Z" })));
exports.default = TruxwebTruckMonsterIcon;
