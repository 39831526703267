"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    itemBox: {
        background: theme.palette.common.white,
        borderRadius: '8px',
        color: theme.palette.grey[600],
        height: '40px',
    },
}));
