"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebAubergineIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.427,4.378a6.552,6.552,0,0,0,1.128-.546,1,1,0,0,0-1.1-1.668A6.508,6.508,0,0,1,20,2.931V1a1,1,0,0,0-2,0V2.931a6.526,6.526,0,0,1-2.451-.767,1,1,0,0,0-1.1,1.668,6.552,6.552,0,0,0,1.128.546,4.968,4.968,0,0,0-1.534,2.994c-.524,2.77-3.486,2.6-6.388,2.63C-2.04,9.608-2.68,23.567,7,24c10.327,0,17-6.28,17-16A4.973,4.973,0,0,0,22.427,4.378ZM7,22c-7.021-.328-6.38-10.473.642-10,3.542.151,7.836-.36,8.381-4.38A3,3,0,0,1,18,5.178c.094.917-.436,2.767,1,2.822,1.434-.054.907-1.9,1-2.816A3,3,0,0,1,22,8C22,16.636,16.252,22,7,22Z" })));
exports.default = TruxwebAubergineIcon;
