import { TPageId, TTruxwebPageProps } from '../../types';
import { AuthorizedPage } from '..';
import { EPermissionV1 } from '@truxweb/schemas';
import { usePermissions } from '../../hooks';

type TAuthorizedPageProps = TTruxwebPageProps & {
  children?: React.ReactNode;
  pageId?: TPageId;
};

// The intended use for this page is to block access to a resource which a user
// must be logged in to see.
// NOTE: This does not as yet account for permissions
export const CarrierPage = ({ children, isAuthed, pageId }: TAuthorizedPageProps): JSX.Element => {
  const [evaluationResult, havePermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_CARRIER_PAGE,
  ]);

  return (
    <AuthorizedPage isAuthed={isAuthed} pageId={pageId}>
      {havePermissionsEvaluated && !evaluationResult && (
        <h1>You do not have permission to view this page</h1>
      )}
      {havePermissionsEvaluated && evaluationResult && children}
    </AuthorizedPage>
  );
};
