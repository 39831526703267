"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipperPaymentTerms = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const ShipperPaymentTerms = ({ arePaymentTermsShown, arePendingTermsLoaded, bankAccount, handleDenyPaymentTerms, handleRevisePaymentTerms, handleUploadFailure, handleUploadFile, isAdmin, isSaving, isUploading, onSubmitSuccess, pendingTermsRequest, proofOfOwnership, setPaymentTermsShown, setProofOfOwnership, setShouldPaymentTermsRefetch, shouldPaymentTermsRefetch, t, }) => {
    (0, react_1.useEffect)(() => {
        if (shouldPaymentTermsRefetch) {
            setShouldPaymentTermsRefetch(false);
        }
    }, [shouldPaymentTermsRefetch, setShouldPaymentTermsRefetch]);
    (0, react_1.useEffect)(() => {
        if ((pendingTermsRequest === null || pendingTermsRequest === void 0 ? void 0 : pendingTermsRequest.status) === schemas_1.EPaymentTermsStatusV1.REVISE) {
            setPaymentTermsShown && setPaymentTermsShown(true);
        }
    }, [pendingTermsRequest, setPaymentTermsShown]);
    return (react_1.default.createElement(ux_1.FlatCard, null,
        react_1.default.createElement(ux_1.Typography, { variant: "h6" },
            t('common:paymentTerms'),
            isAdmin && ` -- ${pendingTermsRequest === null || pendingTermsRequest === void 0 ? void 0 : pendingTermsRequest.status}`),
        react_1.default.createElement(react_1.default.Fragment, null, arePendingTermsLoaded && (react_1.default.createElement(react_1.default.Fragment, null,
            pendingTermsRequest && (react_1.default.createElement(react_1.default.Fragment, null,
                pendingTermsRequest.status === schemas_1.EPaymentTermsStatusV1.SUBMITTED && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.HourglassFullTwoToneIcon, { color: "secondary" })),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { ml: 2 },
                                react_1.default.createElement(ux_1.Typography, null, t('common:pendingPaymentTerms'))))))),
                pendingTermsRequest.status === schemas_1.EPaymentTermsStatusV1.REJECTED && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.ErrorOutlinedIcon, { color: "error" })),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { ml: 2, mt: 0.5 },
                                react_1.default.createElement(ux_1.Typography, null, t('common:paymentTermsRequestRejected'))))))),
                pendingTermsRequest.status === schemas_1.EPaymentTermsStatusV1.REVISE && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.InfoIcon, { color: "secondary" })),
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { ml: 2, mt: 0.5 },
                                react_1.default.createElement(ux_1.Typography, null, t('common:revisePaymentTerms'))))))),
                pendingTermsRequest.status === schemas_1.EPaymentTermsStatusV1.APPROVED && (react_1.default.createElement(__1.PaymentTerms, { bankAccountDetails: bankAccount, paymentTerms: pendingTermsRequest, t: t })))),
            !pendingTermsRequest && (react_1.default.createElement(ux_1.Box, { ml: -2, mt: 4 },
                react_1.default.createElement(ux_1.Button, { color: "primary", onClick: () => {
                        setPaymentTermsShown && setPaymentTermsShown(!arePaymentTermsShown);
                    } },
                    react_1.default.createElement(ux_1.Box, { mr: 0.5 }, arePaymentTermsShown ? react_1.default.createElement(ux_1.RemoveCircleOutlineIcon, null) : react_1.default.createElement(ux_1.AddCircleOutlineIcon, null)),
                    react_1.default.createElement(ux_1.Box, { ml: 0.5, mt: -0.5 }, t('common:requestPaymentTerms')),
                    react_1.default.createElement(ux_1.Box, { ml: 2 },
                        react_1.default.createElement(ux_1.AccountBalanceIcon, null))))),
            arePaymentTermsShown && (react_1.default.createElement(__1.ShipperPaymentTermsForm, { bankAccountDetails: bankAccount, handleDenyPaymentTerms: handleDenyPaymentTerms, handleRevisePaymentTerms: handleRevisePaymentTerms, handleUploadFailure: handleUploadFailure, handleUploadFile: handleUploadFile, isAdmin: isAdmin, isSaving: isSaving, isUploading: isUploading, onSubmitSuccess: onSubmitSuccess, paymentTerms: pendingTermsRequest, proofOfOwnership: proofOfOwnership, setProofOfOwnership: setProofOfOwnership, t: t })))))));
};
exports.ShipperPaymentTerms = ShipperPaymentTerms;
