import { Auth } from 'aws-amplify';
import { TCognitoUserData } from '../../types';
import { TUpdateUserRequestV1 } from '@truxweb/schemas';

export const updateCognitoAttributes = async (userData: TUpdateUserRequestV1): Promise<void> => {
  // Disabled due to external data requirement
  /* eslint-disable camelcase */
  const userAttributes: Partial<TCognitoUserData> = {
    email: userData.email,
    family_name: userData.lastName,
    given_name: userData.firstName,
    phone_number: userData.phoneNumber,
  };
  /* eslint-enable camelcase */
  const user = await Auth.currentAuthenticatedUser();

  await Auth.updateUserAttributes(user, userAttributes);
};
