// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteShipperAcceptanceReasonV1 = void 0;
var QuoteShipperAcceptanceReasonV1;
(function (QuoteShipperAcceptanceReasonV1) {
    QuoteShipperAcceptanceReasonV1["COMPETITIVE_PRICE"] = "COMPETITIVE_PRICE";
    QuoteShipperAcceptanceReasonV1["GOOD_REVIEWS"] = "GOOD_REVIEWS";
    QuoteShipperAcceptanceReasonV1["QUICK_RESPONSE"] = "QUICK_RESPONSE";
    QuoteShipperAcceptanceReasonV1["ACCEPTABLE_SHIPMENT_DATE"] = "ACCEPTABLE_SHIPMENT_DATE";
    QuoteShipperAcceptanceReasonV1["PREVIOUS_RELATIONSHIP"] = "PREVIOUS_RELATIONSHIP";
})(QuoteShipperAcceptanceReasonV1 || (exports.QuoteShipperAcceptanceReasonV1 = QuoteShipperAcceptanceReasonV1 = {}));
