"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSpaIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m19.367 14.727a4.149 4.149 0 0 0 2.633-3.727c0-2.622-2.5-4.354-6.8-4.849a3.267 3.267 0 0 0 .8-2.151c0-2.43-2.355-4-6-4s-6 1.57-6 4a3.464 3.464 0 0 0 1.9 3.051c-2.276 1.001-2.9 2.555-2.9 3.949a4 4 0 0 0 2.229 3.614c-3.436.766-5.229 2.243-5.229 4.386 0 3.271 4.149 5 12 5 7.963 0 12-1.682 12-5 0-2.057-1.555-3.484-4.633-4.273zm-9.367-12.727c1.991 0 4 .619 4 2s-2.009 2-4 2-4-.619-4-2 2.009-2 4-2zm2.5 6c3.46 0 7.5.786 7.5 3s-4.04 3-7.5 3c-2.255 0-7.5-.292-7.5-3s5.245-3 7.5-3zm-.5 14c-6.075 0-10-1.178-10-3s3.925-3 10-3c6.262 0 10 1.122 10 3s-3.738 3-10 3z" })));
exports.default = TruxwebSpaIcon;
