"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBabyCarriageIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15,0a1.959,1.959,0,0,0-2,2V9H5.468L4.229,7.512C3.48,6.459.292,4.933,0,7A1,1,0,0,0,1,8a2.2,2.2,0,0,1,1.692.792L4,10.362V11a5.006,5.006,0,0,0,5,5h2.865l-2.6,3.12a2.5,2.5,0,1,0,1.5,1.323L14,16.562l3.234,3.881a2.5,2.5,0,1,0,1.5-1.323L16.135,16H19a5.006,5.006,0,0,0,5-5V10C24,.114,15.09,0,15,0Zm6.961,9H15l0-7A7.083,7.083,0,0,1,21.961,9ZM19,14H9a3,3,0,0,1-3-3H22A3,3,0,0,1,19,14Z" })));
exports.default = TruxwebBabyCarriageIcon;
