"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTachometerSlowestIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.445,21.516a3.014,3.014,0,0,1-4.073.128l-1.026-.887a1,1,0,0,1,1.308-1.514l1.027.888a1,1,0,0,0,1.357-.037,10,10,0,1,0-14.114-.038,1.016,1.016,0,0,0,1.395.076l1.027-.889a1,1,0,0,1,1.308,1.514l-1.027.888a3.036,3.036,0,0,1-4.119-.176A12.057,12.057,0,0,1,4,4.052C11.021-2.364,22.755,2,23.9,11.437A11.958,11.958,0,0,1,20.445,21.516ZM12,11a2,2,0,0,0-1.988,1.78L5.145,14.916a1,1,0,1,0,.8,1.832l4.867-2.136A1.993,1.993,0,1,0,12,11Z" })));
exports.default = TruxwebTachometerSlowestIcon;
