import { useDispatch, useSelector } from 'react-redux';
import { getShipmentEntityTags } from '../../../actions';
import { selectShipmentEntityTags } from '../../../selectors';
import { TShipmentTagsData } from '../../../stores';
import { useEffect } from 'react';

export const useShipmentEntityTags = (shouldForceRefresh?: boolean): TShipmentTagsData => {
  const data = useSelector(selectShipmentEntityTags());

  const dispatch = useDispatch();

  useEffect(() => {
    if ((!data.hasLoaded && !data.isLoading) || (!data.isLoading && shouldForceRefresh)) {
      dispatch(getShipmentEntityTags());
    }
  }, [data, dispatch, shouldForceRefresh]);

  return data;
};
