"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestCheckout = void 0;
const ux_1 = require("@truxweb/ux");
const quote_utils_1 = require("@truxweb/quote-utils");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const errors_1 = require("@truxweb/errors");
const react_hook_form_1 = require("react-hook-form");
const QuoteRequestCheckout_styles_1 = require("./QuoteRequestCheckout.styles");
const QuoteRequestCheckout = ({ acceptCarrierQuoteForShipper, addAlert, addCreditCard, ApplyForPaymentTerms, bambora, bankAccounts, canSetQuoteTaxExemptStatus, captureException, carrierQuoteData, clearExternalError, company, companyLocations, companySubscription, creditCards, defaultActiveStep, defaultLocationHours, defaultLocationSchedule, externalError, getUpdatedQuoteCharges, handleRouteToSubscription, handleRouteToTermsAndConditions, ipAddress, isAddCardFormShown, isPaymentDataLoading, isSaving, language, onCancelCheckout, paymentTerms, quoteAcceptanceReason, setIsAddCardFormShown, shipmentCredits, shipperQuoteData, t, userData, validPaymentMethods, }) => {
    const [isLocalSaving, setLocalSaving] = (0, react_1.useState)(false);
    const { control, getValues, handleSubmit, register, setValue, trigger, watch } = (0, react_hook_form_1.useForm)({
        mode: 'onChange',
    });
    const [activeStep, setActiveStep] = (0, react_1.useState)(defaultActiveStep || 0);
    const [header, setHeader] = (0, react_1.useState)(t('common:shipmentDetails'));
    const [hasHandledExternalError, setExternalErrorHandled] = (0, react_1.useState)(false);
    const [hasInit, setInit] = (0, react_1.useState)(false);
    const [isSubmitDisabled, setSubmitDisabled] = (0, react_1.useState)(false);
    const [quoteCharges, setQuoteCharges] = (0, react_1.useState)(carrierQuoteData.charges);
    const [areUpdatedChargesLoading, setUpdateChargesLoading] = (0, react_1.useState)(false);
    const [shouldBlockCreditRedemption, setBlockCreditRedemption] = (0, react_1.useState)(false);
    const [isCreditCardSurchargeDisclaimerShown, setCreditCardSurchargeDisclaimerShown] = (0, react_1.useState)(false);
    const classes = (0, QuoteRequestCheckout_styles_1.useStyles)({ activeStep });
    const handleReturn = (0, react_1.useCallback)(() => {
        if (activeStep === 0) {
            onCancelCheckout();
        }
        else {
            const newStep = activeStep - 1;
            if (newStep === 0) {
                setHeader(t('common:shipmentDetails'));
            }
            else {
                setHeader(t('common:payment'));
            }
            setActiveStep(newStep);
        }
    }, [setActiveStep, activeStep, onCancelCheckout, setHeader, t]);
    const submitToken = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        return yield new Promise((resolve, reject) => {
            bambora.createToken((result) => {
                if (result.error)
                    reject(result.error);
                resolve(result.token);
            });
        });
    }), [bambora]);
    const handleUpdateQuoteCharges = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const values = getValues();
            const paymentMethod = values.selectedPaymentMethod;
            let creditCardType = '';
            if (paymentMethod === schemas_1.EPaymentMethodV1.CreditCard) {
                // If the user is paying by credit card we
                // need to know which type of card they are using
                if (!(creditCards === null || creditCards === void 0 ? void 0 : creditCards.length))
                    return;
                const selectedCard = creditCards.find(({ id }) => {
                    return id === values.selectedCreditCardId;
                });
                if (!selectedCard) {
                    throw new Error(`Cannot find card ${values.selectedCreditCardId}`);
                }
                creditCardType = selectedCard.type;
            }
            setUpdateChargesLoading(true);
            const updatedCharges = yield getUpdatedQuoteCharges({
                carrierQuoteRequestId: carrierQuoteData.id,
                creditCardType,
                isTaxExempt: values.isTaxExempt,
                paymentMethod,
                shouldRedeemCredit: values.shouldRedeemCredit,
            });
            setQuoteCharges(updatedCharges);
        }
        finally {
            setUpdateChargesLoading(false);
        }
    }), [
        getValues,
        carrierQuoteData,
        creditCards,
        setQuoteCharges,
        getUpdatedQuoteCharges,
        setUpdateChargesLoading,
    ]);
    const handleExternalError = (0, react_1.useCallback)((err) => __awaiter(void 0, void 0, void 0, function* () {
        if (err.code === '010') {
            // This code indicates that the credit the user was attempting
            // to redeem failed to do so.
            // 1) Automatically deselect the credit redepemtion
            // 2) Fetch updated charges
            // 3) Alert the user
            setBlockCreditRedemption(true);
            setValue('shouldRedeemCredit', false);
            yield handleUpdateQuoteCharges();
            addAlert({
                message: t('common:checkoutCreditRedemptionFailure'),
                severity: 'error',
            });
        }
        else {
            const message = (0, errors_1.getErrorMessage)(err, t) || err;
            addAlert({
                message: `${message}`,
                severity: 'error',
            });
        }
    }), [addAlert, t, setBlockCreditRedemption, setValue, handleUpdateQuoteCharges]);
    const submitForm = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        yield handleSubmit((data) => __awaiter(void 0, void 0, void 0, function* () {
            setExternalErrorHandled(false);
            const { destinations, origin } = (0, quote_utils_1.getOriginAndDestinationFromQuoteLocations)(carrierQuoteData.location);
            const destination = destinations[0];
            const formattedOrigin = Object.assign(Object.assign({}, origin), { closeHour: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-closeHour`], contact: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-contact`], email: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-email`] || '', externalReferenceNumber: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-externalReferenceNumber`], name: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-name`], openHour: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-openHour`], phone: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-phone`], phoneExt: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-phone_ext`], weeklySchedule: data[`${schemas_1.EShipmentLocationTypeV1.ORIGIN}-weeklySchedule`] });
            const formattedDestination = Object.assign(Object.assign({}, destination), { closeHour: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-closeHour`], contact: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-contact`], email: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-email`] || '', externalReferenceNumber: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-externalReferenceNumber`], name: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-name`], openHour: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-openHour`], phone: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-phone`], phoneExt: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-phone_ext`], weeklySchedule: data[`${schemas_1.EShipmentLocationTypeV1.DESTINATION}-weeklySchedule`] });
            const isCrossBorder = (0, quote_utils_1.determineQuoteCrossBorderStatus)(carrierQuoteData);
            const intraCanada = formattedOrigin.country === schemas_1.ECountryV1.CA && formattedDestination.country === schemas_1.ECountryV1.CA;
            try {
                setLocalSaving(true);
                const acceptQuoteRequest = {
                    acceptanceReason: data.acceptanceReason,
                    checkoutInstructions: data.checkoutInstructions,
                    isTaxable: isCrossBorder || !intraCanada ? false : !data.isTaxExempt,
                    location: [formattedOrigin, formattedDestination],
                    paymentMethod: data.selectedPaymentMethod,
                    shipperReferenceNumber: data.shipperReferenceNumber,
                    shouldAddressesUpdate: data.shouldAddressesUpdate,
                    shouldRedeemCredit: data.shouldRedeemCredit,
                };
                if (data.selectedPaymentMethod === schemas_1.EPaymentMethodV1.CreditCard) {
                    const selectedCreditCard = creditCards.find((card) => {
                        return card.id === data.selectedCreditCardId;
                    });
                    acceptQuoteRequest.ipAddress = ipAddress || '127.0.0.1';
                    if (isAddCardFormShown) {
                        if (!addCreditCard) {
                            throw new Error('Adding Credit Cards is disabled');
                        }
                        // if we are adding a new card we need to submit the token
                        // on the client side
                        let cardToken = '';
                        try {
                            cardToken = yield submitToken();
                        }
                        catch (err) {
                            captureException(err);
                            throw err;
                        }
                        const newCardAddress = (0, utils_1.addressFromFormData)(data, userData);
                        const response = yield addCreditCard({
                            address: newCardAddress,
                            isDefault: (data === null || data === void 0 ? void 0 : data.setAsDefaultCard) || false,
                            token: cardToken,
                        });
                        acceptQuoteRequest.cardId = response.id;
                        acceptQuoteRequest.address = newCardAddress;
                    }
                    else if (selectedCreditCard) {
                        acceptQuoteRequest.cardId = selectedCreditCard.id;
                        acceptQuoteRequest.address = selectedCreditCard.address;
                    }
                }
                yield acceptCarrierQuoteForShipper(carrierQuoteData.id, acceptQuoteRequest);
            }
            catch (err) {
                handleExternalError(err);
            }
            finally {
                setLocalSaving(false);
            }
        }))();
    }), [
        handleExternalError,
        setExternalErrorHandled,
        captureException,
        addCreditCard,
        acceptCarrierQuoteForShipper,
        handleSubmit,
        ipAddress,
        submitToken,
        isAddCardFormShown,
        creditCards,
        userData,
        carrierQuoteData,
        setLocalSaving,
    ]);
    const handleContinue = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        setHeader(t('common:payment'));
        if (activeStep === 0) {
            const stepOneValidation = yield trigger([
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-contact`,
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-phone`,
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-name`,
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-email`,
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-weeklySchedule`,
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-openHour`,
                `${schemas_1.EShipmentLocationTypeV1.DESTINATION}-closeHour`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-contact`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-phone`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-name`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-email`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-weeklySchedule`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-openHour`,
                `${schemas_1.EShipmentLocationTypeV1.ORIGIN}-closeHour`,
            ]);
            if (!stepOneValidation)
                return;
        }
        if (activeStep === 1) {
            const stepTwoValidationFields = ['paymentMethod', 'areTermsAndConditionsAccepted'];
            if (isAddCardFormShown) {
                stepTwoValidationFields.push(...['nameOnCard', 'addressLineOne', 'city', 'province', 'postalCode', 'country']);
            }
            else {
                stepTwoValidationFields.push('selectedCreditCardId');
            }
            const stepTwoValidation = yield trigger(stepTwoValidationFields);
            if (!stepTwoValidation)
                return;
            submitForm();
            return;
        }
        setActiveStep(activeStep + 1);
        return;
    }), [t, activeStep, trigger, isAddCardFormShown, submitForm]);
    const selectedPaymentMethod = watch('selectedPaymentMethod');
    (0, react_1.useEffect)(() => {
        if (selectedPaymentMethod === schemas_1.EPaymentMethodV1.CreditCard) {
            setCreditCardSurchargeDisclaimerShown(true);
        }
        else {
            setCreditCardSurchargeDisclaimerShown(false);
        }
    }, [selectedPaymentMethod, setCreditCardSurchargeDisclaimerShown]);
    const handleInit = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        yield handleUpdateQuoteCharges();
        setInit(true);
    }), [setInit, handleUpdateQuoteCharges]);
    (0, react_1.useEffect)(() => {
        if (externalError && !hasHandledExternalError) {
            handleExternalError(externalError);
            setExternalErrorHandled(true);
            clearExternalError();
        }
    }, [
        externalError,
        hasHandledExternalError,
        setExternalErrorHandled,
        handleExternalError,
        clearExternalError,
    ]);
    (0, react_1.useEffect)(() => {
        if (!hasInit) {
            handleInit();
        }
    }, [hasInit, handleInit]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.QuoteRequestSidebarCountdown, { carrierRequests: [carrierQuoteData], className: classes.countdown, isAccepted: false, isExpired: false, isRefused: false }),
        react_1.default.createElement("input", Object.assign({ type: "hidden", value: quoteAcceptanceReason || '' }, register('acceptanceReason', { required: true }))),
        react_1.default.createElement(ux_1.FlatCard, { className: classes.card },
            react_1.default.createElement(ux_1.Box, { pb: 4, pt: 4 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                    react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
                        react_1.default.createElement(ux_1.Box, { mt: 3.5 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.header }, header))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { ml: 3, mr: 3 },
                            react_1.default.createElement(ux_1.Box, { className: classes.stepOneContainer },
                                react_1.default.createElement(__1.QuoteRequestCheckoutDetails, { carrierQuoteData: carrierQuoteData, companyLocations: companyLocations, control: control, defaultLocationHours: defaultLocationHours, defaultLocationSchedule: defaultLocationSchedule, t: t, watch: watch })),
                            react_1.default.createElement(ux_1.Box, { className: classes.stepTwoContainer },
                                react_1.default.createElement(__1.QuoteRequestCheckoutPayment, { ApplyForPaymentTerms: ApplyForPaymentTerms, areUpdatedChargesLoading: areUpdatedChargesLoading, bambora: bambora, bankAccounts: bankAccounts, canSetQuoteTaxExemptStatus: canSetQuoteTaxExemptStatus, carrierQuoteData: carrierQuoteData, company: company, companySubscription: companySubscription, control: control, creditCards: creditCards, getValues: getValues, handleRouteToSubscription: handleRouteToSubscription, handleRouteToTermsAndConditions: handleRouteToTermsAndConditions, handleUpdateQuoteCharges: handleUpdateQuoteCharges, isActive: activeStep === 1, isAddCardFormShown: isAddCardFormShown, isPaymentDataLoading: isPaymentDataLoading, language: language, paymentTerms: paymentTerms, quoteCharges: quoteCharges, register: register, setIsAddCardFormShown: setIsAddCardFormShown, setSubmitDisabled: setSubmitDisabled, setValue: setValue, shipmentCredits: shipmentCredits, shipperQuoteData: shipperQuoteData, shouldBlockCreditRedemption: shouldBlockCreditRedemption, t: t, validPaymentMethods: validPaymentMethods, watch: watch })))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { ml: 4, mr: 4, mt: 3 },
                            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
                                react_1.default.createElement(ux_1.Grid, { item: true },
                                    react_1.default.createElement(ux_1.FlatButton, { color: "primaryLight", "date-testid": 'QuoteRequestCheckout-BackButton', disabled: isLocalSaving || isSaving, onClick: handleReturn, variant: "outlined" }, t('common:back'))),
                                react_1.default.createElement(ux_1.Grid, { item: true },
                                    react_1.default.createElement(__1.SaveButton, { color: "primaryLight", customAction: handleContinue, isDisabled: isSubmitDisabled, isSaving: isLocalSaving || isSaving, t: t, testId: `QuoteRequestCheckout-${activeStep}` }, t('common:continue'))))))),
                react_1.default.createElement(ux_1.Collapse, { in: isCreditCardSurchargeDisclaimerShown && activeStep === 1 },
                    react_1.default.createElement(ux_1.Box, { ml: 4, mr: 4, mt: 2 },
                        react_1.default.createElement(ux_1.Typography, { variant: "bodyXSmall" },
                            react_1.default.createElement("sup", null, "1"),
                            " ",
                            t('common:creditCardSurchargeDisclaimer'))))))));
};
exports.QuoteRequestCheckout = QuoteRequestCheckout;
