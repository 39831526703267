import React from 'react';
import { ShipmentShipperListWrapper } from '../../components';
import { ShipperPage } from '../../pageTemplates';
import { TTruxwebPageContentProps } from '../../types';

export const ShipperShipmentsPage = ({
  ipAddress,
  pageId,
}: TTruxwebPageContentProps): JSX.Element => {
  return (
    <ShipperPage ipAddress={ipAddress} pageId={pageId}>
      <ShipmentShipperListWrapper pageId={pageId} />
    </ShipperPage>
  );
};
