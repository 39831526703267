"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMeteorIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9,24a8.971,8.971,0,0,1-6.364-2.632h0a9.01,9.01,0,0,1,0-12.728C4.892,6.38,9.816,3.108,12.55,1.358A2.242,2.242,0,0,1,16,3.24l4.86-2.9a2.023,2.023,0,0,1,2.533.283,2.034,2.034,0,0,1,.279,2.564L20.771,8a2.242,2.242,0,0,1,1.871,3.449c-1.748,2.734-5.019,7.655-7.278,9.915A8.971,8.971,0,0,1,9,24ZM4.05,19.95a7.009,7.009,0,0,0,9.9,0c2.125-2.126,5.3-6.914,7.007-9.579a.229.229,0,0,0,.009-.245A.232.232,0,0,0,20.751,10H19a1,1,0,0,1-.856-1.517l3.832-6.349-.028-.111L15.512,5.859A1,1,0,0,1,14,5V3.249a.241.241,0,0,0-.372-.206C10.962,4.749,6.172,7.928,4.05,10.05a7.01,7.01,0,0,0,0,9.9ZM9,19c-5.275-.138-5.273-7.863,0-8C14.275,11.138,14.273,18.863,9,19Zm0-6a2,2,0,0,0,0,4A2,2,0,0,0,9,13Z" })));
exports.default = TruxwebMeteorIcon;
