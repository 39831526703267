import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierEquipmentV1 } from '@truxweb/schemas';

export const updateEquipment = async (
  equipment: TCarrierEquipmentV1[]
): Promise<TCarrierEquipmentV1[]> => {
  const results = await makeRequestToApiGateway(
    'carrierPatchV1EquipmentCarrier',
    null,
    {
      'Content-Type': 'application/json',
    },
    equipment
  );
  return results as TCarrierEquipmentV1[];
};
