// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTermsStatusV1 = void 0;
var PaymentTermsStatusV1;
(function (PaymentTermsStatusV1) {
    PaymentTermsStatusV1["SUBMITTED"] = "SUBMITTED";
    PaymentTermsStatusV1["REVISE"] = "REVISE";
    PaymentTermsStatusV1["REJECTED"] = "REJECTED";
    PaymentTermsStatusV1["APPROVED"] = "APPROVED";
})(PaymentTermsStatusV1 || (exports.PaymentTermsStatusV1 = PaymentTermsStatusV1 = {}));
