import { makeRequestToApiGateway } from '../../apiUtils';
import { TShipperShipmentQuoteRequestV1 } from '@truxweb/schemas';

export const fetchShipperQuoteRequestById = async (
  quoteId: number
): Promise<TShipperShipmentQuoteRequestV1> => {
  const results = await makeRequestToApiGateway('shipmentGetV1ShipperQuoteById', {
    id: quoteId,
  });
  const { quoteRequest } = results as { quoteRequest: TShipperShipmentQuoteRequestV1 };
  return quoteRequest;
};
