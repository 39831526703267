"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHumidityIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10,17a1,1,0,0,1-.831-1.555l4-6a1,1,0,0,1,1.664,1.11l-4,6A1,1,0,0,1,10,17Zm9.779,3.778c6.858-7.336,1.519-14.521-4.565-19.6h0a4.947,4.947,0,0,0-6.426,0C2.706,6.231-2.63,13.491,4.222,20.778a11,11,0,0,0,15.556,0ZM13.919,2.7h0C18.7,6.777,24.43,12.966,18.364,19.364a9.043,9.043,0,0,1-12.728,0c-6.071-6.4-.325-12.6,4.445-16.662a2.958,2.958,0,0,1,3.838,0ZM8,10a1,1,0,0,0,2,0A1,1,0,0,0,8,10Zm6,6a1,1,0,0,0,2,0A1,1,0,0,0,14,16Z" })));
exports.default = TruxwebHumidityIcon;
