"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFscValue = exports.getFscValueBasedOnEquipmentPrice = exports.getFscRate = void 0;
const getFscRate = (equipmentPrice, fscValue) => {
    return ((fscValue / equipmentPrice) * 100).toFixed(2);
};
exports.getFscRate = getFscRate;
const getFscValueBasedOnEquipmentPrice = (equipmentPrice, fscRate) => {
    return (equipmentPrice * (fscRate / 100)).toFixed(2);
};
exports.getFscValueBasedOnEquipmentPrice = getFscValueBasedOnEquipmentPrice;
const getFscValue = (equipmentPrice, fscRate) => {
    return (equipmentPrice * (fscRate / 100)).toFixed(2);
};
exports.getFscValue = getFscValue;
