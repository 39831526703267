import { Box, CircularProgress, Grid, TruxwebAngleRightIcon } from '@truxweb/ux';
import {
  CarrierFuelSurchargeManagement,
  EnumCheckboxGroup,
  FormField,
  SaveButton,
} from '@truxweb/common-components';
import { ETruckloadTypeV1, TCarrierFuelSurchargeV1, TCarrierV1 } from '@truxweb/schemas';
import React, { useCallback, useEffect, useState } from 'react';
import { useCarrierData, useErrorHandling } from '../../hooks';
import { ComingSoon } from '..';
import { IS_FUEL_SURCHARGE_ACTIVE } from '../../config';
import { updateCarrier } from '../../actions';
import { useForm } from 'react-hook-form';
import { useStyles } from './AccountCarrierSettings.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const AccountCarrierSettings = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [shouldCompanyDataRefetch, setShouldCompanyDataRefetch] = useState(false);
  const [carrierData, hasCarrierDataLoaded] = useCarrierData(shouldCompanyDataRefetch);
  const [isLoading, setIsLoading] = useState(false);
  const errorHandler = useErrorHandling();

  const [isFuelSurchargeEnabled, setFuelSurchargeEnabled] = useState(false);
  const [activeSettings, setActiveSettings] = useState(null);
  const [activeShipmentTypes, setActiveShipmentTypes] = useState<ETruckloadTypeV1[]>([]);

  const { control, handleSubmit, setValue, watch } = useForm({});
  const handleFuelSurchargeUpdate = useCallback(
    async (data: TCarrierFuelSurchargeV1 & { fuelSurchargeEnabled: Record<string, any> }) => {
      const carrierUpdate: TCarrierV1 = { ...carrierData };
      Object.keys(data.fuelSurchargeEnabled).forEach((key) => {
        if (data.fuelSurchargeEnabled[key] === true) {
          const carrierKey =
            key === ETruckloadTypeV1.LTL
              ? 'isLtlFuelSurchargeEnabled'
              : 'isFtlFuelSurchargeEnabled';
          carrierUpdate[carrierKey] = true;
        }
      });

      let surcharges = [...carrierUpdate.fuelSurcharge].filter((data) => data);
      if (!carrierUpdate.fuelSurcharge) {
        surcharges = [data];
      } else if (!data.id) {
        const defaultIndex = surcharges.findIndex(
          ({ fuelSurchargeId }) => fuelSurchargeId === data.fuelSurchargeId
        );
        if (defaultIndex < 0) {
          surcharges.push(data);
        } else {
          surcharges[defaultIndex] = data;
        }

        carrierUpdate.fuelSurcharge.push(data);
      } else {
        // If this fuel surcharge record has an id, find the index
        // of this in the carrierUpdate fuel surcharges and update that
        const surchargeIndex = carrierUpdate.fuelSurcharge.findIndex(({ id }) => id === data.id);
        if (surchargeIndex < 0) {
          surcharges.push(data);
        } else {
          surcharges[surchargeIndex] = data;
        }
      }
      carrierUpdate.fuelSurcharge = surcharges;
      setIsLoading(true);

      try {
        await updateCarrier(carrierUpdate);
        setShouldCompanyDataRefetch(true);
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsLoading(false);
      }
    },
    [carrierData, setIsLoading, errorHandler, setShouldCompanyDataRefetch]
  );

  const getActiveSettings = useCallback(
    (_enabledShipmentTypes: any[]) => {
      if (!hasCarrierDataLoaded) return null;
      return (carrierData.fuelSurcharge || []).find(({ isActive }) => {
        return isActive;
      });
    },
    [hasCarrierDataLoaded, carrierData]
  );

  watch((data) => {
    const enabledShipmentTypes: any[] = Object.keys(data.fuelSurchargeEnabled || {})
      .map((key) => {
        if (data.fuelSurchargeEnabled[key] === true) return key;
        return null;
      })
      .filter((value) => value);

    setActiveShipmentTypes(enabledShipmentTypes);

    if (!isFuelSurchargeEnabled) {
      setFuelSurchargeEnabled(Boolean(enabledShipmentTypes.length));
    }
  });

  useEffect(() => {
    if (!activeSettings && Boolean(activeShipmentTypes)) {
      const newSettings = getActiveSettings(activeShipmentTypes);

      Object.keys(newSettings || {}).forEach((key) => {
        setValue(key, newSettings[key]);
      });
      setActiveSettings(newSettings);
    }
  }, [activeSettings, activeShipmentTypes, setActiveSettings, setValue, getActiveSettings]);

  useEffect(() => {
    if (hasCarrierDataLoaded && !activeSettings) {
      const fuelSurchargeEnabled = {
        [ETruckloadTypeV1.FTL]: carrierData.isFtlFuelSurchargeEnabled,
        [ETruckloadTypeV1.LTL]: carrierData.isLtlFuelSurchargeEnabled,
      };
      setValue('fuelSurchargeEnabled', fuelSurchargeEnabled);
      const newSettings = getActiveSettings(activeShipmentTypes);
      setActiveSettings(newSettings);
      setFuelSurchargeEnabled(Boolean(activeShipmentTypes.length));
      Object.keys(newSettings || {}).forEach((key) => {
        setValue(key, newSettings[key]);
      });
    }
  }, [
    hasCarrierDataLoaded,
    setValue,
    setActiveSettings,
    activeShipmentTypes,
    carrierData,
    getActiveSettings,
    activeSettings,
  ]);

  useEffect(() => {
    if (shouldCompanyDataRefetch) {
      setShouldCompanyDataRefetch(false);
    }
  }, [shouldCompanyDataRefetch, setShouldCompanyDataRefetch]);

  if (!hasCarrierDataLoaded) {
    return (
      <Grid container item justifyContent="center" xs>
        <CircularProgress />
      </Grid>
    );
  }
  if (!IS_FUEL_SURCHARGE_ACTIVE) return <ComingSoon />;
  return (
    <Box className={classes.subscriptionBox} mt={3} p={3}>
      <Box ml={3} mt={3}>
        <form onSubmit={handleSubmit(handleFuelSurchargeUpdate)}>
          <FormField
            areOnlyChildrenShown
            control={control}
            defaultValue={{ [ETruckloadTypeV1.FTL]: false, [ETruckloadTypeV1.LTL]: false }}
            id={'fuelSurchargeEnabled'}
            label={t('common:fuelSurchargeEnabled')}
            shouldOverrideLabelStyles
            t={t}
          >
            {(onChange, value) => {
              return (
                <EnumCheckboxGroup
                  key={JSON.stringify(value)}
                  onChange={onChange}
                  sourceEnum={ETruckloadTypeV1}
                  t={t}
                  value={value}
                />
              );
            }}
          </FormField>
          {isFuelSurchargeEnabled && (
            <Box mr={2} mt={3}>
              <Grid alignItems="center" container item justifyContent="space-between" xs>
                <Grid item xs>
                  <Box mr={1}>
                    <CarrierFuelSurchargeManagement
                      activeFuelSurcharge={activeSettings}
                      control={control}
                      enabledFields={activeShipmentTypes}
                      labelSuffix={t('common:currentWeek')}
                      section="currentPercent"
                      t={t}
                    />
                  </Box>
                </Grid>
                <Grid container item justifyContent="center" xs={1}>
                  <TruxwebAngleRightIcon />
                </Grid>
                <Grid item xs>
                  <Box ml={1}>
                    <CarrierFuelSurchargeManagement
                      activeFuelSurcharge={activeSettings}
                      control={control}
                      enabledFields={activeShipmentTypes}
                      labelSuffix={t('common:nextWeek')}
                      section="upcomingPercent"
                      t={t}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mt={3}>
            <Grid container item justifyContent="flex-end" xs>
              <SaveButton isSaving={isLoading} t={t}>
                {t('common:save')}
              </SaveButton>
            </Grid>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
