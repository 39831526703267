"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSnowflakeIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.761,13.971l-2.445.646a2.707,2.707,0,0,0-.981.492l-2.617-1.522a4.7,4.7,0,0,0,0-3.174l2.617-1.522a2.707,2.707,0,0,0,.981.492s2.617.679,2.7.679A1,1,0,0,0,23.272,8.1l-2.444-.647a.715.715,0,0,1-.508-.873l.647-2.444a1,1,0,1,0-1.934-.512l-.646,2.444a2.687,2.687,0,0,0-.058,1.1L15.713,8.684A4.978,4.978,0,0,0,13,7.1v-3a2.677,2.677,0,0,0,.919-.6l1.788-1.788A1,1,0,0,0,14.293.293L12.505,2.081a.718.718,0,0,1-1.01,0L9.707.293A1,1,0,0,0,8.293,1.707L10.081,3.5A2.677,2.677,0,0,0,11,4.1v3A4.978,4.978,0,0,0,8.287,8.684L5.671,7.162a2.687,2.687,0,0,0-.058-1.1L4.967,3.62a1,1,0,1,0-1.934.512L3.68,6.576a.715.715,0,0,1-.508.873L.728,8.1a1,1,0,0,0,.254,1.966c.085,0,2.7-.679,2.7-.679a2.707,2.707,0,0,0,.981-.492l2.617,1.522a4.7,4.7,0,0,0,0,3.174L4.665,15.109a2.707,2.707,0,0,0-.981-.492l-2.445-.646A1,1,0,0,0,.728,15.9l2.444.647a.715.715,0,0,1,.508.873l-.647,2.444a1,1,0,1,0,1.934.512l.646-2.444a2.687,2.687,0,0,0,.058-1.1l2.616-1.522A4.978,4.978,0,0,0,11,16.9v3a2.691,2.691,0,0,0-.919.6L8.293,22.293a1,1,0,0,0,1.414,1.414L11.5,21.919a.718.718,0,0,1,1.01,0l1.788,1.788a1,1,0,0,0,1.414-1.414l-1.788-1.788A2.691,2.691,0,0,0,13,19.9v-3a4.978,4.978,0,0,0,2.713-1.583l2.616,1.522a2.687,2.687,0,0,0,.058,1.1l.646,2.444a1,1,0,0,0,1.934-.512l-.647-2.444a.715.715,0,0,1,.508-.873l2.444-.647a1,1,0,1,0-.511-1.933ZM12,15a3,3,0,0,1,0-6A3,3,0,0,1,12,15Z" })));
exports.default = TruxwebSnowflakeIcon;
