"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Login = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const Login = ({ handleError, handleForgotPassword, handleSignIn, hasExternalLoadingState, isExternalLoadingState, loginHeader, loginPrompt, registrationLink, t, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const handleSignInSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            yield handleSignIn(data.email, data.password);
        }
        catch (err) {
            handleError(err);
        }
        finally {
            setIsLoading(false);
        }
    }), [setIsLoading, handleError, handleSignIn]);
    return (react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "column", justifyContent: registrationLink !== null ? 'space-between' : 'space-evenly', style: { height: '100%' } },
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Typography, { color: "primary", variant: "h3" }, loginHeader)),
        react_1.default.createElement(ux_1.Grid, { item: true, style: { width: '100%' } },
            loginPrompt,
            react_1.default.createElement(__1.SignIn, { handleForgotPassword: handleForgotPassword, isLoading: hasExternalLoadingState ? Boolean(isExternalLoadingState) : isLoading, onSubmitSuccess: handleSignInSuccess, t: t })),
        registrationLink));
};
exports.Login = Login;
