"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebExpandArrowsAltIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "arrow-circle-down", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.7,4.724l1.595,1.594A1,1,0,0,0,24,5.613l0-4.6a1,1,0,0,0-1-1H18.393a1,1,0,0,0-.707,1.705l1.6,1.595L12,10.587,4.709,3.3,6.314,1.7A1,1,0,0,0,5.607-.008L1-.01a1,1,0,0,0-1,1v4.6A1,1,0,0,0,1.709,6.3L3.3,4.714,10.587,12,3.305,19.276l-1.6-1.595A1,1,0,0,0,0,18.387l0,4.6a1,1,0,0,0,1,1H5.607a1,1,0,0,0,.707-1.7L4.719,20.689,12,13.413l7.282,7.275-1.6,1.6a1,1,0,0,0,.707,1.7l4.605,0a1,1,0,0,0,1-1v-4.6a1,1,0,0,0-1.707-.707L20.7,19.275,13.414,12Z" })));
exports.default = TruxwebExpandArrowsAltIcon;
