import { AccountCarrierCompany, AccountShipperCompany } from '..';
import { EUserTypeV1 } from '@truxweb/schemas';
import React from 'react';

type TAccountCompanyProps = {
  userType: EUserTypeV1;
};

export const AccountCompany = ({ userType }: TAccountCompanyProps): JSX.Element => {
  if (!userType) return null;
  //FIXME: We should never examine user type directly.
  if (userType === EUserTypeV1.CARRIER) return <AccountCarrierCompany />;
  return <AccountShipperCompany />;
};
