"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSadCryIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,12a12.023,12.023,0,0,1-1.328,5.49,1,1,0,1,1-1.777-.916C24.369,10.166,19.314,1.829,12,2,4.831,1.842-.225,9.855,2.949,16.258a1,1,0,0,1-1.81.851C-2.669,9.424,3.4-.189,12,0A12.013,12.013,0,0,1,24,12ZM13.823,21.834a10.149,10.149,0,0,1-3.637,0A1,1,0,1,0,9.824,23.8a12.134,12.134,0,0,0,4.362,0,1,1,0,1,0-.363-1.967ZM10,12a1,1,0,0,0,1-1c0-1.892-1.232-4-3-4S5,9.108,5,11a1,1,0,0,0,2,0c0-1.054.679-2,1-2s1,.946,1,2A1,1,0,0,0,10,12Zm6-5c-1.768,0-3,2.108-3,4a1,1,0,0,0,2,0c0-1.054.679-2,1-2s1,.946,1,2a1,1,0,0,0,2,0C19,9.108,17.768,7,16,7ZM12,20c2.63-.074,2.63-5.927,0-6C9.37,14.074,9.37,19.927,12,20Zm6-6a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V15A1,1,0,0,0,18,14ZM6,14a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V15A1,1,0,0,0,6,14Z" })));
exports.default = TruxwebSadCryIcon;
