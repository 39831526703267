"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBikingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M5,23a5,5,0,1,1,5-5A5.006,5.006,0,0,1,5,23Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,5,15Zm14,8a5,5,0,1,1,5-5A5.006,5.006,0,0,1,19,23Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,19,15Zm-6,3V14a1,1,0,0,0-.349-.758l-2.286-1.965a.986.986,0,0,1-.348-.743.97.97,0,0,1,.274-.71l1.963-1.562a1.007,1.007,0,0,1,1.418.067l2.6,2.4a1,1,0,0,0,.679.266H20a1,1,0,0,0,0-2H17.34L15.063,6.892a2.973,2.973,0,0,0-4.105-.152L8.994,8.3a3,3,0,0,0,.068,4.491L11,14.459V18a1,1,0,0,0,2,0ZM16.5,1A2.5,2.5,0,1,0,19,3.5,2.5,2.5,0,0,0,16.5,1Z" })));
exports.default = TruxwebBikingIcon;
