"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDiceD8Icon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.8,8.759,14.994,1.337A4,4,0,0,0,12-.011h0a4,4,0,0,0-2.987,1.34L2.169,8.784A5,5,0,0,0,2.2,15.248L9.023,22.67a4,4,0,0,0,3,1.349h.006a4,4,0,0,0,2.985-1.344l6.828-7.466A5.005,5.005,0,0,0,21.8,8.759Zm-8.3-6.083,6.791,7.4a3.017,3.017,0,0,1,.624,1.244L13,13.662V2.257A2.052,2.052,0,0,1,13.508,2.676Zm-3.018,0,.012-.013a2.031,2.031,0,0,1,.5-.408V13.662L3.07,11.32a2.9,2.9,0,0,1,.6-1.217Zm.019,18.655L3.7,13.928a3.093,3.093,0,0,1-.3-.424L11,15.747V21.74A2.085,2.085,0,0,1,10.509,21.33Zm3.012.01a2.035,2.035,0,0,1-.521.423V15.747L20.607,13.5a2.9,2.9,0,0,1-.275.394Z" })));
exports.default = TruxwebDiceD8Icon;
