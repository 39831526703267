import {
  EPermissionV1,
  type TAddCommentPayloadV1,
  type TCompanyV1,
  type TListShipmentsResponseV1,
  type TReducer,
  type TShipmentCommentAttachmentV1,
} from '@truxweb/schemas';

export * from './bambora';
export * from './forms';
export * from './pageIdType';
export * from './utils';
export * from './registration';
export * from './user';
export * from './cognito';
export * from './search';
export * from './fileUpload';
export * from './truxwebPageProps';
export * from './RegistrationFormSteps';
export * from './LoginFormSteps';
export * from './onDocumentUploadSuccess';
export * from './ShipmentDashboardDocumentItemDetails';

// FIXME: try and get these types out of react hook form
export type ReactHookFormControl = any;
export type ReactHookFormRegister = any;
export type ReactHookWatch = any;
export type ReactHookSetValue = any;
export type ReactHookFormSetError = any;

export enum ETicketType {
  contact = 'contact',
  subscription = 'subscription',
}
export enum EPermissionEvaluation {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  NOT_LOADED = 'NOT_LOADED',
}
export type TPermissionsEvalutionType = {
  isLoading: boolean;
  hasLoaded: boolean;
  result: boolean | null;
  age: Date;
};

export type TUserPermissionsType = {
  [key in EPermissionV1]?: TPermissionsEvalutionType;
};

export type TShipmentsResponse = {
  areShipmentsActive: boolean;
  shipments: TListShipmentsResponseV1[];
};
export type TIconState = {
  active: boolean;
  completed: boolean;
};

export type TGetStepIconProps = TIconState & {
  icon: JSX.Element;
  className?: string;
};

export type TReducerLoading<T = any> = TReducer<T>;

export enum EApplicationActions {
  'LOGOUT',
}

export type TReduxListResponse<T> = TReducerLoading<T>;

export enum ECarrierQuoteRequestSegments {
  REQUEST_AWAIT = 'requests',
  REQUEST_CONFIRMED = 'requests/confirmed',
  REQUEST_REFUSED = 'requests/refused',
  REQUEST_REVIEW = 'requests/examination',
}

export enum ECarrierQuoteRequestColumns {
  ACTION = 'action',
  ADDITIONAL_SERVICES = 'accessorialCodes',
  CARRIER_NOTES = 'carrierNote',
  DESTINATION = 'locationDestination',
  DESTINATION_SEARCH = 'locationDestinationString',
  EQUIPMENT = 'equipmentCodes',
  HAS_BEEN_READ = 'isViewed',
  ORIGIN = 'locationOrigin',
  ORIGIN_SEARCH = 'locationOriginString',
  PICK_UP_DATE = 'bookingDateModifiedDate',
  PRICE = 'chargesModified',
  REFUSED_BY = 'refusedBy',
  REFUSED_REASON = 'refusedReason',
  QUOTE_REF = 'quoteRef',
  SENDER = 'shipperName',
  SHIPPER_NOTES = 'shipperNote',
  TENDENCY = 'totalCompetitors',
  TIME_REMAINING = 'countdownTimerTimeRemaining',
  SHIPMENT_TYPE = 'shipmentType',
  STATUS = 'carrierQuoteStatus',
  DATE_CREATED = 'dateCreated',
}

export enum EShipperQuoteRequestColumns {
  ACTION = 'action',
  ACTIVITY = 'totalResponses',
  ADDITIONAL_SERVICES = 'accessorialCodes',
  CARRIER_NAME = 'carrierName',
  CARRIER_NOTES = 'carrierNote',
  DESTINATION = 'locationDestination',
  DESTINATION_SEARCH = 'locationDestinationString',
  EQUIPMENT = 'equipmentCodes',
  HAS_BEEN_READ = 'isViewed',
  ORIGIN = 'locationOrigin',
  ORIGIN_SEARCH = 'locationOriginSearch',
  PICK_UP_DATE = 'bookingDateOriginal',
  DELIVERY_DATE = 'estimatedDeliveryDate',
  PRICE = 'chargesModified',
  QUOTE_REF = 'quoteRef',
  REFUSED_BY = 'refusedBy',
  SHIPPER_NOTES = 'shipperNote',
  TIME_REMAINING = 'countdownTimerTimeRemaining',
  SHIPMENT_TYPE = 'shipmentType',
  DATE_CREATED = 'dateCreated',
  NUMBER_ACCEPTED = 'numberAccepted',
  NUMBER_REFUSED = 'numberRefused',
  NUMBER_SOLICITED = 'numberSolicited',
}

export enum EShipperQuoteRequestSegment {
  REQUEST_AWAIT = 'requests',
  REQUEST_CONFIRMED = 'requests/confirmed',
  REQUEST_REFUSED = 'requests/refused',
}

export type TCompanyToValidate = Omit<TCompanyV1, 'companyExtId'>;

export type TShipmentCommentAttachment = Omit<
  TShipmentCommentAttachmentV1,
  'id' | 'shipmentCommentId' | 'dateCreated' | 'dateUpdated'
>;

export type TSubmitAddCommentPayload = Omit<TAddCommentPayloadV1, 'attachments'> & {
  _tempId: string;
  attachments?: TShipmentCommentAttachment[];
};
