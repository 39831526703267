import {
  type AppState,
  type AppThunk,
  setShipmentListData,
  setShipmentListError,
  setShipmentListLoading,
} from '../../../stores';
import {
  ELanguageV1,
  EShipmentDisplayStatusV1,
  EUserTypeV1,
  type TListResponseWrapper,
  type TListShipmentsResponseV1,
} from '@truxweb/schemas';
import { type GridFilterModel, type GridSortModel } from '@truxweb/ux';
import { availableEndpoints } from '../../../config';
import { makeRequestToApiGateway } from '../../../apiUtils';

type TFetchShipments = {
  filter?: GridFilterModel | null;
  userType: EUserTypeV1;
  limit: number;
  pageSize?: number;
  page?: number;
  sort?: GridSortModel;
  language: ELanguageV1;
};

export const fetchShipments =
  (request: TFetchShipments): AppThunk =>
  async (dispatch, getState) => {
    const { filter, language, limit, page, pageSize, sort, userType } = request;

    const endpoint =
      userType === EUserTypeV1.CARRIER
        ? 'shipmentGetV1CarrierShipmentListByStatusByLimit'
        : 'shipmentGetV1ShipperShipmentListByStatusByLimit';

    const state = getState() as unknown as AppState;
    const status = state.ux.shipmentTableStatuses;
    const groups = state.ux.shipmentTableGroup;

    try {
      dispatch(setShipmentListLoading());

      const fetchRequest = {
        filter,
        language,
        page,
        pageSize,
        sort,
        status,
      };
      const { results, resultsCount } = await fetchShipmentsListSegment<
        TListResponseWrapper<TListShipmentsResponseV1[]>
      >(endpoint, limit, 0, fetchRequest);

      // After fetching this data set, we want to store the filters used on the last load so that they can
      // be restored when the app refreshes
      const filterKey = `TRUXWEB_${userType}_SHIPMENT_TABLE_FILTERS`;
      localStorage.setItem(filterKey, JSON.stringify(groups));

      dispatch(setShipmentListData({ items: results, rowCount: resultsCount }));
    } catch (error) {
      dispatch(setShipmentListError({ error: error.message }));
    }
  };

type TFetchShipmentListSegmentRequest = {
  page?: number;
  pageSize?: number;
  language: ELanguageV1;
  status?: EShipmentDisplayStatusV1[];
  sort?: GridSortModel;
  filter?: GridFilterModel | null;
};
const fetchShipmentsListSegment = async <T>(
  endpoint: keyof typeof availableEndpoints,
  limit: number,
  offset: number,
  request: TFetchShipmentListSegmentRequest
): Promise<T> => {
  const { filter, language, page, pageSize, sort, status } = request;
  return await makeRequestToApiGateway<T>(
    endpoint,
    {
      limit: 0,
      status,
    },
    {
      'Content-Type': 'application/json',
    },
    null,
    {
      filter: filter ? JSON.stringify(filter) : false,
      language,
      limit: pageSize ? pageSize : limit,
      list: true,
      offset: pageSize && page ? pageSize * page : offset,
      sort: sort ? JSON.stringify(sort) : undefined,
    }
  );
};
