"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMapIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.68,1.167l-.021-.007-2.715-.9a5.017,5.017,0,0,0-2.9-.079L9.468,1.8A3.025,3.025,0,0,1,7.42,1.62l-.5-.232A5,5,0,0,0,0,6V18.075a5.013,5.013,0,0,0,3.6,4.8l2.869.9A4.991,4.991,0,0,0,7.964,24,4.592,4.592,0,0,0,9.3,23.819l5.8-1.6a3,3,0,0,1,1.617.013l2.343.676A4,4,0,0,0,24,19.021V5.876A5.01,5.01,0,0,0,20.68,1.167ZM4.176,20.959A3.013,3.013,0,0,1,2,18.075V6a2.953,2.953,0,0,1,1.336-2.5A3,3,0,0,1,5,3a2.9,2.9,0,0,1,1.112.222s.745.319.887.37V21.842ZM9,21.827V3.879a5.105,5.105,0,0,0,1.006-.155L15,2.273V20.2Zm13-2.806a2,2,0,0,1-2.433,1.954L17,20.252V2.075l3.018.979A3.006,3.006,0,0,1,22,5.876Z" })));
exports.default = TruxwebMapIcon;
