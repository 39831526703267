"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarAccounting = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_hook_form_1 = require("react-hook-form");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const ShipmentsDashboardSidebarAccounting_styles_1 = require("./ShipmentsDashboardSidebarAccounting.styles");
const ShipmentsDashboardSidebarAccounting = ({ additionalChargesCollapsedStates, areAdditionalChargesSaving, handleAddCharge, handleChargeRemoval, handleSubmitQuickPay, isCompanyQuickPaySaving, language, setAdditionalChargesCollapsedStates, setShouldNotMountAccounting, shipment, shouldAllowNegativeCarrierValues, shouldAllowNegativeTruxwebShipperCharges, t, }) => {
    var _a, _b;
    const [shouldCarrierChargesReset, setShouldCarrierAdditionalChargesReset] = (0, react_1.useState)(false);
    const classes = (0, ShipmentsDashboardSidebarAccounting_styles_1.useStyles)();
    const accordionClases = (0, ShipmentsDashboardSidebarAccounting_styles_1.useAccordionStyles)();
    const accordionSummaryClasses = (0, ShipmentsDashboardSidebarAccounting_styles_1.useAccordionSummaryStyles)();
    const accordionDetailsClasses = (0, ShipmentsDashboardSidebarAccounting_styles_1.useAccordionDetailsStyles)();
    const deliveryDate = ((_a = shipment === null || shipment === void 0 ? void 0 : shipment.shipment) === null || _a === void 0 ? void 0 : _a.deliveryDate) || ((_b = shipment === null || shipment === void 0 ? void 0 : shipment.shipment) === null || _b === void 0 ? void 0 : _b.estimatedDeliveryDate);
    /* CARRIER FORM MANAGEMENT */
    const carrierAdditionalCharges = (0, react_1.useMemo)(() => {
        return shipment.charges.filter((charge) => {
            return charge.chargeType === 'ADDITIONAL' && charge.recipient === schemas_1.EUserTypeV1.CARRIER;
        });
    }, [shipment]);
    const carrierFormTools = (0, react_hook_form_1.useForm)({
        defaultValues: {
            additionalCharges: carrierAdditionalCharges,
        },
    });
    const handleAddCarrierAdditionalCharge = (0, react_1.useCallback)((formData) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleAddCharge) {
            yield handleAddCharge(schemas_1.EUserTypeV1.CARRIER, schemas_1.EUserTypeV1.SHIPPER, formData.additionalCharges);
            setShouldCarrierAdditionalChargesReset(true);
        }
    }), [handleAddCharge, setShouldCarrierAdditionalChargesReset]);
    const handleCarrierChargeRemove = (0, react_1.useCallback)((id) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleChargeRemoval) {
            yield handleChargeRemoval('CARRIER', id);
            setShouldNotMountAccounting(true);
        }
    }), [handleChargeRemoval, setShouldNotMountAccounting]);
    /* TRUXWEB SHIPPER FORM MANAGEMENT */
    const truxwebShipperAdditionalCharges = (0, react_1.useMemo)(() => {
        return shipment.charges.filter((charge) => {
            return charge.chargeType === 'TRUXWEB_SHIPPER' && charge.recipient === schemas_1.EUserTypeV1.TRUXWEB;
        });
    }, [shipment]);
    const truxwebShipperFormTools = (0, react_hook_form_1.useForm)({
        defaultValues: {
            additionalCharges: truxwebShipperAdditionalCharges,
        },
    });
    const handleAddTruxwebShipperCharge = (0, react_1.useCallback)((formData) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleAddCharge) {
            yield handleAddCharge(schemas_1.EUserTypeV1.TRUXWEB, schemas_1.EUserTypeV1.SHIPPER, formData.additionalCharges);
            setShouldCarrierAdditionalChargesReset(true);
        }
    }), [handleAddCharge, setShouldCarrierAdditionalChargesReset]);
    const handleTruxwebShipperChargeRemoval = (0, react_1.useCallback)((id) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleChargeRemoval) {
            yield handleChargeRemoval('TRUXWEB_SHIPPER', id);
            setShouldNotMountAccounting(true);
        }
    }), [handleChargeRemoval, setShouldNotMountAccounting]);
    /* TRUXWEB CARRIER FORM MANAGEMENT */
    // NOTE: Truxweb Carrier additional charges have two components,
    // 1) A debit to the amount that the carrier is charged
    // 2) An increment to the amount that truxweb is paid
    // Here we show the _TRUXWEB_ side of the charges, as they are a positive number
    const truxwebCarrierAdditionalCharges = (0, react_1.useMemo)(() => {
        return shipment.charges.filter((charge) => {
            return charge.chargeType === 'TRUXWEB_CARRIER' && charge.recipient === schemas_1.EUserTypeV1.TRUXWEB;
        });
    }, [shipment]);
    const truxwebCarrierFormTools = (0, react_hook_form_1.useForm)({
        defaultValues: {
            additionalCharges: truxwebCarrierAdditionalCharges,
        },
    });
    const handleAddTruxwebCarrierCharge = (0, react_1.useCallback)((formData) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleAddCharge) {
            yield handleAddCharge(schemas_1.EUserTypeV1.TRUXWEB, schemas_1.EUserTypeV1.CARRIER, formData.additionalCharges);
            setShouldCarrierAdditionalChargesReset(true);
        }
    }), [handleAddCharge, setShouldCarrierAdditionalChargesReset]);
    const handleTruxwebCarrierChargeRemoval = (0, react_1.useCallback)((id) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleChargeRemoval) {
            yield handleChargeRemoval('TRUXWEB_CARRIER', id);
            setShouldNotMountAccounting(true);
        }
    }), [handleChargeRemoval, setShouldNotMountAccounting]);
    const quickPay = [schemas_1.EUserTypeV1.CARRIER, schemas_1.EUserTypeV1.SHIPPER].map((companyType) => {
        if (!handleSubmitQuickPay)
            return null;
        const paymentTerms = (shipment.paymentTerms || []).find((paymentTerms) => {
            return paymentTerms.companyType === companyType;
        });
        const isShipperCreditCard = companyType === schemas_1.EUserTypeV1.SHIPPER &&
            shipment.shipment.paymentMethod === schemas_1.EPaymentMethodV1.CreditCard;
        return (react_1.default.createElement(__1.ShipmentsDashboardSidebarCompanyPaymentTerms, { arePaymentTermsAdjustmentAvailable: !isShipperCreditCard, companyName: companyType === schemas_1.EUserTypeV1.CARRIER
                ? shipment.shipment.carrierName
                : shipment.shipment.shipperCompanyName, companyType: companyType, deliveryDate: deliveryDate, handleSubmitSuccess: handleSubmitQuickPay, isSaving: Boolean(isCompanyQuickPaySaving), key: companyType, language: language, paymentTermsData: paymentTerms, t: t }));
    });
    (0, react_1.useEffect)(() => {
        if (shouldCarrierChargesReset && !areAdditionalChargesSaving) {
            carrierFormTools.reset({ additionalCharges: carrierAdditionalCharges });
            setShouldCarrierAdditionalChargesReset(false);
            setShouldNotMountAccounting(true);
        }
    }, [
        shouldCarrierChargesReset,
        areAdditionalChargesSaving,
        setShouldCarrierAdditionalChargesReset,
        carrierFormTools,
        carrierAdditionalCharges,
        setShouldNotMountAccounting,
    ]);
    if (!handleSubmitQuickPay) {
        // eslint-disable-next-line no-console
        console.warn('ShipmentsDashboardSidebarAccounting not displayed as handleSubmitQuickPay and isCompanyQuickPaySaving are required to display');
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    if (!handleChargeRemoval) {
        // eslint-disable-next-line no-console
        console.warn('ShipmentsDashboardSidebarAccounting not displayed as handleChargeRemoval is required to display');
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    return (react_1.default.createElement(ux_1.Grid, { container: true },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:quickPay'))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 }, quickPay),
        [schemas_1.EShipmentStatusV1.AWAITING_CARRIER_INVOICE, schemas_1.EShipmentStatusV1.PENDING_APPROVAL].includes(shipment.shipment.status) && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:carrierAdditionalCharges'))),
            react_1.default.createElement(ux_1.Grid, { className: classes.additionalChargeContainer, item: true, xs: 12 },
                react_1.default.createElement(ux_1.Accordion, { TransitionProps: { unmountOnExit: true }, classes: accordionClases, expanded: additionalChargesCollapsedStates.carrier, onChange: (_event, expanded) => {
                        setAdditionalChargesCollapsedStates(Object.assign(Object.assign({}, additionalChargesCollapsedStates), { carrier: expanded }));
                    } },
                    react_1.default.createElement(ux_1.AccordionSummary, { classes: accordionSummaryClasses, expandIcon: react_1.default.createElement(ux_1.ExpandMoreIcon, null) },
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:carrierAdditionalCharges'))),
                    react_1.default.createElement(ux_1.AccordionDetails, { classes: accordionDetailsClasses },
                        react_1.default.createElement(react_hook_form_1.FormProvider, Object.assign({}, carrierFormTools),
                            react_1.default.createElement("form", { onSubmit: carrierFormTools.handleSubmit(handleAddCarrierAdditionalCharge) },
                                react_1.default.createElement(__1.ShipmentDashboardAdditionalCharges, { additionalChargesPrompt: t('shipments:additionalChargesPrompt'), areAdditionalChargesSaving: areAdditionalChargesSaving, carrierCurrency: shipment.charges[0].charge.currency, chargeRecipient: schemas_1.EUserTypeV1.CARRIER, defaultAdditionalChargesState: schemas_1.EYesNoV1.YES, handleChargeRemoval: handleCarrierChargeRemove, isPerChargeSavedEnabled: true, isUploading: false, shouldConfirmChargeRemoval: true, shouldHideRadioButtons: true, sourceEnum: schemas_1.EShipmentAdditionalChargeTypeV1, t: t }))))),
                react_1.default.createElement(ux_1.Collapse, { in: !additionalChargesCollapsedStates.carrier },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                        carrierAdditionalCharges.length,
                        " Charge(s)"))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyLarge" }, t('shipments:truxwebAdditionalCharges'))),
            react_1.default.createElement(ux_1.Grid, { className: classes.additionalChargeContainer, item: true, xs: 12 },
                react_1.default.createElement(ux_1.Accordion, { TransitionProps: { unmountOnExit: true }, classes: accordionClases, expanded: additionalChargesCollapsedStates.truxwebShipper, onChange: (_event, expanded) => {
                        setAdditionalChargesCollapsedStates(Object.assign(Object.assign({}, additionalChargesCollapsedStates), { truxwebShipper: expanded }));
                    } },
                    react_1.default.createElement(ux_1.AccordionSummary, { classes: accordionSummaryClasses, expandIcon: react_1.default.createElement(ux_1.ExpandMoreIcon, null) },
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('shipments:truxwebShipperAdditionalCharges'))),
                    react_1.default.createElement(ux_1.AccordionDetails, { classes: accordionDetailsClasses },
                        react_1.default.createElement(react_hook_form_1.FormProvider, Object.assign({}, truxwebShipperFormTools),
                            react_1.default.createElement("form", { onSubmit: truxwebShipperFormTools.handleSubmit(handleAddTruxwebShipperCharge) },
                                react_1.default.createElement(__1.ShipmentDashboardAdditionalCharges, { additionalChargesPrompt: t('shipments:truxwebShipperAdditionalChargesPrompt'), areAdditionalChargesSaving: areAdditionalChargesSaving, carrierCurrency: shipment.charges[0].charge.currency, chargeRecipient: schemas_1.EUserTypeV1.TRUXWEB, defaultAdditionalChargesState: schemas_1.EYesNoV1.YES, handleChargeRemoval: handleTruxwebShipperChargeRemoval, isPerChargeSavedEnabled: true, isUploading: false, shouldAllowNegativeNumbers: shouldAllowNegativeTruxwebShipperCharges, shouldConfirmChargeRemoval: true, shouldHideRadioButtons: true, shouldSuppressPromptNote: true, sourceEnum: schemas_1.EShipmentTruxwebAdditionalChargeTypeV1, t: t }))))),
                react_1.default.createElement(ux_1.Collapse, { in: !additionalChargesCollapsedStates.truxwebShipper },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                        truxwebShipperAdditionalCharges.length,
                        " Charge(s)"))),
            react_1.default.createElement(ux_1.Grid, { className: classes.additionalChargeContainer, item: true, xs: 12 },
                react_1.default.createElement(ux_1.Accordion, { TransitionProps: { unmountOnExit: true }, classes: accordionClases, expanded: additionalChargesCollapsedStates.truxwebCarrier, onChange: (_event, expanded) => {
                        setAdditionalChargesCollapsedStates(Object.assign(Object.assign({}, additionalChargesCollapsedStates), { truxwebCarrier: expanded }));
                    } },
                    react_1.default.createElement(ux_1.AccordionSummary, { classes: accordionSummaryClasses, expandIcon: react_1.default.createElement(ux_1.ExpandMoreIcon, null) },
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('shipments:truxwebCarrierAdditionalCharges'))),
                    react_1.default.createElement(ux_1.AccordionDetails, { classes: accordionDetailsClasses },
                        react_1.default.createElement(react_hook_form_1.FormProvider, Object.assign({}, truxwebCarrierFormTools),
                            react_1.default.createElement("form", { onSubmit: truxwebCarrierFormTools.handleSubmit(handleAddTruxwebCarrierCharge) },
                                react_1.default.createElement(__1.ShipmentDashboardAdditionalCharges, { additionalChargesPrompt: t('shipments:truxwebCarrierAdditionalChargesPrompt'), areAdditionalChargesSaving: areAdditionalChargesSaving, carrierCurrency: shipment.charges[0].charge.currency, chargeRecipient: schemas_1.EUserTypeV1.TRUXWEB, defaultAdditionalChargesState: schemas_1.EYesNoV1.YES, handleChargeRemoval: handleTruxwebCarrierChargeRemoval, isPerChargeSavedEnabled: true, isUploading: false, shouldAllowNegativeNumbers: shouldAllowNegativeCarrierValues, shouldConfirmChargeRemoval: true, shouldHideRadioButtons: true, shouldSuppressPromptNote: true, sourceEnum: schemas_1.EShipmentTruxwebAdditionalChargeTypeV1, t: t }))))),
                react_1.default.createElement(ux_1.Collapse, { in: !additionalChargesCollapsedStates.truxwebCarrier },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                        truxwebCarrierAdditionalCharges.length,
                        " Charge(s)")))))));
};
exports.ShipmentsDashboardSidebarAccounting = ShipmentsDashboardSidebarAccounting;
