"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAP_THEME = void 0;
exports.MAP_THEME = [
    {
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#1d2c4d',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#8ec3b9',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#1a3646',
            },
        ],
    },
    {
        elementType: 'geometry.stroke',
        featureType: 'administrative.country',
        stylers: [
            {
                color: '#4b6878',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'administrative.land_parcel',
        stylers: [
            {
                color: '#64779e',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'geometry.stroke',
        featureType: 'administrative.province',
        stylers: [
            {
                color: '#4b6878',
            },
        ],
    },
    {
        elementType: 'geometry.stroke',
        featureType: 'landscape.man_made',
        stylers: [
            {
                color: '#334e87',
            },
        ],
    },
    {
        elementType: 'geometry',
        featureType: 'landscape.natural',
        stylers: [
            {
                color: '#023e58',
            },
        ],
    },
    {
        elementType: 'geometry',
        featureType: 'poi',
        stylers: [
            {
                color: '#283d6a',
            },
        ],
    },
    {
        elementType: 'labels.text',
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'poi',
        stylers: [
            {
                color: '#6f9ba5',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        featureType: 'poi',
        stylers: [
            {
                color: '#1d2c4d',
            },
        ],
    },
    {
        elementType: 'geometry.fill',
        featureType: 'poi.park',
        stylers: [
            {
                color: '#023e58',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'poi.park',
        stylers: [
            {
                color: '#3C7680',
            },
        ],
    },
    {
        elementType: 'geometry',
        featureType: 'road',
        stylers: [
            {
                color: '#304a7d',
            },
        ],
    },
    {
        elementType: 'labels',
        featureType: 'road',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'road',
        stylers: [
            {
                color: '#98a5be',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        featureType: 'road',
        stylers: [
            {
                color: '#1d2c4d',
            },
        ],
    },
    {
        elementType: 'labels',
        featureType: 'road.arterial',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'geometry',
        featureType: 'road.highway',
        stylers: [
            {
                color: '#2c6675',
            },
        ],
    },
    {
        elementType: 'geometry.stroke',
        featureType: 'road.highway',
        stylers: [
            {
                color: '#255763',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'road.highway',
        stylers: [
            {
                color: '#b0d5ce',
            },
        ],
    },
    {
        elementType: 'labels.text.stroke',
        featureType: 'road.highway',
        stylers: [
            {
                color: '#023e58',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'geometry',
        featureType: 'water',
        stylers: [
            {
                color: '#0e1626',
            },
        ],
    },
    {
        elementType: 'labels.text',
        featureType: 'water',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'water',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];
