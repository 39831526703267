import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  icon: {
    color: '#636363',
  },
  messages: {
    color: theme.palette.primary.main,
  },
}));
