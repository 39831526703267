"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStatusRollupColor = exports.getActiveColor = exports.getStatusColor = void 0;
const schemas_1 = require("@truxweb/schemas");
// This has the required number of branches, not need to refactor
const getStatusColor = (status) => {
    switch (status) {
        // BOOKED
        case schemas_1.EShipmentStatusV1.REQUIRES_DOCUMENTS:
            return '#224882';
        // Active
        case schemas_1.EShipmentStatusV1.IN_TRANSIT:
            return '#4EAC2D';
        default:
            return '#636363';
    }
};
exports.getStatusColor = getStatusColor;
const getActiveColor = (isActive) => {
    if (isActive)
        return '#4EAC2D';
    return '#636363';
};
exports.getActiveColor = getActiveColor;
const getStatusRollupColor = (status, isDelayed, theme) => {
    let color = isDelayed ? theme.palette.error.main : theme.palette.success.main;
    if ([schemas_1.EShipmentStatusV1.REQUIRES_DOCUMENTS, schemas_1.EShipmentStatusV1.DELIVERED].includes(status)) {
        color =
            status === schemas_1.EShipmentStatusV1.REQUIRES_DOCUMENTS
                ? theme.palette.primary.main
                : theme.palette.grey[600];
    }
    if (status === schemas_1.EShipmentStatusV1.CANCELLED) {
        color = theme.palette.error.light;
    }
    return color;
};
exports.getStatusRollupColor = getStatusRollupColor;
