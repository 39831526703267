"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierDataGrid = void 0;
const tableColumns_1 = require("../../tableColumns");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const quote_utils_1 = require("@truxweb/quote-utils");
const hooks_1 = require("@truxweb/hooks");
const CarrierDataGrid_styles_1 = require("./CarrierDataGrid.styles");
const DEFAULT_ROW_HEIGHT = 115;
const DEFAULT_PAGE_SIZE = 10;
const ROW_COUNT_HEADER_FOOTTER_ADJ = 3.15;
const CarrierDataGrid = ({ adminCarrierList, areColumnsHidden, areFiltersEnabled, availableTags, columnKeys, currentSort, defaultSort, filterMode, handleColumnVisibilityChange, handleFilterChange, handleRouteToCarrier, handleSortingChange, isExportEnabled, isHeaderShown, isLoading, isLoadingPageData, language, lastLoadedDate, onPageChange, onPageSizeChange, pageSize, paginationMode, renderCompanyLink, rowCount, rowHeight, selectedRows, shouldForcePageSize, sortingMode, t, }) => {
    const classes = (0, CarrierDataGrid_styles_1.useStyles)();
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { areColumnsHidden: areColumnsHidden, areFiltersEnabled: areFiltersEnabled, isExportEnabled: isExportEnabled, isHeaderShown: isHeaderShown, isLazyLoading: isLoadingPageData, isLoading: isLoading, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, t: t }));
    };
    const dynamicPageSize = (0, hooks_1.useDynamicTableRowCount)({
        constraints: { lg: '1524px', xlg: '1824px' },
        defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
        isLoading: Boolean(isLoading),
        onPageSizeChange,
        tableRowSize: rowHeight || DEFAULT_ROW_HEIGHT,
        useMediaQuery: ux_1.useMediaQuery,
    });
    pageSize = shouldForcePageSize && Boolean(pageSize) ? pageSize : dynamicPageSize;
    const [displayCols, activeSort, gridHeight, tableRowHeight] = (0, react_1.useMemo)(() => {
        const fallbackText = t('common:na');
        const basicColumnDef = {
            classes,
            fallbackText,
            t,
        };
        const tableRowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
        const gridHeight = ((pageSize || DEFAULT_PAGE_SIZE) + ROW_COUNT_HEADER_FOOTTER_ADJ) * tableRowHeight;
        let activeSort = currentSort;
        const columns = [
            (0, tableColumns_1.carrierNameColumn)(Object.assign(Object.assign({}, basicColumnDef), { renderLink: renderCompanyLink })),
            {
                field: 'active',
                headerName: t('common:carrierRegistrationStatusCellHeader'),
                renderCell: ({ row: { active } }) => {
                    if (active)
                        return t('common:active');
                    return t('common:inactive');
                },
                valueGetter: ({ row }) => {
                    if (row.active)
                        return t('common:active');
                    return t('common:inactive');
                },
            },
            {
                field: 'pendingUpdates',
                headerName: t('common:pendingUpdatesCellHeader'),
                type: 'boolean',
            },
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { fallbackText: '0', field: 'activeLaneCount', headerName: t('common:activeLaneCount'), width: 124 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { fallbackText: '0', field: 'activeQuotes', headerName: t('common:activeQuotes'), width: 124 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { fallbackText: '0', field: 'activeShipments', headerName: t('common:activeShipments'), width: 124 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { fallbackText: '0', field: 'shipmentTotal', headerName: t('common:totalShipments'), width: 124 })),
            // NOTE: this column needs the appropriate data to populate
            (0, tableColumns_1.shipmentTextDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'lastBookedDate', headerName: t('common:lastBookedDate'), width: 176 })),
            (0, tableColumns_1.shipmentTextDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'dateUpdated', headerName: t('common:dateUpdated'), width: 176 })),
            (0, tableColumns_1.carrierActionColumn)(Object.assign(Object.assign({}, basicColumnDef), { handleRouteToCarrier })),
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags, field: 'tags', width: 350 })),
        ];
        const fullColumns = columns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        const availableCols = [];
        const displayCols = fullColumns.map((col) => {
            availableCols.push(col.field);
            if (col.disableColumnMenu)
                return col;
            if (!columnKeys) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) });
        });
        if ((activeSort === null || activeSort === void 0 ? void 0 : activeSort.length) && !availableCols.includes(activeSort[0].field)) {
            activeSort = defaultSort;
        }
        return [displayCols, activeSort, gridHeight, tableRowHeight];
    }, [
        renderCompanyLink,
        pageSize,
        handleRouteToCarrier,
        defaultSort,
        currentSort,
        columnKeys,
        rowHeight,
        classes,
        t,
        availableTags,
    ]);
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, currentSort: activeSort, data: adminCarrierList || [], defaultSort: defaultSort, filterMode: filterMode, gridHeight: gridHeight, handleCellClick: ({ field, row }) => {
            if (['shipmentTotal', 'activeShipments'].includes(field)) {
                handleRouteToCarrier(row.carrierId, 'shipments');
            }
            if (['activeQuotes'].includes(field)) {
                handleRouteToCarrier(row.carrierId, 'quotes');
            }
            if (!['action', 'carrier'].includes(field)) {
                handleRouteToCarrier(row.carrierId);
            }
        }, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: pageSize || DEFAULT_PAGE_SIZE, paginationMode: paginationMode, rowCount: rowCount, rowHeight: tableRowHeight, selectionModel: selectedRows, shouldDisableMultipleSelection: true, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.CarrierDataGrid = CarrierDataGrid;
