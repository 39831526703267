"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    button: {
        '&:hover': {
            color: theme.palette.primary.main,
            fill: theme.palette.primary.main,
        },
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fill: theme.palette.primary.light,
    },
}));
