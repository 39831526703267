"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    accordionButton: {
        background: 'white',
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    optionItem: {
        paddingBottom: theme.spacing(3),
    },
    optionsWrapper: {
        borderTop: '1px solid #C6E7F9',
        paddingTop: '24px',
        width: theme.spacing(95),
    },
    searchButton: {
        width: `${theme.spacing(16)}px !important`,
    },
    sectionTitles: {
        fontSize: '12px',
        fontWeight: 600,
    },
}));
exports.useAccordionStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        '& .MuiAccordionDetails-root': {
            display: 'block',
            padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
        },
        '& .MuiAccordionSummary-content': {
            display: 'block',
            flexGrow: 'inherit',
        },
        '& .MuiAccordionSummary-root': { display: 'flex', justifyContent: 'flex-start' },
        '& .MuiIconButton-root': {
            color: theme.palette.primary.light,
        },
        '& .MuiPaper-root': {
            backgroundColor: '#000',
        },
        backgroundColor: 'transparent',
        border: 'none',
        width: theme.spacing(85),
    },
}));
