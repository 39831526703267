// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotPersonaV1 = void 0;
var HubspotPersonaV1;
(function (HubspotPersonaV1) {
    HubspotPersonaV1["Shipper"] = "persona_1";
    HubspotPersonaV1["Investor"] = "persona_2";
    HubspotPersonaV1["Carrier"] = "persona_3";
    HubspotPersonaV1["Broker"] = "persona_4";
    HubspotPersonaV1["Other"] = "persona_5";
    HubspotPersonaV1["Supplier"] = "persona_6";
    HubspotPersonaV1["Partner"] = "persona_7";
})(HubspotPersonaV1 || (exports.HubspotPersonaV1 = HubspotPersonaV1 = {}));
