"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentCommentActionButton = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const ShipmentCommentActionButton = ({ comment, handleChangeTruxwebCommentVisibility, handleDeleteComment, handleHideActions, handleShowCommentActions, t, }) => {
    /*
     */
    const [anchorEl, setAnchorEl] = (0, react_1.useState)(null);
    const [isMenuShown, setMenuShown] = (0, react_1.useState)(false);
    return (react_1.default.createElement(ux_1.Box, { onMouseOver: handleShowCommentActions, style: {
            background: '#F2F2F2',
            borderRadius: '100%',
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
        } },
        react_1.default.createElement(ux_1.StandardButton, { "aria-describedby": 'shipmentCommentActions', onClick: (event) => {
                setAnchorEl(event.currentTarget);
                setMenuShown(true);
            } },
            react_1.default.createElement(ux_1.MoreVertIcon, { fontSize: 'small' })),
        react_1.default.createElement(ux_1.Popover, { anchorEl: anchorEl, anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
            }, id: 'shipmentCommentActions', onClose: () => {
                setMenuShown(false);
                handleHideActions();
            }, open: isMenuShown },
            react_1.default.createElement(ux_1.Box, { p: 2 },
                react_1.default.createElement(ux_1.StandardButton, { "aria-describedby": 'shipmentCommentActions', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (handleDeleteComment) {
                            yield handleDeleteComment(comment);
                        }
                    }) }, t('common:delete')),
                Boolean(handleChangeTruxwebCommentVisibility) && (react_1.default.createElement(ux_1.StandardButton, { "aria-describedby": 'shipmentCommentActions', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (handleDeleteComment) {
                            if (handleChangeTruxwebCommentVisibility) {
                                yield handleChangeTruxwebCommentVisibility(comment);
                            }
                        }
                    }) }, t('common:change')))))));
};
exports.ShipmentCommentActionButton = ShipmentCommentActionButton;
