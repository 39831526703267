import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TCompanyV1 } from '@truxweb/schemas';
import { type TReducerLoading } from '../types';

export type TCompanyData = TReducerLoading & {
  data: TCompanyV1 | null;
};

export interface ICompanyDataState {
  company: TCompanyData;
}

export const companySlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.company,
      };
    },
  },
  initialState: {
    company: { ...baseLoadingState, data: null },
  } as ICompanyDataState,
  name: 'company',
  reducers: {
    setCompanyError: (state, { payload: { error } }) => {
      return {
        ...state,
        company: manageDomainError(state.company, error),
      };
    },
    setCompanyLoaded: (state, { payload: { data } }) => {
      return {
        ...state,
        company: manageDomainLoaded(state.company, data),
      };
    },
    setCompanyLoading: (state) => {
      return { ...state, company: manageDomainLoading(state.company) };
    },
  },
});

export const {
  actions: { setCompanyError, setCompanyLoaded, setCompanyLoading },
} = companySlice;
