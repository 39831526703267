"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LtlCargoSpecification = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const constants_1 = require("../../constants");
const LtlCargoSpecification = ({ className, control, data, formValueName, index, isDisplayOnly, isTemperatureDataRequired, setValue, shouldDisplay, t, values, watch, }) => {
    const [isLengthDisabled, setLengthDisabled] = (0, react_1.useState)(true);
    const [isWidthDisabled, setWidthDisabled] = (0, react_1.useState)(true);
    const [hasInit, setHasInit] = (0, react_1.useState)(false);
    const dataKey = (0, react_1.useMemo)(() => {
        return formValueName || 'ltlCargo';
    }, [formValueName]);
    if (watch) {
        watch((data, { name }) => {
            if (name === `${dataKey}.${index}.packagingType`) {
                if ((0, utils_1.getObjectPropertyByString)(data, `${dataKey}.${index}.packagingType`)[0] ===
                    schemas_1.ELtlPackagingV1.STANDARD_PALLET) {
                    setValue(`${dataKey}.${index}.dimensions.length`, constants_1.STANDARD_PALLET_DIMENSIONS.length);
                    setValue(`${dataKey}.${index}.dimensions.width`, constants_1.STANDARD_PALLET_DIMENSIONS.width);
                    setValue(`${dataKey}.${index}.dimensions.height`, constants_1.STANDARD_PALLET_DIMENSIONS.height);
                    setLengthDisabled(true);
                    setWidthDisabled(true);
                }
                else {
                    setValue(`${dataKey}.${index}.dimensions.length`, 0);
                    setValue(`${dataKey}.${index}.dimensions.width`, 0);
                    setValue(`${dataKey}.${index}.dimensions.height`, 0);
                    setLengthDisabled(false);
                    setWidthDisabled(false);
                }
            }
            // IF the weight of a pallet is being changed we are giong to attempt
            // to automatically set the total weight
            if ([`${dataKey}.${index}.palletWeight`, `${dataKey}.${index}.palletCount`].includes(name)) {
                const palletWeight = (0, utils_1.getObjectPropertyByString)(data, `${dataKey}.${index}.palletWeight`)[0];
                const palletCount = (0, utils_1.getObjectPropertyByString)(data, `${dataKey}.${index}.palletCount`)[0];
                if (palletWeight !== 0 && palletCount !== 0) {
                    const totalWeight = palletWeight * palletCount;
                    setValue(`${dataKey}.${index}.weightInLbs`, totalWeight);
                }
            }
        });
    }
    (0, react_1.useEffect)(() => {
        if (!hasInit) {
            if (values) {
                if ([schemas_1.ELtlPackagingV1.STANDARD_PALLET].includes(values[`${dataKey}.${index}.packagingType`])) {
                    setLengthDisabled(true);
                    setWidthDisabled(true);
                }
                else {
                    setLengthDisabled(false);
                    setWidthDisabled(false);
                }
            }
            setHasInit(true);
        }
    }, [values, hasInit, setHasInit, dataKey, index, setValue]);
    if (isDisplayOnly) {
        return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:commodity'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, data === null || data === void 0 ? void 0 : data.commodity)),
            (data === null || data === void 0 ? void 0 : data.temperatureControlSpecifications) && (react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:temperatureControlSpecifications'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, data === null || data === void 0 ? void 0 : data.temperatureControlSpecifications))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:packagingType'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, t(`select:${data === null || data === void 0 ? void 0 : data.packagingType}`))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:stackable'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, (data === null || data === void 0 ? void 0 : data.stackable) ? t('common:stackable') : t('common:topLoadOnly'))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Grid, { container: true },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                            t('common:dimensions'),
                            ":\u00A0")),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(__1.CargoDimensions, { control: control, data: data, dataKey: dataKey, index: index, isDisplayOnly: true, isLengthDisabled: isLengthDisabled, isWidthDisabled: isWidthDisabled, shouldDisplay: true, t: t })))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:palletCount'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, data === null || data === void 0 ? void 0 : data.palletCount)),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:palletWeight'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, data === null || data === void 0 ? void 0 : data.palletWeight)),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:weightInLbs'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, data === null || data === void 0 ? void 0 : data.weightInLbs)),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { component: "span", fontStyle: "semibold" },
                    t('common:packageCount'),
                    ":\u00A0"),
                react_1.default.createElement(ux_1.Typography, { component: "span" }, (data === null || data === void 0 ? void 0 : data.packageCount) || t('common:na')))));
    }
    if (!control) {
        throw new Error(`control must be provided is this form is not display only`);
    }
    return (react_1.default.createElement(ux_1.Grid, { className: className, container: true, direction: "column", style: { display: shouldDisplay ? 'block' : 'none' } },
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                    shrink: true,
                }, control: control, defaultValue: '', id: `${dataKey}.${index}.commodity`, isRequired: true, label: t('common:commodity'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })),
        isTemperatureDataRequired && (react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: '', id: `${dataKey}.${index}.temperatureControlSpecifications`, isRequired: true, label: t('common:temperatureInformation'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(__1.FormField, { areOnlyChildrenShown: true, control: control, defaultValue: schemas_1.ELtlPackagingV1.STANDARD_PALLET, id: `${dataKey}.${index}.packagingType`, isRequired: true, label: t('common:packagingType'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t }, (onChange, value) => {
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(__1.EnumSelect, { label: t('common:packagingType'), onChange: onChange, shouldUseModernVariant: true, sourceEnum: schemas_1.ELtlPackagingV1, t: t, value: value, variant: "outlined" })));
                }))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: undefined, name: `${dataKey}.${index}.stackable`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        let displayValue = value;
                        if (value !== undefined) {
                            displayValue = value ? schemas_1.EYesNoV1.YES : schemas_1.EYesNoV1.NO;
                        }
                        return (react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "column" },
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Box, { mr: 1 },
                                    react_1.default.createElement(ux_1.Typography, { color: Boolean(error) ? 'error' : undefined, fontStyle: "semibold" }, t('common:stackable')))),
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", getLabelText: (key) => {
                                        if (key === schemas_1.EYesNoV1.NO) {
                                            return t('common:topLoadOnly');
                                        }
                                        return t('common:stackable');
                                    }, onChange: (value) => {
                                        onChange(value === schemas_1.EYesNoV1.YES);
                                    }, sourceEnum: schemas_1.EYesNoV1, t: t, value: displayValue }))));
                    }, rules: {
                        validate: (value) => {
                            return value !== undefined;
                        },
                    } }))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(__1.CargoDimensions, { control: control, dataKey: dataKey, index: index, isLengthDisabled: isLengthDisabled, isWidthDisabled: isWidthDisabled, shouldDisplay: true, t: t }))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 })),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, { mr: 1 },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultValue: 0, id: `${dataKey}.${index}.palletCount`, isRequired: true, label: t('common:palletCount'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number", validationRules: {
                                    min: 1,
                                } }))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Box, null,
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: control, defaultValue: 0, id: `${dataKey}.${index}.palletWeight`, isRequired: true, label: t('common:palletWeight'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number", validationRules: {
                                    min: 1,
                                } })))))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: 0, id: `${dataKey}.${index}.weightInLbs`, isRequired: true, label: t('common:weightInLbs'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number", validationRules: {
                        min: 1,
                    } }))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: 0, id: `${dataKey}.${index}.packageCount`, label: t('common:packageCount'), shouldErrorIconBeSuppressed: true, shouldIconsBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number" })))));
};
exports.LtlCargoSpecification = LtlCargoSpecification;
