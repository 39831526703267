// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierServiceTypeV1 = exports.DayOfWeekV1 = exports.RangeUnitOfMeasureV1 = exports.TruckloadTypeV1 = exports.ElasticSearchGeoShapeV1 = exports.PriceTypeV1 = exports.CurrencyV1 = void 0;
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var ElasticSearchGeoShapeV1;
(function (ElasticSearchGeoShapeV1) {
    ElasticSearchGeoShapeV1["circle"] = "circle";
    ElasticSearchGeoShapeV1["polygon"] = "polygon";
    ElasticSearchGeoShapeV1["point"] = "point";
})(ElasticSearchGeoShapeV1 || (exports.ElasticSearchGeoShapeV1 = ElasticSearchGeoShapeV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
var RangeUnitOfMeasureV1;
(function (RangeUnitOfMeasureV1) {
    RangeUnitOfMeasureV1["mi"] = "mi";
    RangeUnitOfMeasureV1["km"] = "km";
})(RangeUnitOfMeasureV1 || (exports.RangeUnitOfMeasureV1 = RangeUnitOfMeasureV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var CarrierServiceTypeV1;
(function (CarrierServiceTypeV1) {
    CarrierServiceTypeV1["lane"] = "lane";
})(CarrierServiceTypeV1 || (exports.CarrierServiceTypeV1 = CarrierServiceTypeV1 = {}));
