"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPumpkinIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M17,6a4.074,4.074,0,0,0-1.639.362A4.96,4.96,0,0,0,13.049,6C13.475,1.618,16.927,1.221,17,3a1,1,0,0,0,0,2,2,2,0,0,0,2-2c-.3-3.828-5.018-3.987-6.99-.437-.582-1.045-2.8-3.664-3.9-2.01A1,1,0,0,0,8.553,1.9c1.425.712,2.214,2.062,2.4,4.105a4.976,4.976,0,0,0-2.316.362C4.373,4.824.035,8.846,0,15c.045,6.1,4.272,10.1,8.464,8.666a3.365,3.365,0,0,0,2.525.152,2.873,2.873,0,0,0,2.022,0,3.36,3.36,0,0,0,2.523-.152C19.726,25.1,23.955,21.1,24,15,24,9.366,20.44,6,17,6ZM2,15c0-4.027,2.148-6.558,4.4-6.943-3.1,3.03-3.107,10.757-.182,13.85C4.033,21.42,2,18.918,2,15Zm4,0c0-3.446,1.569-5.66,2.984-6.552-1.343,2.617-1.257,10.983.048,13.287C7.553,20.964,6,18.541,6,15Zm6,7c-2.671.2-2.629-14.178,0-14C14.634,7.83,14.666,22.2,12,22Zm2.968-.265c1.3-2.306,1.391-10.669.048-13.287,3.986,2.276,3.876,11.119-.048,13.287m2.811.172c2.926-3.094,2.913-10.821-.182-13.85C23.343,9.035,23.446,20.676,17.779,21.907Z" })));
exports.default = TruxwebPumpkinIcon;
