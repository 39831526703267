"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    profile: {
        backgroundColor: ({ isSelf }) => isSelf ? theme.palette.primary.light : theme.palette.secondary.light,
        borderRadius: '100%',
        height: ({ isSmall }) => (isSmall ? theme.spacing(4) : theme.spacing(5.5)),
        width: ({ isSmall }) => (isSmall ? theme.spacing(4) : theme.spacing(5.5)),
    },
    profileInitials: {
        color: theme.palette.common.white,
        fontSize: ({ isSmall }) => (isSmall ? '12px' : '15px'),
    },
}));
