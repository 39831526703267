"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    actionColumnHeader: {
        paddingLeft: '32px !important',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginLeft: '12px',
    },
    buttonText: {
        color: `${theme.palette.common.white} !important`,
        fontWeight: 'bold',
    },
    cellPointer: {
        cursor: 'pointer',
    },
    chipTendencyCaution: {
        backgroundColor: theme.palette.warning.main,
        color: `${theme.palette.common.white} !important`,
    },
    chipTendencyError: {
        backgroundColor: theme.palette.error.main,
        color: `${theme.palette.common.white} !important`,
    },
    chipTendencyNeutral: {
        backgroundColor: theme.palette.grey[600],
        color: `${theme.palette.common.white} !important`,
    },
    chipTendencyPrimary: {
        backgroundColor: theme.palette.primary.light,
        color: `${theme.palette.common.white} !important`,
    },
    chipTendencySuccess: {
        backgroundColor: theme.palette.success.main,
        color: `${theme.palette.common.white} !important`,
    },
    chipTendencyWarning: {
        backgroundColor: theme.palette.warning.main,
        color: `${theme.palette.common.white} !important`,
    },
    chipWrapperNoBackground: {
        '& .MuiChip-root': {
            backgroundColor: 'transparent',
            borderRadius: '7px 7px',
            color: `${theme.palette.grey[600]} !important`,
            padding: '20px 10px 20px 10px',
        },
    },
    date: {
        border: `0px !important`,
    },
    shipmentButton: {
        background: '#eaeaea',
    },
    shipmentIcon: {
        '& > g path': {
            fill: theme.palette.primary.light,
        },
        '& > rect': {
            fill: 'transparent',
        },
        height: '42px',
        width: '42px',
    },
    tag: {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} `,
    },
    tagsContainer: {
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        lineHeight: '24px',
        padding: '0.5rem',
        width: '100%',
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
}));
