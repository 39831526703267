"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFootballIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("g", null,
        React.createElement("path", { d: "M256.07-0.047C114.467-0.047-0.326,114.746-0.326,256.349S114.467,512.744,256.07,512.744s256.395-114.792,256.395-256.395   S397.673-0.047,256.07-0.047z M414.976,188.544l16.555-53.525c22.308,32.335,35.196,70.23,37.227,109.461l-45.696-32.192   C415.507,206.961,412.242,197.375,414.976,188.544z M311.467,50.197h-0.171l-42.667,31.061c-7.479,5.437-17.609,5.437-25.088,0   l-42.667-31.061C237.055,40.157,275.286,40.157,311.467,50.197z M80.469,135.04l16.555,53.504   c2.734,8.831-0.531,18.417-8.085,23.744L43.243,244.48C45.274,205.256,58.162,167.367,80.469,135.04z M85.483,383.659l58.603,0.192   c9.251,0.019,17.436,5.998,20.267,14.805l18.432,57.451C144.042,441.847,110.25,416.687,85.483,383.659z M329.237,456.107   L329.237,456.107L329.237,456.107L329.237,456.107l18.411-57.451c2.828-8.8,11.002-14.777,20.245-14.805l58.624-0.192   C401.763,416.691,367.977,441.852,329.237,456.107z M451.605,340.907l-83.84,0.277c-27.759,0.036-52.312,18.009-60.736,44.459   l-26.347,82.133c-16.39,2.077-32.976,2.077-49.365,0v0l-26.347-82.155c-8.434-26.449-32.996-44.413-60.757-44.437l-83.819-0.256   c-6.451-14.883-11.168-30.46-14.059-46.421l67.179-47.339c22.708-15.938,32.521-44.72,24.277-71.211l-24.213-78.336   c12.236-11.119,25.724-20.777,40.192-28.779l64.597,46.933c22.422,16.358,52.842,16.358,75.264,0l64.597-46.933   c14.465,8.007,27.952,17.664,40.192,28.779l-24.213,78.336c-8.243,26.49,1.569,55.272,24.277,71.211l67.179,47.339   C462.775,310.462,458.057,326.031,451.605,340.907z" }),
        React.createElement("path", { d: "M328.235,196.779l-40.896-29.696c-18.669-13.632-44.009-13.632-62.677,0l-40.896,29.696   c-18.777,13.567-26.612,37.728-19.371,59.733l15.616,48.064c7.137,21.957,27.621,36.805,50.709,36.757h50.56   c23.111,0.002,43.594-14.882,50.731-36.864l15.595-48.064C354.802,234.432,346.971,210.327,328.235,196.779z M307.029,243.221   l-15.595,48.064c-1.425,4.403-5.527,7.384-10.155,7.381h-50.56c-4.62-0.006-8.71-2.986-10.133-7.381L204.971,243.2   c-1.437-4.403,0.132-9.23,3.883-11.947l40.875-29.653c3.74-2.718,8.805-2.718,12.544,0l40.896,29.696   C306.901,234.016,308.46,238.829,307.029,243.221z" }))));
exports.default = TruxwebFootballIcon;
