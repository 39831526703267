// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceDirectionV1 = void 0;
var ServiceDirectionV1;
(function (ServiceDirectionV1) {
    ServiceDirectionV1["AtoB"] = "A --> B";
    ServiceDirectionV1["BtoA"] = "B --> A";
    ServiceDirectionV1["AandB"] = "A <--> B";
})(ServiceDirectionV1 || (exports.ServiceDirectionV1 = ServiceDirectionV1 = {}));
