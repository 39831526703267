import React, { useCallback } from 'react';
import { useLinkButtonStyles } from '../../styles/LinkButton.styles';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const AccountHeaderLanguageSwitch = (): JSX.Element => {
  // const { className, pageId } = props;
  const { locale, locales } = useRouter();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useLinkButtonStyles();

  const handleChangeLocale = useCallback(
    (lang: string) => {
      // NOTE: proper language switching disabled for landing page
      /* i18n.changeLanguage(lang);

      // We need to determine the translated route for this page,
      // this is only required on pages which are not the same
      // in the supported languages
      Router.push(route, route, { locale: lang });
      */
      // const { route } = getRouteProperties(lang, pageId);

      window.location.replace(`/${lang}`);
    },
    []
    //[pageId, i18n]
  );

  // We only want to show the option to switch to the non-current
  // language
  // NOTE: we are currently assuming two Languages here, which is not a
  // permanent thing.
  const availableAlternateLanguages = locales.filter((value) => {
    return value !== locale;
  })[0];

  return (
    <button
      className={classes.button}
      data-testid={`${AccountHeaderLanguageSwitch.name}-Button`}
      onClick={() => {
        handleChangeLocale(availableAlternateLanguages);
      }}
    >
      {t(`common:${availableAlternateLanguages}`)}
    </button>
  );
};
