"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    activeLang: {
        backgroundColor: '#E8F4FB',
        color: theme.palette.primary.main,
    },
    langToggle: {
        '&:active, &:focus': {
            backgroundColor: '#E8F4FB',
            color: theme.palette.primary.main,
        },
        backgroundColor: '#C4C4C4 20%',
        boxShadow: '0 0 0 0',
        color: 'black',
        height: theme.spacing(3),
    },
    noDisplay: {
        display: 'none',
    },
    title: {
        color: theme.palette.primary.dark,
    },
    viewExtProfile: {
        color: theme.palette.primary.main,
    },
}));
