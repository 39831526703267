import { EPermissionV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const validateUserPermissions = async (permissions: EPermissionV1[]): Promise<boolean> => {
  const results: unknown = await makeRequestToApiGateway(
    'userPostV1PermissionsValidate',
    null,
    {
      'Content-Type': 'application/json',
    },
    permissions
  );
  return results as boolean;
};
