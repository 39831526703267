"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFrenchFriesIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.3,12.078a2.986,2.986,0,0,0-1.246-.869l.778-2.239a3.017,3.017,0,0,0-3.146-3.951L19.957,3.5a3,3,0,0,0-5.913-1.041l-.061.307a2.986,2.986,0,0,0-3.967,0L9.96,2.487A3,3,0,0,0,4.044,3.505l.267,1.514A3.017,3.017,0,0,0,1.167,8.976l.776,2.233A2.98,2.98,0,0,0,.049,14.536l.974,5.357A5,5,0,0,0,5.942,24H18.058a5,5,0,0,0,4.919-4.105l.974-5.356A2.987,2.987,0,0,0,23.3,12.078ZM20.316,7.055a1,1,0,0,1,.627,1.265L20.013,11H18.63l.654-3.7A1,1,0,0,1,20.316,7.055ZM17.167,2.01a1,1,0,0,1,.819,1.15L16.6,11H15V7.908l1.011-5.081A1,1,0,0,1,17.167,2.01ZM11,5a1,1,0,0,1,2,0v6H11ZM6.833,2.01a1.009,1.009,0,0,1,1.161.842L9,7.908V11H7.4L6.015,3.165A1,1,0,0,1,6.833,2.01ZM3.684,7.055A1,1,0,0,1,4.716,7.3L5.37,11H3.987L3.059,8.326A1,1,0,0,1,3.684,7.055Zm18.3,7.124-.974,5.355A3,3,0,0,1,18.058,22H5.942a3,3,0,0,1-2.951-2.462l-.974-5.356A1,1,0,0,1,3,13H21a1,1,0,0,1,.983,1.179Z" })));
exports.default = TruxwebFrenchFriesIcon;
