import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: theme.palette.primary.main,
    borderRadius: '100%',
    height: theme.spacing(4),
    padding: theme.spacing(1),
    position: 'relative',
    width: theme.spacing(4),
    zIndex: 2,
  },
}));
