import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { PageLink, SocialMediaIconLinks } from '..';
import { useDividerStyle, useStyles } from './ResponsiveFooterLegal.styles';
import React from 'react';
import { useFooterPalette } from '../../hooks';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const ResponsiveFooterLegal = (): JSX.Element => {
  const footerPalette = useFooterPalette();
  const classes = useStyles({ footerPalette });
  const divider = useDividerStyle({ footerPalette });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const legalYear = new Date().getFullYear();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  return (
    <Grid alignItems="center" container direction="column">
      <Divider classes={divider} flexItem variant="fullWidth" />
      <Box mb={3} />
      <Grid alignItems="center" container direction={isTablet ? 'column' : 'row'} spacing={1}>
        <Grid
          alignItems="center"
          container
          item
          justifyContent={isTablet ? 'center' : 'flex-start'}
          xs
        >
          <Typography className={classes.copyright} variant="body1">
            {t('common:copyright', { year: legalYear })}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center" xs>
          <Grid container justifyContent="center">
            <PageLink pageId={'privacy-policy'}>
              <Typography className={classes.linkClass} variant="body1">
                {t('common:privacyPolicy')}
              </Typography>
            </PageLink>
            <Box ml={2} mr={2}>
              <Typography className={classes.linkClass} variant="body1">
                |
              </Typography>
            </Box>
            <PageLink pageId={'terms-and-conditions'}>
              <Typography className={classes.linkClass} variant="body1">
                {t('common:termsAndConditions')}
              </Typography>
            </PageLink>
          </Grid>
        </Grid>
        {!isTablet && (
          <Grid container item xs>
            <Grid className={classes.socialLinks} container item justifyContent="flex-end">
              <SocialMediaIconLinks isDark={footerPalette === 'dark'} screenSize={'mobile'} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
