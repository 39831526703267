import { makeStyles, Theme } from '@truxweb/ux';

type TResponsiveEmailSignUpProps = {
  isLight: boolean;
};
export const useStyles = makeStyles<Theme, TResponsiveEmailSignUpProps>((theme: Theme) => ({
  emailContainer: {
    backgroundColor: 'transparent',
    padding: '2px',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(26.5),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(36.5),
    },
  },
  emailInput: {
    '&::placeholder': {
      color: ({ isLight }) => {
        return isLight ? theme.palette.common.white : theme.palette.grey[600];
      },
    },
    backgroundColor: ({ isLight }) => {
      return isLight ? theme.palette.primary.dark : theme.palette.common.white;
    },
    border: 0,
    borderRadius: '36px 0 0 36px',
    color: ({ isLight }) => {
      return isLight ? theme.palette.common.white : theme.palette.grey[600];
    },
    display: 'flex',
    height: '100%',
    width: '140px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: `${theme.spacing(2)}px`,
      paddingLeft: 22,
      width: theme.spacing(27),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2),
      lineHeight: `${theme.spacing(2.5)}px`,
      paddingLeft: 24,
      width: theme.spacing(37),
    },
  },
  newsletterCta: {},
  newsletterSignup: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(8),
    position: 'relative',
  },
  submitButton: {
    '&:hover': {
      boxShadow: 'unset',
    },
    backgroundColor: 'transparent',
    borderRadius: '0 36px 36px 0',
    boxShadow: 'unset',
    color: theme.palette.common.white,
    lineHeight: `${theme.spacing(5.75)}px`,
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(4),
      width: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(6),
      width: theme.spacing(14),
    },
  },

  wrapper: {
    '&:hover': {
      '& button': {
        '& span': {
          color: ({ isLight }) => {
            return isLight ? theme.palette.primary.main : theme.palette.common.white;
          },
        },
      },
      backgroundColor: ({ isLight }) => {
        return isLight ? theme.palette.common.white : theme.palette.primary.dark;
      },
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: '36px 36px 36px 36px ',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(37.5),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(51.5),
    },
  },
}));

export const buttonFontOverride = makeStyles<Theme>((theme: Theme) => ({
  label: {
    marginLeft: theme.spacing(1.25),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.25),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(2),
    },
  },
}));
