import {
  type AppThunk,
  setQuoteCreateLoading,
  updateApplicationLoadingState,
} from '../../../stores';

export const setQuoteCreateLoadingState =
  (messageId: number | null, newLoadingState: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const applicationLoadingState = (state as any).ux.applicationLoadingState;
    dispatch(setQuoteCreateLoading({ messageId, newLoadingState }));

    if (newLoadingState) {
      dispatch(
        updateApplicationLoadingState({
          ...applicationLoadingState,
          actionId: messageId,
          actionType: 'QUOTE_CREATE',
          isActionLoading: true,
        })
      );
    } else {
      dispatch(
        updateApplicationLoadingState({
          ...applicationLoadingState,
          actionId: undefined,
          actionType: undefined,
          isActionLoading: false,
        })
      );
    }
  };
