// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTermsStatusV1 = exports.StatusGenericV1 = exports.DayOfWeekV1 = exports.CountryV1 = exports.PaymentTermsTypeV1 = void 0;
var PaymentTermsTypeV1;
(function (PaymentTermsTypeV1) {
    PaymentTermsTypeV1["DEBIT"] = "DEBIT";
    PaymentTermsTypeV1["INVOICE"] = "INVOICE";
})(PaymentTermsTypeV1 || (exports.PaymentTermsTypeV1 = PaymentTermsTypeV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var DayOfWeekV1;
(function (DayOfWeekV1) {
    DayOfWeekV1["Monday"] = "Monday";
    DayOfWeekV1["Tuesday"] = "Tuesday";
    DayOfWeekV1["Wednesday"] = "Wednesday";
    DayOfWeekV1["Thursday"] = "Thursday";
    DayOfWeekV1["Friday"] = "Friday";
    DayOfWeekV1["Saturday"] = "Saturday";
    DayOfWeekV1["Sunday"] = "Sunday";
})(DayOfWeekV1 || (exports.DayOfWeekV1 = DayOfWeekV1 = {}));
var StatusGenericV1;
(function (StatusGenericV1) {
    StatusGenericV1["ACTIVE"] = "ACTIVE";
    StatusGenericV1["INACTIVE"] = "INACTIVE";
    StatusGenericV1["ARCHIVED"] = "ARCHIVED";
    StatusGenericV1["PENDING"] = "PENDING";
    StatusGenericV1["REVISE"] = "REVISE";
    StatusGenericV1["REGISTERED"] = "REGISTERED";
    StatusGenericV1["BANNED"] = "BANNED";
})(StatusGenericV1 || (exports.StatusGenericV1 = StatusGenericV1 = {}));
var PaymentTermsStatusV1;
(function (PaymentTermsStatusV1) {
    PaymentTermsStatusV1["SUBMITTED"] = "SUBMITTED";
    PaymentTermsStatusV1["REVISE"] = "REVISE";
    PaymentTermsStatusV1["REJECTED"] = "REJECTED";
    PaymentTermsStatusV1["APPROVED"] = "APPROVED";
})(PaymentTermsStatusV1 || (exports.PaymentTermsStatusV1 = PaymentTermsStatusV1 = {}));
