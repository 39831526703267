// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAcctTransferTypeV1 = void 0;
var BankAcctTransferTypeV1;
(function (BankAcctTransferTypeV1) {
    BankAcctTransferTypeV1["Credit"] = "Credit";
    BankAcctTransferTypeV1["Debit"] = "Debit";
})(BankAcctTransferTypeV1 || (exports.BankAcctTransferTypeV1 = BankAcctTransferTypeV1 = {}));
