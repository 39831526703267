import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  link: {
    '&:hover': {
      color: 'unset',
    },
    '&:visted': {
      color: 'inherit',
    },
    color: 'inherit',
    fontFamily: 'Poppins',
    textDecoration: 'none',
  },
  modernLink: {
    '&:hover': {
      color: 'unset',
    },
    '&:visted': {
      color: 'inherit',
    },
    color: theme.palette.primary.light,
    fontFamily: 'Roboto',
    textDecoration: 'none',
  },
}));
