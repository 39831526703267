"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTirePressureWarningIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M11,12V4a1,1,0,0,1,2,0v8A1,1,0,0,1,11,12Zm13,.429a12.063,12.063,0,0,0-2.638-7.613A6.069,6.069,0,0,1,20,1a1,1,0,0,0-1-1c-2.3.255-.16,5.038.8,6.064A9.42,9.42,0,0,1,17.715,20H6.287A9.426,9.426,0,0,1,4.2,6.064C5.155,5.048,7.306.252,5,0A1,1,0,0,0,4,1,6.069,6.069,0,0,1,2.638,4.816,11.412,11.412,0,0,0,5,21.544V23a1,1,0,0,0,2,0V22H9v1a1,1,0,0,0,2,0V22h2v1a1,1,0,0,0,2,0V22h2v1a1,1,0,0,0,2,0V21.544A11.3,11.3,0,0,0,24,12.429ZM12,17a1,1,0,0,0,0-2A1,1,0,0,0,12,17Z" })));
exports.default = TruxwebTirePressureWarningIcon;
