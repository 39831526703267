import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    borderBottom: '2px solid rgba(196, 196, 196, 0.5)',
    borderRadius: `${theme.spacing(1.25)}px ${theme.spacing(1.25)}px 0px 0px`,
    marginBottom: '-1px',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(6),
  },
  title: {
    color: theme.palette.primary.main,
    lineHeight: `${theme.spacing(2)}px`,
  },
  titleContainer: {
    marginTop: `-12px`,
  },
}));
