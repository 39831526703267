// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotLifecycleStatusV1 = void 0;
var HubspotLifecycleStatusV1;
(function (HubspotLifecycleStatusV1) {
    HubspotLifecycleStatusV1["subscriber"] = "subscriber";
    HubspotLifecycleStatusV1["lead"] = "lead";
    HubspotLifecycleStatusV1["marketingqualifiedlead"] = "marketingqualifiedlead";
    HubspotLifecycleStatusV1["salesqualifiedlead"] = "salesqualifiedlead";
    HubspotLifecycleStatusV1["opportunity"] = "opportunity";
    HubspotLifecycleStatusV1["customer"] = "customer";
    HubspotLifecycleStatusV1["evangelist"] = "evangelist";
})(HubspotLifecycleStatusV1 || (exports.HubspotLifecycleStatusV1 = HubspotLifecycleStatusV1 = {}));
