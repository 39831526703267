"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTabsStyles = exports.useSingleTabStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    autoMargin: {
        margin: '0 auto !important',
    },
    divider: {
        minWidth: '1px',
        padding: 0,
    },
    noMargin: {
        margin: '0 !important',
    },
}));
exports.useSingleTabStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: 1,
        minHeight: `${theme.spacing(5)}px !important`,
        textTransform: 'none',
    },
    selected: {
        fontWeight: 600,
    },
}));
exports.useTabsStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '6px',
            boxShadow: '0px 0px 1px rgb(20 20 20 / 4%), 0px 0px 8px rgb(20 20 20 / 8%)',
            color: theme.palette.primary.light,
        },
        '& .Mui-selected  .MuiTab-wrapper, .MuiTab-wrapper:last-child': {
            border: 'none',
        },
        //hide indicator bar
        '& .MuiTabs-fixed > span': {
            display: 'none',
        },
        '& .MuiTabs-flexContainer': {
            alignItems: 'center',
            backgroundColor: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[200]}`,
            borderRadius: '8px',
            height: theme.spacing(6),
            marginTop: theme.spacing(2),
            padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px `,
        },
        margin: '0 auto',
        width: 'fit-content',
    },
}));
