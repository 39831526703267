"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebWingsIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 417 232", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { clipPath: "url(#clip0_1325_12192)" },
        React.createElement("path", { d: "M275.314 208.9L290.239 232H417L341.779 116.3H118.105L151.04 167C154.721 172.6 157.905 177.6 161.586 182H234.917C249.245 182 263.473 191.4 275.314 208.9Z", fill: "#FDBF0F" }),
        React.createElement("path", { d: "M341.779 116.3H119.299C90.743 116.3 62.7838 97.5 40.9936 63.8L0 0H221.186C251.036 0 279.592 19.4 302.576 54.4L341.779 116.3Z", fill: "#0088CE" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_1325_12192" },
            React.createElement("rect", { fill: "white", height: 232, width: 417 })))));
exports.default = TruxwebWingsIcon;
