"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTerraceIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.122,13A2.881,2.881,0,0,0,24,10.122a5.13,5.13,0,0,0-2.106-4.137L15.009.979a5.1,5.1,0,0,0-6.018,0L2.106,5.985A5.13,5.13,0,0,0,0,10.122,2.881,2.881,0,0,0,2.878,13H11V23a1,1,0,0,0,2,0V13ZM15.5,8.075A2.986,2.986,0,0,1,16,9.734V11H13V4.313ZM20.718,7.6A3.124,3.124,0,0,1,22,10.122a.879.879,0,0,1-.878.878H18V9.734a4.983,4.983,0,0,0-.835-2.766l-2.5-3.77ZM2,10.122A3.124,3.124,0,0,1,3.282,7.6L9.339,3.2l-2.5,3.77A4.983,4.983,0,0,0,6,9.734V11H2.878A.879.879,0,0,1,2,10.122ZM8,11V9.734a2.986,2.986,0,0,1,.5-1.659L11,4.313V11Z" }),
    React.createElement("path", { d: "M4,19H2V16a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0V21H4a1,1,0,0,1,1,1v1a1,1,0,0,0,2,0V22A3,3,0,0,0,4,19Z" }),
    React.createElement("path", { d: "M23,15a1,1,0,0,0-1,1v3H20a3,3,0,0,0-3,3v1a1,1,0,0,0,2,0V22a1,1,0,0,1,1-1h2v2a1,1,0,0,0,2,0V16A1,1,0,0,0,23,15Z" })));
exports.default = TruxwebTerraceIcon;
