import { Box, Grid, RightHandSidebar } from '@truxweb/ux';
import { LaneEditSidebarWrapper, Loading } from '..';
import React, { useCallback, useEffect, useState } from 'react';
import { useCarrierData, useCarrierLaneData } from '../../hooks';
import { CarrierLanes as BaseCarrierLanes } from '@truxweb/common-components';
import { PageLink } from '../PageLink';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const CarrierLanes = (): JSX.Element => {
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [selectedLaneId, setSelectedLaneId] = useState<null | number>(null);
  const [lanes, haveLanesFetched, isLoading] = useCarrierLaneData(shouldRefetch);
  const [carrierData] = useCarrierData();
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const handleRefresh = useCallback(() => {
    setShouldRefetch(true);
  }, [setShouldRefetch]);

  const handleClose = useCallback(() => {
    setSelectedLaneId(null);
  }, [setSelectedLaneId]);

  const handleSelectLane = useCallback(
    (laneId: number) => {
      setSelectedLaneId(laneId);
    },
    [setSelectedLaneId]
  );

  const handleViewFullDetails = (
    <Grid container justifyContent="center">
      <PageLink
        pageId={'fleet/lanes/[laneId]'}
        routeParams={[selectedLaneId]}
        shouldUseModernVariant
      >
        {t('common:manageLaneZones')}
      </PageLink>
    </Grid>
  );

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
    }
  }, [shouldRefetch, setShouldRefetch]);

  return (
    <>
      <Loading isLoading={!haveLanesFetched || isLoading} />
      <Box mt={6}>
        <BaseCarrierLanes
          canManageCarrierData={true}
          data={lanes || []}
          handleRouteToLane={(laneId: string) => {
            handleSelectLane(parseInt(laneId, 10));
          }}
          handleSelectedRows={(laneId) => {
            handleSelectLane(laneId);
          }}
          isLoading={isLoading}
          pageSize={(lanes || []).length < 10 ? (lanes || []).length : 10}
          selectedRows={selectedLaneId ? [selectedLaneId] : []}
          shouldHideActiveColumn
          t={t}
        />
      </Box>
      {Boolean(selectedLaneId) && Boolean(carrierData?.id) && (
        <RightHandSidebar handleClose={handleClose} isOpen key={selectedLaneId}>
          <LaneEditSidebarWrapper
            additionalContent={handleViewFullDetails}
            carrierId={carrierData.id}
            carrierLaneId={selectedLaneId}
            handleClose={handleClose}
            onEditSuccess={handleRefresh}
          />
        </RightHandSidebar>
      )}
    </>
  );
};
