import {
  Box,
  CircularProgress,
  Grid,
  type GridFilterModel,
  type GridSortModel,
  List,
  ListItem,
  RightHandSidebar,
  ToggleButtons,
  TruxwebRefreshIcon,
  Typography,
} from '@truxweb/ux';
import { DEFAULT_PAGE_SIZE, QUOTE_TABLE_REFRESH_MS } from '../../config';
import {
  EShipmentQuoteRequestStatusV1,
  EUserTypeV1,
  type TCarrierQuoteDataGridRowV1,
} from '@truxweb/schemas';
import { getCarrierQuoteIdByRef, markCarrierQuoteAsRead } from '../../actions';
import { getRouteProperties, hasWindow } from '../../utils';
import { QuickAccess, QuoteRequestCarrierDataGrid } from '@truxweb/common-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useErrorHandling,
  useInterval,
  useQuoteCounts,
  useReduxAccessorialData,
  useReduxCarrierQuoteList,
  useReduxEquipmentData,
  useRefresh,
  useShipmentEntityTags,
  useUserData,
  useUxState,
} from '../../hooks';
import { CarrierPage } from '../../pageTemplates';
import classnames from 'classnames';
import { handleToggleQuoteTableStatues } from '../../stores';
import { QuoteRequestCarrierDetailsWrapper } from '../../components';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { type TTruxwebPageContentProps } from '../../types';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './CarrierQuotesPage.styles';
import { useTranslation } from 'next-i18next';

const PAGE_SIZE = 10;
const REQUIRED_NAMESPACES = ['common', 'select', 'shipments'];
const TABLE_ID = 'TRUXWEB_CARRIER_QUOTE_TABLE';
const DEFAULT_COLUMNS = [
  'isViewed',
  'quoteRef',
  'carrierQuoteRef',
  'actions',
  'timer',
  'totalCompetitors',
  'shipperName',
  'chargesModified',
  'bookingDateModifiedDate',
  'locationOrigin',
  'locationDestination',
  'dateCreated',
];

const DEFAULT_SORT: GridSortModel = [
  {
    field: 'dateCreated',
    sort: 'asc',
  },
];

export const CarrierQuotesPage = ({ pageId }: TTruxwebPageContentProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const dispatch = useDispatch();
  const uxState = useUxState();
  const errorHandler = useErrorHandling();
  const [hasInit, setInit] = useState(false);
  const [columnKeys, setColumnKeys] = useState<null | string[]>(null);
  const [tableSort, setTableSort] = useState<null | GridSortModel>(null);
  const [tableFilters, setTableFilters] = useState<null | GridFilterModel>(null);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const { data: accessorials, hasLoaded: hasAccessorialsFetched } = useReduxAccessorialData();
  const { data: equipment, hasLoaded: hasEquipmentFetched } = useReduxEquipmentData();

  const { userType } = useUserData();
  const { updateQuoteCounts } = useQuoteCounts();

  const router = useRouter();
  const {
    locale,
    query: { quoteRequestId },
  } = router;

  const selectedRequestId = useMemo(() => {
    return quoteRequestId ? parseInt(quoteRequestId as string) : null;
  }, [quoteRequestId]);

  const [isRightSidebarOpen, setRightSidebarState] = useState(false);
  const { handleRefresh, shouldRefresh } = useRefresh();
  const tags = useShipmentEntityTags(shouldRefresh);
  const [toggleTimeout, setToggleTimeout] = useState(null);

  const onRefresh = useCallback(() => {
    handleRefresh();
    updateQuoteCounts();
  }, [handleRefresh, updateQuoteCounts]);

  const quoteData = useReduxCarrierQuoteList(
    {
      filter: tableFilters,
      language: transformI18nLocaleToLanguage(locale),
      limit: DEFAULT_PAGE_SIZE,
      page: currentPage,
      pageSize: currentPageSize,
      sort: tableSort,
      t,
      userType,
    },
    hasInit,
    shouldRefresh
  );
  const { data: carrierQuoteData, hasLoaded, isLoading, rowCount } = quoteData;

  const classes = useStyles({ isLoading });

  const handleClose = useCallback(() => {
    const { route } = getRouteProperties(locale, pageId);
    router.push(route);
  }, [router, locale, pageId]);

  const findQuote = useCallback(
    (quoteId: number): any | null => {
      return (carrierQuoteData || []).find((quote: TCarrierQuoteDataGridRowV1) => {
        return quote.id === quoteId;
      });
    },
    [carrierQuoteData]
  );

  const manageColumnKeys = useCallback(
    (event: any) => {
      const { field } = event;
      const fieldIndex = columnKeys.indexOf(field);
      const newColumnKeys = [...columnKeys];
      if (fieldIndex > -1) {
        newColumnKeys.splice(fieldIndex, 1);
      } else {
        newColumnKeys.push(field);
      }
      setColumnKeys(newColumnKeys);

      const storedKeys = localStorage.getItem(TABLE_ID);
      let update = { columnKeys: newColumnKeys };
      if (storedKeys) {
        update = JSON.parse(storedKeys);
        update.columnKeys = newColumnKeys;
      }
      localStorage.setItem(TABLE_ID, JSON.stringify(update));
    },
    [columnKeys, setColumnKeys]
  );

  const manageTableFilters = useCallback(
    (filter: GridFilterModel) => {
      setTableFilters(filter);
      handleRefresh();
    },
    [setTableFilters, handleRefresh]
  );

  const manageTableSort = useCallback(
    (sort: GridSortModel | undefined) => {
      let hasSortChanges = false;
      if (sort && !tableSort) {
        hasSortChanges = true;
      } else if (!sort && tableSort) {
        hasSortChanges = true;
      } else if (sort && tableSort) {
        hasSortChanges = JSON.stringify(sort) !== JSON.stringify(tableSort);
      }
      if (hasSortChanges) {
        setTableSort(sort || null);
        const storedKeys = localStorage.getItem(TABLE_ID);
        let update = { tableSort: sort };
        if (storedKeys) {
          update = JSON.parse(storedKeys);
          update.tableSort = sort;
        }
        localStorage.setItem(TABLE_ID, JSON.stringify(update));
        handleRefresh();
      }
    },
    [setTableSort, tableSort, handleRefresh]
  );

  const handleSelectQuote = useCallback(
    async (quoteId: number) => {
      if (quoteId !== selectedRequestId) {
        const { route } = getRouteProperties(router.locale, pageId);
        router.push(`${route}?quoteRequestId=${quoteId}`);
        const selectedQuote = findQuote(quoteId);

        if (selectedQuote) {
          setRightSidebarState(true);

          if (!selectedQuote.hasBeenViewed) {
            await markCarrierQuoteAsRead(selectedQuote.id);
            onRefresh();
          }
        }
      }
    },
    [router, selectedRequestId, findQuote, onRefresh, pageId]
  );
  const handleQuickAccess = useCallback(
    async (quoteRef: string) => {
      try {
        const quoteIdResponse = await getCarrierQuoteIdByRef(quoteRef);
        handleSelectQuote(quoteIdResponse.id);
      } catch (err) {
        errorHandler(err);
      }
    },
    [handleSelectQuote, errorHandler]
  );

  const handleToggleRefresh = useCallback(() => {
    if (toggleTimeout) clearTimeout(toggleTimeout);
    const newTimeout = setTimeout(() => {
      onRefresh();
    }, 500);
    setToggleTimeout(newTimeout);
  }, [toggleTimeout, setToggleTimeout, onRefresh]);

  const handleRouteToShipment = useCallback(
    (shipmentRef: string) => {
      const { route } = getRouteProperties(router.locale, 'shipments');
      router.push(`${route}?shipmentRef=${shipmentRef}`);
    },
    [router]
  );

  const handleToggle = useCallback(
    (toggle: string, forceSingleToggle: boolean) => {
      dispatch(
        handleToggleQuoteTableStatues({
          forceToggle: forceSingleToggle,
          statuses: [toggle],
        })
      );
      handleToggleRefresh();
    },
    [handleToggleRefresh, dispatch]
  );
  const handleSetToggles = useCallback(
    (toggle: string[]) => {
      dispatch(
        handleToggleQuoteTableStatues({
          forceToggle: true,
          statuses: toggle,
        })
      );
      handleToggleRefresh();
    },
    [handleToggleRefresh, dispatch]
  );

  const [toggles, toggleExplanations] = useMemo(() => {
    const toggles = [
      {
        group: EShipmentQuoteRequestStatusV1.PENDING,
        label: t('common:CARRIER-carrierQuoteStatus-PENDING'),
      },
      {
        group: EShipmentQuoteRequestStatusV1.MODIFIED,
        label: t('common:inReviewByClient'),
      },
      {
        group: EShipmentQuoteRequestStatusV1.ACCEPTED,
        label: t('common:confirmed'),
      },
      {
        group: EShipmentQuoteRequestStatusV1.REFUSED,
        label: t('common:refused'),
      },
    ];

    const list = (
      <List key={1}>
        {toggles.map(({ group, label }) => {
          return (
            <ListItem key={group}>
              <Typography color="white">
                <b>{label}</b>:{' '}
                {t(`common:statusGroup-${group}-${EUserTypeV1.CARRIER}-explanation`)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );

    const statusToggleExplanation = (
      <>
        <Typography color="white" fontStyle="semibold">
          {t('common:statusToggles')}
        </Typography>
        {list}
      </>
    );
    return [toggles, statusToggleExplanation];
  }, [t]);

  useInterval(onRefresh, QUOTE_TABLE_REFRESH_MS);

  useEffect(() => {
    if (!selectedRequestId && isRightSidebarOpen) {
      setRightSidebarState(false);
    }
    if (selectedRequestId && !isRightSidebarOpen) {
      setRightSidebarState(true);
    }
  }, [selectedRequestId, isRightSidebarOpen]);

  useEffect(() => {
    if (hasWindow() && columnKeys === null) {
      const storedKeys = localStorage.getItem(TABLE_ID);
      if (storedKeys) {
        const parsed = JSON.parse(storedKeys);
        const newSort = parsed.tableSort || DEFAULT_SORT;
        setColumnKeys(parsed.columnKeys || DEFAULT_COLUMNS);
        setTableSort(newSort);
      } else {
        setColumnKeys(DEFAULT_COLUMNS);
        setTableSort(DEFAULT_SORT);
      }

      if (!hasInit) {
        const storedFilters = localStorage.getItem(`${TABLE_ID}_FILTERS`);
        if (storedFilters) {
          handleSetToggles(JSON.parse(storedFilters));
        }
      }

      setInit(true);
    }
  }, [columnKeys, setColumnKeys, setTableSort, hasInit, setInit, handleSetToggles]);

  return (
    <CarrierPage pageId={pageId}>
      <Grid container direction="column" style={{ position: 'relative' }}>
        <Box mb={2}>
          <Grid alignItems="center" container direction="row" justifyContent="space-between" xs>
            <Grid item xs>
              <Box mr={2}>
                <QuickAccess
                  handleQuickAccess={handleQuickAccess}
                  placeholder={t('common:quickAccessQuote')}
                  value={''}
                />
              </Box>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={9}>
              <Grid item style={{ marginTop: '8px' }} xs>
                <ToggleButtons
                  explanation={toggleExplanations}
                  handleToggle={handleToggle}
                  isDisabled={isLoading}
                  label={t('common:statusToggles')}
                  selectedToggles={uxState.quoteTableStatuses}
                  toggles={toggles}
                />
              </Grid>
              <Grid item>
                {!isLoading && (
                  <Box ml={1} mt={1.5}>
                    <button className={classes.button} disabled={isLoading} onClick={handleRefresh}>
                      <TruxwebRefreshIcon
                        className={classnames(classes.icon, classes.animatedIcon)}
                      />
                    </button>
                  </Box>
                )}
                {isLoading && (
                  <Box ml={1.9} mt={1.75}>
                    <CircularProgress size={16} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.tableContainer} item>
          {!tableSort || !hasEquipmentFetched || !hasAccessorialsFetched ? (
            <></>
          ) : (
            <QuoteRequestCarrierDataGrid
              accessorial={accessorials}
              areFiltersEnabled
              availableTags={tags?.data || []}
              columnKeys={columnKeys}
              currentSort={tableSort}
              data={carrierQuoteData || []}
              equipment={equipment}
              filterMode={'server'}
              handleColumnVisibilityChange={manageColumnKeys}
              handleFilterChange={manageTableFilters}
              handleRouteToShipment={handleRouteToShipment}
              handleSortingChange={manageTableSort}
              isExportEnabled={false}
              isHeaderShown
              isLazyLoading={isLoading}
              isLoading={!hasLoaded}
              language={transformI18nLocaleToLanguage(locale)}
              lastLoadedDate={
                quoteData?.lastLoadedDate ? new Date(quoteData?.lastLoadedDate) : undefined
              }
              onPageChange={(page) => {
                setCurrentPage(page);
                handleRefresh();
              }}
              onPageSizeChange={(pageSize) => {
                setCurrentPageSize(pageSize);
                handleRefresh();
              }}
              onSelectQuote={handleSelectQuote}
              pageSize={currentPageSize}
              paginationMode={'server'}
              rowCount={rowCount}
              selectedRows={selectedRequestId ? [selectedRequestId] : undefined}
              sortingMode={'server'}
              t={t}
            />
          )}
        </Grid>
      </Grid>
      <RightHandSidebar
        canExpand
        handleClose={handleClose}
        isLeftMenuOpen={uxState.sidebarState}
        isOpen={isRightSidebarOpen}
        testId="CarrierQuotesPage"
      >
        <Grid item key={selectedRequestId}>
          {Boolean(selectedRequestId) && (
            <QuoteRequestCarrierDetailsWrapper
              handleClose={handleClose}
              onQuoteUpdate={onRefresh}
            />
          )}
        </Grid>
      </RightHandSidebar>
    </CarrierPage>
  );
};
