"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    badge: {
        height: theme.spacing(2.25),
        left: theme.spacing(0.5),
        minWidth: theme.spacing(2.25),
        top: 0,
        width: theme.spacing(2.25),
    },
    button: {
        '&:hover': {
            fill: ({ isError }) => {
                if (isError) {
                    return theme.palette.error.light;
                }
                return theme.palette.primary.dark;
            },
        },
        alignItems: 'center',
        background: theme.palette.common.white,
        borderRadius: theme.spacing(0.5),
        cursor: 'pointer',
        display: 'flex',
        fill: ({ isError }) => {
            if (isError) {
                return theme.palette.error.main;
            }
            return theme.palette.primary.light;
        },
        height: theme.spacing(6),
        justifyContent: 'center',
        position: 'relative',
        width: theme.spacing(6),
    },
}));
