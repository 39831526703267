import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  carrierProfile: {
    '& :hover': {
      color: `${theme.palette.common.white}`,
    },
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    border: 0,
    borderRadius: 0,
    color: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 600,
    height: `${theme.spacing(7)}px`,
    justtifyContent: 'center',
    padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
  },
  carrierProfileIcon: {
    color: `${theme.palette.common.white}`,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  carrierProfileText: { color: `${theme.palette.common.white}` },

  container: {
    position: 'relative',
  },
  primaryLightButton: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: 48,
    position: 'relative',
    width: '150px',
  },

  primaryLightButtonOutlined: {
    border: `2px solid ${theme.palette.primary.light} !important`,
  },
  searchBox: {
    background: '#EDF8FF',
    borderRadius: '16px',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
  },
  searchButton: {
    '& .MuiButton-label': {
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: `24px`,
    },
    '&:hover': {
      background: 'transparent !important',
      color: theme.palette.primary.dark,
    },
    color: theme.palette.primary.light,
    marginRight: theme.spacing(0.5),
    position: 'absolute',
    right: '24px',
    top: `-${theme.spacing(7.5)}px`,
    transition: 'ease-in color 350ms',
    zIndex: 1,
  },
  sortSelect: {
    background: theme.palette.common.white,
    width: theme.spacing(30),
  },
  truxi: {
    '@media (min-width:2330px)': {
      color: theme.palette.common.white,
    },
  },
}));
