"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGuitarIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("circle", { cx: 10, cy: 14, r: 2 }),
    React.createElement("path", { d: "M5.707,16.293a1,1,0,0,0-1.414,1.414l2,2a1,1,0,1,0,1.414-1.414Z" }),
    React.createElement("path", { d: "M23.482.518a1.767,1.767,0,0,0-2.5,0l-.214.214a6.035,6.035,0,0,0-1.534,2.62L16.259,6.327a5.894,5.894,0,0,0-7.7.232,4.933,4.933,0,0,0-.445.508,2.283,2.283,0,0,1-1.554.959,7.055,7.055,0,0,0-4.5,2.038c-2.956,2.957-2.7,8.025.575,11.3A8.8,8.8,0,0,0,8.814,24a7.164,7.164,0,0,0,5.122-2.059,7.055,7.055,0,0,0,2.038-4.5,2.283,2.283,0,0,1,.959-1.554,4.933,4.933,0,0,0,.508-.445,5.9,5.9,0,0,0,.232-7.7l2.975-2.975a6.035,6.035,0,0,0,2.62-1.534l.214-.214A1.767,1.767,0,0,0,23.482.518ZM16.027,14.027a2.888,2.888,0,0,1-.3.265,4.192,4.192,0,0,0-1.745,2.99,5.072,5.072,0,0,1-1.458,3.239c-2.175,2.175-5.975,1.92-8.468-.574S1.3,13.655,3.479,11.479a5.072,5.072,0,0,1,3.239-1.458,4.192,4.192,0,0,0,2.99-1.745,2.888,2.888,0,0,1,.265-.3A3.41,3.41,0,0,1,12.419,7a4.435,4.435,0,0,1,2.415.752L13.293,9.293a1,1,0,1,0,1.414,1.414l1.538-1.538A3.858,3.858,0,0,1,16.027,14.027Z" })));
exports.default = TruxwebGuitarIcon;
