import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@truxweb/ux';
import { getRouteAuthenticationStatus, getRouteSection } from '../../utils';
import React, { useCallback, useRef, useState } from 'react';
import { useAuthBoolean, usePermissions } from '../../hooks';
import { EPermissionV1 } from '@truxweb/schemas';
import { PageLink } from '..';
import { TPageId } from '../../types';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TShipperHeaderLinks = {
  pageId: TPageId;
  linkClass: string;
  selectedLinkClass: string;
};
export const ShipperHeaderLinks = ({
  linkClass,
  pageId,
  selectedLinkClass,
}: TShipperHeaderLinks): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { locale } = useRouter();
  const section = getRouteSection(locale, pageId);
  const anchorRef = useRef(null);
  const isAuthed = useAuthBoolean();

  const [isShipmentMenuOpen, setShipmentMenuOpen] = useState(false);
  const handleShipmentsMenuClose = useCallback(() => {
    setShipmentMenuOpen(false);
  }, [setShipmentMenuOpen]);

  const handleShipmentMenuToggle = useCallback(() => {
    setShipmentMenuOpen(!isShipmentMenuOpen);
  }, [isShipmentMenuOpen, setShipmentMenuOpen]);

  const [canViewShipperPages] = usePermissions([EPermissionV1.VIEW_SHIPPER_PAGE]);

  // We need to obtain the authentication status for the shipments page
  // separately as it is not a link (authentication is otherwise handled by `<PageLink />`
  const shipmentsAuthenticated = getRouteAuthenticationStatus('shipments');

  if (!isAuthed || !canViewShipperPages) return null;

  return (
    <Grid alignItems="center" container direction="row" justifyContent="space-around">
      <Grid item>
        <Box mr={3}>
          <PageLink pageId={'search'}>
            <Typography
              className={section === 'search' ? selectedLinkClass : linkClass}
              variant="h6"
            >
              {t('common:siteMap-search')}
            </Typography>
          </PageLink>
        </Box>
      </Grid>
      <Grid item>
        {shipmentsAuthenticated && isAuthed && (
          <Box mr={3}>
            <Button onClick={handleShipmentMenuToggle} ref={anchorRef}>
              <Typography
                className={section === 'shipments' ? selectedLinkClass : linkClass}
                variant="h6"
              >
                {t('common:siteMap-shipmentsHeader')}
              </Typography>
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item>
        <PageLink pageId={'contact-us'}>
          <Typography className={section === 'help' ? selectedLinkClass : linkClass} variant="h6">
            {t('common:help')}
          </Typography>
        </PageLink>
      </Grid>
      <Popper anchorEl={anchorRef.current} disablePortal open={isShipmentMenuOpen}>
        <ClickAwayListener onClickAway={handleShipmentsMenuClose}>
          <Paper>
            <MenuList disablePadding>
              <MenuItem>
                <PageLink pageId={'shipments'}>{t('common:siteMap-shipments')}</PageLink>
              </MenuItem>
              <MenuItem>
                <PageLink pageId={'requests'}>{t('common:siteMap-requests')}</PageLink>
              </MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
};
