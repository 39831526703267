import { makeRequestToApiGateway } from '../../apiUtils';
import { type TShipmentDetailsV1 } from '@truxweb/schemas';

export const getSharedShipment = async (shareRef: string): Promise<TShipmentDetailsV1> => {
  const endpoint = 'shipmentGetV1ShareByShareRef';

  return await makeRequestToApiGateway(
    endpoint,
    {
      shareRef,
    },
    {
      'Content-Type': 'application/json',
    }
  );
};
