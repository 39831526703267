import { TUseActionResponse, useAction } from './useAction';
import { getCarrierZones } from '../actions';
import { TCarrierZoneV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierZones = (
  shouldForceRefetch?: boolean
): TUseActionResponse<TCarrierZoneV1[] | null> => {
  const action = useCallback(() => {
    return getCarrierZones();
  }, []);

  return useAction<TCarrierZoneV1[] | null>(action, true, shouldForceRefetch);
};
