import { useDispatch, useSelector } from 'react-redux';
import { fetchAccessorialData } from '../../../actions';
import { selectAccessorialData } from '../../../selectors';
import { type TCompanyData } from '../../../stores';
import { useEffect } from 'react';

export const useReduxAccessorialData = (shouldRefetch?: boolean): TCompanyData => {
  const dispatch = useDispatch();
  const accessorialData = useSelector(selectAccessorialData);

  useEffect(() => {
    if (
      (!accessorialData.hasLoaded && !accessorialData.isLoading) ||
      (!accessorialData.isLoading && shouldRefetch)
    ) {
      dispatch(fetchAccessorialData());
    }
  }, [accessorialData, dispatch, shouldRefetch]);

  return accessorialData;
};
