"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchInput = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const SearchInput_styles_1 = require("./SearchInput.styles");
const SearchInput = ({ addAlert, comingSoon, customCompleteValues, defaultQuantity, defaultTruckSelection, defaultTruckType, existingFormData, formParams, getRecentSeachesByLocationType, googleMapsApiKey, handleSubmitSuccess, isLoading, language, loadDefinition, minDate, shouldAllowPartialSearchInput, shouldShowAllTypes, t, userData, }) => {
    var _a, _b;
    const [currentTab, setCurrentTab] = (0, react_1.useState)((existingFormData === null || existingFormData === void 0 ? void 0 : existingFormData.truckloadType) || defaultTruckType);
    const [availableAccessorials, setAvailableAccessorials] = (0, react_1.useState)(formParams === null || formParams === void 0 ? void 0 : formParams.shipmentFeaturesEquipmentAccessorials[defaultTruckSelection]);
    const [availableEquipment, setAvailableEquipment] = (0, react_1.useState)({});
    const [areCargoDefinitionsRequired, setCargoDefinitionsRequired] = (0, react_1.useState)(false);
    const classes = (0, SearchInput_styles_1.useSearchStyles)();
    const { clearErrors, control, formState: { errors }, getValues, handleSubmit, setError, setValue, watch, } = (0, react_hook_form_1.useForm)({
        defaultValues: existingFormData || {
            accessorialMetadata: [{ value: '' }],
            accessorials: [{ value: '' }],
            destination: null,
            equipment: ((_a = formParams === null || formParams === void 0 ? void 0 : formParams.searchEquipment) === null || _a === void 0 ? void 0 : _a[defaultTruckSelection].equipment) || null,
            loadDefinition,
            origin: null,
            quantity: defaultQuantity,
            shipmentDate: null,
            truckloadType: defaultTruckType,
        },
    });
    const equipmentChange = watch('equipment');
    const accessorialFieldControls = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'accessorials',
    });
    const accessorialMetadataFieldControls = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'accessorialMetadata',
    });
    const { append, fields: cargoFields, remove, update, } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'loadDefinition.cargoSpecifications',
    });
    const areFormValuesRequired = typeof shouldAllowPartialSearchInput === 'undefined' ? true : !shouldAllowPartialSearchInput;
    // Set map properties
    const handleChangeTabs = (0, react_1.useCallback)((_event, value) => {
        var _a;
        // This is the list of equipment types
        const selectedShipmentFeature = formParams.shipmentFeatures[value];
        if (selectedShipmentFeature) {
            // Take the first type of equipment to get available equipment for this shipment type
            const selectedFeatureEquipment = formParams.shipmentFeaturesEquipment[value][selectedShipmentFeature[0]];
            const selectedShipmentEquipmentCode = selectedFeatureEquipment[0];
            // need to call `setValue` on the form `equipment`
            setValue('equipment', (_a = formParams === null || formParams === void 0 ? void 0 : formParams.searchEquipment) === null || _a === void 0 ? void 0 : _a[selectedShipmentEquipmentCode].equipment);
        }
        setCurrentTab(value);
        setValue('truckloadType', value);
    }, [setCurrentTab, formParams, setValue]);
    const generalInformation = !isLoading && (react_1.default.createElement(ux_1.Grid, null,
        react_1.default.createElement(ux_1.Container, { className: classes.searchComponent },
            react_1.default.createElement(__1.SearchParamsHeader, { accessorialFields: [], accessorialFormControls: accessorialFieldControls, accessorials: {}, addAlert: addAlert, areFormValuesRequired: areFormValuesRequired, clearErrors: clearErrors, control: control, customCompleteValues: customCompleteValues, enabledFields: [
                    schemas_1.ESearchParamsV1.shipmentDate,
                    schemas_1.ESearchParamsV1.origin,
                    schemas_1.ESearchParamsV1.destination,
                ], equipment: {}, errors: errors, estimatedDeliveryDate: null, formParams: formParams, getRecentSeachesByLocationType: getRecentSeachesByLocationType, getValues: getValues, googleMapsApiKey: googleMapsApiKey, isLoading: isLoading, language: language, metadataFormControls: accessorialMetadataFieldControls, minDate: minDate, setError: setError, setValue: setValue, t: t, userData: userData, values: getValues(), watch: watch }))));
    const equipment = !isLoading && (react_1.default.createElement(ux_1.Grid, null,
        react_1.default.createElement(ux_1.Container, { className: classes.searchComponent },
            react_1.default.createElement(ux_1.Typography, { className: classes.title, fontStyle: "semibold", variant: "bodyLarge" }, `2. ${t('search:equipment')}`),
            react_1.default.createElement(ux_1.Typography, { variant: "body1" }, t('search:equipmentPleaseSelect')),
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: ((_b = formParams === null || formParams === void 0 ? void 0 : formParams.searchEquipment) === null || _b === void 0 ? void 0 : _b[defaultTruckSelection].equipment) || null, name: "equipment", render: ({ field: { onChange, value } }) => {
                    if (!value)
                        return react_1.default.createElement(react_1.default.Fragment, null);
                    return (react_1.default.createElement(__1.SearchEquipment, { onChange: onChange, searchEquipment: availableEquipment, t: t, value: value }));
                } }),
            react_1.default.createElement(ux_1.Collapse, { in: areCargoDefinitionsRequired, unmountOnExit: true },
                react_1.default.createElement(ux_1.Box, { mb: 3 },
                    react_1.default.createElement(ux_1.Typography, { className: classes.title, fontStyle: "semibold", variant: "bodyMedium" }, t('common:cargo')),
                    react_1.default.createElement(__1.LtlCargoSpecifications, { control: control, equipmentCode: equipmentChange === null || equipmentChange === void 0 ? void 0 : equipmentChange.code, errors: (errors === null || errors === void 0 ? void 0 : errors.loadDefinition) || [], fields: cargoFields, formAppend: append, formRemove: remove, formValueName: "loadDefinition.cargoSpecifications", isTemperatureDataRequired: areCargoDefinitionsRequired && (equipmentChange === null || equipmentChange === void 0 ? void 0 : equipmentChange.code.includes('REEFER')), setValue: setValue, t: t, update: update, watch: watch }))),
            react_1.default.createElement(ux_1.Typography, { variant: "body1" }, t('search:equipmentGuidelines')))));
    const accessorials = !isLoading && (react_1.default.createElement(ux_1.Grid, null,
        react_1.default.createElement(ux_1.Container, { className: classes.searchComponent },
            react_1.default.createElement(ux_1.Typography, { className: classes.title, fontStyle: "semibold", variant: "bodyLarge" }, `3. ${t('search:additionalServices')}`),
            react_1.default.createElement(ux_1.Typography, { variant: "body1" }, t('search:additionalServicesPleaseSelect')),
            react_1.default.createElement(__1.SearchAccessorials, { accessorialFormControls: accessorialFieldControls, accessorialRecords: (formParams === null || formParams === void 0 ? void 0 : formParams.accessorials) || {}, availableAccessorials: availableAccessorials, control: control, fields: accessorialFieldControls.fields, metadataFormControls: accessorialMetadataFieldControls, setValue: setValue, t: t, values: getValues() }))));
    // Prep the tabs
    const tabs = formParams.shipmentTypes
        .filter((type) => {
        return type.isActive;
    })
        .map((value) => {
        return [
            { label: t(`search:${value.name.toLowerCase()}`), value: value.name },
            { label: '', value: null },
        ];
    })
        .flat();
    // Remove the last `null` label
    // We add them in pair to add dividers in between elements
    // but we don't need a divider after the last element
    tabs.pop();
    tabs.reverse();
    // Shipment Types can be enabled before their equipment is active, in this case
    // we want to show the disabled content component until their is equipment to show
    const disabledContent = (react_1.default.createElement(ux_1.Container, { className: classes.tabContainer, "data-testid": `SearchInput-${currentTab}-DisabledContent` }, comingSoon));
    const content = Boolean(Object.keys(availableEquipment).length) ? (react_1.default.createElement(react_1.default.Fragment, null,
        generalInformation,
        equipment,
        accessorials,
        react_1.default.createElement(ux_1.Grid, null,
            react_1.default.createElement(ux_1.Container, { className: classes.justifyEnd },
                react_1.default.createElement(ux_1.Button, { className: classes.button, color: "primary", "data-testid": `SearchInput-SubmitButton`, disabled: isLoading, type: "submit", variant: "contained" },
                    react_1.default.createElement(ux_1.Box, null, t('common:next'))))))) : (disabledContent);
    const internalContent = tabs.length !== 1 ? (react_1.default.createElement(ux_1.TabContext, { value: currentTab },
        react_1.default.createElement(__1.TabButtons, { hasMargins: true, onTabChange: handleChangeTabs, tabs: tabs, testId: "SearchInput", value: currentTab }),
        formParams.shipmentTypes.map((shipmentType) => {
            const tabId = `SearchInput-${shipmentType.name}`;
            const testId = currentTab === shipmentType.name ? `${tabId}-Selected` : tabId;
            return (react_1.default.createElement(ux_1.TabPanel, { key: shipmentType.name, value: shipmentType.name },
                react_1.default.createElement(ux_1.Box, { "data-testid": testId, id: tabId }, shipmentType.isEnabled || shouldShowAllTypes ? content : disabledContent)));
        }))) : (content);
    (0, react_1.useEffect)(() => {
        setAvailableAccessorials((formParams === null || formParams === void 0 ? void 0 : formParams.shipmentFeaturesEquipmentAccessorials[equipmentChange === null || equipmentChange === void 0 ? void 0 : equipmentChange.code]) || []);
        setCargoDefinitionsRequired(currentTab === schemas_1.ETruckloadTypeV1.LTL);
    }, [
        equipmentChange,
        setAvailableAccessorials,
        formParams,
        currentTab,
        setCargoDefinitionsRequired,
    ]);
    (0, react_1.useEffect)(() => {
        const newEquipment = {};
        Object.keys(formParams.shipmentFeaturesEquipment[currentTab] || {}).forEach((key) => {
            formParams.shipmentFeaturesEquipment[currentTab][key].forEach((equipmentCode) => {
                newEquipment[equipmentCode] = formParams.searchEquipment[equipmentCode];
            });
        });
        // check to see if the selected equipment is available in the selected tab
        const equipment = getValues('equipment');
        const availableCodes = Object.keys(newEquipment);
        if (!availableCodes.includes(equipment === null || equipment === void 0 ? void 0 : equipment.code)) {
            // if not, select the first equipment in the  newly available equipment
            setValue('equipment', Object.values(newEquipment)[0].equipment);
        }
        setAvailableEquipment(newEquipment);
    }, [currentTab, setAvailableEquipment, formParams, getValues, setValue]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
        react_1.default.createElement("form", { className: classes.container, onSubmit: handleSubmit((data) => {
                handleSubmitSuccess(data);
            }) },
            react_1.default.createElement(ux_1.Grid, null,
                react_1.default.createElement(__1.SearchStepper, { currentStep: schemas_1.ESearchQuoteFormStepsV1.search, t: t })),
            react_1.default.createElement(ux_1.Grid, null, internalContent)),
        react_1.default.createElement(__1.Loading, { isLoading: isLoading, t: t })));
};
exports.SearchInput = SearchInput;
