import {
  type AppThunk,
  setShipmentTagsError,
  setShipmentTagsLoaded,
  setShipmentTagsLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const getShipmentEntityTags = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setShipmentTagsLoading());

    const results: Record<string, any> = await makeRequestToApiGateway('shipmentGetV1Tags', null, {
      'Content-Type': 'application/json',
    });

    dispatch(setShipmentTagsLoaded({ items: results }));
  } catch (error) {
    dispatch(setShipmentTagsError({ error: error.message }));
  }
};
