"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDateStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    completeFieldIcon: {
        color: theme.palette.primary.main,
    },
    highlightFieldIcon: {
        color: theme.palette.primary.light,
    },
    incompleteFieldIcon: {
        color: '#C4C4C4',
    },
}));
exports.useDateStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        background: theme.palette.common.white,
    },
}));
