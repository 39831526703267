"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudDrizzleIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18,24a1,1,0,0,1-1-1V22a1,1,0,0,1,2,0v1A1,1,0,0,1,18,24Zm-6,0a1,1,0,0,1-1-1V22a1,1,0,0,1,2,0v1A1,1,0,0,1,12,24ZM6,24a1,1,0,0,1-1-1V22a1,1,0,0,1,2,0v1A1,1,0,0,1,6,24ZM5,19.973a1.032,1.032,0,0,1-.111-.006A5.526,5.526,0,0,1,1.8,10.43a1,1,0,0,0,.345-.9,8.147,8.147,0,0,1-.033-2.889A7.946,7.946,0,0,1,8.5.137a8.06,8.06,0,0,1,8.735,4.44,1.036,1.036,0,0,0,.742.569A7.5,7.5,0,0,1,19.4,19.39a1,1,0,0,1-.792-1.837A5.5,5.5,0,0,0,17.583,7.106a3.011,3.011,0,0,1-2.158-1.672A6,6,0,0,0,4.086,6.967,6.142,6.142,0,0,0,4.11,9.148a3.005,3.005,0,0,1-.964,2.762A3.522,3.522,0,0,0,5.11,17.979,1,1,0,0,1,5,19.973ZM15,18a1,1,0,0,1-1-1V16a1,1,0,0,1,2,0v1A1,1,0,0,1,15,18ZM9,18a1,1,0,0,1-1-1V16a1,1,0,0,1,2,0v1A1,1,0,0,1,9,18Z" })));
exports.default = TruxwebCloudDrizzleIcon;
