import { ELanguageV1, EUserTypeV1, type TCarrierShipmentDataRow } from '@truxweb/schemas';
import { type GridFilterModel, type GridSortModel } from '@truxweb/ux';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShipments } from '../../../actions';
import { selectCarrierShipmentListData } from '../../../selectors';
import { type TFunction } from 'next-i18next';
import { type TReduxListResponse } from '../../../types';
import { useEffect } from 'react';

type TCarrierShipmentListData = {
  userType: EUserTypeV1;
  t: TFunction;
  limit: number;
  pageSize?: number;
  page?: number;
  language: ELanguageV1;
  sort?: GridSortModel;
  filter?: GridFilterModel | null;
};

export const useCarrierShipmentListData = (
  request: TCarrierShipmentListData,
  shouldFetch: boolean,
  shouldRefetch?: boolean
): TReduxListResponse<TCarrierShipmentDataRow[]> => {
  const { filter, language, limit, page, pageSize, sort, t, userType } = request;
  const dispatch = useDispatch();
  const shipmentList = useSelector(selectCarrierShipmentListData(t));

  useEffect(() => {
    if (
      shouldFetch &&
      ((!shipmentList.hasLoaded && !shipmentList.isLoading) ||
        (!shipmentList.isLoading && shouldRefetch))
    ) {
      dispatch(
        fetchShipments({
          filter,
          language,
          limit,
          page,
          pageSize,
          sort,
          userType,
        })
      );
    }
  }, [
    shipmentList,
    dispatch,
    shouldRefetch,
    page,
    pageSize,
    language,
    sort,
    filter,
    limit,
    t,
    userType,
    shouldFetch,
  ]);

  return shipmentList;
};
