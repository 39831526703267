import { Box, Grid, Typography } from '@truxweb/ux';
import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TPublicShipmentsMarketingProps = {
  size?: 'sm' | 'lg';
};
export const PublicShipmentsMarketing = ({ size }: TPublicShipmentsMarketingProps): JSX.Element => {
  let headerSize: any = 'bodyLarge';
  let bodySize: any = 'bodyMedium';
  let subheaderSize: any = 'bodyMedium';

  if (size && size === 'lg') {
    headerSize = 'h2';
    subheaderSize = 'bodyLarge';
    bodySize = 'bodyLarge';
  }
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  return (
    <Box p={3}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant={headerSize}>
            {t('common:publicShipmentsMarketing-Header')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="primaryLight" fontStyle="semibold" variant={subheaderSize}>
            {t('common:publicShipmentsMarketing-P1-Header')}
          </Typography>
        </Grid>
        <Grid container direction="row" item spacing={2}>
          <Grid container item spacing={3} xs={6}>
            <Grid item>
              <Typography variant={bodySize}>{t('common:publicShipmentsMarketing-P1')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant={bodySize}>{t('common:publicShipmentsMarketing-P2')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Image
              height={512}
              src={`/assets/images/marketing/publicShipmentsMarketing.png`}
              width={800}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography color="primaryLight" fontStyle="semibold" variant={subheaderSize}>
            {t('common:publicShipmentsMarketing-P3-Header')}
          </Typography>
          <Typography variant={bodySize}>{t('common:publicShipmentsMarketing-P3')}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
