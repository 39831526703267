"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMentionStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    actions: ({ isChatHelpShown }) => {
        return {
            borderRadius: isChatHelpShown ? 0 : '0 0 16px 16px',
            padding: theme.spacing(1),
        };
    },
    actionsBackground: ({ isCommentPubliclyViewable, viewingUserType }) => {
        let background = theme.palette.primary.light;
        if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB && !isCommentPubliclyViewable) {
            background = theme.palette.primary.dark;
        }
        return {
            background,
        };
    },
    chatHelp: {
        borderRadius: '0 0 16px 16px',
    },
    commentBox: {
        '& img': {
            display: 'block',
            maxWidth: '250px',
            paddingBottom: theme.spacing(1.5),
            paddingTop: theme.spacing(1.5),
        },
        '&:focus': {
            outline: 'none',
        },
        background: ({ isMultiChat, viewingUserType }) => isMultiChat && viewingUserType === schemas_1.EUserTypeV1.SHIPPER ? '#EAEAEA' : 'transparent',
        borderRadius: '24px 24px 0 0',
        height: 'auto',
        minHeight: theme.spacing(8),
        padding: theme.spacing(2.5),
        whiteSpace: 'pre-line',
        width: '100%',
        wordBreak: 'break-word',
    },
    commentBoxBackground: {
        backgroundColor: ({ isQuoteComment, viewingUserType }) => {
            let background = '#EAEAEA';
            if (isQuoteComment && viewingUserType !== schemas_1.EUserTypeV1.TRUXWEB) {
                background = theme.palette.common.white;
            }
            return background;
        },
    },
    commentBoxContainer: {
        borderRadius: `${theme.spacing(2)}px`,
        height: 'auto',
        minHeight: theme.spacing(4.5),
    },
    commentButton: {
        '&:disabled ': {
            background: 'rgb(220, 220, 220)',
            fill: '#C4C4C4 !important',
        },
        background: `#fff`,
        border: 0,
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
    },
    commentInput: ({ isCommentPubliclyViewable, isQuoteComment, viewingUserType }) => {
        let background = 'none';
        if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
            background = isCommentPubliclyViewable
                ? theme.palette.common.white
                : theme.palette.primary.light;
        }
        else if (isQuoteComment) {
            background = theme.palette.common.white;
        }
        return {
            background,
            borderRadius: theme.spacing(2),
            marginTop: viewingUserType === schemas_1.EUserTypeV1.TRUXWEB ? theme.spacing(3) : theme.spacing(6),
            padding: viewingUserType === schemas_1.EUserTypeV1.TRUXWEB ? theme.spacing(0.5) : 0,
        };
    },
    explanation: {
        color: theme.palette.common.white,
    },
    icon: {
        '&:hover': {
            fill: theme.palette.primary.dark,
        },
        fill: theme.palette.primary.light,
    },
    iconDisabled: {
        fill: '#636363',
    },
    labelClassName: ({ isCommentPubliclyViewable }) => {
        return {
            color: isCommentPubliclyViewable ? theme.palette.common.black : theme.palette.common.white,
        };
    },
    mention: {
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.common.white,
        padding: theme.spacing(0.25),
    },
    mentionComplete: {
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.common.white,
        padding: theme.spacing(0.25),
    },
}));
exports.useMentionStyles = (0, ux_1.makeStyles)((theme) => ({
    mentionComplete: {
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.common.white,
        listStyleType: 'none',
        padding: theme.spacing(0.25),
        width: theme.spacing(16),
    },
    mentionCompleteSelected: {
        background: theme.palette.primary.light,
    },
}));
