"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBowlingPinsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9,8a11.618,11.618,0,0,1,.554-2.175A7.168,7.168,0,0,0,10,4,4,4,0,0,0,2,4a7.168,7.168,0,0,0,.446,1.825A11.618,11.618,0,0,1,3,8a8.433,8.433,0,0,1-1.264,3.536A11.789,11.789,0,0,0,0,17a11.08,11.08,0,0,0,.986,4.7A4,4,0,0,0,4.61,24H7.39a4,4,0,0,0,3.624-2.3A11.08,11.08,0,0,0,12,17a11.789,11.789,0,0,0-1.736-5.464A8.433,8.433,0,0,1,9,8ZM6,2A2,2,0,0,1,8,3.973a12,12,0,0,1-.355,1.253A10.708,10.708,0,0,0,7,8H5a10.708,10.708,0,0,0-.646-2.774C4.211,4.772,4.016,4.15,4,4A2,2,0,0,1,6,2ZM9.2,20.856A2,2,0,0,1,7.39,22H4.61A2,2,0,0,1,2.8,20.857,9.092,9.092,0,0,1,2,17a10.092,10.092,0,0,1,1.512-4.542A18.366,18.366,0,0,0,4.637,10H7.363a18.366,18.366,0,0,0,1.125,2.458A10.092,10.092,0,0,1,10,17,9.084,9.084,0,0,1,9.2,20.856Zm13.064-9.32A8.433,8.433,0,0,1,21,8a11.618,11.618,0,0,1,.554-2.175A7.168,7.168,0,0,0,22,4a4,4,0,0,0-8,0,7.168,7.168,0,0,0,.446,1.825A11.618,11.618,0,0,1,15,8a8.433,8.433,0,0,1-1.264,3.536A11.789,11.789,0,0,0,12,17a11.08,11.08,0,0,0,.986,4.7A4,4,0,0,0,16.61,24h2.78a4,4,0,0,0,3.624-2.3A11.08,11.08,0,0,0,24,17,11.789,11.789,0,0,0,22.264,11.536ZM18,2a2,2,0,0,1,2,1.973,12,12,0,0,1-.355,1.253A10.708,10.708,0,0,0,19,8H17a10.708,10.708,0,0,0-.646-2.774C16.211,4.772,16.016,4.15,16,4A2,2,0,0,1,18,2Zm3.2,18.856A2,2,0,0,1,19.39,22H16.61a2,2,0,0,1-1.81-1.143A9.092,9.092,0,0,1,14,17a10.092,10.092,0,0,1,1.512-4.542A18.366,18.366,0,0,0,16.637,10h2.726a18.366,18.366,0,0,0,1.125,2.458A10.092,10.092,0,0,1,22,17,9.084,9.084,0,0,1,21.2,20.856Z" })));
exports.default = TruxwebBowlingPinsIcon;
