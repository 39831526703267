"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARE_MOBILE_MENUS_DARK = exports._ADDITIONAL_BLUE_COLOR = exports.RESPONSIVE_THEME = void 0;
const __1 = require("..");
/* eslint-disable sort-keys */
const breakpoints = (0, __1.createTheme)({
    breakpoints: {
        keys: ['xs', 'largeMobile', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 360,
            largeMobile: 550,
            sm: 768,
            md: 1024,
            lg: 1440,
            xl: 1920,
        },
    },
});
exports.RESPONSIVE_THEME = (0, __1.createTheme)({
    breakpoints: breakpoints.breakpoints,
    palette: {
        primary: {
            main: '#0087CE',
            dark: '#061946',
        },
        secondary: {
            main: '#FCBE0F',
        },
        error: {
            main: '#E94141',
        },
        grey: {
            [400]: '#C4C4C4',
            [600]: '#636363',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#000000',
            secondary: '#42413E',
        },
    },
    typography: {
        fontSize: 15,
        htmlFontSize: 15,
        body1: {
            color: '#636363',
            fontSize: '0.9375rem',
            fontWeight: 400,
            lineHeight: '19px',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '0.75rem',
                lineHeight: '1rem',
            },
        },
        body2: {
            color: '#636363',
            fontSize: '1.25rem',
            fontWeight: 400,
            lineHeight: '28px',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '0.9375rem',
                lineHeight: '19px',
            },
            [breakpoints.breakpoints.up('md')]: {
                fontSize: '1.25rem',
                lineHeight: '28px',
            },
        },
        h1: {
            color: '#224882',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '3.125rem',
            lineHeight: '55px',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '2.1875rem',
                lineHeight: '40px',
            },
            [breakpoints.breakpoints.down('xs')]: {
                fontSize: '1.875rem',
                lineHeight: '33px',
            },
        },
        h2: {
            color: '#224882',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '2.5rem',
            lineHeight: '45px',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '1.875rem',
                lineHeight: '2.1875rem',
            },
            [breakpoints.breakpoints.down('xs')]: {
                fontSize: '1.5625rem',
                lineHeight: '1.875rem',
            },
        },
        h3: {
            color: '#636363',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.875rem',
            lineHeight: '2.1875rem',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '1.25rem',
                lineHeight: '1.5625rem',
            },
        },
        h4: {
            color: '#224882',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1.5625em',
            lineHeight: '2.1875rem',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '0.9375rem',
                lineHeight: '1.25rem',
            },
        },
        h5: {
            fontFamily: 'Poppins',
            color: '#0087CE',
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '1.875rem',
            fontStyle: 'normal',
            [breakpoints.breakpoints.down('md')]: {
                fontSize: '0.75rem',
                lineHeight: '1rem',
            },
        },
        h6: {
            fontFamily: 'Poppins',
            color: '#006CA5',
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: '1.5625rem',
            fontStyle: 'normal',
            [breakpoints.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
                lineHeight: '1rem',
            },
        },
    },
    overrides: {
        MuiButton: {
            label: {
                fontFamily: 'Poppins',
                textTransform: 'none',
                fontSize: 18,
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '28px',
                letterSpacing: '0.03em',
                textAlign: 'center',
                [breakpoints.breakpoints.down('xs')]: {
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                },
            },
            root: {
                borderRadius: 40,
                boxShadow: 'unset',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 20,
                color: '#224882',
                '&:hover': {
                    color: '#224882',
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    border: '1px solid #0087CE',
                    borderColor: '#0087CE',
                },
            },
            notchedOutline: {
                border: '0.5px solid #C4C4C4',
                borderColor: '#C4C4C4',
            },
        },
        MuiIconButton: {
            label: {
                background: 'transparent',
            },
        },
        MuiFormControlLabel: {
            root: {
                color: '#C4C4C4',
            },
        },
        MuiRadio: {
            root: {
                color: '#C4C4C4',
            },
        },
        MuiSelect: {
            root: {
                borderRadius: 8,
            },
        },
        MuiMenuItem: {
            root: {
                '&:first-child': {
                    borderRadius: '4px 4px 0px 0px',
                },
                '&:last-child': {
                    borderRadius: '0px 0px 4px 4px',
                },
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 15,
                lineHeight: '48px',
                height: '48px',
            },
        },
        MuiList: {
            padding: {
                paddingBottom: 0,
                paddingTop: 0,
            },
        },
        MuiListSubheader: {
            root: {
                color: '#636363',
                fontWeight: 'normal',
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    fontWeight: 'normal',
                    color: '#FFF',
                    backgroundColor: '#0087CE',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#224882',
                    },
                },
                color: '#636363',
                '&:hover': {
                    color: '#FFF',
                    backgroundColor: '#0087CE',
                    fontWeight: 'normal',
                },
            },
            button: {
                color: '#636363',
                '&:hover': {
                    color: '#FFF',
                    backgroundColor: '#0087CE',
                    fontWeight: 'normal',
                },
            },
        },
    },
});
/* eslint-enable sort-keys */
exports._ADDITIONAL_BLUE_COLOR = '#EBF3F9';
exports.ARE_MOBILE_MENUS_DARK = true;
