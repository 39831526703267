import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: () => ({
    background: 'transparent',
  }),
  containerFilled: {
    background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%,  ${theme.palette.primary.dark} 35%,  ${theme.palette.primary.light} 100%)`,
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: 600,
    textDecoration: 'none',
  },
}));
