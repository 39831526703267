"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebChessBishopIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,22H17.65A8.985,8.985,0,0,0,21,15c0-4.677-5.961-9.848-7.846-11.367A2,2,0,1,0,10.8,3.6C8.786,5.009,3,9.531,3,15a8.985,8.985,0,0,0,3.35,7H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2ZM5,15c0-4.485,5.2-8.526,6.958-9.765a33.425,33.425,0,0,1,3.4,3.172l-4.127,4.952a1,1,0,0,0,1.536,1.282L16.68,9.946C18,11.619,19,13.423,19,15A7,7,0,0,1,5,15Z" })));
exports.default = TruxwebChessBishopIcon;
