import { getLocalizedRoute, hasWindow } from '../../utils';
import React, { useEffect } from 'react';
import { TPageId, TTruxwebPageProps } from '../../types';
import { useAuth, useAuthBoolean } from '../../hooks';
import { BasePage } from '..';
import { Grid } from '@truxweb/ux';
import { Loading } from '../../components';
import { useRouter } from 'next/router';

type TAuthorizedPageProps = TTruxwebPageProps & {
  children?: React.ReactNode;
  pageId?: TPageId;
  redirectPageId?: TPageId;
  redirectPageQueryParams?: string;
};

// The intended use for this page is to block access to a resource which a user
// must be logged in to see.
// NOTE: This does not as yet account for permissions
export const AuthorizedPage = ({
  children,
  pageId,
  redirectPageId,
  redirectPageQueryParams,
}: TAuthorizedPageProps): JSX.Element => {
  const auth = useAuth();
  const isAuthed = useAuthBoolean();
  const router = useRouter();

  useEffect(() => {
    if ((auth.hasLoaded || auth.hasLoggedOut) && !isAuthed && hasWindow()) {
      const { locale } = router;
      const redirectRoute = redirectPageId ? getLocalizedRoute(locale, redirectPageId) : '/';
      const route = [redirectRoute, redirectPageQueryParams].filter((piece) => {
        return Boolean(piece);
      });
      router.push(route.join('?'));
    }
  }, [auth, isAuthed, redirectPageId, redirectPageQueryParams, router]);

  return (
    <>
      <Loading isGlobalLoader isLoading={!auth.hasLoaded} />
      <BasePage pageId={pageId}>
        <Grid container justifyContent="flex-start">
          <Grid item xs>
            <>{isAuthed && children}</>
          </Grid>
        </Grid>
      </BasePage>
    </>
  );
};
