"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMountainsIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20,8a4,4,0,0,1,0-8A4,4,0,0,1,20,8Zm0-6a2,2,0,0,0,0,4A2,2,0,0,0,20,2Zm1.452,22H12.589a2.548,2.548,0,0,1-2.221-1.26,2.463,2.463,0,0,1-.029-2.471l4.436-7.959a2.6,2.6,0,0,1,4.5,0L23.7,20.263a2.468,2.468,0,0,1-.028,2.475A2.551,2.551,0,0,1,21.452,24ZM17.023,13a.56.56,0,0,0-.5.283l-4.436,7.959a.473.473,0,0,0,.006.485.559.559,0,0,0,.5.273h8.863a.562.562,0,0,0,.5-.275.479.479,0,0,0,0-.489l-4.432-7.952h0A.558.558,0,0,0,17.023,13ZM8,23a1,1,0,0,0-1-1H3.35a1.349,1.349,0,0,1-1.184-1.994l6.652-12.3A1.329,1.329,0,0,1,10,7h0a1.326,1.326,0,0,1,1.183.706L12.41,9.973a1,1,0,1,0,1.76-.952L12.944,6.755a3.344,3.344,0,0,0-5.885,0L.406,19.055A3.351,3.351,0,0,0,3.35,24H7A1,1,0,0,0,8,23Z" })));
exports.default = TruxwebMountainsIcon;
