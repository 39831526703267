import { EPermissionV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const validateUserPermission = async (
  permission: EPermissionV1
): Promise<[EPermissionV1, boolean]> => {
  const results: unknown = await makeRequestToApiGateway(
    'userPostV1PermissionsValidate',
    null,
    {
      'Content-Type': 'application/json',
    },
    [permission]
  );
  return [permission, results as boolean];
};
