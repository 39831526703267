import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  columnWrap: {
    margin: '3% 10% 3% 0',
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
      marginRight: '10%',
    },
  },
  container: { marginBottom: '-1px', width: '100%' },
  darkBlueTrim: {
    backgroundColor: theme.palette.primary.dark,
    height: theme.spacing(4),
  },
  fullHeight: { height: '100%' },
  link: { color: 'inherit', textDecoration: 'none' },
  mobileTruxiWrap: {
    paddingRight: '15%',
  },
  mobileWrap: {
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(44),
    },
  },
  primarybg: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(34),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(23),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(35),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(58),
    },
    zIndex: 1,
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 600,
    marginBottom: '1em',
    [theme.breakpoints.down('xs')]: {
      marginTop: '3em',
    },
  },
  truxi: {
    bottom: `-${theme.spacing(4)}px`,
    height: theme.spacing(33),
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      bottom: `-${theme.spacing(5)}px`,
      height: theme.spacing(19.5),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(33),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(33),
    },
    [theme.breakpoints.up('xl')]: {
      bottom: `-${theme.spacing(8)}px`,
      height: theme.spacing(56),
    },
  },
}));
