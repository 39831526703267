"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    backgroundGrey: {
        background: theme.palette.grey[100],
    },
    bannerContainer: {
        marginLeft: ({ isCenteredContent, isLeftTextMenuOpen }) => {
            if (isCenteredContent)
                return isLeftTextMenuOpen ? `0px` : `${theme.spacing(10)}px`;
            return `-${theme.spacing(10)}px`;
        },
        width: ({ isCenteredContent, isLeftTextMenuOpen }) => {
            if (isCenteredContent)
                return isLeftTextMenuOpen ? `100%` : `calc(100% - 80px)`;
            return 'calc(100% + 80px)';
        },
    },
    centeredContentPanel: {
        background: '#f5f5f5',
        marginLeft: ({ isLeftTextMenuOpen }) => (isLeftTextMenuOpen ? `0px` : `${theme.spacing(10)}px`),
        paddingLeft: theme.spacing(6),
    },
    containerDashboard: {
        height: '100%',
        minHeight: '100vh',
        minWidth: '99vw',
        overflow: 'hidden',
        width: `100vw`,
    },
    contentPanel: {
        marginLeft: ({ isLeftTextMenuOpen }) => (isLeftTextMenuOpen ? `0px` : `${theme.spacing(10)}px`),
        // marginRight: theme.spacing(3),
        paddingLeft: theme.spacing(6),
    },
    headerContainer: {
        minWidth: '1000px',
        width: ({ isLeftTextMenuOpen }) => !isLeftTextMenuOpen ? `calc(100vw - 152px)` : `calc(100vw - 412px)`,
    },
    leftSidebar: {
        background: theme.palette.common.white,
    },
}));
