import {
  Box,
  Button,
  Grid,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Typography,
} from '@truxweb/ux';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { SiteMap } from '..';
import { TPageId } from '../../types';
import { useFooterPalette } from '../../hooks';
import { useStyles } from './MobileDropDownMenuHeader.styles';

type TMobileDropDownMenuHeaderProps = {
  additionalLinks?: JSX.Element[];
  isItemOpen?: boolean;
  hasChevron?: boolean;
  pages: TPageId[];
  setIsActive?: (arg: string) => void;
  text: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const MobileDropDownMenuHeader = ({
  additionalLinks,
  hasChevron = true,
  isItemOpen,
  pages,
  setIsActive,
  text,
  variant,
}: TMobileDropDownMenuHeaderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const footerPalette = useFooterPalette();
  const classes = useStyles({ footerPalette, isOpen });

  useEffect(() => {
    if (isItemOpen) {
      setIsOpen(isItemOpen);
    } else {
      setIsOpen(false);
    }
  }, [isItemOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    {
      setIsActive && setIsActive(text);
    }
  };
  return (
    <>
      <Box mb={2} mt={2}>
        <Grid alignItems="flex-start" container>
          <Grid item>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              id="mobileHamburgerClose"
              onClick={handleToggle}
              ref={anchorRef}
            >
              <Typography className={classes.siteMapHeader} variant={variant || 'h6'}>
                {text}
              </Typography>
            </Button>
            <Grid
              className={classnames(classes.dropDownMenuHeader, isOpen && classes.transitionIn)}
              item
              xs
            >
              <Box>
                <SiteMap isMobile linkClass={classes.siteMapLink} pages={pages} />
                {additionalLinks &&
                  additionalLinks.map((element, index) => (
                    <Box key={`mobile-drop-down-header-${index}`}>{element}</Box>
                  ))}
              </Box>
            </Grid>
          </Grid>
          {isOpen && hasChevron ? (
            <KeyboardArrowUpIcon className={classes.icon} fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon className={classes.icon} fontSize="small" />
          )}
        </Grid>
      </Box>
    </>
  );
};
