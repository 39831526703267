"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFerrisWheelIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,11a3,3,0,0,0-2.841-2.984,9.918,9.918,0,0,0-5.175-5.175A3,3,0,0,0,13,0H11A3,3,0,0,0,8.016,2.841,9.921,9.921,0,0,0,2.841,8.016a2.985,2.985,0,0,0-.659,5.856,9.964,9.964,0,0,0,5.332,7.069l-.48,1.8a1,1,0,0,0,.708,1.224A1.011,1.011,0,0,0,8,24a1,1,0,0,0,.966-.742L9.4,21.652a9.928,9.928,0,0,0,5.211,0l.428,1.606a1,1,0,0,0,1.932-.516l-.48-1.8a9.974,9.974,0,0,0,5.333-7.07A2.994,2.994,0,0,0,24,11ZM11,2h2a1,1,0,0,1,0,2H11a1,1,0,0,1,0-2ZM3,10H5a1,1,0,0,1,0,2H3a1,1,0,0,1,0-2Zm9,10a8.021,8.021,0,0,1-2.09-.28l1.7-6.364a.407.407,0,0,1,.787,0l1.7,6.364A8.034,8.034,0,0,1,12,20Zm3.955-1.049-1.63-6.11A2.38,2.38,0,0,0,12,11.054h0a2.38,2.38,0,0,0-2.326,1.787l-1.629,6.11A7.962,7.962,0,0,1,4.26,14H5a3,3,0,0,0,.081-5.992A8.1,8.1,0,0,1,8.588,4.767,2.989,2.989,0,0,0,11,6h2a2.988,2.988,0,0,0,2.412-1.233,8.094,8.094,0,0,1,3.506,3.241A3,3,0,0,0,19,14h.74A7.968,7.968,0,0,1,15.955,18.951ZM21,12H19a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z" })));
exports.default = TruxwebFerrisWheelIcon;
