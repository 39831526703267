// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequiredTruckFeatureV1 = void 0;
var RequiredTruckFeatureV1;
(function (RequiredTruckFeatureV1) {
    RequiredTruckFeatureV1["DRY"] = "DRY";
    RequiredTruckFeatureV1["FLATBED"] = "FLATBED";
    RequiredTruckFeatureV1["REFRIGERATED"] = "REFRIGERATED";
})(RequiredTruckFeatureV1 || (exports.RequiredTruckFeatureV1 = RequiredTruckFeatureV1 = {}));
