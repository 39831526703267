"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSaltPepperIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.832,11.445A7.8,7.8,0,0,1,20,6a1,1,0,0,0,0-2c-.138-5.276-7.863-5.272-8,0a1,1,0,0,0,0,2,7.8,7.8,0,0,1-1.832,5.445,8.845,8.845,0,0,0-1.3,9.445A4.994,4.994,0,0,0,13.5,24H18.5c3.761.121,5.506-4,5.5-7A9.847,9.847,0,0,0,21.832,11.445ZM16,2a2,2,0,0,1,2,2H14A2,2,0,0,1,16,2Zm2,4a8.5,8.5,0,0,0,1.188,5H12.811A8.492,8.492,0,0,0,14,6Zm3.271,14.155A2.975,2.975,0,0,1,18.5,22H13.5c-2.568.147-3.48-3.224-3.5-5a7.244,7.244,0,0,1,1.537-4h8.926A6.551,6.551,0,0,1,21.271,20.155ZM8,23c-.035,1.327-1.641.953-2.5,1-3.764.122-5.506-4-5.5-7-.042-4.5,4.509-6.634,4-11A1,1,0,0,1,4,4a4.025,4.025,0,0,1,6.667-2.981,1,1,0,0,1-1.334,1.49A2.012,2.012,0,0,0,6,4H8A1,1,0,0,1,8,6H6a8.492,8.492,0,0,1-1.189,5H7a1,1,0,0,1,0,2H3.537a6.554,6.554,0,0,0-.809,7.155A2.977,2.977,0,0,0,5.5,22H7A1,1,0,0,1,8,23Zm10-4a1,1,0,0,1-2,0A1,1,0,0,1,18,19Zm-2-3a1,1,0,0,1-2,0A1,1,0,0,1,16,16Zm4,0a1,1,0,0,1-2,0A1,1,0,0,1,20,16Z" })));
exports.default = TruxwebSaltPepperIcon;
