// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingTransactionMessageActionTypesV1 = void 0;
var BillingTransactionMessageActionTypesV1;
(function (BillingTransactionMessageActionTypesV1) {
    BillingTransactionMessageActionTypesV1["BLOCK"] = "BLOCK";
    BillingTransactionMessageActionTypesV1["UNBLOCK"] = "UNBLOCK";
    BillingTransactionMessageActionTypesV1["CANCEL"] = "CANCEL";
})(BillingTransactionMessageActionTypesV1 || (exports.BillingTransactionMessageActionTypesV1 = BillingTransactionMessageActionTypesV1 = {}));
