"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        background: 'rgba(0, 135, 206, 0.05)',
    },
    help: {
        background: theme.palette.secondary.light,
        borderRadius: theme.spacing(2),
    },
}));
