"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        background: '#f3f3f3',
        height: theme.spacing(8),
    },
    content: {
        height: '100%',
    },
}));
