// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentTrackingStatusV1 = void 0;
var ShipmentTrackingStatusV1;
(function (ShipmentTrackingStatusV1) {
    ShipmentTrackingStatusV1["AT_STOP"] = "AT_STOP";
    ShipmentTrackingStatusV1["COMPLETED"] = "COMPLETED";
    ShipmentTrackingStatusV1["DISPATCHED"] = "DISPATCHED";
    ShipmentTrackingStatusV1["IN_TRANSIT"] = "IN_TRANSIT";
    ShipmentTrackingStatusV1["INFO"] = "INFO";
    ShipmentTrackingStatusV1["TRACKING_FAILED"] = "TRACKING_FAILED";
})(ShipmentTrackingStatusV1 || (exports.ShipmentTrackingStatusV1 = ShipmentTrackingStatusV1 = {}));
