import { makeRequestToApiGateway } from '../../../apiUtils';
import { TCreditCardResponseV2 } from '@truxweb/schemas';

export const fetchCardDetailsByIdV2 = async (cardId: string): Promise<TCreditCardResponseV2> => {
  const results = await makeRequestToApiGateway(
    'billingGetV2CardById',
    { id: cardId },
    {
      'Content-Type': 'application/json',
    }
  );
  return results as TCreditCardResponseV2;
};
