import { Box, Grid, Typography } from '@truxweb/ux';
import { MobileDropDownMenuHeader, ResponsiveEmailSignUp, SocialMediaIconLinks } from '..';
import React, { useState } from 'react';
import { useAuthBoolean, useFooterPalette, usePermissions } from '../../hooks';
import { EPermissionV1 } from '@truxweb/schemas';
import { useStyles } from './MobileFooter.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TMobileFooterProps = {
  setModalEmail?: (arg: string) => void;
};

// eslint-disable-next-line complexity
export const MobileFooter = ({ setModalEmail }: TMobileFooterProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const footerPalette = useFooterPalette();
  const classes = useStyles({ footerPalette });
  const [isActive, setIsActive] = useState('');
  const isAuthed = useAuthBoolean();
  const [shipperPermission, haveShipperPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_SHIPPER_PAGE,
  ]);

  const [carrierPermission, haveCarrierPermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_CARRIER_PAGE,
  ]);

  const careersLink = (
    <a
      className={classes.siteMapLink}
      href="https://truxweb.breezy.hr/"
      style={{ textDecoration: 'none' }}
      target="carreers"
    >
      <Typography align="center" variant="body1">
        {t('common:carreers')}
      </Typography>
    </a>
  );

  const basicFooter = (
    <>
      {/* CARRIER */}
      <MobileDropDownMenuHeader
        hasChevron
        isItemOpen={isActive === t('common:carriersFooter')}
        pages={['login', 'signup/carrier']}
        setIsActive={setIsActive}
        text={t('common:carriersFooter')}
      ></MobileDropDownMenuHeader>

      {/* SHIPPER */}
      <MobileDropDownMenuHeader
        hasChevron
        isItemOpen={isActive === t('common:shippersFooter')}
        pages={['login', 'signup/shipper']}
        setIsActive={setIsActive}
        text={t('common:shippersFooter')}
      ></MobileDropDownMenuHeader>

      {/* ABOUT US */}
      <MobileDropDownMenuHeader
        additionalLinks={[careersLink]}
        hasChevron
        isItemOpen={isActive === t('common:aboutUsFooter')}
        pages={['contact-us']}
        setIsActive={setIsActive}
        text={t('common:aboutUsFooter')}
      ></MobileDropDownMenuHeader>
    </>
  );

  let siteMapContent = isAuthed ? null : basicFooter;

  if (isAuthed && carrierPermission && haveCarrierPermissionsEvaluated) {
    siteMapContent = (
      <>
        {/* SHIPMENTS */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:shipmentsFooter')}
          pages={['shipments']}
          setIsActive={setIsActive}
          text={t('common:shipmentsFooter')}
        ></MobileDropDownMenuHeader>

        {/* EQUIPMENT */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:equipmentFooter')}
          pages={['fleet/equipment', 'fleet/lanes', 'fleet/accessorials']}
          setIsActive={setIsActive}
          text={t('common:equipmentFooter')}
        ></MobileDropDownMenuHeader>

        {/* ACCOUNT */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:accountFooter')}
          pages={[
            'account/company',
            'account/payment',
            'account/address',
            'account/notifications',
            'account/profile',
          ]}
          setIsActive={setIsActive}
          text={t('common:accountFooter')}
        ></MobileDropDownMenuHeader>
      </>
    );
  } else if (isAuthed && shipperPermission && haveShipperPermissionsEvaluated) {
    siteMapContent = (
      <>
        {/* SEARCH  */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:searchFooter')}
          pages={['search', 'results']}
          setIsActive={setIsActive}
          text={t('common:searchFooter')}
        ></MobileDropDownMenuHeader>
        {/* SHIPMENTS */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:shipmentsFooter')}
          pages={['shipments']}
          setIsActive={setIsActive}
          text={t('common:shipmentsFooter')}
        ></MobileDropDownMenuHeader>

        {/* ACCOUNT */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:accountFooter')}
          pages={[
            'account',
            'account/company',
            'account/payment',
            'account/address',
            'account/notifications',
          ]}
          setIsActive={setIsActive}
          text={t('common:accountFooter')}
        ></MobileDropDownMenuHeader>

        {/* HELP */}
        <MobileDropDownMenuHeader
          hasChevron
          isItemOpen={isActive === t('common:help')}
          pages={['contact-us']}
          setIsActive={setIsActive}
          text={t('common:help')}
        ></MobileDropDownMenuHeader>
      </>
    );
  } else if (isAuthed && haveCarrierPermissionsEvaluated && haveShipperPermissionsEvaluated) {
    // IF a user is authenticated, but is neither a shipper or carrier
    // show them the logged out footer
    siteMapContent = basicFooter;
  }

  return (
    <>
      <Box mb={7}>
        <Grid alignItems="center" container direction="column">
          {siteMapContent}
        </Grid>
      </Box>
      <Grid item>
        <Box mb={3}>
          <Typography className={classes.subscribe} variant="body1">
            {t('common:subscribeToNewsletter')}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <ResponsiveEmailSignUp
          isLight={footerPalette === 'dark' || false}
          setModalEmail={setModalEmail}
        />
      </Grid>
      <Box className={classes.socialMedia} mb={4}>
        <Grid item>
          <SocialMediaIconLinks isDark={footerPalette === 'dark'} screenSize={'mobile'} />
        </Grid>
      </Box>
    </>
  );
};
