// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentStatusGroupV1 = void 0;
var ShipmentStatusGroupV1;
(function (ShipmentStatusGroupV1) {
    ShipmentStatusGroupV1["BOOKED"] = "booked";
    ShipmentStatusGroupV1["ACTIVE"] = "active";
    ShipmentStatusGroupV1["DELIVERED"] = "delivered";
    ShipmentStatusGroupV1["HISTORICAL"] = "historical";
    ShipmentStatusGroupV1["UPCOMING"] = "UPCOMING";
    ShipmentStatusGroupV1["IN_PROGRESS"] = "IN_PROGRESS";
    ShipmentStatusGroupV1["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    ShipmentStatusGroupV1["PAYMENT_APPROVED"] = "PAYMENT_APPROVED";
    ShipmentStatusGroupV1["COMPLETED"] = "COMPLETED";
})(ShipmentStatusGroupV1 || (exports.ShipmentStatusGroupV1 = ShipmentStatusGroupV1 = {}));
