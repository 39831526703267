"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessorialTableFilter = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("../../utils");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const AccessorialTableFilter = ({ accessorials, control, t, }) => {
    const accessorialCodes = (0, react_hook_form_1.useFieldArray)({ control, name: 'accessorial.code' });
    (0, react_1.useEffect)(() => {
        if (!accessorialCodes.fields.length) {
            accessorialCodes.append((0, utils_1.emptyListFilter)({ property: 'accessorial.code', propertyType: schemas_1.EPropertyTypeV1.STRING }));
        }
    }, [accessorialCodes]);
    return (react_1.default.createElement(react_1.default.Fragment, null, accessorialCodes.fields.map((field, index) => {
        return (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: field, key: field.id, name: `accessorial.code.${index}`, render: ({ field: { onChange, value } }) => {
                return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" }, Object.keys(accessorials).map((code) => {
                    return (react_1.default.createElement(ux_1.Grid, { item: true, key: code },
                        react_1.default.createElement(ux_1.Box, { mb: 2 },
                            react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: (0, utils_1.isListFilterOptionSelected)(value, code), color: "grey400", onChange: () => {
                                        onChange((0, utils_1.manageFilterListValue)(value, [code]));
                                    }, value: code }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t(`common:SHIPPER-accessorial-${code}`)) }))));
                })));
            } }));
    })));
};
exports.AccessorialTableFilter = AccessorialTableFilter;
