"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        background: theme.palette.primary.light,
        border: 0,
        borderRadius: theme.spacing(1),
        color: theme.palette.common.white,
        cursor: 'pointer',
        width: theme.spacing(6),
    },
    icon: {
        marginLeft: theme.spacing(1),
        marginTop: `${theme.spacing(0.5)}px !important`,
    },
    localClassName: {
        padding: `${theme.spacing(0.5)}px 0 0 0`,
    },
    wrapperClassName: {
        background: `#F3F3F3 !important`,
        borderRadius: theme.spacing(1),
        height: theme.spacing(5),
        lineHeight: `${theme.spacing(5)}px`,
        paddingLeft: theme.spacing(1),
        width: '100%',
    },
}));
