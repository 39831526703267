"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSmogIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,24H17a1,1,0,0,1,0-2h2A1,1,0,0,1,19,24Zm-6,0H1a1,1,0,0,1,0-2H13A1,1,0,0,1,13,24Zm10-4H11a1,1,0,0,1,0-2H23A1,1,0,0,1,23,20ZM7,20H5a1,1,0,0,1,0-2H7A1,1,0,0,1,7,20Zm12-4H17a1,1,0,0,1,0-2h2A1,1,0,0,1,19,16Zm-6,0H1a1,1,0,0,1,0-2H13A1,1,0,0,1,13,16Zm3-4a5,5,0,0,1-2.336-.579,6.271,6.271,0,0,1-5.536-.152.587.587,0,0,0-.571.028A5,5,0,0,1,.134,5.829,4.95,4.95,0,0,1,3.811,2.138a5.092,5.092,0,0,1,2.374,0,.277.277,0,0,0,.283-.074,6.033,6.033,0,0,1,8.8-.287c.065.065.217.219.734.219a4.978,4.978,0,0,1,3.9,1.875.4.4,0,0,0,.193.148,3.579,3.579,0,0,1,1.438.129,3.443,3.443,0,0,1,2.348,2.433h0a3.5,3.5,0,0,1-4.341,4.282c-.274-.079-.333-.032-.358-.011A4.932,4.932,0,0,1,16,12ZM13.614,9.409a2.108,2.108,0,0,1,.986.245,3.041,3.041,0,0,0,3.294-.327,2.266,2.266,0,0,1,2.2-.384,1.5,1.5,0,0,0,1.857-1.858h0a1.455,1.455,0,0,0-.978-1.014,1.6,1.6,0,0,0-.642-.06,2.231,2.231,0,0,1-1.984-.885c-1.04-1.6-3.361-.663-4.5-1.937a4.022,4.022,0,0,0-5.866.191,2.271,2.271,0,0,1-2.266.706,3.09,3.09,0,0,0-1.447,0A3,3,0,0,0,2.651,8.867a3.067,3.067,0,0,0,3.882.712,2.584,2.584,0,0,1,2.554-.065,4.06,4.06,0,0,0,3.638.1A2.055,2.055,0,0,1,13.614,9.409Z" })));
exports.default = TruxwebSmogIcon;
