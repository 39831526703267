import { Box } from '@truxweb/ux';
import React from 'react';
import { useStyles } from './LoginVerification.styles';
import VerificationInput from 'react-verification-input';

type LoginVerificationProps = {
  onChange: () => void;
  value: string;
};

export const LoginVerification = ({ onChange, value }: LoginVerificationProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box mb={3} mt={3}>
      <VerificationInput
        classNames={{
          character: classes.character,
          characterInactive: classes.characterInactive,
          characterSelected: classes.characterSelected,
          container: classes.container,
        }}
        onChange={onChange}
        placeholder={' '}
        validChars="0-9"
        value={value}
      />
    </Box>
  );
};
