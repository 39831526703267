// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteShipperRefusalReasonV1 = void 0;
var QuoteShipperRefusalReasonV1;
(function (QuoteShipperRefusalReasonV1) {
    QuoteShipperRefusalReasonV1["PRICE_TOO_HIGH"] = "PRICE_TOO_HIGH";
    QuoteShipperRefusalReasonV1["LOW_RATING"] = "LOW_RATING";
    QuoteShipperRefusalReasonV1["EXISTING_RELATIONSHIP"] = "EXISTING_RELATIONSHIP";
    QuoteShipperRefusalReasonV1["SLOW_RESPONSE"] = "SLOW_RESPONSE";
    QuoteShipperRefusalReasonV1["PICKUP_DATE"] = "PICKUP_DATE";
})(QuoteShipperRefusalReasonV1 || (exports.QuoteShipperRefusalReasonV1 = QuoteShipperRefusalReasonV1 = {}));
