"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    actions: {
        background: theme.palette.primary.light,
        borderRadius: '0 0 16px 16px',
        padding: theme.spacing(1),
    },
    attachmentBox: {
        background: '#EAEAEA',
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: '16px',
        padding: '8px',
    },
    closedChat: {
        background: theme.palette.primary.dark,
        borderRadius: theme.spacing(2),
    },
    commentBox: {
        '& img': {
            display: 'block',
            maxWidth: '250px',
            paddingBottom: theme.spacing(1.5),
            paddingTop: theme.spacing(1.5),
        },
        '&:focus': {
            outline: 'none',
        },
        height: 'auto',
        minHeight: theme.spacing(8),
        padding: theme.spacing(2.5),
        whiteSpace: 'pre-line',
        width: '100%',
        wordBreak: 'break-word',
    },
    commentBoxContainer: {
        backgroundColor: '#EAEAEA',
        borderRadius: `${theme.spacing(3.75)}px`,
        height: 'auto',
        minHeight: theme.spacing(4.5),
    },
    commentButton: {
        '&:disabled ': {
            background: 'rgb(220, 220, 220)',
            fill: '#C4C4C4 !important',
        },
        background: `#fff`,
        border: 0,
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
    },
    commentTime: {
        color: '#C4C4C4',
    },
    container: {
        height: '100%',
    },
    header: {
        color: theme.palette.primary.dark,
    },
    icon: {
        '&:hover': {
            fill: theme.palette.primary.dark,
        },
        fill: theme.palette.primary.light,
    },
    iconDisabled: {
        fill: '#636363',
    },
    input: {
        height: '36px',
        lineHeight: '36px',
        padding: '0',
    },
    publish: {
        color: theme.palette.primary.main,
    },
    scroll: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    scrollContainer: {
        height: 'calc(100% - 180px)',
        minHeight: theme.spacing(32),
        overflow: 'hidden',
    },
}));
