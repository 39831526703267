// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationMethodV1 = void 0;
var NotificationMethodV1;
(function (NotificationMethodV1) {
    NotificationMethodV1["IN_APP"] = "IN_APP";
    NotificationMethodV1["EMAIL"] = "EMAIL";
    NotificationMethodV1["SMS"] = "SMS";
})(NotificationMethodV1 || (exports.NotificationMethodV1 = NotificationMethodV1 = {}));
