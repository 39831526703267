"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    addressContainer: {
        flexGrow: 4,
    },
    addressLinkedImage: {
        paddingTop: '8px',
    },
    datebox: {
        width: '220px',
    },
    datepicker: {
        width: '60%',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        paddingLeft: '20px',
        paddingRight: '0px',
    },
    flexContainer: {
        minWidth: '225px',
        padding: '0px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        padding: '0px',
    },
    generalContainer: {
        padding: '16px 0px',
    },
    icon: {
        color: theme.palette.grey[400],
        fill: theme.palette.grey[400],
        fontSize: 28,
    },
    itemBox: {
        background: theme.palette.common.white,
        borderRadius: '8px',
        color: theme.palette.grey[600],
        height: '40px',
    },
    paddingBottom: {
        paddingBottom: '24px',
    },
    selectedIcon: {
        color: theme.palette.primary.light,
        fill: theme.palette.primary.light,
        fontSize: 28,
    },
    title: {
        color: theme.palette.primary.light,
    },
}));
