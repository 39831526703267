"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentOrderSummaryRow = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const react_hook_form_1 = require("react-hook-form");
const ShipmentOrderSummaryRow = (request) => {
    const { advancedFieldTypeSelection, canRemove, canReset, currencyValue, currencyValueName, details, field, fieldManagement, fieldType, formControls, handleReset, hasDescription, hasRecalculated, index, isCurrencyDisabled, isPercentDisabled, language, manageConsumedSelections, name, percentValue, percentValueName, renderFieldTypeSelection, renderPriceValue, shouldAllowNegativeValues, shouldDisallowSavingCharges, t, title, valueType, } = request;
    const handleRemove = (0, react_1.useCallback)(() => {
        if (index !== undefined && fieldManagement) {
            fieldManagement.remove(index);
            if (manageConsumedSelections)
                manageConsumedSelections(field);
        }
    }, [index, fieldManagement, manageConsumedSelections, field]);
    if (!field.isEditable) {
        return (react_1.default.createElement(__1.ShipmentOrderSummaryPriceLine, { details: details, language: language, price: field.charge, renderPriceValue: renderPriceValue, title: title }));
    }
    const removeButton = (react_1.default.createElement(ux_1.StandardButton, { onClick: handleRemove },
        react_1.default.createElement(ux_1.RemoveCircleOutlineIcon, null)));
    const resetButton = canReset ? (react_1.default.createElement(ux_1.Grid, { item: true },
        react_1.default.createElement(ux_1.StandardButton, { onClick: () => {
                if (handleReset) {
                    handleReset(field, formControls, fieldType, index);
                }
            } },
            react_1.default.createElement(ux_1.TruxwebRotateRightIcon, null)))) : (react_1.default.createElement(react_1.default.Fragment, null));
    let valueDisplay = (react_1.default.createElement(react_1.default.Fragment, null,
        resetButton,
        react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
            react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: field.charge.value, name: name, render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(__1.ShipmentOrderSummaryCurrencyInput, { canRemove: canRemove, hasRecalculated: hasRecalculated, onChange: onChange, prefix: '$', shouldAllowNegativeValues: shouldAllowNegativeValues, shouldDisallowSavingCharges: shouldDisallowSavingCharges, suffix: '', value: value }));
                } })),
        canRemove && removeButton));
    let direction = 'row';
    let alignItems = 'center';
    let rowHeight = 36;
    if (valueType === schemas_1.EPriceTypeV1.PERCENT) {
        if (percentValueName === undefined ||
            percentValue === undefined ||
            currencyValueName === undefined ||
            currencyValue === undefined) {
            // eslint-disable-next-line no-console
            console.warn(`Unable to dispay Editable Percent: percentValueName  = ${Boolean(percentValueName)}; percentValue  = ${Boolean(percentValue)};  currencyValueName = ${Boolean(currencyValueName)};   currencyValueName = ${Boolean(currencyValue)}`);
            return null;
        }
        rowHeight = 72;
        alignItems = 'flex-start';
        direction = 'column';
        valueDisplay = (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
            react_1.default.createElement(__1.ShipmentOrderSummaryPercentValue, { currencyValue: currencyValue, currencyValueName: currencyValueName, formControls: formControls, hasRecalculated: hasRecalculated, isCurrencyDisabled: isCurrencyDisabled, isPercentDisabled: isPercentDisabled, percentValue: percentValue, percentValueName: percentValueName }),
            canRemove && react_1.default.createElement(ux_1.Grid, { item: true }, removeButton)));
    }
    let valueRow = (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
            react_1.default.createElement(__1.ShipmentOrderSummaryPriceLineTitle, { title: title })),
        field.isEditable && valueDisplay));
    let heightProp = 'height';
    if (field.isNewCharge && !advancedFieldTypeSelection) {
        valueRow = (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: field.charge.value, name: `${fieldType}.${index}.chargeReference`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        if (!renderFieldTypeSelection)
                            return react_1.default.createElement(__1.ShipmentOrderSummaryPriceLineTitle, { title: title });
                        return renderFieldTypeSelection({
                            error,
                            fieldType,
                            index,
                            onChange,
                            value,
                        });
                    } })),
            valueDisplay));
    }
    else if (field.isNewCharge && advancedFieldTypeSelection) {
        heightProp = 'minHeight';
        valueRow = advancedFieldTypeSelection({
            field,
            fieldManagement,
            fieldType,
            index,
            valueDisplay,
        });
    }
    return (react_1.default.createElement(ux_1.Grid, { item: true, style: { [heightProp]: `${hasDescription ? rowHeight + 168 : rowHeight}px` } },
        react_1.default.createElement(ux_1.Box, { mb: 0.5 },
            react_1.default.createElement(ux_1.Grid, { alignItems: alignItems, container: true, direction: direction, justifyContent: "space-between" }, valueRow),
            hasDescription && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: field.chargeComment, name: `${fieldType}.${index}.chargeComment`, render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(ux_1.GreyTextArea, { fullWidth: true, onChange: onChange, placeholder: t('common:chargeDescription'), type: 'text', value: value, variant: 'outlined' }));
                    } }))))));
};
exports.ShipmentOrderSummaryRow = ShipmentOrderSummaryRow;
