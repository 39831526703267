// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardInvoiceTransactionStatusV1 = void 0;
var StandardInvoiceTransactionStatusV1;
(function (StandardInvoiceTransactionStatusV1) {
    StandardInvoiceTransactionStatusV1["HOLD"] = "Hold";
    StandardInvoiceTransactionStatusV1["ISSUED"] = "Issued";
    StandardInvoiceTransactionStatusV1["PAID"] = "Paid";
    StandardInvoiceTransactionStatusV1["VOID"] = "Void";
    StandardInvoiceTransactionStatusV1["CANCELLED"] = "Cancelled";
})(StandardInvoiceTransactionStatusV1 || (exports.StandardInvoiceTransactionStatusV1 = StandardInvoiceTransactionStatusV1 = {}));
