import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TShipmentCommentV1 } from '@truxweb/schemas';

type TShipmentComments = Record<string, TShipmentCommentV1[]>;
export interface IShipmentCommentDataState {
  comments: TShipmentComments;
}

export const shipmentCommentSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.shipmentComments,
      };
    },
  },
  initialState: {
    comments: {},
  } as IShipmentCommentDataState,
  name: 'shipmentComments',
  reducers: {
    addCommentToShipment: (state, { payload: { _tempId, comment, shipmentRef } }) => {
      const shipmentComments = (state.comments[shipmentRef] || []).slice();
      shipmentComments.push(comment);
      const filtered = shipmentComments.filter((comment) => {
        return comment._tempId !== _tempId;
      });
      return {
        ...state,
        comments: {
          ...state.comments,
          [shipmentRef]: filtered,
        },
      };
    },
    addTemporaryCommentToShipment: (
      state,
      {
        payload: {
          _tempId,
          attachments,
          comment,
          companyName,
          shipmentRef,
          userExtId,
          userName,
          userType,
        },
      }
    ) => {
      const tempComment: TShipmentCommentV1 = {
        _tempId,
        attachments,
        comment,
        companyName,
        dateCreated: new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        id: -1,
        isVisibleExternally: true,
        shipmentId: 0,
        userExtId,
        userName,
        userType,
      };

      const shipmentComments = (state.comments[shipmentRef] || []).slice();
      shipmentComments.push(tempComment);
      return {
        ...state,
        comments: {
          ...state.comments,
          [shipmentRef]: shipmentComments,
        },
      };
    },
    setShipmentComments: (state, { payload: { comments, shipmentRef } }) => {
      return {
        ...state,

        comments: {
          ...state.comments,
          [shipmentRef]: comments,
        },
      };
    },
  },
});

export const {
  actions: {
    addCommentToShipment,

    addTemporaryCommentToShipment,
    setShipmentComments,
  },
} = shipmentCommentSlice;
