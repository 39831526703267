import {
  convertFormDataToCarrierAccessorialV1,
  mapAccessorialDataForManagement,
  objectArrayDiff,
} from '@truxweb/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAlerts,
  useCarrierAccessorialData,
  useCarrierData,
  useErrorHandling,
  useReduxAccessorialData,
} from '../../hooks';
import { CarrierAccessorialsManagementForm as BaseCarrierAccessorialsManagementForm } from '@truxweb/common-components';
import { TCarrierAccessorialMapV1 } from '@truxweb/schemas';
import { updateAccessorials } from '../../actions';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'account'];

type TCarrierAccessorialsManagementFormProps = {
  arePricesRequired: boolean;
};

type TFormValues = {
  accessorial: TCarrierAccessorialMapV1[];
};

export const CarrierAccessorialsManagementForm = ({
  arePricesRequired,
}: TCarrierAccessorialsManagementFormProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [shouldCarrierAccessorialsRefetch, setCarrierAccessorialsRefecth] = useState(false);
  const { data: accessorials, hasLoaded: hasAccessorialsFetched } = useReduxAccessorialData();
  const [carrierAccessorials, hasCarrierAccessorialsFetched] = useCarrierAccessorialData(
    shouldCarrierAccessorialsRefetch
  );
  const [isSaving, setIsSaving] = useState(false);

  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();

  const [carrierData, hasCarrierDataFetched] = useCarrierData();

  const accessorialData: TCarrierAccessorialMapV1[] = useMemo(() => {
    if (
      !hasAccessorialsFetched ||
      !hasCarrierAccessorialsFetched ||
      !carrierAccessorials ||
      !hasCarrierDataFetched
    )
      return [];

    return mapAccessorialDataForManagement(accessorials, carrierAccessorials);
  }, [
    accessorials,
    carrierAccessorials,
    hasCarrierAccessorialsFetched,
    hasAccessorialsFetched,
    hasCarrierDataFetched,
  ]);

  useEffect(() => {
    if (shouldCarrierAccessorialsRefetch) {
      setCarrierAccessorialsRefecth(false);
    }
  }, [shouldCarrierAccessorialsRefetch, setCarrierAccessorialsRefecth]);

  const handleSubmitSuccess = useCallback(
    async (data: TFormValues) => {
      try {
        setIsSaving(true);

        await updateAccessorials(
          convertFormDataToCarrierAccessorialV1(objectArrayDiff(data.accessorial, accessorialData))
        );
        addAlert({
          message: t('common:updateSuccess'),
          severity: 'success',
        });
        setCarrierAccessorialsRefecth(true);
      } catch (err) {
        errorHandler(err, t('common:updateFailed'));
      } finally {
        setIsSaving(false);
      }
    },
    [setIsSaving, addAlert, t, accessorialData, setCarrierAccessorialsRefecth, errorHandler]
  );

  return (
    <BaseCarrierAccessorialsManagementForm
      accessorialData={accessorialData}
      arePricesRequired={arePricesRequired}
      carrier={carrierData}
      handleSubmitSuccess={handleSubmitSuccess}
      isLoading={!hasCarrierAccessorialsFetched}
      isSaving={isSaving}
      t={t}
    />
  );
};
