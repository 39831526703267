"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSoapIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m17 7.964v-.964a3 3 0 0 0 -2-2.816v-2.184h4a1 1 0 0 0 0-2h-7a3 3 0 0 0 -3 3v1.184a3 3 0 0 0 -2 2.816v.964a8.943 8.943 0 0 0 -5 8.036v2a6.006 6.006 0 0 0 6 6h8a6.006 6.006 0 0 0 6-6v-2a8.943 8.943 0 0 0 -5-8.036zm-6-4.964a1 1 0 0 1 1-1h1v2h-2zm-2 4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h-6zm11 11a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4-4v-2a6.968 6.968 0 0 1 3.394-6h9.212a6.968 6.968 0 0 1 3.394 6zm-5-4h-6a3 3 0 0 0 0 6h6a3 3 0 0 0 0-6zm0 4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z" })));
exports.default = TruxwebSoapIcon;
