import { useDispatch, useSelector } from 'react-redux';
import { fetchReduxCarrierQuoteRequestById } from '../../../actions';
import { selectCarrierQuoteById } from '../../../selectors';
import { type TCarrierQuoteRequest } from '../../../stores';
import { useEffect } from 'react';

export const useReduxCarrierQuoteById = (
  quoteId?: number,
  shouldRefetch?: boolean
): TCarrierQuoteRequest => {
  const dispatch = useDispatch();
  const quoteData = useSelector(selectCarrierQuoteById(quoteId));

  useEffect(() => {
    if (
      ((!quoteData.hasLoaded && !quoteData.isLoading) || (!quoteData.isLoading && shouldRefetch)) &&
      quoteId
    ) {
      dispatch(fetchReduxCarrierQuoteRequestById(quoteId));
    }
  }, [quoteData, dispatch, shouldRefetch, quoteId]);

  return quoteData;
};
