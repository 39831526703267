"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRocketIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18,7a1,1,0,1,1-1-1A1,1,0,0,1,18,7ZM14,9a1,1,0,1,0,1,1A1,1,0,0,0,14,9Zm-3,3a1,1,0,1,0,1,1A1,1,0,0,0,11,12ZM23.866,2.958l-.075.211C22.289,7.337,20.848,11.3,18,14.82V15a11.02,11.02,0,0,1-3.6,8.135,3.264,3.264,0,0,1-2.2.865A3.21,3.21,0,0,1,9,20.794V19.643a4.951,4.951,0,0,1-1.961-1.191L7,18.414,4.707,20.707a1,1,0,0,1-1.414-1.414L5.586,17l-.039-.038A4.98,4.98,0,0,1,4.353,15h-.99A3.366,3.366,0,0,1,0,11.638,3.4,3.4,0,0,1,.938,9.3,10.922,10.922,0,0,1,9,6h.18C12.7,3.153,16.66,1.712,20.826.211l.216-.077a2.2,2.2,0,0,1,2.824,2.824ZM3.363,13H4.1a5,5,0,0,1,.891-2.45A25.362,25.362,0,0,1,6.8,8.244a8.507,8.507,0,0,0-4.4,2.426A1.407,1.407,0,0,0,2,11.638,1.364,1.364,0,0,0,3.363,13ZM15.7,17.252a25.552,25.552,0,0,1-2.252,1.759A4.983,4.983,0,0,1,11,19.887v.907A1.208,1.208,0,0,0,12.207,22a1.271,1.271,0,0,0,.852-.345A9,9,0,0,0,15.7,17.252ZM21.937,2.063a.191.191,0,0,0-.217-.048l-.215.078C16.661,3.838,12.478,5.346,8.876,8.947A20.867,20.867,0,0,0,6.628,11.7a3,3,0,0,0,.333,3.851l1.492,1.49a3,3,0,0,0,3.849.334,20.817,20.817,0,0,0,2.751-2.248c3.6-3.6,5.11-7.787,6.856-12.633l.076-.211A.192.192,0,0,0,21.937,2.063Z" })));
exports.default = TruxwebRocketIcon;
