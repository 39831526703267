// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequiredOptionalV1 = void 0;
var RequiredOptionalV1;
(function (RequiredOptionalV1) {
    RequiredOptionalV1["REQUIRED"] = "REQUIRED";
    RequiredOptionalV1["OPTIONAL"] = "OPTIONAL";
})(RequiredOptionalV1 || (exports.RequiredOptionalV1 = RequiredOptionalV1 = {}));
