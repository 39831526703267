"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    activeIcon: {
        background: 'rgba(34, 72, 130, 0.8)',
        borderRadius: '6.5px',
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
    container: {
        backgroundColor: theme.palette.primary.dark,
        height: '100%',
        maxWidth: '80px',
        minHeight: '100vh',
        minWidth: '80px',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(5),
        position: 'fixed',
        width: '80px',
    },
    fixedMenu: {
        maxWidth: theme.spacing(10),
    },
    icon: {
        '&:hover,&:active': {
            background: 'rgba(34, 72, 130, 0.8)',
            borderRadius: '6.5px',
        },
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
    iconColor: {
        color: theme.palette.common.white,
        position: 'relative',
    },
    iconMargin: {
        marginTop: theme.spacing(5),
    },
    logoIconWrapper: {
        height: theme.spacing(54),
    },
    maintenanceIconsWrapper: {
        bottom: 0,
        height: theme.spacing(32),
        maxWidth: theme.spacing(10),
    },
    notificationDot: {
        background: theme.palette.primary.light,
        borderRadius: '100%',
        height: theme.spacing(1),
        position: 'absolute',
        right: theme.spacing(1.75),
        top: 0,
        width: theme.spacing(1),
    },
    profile: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
    },
}));
