"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGemIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.147,9.471,22.36,8.4A5.885,5.885,0,0,0,17.632,6H6.383A5.892,5.892,0,0,0,1.721,8.309L.9,9.383a4.418,4.418,0,0,0,.548,5.937l7.99,7.707a3.82,3.82,0,0,0,2.55.969,3.9,3.9,0,0,0,2.6-1l7.936-7.633A4.39,4.39,0,0,0,23.147,9.471Zm-2.4.107.787,1.074a2.388,2.388,0,0,1,.208.348H16.951a6.616,6.616,0,0,0-.372-1.4L15.939,8h1.693A3.878,3.878,0,0,1,20.747,9.578ZM11.99,20.088l-2.769-6.27A4.642,4.642,0,0,1,8.995,13h5.952a4.354,4.354,0,0,1-.176.647ZM9.1,11a4.652,4.652,0,0,1,.157-.529L10.241,8h3.544l.937,2.343a4.8,4.8,0,0,1,.2.657ZM3.311,9.521A3.884,3.884,0,0,1,6.383,8h1.7L7.4,9.729A6.57,6.57,0,0,0,7.033,11H2.245a2.322,2.322,0,0,1,.247-.4Zm-.5,4.333a2.374,2.374,0,0,1-.6-.854H6.983a6.874,6.874,0,0,0,.387,1.576l2.827,6.4Zm10.96,7.16L16.625,14.4a6.573,6.573,0,0,0,.344-1.4H21.8a2.5,2.5,0,0,1-.633.9Z" }),
    React.createElement("path", { d: "M12,4a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V3A1,1,0,0,0,12,4Z" }),
    React.createElement("path", { d: "M16.553,3.9A1,1,0,0,0,17.9,3.447l1-2a1,1,0,1,0-1.79-.894l-1,2A1,1,0,0,0,16.553,3.9Z" }),
    React.createElement("path", { d: "M6.105,3.447A1,1,0,0,0,7.9,2.553l-1-2a1,1,0,1,0-1.79.894Z" })));
exports.default = TruxwebGemIcon;
