import { makeStyles, Theme } from '@truxweb/ux';

type TResponsiveHeaderStylesProps = {
  palette: 'light' | 'dark';
  isTransparent: boolean;
  isMobile: boolean;
  isScrolled?: boolean;
  isMobileMenuOpen: boolean;
};

export const useStyles = makeStyles<Theme, TResponsiveHeaderStylesProps>((theme: Theme) => ({
  gridContainer: {
    backgroundColor: ({ isScrolled, isTransparent, palette }) => {
      return isTransparent && !isScrolled
        ? 'transparent'
        : palette === 'dark'
        ? theme.palette.primary.dark
        : theme.palette.common.white;
    },
    maxWidth: 1920,
    padding: '0 4% 0 4%',
  },
  hamburger: {
    color: ({ isScrolled, isTransparent, palette }) => {
      return [isTransparent && !isScrolled].includes(true) || palette === 'dark'
        ? theme.palette.common.white
        : theme.palette.grey[600];
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 360,
    position: 'fixed',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(9),
    },
    [theme.breakpoints.between('xs', 'md')]: {
      height: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(13.5),
    },
    width: '100%',
    zIndex: 2,
  },
  languageSwitcher: {
    '&:hover,&:active': { color: theme.palette.primary.main },
    color: ({ isScrolled, isTransparent, palette }) => {
      return [isScrolled && palette === 'dark', isTransparent && !isScrolled].includes(true)
        ? theme.palette.common.white
        : theme.palette.grey[600];
    },
    fontSize: '18px',
    fontWeight: 400,
  },
  link: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
    },
    color: ({ isScrolled, isTransparent, palette }) => {
      return isTransparent && !isScrolled
        ? palette === 'dark'
          ? theme.palette.common.white
          : theme.palette.primary.main
        : palette === 'dark'
        ? theme.palette.common.white
        : theme.palette.grey[600];
    },
    position: 'relative',
  },
  linkButton: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
    },
    color: ({ isScrolled, isTransparent, palette }) => {
      return isTransparent && !isScrolled
        ? palette === 'dark'
          ? theme.palette.common.white
          : theme.palette.primary.main
        : palette === 'dark'
        ? theme.palette.common.white
        : theme.palette.grey[600];
    },
    height: theme.spacing(4.5),
    position: 'relative',
    widtht: theme.spacing(4),
  },
  logoWrapper: {
    '&:hover': { cursor: 'pointer' },
    maxHeight: theme.spacing(7),
    maxWidth: theme.spacing(30),
    minHeight: theme.spacing(4),
    minWidth: theme.spacing(17),
    width: '25vw',
  },
  root: {
    background: 'transparent',
    height: 'auto',
  },
}));
