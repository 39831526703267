"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    rating: {
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(4),
        width: theme.spacing(43),
    },
    ratingContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
}));
