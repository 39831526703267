// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAcctReturnTypeV1 = exports.BankAcctTransferTypeV1 = exports.BankAcctTransactionTypeV1 = exports.CreditCardTransactionTypeV1 = exports.PaymentTransactionStatusV1 = exports.AvsV1 = exports.StandardInvoiceTransactionStatusV1 = exports.PaymentMethodV1 = void 0;
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
var StandardInvoiceTransactionStatusV1;
(function (StandardInvoiceTransactionStatusV1) {
    StandardInvoiceTransactionStatusV1["HOLD"] = "Hold";
    StandardInvoiceTransactionStatusV1["ISSUED"] = "Issued";
    StandardInvoiceTransactionStatusV1["PAID"] = "Paid";
    StandardInvoiceTransactionStatusV1["VOID"] = "Void";
    StandardInvoiceTransactionStatusV1["CANCELLED"] = "Cancelled";
})(StandardInvoiceTransactionStatusV1 || (exports.StandardInvoiceTransactionStatusV1 = StandardInvoiceTransactionStatusV1 = {}));
var AvsV1;
(function (AvsV1) {
    AvsV1["Match"] = "Match";
    AvsV1["AddressOnly"] = "AddressOnly";
    AvsV1["ZipOnly"] = "ZipOnly";
    AvsV1["NoMatch"] = "NoMatch";
    AvsV1["NA"] = "NA";
})(AvsV1 || (exports.AvsV1 = AvsV1 = {}));
var PaymentTransactionStatusV1;
(function (PaymentTransactionStatusV1) {
    PaymentTransactionStatusV1["Failed"] = "Failed";
    PaymentTransactionStatusV1["Success"] = "Success";
    PaymentTransactionStatusV1["Pending"] = "Pending";
    PaymentTransactionStatusV1["Hold"] = "Hold";
    PaymentTransactionStatusV1["Cancelled"] = "Cancelled";
})(PaymentTransactionStatusV1 || (exports.PaymentTransactionStatusV1 = PaymentTransactionStatusV1 = {}));
var CreditCardTransactionTypeV1;
(function (CreditCardTransactionTypeV1) {
    CreditCardTransactionTypeV1["Capture"] = "Capture";
    CreditCardTransactionTypeV1["PreAuth"] = "PreAuth";
    CreditCardTransactionTypeV1["Refund"] = "Refund";
    CreditCardTransactionTypeV1["Void"] = "Void";
})(CreditCardTransactionTypeV1 || (exports.CreditCardTransactionTypeV1 = CreditCardTransactionTypeV1 = {}));
var BankAcctTransactionTypeV1;
(function (BankAcctTransactionTypeV1) {
    BankAcctTransactionTypeV1["ACH"] = "ACH";
    BankAcctTransactionTypeV1["EFT"] = "EFT";
})(BankAcctTransactionTypeV1 || (exports.BankAcctTransactionTypeV1 = BankAcctTransactionTypeV1 = {}));
var BankAcctTransferTypeV1;
(function (BankAcctTransferTypeV1) {
    BankAcctTransferTypeV1["Credit"] = "Credit";
    BankAcctTransferTypeV1["Debit"] = "Debit";
})(BankAcctTransferTypeV1 || (exports.BankAcctTransferTypeV1 = BankAcctTransferTypeV1 = {}));
var BankAcctReturnTypeV1;
(function (BankAcctReturnTypeV1) {
    BankAcctReturnTypeV1["Rejected"] = "Rejected";
    BankAcctReturnTypeV1["Returned"] = "Returned";
})(BankAcctReturnTypeV1 || (exports.BankAcctReturnTypeV1 = BankAcctReturnTypeV1 = {}));
