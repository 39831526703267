"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFogIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18,24H17a1,1,0,0,1,0-2h1A1,1,0,0,1,18,24Zm-5,0H6a1,1,0,0,1,0-2h7A1,1,0,0,1,13,24Zm5-4H11a1,1,0,0,1,0-2h7A1,1,0,0,1,18,20ZM7,20H6a1,1,0,0,1,0-2H7A1,1,0,0,1,7,20ZM2.67,19a.994.994,0,0,1-.628-.223A5.533,5.533,0,0,1,1.8,10.43a1,1,0,0,0,.345-.9A8.134,8.134,0,0,1,2.113,6.64,7.945,7.945,0,0,1,8.5.138a8.049,8.049,0,0,1,8.735,4.438,1.039,1.039,0,0,0,.743.57c5.824,1.034,8.092,8.769,3.718,12.765a1,1,0,1,1-1.384-1.443,5.538,5.538,0,0,0-2.725-9.362,3.009,3.009,0,0,1-2.158-1.672A6,6,0,0,0,4.086,6.967a6.159,6.159,0,0,0,.023,2.18,3,3,0,0,1-.963,2.762A3.523,3.523,0,0,0,3.3,17.223,1,1,0,0,1,2.67,19ZM18,16H17a1,1,0,0,1,0-2h1A1,1,0,0,1,18,16Zm-5,0H6a1,1,0,0,1,0-2h7A1,1,0,0,1,13,16Z" })));
exports.default = TruxwebFogIcon;
