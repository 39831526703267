"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordFormField = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const styles_1 = require("../../styles");
const PasswordFormField_styles_1 = require("./PasswordFormField.styles");
const PasswordFormField = ({ additionalPasswordRequirements, error, isDisabled, isValidationHidden, isVisiblePasswordToggleHidden, label, onChange, passwordSchemaViolations, t, testId, value, }) => {
    const iconClasses = (0, styles_1.useFormIconStyles)();
    const inputClasses = (0, PasswordFormField_styles_1.useInputStyles)();
    const displayPaswordSchema = [
        'min',
        'uppercase',
        'lowercase',
        'digits',
        ...(additionalPasswordRequirements || []),
    ];
    const [passwordFieldType, setPasswordFieldType] = (0, react_1.useState)('password');
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                    shrink: true,
                }, InputProps: {
                    classes: {
                        root: inputClasses.root,
                    },
                    endAdornment: !isVisiblePasswordToggleHidden && (react_1.default.createElement(ux_1.InputAdornment, { position: "end" },
                        react_1.default.createElement(ux_1.IconButton, { onClick: () => {
                                setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password');
                            } }, passwordFieldType === 'password' ? (react_1.default.createElement(ux_1.VisibilityOutlinedIcon, { className: iconClasses.completeFieldIcon })) : (react_1.default.createElement(ux_1.VisibilityOffOutlinedIcon, { className: iconClasses.completeFieldIcon }))))),
                }, disabled: isDisabled, error: Boolean(error), fullWidth: true, id: "password", inputProps: { 'data-testid': testId, required: false }, label: label, onChange: onChange, required: true, type: passwordFieldType, value: value, variant: "outlined" })),
        !isValidationHidden && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { ml: 1, mt: 1 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" }, displayPaswordSchema.map((schemaParam) => {
                    const isInvalid = passwordSchemaViolations.includes(schemaParam);
                    const isValid = paramValidation(schemaParam, value);
                    let color = isInvalid ? 'red' : '#C4C4C4';
                    if (isValid) {
                        color = '#224882';
                    }
                    return (react_1.default.createElement(ux_1.Grid, { item: true, key: schemaParam, xs: 6 },
                        react_1.default.createElement(ux_1.Typography, { style: {
                                color,
                                fontSize: '12px',
                            } },
                            react_1.default.createElement("li", null, t(`common:passwordSchema-${schemaParam}`)))));
                })))))));
};
exports.PasswordFormField = PasswordFormField;
// FIXME this is such a damn hack it pisses me off, but `password-validator` only return
// errors not which params have been met.
const paramValidation = (paramName, value) => {
    switch (paramName) {
        case 'min':
            return value.length >= 8;
        case 'uppercase':
            return /[A-Z]/.test(value);
        case 'lowercase':
            return /[a-z]/.test(value);
        case 'digits':
            return /[0-9]/.test(value);
        case 'symbol':
            return /[!@#$%^&*]/.test(value);
        default:
            return false;
    }
};
