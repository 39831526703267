import { makeRequestToApiGateway } from '../../apiUtils';
import { TUserPaymentTermsRequestV1 } from '@truxweb/schemas';

export const getCompanyPaymentTermsRequest = async (): Promise<TUserPaymentTermsRequestV1> => {
  const result = await makeRequestToApiGateway('userGetV1CompanyShipperTerms', null, {
    'Content-Type': 'application/json',
  });

  return result as TUserPaymentTermsRequestV1;
};
