"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCheckboxFilledIcon = (props) => (React.createElement("svg", Object.assign({ fill: "evenodd", height: 24, viewBox: "0 0 20 20", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15.418 20H4.58203C2.05469 19.9961 0.00390625 17.9453 0 15.418V4.58203C0.00390625 2.05469 2.05469 0.00390625 4.58203 0H15.4141C17.9453 0.00390625 19.9961 2.05469 20 4.58203V15.4141C19.9961 17.9453 17.9453 19.9961 15.418 20Z" }),
    React.createElement("path", { d: "M8.05469 14.8242C7.52734 14.8242 7.02344 14.6133 6.65625 14.2422L4.16797 11.7578C3.67969 11.2695 3.67969 10.4766 4.16797 9.98828C4.65625 9.5 5.44922 9.5 5.93359 9.98828L8.05469 12.1094L14.0664 6.09766C14.5547 5.60938 15.3477 5.60938 15.832 6.09766C16.3203 6.58594 16.3203 7.37891 15.832 7.86719L9.45313 14.2422C9.08594 14.6172 8.58203 14.8242 8.05469 14.8242Z", fill: "white" })));
exports.default = TruxwebCheckboxFilledIcon;
