/* eslint-disable complexity */
import { Box, Button, Divider, Grid, Typography } from '@truxweb/ux';
import {
  CarrierProfileAccessorials,
  CarrierProfileAdditionalCosts,
  CarrierProfileCoverage,
  CarrierProfileFeatures,
} from '@truxweb/common-components';
import { EPageAlertStatusV1, TCarrierProfileDisplayV1 } from '@truxweb/schemas';
import { Loading, PageAlertCard } from '..';
import React, { useEffect, useState } from 'react';
import { CARRIER_PROFILE_BUCKET } from '../../config';
import { getPublicS3BucketUrl } from '../../apiUtils';
import Image from 'next/image';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useRouter } from 'next/router';
import { useStyles } from './CarrierProfile.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'companyInfo', 'profile', 'search', 'shipments'];
type TCarrierProfilePageProps = {
  companyCode: string;
  isInternalProfile?: boolean;
  carrierData: TCarrierProfileDisplayV1;
  hasResponseLoaded: boolean;
};
export const CarrierProfile = ({
  carrierData,
  companyCode,
  hasResponseLoaded,
  isInternalProfile,
}: TCarrierProfilePageProps): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { locale } = router;
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [carrier, setCarrier] = useState(null);
  const [externalLogoLink, setExternalLogoLink] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (carrierData !== null) {
      // Find any active profiles, if one exists
      const activeProfile = carrierData.carrierProfileRecord.filter((profile) => profile.isActive);

      // Handle case of a carrier viewing their own profile
      // This should allow them to see their own inactive profile and changes made to it
      if (isInternalProfile && !activeProfile[0] && carrierData.carrierProfileRecord.length > 0) {
        setCarrier(carrierData.carrierProfileRecord[0]);
        setExternalLogoLink(
          getPublicS3BucketUrl(CARRIER_PROFILE_BUCKET, carrierData.carrierProfileRecord[0].logoLink)
        );
        setIsPending(true);
      } else if (
        !isInternalProfile &&
        activeProfile[0] &&
        carrierData.carrierProfileRecord.length > 0
      ) {
        // Handle case of externally facing profiles that have been edited and approved
        setCarrier(activeProfile[0]);
        setExternalLogoLink(
          getPublicS3BucketUrl(CARRIER_PROFILE_BUCKET, activeProfile[0].logoLink)
        );
        setIsPending(false);
      }
      // Set items to display by default
      setIsLoading(false);
    }

    // Carrier does not exist
    if (hasResponseLoaded && !carrierData) {
      router.push('/');
    }
  }, [carrier, companyCode, locale, router, hasResponseLoaded, carrierData, isInternalProfile]);

  return (
    <>
      <Loading isLoading={!hasResponseLoaded} />
      {!isLoading && (
        <Grid container direction="column">
          {/* PENDING PROFILE */}
          {isPending && (
            <PageAlertCard status={EPageAlertStatusV1.Pending}>
              {t(`companyInfo:pendingProfileAlert`)}
            </PageAlertCard>
          )}

          {/* CARRIER DESCRIPTION */}
          <Box mb={2.5}>
            <Grid alignItems="center" container>
              <Grid item>
                {carrier && (
                  <Box className={classes.image} mr={3}>
                    <Image layout="fill" objectFit="contain" src={externalLogoLink} />
                  </Box>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h4">{carrierData.company.name}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2.5}>
            {carrier && <Typography variant="h6">{carrier.profileTagline}</Typography>}
            <Grid container justifyContent="space-between">
              <Grid item>
                {carrier && (
                  <Grid container>
                    <Typography className={classes.quotes} variant="h4">
                      “
                    </Typography>
                    <Typography variant="body1">{carrier.profileDescription}</Typography>
                    <Typography className={classes.quotes} variant="h4">
                      ”
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container item justifyContent="flex-start">
                {carrierData.company.companyWebsite && (
                  <Box mt={1}>
                    <Button color="primary" variant="contained">
                      <a
                        className={classes.link}
                        href={`//${carrierData.company.companyWebsite}`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {t('companyInfo:visitWebsite')}
                      </a>
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box mt={3} />
          {/* CARRIER DETAIL CARDS */}

          <Grid container direction="column">
            <Grid container item>
              <Grid item>
                {/* CARRIER INFO */}
                <Box mb={2.5} style={{ width: 380 }}>
                  <Typography variant="h4">{t(`common:subscriptionFeatures`)}</Typography>
                  <Box mt={3}>
                    <CarrierProfileFeatures profile={carrierData} t={t} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="h4">{t(`companyInfo:areaOfOperation`)}</Typography>
                <CarrierProfileCoverage profile={carrierData} />
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid item style={{ minWidth: 680 }} xs>
                  <Typography variant="h4">{t(`common:accessorials`)}</Typography>
                  <Box mt={3}>
                    <CarrierProfileAccessorials direction="row" profile={carrierData} t={t} />
                  </Box>
                </Grid>
                <Grid item style={{ maxWidth: 324 }} xs>
                  <Typography variant="h4">{t(`shipments:additionalCharges`)}</Typography>
                  <Box mt={3}>
                    <CarrierProfileAdditionalCosts
                      equipment={carrierData.carrierProfileEquipment}
                      language={transformI18nLocaleToLanguage(locale)}
                      t={t}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CarrierProfile;
