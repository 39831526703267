"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormIconStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useFormIconStyles = (0, ux_1.makeStyles)((theme) => ({
    completeFieldIcon: {
        color: theme.palette.primary.main,
    },
    errorFieldIcon: {
        color: theme.palette.error.main,
    },
    fileUploadError: {
        // TODO: get rid of `!important` shame on me!
        borderColor: `${theme.palette.error.main} !important`,
        color: `${theme.palette.error.main} !important`,
    },
    highlightFieldIcon: {
        color: theme.palette.primary.light,
    },
    incompleteFieldIcon: {
        color: '#C4C4C4',
    },
}));
