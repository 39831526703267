"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDashboardAdditionalCharges = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("../../utils");
const ShipmentDashboardAdditionalCharges_styles_1 = require("./ShipmentDashboardAdditionalCharges.styles");
const ShipmentDashboardAdditionalCharges = ({ additionalChargesPrompt, areAdditionalChargesSaving, carrierCurrency, chargeRecipient, defaultAdditionalChargesState, handleChargeRemoval, isPerChargeSavedEnabled, isUploading, maximumAdditionalCharges, shouldAllowNegativeNumbers, shouldConfirmChargeRemoval, shouldHideRadioButtons, shouldSuppressPromptNote, sourceEnum, t, }) => {
    const classes = (0, ShipmentDashboardAdditionalCharges_styles_1.useStyles)();
    const [areThereAdditionalCharges, setAreThereAdditionalCharges] = (0, react_1.useState)(defaultAdditionalChargesState);
    const defaultChargeForRecipient = (0, react_1.useMemo)(() => {
        return (0, utils_1.generateDefaultChargeForRecipient)(chargeRecipient);
    }, [chargeRecipient]);
    // UPLOAD FORM -- START
    const { control, reset, trigger } = (0, react_hook_form_1.useFormContext)();
    const { append, fields, remove } = (0, react_hook_form_1.useFieldArray)({
        control,
        keyName: 'formId',
        name: 'additionalCharges',
    });
    const hasUnsavedChanges = (0, react_1.useMemo)(() => {
        const ids = fields.map(({ id }) => id);
        return ids.includes(undefined);
    }, [fields]);
    (0, react_1.useEffect)(() => {
        if (fields && fields.length) {
            trigger('additionalCharges');
        }
    }, [trigger, fields]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Typography, { variant: "caption" }, t('shipments:additionalCharges'))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Typography, { className: classes.primaryLight }, additionalChargesPrompt)),
        Boolean(maximumAdditionalCharges) && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Typography, null, t('shipments:additionalChargesMaximum', { maximumAdditionalCharges })))),
        !shouldHideRadioButtons && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 1 },
                react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", isDisabled: isUploading, onChange: (event) => {
                        if (event === schemas_1.EYesNoV1.YES && fields.length === 0) {
                            append(defaultChargeForRecipient);
                        }
                        else if (event === schemas_1.EYesNoV1.NO && fields.length !== 0) {
                            reset();
                            fields.forEach((_field, index) => {
                                remove(index);
                            });
                        }
                        setAreThereAdditionalCharges(event);
                    }, sourceEnum: schemas_1.EYesNoV1, t: t, value: areThereAdditionalCharges })))),
        areThereAdditionalCharges === schemas_1.EYesNoV1.YES && (react_1.default.createElement(react_1.default.Fragment, null,
            !shouldSuppressPromptNote && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.Box, { mb: -1, mt: 1 },
                    react_1.default.createElement(ux_1.Typography, { color: "error" }, t('shipments:additionalChargesPromptNote'))))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                react_1.default.createElement(ux_1.List, null, (fields || []).map((field, idx) => {
                    return (react_1.default.createElement(ux_1.Box, { key: field.formId, mt: idx === 0 ? 1 : 0 },
                        react_1.default.createElement(__1.ShipmentDashboardAdditionalChargeItem, { additionalCharge: Object.assign({}, field), areAdditionalChargesSaving: areAdditionalChargesSaving, control: control, currency: carrierCurrency, handleChargeRemoval: handleChargeRemoval, index: idx, isDisabled: isUploading, isPerChargeSavedEnabled: isPerChargeSavedEnabled, localizationPrefix: `additionalchargetype-${chargeRecipient}-`, onRemoveItem: (indexInput) => {
                                remove(indexInput);
                                // Only turn the radio buttons off, if they are visible to turn back on
                                if (fields.length === 1 && !shouldHideRadioButtons) {
                                    setAreThereAdditionalCharges(schemas_1.EYesNoV1.NO);
                                }
                            }, shouldAllowNegativeNumbers: shouldAllowNegativeNumbers, shouldConfirmChargeRemoval: shouldConfirmChargeRemoval, sourceEnum: sourceEnum, t: t })));
                })),
                (hasUnsavedChanges || isPerChargeSavedEnabled) && (react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mb: 2, ml: -1 },
                        react_1.default.createElement(ux_1.Button, { className: classes.primaryLight, disabled: isUploading, onClick: () => append(defaultChargeForRecipient), startIcon: react_1.default.createElement(ux_1.AddCircleOutlineIcon, null) },
                            react_1.default.createElement(ux_1.Typography, { className: classes.primaryLight }, t(`common:addAdditionalChargesBtnLabel`)))))))))));
};
exports.ShipmentDashboardAdditionalCharges = ShipmentDashboardAdditionalCharges;
