import { Box, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { LandingPageBookADemo, PageLink, SignupCarousel } from '..';
import Image from 'next/image';
import React from 'react';
import { SplitPage } from '../../pageTemplates';
import { useStyles } from './LoadPlanningMarketing.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TLoadPlanningMarketing = {
  shouldSuppressCTA?: boolean;
  banner?: JSX.Element;
};
export const LoadPlanningMarketing = ({
  banner,
  shouldSuppressCTA,
}: TLoadPlanningMarketing): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isImageBottom = useMediaQuery(
    theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.md)
  );
  const imagePrefix = `SitePreview`;

  const slides = [
    {
      image: `/assets/images/marketing/${imagePrefix}-SavedLoads-1.png`,
      position: 'top left',
      title: '',
    },
    {
      image: `/assets/images/marketing/${imagePrefix}-SavedLoads-2.png`,
      position: 'top left',
      title: '',
    },
    {
      image: `/assets/images/marketing/${imagePrefix}-SavedLoads-3.png`,
      position: 'top right',
      title: '',
    },
  ].map((slide) => {
    return {
      image: (
        <Box
          borderRadius={theme.spacing(1)}
          className={classes.shadowFilter}
          height={'100%'}
          overflow={'hidden'}
          position="relative"
          width={'100%'}
        >
          <Image
            key={`client-logo-1`}
            layout="fill"
            objectFit="cover"
            objectPosition={slide.position}
            src={slide.image}
            unoptimized
          />
        </Box>
      ),
      title: slide.title,
    };
  });

  const content = (
    <Box style={{ maxWidth: '100%' }}>
      {Boolean(banner) && (
        <Box mb={8} mt={0}>
          {banner}
        </Box>
      )}

      <Box mb={2}>
        <Typography color="primaryLight" fontStyle="semibold" variant="h2">
          {t('common:loadPlanningMarketing-h1')}
        </Typography>
      </Box>

      <Typography className={classes.content} variant="bodyLarge">
        {t('common:loadPlanningMarketing-p1')}
      </Typography>
      <Typography variant="bodyLarge">{t('common:loadPlanningMarketing-p2')}</Typography>

      <Box mb={3}>
        <Box mb={2} mt={2}>
          <Typography color="primaryLight" fontStyle="semibold" variant="h3">
            {t('common:loadPlanningMarketing-h2-a')}
          </Typography>
        </Box>
        <Typography className={classes.content} variant="bodyLarge">
          {t('common:loadPlanningMarketing-p3')}
        </Typography>
        <Typography className={classes.content} variant="bodyLarge">
          {t('common:loadPlanningMarketing-p4')}
        </Typography>
        <Typography className={classes.content} variant="bodyLarge">
          {t('common:loadPlanningMarketing-p5')}
        </Typography>
      </Box>

      <Box mb={6}>
        <Box mb={2} mt={2}>
          <Typography color="primaryLight" fontStyle="semibold" variant="h3">
            {t('common:loadPlanningMarketing-h2-b')}
          </Typography>
        </Box>
        <Typography className={classes.content} variant="bodyLarge">
          {t('common:loadPlanningMarketing-p6')}
        </Typography>
        <Typography className={classes.content} variant="bodyLarge">
          {t('common:loadPlanningMarketing-p7')}
        </Typography>
        <Typography variant="bodyLarge">{t('common:loadPlanningMarketing-p8')}</Typography>
      </Box>
      {!shouldSuppressCTA && (
        <Box mb={6}>
          <LandingPageBookADemo
            className={classes.cta}
            content={{
              cta: t('common:weWantToHearFromYou'),
              link: <PageLink pageId={'contact-us'}>{t('common:contactUs')}</PageLink>,
              prompt: t('common:alwaysImproving'),
            }}
          />
        </Box>
      )}
    </Box>
  );
  if (shouldSuppressCTA) return content;

  return (
    <SplitPage
      isFullPage
      pageId="search"
      rightHandContent={
        <Box
          sx={{
            height: isImageBottom ? '130%' : '100%',
            position: 'relative',
            top: isImageBottom && '-90px',
            width: '100%',
          }}
        >
          <SignupCarousel isAnimated slideCollection={slides} />
        </Box>
      }
      shouldSuppressHeader
      shouldSuppressLeftMaxWidth
    >
      {content}
    </SplitPage>
  );
};
