"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TailGateBIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 42 28", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M7.25814 19.4557L5.96771 24.8993L8.68769e-05 24.8993", stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("path", { d: "M40.4163 12.9773V19.9318H37.9844H37.2344V20.6818C37.2344 22.9085 35.4384 24.7045 33.2117 24.7045C30.985 24.7045 29.189 22.9085 29.189 20.6818V19.9318H28.439H18.8935H18.1435V20.6818C18.1435 22.9085 16.3475 24.7045 14.1208 24.7045C11.8941 24.7045 10.0981 22.9085 10.0981 20.6818V19.9318H9.34808H6.91626V3.18182C6.91626 1.84603 8.01229 0.75 9.34808 0.75H30.8708V6.36364V7.11364H31.6208H36.0185L40.4163 12.9773ZM36.1883 8.28726L35.9631 8H35.5981H31.6208H30.8708V8.75V12.7273V13.4773H31.6208H38.7163H40.2573L39.3065 12.2645L36.1883 8.28726ZM10.9844 20.6818C10.9844 22.4165 12.3861 23.8182 14.1208 23.8182C15.8555 23.8182 17.2572 22.4165 17.2572 20.6818C17.2572 18.9471 15.8555 17.5455 14.1208 17.5455C12.3861 17.5455 10.9844 18.9471 10.9844 20.6818ZM30.0753 20.6818C30.0753 22.4165 31.477 23.8182 33.2117 23.8182C34.9464 23.8182 36.3481 22.4165 36.3481 20.6818C36.3481 18.9472 34.9464 17.5455 33.2117 17.5455C31.477 17.5455 30.0753 18.9471 30.0753 20.6818Z", stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("path", { d: "M19.9104 10.502H17.3743L17.3606 9.25098H19.5754C19.9491 9.25098 20.2659 9.19629 20.5256 9.08691C20.79 8.97298 20.9905 8.8112 21.1272 8.60156C21.2639 8.38737 21.3323 8.12988 21.3323 7.8291C21.3323 7.49642 21.2685 7.22526 21.1409 7.01562C21.0133 6.80599 20.8173 6.65332 20.553 6.55762C20.2932 6.46191 19.9605 6.41406 19.5549 6.41406H17.8938V15H16.178V5.04688H19.5549C20.1018 5.04688 20.5894 5.09928 21.0178 5.2041C21.4508 5.30892 21.8176 5.47298 22.1184 5.69629C22.4237 5.91504 22.6539 6.19303 22.8088 6.53027C22.9683 6.86751 23.0481 7.26855 23.0481 7.7334C23.0481 8.14355 22.9501 8.51953 22.7542 8.86133C22.5582 9.19857 22.2688 9.47428 21.886 9.68848C21.5032 9.90267 21.0269 10.0303 20.4573 10.0713L19.9104 10.502ZM19.8352 15H16.8342L17.6067 13.6396H19.8352C20.2226 13.6396 20.5461 13.5758 20.8059 13.4482C21.0657 13.3161 21.2594 13.1361 21.387 12.9082C21.5191 12.6758 21.5852 12.4046 21.5852 12.0947C21.5852 11.7712 21.5282 11.4909 21.4143 11.2539C21.3004 11.0124 21.1204 10.8278 20.8743 10.7002C20.6282 10.568 20.3069 10.502 19.9104 10.502H17.9827L17.9963 9.25098H20.5051L20.8948 9.72266C21.4417 9.74089 21.8905 9.86165 22.2415 10.085C22.5969 10.3083 22.8612 10.5977 23.0344 10.9531C23.2076 11.3086 23.2942 11.6914 23.2942 12.1016C23.2942 12.735 23.1552 13.266 22.8772 13.6943C22.6038 14.1227 22.2096 14.4486 21.6946 14.6719C21.1796 14.8906 20.5598 15 19.8352 15Z", fill: "#224882", strokeWidth: 1.5 })));
exports.default = TailGateBIcon;
