"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebNoodlesIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,6a1,1,0,0,1-1,1H12.456c-.315.451-.71,1.032-.29,1.555a4.011,4.011,0,0,1,0,4.889.944.944,0,0,0,0,1.111,4.011,4.011,0,0,1,0,4.889.944.944,0,0,0,0,1.111c.705.89,1.559,3.246-.166,3.445-1.329-.037-.758-1.509-1.416-2.222a2.945,2.945,0,0,1,0-3.557,2,2,0,0,0,0-2.443,2.945,2.945,0,0,1,0-3.557,2,2,0,0,0,0-2.443A2.944,2.944,0,0,1,10.164,7H8.472c-.316.452-.711,1.031-.291,1.556a4.012,4.012,0,0,1,0,4.889.947.947,0,0,0,0,1.111,4.012,4.012,0,0,1,0,4.889.947.947,0,0,0,0,1.111C8.886,21.443,9.74,23.8,8.015,24c-1.341-.049-.75-1.5-1.416-2.221a2.945,2.945,0,0,1,0-3.557,2,2,0,0,0,0-2.443,2.945,2.945,0,0,1,0-3.557,2,2,0,0,0,0-2.443A2.946,2.946,0,0,1,6.18,7H4.456c-.315.451-.71,1.032-.29,1.555a4.012,4.012,0,0,1,0,4.889.942.942,0,0,0,0,1.111,4.012,4.012,0,0,1,0,4.889.942.942,0,0,0,0,1.111C4.871,21.445,5.725,23.8,4,24c-1.329-.037-.758-1.509-1.416-2.222a2.945,2.945,0,0,1,0-3.557,2,2,0,0,0,0-2.443,2.945,2.945,0,0,1,0-3.557,2,2,0,0,0,0-2.443A2.944,2.944,0,0,1,2.164,7H1A1,1,0,0,1,1,5H23A1,1,0,0,1,24,6ZM1,4c.042.013,22.03-2,22.092-2A1,1,0,0,0,22.909,0L.91,2A1,1,0,0,0,1,4Z" })));
exports.default = TruxwebNoodlesIcon;
