"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebToothIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15.909,24A2.912,2.912,0,0,1,13,21.091V15a1,1,0,0,0-2,0v6.091a2.91,2.91,0,0,1-5.418,1.476C3.186,18.463,0,12.279,0,6A5.785,5.785,0,0,1,6,0,12.8,12.8,0,0,1,9.915.615,7.037,7.037,0,0,0,12,1,7.037,7.037,0,0,0,14.085.615,12.792,12.792,0,0,1,18,0a5.785,5.785,0,0,1,6,6c0,6.278-3.186,12.462-5.582,16.567A2.911,2.911,0,0,1,15.909,24ZM12,12a3,3,0,0,1,3,3v6.091a.911.911,0,0,0,1.691.469C18.97,17.654,22,11.793,22,6a3.85,3.85,0,0,0-4-4,10.752,10.752,0,0,0-3.358.536A8.959,8.959,0,0,1,12,3a8.953,8.953,0,0,1-2.642-.464A10.758,10.758,0,0,0,6,2C2.045,2,2,5.837,2,6c0,5.794,3.03,11.655,5.31,15.56A.91.91,0,0,0,9,21.091V15A3,3,0,0,1,12,12Z" })));
exports.default = TruxwebToothIcon;
