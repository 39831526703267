"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)(() => ({
    textWrapper: {
        fontSize: '0.9em',
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
        textAlign: 'center',
    },
}));
