"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isListFilterOptionSelected = exports.emptyListFilter = exports.manageFilterListValue = void 0;
const schemas_1 = require("@truxweb/schemas");
const _1 = require(".");
const manageFilterListValue = (filter, values, shouldForceAction, actionToForce) => {
    return Object.assign(Object.assign({}, filter), { value: (0, _1.manageListValues)(filter.value, values, shouldForceAction, actionToForce) });
};
exports.manageFilterListValue = manageFilterListValue;
const emptyListFilter = ({ property, propertyType, }) => {
    return {
        operation: schemas_1.EFilterOperationV1.IN,
        property,
        propertyType,
        value: [],
    };
};
exports.emptyListFilter = emptyListFilter;
const isListFilterOptionSelected = (filter, value) => {
    return filter.value.includes(value);
};
exports.isListFilterOptionSelected = isListFilterOptionSelected;
