"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebShrimpIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M14,4a1,1,0,0,1,2,0A1,1,0,0,1,14,4ZM24,21a3,3,0,0,1-3,3H12C5.427,24.157-.159,18.292,0,11.775A12.247,12.247,0,0,1,12.365,0L23,.023a1,1,0,0,1,0,2l-1.065,0A7.889,7.889,0,0,1,13.815,9a6.973,6.973,0,0,0-4.021,1.278A2.876,2.876,0,0,0,12,11a1,1,0,0,1,0,2,4.736,4.736,0,0,1-3.653-1.349A6.325,6.325,0,0,0,7.2,13.944,3.683,3.683,0,0,0,10,15a1,1,0,0,1,1,1c-.163,1.87-3.22.675-3.943.307A6.516,6.516,0,0,0,13.5,22c1.309-1.023,5.442-4,7.5-4A3,3,0,0,1,24,21ZM11,4.5A2.589,2.589,0,0,0,14,7a6.011,6.011,0,0,0,5.915-4.983L13.476,2A2.5,2.5,0,0,0,11,4.5ZM4.5,5.511a4.081,4.081,0,0,0,3.625,3.53A8.978,8.978,0,0,1,10.346,7.7a4.478,4.478,0,0,1-.795-5.316A10.5,10.5,0,0,0,4.5,5.511ZM6.539,20.382a8.337,8.337,0,0,1-1.514-5.4,5.271,5.271,0,0,1-2.677-.367A10.037,10.037,0,0,0,6.539,20.382Zm0-9.649a6.151,6.151,0,0,1-3.479-3.1A9.557,9.557,0,0,0,2,11.793c.231.438,1.081,1.58,3.425,1.114A8.356,8.356,0,0,1,6.542,10.733ZM22,21c-.4-2.2-2.947-.233-4.95,1H21A1,1,0,0,0,22,21Z" })));
exports.default = TruxwebShrimpIcon;
