"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    button: {
        '&:hover': {
            color: '#e53e3e',
        },
        background: 'none',
        border: '0',
        borderRadius: '50%',
        cursor: 'pointer',
        lineHeight: 'inherit',
        padding: '0  0.5rem',
    },
    tag: {
        alignItems: 'center',
        background: '#edf2f7',
        borderRadius: '0.375rem',
        display: 'inline-flex',
        justifyContent: 'center',
        padding: '0.15rem 0.25rem',
    },
}));
