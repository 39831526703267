"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarBumpIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.229,23.973a1,1,0,0,1-1.2-.744c-.7-2.946-5.316-2.915-6.057.016a1,1,0,1,1-1.94-.49c1.231-4.947,8.773-4.935,9.943.016A1,1,0,0,1,23.229,23.973ZM8.854,19.251a2,2,0,0,1-3.707,1.5l-.076-.185a4.01,4.01,0,0,1-4.027-2.483l-.529-1.3A9.919,9.919,0,0,1,0,13.385S.121,8.143.125,8.106A5.012,5.012,0,0,1,3.2,4.138l7.108-2.775a5,5,0,0,1,4.958.7l3.818,3.476A7.281,7.281,0,0,1,21.175,8.5l.53,1.3a4.017,4.017,0,0,1-.973,4.429l.069.168a2,2,0,0,1-3.681,1.563ZM2.115,8.321,2,13.383,17.8,7.07h0L13.974,3.593a2.988,2.988,0,0,0-2.927-.372L3.939,6A3,3,0,0,0,2.115,8.321ZM5.5,18.432,18.755,13.16a2,2,0,0,0,1.1-2.6l-.529-1.3c-.076-.186-.159-.367-.247-.54l-1.834.733.381.937a1,1,0,1,1-1.853.753l-.385-.948L5.866,13.992l.363.915a1,1,0,0,1-1.858.738l-.362-.911L2.2,15.458a3.637,3.637,0,0,0,.172.57l.529,1.3A2,2,0,0,0,5.5,18.432Z" })));
exports.default = TruxwebCarBumpIcon;
