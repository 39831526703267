import { makeStyles, Theme } from '@truxweb/ux';

type TSocialMediaIconLinksProps = {
  isDark?: boolean;
  screenSize: 'mobile' | 'tablet' | 'desktop';
};

export const useStyles = makeStyles<Theme, TSocialMediaIconLinksProps>((theme: Theme) => ({
  logo: {
    '& path': {
      '&:hover,&:active': {
        fill: ({ isDark }) => {
          return isDark ? theme.palette.primary.main : theme.palette.primary.dark;
        },
      },
      fill: ({ isDark }) => {
        return isDark ? theme.palette.common.white : theme.palette.primary.main;
      },
    },
    '& span:first-child': { verticalAlign: 'middle' },
  },
  logoHeight: {
    height: ({ screenSize }) => {
      return screenSize === 'mobile' ? theme.spacing(2) : theme.spacing(3);
    },
  },
}));
