"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    referenceNumber: {
        borderRadius: '8px',
        height: '40px',
    },
}));
