// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentFeeTypeEndpointsV1 = void 0;
var ShipmentFeeTypeEndpointsV1;
(function (ShipmentFeeTypeEndpointsV1) {
    ShipmentFeeTypeEndpointsV1["companyShipmentFee"] = "companyShipmentFee";
    ShipmentFeeTypeEndpointsV1["shipmentFeeType"] = "shipmentFeeType";
})(ShipmentFeeTypeEndpointsV1 || (exports.ShipmentFeeTypeEndpointsV1 = ShipmentFeeTypeEndpointsV1 = {}));
