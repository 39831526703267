"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSpadeIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,13.5c0-4.363-4.846-9.756-7.709-12.255a5.029,5.029,0,0,0-6.583,0C5.846,3.745,1,9.138,1,13.5A6.271,6.271,0,0,0,7,20a5.824,5.824,0,0,0,3.93-1.658C10.75,20.805,10.115,22,8,22H6a1,1,0,0,0,0,2H18a1,1,0,0,0,0-2H16c-2.115,0-2.75-1.2-2.93-3.658A5.824,5.824,0,0,0,17,20,6.271,6.271,0,0,0,23,13.5ZM12.9,14.917A1,1,0,0,0,12,14.365h0a1,1,0,0,0-.893.55C11.091,14.945,9.514,18,7,18a4.277,4.277,0,0,1-4-4.5c0-3.032,3.533-7.7,7.023-10.747a3.019,3.019,0,0,1,3.954,0C17.467,5.8,21,10.47,21,13.5A4.277,4.277,0,0,1,17,18C14.485,18,12.909,14.945,12.9,14.917Z" })));
exports.default = TruxwebSpadeIcon;
