"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        '& :hover': {
            backgroundColor: '#fff !important',
            borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        },
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
        boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.15)',
        color: theme.palette.grey[700],
        display: 'flex',
        height: theme.spacing(6),
        lineHeight: theme.spacing(3),
        paddingBottom: theme.spacing(0),
        paddingRight: `${theme.spacing(2)}px !important`,
        paddingTop: theme.spacing(0),
        transition: 'none',
        width: theme.spacing(34),
    },
}));
exports.useSelectStyles = (0, ux_1.makeStyles)(() => ({
    transparent: {
        backgroundColor: 'transparent !important',
    },
}));
