"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    profile: {
        borderRadius: '100%',
        height: theme.spacing(5),
        maxHeight: theme.spacing(5),
        minWidth: theme.spacing(5),
        overflow: 'hidden',
        padding: '0px 0px 0px 0px !important',
        width: theme.spacing(5),
    },
}));
