import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierRegistrationV1 } from '@truxweb/schemas';

export const createCarrier = async (
  data: TCarrierRegistrationV1,
  userEmail: string,
  companyName: string
): Promise<any> => {
  return await makeRequestToApiGateway(
    'carrierPostV1Carrier',
    null,
    {
      'Content-Type': 'application/json',
    },
    {
      ...data,
      companyName,
      userEmail,
    }
  );
};
