// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeV1 = exports.CountryV1 = exports.LanguageV1 = void 0;
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var CountryV1;
(function (CountryV1) {
    CountryV1["CA"] = "CA";
    CountryV1["US"] = "US";
})(CountryV1 || (exports.CountryV1 = CountryV1 = {}));
var UserTypeV1;
(function (UserTypeV1) {
    UserTypeV1["CARRIER"] = "CARRIER";
    UserTypeV1["SHIPPER"] = "SHIPPER";
    UserTypeV1["TRUXWEB"] = "TRUXWEB";
    UserTypeV1["EVIL_SUPER_ADMIN"] = "EVIL-SUPER-ADMIN";
})(UserTypeV1 || (exports.UserTypeV1 = UserTypeV1 = {}));
