"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    actionButton: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
    },
    deleteButton: {
        '&:hover': {
            background: theme.palette.error.dark,
        },
        background: theme.palette.error.main,
        color: theme.palette.common.white,
    },
    explanationButton: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
    },
    explanationText: {
        background: '#E8F4FB',
        borderRadius: theme.spacing(2),
    },
}));
