"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMehRollingEyesIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M16.5,6c-4.612.129-4.611,7.872,0,8C21.112,13.871,21.111,6.128,16.5,6Zm0,6c-1.114.045-1.833-1.743-1.352-2.85a1.514,1.514,0,0,0,2.7,0C18.333,10.257,17.614,12.045,16.5,12ZM11,10c-.128-5.278-6.872-5.277-7,0C4.128,15.278,10.872,15.277,11,10ZM6,10a2.479,2.479,0,0,1,.148-.85,1.514,1.514,0,0,0,2.7,0C9.772,11.956,6.265,13.4,6,10Zm11,7a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h8A1,1,0,0,1,17,17ZM12,0A12.013,12.013,0,0,0,0,12c.6,15.9,23.4,15.894,24,0A12.013,12.013,0,0,0,12,0Zm0,22A10.011,10.011,0,0,1,2,12C2.5-1.248,21.5-1.244,22,12A10.011,10.011,0,0,1,12,22Z" })));
exports.default = TruxwebMehRollingEyesIcon;
