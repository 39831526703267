"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudDisabledIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m21.781 20.367a7.494 7.494 0 0 0 2.2-4.729 7.96 7.96 0 0 0 -5.621-8.277 1.089 1.089 0 0 1 -.721-.734 7.96 7.96 0 0 0 -13.07-3.473l-2.862-2.861a1 1 0 0 0 -1.414 1.414l22 22a1 1 0 0 0 1.414-1.414zm-12.681-17.302a5.992 5.992 0 0 1 6.624 4.155 3.1 3.1 0 0 0 2.044 2.052 5.971 5.971 0 0 1 4.213 6.21 5.406 5.406 0 0 1 -1.627 3.458l-14.372-14.372a5.952 5.952 0 0 1 3.118-1.503zm7.893 18.763a1 1 0 0 1 -.887 1.1 12.216 12.216 0 0 1 -1.321.07h-9.1a5.843 5.843 0 0 1 -5.628-4.698 5.446 5.446 0 0 1 2.881-5.65 7.646 7.646 0 0 1 -.858-4.789 1 1 0 0 1 1.981.278 5.968 5.968 0 0 0 .6 3.585 2 2 0 0 1 -.791 2.7 3.467 3.467 0 0 0 -1.832 3.6 3.809 3.809 0 0 0 3.645 2.976h9.1a10.245 10.245 0 0 0 1.107-.059 1.008 1.008 0 0 1 1.104.887z" })));
exports.default = TruxwebCloudDisabledIcon;
