import {
  bookingSearch,
  createShipmentQuoteRequest,
  setQuoteCreateLoadingState,
  shipmentQuoteRequestMessagePoll,
  submitTicket,
} from '../../actions';
import { Box, Typography } from '@truxweb/ux';
import {
  clearBookingQuery,
  clearBookings,
  clearSearchForm,
  getRouteProperties,
  restoreBookingQuery,
  restoreBookings,
  restoreSearchForm,
  saveBookingQuery,
  serializeBookings,
} from '../../utils';
import {
  clearLoad,
  setSearchFormData,
  updateApplicationLoadingState,
  updateQuoteCompanyLocations,
} from '../../stores';
import {
  EPermissionV1,
  EShipmentLocationTypeV1,
  TBookingSearchRequestV1,
  type TCreateShipmentQuoteRequestV1,
} from '@truxweb/schemas';
import { LandingPageBookADemo, PageLink, TruxiiMessage } from '../../components';
import {
  Loading,
  QuoteRequestDetails,
  type TReservationFormValues,
} from '@truxweb/common-components';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import {
  useActionController,
  useAlerts,
  useApplicationLoadingState,
  useCompanyData,
  useErrorHandling,
  usePermissions,
  usePollResponse,
  useQuoteCreationState,
  useSavedSearchFormData,
  useSearchFormParams,
  useSearchLoad,
  useUserData,
} from '../../hooks';
import cloneDeep from 'lodash/cloneDeep';
import { CUSTOM_METADATA_HANDLERS } from '../../config';
import { ETicketType } from '../../types';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useStyles } from './ReservationPage.styles';

const REQUIRED_NAMESPACES = ['search', 'common', 'select'];

export const ReservationPage = (): JSX.Element => {
  const searchQuery = restoreBookingQuery();
  const dispatch = useDispatch();

  const quoteCreateState = useQuoteCreationState();
  const existingFormData = restoreSearchForm();
  const selectedBookings = restoreBookings();
  const applicationLoadingState = useApplicationLoadingState();

  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const { locale } = router;
  const classes = useStyles();
  const loadDefinition = useSearchLoad();

  const userData = useUserData();
  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();

  const { data: searchParams, hasLoaded: haveSearchParamsLoaded } = useSearchFormParams();
  const { data: savedFormData } = useSavedSearchFormData();
  const [companyData] = useCompanyData();
  const [canSetTaxExemptState, havePermissionsEvaluated] = usePermissions([
    EPermissionV1.CAN_SET_NON_TAXABLE_SHIPMENT,
  ]);

  const [canSaveLoads] = usePermissions([EPermissionV1.SAVE_LOAD_FROM_SEARCH]);

  const [shouldMountDetails, setShouldMountDetails] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [areResultsLoading, setResultsLoading] = useState(false);
  const [quoteCreationMessageId, setQuoteCreationMessageId] = useState(null);

  const [quoteId, setQuoteId] = useState<null | number>(null);
  const [bookingsToRequest, setRequestedBookings] = useState(selectedBookings);

  const [queryParams, setQueryParam] = useState(searchQuery);
  const { route: searchRoute } = getRouteProperties(locale, 'search');
  const { route: resultsRoute } = getRouteProperties(locale, 'results');

  const hasSubmittedSubscription =
    typeof window !== 'undefined'
      ? localStorage.getItem(`SUBSCRIPTION-SUBMISSION-CONFIRMATION`) === 'true'
      : false;

  const handleReloadSearchResults = useCallback(
    async (newSearchParams: TBookingSearchRequestV1) => {
      setResultsLoading(true);
      const newSearchResults = await bookingSearch(newSearchParams);
      const selectedBookingCarriers = bookingsToRequest.map(({ carrierName }) => carrierName);
      const filteredResults = newSearchResults.results.filter(({ carrierName }) => {
        return selectedBookingCarriers.includes(carrierName);
      });
      serializeBookings(filteredResults);
      setShouldMountDetails(false);
      setRequestedBookings(filteredResults);
      setShouldMountDetails(true);
      setResultsLoading(false);
    },
    [bookingsToRequest, setShouldMountDetails, setResultsLoading]
  );

  const handleSubmitTicket = useCallback(async () => {
    try {
      setIsLoading(true);
      await submitTicket({
        companyName: userData.companyData.name,
        formType: ETicketType.subscription,
        submission: {
          form: 'SubscriptionMarketing',
          request: 'subscription',
        },
        userData: userData.userData,
      });
      addAlert({
        message: t('common:subscriptionRequestSubmitted'),
        severity: 'success',
      });

      if (typeof window !== 'undefined') {
        localStorage.setItem(`SUBSCRIPTION-SUBMISSION-CONFIRMATION`, 'true');
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  }, [userData, addAlert, t, errorHandler, setIsLoading]);

  const onRequestQuote = useCallback(
    async (formData: TReservationFormValues) => {
      const hasSubscription = Boolean(userData.companyData.subscription?.isActive);

      const loadingPhrases = [
        t('common:quoteLoadingPhraseOne'),
        hasSubscription ? t('common:quoteLoadingPhraseTwo-B') : t('common:quoteLoadingPhraseTwo-A'),
        t('common:quoteLoadingPhraseThree'),
        t('common:quoteLoadingPhraseFour'),
      ];

      dispatch(
        updateApplicationLoadingState({
          ...applicationLoadingState,
          isActionLoading: true,
          loadingPhrases,
          staticText: t('common:stayOnPage'),
        })
      );
      setIsComplete(true);

      // We need to parse the form data  for any address changes
      // and update the addresses

      const newParams = cloneDeep(queryParams);
      Object.keys(formData).forEach((key) => {
        const [prefix, field] = key.split('-');
        if (prefix === EShipmentLocationTypeV1.ORIGIN) {
          newParams.originAddress[field] = formData[key];
        } else if (prefix === EShipmentLocationTypeV1.DESTINATION) {
          newParams.destinationAddress[field] = formData[key];
        }
      });

      setQueryParam(newParams);

      const requestedCarrierIds = formData.carrier
        .filter(({ isSelected }) => {
          return isSelected;
        })
        .map(({ id }) => {
          return id;
        });

      const accessorialMetadata = formData.accessorial
        .filter((datum) => {
          return datum.hasMetadata;
        })
        .map((datum) => {
          return {
            accessorialId: datum.accessorial.id,
            metadataName: datum.metadataName,
            metadataType: datum.metadataType,
            metadataValue: datum.metadataValue,
          };
        });

      const requestParams: TCreateShipmentQuoteRequestV1 = {
        accessorialMetadata,
        confirmationRequest: {
          queryParams: newParams,
        },
        isBookingDateFlexible: formData.isBookingDateFlexible,
        isTaxable: !formData.isTaxExempt,
        ltlCargoDimensions: formData.loadDefinition?.cargoSpecifications,
        requestedCarrierIds,
        shipperNote: formData.shipperNote || '',
        shouldSaveSearch: formData.shouldSaveSearch,
      };

      // The initial request returns a message id. This message ID corresponds
      // to an operation taking place off the main thread of the request
      // Once `setQuoteCreationMessageId` is set, the `usePollResponse`
      // hook will start polling for a response with this messageId
      const messageId = await createShipmentQuoteRequest(requestParams);
      dispatch(setQuoteCreateLoadingState(messageId, true));
      setQuoteCreationMessageId(messageId);
    },
    [
      queryParams,
      setQueryParam,
      setQuoteCreationMessageId,
      dispatch,
      applicationLoadingState,
      t,
      userData,
    ]
  );

  const handleQuoteCreationSuccess = useCallback(
    async (successResponse: Record<string, any>) => {
      dispatch(
        updateApplicationLoadingState({
          ...applicationLoadingState,
          actionId: undefined,
          actionType: undefined,
          isActionLoading: false,
        })
      );
      const { shipmentQuoteRequestId } = successResponse;
      setQuoteCreationMessageId(null);

      addAlert({
        message: t('common:requestSuccess'),
        severity: 'success',
      });

      // Mark which locations will be created alongside with the quote
      const locationsToCreate = {
        [EShipmentLocationTypeV1.DESTINATION]: !queryParams.destinationLocationId,
        [EShipmentLocationTypeV1.ORIGIN]: !queryParams.originLocationId,
      };
      dispatch(updateQuoteCompanyLocations(locationsToCreate));
      setQuoteId(shipmentQuoteRequestId);
      setHasCompleted(true);

      setTimeout(() => {
        clearSearchForm();
        clearBookingQuery();
        clearBookings();
        dispatch(setSearchFormData({ data: null }));
        dispatch(clearLoad());

        const { route } = getRouteProperties(router.locale, 'requests');
        router.push(`${route}?quoteRequestId=${shipmentQuoteRequestId}`);
      }, 3000);
    },
    [
      queryParams,
      setQuoteId,
      setHasCompleted,
      router,
      addAlert,
      t,
      setQuoteCreationMessageId,
      dispatch,
      applicationLoadingState,
    ]
  );

  const customErrorHandler = useCallback(
    (_payload: any, err: any) => {
      if (err.code === '010') {
        const newParams = cloneDeep(queryParams);
        newParams.shouldExcludeTruxwebPricing = false;
        setQueryParam(newParams);
        saveBookingQuery(newParams);
        setIsComplete(false);
        handleReloadSearchResults(newParams);
        addAlert({
          message: t('common:noAvailableCredits'),
          severity: 'error',
        });
        dispatch(updateApplicationLoadingState(false));
      } else {
        errorHandler(err || t('common:quoteRequestFailedTryAgain'));
        dispatch(updateApplicationLoadingState(false));
      }
    },
    [
      t,
      dispatch,
      addAlert,
      queryParams,
      setQueryParam,
      setIsComplete,
      handleReloadSearchResults,
      errorHandler,
    ]
  );

  const { action: handleRequestQuote, isLoading: isSaving } = useActionController({
    action: onRequestQuote,
    errorHandler: customErrorHandler,
    shouldSuppressSuccessMessage: true,
  });

  const completedQuoteDisplay = (
    <Box
      mt={6.5}
      pb={5}
      pl={3.75}
      pr={3.75}
      pt={5}
      style={{ backgroundColor: '#EDF8FF', borderRadius: 20 }}
    >
      <TruxiiMessage
        additionalContent={
          <Box mt={2}>
            <Typography>
              {/* NOTE on `<Trans />`: in order to interpolate components
                        it needs _some_ value before each component so that it can index
                        which key to use, thats why there is an `&nbsp;` here  */}
              <Trans i18nKey="common:quoteConfirmationPrompt">
                &nbsp;
                <PageLink
                  className={classes.link}
                  pageId={'requests/examination'}
                  urlParams={[{ name: 'quoteRequestId', value: quoteId }]}
                >
                  {t('common:requests')}
                </PageLink>
              </Trans>
            </Typography>
          </Box>
        }
        content={t('common:quoteConfirmationHeader')}
        truxii={'smiling-large'}
      />
    </Box>
  );

  const subscriptionCta = !userData.companyData.subscription?.isActive ? (
    <LandingPageBookADemo
      content={{
        cta: t(`common:subscriptionsDesc-title`),
        isSaving: isLoading,
        link: hasSubmittedSubscription
          ? t('common:thanksForYourSubscriptionInterest')
          : t('common:subscriptionInfo'),
        linkAction: !hasSubmittedSubscription && handleSubmitTicket,
        prompt: t(`common:subscriptionsDesc-subtitle`),
      }}
    />
  ) : null;

  // Quote Creation is handled off the main thread of the HTTP response,
  // so once the request to create the quote has been submitted to the BE
  // we need to long poll to get the operation response (success or failure)
  // of the request.
  const isAwaitingCompletion = usePollResponse(quoteCreationMessageId, {
    errorFn: (err, code) => {
      setQuoteCreationMessageId(null);
      customErrorHandler(null, { code, err });
    },
    pollFn: shipmentQuoteRequestMessagePoll,
    successFn: handleQuoteCreationSuccess,
  });

  useEffect(() => {
    if (!searchQuery && !hasCompleted) {
      router.push(searchRoute);
    }
  }, [searchRoute, router, searchQuery, hasCompleted]);

  useEffect(() => {
    if (!savedFormData && existingFormData) {
      dispatch(setSearchFormData({ data: existingFormData, shouldForceRefresh: true }));
    }
  }, [dispatch, existingFormData, savedFormData]);

  useEffect(() => {
    if (quoteCreationMessageId && !quoteCreateState.data) {
      setQuoteCreationMessageId(null);
    }
  }, [quoteCreateState, quoteCreationMessageId, setQuoteCreationMessageId]);

  if (!havePermissionsEvaluated || !haveSearchParamsLoaded || !queryParams)
    return <Loading isLoading={true} t={t} />;

  // FIXME: the way `isTemperatureDataRequired` is determined is very fragile and needs to be fixed
  return (
    <QuoteRequestDetails
      accessorialData={searchParams?.accessorials || {}}
      additionalContent={subscriptionCta}
      canSaveSearches={
        canSaveLoads === true
          ? loadDefinition?.isExistingSearch !== undefined
            ? !loadDefinition?.isExistingSearch
            : true
          : false
      }
      canSetQuoteTaxExemptStatus={canSetTaxExemptState}
      company={companyData}
      completedQuoteDisplay={completedQuoteDisplay}
      confirmationText={t('common:quoteRequestLTLConfirmation')}
      customMetadataHandlers={CUSTOM_METADATA_HANDLERS}
      equipmentCode={savedFormData?.equipment.code}
      handleNavigateBack={() => {
        router.push(resultsRoute);
      }}
      handleRequestQuote={handleRequestQuote}
      hasCompleted={hasCompleted}
      isComplete={isComplete}
      isSaving={isAwaitingCompletion || isSaving || areResultsLoading}
      isTemperatureDataRequired={savedFormData?.equipment.code.includes('REEFER')}
      language={transformI18nLocaleToLanguage(locale)}
      queryParams={searchQuery || queryParams}
      searchAccessorials={{
        accessorialMetadata: savedFormData?.accessorialMetadata,
        accessorials: savedFormData?.accessorials,
      }}
      selectedBookings={bookingsToRequest || []}
      shouldForceFormUpdate={shouldMountDetails}
      t={t}
    />
  );
};
