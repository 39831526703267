"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLassoIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m13 0c-6.065 0-11 4.037-11 9a7.544 7.544 0 0 0 .685 3.107 2.939 2.939 0 0 0 -.435 3.072 6.976 6.976 0 0 0 -2.25 4.821 3.888 3.888 0 0 0 4 4 1 1 0 0 0 .008-2 1.885 1.885 0 0 1 -2.008-2 5.04 5.04 0 0 1 1.683-3.426 10.823 10.823 0 0 0 3.43.435c.3 0 .577-.007.843-.016a13.048 13.048 0 0 0 5.044 1.007c6.065 0 11-4.037 11-9s-4.935-9-11-9zm-8.71 14.688a.986.986 0 0 1 0-1.4.985.985 0 0 1 1.4 0 8.432 8.432 0 0 1 1.047 1.712 7.448 7.448 0 0 1 -2.447-.312zm8.71 1.312a11.119 11.119 0 0 1 -3.907-.712 14.454 14.454 0 0 0 -1.993-3.412 3.042 3.042 0 0 0 -2.691-.795 5.535 5.535 0 0 1 -.409-2.081c0-3.859 4.037-7 9-7s9 3.141 9 7-4.037 7-9 7z" })));
exports.default = TruxwebLassoIcon;
