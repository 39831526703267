"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFlowerTulipIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.535,14.725a1.99,1.99,0,0,0-1.726-.716A11.993,11.993,0,0,0,13,18.987V13.91A6.006,6.006,0,0,0,18,8c0-2.793-1.943-5.152-3.844-7.091a3.085,3.085,0,0,0-4.312,0C7.943,2.848,6,5.207,6,8a6.006,6.006,0,0,0,5,5.91v5.077a11.993,11.993,0,0,0-8.809-4.978,1.992,1.992,0,0,0-1.726.716,1.971,1.971,0,0,0-.393,1.792C2.111,23.855,11.591,24,11.994,24h.012c.4,0,9.884-.145,11.923-7.483A1.975,1.975,0,0,0,23.535,14.725ZM8,8c0-2.654,3.318-5.719,3.338-5.736C10.973,3.2,8.693,8.9,11,9a1,1,0,0,0,1-1,13.472,13.472,0,0,1,1.257-5.137C14.688,4.393,16,6.154,16,8A4,4,0,0,1,8,8ZM2.006,16a10.268,10.268,0,0,1,8.462,5.863C7.971,21.517,3.2,20.3,2.006,16Zm11.539,5.861A10.379,10.379,0,0,1,22,15.981C20.806,20.283,16.046,21.511,13.545,21.861Z" })));
exports.default = TruxwebFlowerTulipIcon;
