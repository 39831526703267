import {
  type AppThunk,
  setCompanyLocationData,
  setCompanyLocationListError,
  setCompanyLocationListLoaded,
  setCompanyLocationListLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const fetchCompanyLocations =
  (shouldOverwrite?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCompanyLocationListLoading());
      const results = await makeRequestToApiGateway('userGetV1CompanyLocation', null, {
        'Content-Type': 'application/json',
      });

      dispatch(
        setCompanyLocationData({ items: results, shouldOverwriteList: Boolean(shouldOverwrite) })
      );
      dispatch(setCompanyLocationListLoaded({ isProgressiveLoading: false }));
    } catch (error) {
      dispatch(setCompanyLocationListError({ error: error.message }));
    }
  };
