"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountAddress = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const react_1 = __importStar(require("react"));
const AccountAddress = ({ address, defaultCountryCode, handleAddressSubmit, isFormDisabled, isNewAddress, t, }) => {
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            yield handleAddressSubmit(data, (address === null || address === void 0 ? void 0 : address.id) || 0, isNewAddress);
        }
        finally {
            setIsSaving(false);
        }
    }), [setIsSaving, handleAddressSubmit, isNewAddress, address]);
    const { control, formState: { isDirty }, handleSubmit, watch, } = (0, react_hook_form_1.useForm)();
    return (react_1.default.createElement(__1.Card, { topColor: (address === null || address === void 0 ? void 0 : address.isDefault) ? 'primary' : undefined },
        react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
            react_1.default.createElement(ux_1.Box, { style: { position: 'absolute', right: 16, top: 16 } },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: Boolean(address === null || address === void 0 ? void 0 : address.isDefault), name: `isDefault`, render: ({ field: { onChange, value } }) => {
                        return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value, color: "primary", disabled: isFormDisabled, onChange: onChange }), id: `isDefault`, label: t(`common:defaultAddress`), value: (address === null || address === void 0 ? void 0 : address.isDefault) || false }));
                    } })),
            react_1.default.createElement(ux_1.Box, { mt: 4 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(__1.FormField, { control: control, defaultCountryCode: defaultCountryCode, defaultValue: (address === null || address === void 0 ? void 0 : address.companyLocationName) || '', id: `addressName`, inputProps: {
                                required: false,
                            }, isDisabled: isFormDisabled, isRequired: true, label: t('common:addressName'), t: t })),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mr: 2 },
                                    react_1.default.createElement(__1.AddressContactForm, { control: control, data: address, defaultCountryCode: defaultCountryCode, isFormDisabled: isFormDisabled, t: t }))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { ml: 2 },
                                    react_1.default.createElement(__1.AddressForm, { areNamesDisabled: true, control: control, data: address, defaultCountryCode: defaultCountryCode, isFormDisabled: isFormDisabled, t: t, watch: watch }))))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                        react_1.default.createElement(__1.SaveButton, { buttonText: isNewAddress ? t('common:addNewAddress') : t('common:updateAddress'), isDisabled: !isDirty, isSaving: isSaving, t: t })))))));
};
exports.AccountAddress = AccountAddress;
