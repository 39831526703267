import { Button, CircularProgress, Grid } from '@truxweb/ux';
import { landingPageButton, useStyles } from './LandingPageButton.styles';
import React from 'react';

type TLandingPageButtonProps = {
  isSaving?: boolean;
  onClick?: () => void;
  text?: React.ReactNode;
  type: 'bookNow' | 'standard' | 'outlineWhite' | 'mediumStandard' | 'mediumOutlineWhite';
  variant?: 'midnight';
  isSubmit?: boolean;
};

export const LandingPageButton = ({
  isSaving,
  isSubmit,
  onClick,
  text,
  type,
  variant,
}: TLandingPageButtonProps): JSX.Element => {
  const classes = useStyles({ variant });
  const label = landingPageButton();

  return (
    <>
      <Grid item>
        <Button
          className={classes[type]}
          classes={label}
          onClick={onClick}
          type={isSubmit ? 'submit' : 'button'}
        >
          {isSaving ? <CircularProgress className={classes.icon} size={24} /> : text}
        </Button>
      </Grid>
    </>
  );
};
