"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestCheckoutOrderSummary = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("../../utils");
const QuoteRequestCheckoutOrderSummary_styles_1 = require("./QuoteRequestCheckoutOrderSummary.styles");
const QuoteRequestCheckoutOrderSummary = ({ carrierQuoteData, charges, companySubscription, control, handleRouteToSubscription, handleToggleCreditRedemption, isLoading, language, shipmentCredits, shouldBlockCreditRedemption, t, }) => {
    const classes = (0, QuoteRequestCheckoutOrderSummary_styles_1.useStyles)();
    const { accessorialPricing, additionalCharges, adminFees, equipmentPricing, internalCharges, isTruxwebChargeBillable, savingsTotal, subtotalDisplay, surcharge, taxes, totalDisplay, truxwebFees, } = (0, react_1.useMemo)(() => {
        return (0, utils_1.groupQuoteCharges)({
            charges,
            language,
            quoteData: carrierQuoteData,
            t,
            userType: schemas_1.EUserTypeV1.SHIPPER,
        });
    }, [charges, t, carrierQuoteData, language]);
    const renderSummaryLineItem = (0, react_1.useCallback)((lineItem, index) => {
        return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", item: true, justifyContent: "space-between", key: index },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { className: classes.lineItemDescription }, lineItem.title)),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { className: classes.lineItemCost },
                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: lineItem.charge.isBillable
                            ? lineItem.charge.charge.modified
                            : Object.assign(Object.assign({}, lineItem.charge.charge.modified), { value: 0 }), valueFormatter: (displayValue, value) => {
                            if (!parseFloat(`${value}`)) {
                                return t('common:includedAbbr');
                            }
                            return displayValue;
                        } })))));
    }, [language, classes, t]);
    return (react_1.default.createElement(ux_1.Box, { style: { position: 'relative' } },
        isLoading && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center", style: {
                backdropFilter: 'blur(2px)',
                background: 'rgba(255, 255, 255, 0.3)',
                bottom: 0,
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
            } },
            react_1.default.createElement(ux_1.CircularProgress, null))),
        react_1.default.createElement(ux_1.Box, { mb: 2 },
            react_1.default.createElement(ux_1.Typography, { className: classes.subheader }, t('common:orderSummary'))),
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            Object.values(equipmentPricing).map(renderSummaryLineItem),
            Object.values(accessorialPricing).map(renderSummaryLineItem),
            Object.values(additionalCharges).map(renderSummaryLineItem),
            Object.values(adminFees).map(renderSummaryLineItem),
            Object.values(truxwebFees).map(renderSummaryLineItem),
            Object.values(internalCharges).map(renderSummaryLineItem),
            react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
                react_1.default.createElement(ux_1.Divider, null)),
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", item: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.lineItemDescription }, t(`checkout:subtotal`))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.lineItemCost },
                        react_1.default.createElement(__1.PriceDisplay, { language: language, price: subtotalDisplay.charge.modified })))),
            taxes.map((charge) => {
                return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", item: true, justifyContent: "space-between", key: charge.id },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { className: classes.lineItemDescription }, t(`checkout:${charge.chargeType}`))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { className: classes.lineItemCost },
                            react_1.default.createElement(__1.PriceDisplay, { language: language, price: charge.charge.modified })))));
            }),
            Boolean(surcharge.length) && (react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", item: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.lineItemDescription }, t(`common:charge-${surcharge[0].chargeType}`))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.lineItemCost, component: "span" },
                        react_1.default.createElement(__1.PriceDisplay, { language: language, price: surcharge[0].charge.modified })),
                    react_1.default.createElement(ux_1.Typography, { component: "span", style: { verticalAlign: 'super' }, variant: "bodyXSmall" },
                        react_1.default.createElement("sup", null, "1"))))),
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", item: true, justifyContent: "space-between" },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { className: classes.total }, t(`checkout:total`))),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Typography, { className: classes.total },
                            react_1.default.createElement(__1.PriceDisplay, { language: language, price: totalDisplay.charge.modified })))))),
        react_1.default.createElement(__1.QuoteRequestSubscriptionCTA, { companySubscription: companySubscription, control: control, handleRouteToSubscription: handleRouteToSubscription, handleToggleCreditRedemption: handleToggleCreditRedemption, isTruxwebChargeBillable: isTruxwebChargeBillable, language: language, savingsTotal: savingsTotal, shipmentCredits: shipmentCredits, shouldBlockCreditRedemption: shouldBlockCreditRedemption, t: t })));
};
exports.QuoteRequestCheckoutOrderSummary = QuoteRequestCheckoutOrderSummary;
