"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCandyIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M5.005,23.992c-2.021-.1-3.872-2.077-4.74-3.761A3.049,3.049,0,0,1,3.413,16C.841,9.025,9.03.845,16,3.413A3.049,3.049,0,0,1,20.231.265a7.972,7.972,0,0,1,3.488,3.5h0A3.046,3.046,0,0,1,20.587,8C23.158,14.973,14.971,23.158,8,20.587A3.076,3.076,0,0,1,5.005,23.992Zm-2-6c-2.543.508.405,3.415,1.606,3.909A.985.985,0,0,0,6,20.979c.083-.988-.3-2.479.521-2.859a1,1,0,0,1,1.018.035C13.425,21.9,21.9,13.421,18.155,7.541A1.006,1.006,0,0,1,19,6h1.983a.984.984,0,0,0,.926-1.392h0C21.415,3.41,18.5.462,18,3.005c-.082,1,.3,2.5-.52,2.875a.992.992,0,0,1-1.018-.035C13.879,4.2,10.114,4.891,7.5,7.5S4.2,13.881,5.845,16.458A1.007,1.007,0,0,1,5,18Zm11.638-2.51a1,1,0,0,0-1.288-1.528c-1.244,1.047-2.887,1.353-3.75.7A1,1,0,1,0,8.4,16.254C10.3,17.608,12.8,17.009,14.643,15.487Z" })));
exports.default = TruxwebCandyIcon;
