import { TAddBankAccountRequestV2, TBankAccountResponseV2 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const addBankAccountV2 = async (
  params: TAddBankAccountRequestV2
): Promise<TBankAccountResponseV2> => {
  const results = await makeRequestToApiGateway(
    'billingPostV2Bankacct',
    null,
    {
      'Content-Type': 'application/json',
    },
    params
  );
  return results as TBankAccountResponseV2;
};
