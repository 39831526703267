"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    cargo: {
        lineHeight: `${theme.spacing(2)}px`,
    },
    characterCount: {
        color: theme.palette.primary.light,
    },
    controlContainer: {
        alignItems: 'stretch',
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        margin: '0.5rem 0',
        width: '100%',
    },
    controlInput: {
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: `${theme.spacing(1)}px`,
        display: 'flex',
        width: '100%',
    },
    equipmentCard: {
        background: '#eaeaea',
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: 16,
    },
}));
