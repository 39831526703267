"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCreamIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22,12.184V11a3,3,0,0,0-2-2.816A14.458,14.458,0,0,0,18.15.809a1.948,1.948,0,0,0-1.717-.818,2.035,2.035,0,0,0-1.664,1.074C14,2.476,13.24,3,11.962,3H10A5.676,5.676,0,0,0,4.061,8.165,3,3,0,0,0,2,11v1.184A3,3,0,0,0,0,15v4a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V15A3,3,0,0,0,22,12.184ZM10,5h1.962a4.952,4.952,0,0,0,4.566-3.022A12.409,12.409,0,0,1,18,8H6.131A3.658,3.658,0,0,1,10,5ZM4,11a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1v1H4Zm18,8a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V15a1,1,0,0,1,1-1H21a1,1,0,0,1,1,1Z" })));
exports.default = TruxwebCreamIcon;
