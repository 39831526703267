import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchFormData } from '../../../actions';
import { selectSearchFormParams } from '../../../selectors';
import { type TSearchFormParams } from '../../../stores';
import { useEffect } from 'react';

export const useSearchFormParams = (shouldRefetch?: boolean): TSearchFormParams => {
  const dispatch = useDispatch();
  const searchFormParams = useSelector(selectSearchFormParams);

  useEffect(() => {
    if (
      (!searchFormParams.hasLoaded && !searchFormParams.isLoading) ||
      (!searchFormParams.isLoading && shouldRefetch)
    ) {
      dispatch(fetchSearchFormData());
    }
  }, [searchFormParams, dispatch, shouldRefetch]);

  return searchFormParams;
};
