"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    accordionHeader: {
        color: 'inherit',
        fontWeight: 600,
    },
    bolded: {
        fontWeight: 600,
    },
    card: {
        background: ({ isLargeWidth }) => (isLargeWidth ? 'transparent' : theme.palette.common.white),
        padding: 0,
    },
    cardContents: {
        width: ({ isLargeWidth }) => (isLargeWidth ? 408 : '100%'),
    },
    collapseEntered: {
        width: '100%',
    },
    loader: {
        height: `${theme.spacing(2.5)}px !important`,
        position: 'absolute',
        right: theme.spacing(11),
        top: theme.spacing(3),
        width: `${theme.spacing(2.5)}px !important`,
    },
    paymentDate: {
        background: `#D5F2D6`,
        borderRadius: theme.spacing(2),
        marginBottom: theme.spacing(3),
        maxWidth: `calc(100% + ${theme.spacing(2)}px)`,
    },
    paymentDateText: {
        color: theme.palette.success.main,
    },
    trackingCardHeader: {
        width: '100%',
    },
    trackingCardSmallHeader: {
        fontSize: 10,
    },
}));
