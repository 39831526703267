import { EUserTypeV1, type TQuoteStatusCountV1 } from '@truxweb/schemas';
import { fetchCarrierQuoteSummary, fetchShipperQuoteSummary } from '.';

// FIXME: This is fetching _all_ quote data not just summary data
// This should be updated to call a specific summary endpoint to
// avoid the need for this aggregation on the FE.
// Not done at this point due to much active work on the shipment lambda.
// Will be done in TD-2013
export const fetchUserQuoteRequests = async (
  userType: EUserTypeV1
): Promise<TQuoteStatusCountV1> => {
  const response = {
    confirmed: 0,
    inReview: 0,
    refused: 0,
    responseRequired: 0,
  };
  if (userType === EUserTypeV1.CARRIER) {
    return await fetchCarrierQuoteSummary();
  } else if (userType === EUserTypeV1.SHIPPER) {
    return await fetchShipperQuoteSummary();
  }

  return response;
};
