import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  accent: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  bgCARRIER: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: 'url(assets/images/landingPage/carrier-bg.png) top no-repeat',
    backgroundSize: 'cover',
    borderTopRightRadius: '10px',
    height: '50%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  bgSHIPPER: {
    '&:hover': {
      cursor: 'pointer',
    },
    background: 'url(assets/images/landingPage/shipper-bg.png) top no-repeat',
    backgroundSize: 'cover',
    borderBottomRightRadius: '10px',
    height: '50%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  blueFlash: {
    background: theme.palette.primary.main,
    height: '300px',
    left: '-33%',
    position: 'absolute',
    top: '-26%',
    transform: 'rotate(-32deg)',
    width: '450px',
    zIndex: 0,
    [theme.breakpoints.down('xs')]: {
      height: '230px',
    },
  },
  bold: { fontWeight: 600 },
  carrierMobile: {
    '&:hover': {
      cursor: 'pointer',
    },
    alignItems: 'center',
    background: theme.palette.primary.main,
    borderBottomLeftRadius: '10px',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '64px',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    width: '50%',
    zIndex: 5,
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconDark: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    right: 20,
    top: 20,
  },
  container: {
    height: '100%',
    [theme.breakpoints.between('sm', 'md')]: {
      height: 400,
      position: 'relative',
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      position: 'relative',
      width: '100vw',
    },
  },
  formField: {
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(5),
      position: 'relative',
    },
  },
  icon: {
    color: theme.palette.success.main,
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(1.5),
      position: 'relative',
      width: theme.spacing(1.5),
    },
  },
  image: {
    height: '55%',
    marginLeft: '-35%',
    position: 'absolute',
    top: '18%',
    width: '390px',
  },

  label: {
    color: theme.palette.common.white,
    fontSize: '20px',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  launchingSoon: {
    width: 216,
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: 147,
    },
  },
  leftColumnWrapper: {
    background: theme.palette.common.white,
    borderBottomLeftRadius: '10px',
    borderTopLeftRadius: '10px',
    height: '646px',
    overflow: 'hidden',
    padding: theme.spacing(4.5),
    position: 'relative',
    width: '400px',
    zIndex: 1,
    [theme.breakpoints.between('sm', 'md')]: {
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '10px',
      height: '338px',
      padding: theme.spacing(3),
      width: '500px',
    },
    [theme.breakpoints.down('xs')]: {
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '10px',
      height: 'calc(100vh - 64px)',
      padding: theme.spacing(3),
      width: '100vw',
    },
  },
  link: {
    '&:hover,&:active': { color: theme.palette.primary.main },
    color: theme.palette.common.white,
    textDecoration: 'none',
    width: theme.spacing(16),
  },
  mobileGetBackToYou: {
    width: theme.spacing(25),
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  overlayCARRIER: {
    backgroundColor: 'rgba(0, 135, 206, 1)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  overlayColorCARRIER: {
    background:
      'linear-gradient(301.15deg, rgba(0, 135, 206, 0.8) -0.68%, rgba(0, 135, 206, 0) 55.93%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  overlayColorSHIPPER: {
    background: 'rgba(6, 25, 70, 1)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  overlaySHIPPER: {
    background: 'linear-gradient(301.15deg, #061946 -0.9%, rgba(6, 25, 70, 0) 55.71%)',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  plainWhite: {
    color: theme.palette.common.white,
  },
  popover: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    left: '0 !important',
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
  },
  rightColumnWrapper: {
    borderBottomRightRadius: '10px',
    borderTopRightRadius: '10px',
    width: '499px',
  },
  rightColumnbg: {
    background: theme.palette.primary.dark,
    borderBottomRightRadius: '10px',
    borderTopRightRadius: '10px',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      borderRadius: '10px',
    },
  },
  shipperMobile: {
    '&:hover': {
      cursor: 'pointer',
    },
    alignItems: 'center',
    background: theme.palette.primary.dark,
    borderBottomRightRadius: '10px',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '64px',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: '50%',
    zIndex: 5,
  },

  title: {
    lineHeight: `${theme.spacing(2)}px`,
  },
  truxiWrapper: {
    zIndex: 2,
  },
  white: {
    color: theme.palette.common.white,
    fontSize: '20px',
    fontWeight: 600,
  },
  whiteUserType: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
}));
