import { EUserTypeV1 } from '@truxweb/schemas';
import { useQuoteComments } from '.';

export const useShipperQuoteComments = (
  carrierQuoteId: number,
  shouldRefetch?: boolean,
  shouldMarkAsRead?: boolean
) => {
  return useQuoteComments(EUserTypeV1.SHIPPER, carrierQuoteId, shouldRefetch, shouldMarkAsRead);
};
