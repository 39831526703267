"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBadgeStyles = exports.useAccordionDetailsStyles = exports.useAccordionSummaryStyles = exports.useAccordionStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)(() => ({
    accordionHeader: {
        color: 'inherit',
        fontWeight: 600,
    },
}));
exports.useAccordionStyles = (0, ux_1.makeStyles)((theme) => ({
    expanded: {
        borderTop: '1px solid #DCDCDC',
    },
    root: {
        boxShadow: 'unset',
        fontFamily: 'Roboto',
        margin: '0px 0 0 0 !important',
    },
    rounded: {
        '&:first-child': {
            borderRadius: ({ isStaticExpanded }) => isStaticExpanded
                ? `${theme.spacing(2)}px !important`
                : `${theme.spacing(2)}px ${theme.spacing(2)}px  0 0 !important`,
            borderTop: '1px solid white',
        },
    },
}));
exports.useAccordionSummaryStyles = (0, ux_1.makeStyles)((theme) => ({
    expanded: {
        color: theme.palette.primary.light,
    },
    root: {
        color: theme.palette.grey[600],
        height: theme.spacing(8),
    },
}));
exports.useAccordionDetailsStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        borderRadius: '16px',
        padding: 0,
    },
}));
exports.useBadgeStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
    },
}));
