import { CarrierPage } from '../../pageTemplates';
import React from 'react';
import { ShipmentCarrierListWrapper } from '../../components';
import { TTruxwebPageContentProps } from '../../types';

export const CarrierShipmentsPage = ({
  ipAddress,
  pageId,
}: TTruxwebPageContentProps): JSX.Element => {
  return (
    <CarrierPage ipAddress={ipAddress} pageId={pageId}>
      <ShipmentCarrierListWrapper pageId={pageId} />
    </CarrierPage>
  );
};
