import { makeStyles, Theme } from '@truxweb/ux';

export const useTextWebPage = makeStyles<Theme>(() => ({
  ol: {
    '& li': {
      '&::before': {
        content: 'counters(section, ".") ',
        counterIncrement: 'section',
      },
    },
    counterReset: 'section',
    listStyleType: 'none',
  },
  topOl: {
    '& li': {
      '& span': {
        display: 'inline-block',
        paddingLeft: '8px',
      },
      content: 'counters(section, ".") ',
    },
    counterReset: 'section',
    listStyleType: 'none',
  },
}));
