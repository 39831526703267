"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHeartArrowIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,17a1,1,0,0,0-1,1v2.586l-2.994-2.994C21.2,15.25,23,12.547,23,9.987a5.745,5.745,0,0,0-5.5-5.949A5.39,5.39,0,0,0,13,6.57,5.39,5.39,0,0,0,8.5,4.038a5.13,5.13,0,0,0-2.428.619L1.707.293A1,1,0,0,0,.293,1.707L4.5,5.915A6.188,6.188,0,0,0,3,9.987c0,3.791,3.951,7.9,7.265,10.676a4.248,4.248,0,0,0,5.47,0c.608-.51,1.238-1.065,1.861-1.654L20.586,22H18a1,1,0,0,0,0,2h4a2,2,0,0,0,2-2V18A1,1,0,0,0,23,17Zm-8.55,2.132a2.311,2.311,0,0,1-2.9,0C7.387,15.643,5,12.31,5,9.987A3.749,3.749,0,0,1,8.5,6.038,3.749,3.749,0,0,1,12,9.987a1,1,0,1,0,2,0,3.749,3.749,0,0,1,3.5-3.949A3.749,3.749,0,0,1,21,9.987C21,12.309,18.612,15.642,14.45,19.132Z" })));
exports.default = TruxwebHeartArrowIcon;
