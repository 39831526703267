// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCardTypeV1 = exports.StatusGenericV1 = void 0;
var StatusGenericV1;
(function (StatusGenericV1) {
    StatusGenericV1["ACTIVE"] = "ACTIVE";
    StatusGenericV1["INACTIVE"] = "INACTIVE";
    StatusGenericV1["ARCHIVED"] = "ARCHIVED";
    StatusGenericV1["PENDING"] = "PENDING";
    StatusGenericV1["REVISE"] = "REVISE";
    StatusGenericV1["REGISTERED"] = "REGISTERED";
    StatusGenericV1["BANNED"] = "BANNED";
})(StatusGenericV1 || (exports.StatusGenericV1 = StatusGenericV1 = {}));
var CreditCardTypeV1;
(function (CreditCardTypeV1) {
    CreditCardTypeV1["AmericanExpress"] = "American Express";
    CreditCardTypeV1["Discover"] = "Discover";
    CreditCardTypeV1["Mastercard"] = "Mastercard";
    CreditCardTypeV1["Visa"] = "Visa";
    CreditCardTypeV1["Unknown"] = "Unknown";
})(CreditCardTypeV1 || (exports.CreditCardTypeV1 = CreditCardTypeV1 = {}));
