"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBaseballAltIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.712,7.5A4.4,4.4,0,0,0,19.6,0,4.363,4.363,0,0,0,16.5,1.288L13.82,3.964A33.868,33.868,0,0,0,8.952,10.11a21.946,21.946,0,0,1-2.647,3.44l-4.17,4.171-.428-.428A1,1,0,1,0,.293,18.707l5,5a1,1,0,1,0,1.414-1.414L6.318,21.9l4.094-4.093A26.185,26.185,0,0,1,14.1,14.975a33.73,33.73,0,0,0,5.793-4.65ZM4.9,20.49,3.549,19.135l4.17-4.171c.161-.161.343-.357.54-.58l1.4,1.4c-.266.229-.493.437-.664.608Zm8.124-7.2c-.591.374-1.212.81-1.794,1.246L9.521,12.819c.395-.531.787-1.1,1.133-1.658a31.86,31.86,0,0,1,4.58-5.783L17.911,2.7a2.451,2.451,0,0,1,3.387,0,2.394,2.394,0,0,1,0,3.387L18.477,8.911A31.737,31.737,0,0,1,13.028,13.285ZM20.5,16.979a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,20.5,16.979Zm0,5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,20.5,21.979Z" })));
exports.default = TruxwebBaseballAltIcon;
