import {
  TruxiiMessage as BaseTruxiiMessage,
  TTruxiiMessageProps as TBaseTruxiiMessageProps,
} from '@truxweb/common-components';
import Image from 'next/image';
import React from 'react';

type TTruxiiMessageProps = Omit<TBaseTruxiiMessageProps, 'truxiiImage'> & {
  truxii?: string;
};

export const TruxiiMessage = (props: TTruxiiMessageProps): JSX.Element => {
  const { truxii, ...others } = props;
  const truxiiImage = (
    <Image height={118} src={`/assets/images/truxii/truxii-${truxii || 'info'}.svg`} width={118} />
  );

  return <BaseTruxiiMessage {...others} truxiiImage={truxiiImage} />;
};
