import { markCarrierCommentsAsReadByShipper, markShipperCommentsAsReadByCarrier } from '.';
import { EUserTypeV1 } from '@truxweb/schemas';

export const markCommentsAsRead = async (
  shipmentRef: string,
  userType: EUserTypeV1
): Promise<void> => {
  const action =
    userType === EUserTypeV1.CARRIER
      ? markShipperCommentsAsReadByCarrier
      : markCarrierCommentsAsReadByShipper;
  await action(shipmentRef);
};
