import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  accessorialCard: {
    height: '100%',
    maxHeight: 400,
  },
  accessorialCardInterior: {
    minHeight: 300,
  },

  accessorials: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    maxHeight: 270,
  },
  buttonLink: {
    color: theme.palette.primary.main,
  },
  comingSoonContainer: {
    height: 'calc(100% - 79px)',
    maxHeight: 500,
    maxWidth: 600,
    padding: 0,
    width: 'auto',
  },
  comingSoonOne: {
    color: theme.palette.primary.main,
  },
  comingSoonTwo: {
    color: theme.typography.body1.color,
  },
  fullHeight: { height: '100%' },
  icon: { color: theme.palette.primary.dark, height: 28, transform: 'scale(1.25)', width: 26 },
  image: {
    height: 100,
    position: 'relative',
    width: 280,
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  map: { minHeight: 740 },
  quotes: {
    fontFamily: 'Prata',
  },
  setHeight: { height: 1145 },
}));
