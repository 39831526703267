"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountCardWithVerticalTabs = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const AccountCardWithVerticalTabs = ({ activeTab, children, tabs, testId, }) => {
    const selectedTab = (0, react_1.useMemo)(() => {
        return tabs.find((tab) => {
            return `${tab.route}` === activeTab;
        });
    }, [activeTab, tabs]);
    return (react_1.default.createElement(__1.AccountCard, { menu: react_1.default.createElement(__1.TabsSideMenu, { activeTab: activeTab, tabs: tabs, testId: testId }), testId: testId },
        react_1.default.createElement(ux_1.Box, { mb: 8, mt: -7 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Typography, { color: "primary", "data-testid": `${testId}-SelectedTabHeader`, fontStyle: "semibold", variant: "h3" }, selectedTab === null || selectedTab === void 0 ? void 0 : selectedTab.label)),
                children))));
};
exports.AccountCardWithVerticalTabs = AccountCardWithVerticalTabs;
