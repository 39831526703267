import { Box, FlatCard, Grid, HighlightOffIcon, Typography } from '@truxweb/ux';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useCreditCards, useUserData, useUxState } from '../../hooks';
import { formatDate } from '@truxweb/utils';
import { handleShipperSubscriptionBannerState } from '../../actions';
import { PageLink } from '..';
import { useDispatch } from 'react-redux';
import { useStyles } from './ShipperSubscriptionPaymentBanner.styles';

const REQUIRED_NAMESPACES = ['common'];

export const ShipperSubscriptionPaymentBanner = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const dispatch = useDispatch();
  const creditCards = useCreditCards();
  const { companyData, userData } = useUserData();
  const uxState = useUxState();
  const classes = useStyles();

  const areConditionsMetToShowBanner = useMemo(() => {
    if (!creditCards[1]) return null;
    if (!creditCards[0]?.length && userData?.isDefault) return true;
    const defaultCreditCard = creditCards[0].find(({ isDefault }) => isDefault);
    return (
      !defaultCreditCard && userData?.isDefault && uxState.isShipperSubscriptionBannerShown !== true
    );
  }, [creditCards, userData, uxState]);

  const hideBanner = useCallback(() => {
    dispatch(handleShipperSubscriptionBannerState(false));
  }, [dispatch]);

  useEffect(() => {
    if (
      uxState.isShipperSubscriptionBannerShown === undefined &&
      areConditionsMetToShowBanner !== null
    ) {
      dispatch(handleShipperSubscriptionBannerState(areConditionsMetToShowBanner));
    }
  }, [uxState, dispatch, areConditionsMetToShowBanner]);

  if (!areConditionsMetToShowBanner || areConditionsMetToShowBanner === null) return <></>;

  return (
    <Box
      className={classes.container}
      data-testid={'ShipperSubscriptionPaymentBanner'}
      p={3}
      width={'100%'}
    >
      <button
        className={classes.hideBannerButton}
        data-testid={'ShipperSubscriptionPaymentBanner-DismissButton'}
        onClick={hideBanner}
      >
        <HighlightOffIcon fontSize="large" />
      </button>
      <Grid container justifyContent="center">
        <Box width={'60%'}>
          <FlatCard>
            <Grid container direction="column" item justifyContent="center" spacing={2} xs>
              <Grid container item justifyContent="center" xs>
                <Typography
                  align="center"
                  color="error"
                  component="span"
                  fontStyle="semibold"
                  variant="h3"
                >
                  {t('common:subscriptionRenewalWarning-Header')}
                </Typography>
              </Grid>
              <Grid container item justifyContent="center" xs>
                <Typography component="span" fontStyle="semibold" variant="bodyLarge">
                  {t('common:subscriptionRenewalWarning-P1', {
                    renewalDate: formatDate(companyData?.subscription?.nextBillingDate),
                  })}
                </Typography>
              </Grid>
              <Grid container item justifyContent="center">
                <Typography component="span" fontStyle="semibold" variant="bodyLarge">
                  {t('common:subscriptionRenewalWarning-P2')}
                </Typography>
              </Grid>
              <Grid container item justifyContent="center">
                <Typography component="span" fontStyle="semibold" variant="bodyLarge">
                  <Trans i18nKey={'common:subscriptionRenewalWarning-P3'}>
                    <PageLink pageId={'account/payment'} shouldUseModernVariant>
                      {t('common:subscriptionRenewalWarning-P3-Link')}
                    </PageLink>
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </FlatCard>
        </Box>
      </Grid>
    </Box>
  );
};
