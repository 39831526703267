"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHeatIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.016,24a1,1,0,0,1-.964-1.269c.116-.419.247-.808.377-1.191,1.285-2.684.18-6.683-1.256-8.554A12.313,12.313,0,0,1,17.359.576a1,1,0,0,1,1.811.848,10.307,10.307,0,0,0,.686,10.483c1.675,2.137,2.986,7.113,1.467,10.271-.118.351-.238.708-.345,1.091A1,1,0,0,1,20.016,24Zm-7,0a1,1,0,0,1-.964-1.269c.116-.419.247-.808.377-1.191,1.285-2.684.18-6.683-1.256-8.554A12.313,12.313,0,0,1,10.359.576a1,1,0,0,1,1.811.848,10.307,10.307,0,0,0,.686,10.483c1.675,2.137,2.986,7.113,1.467,10.271-.118.351-.238.708-.345,1.091A1,1,0,0,1,13.016,24Zm-7,0a1,1,0,0,1-.964-1.269c.116-.419.247-.808.377-1.191,1.285-2.684.18-6.683-1.256-8.554A12.313,12.313,0,0,1,3.359.576a1,1,0,0,1,1.811.848,10.307,10.307,0,0,0,.686,10.483c1.675,2.137,2.986,7.113,1.467,10.271-.118.351-.238.708-.345,1.091A1,1,0,0,1,6.016,24Z" })));
exports.default = TruxwebHeatIcon;
