"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    fieldset: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: theme.spacing(1),
        },
    },
    root: {
        '& :hover': {
            backgroundColor: 'transparent !important',
            borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        },
        alignItems: 'center',
        backgroundColor: 'transparent !important',
        borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
        color: theme.palette.grey[700],
        display: 'flex',
        height: theme.spacing(6),
        lineHeight: theme.spacing(3),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        transition: 'none',
        width: theme.spacing(15),
    },
    selectRoot: {
        padding: '11px !important',
        paddingRight: '36px !important',
    },
}));
