"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
const drawerWidth = 456;
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    closeIcon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
        color: theme.palette.grey[400],
    },
    container: {
        '& div': {
            flexShrink: 0,
        },
        height: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    drawer: {
        flexShrink: 0,
        height: '100%',
        width: ({ isExpanded, isMobile }) => {
            if (isMobile || isExpanded)
                return '100%';
            return drawerWidth;
        },
    },
    drawerContainer: {
        left: ({ isExpanded }) => (isExpanded ? 80 : undefined),
        position: 'fixed',
        right: 0,
        top: 0,
        width: 'fit-content',
        zIndex: 7,
    },
    drawerPaper: {
        backgroundColor: '#EDEDED',
        boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.15)',
        height: '100%',
        overflow: 'auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        transition: 'width 200ms linear !important',
        width: ({ isExpanded, isLeftMenuOpen, isMobile }) => {
            if (isMobile)
                return '100%';
            if (isExpanded && !isLeftMenuOpen)
                return 'calc(100% - 80px)';
            if (isExpanded && isLeftMenuOpen)
                return 'calc(100% - 360px)';
            return drawerWidth;
        },
    },
    expansionButton: {
        '&:hover': {
            background: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
            color: theme.palette.common.white,
            fill: theme.palette.common.white,
        },
        alignItems: 'center',
        background: 'white',
        border: '1px solid lightgray',
        borderRadius: ({ isExpanded }) => {
            if (!isExpanded)
                return '24px';
            return `0 24px 24px 0`;
        },
        cursor: 'pointer',
        display: 'flex',
        height: '48px',
        justifyContent: 'center',
        left: ({ isExpanded, isLeftMenuOpen }) => {
            if (isExpanded && !isLeftMenuOpen)
                return '64px';
            if (isExpanded && isLeftMenuOpen)
                return '344px';
            return `calc(100% - 478px)`;
        },
        position: 'fixed',
        top: '50%',
        transition: 'left 200ms linear',
        width: '48px',
        zIndex: 1,
    },
}));
