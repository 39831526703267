"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    '@keyframes fade-in': {
        from: {
            marginLeft: theme.spacing(-3),
            opacity: 0,
        },
        to: {
            marginLeft: theme.spacing(-2),
            opacity: 1,
        },
    },
    listItemWrapper: {
        '&:hover': {
            // FIXME: Use correct colour
            backgroundColor: '#EDF8FF',
            textDecoration: 'none',
        },
        alignItems: 'center',
        animation: `$fade-in 0.3s ease-in-out`,
        borderRadius: theme.spacing(0.8125),
        boxSizing: 'border-box',
        color: theme.palette.primary.dark,
        display: 'flex',
        height: theme.spacing(6),
        marginBottom: theme.spacing(1.75),
        marginLeft: theme.spacing(-2),
        padding: theme.spacing(2),
        width: theme.spacing(31),
    },
    subItems: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        marginLeft: theme.spacing(1.25),
    },
}));
