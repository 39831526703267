"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    card: {
        '& .MuiCardContent-root': {
            padding: theme.spacing(3.25),
        },
        '& .MuiCardContent-root:last-child': {
            padding: ({ isSmallVariant }) => (isSmallVariant ? theme.spacing(1.15) : theme.spacing(3.25)),
        },
        '&:hover': {
            border: ({ isClickable }) => {
                if (isClickable)
                    return `solid 3px ${theme.palette.primary.light}`;
                return `solid 3px ${theme.palette.grey[100]}`;
            },
            color: theme.palette.primary.main,
        },
        border: `solid 3px ${theme.palette.common.white}`,
        borderRadius: `${theme.spacing(2)}px `,
        cursor: 'pointer',
        margin: '0',
        padding: 0,
        width: ({ isSmallVariant }) => (isSmallVariant ? '166px' : `${theme.spacing(32)}px`),
    },
    checkBox: {
        color: theme.palette.grey[400],
        margin: '0 0 12px 0',
        padding: '0',
    },
    checkboxRow: {
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    container: {
        margin: 0,
        padding: 0,
    },
    grey: {
        backgroundColor: theme.palette.grey[100],
        border: `solid 3px ${theme.palette.grey[100]}`,
        color: theme.palette.grey[400],
    },
    primary: {
        '& .MuiCardContent-root': {
            padding: theme.spacing(2.5),
        },
        '& .MuiCardContent-root:last-child': {
            padding: theme.spacing(2.5),
        },
        backgroundColor: '#EDF8FF',
        border: `solid 3px ${'#EDF8FF'}`,
        color: theme.palette.primary.main[100],
        width: ({ isSmallVariant }) => (isSmallVariant ? '166px' : '191px'),
    },
}));
