"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    error: {
        '&:hover': {
            background: ({ variant }) => {
                if (variant === 'outlined') {
                    return `${theme.palette.common.white} !important`;
                }
                return `${theme.palette.error.main} !important`;
            },
            borderColor: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.error.main;
                }
                return 'none';
            },
            color: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.error.main;
                }
                return theme.palette.common.white;
            },
        },
        background: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.common.white;
            }
            return theme.palette.error.light;
        },
        borderColor: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.error.light;
            }
            return 'none';
        },
        color: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.error.light;
            }
            return theme.palette.common.white;
        },
    },
    flatButton: {
        boxShadow: 'none',
        height: 40,
        width: 'auto',
    },
    primaryDark: {
        '&:hover': {
            background: ({ variant }) => {
                if (variant === 'outlined') {
                    return `${theme.palette.common.white} !important`;
                }
                return `${theme.palette.primary.main} !important`;
            },
            borderColor: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.primary.dark;
                }
                return 'none';
            },
            color: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.primary.dark;
                }
                return theme.palette.common.white;
            },
        },
        background: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.common.white;
            }
            return theme.palette.primary.dark;
        },
        borderColor: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.primary.dark;
            }
            return 'none';
        },
        color: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.primary.dark;
            }
            return theme.palette.common.white;
        },
    },
    primaryLight: {
        '&:hover': {
            background: ({ variant }) => {
                if (variant === 'outlined') {
                    return `${theme.palette.common.white} !important`;
                }
                return `${theme.palette.primary.main} !important`;
            },
            borderColor: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.primary.main;
                }
                return 'none';
            },
            color: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.primary.main;
                }
                return theme.palette.common.white;
            },
        },
        background: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.common.white;
            }
            return theme.palette.primary.light;
        },
        borderColor: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.primary.light;
            }
            return 'none';
        },
        color: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.primary.light;
            }
            return theme.palette.common.white;
        },
    },
    success: {
        '&:hover': {
            background: ({ variant }) => {
                if (variant === 'outlined') {
                    return `${theme.palette.common.white} !important`;
                }
                return `${theme.palette.success.main} !important`;
            },
            borderColor: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.success.main;
                }
                return 'none';
            },
            color: ({ variant }) => {
                if (variant === 'outlined') {
                    return theme.palette.success.main;
                }
                return theme.palette.common.white;
            },
        },
        background: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.common.white;
            }
            return theme.palette.success.light;
        },
        borderColor: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.success.light;
            }
            return 'none';
        },
        color: ({ variant }) => {
            if (variant === 'outlined') {
                return theme.palette.success.light;
            }
            return theme.palette.common.white;
        },
    },
}));
