"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const AddressLinkedIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 12 114", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("circle", { cx: 6, cy: 6, fill: "#0087CE", r: 6 }),
    React.createElement("path", { d: "M12 107.12C12 110.434 9.31371 113.12 6 113.12C2.68629 113.12 0 110.434 0 107.12C0 103.806 2.68629 101.12 6 101.12C9.31371 101.12 12 103.806 12 107.12Z", fill: "#0087CE" }),
    React.createElement("path", { d: "M3.83984 6.59473H8.16068V107.398H3.83984V6.59473Z", fill: "url(#paint0_linear_278_34366)" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "paint0_linear_278_34366", x1: 5.37061, x2: 5.37061, y1: 6.59473, y2: 99.1042 },
            React.createElement("stop", { stopColor: "#0087CE" }),
            React.createElement("stop", { offset: 1, stopColor: "#0087CE", stopOpacity: 0 })))));
exports.default = AddressLinkedIcon;
