import { makeRequestToApiGateway } from '../../apiUtils';

export const cancelCreateShipmentQuoteRequest = async (messageId: number): Promise<void> => {
  await makeRequestToApiGateway(
    'shipmentPatchV1ShipperQuote',
    null,
    {
      'Content-Type': 'application/json',
    },
    {
      action: 'CANCEL',
      messageId,
    }
  );
};
