"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSkiingNordicIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23,19a1,1,0,0,0-1,1,2,2,0,0,1-1.758,1.975L22.978,9.209a1,1,0,1,0-1.956-.418L20.549,11H19.13a1.006,1.006,0,0,1-.856-.484L17.013,8.422A5.026,5.026,0,0,0,12.729,6H6.5A3.01,3.01,0,0,0,4.083,7.22L3.208,8.406A1,1,0,1,0,4.817,9.594l.876-1.187A1,1,0,0,1,6.5,8H9.462L7.7,12.274a3,3,0,0,0,1.175,3.675l3.658,2.308A1,1,0,0,1,13,19.1V22H7.126l1.108-3.436a1,1,0,1,0-1.9-.614L5.024,22h-2.8l1.817-8.8a1,1,0,0,0-1.959-.4L.021,22.8A1,1,0,0,0,1,24H20a4,4,0,0,0,4-4A1,1,0,0,0,23,19Zm-9.4-2.434L9.943,14.258a1,1,0,0,1-.392-1.224L11.625,8h1.1a2.97,2.97,0,0,1,.718.094l-1.864,4.525a1,1,0,1,0,1.849.762l1.7-4.134c.052.071.118.13.164.206l1.261,2.094A3.018,3.018,0,0,0,19.13,13h.99l-1.929,9H15V19.1A2.984,2.984,0,0,0,13.6,16.566ZM12,2.5A2.5,2.5,0,1,1,14.5,5,2.5,2.5,0,0,1,12,2.5Z" })));
exports.default = TruxwebSkiingNordicIcon;
