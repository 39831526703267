"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCallOutgoingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.98,2.023v4a1,1,0,0,1-2,0V3.46l-5.3,5.272a1,1,0,0,1-1.41-1.418L20.59,2.023H17.98a1,1,0,0,1,0-2h4A2,2,0,0,1,23.98,2.023Zm-.905,14.739a3.1,3.1,0,0,1,0,4.378l-.912,1.05c-8.191,7.838-28.12-12.085-20.4-20.3l1.149-1A3.085,3.085,0,0,1,7.241.928c.03.031,1.883,2.439,1.883,2.439a3.1,3.1,0,0,1-.006,4.281L7.959,9.105A12.781,12.781,0,0,0,14.89,16.05l1.465-1.165a3.1,3.1,0,0,1,4.28-.006S23.044,16.732,23.075,16.762ZM21.7,18.216s-2.393-1.842-2.424-1.872a1.1,1.1,0,0,0-1.549,0c-.027.026-2.044,1.634-2.044,1.634a1,1,0,0,1-.979.152A15,15,0,0,1,5.88,9.318a1,1,0,0,1,.145-.995S7.632,6.306,7.659,6.279a1.1,1.1,0,0,0,0-1.549C7.629,4.7,5.787,2.306,5.787,2.306a1.1,1.1,0,0,0-1.51.038l-1.149,1C-2.516,10.126,14.757,26.442,20.7,20.826l.912-1.05A1.12,1.12,0,0,0,21.7,18.216Z" })));
exports.default = TruxwebCallOutgoingIcon;
