import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierProfileRecordV1 } from '@truxweb/schemas';

export const createCarrierProfile = async (data: TCarrierProfileRecordV1[]): Promise<any> => {
  return await makeRequestToApiGateway(
    'carrierPostV1CarrierProfile',
    null,
    {
      'Content-Type': 'application/json',
    },
    {
      data,
    }
  );
};
