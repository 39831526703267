import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  contentContainer: {
    position: 'relative',
    width: '100%',
    zIndex: 3,
  },
  contentContainerSlide: {
    left: '50%',
    marginBottom: theme.spacing(17.5),
    paddingRight: theme.spacing(5),
    width: '50%',
    zIndex: 1,
  },
  contentContainerSlideFull: {
    left: '0%',
    marginBottom: theme.spacing(17.5),
    paddingRight: 0,
    width: '100%',
    zIndex: 1,
  },
  dialogFooter: {
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  imagePane: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
  imagePaneSlide: {
    right: '50%',
  },
  imagePaneSlideBack: {
    right: '0%',
  },
  imagePaneSlideHide: {
    right: '100%',
  },
  imagePaneSlideIn: {
    right: '50%',
  },
  loginLink: {
    color: '#43B4F9',
  },
  registrationCancelButton: {
    color: '#43B4F9',
    fontWeight: 400,
  },
}));
