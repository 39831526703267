import { AddCircleIcon, Box, Button, Grid } from '@truxweb/ux';
import { base64ToBlob, downloadBlob, transformI18nLocaleToLanguage } from '@truxweb/utils';
import {
  CarrierAccessorialsManagementForm,
  CarrierEquipmentManagementForm,
  CarrierLaneManagementForm,
  CarrierLanes,
  PageLink,
} from '../../components';
import { useCarrierData, useUserData } from '../../hooks';
import { CarrierPage } from '../../pageTemplates';
import { downloadRateSheetById } from '../../actions';
import React from 'react';
import { TTruxwebPageContentProps } from '../../types';
import { useRouter } from 'next/router';
import { useStyles } from './FleetPage.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'shipments'];
type TFleetPageProps = TTruxwebPageContentProps & {
  children?: JSX.Element;
  defaultTab?: number;
};
//eslint-disable-next-line complexity
export const FleetPage = ({ children, defaultTab, pageId }: TFleetPageProps): JSX.Element => {
  const { t } = useTranslation([...REQUIRED_NAMESPACES, pageId]);
  const router = useRouter();
  const userData = useUserData();
  const [carrierData] = useCarrierData();
  const { locale, query } = router;
  const classes = useStyles();
  defaultTab = defaultTab || 0;

  let tabContent = null;
  let actionButton = null;
  switch (defaultTab) {
    case 1:
      tabContent = <CarrierAccessorialsManagementForm arePricesRequired={true} />;
      break;
    case 2:
      actionButton = !query.laneId && (
        <Button color="primary" variant="contained">
          <PageLink
            className={classes.actionButton}
            pageId={`fleet/lanes/[laneId]`}
            routeParams={['create']}
          >
            <Grid alignItems="center" container direction="row">
              <Grid item>
                <Box mr={2} mt={1}>
                  <AddCircleIcon />
                </Box>
              </Grid>
              <Grid item>{t('common:addLane')}</Grid>
            </Grid>
          </PageLink>
        </Button>
      );
      tabContent = <CarrierLanes />;
      if (query.laneId) {
        const isCreateMode = query.laneId === 'create';

        tabContent = (
          <CarrierLaneManagementForm
            handleDownloadRateSheet={async (row) => {
              const rateSheetXlsx = await downloadRateSheetById(
                row.id as number,
                carrierData.id as number
              );

              downloadBlob(
                base64ToBlob(rateSheetXlsx as string),
                `Truxweb - ${userData.companyData.name} - ${row.name} - V${row.version}.xlsx`
              );
            }}
            laneId={isCreateMode ? null : parseInt(query.laneId as string, 10)}
            language={transformI18nLocaleToLanguage(locale)}
          />
        );
      }
      break;
    case 0:
    /* falls through */
    default:
      tabContent = <CarrierEquipmentManagementForm />;
      break;
  }

  return (
    <Box style={{ background: 'white' }}>
      <CarrierPage pageId={pageId}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Box mr={8}>{actionButton}</Box>
            </Grid>
          </Grid>
          <Grid item xs>
            <Box mx={8}>{children || tabContent}</Box>
          </Grid>
        </Grid>
      </CarrierPage>
    </Box>
  );
};
