"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manageListValues = void 0;
const manageListValues = (values, valuesToManage, shouldForceAction, actionToForce) => {
    const newValues = [...values];
    if (shouldForceAction) {
        valuesToManage.forEach((value) => {
            const valueIndex = newValues.indexOf(value);
            if (actionToForce === 'select') {
                if (valueIndex < 0) {
                    newValues.push(value);
                }
            }
            else {
                if (valueIndex > -1) {
                    newValues.splice(valueIndex, 1);
                }
            }
        });
    }
    else {
        valuesToManage.forEach((value) => {
            const valueIndex = newValues.indexOf(value);
            if (valueIndex < 0) {
                newValues.push(value);
            }
            else {
                newValues.splice(valueIndex, 1);
            }
        });
    }
    return newValues;
};
exports.manageListValues = manageListValues;
