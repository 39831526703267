import { EUserTypeV1, type TShipmentDetailsV1 } from '@truxweb/schemas';
import { useDispatch, useSelector } from 'react-redux';
import { getShipmentByRef } from '../../../actions';
import { selectShipmentDetailsByRef } from '../../../selectors';
import { type TReduxListResponse } from '../../../types';
import { useEffect } from 'react';

export const useShipmentDetailsByRef = (
  userType: EUserTypeV1,
  shipmentRef: string,
  shouldRefetch?: boolean
): TReduxListResponse<TShipmentDetailsV1> => {
  const dispatch = useDispatch();
  const shipmentList = useSelector(selectShipmentDetailsByRef(shipmentRef));

  useEffect(() => {
    if (
      (userType && shipmentRef && !shipmentList.hasLoaded && !shipmentList.isLoading) ||
      (userType && shipmentRef && !shipmentList.isLoading && shouldRefetch)
    ) {
      dispatch(getShipmentByRef(userType, shipmentRef));
    }
  }, [shipmentList, dispatch, shouldRefetch, userType, shipmentRef]);

  return shipmentList;
};
