"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTireRuggedIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M12,5C2.749,5.3,2.751,18.707,12,19,21.251,18.7,21.249,5.293,12,5Zm0,12c-6.608-.21-6.606-9.791,0-10C18.608,7.21,18.606,16.791,12,17ZM22.611,9.1a10.961,10.961,0,0,0-1.055-2.542,2.006,2.006,0,0,0-.364-2.332L19.778,2.808a2.015,2.015,0,0,0-2.331-.367A9.4,9.4,0,0,0,14.9,1.385,2,2,0,0,0,13,0H11A2,2,0,0,0,9.1,1.387a9.215,9.215,0,0,0-2.55,1.055,1.984,1.984,0,0,0-.913-.22,1.947,1.947,0,0,0-1.416.586L2.808,4.222a2,2,0,0,0-.363,2.331A11.116,11.116,0,0,0,1.386,9.1,2,2,0,0,0,0,11v2a2,2,0,0,0,1.392,1.9,11.67,11.67,0,0,0,1.054,2.538,2,2,0,0,0,.362,2.335l1.414,1.414a2.007,2.007,0,0,0,2.32.37A7.4,7.4,0,0,0,9.1,22.62,2,2,0,0,0,11,24h2a2,2,0,0,0,1.9-1.382,7.407,7.407,0,0,0,2.557-1.056,2.006,2.006,0,0,0,2.319-.37l1.414-1.414a2.024,2.024,0,0,0,.365-2.34A12.085,12.085,0,0,0,22.612,14.9,2,2,0,0,0,24,13V11A2,2,0,0,0,22.611,9.1ZM22,13a.979.979,0,0,0-.171.015c-.243.042-.838.246-1.111,1.225a9.748,9.748,0,0,1-.926,2.255c-.572.995-.28,1.57-.014,1.869l-1.413,1.415a1.106,1.106,0,0,0-.095-.085,1.507,1.507,0,0,0-1.855.156,5.416,5.416,0,0,1-2.13.857,1.481,1.481,0,0,0-1.274,1.148A1,1,0,0,0,13,22H11a1,1,0,0,0-.011-.145,1.479,1.479,0,0,0-1.273-1.147,5.414,5.414,0,0,1-2.131-.859,1.524,1.524,0,0,0-1.855-.155,1.081,1.081,0,0,0-.094.084L4.264,18.319c.224-.254.516-.829-.057-1.825a9.8,9.8,0,0,1-.926-2.255c-.272-.978-.867-1.182-1.11-1.224A.979.979,0,0,0,2,13V11a.929.929,0,0,0,.15-.012c.254-.038.871-.235,1.132-1.231A8.933,8.933,0,0,1,4.19,7.531c.621-1.064.283-1.648.032-1.895l1.429-1.4c.172.163.813.643,1.893-.055a7.308,7.308,0,0,1,2.2-.9C10.933,2.985,11.006,2.206,11,2l2-.026c-.006.232.067,1.011,1.255,1.312a7.329,7.329,0,0,1,2.2.9c1.079.7,1.721.219,1.907.04l1.419,1.409c-.256.252-.594.836.026,1.9a8.942,8.942,0,0,1,.909,2.228c.261,1,.878,1.193,1.132,1.231A.929.929,0,0,0,22,11ZM13,9a1,1,0,0,1-2,0A1,1,0,0,1,13,9Zm2,2a1,1,0,0,1,0,2A1,1,0,0,1,15,11Zm-5,1a1,1,0,0,1-2,0A1,1,0,0,1,10,12Zm3,3a1,1,0,0,1-2,0A1,1,0,0,1,13,15Z" })));
exports.default = TruxwebTireRuggedIcon;
