"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.triggerDownload = void 0;
const triggerDownload = (fileData) => {
    var _a;
    const link = document.createElement('a');
    link.href = fileData;
    link.setAttribute(`target`, `_blank`);
    link.setAttribute(`rel`, `noopener noreferrer`);
    document.body.appendChild(link);
    link.click();
    (_a = link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
};
exports.triggerDownload = triggerDownload;
