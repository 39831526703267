import { AccountShipperPayment, CarrierBankAccount } from '..';
import { EUserTypeV1 } from '@truxweb/schemas';
import React from 'react';

type TAccountPaymentProps = {
  userType: EUserTypeV1;
};

export const AccountPayment = ({ userType }: TAccountPaymentProps): JSX.Element => {
  if (!userType) return null;
  // FIXME: never examine user type directly. This should be a permission granted
  if (userType === EUserTypeV1.CARRIER) return <CarrierBankAccount />;

  return <AccountShipperPayment />;
};
