import { Box, Grid, Typography } from '@truxweb/ux';
import { ResponsiveEmailSignUp, SiteMap } from '..';
import { useAuthBoolean, useFooterPalette } from '../../hooks';
import React from 'react';
import { useStyles } from './DesktopFooter.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TDesktopFooterProps = {
  hasCarrierPermission: boolean;
  areCarrierPermissionsEvaluated: boolean;
  areShipperPermissionsEvaluated: boolean;
  setModalEmail?: (arg: string) => void;
  hasShipperPermission: boolean;
};

// eslint-disable-next-line complexity
export const DesktopFooter = ({
  areCarrierPermissionsEvaluated,
  areShipperPermissionsEvaluated,
  hasCarrierPermission,
  hasShipperPermission,
  setModalEmail,
}: TDesktopFooterProps): JSX.Element => {
  const footerPalette = useFooterPalette();
  const classes = useStyles({ footerPalette });
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const isAuthed = useAuthBoolean();

  const basicFooter = (
    <>
      {/* CARRIER */}

      <Grid item>
        <Typography className={classes.header} variant="body2">
          {t('common:shippersFooter')}
        </Typography>
        <Box mt={0.5}>
          <SiteMap linkClass={classes.siteMapLink} pages={['signup/shipper', 'login']} />
        </Box>
      </Grid>
      <Grid item>
        <Typography className={classes.header} variant="body2">
          {t('common:carriersFooter')}
        </Typography>
        <Box mt={0.5}>
          <SiteMap linkClass={classes.siteMapLink} pages={['signup/carrier', 'login']} />
        </Box>
      </Grid>
      <Grid item>
        <Typography className={classes.header} variant="body2">
          {t('common:aboutUsFooter')}
        </Typography>
        <Box mt={0.5}>
          <SiteMap linkClass={classes.siteMapLink} pages={['contact-us']} />
          <a
            className={classes.siteMapLink}
            href="https://truxweb.breezy.hr/"
            style={{ textDecoration: 'none' }}
            target="carreers"
          >
            <Typography variant="body1">{t('common:carreers')}</Typography>
          </a>
        </Box>
      </Grid>
    </>
  );

  let siteMapContent = isAuthed ? null : basicFooter;

  if (isAuthed && hasCarrierPermission && areCarrierPermissionsEvaluated) {
    siteMapContent = (
      <>
        {/* SHIPMENTS */}
        <Grid item>
          <Typography className={classes.header}>{t('common:shipmentsFooter')}</Typography>
          <Box mt={2}>
            <SiteMap linkClass={classes.siteMapLink} pages={['shipments']} />
          </Box>
        </Grid>

        {/* EQUIPMENT */}
        <Grid item>
          <Typography className={classes.header}>{t('common:equipmentFooter')}</Typography>
          <Box mt={2}>
            <SiteMap
              linkClass={classes.siteMapLink}
              pages={['fleet/equipment', 'fleet/lanes', 'fleet/accessorials']}
            />
          </Box>
        </Grid>

        {/* ACCOUNT */}
        <Grid item>
          <Typography className={classes.header}>{t('common:accountFooter')}</Typography>
          <Box mt={2}>
            <SiteMap
              linkClass={classes.siteMapLink}
              pages={[
                'account/company',
                'account/payment',
                'account/address',
                'account/notifications',
                `account/profile`,
              ]}
            />
          </Box>
        </Grid>

        {/* HELP */}
        <Grid item>
          <Typography className={classes.header}>{t('common:help')}</Typography>
          <Box mt={2}>
            <SiteMap linkClass={classes.siteMapLink} pages={['contact-us']} />
          </Box>
        </Grid>
      </>
    );
  } else if (isAuthed && hasShipperPermission && areShipperPermissionsEvaluated) {
    siteMapContent = (
      <>
        {/* SEARCH  */}
        <Grid item>
          <Typography className={classes.header}>{t('common:searchFooter')}</Typography>
          <Box mt={2}>
            <SiteMap linkClass={classes.siteMapLink} pages={['search', 'results']} />
          </Box>
        </Grid>

        {/* SHIPMENTS */}
        <Grid item>
          <Typography className={classes.header}>{t('common:shipmentsFooter')}</Typography>
          <Box mt={2}>
            <SiteMap linkClass={classes.siteMapLink} pages={['shipments']} />
          </Box>
        </Grid>

        {/* ACCOUNT */}
        <Grid item>
          <Typography className={classes.header}>{t('common:accountFooter')}</Typography>
          <Box mt={2}>
            <SiteMap
              linkClass={classes.siteMapLink}
              pages={[
                'account/company',
                'account/payment',
                'account/address',
                'account/notifications',
              ]}
            />
          </Box>
        </Grid>

        {/* HELP */}
        <Grid item>
          <Typography className={classes.header}>{t('common:help')}</Typography>
          <Box mt={2}>
            <SiteMap linkClass={classes.siteMapLink} pages={['contact-us']} />
          </Box>
        </Grid>
      </>
    );
  } else if (isAuthed && areCarrierPermissionsEvaluated && areShipperPermissionsEvaluated) {
    // IF a user is authenticated, but is neither a shipper or carrier
    // show them the logged out footer
    siteMapContent = basicFooter;
  }

  return (
    <>
      <Box className={classes.fullWidth} mt={5}>
        <Grid container justifyContent="space-between" wrap="wrap">
          <Grid className={classes.siteMap} item xs={7}>
            <Box mb={8} mr={10}>
              <Grid container justifyContent="space-between">
                {siteMapContent}
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Box mb={3}>
                <Typography className={classes.header} variant="body2">
                  {t('common:subscribeToNewsletter')}
                </Typography>
              </Box>
              <ResponsiveEmailSignUp
                isLight={footerPalette === 'dark' || false}
                setModalEmail={setModalEmail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
