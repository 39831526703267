import { useEffect, useState } from 'react';

// TODO: this should replace most of the actions which currently exist
// at least where the loading the params are similar enough to allow so
// TRB 09/12/2021

export type TUseActionResponse<T> = [T, boolean, boolean, Error | null];

export const useAction = <T = any>(
  action: () => Promise<any>,
  isActionReady = true,
  forceRefetch?: boolean
): TUseActionResponse<T> => {
  const [actionData, setActionData] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setError] = useState(null);

  useEffect(() => {
    const fetchActionData = async () => {
      if ((isActionReady && hasLoaded === false && loadingError === null) || forceRefetch) {
        setIsLoading(true);
        try {
          const actionData = await action();
          setActionData(actionData);
          setHasLoaded(true);
          setIsLoading(false);
        } catch (err) {
          setError(err);
          setHasLoaded(true);
          setIsLoading(false);
        }
      }
    };
    fetchActionData();
  }, [
    setActionData,
    setHasLoaded,
    hasLoaded,
    setError,
    loadingError,
    forceRefetch,
    action,
    isActionReady,
    isLoading,
  ]);

  return [actionData, hasLoaded, isLoading, loadingError];
};
