"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSingleTabStyles = exports.useTabsStyles = exports.useStepperConnectorStyles = exports.useStepperStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    arrowBtnGroup: {
        border: '1px solid #C4C4C4',
        borderRadius: theme.spacing(0.5),
        color: `#C4C4C4`,
    },
    companyButton: {
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.light,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    delayed: {
        background: '#FED4D4',
        borderRadius: `${theme.spacing(0.5)}px`,
        color: '#E94141',
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    onTime: {
        background: '#D5F2D6',
        borderRadius: `${theme.spacing(0.5)}px`,
        color: '#4CAF50',
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    resolvePaymentFailureButton: {
        background: '#EDF8FF',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '16px',
        color: theme.palette.primary.light,
    },
}));
exports.useStepperStyles = (0, ux_1.makeStyles)((theme) => ({
    activeDot: {
        '& rect': {
            fill: theme.palette.success.dark,
        },
        '& svg': {
            height: theme.spacing(4),
            width: theme.spacing(4),
        },
        alignItems: 'center',
        backgroundColor: theme.palette.success.light,
        borderRadius: '100%',
        color: 'white',
        display: 'flex',
        height: theme.spacing(5),
        justifyContent: 'center',
        marginTop: `-${theme.spacing(2)}px`,
        position: 'relative',
        width: theme.spacing(5),
        zIndex: 1,
    },
    activeStepText: {
        color: theme.palette.primary.light,
    },
    bookingDot: {
        backgroundColor: '#0087CE',
        borderRadius: '100%',
        color: theme.palette.common.white,
        height: `${theme.spacing(3)}px !important`,
        width: `${theme.spacing(3)}px !important`,
    },
    bookingIcon: {
        scale: `0.62 !important`,
    },
    changeEligibleButton: {
        backgroundColor: 'transparent',
        border: 0,
        margin: 0,
        padding: 0,
    },
    changeEligibleDot: {
        '&:hover': {
            '& svg': {
                scale: 1.25,
            },
        },
        cursor: ({ canManageCarrierData }) => (canManageCarrierData ? 'pointer' : 'unset'),
        transition: 'all 250ms',
    },
    completed: {
        '& $line': {
            backgroundColor: '#0087CE',
        },
        color: 'white',
    },
    completedDot: {
        '& svg': {
            scale: `1`,
            visibility: 'visible !important',
        },
        '&:hover': {
            '& rect': {
                fill: '#0087CE',
            },
        },
        height: `${theme.spacing(3)}px !important`,
        marginTop: `-${theme.spacing(1)}px`,
        position: 'relative',
        width: `${theme.spacing(3)}px !important`,
        zIndex: 2,
    },
    deliveredIcon: {
        '& path': {
            scale: 2,
        },
    },
    error: {
        backgroundColor: theme.palette.error.main,
        borderRadius: '100%',
    },
    icon: {
        height: 'auto',
        width: theme.spacing(3),
    },
    inDisputeDot: {
        backgroundColor: theme.palette.secondary.main,
    },
    inactiveDot: {
        '& svg': {
            scale: 0,
            visibility: 'hidden',
        },
        '&:hover': {
            '& rect': {
                fill: theme.palette.primary.main,
            },
            '& svg': {
                scale: 1,
                transition: 'all 350ms',
                visibility: 'visible',
            },
            backgroundColor: theme.palette.primary.light,
            height: theme.spacing(3),
            marginTop: `-${theme.spacing(1)}px`,
            position: 'relative',
            width: theme.spacing(3),
            zIndex: 2,
        },
        borderRadius: '100%',
        height: theme.spacing(1.25),
        transformOrigin: 'center center',
        transition: 'all 250ms',
        width: theme.spacing(1.25),
    },
    line: {
        backgroundColor: '#c4c4c4',
        height: 2,
        left: '-20px',
        position: 'relative',
    },
    neutralIcon: {
        backgroundColor: 'rgba(60, 60, 60, 0.3)',
        borderRadius: '100%',
        color: theme.palette.grey[600],
    },
    statusText: {
        fontWeight: 600,
        lineHeight: '18px',
    },
    step: {
        marginBottom: '0px',
    },
    untouchedDot: {
        backgroundColor: '#c4c4c4',
    },
}));
exports.useStepperConnectorStyles = (0, ux_1.makeStyles)((theme) => ({
    active: {
        '& $line': {
            backgroundColor: '#0087CE',
        },
        color: theme.palette.primary.light,
    },
    alternativeLabel: {
        marginLeft: 0,
        top: 0,
        width: '100%',
    },
    completed: {
        '& $line': {
            backgroundColor: '#0087CE',
        },
        color: 'white',
    },
    line: {
        backgroundColor: '#c4c4c4',
        height: 2,
        left: `-${theme.spacing(3)}px`,
        position: 'relative',
        top: theme.spacing(0.5),
    },
    root: {
        color: 'lightgrey',
    },
}));
exports.useTabsStyles = (0, ux_1.makeStyles)((theme) => ({
    divider: {
        minWidth: '1px',
        padding: 0,
    },
    root: {
        '& .Mui-selected': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '6px',
            boxShadow: '0px 0px 1px rgb(20 20 20 / 4%), 0px 0px 8px rgb(20 20 20 / 8%)',
            color: theme.palette.primary.light,
        },
        '& .Mui-selected  .MuiTab-wrapper, .MuiTab-wrapper:last-child': {
            border: 'none',
        },
        '& .MuiTab-root': {
            minHeignt: `0px`,
            minWidth: `0px`,
        },
        '& .MuiTabs-fixed': {
            maxWidth: `184px`,
        },
        '& .MuiTabs-fixed > span': {
            // Hide indicator bar
            display: 'none',
        },
        '& .MuiTabs-flexContainer': {
            alignItems: 'center',
            backgroundColor: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[200]}`,
            borderRadius: theme.spacing(1),
            padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px `,
        },
        margin: '0 auto',
        width: 'fit-content',
    },
}));
exports.useSingleTabStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '18px',
        minHeight: `0px`,
        // minHeight: `${theme.spacing(5)}px !important`,
        textTransform: 'none',
    },
    selected: {
        fontWeight: 600,
    },
}));
