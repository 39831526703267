"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPawIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M8.164,8a2.5,2.5,0,0,1-1.579-.594A4.833,4.833,0,0,1,5.028,4.145C4.785,1.652,6.145.181,7.614.017A2.655,2.655,0,0,1,9.6.611a4.179,4.179,0,0,1,1.376,2.9C11.2,5.835,10.128,8,8.164,8Zm-.328-6c-.577.064-.921.883-.817,1.946a2.868,2.868,0,0,0,.826,1.9A.539.539,0,0,0,8.2,6c.351-.039.916-.941.783-2.3h0C8.641,1.834,7.861,2,7.836,2.005ZM3.164,14a2.5,2.5,0,0,1-1.579-.594A4.833,4.833,0,0,1,.028,10.145C-.215,7.652,1.145,6.181,2.614,6.017A2.663,2.663,0,0,1,4.6,6.611a4.179,4.179,0,0,1,1.376,2.9C6.2,11.835,5.077,14,3.164,14Zm-.328-6c-.577.064-.921.883-.817,1.946a2.868,2.868,0,0,0,.826,1.9A.557.557,0,0,0,3.2,12c.351-.039.916-.941.783-2.3h0C3.718,7.872,2.861,8,2.836,8.005Zm13,0c-1.708.012-3.034-2.166-2.807-4.492h0a4.179,4.179,0,0,1,1.376-2.9A2.659,2.659,0,0,1,16.386.017c1.469.164,2.829,1.635,2.586,4.128a4.833,4.833,0,0,1-1.557,3.262A2.5,2.5,0,0,1,15.836,8ZM15.02,3.7h0c-.133,1.355.432,2.257.783,2.3a.553.553,0,0,0,.352-.145,2.868,2.868,0,0,0,.826-1.9C17.191,1.334,15.2,1.505,15.02,3.7ZM20.836,14c-1.862,0-3.034-2.166-2.807-4.492h0a4.179,4.179,0,0,1,1.376-2.9,2.653,2.653,0,0,1,1.981-.594c1.469.164,2.829,1.635,2.586,4.128a4.833,4.833,0,0,1-1.557,3.262A2.5,2.5,0,0,1,20.836,14ZM20.02,9.7h0c-.133,1.355.432,2.257.783,2.3a.574.574,0,0,0,.352-.145,2.868,2.868,0,0,0,.826-1.9c.1-1.063-.24-1.882-.817-1.946C21.139,8,20.115,7.911,20.02,9.7ZM16,24a4.853,4.853,0,0,1-2.447-.606,3.332,3.332,0,0,0-3.106,0C7.434,25.083,3.922,23.227,4,19c0-4.635,4.507-9,8-9s8,4.364,8,9C20,21.944,18.355,24,16,24Zm-4-3a4.865,4.865,0,0,1,2.447.605C16.684,22.823,18.035,21.172,18,19c0-3.38-3.521-7-6-7s-6,3.62-6,7c-.032,2.169,1.308,3.823,3.553,2.605A4.865,4.865,0,0,1,12,21Z" })));
exports.default = TruxwebPawIcon;
