"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useSearchStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        '& .MuiButton-label': { fontSize: '18px' },
        alignContent: 'end',
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        height: 48,
        position: 'relative',
        width: '150px',
    },
    container: {
        maxWidth: `${theme.spacing(125)}px`,
        width: '100%',
    },
    justifyEnd: {
        display: 'flex',
        justifyContent: 'end',
    },
    searchComponent: {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        marginTop: theme.spacing(7),
        maxWidth: '1440px',
        paddingTop: theme.spacing(7),
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        color: theme.palette.primary.light,
        marginBottom: theme.spacing(3),
    },
}));
