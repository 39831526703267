"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    addCardButton: {
        color: theme.palette.primary.light,
    },
    cardDetailsHeader: {
        color: theme.palette.grey[600],
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '15px',
    },
    cardItem: {
        border: '1px solid #C4C4C4',
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        position: 'relative',
    },
    defaultIndication: {
        background: '#fff',
        border: '1px solid #c4c4c4',
        borderRadius: '12px',
        color: '#c4c4c4',
        fontSize: '10px',
        fontWeight: 600,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        position: 'absolute',
        right: 24,
        top: -13,
    },
    selectedCardItem: {
        background: '#EDF8FF',
        borderColor: theme.palette.primary.light,
        borderWidth: '2px',
    },
    selectedDefault: {
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
    },
}));
