"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    contentRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    primaryDescription: {
        color: theme.palette.grey[600],
        fontSize: '0.75rem',
        lineHeight: '1rem',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
    },
    primaryImage: { height: '191px' },
    primaryName: {
        color: theme.palette.primary.light,
        fontWeight: 'bold',
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
    },
    secondaryDescription: {
        color: theme.palette.primary.light,
        fontWeight: 'normal',
        textAlign: 'center',
    },
    secondaryImage: {
        color: theme.palette.primary.light,
    },
    secondaryName: {
        color: theme.palette.primary.light,
        fontWeight: 'bold',
        textAlign: 'center',
    },
}));
