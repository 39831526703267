"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const MultiStopIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 26 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("line", { stroke: "#224882", strokeDasharray: "1.5 1.5", strokeLinecap: "round", x1: 1, x2: 25, y1: 16.5, y2: 16.5 }),
    React.createElement("path", { d: "M5.50016 7.83331C3.88766 7.83331 2.5835 9.13748 2.5835 10.75C2.5835 12.9375 5.50016 16.1666 5.50016 16.1666C5.50016 16.1666 8.41683 12.9375 8.41683 10.75C8.41683 9.13748 7.11266 7.83331 5.50016 7.83331ZM5.50016 11.7916C4.92516 11.7916 4.4585 11.325 4.4585 10.75C4.4585 10.175 4.92516 9.70831 5.50016 9.70831C6.07516 9.70831 6.54183 10.175 6.54183 10.75C6.54183 11.325 6.07516 11.7916 5.50016 11.7916Z", fill: "#224882" }),
    React.createElement("path", { d: "M20.5002 7.83331C18.8877 7.83331 17.5835 9.13748 17.5835 10.75C17.5835 12.9375 20.5002 16.1666 20.5002 16.1666C20.5002 16.1666 23.4168 12.9375 23.4168 10.75C23.4168 9.13748 22.1127 7.83331 20.5002 7.83331ZM20.5002 11.7916C19.9252 11.7916 19.4585 11.325 19.4585 10.75C19.4585 10.175 19.9252 9.70831 20.5002 9.70831C21.0752 9.70831 21.5418 10.175 21.5418 10.75C21.5418 11.325 21.0752 11.7916 20.5002 11.7916Z", fill: "#224882" })));
exports.default = MultiStopIcon;
