import { makeRequestToApiGateway } from '../../../apiUtils';
import { TAddCarrierDocumentDetailsV1 } from '../../../types';
import { type TShipmentDocumentV1 } from '@truxweb/schemas';

export const addCarrierDocumentToShipmentV2 = async (
  document: TShipmentDocumentV1,
  additionalDocumentInformation?: TAddCarrierDocumentDetailsV1
): Promise<void> => {
  await makeRequestToApiGateway(
    'shipmentPostV2CarrierDocument',
    null,
    {
      'Content-Type': 'application/json',
    },
    { ...additionalDocumentInformation, document }
  );
};
