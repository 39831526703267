"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHeadSideThinkingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19.8,4.419A10.9,10.9,0,0,0,9.453.106,11,11,0,0,0,2.26,17.674,3.886,3.886,0,0,1,3,20.029V21a3,3,0,0,0,3,3h7a3,3,0,0,0,3-3h.494a5.014,5.014,0,0,0,4.957-4.345L21.67,15H22a1.959,1.959,0,0,0,2-2C24,11.58,21.068,5.985,19.8,4.419ZM20.793,13a1,1,0,0,0-.991.869l-.334,2.525A3.008,3.008,0,0,1,16.494,19H15a1,1,0,0,0-1,1v1a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1v-.971a5.9,5.9,0,0,0-1.151-3.571A9,9,0,0,1,9.725,2.088,9.265,9.265,0,0,1,11.005,2a8.941,8.941,0,0,1,7.218,3.648A31.212,31.212,0,0,1,22,13ZM16.2,8.011a1,1,0,0,0-1.366-.369l-.982.564A3.994,3.994,0,0,0,12,7.142V6a1,1,0,0,0-2,0V7.142A3.994,3.994,0,0,0,8.145,8.206l-.982-.564a1,1,0,1,0-1,1.734l.993.571A3.938,3.938,0,0,0,7,11a3.938,3.938,0,0,0,.159,1.053l-.993.571a1,1,0,0,0,1,1.734l.982-.564A3.994,3.994,0,0,0,10,14.858V16a1,1,0,0,0,2,0V14.858a3.994,3.994,0,0,0,1.855-1.064l.982.564a1,1,0,1,0,1-1.734l-.993-.571A3.938,3.938,0,0,0,15,11a3.938,3.938,0,0,0-.159-1.053l.993-.571A1,1,0,0,0,16.2,8.011ZM11,13a2,2,0,1,1,2-2A2,2,0,0,1,11,13Z" })));
exports.default = TruxwebHeadSideThinkingIcon;
