// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentAdditionalChargeTypeV1 = void 0;
var ShipmentAdditionalChargeTypeV1;
(function (ShipmentAdditionalChargeTypeV1) {
    ShipmentAdditionalChargeTypeV1["OTHER"] = "OTHER";
    ShipmentAdditionalChargeTypeV1["WAITING_TIME"] = "WAITING_TIME";
    ShipmentAdditionalChargeTypeV1["ADDITIONAL_APPOINTMENTS"] = "ADDITIONAL_APPOINTMENTS";
    ShipmentAdditionalChargeTypeV1["REDELIVERY"] = "REDELIVERY";
    ShipmentAdditionalChargeTypeV1["VAN_CLEANING_FEE"] = "VAN_CLEANING_FEE";
    ShipmentAdditionalChargeTypeV1["LAYOVER"] = "LAYOVER";
    ShipmentAdditionalChargeTypeV1["ADVANCE_NOTICE"] = "ADVANCE_NOTICE";
    ShipmentAdditionalChargeTypeV1["INSIDE_DELIVERY"] = "INSIDE_DELIVERY";
    ShipmentAdditionalChargeTypeV1["OVERSIZED_OVERLENGTH"] = "OVERSIZED_OVERLENGTH";
    ShipmentAdditionalChargeTypeV1["AFTER_HOUR_DELIVERIES"] = "AFTER_HOUR_DELIVERIES";
    ShipmentAdditionalChargeTypeV1["DIVERSION_MILES"] = "DIVERSION_MILES";
    ShipmentAdditionalChargeTypeV1["MISSED_PICKUP_DELIVERY_APPOINTMENT"] = "MISSED_PICKUP_DELIVERY_APPOINTMENT";
    ShipmentAdditionalChargeTypeV1["ADDITIONAL_STOPS"] = "ADDITIONAL_STOPS";
    ShipmentAdditionalChargeTypeV1["STORAGE_CHARGE"] = "STORAGE_CHARGE";
    ShipmentAdditionalChargeTypeV1["DETENTION"] = "DETENTION";
})(ShipmentAdditionalChargeTypeV1 || (exports.ShipmentAdditionalChargeTypeV1 = ShipmentAdditionalChargeTypeV1 = {}));
