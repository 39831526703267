"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebEggFriedIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9.5,5c-7.268.231-7.267,10.77,0,11C16.768,15.769,16.767,5.23,9.5,5Zm0,9a3.5,3.5,0,0,1,0-7A3.5,3.5,0,0,1,9.5,14ZM19.524,5.322a2.3,2.3,0,0,1-1.212-.866A9.943,9.943,0,0,0,9.549.01,10.227,10.227,0,0,0,0,11,13.015,13.015,0,0,0,13,24a8.223,8.223,0,0,0,7.829-6.121,2.534,2.534,0,0,1,.829-1.389C25.561,13.437,24.287,6.715,19.524,5.322Zm.854,9.631a4.547,4.547,0,0,0-1.5,2.487A6.157,6.157,0,0,1,13,22,11.013,11.013,0,0,1,2,11c-.339-4.727,3.367-9.047,8-9a7.94,7.94,0,0,1,6.646,3.566A4.257,4.257,0,0,0,18.9,7.223h0A4.524,4.524,0,0,1,20.378,14.953ZM13,19a1,1,0,0,1-2,0A1,1,0,0,1,13,19Zm7-8a1,1,0,0,1-2,0A1,1,0,0,1,20,11Zm-4,6a1,1,0,0,1-2,0A1,1,0,0,1,16,17Z" })));
exports.default = TruxwebEggFriedIcon;
