"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    attachment: {
        borderRadius: theme.spacing(1),
        marginRight: theme.spacing(1),
        overflow: 'hidden',
    },
    attachmentImage: {
        boxShadow: '0px 0px 8px #AAAAAA',
        height: theme.spacing(6),
        objectFit: 'cover',
        width: theme.spacing(6),
    },
    attachmentText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
        width: '250px',
    },
    comment: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
    },
    itemBackground: {
        background: ({ commentUserType, isOwnComment, viewingUserType, }) => {
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return theme.palette.primary.main;
            }
            if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return commentUserType === schemas_1.EUserTypeV1.CARRIER
                    ? theme.palette.secondary.light
                    : '#0087CE0D';
            }
            return isOwnComment ? '#0087CE0D' : '#f7f7f7';
        },
        borderRadius: ({ commentUserType, isOwnComment, viewingUserType }) => {
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return 0;
            }
            if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return commentUserType === schemas_1.EUserTypeV1.CARRIER
                    ? `0 ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px`
                    : ` ${theme.spacing(1.25)}px 0  ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px`;
            }
            return isOwnComment
                ? `0 ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px`
                : ` ${theme.spacing(1.25)}px 0  ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px`;
        },
        boxShadow: 'unset',
        padding: `${theme.spacing(2.25)}px ${theme.spacing(2.5)}px`,
        position: 'relative',
        width: '100%',
    },
    privateIcon: {
        alignItems: 'center',
        background: '#fff',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '100%',
        display: 'flex',
        height: '32px',
        justifyContent: 'center',
        width: '32px',
        zIndex: 1,
    },
    userAvatar: {
        color: ({ commentUserType, isOwnComment, viewingUserType }) => {
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return theme.palette.primary.light;
            }
            if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return commentUserType === schemas_1.EUserTypeV1.CARRIER
                    ? theme.palette.secondary.light
                    : theme.palette.primary.main;
            }
            return isOwnComment ? theme.palette.primary.main : '#636363';
        },
    },
    userType: {
        background: ({ commentUserType }) => {
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return theme.palette.primary.main;
            }
            return 'transparent';
        },
        border: ({ commentUserType, isOwnComment, viewingUserType }) => {
            let borderColor = isOwnComment ? theme.palette.primary.light : theme.palette.secondary.light;
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                borderColor = theme.palette.primary.main;
            }
            if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                borderColor =
                    commentUserType === schemas_1.EUserTypeV1.CARRIER
                        ? theme.palette.secondary.light
                        : theme.palette.primary.light;
            }
            return `1px solid ${borderColor}`;
        },
        borderRadius: '5px',
        color: ({ commentUserType, isOwnComment, viewingUserType }) => {
            const borderColor = isOwnComment
                ? theme.palette.primary.light
                : theme.palette.secondary.light;
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return theme.palette.common.white;
            }
            if (viewingUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return commentUserType === schemas_1.EUserTypeV1.CARRIER
                    ? theme.palette.secondary.light
                    : theme.palette.primary.light;
            }
            return borderColor;
        },
        padding: ({ commentUserType }) => {
            if (commentUserType === schemas_1.EUserTypeV1.TRUXWEB) {
                return `3px 64px 3px 64px`;
            }
            return `3px 10px`;
        },
    },
}));
