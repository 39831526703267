import { makeStyles, Theme } from '@truxweb/ux';

type TStyleVars = {
  isMobile: boolean;
};
export const useSharedPageStyles = makeStyles<Theme, TStyleVars>((theme: Theme) => ({
  baseWidth: {
    width: ({ isMobile }) => (!isMobile ? theme.spacing(51) : '100%'),
  },
  cloudContainer: {
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  container: {
    backgroundColor: theme.palette.common.white,
    height: 'auto',
    minHeight: '100vh',
    minWidth: theme.spacing(57),
  },
  wrapper: {
    background: '#dceefa',
    borderRadius: theme.spacing(2),
    height: 'auto',
    maxWidth: theme.spacing(119),
    overflow: 'hidden',
  },
}));
