import { makeRequestToApiGateway } from '../../apiUtils';
import { TUpdateUserRequestV1 } from '@truxweb/schemas';

export const updateUserAttributes = async (attributes: TUpdateUserRequestV1): Promise<void> => {
  await makeRequestToApiGateway(
    'userPatchV1User',
    null,
    {
      'Content-Type': 'application/json',
    },
    attributes
  );
};
