"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetTemplates = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const schemas_1 = require("@truxweb/schemas");
const RateSheetTemplates = ({ handleCancel, isSaving, onSubmitSuccess, shouldAllowModifications, t, }) => {
    const rateSheetTemplatesControls = (0, react_hook_form_1.useForm)();
    const pricingModelChange = rateSheetTemplatesControls.watch('pricingModel');
    const weightTeirChange = rateSheetTemplatesControls.watch('weightTeirs');
    const weightTeirsFieldArray = (0, react_hook_form_1.useFieldArray)({
        control: rateSheetTemplatesControls.control,
        name: 'weightTeirs',
    });
    (0, react_1.useEffect)(() => {
        if (!(weightTeirChange === null || weightTeirChange === void 0 ? void 0 : weightTeirChange.length)) {
            weightTeirsFieldArray.append({ teirMax: 500 });
            weightTeirsFieldArray.append({ teirMax: 1000 });
            weightTeirsFieldArray.append({ teirMax: 2000 });
            weightTeirsFieldArray.append({ teirMax: 5000 });
            weightTeirsFieldArray.append({ teirMax: 10000 });
            weightTeirsFieldArray.append({ teirMax: 15000 });
            rateSheetTemplatesControls.setValue('maxPallets', 12);
        }
    }, [weightTeirChange, weightTeirsFieldArray, rateSheetTemplatesControls]);
    return (react_1.default.createElement("form", { onSubmit: rateSheetTemplatesControls.handleSubmit(onSubmitSuccess) },
        react_1.default.createElement(react_hook_form_1.Controller, { control: rateSheetTemplatesControls.control, defaultValue: schemas_1.ERateSheetTypeV1.CWT, name: "pricingModel", render: ({ field: { onChange, value } }) => {
                return (react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", onChange: onChange, sourceEnum: schemas_1.ERateSheetTypeV1, t: t, value: value }));
            }, rules: { required: true } }),
        react_1.default.createElement("div", { style: { display: shouldAllowModifications ? 'block' : 'none' } },
            pricingModelChange === schemas_1.ERateSheetTypeV1.PALLET_WEIGHT && (react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: rateSheetTemplatesControls.control, defaultValue: '', id: 'maxPallets', isRequired: true, label: t('common:maxPallets'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number" }))),
            weightTeirsFieldArray.fields.map((field, index) => {
                return (react_1.default.createElement(ux_1.Box, { key: `weightTeirs.${index}.teirMax`, mb: 2, p: 2, style: { background: '#f9f9f9', borderRadius: '16px' } },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 10 },
                            react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                    shrink: true,
                                }, control: rateSheetTemplatesControls.control, defaultValue: field.teirMax, id: `weightTeirs.${index}.teirMax`, isRequired: true, label: t('common:teirMax'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: "number" })),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.StandardButton, { onClick: () => weightTeirsFieldArray.remove(index) },
                                react_1.default.createElement(ux_1.RemoveCircleOutlineIcon, null))))));
            }),
            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3 },
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                    react_1.default.createElement(ux_1.StandardButton, { onClick: () => weightTeirsFieldArray.append({}) },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                            react_1.default.createElement(ux_1.AddCircleOutlineIcon, null),
                            " ",
                            t('common:addWeightTeir')))))),
        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:back'))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }, t('common:downloadTemplate'))))));
};
exports.RateSheetTemplates = RateSheetTemplates;
