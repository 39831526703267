import { makeStyles, Theme } from '@truxweb/ux';

type HeaderStylesType = {
  isScrolled?: boolean;
};
export const useStyles = makeStyles<Theme, HeaderStylesType>((theme: Theme) => ({
  root: (props) => ({
    background: 'transparent',
    boxShadow: props.isScrolled ? 'auto' : 'unset',
    height: 'auto',
    top: 0,
    // top: props.isScrolled ? `-${theme.spacing(5)}px` : 0,
    zIndex: 4,
    [theme.breakpoints.down('xs')]: {
      top: 0,
    },
  }),
}));
