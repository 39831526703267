import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import { Card } from '..';
import classnames from 'classnames';
import React from 'react';
import { useStyles } from './LandingValuePropCard.styles';

type TLandingValuePropCardProps = {
  cardImage: React.ReactNode;
  subtitle: string;
  title: string;
  valueProp1: React.ReactNode;
  valueProp2: React.ReactNode;
  valueProp3: React.ReactNode;
  variant: 'dark' | 'light';
  className?: string;
};

export const LandingValuePropCard = ({
  cardImage,
  className,
  subtitle,
  title,
  valueProp1,
  valueProp2,
  valueProp3,
  variant,
}: TLandingValuePropCardProps): JSX.Element => {
  const classes = useStyles({ variant });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const bodyVariant = isTablet ? 'body1' : 'body2';
  return (
    <Card className={classnames(classes.container, className)}>
      <Grid alignItems="center" container direction="column">
        <Card className={classes.imageContainer}>
          <Grid alignItems="center" className={classes.imageGrid} container justifyContent="center">
            {cardImage}
          </Grid>
        </Card>
        <Box mb={isTablet ? 2 : 4} mt={isTablet ? 2 : 5}>
          <Grid alignItems="center" container direction="column">
            <Box mb={1}>
              <Typography align="center" variant="h3">
                {title}
              </Typography>
            </Box>
            <Typography variant="h5">{subtitle}</Typography>
          </Grid>
        </Box>
        <Grid alignItems="center" container direction="column">
          <Grid item>
            <Typography align="center" variant={bodyVariant}>
              {valueProp1}
            </Typography>
          </Grid>
          <Grid className={classes.divider} item>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item>
            <Typography align="center" variant={bodyVariant}>
              {valueProp2}
            </Typography>
          </Grid>
          <Grid className={classes.divider} item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography align="center" variant={bodyVariant}>
              {valueProp3}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
