"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebOpacityIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M2.886,6.836A1,1,0,0,0,3.715,6.4,10.015,10.015,0,0,1,6.524,3.63a1,1,0,1,0-1.1-1.672A12,12,0,0,0,2.06,5.275a1,1,0,0,0,.826,1.561Z" }),
    React.createElement("path", { d: "M2,12a10.112,10.112,0,0,1,.193-1.969A1,1,0,1,0,.232,9.639a12.135,12.135,0,0,0,0,4.728,1,1,0,0,0,.98.8,1.019,1.019,0,0,0,.2-.019,1,1,0,0,0,.784-1.177A10.1,10.1,0,0,1,2,12Z" }),
    React.createElement("path", { d: "M6.528,20.372a10,10,0,0,1-2.81-2.766,1,1,0,0,0-1.656,1.123,11.993,11.993,0,0,0,3.37,3.315,1,1,0,0,0,1.1-1.672Z" }),
    React.createElement("path", { d: "M12,0A12.107,12.107,0,0,0,9.829.2a1,1,0,0,0,.179,1.984,1.126,1.126,0,0,0,.181-.016A10.033,10.033,0,0,1,12,2V22a10.033,10.033,0,0,1-1.811-.164,1,1,0,0,0-.36,1.968A12.01,12.01,0,0,0,24,12,12.013,12.013,0,0,0,12,0Z" })));
exports.default = TruxwebOpacityIcon;
