"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    paymentMethodButton: {
        background: '#f3f3f3',
        border: '0px',
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
        fill: '#c4c4c4',
        height: theme.spacing(6),
        width: theme.spacing(11.25),
    },
    paymentMethodHeader: {
        color: theme.palette.grey[600],
        fontSize: '12px !important',
        fontWeight: 600,
        lineHeight: '15px !important',
    },
    selectedButton: {
        background: theme.palette.primary.light,
        fill: '#fff',
    },
    subheader: {
        color: theme.palette.primary.light,
        fontSize: '15px !important',
        fontWeight: 600,
        lineHeight: '18px ',
    },
}));
