"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCursorTextAltIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22,17.351V6.649A3.492,3.492,0,1,0,17.351,2H6.649A3.492,3.492,0,1,0,2,6.649v10.7A3.492,3.492,0,1,0,6.649,22h10.7A3.492,3.492,0,1,0,22,17.351ZM17.051,20H6.949A3.485,3.485,0,0,0,4,17.051V6.949A3.485,3.485,0,0,0,6.949,4h10.1A3.485,3.485,0,0,0,20,6.949v10.1A3.485,3.485,0,0,0,17.051,20ZM20.5,2A1.5,1.5,0,1,1,19,3.5,1.5,1.5,0,0,1,20.5,2ZM3.5,2A1.5,1.5,0,1,1,2,3.5,1.5,1.5,0,0,1,3.5,2Zm0,20A1.5,1.5,0,1,1,5,20.5,1.5,1.5,0,0,1,3.5,22Zm17,0A1.5,1.5,0,1,1,22,20.5,1.5,1.5,0,0,1,20.5,22ZM13,9v3h1a1,1,0,0,1,0,2H13v1a1,1,0,0,0,1,1,1,1,0,0,1,0,2,2.981,2.981,0,0,1-2-.78A2.981,2.981,0,0,1,10,18a1,1,0,0,1,0-2,1,1,0,0,0,1-1V14H10a1,1,0,0,1,0-2h1V9a1,1,0,0,0-1-1,1,1,0,0,1,0-2,2.981,2.981,0,0,1,2,.78A2.981,2.981,0,0,1,14,6a1,1,0,0,1,0,2A1,1,0,0,0,13,9Z" })));
exports.default = TruxwebCursorTextAltIcon;
