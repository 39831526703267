import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TAlertV1 } from '@truxweb/schemas';

export interface IAlertDataState {
  alerts: TAlertV1[];
}

export const alertSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.alerts,
      };
    },
  },
  initialState: {
    alerts: [],
  } as IAlertDataState,
  name: 'alerts',
  reducers: {
    addAlert: (state, { payload }) => {
      return { ...state, alerts: [...state.alerts, payload] };
    },
    removeAlert: (state) => {
      const alerts = state.alerts.slice();

      alerts.shift();
      return {
        ...state,
        alerts,
      };
    },
  },
});

export const {
  actions: { addAlert, removeAlert },
} = alertSlice;
