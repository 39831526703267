import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    height: '100%',
  },
  content: {
    margin: '24px 0',
  },
  cta: {
    height: 'auto',
    maxWidth: '100%',
  },
  panel: {
    background: theme.palette.primary.light,
  },
  shadowFilter: {
    boxShadow: `0 ${theme.spacing(0.5)}px ${theme.spacing(2.5)}px rgba(0, 0, 0, 0.25)`,
    [theme.breakpoints.between(theme.breakpoints.values.largeMobile, theme.breakpoints.values.sm)]:
      {
        top: theme.spacing(2),
      },
  },
}));
