"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    account: {
        left: 16,
        position: 'absolute',
        top: 40,
    },
    adjustment: {
        position: 'absolute',
        right: 16,
        top: 96,
    },
    clickableContainer: {
        cursor: 'pointer',
    },
    container: {
        position: 'relative',
    },
    header: {
        color: ({ isUnselected }) => (isUnselected ? '#C4C4C4' : theme.palette.common.white),
        fontFamily: 'Roboto Mono',
        fontSize: theme.spacing(1.5),
        fontWeight: 300,
    },
    terms: {
        left: 16,
        position: 'absolute',
        top: 96,
    },
    value: {
        color: ({ isUnselected }) => (isUnselected ? '#9D9B9B' : theme.palette.common.white),
        fontFamily: 'Roboto Mono',
        fontSize: theme.spacing(3),
        fontWeight: 500,
    },
}));
