import {
  type AppThunk,
  setCompanyDocumentData,
  setCompanyDocumentListError,
  setCompanyDocumentListLoaded,
  setCompanyDocumentListLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const fetchCompanyDocuments =
  (shouldOverwrite?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCompanyDocumentListLoading());
      const results = await makeRequestToApiGateway('userGetV1CompanyDocuments', null, {
        'Content-Type': 'application/json',
      });

      dispatch(
        setCompanyDocumentData({ items: results, shouldOverwriteList: Boolean(shouldOverwrite) })
      );
      dispatch(setCompanyDocumentListLoaded({ isProgressiveLoading: false }));
    } catch (error) {
      dispatch(setCompanyDocumentListError({ error: error.message }));
    }
  };
