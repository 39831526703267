// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotWantToHelpUsV1 = void 0;
var HubspotWantToHelpUsV1;
(function (HubspotWantToHelpUsV1) {
    HubspotWantToHelpUsV1["Yes"] = "Yes";
    HubspotWantToHelpUsV1["No"] = "No";
})(HubspotWantToHelpUsV1 || (exports.HubspotWantToHelpUsV1 = HubspotWantToHelpUsV1 = {}));
