import {
  type AppThunk,
  setShipmentCreditsError,
  setShipmentCreditsLoaded,
  setShipmentCreditsLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const getShipmentCredits = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setShipmentCreditsLoading());

    const results: Record<string, any> = await makeRequestToApiGateway(
      'shipmentGetV1ShipperShipment'
    );

    dispatch(setShipmentCreditsLoaded({ items: results.credits }));
  } catch (error) {
    dispatch(setShipmentCreditsError({ error: error.message }));
  }
};
