import { manageDomainError, manageDomainLoaded, manageDomainLoading } from './stateUtils';
import {
  type TListShipmentsResponseV1,
  type TShipmentCommentV1,
  type TShipmentDetailsV1,
  type TShipmentDocumentToUploadV1,
} from '@truxweb/schemas';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TReducerLoading } from '../types';

type TShipmentCredit = {
  id: number;
  dateExpires: string;
};
export type TShipmentCreditData = TReducerLoading<TShipmentCredit[]>;
export type TShipmentTagsData = TReducerLoading<string[]>;

export type TShipmentListData = TReducerLoading<TListShipmentsResponseV1[]>;
export type TShipmentDetails = TReducerLoading<TShipmentDetailsV1>;
export type TShipmentComments = TReducerLoading<TShipmentCommentV1[]>;
export type TShipmentDocumentTypes = TReducerLoading<TShipmentDocumentToUploadV1[]>;

export type TShipmentReducer = {
  tags: TShipmentTagsData;
  credits: TShipmentCreditData;
  shipments: {
    details: {
      [ref: string]: TShipmentDetails;
    };
    list: TShipmentListData;
  };
};

export const shipmentSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.shipment,
      };
    },
  },
  initialState: {
    credits: {},
    shipments: {},
    tags: {},
  } as TShipmentReducer,
  name: 'shipment',
  reducers: {
    setShipmentCreditsError: (state, { payload: { error } }) => {
      return { ...state, credits: manageDomainError(state.credits, error) };
    },
    setShipmentCreditsLoaded: (state, { payload: { items } }) => {
      return { ...state, credits: manageDomainLoaded(state.credits, items) };
    },
    setShipmentCreditsLoading: (state) => {
      return { ...state, credits: manageDomainLoading(state.credits) };
    },
    setShipmentDetailsData: (state, { payload: { items } }) => {
      const detailsData = {};

      items.forEach((item: TShipmentDetailsV1) => {
        const {
          shipment: { shipmentRef },
        } = item;

        detailsData[shipmentRef] = manageDomainLoaded(
          state.shipments?.details?.[shipmentRef],
          item
        );
      });

      return {
        ...state,
        shipments: {
          ...state.shipments,
          details: {
            ...(state.shipments?.details || {}),
            ...detailsData,
          },
        },
      };
    },
    setShipmentDetailsError: (state, { payload: { error, shipmentRefs } }) => {
      const detailsData = {};

      shipmentRefs.forEach((shipmentRef: string) => {
        detailsData[shipmentRef] = manageDomainError(
          state.shipments?.details?.[shipmentRef],
          error
        );
      });
      return {
        ...state,
        shipments: {
          ...state.shipments,
          details: {
            ...(state.shipments?.details || {}),
            ...detailsData,
          },
        },
      };
    },
    setShipmentDetailsLoading: (state, { payload: { shipmentRefs } }) => {
      const detailsData = {};

      shipmentRefs.forEach((shipmentRef: string) => {
        detailsData[shipmentRef] = manageDomainLoading(state.shipments?.details?.[shipmentRef]);
      });
      return {
        ...state,
        shipments: {
          ...state.shipments,
          details: {
            ...(state.shipments?.details || {}),
            ...detailsData,
          },
        },
      };
    },

    setShipmentListData: (state, { payload: { items, rowCount } }) => {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          list: manageDomainLoaded(state.shipments.list, items, false, rowCount),
        },
      };
    },
    setShipmentListError: (state, { payload: { error } }) => {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          list: manageDomainError(state.shipments.list, error),
        },
      };
    },
    setShipmentListLoading: (state) => {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          list: manageDomainLoading(state.shipments.list),
        },
      };
    },
    setShipmentTagsError: (state, { payload: { error } }) => {
      return { ...state, tags: manageDomainError(state.tags, error) };
    },
    setShipmentTagsLoaded: (state, { payload: { items } }) => {
      return { ...state, tags: manageDomainLoaded(state.tags, items) };
    },
    setShipmentTagsLoading: (state) => {
      return { ...state, tags: manageDomainLoading(state.tags) };
    },
  },
});

export const {
  actions: {
    setShipmentCreditsError,
    setShipmentCreditsLoaded,
    setShipmentCreditsLoading,
    setShipmentDetailsData,
    setShipmentDetailsError,
    setShipmentDetailsLoading,
    setShipmentListData,
    setShipmentListError,
    setShipmentListLoading,
    setShipmentTagsError,
    setShipmentTagsLoaded,
    setShipmentTagsLoading,
  },
} = shipmentSlice;
