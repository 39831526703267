// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruckloadTypeV1 = exports.RangeUnitOfMeasureV1 = exports.PriceTypeV1 = exports.CurrencyV1 = exports.PaymentMethodV1 = void 0;
var PaymentMethodV1;
(function (PaymentMethodV1) {
    PaymentMethodV1["BankAccount"] = "BankAccount";
    PaymentMethodV1["CreditCard"] = "CreditCard";
    PaymentMethodV1["StandardInvoice"] = "StandardInvoice";
})(PaymentMethodV1 || (exports.PaymentMethodV1 = PaymentMethodV1 = {}));
var CurrencyV1;
(function (CurrencyV1) {
    CurrencyV1["CAD"] = "CAD";
    CurrencyV1["USD"] = "USD";
})(CurrencyV1 || (exports.CurrencyV1 = CurrencyV1 = {}));
var PriceTypeV1;
(function (PriceTypeV1) {
    PriceTypeV1["CURRENCY"] = "CURRENCY";
    PriceTypeV1["PERCENT"] = "PERCENT";
})(PriceTypeV1 || (exports.PriceTypeV1 = PriceTypeV1 = {}));
var RangeUnitOfMeasureV1;
(function (RangeUnitOfMeasureV1) {
    RangeUnitOfMeasureV1["mi"] = "mi";
    RangeUnitOfMeasureV1["km"] = "km";
})(RangeUnitOfMeasureV1 || (exports.RangeUnitOfMeasureV1 = RangeUnitOfMeasureV1 = {}));
var TruckloadTypeV1;
(function (TruckloadTypeV1) {
    TruckloadTypeV1["FTL"] = "FTL";
    TruckloadTypeV1["LTL"] = "LTL";
})(TruckloadTypeV1 || (exports.TruckloadTypeV1 = TruckloadTypeV1 = {}));
