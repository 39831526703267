"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCaravanIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M491.52,399.343h-40.96v-266.24c-0.068-67.837-55.043-122.812-122.88-122.88h-204.8C55.043,10.291,0.068,65.267,0,133.103  v204.8c0.07,49.476,35.444,91.852,84.111,100.762c11.021,44.044,55.659,70.814,99.703,59.793  c28.851-7.219,51.553-29.459,59.365-58.155h248.34c11.311,0,20.48-9.169,20.48-20.48C512,408.512,502.831,399.343,491.52,399.343z   M40.96,174.063h92.16c5.655,0,10.24,4.585,10.24,10.24v20.48c0,5.655-4.585,10.24-10.24,10.24H40.96V174.063z M163.84,460.783  c-22.622,0-40.96-18.338-40.96-40.96c0-22.622,18.338-40.96,40.96-40.96s40.96,18.338,40.96,40.96  C204.8,442.445,186.462,460.783,163.84,460.783z M327.68,399.343h-61.44v-215.04c0-5.655,4.585-10.24,10.24-10.24h40.96  c5.655,0,10.24,4.585,10.24,10.24V399.343z M409.6,399.343h-40.96v-215.04c0-28.277-22.923-51.2-51.2-51.2h-40.96  c-28.277,0-51.2,22.923-51.2,51.2v181.391c-30.705-33.766-82.969-36.247-116.735-5.543c-10.923,9.932-18.985,22.61-23.349,36.713  c-26.207-7.644-44.228-31.663-44.237-58.962v-81.92h92.16c28.277,0,51.2-22.923,51.2-51.2v-20.48c0-28.277-22.923-51.2-51.2-51.2  H40.96c0-45.243,36.677-81.92,81.92-81.92h204.8c45.243,0,81.92,36.677,81.92,81.92V399.343z" })));
exports.default = TruxwebCaravanIcon;
