import { Box, Grid, Typography } from '@truxweb/ux';
import { Card } from '..';
import { EPageAlertStatusV1 } from '@truxweb/schemas';
import React from 'react';
import { useStyles } from './PageAlertCard.styles';
import { useTranslation } from 'next-i18next';

type TPageAlertCardProps = {
  children: string;
  status: EPageAlertStatusV1;
};

export const PageAlertCard = ({ children, status }: TPageAlertCardProps): JSX.Element => {
  const { t } = useTranslation();

  const classes = useStyles({ status });

  return (
    <Box mb={3}>
      <Card className={classes.displayPurposesContainer}>
        <Grid alignItems="center" className={classes.fullHeight} container spacing={2}>
          <Grid className={classes.status} item>
            <Typography className={classes.statusText} variant="h5">
              {t(`common:${status}`)}
            </Typography>
          </Grid>
          <Grid className={classes.fullHeight} item>
            <Typography className={classes.infoText} variant="h6">
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
