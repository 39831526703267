import { getErrorMessage } from '@truxweb/errors';
import { useAlerts } from '.';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const useErrorHandling = () => {
  const { addAlert } = useAlerts();
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  return useCallback(
    (err: unknown | null, message?: string) => {
      addAlert({
        message: message || getErrorMessage(err, t) || t('common:error'),
        severity: 'error',
      });
    },
    [t, addAlert]
  );
};
