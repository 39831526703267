// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAcctReturnTypeV1 = void 0;
var BankAcctReturnTypeV1;
(function (BankAcctReturnTypeV1) {
    BankAcctReturnTypeV1["Rejected"] = "Rejected";
    BankAcctReturnTypeV1["Returned"] = "Returned";
})(BankAcctReturnTypeV1 || (exports.BankAcctReturnTypeV1 = BankAcctReturnTypeV1 = {}));
