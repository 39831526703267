import { Box, Grid, Typography } from '@truxweb/ux';
import React, { useMemo } from 'react';
import Image from 'next/image';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const ShipmentCreditsMarketing = (): JSX.Element => {
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);

  const language = useMemo(() => {
    return transformI18nLocaleToLanguage(i18n.language);
  }, [i18n]);
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      style={{ maxWidth: 684, position: 'relative', zIndex: 2 }}
    >
      <Grid alignItems="center" container direction="row" item>
        <Grid item xs={6}>
          <Box mb={3}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
              {t('common:shipmentCreditsMarketing-HEADER')}
            </Typography>
          </Box>
          <Typography>{t('common:shipmentCreditsMarketing-P1')}</Typography>
          <br />
          <Typography>{t('common:shipmentCreditsMarketing-P2')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Image
            height={236}
            src={`/assets/images/marketing/shipmentsCreditsMarketing-${language}.png`}
            width={400}
          />
        </Grid>
      </Grid>
      <Grid item xs>
        <br />
        <Typography>{t('common:shipmentCreditsMarketing-P3')}</Typography>
        <br />
        <Typography>{t('common:shipmentCreditsMarketing-P4')}</Typography>
        <br />
        <Typography>{t('common:shipmentCreditsMarketing-P5')}</Typography>
      </Grid>
    </Grid>
  );
};
