"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        minHeight: '100vh',
        overflow: ({ isOpen }) => {
            return isOpen ? 'visible' : 'hidden';
        },
        paddingBottom: theme.spacing(4.5),
        paddingLeft: ({ isOpen }) => {
            return isOpen ? theme.spacing(14) : 0;
        },
        paddingRight: ({ isOpen }) => {
            return isOpen ? theme.spacing(4) : 0;
        },
        paddingTop: theme.spacing(6),
    },
    entered: {
        width: theme.spacing(45),
    },
    entering: {
        transition: theme.transitions.create(['width'], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeInOut,
        }),
        width: theme.spacing(45),
    },
    exited: {
        width: 0,
    },
    exiting: {
        transition: theme.transitions.create(['width'], {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.easeInOut,
        }),
        width: 0,
    },
    fixedSignout: {
        bottom: theme.spacing(5),
        position: 'fixed',
        width: theme.spacing(31),
    },
    grow: {
        flexGrow: 1,
    },
    listItemWrapper: {
        '&:hover': {
            // FIXME: Use correct colour
            backgroundColor: '#EDF8FF',
        },
        borderRadius: theme.spacing(0.8125),
        color: theme.palette.primary.dark,
        height: theme.spacing(6),
        marginBottom: theme.spacing(1.75),
        padding: theme.spacing(2),
        width: theme.spacing(28.75),
    },
    logoutIcon: {
        color: theme.palette.primary.light,
    },
    menuContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: `${theme.spacing(11)}.5vh`,
        position: 'fixed',
    },
    subItems: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        marginLeft: theme.spacing(1.25),
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        marginBottom: theme.spacing(5.125),
    },
}));
