"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = exports.useBtnStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useBtnStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        color: theme.palette.grey[400],
        left: `24px`,
        position: `relative`,
    },
}));
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    accessorialDetails: {
        alignItems: `center`,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        display: `flex`,
        height: theme.spacing(7),
        justifyContent: `left`,
        marginLeft: `0 0 0 ${theme.spacing(1.5)}px`,
        paddingLeft: theme.spacing(1.5),
    },
    accessorialLineItem: {
        borderRadius: `6.5px 6.5px`,
        height: theme.spacing(9),
        maxWidth: theme.spacing(80),
        minWidth: theme.spacing(45),
    },
    accessorialLineItemText: {
        color: theme.palette.grey[700],
        fontSize: '15px',
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: `nowrap`,
        width: `230px`,
    },
    accessorialSelect: {
        borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        minWidth: '100%',
    },
    characterCount: {
        color: theme.palette.primary.light,
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 80,
    },
    confirmationBox: {
        background: 'rgb(255, 210, 130)',
        borderRadius: `${theme.spacing(2)}px`,
    },
    container: {
        backgroundColor: `${theme.palette.grey[200]}`,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        overflowX: 'auto',
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 1,
    },
    content: {
        paddingBottom: theme.spacing(6),
        width: '100%',
    },
    contentWrapper: {
        backgroundColor: theme.palette.common.white,
        borderRadius: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
        padding: `0 ${theme.spacing(5)}px`,
        width: '100%',
    },
    deleteButton: {},
    deleteIcon: {},
    errorIcon: {
        color: theme.palette.error.main,
        left: `24px`,
        position: `relative`,
        top: `4px`,
    },
    grey: {
        color: theme.palette.grey[700],
    },
    iconWrapper: {
        margin: 0,
        padding: 0,
    },
    lineItemText: {
        fontWeight: 600,
    },
    link: {
        '&:hover': {
            color: theme.palette.success.main,
        },
        color: theme.palette.success.main,
        fontFamily: theme.typography.body1.fontFamily,
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    removeLineItemIcon: {
        fill: '#C4C4C4',
    },
    shipperNote: {
        borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        width: theme.spacing(54.25),
    },
    wrapper: {
        maxWidth: theme.spacing(125),
        position: 'relative',
    },
}));
