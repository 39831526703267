import { makeRequestToApiGateway } from '../../apiUtils';
import { TCompanyV1 } from '@truxweb/schemas';

export const getUserCompany = async (): Promise<TCompanyV1> => {
  const data = await makeRequestToApiGateway('userGetV1Company', null, {
    'Content-Type': 'application/json',
  });

  return data as TCompanyV1;
};
