// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentTransactionNotificationActionV1 = void 0;
var ShipmentTransactionNotificationActionV1;
(function (ShipmentTransactionNotificationActionV1) {
    ShipmentTransactionNotificationActionV1["SHIPPER_CC_TRANSACTION_SUCCESS"] = "SHIPPER_CC_TRANSACTION_SUCCESS";
    ShipmentTransactionNotificationActionV1["CARRIER_PAYMENT_ISSUED"] = "CARRIER_PAYMENT_ISSUED";
})(ShipmentTransactionNotificationActionV1 || (exports.ShipmentTransactionNotificationActionV1 = ShipmentTransactionNotificationActionV1 = {}));
