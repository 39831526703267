// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationZoneTypeV1 = void 0;
var LocationZoneTypeV1;
(function (LocationZoneTypeV1) {
    LocationZoneTypeV1["SERVICE"] = "SERVICE";
    LocationZoneTypeV1["EXCLUSION"] = "EXCLUSION";
})(LocationZoneTypeV1 || (exports.LocationZoneTypeV1 = LocationZoneTypeV1 = {}));
