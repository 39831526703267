"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountPayablesDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const tableColumns_1 = require("../../tableColumns");
const quote_utils_1 = require("@truxweb/quote-utils");
const hooks_1 = require("@truxweb/hooks");
const AccountPayablesDataGrid_styles_1 = require("./AccountPayablesDataGrid.styles");
const DEFAULT_ROW_HEIGHT = 48;
const DEFAULT_PAGE_SIZE = 10;
const ROW_COUNT_HEADER_FOOTTER_ADJ = 3.15;
const AccountPayablesDataGrid = ({ areFiltersEnabled, columnKeys, currentSort, data, defaultSort, filterMode, gapInterval, handleColumnVisibilityChange, handleFilterChange, handleRouteToShipment, handleSortingChange, isExportEnabled, isLoading, isLoadingPageData, language, lastLoadedDate, onPageChange, onPageSizeChange, pageSize, paginationMode, rowCount, rowHeight, selectedRows, sortingMode, t, }) => {
    const classes = (0, AccountPayablesDataGrid_styles_1.useStyles)();
    const theme = (0, ux_1.useTheme)();
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { areFiltersEnabled: areFiltersEnabled, isExportEnabled: isExportEnabled, isHeaderShown: true, isLazyLoading: isLoadingPageData, isLoading: isLoading, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, t: t }));
    };
    const dynamicPageSize = (0, hooks_1.useDynamicTableRowCount)({
        constraints: { lg: '1524px', xlg: '1824px' },
        defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
        isLoading: Boolean(isLoading),
        onPageSizeChange,
        tableRowSize: rowHeight || DEFAULT_ROW_HEIGHT,
        useMediaQuery: ux_1.useMediaQuery,
    });
    const [displayCols, activeSort, gridHeight, tableRowHeight] = (0, react_1.useMemo)(() => {
        const fallbackText = t('common:na');
        const heightAdj = gapInterval ? theme.spacing(gapInterval) : 0;
        const basicColumnDef = {
            classes,
            fallbackText,
            t,
        };
        const tableRowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
        const gridHeight = ((dynamicPageSize || DEFAULT_PAGE_SIZE) + ROW_COUNT_HEADER_FOOTTER_ADJ) * tableRowHeight;
        let activeSort = currentSort;
        const columns = [
            (0, tableColumns_1.shipmentComboShipmentRefColumn)(Object.assign(Object.assign({}, basicColumnDef), { canViewCarrierReferenceNumber: true, canViewShipperReferenceNumber: true, heightAdj, rowHeight: tableRowHeight, viewingUserType: schemas_1.EUserTypeV1.TRUXWEB })),
            (0, tableColumns_1.shipmentCarrierReferenceColumn)(Object.assign(Object.assign({}, basicColumnDef), { headerName: t('common:carrierReferenceNumber') })),
            (0, tableColumns_1.shipmentCarrierNameColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'customerCode', headerName: t('common:customerCode'), width: 150 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'batchId', headerName: t('common:batchId'), width: 150 })),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'transactionAmount', headerName: t('common:transactionAmount'), width: 100 })),
            (0, tableColumns_1.shipmentTextDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'deliveryDate', headerName: t('common:deliveryDate'), width: 100 })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'paymentTerms', headerName: t('common:paymentTerms'), width: 64 })),
            (0, tableColumns_1.shipmentTextDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'settlementDate', headerName: t('common:settlementDate'), width: 100 })),
        ];
        const fullColumns = columns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        const availableCols = [];
        const displayCols = fullColumns.map((col) => {
            availableCols.push(col.field);
            if (col.disableColumnMenu)
                return col;
            if (!columnKeys) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) });
        });
        if ((activeSort === null || activeSort === void 0 ? void 0 : activeSort.length) && !availableCols.includes(activeSort[0].field)) {
            activeSort = defaultSort;
        }
        return [displayCols, activeSort, gridHeight, tableRowHeight];
    }, [
        dynamicPageSize,
        defaultSort,
        currentSort,
        columnKeys,
        rowHeight,
        classes,
        t,
        theme,
        gapInterval,
    ]);
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, currentSort: activeSort, data: data || [], defaultSort: defaultSort, filterMode: filterMode, gridHeight: gridHeight, handleCellClick: ({ field, row }) => {
            if (field !== 'action') {
                handleRouteToShipment(row.shipmentRef);
            }
        }, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: dynamicPageSize || DEFAULT_PAGE_SIZE, paginationMode: paginationMode, rowCount: rowCount, rowHeight: tableRowHeight, selectionModel: selectedRows, shouldDisableMultipleSelection: true, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.AccountPayablesDataGrid = AccountPayablesDataGrid;
