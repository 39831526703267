"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBasketballIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M256.07-0.047C114.467-0.047-0.326,114.746-0.326,256.349S114.467,512.744,256.07,512.744s256.395-114.792,256.395-256.395  S397.673-0.047,256.07-0.047z M468.117,233.429l-0.107-0.085c-41.298-4.284-80.429-20.604-112.533-46.933l65.493-65.493  C447.356,153.001,463.748,192.12,468.117,233.429z M390.72,90.731l-65.408,65.6c-26.338-32.099-42.659-71.232-46.933-112.533  C319.605,48.138,358.659,64.453,390.72,90.731z M235.797,43.691c4.468,52.666,25.218,102.636,59.371,142.976L256,225.835  L121.088,90.923C153.772,64.114,193.713,47.669,235.797,43.691z M90.913,121.1l-0.097-0.097l0.107,0.085  C90.919,121.092,90.916,121.096,90.913,121.1L225.835,256l-39.189,39.189c-40.316-34.167-90.275-54.926-142.933-59.392  C47.683,193.72,64.118,153.784,90.913,121.1z M43.883,278.571v-0.085c41.301,4.274,80.434,20.595,112.533,46.933l-65.493,65.493  C64.607,358.863,48.254,319.808,43.883,278.571z M121.173,421.077l65.408-65.493c26.338,32.099,42.659,71.232,46.933,112.533  C192.277,463.746,153.222,447.393,121.173,421.077z M276.203,468.309c-4.461-52.666-25.221-102.633-59.392-142.955L256,286.165  l134.912,134.912C358.233,447.895,318.29,464.341,276.203,468.309z M421.035,390.912L286.165,256l39.168-39.168  c40.328,34.145,90.282,54.895,142.933,59.371C464.289,318.287,447.843,358.228,421.035,390.912z" })));
exports.default = TruxwebBasketballIcon;
