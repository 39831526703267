import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    '&:hover': {
      color: 'unset',
    },

    color: theme.palette.common.white,
    fontFamily: 'Poppins',
    margin: '0 0 0 0',
    padding: '0 0 0 0 !important',
    textDecoration: 'none',
  },
  divider: {
    backgroundColor: '#fff',
    border: 0,
    height: 1,
  },
  legal: {
    color: '#fff',
    fontSize: theme.spacing(1.5),
    textAlign: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  text: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: `${theme.palette.common.white} !important`,
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: '21px',
  },
}));
