"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBellSchoolIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m10 8a2 2 0 1 1 -2 2 2 2 0 0 1 2-2zm14 2a14.27 14.27 0 0 1 -11.484 13.952 2.95 2.95 0 0 1 -2.431-.646 3.013 3.013 0 0 1 -1.085-2.316v-1.041a10 10 0 1 1 9.382-15.394 1 1 0 1 1 -1.682 1.091 8 8 0 1 0 0 8.708 1 1 0 1 1 1.677 1.091 9.959 9.959 0 0 1 -7.377 4.492v1.053a1.016 1.016 0 0 0 .367.781.948.948 0 0 0 .791.213 12.192 12.192 0 0 0 9.47-9.053 2.955 2.955 0 0 1 -.628.069 3 3 0 1 1 3-3zm-2 0a1 1 0 1 0 -1 1 1 1 0 0 0 1-1z" })));
exports.default = TruxwebBellSchoolIcon;
