"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentDashboardOrderSummary = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const utils_1 = require("../../utils");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_2 = require("@truxweb/utils");
const ShipmentDashboardOrderSummary_styles_1 = require("./ShipmentDashboardOrderSummary.styles");
const ADDITIONAL_CHARGES_TYPES = Object.values(schemas_1.EShipmentAdditionalChargeTypeV1);
const getAdditionalChargesToDisplay = (companyType, additionalTruxwebShipper, additionalTruxwebCarrier) => {
    return companyType === schemas_1.EUserTypeV1.CARRIER ? additionalTruxwebCarrier : additionalTruxwebShipper;
};
const ShipmentDashboardOrderSummary = ({ charges, companyType, handleRouteToPage, language, shipmentDetails, subtotal, t, total, }) => {
    const { accessorial, equipment } = shipmentDetails;
    const classes = (0, ShipmentDashboardOrderSummary_styles_1.useStyles)();
    const [feeTypeExplanationShown, setFeeTypeExplanationShown] = (0, react_1.useState)([]);
    const handleToggleFeeExplanation = (0, react_1.useCallback)((feeType) => {
        setFeeTypeExplanationShown((0, utils_2.toggleListValue)(feeTypeExplanationShown, feeType));
    }, [feeTypeExplanationShown, setFeeTypeExplanationShown]);
    const { accessorialPricing, additional, additionalTruxwebCarrier, additionalTruxwebShipper, adjustments, creditCardSurcharge, equipmentPricing, fuelSurcharge, serviceFees, taxes, truxweb, truxwebAdjustments, truxwebSubtotal, truxwebTotal, } = (0, react_1.useMemo)(() => {
        return (0, utils_1.groupShipmentCharges)(charges, companyType);
    }, [charges, companyType]);
    const truxwebAdditionalCharges = getAdditionalChargesToDisplay(companyType, additionalTruxwebShipper, additionalTruxwebCarrier);
    const renderPriceValue = (0, react_1.useCallback)((displayValue, value) => {
        if (!parseFloat(`${value}`)) {
            return t('common:includedAbbr');
        }
        return displayValue;
    }, [t]);
    const creditCardSurchargeDisplay = Boolean(creditCardSurcharge.length) ? (react_1.default.createElement(__1.OrderPriceLine, { classes: classes, key: 'CREDIT_CARD_SURCHARGE', language: language, price: creditCardSurcharge[0].charge, t: t, title: t(`common:charge-CREDIT_CARD_SURCHARGE`) })) : undefined;
    if (companyType === schemas_1.EUserTypeV1.TRUXWEB) {
        return (react_1.default.createElement(__1.ShipmentDashboardTruxwebOrderSummary, { additionalTruxwebCarrier: additionalTruxwebCarrier, additionalTruxwebShipper: additionalTruxwebShipper, classes: classes, creditCardSurchargeDisplay: creditCardSurchargeDisplay, feeTypeExplanationShown: feeTypeExplanationShown, handleRouteToPage: handleRouteToPage, handleToggleFeeExplanation: handleToggleFeeExplanation, language: language, t: t, taxes: taxes, truxweb: truxweb, truxwebAdjustments: truxwebAdjustments, truxwebSubtotal: truxwebSubtotal, truxwebTotal: truxwebTotal }));
    }
    return (react_1.default.createElement(ux_1.Box, { style: { position: 'relative' } },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(__1.OrderSummaryPriceGroups, { classes: classes, getCode: (equip) => {
                        return equip.equipmentCode;
                    }, getLineItemTitle: (equip) => {
                        return t(`common:${companyType}-equipment-${equip.equipmentCode}`);
                    }, getPrice: (equip) => {
                        return equipmentPricing[equip.equipmentId];
                    }, items: equipment, language: language, section: "equipment", t: t, title: t('common:freightCost'), validateLineItem: (equip) => {
                        return Boolean(equip && equipmentPricing[equip.equipmentId]);
                    } }),
                react_1.default.createElement(ux_1.Grid, { item: true }, fuelSurcharge.map((fee, index) => {
                    return (react_1.default.createElement(__1.OrderChargeLine, { charge: fee, chargeTitleLocale: `common:charge-${fee.chargeType}`, classes: classes, explanationId: fee.chargeType, feeTypeExplanationShown: feeTypeExplanationShown, handleRouteToPage: handleRouteToPage, handleToggleFeeExplanation: handleToggleFeeExplanation, hasExplanation: true, key: `${fee.recipient}-${fee.chargeTypeId}-${index}`, language: language, renderPriceValue: renderPriceValue, t: t }));
                })),
                accessorial.length !== 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(__1.OrderSummaryPriceGroups, { classes: classes, getCode: (accessorial) => {
                            return accessorial.accessorialCode;
                        }, getLineItemTitle: (accessorial) => {
                            return t(`common:${companyType}-accessorial-${accessorial.accessorialCode}`);
                        }, getPrice: (item) => {
                            const accessorial = item;
                            return accessorialPricing[accessorial.accessorialId] || accessorial.price;
                        }, items: accessorial, language: language, renderPriceValue: renderPriceValue, section: "accessorial", t: t, title: t('common:additionalServicesHeader'), validateLineItem: (item) => {
                            const accessorial = item;
                            const price = accessorialPricing[accessorial.accessorialId] || accessorial.price;
                            return Boolean(price);
                        } }))),
                adjustments.length > 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mb: 1 },
                            react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowHeader }, t('common:priceAdjustments')))),
                    adjustments.map((chargeAdjustment, idx) => {
                        const localeString = (0, utils_1.getChargeAdjustLocaleString)(chargeAdjustment, companyType);
                        return (react_1.default.createElement(__1.OrderChargeLine, { charge: chargeAdjustment, chargeTitleLocale: `common:${companyType}-${localeString}`, classes: classes, explanationId: `${companyType}-${localeString}`, feeTypeExplanationShown: feeTypeExplanationShown, handleRouteToPage: handleRouteToPage, handleToggleFeeExplanation: handleToggleFeeExplanation, hasExplanation: true, key: idx, language: language, renderPriceValue: renderPriceValue, t: t }));
                    }))),
                react_1.default.createElement(ux_1.Grid, { item: true }, serviceFees.map((fee, index) => {
                    const { chargeTitle, explanationDetails, explanationId, explanationTitle, isExplanationSuppressed, } = (0, utils_1.getServiceFeeTitle)(fee, language, t);
                    return (react_1.default.createElement(__1.OrderChargeLine, { charge: fee, classes: classes, explanation: Boolean(explanationDetails) && (react_1.default.createElement(ux_1.Typography, { color: "primaryLight" }, explanationDetails)), explanationId: explanationId, explanationTitle: explanationTitle, feeTypeExplanationShown: feeTypeExplanationShown, handleRouteToPage: handleRouteToPage, handleToggleFeeExplanation: handleToggleFeeExplanation, hasExplanation: !isExplanationSuppressed, key: `${fee.recipient}-${fee.chargeTypeId}-${index}`, language: language, renderPriceValue: renderPriceValue, shouldZeroOutNonBillableCharges: true, t: t, title: chargeTitle }));
                })),
                additional.length > 0 && (react_1.default.createElement(__1.OrderSummaryAdditionalCharges, { additionalChargesTypes: ADDITIONAL_CHARGES_TYPES, charges: additional, classes: classes, companyType: companyType, feeTypeExplanationShown: feeTypeExplanationShown, handleRouteToPage: handleRouteToPage, handleToggleFeeExplanation: handleToggleFeeExplanation, language: language, t: t, title: companyType === schemas_1.EUserTypeV1.SHIPPER
                        ? t('shipments:additionalCarrierCharges')
                        : t('shipments:additionalCharges') })),
                truxwebAdditionalCharges.length > 0 && (react_1.default.createElement(__1.OrderSummaryAdditionalCharges, { additionalChargesTypes: ADDITIONAL_CHARGES_TYPES, charges: truxwebAdditionalCharges, classes: classes, companyType: companyType, feeTypeExplanationShown: feeTypeExplanationShown, handleRouteToPage: handleRouteToPage, handleToggleFeeExplanation: handleToggleFeeExplanation, language: language, t: t, title: t('shipments:additionalTruxwebCharges') })),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Divider, null)),
                react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(ux_1.Box, { mb: 0.5 },
                            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                react_1.default.createElement(ux_1.Grid, { item: true },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.summaryRowTitle }, t(`common:subtotal`))),
                                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                                    react_1.default.createElement(__1.PriceDisplay, { language: language, price: subtotal })))))),
                react_1.default.createElement(ux_1.Grid, { item: true }, taxes.map((tax, index) => {
                    return (react_1.default.createElement(__1.OrderPriceLine, { classes: classes, key: `${tax.recipient}-${tax.chargeTypeId}-${index}`, language: language, price: tax.charge, t: t, title: t(`common:charge-${tax.chargeType}`) }));
                })),
                creditCardSurchargeDisplay)),
        react_1.default.createElement(ux_1.Box, { mt: 2 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { className: classes.totalRowItem, fontStyle: "semibold", variant: "bodyLarge" }, t('common:total'))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyLarge" },
                        react_1.default.createElement(__1.PriceDisplay, { className: classes.totalRowItem, language: language, price: total })))))));
};
exports.ShipmentDashboardOrderSummary = ShipmentDashboardOrderSummary;
