import { makeRequestToApiGateway } from '../../apiUtils';
import { TCompanyLocationV1 } from '@truxweb/schemas';

export const updateUserCompanyLocation = async (
  companyLocationId: number,
  data: TCompanyLocationV1
): Promise<void> => {
  const request = {
    id: companyLocationId,
    ...data,
  };

  await makeRequestToApiGateway(
    'userPatchV1CompanyLocation',
    null,
    {
      'Content-Type': 'application/json',
    },
    request
  );
};
