import { Box } from '@truxweb/ux';
import classnames from 'classnames';
import React from 'react';
import { TPageId } from '../../types';
import { useFullHeightStyles } from '../../styles';
import { useStyles } from './PageBackground.styles';

type TPageBackgroundProps = {
  className?: string;
  children: React.ReactElement | React.ReactElement[];
  pageId?: TPageId;
};

export const PageBackground = ({
  children,
  className,
  pageId,
}: TPageBackgroundProps): JSX.Element => {
  const globalStyles = useFullHeightStyles();
  const classes = useStyles();
  // FIXME: the intent here is that we can specify per page bacgrounds... this is not really implemented
  const pageClasses = pageId
    ? classnames(classes.content, className)
    : classnames(globalStyles.fullHeightContainer, classes.content, classes.container, className);
  return <Box className={pageClasses}>{children}</Box>;
};
