"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebEyeDropperIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m23.112 5.165a3.025 3.025 0 0 0 0-4.279 3.094 3.094 0 0 0 -4.276 0l-2.436 2.438c-1.012 1.013-3.123.789-4.579-.013a2.6 2.6 0 0 0 -3.057 4.144l1.416 1.416-8.155 8.154a3.455 3.455 0 0 0 -.593 4.129l-1.139 1.139a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l1.139-1.139a3.455 3.455 0 0 0 4.129-.593l8.154-8.155 1.416 1.416a2.6 2.6 0 0 0 4.144-3.057c-.8-1.456-1.025-3.568-.013-4.58zm-17.552 15.396a1.536 1.536 0 0 1 -2.121 0 1.5 1.5 0 0 1 0-2.121l8.155-8.154 2.121 2.121zm13.7-14.376c-1.759 1.759-1.5 4.832-.325 6.958a.578.578 0 0 1 -.119.679.621.621 0 0 1 -.859 0l-7.779-7.781a.612.612 0 0 1 .428-1.041.522.522 0 0 1 .251.063c2.126 1.17 5.2 1.434 6.958-.325l2.439-2.438a1.045 1.045 0 0 1 1.446 0 1.024 1.024 0 0 1 0 1.449z" })));
exports.default = TruxwebEyeDropperIcon;
