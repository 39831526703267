"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        minHeight: '100vh',
        paddingTop: theme.spacing(6),
    },
    subItems: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        marginLeft: 10,
    },
    title: {
        color: theme.palette.primary.dark,
    },
}));
