// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetTypeV1 = void 0;
var RateSheetTypeV1;
(function (RateSheetTypeV1) {
    RateSheetTypeV1["CWT"] = "CWT";
    RateSheetTypeV1["PALLET_WEIGHT"] = "PALLET_WEIGHT";
})(RateSheetTypeV1 || (exports.RateSheetTypeV1 = RateSheetTypeV1 = {}));
