import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import {
  type TCarrierShipmentQuoteRequestV1,
  type TQuoteCommentV1,
  type TShipperShipmentQuoteRequestV1,
} from '@truxweb/schemas';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TReducerLoading } from '../types';

export type TQuoteComments = TReducerLoading & {
  data: { comments: TQuoteCommentV1[]; commentUserData: any } | null;
};

export type TShipperQuoteRequest = TReducerLoading & {
  data: TShipperShipmentQuoteRequestV1;
};

export type TCarrierQuoteRequest = TReducerLoading & {
  data: TCarrierShipmentQuoteRequestV1;
};

export type TQuoteListLoading = TReducerLoading & {
  data: TShipperShipmentQuoteRequestV1[];
};

export type TCarrierQuoteList = TReducerLoading & {
  data: TCarrierShipmentQuoteRequestV1[];
};
export type TQuoteCreateLoading = TReducerLoading & {
  data: number | null;
};

export type TClientSideShipperQuoteList = Record<string, TShipperQuoteRequest>;

export interface IQuoteDataState {
  carrierQuoteList: TCarrierQuoteList;
  shipperQuoteList: TQuoteListLoading;
  shipperQuoteCreate: TQuoteCreateLoading;
  shipperQuoteData: TClientSideShipperQuoteList;
  quoteComments: { [id: string]: TQuoteComments };
  selectedCarrierQuote: Record<string, TShipperQuoteRequest>;
  selectedShipperQuote: Record<string, TShipperQuoteRequest>;
}

export const quoteSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.quotes,
      };
    },
  },
  initialState: {
    carrierQuoteList: { ...baseLoadingState, data: false },
    quoteComments: {},
    shipperQuoteCreate: { ...baseLoadingState, data: null },
    shipperQuoteData: {},
    shipperQuoteList: { ...baseLoadingState, data: false },
  } as IQuoteDataState,
  name: 'quote',
  reducers: {
    setCarrierQuoteLoading: (state, { payload: { id } }) => {
      return {
        ...state,
        selectedCarrierQuote: {
          [id]: manageDomainLoading(state?.selectedCarrierQuote?.[id] || { ...baseLoadingState }),
        },
      };
    },
    setCarrierQuotesData: (state, { payload: { item } }) => {
      return {
        ...state,
        selectedCarrierQuote: {
          [item.id]: manageDomainLoaded(
            state?.selectedCarrierQuote?.[item.id] || { ...baseLoadingState },
            item
          ),
        },
      };
    },
    setCarrierQuotesListError: (state, { payload: { error } }) => {
      return { ...state, carrierQuoteList: manageDomainError(state.carrierQuoteList, error) };
    },
    setCarrierQuotesListLoaded: (state, { payload: { isProgressiveLoading, items, rowCount } }) => {
      return {
        ...state,
        carrierQuoteList: manageDomainLoaded(
          state.carrierQuoteList,
          items,
          isProgressiveLoading,
          rowCount
        ),
      };
    },
    setCarrierQuotesListLoading: (state) => {
      return { ...state, carrierQuoteList: manageDomainLoading(state.carrierQuoteList) };
    },

    setQuoteCommentError: (state, { payload: { error, id } }) => {
      return {
        ...state,
        quoteComments: {
          ...state.quoteComments,
          [id]: manageDomainError(state.quoteComments[id] || { ...baseLoadingState }, error),
        },
      };
    },
    setQuoteCommentsData: (state, { payload: { data, id } }) => {
      return {
        ...state,
        quoteComments: {
          ...state.quoteComments,
          [id]: manageDomainLoaded(state.quoteComments[id] || { ...baseLoadingState }, data),
        },
      };
    },
    setQuoteCommentsLoading: (state, { payload: { id } }) => {
      return {
        ...state,
        quoteComments: {
          ...state.quoteComments,
          [id]: manageDomainLoading(state.quoteComments[id] || { ...baseLoadingState }),
        },
      };
    },
    setQuoteCreateLoading: (state, { payload: { messageId, newLoadingState } }) => {
      return {
        ...state,
        shipperQuoteCreate: {
          ...state.shipperQuoteCreate,
          data: messageId,
          isLoading: newLoadingState,
        },
      };
    },

    setShipperQuoteLoading: (state, { payload: { id } }) => {
      return {
        ...state,
        selectedShipperQuote: {
          [id]: manageDomainLoading(state?.selectedShipperQuote?.[id]) || { ...baseLoadingState },
        },
      };
    },
    setShipperQuotesData: (state, { payload: { item } }) => {
      return {
        ...state,
        selectedShipperQuote: {
          [item.id]: manageDomainLoaded(
            state?.selectedShipperQuote?.[item.id] || { ...baseLoadingState },
            item
          ),
        },
      };
    },
    setShipperQuotesListError: (state, { payload: { error } }) => {
      return { ...state, shipperQuoteList: manageDomainError(state.shipperQuoteList, error) };
    },
    setShipperQuotesListLoaded: (
      state,
      { payload: { isProgressiveLoading, items, rowCount, shouldLoadAllData } }
    ) => {
      if (shouldLoadAllData) {
        const shipperQuoteData: Record<string, TShipperQuoteRequest> = {};
        items.forEach((item: TShipperShipmentQuoteRequestV1) => {
          shipperQuoteData[item.id] = manageDomainLoaded(state.shipperQuoteData[item.id], item);
        });
        return {
          ...state,
          shipperQuoteData: {
            ...state.shipperQuoteData,
            ...shipperQuoteData,
          },
          shipperQuoteList: manageDomainLoaded(
            state.shipperQuoteList,
            items,
            isProgressiveLoading,
            rowCount
          ),
        };
      }

      return {
        ...state,
        shipperQuoteList: manageDomainLoaded(
          state.shipperQuoteList,
          items,
          isProgressiveLoading,
          rowCount
        ),
      };
    },
    setShipperQuotesListLoading: (state) => {
      return { ...state, shipperQuoteList: manageDomainLoading(state.shipperQuoteList) };
    },
  },
});

export const {
  actions: {
    setCarrierQuoteLoading,
    setCarrierQuotesData,
    setCarrierQuotesListError,
    setCarrierQuotesListLoaded,
    setCarrierQuotesListLoading,
    setQuoteCommentError,
    setQuoteCommentsData,
    setQuoteCommentsLoading,
    setQuoteCreateLoading,
    setShipperQuoteLoading,
    setShipperQuotesData,
    setShipperQuotesListError,
    setShipperQuotesListLoaded,
    setShipperQuotesListLoading,
  },
} = quoteSlice;
