import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  '.MuiInputBase-root:has(> input:-webkit-autofill)': {
    backgroundColor: 'blue',
  },
  align: {
    alignItems: 'center',
    display: 'flex',
  },
  checkbox: {
    padding: '0',
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    maxWidth: theme.spacing(62),
    padding: '4rem 1rem 1rem 1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(5),
      height: 'auto',
      padding: '1rem',
    },
  },
  fitContainer: {
    width: '100%',
  },
  fitContent: {
    width: 'fit-content',
  },
  gapContainer: {
    gap: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  greyFont: {
    color: `${theme.palette.grey[600]} !important`,
  },
  halfContainer: {
    width: '45%',
  },
  halfWidth: {
    width: '50%',
  },
  itemBox: {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    height: theme.spacing(5),
  },
  linkFont: {
    fontFamily: 'Roboto !important',
  },
  noMargins: {
    margin: 0,
  },
  primaryFont: {
    color: `${theme.palette.primary.light} !important`,
  },
  submitButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    display: 'flex',
    height: theme.spacing(5),
    justifySelf: 'end',
    padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px !important`,
  },
  submitContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      display: 'flex',
      justifyContent: 'end',
    },
  },
}));

export const useToggleButtonStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: 'black',
    fontWeight: 200,
    width: theme.spacing(24),
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
}));
