import { type AppState, baseLoadingState } from '../../stores';
import { type TCarrierShipmentDataRow } from '@truxweb/schemas';
import { type TFunction } from 'next-i18next';
import { transformShipmentsToCarrierShipmentDataRows } from '@truxweb/shipment-utils';
import { type TReduxListResponse } from '../../types';

export const selectCarrierShipmentListData = (
  t: TFunction
): ((state: AppState) => TReduxListResponse<TCarrierShipmentDataRow[]>) => {
  return (state: AppState) => {
    const loadingState = state?.shipment?.shipments.list || { ...baseLoadingState };
    if (!loadingState.hasLoaded) return loadingState;

    // If the list has loaded, return the list data matching the list type
    const response = state?.shipment?.shipments.list;

    const data = transformShipmentsToCarrierShipmentDataRows(response.data, t);

    return { ...loadingState, data };
  };
};
