import { makeStyles, Theme } from '@truxweb/ux';

type TResponsiveFooterProps = {
  footerPalette: 'light' | 'dark';
};

export const useStyles = makeStyles<Theme, TResponsiveFooterProps>((theme: Theme) => ({
  logoSize: {
    height: '100px',
    width: '300px',
  },
  wrapper: {
    background: ({ footerPalette }) => {
      return footerPalette === 'dark' ? theme.palette.primary.dark : theme.palette.common.white;
    },
    maxWidth: 1920,
    minWidth: 360,
    padding: '4%',
    width: '100%',
  },
}));
