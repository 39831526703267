import {
  AuthenticatedHeaderLinks,
  CarrierHeaderLinks,
  HeaderButton,
  LanguageSwitcher,
  PageLink,
  ShipperHeaderLinks,
  UnauthenticatedHeaderLinks,
} from '..';
import { Box, Grid } from '@truxweb/ux';
import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './DesktopHeader.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'shipments', 'notifications'];

export type TDesktopHeaderProps = {
  isScrolled: boolean;
  isTransparent: boolean;
  pageId?: TPageId;
};

export const DesktopHeader = ({
  isScrolled,
  isTransparent,
  pageId,
}: TDesktopHeaderProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles({ isScrolled });

  const headerClass = isTransparent && !isScrolled ? classes.container : classes.containerFilled;

  return (
    <Box className={classnames(headerClass, classes.content)} data-tempid={pageId}>
      <Grid
        alignItems="center"
        className={classes.header}
        container
        data-testid="DesktopHeader"
        justifyContent="space-between"
        style={{ paddingLeft: 16, paddingRight: 16 }}
      >
        <Grid item>
          <PageLink pageId={'home'}>
            <Image
              alt={t('common:appTitle')}
              data-testid="DesktopHeader-Logo"
              height={44}
              objectFit="scale-down"
              src={`/assets/images/logos/Truxweb_logo_Noir.svg`}
              unoptimized
              width={240}
            />
          </PageLink>
        </Grid>
        <Grid item>
          <UnauthenticatedHeaderLinks
            linkClass={classes.sectionLink}
            pageId={pageId}
            selectedLinkClass={classes.selectedSectionLink}
          />
          <Grid container>
            <Grid item>
              <CarrierHeaderLinks
                linkClass={classes.sectionLink}
                pageId={pageId}
                selectedLinkClass={classes.selectedSectionLink}
              />
            </Grid>

            <Grid item>
              <ShipperHeaderLinks
                linkClass={classes.sectionLink}
                pageId={pageId}
                selectedLinkClass={classes.selectedSectionLink}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.actions} item>
          <Grid alignItems="center" container direction="row" justifyContent="space-evenly">
            <Grid item>
              <AuthenticatedHeaderLinks iconClass={classes.link} />
            </Grid>
            <Grid item>
              <PageLink pageId={'login'}>
                <HeaderButton
                  text={t('common:logIn')}
                  type="login"
                  variant={isTransparent && !isScrolled ? null : 'midnight'}
                />
              </PageLink>
            </Grid>
            <Grid item>
              <LanguageSwitcher pageId={pageId} textClassName={classes.link} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
