"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    checkboxIcon: {
        fill: theme.palette.primary.light,
    },
    wrapper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        paddingRight: theme.spacing(8),
    },
    wrapperCondensed: {
        marginBottom: ({ isSmallVariant }) => (isSmallVariant ? 0 : theme.spacing(3)),
        marginTop: ({ isSmallVariant }) => (isSmallVariant ? 0 : theme.spacing(3)),
        paddingRight: ({ isSmallVariant }) => (isSmallVariant ? 0 : theme.spacing(3)),
    },
}));
