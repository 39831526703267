import { type AppState } from '../../stores';
import { type TLoadDefinitionV1 } from '@truxweb/schemas';

export const selectSearchLoad = (): ((state: AppState) =>
  | (TLoadDefinitionV1 & {
      isExistingSearch: boolean;
    })
  | null) => {
  return (state: AppState) => {
    return state?.search?.searchLoadData;
  };
};
