"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBasketballHoopIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,0H5A5.006,5.006,0,0,0,0,5v8a5.007,5.007,0,0,0,4.616,4.985L5,21.062V23a1,1,0,0,0,2,0V22H9v1a1,1,0,0,0,2,0V22h2v1a1,1,0,0,0,2,0V22h2v1a1,1,0,0,0,2,0V21.226c.008-.034.4-3.242.4-3.242A5.007,5.007,0,0,0,24,13V5A5.006,5.006,0,0,0,19,0ZM6.273,15.124A1,1,0,0,1,7.266,14H9v2H6.383ZM13,14v2H11V14Zm3.742,0a1,1,0,0,1,.992,1.121L17.627,16H15V14ZM6.883,20l-.25-2H9v2ZM11,20V18h2v2Zm4-2h2.384l-.244,2H15Zm7-5a3,3,0,0,1-2.349,2.928l.069-.566A3,3,0,0,0,16.742,12H7.266a3,3,0,0,0-2.977,3.372l.07.558A3,3,0,0,1,2,13V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3ZM18,8V9a1,1,0,0,1-2,0V8a1,1,0,0,0-1-1H9A1,1,0,0,0,8,8V9A1,1,0,0,1,6,9V8A3,3,0,0,1,9,5h6A3,3,0,0,1,18,8Z" })));
exports.default = TruxwebBasketballHoopIcon;
