// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionFeaturesV1 = exports.SubscriptionV1 = void 0;
var SubscriptionV1;
(function (SubscriptionV1) {
    SubscriptionV1["FREE"] = "FREE";
    SubscriptionV1["LEVEL_ONE"] = "LEVEL_ONE";
})(SubscriptionV1 || (exports.SubscriptionV1 = SubscriptionV1 = {}));
var SubscriptionFeaturesV1;
(function (SubscriptionFeaturesV1) {
    SubscriptionFeaturesV1["SAVE_LOAD_FROM_SEARCH"] = "SAVE_LOAD_FROM_SEARCH";
    SubscriptionFeaturesV1["VIEW_INSTANT_PRICING"] = "VIEW_INSTANT_PRICING";
})(SubscriptionFeaturesV1 || (exports.SubscriptionFeaturesV1 = SubscriptionFeaturesV1 = {}));
