import { useDispatch, useSelector } from 'react-redux';
import { fetchLoadParameters } from '../../../actions';
import { selectCompanyLoadParameters } from '../../../selectors';
import { type TFunction } from 'next-i18next';
import { type TLoadParametersV1 } from '@truxweb/schemas';
import { type TReducerLoading } from '../../../types';
import { useEffect } from 'react';

export const useLoadParameters = (
  shouldRefetch?: boolean,
  t?: TFunction
): TReducerLoading & { data: TLoadParametersV1[] } => {
  const dispatch = useDispatch();
  const loadParametersList = useSelector(selectCompanyLoadParameters(t));

  useEffect(() => {
    if (
      (!loadParametersList.hasLoaded && !loadParametersList.isLoading) ||
      (!loadParametersList.isLoading && shouldRefetch)
    ) {
      // NOTE: Always force a fresh list of locations when refetching
      dispatch(fetchLoadParameters(true));
    }
  }, [loadParametersList, dispatch, shouldRefetch]);

  return loadParametersList;
};
