"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    cellPointer: {
        cursor: 'pointer',
    },
    tag: {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} `,
    },
    tagsContainer: {
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        lineHeight: '24px',
        padding: '0.5rem',
        width: '100%',
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
}));
