"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSelectTopResults = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const SearchSelectTopResults = ({ className, dataSet, isChecked, onChange, quoteMaxSelection, t, }) => {
    const { apiRef } = (0, ux_1.useGridSlotComponentProps)();
    const handleChange = (0, react_1.useCallback)(() => {
        const toggleSelectedRows = (isSelected) => {
            const ids = apiRef.current.getAllRowIds().filter((_data, index) => {
                return index < quoteMaxSelection;
            });
            apiRef.current.selectRows(ids, isSelected);
        };
        isChecked ? toggleSelectedRows(false) : toggleSelectedRows(true);
        // If this was previously in a selected state, deselect the items
        const selections = isChecked
            ? []
            : dataSet.length > quoteMaxSelection
                ? dataSet.slice(0, quoteMaxSelection)
                : dataSet;
        onChange(selections);
    }, [apiRef, dataSet, isChecked, onChange, quoteMaxSelection]);
    return (react_1.default.createElement(ux_1.FormControl, null,
        react_1.default.createElement(ux_1.FormControlLabel, { className: className, control: react_1.default.createElement(ux_1.Checkbox, { checked: isChecked, color: "primaryLight", hasDefaultBorderColor: true, onChange: handleChange, size: 'small' }), label: t('common:selectTopResults', { count: quoteMaxSelection }), name: "topResults" })));
};
exports.SearchSelectTopResults = SearchSelectTopResults;
