"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentShipperDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const tableColumns_1 = require("../../tableColumns");
const utils_1 = require("@truxweb/utils");
const quote_utils_1 = require("@truxweb/quote-utils");
const hooks_1 = require("@truxweb/hooks");
const ShipmentShipperDataGrid_styles_1 = require("./ShipmentShipperDataGrid.styles");
const DEFAULT_ROW_HEIGHT = 115;
const DEFAULT_PAGE_SIZE = 10;
const ROW_COUNT_HEADER_FOOTTER_ADJ = 1.85;
const ShipmentShipperDataGrid = ({ accessorial, areFiltersEnabled, availableTags, columnKeys, currentSort, data, defaultSort, equipment, filterMode, gapInterval, handleColumnVisibilityChange, handleFilterChange, handleRouteToShipment, handleSortingChange, isActionsOverlayEnabled, isExportEnabled, isLoading, isLoadingPageData, language, lastLoadedDate, onPageChange, onPageSizeChange, pageSize, paginationMode, renderActionsContent, rowCount, rowHeight, selectedRows, sortingMode, t, }) => {
    const classes = (0, ShipmentShipperDataGrid_styles_1.useStyles)();
    const fallbackText = t('common:na');
    const theme = (0, ux_1.useTheme)();
    const heightAdj = gapInterval ? theme.spacing(gapInterval) : 0;
    const dynamicPageSize = (0, hooks_1.useDynamicTableRowCount)({
        constraints: { lg: '1524px', xlg: '1824px' },
        defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
        isLoading: Boolean(isLoading),
        onPageSizeChange,
        useMediaQuery: ux_1.useMediaQuery,
    });
    const [displayCols, activeSort, gridHeight, tableRowHeight] = (0, react_1.useMemo)(() => {
        const basicColumnDef = {
            classes,
            fallbackText,
            t,
        };
        const tableRowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
        //Column definitions
        const columns = [
            (0, tableColumns_1.shipmentComboShipmentRefColumn)(Object.assign(Object.assign({}, basicColumnDef), { canViewCarrierReferenceNumber: false, canViewShipperReferenceNumber: true, heightAdj, rowHeight: tableRowHeight, viewingUserType: schemas_1.EUserTypeV1.SHIPPER })),
            (0, tableColumns_1.shipmentShipperStatusTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { type: 'singleSelect', valueOptions: Object.keys(schemas_1.EShipmentDisplayStatusV1).map((status) => t(`shipments:shipmentStatus-${status}`)) })),
            (0, tableColumns_1.shipmentDelayedColumn)(Object.assign({}, basicColumnDef)),
            (0, tableColumns_1.shipmentActivityColumn)(Object.assign(Object.assign({}, basicColumnDef), { filterable: false, handleRouteToShipment })),
            (0, tableColumns_1.shipmentCarrierNameColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentStaticStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'price', headerName: t('common:price'), type: 'number', width: 100 })),
            (0, tableColumns_1.shipmentPickUpDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentDeliveryDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'paymentMethod', getTextValue: (row) => {
                    return t(`common:paymentMethod-${row.paymentMethod}`);
                }, headerName: t('shipments:paymentMethod'), type: 'singleSelect', valueOptions: Object.values(schemas_1.EPaymentMethodV1).map((code) => t(`common:paymentMethod-${code}`)), width: 150 })),
            (0, tableColumns_1.shipmentShipperReferenceColumn)(Object.assign(Object.assign({}, basicColumnDef), { headerName: t('common:shipperReferenceNumber') })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipperQuoteReferenceNumber', headerName: t('common:shipperQuoteRef'), width: 150 })),
            (0, tableColumns_1.shipmentTypeColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentPalletType)(basicColumnDef),
            (0, tableColumns_1.shipmentOriginColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentOriginReferenceColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentLocationContactColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'originContact', headerName: t('common:locationOriginContact'), location: schemas_1.EShipmentLocationTypeV1.ORIGIN })),
            (0, tableColumns_1.shipmentDestinationColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentDestinationReferenceColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentLocationContactColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'destinationContact', headerName: t('common:locationDestinationContact'), location: schemas_1.EShipmentLocationTypeV1.DESTINATION })),
            (0, tableColumns_1.shipmentEquipmentColumn)(Object.assign(Object.assign({}, basicColumnDef), { type: 'singleSelect', valueOptions: equipment.map(({ code }) => t(`common:SHIPPER-equipment-${code}`)) })),
            (0, tableColumns_1.shipmentAccessorialsColumn)(Object.assign(Object.assign({}, basicColumnDef), { type: 'singleSelect', valueOptions: accessorial.map(({ code }) => {
                    return t(`common:SHIPPER-accessorial-${code}`);
                }) })),
            (0, tableColumns_1.shipmentTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'daysUntilShipperPayment', headerName: t('common:disbursementDate'), sortable: true, tooltip: (row) => {
                    return row.shipperPaymentDate !== fallbackText
                        ? (0, utils_1.formatDate)(row.shipperPaymentDate)
                        : row.shipperPaymentDate;
                }, type: 'number', valueGetter: (row) => {
                    return row.shipperPaymentDate !== fallbackText
                        ? new Date(row.shipperPaymentDate).getTime()
                        : 0;
                }, width: 150 })),
            (0, tableColumns_1.shipmentBookedDateColumn)(basicColumnDef),
            (0, tableColumns_1.shipmentDateUpdatedColumn)(basicColumnDef),
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags, field: 'tags', width: 150 })),
        ];
        const fullColumns = columns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        //Filter the Column definition to display only the columns that are needed
        const availableCols = [];
        const displayCols = fullColumns.map((col) => {
            availableCols.push(col.field);
            if (col.disableColumnMenu)
                return col;
            if (!columnKeys) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) });
        });
        let activeSort = currentSort;
        if ((activeSort === null || activeSort === void 0 ? void 0 : activeSort.length) && !availableCols.includes(activeSort[0].field)) {
            activeSort = defaultSort;
        }
        const gridHeight = ((dynamicPageSize || DEFAULT_PAGE_SIZE) + ROW_COUNT_HEADER_FOOTTER_ADJ) * tableRowHeight;
        return [displayCols, activeSort, gridHeight, tableRowHeight];
    }, [
        availableTags,
        equipment,
        accessorial,
        classes,
        columnKeys,
        currentSort,
        defaultSort,
        fallbackText,
        handleRouteToShipment,
        heightAdj,
        dynamicPageSize,
        rowHeight,
        t,
    ]);
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { areFiltersEnabled: areFiltersEnabled, isExportEnabled: isExportEnabled, isHeaderShown: true, isLazyLoading: isLoadingPageData, isLoading: isLoading, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, t: t }));
    };
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, currentSort: activeSort, data: data || [], defaultSort: defaultSort, filterMode: filterMode, gridHeight: gridHeight, handleCellClick: ({ field, row }) => {
            if (field !== 'action') {
                handleRouteToShipment(row.shipmentRef);
            }
        }, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isActionsOverlayEnabled: isActionsOverlayEnabled, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: dynamicPageSize || DEFAULT_PAGE_SIZE, paginationMode: paginationMode, renderActionsContent: renderActionsContent, rowCount: rowCount, rowHeight: tableRowHeight, selectionModel: selectedRows, shouldDisableMultipleSelection: true, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.ShipmentShipperDataGrid = ShipmentShipperDataGrid;
