"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    confirmAddress: {
        borderColor: `${theme.palette.primary.light} !important`,
    },
    container: {
        borderColor: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        borderStyle: 'solid',
        borderWidth: `3px`,
        padding: `${theme.spacing(3)}px`,
        width: theme.spacing(54.5),
    },
}));
