"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    header: {
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(2),
        fontWeight: 500,
        lineHeight: `${theme.spacing(3)}px`,
    },
}));
