"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGrapeIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22,10a4.006,4.006,0,0,0-7-2.618,3.984,3.984,0,0,0-1.967-1.23C13.389,1.618,16.927,1.205,17,3a1,1,0,0,0,0,2,2,2,0,0,0,2-2c-.3-3.828-5.018-3.987-6.99-.438C11.428,1.517,9.216-1.1,8.105.553A1,1,0,0,0,8.553,1.9c1.46.73,2.252,2.129,2.413,4.258A3.984,3.984,0,0,0,9,7.382a3.991,3.991,0,1,0-3.828,6.529,3.985,3.985,0,0,0,3,5,3.984,3.984,0,1,0,7.656,0,3.985,3.985,0,0,0,3-5A4,4,0,0,0,22,10ZM12,8a2,2,0,0,1,0,4A2,2,0,0,1,12,8ZM4,10a2,2,0,0,1,4,0A2,2,0,0,1,4,10Zm3,5a1.991,1.991,0,0,1,.5-1.3A3.991,3.991,0,0,0,9,12.618,3.991,3.991,0,0,0,10.505,13.7,1.994,1.994,0,1,1,7,15Zm5,7A2,2,0,0,1,10.5,18.7,3.991,3.991,0,0,0,12,17.618,3.991,3.991,0,0,0,13.505,18.7,2,2,0,0,1,12,22Zm3-5A2,2,0,0,1,13.5,13.7,3.991,3.991,0,0,0,15,12.618a3.991,3.991,0,0,0,1.5,1.086A2,2,0,0,1,15,17Zm3-5a2,2,0,0,1,0-4A2,2,0,0,1,18,12Z" })));
exports.default = TruxwebGrapeIcon;
