import { Box, Grid, Typography } from '@truxweb/ux';

import classnames from 'classnames';
import Image from 'next/image';
import { useStyles } from './TruxiiComingSoon.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const TruxiiComingSoon = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  return (
    <Box mt={12}>
      <Grid className={classes.container} container>
        <Grid item>
          <Image
            alt="truxii smile"
            height={114}
            src={`/assets/images/truxii/truxii-helmet.svg`}
            width={81}
          />
        </Grid>

        <Grid item>
          <Grid className={classes.title} container>
            <Typography variant="body2">
              <span className={classnames(classes.comingSoonPrompt, classes.comingSoonText)}>
                {t('common:comingSoonPromptOne')}
              </span>
            </Typography>
            <Typography variant="body2">
              <Grid
                alignItems="center"
                className={classnames(classes.comingSoonDescription, classes.comingSoonText)}
                container
                justifyContent="center"
                wrap="wrap"
              >
                <span>{t('common:comingSoonDescriptionOne')}</span>
                <span>{t('common:comingSoonDescriptionTwo')}</span>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
