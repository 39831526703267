"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    lineItemCost: {
        color: '#636364',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    lineItemDescription: {
        color: '#636364',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    potentialSavings: {
        color: theme.palette.secondary.light,
        display: 'block',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    savedPrice: {
        color: theme.palette.primary.light,
        fontWeight: 600,
    },
    subheader: {
        color: theme.palette.primary.light,
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '18px',
    },
    total: {
        color: '#636364',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
    },
}));
