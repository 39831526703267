// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyTypeV1 = void 0;
var PropertyTypeV1;
(function (PropertyTypeV1) {
    PropertyTypeV1["STRING"] = "STRING";
    PropertyTypeV1["DATE"] = "DATE";
    PropertyTypeV1["NUMBER"] = "NUMBER";
})(PropertyTypeV1 || (exports.PropertyTypeV1 = PropertyTypeV1 = {}));
