"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        flexWrap: 'nowrap',
    },
    delayedIndication: ({ statusColor }) => {
        return { background: statusColor };
    },
    dot: {
        borderRadius: '100%',
        height: theme.spacing(2),
        width: theme.spacing(2),
    },
    label: {
        lineHeight: theme.spacing(2),
    },
}));
