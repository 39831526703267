import { type AppThunk, setCarrierQuoteLoading, setCarrierQuotesData } from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { type TCarrierShipmentQuoteRequestV1 } from '@truxweb/schemas';

export const fetchReduxCarrierQuoteRequestById =
  (quoteId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCarrierQuoteLoading({ id: quoteId }));

      const results = await makeRequestToApiGateway('shipmentGetV1CarrierQuoteRequestById', {
        id: quoteId,
      });
      const { quoteRequest } = results as { quoteRequest: TCarrierShipmentQuoteRequestV1 };
      dispatch(setCarrierQuotesData({ item: quoteRequest }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };
