// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatusV1 = exports.LanguageV1 = void 0;
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var UserStatusV1;
(function (UserStatusV1) {
    UserStatusV1["Active"] = "ACTIVE";
    UserStatusV1["Inactive"] = "INACTIVE";
    UserStatusV1["Banned"] = "BANNED";
    UserStatusV1["Pending"] = "PENDING";
    UserStatusV1["Review"] = "REVIEW";
    UserStatusV1["Registered"] = "REGISTERED";
})(UserStatusV1 || (exports.UserStatusV1 = UserStatusV1 = {}));
