"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSkiJumpIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.757,12.266a3.976,3.976,0,0,1-2.067,2.257L1.418,23.908a1,1,0,0,1-.84-1.816l4.429-2.05C5.006,20.027,5,20.015,5,20V13.266A5.957,5.957,0,0,1,6.756,9.024L9.78,6H2A1,1,0,0,1,2,4h8.507a4.008,4.008,0,0,1,2.566.931l.948.792a3,3,0,0,1,.1,4.338l-3.427,3.427a1,1,0,0,1-1.414-1.414l3.427-3.428a1,1,0,0,0-.017-1.431l-.707-.589L8.17,10.438A3.973,3.973,0,0,0,7,13.266V19.12l13.849-6.411a2,2,0,0,0,.963-2.656,1,1,0,1,1,1.812-.846A3.975,3.975,0,0,1,23.757,12.266ZM16.5,4.982a2.5,2.5,0,1,0-2.5-2.5A2.5,2.5,0,0,0,16.5,4.982Z" })));
exports.default = TruxwebSkiJumpIcon;
