import {
  type AppThunk,
  setShipmentComments,
  setShipmentDetailsData,
  setShipmentDetailsError,
  setShipmentDetailsLoading,
} from '../../../stores';
import { EUserTypeV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../../apiUtils';
// import { refreshShipmentListItem } from '..';

export const getShipmentByRef =
  (userType: EUserTypeV1, shipmentRef: string): AppThunk =>
  async (dispatch) => {
    const endpoint =
      userType === EUserTypeV1.CARRIER
        ? 'shipmentGetV1CarrierShipmentByShipmentRef'
        : 'shipmentGetV1ShipperShipmentByShipmentRef';
    try {
      dispatch(setShipmentDetailsLoading({ shipmentRefs: [shipmentRef] }));

      const results: Record<string, any> = await makeRequestToApiGateway(
        endpoint,
        {
          shipmentRef,
        },
        {
          'Content-Type': 'application/json',
        }
      );

      dispatch(setShipmentDetailsData({ items: [results] }));
      // dispatch(refreshShipmentListItem(userType, shipmentRef));
      dispatch(setShipmentComments({ comments: results.comments, shipmentRef }));
    } catch (error) {
      dispatch(setShipmentDetailsError({ error: error.message, shipmentRefs: [shipmentRef] }));
    }
  };
