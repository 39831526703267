"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFlowerBouquetIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,11.1a3.044,3.044,0,0,0-2.664-3.055c.3-.545.736-1.813.654-5A2.094,2.094,0,0,0,21.141,1.4a2,2,0,0,0-1.733-.322A12.054,12.054,0,0,0,15.3,3.139,21.337,21.337,0,0,0,13.676.809a2.206,2.206,0,0,0-3.351,0A21.342,21.342,0,0,0,8.707,3.134a12.239,12.239,0,0,0-4.121-2.06A1.981,1.981,0,0,0,2.859,1.4,2.091,2.091,0,0,0,2.01,3.042c-.082,3.188.357,4.455.652,5A3.045,3.045,0,0,0,0,11.1c0,4.29,6.387,7.185,9.478,8.335a6.443,6.443,0,0,0-1.192,1.433,2.027,2.027,0,0,0-.012,2.06A2.128,2.128,0,0,0,10.118,24h3.764a2.128,2.128,0,0,0,1.844-1.076,2.027,2.027,0,0,0-.011-2.059A5.722,5.722,0,0,0,15.067,20H16a2,2,0,0,1,2,2,1,1,0,0,0,2,0,3.992,3.992,0,0,0-2.683-3.759C20.415,16.74,24,14.31,24,11.1ZM16.372,4.884A8.753,8.753,0,0,1,19.943,3s.046.019.048.093h0c.063,2.434-.214,3.72-.47,4.083a1.893,1.893,0,0,1-1.28.806,1.79,1.79,0,0,1-1.385-.388A1.9,1.9,0,0,1,16.372,4.884Zm-4.48-2.832a.138.138,0,0,1,.217,0,14.5,14.5,0,0,1,1.928,2.977.858.858,0,0,0,.069.1,3.757,3.757,0,0,0-.039,1.493,2.24,2.24,0,0,1-4.078.123,1.071,1.071,0,0,0-.071-.092A3.748,3.748,0,0,0,9.9,5.162a.188.188,0,0,0,.014-.024A13.779,13.779,0,0,1,11.892,2.052ZM4.009,3.092A.123.123,0,0,1,4.047,3a8.849,8.849,0,0,1,3.6,1.882A1.929,1.929,0,0,1,7.122,7.6a1.77,1.77,0,0,1-1.372.387,1.871,1.871,0,0,1-1.272-.805C4.222,6.814,3.946,5.527,4.009,3.092ZM13.882,22H10.118c-.062,0-.1-.046-.112-.116a5.836,5.836,0,0,1,1.963-1.843,5.647,5.647,0,0,1,2.019,1.894A.124.124,0,0,1,13.882,22Zm.794-4.791a8.491,8.491,0,0,0,1.311-3.046,1,1,0,1,0-1.974-.326,9.2,9.2,0,0,1-2,3.637,10.373,10.373,0,0,1-2.036-3.693,1,1,0,1,0-1.952.438,9.881,9.881,0,0,0,1.359,3.014C6.3,15.965,2,13.64,2,11.1A1.067,1.067,0,0,1,3.082,10H20.918A1.067,1.067,0,0,1,22,11.1C22,13.623,17.755,15.935,14.676,17.209Z" })));
exports.default = TruxwebFlowerBouquetIcon;
