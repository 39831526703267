"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddCreditCard = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const AddCreditCard_styles_1 = require("./AddCreditCard.styles");
const CARD_INPUT_IDS = {
    cardNumberInput: 'cardNumberInput',
    cvvInput: 'cvvInput',
    expiryInput: 'expiryInput',
};
const AddCreditCard = ({ areCheckboxesDisabled, bambora, control, t, watch, }) => {
    const classes = (0, AddCreditCard_styles_1.useStyles)();
    const [cardNumberInput, setCardNumberInput] = (0, react_1.useState)(null);
    const [cardCvvInput, setCardCvvInput] = (0, react_1.useState)(null);
    const [cardExpiryInput, setCardExpiryInput] = (0, react_1.useState)(null);
    // Mount the bambora element into the dom
    (0, react_1.useEffect)(() => {
        if (bambora) {
            if (!cardNumberInput) {
                const newCardNumberInput = bambora.create('card-number', {
                    brands: ['visa', 'mastercard', 'amex'],
                    placeholder: 'Card Number',
                    style: {
                        base: {
                            color: '#224882',
                            fontSize: '15px',
                        },
                        empty: {
                            color: '#42413E',
                        },
                    },
                });
                newCardNumberInput.mount(`#${CARD_INPUT_IDS.cardNumberInput}`);
                setCardNumberInput(newCardNumberInput);
            }
            if (!cardCvvInput) {
                const newCardCvvInput = bambora.create('cvv', {
                    placeholder: '- - -',
                    style: {
                        base: {
                            color: '#224882',
                            fontSize: '15px',
                        },
                        empty: {
                            color: '#42413E',
                        },
                    },
                });
                newCardCvvInput.mount(`#${CARD_INPUT_IDS.cvvInput}`);
                setCardCvvInput(newCardCvvInput);
            }
            if (!cardExpiryInput) {
                const newCardExpiryInput = bambora.create('expiry', {
                    placeholder: 'mm / yy',
                    style: {
                        base: {
                            color: '#224882',
                            fontSize: '15px',
                        },
                        empty: {
                            color: '#42413E',
                        },
                    },
                });
                newCardExpiryInput.mount(`#${CARD_INPUT_IDS.expiryInput}`);
                setCardExpiryInput(newCardExpiryInput);
            }
        }
        return () => {
            if (bambora) {
                cardNumberInput === null || cardNumberInput === void 0 ? void 0 : cardNumberInput.unmount();
                cardCvvInput === null || cardCvvInput === void 0 ? void 0 : cardCvvInput.unmount();
                cardExpiryInput === null || cardExpiryInput === void 0 ? void 0 : cardExpiryInput.unmount();
            }
        };
    }, [
        cardNumberInput,
        cardCvvInput,
        cardExpiryInput,
        bambora,
        setCardExpiryInput,
        setCardCvvInput,
        setCardNumberInput,
    ]);
    return (react_1.default.createElement(ux_1.Box, null,
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mb: 3 },
                    react_1.default.createElement(ux_1.Typography, { className: classes.cardHeader }, t('common:addCreditCard')))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mb: 3 },
                    react_1.default.createElement(__1.FormField, { control: control, defaultValue: '', id: "fullName", inputProps: {
                            required: false,
                        }, isRequired: true, label: t('common:nameOnCard'), t: t }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 1 },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.text }, t('common:cardNumber')))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement("div", { className: classes.cardInputContainer, id: CARD_INPUT_IDS.cardNumberInput })))))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement(ux_1.Box, { mb: 1 },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.text }, t('common:expiry')))),
                            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                react_1.default.createElement("div", { className: classes.cardInputContainer, id: CARD_INPUT_IDS.expiryInput })))),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
                        react_1.default.createElement(ux_1.Box, { ml: 1 },
                            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                    react_1.default.createElement(ux_1.Box, { mb: 1 },
                                        react_1.default.createElement(ux_1.Typography, { className: classes.text }, t('common:securityCode')))),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                                    react_1.default.createElement("div", { className: classes.cardInputContainer, id: CARD_INPUT_IDS.cvvInput }))))))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mt: 2.5 },
                    react_1.default.createElement(ux_1.Typography, { className: classes.cardHeader }, t('common:billingAddress')))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(__1.AddCardDetails, { areCheckboxesDisabled: areCheckboxesDisabled, areNamesDisabled: true, control: control, t: t, watch: watch })))));
};
exports.AddCreditCard = AddCreditCard;
