"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHockeyMaskIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M12,0C5.935,0,1,4.686,1,10.444,1,15.9,5.619,24,12,24s11-8.1,11-13.556C23,4.686,18.065,0,12,0Zm0,22c-4.968,0-9-6.992-9-11.556A8.746,8.746,0,0,1,12,2a8.746,8.746,0,0,1,9,8.444C21,15.008,16.968,22,12,22Zm7-11a2.292,2.292,0,0,1-2.5,2A2.292,2.292,0,0,1,14,11a2.292,2.292,0,0,1,2.5-2A2.292,2.292,0,0,1,19,11ZM7.5,13A2.292,2.292,0,0,1,5,11,2.292,2.292,0,0,1,7.5,9,2.292,2.292,0,0,1,10,11,2.292,2.292,0,0,1,7.5,13ZM11,16a1,1,0,1,1-1-1A1,1,0,0,1,11,16Zm4,0a1,1,0,1,1-1-1A1,1,0,0,1,15,16ZM9,5a1,1,0,1,1,1,1A1,1,0,0,1,9,5Zm4,3a1,1,0,1,1-1-1A1,1,0,0,1,13,8Zm0-3a1,1,0,1,1,1,1A1,1,0,0,1,13,5ZM11,19a1,1,0,1,1-1-1A1,1,0,0,1,11,19Zm4,0a1,1,0,1,1-1-1A1,1,0,0,1,15,19Z" })));
exports.default = TruxwebHockeyMaskIcon;
