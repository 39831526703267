"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPizzaSliceIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.2.8A2.716,2.716,0,0,0,20.3.176L3.155,6.929a4.976,4.976,0,0,0-3,5.824,15.855,15.855,0,0,0,11.094,11.1h0a4.983,4.983,0,0,0,5.825-3.009L23.832,3.672A2.71,2.71,0,0,0,23.2.8ZM11.746,21.911A13.8,13.8,0,0,1,2.09,12.254a2.949,2.949,0,0,1,1.265-3.18A11.987,11.987,0,0,0,14.924,20.646,2.947,2.947,0,0,1,11.746,21.911Zm4.027-3.227c-6.191-.1-10.355-4.265-10.456-10.457L21.01,2.047a.717.717,0,0,1,.771.17.7.7,0,0,1,.181.747L21.3,4.645c-5.758-1.98-8.942,6.209-3.413,8.668Zm2.847-7.232a2.654,2.654,0,0,1,1.943-4.935Z" }),
    React.createElement("path", { d: "M11,9a2,2,0,0,0,0,4A2,2,0,0,0,11,9Z" })));
exports.default = TruxwebPizzaSliceIcon;
