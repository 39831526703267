"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginLeft: '12px',
    },
    buttonText: {
        color: `${theme.palette.common.white} !important`,
    },
    cellPointer: {
        cursor: 'pointer',
    },
    chipResponseValue: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.spacing(1),
        color: `${theme.palette.common.white} !important`,
        padding: `0 ${theme.spacing(1)}px`,
        width: 'auto !important',
    },
    chipResponseValueAccepted: {
        backgroundColor: theme.palette.success.dark,
        borderRadius: theme.spacing(1),
        color: `${theme.palette.common.white} !important`,
        padding: `0 ${theme.spacing(1)}px`,
        width: 'auto !important',
    },
    chipResponseValueNeutral: {
        backgroundColor: theme.palette.grey[600],
        borderRadius: theme.spacing(1),
        color: `${theme.palette.common.white} !important`,
        padding: `0 ${theme.spacing(1)}px`,
        width: 'auto !important',
    },
    chipResponseValueRefused: {
        backgroundColor: theme.palette.error.dark,
        borderRadius: theme.spacing(1),
        color: `${theme.palette.common.white} !important`,
        padding: `0 ${theme.spacing(1)}px`,
        width: 'auto !important',
    },
    //add #EDF8FF lightBluecolor to theme
    chipWrapper: {
        '& .MuiChip-root': {
            backgroundColor: '#EDF8FF',
            borderRadius: '7px 7px',
            color: `${theme.palette.grey[600]} !important`,
            padding: '20px 10px 20px 10px',
        },
    },
    chipWrapperEmpty: {
        '& .MuiChip-root': {
            backgroundColor: 'transparent',
        },
    },
    date: {
        border: `0px !important`,
    },
    shipmentButton: {
        background: '#eaeaea',
    },
    shipmentIcon: {
        '& > g path': {
            fill: theme.palette.primary.light,
        },
        '& > rect': {
            fill: 'transparent',
        },
        height: '42px',
        width: '42px',
    },
    tag: {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} `,
    },
    tagsContainer: {
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        lineHeight: '24px',
        padding: '0.5rem',
        width: '100%',
    },
}));
