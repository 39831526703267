"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMapMarkerPlusIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M174.394,478.877c44.856,43.881,116.497,44.06,161.572,0.404l84.946-78.499c92.021-90.795,93.014-238.997,2.218-331.017  S184.133-23.249,92.113,67.547c-0.744,0.734-1.484,1.474-2.218,2.218C-1.508,161.182-1.496,309.387,89.921,400.79  c0.189,0.189,0.379,0.378,0.569,0.567L174.394,478.877z M119.983,99.854c74.626-74.955,195.885-75.222,270.84-0.596  s75.222,195.885,0.596,270.84l-84.776,78.35c-28.956,27.252-74.174,27.084-102.927-0.383l-83.733-77.371  c-74.79-74.79-74.791-196.048-0.002-270.839C119.982,99.855,119.983,99.854,119.983,99.854L119.983,99.854z M149.008,235.274  c0-11.752,9.527-21.279,21.279-21.279h63.837v-63.837c0-11.752,9.527-21.279,21.279-21.279c11.752,0,21.279,9.527,21.279,21.279  v63.837h63.837c11.752,0,21.279,9.527,21.279,21.279s-9.527,21.279-21.279,21.279h-63.837v63.837  c0,11.752-9.527,21.279-21.279,21.279c-11.752,0-21.279-9.527-21.279-21.279v-63.837h-63.837  C158.535,256.553,149.008,247.026,149.008,235.274z" })));
exports.default = TruxwebMapMarkerPlusIcon;
