"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumCheckboxGroup = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const EnumCheckboxGroup = ({ defaultValue, filter, hiddenOptions, i18nPrefix, isDisabled, onChange, renderLabel, sourceEnum, t, value, }) => {
    const handleChange = (0, react_1.useCallback)((event) => {
        onChange(Object.assign(Object.assign({}, value), { [event.target.name]: event.target.checked }));
    }, [value, onChange]);
    const values = Object.values(sourceEnum);
    return (react_1.default.createElement(ux_1.FormGroup, null, Object.keys(sourceEnum).map((key, index) => {
        if (hiddenOptions && hiddenOptions.includes(key))
            return null;
        if (filter && !filter(values[index])) {
            return null;
        }
        return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value[key] || (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue[key]), color: "primaryLight", disabled: isDisabled, name: key, onChange: handleChange, value: sourceEnum[key] }), key: key, label: react_1.default.createElement(ux_1.Box, { ml: 0.5 }, renderLabel ? renderLabel(key) : t(`common:${i18nPrefix || ''}${key}`)) }));
    })));
};
exports.EnumCheckboxGroup = EnumCheckboxGroup;
