"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentOrderSummaryChargeSet = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const utils_1 = require("../../utils");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const ShipmentOrderSummaryChargeSet = ({ advancedFieldTypeSelection, buildFieldDetails, chargeType, explanation, fieldManagement, fieldType, formControls, getChargeTitle, hasDescription, hasRecalculated, isAddingFeesDisabled, language, manageConsumedSelections, recipient, renderFieldTypeSelection, renderPriceValue, shouldAllowNegativeValues, t, title, }) => {
    const handleAddNew = (0, react_1.useCallback)(() => {
        fieldManagement.append((0, utils_1.generateNewOrderSummaryFieldForArray)(chargeType, recipient, buildFieldDetails ? buildFieldDetails() : undefined));
    }, [fieldManagement, chargeType, recipient, buildFieldDetails]);
    return (react_1.default.createElement(ux_1.Grid, { item: true },
        react_1.default.createElement(ux_1.Box, { mt: 2 },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mb: 1 },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between" },
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(__1.ShipmentOrderSummarySectionTitle, { explanation: explanation, title: title })),
                            !isAddingFeesDisabled && (react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.StandardButton, { onClick: handleAddNew },
                                    react_1.default.createElement(ux_1.AddCircleOutlineIcon, null))))))),
                !fieldManagement.fields.length && react_1.default.createElement(ux_1.Typography, null, t('common:noCharges')),
                fieldManagement.fields.map((field, index) => {
                    var _a, _b;
                    let currencyValue, currencyValueName, percentValue, percentValueName;
                    const isPercentDisabled = false;
                    let isCurrencyDisabled = false;
                    let name = `${fieldType}.${index}.charge.value`;
                    if (chargeType === schemas_1.EShipmentChargeTypeV1.TRUXWEB_FEE) {
                        if (((_a = field === null || field === void 0 ? void 0 : field.details) === null || _a === void 0 ? void 0 : _a.feeValueType) === schemas_1.EPriceTypeV1.PERCENT) {
                            isCurrencyDisabled = true;
                            currencyValue = field.charge.value;
                            currencyValueName = `${fieldType}.${index}.charge.value`;
                            percentValue = field.details.feeValue;
                            percentValueName = `${fieldType}.${index}.details.feeValue`;
                        }
                        else {
                            name = `${fieldType}.${index}.details.feeValue`;
                        }
                    }
                    return (react_1.default.createElement(__1.ShipmentOrderSummaryRow, { advancedFieldTypeSelection: advancedFieldTypeSelection, canRemove: field.isEditable, currencyValue: currencyValue, currencyValueName: currencyValueName, field: field, fieldManagement: fieldManagement, fieldType: fieldType, formControls: formControls, hasDescription: hasDescription, hasRecalculated: hasRecalculated, index: index, isCurrencyDisabled: isCurrencyDisabled, isPercentDisabled: isPercentDisabled, key: `${fieldType}-${field.id}`, language: language, manageConsumedSelections: manageConsumedSelections, name: name, percentValue: percentValue, percentValueName: percentValueName, renderFieldTypeSelection: renderFieldTypeSelection, renderPriceValue: renderPriceValue, shouldAllowNegativeValues: shouldAllowNegativeValues, t: t, title: getChargeTitle(field), valueType: ((_b = field === null || field === void 0 ? void 0 : field.details) === null || _b === void 0 ? void 0 : _b.feeValueType) || schemas_1.EPriceTypeV1.CURRENCY }));
                })))));
};
exports.ShipmentOrderSummaryChargeSet = ShipmentOrderSummaryChargeSet;
