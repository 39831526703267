import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentData } from '../../../actions';
import { selectEquipmentData } from '../../../selectors';
import { type TCompanyData } from '../../../stores';
import { useEffect } from 'react';

export const useReduxEquipmentData = (shouldRefetch?: boolean): TCompanyData => {
  const dispatch = useDispatch();
  const equipmentData = useSelector(selectEquipmentData);

  useEffect(() => {
    if (
      (!equipmentData.hasLoaded && !equipmentData.isLoading) ||
      (!equipmentData.isLoading && shouldRefetch)
    ) {
      dispatch(fetchEquipmentData());
    }
  }, [equipmentData, dispatch, shouldRefetch]);

  return equipmentData;
};
