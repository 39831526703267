import { EUserTypeV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const getShipmentShareLink = async (
  userType: EUserTypeV1,
  shipmentRef: string
): Promise<any> => {
  return await makeRequestToApiGateway(
    userType === EUserTypeV1.SHIPPER
      ? 'shipmentPatchV1ShipperShipmentByShipmentRef'
      : 'shipmentPatchV1CarrierShipmentByShipmentRef',
    {
      shipmentRef,
    },
    {
      'Content-Type': 'application/json',
    },
    {
      shouldShare: true,
    },
    { getShareLink: true }
  );
};
