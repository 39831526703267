"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSearchHeartIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M506.02,475.743L378.865,348.588c74.591-91.227,61.105-225.649-30.122-300.239S123.095-12.757,48.504,78.47  S-12.601,304.119,78.626,378.71c78.578,64.249,191.54,64.249,270.118,0l127.155,127.155c8.463,8.173,21.949,7.939,30.122-0.524  C513.993,497.086,513.993,483.998,506.02,475.743z M43.604,213.87c0-94.121,76.3-170.421,170.421-170.421  s170.421,76.3,170.421,170.421s-76.3,170.421-170.421,170.421C119.948,384.185,43.71,307.947,43.604,213.87z M256.631,128.659  c-15.914,0.072-31.183,6.301-42.605,17.383c-11.422-11.081-26.691-17.311-42.605-17.383c-36.433,1.262-64.997,31.73-63.908,68.168  c0,42.605,43.479,86.553,79.97,115.886c15.533,12.371,37.553,12.371,53.086,0c36.491-29.334,79.97-73.281,79.97-115.886  C321.627,160.389,293.064,129.921,256.631,128.659z M214.153,279.482c-39.495-31.741-64.036-63.482-64.036-82.654  c-1.063-12.906,8.416-24.282,21.303-25.563c12.886,1.281,22.366,12.657,21.303,25.563c0,11.765,9.537,21.303,21.303,21.303  c11.765,0,21.303-9.537,21.303-21.303c-1.063-12.906,8.416-24.282,21.303-25.563c12.886,1.281,22.366,12.657,21.303,25.563  C277.933,216,253.393,247.741,214.153,279.482L214.153,279.482z" })));
exports.default = TruxwebSearchHeartIcon;
