import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  container: {
    background: '#EDF8FF',
    borderRadius: theme.spacing(2),
    height: theme.spacing(37),
    padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
    width: '634px',
    // To let this elt be on top so that its content is clickable
    zIndex: 999,
  },
  primaryMessage: {
    color: theme.palette.primary.light,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  secondaryMessage: {
    color: theme.palette.grey[700],
    fontSize: '15px',
    fontWeight: 400,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
