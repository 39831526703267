"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMushroomIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M12,0C5.383,0,0,5.083,0,11.33c.034,4.28,4.67,4.22,7.728,2.6A30.508,30.508,0,0,0,7,19a5,5,0,0,0,10,0,30.508,30.508,0,0,0-.728-5.071c3.7,1.888,7.786,1.294,7.728-2.6C24,5.083,18.617,0,12,0Zm3,19a3,3,0,0,1-6,0,32.594,32.594,0,0,1,.936-5.77,9.548,9.548,0,0,1,4.128,0A32.594,32.594,0,0,1,15,19Zm5-6c-2.62-.38-4.632-2.09-8-2-3.366-.091-5.383,1.621-8,2-1.758,0-2-.8-2-1.67C2,6.186,6.486,2,12,2s10,4.186,10,9.33C22,12.413,21.681,13,20,13Z" })));
exports.default = TruxwebMushroomIcon;
