import { ELanguageV1, TCarrierProfileDisplayV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const getInternalCarrierProfile = async (
  companyCode: string,
  language: ELanguageV1
): Promise<TCarrierProfileDisplayV1 | null> => {
  const results = await makeRequestToApiGateway(
    'carrierGetV1CarrierProfileInternalByCompanyCodeByLanguage',
    { companyCode, language },
    {
      'Content-Type': 'application/json',
    }
  );
  return results as TCarrierProfileDisplayV1 | null;
};
