"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    disabledDropzoneText: {
        color: '#C4C4C4',
        fontSize: '14px',
        fontWeight: 400,
        padding: '8px 16px 0 16px',
        textAlign: 'center',
    },
    disabledDropzoneWrapper: {
        backgroundColor: '#F3F3F3',
        borderColor: '#C4C4C4',
        borderRadius: '8px',
        borderStyle: 'dashed',
        borderWidth: '2px',
        height: theme.spacing(11),
    },
    dragDropRoot: {
        backgroundColor: `#EDF8FF`,
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(1),
        borderWidth: '2px',
        minHeight: `0px`,
    },
    dragDropRootSelected: {
        backgroundColor: '#b6e2b8',
        borderColor: theme.palette.success.dark,
        borderRadius: theme.spacing(1),
        borderWidth: '2px',
        minHeight: `0px`,
    },
    dragDropzoneClass: {
        height: theme.spacing(11),
    },
    dragDropzoneParagraphClass: {
        fontFamily: 'Roboto',
        fontSize: `14px`,
        fontWeight: 400,
        lineHeight: `19px`,
        margin: `0px`,
        padding: '24px 16px 0 16px',
    },
    dragDropzoneSublabel: {
        fontSize: `12px`,
    },
    dragDropzoneSublabelDisabled: {
        color: '#C4C4C4',
        fontSize: `12px`,
    },
    loader: {
        height: `${theme.spacing(2)}px !important`,
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
        width: `${theme.spacing(2)}px !important`,
    },
    myDocumentEditableTag: {},
    myDocumentRounded: {
        border: '2px solid #C4C4C4',
        borderRadius: theme.spacing(1),
        marginBottom: '0.5rem',
        padding: '0.5rem 1rem',
    },
    notMyDocumentHeader: {
        color: theme.palette.primary.light,
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    success: {
        color: theme.palette.success.main,
    },
    tableTitle: {
        color: '#C4C4C4',
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
    uploadedDocument: {
        background: '#D5F2D6',
        borderRadius: `${theme.spacing(0.5)}px`,
        color: '#4CAF50',
        padding: '3px 12px',
    },
    willBeRequired: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: `${theme.spacing(0.5)}px`,
        padding: '3px 12px',
    },
}));
