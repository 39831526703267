// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DangerousGoodsV1 = void 0;
var DangerousGoodsV1;
(function (DangerousGoodsV1) {
    DangerousGoodsV1["CLASS1"] = "CLASS1";
    DangerousGoodsV1["CLASS2"] = "CLASS2";
    DangerousGoodsV1["CLASS3"] = "CLASS3";
    DangerousGoodsV1["CLASS4"] = "CLASS4";
    DangerousGoodsV1["CLASS5"] = "CLASS5";
    DangerousGoodsV1["CLASS6"] = "CLASS6";
    DangerousGoodsV1["CLASS7"] = "CLASS7";
    DangerousGoodsV1["CLASS8"] = "CLASS8";
    DangerousGoodsV1["CLASS9"] = "CLASS9";
})(DangerousGoodsV1 || (exports.DangerousGoodsV1 = DangerousGoodsV1 = {}));
