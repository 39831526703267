"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDartIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m20 0h-1.757a3.975 3.975 0 0 0 -2.829 1.172l-3.242 3.242a4.022 4.022 0 0 0 -1.172 2.828v3.463c-3.266 2.415-7.581 5.633-8.121 6.174a2.993 2.993 0 0 0 -.577 3.4l-2.009 2.014a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l2.007-2.007a2.965 2.965 0 0 0 3.407-.579c.541-.541 3.759-4.855 6.179-8.121h3.462a3.975 3.975 0 0 0 2.829-1.172l3.242-3.242a4.022 4.022 0 0 0 1.167-2.828v-1.758a4 4 0 0 0 -4-4zm-14.293 19.707a1.021 1.021 0 0 1 -1.414 0 1 1 0 0 1 0-1.414c.339-.336 3-2.343 5.948-4.534-2.192 2.947-4.199 5.61-4.534 5.948zm16.293-13.949a2.011 2.011 0 0 1 -.586 1.414l-3.242 3.242a1.99 1.99 0 0 1 -1.415.586h-1.986l.991-1.348 3.945-3.945a1 1 0 1 0 -1.414-1.414l-3.944 3.944-1.349.992v-1.987a2.011 2.011 0 0 1 .586-1.414l3.242-3.242a1.99 1.99 0 0 1 1.415-.586h1.757a2 2 0 0 1 2 2z" })));
exports.default = TruxwebDartIcon;
