"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    buttons: {
        '& > button': {
            '& > svg > path': {
                fill: theme.palette.primary.light,
            },
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
        },
        '& > button[disabled=""]': {
            '& > svg > path': {
                fill: 'lightgrey',
            },
            background: 'transparent',
            border: 'none',
            cursor: 'default',
        },
        textAlign: 'center',
    },
    item: {
        '&[aria-selected="true"]': {
            color: theme.palette.primary.light,
            fontWeight: 800,
        },
        cursor: 'pointer',
        listStyleType: 'none',
    },
}));
