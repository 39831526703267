import React, { useCallback } from 'react';
import { logout } from '../../actions';
import { useDispatch } from 'react-redux';
import { useLinkButtonStyles } from '../../styles/LinkButton.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export const AccountHeaderLogout = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useLinkButtonStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);

  const handleSignOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <button
      className={classes.button}
      data-testid={`AccountHeaderLogout-Button`}
      onClick={handleSignOut}
    >
      {t('common:logout')}
    </button>
  );
};
