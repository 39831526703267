"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebRaquetIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.923,2.067C18.758-1.1,13.077-.569,9.258,3.248A10.6,10.6,0,0,0,6.02,10.9a13.291,13.291,0,0,0,.157,1.433c.149,1.571.129,3.938-.884,4.951l-5,5A1,1,0,1,0,1.707,23.7l5-5c1.025-1.023,3.433-1.033,5-.879a13.448,13.448,0,0,0,1.62.163,10.667,10.667,0,0,0,7.412-3.249C24.56,10.915,25.089,5.234,21.923,2.067Zm-.517,8.154L19.924,8.739,21.967,6.7A7.444,7.444,0,0,1,21.406,10.221Zm-.1-5.689L18.51,7.325,16.584,5.4l2.753-2.753a4.838,4.838,0,0,1,1.172.835A4.831,4.831,0,0,1,21.3,4.532ZM13.037,15.98l-.3-.051,2.241-2.241,1.568,1.568A7.685,7.685,0,0,1,13.037,15.98ZM8.01,10.951a7.7,7.7,0,0,1,.724-3.5l1.487,1.486L8.039,11.117C8.029,11.058,8.019,11,8.01,10.951Zm2.532,4.342a4.718,4.718,0,0,1-1.9-1.952l2.992-2.993,1.926,1.926Zm2.507-6.359L15.17,6.813,17.1,8.739,14.975,10.86Zm4.079-6.907L15.17,3.985l-1.4-1.4a7.7,7.7,0,0,1,2.891-.6C16.823,1.988,16.973,2.016,17.128,2.027ZM11.949,3.592,13.756,5.4,11.635,7.52,9.793,5.679a9.381,9.381,0,0,1,2.156-2.087Zm-3.9,12.346c0-.014.005-.029.008-.043l.017.019.018.017Zm10.26-1.74-1.923-1.924,2.121-2.121L20.4,12.044a9.879,9.879,0,0,1-1.073,1.275A10.035,10.035,0,0,1,18.312,14.2Z" })));
exports.default = TruxwebRaquetIcon;
