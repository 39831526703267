"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestShipperDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const tableColumns_1 = require("../../tableColumns");
const react_1 = __importStar(require("react"));
const quote_utils_1 = require("@truxweb/quote-utils");
const __1 = require("..");
const hooks_1 = require("@truxweb/hooks");
const QuoteRequestShipperDataGrid_styles_1 = require("./QuoteRequestShipperDataGrid.styles");
const DEFAULT_ROW_HEIGHT = 115;
const DEFAULT_PAGE_SIZE = 10;
const QuoteRequestShipperDataGrid = ({ accessorial, areFiltersEnabled, availableTags, availableTruxwebTags, columnKeys, currentSort, data, defaultSort, equipment, excludeColumnKeys, filterMode, handleColumnVisibilityChange, handleExport, handleFilterChange, handleRouteToShipment, handleSearch, handleSortingChange, handleToggleAutoRefresh, isActionsOverlayEnabled, isExportEnabled, isHeaderShown, isLazyLoading, isLoading, isServerSideExport, language, lastLoadedDate, onPageChange, onPageSizeChange, onSelectQuote, pageSize, paginationMode, quoteLoadingId, renderActionsContent, renderCompanyLink, rowCount, selectedRows, shouldAutoRefresh, sortingMode, t, viewingUserType, }) => {
    const theme = (0, ux_1.useTheme)();
    const classes = (0, QuoteRequestShipperDataGrid_styles_1.useStyles)(theme);
    const dynamicPageSize = (0, hooks_1.useDynamicTableRowCount)({
        defaultPageSize: pageSize || DEFAULT_PAGE_SIZE,
        isLoading: Boolean(isLoading),
        onPageSizeChange,
        useMediaQuery: ux_1.useMediaQuery,
    });
    //Column definitions
    const [displayCols, activeSort, gridHeight] = (0, react_1.useMemo)(() => {
        const basicColumnDef = {
            classes,
            language,
            t,
        };
        const columns = [
            {
                align: 'left',
                field: 'isViewed',
                headerClassName: 'datagrid-header',
                headerName: t('common:isViewed'),
                renderCell: ({ value }) => {
                    return react_1.default.createElement(ux_1.DataGridUnreadIndicator, { hasBeenRead: Boolean(value) });
                },
                renderHeader: () => {
                    return ' ';
                },
                type: 'boolean',
                width: 25,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'quoteRef',
                headerAlign: 'center',
                headerName: t(`shipments:ref`, { shipmentRef: '' }),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.Grid, { container: true, spacing: 1, style: { lineHeight: 0 } },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, spacing: 1, style: { lineHeight: 0 } },
                            react_1.default.createElement(ux_1.Grid, { item: true, title: t('common:referenceNumber') },
                                react_1.default.createElement(ux_1.TruxwebWingsIcon, null)),
                            react_1.default.createElement(ux_1.Grid, { item: true, style: { maxWidth: '162px', minWidth: '162px', textAlign: 'left' } },
                                react_1.default.createElement(ux_1.DataGridColumnText, { text: row.quoteRef }))),
                        Boolean(row.shipperQuoteRef) && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, spacing: 1, style: { lineHeight: 0 } },
                            react_1.default.createElement(ux_1.Grid, { item: true, title: t('common:shipperQuoteRef') },
                                react_1.default.createElement(ux_1.TruxwebCubeIcon, null)),
                            react_1.default.createElement(ux_1.Grid, { item: true, style: { maxWidth: '162px', minWidth: '162px', textAlign: 'left' } },
                                react_1.default.createElement(ux_1.DataGridColumnText, { className: classes.elideText, text: row.shipperQuoteRef }))))));
                },
                sortable: true,
                width: 210,
            },
            (0, tableColumns_1.quoteRequestSimpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipperQuoteRef', headerName: t('common:shipperQuoteRef') })),
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'actions',
                filterable: false,
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`common:actionsCellHeader`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "center", spacing: 2 },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.DataGridActionIconButton, { count: 0, isActive: false, isDisabled: quoteLoadingId !== null, onClick: (e) => {
                                    if (handleSearch) {
                                        e.stopPropagation();
                                        handleSearch(row);
                                    }
                                } },
                                row.id === quoteLoadingId && react_1.default.createElement(ux_1.CircularProgress, { size: 16 }),
                                row.id !== quoteLoadingId && (react_1.default.createElement(ux_1.TruxwebSearchAltIcon, { style: { fill: 'inherit' } })))),
                        row.isAccepted && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.DataGridActionIconButton, { count: 0, isActive: true, onClick: (e) => {
                                    e.stopPropagation();
                                    handleRouteToShipment(row.shipmentRef);
                                } },
                                react_1.default.createElement(ux_1.TruckDrivingIcon, { className: classes.shipmentIcon }))))));
                },
                sortable: false,
                valueGetter: ({ row }) => {
                    return row.countdownTimerTimeRemaining;
                },
                width: 148,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'timer',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`shipments:timeRemaining`),
                renderCell: ({ row }) => {
                    const nonCountdownStates = {
                        isAccepted: row._source.shipperQuoteStatus === schemas_1.EShipmentShipperQuoteStatusV1.CONFIRMED,
                        isExpired: true,
                        isRefused: row._source.shipperQuoteStatus === schemas_1.EShipmentShipperQuoteStatusV1.CARRIER_DECLINED,
                    };
                    if ([
                        schemas_1.EShipmentShipperQuoteStatusV1.RESPONSE_REQUIRED,
                        schemas_1.EShipmentShipperQuoteStatusV1.WAITING_FOR_RESPONSES,
                    ].includes(row._source.shipperQuoteStatus)) {
                        return (react_1.default.createElement(ux_1.DataGridColumnTimer, { maxTime: row.countdownTimerMaxTime, timeRemainingInSeconds: row.countdownTimerTimeRemaining }));
                    }
                    return (react_1.default.createElement(ux_1.Box, { style: {
                            height: '100%',
                            lineHeight: '64px',
                            marginTop: '12px',
                            position: 'relative',
                            width: '100%',
                        } },
                        react_1.default.createElement(__1.QuoteRequestSidebarCountdown, Object.assign({ carrierRequests: [row], isSkinny: true }, nonCountdownStates))));
                },
                type: 'number',
                valueGetter: ({ row }) => {
                    if (row.isPending || row.isInReview) {
                        return row.countdownTimerTimeRemaining;
                    }
                    return 0;
                },
                width: 148,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'status',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`common:status`),
                renderCell: ({ row }) => {
                    let state = 'neutral';
                    let status = row._source.shipperQuoteStatus;
                    if (row._source.shipperQuoteStatus === schemas_1.EShipmentShipperQuoteStatusV1.CONFIRMED) {
                        state = 'positive';
                    }
                    else if (row._source.shipperQuoteStatus === schemas_1.EShipmentShipperQuoteStatusV1.CARRIER_DECLINED) {
                        state = 'negative';
                    }
                    else if (row._source.shipperQuoteStatus === schemas_1.EShipmentShipperQuoteStatusV1.RESPONSE_REQUIRED ||
                        row._source.shipperQuoteStatus === schemas_1.EShipmentShipperQuoteStatusV1.WAITING_FOR_RESPONSES) {
                        state = 'acknowledged';
                        if (row.isSoftExpired) {
                            state = 'neutral';
                            status = schemas_1.EShipmentShipperQuoteStatusV1.EXPIRED;
                        }
                    }
                    return (react_1.default.createElement(ux_1.DataGridColumnStatusText, { content: t(`common:shipmentQuoteRequestDisplayStatus-${status}`), state: state }));
                },
                type: 'singleSelect',
                valueGetter: ({ row }) => {
                    if (row.isCarrierExpired) {
                        return t('common:carrierExpired');
                    }
                    // if all of the carrier quote requests have expired or been refused: Carrier Declined
                    if (row.isCarrierRefused) {
                        return t('common:carrierDeclined');
                    }
                    // if a shipment has been accepted by the shipper: Confirmed
                    if (row.isAccepted) {
                        return t('common:confirmed');
                    }
                    if (row.isAwaitingCarrierResponse && !row.isSoftExpired) {
                        return t('common:awaitingCarrierResponse');
                    }
                    // is a shipment has not been accepted by the shipper: pending
                    if (row.isAwaitingShipperResponse && !row.isSoftExpired) {
                        return t('common:CARRIER-carrierQuoteStatus-PENDING');
                    }
                    // if all of the quotes cancelled: Cancelled
                    if (row.isCancelled) {
                        return t('common:quoteStatusBar-CANCELLED');
                    }
                    // if all of the quotes expired without the shipper responding: Expired
                    if (row.isShipperExpired || row.isSoftExpired) {
                        return t('common:expired');
                    }
                    // if all of the carrier quote requests have expired or been refused, and there are any
                    // declined by the shipper: Declined
                    if (row.isShipperRefused) {
                        return t('common:youDeclined');
                    }
                    return t('common:na');
                },
                valueOptions: [
                    t('common:awaitingCarrierResponse'),
                    t('common:CARRIER-carrierQuoteStatus-PENDING'),
                    t('common:confirmed'),
                    t('common:carrierDeclined'),
                    t('common:youDeclined'),
                    t('common:expired'),
                    t('common:carrierExpired'),
                    t('common:quoteStatusBar-CANCELLED'),
                    t('common:na'),
                ],
                width: 168,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'totalResponses',
                filterable: false,
                headerAlign: 'center',
                headerName: t(`shipments:activity`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.DataGridColumnChip, { classNameAvatar: classes.chipResponseValue, classNameWrapper: classes.chipWrapper, data: `${row.totalResponses} / ${row.carrierRequests.length}`, label: t('shipments:responsesWaiting') }));
                },
                sortable: false,
                type: 'number',
                width: 224,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'numberAccepted',
                headerAlign: 'center',
                headerName: t(`common:numberAccepted`),
                renderCell: ({ row }) => {
                    const availableRequests = row.carrierRequests.filter(({ carrierQuoteStatus }) => {
                        return [
                            schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED,
                            schemas_1.EShipmentQuoteRequestStatusV1.MODIFIED,
                        ].includes(carrierQuoteStatus);
                    });
                    return (react_1.default.createElement(ux_1.DataGridColumnChip, { classNameAvatar: availableRequests.length === 0
                            ? classes.chipResponseValueNeutral
                            : classes.chipResponseValueAccepted, classNameWrapper: classes.chipWrapperEmpty, data: `${availableRequests.length}`, label: '' }));
                },
                type: 'number',
                valueGetter: ({ row }) => {
                    const availableRequests = row.carrierRequests.filter(({ carrierQuoteStatus }) => {
                        return [
                            schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED,
                            schemas_1.EShipmentQuoteRequestStatusV1.MODIFIED,
                        ].includes(carrierQuoteStatus);
                    });
                    return availableRequests.length;
                },
                width: 124,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'numberRefused',
                headerAlign: 'center',
                headerName: t(`common:numberRefused`),
                renderCell: ({ row }) => {
                    const availableRequests = row.carrierRequests.filter(({ carrierQuoteStatus }) => {
                        return [schemas_1.EShipmentQuoteRequestStatusV1.REFUSED].includes(carrierQuoteStatus);
                    });
                    return (react_1.default.createElement(ux_1.DataGridColumnChip, { classNameAvatar: availableRequests.length === 0
                            ? classes.chipResponseValueNeutral
                            : classes.chipResponseValueRefused, classNameWrapper: classes.chipWrapperEmpty, data: `${availableRequests.length}`, label: '' }));
                },
                type: 'number',
                valueGetter: ({ row }) => {
                    const availableRequests = row.carrierRequests.filter(({ carrierQuoteStatus }) => {
                        return [schemas_1.EShipmentQuoteRequestStatusV1.REFUSED].includes(carrierQuoteStatus);
                    });
                    return availableRequests.length;
                },
                width: 124,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'numberSolicited',
                headerAlign: 'center',
                headerName: t(`common:numberSolicited`),
                renderCell: ({ row }) => {
                    return (react_1.default.createElement(ux_1.DataGridColumnChip, { classNameAvatar: classes.chipResponseValue, classNameWrapper: classes.chipWrapperEmpty, data: `${row.carrierRequests.length}`, label: '' }));
                },
                type: 'number',
                valueGetter: ({ row }) => {
                    return row.carrierRequests.length;
                },
                width: 124,
            },
            viewingUserType === schemas_1.EUserTypeV1.TRUXWEB
                ? (0, tableColumns_1.quoteRequestSimpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipperName', headerName: t('common:shipper'), renderLink: renderCompanyLink }))
                : undefined,
            (0, tableColumns_1.quoteRequestSimpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'carrierName', headerName: t('common:carrier') })),
            (0, tableColumns_1.quoteRequestModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'chargesOriginal', headerName: t(`common:price`), modifiedField: 'chargesModified', type: 'number', valueGetter: ({ row }) => {
                    return row.chargesModifiedValue;
                } })),
            (0, tableColumns_1.quoteRequestStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'bookingDateModified', headerName: t('common:pickUpDate'), valueGetter: ({ row }) => {
                    return row.bookingDateModifiedDate;
                } })),
            (0, tableColumns_1.quoteRequestStaticModifiedColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'estimatedDeliveryDate', valueGetter: ({ row }) => {
                    return row.estimatedDeliveryDateDate;
                } })),
            (0, tableColumns_1.quoteRequestLocationColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'locationOrigin', headerName: t(`shipments:origin`) })),
            (0, tableColumns_1.quoteRequestLocationColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'locationDestination', headerName: t(`shipments:consignee`) })),
            (0, tableColumns_1.quoteRequestSimpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'shipmentType', headerName: t('common:shipmentType') })),
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'cargo',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t('common:packagingType'),
                renderCell: ({ value }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnText, { fallbackText: '', text: value });
                },
                sortable: true,
                type: 'singleSelect',
                valueGetter: ({ row, value }) => {
                    let content = t('common:na');
                    if (row.shipmentType !== schemas_1.ETruckloadTypeV1.FTL) {
                        // If this isn't and FTL shipment we need to look at the cargo data
                        //  - If there is more than one pallet return mixed
                        const palletTypes = new Set();
                        (value || []).forEach(({ packagingType }) => {
                            palletTypes.add(packagingType);
                        });
                        if (palletTypes.size !== 1) {
                            content = t('common:mixed');
                        }
                        else if (palletTypes.size) {
                            const palletType = Array.from(palletTypes)[0];
                            content = t(`select:${palletType}`);
                        }
                    }
                    return content;
                },
                valueOptions: [
                    ...Object.values(schemas_1.ELtlPackagingV1).map((value) => t(`select:${value}`)),
                    t('common:na'),
                ],
                width: 120,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'equipmentCodes',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`shipments:equipment`),
                renderCell: ({ row }) => {
                    return react_1.default.createElement(ux_1.DataGridColumnList, { items: row.equipmentCodes });
                },
                type: 'singleSelect',
                valueGetter: ({ row }) => {
                    return row.equipmentCodes.join(',');
                },
                valueOptions: equipment.map(({ code }) => t(`common:SHIPPER-equipment-${code}`)),
                width: 250,
            },
            {
                align: 'center',
                cellClassName: classes.cellPointer,
                field: 'accessorialCodes',
                headerAlign: 'center',
                headerClassName: 'datagrid-header',
                headerName: t(`shipments:additionalServices`),
                renderCell: ({ row }) => {
                    if (row.accessorialCodes.length === 0)
                        return t('common:na');
                    return react_1.default.createElement(ux_1.DataGridColumnList, { items: row.accessorialCodes });
                },
                type: 'singleSelect',
                valueGetter: ({ row }) => {
                    return row.accessorialCodes.join(',');
                },
                valueOptions: accessorial.map(({ code }) => t(`common:SHIPPER-accessorial-${code}`)),
                width: 350,
            },
            (0, tableColumns_1.quoteRequestStaticDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'dateCreated' })),
            (0, tableColumns_1.quoteRequestStaticDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'dateUpdated' })),
            (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags, field: 'tags', width: 350 })),
            viewingUserType === schemas_1.EUserTypeV1.TRUXWEB
                ? (0, tableColumns_1.tagsColumn)(Object.assign(Object.assign({}, basicColumnDef), { availableTags: availableTruxwebTags, field: 'truxwebTags', width: 350 }))
                : undefined,
        ];
        const fullColumns = columns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        const availableCols = [];
        const displayCols = fullColumns.map((col) => {
            availableCols.push(col.field);
            if (col.disableColumnMenu)
                return col;
            if (columnKeys && excludeColumnKeys) {
                return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) || excludeColumnKeys.includes(col.field) });
            }
            if (columnKeys) {
                return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) });
            }
            if (excludeColumnKeys) {
                return Object.assign(Object.assign({}, col), { hide: excludeColumnKeys.includes(col.field) });
            }
            return Object.assign(Object.assign({}, col), { hide: false });
        });
        let activeSort = currentSort;
        if ((activeSort === null || activeSort === void 0 ? void 0 : activeSort.length) && !availableCols.includes(activeSort[0].field)) {
            activeSort = defaultSort;
        }
        const gridHeight = ((dynamicPageSize || DEFAULT_PAGE_SIZE) + 1.85) * DEFAULT_ROW_HEIGHT;
        return [displayCols, activeSort, gridHeight];
    }, [
        renderCompanyLink,
        availableTruxwebTags,
        availableTags,
        t,
        equipment,
        accessorial,
        dynamicPageSize,
        classes,
        language,
        handleRouteToShipment,
        handleSearch,
        quoteLoadingId,
        viewingUserType,
        excludeColumnKeys,
        columnKeys,
        currentSort,
        defaultSort,
    ]);
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { areFiltersEnabled: areFiltersEnabled, handleExport: handleExport, handleToggleAutoRefresh: handleToggleAutoRefresh, isExportEnabled: isExportEnabled, isHeaderShown: isHeaderShown, isLazyLoading: isLazyLoading, isLoading: isLoading, isServerSideExport: isServerSideExport, language: language, lastLoadedDate: lastLoadedDate, rowCount: rowCount, shouldAutoRefresh: shouldAutoRefresh, t: t }));
    };
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, currentSort: activeSort, data: data || [], defaultSort: defaultSort, filterMode: filterMode, gridHeight: gridHeight, handleCellClick: ({ field, row }) => {
            if (viewingUserType !== schemas_1.EUserTypeV1.TRUXWEB) {
                onSelectQuote(row.id);
                return;
            }
            if (!['shipperName', 'carrierName'].includes(field)) {
                onSelectQuote(row.id);
            }
        }, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isActionsOverlayEnabled: isActionsOverlayEnabled, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: dynamicPageSize || DEFAULT_PAGE_SIZE, paginationMode: paginationMode, renderActionsContent: renderActionsContent, rowCount: rowCount, rowHeight: DEFAULT_ROW_HEIGHT, selectionModel: selectedRows, shouldDisableMultipleSelection: true, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.QuoteRequestShipperDataGrid = QuoteRequestShipperDataGrid;
