"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        cursor: 'pointer',
        // NOTE: This is the only exception where a font is not
        // pull from the theme
        fontFamily: 'Roboto Mono',
        fontSize: theme.spacing(2),
        fontWeight: 500,
        height: 139,
        lineHeight: `24px`,
        paddingTop: theme.spacing(1),
        position: 'relative',
        width: 245,
    },
    cardExpiry: {
        position: 'absolute',
        right: 34,
        top: 104,
    },
    cardNumber: {
        left: theme.spacing(2),
        position: 'absolute',
        top: 104,
    },
    removeCard: {
        '&:hover': {
            background: '#FFF',
        },
        background: '#FFF',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: '100%',
        height: theme.spacing(3),
        position: 'absolute',
        right: theme.spacing(1.75),
        top: '-4px',
        width: theme.spacing(3),
    },
    selectedCard: {
        bottom: -20,
        color: 'green',
        position: 'absolute',
        right: theme.spacing(1),
    },
}));
