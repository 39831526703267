"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    carrierExpiredButton: {
        textDecoration: 'underline',
    },
    carrierExpiredContainer: {
        background: 'rgba(0, 135, 206, 0.05)',
        borderRadius: theme.spacing(2),
    },
    carrierQuoteRef: {
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(0.75),
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: theme.spacing(2),
        width: '100%',
    },
    characterCount: {
        color: theme.palette.primary.light,
    },
    comment: {
        border: 0,
        width: '100%',
    },
}));
