"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    cancel: {
        '&:disabled': {
            backgroundColor: theme.palette.grey[600],
            cursor: 'unset',
        },
        backgroundColor: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.primary.dark}`,
        borderRadius: theme.spacing(1),
        color: `${theme.palette.common.white}`,
        cursor: 'pointer',
    },
    confirmRemove: {
        '&:disabled': {
            backgroundColor: theme.palette.grey[600],
            cursor: 'unset',
        },
        backgroundColor: theme.palette.error.dark,
        border: `1px solid ${theme.palette.error.dark}`,
        borderRadius: theme.spacing(1),
        color: `${theme.palette.common.white}`,
        cursor: 'pointer',
    },
    container: {
        borderColor: ({ isInRemovalConfirmation }) => isInRemovalConfirmation ? theme.palette.error.light : 'transparent',
        borderRadius: theme.spacing(2),
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    greyCard: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: '6.5px',
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    typeEnumSelect: {
        maxWidth: '85%',
    },
    typeEnumSelectContainer: {
        borderRadius: theme.spacing(1),
        height: '40px',
    },
}));
