import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(3.25),
    },
    '& .MuiCardContent-root:last-child': {
      padding: theme.spacing(3.25),
    },
    '&:hover': {
      border: `solid 3px ${theme.palette.primary.light}`,
      color: theme.palette.primary.main,
    },
    alignItems: 'center',
    border: `solid 3px ${theme.palette.common.white}`,
    borderRadius: `${theme.spacing(1)}px `,
    cursor: 'pointer',
    display: 'flex',
    height: theme.spacing(12),
    justifyContent: 'flex-start',
    margin: '0',
    padding: 0,
    width: `${theme.spacing(40)}px`,
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      height: theme.spacing(16),
    },
  },
  itemHeight: {
    height: '24px',
  },
  primaryTile: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(2.5),
    },
    '& .MuiCardContent-root:last-child': {
      padding: theme.spacing(2.5),
    },
    backgroundColor: '#EDF8FF',
    border: `solid 3px transparent`,
    color: theme.palette.primary.light,
    width: '100%',
  },
  tileFont: {
    color: theme.palette.primary.light,
  },
}));
