import { makeStyles, Theme } from '@truxweb/ux';

export const useLinkButtonStyles = makeStyles<Theme>((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: '#0087CE',
      color: '#FFF',
      fontWeight: 'normal',
    },
    background: 'transparent',
    border: 0,
    color: '#636363',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    height: 48,
    lineHeight: '48px',
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
    width: '100%',
  },
}));
