/* eslint-disable complexity */
import { Box, Grid, useMediaQuery, useTheme } from '@truxweb/ux';
import { ForgotPasswordForm, LoginForm } from '../../components';
import React, { useState } from 'react';
import ENLaptopImage from '../../public/assets/images/registration/Laptop_Onboarding-en-CA.png';
import FRLaptopImage from '../../public/assets/images/registration/Laptop_Onboarding-fr-CA.png';
import Image from 'next/image';
import { SplitPage } from '../../pageTemplates';
import { type TPageId } from '../../types';
import { type TUserV1 } from '@truxweb/schemas';
import { useRouter } from 'next/router';

type TLoginPageV2Props = {
  pageId: TPageId;
};

export const LoginPageV2 = ({ pageId }: TLoginPageV2Props): JSX.Element => {
  const [isPasswordForgotten, setPasswordForgotten] = useState(false);
  const [isResettingPassword, setResettingPassword] = useState(false);
  const [resetUserData, setUserReset] = useState<TUserV1 | null>(null);
  const theme = useTheme();
  const router = useRouter();
  const { locale } = router;
  const isImageOffset = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isImageBottom = useMediaQuery(theme.breakpoints.down('xs'));
  const successRedirect = (router.query.dest || '').toLocaleString();

  return (
    <>
      <SplitPage
        isFullPage={isPasswordForgotten}
        pageId={pageId}
        rightHandContent={
          <Box
            sx={{
              height: isImageBottom ? '120%' : isImageOffset ? '70%' : '100%',
              position: 'relative',
              top: isImageBottom && '-60px',
              width: '100%',
            }}
          >
            <Image
              alt="Login to Truxweb"
              layout="fill"
              loading={'eager'}
              objectFit={isImageOffset ? 'cover' : 'scale-down'}
              objectPosition={isImageOffset ? '60px center' : 'center 55%'}
              priority
              src={locale === 'en-CA' ? ENLaptopImage : FRLaptopImage}
            />
          </Box>
        }
      >
        <Grid alignItems="center" container direction="column">
          <Grid container item justifyContent="center">
            {!isResettingPassword && !isPasswordForgotten && (
              <LoginForm
                onForgotPassword={() => {
                  setPasswordForgotten(true);
                }}
                onResetPassword={(user) => {
                  setUserReset(user);
                  setResettingPassword(true);
                }}
                successRedirect={Boolean(successRedirect) && successRedirect}
              />
            )}

            {(isPasswordForgotten || isResettingPassword) && (
              <ForgotPasswordForm authedUser={resetUserData} isResetOnly={isResettingPassword} />
            )}
          </Grid>
        </Grid>
      </SplitPage>
    </>
  );
};
