import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    zIndex: 5,
  },
  container: {
    background: theme.palette.primary.dark,
    minWidth: 360,
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: theme.spacing(21),
      marginLeft: 0,
      marginRight: 0,
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: '10px',
      height: theme.spacing(14),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(16),
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '20px',
      height: theme.spacing(25),
    },
    [theme.breakpoints.up('xl')]: {
      borderRadius: '20px',
      height: theme.spacing(29),
    },
  },
  link: {
    '&:hover': { color: theme.palette.common.white },
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 600,
    zIndex: 5,
  },
  textWrap: {
    zIndex: 5,
    [theme.breakpoints.between('xs', 'sm')]: { maxWidth: '100%', paddingRight: theme.spacing(1) },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '70%',
    },
  },
  wings: {
    margin: 0,
    position: 'absolute',
    right: '10%',
    top: -2,
    zIndex: 0,
    [theme.breakpoints.down(theme.breakpoints.values.xs + 100)]: {
      maxHeight: theme.spacing(21.5),
      right: '-25%',
    },
    [theme.breakpoints.between(
      theme.breakpoints.values.xs + 40,
      theme.breakpoints.values.xs + 200
    )]: {
      maxHeight: theme.spacing(21.5),
      right: '-15%',
    },
    [theme.breakpoints.between(
      theme.breakpoints.values.xs + 200,
      theme.breakpoints.values.xs + 300
    )]: {
      maxHeight: theme.spacing(21.5),
      right: '-10%',
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 300)]: {
      maxHeight: theme.spacing(21.5),
      right: '-5%',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(14.5),
      right: '18%',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: theme.spacing(16.25),
      right: '15%',
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: theme.spacing(25.25),
      right: '8%',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: theme.spacing(29.25),
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}));
