"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    button: {
        alignItems: 'center',
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.light,
        cursor: 'pointer',
        display: 'flex',
        fill: theme.palette.primary.light,
        fontSize: '16px',
    },
    cargo: {
        background: '#f3f3f3',
        borderColor: '#f3f3f3',
        borderRadius: theme.spacing(2),
        borderStyle: 'solid',
        borderWidth: '1px',
        position: 'relative',
    },
    cargoButton: {
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.light,
        cursor: 'pointer',
        padding: 0,
        textDecoration: 'underline',
    },
    cargoError: {
        borderColor: theme.palette.error.main,
    },
    cargoRemove: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        position: 'absolute',
        right: 24,
        top: 24,
    },
    cargoSelected: {
        borderColor: theme.palette.primary.light,
        borderWidth: '2px',
    },
    cargoSpecificationDefinitions: {
        background: '#f3f3f3',
        borderColor: theme.palette.primary.light,
        borderWidth: '1px',
    },
}));
