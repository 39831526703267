import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme) => ({
  bodyContent: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
      paddingTop: theme.spacing(9),
    },
    [theme.breakpoints.between('xs', 'md')]: {
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(13.5),
    },
  },
  container: {
    background: 'rgba(6, 25, 70, 1)',

    height: '100%',
    maxWidth: 1920,
    minHeight: '100vh',
    minWidth: 360,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  ctaWrap: {
    marginBottom: theme.spacing(6),
  },
  follow: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(16),
      marginTop: theme.spacing(9),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(20),
      marginTop: theme.spacing(9),
    },
  },
  followText: {
    color: theme.palette.common.white,
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      textAlign: 'center',
      width: '100%',
    },
  },
  formEntry: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(27),
      marginTop: theme.spacing(2),
    },
  },
  formWrapper: {
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(64),
    },
  },
  image: {
    height: '100%',
    position: 'absolute',
    top: 100,
    width: '100%',
    zIndex: 0,
    [theme.breakpoints.up('lg')]: {
      top: 300,
    },
  },
  innerCard: {
    borderRadius: '50px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      minHeight: theme.spacing(95),
      minWidth: theme.spacing(36),
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(71),
      padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
      width: theme.spacing(78),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(75),
      padding: theme.spacing(6),
      width: theme.spacing(94),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(110),
      padding: theme.spacing(10),
      width: theme.spacing(124),
    },
  },
  input: {
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(5),
    },
  },
  label: {
    color: theme.palette.primary.dark,
    fontSize: '1.25rem',
  },
  logo: {
    background: theme.palette.primary.main,
    borderRadius: '100%',
    height: theme.spacing(4),
    padding: theme.spacing(1),
    width: theme.spacing(4),
    zIndex: 2,
  },
  message: { background: theme.palette.grey[100], height: '100% !important' },
  messageWrap: {
    '& div:nth-child(1)': {
      '& div:nth-child(2)': { '& div:nth-child(1)': { height: '100%' } },
      flexGrow: 0,
      height: '100%',
    },
    height: 'calc(100% - 22px)',
  },
  overlay: {
    background: 'url(/assets/images/pageBackgrounds/forest-fade-bg.png) top no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'cover',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  save: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
    },
  },
  successfulSignUp: { height: '100%', width: '100%' },
  title: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(13),
      marginTop: theme.spacing(17),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(14),
      marginTop: theme.spacing(19),
    },
  },
  topGridContainer: {
    height: '100%',
    maxWidth: '100vw',
    width: '100%',
  },
}));
