"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDiceD20Icon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.573,4.312l-6-3.6a4.989,4.989,0,0,0-5.146,0l-6,3.6A5.027,5.027,0,0,0,1,8.6v6.8a5.027,5.027,0,0,0,2.427,4.288l6,3.6a4.987,4.987,0,0,0,5.146,0l6-3.6A5.027,5.027,0,0,0,23,15.4V8.6A5.027,5.027,0,0,0,20.573,4.312ZM3.005,8.437l2.733,1.639L3,14.09S3,8.491,3.005,8.437Zm9-5.378L15.3,9H8.7Zm9,10.918-2.73-3.905L21,8.437C21,8.491,21,13.977,21,13.977ZM8.805,11H15.2L12,16.113ZM4.018,16.147l2.943-4.322L10.007,16.7Zm13.029-4.334,3.027,4.332L14,16.683Zm2.5-5.786a2.98,2.98,0,0,1,.668.548L17.233,8.361l-3.1-5.579Zm-15.086,0L9.868,2.781,6.77,8.363,3.789,6.575A2.98,2.98,0,0,1,4.457,6.027Zm.44,12.21L11,18.8v3.03a3.022,3.022,0,0,1-.543-.257Zm8.646,3.335a3.022,3.022,0,0,1-.543.257v-3.05l6.1-.54Z" })));
exports.default = TruxwebDiceD20Icon;
