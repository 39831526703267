import { makeRequestToApiGateway } from '../../apiUtils';

export const downloadRateSheetById = async (rateSheetId: number, carrierId: number) => {
  return await makeRequestToApiGateway(
    'carrierGetV1CarrierRates',
    null,
    {
      'Content-Type': 'application/json',
    },
    null,
    { action: 'download', carrierId, rateSheetId }
  );
};
