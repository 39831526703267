"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrimaryLightUncheckedStyles = exports.usePrimaryLightStyles = exports.useGrey400UncheckedStyles = exports.useGrey400Styles = void 0;
const __1 = require("..");
exports.useGrey400Styles = (0, __1.makeStyles)((theme) => ({
    checked: {
        color: `${theme.palette.primary.light} !important`,
        fill: `${theme.palette.primary.light} !important`,
        scale: 0.913,
    },
    root: {
        '&.checked': {
            color: theme.palette.primary.light,
        },
        color: theme.palette.grey[400],
        margin: 0,
        padding: 0,
    },
}));
exports.useGrey400UncheckedStyles = (0, __1.makeStyles)((theme) => ({
    unchecked: {
        fill: theme.palette.grey[400],
        scale: 0.913,
    },
}));
exports.usePrimaryLightStyles = (0, __1.makeStyles)((theme) => ({
    checked: {
        color: `${theme.palette.primary.light} !important`,
        fill: `${theme.palette.primary.light} !important`,
        scale: 0.913,
    },
    root: {
        '&.checked': {
            color: theme.palette.primary.light,
        },
        color: ({ hasDefaultBorderColor }) => hasDefaultBorderColor ? theme.palette.grey[400] : theme.palette.primary.light,
        padding: 0,
    },
}));
exports.usePrimaryLightUncheckedStyles = (0, __1.makeStyles)((theme) => ({
    unchecked: {
        fill: ({ hasDefaultBorderColor }) => hasDefaultBorderColor ? theme.palette.grey[400] : `${theme.palette.primary.light} !important`,
        scale: 0.913,
    },
}));
