"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPooIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M12,19a9.454,9.454,0,0,1-5.666-2.254,1,1,0,0,1,1.332-1.492,6.359,6.359,0,0,0,8.67,0,1,1,0,0,1,1.33,1.493A9.454,9.454,0,0,1,12,19ZM9.5,11a1.5,1.5,0,0,0,0,3A1.5,1.5,0,0,0,9.5,11Zm5,0a1.5,1.5,0,0,0,0,3A1.5,1.5,0,0,0,14.5,11ZM24,18.5a5.483,5.483,0,0,0-2.139-4.329,4.982,4.982,0,0,0-1.9-5.178A3.514,3.514,0,0,0,16.89,5.021C16.408,2.747,14.4,0,12,0a1,1,0,0,0-1,1A4.1,4.1,0,0,1,8.806,5H7.5A3.51,3.51,0,0,0,4.035,8.993a4.981,4.981,0,0,0-1.9,5.178A5.5,5.5,0,0,0,5.5,24h13A5.506,5.506,0,0,0,24,18.5ZM9,7a1,1,0,0,0,.316-.052A5.773,5.773,0,0,0,12.9,2.309,5.237,5.237,0,0,1,15,6a1,1,0,0,0,1,1h.5a1.505,1.505,0,0,1,1.385,2.071,1,1,0,0,0,.469,1.272,2.985,2.985,0,0,1,1.4,3.842,1,1,0,0,0,.433,1.271A3.5,3.5,0,0,1,18.5,22H5.5a3.5,3.5,0,0,1-1.687-6.544,1,1,0,0,0,.433-1.271,2.985,2.985,0,0,1,1.4-3.842,1,1,0,0,0,.469-1.272A1.505,1.505,0,0,1,7.5,7Zm8-1h0Z" })));
exports.default = TruxwebPooIcon;
