"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        '&:hover': {
            border: `2px solid ${theme.palette.primary.light}`,
        },
        border: ({ isInAcceptance }) => isInAcceptance
            ? `2px solid ${theme.palette.primary.light}`
            : `2px solid ${theme.palette.common.white}`,
        paddingLeft: ({ isAccepted }) => (isAccepted ? 0 : theme.spacing(4)),
        paddingRight: ({ isAccepted }) => (isAccepted ? 0 : theme.spacing(4)),
        paddingTop: ({ isAccepted, isInAcceptance }) => isAccepted && !isInAcceptance ? 0 : theme.spacing(4),
        transition: 'border-color 0.2s linear',
    },
    confirmed: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '100%',
        color: theme.palette.success.main,
    },
    formContainer: {
        width: '100%',
    },
    header: {
        fontWeight: 600,
    },
    quoteDetailsContainer: {
        paddingLeft: ({ isAccepted }) => (isAccepted ? theme.spacing(4) : 0),
        paddingRight: ({ isAccepted }) => (isAccepted ? theme.spacing(4) : 0),
    },
    selectedQuote: {
        background: theme.palette.primary.dark,
        borderTopLeftRadius: theme.spacing(1.75),
        borderTopRightRadius: theme.spacing(1.75),
        color: theme.palette.common.white,
        height: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    selectedQuoteText: {
        color: theme.palette.common.white,
    },
    statusDescription: {
        color: theme.palette.primary.light,
    },
    timeExpired: {
        color: '#c4c4c4',
    },
    timeTierOne: {
        color: theme.palette.success.main,
    },
    timeTierThree: {
        color: theme.palette.error.main,
    },
    timeTierTwo: {
        color: theme.palette.warning.light,
    },
    timer: {
        fontSize: 12,
        fontWeight: 600,
    },
}));
