"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTrainIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15,5a1,1,0,0,1-1,1H10a1,1,0,0,1,0-2h4A1,1,0,0,1,15,5Zm6,.72V16.177a5,5,0,0,1-1.326,3.39l1.25,3.054a1,1,0,1,1-1.852.757L18,20.745A13.573,13.573,0,0,1,12,22a13.583,13.583,0,0,1-6-1.255L4.927,23.378a1,1,0,1,1-1.851-.757l1.249-3.054A5,5,0,0,1,3,16.177V5.72A4.982,4.982,0,0,1,6.475.956,18.367,18.367,0,0,1,12,0a18.329,18.329,0,0,1,5.525.957A4.979,4.979,0,0,1,21,5.72Zm-2,6.017C17.447,14.065,15.05,16,12,16s-5.447-1.935-7-4.263v4.44a2.958,2.958,0,0,0,1.512,2.607A11.331,11.331,0,0,0,12,20a11.321,11.321,0,0,0,5.487-1.216A2.957,2.957,0,0,0,19,16.177ZM19,6V5.72a2.988,2.988,0,0,0-2.083-2.858A16.364,16.364,0,0,0,12,2a16.4,16.4,0,0,0-4.917.861A2.99,2.99,0,0,0,5,5.72V6c0,2.443,2.645,8,7,8S19,8.443,19,6ZM12,17a1,1,0,1,0,1,1A1,1,0,0,0,12,17Z" })));
exports.default = TruxwebTrainIcon;
