"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierProfileMobile = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const CarrierProfileMobile_styles_1 = require("./CarrierProfileMobile.styles");
const CarrierProfileMobile = ({ language, profile, profileImage, renderCompanyProfileLink, t, }) => {
    const classes = (0, CarrierProfileMobile_styles_1.useStyles)();
    const activeProfile = (0, react_1.useMemo)(() => {
        return profile.carrierProfileRecord.find((record) => {
            return record.language === language;
        });
    }, [profile, language]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Box, { p: 4, style: { background: 'white', borderRadius: '16px 16px 0 0' } },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                Boolean(profileImage) && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center", xs: true },
                    react_1.default.createElement(ux_1.Box, { mb: 3.5 }, profileImage),
                    react_1.default.createElement(ux_1.Divider, { style: { width: '100%' } }))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Box, { mb: 4, mt: 3.5 },
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, profile.company.name),
                        react_1.default.createElement(ux_1.Box, { mt: 1 },
                            react_1.default.createElement(ux_1.Rating, { precision: 0.5, readOnly: true, value: profile.rating / utils_1.STAR_VALUE }))),
                    react_1.default.createElement(ux_1.Box, { mb: 2 },
                        react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" }, activeProfile === null || activeProfile === void 0 ? void 0 : activeProfile.profileTagline)),
                    react_1.default.createElement(ux_1.Box, { mb: 3 },
                        react_1.default.createElement(ux_1.Typography, { style: {
                                whiteSpace: 'pre-line',
                            } }, activeProfile === null || activeProfile === void 0 ? void 0 : activeProfile.profileDescription))),
                react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "space-between", xs: true },
                    react_1.default.createElement(ux_1.Grid, { item: true }, Boolean(profile.company.companyWebsite) && (react_1.default.createElement("a", { className: classes.link, href: `//${profile.company.companyWebsite}`, rel: "noreferrer noopener", target: "_blank" },
                        react_1.default.createElement(ux_1.FlatButton, { color: "primaryLight", variant: "contained" }, t('companyInfo:visitWebsite'))))),
                    react_1.default.createElement(ux_1.Grid, { item: true }, renderCompanyProfileLink !== undefined && renderCompanyProfileLink())))),
        react_1.default.createElement(__1.SidebarSection, { title: t('common:subscriptionFeatures') },
            react_1.default.createElement(ux_1.Box, { p: 4, pt: 0 },
                react_1.default.createElement(__1.CarrierProfileFeatures, { className: classes.featuresContainer, profile: profile, t: t }))),
        react_1.default.createElement(__1.SidebarSection, { title: t('shipments:additionalCharges') },
            react_1.default.createElement(ux_1.Box, { m: 4, pt: 0, style: { width: 344 } },
                react_1.default.createElement(__1.CarrierProfileAdditionalCosts, { equipment: profile.carrierProfileEquipment, language: language, t: t }))),
        react_1.default.createElement(__1.SidebarSection, { title: t('companyInfo:areaOfOperation') },
            react_1.default.createElement(ux_1.Box, { p: 4, pt: 0, style: { width: '100%' } },
                react_1.default.createElement(__1.CarrierProfileCoverage, { profile: profile }))),
        react_1.default.createElement(__1.SidebarSection, { title: t('common:accessorials') },
            react_1.default.createElement(ux_1.Box, { p: 4, pt: 0, style: { width: '100%' } },
                react_1.default.createElement(__1.CarrierProfileAccessorials, { profile: profile, t: t })))));
};
exports.CarrierProfileMobile = CarrierProfileMobile;
