"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarOrderSummary = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const ShipmentsDashboardSidebarOrderSummary_styles_1 = require("./ShipmentsDashboardSidebarOrderSummary.styles");
const ShipmentsDashboardSidebarOrderSummary = ({ canSetTaxExemptStatus, charges, companyType, handleRouteToPage, isLoading, language, onEditShipment, shipment, subtotal, t, total, }) => {
    var _a;
    const classes = (0, ShipmentsDashboardSidebarOrderSummary_styles_1.useStyles)();
    const originalTaxExemptState = (0, react_1.useMemo)(() => {
        return !Boolean(shipment.shipment.isTaxable);
    }, [shipment]);
    const [isTaxExempt, setIsTaxExempt] = (0, react_1.useState)(originalTaxExemptState);
    const [areChargesUpdating, setChargesUpdating] = (0, react_1.useState)(false);
    const handleShipmentUpdate = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setChargesUpdating(true);
            yield onEditShipment(`isTaxable`, Object.assign(Object.assign({}, shipment.shipment), { isTaxable: !isTaxExempt }));
        }
        finally {
            setChargesUpdating(false);
        }
    }), [onEditShipment, setChargesUpdating, isTaxExempt, shipment]);
    return (react_1.default.createElement(ux_1.Box, { className: classes.container, pb: 3, pl: 4, pr: 4, pt: 1 },
        react_1.default.createElement(__1.ShipmentDashboardOrderSummary, { charges: charges, companyType: companyType, handleRouteToPage: handleRouteToPage, language: language, shipmentDetails: shipment, subtotal: subtotal, t: t, total: total }),
        canSetTaxExemptStatus &&
            !shipment.shipment.isCrossBorder &&
            ![schemas_1.EShipmentStatusV1.READY_FOR_PROCESSING].includes(shipment.shipment.status) && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Box, { ml: 1, mt: 3 },
                react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: isTaxExempt, color: "primaryLight", onChange: (event) => {
                            setIsTaxExempt(event.target.checked);
                        } }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t('common:isTaxExempt')) })),
            react_1.default.createElement(ux_1.Collapse, { in: isTaxExempt !== originalTaxExemptState },
                react_1.default.createElement(ux_1.Box, { className: classes.taxExemptConfirmation, mt: 2, p: 3 },
                    isTaxExempt && (react_1.default.createElement(ux_1.Typography, null, t('common:taxExemptDeclaration', {
                        companyName: (_a = shipment === null || shipment === void 0 ? void 0 : shipment.shipment) === null || _a === void 0 ? void 0 : _a.shipperCompanyName,
                    }))),
                    !isTaxExempt && (react_1.default.createElement(ux_1.Typography, null, t('common:taxExemptRemovalConfirmation'))),
                    react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                        react_1.default.createElement(ux_1.Box, { mt: 2 },
                            react_1.default.createElement(__1.SaveButton, { color: "primaryLight", customAction: handleShipmentUpdate, isSaving: areChargesUpdating || isLoading, t: t }, t('common:confirm'))))))))));
};
exports.ShipmentsDashboardSidebarOrderSummary = ShipmentsDashboardSidebarOrderSummary;
