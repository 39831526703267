import { type AppThunk, setAuthProviderData } from '../../../stores';
import { getAuthedUserData, getAuthedUserPermissions } from '../..';
import { TCognitoUserData } from '../../../types';

export const getAuth =
  (
    cognitoUser: TCognitoUserData,
    shouldSkipSuccess?: boolean,
    successRedirect?: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setAuthProviderData({ authProviderData: cognitoUser }));
    dispatch(getAuthedUserData(shouldSkipSuccess, successRedirect));
    dispatch(getAuthedUserPermissions());
  };
