// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteCarrierRefusalReasonV1 = void 0;
var QuoteCarrierRefusalReasonV1;
(function (QuoteCarrierRefusalReasonV1) {
    QuoteCarrierRefusalReasonV1["NO_AVAILABILITY"] = "NO_AVAILABILITY";
    QuoteCarrierRefusalReasonV1["PRICE_TOO_LOW"] = "PRICE_TOO_LOW";
    QuoteCarrierRefusalReasonV1["PICKUP_TIME_UNAVAILABLE"] = "PICKUP_TIME_UNAVAILABLE";
    QuoteCarrierRefusalReasonV1["LOAD_TOO_HEAVY"] = "LOAD_TOO_HEAVY";
})(QuoteCarrierRefusalReasonV1 || (exports.QuoteCarrierRefusalReasonV1 = QuoteCarrierRefusalReasonV1 = {}));
