"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTramIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,9H12V3c0-.017,0-.035,0-.052A2,2,0,1,0,10,5V9H5a5.006,5.006,0,0,0-5,5v5a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V14A5.006,5.006,0,0,0,19,9Zm3,5v2H17V11h2A3,3,0,0,1,22,14ZM9,11h6v5H9ZM5,11H7v5H2V14A3,3,0,0,1,5,11ZM19,22H5a3,3,0,0,1-3-3V18H22v1A3,3,0,0,1,19,22ZM13,2a2,2,0,0,1,4,0A2,2,0,0,1,13,2Zm6.016-.276A1,1,0,0,1,19.821.562l3-.546a1,1,0,0,1,.358,1.968l-3,.545A1,1,0,0,1,19.016,1.724Zm-19,3.455A1,1,0,0,1,.821,4.016l4-.727a1,1,0,0,1,.358,1.968l-4,.727A1,1,0,0,1,.016,5.179Z" })));
exports.default = TruxwebTramIcon;
