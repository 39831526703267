import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  character: {
    borderBottom: `2px solid #C4C4C4`,
    fontSize: theme.spacing(3),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
    width: theme.spacing(5),
  },
  characterInactive: {
    borderBottom: `2px solid #C4C4C4`,
  },
  characterSelected: {
    borderBottom: `2px solid #0087CE`,
  },
  container: {
    height: theme.spacing(86.5),
    margin: 0,
    minWidth: theme.spacing(105),
    overflow: 'hidden',
    padding: 0,
  },
  verificationContainer: {
    alignItems: 'flex-end',
    height: theme.spacing(5),
  },
}));
