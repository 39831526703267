import classnames from 'classnames';
import { getRouteProperties } from '../../utils';
import Link from 'next/link';
import React from 'react';
import { TPageId } from '../../types';
import { useAuthBoolean } from '../../hooks';
import { useRouter } from 'next/router';
import { useStyles } from './PageLink.styles';

type TUrlParam = {
  name: string;
  value: any;
};

type PageLinkProps = {
  children?: React.ReactNode;
  className?: string;
  pageId: TPageId;
  target?: string;
  routeParams?: any[];
  urlParams?: TUrlParam[];
  shouldUseModernVariant?: boolean;
  handleClick?: () => void;
};

export const PageLink = ({
  children,
  className,
  handleClick,
  pageId,
  routeParams,
  shouldUseModernVariant,
  target,
  urlParams,
}: PageLinkProps): JSX.Element => {
  const router = useRouter();
  const classes = useStyles();
  const isAuthed = useAuthBoolean();

  const { locale } = router;

  const routeProperties = getRouteProperties(locale, pageId, routeParams);
  const { requiresAuthentication } = routeProperties;
  let { route } = routeProperties;
  if (requiresAuthentication && !isAuthed) return null;

  if (urlParams && urlParams.length) {
    const params = urlParams
      .map((param) => {
        return `${param.name}=${param.value}`;
      })
      .join('&');
    route = `${route}?${params}`;
  }
  return (
    <Link href={route} locale={locale} onClick={handleClick}>
      <a
        className={classnames(
          shouldUseModernVariant ? classes.modernLink : classes.link,
          className
        )}
        data-testid={`${pageId}-${locale}-pageLink`}
        target={target || '_self'}
      >
        {children}
      </a>
    </Link>
  );
};
