"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierRegistrationServices = void 0;
const ux_1 = require("@truxweb/ux");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("@truxweb/utils");
const styles_1 = require("../../styles");
const CarrierRegistrationServices = ({ accessorialData, addAlert, className, equipmentData, isFirstStep, isFormDisabled, isLastStep, isLoading, isOnlyActiveShown, isTitleSuppressed, onPreviousStep, onSubmitSuccess, registrationDetails, shouldUseStepperButtons, t, }) => {
    const iconClasses = (0, styles_1.useFormIconStyles)();
    const handleSubmitSuccess = (0, react_1.useCallback)((data) => {
        // Force carrier to have at least one item active
        const activeStates = data.equipment.map(({ active }) => active);
        if (!activeStates.includes(true)) {
            addAlert({
                message: t('common:mustHaveAtLeastOneActiveEquipment'),
                severity: 'error',
            });
            return;
        }
        data.accessorial = (0, utils_1.mapCompoundAccessorialState)(data.accessorial);
        onSubmitSuccess(data);
        return;
    }, [onSubmitSuccess, addAlert, t]);
    const { control, getValues, handleSubmit, register, setValue } = (0, react_hook_form_1.useForm)({
        defaultValues: {
            accessorial: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.accessorial) || accessorialData,
            equipment: (registrationDetails === null || registrationDetails === void 0 ? void 0 : registrationDetails.equipment) || equipmentData,
        },
    });
    const { fields: equipmentFields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'equipment',
    });
    const { fields: accessorialFields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'accessorial',
    });
    const content = (react_1.default.createElement(react_1.default.Fragment, null,
        !isFormDisabled && !isTitleSuppressed && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", style: { flexGrow: 1 } },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.IconButton, { onClick: () => {
                        if (onPreviousStep) {
                            onPreviousStep();
                        }
                    } },
                    react_1.default.createElement(ux_1.ArrowBackIcon, { className: iconClasses.incompleteFieldIcon }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, { color: "primary", variant: "h5" }, t('signup:carrierRegistrationServices'))))),
        react_1.default.createElement(ux_1.Box, { className: className, ml: 4, mt: 2, style: { width: '100%' } },
            react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "column" },
                react_1.default.createElement(ux_1.Grid, { item: true, style: { width: '100%' } },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "row", justifyContent: "center", style: { flexGrow: 1 } },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mr: 2, mt: 0.5 },
                                react_1.default.createElement(ux_1.LocalShippingIcon, { className: iconClasses.completeFieldIcon }))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { color: "primary", variant: "h6" }, t('common:equipment')))),
                    react_1.default.createElement(ux_1.Box, { ml: 4.5, mt: 4 },
                        react_1.default.createElement(__1.CarrierEquipmentManagement, { control: control, equipmentData: equipmentData, fields: equipmentFields, isCarrierActive: false, isFormDisabled: isFormDisabled, isOnlyActiveShown: isOnlyActiveShown, register: register, t: t }))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mt: 12.5 },
                        react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "row", justifyContent: "center", style: { flexGrow: 1 } },
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Box, { mr: 2, mt: 0.5 },
                                    react_1.default.createElement(ux_1.AddToPhotosIcon, { className: iconClasses.completeFieldIcon }))),
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(ux_1.Typography, { color: "primary", variant: "h6" }, t('common:additionalServices')))),
                        react_1.default.createElement(ux_1.Box, { ml: 4, mt: 4 },
                            react_1.default.createElement(__1.CarrierAccessorialsManagement, { accessorialData: accessorialData, arePricesRequired: false, control: control, fields: accessorialFields, isCarrierActive: false, isFormDisabled: isFormDisabled, isOnlyActiveShown: isOnlyActiveShown, register: register, setValue: setValue, t: t })))),
                !isFormDisabled && (react_1.default.createElement(ux_1.Grid, { item: true, style: { width: '100%' }, xs: true },
                    shouldUseStepperButtons && (react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.FlatButton, { color: "primary", onClick: () => {
                                    if (onPreviousStep) {
                                        const formData = getValues();
                                        onSubmitSuccess(formData, true);
                                        onPreviousStep();
                                    }
                                }, variant: "outlined" }, !isFirstStep ? t('common:back') : t('common:cancel'))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.FlatButton, { color: "primary", type: "submit", variant: "contained" }, isLastStep ? t('common:lastStep') : t('common:next'))))),
                    !shouldUseStepperButtons && (react_1.default.createElement(ux_1.Box, { ml: 3, mt: 4 },
                        react_1.default.createElement(ux_1.Button, { color: "primary", disabled: isLoading, fullWidth: true, startIcon: isLoading ? react_1.default.createElement(ux_1.CircularProgress, null) : null, style: { height: 45 }, type: "submit", variant: "contained" }, isLastStep ? t('common:lastStep') : t('common:continue'))))))))));
    if (isFormDisabled)
        return content;
    return react_1.default.createElement("form", { onSubmit: handleSubmit(handleSubmitSuccess) }, content);
};
exports.CarrierRegistrationServices = CarrierRegistrationServices;
