import { AccountHeaderItem, NotificationsButton } from '..';
import classnames from 'classnames';
import { Grid } from '@truxweb/ux';
import React from 'react';
import { useAuthBoolean } from '../../hooks';
import { useStyles } from './AuthenticatedHeaderLinks.styles';

type TAuthenticatedHeaderLinksProps = {
  buttonClass?: string;
  iconClass?: string;
};

export const AuthenticatedHeaderLinks = ({
  buttonClass,
  iconClass,
}: TAuthenticatedHeaderLinksProps): JSX.Element => {
  const isAuthed = useAuthBoolean();
  const classes = useStyles();
  if (!isAuthed) return null;
  return (
    <Grid alignItems="center" container direction="row" justifyContent="space-evenly">
      <Grid item>
        <NotificationsButton className={classnames(classes.icon, buttonClass)} size="large" />
      </Grid>
      <Grid item>
        <AccountHeaderItem linkClass={iconClass} />
      </Grid>
    </Grid>
  );
};
