"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarBillOfLading = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const react_1 = __importStar(require("react"));
const ShipmentsDashboardSidebarBillOfLading_styles_1 = require("./ShipmentsDashboardSidebarBillOfLading.styles");
const ShipmentsDashboardSidebarBillOfLading = ({ shipmentEquipment, t, }) => {
    const classes = (0, ShipmentsDashboardSidebarBillOfLading_styles_1.useStyles)();
    const { control, register, trigger } = (0, react_hook_form_1.useFormContext)();
    const [hasTriggered, setHasTriggered] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!hasTriggered) {
            trigger('billOfLading');
            setHasTriggered(true);
        }
    }, [trigger, hasTriggered, setHasTriggered]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
            react_1.default.createElement("input", Object.assign({ id: 'shouldInputBillOfLading', type: "hidden", value: 'true' }, register('shouldInputBillOfLading', { required: false }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mb: 2.5, mt: 2.5 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: shipmentEquipment.billOfLading || '', name: 'billOfLading', render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                                    shrink: true,
                                }, InputProps: {
                                    classes: {
                                        root: classes.referenceNumber,
                                    },
                                }, fullWidth: true, label: t('common:billOfLading'), onChange: onChange, required: true, value: value, variant: "outlined" }));
                        }, rules: { required: true } }))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mb: 2 },
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: shipmentEquipment.trackingId || '', name: 'trackingId', render: ({ field: { onChange, value } }) => {
                            return (react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                                    shrink: true,
                                }, InputProps: {
                                    classes: {
                                        root: classes.referenceNumber,
                                    },
                                }, fullWidth: true, label: t('common:truckId'), onChange: onChange, value: value, variant: "outlined" }));
                        } }))))));
};
exports.ShipmentsDashboardSidebarBillOfLading = ShipmentsDashboardSidebarBillOfLading;
