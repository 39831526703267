import {
  type AppThunk,
  setEquipmentError,
  setEquipmentLoaded,
  setEquipmentLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

export const fetchEquipmentData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setEquipmentLoading());
    const data = await makeRequestToApiGateway('carrierGetV1Equipment', null, {
      'Content-Type': 'application/json',
    });
    dispatch(setEquipmentLoaded({ data }));
  } catch (error) {
    dispatch(setEquipmentError({ error }));
  }
};
