import { TUseActionResponse, useAction } from './useAction';
import { getCompanyPaymentTermsRequest } from '../actions';
import { TShipperPaymentTermsV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const usePaymentTerms = (
  shouldRefetch?: boolean
): TUseActionResponse<TShipperPaymentTermsV1 | null> => {
  const action = useCallback(() => {
    return getCompanyPaymentTermsRequest();
  }, []);

  return useAction<TShipperPaymentTermsV1 | null>(action, true, shouldRefetch || false);
};
