import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import { type TAccessorialV1, type TEquipmentV1 } from '@truxweb/schemas';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TReducerLoading } from '../types';

export type TAccessorialData = TReducerLoading & {
  data: TAccessorialV1[] | null;
};
export type TEquipmentData = TReducerLoading & {
  data: TEquipmentV1[] | null;
};

export interface TCarrierDataState {
  accessorial: TAccessorialData;
  equipment: TEquipmentData;
}

export const carrierSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.company,
      };
    },
  },
  initialState: {
    accessorial: { ...baseLoadingState, data: null },
    equipment: { ...baseLoadingState, data: null },
  } as TCarrierDataState,
  name: 'carrier',
  reducers: {
    setAccessorialError: (state, { payload: { error } }) => {
      return {
        ...state,
        accessorial: manageDomainError(state.accessorial, error),
      };
    },
    setAccessorialLoaded: (state, { payload: { data } }) => {
      return {
        ...state,
        accessorial: manageDomainLoaded(state.accessorial, data),
      };
    },
    setAccessorialLoading: (state) => {
      return { ...state, accessorial: manageDomainLoading(state.accessorial) };
    },

    setEquipmentError: (state, { payload: { error } }) => {
      return {
        ...state,
        equipment: manageDomainError(state.equipment, error),
      };
    },
    setEquipmentLoaded: (state, { payload: { data } }) => {
      return {
        ...state,
        equipment: manageDomainLoaded(state.equipment, data),
      };
    },
    setEquipmentLoading: (state) => {
      return { ...state, equipment: manageDomainLoading(state.equipment) };
    },
  },
});

export const {
  actions: {
    setAccessorialError,
    setAccessorialLoaded,
    setAccessorialLoading,
    setEquipmentError,
    setEquipmentLoaded,
    setEquipmentLoading,
  },
} = carrierSlice;
