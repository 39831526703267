"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    dragDropRoot: {
        backgroundColor: `#EDF8FF`,
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(1),
        minHeight: `0px`,
    },
    dragDropzoneClass: {
        height: theme.spacing(11),
    },
    dragDropzoneParagraphClass: {
        fontSize: `15px`,
        lineHeight: `19px`,
        margin: `0px`,
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
    dragDropzoneSublabel: {
        fontSize: `12px`,
    },
    myDocumentEditableTag: {
        borderRadius: '4px',
        padding: '3px 12px',
    },
    myDocumentRounded: {
        border: '2px solid #C4C4C4',
        borderRadius: theme.spacing(1),
        marginBottom: '0.5rem',
        padding: '0.5rem 1rem',
    },
    notMyDocumentHeader: {
        color: theme.palette.primary.light,
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    success: {
        color: theme.palette.success.main,
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
}));
