"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudSunIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M13.892,24c-1.96,0-3.837-.008-4.491-.026a3.749,3.749,0,0,1-.834-.121,3.5,3.5,0,0,1-.449-6.588,6.366,6.366,0,0,1,.057-2.732,6,6,0,0,1,11.368-.855c.136.326.286.36.587.427l.176.042h0a5.014,5.014,0,0,1,3.559,6,4.951,4.951,0,0,1-3.7,3.7,5.283,5.283,0,0,1-1.064.131C18.229,23.99,16.013,24,13.892,24Zm.09-12.023a4.045,4.045,0,0,0-.589.043A3.994,3.994,0,0,0,10.087,16.8,2.017,2.017,0,0,1,8.907,19.1a1.494,1.494,0,0,0-.851,1.8,1.473,1.473,0,0,0,1.02,1.019,1.673,1.673,0,0,0,.382.057c1.342.036,7.945.03,9.6,0a3.234,3.234,0,0,0,.657-.081,2.977,2.977,0,0,0,2.2-2.193,3.023,3.023,0,0,0-2.132-3.622l-.092-.021a2.582,2.582,0,0,1-2-1.611A4.013,4.013,0,0,0,13.982,11.977ZM2.28,16.894a1,1,0,0,1-.512-1.86l2.709-1.612a.5.5,0,0,0,.3-.54,6.425,6.425,0,0,1-.2-.882H1a1,1,0,0,1,0-2H4.575a6.206,6.206,0,0,1,.387-1.4l-3.078-1.8a1,1,0,1,1,1.01-1.725l3.1,1.814a6.718,6.718,0,0,1,.951-.934L5.11,2.854a1,1,0,0,1,1.721-1.02L8.674,4.943A6.5,6.5,0,0,1,10,4.58V1a1,1,0,0,1,2,0V4.577a6.137,6.137,0,0,1,1.384.386l1.823-3.115a1,1,0,0,1,1.727,1.011L15.091,6.005c.427.274.717,1,1.27.717L19,4.972a1,1,0,1,1,1.1,1.668L17.416,8.42a2.511,2.511,0,0,1-3.147-.442A4.7,4.7,0,0,0,10.986,6.5,4.692,4.692,0,0,0,6.5,11.023,4.115,4.115,0,0,0,6.691,12.3,2.462,2.462,0,0,1,5.46,15.163l-2.669,1.59A1,1,0,0,1,2.28,16.894Z" })));
exports.default = TruxwebCloudSunIcon;
