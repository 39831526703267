import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierLaneV1 } from '@truxweb/schemas';

export const getCarrierLaneById = async (laneId: number | null): Promise<TCarrierLaneV1> => {
  const results = await makeRequestToApiGateway(
    'carrierGetV1LaneByLaneId',
    { laneId },
    {
      'Content-Type': 'application/json',
    }
  );
  return results[0] as TCarrierLaneV1;
};
