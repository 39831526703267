import { makeRequestToApiGateway } from '../../apiUtils';
import { TUserV1 } from '@truxweb/schemas';

export const getUserData = async (): Promise<TUserV1> => {
  const data = await makeRequestToApiGateway('userGetV1User', null, {
    'Content-Type': 'application/json',
  });

  return data as TUserV1;
};
