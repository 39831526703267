"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudMoonRainIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M18.986,24a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v3A1,1,0,0,1,18.986,24Zm-4,0a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v3A1,1,0,0,1,14.986,24Zm-4,0a1,1,0,0,1-1-1V20a1,1,0,0,1,2,0v3A1,1,0,0,1,10.986,24ZM8,22.487a1,1,0,0,1-.6-.2,3.464,3.464,0,0,1-1.281-1.882,3.5,3.5,0,0,1,2-4.143,6.381,6.381,0,0,1,.057-2.732,6,6,0,0,1,11.367-.855c.136.326.287.36.588.427l.175.042A5.041,5.041,0,0,1,22.748,21.3a1,1,0,1,1-1.5-1.328,2.911,2.911,0,0,0,.664-1.272,3.023,3.023,0,0,0-2.133-3.621A2.643,2.643,0,0,1,17.7,13.446,4,4,0,0,0,10.087,15.8,2.016,2.016,0,0,1,8.906,18.1a1.494,1.494,0,0,0-.849,1.8,1.445,1.445,0,0,0,.54.786,1,1,0,0,1-.6,1.8ZM4.5,17a1,1,0,0,1-.577-.184A9.3,9.3,0,0,1,.051,8.272,9.349,9.349,0,0,1,7.779.117a10.038,10.038,0,0,1,3.875.159A2.122,2.122,0,0,1,12.6,3.87a8.025,8.025,0,0,0-2.539,4.283,1,1,0,0,1-1.976-.306,9.784,9.784,0,0,1,3.146-5.435.093.093,0,0,0,.024-.1.116.116,0,0,0-.086-.092,8.092,8.092,0,0,0-3.073-.122A7.321,7.321,0,0,0,2.04,8.478a7.205,7.205,0,0,0,3.033,6.706A1,1,0,0,1,4.5,17ZM19,3a1,1,0,0,0,2,0A1,1,0,0,0,19,3ZM15,6a1,1,0,0,0,2,0A1,1,0,0,0,15,6Zm6,3a1,1,0,0,0,2,0A1,1,0,0,0,21,9Z" })));
exports.default = TruxwebCloudMoonRainIcon;
