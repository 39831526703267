"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCarSideIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.74,10.312l-4.3-6.171A5,5,0,0,0,12.336,2H8.5A5.024,5.024,0,0,0,3.825,5.228L1.753,10.692A4,4,0,0,0,0,14v1.5a2.505,2.505,0,0,0,2,2.45v.55a3.5,3.5,0,0,0,7,0V18h6v.5a3.5,3.5,0,0,0,7,0v-.55a2.505,2.505,0,0,0,2-2.45V15A5.011,5.011,0,0,0,20.74,10.312ZM14.8,5.284,18.084,10H11V4h1.336A3,3,0,0,1,14.8,5.284Zm-9.1.653A3.014,3.014,0,0,1,8.5,4H9v6H4.154ZM7,18.5a1.5,1.5,0,0,1-3,0V18H7ZM18.5,20A1.5,1.5,0,0,1,17,18.5V18h3v.5A1.5,1.5,0,0,1,18.5,20ZM22,15.5a.5.5,0,0,1-.5.5H2.5a.5.5,0,0,1-.5-.5V14a2,2,0,0,1,2-2H19a3,3,0,0,1,3,3Z" })));
exports.default = TruxwebCarSideIcon;
