"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CARRIER_QUOTE_SIDBAR_WIDTH = exports.SIDEBAR_COLUMN_WIDTH = exports.FULL_SCREEN_BREAKPOINT = exports.DEFAULT_LOCATION_HOURS = exports.DEFAULT_LOCATION_SCHEDULE = exports.STANDARD_PALLET_DIMENSIONS = exports.MAX_UPLOAD_FILE_SIZE_BYTES = exports.CSR_DELETABLE_SHIPMENT_DOCS = exports.DEFAULT_MAP_SIZE = exports.DEFAULT_MAP_ZOOM = exports.DEFAULT_MAP_CENTER = exports.DEFAULT_PHONE_COUNTRY_CODE = void 0;
const schemas_1 = require("@truxweb/schemas");
exports.DEFAULT_PHONE_COUNTRY_CODE = '+1';
exports.DEFAULT_MAP_CENTER = { latitude: 39.5, longitude: -98.35 };
exports.DEFAULT_MAP_ZOOM = 4;
exports.DEFAULT_MAP_SIZE = {
    height: 500,
    width: 500,
};
exports.CSR_DELETABLE_SHIPMENT_DOCS = [
    schemas_1.EShipmentDocumentTypeV1.REVISED_PURCHASE_ORDER,
    schemas_1.EShipmentDocumentTypeV1.INVOICE,
    schemas_1.EShipmentDocumentTypeV1.RECEIPT,
    schemas_1.EShipmentDocumentTypeV1.PROOF_OF_DELIVERY,
];
exports.MAX_UPLOAD_FILE_SIZE_BYTES = 20971520;
exports.STANDARD_PALLET_DIMENSIONS = { height: 0, length: 48, width: 40 };
exports.DEFAULT_LOCATION_SCHEDULE = [
    schemas_1.EDayOfWeekV1.Monday,
    schemas_1.EDayOfWeekV1.Tuesday,
    schemas_1.EDayOfWeekV1.Wednesday,
    schemas_1.EDayOfWeekV1.Thursday,
    schemas_1.EDayOfWeekV1.Friday,
];
exports.DEFAULT_LOCATION_HOURS = {
    closeHour: 17,
    openHour: 9,
};
exports.FULL_SCREEN_BREAKPOINT = 944;
exports.SIDEBAR_COLUMN_WIDTH = 424;
exports.CARRIER_QUOTE_SIDBAR_WIDTH = 400;
