"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const EditAltIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 18 18", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M4.76471 9.47059L4.23438 8.94026C4.09372 9.08091 4.01471 9.27168 4.01471 9.47059H4.76471ZM13.2353 1L13.7656 0.46967C13.4727 0.176777 12.9979 0.176777 12.705 0.46967L13.2353 1ZM17 4.76471L17.5303 5.29504C17.8232 5.00214 17.8232 4.52727 17.5303 4.23438L17 4.76471ZM8.52941 13.2353V13.9853C8.72832 13.9853 8.91909 13.9063 9.05974 13.7656L8.52941 13.2353ZM4.76471 13.2353H4.01471C4.01471 13.6495 4.35049 13.9853 4.76471 13.9853V13.2353ZM16.0588 17V17.75C16.2577 17.75 16.4485 17.671 16.5892 17.5303C16.7298 17.3897 16.8088 17.1989 16.8088 17H16.0588ZM1 17H0.25C0.25 17.4142 0.585786 17.75 1 17.75L1 17ZM1 1.94118L1 1.19118C0.585788 1.19118 0.250001 1.52696 0.250001 1.94118L1 1.94118ZM8.52941 2.69118C8.94363 2.69118 9.27941 2.35539 9.27941 1.94118C9.27941 1.52696 8.94363 1.19118 8.52941 1.19118V2.69118ZM16.8088 9.47059C16.8088 9.05637 16.473 8.72059 16.0588 8.72059C15.6446 8.72059 15.3088 9.05637 15.3088 9.47059H16.8088ZM5.29504 10.0009L13.7656 1.53033L12.705 0.46967L4.23438 8.94026L5.29504 10.0009ZM12.705 1.53033L16.4697 5.29504L17.5303 4.23438L13.7656 0.46967L12.705 1.53033ZM16.4697 4.23438L7.99908 12.705L9.05974 13.7656L17.5303 5.29504L16.4697 4.23438ZM8.52941 12.4853H4.76471V13.9853H8.52941V12.4853ZM5.51471 13.2353V9.47059H4.01471V13.2353H5.51471ZM9.88143 4.35386L13.6461 8.11857L14.7068 7.05791L10.9421 3.2932L9.88143 4.35386ZM16.0588 16.25L1 16.25L1 17.75L16.0588 17.75V16.25ZM1.75 17L1.75 1.94118L0.250001 1.94118L0.25 17H1.75ZM1 2.69118L8.52941 2.69118V1.19118L1 1.19118L1 2.69118ZM15.3088 9.47059V17H16.8088V9.47059H15.3088Z", fill: "#636363" })));
exports.default = EditAltIcon;
