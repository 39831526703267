import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyDocuments } from '../../../actions';
import { selectCompanyDocuments } from '../../../selectors';
import type { TCompanyDocument } from '../../../stores';
import { type TReducerLoading } from '../../../types';
import { useEffect } from 'react';

export const useCompanyDocuments = (
  shouldRefetch?: boolean
): TReducerLoading & { data: TCompanyDocument[] } => {
  const dispatch = useDispatch();
  const companyDocumentList = useSelector(selectCompanyDocuments());

  useEffect(() => {
    if (
      (!companyDocumentList.hasLoaded && !companyDocumentList.isLoading) ||
      (!companyDocumentList.isLoading && shouldRefetch)
    ) {
      // NOTE: Always force a fresh list of locations when refetching
      dispatch(fetchCompanyDocuments(true));
    }
  }, [companyDocumentList, dispatch, shouldRefetch]);

  return companyDocumentList;
};
