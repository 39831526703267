import { makeRequestToApiGateway } from '../../apiUtils';
import { type TContactFormRequestV1 } from '@truxweb/schemas';

export const submitContactForm = async (data: TContactFormRequestV1): Promise<any> => {
  return await makeRequestToApiGateway(
    'hubspotPostV1FormByFormType',
    { formType: 'contact' },
    {
      'Content-Type': 'application/json',
    },
    data
  );
};
