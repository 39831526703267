// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentIdentifierTypeV1 = void 0;
var ShipmentIdentifierTypeV1;
(function (ShipmentIdentifierTypeV1) {
    ShipmentIdentifierTypeV1["BILL_OF_LADING"] = "BILL_OF_LADING";
    ShipmentIdentifierTypeV1["ORDER"] = "ORDER";
})(ShipmentIdentifierTypeV1 || (exports.ShipmentIdentifierTypeV1 = ShipmentIdentifierTypeV1 = {}));
