import { Box, Grid, Typography } from '@truxweb/ux';
import { ELanguageV1, EUserTypeV1 } from '@truxweb/schemas';
import React, { useMemo } from 'react';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useTranslation } from 'next-i18next';
import { useUserData } from '../../hooks';

const REQUIRED_NAMESPACES = ['common'];

type TDashboardUpdatesMarketingProps = {
  size?: 'sm' | 'lg';
};

export const DashboardUpdatesMarketing = ({
  size,
}: TDashboardUpdatesMarketingProps): JSX.Element => {
  const { userType } = useUserData();
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);

  const language = useMemo(() => {
    return transformI18nLocaleToLanguage(i18n.language);
  }, [i18n]);

  let headerSize: any = 'bodyLarge';
  let bodySize: any = 'bodyMedium';

  if (size && size === 'lg') {
    headerSize = 'h2';
    bodySize = 'bodyLarge';
  }

  const videos = {
    [EUserTypeV1.CARRIER]: {
      [ELanguageV1.EN_CA]:
        'https://www.loom.com/embed/0b2695703fa34be8a0a20ef78cee2e1c?sid=e81d466c-c409-4294-9f91-f25938d88b3a',
      [ELanguageV1.FR_CA]:
        'https://www.loom.com/embed/782cddcb501145e29b8285ce341aa717?sid=7e9b7800-5b26-4a87-9604-b1ecb63ae38a',
    },
    [EUserTypeV1.SHIPPER]: {
      [ELanguageV1.EN_CA]:
        'https://www.loom.com/embed/03e2a7fe560545389d2c36c04ea8187a?sid=5388d846-5821-41cb-906a-88bb13ed1fd5',
      [ELanguageV1.FR_CA]:
        'https://www.loom.com/embed/a34b319f1cf4418f9cdddbdb62996cee?sid=7f04e50c-4486-4709-ab2f-0e7684e24ae9',
    },
  };

  const video = (
    <iframe
      allowFullScreen
      frameBorder="0"
      height="311"
      src={`${videos[userType][language]}&hideEmbedTopBar=true&hide_title=true&hide_owner=true&hide_share=true`}
      title={'Dashboard Updates Marketing'}
      width="565"
    />
  );
  return (
    <Box p={3} style={{ width: '100%' }}>
      <Grid alignItems="flex-start" container direction="column" spacing={2}>
        <Grid item xs>
          {video}
        </Grid>
        <Grid item xs>
          <Typography color="primaryLight" fontStyle="semibold" variant={headerSize}>
            {t(`common:dashboardUpdatedMarketing-${userType}-h1`)}
          </Typography>
          <Box mt={2}>
            <Typography variant={bodySize}>
              {t(`common:dashboardUpdatedMarketing-${userType}-p1`)}
            </Typography>
          </Box>
          <ul>
            <li>
              <Typography variant={bodySize}>
                {t(`common:dashboardUpdatedMarketing-${userType}-p2`)}
              </Typography>
            </li>

            <li>
              <Typography variant={bodySize}>
                {t(`common:dashboardUpdatedMarketing-${userType}-p3`)}
              </Typography>
            </li>

            <li>
              <Typography variant={bodySize}>
                {t(`common:dashboardUpdatedMarketing-${userType}-p4`)}
              </Typography>
            </li>

            <li>
              <Typography variant={bodySize}>
                {t(`common:dashboardUpdatedMarketing-${userType}-p5`)}
              </Typography>
            </li>
            {userType === EUserTypeV1.CARRIER && (
              <li>
                <Typography variant={bodySize}>
                  {t(`common:dashboardUpdatedMarketing-${userType}-p6`)}
                </Typography>
              </li>
            )}
          </ul>
          {userType === EUserTypeV1.SHIPPER && (
            <Box mt={2}>
              {' '}
              <Typography variant={bodySize}>
                {' '}
                {t(`common:dashboardUpdatedMarketing-${userType}-p6`)}{' '}
              </Typography>{' '}
            </Box>
          )}
          {userType === EUserTypeV1.CARRIER && (
            <Box mt={2}>
              <Typography variant={bodySize}>
                {t(`common:dashboardUpdatedMarketing-${userType}-p7`)}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
