"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    header: {
        fontWeight: 600,
    },
    locationButton: {
        background: 'transparent',
        border: 0,
        color: theme.palette.primary.light,
        cursor: 'pointer',
        textAlign: 'left',
        textDecoration: 'underline',
    },
    locationConnector: {
        background: 'linear-gradient(180deg, #0087CE 0%, rgba(0, 135, 206, 0) 91.77%)',
        width: theme.spacing(0.5),
    },
    locationContainer: {
        height: '100%',
    },
    locationDot: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '100%',
        height: theme.spacing(1.5),
        width: theme.spacing(1.5),
    },
}));
