"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebShieldInterrogationIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m12 15a1 1 0 0 1 -1-1 3.953 3.953 0 0 1 1.963-3.195 1.994 1.994 0 0 0 1-2.124 2.024 2.024 0 0 0 -1.6-1.6 2 2 0 0 0 -2.363 1.971 1 1 0 0 1 -2 0 4 4 0 0 1 1.429-3.065 4 4 0 1 1 4.5 6.57 1.987 1.987 0 0 0 -.929 1.443 1 1 0 0 1 -1 1zm.793 8.76c2.16-.868 9.207-4.281 9.207-11.717v-5.171a4.993 4.993 0 0 0 -3.426-4.746l-6.259-2.075a.985.985 0 0 0 -.63 0l-6.259 2.075a4.993 4.993 0 0 0 -3.426 4.746v5.171c0 6.562 7.005 10.576 9.153 11.65a2.331 2.331 0 0 0 .847.307 2.242 2.242 0 0 0 .793-.24zm5.151-19.736a3 3 0 0 1 2.056 2.848v5.171c0 6.183-6.087 9.111-7.953 9.861-1.888-.944-8.047-4.445-8.047-9.861v-5.171a3 3 0 0 1 2.056-2.848l5.944-1.97zm-5.944 12.976a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" })));
exports.default = TruxwebShieldInterrogationIcon;
