import {
  addUserCompanyLocation,
  archiveCompanyLocation,
  updateUserCompanyLocation,
} from '../../actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlerts, useCompanyLocationById, useErrorHandling } from '../../hooks';
import { CompanyLocationSidebar as BaseCompanyLocationSidebar } from '@truxweb/common-components';
import { companyLocationFromFormData } from '@truxweb/utils';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TCompanyLocationSidebarProps = {
  locationEditId: number | null;
  companyExtId: string;
  companyId: number;
  onSuccess: () => void;
  onDeleteSuccess: () => void;
};

export const CompanyLocationSidebar = ({
  companyExtId,
  companyId,
  locationEditId,
  onDeleteSuccess,
  onSuccess,
}: TCompanyLocationSidebarProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [isSaving, setIsSaving] = useState(false);
  const [hasDiscardChanges, setDiscardChanges] = useState(false);
  const [nextRoute, setNextRoute] = useState<null | string>(null);
  const router = useRouter();

  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm();
  const { data: selectedLocation } = useCompanyLocationById(locationEditId);

  useEffect(() => {
    Object.keys(selectedLocation || {}).forEach((key) => {
      if (key === 'companyLocationName') {
        setValue('addressName', selectedLocation[key] || '');
      } else {
        setValue(key, selectedLocation[key] || '');
      }
    });
  }, [selectedLocation, setValue]);

  const handleRouting = useCallback(
    (route: string) => {
      if (isDirty && !hasDiscardChanges) {
        setNextRoute(route);
        router.events.emit('routeChangeError');
        if (window.confirm(t('common:confirmLeaveUnchanged'))) {
          setDiscardChanges(true);
        }
        throw 'routeChange aborted.';
      }
    },
    [router, isDirty, hasDiscardChanges, setNextRoute, t]
  );

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouting);

    if (nextRoute && ((isDirty && hasDiscardChanges) || !isDirty)) {
      router.push(nextRoute);
    }

    return () => router.events.off('routeChangeStart', handleRouting);
  }, [router, handleRouting, nextRoute, isDirty, hasDiscardChanges]);

  const onSubmitSuccess = useCallback(
    async (data: Record<string, any>) => {
      try {
        setIsSaving(true);
        const companyAddress = companyLocationFromFormData(data, companyId, companyExtId);
        if (locationEditId === 0) {
          await addUserCompanyLocation(companyAddress);
        } else {
          await updateUserCompanyLocation(locationEditId || 0, companyAddress);
        }
        addAlert({
          message: t('common:updateSuccess'),
          severity: 'success',
        });
        onSuccess();
        setDiscardChanges(true);
      } catch (err) {
        errorHandler(err, t('common:updateFailed'));
      } finally {
        setIsSaving(false);
      }
    },
    [
      companyId,
      t,
      errorHandler,
      addAlert,
      onSuccess,
      companyExtId,
      locationEditId,
      setIsSaving,
      setDiscardChanges,
    ]
  );

  const onDelete = useCallback(() => {
    setIsSaving(true);
    dispatch(
      archiveCompanyLocation(
        selectedLocation,
        () => {
          addAlert({
            message: t('common:updateSuccess'),
            severity: 'success',
          });
          setIsSaving(false);
          onDeleteSuccess();
        },
        () => {
          errorHandler(null, t('common:updateFailed'));
          setIsSaving(false);
        }
      )
    );
  }, [selectedLocation, dispatch, addAlert, setIsSaving, t, onDeleteSuccess, errorHandler]);

  return (
    <form onSubmit={handleSubmit(onSubmitSuccess)}>
      <BaseCompanyLocationSidebar
        control={control}
        isSaving={isSaving}
        locationEditId={locationEditId}
        onDelete={onDelete}
        selectedLocation={selectedLocation}
        t={t}
        watch={watch}
      />
    </form>
  );
};
