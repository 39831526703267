"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInputStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useInputStyles = (0, ux_1.makeStyles)(() => ({
    root: {
        borderRadius: '8px',
        height: '40px',
    },
}));
