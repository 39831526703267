import { TUseActionResponse, useAction } from './useAction';
import { getCarrierData } from '../actions';
import { TCarrierV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierData = (forceRefetch?: boolean): TUseActionResponse<TCarrierV1> => {
  const action = useCallback(() => {
    return getCarrierData();
  }, []);

  return useAction<TCarrierV1>(action, true, forceRefetch || false);
};
