"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPeachIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.6,5.235C22.74,2.682,22.513-.173,18.74,0a7.159,7.159,0,0,0-6.191,3.439A4.657,4.657,0,0,0,8,0,1,1,0,0,0,8,2c1.915,0,2.605,1.621,2.855,2.8A7.536,7.536,0,0,0,0,11.5C0,19.588,7.927,24,12,24s12-4.412,12-12.5A7.471,7.471,0,0,0,20.6,5.235ZM18.74,2a5.958,5.958,0,0,1,1.233.125c-.659,2.694-3.12,4-6.48,3.865A5.573,5.573,0,0,1,18.74,2ZM12,22C9.01,22,2,18.428,2,11.5c.232-7.268,10.769-7.268,11,0a12.252,12.252,0,0,1-1.833,5.946,1,1,0,0,0,1.665,1.109c.792-.88,3.532-7.093,1.293-10.563a8.9,8.9,0,0,0,4.928-1.349A5.477,5.477,0,0,1,22,11.5C22,18.428,14.99,22,12,22Z" })));
exports.default = TruxwebPeachIcon;
