import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  linkOrButton: {
    background: 'transparent',
    border: 0,
    color: theme.palette.primary.light,
    fontFamily: 'Roboto',
    margin: 0,
    padding: 0,
  },
}));
