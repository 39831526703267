"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    card: {
        borderRadius: '0px 0px 16px 16px',
    },
    container: {
        background: '#dbdbdb',
        border: '1px solid black',
        borderRadius: theme.spacing(2),
        maxWidth: theme.spacing(64),
        overflow: 'hidden',
        width: '100%',
    },
    currentPercent: {
        background: '#dbdbdb',
        border: '4px solid black',
        borderRadius: '8px',
        fontSize: '16px',
        height: '32px',
        lineHeight: '16px',
        padding: '8px',
        textAlign: 'center',
        width: '64px',
    },
    header: {
        background: ({ section }) => section === 'currentPercent' ? theme.palette.primary.light : theme.palette.secondary.light,
        textAlign: 'center',
        width: '100%',
    },
    upcomingPercent: {
        background: '#dbdbdb',
        border: '2px solid black',
        borderRadius: '8px',
        fontSize: '16px',
        height: '48px',
        lineHeight: '16px',
        padding: '8px',
        textAlign: 'center',
        width: '100%',
    },
}));
