import { TCarrierShipmentQuoteRequestV1, TUpdateCarrierQuoteRequestV1 } from '@truxweb/schemas';
import { makeRequestToApiGateway } from '../../apiUtils';

export const updateQuoteRequestForCarrier = async (
  carrierQuoteRequestId: number,
  request: TUpdateCarrierQuoteRequestV1
): Promise<TCarrierShipmentQuoteRequestV1> => {
  const results = await makeRequestToApiGateway(
    'shipmentPatchV1CarrierQuoteRequestById',
    {
      id: carrierQuoteRequestId,
    },
    { 'Content-Type': 'application/json' },
    request
  );
  const { quoteRequest } = results as Record<string, any>;
  return quoteRequest as TCarrierShipmentQuoteRequestV1;
};
