"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLeafIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.119.872A2.985,2.985,0,0,0,20.714.015C17.921.285,8.528,1.448,4.9,5.072a9.931,9.931,0,0,0-.671,13.281l-3.94,3.94a1,1,0,0,0,1.414,1.414l3.94-3.94A9.929,9.929,0,0,0,18.928,19.1c3.676-3.677,4.8-13.041,5.059-15.823A2.987,2.987,0,0,0,23.119.872Zm-5.6,16.81a7.925,7.925,0,0,1-10.439.657l9.632-9.632a1,1,0,0,0-1.414-1.414L5.661,16.925A7.924,7.924,0,0,1,6.318,6.486C8.827,3.978,15.745,2.5,20.907,2.005A1,1,0,0,1,22,3.088C21.5,8.475,20.059,15.137,17.514,17.682Z" })));
exports.default = TruxwebLeafIcon;
