"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPhonePauseIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19.981,7.023v-6a1,1,0,0,1,2,0v6A1,1,0,0,1,19.981,7.023Zm-3,1a1,1,0,0,0,1-1v-6a1,1,0,0,0-2,0v6A1,1,0,0,0,16.981,8.023Zm6.095,13.116-.912,1.05c-8.19,7.84-28.12-12.084-20.4-20.3l1.15-1A3.08,3.08,0,0,1,7.242.93c.031.03,1.882,2.437,1.882,2.437a3.1,3.1,0,0,1-.005,4.281L7.959,9.1a12.783,12.783,0,0,0,6.932,6.947l1.464-1.165a3.1,3.1,0,0,1,4.282-.007s2.407,1.853,2.438,1.884A3.1,3.1,0,0,1,23.076,21.139ZM21.7,18.216s-2.4-1.842-2.425-1.872a1.121,1.121,0,0,0-1.549,0c-.026.027-2.044,1.635-2.044,1.635a1,1,0,0,1-.979.151A15,15,0,0,1,5.88,9.318a1,1,0,0,1,.146-.995S7.633,6.306,7.661,6.279a1.1,1.1,0,0,0,0-1.55C7.629,4.7,5.788,2.306,5.788,2.306a1.1,1.1,0,0,0-1.51.038L3.127,3.349C-2.513,10.128,14.758,26.442,20.7,20.826l.912-1.05A1.122,1.122,0,0,0,21.7,18.216Z" })));
exports.default = TruxwebPhonePauseIcon;
