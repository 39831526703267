"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const NewCommentsIcon = (props) => (React.createElement("svg", Object.assign({ fill: "#000", height: 24, viewBox: "0 0 27 27", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M7.82456 13.164C8.77244 13.164 9.54086 12.4068 9.54086 11.4727C9.54086 10.5387 8.77244 9.78144 7.82456 9.78144C6.87668 9.78144 6.10827 10.5387 6.10827 11.4727C6.10827 12.4068 6.87668 13.164 7.82456 13.164Z" }),
    React.createElement("path", { d: "M13.096 13.164C14.0439 13.164 14.8123 12.4068 14.8123 11.4727C14.8123 10.5387 14.0439 9.78144 13.096 9.78144C12.1481 9.78144 11.3797 10.5387 11.3797 11.4727C11.3797 12.4068 12.1481 13.164 13.096 13.164Z" }),
    React.createElement("path", { d: "M18.3675 13.164C19.3153 13.164 20.0838 12.4068 20.0838 11.4727C20.0838 10.5387 19.3153 9.78144 18.3675 9.78144C17.4196 9.78144 16.6512 10.5387 16.6512 11.4727C16.6512 12.4068 17.4196 13.164 18.3675 13.164Z" }),
    React.createElement("path", { d: "M21.7088 0.754883H4.47802C2.10084 0.754883 0.172852 2.68287 0.172852 5.06005V17.9806C0.172852 20.3578 2.10084 22.2858 4.47802 22.2858H7.60217L12.3969 26.3386C12.8007 26.6767 13.3861 26.6767 13.7899 26.3386L18.5897 22.2858H21.7138C24.091 22.2858 26.019 20.3578 26.019 17.9806V5.06005C26.014 2.68287 24.086 0.754883 21.7088 0.754883ZM23.8589 17.9806C23.8589 19.1717 22.8949 20.1357 21.7038 20.1357H18.5796C18.0698 20.1357 17.5803 20.3174 17.1917 20.6455L13.0934 24.1078L8.99517 20.6455C8.60655 20.3174 8.11193 20.1357 7.60217 20.1357H4.47802C3.28691 20.1357 2.32291 19.1717 2.32291 17.9806V5.06005C2.32291 3.86894 3.28691 2.90494 4.47802 2.90494H21.7038C22.8949 2.90494 23.8589 3.86894 23.8589 5.06005V17.9806Z" })));
exports.default = NewCommentsIcon;
