"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebDrinkAltIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.341,5.125A2.985,2.985,0,0,0,19,4H14.673L14.8,2.89A1,1,0,0,1,15.79,2H20a1,1,0,0,0,0-2H15.79a3,3,0,0,0-2.981,2.669L12.661,4H5A3.02,3.02,0,0,0,2.056,7.556L3.568,19.622A5.008,5.008,0,0,0,8.529,24H15.47a5.01,5.01,0,0,0,4.962-4.379l1.5-11.968A2.982,2.982,0,0,0,21.341,5.125ZM19,6a1,1,0,0,1,.781.375,1.068,1.068,0,0,1,.179.937L19.622,10H14.006l.445-4ZM4.221,6.374A1,1,0,0,1,5,6h7.437l-.445,4H4.374l-.35-2.784A.993.993,0,0,1,4.221,6.374Zm14.226,13A3.005,3.005,0,0,1,15.47,22H8.529a3,3,0,0,1-2.976-2.626L4.626,12H19.371ZM13.438,15.11l-.444,4a1,1,0,1,1-1.988-.22l.444-4a1.014,1.014,0,0,1,1.105-.884A1,1,0,0,1,13.438,15.11Z" })));
exports.default = TruxwebDrinkAltIcon;
