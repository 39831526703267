"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCherryIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.4,13a3.162,3.162,0,0,0-1.98.565C18.051,9.492,16.137,4.98,12.809.411a1.038,1.038,0,0,0-1.618,0C7.86,4.984,5.946,9.5,5.577,13.575,3.275,12-.059,13.741,0,17.5c0,6.5,8.625,8.906,12,3.448C15.378,26.407,24,24,24,17.5,24,14.684,22.171,13,20.4,13ZM12,2.739a24.5,24.5,0,0,1,4.408,10.723A3.4,3.4,0,0,0,12,14.377a3.4,3.4,0,0,0-4.4-.926A24.51,24.51,0,0,1,12,2.739ZM6.5,22A4.505,4.505,0,0,1,2,17.5C2,15.63,3.077,15,3.568,15c1.368.025,1.132,1.025,2.932,1,1.814.016,1.555-.982,2.9-1,.5,0,1.6.63,1.6,2.5A4.505,4.505,0,0,1,6.5,22Zm11,0A4.505,4.505,0,0,1,13,17.5c0-1.87,1.077-2.5,1.568-2.5,1.368.025,1.132,1.025,2.932,1,1.814.016,1.555-.982,2.9-1,.5,0,1.6.63,1.6,2.5A4.505,4.505,0,0,1,17.5,22Z" })));
exports.default = TruxwebCherryIcon;
