"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    largeBody: {
        fontSize: '20px',
        lineHeight: `${theme.spacing(3)}px`,
    },
}));
