"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    contentContainer: {
        width: '100%',
    },
    detailsTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    fieldName: {
        textAlign: 'left',
    },
    fieldValue: {
        alignSelf: 'center',
        color: theme.palette.primary.light,
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
    },
    header: {
        alignSelf: 'center',
        color: theme.palette.grey[500],
        fontSize: '0.875rem',
        height: 'inherit',
        minWidth: 'max-content',
        width: '96px',
    },
    link: {
        textDecoration: 'none',
    },
    row: {
        height: '21px',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    value: {
        color: theme.palette.primary.light,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
    },
}));
