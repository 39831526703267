"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudRainbowIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M9.236,6.4A17.974,17.974,0,0,1,23,0a1,1,0,0,1,0,2A15.979,15.979,0,0,0,10.764,7.689,1,1,0,1,1,9.236,6.4ZM23,8a10.012,10.012,0,0,0-7.453,3.333,1,1,0,0,0,1.49,1.334A8.012,8.012,0,0,1,23,10,1,1,0,0,0,23,8Zm0-4A13.883,13.883,0,0,0,12.9,8.308a1,1,0,0,0,1.444,1.384A11.9,11.9,0,0,1,23,6,1,1,0,0,0,23,4ZM17.865,20.172a4.951,4.951,0,0,1-3.7,3.7A5.2,5.2,0,0,1,13.1,24c-.865.017-3.08.026-5.2.026-3.695-.111-6.571.708-7.773-2.592a3.625,3.625,0,0,1,2-4.258A6,6,0,0,1,13.542,13.7c.137.326.287.359.588.427l.176.042A5.013,5.013,0,0,1,17.865,20.172ZM13.784,16.1A2.722,2.722,0,0,1,11.7,14.471a4,4,0,0,0-7.609,2.352,1.892,1.892,0,0,1-1.181,2.19A1.566,1.566,0,0,0,3.532,22c1.5.036,7.9.029,9.526,0a3.126,3.126,0,0,0,.658-.082,2.974,2.974,0,0,0,2.2-2.192A3.023,3.023,0,0,0,13.784,16.1ZM23,12a5.957,5.957,0,0,0-4.8,2.4,1,1,0,0,0,1.6,1.2A3.966,3.966,0,0,1,23,14,1,1,0,0,0,23,12Z" })));
exports.default = TruxwebCloudRainbowIcon;
