"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebEnvelopeOpenIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M22.459,8.1,15.535,1.464A5.026,5.026,0,0,0,8.48,1.449L1.54,8.1A5.028,5.028,0,0,0,0,11.708V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V11.708A5.025,5.025,0,0,0,22.459,8.1ZM9.879,2.878a3.013,3.013,0,0,1,4.258.016l6.747,6.464-6.763,6.764a3.074,3.074,0,0,1-4.242,0L3.115,9.358ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V11.708a3.014,3.014,0,0,1,.059-.578l6.406,6.406a5.024,5.024,0,0,0,7.07,0l6.406-6.406a3.014,3.014,0,0,1,.059.578Z" })));
exports.default = TruxwebEnvelopeOpenIcon;
