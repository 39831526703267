import { makeRequestToApiGateway } from '../../../apiUtils';

export const activateCreditCardByIdV2 = async (cardId: number): Promise<void> => {
  await makeRequestToApiGateway(
    'billingPatchV2CardByIdActivate',
    { id: cardId },
    {
      'Content-Type': 'application/json',
    },
    { cardId }
  );
};
