"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADDITIONAL_BLUE_COLOR_052022 = exports.TRUXWEB_THEME_05_2022 = void 0;
const __1 = require("..");
/* eslint-disable sort-keys */
exports.TRUXWEB_THEME_05_2022 = (0, __1.createTheme)({
    breakpoints: {
        values: {
            xs: 360,
            largeMobile: 550,
            sm: 768,
            md: 1024,
            lg: 1440,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            light: '#0087CE',
            main: '#224882',
            dark: '#061946',
        },
        secondary: {
            main: '#FCBE0F',
        },
        error: {
            main: '#E94141',
        },
        grey: {
            [400]: '#C4C4C4',
            [600]: '#636363',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: '#000000',
            secondary: '#42413E',
        },
    },
    typography: {
        fontSize: 15,
        htmlFontSize: 15,
        body1: {
            color: '#636363',
            fontSize: 15,
            lineHeight: '21px',
        },
        h1: {
            color: '#224882',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '3.13em',
            lineHeight: '55px',
        },
        h2: {
            color: '#224882',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '2.5em',
            lineHeight: '45px',
        },
        h3: {
            color: '#636363',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.88em',
            lineHeight: '35px',
        },
        h4: {
            color: '#224882',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1.88em',
            lineHeight: '35px',
        },
        h5: {
            fontFamily: 'Poppins',
            color: '#0087CE',
            fontSize: '1.56em',
            fontWeight: 500,
            lineHeight: '30px',
            fontStyle: 'normal',
        },
        h6: {
            fontFamily: 'Poppins',
            color: '#006CA5',
            fontSize: '1.25em',
            fontWeight: 500,
            lineHeight: '25px',
            fontStyle: 'normal',
        },
    },
    overrides: {
        MuiButton: {
            label: {
                fontFamily: 'Poppins',
                textTransform: 'none',
                fontSize: 15,
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '23px',
                letterSpacing: '0.03em',
                textAlign: 'center',
            },
            root: {
                borderRadius: 40,
                boxShadow: 'unset !important',
                padding: '6px 16px !important',
            },
        },
        MuiOutlinedInput: {
            root: {
                color: '#224882',
                '&:hover': {
                    color: '#224882',
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    border: '1px solid #0087CE',
                    borderColor: '#0087CE',
                },
            },
            notchedOutline: {
                border: '0.5px solid #C4C4C4',
                borderColor: '#C4C4C4',
            },
        },
        MuiSelect: {
            root: {
                borderRadius: 8,
            },
        },
        MuiMenuItem: {
            root: {
                '&:first-child': {
                    borderRadius: '4px 4px 0px 0px',
                },
                '&:last-child': {
                    borderRadius: '0px 0px 4px 4px',
                },
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 15,
                lineHeight: '48px',
                height: '48px',
            },
        },
        MuiListSubheader: {
            root: {
                color: '#636363',
                fontWeight: 'normal',
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    fontWeight: 'normal',
                    color: '#FFF',
                    backgroundColor: '#0087CE',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#224882',
                    },
                },
                color: '#636363',
                '&:hover': {
                    color: '#FFF',
                    backgroundColor: '#0087CE',
                    fontWeight: 'normal',
                },
            },
            button: {
                color: '#636363',
                '&:hover': {
                    color: '#FFF',
                    backgroundColor: '#0087CE',
                    fontWeight: 'normal',
                },
            },
        },
    },
});
/* eslint-enable sort-keys */
exports.ADDITIONAL_BLUE_COLOR_052022 = '#EBF3F9';
