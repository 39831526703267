"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGasPumpSlashIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15.843,20.249A5,5,0,0,1,11,24H5a5.006,5.006,0,0,1-5-5V6A1,1,0,0,1,2,6V9H4a1,1,0,0,1,0,2H2v8a3,3,0,0,0,3,3h6a3,3,0,0,0,2.9-2.249,1,1,0,1,1,1.938.5ZM24,18a3,3,0,0,1-1.826,2.76l1.533,1.533a1,1,0,0,1-1.414,1.414l-22-22A1,1,0,0,1,1.707.293l.535.535A4.986,4.986,0,0,1,5,0h6a5.006,5.006,0,0,1,5,5v9.586L20.414,19H21a1,1,0,0,0,1-1V8a2,2,0,0,1-2-2V3.414L18.293,1.707A1,1,0,0,1,19.707.293l1.99,1.99.02.02.525.525A5.956,5.956,0,0,1,24,6.945C24,6.963,24,18,24,18ZM14,11H12.414L14,12.586ZM3.707,2.293,10.414,9H14V5a3,3,0,0,0-3-3H5A3,3,0,0,0,3.707,2.293Z" })));
exports.default = TruxwebGasPumpSlashIcon;
