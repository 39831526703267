import { useAuth } from '.';
import { useMemo } from 'react';

export const useAuthBoolean = (): boolean => {
  const auth = useAuth();

  return useMemo(() => {
    if (!auth.hasLoaded) return false;
    return Boolean(auth.data);
  }, [auth]);
};
