"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetDetailsFixLocations = void 0;
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("@truxweb/utils");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const utils_2 = require("../../utils");
const RateSheetDetailsFixLocations = ({ addAlert, addressAliases, entriesToFix, googleMapsApiKey, handleCancel, handleSkipStep, isSaving, language, onSubmitSuccess, rateSheetLocations, shouldSuppressCityToggle, t, }) => {
    const [boundaryTypes, setBoundaryTypes] = (0, react_1.useState)({});
    const [cityDetailsState, setCityDetailsState] = (0, react_1.useState)({});
    const [cityEditStates, setCityEditStates] = (0, react_1.useState)({});
    const [locationsToCreate, regionsToConfirm] = (0, react_1.useMemo)(() => {
        if (!addressAliases)
            return [[], []];
        const existingLocations = rateSheetLocations.map((record) => {
            return (0, utils_1.formatRateSheetCityLookupKey)(record);
        });
        const fixedAliases = addressAliases
            .filter((record) => {
            let aliasKey = (0, utils_1.formatRateSheetCityLookupKey)(record);
            if (record.shouldFixManually && record.aliases.length === 1) {
                aliasKey = (0, utils_1.formatRateSheetCityLookupKey)(record.aliases[0]);
            }
            const result = existingLocations.includes(aliasKey);
            return !result;
        })
            .map((record) => {
            let manualFix = {};
            if (record.shouldFixManually && record.aliases.length === 1) {
                manualFix = record.aliases[0];
            }
            return Object.assign(Object.assign(Object.assign({}, record), manualFix), { _source: Object.assign(Object.assign({}, record), manualFix) });
        });
        // GEt a list of regions to validate
        const regions = entriesToFix
            .filter(({ boundaryType }) => {
            return boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION;
        })
            .map((region) => {
            return {
                _source: Object.assign({}, region),
                aliases: [Object.assign({}, (region.cityDetails || {}))],
                boundaryType: schemas_1.ERateSheetBoundaryTypeV1.REGION,
                city: region.name,
                name: region.name,
                shouldFixManually: false,
            };
        });
        return [fixedAliases, regions];
    }, [addressAliases, rateSheetLocations, entriesToFix]);
    const locationsToCreateFormControls = (0, react_hook_form_1.useForm)({
        defaultValues: {
            locationsToCreate,
            regionsToConfirm,
        },
    });
    const { fields } = (0, react_hook_form_1.useFieldArray)({
        control: locationsToCreateFormControls.control,
        name: 'locationsToCreate',
    });
    const { fields: regionFields } = (0, react_hook_form_1.useFieldArray)({
        control: locationsToCreateFormControls.control,
        name: 'regionsToConfirm',
    });
    const renderCityBoundary = (0, react_1.useCallback)((field, index, name) => {
        const boundaryType = boundaryTypes[field.id] || field.boundaryType;
        const cityDetails = cityDetailsState[field.id] || field;
        return (react_1.default.createElement(ux_1.Box, { key: `${field.id}-${index}`, mb: 1, mt: index > 0 ? 1 : 0 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "flex-start" },
                !field.shouldFixManually && boundaryType === schemas_1.ERateSheetBoundaryTypeV1.CITY && (react_1.default.createElement(ux_1.Grid, { item: true }, field.aliases.length === 1 && (react_1.default.createElement(ux_1.Box, { mr: 1 },
                    react_1.default.createElement(ux_1.StandardButton, { onClick: () => {
                            setCityEditStates(Object.assign(Object.assign({}, cityEditStates), { [field.id]: !Boolean(cityEditStates[field.id]) }));
                        } },
                        react_1.default.createElement(ux_1.EditIcon, null)))))),
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", style: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                            width: '164px',
                        } }, field.shouldFixManually
                        ? (0, utils_2.formatCityDetailsString)(field.aliases[0])
                        : (0, utils_2.formatCityDetailsString)(field))),
                !shouldSuppressCityToggle && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                    react_1.default.createElement(ux_1.Box, null,
                        react_1.default.createElement(react_hook_form_1.Controller, { control: locationsToCreateFormControls.control, defaultValue: boundaryType, name: `${name}.${index}.boundaryType`, render: ({ field: { onChange, value } }) => {
                                return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { checked: value === schemas_1.ERateSheetBoundaryTypeV1.CITY, color: "grey400", onChange: (event) => {
                                            const newValue = event.target.checked
                                                ? schemas_1.ERateSheetBoundaryTypeV1.CITY
                                                : schemas_1.ERateSheetBoundaryTypeV1.REGION;
                                            setBoundaryTypes(Object.assign(Object.assign({}, boundaryTypes), { [field.id]: newValue }));
                                            onChange(newValue);
                                        } }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t(`common:hasBeenConfirmedAsCity`)) }));
                            } }))))),
            (field.shouldFixManually ||
                (field.aliases.length === 1 && cityEditStates[field.id] === true) ||
                field.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION) &&
                boundaryType === schemas_1.ERateSheetBoundaryTypeV1.CITY && (react_1.default.createElement(ux_1.Box, { mt: 1 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: locationsToCreateFormControls.control, defaultValue: field.name, name: `${name}.${index}.name`, render: ({ field: { onChange }, fieldState: { error } }) => {
                        return (react_1.default.createElement(__1.LocationAutocomplete, { InputLabelProps: {
                                shrink: true,
                            }, areFormValuesRequired: field.shouldFixManually ||
                                field.boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION, customCompleteValues: [], favorites: [], googleMapsApiKey: googleMapsApiKey, handleError: () => {
                                locationsToCreateFormControls.setValue(`${name}.${index}.name`, '');
                            }, hasError: Boolean(error), label: t('common:locationToAdd'), language: language, locationTypes: [], onChange: (place, geoCode) => __awaiter(void 0, void 0, void 0, function* () {
                                try {
                                    locationsToCreateFormControls.clearErrors([
                                        `${name}.${index}.name`,
                                    ]);
                                    const location = yield (0, utils_1.convertGooglePlaceDataToShipperBookingV1)(place, geoCode, {});
                                    const updatedCityDetails = Object.assign(Object.assign({}, location.address), { latitude: location.lat, longitude: location.lng });
                                    if (!updatedCityDetails.city) {
                                        locationsToCreateFormControls.setError(`${name}.${index}.name`, {
                                            message: 'City cannot be determined from selected location',
                                            type: 'custom',
                                        });
                                        addAlert({
                                            message: 'City cannot be determined from selected location',
                                            severity: 'error',
                                        });
                                        return;
                                    }
                                    locationsToCreateFormControls.setValue(`${name}.${index}.country`, updatedCityDetails.country);
                                    locationsToCreateFormControls.setValue(`${name}.${index}.province`, updatedCityDetails.province);
                                    locationsToCreateFormControls.setValue(`${name}.${index}.city`, updatedCityDetails.city);
                                    locationsToCreateFormControls.setValue(`${name}.${index}.latitude`, updatedCityDetails.latitude);
                                    locationsToCreateFormControls.setValue(`${name}.${index}.longitude`, updatedCityDetails.longitude);
                                    setCityDetailsState(Object.assign(Object.assign({}, cityDetailsState), { [field.id]: updatedCityDetails }));
                                    onChange(place.description);
                                }
                                catch (err) {
                                    locationsToCreateFormControls.setValue(`${name}.${index}.name`, '');
                                }
                            }), onClear: () => {
                                locationsToCreateFormControls.setValue(`${name}.${index}.name`, '');
                            }, placeholder: t('common:confirmLocation'), value: { place: { description: '' } } || '' }));
                    }, rules: { required: field.shouldFixManually } }),
                react_1.default.createElement(__1.SimpleList, { items: [
                        { header: t('common:city'), value: cityDetails.city },
                        { header: t('common:province'), value: cityDetails.province },
                        { header: t('common:country'), value: cityDetails.country },
                    ] }))),
            boundaryType === schemas_1.ERateSheetBoundaryTypeV1.REGION && (react_1.default.createElement(ux_1.Box, { mt: 0.5 },
                react_1.default.createElement(ux_1.Typography, null, t('common:willBeDefinedNextStep'))))));
    }, [
        addAlert,
        language,
        shouldSuppressCityToggle,
        t,
        locationsToCreateFormControls,
        boundaryTypes,
        cityDetailsState,
        cityEditStates,
        googleMapsApiKey,
    ]);
    const [aliasedLocations, confirmedLocations, unconfirmedLocations] = (0, react_1.useMemo)(() => {
        const aliasedLocations = [];
        const confirmedLocations = [];
        const unconfirmedLocations = [];
        fields.forEach((field, index) => {
            if (field.aliases.length > 1) {
                aliasedLocations.push(renderCityBoundary(field, index, 'locationsToCreate'));
            }
            if (field.aliases.length === 1 && !field.shouldFixManually) {
                confirmedLocations.push(renderCityBoundary(field, index, 'locationsToCreate'));
            }
            if (field.aliases.length === 1 && field.shouldFixManually) {
                unconfirmedLocations.push(renderCityBoundary(field, index, 'locationsToCreate'));
            }
        });
        return [aliasedLocations, confirmedLocations, unconfirmedLocations];
    }, [fields, renderCityBoundary]);
    (0, react_1.useEffect)(() => {
        if (!locationsToCreate.length && !regionsToConfirm.length) {
            handleSkipStep();
        }
    }, [locationsToCreate, handleSkipStep, regionsToConfirm]);
    return (react_1.default.createElement("form", { onSubmit: locationsToCreateFormControls.handleSubmit(onSubmitSuccess) },
        Boolean(fields.length) && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                react_1.default.createElement(ux_1.Explanation, { label: t('common:rateSheetLocationDetectedLocations') },
                    react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3, p: 3, style: { background: '#f3f3f3', borderRadius: '16px', width: '345px' } },
                        react_1.default.createElement(ux_1.Typography, null, t('common:rateSheetLocationDetectedLocationsExplanation'))))),
            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3, p: 0.5, style: { background: '#f3f3f3', borderRadius: '16px', width: '345px' } },
                unconfirmedLocations.length !== 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                        react_1.default.createElement(ux_1.Explanation, { label: t('common:unconfirmedLocations') },
                            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3, p: 3, style: { background: '#f3f3f3', borderRadius: '16px', width: '300px' } },
                                react_1.default.createElement(ux_1.Typography, null, t('common:unconfirmedLocationsExplanation'))))),
                    unconfirmedLocations)),
                confirmedLocations.length !== 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
                        react_1.default.createElement(ux_1.Explanation, { label: t('common:confirmedLocations') },
                            react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3, p: 3, style: { background: '#f3f3f3', borderRadius: '16px', width: '300px' } },
                                react_1.default.createElement(ux_1.Typography, null, t('common:confirmedLocationsExplanation'))))),
                    confirmedLocations)),
                aliasedLocations.length !== 0 && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                    react_1.default.createElement(ux_1.Explanation, { label: t('common:locationGroups') },
                        react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3, p: 3, style: { background: '#f3f3f3', borderRadius: '16px', width: '300px' } },
                            react_1.default.createElement(ux_1.Typography, null, t('common:locationGroupsExplanation')))),
                    aliasedLocations))))),
        Boolean(regionFields.length) && (react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Explanation, { label: t('common:rateSheetLocationDetectedRegions') },
                react_1.default.createElement(ux_1.Box, { mb: 3, mt: 3, p: 3, style: { background: '#f3f3f3', borderRadius: '16px', width: '345px' } },
                    react_1.default.createElement(ux_1.Typography, null, t('common:rateSheetLocationDetectedRegionsExplanation')))),
            regionFields.map((field, index) => {
                return renderCityBoundary(field, index, 'regionsToConfirm');
            }))),
        react_1.default.createElement(ux_1.Box, { mt: 3 },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "space-between" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.StandardButton, { onClick: handleCancel }, t('common:back'))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(__1.SaveButton, { isSaving: isSaving, t: t }, t('common:next')))))));
};
exports.RateSheetDetailsFixLocations = RateSheetDetailsFixLocations;
