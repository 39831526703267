"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebHouseFloodIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", null,
        React.createElement("path", { d: "M6.928,20.627h0Z" }),
        React.createElement("path", { d: "M6.929,20.631v0Z" }),
        React.createElement("path", { d: "M6.928,20.626Z" }),
        React.createElement("path", { d: "M5.07,20.633c0-.013-.006.013,0,0Z" }),
        React.createElement("path", { d: "M22.332,21.49a2.2,2.2,0,0,1-3.387-.817,1.006,1.006,0,0,0-1.89,0,2.278,2.278,0,0,1-4.11,0,1.006,1.006,0,0,0-1.89,0,2.274,2.274,0,0,1-4.126-.041,1.012,1.012,0,0,0-1.859,0,2.207,2.207,0,0,1-3.4.856A1,1,0,1,0,.332,22.978,4.186,4.186,0,0,0,6,22.775a4.336,4.336,0,0,0,6,0,4.336,4.336,0,0,0,6,0,4.185,4.185,0,0,0,5.668.2A1,1,0,0,0,22.332,21.49Z" }),
        React.createElement("path", { d: "M2,17V11.169a1,1,0,0,1,.293-.707L9.878,2.879a3.008,3.008,0,0,1,4.244,0l7.585,7.586a1.009,1.009,0,0,1,.293.7V17a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V11.169a2.978,2.978,0,0,0-.879-2.121L15.536,1.462a5.008,5.008,0,0,0-7.072,0L.879,9.048A2.978,2.978,0,0,0,0,11.169V17a1,1,0,0,0,1,1H1A1,1,0,0,0,2,17Z" }),
        React.createElement("path", { d: "M12,13.031a5,5,0,0,0-4.821,3.681A1.01,1.01,0,0,0,8.14,18h0a1.034,1.034,0,0,0,.977-.79,3,3,0,0,1,5.766,0,1.034,1.034,0,0,0,.977.79h0a1.01,1.01,0,0,0,.961-1.288A5,5,0,0,0,12,13.031Z" }))));
exports.default = TruxwebHouseFloodIcon;
