"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)(() => ({
    'cls-1': {
        fill: '#d1e9f9',
    },
    'cls-2': {
        fill: '#aaceed',
    },
    'cls-3': {
        fill: '#4292cf',
    },
    'cls-4': {
        fill: '#0c4d93',
    },
    'cls-5': {
        fill: '#2873ac',
    },
    'cls-6': {
        fill: '#1f376b',
    },
    'cls-7': {
        opacity: '0.3',
    },
    'cls-8': {
        fill: '#76b5e3',
    },
    'cls-9': {
        fill: '#1887c9',
    },
}));
