import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierV1 } from '@truxweb/schemas';

export const updateCarrier = async (data: TCarrierV1): Promise<any> => {
  return await makeRequestToApiGateway(
    'carrierPatchV1Carrier',
    null,
    {
      'Content-Type': 'application/json',
    },
    {
      ...data,
    }
  );
};
