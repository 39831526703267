import { makeStyles, Theme } from '@truxweb/ux';

export const useHomeStyles = makeStyles<Theme>((theme: Theme) => ({
  '@keyframes bounce': {
    '0%': {
      animationTimingFunction: 'ease-in',
      transform: 'translate3d(50px, 40px, 0px)',
    },

    '100%': {
      transform: 'translate3d(50px, 40px, 0px)',
    },
    '50%': {
      animationTimingFunction: 'ease-out',
      transform: 'translate3d(50px, 60px, 0px)',
    },
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  accentDark: { color: theme.palette.primary.dark },
  accentMain: { color: theme.palette.primary.main },
  blueRoad: {
    marginBottom: theme.spacing(5),
    marginLeft: '10%',
    marginTop: '-50px',
    maxHeight: theme.spacing(68),
    minHeight: theme.spacing(30),
    position: 'relative',
    width: '100%',
    zIndex: -5,
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(30),
      marginTop: '-120px',
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(30),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg - 200)]: {
      height: theme.spacing(55),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(55),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl - 200)]: {
      height: theme.spacing(68),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(68),
    },
  },
  bodyCopyContainer: {
    marginTop: theme.spacing(7),
    maxWidth: '100vw',
    position: 'relative',
    width: '100%',
  },
  bodyCopyOne: {
    fontStyle: 'italic',
    marginBottom: theme.spacing(2),
  },
  bodyCopyTwo: {
    color: theme.palette.primary.light,
    fontStyle: 'italic',
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase',
  },
  bodyTitle: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(6),
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(3.75),
    },
  },
  bookADemo: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.down(theme.breakpoints.values.xs + 360)]: {
      marginLeft: 0,
      marginRight: 0,
      width: '100vw',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(16),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(20),
    },
  },
  cloudOne: {
    animation: '$bounce 8s infinite linear',
    height: theme.spacing(8),
    left: '-18%',
    position: 'absolute',
    top: '40%',
    width: '204px',
    zIndex: -1,
  },
  cloudThree: {
    animation: '$bounce 13s infinite linear',
    height: theme.spacing(6),
    position: 'absolute',
    right: 0,
    top: '63%',
    width: '130px',
    zIndex: -1,
  },
  cloudTwo: {
    animation: '$bounce 10s infinite linear',
    height: theme.spacing(5),
    position: 'absolute',
    right: '10%',
    top: '10%',
    width: '208px',
    zIndex: -1,
  },
  connectsShippersText: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },
  connectsTextWrap: {
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(7),
    maxWidth: '60%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(18),
      marginTop: theme.spacing(18),
    },
  },
  container: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    maxWidth: '100vw',
    position: 'relative',
    width: '100%',
    zIndex: -1,
  },
  freightNeedsToChangeWrap: {
    position: 'relative',
  },
  gear: {
    animation: '$rotate 20s infinite',
    maxHeight: theme.spacing(27),
    maxWidth: theme.spacing(27),
    position: 'absolute',
    right: '10%',
    top: '-50%',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(15),
      top: '-35%',
      width: theme.spacing(15),
    },
  },
  greatForCompaniesText: {
    width: '100%',
    [theme.breakpoints.between('xs', 'lg')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '10%',
      maxWidth: '63%',
    },
  },
  gridMargin: {
    [theme.breakpoints.between('md', 'lg')]: {
      minWidth: '130px',
    },
  },
  newsletter: {
    width: theme.spacing(85),
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  newsletterContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  readyToTakeControl: {
    fontWeight: 600,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
  },
  readyToTakeControlText: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(6),
    },
  },
  splitScreen: {
    width: '100%',
    [theme.breakpoints.up('largeMobile')]: {
      maxWidth: '550px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
  },
  valuePropCard: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(61),
      maxWidth: '314px',
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(61),
      maxWidth: '328px',
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(63),
      maxWidth: '374px',
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(97),
      maxWidth: theme.spacing(66),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(97),
      width: theme.spacing(66),
    },
  },
}));
