"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSpaceShuttleIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M464.933,193.233c-27.519-15.048-58.44-22.78-89.804-22.456h-86.075L224.093,54.789  c-18.86-33.614-54.392-54.435-92.935-54.457h-24.629c-35.3,0-63.917,28.617-63.917,63.917v42.611  c0,11.767,9.539,21.306,21.306,21.306s21.306-9.539,21.306-21.306V64.249c0-11.767,9.539-21.306,21.306-21.306h24.629  c23.13,0.003,44.454,12.503,55.757,32.683l53.307,95.151H63.917C28.617,170.777,0,199.394,0,234.694v42.611  c0,35.3,28.617,63.917,63.917,63.917h176.305l-53.264,95.151c-11.31,20.194-32.654,32.695-55.8,32.683h-24.629  c-11.767,0-21.306-9.539-21.306-21.306V405.14c0-11.767-9.539-21.306-21.306-21.306s-21.306,9.539-21.306,21.306v42.611  c0,35.3,28.617,63.917,63.917,63.917h24.629c38.544-0.022,74.076-20.843,92.935-54.457l64.961-115.988h86.075  c31.363,0.324,62.284-7.408,89.804-22.456C527.657,281.567,527.721,230.433,464.933,193.233z M356.402,298.611H127.834v-85.223  H356.38c23.536,11.467,33.321,39.842,21.854,63.379C373.599,286.282,365.914,293.971,356.402,298.611z M42.611,277.306v-42.611  c0-11.767,9.539-21.306,21.306-21.306h21.306v85.223H63.917C52.15,298.611,42.611,289.073,42.611,277.306z M444.053,281.567  c-8.363,4.656-17.226,8.351-26.419,11.015c11.042-23.183,11.042-50.109,0-73.292c9.194,2.664,18.056,6.359,26.419,11.015  C476.63,249.118,476.63,262.882,444.053,281.567z" })));
exports.default = TruxwebSpaceShuttleIcon;
