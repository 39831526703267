import { makeRequestToApiGateway } from '../../apiUtils';
import { TCarrierShipmentQuoteRequestV1 } from '@truxweb/schemas';

export const fetchCarrierQuoteRequestById = async (
  carrierRequestId: number
): Promise<TCarrierShipmentQuoteRequestV1> => {
  const results = await makeRequestToApiGateway('shipmentGetV1CarrierQuoteRequestById', {
    id: carrierRequestId,
  });
  const { quoteRequest } = results as { quoteRequest: TCarrierShipmentQuoteRequestV1 };
  return quoteRequest;
};
