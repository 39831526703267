"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentMap = void 0;
const __1 = require("..");
const react_1 = __importStar(require("react"));
const mapUtils_1 = require("../../mapUtils");
const ShipmentMap = ({ defaultMapCenter, defaultMapZoom, destinationCoords, googleApiKey, height, isDraggableSuppressed, isFullscreenSuppressed, language, mapTheme, originCoords, shipment, t, width, }) => {
    const [hasMapInitialized, setMapInitialized] = (0, react_1.useState)(false);
    const [googleMapsApiReference, setGoogleMapsApiReference] = (0, react_1.useState)({
        mapReference: null,
        mapsApiReference: null,
    });
    (0, react_1.useEffect)(() => {
        const { mapReference, mapsApiReference } = googleMapsApiReference;
        if (destinationCoords && originCoords && mapReference && mapsApiReference) {
            setMapInitialized(true);
        }
    }, [googleMapsApiReference, originCoords, destinationCoords, shipment]);
    (0, react_1.useEffect)(() => {
        if (hasMapInitialized) {
            (0, mapUtils_1.showRouteOnMap)(Object.assign({ destination: destinationCoords, origin: originCoords }, googleMapsApiReference));
        }
    }, [hasMapInitialized, originCoords, destinationCoords, googleMapsApiReference]);
    return (react_1.default.createElement(__1.TruxwebGoogleMap, { googleApiKey: googleApiKey, height: height, isDraggableSuppressed: isDraggableSuppressed, isFullscreenSuppressed: isFullscreenSuppressed, language: language, mapCenter: defaultMapCenter, mapTheme: mapTheme, mapZoom: defaultMapZoom, onRegisterMapsReference: (map) => {
            if (!googleMapsApiReference.mapReference || !googleMapsApiReference.mapsApiReference) {
                setGoogleMapsApiReference(map);
            }
        }, width: width },
        react_1.default.createElement(__1.MapMarker, { lat: originCoords === null || originCoords === void 0 ? void 0 : originCoords.latitude, lng: originCoords === null || originCoords === void 0 ? void 0 : originCoords.longitude, t: t, type: "origin" }),
        react_1.default.createElement(__1.MapMarker, { lat: destinationCoords === null || destinationCoords === void 0 ? void 0 : destinationCoords.latitude, lng: destinationCoords === null || destinationCoords === void 0 ? void 0 : destinationCoords.longitude, t: t, type: "dest" })));
};
exports.ShipmentMap = ShipmentMap;
