"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const MastercardUnselectedIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 53 32", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "#F3F3F3", height: 32, rx: 6.5, width: 52, x: 0.0742188 }),
    React.createElement("g", { style: {
            mixBlendMode: 'luminosity',
        } },
        React.createElement("path", { d: "M29.5908 21.7386H22.5586V9.20508H29.5908V21.7386Z", fill: "#FF5F00" }),
        React.createElement("path", { d: "M23.0082 15.4703C23.0082 12.9279 24.2085 10.6631 26.0778 9.20357C24.7108 8.1363 22.9857 7.49928 21.1108 7.49928C16.6722 7.49928 13.0742 11.068 13.0742 15.4703C13.0742 19.8727 16.6722 23.4414 21.1108 23.4414C22.9857 23.4414 24.7108 22.8044 26.0778 21.7371C24.2085 20.2776 23.0082 18.0128 23.0082 15.4703Z", fill: "#EB001B" }),
        React.createElement("path", { d: "M39.0744 15.4703C39.0744 19.8727 35.4764 23.4414 31.0378 23.4414C29.1629 23.4414 27.4378 22.8044 26.0703 21.7371C27.9401 20.2776 29.1404 18.0128 29.1404 15.4703C29.1404 12.9279 27.9401 10.6631 26.0703 9.20357C27.4378 8.1363 29.1629 7.49928 31.0378 7.49928C35.4764 7.49928 39.0744 11.068 39.0744 15.4703Z", fill: "#F79E1B" }))));
exports.default = MastercardUnselectedIcon;
