"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    container: {
        paddingBottom: theme.spacing(5),
        paddingLeft: 0,
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(8.3125),
    },
    creditsContainer: {
        background: theme.palette.primary.main,
        borderRadius: 32,
        height: 42,
        width: 106,
    },
    creditsRight: {
        background: `linear-gradient(290deg, ${theme.palette.primary.light} 5.7ch, ${theme.palette.primary.main} 1ch)`,
        height: 42,
    },
    subscriptionIcon: {
        '& > g path': {
            fill: 'white',
        },
        '& > rect': {
            fill: 'transparent',
        },
        height: '42px',
        width: '42px',
    },
}));
