import { selectSearchLoad } from '../../../selectors';
import { type TLoadDefinitionV1 } from '@truxweb/schemas';
import { useSelector } from 'react-redux';

export const useSearchLoad = ():
  | (TLoadDefinitionV1 & {
      isExistingSearch: boolean;
    })
  | null => {
  return useSelector(selectSearchLoad());
};
