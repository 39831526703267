import { type AppThunk, setLoadParametersListError } from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';
import { TLoadParametersV1 } from '@truxweb/schemas';

export const updateLoadParameters =
  (loadParameters: TLoadParametersV1): AppThunk =>
  async (dispatch) => {
    try {
      await updateLoadParametersAction(loadParameters);
    } catch (error) {
      dispatch(setLoadParametersListError({ error: error.message }));
    }
  };

export const updateLoadParametersAction = async (loadParameters: TLoadParametersV1) => {
  await makeRequestToApiGateway(
    'shipmentPatchV1ShipperLoad',
    null,
    {
      'Content-Type': 'application/json',
    },
    loadParameters
  );
};
