"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSnowplowIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M504.653,457.833c-27.22-27.172-42.434-64.107-42.246-102.568V171.988c0-11.608-9.41-21.018-21.018-21.018  s-21.018,9.41-21.018,21.018v84.072h-63.264v-0.21c-0.098-29.174-20.197-54.472-48.594-61.163l-49.75-111.291  C232.007,13.932,110.144-3.155,105.1,87.748v42.204c-46.432,0-84.072,37.64-84.072,84.072c0,0.035,0,0.07,0,0.105l0.126,104.796  c-34.561,47.089-24.406,113.28,22.683,147.841c17.784,13.053,39.204,20.218,61.263,20.492h168.144  c58.395-0.711,105.158-48.626,104.446-107.021c-0.268-21.998-7.39-43.364-20.374-61.123v-21.018h63.054v57.169  c-0.175,49.598,19.503,97.205,54.647,132.203c7.55,8.817,20.819,9.843,29.635,2.293c8.817-7.55,9.844-20.819,2.293-29.635  C506.242,459.304,505.475,458.538,504.653,457.833z M168.154,66.688c22.554,0.012,43.001,13.259,52.23,33.839l41.342,92.479H168.28  c-11.608,0-21.018-9.41-21.018-21.018l-0.126-84.282C147.136,76.098,156.546,66.688,168.154,66.688z M75.422,184.389  c7.839-7.932,18.526-12.397,29.677-12.401l0,0v0.105c0.058,34.783,28.271,62.949,63.054,62.949h125.898  c11.551,0,20.937,9.32,21.018,20.871v29.909c-13.191-5.762-27.431-8.739-41.826-8.744H105.1c-14.406-0.014-28.66,2.948-41.868,8.701  l-0.168-71.756c-0.021-11.154,4.431-21.852,12.359-29.699L75.422,184.389z M273.244,445.223H105.1  c-34.824,0-63.054-28.23-63.054-63.054s28.23-63.054,63.054-63.054h168.144c34.824,0,63.054,28.23,63.054,63.054  S308.068,445.223,273.244,445.223z M126.118,382.169c0,11.608-9.41,21.018-21.018,21.018s-21.018-9.41-21.018-21.018  c0-11.608,9.41-21.018,21.018-21.018S126.118,370.561,126.118,382.169z M294.262,382.169c0,11.608-9.41,21.018-21.018,21.018  c-11.608,0-21.018-9.41-21.018-21.018c0-11.608,9.41-21.018,21.018-21.018C284.852,361.15,294.262,370.561,294.262,382.169z   M210.19,382.169c0,11.608-9.41,21.018-21.018,21.018s-21.018-9.41-21.018-21.018c0-11.608,9.41-21.018,21.018-21.018  S210.19,370.561,210.19,382.169z" })));
exports.default = TruxwebSnowplowIcon;
