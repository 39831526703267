"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBadgeStyles = exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    chatButton: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(1),
    },
    selectedChatButton: {
        background: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
}));
exports.useBadgeStyles = (0, ux_1.makeStyles)((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
    },
}));
