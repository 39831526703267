"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EQUIPMENT_CATEGORY_SORTING = void 0;
const FLATBED_SORT_ORDER = [
    'FLATBED_DUAL_AXLE',
    'FLATBED_TRIPLE_AXLE',
    'FLATBED_QUAD_AXLE',
    'FLATBED_B_TRAIN',
    'STEP_DECK',
    'FLATBED_TRIPLE_AXLE_DROP_DECK',
    'DOUBLE_DROP_DECK',
    'FLATBED_RGN',
];
const DRY_VAN_SORT_ORDER = ['53_DRY_FTL', 'DRY_VAN_TRIPLE_AXLE'];
const REEFER_SORT_ORDER = ['53_REEFER_FTL'];
exports.EQUIPMENT_CATEGORY_SORTING = {
    DRY_VAN: DRY_VAN_SORT_ORDER,
    FLATBED: FLATBED_SORT_ORDER,
    REEFER: REEFER_SORT_ORDER,
};
