import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    maxWidth: theme.spacing(62),
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 1rem',
    },
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      padding: '2rem 2rem',
    },
  },
}));
