// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TmsPackagesV1 = void 0;
var TmsPackagesV1;
(function (TmsPackagesV1) {
    TmsPackagesV1["McCloud"] = "McCloud";
    TmsPackagesV1["LoadMasters"] = "LoadMasters";
    TmsPackagesV1["ClearDestination"] = "ClearDestination";
    TmsPackagesV1["Unicom"] = "Unicom";
    TmsPackagesV1["Bell"] = "Bell";
    TmsPackagesV1["DispatchMate"] = "DispatchMate";
    TmsPackagesV1["Transplus"] = "Transplus";
    TmsPackagesV1["RoseRocket"] = "RoseRocket";
    TmsPackagesV1["Truckmate"] = "Truckmate";
    TmsPackagesV1["TMWSuite"] = "TMWSuite";
    TmsPackagesV1["TMWICC"] = "TMWICC";
    TmsPackagesV1["None"] = "None";
})(TmsPackagesV1 || (exports.TmsPackagesV1 = TmsPackagesV1 = {}));
