import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  buttonText: {
    color: theme.palette.primary.dark,
  },
  selected: {
    background: 'rgba(0, 135, 206, 0.15)',
  },
}));
