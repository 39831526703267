import { makeStyles, Theme } from '@truxweb/ux';

type THeaderButtonType = {
  variant?: 'midnight' | 'column';
};

export const useStyles = makeStyles<Theme, THeaderButtonType>((theme: Theme) => ({
  login: {
    '&:active,&:hover': {
      border: ({ variant }) => {
        return variant === 'midnight'
          ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
          : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
      },
      color: ({ variant }) => {
        return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
      },
    },
    background: 'transparent',
    border: ({ variant }) => {
      return variant === 'midnight'
        ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`
        : `${theme.spacing(0.25)}px solid ${theme.palette.common.white}`;
    },
    color: ({ variant }) => {
      return variant === 'midnight' ? theme.palette.primary.main : theme.palette.common.white;
    },
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
  },
  standard: {
    '&:active,&:hover': {
      background: 'transparent',
      border: ({ variant }) => {
        return variant === 'midnight'
          ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
          : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
      },
      color: ({ variant }) => {
        return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
      },
    },
    background: ({ variant }) => {
      return variant === 'midnight' ? theme.palette.primary.dark : theme.palette.primary.main;
    },
    border: ({ variant }) => {
      return variant === 'midnight'
        ? `${theme.spacing(0.25)}px solid ${theme.palette.primary.dark}`
        : `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`;
    },
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
  },
}));
