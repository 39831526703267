import { TUseActionResponse, useAction } from './useAction';
import { getCarrierAccessorialData } from '../actions';
import { TCarrierAccessorialV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCarrierAccessorialData = (
  shouldRefetch?: boolean
): TUseActionResponse<TCarrierAccessorialV1[]> => {
  const action = useCallback(() => {
    return getCarrierAccessorialData();
  }, []);
  return useAction<TCarrierAccessorialV1[]>(action, true, shouldRefetch);
};
