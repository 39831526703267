import { Box, Collapse, FlatButton, FlatCard, Grid, Typography } from '@truxweb/ux';
import { captureMessage, captureUserFeedback, init, lastEventId } from '@sentry/browser';
import { FormField, SaveButton } from '@truxweb/common-components';
import { IS_SENTRY_RELEASE_ENABLED, SENTRY_DSN } from '../../config';
import React, { useCallback, useState } from 'react';
import TruxiiDead from '../../public/assets/images/truxii/truxii-dead.svg';
import { useForm } from 'react-hook-form';
import { useStyles } from './UserFeedback.styles';
import { useTranslation } from 'next-i18next';
import { useUserData } from '../../hooks';

const REQUIRED_NAMESPACES = ['common'];

export const UserFeedback = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [isFeedbackFormShown, setFeebackFormShown] = useState(false);
  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { control, handleSubmit } = useForm();

  const userData = useUserData();
  const onSubmitSuccess = useCallback(
    async (data: any) => {
      if (IS_SENTRY_RELEASE_ENABLED) {
        init({
          dsn: SENTRY_DSN,
        });
        const eventId = lastEventId() || captureMessage(`User Feedback`);
        /* eslint-disable camelcase */
        const userFeedback = {
          comments: data.errorDescription,
          email: userData.userData.email,
          event_id: eventId,
          name: `${userData.userData.firstName} ${userData.userData.lastName} (${userData.companyData.name})`,
        };
        /* eslint-enable camelcase */
        captureUserFeedback(userFeedback);
        setFeedbackSubmitted(true);
      }
    },
    [userData]
  );

  return (
    <form onSubmit={handleSubmit(onSubmitSuccess)}>
      <Grid
        alignItems="center"
        container
        direction="column"
        item
        justifyContent="center"
        style={{
          background: '#E8F4FB',
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
        xs
      >
        <Grid alignItems="center" container item style={{ width: '640px' }} xs>
          <FlatCard className={classes.container}>
            <Grid alignItems="center" container direction="column" item justifyContent="center" xs>
              <Grid alignItems="center" container direction="row" item justifyContent="center" xs>
                <Grid item>
                  <TruxiiDead />
                </Grid>
                <Grid item>
                  <Box ml={3}>
                    <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
                      {t('common:applicationError')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {isFeedbackSubmitted && (
                <Box mb={3} mt={3}>
                  <Typography variant="bodyMedium">{t('common:feedbackThanks')}</Typography>
                </Box>
              )}
              {!isFeedbackSubmitted && (
                <>
                  <Grid>
                    <Box mb={3} mt={3}>
                      <Typography variant="bodyMedium">
                        {t('common:applicationErrorExplanation')}
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <Typography variant="bodyMedium">
                        {t('common:submitFeedbackPrompt')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid container item justifyContent="center">
                    <Box mb={3}>
                      <FlatButton
                        color="primaryLight"
                        onClick={() => {
                          setFeebackFormShown(!isFeedbackFormShown);
                        }}
                        variant="contained"
                      >
                        {t('common:leaveFeedback')}
                      </FlatButton>
                    </Box>
                  </Grid>
                  <Collapse in={isFeedbackFormShown} style={{ width: '100%' }}>
                    <>
                      <FormField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          className: classes.message,
                        }}
                        control={control}
                        defaultValue={''}
                        id={'errorDescription'}
                        label={t('common:feedback')}
                        shouldOverrideLabelStyles
                        shouldUseModernVariant
                        t={t}
                        type="textfield"
                      />
                      <Grid container item justifyContent="center">
                        <Box mt={3}>
                          <SaveButton t={t} />
                        </Box>
                      </Grid>
                    </>
                  </Collapse>
                </>
              )}
            </Grid>
          </FlatCard>
        </Grid>
      </Grid>
    </form>
  );
};
