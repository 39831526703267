"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    availableTransaction: { background: theme.palette.success.main },
    blockedTransaction: { background: theme.palette.warning.main },
    cancelledTransaction: { background: theme.palette.error.main },
    card: {
        background: 'lightgrey',
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(2),
        width: `100%`,
    },
    title: {
        lineHeight: `${theme.spacing(2)}px`,
    },
    transactionDot: {
        borderRadius: '100%',
        height: 24,
        width: 24,
    },
}));
