"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCheckboxEmptyIcon = (props) => (React.createElement("svg", Object.assign({ fill: "evenodd", height: 24, viewBox: "0 0 20 20", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M15.418 20H4.58203C2.05469 19.9961 0.00390625 17.9453 0 15.418V4.58203C0.00390625 2.05469 2.05469 0.00390625 4.58203 0H15.4141C17.9453 0.00390625 19.9961 2.05469 20 4.58203V15.4141C19.9961 17.9453 17.9453 19.9961 15.418 20ZM4.58203 2.5C3.43359 2.5 2.5 3.43359 2.5 4.58203V15.4141C2.5 16.5664 3.43359 17.4961 4.58203 17.4961H15.4141C16.5664 17.4961 17.4961 16.5625 17.4961 15.4141V4.58203C17.4961 3.42969 16.5625 2.5 15.4141 2.5H4.58203Z" })));
exports.default = TruxwebCheckboxEmptyIcon;
