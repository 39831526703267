"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebOilCanIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.587,8.19a1.007,1.007,0,0,0-.9-.14L16.857,9.961a.993.993,0,0,1-.911-.19A3.9,3.9,0,0,0,12.691,8H11V5h2a1,1,0,0,0,0-2H7A1,1,0,0,0,7,5H9V8H5.973L4.046,7.189A3,3,0,0,0,0,10v1.465a4,4,0,0,0,2.671,3.772L4,15.708V16a4,4,0,0,0,4,4h5.149a4.991,4.991,0,0,0,3.812-1.764l6.8-8.01A1,1,0,0,0,24,9.578V9A1,1,0,0,0,23.587,8.19ZM3.335,13.353A2,2,0,0,1,2,11.467V10a.986.986,0,0,1,.429-.821.973.973,0,0,1,.88-.132L4,9.34v4.247Zm12.1,3.589A3,3,0,0,1,13.149,18H8a2,2,0,0,1-2-2V10h6.691a1.885,1.885,0,0,1,1.616.923.984.984,0,0,0,.154.2,3.014,3.014,0,0,0,2.982.752l3.182-1.042Zm7.978-.345a2,2,0,1,1-2.828,0l.8-.9a.819.819,0,0,1,1.226,0Z" })));
exports.default = TruxwebOilCanIcon;
