"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteDetailsDate = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const utils_1 = require("@truxweb/utils");
const quote_utils_1 = require("@truxweb/quote-utils");
const __1 = require("..");
const QuoteDetailsDate = ({ anchorEl, className, date, icon, isEditable, isLocked, locale, onChange, testId, title, }) => {
    const renderProperty = (0, react_1.useCallback)((onClick) => {
        const hasBeenModified = date.original !== date.modified;
        return (react_1.default.createElement(__1.QuoteDetailsModifiableProperty, { className: className, handleToggleEditState: (event) => {
                if (onClick) {
                    onClick(event);
                }
            }, hasBeenModified: hasBeenModified, icon: icon || react_1.default.createElement(ux_1.TruxwebCalendarIcon, null), isEditable: isEditable, isLocked: isLocked, locale: locale, modified: `${(0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(locale, date.modified ? new Date(date.modified) : undefined, true)}`, original: `${(0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(locale, date.original ? new Date(date.original) : undefined, true)}`, testId: `${testId}-QuoteDetailsDate`, title: title }));
    }, [title, testId, isLocked, isEditable, className, date, icon, locale]);
    if (!isEditable)
        return renderProperty();
    return (react_1.default.createElement(ux_1.DatePickerProvider, { language: locale },
        react_1.default.createElement(ux_1.DatePicker, { PopoverProps: {
                PaperProps: {
                    id: `${testId}-QuoteDetailsDate-Picker`,
                },
                anchorEl,
            }, TextFieldComponent: ({ onClick }) => {
                return renderProperty(onClick);
            }, format: utils_1.DATE_FORMAT_STRINGS[locale], fullWidth: true, inputProps: {
                'data-testid': `${testId}-QuoteDetailsDate`,
            }, inputVariant: "outlined", minDate: new Date(), onChange: (date) => {
                if (onChange) {
                    onChange((date === null || date === void 0 ? void 0 : date.toISOString()) || null);
                }
            }, value: date.modified !== date.original ? date.modified : date.original, variant: "inline" })));
};
exports.QuoteDetailsDate = QuoteDetailsDate;
