import { pageIdToLocaleString } from '../../utils';
import React from 'react';
import type { TFunction } from 'next-i18next';
import { TPageId } from '../../types';

type THeaderProps = {
  pageId?: TPageId;
  t: TFunction;
};

export const HeaderMeta = ({ pageId, t }: THeaderProps): JSX.Element => {
  const [localeString, formattedPageId] = pageIdToLocaleString(pageId);

  let pageTitlePrefix = t(localeString);

  if (pageTitlePrefix === formattedPageId) {
    pageTitlePrefix = t('common:siteMap-default');
  }

  const socialTitle = t('common:socialShareMetaTitle');
  const socialDesc = t('common:socialShareMetaDesc');

  return (
    <>
      <link href="/favicon.ico" rel="icon" />
      <meta content={socialDesc} name="description" />

      <meta content={socialTitle} itemProp="name" />
      <meta content={socialDesc} itemProp="description" />
      <meta content={`/assets/images/logos/truxweb-wings.svg`} itemProp="image" />

      <meta content="https://www.truxweb.com" property="og:url" />
      <meta content="website" property="og:type" />
      <meta content={socialTitle} property="og:title" />
      <meta content={socialDesc} property="og:description" />
      <meta content={`/assets/images/logos/truxweb-wings.svg`} property="og:image" />
      <meta content="26" property="og:image:width" />
      <meta content="44" property="og:image:height" />

      <meta content="summary_large_image" name="twitter:card" />
      <meta content={socialTitle} name="twitter:title" />
      <meta content={socialDesc} name="twitter:description" />
      <meta content={`/assets/images/logos/truxweb-wings.svg`} name="twitter:image" />
    </>
  );
};
