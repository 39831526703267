import { Box, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import React from 'react';
import { useStyles } from './LandingPageValuePropSelectorItem.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TLandingPageValuePropSelectorItemProps = {
  index: number;
  activeIndex: number;
  setActive: (arg: number) => void;
};

export const LandingPageValuePropSelectorItem = ({
  activeIndex,
  index,
  setActive,
}: TLandingPageValuePropSelectorItemProps): JSX.Element => {
  const isActive = index === activeIndex;
  const classes = useStyles({ isActive });
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const content = [
    isMobile ? t('common:valuePropSelectorText1-Mobile') : t('common:valuePropSelectorText1'),
    t('common:valuePropSelectorText2'),
    t('common:valuePropSelectorText3'),
  ];

  return (
    <Box className={classes.container} onClick={() => setActive(index)}>
      <Typography className={classes.color} variant="h5">
        {content[index]}
      </Typography>
    </Box>
  );
};
