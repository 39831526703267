"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSnowBlowingIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M11.5,24a3.705,3.705,0,0,1-3.443-2.334A1,1,0,1,1,9.943,21c.559,1.477,3.073,1.249,3.057-.5A1.5,1.5,0,0,0,11.5,19H1a1,1,0,0,1,0-2H11.5A3.5,3.5,0,0,1,11.5,24ZM20,19a4.24,4.24,0,0,1-3.943-2.667,1,1,0,0,1,1.886-.666C18.72,17.649,21.991,17.337,22,15a2,2,0,0,0-2-2H17a1,1,0,0,1,0-2h3C25.276,11.139,25.272,18.863,20,19ZM9.76,15a1,1,0,0,1-.707-.293L8,13.65,6.919,14.707a1,1,0,0,1-1.414-1.414l1.057-1.057A2.029,2.029,0,0,1,7,11.9V10.854A3.5,3.5,0,0,1,5.658,10.1l-.958.557a2.017,2.017,0,0,1-.067.53L4.25,12.631a1,1,0,1,1-1.933-.512L2.7,10.674l-1.454-.4a1,1,0,1,1,.51-1.933L3.2,8.724a1.993,1.993,0,0,1,.493.2l.921-.536a3.536,3.536,0,0,1,.01-1.821l-.931-.542a1.964,1.964,0,0,1-.492.2l-1.447.382a1,1,0,1,1-.51-1.933L2.69,4.3,2.317,2.838A1,1,0,1,1,4.25,2.326l.383,1.445A2.017,2.017,0,0,1,4.7,4.3l.987.574A3.489,3.489,0,0,1,7,4.146V3.1a2,2,0,0,1-.438-.333L5.505,1.707A1,1,0,0,1,6.919.293L7.977,1.35,9.053.293a1,1,0,1,1,1.414,1.414L9.41,2.764A2.009,2.009,0,0,1,9,3.081V4.146a3.489,3.489,0,0,1,1.313.729L11.3,4.3a2.017,2.017,0,0,1,.067-.53l.383-1.445a1,1,0,1,1,1.933.512L13.3,4.283l1.454.4a1,1,0,1,1-.51,1.933L12.8,6.233a1.955,1.955,0,0,1-.493-.2l-.931.542a3.536,3.536,0,0,1,.01,1.821l.921.536a2,2,0,0,1,.492-.2l1.447-.382a1,1,0,1,1,.51,1.933l-1.445.382.373,1.462a1,1,0,1,1-1.933.512l-.383-1.445a2.017,2.017,0,0,1-.067-.53l-.958-.557A3.5,3.5,0,0,1,9,10.854v1.065a2.08,2.08,0,0,1,.411.317l1.056,1.057A1,1,0,0,1,9.76,15ZM8,6A1.5,1.5,0,0,0,8,9,1.5,1.5,0,0,0,8,6ZM20.5,9H18a1,1,0,0,1,0-2h2.5A1.5,1.5,0,0,0,22,5.5a1.585,1.585,0,0,0-2.846-.867,1,1,0,0,1-1.549-1.266C19.616.79,24.044,2.139,24,5.5A3.5,3.5,0,0,1,20.5,9Z" })));
exports.default = TruxwebSnowBlowingIcon;
