import {
  addCommentToShipment,
  addTemporaryCommentToShipment,
  type AppThunk,
  setShipmentDetailsError,
  setShipmentDetailsLoading,
} from '../../../stores';
import { EUserTypeV1, type TShipmentCommentV1 } from '@truxweb/schemas';
import { getShipmentByRef } from '.';
import { type TSubmitAddCommentPayload } from '../../../types';

export const addTempShipmentComment =
  (request: TSubmitAddCommentPayload): AppThunk =>
  async (dispatch, getState) => {
    const { _tempId, attachments, comment, companyType, shipmentRef } = request;
    try {
      // Get data about the use leaving this comment
      const auth = (getState() as any).auth;
      dispatch(setShipmentDetailsLoading({ shipmentRefs: [shipmentRef] }));
      dispatch(
        addTemporaryCommentToShipment({
          _tempId,
          attachments,
          comment,
          companyName: auth.userData.data.companyName,
          shipmentRef,
          userExtId: auth.userData.data.extId,
          userName: `${auth.userData.data.firstName} ${auth.userData.data.lastName}`,
          userType: companyType,
        })
      );
    } catch (error) {
      dispatch(setShipmentDetailsError({ error: error.message, shipmentRefs: [shipmentRef] }));
    }
  };

type TPostAddCommentPayload = {
  _tempId: string;
  attachments?: TSubmitAddCommentPayload['attachments'];
  comment: TShipmentCommentV1;
  companyType: EUserTypeV1;
  shipmentRef: string;
};

export const postCommentAdd =
  (request: TPostAddCommentPayload): AppThunk =>
  async (dispatch) => {
    const { _tempId, attachments, comment, companyType, shipmentRef } = request;
    try {
      // Get data about the use leaving this comment

      dispatch(addCommentToShipment({ _tempId, attachments, comment, shipmentRef }));
      dispatch(getShipmentByRef(companyType, shipmentRef));
    } catch (error) {
      dispatch(setShipmentDetailsError({ error: error.message, shipmentRefs: [shipmentRef] }));
    }
  };
