"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    timeTierOne: {
        color: theme.palette.success.main,
    },
    timeTierThree: {
        color: theme.palette.error.main,
    },
    timeTierTwo: {
        color: theme.palette.warning.light,
    },
    timer: {
        fontSize: 12,
        fontWeight: 600,
    },
}));
