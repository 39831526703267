"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    '@keyframes glow': {
        '0%': {
            boxShadow: '0px 0px 0px 0px #fff',
        },
        '100%': {
            boxShadow: `0px 0px 8px 4px #0FF`,
        },
    },
    button: {
        background: ({ hasExports }) => (hasExports ? theme.palette.primary.light : 'transparent'),
        border: 0,
        borderRadius: '100%',
        color: ({ hasExports }) => (hasExports ? '#EDF8FF' : 'transparent'),
        cursor: ({ hasExports }) => (hasExports ? 'pointer' : undefined),
        height: 24,
        transition: 'background',
        width: 24,
    },
    buttonAnimation: {
        animation: '$glow 0.75s 2',
    },
    downloadable: {
        cursor: 'pointer',
    },
    exportButton: {
        '&:hover': {
            background: theme.palette.grey[100],
        },
        background: 'transparent',
        border: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
        textAlign: 'left',
        transition: 'background',
        transitionDuration: '0.2s',
        width: '100%',
    },
    processing: {
        '&:hover': {
            background: theme.palette.grey[300],
        },
        background: theme.palette.grey[300],
    },
    unread: {
        '&:hover': {
            background: '#d7ecfa',
        },
        background: '#EDF8FF',
        width: '100%',
    },
    unreadDot: {
        background: theme.palette.primary.light,
        borderRadius: '100%',
        height: 12,
        width: 12,
    },
}));
