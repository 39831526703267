import {
  baseLoadingState,
  manageDomainError,
  manageDomainLoaded,
  manageDomainLoading,
} from './stateUtils';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TCompanyDocumentV1 } from '@truxweb/schemas';
import { type TReducerLoading } from '../types';

export type TCompanyDocument = TReducerLoading & {
  data: TCompanyDocumentV1;
};

export type TCompanyDocumentListLoading = TReducerLoading & {
  data: boolean;
};

export interface ICompanyDocumentDataState {
  companyDocuments: Record<string, TCompanyDocument>;
  companyDocumentsList: TCompanyDocumentListLoading;
}

export const companyDocumentSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.companyDocuments,
      };
    },
  },
  initialState: {
    companyDocuments: {},
    companyDocumentsList: { ...baseLoadingState, data: false },
  } as ICompanyDocumentDataState,
  name: 'companyDocuments',
  reducers: {
    setCompanyDocumentData: (state, { payload: { items, shouldOverwriteList } }) => {
      const companyDocuments: ICompanyDocumentDataState['companyDocuments'] = {};
      items.forEach((item: TCompanyDocumentV1) => {
        companyDocuments[item.id] = manageDomainLoaded(state.companyDocuments[item.id], item);
      });

      const updatedState = shouldOverwriteList
        ? { ...companyDocuments }
        : {
            ...state.companyDocuments,
            ...companyDocuments,
          };

      return {
        ...state,
        companyDocuments: updatedState,
      };
    },
    setCompanyDocumentListError: (state, { payload: { error } }) => {
      return {
        ...state,
        companyDocumentsList: manageDomainError(state.companyDocumentsList, error),
      };
    },
    setCompanyDocumentListLoaded: (state, { payload: { isProgressiveLoading } }) => {
      return {
        ...state,
        companyDocumentsList: manageDomainLoaded(
          state.companyDocumentsList,
          true,
          isProgressiveLoading
        ),
      };
    },
    setCompanyDocumentListLoading: (state) => {
      return { ...state, companyDocumentsList: manageDomainLoading(state.companyDocumentsList) };
    },
  },
});

export const {
  actions: {
    setCompanyDocumentData,
    setCompanyDocumentListError,
    setCompanyDocumentListLoaded,
    setCompanyDocumentListLoading,
  },
} = companyDocumentSlice;
