// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotWantToHelpUsV1 = exports.HubspotLeadStatusV1 = exports.HubspotCaptureSourceV1 = exports.HubspotPersonaV1 = exports.HubspotLifecycleStatusV1 = exports.LanguageV1 = void 0;
var LanguageV1;
(function (LanguageV1) {
    LanguageV1["EN_CA"] = "EN-CA";
    LanguageV1["FR_CA"] = "FR-CA";
})(LanguageV1 || (exports.LanguageV1 = LanguageV1 = {}));
var HubspotLifecycleStatusV1;
(function (HubspotLifecycleStatusV1) {
    HubspotLifecycleStatusV1["subscriber"] = "subscriber";
    HubspotLifecycleStatusV1["lead"] = "lead";
    HubspotLifecycleStatusV1["marketingqualifiedlead"] = "marketingqualifiedlead";
    HubspotLifecycleStatusV1["salesqualifiedlead"] = "salesqualifiedlead";
    HubspotLifecycleStatusV1["opportunity"] = "opportunity";
    HubspotLifecycleStatusV1["customer"] = "customer";
    HubspotLifecycleStatusV1["evangelist"] = "evangelist";
})(HubspotLifecycleStatusV1 || (exports.HubspotLifecycleStatusV1 = HubspotLifecycleStatusV1 = {}));
var HubspotPersonaV1;
(function (HubspotPersonaV1) {
    HubspotPersonaV1["Shipper"] = "persona_1";
    HubspotPersonaV1["Investor"] = "persona_2";
    HubspotPersonaV1["Carrier"] = "persona_3";
    HubspotPersonaV1["Broker"] = "persona_4";
    HubspotPersonaV1["Other"] = "persona_5";
    HubspotPersonaV1["Supplier"] = "persona_6";
    HubspotPersonaV1["Partner"] = "persona_7";
})(HubspotPersonaV1 || (exports.HubspotPersonaV1 = HubspotPersonaV1 = {}));
var HubspotCaptureSourceV1;
(function (HubspotCaptureSourceV1) {
    HubspotCaptureSourceV1["contact_page"] = "contact_page";
    HubspotCaptureSourceV1["landing_page_main_capture"] = "landing_page_main_capture";
    HubspotCaptureSourceV1["footer_newsletter"] = "footer_newsletter";
    HubspotCaptureSourceV1["registration"] = "registration";
    HubspotCaptureSourceV1["other"] = "other";
})(HubspotCaptureSourceV1 || (exports.HubspotCaptureSourceV1 = HubspotCaptureSourceV1 = {}));
var HubspotLeadStatusV1;
(function (HubspotLeadStatusV1) {
    HubspotLeadStatusV1["VALIDATED"] = "Validated";
    HubspotLeadStatusV1["UNVERIFIED"] = "UNVERIFIED";
    HubspotLeadStatusV1["REGISTERED"] = "Registered";
    HubspotLeadStatusV1["PENDING_REVIEW"] = "Pending review";
    HubspotLeadStatusV1["ONBOARDED"] = "ONBOARDED";
    HubspotLeadStatusV1["OPEN"] = "OPEN";
    HubspotLeadStatusV1["UNQAULIFIED"] = "UNQAULIFIED";
    HubspotLeadStatusV1["ATTEMPTED_TO_CONTACT"] = "ATTEMPTED_TO_CONTACT";
    HubspotLeadStatusV1["IN_PROGRESS"] = "IN_PROGRESS";
    HubspotLeadStatusV1["OPEN_DEAL"] = "OPEN_DEAL";
    HubspotLeadStatusV1["VERIFIED"] = "VERIFIED";
})(HubspotLeadStatusV1 || (exports.HubspotLeadStatusV1 = HubspotLeadStatusV1 = {}));
var HubspotWantToHelpUsV1;
(function (HubspotWantToHelpUsV1) {
    HubspotWantToHelpUsV1["Yes"] = "Yes";
    HubspotWantToHelpUsV1["No"] = "No";
})(HubspotWantToHelpUsV1 || (exports.HubspotWantToHelpUsV1 = HubspotWantToHelpUsV1 = {}));
