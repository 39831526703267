"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPieIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M24,14c0-3.624-5.353-8-12-8S0,10.376,0,14a2.946,2.946,0,0,0,2.142,2.887l.532,2.989A5,5,0,0,0,7.6,24H16.4a4.992,4.992,0,0,0,4.922-4.125l.532-2.988A2.946,2.946,0,0,0,24,14Zm-7.6,8H7.6a3.213,3.213,0,0,1-3.1-3.3A4.015,4.015,0,0,0,9,17.648a4.038,4.038,0,0,0,6,0A4.015,4.015,0,0,0,19.5,18.7,3.211,3.211,0,0,1,16.4,22ZM21,15a1.57,1.57,0,0,0-.406.09,1.232,1.232,0,0,0-.75.688,2.015,2.015,0,0,1-3.687,0,1.259,1.259,0,0,0-2.313,0,2,2,0,0,1-3.687,0,1.259,1.259,0,0,0-2.313,0,2.015,2.015,0,0,1-3.687,0,1.236,1.236,0,0,0-.751-.691C3.4,15.085,3.04,15,3,15a.915.915,0,0,1-1-1c0-2.369,4.479-6,10-6s10,3.631,10,5.988A.918.918,0,0,1,21,15ZM11,3V1a1,1,0,0,1,2,0V3A1,1,0,0,1,11,3Zm4,0V1a1,1,0,0,1,2,0V3A1,1,0,0,1,15,3ZM7,3V1A1,1,0,0,1,9,1V3A1,1,0,0,1,7,3Zm3.985,7.65a5.4,5.4,0,0,1-.752,1.967,1.308,1.308,0,0,1-1.85-1.85,5.4,5.4,0,0,1,1.967-.752A.526.526,0,0,1,10.985,10.65Zm4.632.117a1.308,1.308,0,0,1-1.85,1.85,5.4,5.4,0,0,1-.752-1.967.526.526,0,0,1,.635-.635A5.4,5.4,0,0,1,15.617,10.767Z" })));
exports.default = TruxwebPieIcon;
