"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestValidateAddress = void 0;
const ux_1 = require("@truxweb/ux");
const utils_1 = require("@truxweb/utils");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const QuoteRequestValidateAddress_styles_1 = require("./QuoteRequestValidateAddress.styles");
const QuoteRequestValidateAddress = ({ address, addressType, control, header, isDisabled, t, testId, }) => {
    const classes = (0, QuoteRequestValidateAddress_styles_1.useStyles)();
    const [postalCodeRegex, setPostalCodeRegex] = (0, react_1.useState)(utils_1.POSTAL_CODE_REGEX);
    (0, react_1.useEffect)(() => {
        // Whenver the country is update, change the postal
        // code regex to be the appropriat validation
        // NOTE: Currently assumes only CA and US
        if (address.country === 'US') {
            setPostalCodeRegex(utils_1.ZIP_CODE_REGEX);
        }
        else {
            setPostalCodeRegex(utils_1.POSTAL_CODE_REGEX);
        }
    }, [address, setPostalCodeRegex]);
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", style: { width: '100%' } },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: address.addressLineOne, name: `${addressType}-addressLineOne`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        return (react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                                shrink: true,
                            }, InputProps: {
                                classes: {
                                    root: classes.itemBox,
                                },
                            }, disabled: isDisabled, error: Boolean(error), fullWidth: true, inputProps: {
                                'data-testid': `${testId}-${addressType}-addressLineOne`,
                            }, label: header, onChange: onChange, prefix: addressType, type: "string", value: value, variant: "outlined" }));
                    }, rules: {
                        pattern: utils_1.NON_EMPTY_REGEX,
                        required: true,
                    } }))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: address.city, name: `${addressType}-city`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        return (react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                                shrink: true,
                            }, InputProps: {
                                classes: {
                                    root: classes.itemBox,
                                },
                            }, disabled: isDisabled, error: Boolean(error), fullWidth: true, inputProps: {
                                'data-testid': `${testId}-${addressType}-city`,
                            }, label: t('common:city'), onChange: onChange, prefix: addressType, type: "string", value: value, variant: "outlined" }));
                    }, rules: {
                        pattern: utils_1.NON_EMPTY_REGEX,
                        required: true,
                    } }))),
        react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: address.postalCode, name: `${addressType}-postalCode`, render: ({ field: { onChange, value }, fieldState: { error } }) => {
                        return (react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                                shrink: true,
                            }, InputProps: {
                                classes: {
                                    root: classes.itemBox,
                                },
                            }, disabled: isDisabled, error: Boolean(error), fullWidth: true, inputProps: {
                                'data-testid': `${testId}-${addressType}-postalCode`,
                            }, label: t('common:postalCode'), onChange: onChange, prefix: addressType, type: "string", value: value, variant: "outlined" }));
                    }, rules: {
                        pattern: postalCodeRegex,
                        required: true,
                    } }))),
        react_1.default.createElement(ux_1.Grid, { container: true },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
                react_1.default.createElement(ux_1.Typography, { className: classes.header },
                    t('common:province'),
                    ":")),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, null,
                    " ",
                    address.province))),
        react_1.default.createElement(ux_1.Grid, { container: true },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
                react_1.default.createElement(ux_1.Typography, { className: classes.header },
                    t('common:country'),
                    ":")),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Typography, null, address.country)))));
};
exports.QuoteRequestValidateAddress = QuoteRequestValidateAddress;
