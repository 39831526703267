"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebForwardIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M3.787,20.656A3.789,3.789,0,0,1,0,16.87V7.13A3.783,3.783,0,0,1,6.021,4.079L9.8,6.853a3.783,3.783,0,0,1,6.01-2.774l6.641,4.87a3.784,3.784,0,0,1,0,6.1l-6.641,4.87A3.783,3.783,0,0,1,9.8,17.147L6.021,19.921A3.775,3.775,0,0,1,3.787,20.656Zm7.006-6.475a1,1,0,0,1,1,1V16.87a1.784,1.784,0,0,0,2.838,1.438l6.64-4.87a1.783,1.783,0,0,0,0-2.876l-6.64-4.87A1.784,1.784,0,0,0,11.793,7.13V8.819a1,1,0,0,1-1.591.806L4.838,5.692A1.784,1.784,0,0,0,2,7.13v9.74a1.784,1.784,0,0,0,2.838,1.438L10.2,14.375A1,1,0,0,1,10.793,14.181Z" })));
exports.default = TruxwebForwardIcon;
