"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebBeerIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19.5,9H19V7a1,1,0,0,0-1-1,3.9,3.9,0,0,0-2.016-3.485,4.093,4.093,0,0,0-2.359-.5.593.593,0,0,1-.54-.223,4.509,4.509,0,0,0-7.332.229A3.993,3.993,0,0,0,2,6,1,1,0,0,0,1,7V19a5.006,5.006,0,0,0,5,5h8a5.006,5.006,0,0,0,5-5h.5A3.5,3.5,0,0,0,23,15.5v-3A3.5,3.5,0,0,0,19.5,9ZM6,4a.963.963,0,0,1,.171.021,1,1,0,0,0,1.068-.567A2.5,2.5,0,0,1,11.486,3a2.576,2.576,0,0,0,2.332,1.012,2.157,2.157,0,0,1,1.228.271A1.938,1.938,0,0,1,16,6H12A3,3,0,0,0,9,9v3a1,1,0,0,1-2,0V9A3,3,0,0,0,4,6,2,2,0,0,1,6,4ZM17,19a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V8H4A1,1,0,0,1,5,9v3a3,3,0,0,0,6,0V9a1,1,0,0,1,1-1h5Zm4-3.5A1.5,1.5,0,0,1,19.5,17H19V11h.5A1.5,1.5,0,0,1,21,12.5Z" })));
exports.default = TruxwebBeerIcon;
