"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const RollTiteIcon = (props) => (React.createElement("svg", Object.assign({ fill: "none", height: 24, viewBox: "0 0 24 21", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("rect", { fill: "white", height: 4.03732, stroke: "#224882", strokeWidth: 0.5, width: 4.84725, x: 10.4331, y: 13.8281 }),
    React.createElement("path", { d: "M0 12.855H10.7107V17.7235H0V12.855Z", fill: "#224882" }),
    React.createElement("mask", { height: 4, id: "mask0_641_487", maskUnits: "userSpaceOnUse", style: {
            maskType: 'alpha',
        }, width: 11, x: 0, y: 11 },
        React.createElement("ellipse", { cx: 1.03012, cy: 13.0182, fill: "#C4C4C4", rx: 1.03012, ry: 1.0395 }),
        React.createElement("ellipse", { cx: 2.78135, cy: 13.0182, fill: "#C4C4C4", rx: 1.03012, ry: 1.0395 }),
        React.createElement("ellipse", { cx: 4.45566, cy: 13.0182, fill: "#C4C4C4", rx: 1.03012, ry: 1.0395 }),
        React.createElement("ellipse", { cx: 6.20713, cy: 13.0182, fill: "#C4C4C4", rx: 1.03012, ry: 1.0395 }),
        React.createElement("ellipse", { cx: 7.9293, cy: 13.0182, fill: "#C4C4C4", rx: 1.03013, ry: 1.0395 }),
        React.createElement("ellipse", { cx: 9.68052, cy: 13.0182, fill: "#C4C4C4", rx: 1.03013, ry: 1.0395 })),
    React.createElement("g", { mask: "url(#mask0_641_487)" },
        React.createElement("path", { d: "M0.0922349 11.9787H10.6186L11.1449 17.8209H-0.434082L0.0922349 11.9787Z", fill: "#224882" })),
    React.createElement("line", { stroke: "#224882", x1: 0.00244141, x2: 17.6476, y1: 17.9255, y2: 17.9255 }),
    React.createElement("circle", { cx: 4.23753, cy: 18.4256, fill: "white", r: 1.36741, stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("circle", { cx: 19.0596, cy: 18.4256, fill: "white", r: 1.36741, stroke: "#224882", strokeWidth: 1.5 }),
    React.createElement("path", { clipRule: "evenodd", d: "M21.2917 19.4843C21.4441 19.1634 21.5294 18.8045 21.5294 18.4257C21.5294 17.0613 20.4234 15.9553 19.0591 15.9553C18.1607 15.9553 17.3743 16.4349 16.942 17.1521V17.014V11.0146H20.8251L24 14.9079V19.4843H21.2917ZM18.0007 11.7205H20.471L22.9413 14.8966H18.0007V13.485V11.7205Z", fill: "#224882", fillRule: "evenodd" }),
    React.createElement("path", { d: "M8.64871 5.04464L2.53174 5.04464M8.64871 5.04464L6.35484 7.3385M8.64871 5.04464L6.35485 2.75077", stroke: "#224882", strokeLinecap: "round", strokeLinejoin: "round" })));
exports.default = RollTiteIcon;
