"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    container: {
        background: '#EDF8FF',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(3),
        height: theme.spacing(3),
        padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
        position: 'relative',
        zIndex: 2,
    },
    filterNameButton: {
        background: ' transparent',
        border: 0,
        padding: 0,
        textDecoration: 'underline',
    },
    removeButton: {
        background: theme.palette.primary.main,
        border: 0,
        borderRadius: '100%',
        color: theme.palette.common.white,
        cursor: 'pointer',
        height: theme.spacing(2),
        position: 'absolute',
        right: '4px',
        top: '3px',
        width: theme.spacing(2),
    },
}));
