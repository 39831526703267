"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteComments = void 0;
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const hooks_1 = require("@truxweb/hooks");
const __1 = require("..");
const QuoteComments = ({ canAdministerComments, emptyCommentDisplay, handleDeleteComment, handleSubmitComment, hideChat, isAddingCommentsDisabled, isExpired, isMultiChat, isRefused, language, quoteRequestId, t, useQuoteComments, userType, }) => {
    var _a, _b;
    const { handleRefresh, shouldRefresh } = (0, hooks_1.useRefresh)(true);
    const comments = useQuoteComments(quoteRequestId, shouldRefresh, userType !== schemas_1.EUserTypeV1.TRUXWEB);
    (0, hooks_1.useTimeout)(handleRefresh, 2000);
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const handleSubmit = (0, react_1.useCallback)((data, attachments, mentions) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            yield handleSubmitComment(data, attachments, mentions);
            handleRefresh();
        }
        finally {
            setIsSaving(false);
        }
    }), [handleSubmitComment, handleRefresh, setIsSaving]);
    const handleDelete = (0, react_1.useCallback)((comment) => __awaiter(void 0, void 0, void 0, function* () {
        if (handleDeleteComment) {
            yield handleDeleteComment(comment);
            handleRefresh();
        }
    }), [handleDeleteComment, handleRefresh]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (comments.hasLoaded &&
            (isExpired || isRefused) &&
            (((_a = comments.data) === null || _a === void 0 ? void 0 : _a.comments) || []).length === 0 &&
            hideChat) {
            hideChat();
        }
    }, [isExpired, isRefused, hideChat, comments]);
    return (react_1.default.createElement(__1.ShipmentComments, { canAdministerComments: canAdministerComments, commentUserData: ((_a = comments === null || comments === void 0 ? void 0 : comments.data) === null || _a === void 0 ? void 0 : _a.commentUserData) || {}, comments: ((_b = comments === null || comments === void 0 ? void 0 : comments.data) === null || _b === void 0 ? void 0 : _b.comments) || [], emptyCommentDisplay: emptyCommentDisplay, handleDeleteComment: handleDelete, handleSubmitComment: handleSubmit, isAddingCommentsDisabled: isAddingCommentsDisabled, isLoading: isSaving || !(comments === null || comments === void 0 ? void 0 : comments.hasLoaded), isMultiChat: isMultiChat, isQuoteComment: true, language: language, shouldSuppressHeader: true, t: t, viewingUserType: userType }));
};
exports.QuoteComments = QuoteComments;
