"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSaladIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M23.194,11.6a3.942,3.942,0,0,0-2.143-1.447,21.606,21.606,0,0,0,.922-3.731,3.008,3.008,0,0,0-3.4-3.392c-.126.017-.5.072-1.011.171A4.979,4.979,0,0,0,18,.968a1,1,0,1,0-2,.064,3.021,3.021,0,0,1-.289,1.4A3.894,3.894,0,0,0,15.1,1.352,4.006,4.006,0,0,0,11.5.034,3.8,3.8,0,0,0,9.5.8,3.939,3.939,0,0,0,7.233.01,3.949,3.949,0,0,0,4.348.887,4.028,4.028,0,0,0,3,3.782,4.1,4.1,0,0,0,3.73,6.248a4.951,4.951,0,0,0-1.7,4.288A3.967,3.967,0,0,0,.164,15.15a14.749,14.749,0,0,0,3.707,6.316A8.922,8.922,0,0,0,10.139,24h3.722a8.918,8.918,0,0,0,6.267-2.534,14.741,14.741,0,0,0,3.708-6.316A4,4,0,0,0,23.194,11.6ZM18.851,5.01A1.013,1.013,0,0,1,19.99,6.152,20.024,20.024,0,0,1,18.975,10H16.414l1.293-1.293a1,1,0,0,0-1.414-1.414L13.586,10H12.037a3.428,3.428,0,0,1,.976-2.9C13.44,6.669,15.077,5.5,18.851,5.01ZM5,3.849a2.026,2.026,0,0,1,.665-1.457A2.035,2.035,0,0,1,7.1,2.006a1.748,1.748,0,0,1,1.328.531A1.438,1.438,0,0,0,9.5,3.016h0a1.44,1.44,0,0,0,1.068-.482,1.64,1.64,0,0,1,1.156-.513,2.031,2.031,0,0,1,1.828.6A2.118,2.118,0,0,1,14,3.985c0,.065,0,.207,0,.207a6.446,6.446,0,0,0-3.228,2.563,4.916,4.916,0,0,0-5.284-1.5A2.064,2.064,0,0,1,5,3.849ZM4,10a3,3,0,0,1,6,0H4Zm17.916,4.589a12.919,12.919,0,0,1-3.2,5.46A6.9,6.9,0,0,1,13.861,22H10.139a6.909,6.909,0,0,1-4.857-1.951,12.917,12.917,0,0,1-3.2-5.46A2.019,2.019,0,0,1,2.406,12.8,1.978,1.978,0,0,1,4,12H20a1.978,1.978,0,0,1,1.6.8A2.019,2.019,0,0,1,21.916,14.589Z" })));
exports.default = TruxwebSaladIcon;
