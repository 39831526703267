import { TUseActionResponse, useAction } from './useAction';
import { getUserCompany } from '../actions';
import { TCompanyV1 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useCompanyData = (shouldForceRefetch?: boolean): TUseActionResponse<TCompanyV1> => {
  const action = useCallback(async () => {
    return await getUserCompany();
  }, []);

  return useAction<TCompanyV1>(action, true, shouldForceRefetch);
};
