"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarDocumentItem = void 0;
/* eslint-disable complexity */
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const __1 = require("../..");
const utils_1 = require("@truxweb/utils");
const __2 = require("..");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const ShipmentsDashboardSidebarDocumentItem_styles_1 = require("./ShipmentsDashboardSidebarDocumentItem.styles");
const quote_utils_1 = require("@truxweb/quote-utils");
const utils_2 = require("../../utils");
// FIXME: The logic in this component around editable states is nutso banana-pants
// it needs to be cleaned the F up
// TRB 20062023
const ShipmentsDashboardSidebarDocumentItem = ({ addAlert, canManageCarrierData, canManageShipperData, canTriggerDocumentRegeneration, children, determineDocumentGeneratedState, documentToUpload, documentViewIcon, handleDeactivateShipmentDocument, handleDocumentRegeneration, handleDownloadDocument, handleUploadDocument, hasPermissionToViewAllDocuments, isEditable, isLoading, isTruxwebDocumentSection, isUploading, language, onDownloadError, onDownloadSuccess, ownerUserType, requiredDocument, shipmentRef, t, uploadedDocument, viewingUserType, }) => {
    var _a;
    const classes = (0, ShipmentsDashboardSidebarDocumentItem_styles_1.useStyles)();
    const tooltipClasses = (0, ShipmentsDashboardSidebarDocumentItem_styles_1.useTooltipStyles)();
    const theme = (0, ux_1.useTheme)();
    const [shouldHideUploadWhenRequiredNotUploaded, setShouldHideUploadWhenRequiredNotUploaded] = (0, react_1.useState)(false);
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const [isGenerating, setIsGenerating] = (0, react_1.useState)(false);
    const hasDetailsComponent = Boolean(children);
    const isUploaded = Boolean(uploadedDocument);
    // Track the completed state of the details component, if it exists
    // STEP -- Read state
    const shouldMarkAsRead = (0, __1.getShipmentDocumentReadState)(uploadedDocument, viewingUserType);
    // STEP -- Required state (will be required as well)
    const shouldMarkAsRequired = (0, __1.getShipmentDocumentRequiredStatus)(documentToUpload, ownerUserType, viewingUserType, requiredDocument);
    // Specific permission for correctly rendering the current item
    const isTruxwebDocumentNotYetUploaded = isTruxwebDocumentSection && !isUploaded;
    const isEntitledToModifyThisDocument = (!isTruxwebDocumentNotYetUploaded &&
        (canManageCarrierData || canManageShipperData) &&
        documentToUpload.documentOwnerType === viewingUserType) ||
        hasPermissionToViewAllDocuments;
    const canUploadThisDocument = isEntitledToModifyThisDocument &&
        (!documentToUpload.requiresEditableState || isEditable) &&
        (!isTruxwebDocumentSection ||
            (isTruxwebDocumentSection && viewingUserType === schemas_1.EUserTypeV1.TRUXWEB));
    const canDownloadThisDocument = isUploaded &&
        (isEntitledToModifyThisDocument ||
            ((uploadedDocument === null || uploadedDocument === void 0 ? void 0 : uploadedDocument.isVisibleByCarrier) && viewingUserType === schemas_1.EUserTypeV1.CARRIER) ||
            ((uploadedDocument === null || uploadedDocument === void 0 ? void 0 : uploadedDocument.isVisibleByShipper) && viewingUserType === schemas_1.EUserTypeV1.SHIPPER));
    const shouldHideDragDrop = shouldHideUploadWhenRequiredNotUploaded && canUploadThisDocument && !isUploaded;
    // STEP -- actions START
    const isDisabled = !isEditable;
    // UPLOAD FORM -- START
    let defaultValues;
    // We only want to add an additional charge record when the carrier transactions
    // are not ready - i.e. that this is the first time this document is being uploaded
    if (!uploadedDocument &&
        documentToUpload.documentType === schemas_1.EShipmentDocumentTypeV1.CARRIER_INVOICE) {
        defaultValues = {
            additionalCharges: [(0, utils_2.generateDefaultChargeForRecipient)(schemas_1.EUserTypeV1.CARRIER)],
            files: '',
        };
    }
    const formTools = (0, react_hook_form_1.useForm)({
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const { control, formState: { errors }, handleSubmit, setError, } = formTools;
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        const { files } = data, others = __rest(data, ["files"]);
        yield handleUploadDocument(documentToUpload, files, others);
    }), [documentToUpload, handleUploadDocument]);
    // UPLOAD FORM -- END
    const actions = (0, react_1.useMemo)(() => {
        const ret = [];
        if (!canDownloadThisDocument)
            return ret;
        // Download
        ret.push(react_1.default.createElement(ux_1.Tooltip, { classes: tooltipClasses, key: `${documentToUpload.documentType}-download`, placement: "top-end", title: t(`common:previsualise`) },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(__2.ShipmentDocumentDetailsDownload, { documentId: (uploadedDocument === null || uploadedDocument === void 0 ? void 0 : uploadedDocument.id) || 0, downloadIcon: documentViewIcon, handleDownloadDocument: handleDownloadDocument, onDownloadError: onDownloadError, onDownloadSuccess: onDownloadSuccess, singleDocumentType: documentToUpload.documentType }))));
        // Admin deactivates certain doc types only
        if (hasPermissionToViewAllDocuments &&
            handleDeactivateShipmentDocument &&
            __1.CSR_DELETABLE_SHIPMENT_DOCS.includes(documentToUpload.documentType)) {
            //=> Action also to logically delete a document
            ret.push(react_1.default.createElement(ux_1.Tooltip, { classes: tooltipClasses, key: `${documentToUpload.documentType}-delete`, placement: "top-end", title: t(`common:deactivateDocument`) },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { ml: -2.5 },
                        react_1.default.createElement(ux_1.Button, { component: "span", disabled: isDisabled, onClick: () => {
                                if (uploadedDocument) {
                                    handleDeactivateShipmentDocument(uploadedDocument);
                                }
                            } }, 'X')))));
        }
        return ret;
    }, [
        tooltipClasses,
        canDownloadThisDocument,
        documentToUpload.documentType,
        documentViewIcon,
        handleDeactivateShipmentDocument,
        handleDownloadDocument,
        hasPermissionToViewAllDocuments,
        isDisabled,
        onDownloadError,
        onDownloadSuccess,
        t,
        uploadedDocument,
    ]);
    // STEP -- actions END
    // STEP -- Rendering logic START
    const Row1Content = (0, react_1.useCallback)(() => {
        if (!isEntitledToModifyThisDocument) {
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Typography, { className: classes.notMyDocumentHeader, fontStyle: "semibold", variant: "bodyMedium" }, t(`common:${documentToUpload.documentType}`))));
        }
        let documentStateTag = react_1.default.createElement(react_1.default.Fragment, null);
        let documentIconColor = '';
        let postDocumentTypeElt;
        if (!isUploaded && canUploadThisDocument) {
            if (shouldMarkAsRequired) {
                postDocumentTypeElt = react_1.default.createElement("span", { style: { color: theme.palette.error.main } }, "*");
                documentIconColor = theme.palette.error.main;
            }
            documentStateTag = (react_1.default.createElement(ux_1.Box, { className: classes.myDocumentEditableTag }, isUploading && react_1.default.createElement(ux_1.CircularProgress, { size: '1rem' })));
        }
        let showExpansionIcon = true;
        if (!isUploaded && documentToUpload.requiresEditableState && !isEditable) {
            showExpansionIcon = false;
            documentIconColor = theme.palette.grey[100];
            documentStateTag = (react_1.default.createElement(ux_1.Box, { className: classes.myDocumentEditableTag },
                react_1.default.createElement(ux_1.Typography, { className: classes.willBeRequired }, t('common:willBeRequired'))));
        }
        if (isUploaded) {
            documentIconColor = theme.palette.success.main;
            documentStateTag = (react_1.default.createElement(ux_1.Grid, { alignItems: "baseline", container: true, direction: "row" },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { className: classes.myDocumentEditableTag },
                        react_1.default.createElement(ux_1.Typography, { className: classes.uploadedDocument }, t(`common:uploaded`)))),
                react_1.default.createElement(ux_1.Grid, { item: true }, isUploading && (react_1.default.createElement(ux_1.Box, { ml: 2 },
                    react_1.default.createElement(ux_1.CircularProgress, { size: '1rem' }))))));
        }
        return (react_1.default.createElement(react_1.default.Fragment, null,
            isLoading && react_1.default.createElement(ux_1.CircularProgress, { className: classes.loader, size: '20px' }),
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between", style: { height: 48 } },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mr: 1, mt: 0.5 },
                                react_1.default.createElement(ux_1.TruxwebDocumentIcon, { fill: documentIconColor }))),
                        react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true, xs: true },
                            react_1.default.createElement(ux_1.Typography, { style: {
                                    maxWidth: '128px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'pre',
                                }, title: t(`common:${documentToUpload.documentType}`), variant: "bodyMedium" },
                                t(`common:${documentToUpload.documentType}`),
                                postDocumentTypeElt),
                            canTriggerDocumentRegeneration &&
                                Boolean(handleDocumentRegeneration) &&
                                Boolean(documentToUpload.canBeGenerated) &&
                                determineDocumentGeneratedState &&
                                determineDocumentGeneratedState(documentToUpload) && (react_1.default.createElement(ux_1.StandardButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    if (handleDocumentRegeneration) {
                                        setIsGenerating(true);
                                        try {
                                            yield handleDocumentRegeneration(shipmentRef, documentToUpload.documentType);
                                        }
                                        finally {
                                            setIsGenerating(false);
                                        }
                                    }
                                }) },
                                react_1.default.createElement(ux_1.Tooltip, { title: t('common:generate') },
                                    react_1.default.createElement(react_1.default.Fragment, null,
                                        !isGenerating && react_1.default.createElement(ux_1.BuildOutlinedIcon, { fontSize: 'small' }),
                                        isGenerating && react_1.default.createElement(ux_1.CircularProgress, { size: 16 })))))))),
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                        react_1.default.createElement(ux_1.Grid, { item: true }, documentStateTag),
                        showExpansionIcon && hasDetailsComponent && !isUploading && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.IconButton, { onClick: () => setIsOpen((prev) => !prev) }, isOpen ? (react_1.default.createElement(ux_1.KeyboardArrowUpIcon, { fill: theme.palette.primary.light })) : (react_1.default.createElement(ux_1.KeyboardArrowDownIcon, { fill: theme.palette.grey[100] }))))))))));
    }, [
        determineDocumentGeneratedState,
        setIsGenerating,
        isGenerating,
        canTriggerDocumentRegeneration,
        handleDocumentRegeneration,
        shipmentRef,
        canUploadThisDocument,
        classes,
        documentToUpload,
        hasDetailsComponent,
        isEditable,
        isEntitledToModifyThisDocument,
        isLoading,
        isOpen,
        isUploaded,
        isUploading,
        shouldMarkAsRequired,
        t,
        theme,
    ]);
    const Row3Content = (0, react_1.useCallback)(() => {
        return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", justifyContent: "space-between" },
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
                react_1.default.createElement(ux_1.Box, { mt: 1 },
                    react_1.default.createElement(ux_1.Typography, { className: classes.tableTitle, variant: "bodyMedium" }, `${t(`common:uploadedOn`)}:`))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                isUploaded && (
                // DATE UPLOADED
                react_1.default.createElement(ux_1.Typography, null, (0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(language, new Date((uploadedDocument === null || uploadedDocument === void 0 ? void 0 : uploadedDocument.dateCreated) || '')))),
                !isUploaded && (react_1.default.createElement(ux_1.Typography, { style: { textAlign: 'right' }, variant: "bodyMedium" }, t(`common:noDocument`)))),
            react_1.default.createElement(ux_1.Grid, { item: true, xs: 1 }, (isUploaded || !canUploadThisDocument) && (react_1.default.createElement(react_1.default.Fragment, null, isUploaded && (react_1.default.createElement(ux_1.Grid, { alignItems: "flex-end", container: true, justifyContent: "flex-end" }, actions)))))));
    }, [actions, canUploadThisDocument, isUploaded, t, uploadedDocument, classes, language]);
    const Row4Content = (0, react_1.useCallback)(() => (react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "flex-start" },
        react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
            react_1.default.createElement(ux_1.Box, { mr: 1 },
                react_1.default.createElement(ux_1.Typography, { className: classes.tableTitle, variant: "bodyMedium" }, `${t(`common:status`)}:`))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Typography, { style: {
                    color: shouldMarkAsRead ? theme.palette.success.main : theme.palette.error.main,
                } }, t(`common:${shouldMarkAsRead ? 'read' : 'unread'}`))))), [shouldMarkAsRead, t, theme, classes]);
    const deliveryItems = Object.keys((uploadedDocument === null || uploadedDocument === void 0 ? void 0 : uploadedDocument.deliveries) || {})
        .map((key) => {
        var _a;
        if (key === 'migration')
            return null;
        return {
            header: (_a = uploadedDocument === null || uploadedDocument === void 0 ? void 0 : uploadedDocument.deliveries) === null || _a === void 0 ? void 0 : _a[key],
            value: (0, utils_1.formatDate)(key, utils_1.DATE_FORMAT_STRINGS_SHORT_WITH_TIMES[language]),
        };
    })
        .filter((item) => item);
    const deliveries = react_1.default.createElement(__2.SimpleList, { items: deliveryItems });
    // Rendering logic END
    return (react_1.default.createElement(react_hook_form_1.FormProvider, Object.assign({}, formTools),
        react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
            react_1.default.createElement(ux_1.Grid, { className: isEntitledToModifyThisDocument ? classes.myDocumentRounded : '', container: true, direction: "column" },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                    react_1.default.createElement(Row1Content, null)),
                (!hasDetailsComponent || isOpen) && ((_a = Object.keys(errors)) === null || _a === void 0 ? void 0 : _a.length) > 0 && (react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 }, Object.keys(errors).map((errKey) => {
                    var _a;
                    return (react_1.default.createElement(ux_1.Typography, { color: "error", key: `error-${errKey}` }, (_a = errors === null || errors === void 0 ? void 0 : errors[errKey]) === null || _a === void 0 ? void 0 : _a.message));
                }))),
                hasDetailsComponent && children && (react_1.default.createElement(react_1.default.Fragment, null, children({
                    canUploadThisDocument,
                    isOpen,
                    isUploading,
                    setDetailsOpen: setIsOpen,
                    setHideUpload: setShouldHideUploadWhenRequiredNotUploaded,
                    shouldMarkAsRequired,
                }))),
                (!hasDetailsComponent || isOpen) && canUploadThisDocument && !shouldHideDragDrop && (react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: '', name: "files", render: ({ field: { onChange } }) => {
                                var _a;
                                const shouldHide = Boolean(((_a = Object.keys(errors)) === null || _a === void 0 ? void 0 : _a.length) > 0) || isUploading;
                                return (react_1.default.createElement("label", { htmlFor: `${documentToUpload.documentType}-FileUpload`, id: `${documentToUpload.documentType}-FileUpload` },
                                    react_1.default.createElement(ux_1.Box, { mb: 2 },
                                        shouldHide && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", className: classes.disabledDropzoneWrapper, container: true, direction: "column", justifyContent: "center" },
                                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, item: true },
                                                react_1.default.createElement(ux_1.Typography, { className: classes.disabledDropzoneText, variant: "bodyMedium" }, t(`common:dragDocOrChooseFile`))),
                                            react_1.default.createElement(ux_1.Grid, { item: true },
                                                react_1.default.createElement(__2.DocumentUploadText, { fileTypes: "pdf", isDisabled: true, t: t })))),
                                        !shouldHide && (react_1.default.createElement(__2.FileUpload, { Icon: () => {
                                                return react_1.default.createElement(__2.DocumentUploadText, { fileTypes: "pdf", t: t });
                                            }, acceptedFileType: "application/pdf", className: classes.dragDropRoot, dropzoneClass: classes.dragDropzoneClass, dropzoneParagraphClass: classes.dragDropzoneParagraphClass, label: t(`common:dragDocOrChooseFile`), onChange: (files) => {
                                                onChange(files);
                                                // Manually submitting the form as we have no submit btn by design
                                                handleSubmit(onSubmitSuccess)();
                                            }, onError: (errorMessage) => {
                                                addAlert({
                                                    message: errorMessage,
                                                    severity: 'error',
                                                });
                                                setError('files', {
                                                    message: errorMessage,
                                                    type: 'manual',
                                                });
                                            } })))));
                            }, rules: { required: true } })))),
                (!isEntitledToModifyThisDocument || !hasDetailsComponent || isOpen) &&
                    (isUploaded || !isEntitledToModifyThisDocument) && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                        react_1.default.createElement(Row3Content, null)),
                    isUploaded && (react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                        react_1.default.createElement(Row4Content, null))))),
                hasPermissionToViewAllDocuments && deliveryItems.length !== 0 && (react_1.default.createElement(__2.SidebarSection, { headerStyle: "bodyMedium", title: t('common:documentDeliveries') }, deliveries))))));
};
exports.ShipmentsDashboardSidebarDocumentItem = ShipmentsDashboardSidebarDocumentItem;
