import { type AppState, baseLoadingState } from '../../stores';
import { type TFunction } from 'next-i18next';
import { transformShipmentsToShipperShipmentDataRows } from '@truxweb/shipment-utils';
import { type TReduxListResponse } from '../../types';
import { type TShipperShipmentDataRow } from '@truxweb/schemas';

export const selectShipperShipmentListData = (
  t: TFunction
): ((state: AppState) => TReduxListResponse<TShipperShipmentDataRow[]>) => {
  return (state: AppState) => {
    const userData = state?.auth?.userData || { ...baseLoadingState, hasLoggedOut: false };
    const loadingState = state?.shipment?.shipments.list || { ...baseLoadingState };
    if (!loadingState.hasLoaded || !userData.hasLoaded) return loadingState;

    // If the list has loaded, return the list data matching the list type
    const response = state?.shipment?.shipments?.list;
    const data = transformShipmentsToShipperShipmentDataRows(
      response?.data || [],
      userData.data.language,
      t
    );

    return { ...loadingState, data };
  };
};
