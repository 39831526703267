import { Box, Grid } from '@truxweb/ux';
import { TPageId, TTruxwebPageProps } from '../../types';
import { AuthorizedPage } from '..';
import { ComingSoon } from '../../components';
import { EPermissionV1 } from '@truxweb/schemas';
import { usePermissions } from '../../hooks';

type TAuthorizedPageProps = TTruxwebPageProps & {
  children?: React.ReactNode;
  pageId?: TPageId;
  permissions?: EPermissionV1[];
};

export const ShipperPage = ({
  children,
  isAuthed,
  pageId,
  permissions,
}: TAuthorizedPageProps): JSX.Element => {
  const [evaluationResult, havePermissionsEvaluated] = usePermissions([
    EPermissionV1.VIEW_SHIPPER_PAGE,
    ...(permissions || []),
  ]);

  return (
    <AuthorizedPage isAuthed={isAuthed} pageId={pageId}>
      {havePermissionsEvaluated && !evaluationResult && (
        <Grid alignItems="center" container justifyContent="center">
          <Box mt={3}>
            <ComingSoon />
          </Box>
        </Grid>
      )}
      {havePermissionsEvaluated && evaluationResult && children}
    </AuthorizedPage>
  );
};
