/* eslint-disable complexity */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@truxweb/ux';
import Boxes from '../../public/assets/images/landingPage/landingpage-boxesblue.svg';
import Checkmark from '../../public/assets/images/icons/CheckmarkIconWhiteFill.svg';
import classnames from 'classnames';
import { EUserTypeV1 } from '@truxweb/schemas';
import { LandingPageButton } from '..';
import React from 'react';
import Truck from '../../public/assets/images/landingPage/landingpage-truckparcels.svg';
import { useLandingPageMarginStyles } from '../../styles';
import { useStyles } from './ShipperCarrierSplitValueProps.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TShipperCarrierSplitValuePropsProps = {
  handleOpenSignup: () => void;
  userType: EUserTypeV1.CARRIER | EUserTypeV1.SHIPPER;
};

export const ShipperCarrierSplitValueProps = ({
  handleOpenSignup,
  userType,
}: TShipperCarrierSplitValuePropsProps): JSX.Element => {
  const classes = useStyles();
  const margins = useLandingPageMarginStyles();
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  let image;
  let headerText;
  let variant = null;
  let xsSize;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const valuePropVariant = 'body2';

  if (userType === EUserTypeV1.CARRIER) {
    image = <Truck height={'100%'} />;
    headerText = t('common:carriers');
    variant = 'midnight';
    xsSize = 4;
  } else if (userType === EUserTypeV1.SHIPPER) {
    image = <Boxes height={'100%'} />;
    headerText = t('common:shippers');
    xsSize = isMobile ? 2 : 3;
  }

  return (
    <>
      <Box className={classes[`bg${userType}`]}>
        <Box className={classes[`bgOverlay${userType}`]} />
        <Box className={classes[`overlay${userType}`]} />
        <Box className={classes[`overlayColor${userType}`]} />
        <Box className={classnames(classes.containerBox, margins.landingPageRightMargins)}>
          {/* TEXT */}
          <Grid
            alignItems="flex-end"
            className={classes.container}
            container
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
          >
            {/* TITLE */}
            <Grid item>
              <Typography className={classes.textHeader} variant={isMobile ? 'h3' : 'h2'}>
                {t(`common:${headerText}`)}
              </Typography>
            </Grid>

            {/* VALUE PROPS */}
            <Grid alignItems="center" className={classes.centerRow} container item wrap="nowrap" xs>
              {/*IMAGE*/}
              <Grid
                alignItems="center"
                className={classes.imageColumn}
                container
                item
                justifyContent="flex-end"
                xs={xsSize}
              >
                <Box className={classes[`svgimage${userType}`]}>{image}</Box>
              </Grid>

              {/*TEXT*/}
              <Grid alignItems="flex-end" container direction="column" wrap="nowrap">
                <Grid alignItems="center" container justifyContent="flex-end" wrap="nowrap">
                  <Typography className={classes.text} noWrap variant={valuePropVariant}>
                    {!isTablet && t(`common:${userType}-landingPage-splitValueProp1`)}
                    {isTablet && t(`common:${userType}-landingPage-splitValueProp1-mobile`)}
                  </Typography>
                  <Box className={classes.iconWrap}>
                    <Checkmark className={classes[`${userType}icon`]} />
                  </Box>
                </Grid>
                <Grid alignItems="center" container justifyContent="flex-end" wrap="nowrap">
                  <Typography className={classes.text} noWrap variant={valuePropVariant}>
                    {t(`common:${userType}-landingPage-splitValueProp2`)}
                  </Typography>
                  <Box className={classes.iconWrap}>
                    <Checkmark className={classes[`${userType}icon`]} />
                  </Box>
                </Grid>
                <Grid alignItems="center" container justifyContent="flex-end" wrap="nowrap">
                  <Typography className={classes.text} noWrap variant={valuePropVariant}>
                    {!isTablet && t(`common:${userType}-landingPage-splitValueProp3`)}
                    {isTablet && t(`common:${userType}-landingPage-splitValueProp3-mobile`)}
                  </Typography>
                  <Box className={classes.iconWrap}>
                    <Checkmark className={classes[`${userType}icon`]} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* BUTTON */}
            <Grid className={classes.buttonWrap} container item justifyContent="flex-end">
              <LandingPageButton
                isSubmit
                onClick={() => {
                  handleOpenSignup();
                }}
                text={isTablet ? t('common:startForFreeTodayShort') : t('common:startForFreeToday')}
                type="standard"
                variant={variant}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
