import { makeRequestToApiGateway } from '../../apiUtils';

export const markUserNotificationsAsReadByIds = async (ids: number[]): Promise<void> => {
  await makeRequestToApiGateway(
    'notificationPatchV1NotificationsReadByIds',
    {
      ids: ids.join(','),
    },
    null,
    null,
    { foo: 123 }
  );
};
