import {
  ACCEPTED_QUOTE_STATUSES,
  quoteToLoadDefinition,
  quoteToSearch,
} from '@truxweb/quote-utils';
import {
  addCommentToCarrierQuoteRequest,
  updateQuoteRequestStatusForShipper,
  updateShipperQuoteRequest,
  uploadCommentAttachments,
} from '../../actions';
import { Box, CircularProgress, Grid, Typography } from '@truxweb/ux';
import { clearSearchResults, setLoad, setSearchFormData, setShipperQuotesData } from '../../stores';
import {
  ELanguageV1,
  EShipmentQuoteRequestStatusV1,
  EUserTypeV1,
  type TCarrierShipmentQuoteRequestV1,
  type TQuoteSearchFormDataV1,
  type TShipmentQuoteRequestLocationV1,
  type TShipperShipmentQuoteRequestV1,
} from '@truxweb/schemas';
import { getLocalizedRoute, getRouteProperties, saveBookingQuery } from '../../utils';
import { QUOTE_SIDEBAR_REFRESH_MS, SHIPMENT_DOCUMENT_UPLOAD } from '../../config';
import { QuoteRequestCheckoutWrapper, TruxiiMessage } from '..';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useAlerts,
  useErrorHandling,
  useInterval,
  useReduxShipperQuoteById,
  useRefresh,
  useSearchFormParams,
  useShipmentCredits,
  useShipmentEntityTags,
  useShipperQuoteComments,
  useUserData,
  useUxState,
} from '../../hooks';
import { convertFormDataToBookingQueryV1 } from '@truxweb/utils';
import { QuoteRequestShipperRequestDetails } from '@truxweb/common-components';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

type TQuoteRequestShipperSidebarProps = {
  ipAddress: string;
  onQuoteUpdate: () => void;
};

const REQUIRED_NAMESPACES = ['common'];

export const QuoteRequestShipperSidebar = ({
  ipAddress,
  onQuoteUpdate,
}: TQuoteRequestShipperSidebarProps): JSX.Element => {
  const {
    i18n: { language },
    t,
  } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const dispatch = useDispatch();
  const errorHandler = useErrorHandling();

  const {
    query: { quoteRequestId },
  } = router;
  const { addAlert } = useAlerts();
  const { userData, userType } = useUserData();
  const [currentStep, setCurrentStep] = useState<'details' | 'checkout'>('details');
  const [quoteAcceptanceReason, setAcceptanceReason] = useState<string | null>(null);
  const [acceptedQuoteId, setQuoteAcceptance] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasInit, setInit] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const { data: searchFormParams } = useSearchFormParams();
  const shipmentCredits = useShipmentCredits(false);
  const uxState = useUxState();
  const otherUserType = Object.values(EUserTypeV1).filter((type) => {
    return ![userType].includes(type);
  });
  const { handleRefresh, shouldRefresh } = useRefresh();
  const tags = useShipmentEntityTags(shouldRefresh);

  const { data: quoteData, isLoading } = useReduxShipperQuoteById(
    parseInt(quoteRequestId as string, 10),
    shouldRefresh
  );
  const handleCommentAttachments = useCallback(
    async (carrierQuoteRequestId: number, files: File[]): Promise<any[]> => {
      return await uploadCommentAttachments(
        `QUOTES/${carrierQuoteRequestId}`,
        SHIPMENT_DOCUMENT_UPLOAD,
        files
      );
    },
    []
  );
  const handleUpdateShipperQuoteRequest = useCallback(
    async (request: TShipperShipmentQuoteRequestV1) => {
      try {
        await updateShipperQuoteRequest({ ...quoteData, ...request });
        handleRefresh();
      } catch (err) {
        errorHandler(err);
      }
    },
    [errorHandler, quoteData, handleRefresh]
  );
  const handleAddComment = useCallback(
    async (
      carrierQuoteRequestId: number,
      commentData: Record<string, any>,
      isVisibleExternally: boolean,
      attachments?: File[]
    ) => {
      let attachmentData = [];

      try {
        if (attachments?.length) {
          attachmentData = await handleCommentAttachments(carrierQuoteRequestId, attachments);
        }
        await addCommentToCarrierQuoteRequest(EUserTypeV1.SHIPPER, carrierQuoteRequestId, {
          addCommentRequest: {
            attachments: attachmentData,
            comment: commentData.comment,
          },
          carrierQuoteRequestId,
          companyExtId: userData.companyExtId,
          overrideCommentVisbility: isVisibleExternally,
          userExtId: userData.extId,
          userType: EUserTypeV1.SHIPPER,
        });
      } catch (err) {
        errorHandler(err);
      }
    },
    [handleCommentAttachments, errorHandler, userData]
  );

  const handleCancelCheckout = useCallback(() => {
    setQuoteAcceptance(null);
    setAcceptanceReason(null);
    setCurrentStep('details');
  }, [setCurrentStep, setQuoteAcceptance, setAcceptanceReason]);

  const handleCheckoutComplete = useCallback(() => {
    handleRefresh();
    onQuoteUpdate();
    handleCancelCheckout();
  }, [handleCancelCheckout, onQuoteUpdate, handleRefresh]);

  const handleCheckout = useCallback(
    (acceptanceReason: string) => {
      setAcceptanceReason(acceptanceReason);
      setCurrentStep('checkout');
    },
    [setCurrentStep, setAcceptanceReason]
  );

  const handleRouteToShipment = useCallback(
    (shipmentRef: string) => {
      const pageId = 'shipments';
      const { route } = getRouteProperties(router.locale, pageId, [shipmentRef]);
      router.push(`${route}/?shipmentRef=${shipmentRef}`);
    },
    [router]
  );

  const handleRouteToLocation = useCallback(
    (location: TShipmentQuoteRequestLocationV1) => {
      const pageId = 'account/address';
      const { route } = getRouteProperties(router.locale, pageId);
      router.push(`${route}/?selectedLocationId=${location.sourceLocationId}`);
    },
    [router]
  );

  const handleUpdateCarrierQuoteStatusForShipper = useCallback(
    async (
      quoteRequestId: number,
      quoteRequestStatus: EShipmentQuoteRequestStatusV1,
      statusChangeReason?: string
    ): Promise<number> => {
      try {
        setIsSaving(true);
        const response = await updateQuoteRequestStatusForShipper(
          quoteRequestId,
          quoteRequestStatus,
          statusChangeReason
        );
        dispatch(setShipperQuotesData({ item: response }));
        onQuoteUpdate();
        handleRefresh();
        return response.id;
      } finally {
        setIsSaving(false);
      }
    },
    [setIsSaving, onQuoteUpdate, handleRefresh, dispatch]
  );

  const onRefresh = useCallback(() => {
    handleRefresh();
    setIsPolling(false);
  }, [handleRefresh, setIsPolling]);

  const handlePoll = useCallback(() => {
    if (!quoteData?.shipmentId && !isSaving && !isPolling) {
      setIsPolling(true);
      onRefresh();
    }
  }, [onRefresh, quoteData, isSaving, setIsPolling, isPolling]);

  const emptyCommentDisplay = (
    <TruxiiMessage
      additionalContent={
        <Box mt={1.5} style={{ width: '100%' }}>
          <Typography>
            {t('common:chatWithYourContent', {
              userType: t(`common:${otherUserType[0].toLowerCase()}`),
            })}{' '}
          </Typography>
        </Box>
      }
      content={t('common:chatWithYour', {
        userType: t(`common:${otherUserType[0].toLowerCase()}`),
      })}
      direction="column"
    />
  );

  // Refresh the data on an interval
  useInterval(handlePoll, QUOTE_SIDEBAR_REFRESH_MS);

  useEffect(() => {
    if (!acceptedQuoteId && quoteData) {
      const selectedQuote = quoteData.carrierRequests.find(
        ({ carrierQuoteStatus, shipperQuoteStatus }) => {
          return (
            ACCEPTED_QUOTE_STATUSES.includes(shipperQuoteStatus) &&
            ACCEPTED_QUOTE_STATUSES.includes(carrierQuoteStatus)
          );
        }
      );
      setQuoteAcceptance(selectedQuote?.id || null);
    }
  }, [quoteData, acceptedQuoteId, setQuoteAcceptance]);

  useEffect(() => {
    if (!hasInit) {
      handleRefresh();
      setInit(true);
    }
  }, [handleRefresh, hasInit, setInit]);

  if (!quoteData) {
    return (
      <Grid alignItems="center" container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (currentStep === 'details')
    return (
      <QuoteRequestShipperRequestDetails
        acceptedQuoteId={acceptedQuoteId}
        addAlert={addAlert}
        availableTags={tags?.data || []}
        canAdministerComments={false}
        companyLocationState={uxState.createdQuoteCompanyLocations}
        emptyCommentDisplay={emptyCommentDisplay}
        handleRouteToLocation={handleRouteToLocation}
        handleRouteToShipment={handleRouteToShipment}
        handleSearch={async () => {
          const searchData = await quoteToSearch({
            quote: quoteData,
            searchParams: searchFormParams,
          });
          const loadDefinition = quoteToLoadDefinition({
            quote: quoteData,
          });
          searchData.loadDefinition = loadDefinition;

          const query = convertFormDataToBookingQueryV1(
            searchData as TQuoteSearchFormDataV1,
            searchFormParams,
            Boolean(shipmentCredits.data.length)
          );

          saveBookingQuery(query);
          dispatch(setSearchFormData({ data: searchData, shouldForceRefresh: false }));
          dispatch(clearSearchResults());
          dispatch(setLoad({ data: { ...loadDefinition, isExistingSearch: false } }));

          const resultsRoute = getLocalizedRoute(router.locale, 'results');
          router.push(resultsRoute);
        }}
        handleSubmitComment={handleAddComment}
        handleUpdateShipperQuoteRequest={handleUpdateShipperQuoteRequest}
        isReloading={isLoading}
        isSaving={isSaving}
        key={quoteData.dateUpdated}
        linkedCarrierRequestId={quoteRequestId ? parseInt(quoteRequestId as string, 10) : null}
        locale={language as ELanguageV1}
        onAcceptQuote={handleCheckout}
        quoteData={quoteData}
        setQuoteAcceptance={setQuoteAcceptance}
        t={t}
        updateQuoteRequestStatusForShipper={handleUpdateCarrierQuoteStatusForShipper}
        useQuoteComments={useShipperQuoteComments}
        userData={userData}
        viewingUserType={EUserTypeV1.SHIPPER}
      />
    );
  if (currentStep === 'checkout') {
    const selectedCarrierQuote = quoteData.carrierRequests.find(
      (request: TCarrierShipmentQuoteRequestV1) => {
        return request.id === acceptedQuoteId;
      }
    );
    // This is an edge case
    if (!selectedCarrierQuote) return null;
    return (
      <QuoteRequestCheckoutWrapper
        carrierQuoteData={selectedCarrierQuote}
        ipAddress={ipAddress}
        key={quoteData.dateUpdated}
        onCancelCheckout={handleCancelCheckout}
        onCheckoutComplete={handleCheckoutComplete}
        quoteAcceptanceReason={quoteAcceptanceReason}
        shipperQuoteData={quoteData}
      />
    );
  }

  return null;
};
