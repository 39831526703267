"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebCloudsMoonIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M2.777,15.513a1.284,1.284,0,0,0-.728,1.543,1,1,0,0,1-1.934.509,3.284,3.284,0,0,1,1.874-3.89,5.316,5.316,0,0,1,.039-2.442A5.534,5.534,0,0,1,9.634,7.465a1,1,0,0,1-.8,1.832,3.63,3.63,0,0,0-1.972-.258,3.581,3.581,0,0,0-2.916,4.27A1.963,1.963,0,0,1,2.777,15.513Zm21.185-4.357A1.963,1.963,0,0,0,22.412,9.9,5.944,5.944,0,0,1,18.043,7.15a5.869,5.869,0,0,1-.351-4.614A1.945,1.945,0,0,0,17.231.6,1.971,1.971,0,0,0,15.3.069a8.787,8.787,0,0,0-3.011,1.54A8.027,8.027,0,0,0,9.979,4.59a1,1,0,0,0,1.824.82,6.006,6.006,0,0,1,1.731-2.234,8.662,8.662,0,0,1,2.25-1.237A7.864,7.864,0,0,0,16.3,8.133a7.7,7.7,0,0,0,5.8,3.68,7.433,7.433,0,0,1-1.78,1.4,1,1,0,0,0,.983,1.742,9.3,9.3,0,0,0,2.307-1.832A1.963,1.963,0,0,0,23.962,11.156Zm-4.087,9.253a4.616,4.616,0,0,1-3.453,3.445,5.011,5.011,0,0,1-.992.123c-1.279.024-7.735.038-9.236,0a3.476,3.476,0,0,1-.784-.115,3.285,3.285,0,0,1-.421-6.187,5.316,5.316,0,0,1,.039-2.442,5.548,5.548,0,0,1,10.525-.793c.078.188.406.262.736.323.108.02.2.039.268.056h0A4.672,4.672,0,0,1,19.875,20.409Zm-3.834-3.657a2.948,2.948,0,0,1-2.335-1.544A3.6,3.6,0,0,0,9.86,13.039a3.581,3.581,0,0,0-2.917,4.27,1.963,1.963,0,0,1-1.166,2.2,1.284,1.284,0,0,0-.728,1.543,1.26,1.26,0,0,0,.871.873,1.566,1.566,0,0,0,.334.049c1.222.033,7.594.029,9.139,0a2.947,2.947,0,0,0,.586-.074,2.636,2.636,0,0,0,1.947-1.941A2.679,2.679,0,0,0,16.041,16.752Z" })));
exports.default = TruxwebCloudsMoonIcon;
