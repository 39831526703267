import { ELanguageV1, EUserTypeV1, type TShipperQuoteDataGridRowV1 } from '@truxweb/schemas';
import { type GridFilterModel, type GridSortModel } from '@truxweb/ux';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuoteRequestListData } from '../../../actions';
import { selectShipperQuoteListData } from '../../../selectors';
import { type TFunction } from 'next-i18next';
import { type TReduxListResponse } from '../../../types';
import { useEffect } from 'react';
import { useUserData } from '../..';

type TShipperShipmentListData = {
  language: ELanguageV1;
  t: TFunction;
  userType: EUserTypeV1;
  limit: number;
  pageSize?: number;
  page?: number;
  sort?: GridSortModel;
  filter?: GridFilterModel | null;
  shouldLoadAllData: boolean;
};

export const useReduxShipperQuoteList = (
  request: TShipperShipmentListData,
  shouldFetch: boolean,
  shouldRefetch?: boolean
): TReduxListResponse<TShipperQuoteDataGridRowV1[]> => {
  const { filter, limit, page, pageSize, shouldLoadAllData, sort, userType } = request;
  const dispatch = useDispatch();
  const shipmentList = useSelector(selectShipperQuoteListData({ ...request, shouldLoadAllData }));
  const { userData } = useUserData();

  useEffect(() => {
    if (
      shouldFetch &&
      ((!shipmentList.hasLoaded && !shipmentList.isLoading && !shipmentList.isProgressiveLoading) ||
        (!shipmentList.isLoading && !shipmentList.isProgressiveLoading && shouldRefetch)) &&
      userType
    ) {
      dispatch(
        fetchQuoteRequestListData({
          filter,
          language: userData?.language || ELanguageV1.EN_CA,
          limit,
          page,
          pageSize,
          shouldLoadAllData,
          sort,
          userType,
        })
      );
    }
  }, [
    shipmentList,
    shouldLoadAllData,
    dispatch,
    shouldRefetch,
    userType,
    limit,
    shouldFetch,
    userData,
    pageSize,
    page,
    sort,
    filter,
  ]);
  return shipmentList;
};
