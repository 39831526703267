import { TUseActionResponse, useAction } from './useAction';
import { listUserBankAccountsV2 } from '../actions';
import { TBankAccountResponseV2 } from '@truxweb/schemas';
import { useCallback } from 'react';

export const useBankAccounts = (
  shouldForceRefetch?: boolean
): TUseActionResponse<TBankAccountResponseV2[]> => {
  const action = useCallback(async (): Promise<TBankAccountResponseV2[]> => {
    return await listUserBankAccountsV2();
  }, []);

  return useAction<TBankAccountResponseV2[]>(action, true, shouldForceRefetch);
};
