import { makeStyles, Theme } from '@truxweb/ux';

type TDesktopFooterStyleProps = {
  footerPalette: 'light' | 'dark';
};

export const useStyles = makeStyles<Theme, TDesktopFooterStyleProps>((theme: Theme) => ({
  fullWidth: {
    width: '100%',
  },
  header: {
    color: ({ footerPalette }) => {
      return footerPalette === 'light' ? theme.palette.primary.main : theme.palette.common.white;
    },
    fontWeight: 600,
  },
  siteMap: {
    minWidth: theme.spacing(79),
  },
  siteMapLink: {
    '& p': {
      '&:hover': {
        color: theme.palette.primary.main,
      },
      color: ({ footerPalette }) => {
        return footerPalette === 'light' ? theme.palette.grey[600] : theme.palette.common.white;
      },
    },
  },
  title: {
    lineHeight: `${theme.spacing(2)}px`,
  },
}));
