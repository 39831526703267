// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniqueIdentifierTypeV1 = void 0;
var UniqueIdentifierTypeV1;
(function (UniqueIdentifierTypeV1) {
    UniqueIdentifierTypeV1["FMCSA"] = "FMCSA";
    UniqueIdentifierTypeV1["DOT"] = "DOT";
    UniqueIdentifierTypeV1["NIR"] = "NIR";
    UniqueIdentifierTypeV1["SCAC"] = "SCAC";
})(UniqueIdentifierTypeV1 || (exports.UniqueIdentifierTypeV1 = UniqueIdentifierTypeV1 = {}));
