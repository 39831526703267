import {
  AppBar,
  Box,
  CloseIcon,
  Dialog,
  Grid,
  IconButton,
  StandardButton,
  Typography,
} from '@truxweb/ux';
import React, { useCallback, useState } from 'react';
import { ANNOUNCEMENTS_VERSION } from '../../config';
import { getLocalizedRoute } from '../../utils';
import { PublicShipmentsMarketing } from '..';
import { useRouter } from 'next/router';
import { useStyles } from './ReleasePopup.styles';
import { useTranslation } from 'next-i18next';
import Wings from '../../public/assets/images/logos/truxweb-wings.svg';

const REQUIRED_NAMESPACES = ['common'];

export const ReleasePopup = (): JSX.Element => {
  const classes = useStyles();
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);
  const router = useRouter();
  const [isOpen, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
    localStorage.setItem(`${ANNOUNCEMENTS_VERSION}-popup`, 'true');
  }, [setOpen]);

  const handleDirectToAnnouncements = useCallback(() => {
    handleClose();
    const route = getLocalizedRoute(i18n.language, 'announcements');
    router.push(route);
  }, [i18n, router, handleClose]);

  const width = '614px';

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: '16px' } }}
      maxWidth={'lg'}
      onClose={handleClose}
      open={isOpen}
    >
      <Grid container direction="column" style={{ width }}>
        <Grid item style={{ position: 'relative' }} xs>
          <AppBar style={{ height: 72, lineHeight: '72px', position: 'relative' }}>
            <Grid alignItems="center" container justifyContent="space-between">
              <Grid item>
                <Grid alignItems="center" container item>
                  <Grid item>
                    <Box ml={2} mr={2}>
                      <Wings style={{ height: 36, marginTop: 16 }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={-1}>
                      <Typography
                        color="white"
                        fontStyle="semibold"
                        style={{ fontFamily: 'Roboto' }}
                        variant="h2"
                      >
                        {t('common:releaseNotes')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box mt={-1}>
                  <IconButton data-testid="ReleasePopup-CloseButton" onClick={handleClose}>
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </AppBar>
        </Grid>
        <Grid item style={{ width: '100%' }} xs>
          <PublicShipmentsMarketing />
          <Box mt={9} style={{ width: '100%' }}>
            <StandardButton
              className={classes.button}
              onClick={handleDirectToAnnouncements}
              textClassName={classes.buttonText}
            >
              {t('common:clickToViewAllReleaseNotes')}
            </StandardButton>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};
