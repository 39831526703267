import { type AppState, baseLoadingState } from '../../stores';
import { ELanguageV1, EUserTypeV1, type TCarrierQuoteDataGridRowV1 } from '@truxweb/schemas';
import { type GridSortModel } from '@truxweb/ux';
import { mapCarrierQuoteRequestToDataGridRow } from '@truxweb/quote-utils';
import { type TFunction } from 'next-i18next';
import { type TReduxListResponse } from '../../types';

type TSelectCarrierQuoteList = {
  language: ELanguageV1;
  t: TFunction;
  userType: EUserTypeV1;
  sort?: GridSortModel;
};
type TCarrierQuoteListResponse = TReduxListResponse<TCarrierQuoteDataGridRowV1[]>;

export const selectCarrierQuoteListData = (
  request: TSelectCarrierQuoteList
): ((state: AppState) => TCarrierQuoteListResponse) => {
  return (state: AppState): TCarrierQuoteListResponse => {
    const { language, t, userType } = request;
    const loadingState = state?.quote?.carrierQuoteList || { ...baseLoadingState };
    if (!loadingState.hasLoaded) return loadingState;

    const data = mapCarrierQuoteRequestToDataGridRow(
      state.quote.carrierQuoteList?.data || [],
      language,
      userType,
      t
    );

    return { ...loadingState, data };
  };
};
