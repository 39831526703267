"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    //Datagrid Containers/Wrappers and style overwrites
    checkbox: {
        color: `${theme.palette.grey[400]} !important`,
        padding: 0,
    },
    checked: {
        color: `${theme.palette.primary.light} !important`,
    },
    dataGridFlexContainer: {
        flexGrow: 1,
    },
    dataGridFlexWrapper: {
        '& .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer ': {
            paddingLeft: '26px',
        },
        //Bold header text
        '& .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '500',
        },
        //fix left align header padding
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: '0',
        },
        //Remove column Separator in between headers
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
            visibility: 'hidden',
        },
        //Footer uses flex by default; this is needed to align pagination away from the right
        '& .MuiDataGrid-footerContainer': {
            justifyContent: ({ paginationPosition }) => paginationPosition,
        },
        //Remove border from datagrid Wrapper and apply smaller font size
        '& .MuiDataGrid-root': {
            border: 'none',
            color: '#636363',
            fontSize: '15px',
            fontWeight: 500,
        },
        '& .MuiDataGrid-root  .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .MuiPagination-ul > li  .Mui-selected': {
            backgroundColor: `transparent !important`,
            color: `${theme.palette.grey[700]} !important`,
        },
        '& .MuiPaginationItem-root': {
            color: `${theme.palette.grey[400]} !important`,
        },
        display: 'flex',
        height: ({ gridHeight, shouldUseViewportHeight }) => {
            if (!gridHeight)
                return `50vh !important`;
            if (shouldUseViewportHeight) {
                return `${gridHeight}vh !important`;
            }
            return `${gridHeight}px !important`;
        },
        width: '100%',
    },
    paginationText: {
        fontSize: '13px',
        margin: '5px 0 5px 12px',
    },
    //Grid Toolbar
    toolbarButton: {
        backgroundColor: '#f3f3f3',
        border: '1px solid #d2d2d2',
        borderRadius: '5px 5px',
        color: '#636363',
        fontWeight: 'bold',
        height: '30px',
        lineHeight: '0',
        margin: '0 5px',
        padding: '0px 10px !important',
    },
    toolbarContainer: {
        minHeight: '80px',
    },
    toolbarWrapper: {
        width: '100%',
    },
}));
