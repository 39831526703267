/* eslint-disable max-lines */
import {
  Box,
  Button,
  CloseIcon,
  Collapse,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TruxwebShieldCheckIcon,
  Typography,
} from '@truxweb/ux';
import { displayPasswordSchema, paramValidation, passwordSchema } from '../../utils';
import { ELanguageV1, ENotificationTypeV1, type TUpdateUserRequestV1 } from '@truxweb/schemas';
import { EnumCheckboxGroup, EnumSelect, FormField, SaveButton } from '@truxweb/common-components';
import { Loading, RegisterConfirmationCode } from '..';
import React, { useCallback, useEffect, useState } from 'react';
import { reloadUserData, updateUserAttributes } from '../../actions';
import { useAlerts, useErrorHandling, useUserData } from '../../hooks';
import { Auth } from 'aws-amplify';
import { formatPhoneNumberFromCountryName } from '@truxweb/utils';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useStyles } from './AccountDetails.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

const DEFAULT_PHONE_COUNTRY_CODE = 'CA';
export const AccountDetails = (): JSX.Element => {
  const [isPasswordEdit, setPasswordEdit] = useState(false);

  const [isCodeSending, setCodeSending] = useState(false);
  const [isVerificationDialogShown, setVerificationDialogShown] = useState(false);
  const [verificationDialogType, setVerificationDialogType] = useState<
    'password' | 'email' | 'phone' | null
  >(null);

  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isPasswordComponentValid, setIsPasswordComponentValid] = useState(
    (): Record<string, boolean> => {
      const initialState = {};
      displayPasswordSchema.forEach((args) => {
        initialState[args] = false;
      });
      return initialState;
    }
  );

  const classes = useStyles({ isPasswordEdit });
  const dispatch = useDispatch();
  const { isEmailVerified, isPhoneNumberVerified, userData } = useUserData();
  const {
    areNotificationsEnabled,
    email,
    firstName,
    jobTitle,
    lastName,
    phone: phoneNumber,
    phoneExt,
  } = userData || {};

  const [areNotificationPreferencesVisible, setNotificationPreferencesVisible] = useState(
    Boolean(areNotificationsEnabled)
  );

  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { addAlert } = useAlerts();
  const errorHandler = useErrorHandling();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm();

  const passwordResetForm = useForm();

  const upToDatePassword = passwordResetForm.watch('password');
  const upToDatePasswordConfirm = passwordResetForm.watch('passwordConfirm');
  const notificationStatus = watch('areNotificationsEnabled');

  const showVerificationDialog = useCallback(
    (dialogType: 'email' | 'phone' | 'password') => {
      setVerificationDialogType(dialogType);
      setVerificationDialogShown(true);
    },
    [setVerificationDialogType, setVerificationDialogShown]
  );

  const hideVerificationDialog = useCallback(() => {
    setVerificationDialogType(null);
    setVerificationDialogShown(false);
  }, [setVerificationDialogShown, setVerificationDialogType]);

  const handleResetPassword = useCallback(async () => {
    const values = passwordResetForm.getValues();
    try {
      if (!isVerificationDialogShown) {
        setCodeSending(true);
        await Auth.forgotPassword(values.email);
        showVerificationDialog('password');
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setCodeSending(false);
    }
  }, [
    showVerificationDialog,
    errorHandler,
    passwordResetForm,
    isVerificationDialogShown,
    setCodeSending,
  ]);

  const handleResendConfirmationCode = useCallback(async () => {
    try {
      setCodeSending(true);
      if (verificationDialogType === 'password') {
        await Auth.forgotPassword(userData.email);
      } else {
        await Auth.resendSignUp(userData.email);
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setCodeSending(false);
    }
  }, [userData, errorHandler, setCodeSending, verificationDialogType]);

  const handleSubmitConfirmationCode = useCallback(
    async ({ confirmationCode }) => {
      try {
        setIsVerifying(true);
        if (verificationDialogType === 'password') {
          const values = passwordResetForm.getValues();
          await Auth.forgotPasswordSubmit(values.email, confirmationCode, values.password);
          setPasswordEdit(false);
        } else if (['email', 'phone'].includes(verificationDialogType)) {
          const property = verificationDialogType === 'email' ? 'email' : 'phone_number';
          await Auth.verifyCurrentUserAttributeSubmit(property, confirmationCode);
        }
        dispatch(reloadUserData());
        hideVerificationDialog();
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsVerifying(false);
      }
    },
    [passwordResetForm, verificationDialogType, errorHandler, dispatch, hideVerificationDialog]
  );

  const onSubmitUserInformationUpdateSuccess = useCallback(
    async (data: Record<string, any>): Promise<void> => {
      try {
        setIsLoading(true);
        const congitoUser = await Auth.currentAuthenticatedUser();
        /* eslint-disable camelcase */
        // NOTE: Currently we assume all phone numbers are North American
        await Auth.updateUserAttributes(congitoUser, {
          email: data.email,
          phone_number: formatPhoneNumberFromCountryName(
            data.phoneNumber,
            DEFAULT_PHONE_COUNTRY_CODE
          ),
        });
        /* eslint-enable camelcase */
        const update = {
          ...data,
          phoneExt: data.phoneNumber_ext,
        };
        await updateUserAttributes(update as TUpdateUserRequestV1);

        addAlert({
          message: t('common:updateSuccessful'),
          severity: 'success',
        });

        dispatch(reloadUserData());
      } catch (err) {
        errorHandler(err);
      } finally {
        setIsLoading(false);
      }
    },
    [errorHandler, addAlert, t, dispatch, setIsLoading]
  );
  const handlePasswordEditState = useCallback(
    (newPasswordEditState: boolean) => {
      if (!newPasswordEditState) {
        passwordResetForm.setValue('password', '**********************');
      } else {
        passwordResetForm.setValue('password', '');
      }
      setPasswordEdit(newPasswordEditState);
    },
    [setPasswordEdit, passwordResetForm]
  );

  useEffect(() => {
    const schemaViolations = passwordSchema.validate(upToDatePassword, { list: true });

    displayPasswordSchema.forEach((schemaParam) => {
      const containsParamViolation = Array.isArray(schemaViolations)
        ? schemaViolations.includes(schemaParam)
        : false;

      const isValidationParamChecked = upToDatePassword
        ? paramValidation(schemaParam, upToDatePassword)
        : false;

      const isValid = !containsParamViolation && isValidationParamChecked;
      if (isPasswordComponentValid[schemaParam] !== isValid) {
        setIsPasswordComponentValid((prev) => {
          return { ...prev, [schemaParam]: isValid };
        });
      }
    });
  }, [isPasswordComponentValid, upToDatePassword, upToDatePasswordConfirm]);

  useEffect(() => {
    setNotificationPreferencesVisible(notificationStatus);
  }, [notificationStatus, setNotificationPreferencesVisible]);

  if (!userData) return <Loading isLoading={userData === null} />;

  const newPassword = (
    <form onSubmit={passwordResetForm.handleSubmit(handleResetPassword)}>
      <input
        name="email"
        type="hidden"
        value={email}
        {...passwordResetForm.register('email', { required: false })}
      />
      <Box className={classes.passwordResetForm} mb={3}>
        <Collapse in={isPasswordEdit}>
          <Box mb={1}>
            <Typography color="primaryLight" fontStyle="semibold" variant="bodyMedium">
              {t('common:newPassword')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="bodyMedium">{t('common:passwordLengthPrompt')}</Typography>
          </Box>
        </Collapse>
        <Grid alignItems={'center'} container direction="row">
          <Grid container direction="column" item xs={isPasswordEdit ? 12 : 9}>
            <Grid item>
              <FormField
                InputLabelProps={{
                  shrink: true,
                }}
                control={passwordResetForm.control}
                defaultValue={!isPasswordEdit ? '**********************' : ''}
                id={'password'}
                isDisabled={!isPasswordEdit}
                isRequired
                isVisiblePasswordToggleHidden={!isPasswordEdit}
                label={!isPasswordEdit ? t('common:password') : t('common:newPassword')}
                shouldErrorIconBeSuppressed
                shouldOverrideLabelStyles
                shouldUseModernVariant
                t={t}
                type={!isPasswordEdit ? 'passwordConfirm' : 'password'}
              />
            </Grid>
            <Grid item>
              <Collapse in={isPasswordEdit}>
                <Box mt={3}>
                  <FormField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    control={passwordResetForm.control}
                    defaultValue={''}
                    id={'passwordConfirm'}
                    isRequired
                    label={t('common:passwordConfirm')}
                    shouldErrorIconBeSuppressed
                    shouldOverrideLabelStyles
                    shouldUseModernVariant
                    t={t}
                    type="passwordConfirm"
                    validationRules={{
                      validate: (value: any) => {
                        if (upToDatePassword && upToDatePassword !== value) {
                          return 'Passwords must match!';
                        }
                        return true;
                      },
                    }}
                  />
                </Box>
              </Collapse>
            </Grid>
            <Grid item xs>
              <Collapse in={isPasswordEdit}>
                <Grid alignItems="center" container justifyContent="flex-end">
                  <Grid item>
                    <Box mt={3}>
                      <Button onClick={() => handlePasswordEditState(!isPasswordEdit)}>
                        {t('common:cancel')}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={3}>
                      <SaveButton
                        isDisabled={!passwordResetForm.formState.isDirty}
                        isSaving={verificationDialogType === 'password' && isCodeSending}
                        t={t}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>

          {!isPasswordEdit && (
            <Grid item xs>
              <Button color="primary" onClick={() => handlePasswordEditState(!isPasswordEdit)}>
                <Typography color="primaryLight" variant="bodyMedium">
                  {!isPasswordEdit ? t('common:modify') : t('common:cancel')}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </form>
  );

  const generalInformation = (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item xs>
        <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
          {t('common:generalInformation')}
        </Typography>
        <Box pb={3} pt={1}>
          <Typography>{t('common:accountAddressPrompt')}</Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Box mb={3}>
          <Grid container direction="row">
            <Grid item xs>
              {/* FIRST NAME */}
              <Box mr={1.5}>
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  control={control}
                  defaultValue={firstName || ''}
                  id={'firstName'}
                  label={t('common:firstName')}
                  shouldOverrideLabelStyles
                  shouldUseModernVariant
                  t={t}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box ml={1.5}>
                {/* LAST NAME */}
                <FormField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  control={control}
                  defaultValue={lastName || ''}
                  id={'lastName'}
                  label={t('common:lastName')}
                  shouldOverrideLabelStyles
                  shouldUseModernVariant
                  t={t}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs>
        <Box mb={3}>
          {/* JOB TITLE */}
          <FormField
            InputLabelProps={{
              shrink: true,
            }}
            control={control}
            defaultValue={jobTitle || ''}
            id={'jobTitle'}
            label={t('common:jobTitle')}
            shouldOverrideLabelStyles
            shouldUseModernVariant
            t={t}
          />
        </Box>
      </Grid>
      <Grid item xs>
        {/* MOBILE NUMBER */}
        <Box mb={3}>
          <FormField
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!isPhoneNumberVerified ? (
                    <Button
                      className={classes.verifyButton}
                      onClick={async () => {
                        showVerificationDialog('phone');
                      }}
                    >
                      <Typography className={classes.verifyFont} variant="bodyMedium">
                        {t('common:verify')}
                      </Typography>
                    </Button>
                  ) : (
                    <TruxwebShieldCheckIcon className={classes.verifiedIcon} />
                  )}
                </InputAdornment>
              ),
            }}
            control={control}
            defaultValue={phoneNumber || ''}
            id={'phoneNumber'}
            label={t('common:phoneNumber')}
            phoneNumberExtension={phoneExt}
            shouldOverrideLabelStyles
            shouldUseModernVariant
            t={t}
            type={'phone'}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box mb={3}>
          {/* EMAIL */}
          <FormField
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!isEmailVerified ? (
                    <Button
                      className={classes.verifyButton}
                      onClick={async () => {
                        showVerificationDialog('email');
                      }}
                    >
                      <Typography className={classes.verifyFont} variant="bodyMedium">
                        {t('common:verify')}
                      </Typography>
                    </Button>
                  ) : (
                    <Box className={classes.verifiedIcon}>
                      <TruxwebShieldCheckIcon className={classes.verifiedIcon} />
                    </Box>
                  )}
                </InputAdornment>
              ),
            }}
            control={control}
            defaultValue={email || ''}
            id={'email'}
            label={t('common:email')}
            shouldOverrideLabelStyles
            shouldUseModernVariant
            t={t}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const notificationPreferences = (
    <Grid container direction="column">
      <Grid item xs>
        <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
          {t('common:notificationPreferences')}
        </Typography>
      </Grid>
      <Grid item xs>
        <FormField
          areOnlyChildrenShown
          control={control}
          defaultValue={
            userData?.areNotificationsEnabled ? userData?.areNotificationsEnabled : false
          }
          id={`areNotificationsEnabled`}
          label={t(`common:notificationStatus`)}
          shouldErrorIconBeSuppressed
          shouldOverrideLabelStyles
          shouldUseModernVariant
          t={t}
        >
          {(onChange, value) => {
            return (
              <FormControlLabel
                control={<Switch checked={value} color="primary" onClick={onChange} />}
                label={t('common:notificationStatus')}
                labelPlacement="start"
              />
            );
          }}
        </FormField>
      </Grid>
      <Grid item xs>
        <Box ml={2} mt={2}>
          <Typography>{t('common:notificationsDisclaimer')}</Typography>
        </Box>
      </Grid>
      {areNotificationPreferencesVisible && (
        <>
          <Grid item xs>
            <Box mt={3}>
              <Typography color="primaryLight" fontStyle="semibold" variant="bodyMedium">
                {t('common:notificationCategories')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box ml={4} mt={1}>
              <FormField
                areOnlyChildrenShown
                control={control}
                defaultValue={
                  userData?.notificationCategoryPreferences
                    ? userData?.notificationCategoryPreferences
                    : {}
                }
                id={`notificationCategoryPreferences`}
                label={t(`common:notificationStatus`)}
                shouldErrorIconBeSuppressed
                shouldOverrideLabelStyles
                shouldUseModernVariant
                t={t}
              >
                {(onChange, value) => {
                  return (
                    <EnumCheckboxGroup
                      hiddenOptions={[
                        ENotificationTypeV1.GENERIC,
                        ENotificationTypeV1.ACCOUNT,
                        ENotificationTypeV1.COMPANY,
                        ENotificationTypeV1.FINANCE,
                      ]}
                      i18nPrefix="NOTIFICATION_CATEGORY-"
                      onChange={onChange}
                      sourceEnum={ENotificationTypeV1}
                      t={t}
                      value={value}
                    />
                  );
                }}
              </FormField>
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs>
        <Box mt={3}>
          <Grid alignItems="center" container direction="row">
            <Grid item>
              <Box mr={2}>
                <Typography color="primaryLight" fontStyle="semibold" variant="bodyMedium">
                  {t('common:notificationLanguage')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <FormField
                areOnlyChildrenShown
                control={control}
                defaultValue={userData?.language ? userData?.language : ELanguageV1.EN_CA}
                id={`language`}
                label={t(`common:communicationLanguage`)}
                shouldErrorIconBeSuppressed
                shouldOverrideLabelStyles
                shouldUseModernVariant
                t={t}
              >
                {(onChange, value) => {
                  return (
                    <EnumSelect
                      localizationPrefix="common:"
                      onChange={onChange}
                      sourceEnum={ELanguageV1}
                      t={t}
                      value={value}
                    />
                  );
                }}
              </FormField>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box style={{ maxWidth: '916px', position: 'relative' }}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmitUserInformationUpdateSuccess)}>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box ml={2}>{generalInformation}</Box>
              </Grid>
              <Grid item xs={6}>
                <Box ml={3}>{notificationPreferences}</Box>
              </Grid>
            </Grid>
            <Box
              style={{
                bottom: 0,
                position: 'absolute',
                right: 16,
              }}
            >
              <SaveButton
                color="primaryLight"
                isDisabled={!isDirty || isPasswordEdit}
                isSaving={isLoading}
                t={t}
              >
                {t('common:submit')}
              </SaveButton>
            </Box>
          </form>
        </Grid>
        <Grid item xs={6}>
          {newPassword}
        </Grid>
      </Grid>
      <Dialog
        PaperProps={{
          style: { borderRadius: 20 },
        }}
        maxWidth="md"
        open={isVerificationDialogShown}
      >
        <IconButton
          onClick={hideVerificationDialog}
          style={{ position: 'absolute', right: 2, top: 2 }}
        >
          <CloseIcon />
        </IconButton>

        <Box mb={6} ml={6.5} mr={6.5} mt={6}>
          <Grid
            className={classes.dialogContainer}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography color="primaryLight" fontStyle="semibold" variant="h3">
                {t('common:verificationCode')}
              </Typography>
            </Grid>
            <Grid item>
              <Box mt={6}>
                <Typography color="primaryLight" fontStyle="semibold" variant="bodyLarge">
                  {t('common:confirmItsYou')}
                </Typography>
              </Box>
            </Grid>
            <RegisterConfirmationCode
              confirmationSource={verificationDialogType === 'phone' ? phoneNumber : email}
              isLoading={isVerifying}
              onResendConfirmationCode={handleResendConfirmationCode}
              onSubmitSuccess={handleSubmitConfirmationCode}
            />
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};
