"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    quickPay: {
        background: '#EDF8FF',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(1),
        color: theme.palette.primary.light,
        cursor: 'pointer',
        padding: `${theme.spacing(0.25)} ${theme.spacing(1)} ${theme.spacing(0.25)} ${theme.spacing(1)} `,
    },
}));
