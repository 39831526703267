"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebMugTeaIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.552,12.139C21.149,9.563,21.6,6.247,18,6H9.009C8.992,5.991,3.023,6.008,3,6a3.035,3.035,0,0,0-2.245.981,2.931,2.931,0,0,0-.747,2.28A31.916,31.916,0,0,0,3.144,20.066,6.976,6.976,0,0,0,9.437,24h2.127A6.955,6.955,0,0,0,17.3,21H19C24.091,21.162,26.377,13.16,20.552,12.139Zm-12.258.982L9,12.414c.316.363,1.055.86,1,1.415V15.5a.5.5,0,0,1-.5.5c-.431-.046-1.508.219-1.5-.5C8.061,14.967,7.8,13.509,8.294,13.121ZM11.564,22H9.437a4.992,4.992,0,0,1-4.5-2.817A29.9,29.9,0,0,1,2,9.067.972.972,0,0,1,3,8H8v2.585c-.872.857-2.1,1.887-2,3.243V15.5A2.5,2.5,0,0,0,8.5,18h1A2.5,2.5,0,0,0,12,15.5V13.829c.1-1.353-1.127-2.39-2-3.243V8h8a1.012,1.012,0,0,1,.763.326.947.947,0,0,1,.238.74,29.868,29.868,0,0,1-2.936,10.114A4.989,4.989,0,0,1,11.564,22ZM19,19h-.643A32.94,32.94,0,0,0,20.1,14.09C21.991,14.415,22,15.547,22,16A3,3,0,0,1,19,19ZM9.026,3V1a1,1,0,0,1,2,0V3A1,1,0,0,1,9.026,3Zm4,0V1a1,1,0,0,1,2,0V3A1,1,0,0,1,13.026,3Zm-8,0V1a1,1,0,0,1,2,0V3A1,1,0,0,1,5.026,3Z" })));
exports.default = TruxwebMugTeaIcon;
