"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const __1 = require("..");
exports.useStyles = (0, __1.makeStyles)((theme) => ({
    checked: {
        color: `${theme.palette.success.main} !important`,
    },
    root: {
        '&.checked': {
            color: theme.palette.success.main,
        },
    },
}));
