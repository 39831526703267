/* eslint-disable complexity */
import {
  Box,
  CircularProgress,
  EventIcon,
  Grid,
  LocationOnOutlinedIcon,
  MailOutlineIcon,
  PhoneOutlinedIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@truxweb/ux';
import {
  Card,
  LandingPageButton,
  LandingPageInfoCard,
  SocialMediaIconCircle,
} from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { EContactOptionsV1, EHubspotCaptureSourceV1, ELanguageV1 } from '@truxweb/schemas';
import { EnumSelect, FormField } from '@truxweb/common-components';
import { ETicketType, type TTruxwebPageContentProps } from '../../types';
import React, { useCallback, useEffect, useState } from 'react';
import { submitContactForm, submitTicket } from '../../actions';
import {
  useAuth,
  useAuthBoolean,
  useErrorHandling,
  useFooterPalette,
  useHeaderPalette,
  useUserData,
} from '../../hooks';
import { CONTACT_EMAIL_ADDRESS } from '../../config';
import FacebookLogo from '../../public/assets/images/logos/facebook-logo.svg';
import LinkedInLogo from '../../public/assets/images/logos/linkedIn-logo.svg';
import { ResponsiveBasePage } from '../../pageTemplates';
import RoadTruck from '../../public/assets/images/contact/road-truck.svg';
import TruxiStars from '../../public/assets/images/truxii/truxii-stars-large.svg';
import TwitterLogo from '../../public/assets/images/logos/twitter-logo.svg';
import { useRouter } from 'next/router';
import { useStyles } from './ContactPage.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'contactUs'];

export const ContactPage = ({ pageId }: TTruxwebPageContentProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const errorHandler = useErrorHandling();
  const router = useRouter();
  const { locale, query } = router;
  const classes = useStyles();
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSignupSuccessful, setSignupSucces] = useState(false);
  const {
    control,

    handleSubmit,
  } = useForm();
  const theme = useTheme();
  const isAuthed = useAuthBoolean();
  const authData = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  useFooterPalette('light');
  useHeaderPalette('light');
  const userData = useUserData();

  const onSubmitSuccess = useCallback(
    async (data: any) => {
      try {
        setIsSending(true);
        data.source = EHubspotCaptureSourceV1.landing_page_main_capture;
        data.language = locale === 'en-CA' ? ELanguageV1.EN_CA : ELanguageV1.FR_CA;
        const { message, ...others } = data;
        const submission = {
          contact: others,
          submission: {
            content: message,
            subject: `${t('common:contactFormSubmission')} ${data.email}`,
          },
        };
        await submitContactForm(submission);
        setSignupSucces(true);
      } catch (err) {
        errorHandler(err, t('contactUs:submitFail'));
        setSignupSucces(false);
      } finally {
        setIsSending(false);
      }
    },
    [setSignupSucces, locale, t, errorHandler]
  );

  const onSubmitTicket = useCallback(
    async (data: any) => {
      try {
        setIsSending(true);
        const { contactReason, message } = data;
        const submission = {
          companyName: userData?.companyData?.name,
          formType: ETicketType.contact,
          submission: {
            content: message,
            subject: t(`select:${contactReason}`),
          },
          userData: userData?.userData,
        };
        await submitTicket(submission);
        setSignupSucces(true);
      } catch (err) {
        errorHandler(err, t('contactUs:submitFail'));
        setSignupSucces(false);
      } finally {
        setIsSending(false);
      }
    },
    [setSignupSucces, t, errorHandler, userData]
  );

  const formMessageEntry = (
    <Grid className={classes.formEntry} container direction={'column'} item>
      <Grid item>
        <Box>
          <Controller
            control={control}
            defaultValue={query?.subject || ''}
            name="contactReason"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <EnumSelect
                  hasError={Boolean(error)}
                  isRequired={true}
                  labelClassName={classes.label}
                  onChange={onChange}
                  optionsFilter={(option) => {
                    if (!isAuthed) {
                      return option !== 'FEATURE_REQUEST';
                    }
                    return !['OPENING_CARRIER', 'OPENING_SHIPPER'].includes(option);
                  }}
                  placeholder={t('common:contactReason')}
                  selectClassName={classes.input}
                  sourceEnum={EContactOptionsV1}
                  t={t}
                  value={value || ''}
                />
              );
            }}
            rules={{ required: true }}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box className={classes.messageWrap} mb={isTablet ? 0 : 3} mt={isTablet ? 2 : 3}>
          <FormField
            InputProps={{
              className: classes.message,
            }}
            control={control}
            defaultValue={''}
            id="message"
            inputProps={{
              className: classes.message,
              placeholder: t('common:writeYourMessage'),
            }}
            isRequired={true}
            label={t('common:message')}
            labelClassName={classes.label}
            t={t}
            type="textfield"
          />
        </Box>
      </Grid>
    </Grid>
  );

  const content = (
    <>
      {isSignupSuccessful && (
        /* Thank you! We'll be in touch! / Merci */
        <Grid
          alignItems="center"
          className={classes.successfulSignUp}
          container
          direction="column"
          justifyContent="center"
        >
          <TruxiStars
            height={isDesktop ? '184px' : '100px'}
            width={isDesktop ? '184px' : '100px'}
          />
          <Box mb={3} mt={3}>
            <Typography variant="h3">{t('common:requestSuccess')}</Typography>
          </Box>
          <Box mb={6}>
            <Typography variant="h6">{t('common:ourTeamWillGetBackToYou')}</Typography>
          </Box>
          <LandingPageButton
            isSubmit
            onClick={() => {
              setSignupSucces(false);
            }}
            text={t('common:back')}
            type="standard"
          />
        </Grid>
      )}
      {!isSignupSuccessful && (
        <Box>
          <form onSubmit={handleSubmit(isAuthed ? onSubmitTicket : onSubmitSuccess)}>
            <Grid className={classes.formWrapper} container>
              <Grid
                container
                direction={isMobile ? 'column' : 'row'}
                item
                style={{
                  display: isAuthed ? 'none' : 'block',
                }}
                xs
              >
                <Grid container direction="column" item xs={isMobile ? 12 : 11}>
                  <Grid item>
                    <FormField
                      InputProps={{
                        className: classes.input,
                      }}
                      control={control}
                      defaultValue={userData?.companyData?.name}
                      id={'companyName'}
                      isRequired={true}
                      label={t('common:companyName')}
                      labelClassName={classes.label}
                      t={t}
                    />
                  </Grid>
                  <Grid item>
                    <Box mt={isTablet ? 2 : 3}>
                      <FormField
                        InputProps={{
                          className: classes.input,
                        }}
                        control={control}
                        defaultValue={userData?.userData?.firstName}
                        id={'firstName'}
                        isRequired={true}
                        label={t('common:firstName')}
                        labelClassName={classes.label}
                        t={t}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={isTablet ? 2 : 3}>
                      <FormField
                        InputProps={{
                          className: classes.input,
                        }}
                        control={control}
                        defaultValue={userData?.userData?.lastName}
                        id={'lastName'}
                        isRequired={true}
                        label={t('common:lastName')}
                        labelClassName={classes.label}
                        t={t}
                      />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box mt={isTablet ? 2 : 3}>
                      <FormField
                        InputProps={{
                          className: classes.input,
                        }}
                        control={control}
                        defaultValue={userData?.userData?.email}
                        id={'email'}
                        isRequired={true}
                        label={t('common:email')}
                        labelClassName={classes.label}
                        t={t}
                        type="email"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mt={isTablet ? 2 : 3}>
                      <Box mb={1}>
                        <Typography className={classes.label}>
                          {t('common:contactNumber')}
                          <sup>*</sup>
                        </Typography>
                      </Box>
                      <FormField
                        InputProps={{
                          className: classes.input,
                        }}
                        control={control}
                        defaultValue={userData?.userData?.phone}
                        id="contactNumber"
                        inputProps={{ placeholder: '(___) ___ - ____' }}
                        isRequired={true}
                        label={''}
                        phoneNumberExtension={userData?.userData?.phoneExt}
                        shouldOverrideLabelStyles
                        t={t}
                        type="phone"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {isMobile && formMessageEntry}
              {!isMobile && (
                <Grid container direction="column" item xs>
                  {formMessageEntry}
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="center">
              <Box mt={6}>
                <LandingPageButton
                  isSaving={isSending}
                  isSubmit
                  text={t('common:sendMessage')}
                  type={isTablet ? 'standard' : 'mediumStandard'}
                />
              </Box>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );

  useEffect(() => {
    if (isLoading) {
      if ((isAuthed && userData) || (authData.hasLoaded && !authData.data)) {
        setIsLoading(false);
      }
    }
  }, [isAuthed, userData, setIsLoading, isLoading, authData]);

  if (isLoading) {
    return (
      <ResponsiveBasePage isHeaderTransparent={true} pageId={pageId}>
        {() => {
          return (
            <Box className={classes.container}>
              <Box className={classes.overlay} />
              <Grid alignItems="center" container justifyContent="center">
                <Box mt={20}>
                  <CircularProgress />
                </Box>
              </Grid>
            </Box>
          );
        }}
      </ResponsiveBasePage>
    );
  }

  return (
    <ResponsiveBasePage isHeaderTransparent={true} pageId={pageId}>
      {() => (
        <Box className={classes.container}>
          <Box className={classes.overlay} />
          {!isMobile && (
            <Box className={classes.image}>
              <RoadTruck height={'100%'} width={'100%'} />
            </Box>
          )}
          <Box className={classes.bodyContent}>
            <Grid
              alignItems="center"
              className={classes.topGridContainer}
              container
              direction="column"
            >
              <Typography className={classes.title} variant={isMobile ? 'h2' : 'h1'}>
                {t('common:contactUs')}
              </Typography>
              <Grid className={classes.ctaWrap} container item justifyContent="center">
                <Grid item>
                  <Grid container>
                    <LandingPageInfoCard
                      content={t('common:planACall')}
                      icon={<EventIcon color="primary" />}
                      link={'https://meetings.hubspot.com/max-gravel/demo-general-calendar'}
                    />
                    <LandingPageInfoCard
                      content={CONTACT_EMAIL_ADDRESS}
                      icon={<MailOutlineIcon color="primary" />}
                      link={`mailto:${CONTACT_EMAIL_ADDRESS}`}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <LandingPageInfoCard
                      content={t('common:tollFreeNumber')}
                      icon={<PhoneOutlinedIcon color="primary" />}
                      link={`tel:${t('common:tollFreeNumber')}`}
                    />
                    <LandingPageInfoCard
                      content={
                        <>
                          <Box>{t('common:companyAddressLineOne')}</Box>
                          <Box>{t('common:companyAddressLineThree')}</Box>
                        </>
                      }
                      icon={<LocationOnOutlinedIcon color="primary" />}
                      link={`https://goo.gl/maps/U9jYHP8mFJYNnVDH6`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid alignItems="center" container justifyContent="center">
                <Card className={classes.innerCard}>{content}</Card>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.follow}
                container
                justifyContent="center"
              >
                <Typography className={classes.followText} variant={isTablet ? 'body1' : 'h6'}>
                  {t('common:followUs')}
                </Typography>
                <Box ml={3} mr={3}>
                  <SocialMediaIconCircle
                    icon={<FacebookLogo height={'100%'} width={'100%'} />}
                    onClick={() => {
                      parent.open(`http://www.facebook.com/Truxweb`);
                    }}
                  />
                </Box>
                <SocialMediaIconCircle
                  icon={<LinkedInLogo height={'100%'} width={'100%'} />}
                  onClick={() => {
                    parent.open(`http://www.linkedin.com/company/truxweb`);
                  }}
                />
                <Box ml={3} mr={3}>
                  <SocialMediaIconCircle
                    icon={<TwitterLogo height={'100%'} width={'100%'} />}
                    onClick={() => {
                      parent.open(`http://www.twitter.com/Truxweb1`);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </ResponsiveBasePage>
  );
};
