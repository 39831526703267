import { browserSupport, IS_BROWSER_DETECTION_ENABLED } from '../../config';
import { Grid, useScrollTrigger } from '@truxweb/ux';
import { JoinTheRevolutionModal, ResponsiveFooter, ResponsiveHeader } from '../../components';
import React, { useCallback, useState } from 'react';
import Bowser from 'bowser';
import classnames from 'classnames';
import { TPageId } from '../../types';
import { useStyles } from './ResponsiveBasePage.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

export type TResponsiveInjectedProps = {
  handleOpenSignup: () => void;
  isScrolled: boolean;
};
type TBasePageProps = {
  children?: (props?: TResponsiveInjectedProps) => React.ReactNode;
  isHeaderTransparent?: boolean;
  isScrollTriggerDisabled?: boolean;
  onScroll?: (isScrolled: boolean) => void;
  pageId?: TPageId;
};

type AppScrollType = {
  isScrolled: boolean;
};

type AppBarScrollPropsType = {
  onScroll?: (isScrolled: boolean) => void;
  children: (props: AppScrollType) => React.ReactElement;
  window?: () => Window;
};

export const ResponsiveAppBarScroll = (props: AppBarScrollPropsType): JSX.Element => {
  const { children, onScroll, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
    threshold: 10,
  });

  if (onScroll) {
    onScroll(trigger);
  }

  return children({
    isScrolled: trigger,
  });
};

export const ResponsiveBasePage = ({
  children,
  isHeaderTransparent,
  isScrollTriggerDisabled,
  onScroll,
  pageId,
}: TBasePageProps): JSX.Element => {
  const classes = useStyles({
    isScrollTriggerDisabled,
  });
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEmail, setModalEmail] = useState(null);

  const handleOpenSignup = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  if (IS_BROWSER_DETECTION_ENABLED && typeof window !== 'undefined') {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.satisfies(browserSupport)) {
      return <h1 className={classes.unsupportedBrowser}>{t('common:unsupportedBrowser')}</h1>;
    }
  }

  const content = ({ isScrolled }) => {
    return (
      <>
        <Grid
          className={classnames(classes.landingPage, classes.pageWrapper)}
          container
          direction="column"
          justifyContent="space-between"
        >
          <Grid item>
            <ResponsiveHeader
              isScrolled={isScrolled}
              isTransparent={isHeaderTransparent || false}
              pageId={pageId}
            />
          </Grid>
          <Grid className={classnames(classes.landingPage, classes.container)} item>
            <main className={classes.content} role="main">
              {children({ handleOpenSignup, isScrolled })}
            </main>
          </Grid>
          <ResponsiveFooter setModalEmail={setModalEmail} />
        </Grid>
        <JoinTheRevolutionModal
          email={modalEmail ? modalEmail : ''}
          isOpen={isModalOpen}
          setModalEmail={setModalEmail}
          setOpen={(arg: boolean) => {
            setIsModalOpen(arg);
          }}
        />
      </>
    );
  };

  if (isScrollTriggerDisabled) {
    return content({ isScrolled: true });
  }

  return <ResponsiveAppBarScroll onScroll={onScroll}>{content}</ResponsiveAppBarScroll>;
};
