"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    buttonIcon: {
        '& .MuiButtonBase-root > button': { display: 'none !important' },
        fontSize: `${theme.spacing(4)}px !important`,
        margin: 0,
        padding: 0,
    },
    carrierProfile: {
        '& :hover': {
            color: `${theme.palette.common.white}`,
        },
        alignItems: 'center',
        backgroundColor: theme.palette.primary.light,
        borderRadius: 0,
        color: theme.palette.common.white,
        display: 'flex',
        fontFamily: 'Poppins',
        fontSize: '15px',
        fontWeight: 600,
        height: `${theme.spacing(7)}px`,
        justtifyContent: 'center',
        padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
    },
    carrierProfileIcon: {
        color: `${theme.palette.common.white}`,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    carrierProfileText: { color: `${theme.palette.common.white}` },
    container: {
        //selected row style
        '& .Mui-selected': {
            backgroundColor: '#EDF8FF !important',
        },
        //hide checkbox from header
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-root': {
            display: 'none',
        },
        //table header cell text
        '& .MuiDataGrid-root': {
            border: 'none',
            color: theme.palette.grey[700],
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '15px',
        },
        //row spacing
        '& .MuiDataGrid-root .MuiDataGrid-cell': {
            borderBottom: ({ gapInterval }) => `${theme.spacing(gapInterval)}px solid ${theme.palette.common.white}`,
            textOverflow: 'ellipsis',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
        //row background color
        '& .MuiDataGrid-root .MuiDataGrid-row': {
            backgroundColor: `${theme.palette.grey[200]} `,
            display: 'flex',
        },
        //table body cell text
        '& .MuiTypography-body1': {
            color: theme.palette.grey[700],
            fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
            fontSize: `15px`,
            fontWeight: 600,
            lineHeight: `${theme.spacing(2)}px`,
        },
        padding: `${theme.spacing(6.5)}px 0`,
    },
    realignTableHeader: {
        marginLeft: `-${theme.spacing(2)}px !important`,
        marginRight: `${theme.spacing(2)}px !important`,
    },
    toolbar: {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        display: 'flex',
        flexDirection: 'row',
        height: '48px',
    },
    toolbarItem: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
    },
    toolbarLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '12px',
            fontWeight: 400,
            marginLeft: `${theme.spacing(0.5)}px`,
        },
        color: theme.palette.grey[700],
        fontSize: '12px',
        justifyContent: 'flex-end',
        margin: '0 !important',
        textAlign: 'end',
    },
    trackingAvailable: {
        color: theme.palette.primary.light,
    },
}));
