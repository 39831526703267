import { manageDomainError, manageDomainLoaded, manageDomainLoading } from './stateUtils';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { type TReducerLoading } from '../types';

export interface IReportingDataState {
  reportsByCompanyExtId: TReducerLoading & { data: Record<string, any> };
}

export const reportingSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.reporting,
      };
    },
  },
  initialState: {
    reportsByCompanyExtId: {},
  } as IReportingDataState,
  name: 'reporting',
  reducers: {
    setReportingListData: (state, { payload: { companyExtId, report } }) => {
      return {
        ...state,
        reportsByCompanyExtId: {
          ...state.reportsByCompanyExtId,
          [companyExtId]: manageDomainLoaded(state.reportsByCompanyExtId[companyExtId], report),
        },
      };
    },
    setReportingListError: (state, { payload: { companyExtId, error } }) => {
      return {
        ...state,
        reportsByCompanyExtId: {
          ...state.reportsByCompanyExtId,
          [companyExtId]: manageDomainError(state.reportsByCompanyExtId[companyExtId], error),
        },
      };
    },
    setReportingListLoading: (state, { payload: { companyExtId } }) => {
      return {
        ...state,
        reportsByCompanyExtId: {
          ...state.reportsByCompanyExtId,
          [companyExtId]: manageDomainLoading(state.reportsByCompanyExtId[companyExtId]),
        },
      };
    },
  },
});

export const {
  actions: { setReportingListData, setReportingListError, setReportingListLoading },
} = reportingSlice;
