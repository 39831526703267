import { CarrierProfile } from '../../components';
import { ELanguageV1 } from '@truxweb/schemas';
import { ShipperPage } from '../../pageTemplates';
import { TTruxwebPageContentProps } from '../../types';
import { usePublicCarrierProfileData } from '../../hooks';
import { useRouter } from 'next/router';

type TCarrierProfilePageProps = TTruxwebPageContentProps & { companyCode: string };
export const CarrierProfilePage = ({
  companyCode,
  pageId,
}: TCarrierProfilePageProps): JSX.Element => {
  const { locale } = useRouter();
  const [carrierData, hasResponseLoaded] = usePublicCarrierProfileData(
    companyCode,
    locale.toUpperCase() as ELanguageV1
  );
  return (
    <ShipperPage pageId={pageId}>
      <CarrierProfile
        carrierData={carrierData}
        companyCode={companyCode}
        hasResponseLoaded={hasResponseLoaded}
      />
    </ShipperPage>
  );
};

export default CarrierProfilePage;
