import { AppBar, useMediaQuery, useTheme } from '@truxweb/ux';
import { DesktopHeader, HeaderMeta, MobileHeader } from '..';
import Head from 'next/head';
import { pageIdToLocaleString } from '../../utils';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './Header.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type THeaderProps = {
  isScrolled: boolean;
  isTransparent?: boolean;
  pageId?: TPageId;
  shouldHideAppBar?: boolean;
};

export const Header = ({
  isScrolled,
  isTransparent,
  pageId,
  shouldHideAppBar,
}: THeaderProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isScrolled });

  const [localeString, formattedPageId] = pageIdToLocaleString(pageId);

  let pageTitlePrefix = t(localeString);

  if (pageTitlePrefix === formattedPageId) {
    pageTitlePrefix = t('common:siteMap-default');
  }

  const pageTitle = pageId
    ? t('common:appTitle', { pageTitle: `${pageTitlePrefix} - ` })
    : t('common:appTitle', { pageTitle: '' });

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <HeaderMeta pageId={pageId} t={t} />
      </Head>
      {!shouldHideAppBar && (
        <AppBar classes={classes} position={isTransparent ? 'fixed' : 'relative'}>
          {isMobile && (
            <MobileHeader isScrolled={isScrolled} isTransparent={isTransparent} pageId={pageId} />
          )}
          {!isMobile && (
            <DesktopHeader isScrolled={isScrolled} isTransparent={isTransparent} pageId={pageId} />
          )}
        </AppBar>
      )}
    </>
  );
};
