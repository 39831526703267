"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateSheetRegionLocationSelection = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const RateSheetRegionLocationSelection_styles_1 = require("./RateSheetRegionLocationSelection.styles");
const RateSheetRegionLocationSelection = ({ baseName, formControls, name, rateSheetLocations, rateSheetRegion, t, }) => {
    const classes = (0, RateSheetRegionLocationSelection_styles_1.useStyles)();
    const baseAvailableLocation = (0, react_1.useMemo)(() => {
        const assignedIds = ((rateSheetRegion === null || rateSheetRegion === void 0 ? void 0 : rateSheetRegion.locations) || []).map(({ id }) => id);
        return rateSheetLocations.filter(({ id }) => !assignedIds.includes(id));
    }, [rateSheetRegion, rateSheetLocations]);
    const [assignedLocations, setAssignedLocation] = (0, react_1.useState)((rateSheetRegion === null || rateSheetRegion === void 0 ? void 0 : rateSheetRegion.locations) || []);
    const [availableLocations, setAvailableLocation] = (0, react_1.useState)(baseAvailableLocation);
    return (react_1.default.createElement(react_hook_form_1.Controller, { control: formControls.control, defaultValue: assignedLocations, name: name || 'locations', render: ({ field: { onChange, value } }) => {
            return (react_1.default.createElement(ux_1.PickList, { filter: true, filterBy: 'name', itemTemplate: (record) => {
                    return (react_1.default.createElement(ux_1.Typography, { style: { color: 'inherit', fontWeight: 'inherit' }, variant: "bodyMedium" }, [record === null || record === void 0 ? void 0 : record.city, record === null || record === void 0 ? void 0 : record.province, record === null || record === void 0 ? void 0 : record.country].join(', ')));
                }, moveAllToSourceIcon: react_1.default.createElement(react_1.default.Fragment, null), moveAllToTargetIcon: react_1.default.createElement(react_1.default.Fragment, null), moveDownIcon: react_1.default.createElement(react_1.default.Fragment, null), moveToSourceIcon: react_1.default.createElement(ux_1.TruxwebChevronDoubleUpIcon, null), moveToTargetIcon: react_1.default.createElement(ux_1.TruxwebChevronDoubleUpIcon, null), moveUpIcon: react_1.default.createElement(react_1.default.Fragment, null), onChange: (event) => {
                    onChange(event.target);
                    if (baseName) {
                        formControls.setValue(`${baseName}.boundaryType`, schemas_1.ERateSheetBoundaryTypeV1.REGION);
                    }
                    setAvailableLocation(event.source);
                    setAssignedLocation(event.target);
                }, pt: {
                    buttons: {
                        className: classes.buttons,
                    },
                    item: {
                        className: classes.item,
                    },
                }, showSourceControls: false, showSourceFilter: true, showTargetControls: false, showTargetFilter: true, source: availableLocations, sourceFilterPlaceholder: t('common:filterBy', { option: t('common:name') }), sourceHeader: react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:available'))), sourceStyle: { height: '12rem', overflow: 'auto' }, target: value, targetFilterPlaceholder: t('common:filterBy', { option: t('common:name') }), targetHeader: react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:assigned'))), targetStyle: { height: '12rem', overflow: 'auto' } }));
        }, rules: { required: true } }));
};
exports.RateSheetRegionLocationSelection = RateSheetRegionLocationSelection;
