"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarDocuments = void 0;
/* eslint-disable complexity */
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const ShipmentsDashboardSidebarDocuments_styles_1 = require("./ShipmentsDashboardSidebarDocuments.styles");
const TRUXWEB_DOCUMENT_TYPES = new Set([
    schemas_1.EShipmentDocumentTypeV1.PURCHASE_ORDER,
    schemas_1.EShipmentDocumentTypeV1.REVISED_PURCHASE_ORDER,
    schemas_1.EShipmentDocumentTypeV1.RECEIPT,
    schemas_1.EShipmentDocumentTypeV1.INVOICE,
]);
const ShipmentsDashboardSidebarDocuments = ({ addAlert, canAdministerShipments, canCarrierAddAdditionalCharges, canManageCarrierData, canManageShipperData, canTriggerDocumentRegeneration, companyType, handleDocumentRegeneration, handleDownloadDocument, handleUploadFile, isLoading, isUploadingPerTypeState, language, promptForCarrierBankInformation, refreshShipmentDetails, shipment, shipmentDocumentTypes, t, }) => {
    var _a, _b, _c;
    const classes = (0, ShipmentsDashboardSidebarDocuments_styles_1.useStyles)();
    const theme = (0, ux_1.useTheme)();
    const areCarrierBankTransactionsReady = ((_a = shipment.areBankTransactionsReady) === null || _a === void 0 ? void 0 : _a.carrier) || false;
    // STEP 10 -- Separate document types according for the 3 sections of the UI
    const { carrierDocuments, shipperDocuments, truxwebDocuments } = (0, react_1.useMemo)(() => {
        const ret = {
            carrierDocuments: [],
            shipperDocuments: [],
            truxwebDocuments: [],
        };
        if ((shipmentDocumentTypes === null || shipmentDocumentTypes === void 0 ? void 0 : shipmentDocumentTypes.length) > 0) {
            //=> We are not working on an empty array...
            ret.carrierDocuments = shipmentDocumentTypes.filter((elt) => elt.documentOwnerType === schemas_1.EUserTypeV1.CARRIER &&
                !TRUXWEB_DOCUMENT_TYPES.has(elt.documentType));
            ret.shipperDocuments = shipmentDocumentTypes.filter((elt) => elt.documentOwnerType === schemas_1.EUserTypeV1.SHIPPER &&
                !TRUXWEB_DOCUMENT_TYPES.has(elt.documentType));
            ret.truxwebDocuments = shipmentDocumentTypes.filter((elt) => TRUXWEB_DOCUMENT_TYPES.has(elt.documentType));
        }
        return ret;
    }, [shipmentDocumentTypes]);
    // STEP 40 -- LOOKUPS per document type
    const { isEditablePerTypeLookup, requiredDocPerTypeLookup, uploadedDocPerTypeLookup } = (0, react_1.useMemo)(() => {
        const ret = {
            isEditablePerTypeLookup: {},
            requiredDocPerTypeLookup: {},
            uploadedDocPerTypeLookup: {},
        };
        // 1/3 -- isEditable lookup
        (shipmentDocumentTypes || []).forEach((domainDocType) => {
            // We look for a matching record in the requiredDocuments...
            const matchingRequiredDoc = shipment.requiredDocuments.find((elt) => elt.documentType === domainDocType.documentType);
            if (domainDocType.requiresEditableState === true) {
                ret.isEditablePerTypeLookup[domainDocType.documentType] =
                    !matchingRequiredDoc || !matchingRequiredDoc.isEditable ? false : true;
            }
            else {
                ret.isEditablePerTypeLookup[domainDocType.documentType] = matchingRequiredDoc
                    ? Boolean(matchingRequiredDoc.isEditable)
                    : true;
            }
        });
        // 2/3 -- uploaded doc lookup
        shipment.documents.forEach((uploadedDoc) => {
            //NOTE: This gives for granted that a single active uploaded document of a given type may exist
            if (uploadedDoc.shipmentDocumentType &&
                !ret.uploadedDocPerTypeLookup[uploadedDoc.shipmentDocumentType]) {
                ret.uploadedDocPerTypeLookup[uploadedDoc.shipmentDocumentType] = uploadedDoc;
            }
        });
        // 3/3 -- required doc lookup
        shipment.requiredDocuments.forEach((requiredDoc) => {
            //NOTE: This gives for granted that a single required uploaded document of a given type may exist
            if (requiredDoc.documentType && !ret.requiredDocPerTypeLookup[requiredDoc.documentType]) {
                ret.requiredDocPerTypeLookup[requiredDoc.documentType] = requiredDoc;
            }
        });
        return ret;
    }, [shipment.documents, shipment.requiredDocuments, shipmentDocumentTypes]);
    // STEP 50 -- Callbacks START
    const getDocumentOwnerIdByType = (0, react_1.useCallback)((documentOwnerType) => {
        var _a, _b;
        // SHIPPER or CARRIER only
        let ret = ``;
        switch (documentOwnerType) {
            case schemas_1.EUserTypeV1.CARRIER:
                ret = ((_a = shipment.shipment) === null || _a === void 0 ? void 0 : _a.carrierExtId) || '';
                break;
            case schemas_1.EUserTypeV1.SHIPPER:
                ret = ((_b = shipment.shipment) === null || _b === void 0 ? void 0 : _b.shipperExtId) || '';
                break;
            default:
                ret = '';
        }
        return ret;
    }, [shipment.shipment]);
    // Download doc -- START
    const onDownloadError = (0, react_1.useCallback)((errorMessage) => {
        addAlert({
            message: errorMessage,
            severity: 'error',
        });
    }, [addAlert]);
    // STEP 60 -- Docs JSX Sections (x3)
    const getUserDocumentSectionTitles = (0, react_1.useCallback)((documentOwnerType, viewingUserType) => {
        if ((documentOwnerType === schemas_1.EUserTypeV1.CARRIER && viewingUserType === schemas_1.EUserTypeV1.CARRIER) ||
            (documentOwnerType === schemas_1.EUserTypeV1.SHIPPER && viewingUserType === schemas_1.EUserTypeV1.SHIPPER)) {
            return t('common:yourDocuments');
            //
        }
        else if (documentOwnerType === schemas_1.EUserTypeV1.CARRIER &&
            [schemas_1.EUserTypeV1.SHIPPER].includes(viewingUserType)) {
            return t('common:carrierDocuments');
            //
        }
        else if (documentOwnerType === schemas_1.EUserTypeV1.SHIPPER &&
            [schemas_1.EUserTypeV1.CARRIER].includes(viewingUserType)) {
            return t('common:shipperDocuments');
        }
        return t('common:documents');
    }, [t]);
    // NOTE: A charge whose recipient is the carrier must exist since
    // shipment charges are inserted as we insert shipment records
    const carrierCurrency = (_c = (_b = shipment === null || shipment === void 0 ? void 0 : shipment.charges) === null || _b === void 0 ? void 0 : _b.find((c) => {
        return c.recipient === 'CARRIER';
    })) === null || _c === void 0 ? void 0 : _c.charge.currency;
    // Construct an array of (the 3) document sections...
    const documentSections = [];
    if ((canManageShipperData && !canManageCarrierData) || canAdministerShipments) {
        // The display order for shippers OR admins
        documentSections.push({
            canAddAdditionalCharges: false,
            documentsToUpload: shipperDocuments,
            isTruxwebDocumentSection: false,
            requiredDocPerTypeLookup,
            title: getUserDocumentSectionTitles(schemas_1.EUserTypeV1.SHIPPER, companyType),
        });
        documentSections.push({
            canAddAdditionalCharges: canAdministerShipments,
            documentsToUpload: carrierDocuments,
            isTruxwebDocumentSection: false,
            requiredDocPerTypeLookup,
            title: getUserDocumentSectionTitles(schemas_1.EUserTypeV1.CARRIER, companyType),
        });
        documentSections.push({
            canAddAdditionalCharges: false,
            documentsToUpload: truxwebDocuments,
            isTruxwebDocumentSection: true,
            requiredDocPerTypeLookup,
            title: t(`common:truxwebDocuments`),
        });
    }
    else if (!canManageShipperData && canManageCarrierData) {
        // The display order for carriers
        documentSections.push({
            canAddAdditionalCharges: areCarrierBankTransactionsReady &&
                !canAdministerShipments &&
                canCarrierAddAdditionalCharges,
            documentsToUpload: carrierDocuments,
            isTruxwebDocumentSection: false,
            requiredDocPerTypeLookup,
            title: getUserDocumentSectionTitles(schemas_1.EUserTypeV1.CARRIER, companyType),
        });
        documentSections.push({
            canAddAdditionalCharges: false,
            documentsToUpload: shipperDocuments,
            isTruxwebDocumentSection: false,
            requiredDocPerTypeLookup,
            title: getUserDocumentSectionTitles(schemas_1.EUserTypeV1.SHIPPER, companyType),
        });
        documentSections.push({
            canAddAdditionalCharges: false,
            documentsToUpload: truxwebDocuments,
            isTruxwebDocumentSection: true,
            requiredDocPerTypeLookup,
            title: t(`common:truxwebDocuments`),
        });
    }
    return (react_1.default.createElement(ux_1.Box, { className: classes.container, p: 2, pb: 0 },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" }, documentSections.map((documentSection, index) => (react_1.default.createElement(ux_1.Grid, { item: true, key: index },
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.ShipmentsDashboardSidebarDocumentList, Object.assign({}, documentSection, { addAlert: addAlert, areCarrierBankTransactionsReady: areCarrierBankTransactionsReady, canManageCarrierData: canManageCarrierData, canManageShipperData: canManageShipperData, canTriggerDocumentRegeneration: canTriggerDocumentRegeneration, carrierCurrency: carrierCurrency || schemas_1.ECurrencyV1.CAD, documentViewIcon: react_1.default.createElement(ux_1.VisibilityOutlinedIcon, { height: 18, htmlColor: theme.palette.primary.light, width: 16 }), getDocumentOwnerIdByType: getDocumentOwnerIdByType, handleDocumentRegeneration: handleDocumentRegeneration, handleDownloadDocument: handleDownloadDocument, handleUploadDocument: handleUploadFile, hasPermissionToViewAllDocuments: canAdministerShipments, isEditablePerTypeLookup: isEditablePerTypeLookup, isLoading: isLoading, isUploadingPerTypeState: isUploadingPerTypeState, language: language, onDownloadError: onDownloadError, onDownloadSuccess: refreshShipmentDetails, promptForCarrierBankInformation: promptForCarrierBankInformation, refreshShipmentDetails: refreshShipmentDetails, shipment: shipment, shipmentRef: shipment.shipment.shipmentRef || '', t: t, uploadedDocPerTypeLookup: uploadedDocPerTypeLookup, viewingUserType: companyType })))))))));
};
exports.ShipmentsDashboardSidebarDocuments = ShipmentsDashboardSidebarDocuments;
