"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebGifIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.969,4.969,0,0,0,3.536-1.465l2.656-2.656A4.969,4.969,0,0,0,24,16.343V5A5.006,5.006,0,0,0,19,0ZM2,19V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3V15H18a3,3,0,0,0-3,3v4H5A3,3,0,0,1,2,19Zm16.465,2.121a2.98,2.98,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.922a2.98,2.98,0,0,1-.8,1.465ZM8,8.75v.334a2,2,0,0,1-2,1.984H6A1.991,1.991,0,0,1,4,9.084v-2.1A1.991,1.991,0,0,1,6,5H6A2.022,2.022,0,0,1,7.772,6.079a.626.626,0,1,1-1.11.58A.779.779,0,0,0,6,6.25.742.742,0,0,0,5.251,7V9.068A.742.742,0,0,0,6,9.819a.759.759,0,0,0,.75-.735V9H6.5a.5.5,0,0,1,0-1h.75A.75.75,0,0,1,8,8.75Zm6-.112v1.8a.625.625,0,0,1-1.25,0V5.625A.625.625,0,0,1,13.375,5h2.013a.625.625,0,0,1,0,1.25H14V7.388h1.239a.625.625,0,0,1,0,1.25ZM11,5.625v4.819a.625.625,0,1,1-1.25,0V5.625a.625.625,0,0,1,1.25,0Z" })));
exports.default = TruxwebGifIcon;
