"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    accessorialContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: `${theme.spacing(3)}px 0px`,
    },
    accessorialItem: {
        display: 'flex',
        marginBottom: `${theme.spacing(2)}px`,
        marginRight: `${theme.spacing(3)}px`,
    },
    accessorialPicker: {
        padding: '0px',
    },
    accessorialRow: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    button: {
        color: theme.palette.primary.light,
        marginLeft: `${theme.spacing(0.5)}px`,
        padding: '0px !important',
    },
    buttonText: {
        color: theme.palette.primary.light,
        marginTop: '2px',
    },
    icon: {
        color: '#AAA9A2',
    },
    root: {
        height: theme.spacing(8),
        marginBottom: theme.spacing(3),
        //padding: theme.spacing(3),
    },
    tooltipContainer: {
        height: '100%',
    },
}));
