import { type AppState, baseLoadingState } from '../../stores';
import { EUserTypeV1, type TCompanyV1, type TUserV1 } from '@truxweb/schemas';

export type TUserData = {
  companyData: TCompanyV1 | null;
  userData: TUserV1 | null;
  userType: EUserTypeV1 | null;
  isEmailVerified: boolean;
  isPhoneNumberVerified: boolean;
};

export const selectAuthedUserData = (): ((state: AppState) => TUserData) => {
  return (state: AppState) => {
    const userData = state?.auth?.userData || { ...baseLoadingState, hasLoggedOut: false };
    const authProviderData = state?.auth?.authProviderData || null;

    if (!userData.hasLoaded || userData.hasLoggedOut)
      return {
        companyData: null,
        isEmailVerified: false,
        isPhoneNumberVerified: false,
        userData: null,
        userType: null,
      };

    return {
      companyData: userData.data?.company,
      isEmailVerified: authProviderData?.email_verified || false,
      isPhoneNumberVerified: authProviderData?.phone_number_verified || false,
      userData: userData.data?.user,
      userType: userData.data?.company.type,
    };
  };
};
