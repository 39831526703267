import { ARE_MOBILE_MENUS_DARK, makeStyles, Theme } from '@truxweb/ux';

type TMobileHeaderMenuProps = {
  isMobile: boolean;
};

export const useStyles = makeStyles<Theme, TMobileHeaderMenuProps>((theme: Theme) => ({
  authedMenuItem: { lineHeight: `${theme.spacing(2)}px` },
  buttonWrap: { '& div': { lineHeight: `${theme.spacing(6.5)}px` } },
  drawer: {
    midWidth: 360,
    padding: '0 4vw 0 4vw',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(9),
      lineHeight: `${theme.spacing(9)}px`,
    },
    [theme.breakpoints.between('xs', 'md')]: {
      height: theme.spacing(10),
      lineHeight: `${theme.spacing(10)}px`,
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(13.5),
      lineHeight: `${theme.spacing(13.5)}px`,
    },
    width: '100%',
  },
  languageSwitcherText: {
    '&:hover,&:active': { color: theme.palette.primary.main },
    color: theme.palette.common.white,
    fontSize: ({ isMobile }) => {
      return isMobile ? theme.typography.h6.fontSize : theme.typography.h4.fontSize;
    },
    fontWeight: ({ isMobile }) => {
      return isMobile ? theme.typography.h6.fontWeight : theme.typography.h4.fontWeight;
    },
  },
  link: {
    '&:hover': {
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        content: '""',
        height: 10,
        left: `calc(100% + 4px)`,
        position: 'absolute',
        top: `-4px`,
        width: 10,
        zIndex: 999,
      },
    },
    color: theme.palette.common.white,
    position: 'relative',
  },
  list: {
    backgroundColor: () => {
      return ARE_MOBILE_MENUS_DARK ? theme.palette.primary.dark : theme.palette.common.white;
    },
    width: '100vw',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100vh - ${theme.spacing(6)}px)`,
    },
    [theme.breakpoints.between('xs', 'md')]: {
      height: `calc(100vh - ${theme.spacing(6)}px)`,
    },
  },
  logoWrap: {
    maxHeight: theme.spacing(9),
    maxWidth: theme.spacing(38),
    minHeight: theme.spacing(6),
    minWidth: theme.spacing(26),
    width: '45vw',
  },
  popover: {
    backgroundColor: () => {
      return ARE_MOBILE_MENUS_DARK ? theme.palette.primary.dark : theme.palette.common.white;
    },
    height: '100%',
    left: '0 !important',
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
  },
  socialsWidth: { maxWidth: '200px' },
  socialsWrap: {
    padding: '0 4vw 0 6vw',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  twitterIcon: { color: theme.palette.common.white },
}));
