"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierBankAccount = void 0;
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const CarrierBankAccount = ({ bankAccounts, control, defaultCountryCode, handleBankAccountSubmit, handleCancel, handleSubmit, handleUpload, handleUploadFailure, handleUploadSuccess, isDirty, isFormDisabled, isSaving, setError, setValue, shouldSuppressHeader, t, user, watch, }) => {
    const [isUploading, setIsUploading] = (0, react_1.useState)(false);
    const [bankAccountDetails, setBankAccountDetails] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const defaultBankAccount = bankAccounts.find((account) => {
            return account.isDefault === true;
        });
        if (defaultBankAccount) {
            setBankAccountDetails(defaultBankAccount);
            setValue('accountHolderName', defaultBankAccount.accountName);
            setValue('addressLineOne', defaultBankAccount.address.addressLineOne);
            setValue('city', defaultBankAccount.address.city);
            setValue('province', defaultBankAccount.address.province);
            setValue('postalCode', defaultBankAccount.address.postalCode);
            setValue('bankName', defaultBankAccount.address.name);
            setValue('country', defaultBankAccount.address.country);
            setValue('accountType', defaultBankAccount.type);
            setValue('branchNumber', defaultBankAccount.branch || '');
            setValue('institutionNumber', defaultBankAccount.institution || '');
            setValue('accountNumber', defaultBankAccount.accountNumber || '');
            setValue('routingNumber', defaultBankAccount.routing || '');
        }
    }, [bankAccounts, setBankAccountDetails, setValue]);
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        handleBankAccountSubmit(data);
    }), [handleBankAccountSubmit]);
    const onUploadFile = (0, react_1.useCallback)((file, onChange) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsUploading(true);
            const postFileName = `${user.companyExtId}_bankingDetails_${new Date().toISOString()}.pdf`;
            // FIXME This is uploading to the incorrect bucket
            // TRUE the bucket is incorrect
            const s3Response = yield handleUpload(postFileName, file);
            onChange({
                localName: file.name,
                remoteName: s3Response.key,
            });
            handleUploadSuccess();
        }
        catch (err) {
            handleUploadFailure();
        }
        finally {
            setIsUploading(false);
        }
    }), [user, setIsUploading, handleUpload, handleUploadFailure, handleUploadSuccess]);
    return (react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) },
        !shouldSuppressHeader && react_1.default.createElement(__1.InteriorHeader, null, t(`common:bankAccountDetails`)),
        react_1.default.createElement(ux_1.Box, { mt: 2 },
            react_1.default.createElement(ux_1.FlatCard, null,
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "space-between" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                        react_1.default.createElement(__1.FormField, { control: control, defaultCountryCode: defaultCountryCode, defaultValue: '', id: "accountHolderName", inputProps: {
                                required: false,
                            }, isDisabled: isFormDisabled, isRequired: true, label: t('common:accountHolderName'), t: t })),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 5 },
                        react_1.default.createElement(__1.FormField, { control: control, defaultCountryCode: defaultCountryCode, defaultValue: '', id: "bankName", inputProps: {
                                required: false,
                            }, isDisabled: isFormDisabled, isRequired: true, label: t('common:bankName'), t: t }))),
                react_1.default.createElement(ux_1.Box, { mt: 3.25 },
                    react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t('common:branchAddress')),
                    react_1.default.createElement(__1.AddressForm, { areNamesDisabled: true, control: control, defaultCountryCode: defaultCountryCode, isAddressLineTwoDisabled: true, isFormDisabled: isFormDisabled, t: t, watch: watch })),
                react_1.default.createElement(ux_1.Box, { mb: 3.25, mt: 3.25 },
                    react_1.default.createElement(ux_1.Typography, { variant: "h6" }, t('common:accountInformation')),
                    react_1.default.createElement(__1.BankAccountDetails, { control: control, data: bankAccountDetails, defaultCountryCode: defaultCountryCode, handleUploadFailure: handleUploadFailure, isDirty: isDirty, isFormDisabled: isFormDisabled, isUploading: isUploading, onUploadFile: onUploadFile, setError: setError, t: t })),
                react_1.default.createElement(__1.SaveButton, { buttonText: t('common:submit'), isDisabled: isFormDisabled, isFullWidth: true, isSaving: isSaving, t: t }),
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" }, isDirty && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                    react_1.default.createElement(__1.CancelButton, { handleCancel: handleCancel, t: t }))))))));
};
exports.CarrierBankAccount = CarrierBankAccount;
