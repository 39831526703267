"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebKeyboardIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M19,6H13V3a1,1,0,0,0-2,0V6H5a5.006,5.006,0,0,0-5,5v4a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V11A5.006,5.006,0,0,0,19,6Zm3,9a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V11A3,3,0,0,1,5,8H19a3,3,0,0,1,3,3Z" }),
    React.createElement("path", { d: "M15,14H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" }),
    React.createElement("path", { d: "M10,12h1a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2Z" }),
    React.createElement("path", { d: "M19,10H15a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" }),
    React.createElement("path", { d: "M6,10H5a1,1,0,0,0,0,2H6a1,1,0,0,0,0-2Z" }),
    React.createElement("circle", { cx: 5, cy: 15, r: 1 }),
    React.createElement("circle", { cx: 19, cy: 15, r: 1 })));
exports.default = TruxwebKeyboardIcon;
