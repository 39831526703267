// DO NOT EDIT: These files are auto generated by `@truxweb/schemas`

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTransactionStatusV1 = void 0;
var PaymentTransactionStatusV1;
(function (PaymentTransactionStatusV1) {
    PaymentTransactionStatusV1["Failed"] = "Failed";
    PaymentTransactionStatusV1["Success"] = "Success";
    PaymentTransactionStatusV1["Pending"] = "Pending";
    PaymentTransactionStatusV1["Hold"] = "Hold";
    PaymentTransactionStatusV1["Cancelled"] = "Cancelled";
})(PaymentTransactionStatusV1 || (exports.PaymentTransactionStatusV1 = PaymentTransactionStatusV1 = {}));
