"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebLifeRingIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Outline", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M21.155,19.741a11.954,11.954,0,0,0,0-15.482l.86-.86A1,1,0,0,0,20.6,1.985l-.86.86a11.954,11.954,0,0,0-15.482,0l-.86-.86A1,1,0,0,0,1.985,3.4l.86.86a11.954,11.954,0,0,0,0,15.482l-.86.86A1,1,0,1,0,3.4,22.015l.86-.86a11.954,11.954,0,0,0,15.482,0l.86.86A1,1,0,0,0,22.015,20.6ZM22,12a9.949,9.949,0,0,1-2.262,6.324l-3.571-3.571a4.966,4.966,0,0,0,0-5.506l3.571-3.571A9.949,9.949,0,0,1,22,12ZM9,12a3,3,0,1,1,3,3A3,3,0,0,1,9,12ZM12,2a9.949,9.949,0,0,1,6.324,2.262L14.753,7.833a4.966,4.966,0,0,0-5.506,0L5.676,4.262A9.949,9.949,0,0,1,12,2ZM2,12A9.949,9.949,0,0,1,4.262,5.676L7.833,9.247a4.966,4.966,0,0,0,0,5.506L4.262,18.324A9.949,9.949,0,0,1,2,12ZM12,22a9.949,9.949,0,0,1-6.324-2.262l3.571-3.571a4.966,4.966,0,0,0,5.506,0l3.571,3.571A9.949,9.949,0,0,1,12,22Z" })));
exports.default = TruxwebLifeRingIcon;
