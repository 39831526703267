import { Box, Typography } from '@truxweb/ux';
import { ELanguageV1, EUserTypeV1 } from '@truxweb/schemas';
import React, { useMemo } from 'react';
import Image from 'next/image';
import { transformI18nLocaleToLanguage } from '@truxweb/utils';
import { useTranslation } from 'next-i18next';
import { useUserData } from '../../hooks';

const REQUIRED_NAMESPACES = ['common'];
type TQuoteChatMarketingProps = {
  handleClose?: () => void;
  size?: 'sm' | 'lg';
};

export const QuoteChatMarketing = ({
  handleClose,
  size,
}: TQuoteChatMarketingProps): JSX.Element => {
  const { userType } = useUserData();
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);

  const language = useMemo(() => {
    return transformI18nLocaleToLanguage(i18n.language);
  }, [i18n]);

  let headerSize: any = 'bodyLarge';
  let bodySize: any = 'bodyMedium';

  const width = 272;
  let height = 565;
  if (userType === EUserTypeV1.CARRIER && language === ELanguageV1.FR_CA) {
    height = 468;
  }
  if (size && size === 'lg') {
    headerSize = 'h2';
    bodySize = 'bodyLarge';
  }

  return (
    <>
      <Box p={3}>
        <Box mr={2} style={{ float: 'left' }}>
          <Image
            height={height}
            onClick={handleClose}
            src={`/assets/images/marketing/quoteRequestChatMarketing-${userType}-${language}.png`}
            width={width}
          />
        </Box>
        <Typography color="primaryLight" fontStyle="semibold" variant={headerSize}>
          {t('common:quoteChatMarketing-h1')}
        </Typography>
        <Box mt={2}>
          <Typography variant={bodySize}>
            {t(`common:quoteChatMarketing-${userType}-p1`)}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>
            {t(`common:quoteChatMarketing-${userType}-p2`)}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>
            {t(`common:quoteChatMarketing-${userType}-p3`)}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant={bodySize}>
            {t(`common:quoteChatMarketing-${userType}-p4`)}
          </Typography>
        </Box>
        {userType === EUserTypeV1.CARRIER && (
          <Box mt={2}>
            <Typography variant={bodySize}>
              {t(`common:quoteChatMarketing-${userType}-p5`)}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
