"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentsDashboardSidebarTrackingLocation = void 0;
const __1 = require("..");
const utils_1 = require("@truxweb/utils");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const constants_1 = require("../../constants");
const react_1 = __importStar(require("react"));
const ShipmentsDashboardSidebarTrackingLocation_styles_1 = require("./ShipmentsDashboardSidebarTrackingLocation.styles");
const ShipmentsDashboardSidebarTrackingLocation = ({ canAdministerShipments, canManageShipperData, isScheduleHidden, language, location, locationDate, locationDateLabel, locationType, onEditShipmentLocation, shouldHideLocationContactDetails, t, }) => {
    const [timer, setTimer] = (0, react_1.useState)(null);
    const [isInEditMode, setEditMode] = (0, react_1.useState)(false);
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const classes = (0, ShipmentsDashboardSidebarTrackingLocation_styles_1.useStyles)({ isInEditMode });
    const { control, handleSubmit, watch } = (0, react_hook_form_1.useForm)();
    const toggleEditMode = (0, react_1.useCallback)(() => {
        setEditMode(!isInEditMode);
    }, [isInEditMode, setEditMode]);
    const onSubmitSuccess = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        const { externalReferenceNumber } = data;
        if (!onEditShipmentLocation) {
            //eslint-disable-next-line no-console
            console.error(`No edit handler was provided to ShipmentsDashboardSidebarTrackingLocation while it was editable. Saving edits is impossible`);
        }
        else {
            yield onEditShipmentLocation(Object.assign(Object.assign({}, location), { externalReferenceNumber }), locationType);
        }
    }), [location, onEditShipmentLocation, locationType]);
    const onEditFullLocation = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        const edit = Object.assign(Object.assign(Object.assign({}, location), data), { name: data.addressName });
        if (!isInEditMode || !canAdministerShipments)
            throw new Error('Page is not in a valid state to be edited');
        if (!onEditShipmentLocation) {
            //eslint-disable-next-line no-console
            console.error(`No edit handler was provided to ShipmentsDashboardSidebarTrackingLocation while it was editable. Saving edits is impossible`);
        }
        else {
            setIsSaving(true);
            yield onEditShipmentLocation(edit, locationType);
            setIsSaving(false);
            setEditMode(false);
        }
    }), [
        isInEditMode,
        canAdministerShipments,
        location,
        onEditShipmentLocation,
        locationType,
        setIsSaving,
        setEditMode,
    ]);
    const debouncedSubmit = (0, react_1.useCallback)(() => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            handleSubmit(onSubmitSuccess)();
        }, 1000);
        setTimer(newTimer);
    }, [onSubmitSuccess, timer, setTimer, handleSubmit]);
    let emailDisplay = (react_1.default.createElement(ux_1.Typography, { className: classes.value, title: location.email || t('common:na') }, location.email || t('common:na')));
    if (location.email) {
        emailDisplay = (react_1.default.createElement("a", { className: classes.link, href: `mailto:${location.email}` }, emailDisplay));
    }
    const formattedPhoneNumber = (0, utils_1.formatPhoneNumber)(location.phone, location.phoneExt || '');
    const externalReferenceNumber = (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: location.externalReferenceNumber || '', name: 'externalReferenceNumber', render: ({ field: { onChange, value } }) => {
            return (react_1.default.createElement(ux_1.TextField, { InputLabelProps: {
                    shrink: true,
                }, InputProps: {
                    classes: {
                        root: classes.referenceNumber,
                    },
                }, fullWidth: true, label: t(`common:${locationType}-externalReferenceNumber`), onChange: (event) => {
                    onChange(event);
                    if (!isInEditMode)
                        debouncedSubmit();
                }, value: value, variant: "outlined" }));
        } }));
    const content = (react_1.default.createElement(ux_1.Box, { className: classes.wrapper },
        canAdministerShipments && (react_1.default.createElement(ux_1.Collapse, { in: !isInEditMode },
            react_1.default.createElement(ux_1.Box, { mb: 1 },
                react_1.default.createElement("button", { className: classes.button, onClick: toggleEditMode, type: "button" },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, justifyContent: "center" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mr: 1 },
                                react_1.default.createElement(ux_1.EditAltIcon, null))),
                        react_1.default.createElement(ux_1.Grid, { item: true }, t('common:editLocation', {
                            locationType: t(`common:${locationType.toLowerCase()}`),
                        }))))))),
        canManageShipperData && (react_1.default.createElement(ux_1.Box, { mb: 1 },
            isInEditMode && externalReferenceNumber,
            !isInEditMode && (react_1.default.createElement("form", { onSubmit: handleSubmit(onSubmitSuccess) }, externalReferenceNumber)))),
        canManageShipperData && !canAdministerShipments && (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.TruxwebCalendarIcon, { className: classes.icon })),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mr: 1, mt: -0.25 },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyMedium" },
                        locationDateLabel,
                        ":"))),
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { mt: -0.25 },
                    react_1.default.createElement(ux_1.Typography, { variant: "bodyMedium" }, (0, utils_1.formatDate)(locationDate, utils_1.DATE_FORMAT_STRINGS_SHORT[language])))))),
        isInEditMode && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(__1.AddressForm, { addressName: (location === null || location === void 0 ? void 0 : location.name) || '', areNamesDisabled: true, control: control, data: location, isAddressNameShown: true, isFormDisabled: false, t: t, watch: watch }),
            react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: location.contact || '', id: "contact", isDisabled: !isInEditMode, isRequired: true, label: t('common:contact'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: 'alphanumeric-only' })),
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: location.phone || '', id: "phone", isDisabled: !isInEditMode, isRequired: true, label: t('common:phone'), phoneNumberExtension: location.phoneExt || '', shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: 'phone' })),
            react_1.default.createElement(ux_1.Box, { mb: 2 },
                react_1.default.createElement(__1.FormField, { InputLabelProps: {
                        shrink: true,
                    }, control: control, defaultValue: location.email || '', id: "email", isDisabled: !isInEditMode, label: t('common:email'), shouldErrorIconBeSuppressed: true, shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t, type: 'email' })))),
        !isInEditMode && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(ux_1.Box, { mt: 1, style: { position: 'relative' } },
                react_1.default.createElement(ux_1.Typography, { className: classes.address, fontStyle: "semibold" }, (0, utils_1.addressToDisplayString)(location))),
            react_1.default.createElement("table", { className: classes.locationTable },
                react_1.default.createElement("tbody", null,
                    !canManageShipperData && location.externalReferenceNumber && (react_1.default.createElement("tr", { className: classes.tableRow },
                        react_1.default.createElement("td", { className: classes.tableHeader },
                            react_1.default.createElement(ux_1.Typography, { className: classes.header }, t(`common:${locationType}-externalReferenceNumber-abbr`))),
                        react_1.default.createElement("td", null,
                            react_1.default.createElement(ux_1.Box, { className: classes.tableValueOuter, ml: 2 },
                                react_1.default.createElement("div", { className: classes.tableValueInner },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.value, title: location.externalReferenceNumber }, location.externalReferenceNumber)))))),
                    react_1.default.createElement("tr", { className: classes.tableRow },
                        react_1.default.createElement("td", { className: classes.tableHeader },
                            react_1.default.createElement(ux_1.Typography, { className: classes.header }, t('common:company'))),
                        react_1.default.createElement("td", null,
                            react_1.default.createElement(ux_1.Box, { className: classes.tableValueOuter, ml: 2 },
                                react_1.default.createElement("div", { className: classes.tableValueInner },
                                    react_1.default.createElement(ux_1.Typography, { className: classes.value, title: location.name }, location.name))))),
                    !shouldHideLocationContactDetails && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("tr", { className: classes.tableRow },
                            react_1.default.createElement("td", { className: classes.tableHeader },
                                react_1.default.createElement(ux_1.Typography, { className: classes.header }, t('common:contact'))),
                            react_1.default.createElement("td", null,
                                react_1.default.createElement(ux_1.Box, { className: classes.tableValueOuter, ml: 2 },
                                    react_1.default.createElement("div", { className: classes.tableValueInner },
                                        react_1.default.createElement(ux_1.Typography, { className: classes.value, title: location.contact }, location.contact))))),
                        react_1.default.createElement("tr", { className: classes.tableRow },
                            react_1.default.createElement("td", { className: classes.tableHeader },
                                react_1.default.createElement(ux_1.Typography, { className: classes.header }, t('common:phoneNumber'))),
                            react_1.default.createElement("td", null,
                                react_1.default.createElement(ux_1.Box, { className: classes.tableValueOuter, ml: 2 },
                                    react_1.default.createElement("div", { className: classes.tableValueInner },
                                        react_1.default.createElement("a", { className: classes.link, href: `tel:${formattedPhoneNumber}` },
                                            react_1.default.createElement(ux_1.Typography, { className: classes.value, title: formattedPhoneNumber }, formattedPhoneNumber)))))),
                        react_1.default.createElement("tr", { className: classes.tableRow },
                            react_1.default.createElement("td", { className: classes.tableHeader },
                                react_1.default.createElement(ux_1.Typography, { className: classes.header }, t('common:email'))),
                            react_1.default.createElement("td", null,
                                react_1.default.createElement(ux_1.Box, { className: classes.tableValueOuter, ml: 2 },
                                    react_1.default.createElement("div", { className: classes.tableValueInner }, emailDisplay)))))))))),
        !isScheduleHidden && (react_1.default.createElement("table", { className: classes.locationTable },
            react_1.default.createElement("tbody", null,
                react_1.default.createElement("tr", null,
                    react_1.default.createElement("td", { colSpan: 2 },
                        react_1.default.createElement(ux_1.Box, { mt: 2 },
                            react_1.default.createElement(__1.CompanyLocationSchedule, { control: control, defaultLocationHours: constants_1.DEFAULT_LOCATION_HOURS, defaultLocationSchedule: constants_1.DEFAULT_LOCATION_SCHEDULE, isDisplayOnly: !isInEditMode, selectedLocation: location, t: t, watch: watch }))))))),
        react_1.default.createElement(ux_1.Collapse, { in: isInEditMode },
            react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", justifyContent: "center" },
                react_1.default.createElement(__1.SaveButton, { color: "primaryLight", isSaving: isSaving, t: t })),
            react_1.default.createElement(ux_1.Box, { mt: 2 },
                react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
                    react_1.default.createElement("button", { className: classes.button, onClick: toggleEditMode, type: "button" }, t('common:cancel')))))));
    if (!isInEditMode)
        return content;
    return react_1.default.createElement("form", { onSubmit: handleSubmit(onEditFullLocation) }, content);
};
exports.ShipmentsDashboardSidebarTrackingLocation = ShipmentsDashboardSidebarTrackingLocation;
