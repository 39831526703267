import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  '.MuiInputBase-root:has(> input:-webkit-autofill)': {
    backgroundColor: 'blue',
  },
  backButton: {
    alignItems: 'center',
    color: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    maxWidth: theme.spacing(62),
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 1rem',
    },
    [theme.breakpoints.down(theme.breakpoints.values.largeMobile)]: {
      padding: '2rem 2rem',
    },
  },
  fitContainer: {
    width: '100%',
  },
  iconMargin: {
    marginRight: theme.spacing(1.5),
  },
  itemBox: {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    height: theme.spacing(5),
  },
  linkFont: {
    fontFamily: 'Roboto !important',
  },
  primaryFont: {
    color: `${theme.palette.primary.light} !important`,
  },
  resendButton: {
    background: 'none!important',
    border: 'none',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '0!important',
  },
  resendFont: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
  },
  submitButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: theme.spacing(5),
    padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px !important`,
  },
  ul: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
  verificationCode: {
    width: theme.spacing(42),
  },
}));
