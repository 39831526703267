import { Box, Grid, Typography } from '@truxweb/ux';
import { getRouteSection } from '../../utils';
import { PageLink } from '..';
import { TPageId } from '../../types';
import { useAuthBoolean } from '../../hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common'];

type TUnauthenticatedHeaderLinksProps = {
  linkClass: string;
  selectedLinkClass: string;
  pageId: TPageId;
};
export const UnauthenticatedHeaderLinks = ({
  linkClass,
  pageId,
  selectedLinkClass,
}: TUnauthenticatedHeaderLinksProps): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const { locale } = useRouter();
  const section = getRouteSection(locale, pageId);

  const isAuthed = useAuthBoolean();
  if (isAuthed) return null;

  return (
    <Grid alignItems="center" container direction="row" justifyContent="space-around">
      <Grid item>
        <Box mr={3}>
          <a href="https://truxweb.breezy.hr/" style={{ textDecoration: 'none' }} target="carreers">
            <Typography className={linkClass} variant="h6">
              {t('common:carreers')}
            </Typography>
          </a>
        </Box>
      </Grid>
      <Grid item>
        <PageLink pageId={'contact-us'}>
          <Typography
            className={section === 'contactUs' ? selectedLinkClass : linkClass}
            variant="h6"
          >
            {t('common:contactUs')}
          </Typography>
        </PageLink>
      </Grid>
    </Grid>
  );
};
