"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebFieldHockeyIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M20.5,17A3.5,3.5,0,1,0,24,20.5,3.5,3.5,0,0,0,20.5,17Zm0,5A1.5,1.5,0,1,1,22,20.5,1.5,1.5,0,0,1,20.5,22ZM23.947,2.438a3,3,0,0,0-5.435-1.114L7.785,17.539A.96.96,0,1,1,6.15,16.532a3,3,0,1,0-5.108-3.147A6.959,6.959,0,0,0,6.949,24a7.045,7.045,0,0,0,1.626-.191,6.886,6.886,0,0,0,4.3-3.091L23.485,4.681A2.987,2.987,0,0,0,23.947,2.438ZM11.19,19.638a4.96,4.96,0,0,1-8.446-5.2,1,1,0,1,1,1.7,1.049A2.96,2.96,0,0,0,9.47,18.616l4.779-7.223,1.667,1.1ZM21.827,3.562l-.007.011-4.8,7.255-1.667-1.1,4.821-7.287a1,1,0,0,1,1.654,1.124Z" })));
exports.default = TruxwebFieldHockeyIcon;
