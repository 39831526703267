"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebPokerChipIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m12 0a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12zm10 12a10 10 0 0 1 -.186 1.9l-1.932-.58a7.442 7.442 0 0 0 0-2.64l1.932-.58a10 10 0 0 1 .186 1.9zm-.76-3.816-1.93.579a8.048 8.048 0 0 0 -4.073-4.073l.579-1.93a10.058 10.058 0 0 1 5.424 5.424zm-9.24 9.816a6 6 0 1 1 6-6 6.006 6.006 0 0 1 -6 6zm1.9-15.814-.58 1.932a7.442 7.442 0 0 0 -2.64 0l-.58-1.932a9.8 9.8 0 0 1 3.8 0zm-5.716.574.579 1.93a8.048 8.048 0 0 0 -4.073 4.073l-1.93-.579a10.058 10.058 0 0 1 5.424-5.424zm-6.184 9.24a10 10 0 0 1 .186-1.9l1.932.58a7.442 7.442 0 0 0 0 2.64l-1.932.58a10 10 0 0 1 -.186-1.9zm.76 3.816 1.93-.579a8.048 8.048 0 0 0 4.073 4.073l-.579 1.93a10.058 10.058 0 0 1 -5.424-5.424zm7.34 6 .58-1.932a7.442 7.442 0 0 0 2.64 0l.58 1.932a9.8 9.8 0 0 1 -3.8 0zm5.716-.575-.579-1.929a8.048 8.048 0 0 0 4.073-4.073l1.93.579a10.053 10.053 0 0 1 -5.424 5.421zm-1.185-10.349a1.848 1.848 0 0 1 0 2.216l-1.893 2.523a.922.922 0 0 1 -1.476 0l-1.893-2.523a1.848 1.848 0 0 1 0-2.216l1.893-2.523a.922.922 0 0 1 1.476 0z" })));
exports.default = TruxwebPokerChipIcon;
