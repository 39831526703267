"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaneEditSidebar = void 0;
const ux_1 = require("@truxweb/ux");
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const __1 = require("..");
const LaneEditSidebar_styles_1 = require("./LaneEditSidebar.styles");
const LaneEditSidebar = ({ carrier, carrierLaneEquipment, carrierName, explanation, isSaving, lane, laneEquipmentToUpdate, onSubmitSuccess, prompt, t, }) => {
    const classes = (0, LaneEditSidebar_styles_1.useStyles)();
    const [hasProcessedUpdate, setProcessedUpdate] = (0, react_1.useState)(false);
    const { clearErrors, control, formState: { isDirty }, handleSubmit, register, setError, setValue, } = (0, react_hook_form_1.useForm)({
        defaultValues: Object.assign(Object.assign({}, lane), { equipment: carrierLaneEquipment, rateSheets: lane.rateSheets || [] }),
    });
    const { fields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'equipment',
    });
    (0, react_1.useEffect)(() => {
        if ((laneEquipmentToUpdate === null || laneEquipmentToUpdate === void 0 ? void 0 : laneEquipmentToUpdate.length) && !hasProcessedUpdate) {
            const updateLookup = {};
            laneEquipmentToUpdate.forEach((record) => {
                updateLookup[record.equipmentId] = record;
            });
            fields.forEach((record, index) => {
                if (updateLookup[record.equipmentId]) {
                    setValue(`equipment.${index}.hasPendingUpdate`, true, { shouldDirty: true });
                    setValue(`equipment.${index}.minimumFlatRate.value`, updateLookup[record.equipmentId].minimumFlatRate.value);
                }
            });
            setProcessedUpdate(true);
        }
    }, [setValue, fields, hasProcessedUpdate, laneEquipmentToUpdate, setProcessedUpdate]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Box, { mb: 3 },
                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold", variant: "bodyLarge" }, Boolean(prompt) ? (react_1.default.createElement(react_1.default.Fragment, null, prompt)) : (react_1.default.createElement(react_1.default.Fragment, null,
                    carrierName,
                    " - ",
                    t('common:editLane')))))),
        react_1.default.createElement(ux_1.FlatCard, { className: classes.card },
            react_1.default.createElement(ux_1.Box, { p: 3 },
                Boolean(explanation) && react_1.default.createElement(ux_1.Box, { mb: 3 }, explanation),
                react_1.default.createElement(__1.LaneEditForm, { carrier: carrier, carrierLaneEquipment: carrierLaneEquipment, clearErrors: clearErrors, control: control, fields: fields, handleSubmit: handleSubmit, isDirty: Boolean(isDirty), isSaving: isSaving, lane: lane, onSubmitSuccess: onSubmitSuccess, register: register, setError: setError, setValue: setValue, t: t })))));
};
exports.LaneEditSidebar = LaneEditSidebar;
