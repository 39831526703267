import { makeRequestToApiGateway } from '../../apiUtils';
import { TCreateShipmentQuoteRequestV1 } from '@truxweb/schemas';

export const createShipmentQuoteRequest = async (
  request: TCreateShipmentQuoteRequestV1
): Promise<number> => {
  const results = await makeRequestToApiGateway(
    'shipmentPostV1ShipperQuote',
    null,
    {
      'Content-Type': 'application/json',
    },
    request
  );

  const { messageId } = results as Record<string, any>;
  return messageId as number;
};

export const shipmentQuoteRequestMessagePoll = async (
  messageId: number
): Promise<Record<string, any>> => {
  return await makeRequestToApiGateway('shipmentGetV1ShipperQuote', null, null, null, {
    messageId,
  });
};
