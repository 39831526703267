import { Box, Button, Typography } from '@truxweb/ux';
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { getRouteProperties } from '../../utils';
import { TPageId } from '../../types';
import { useRouter } from 'next/router';
import { useStyles } from './LanguageSwitcher.styles';
import { useTranslation } from 'next-i18next';

type TLanguageSwitcherProps = {
  className?: string;
  localeString?: string;
  pageId: TPageId;
  textClassName?: string;
};

const REQUIRED_NAMESPACES = ['common'];

export const LanguageSwitcher = ({
  className,
  localeString,
  pageId,
  textClassName,
}: TLanguageSwitcherProps): JSX.Element => {
  const router = useRouter();
  const { locale, locales } = router;
  const { i18n, t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();

  const handleChangeLocale = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);

      // We need to determine the translated route for this page,
      // this is only required on pages which are not the same
      // in the supported languages
      const { route } = getRouteProperties(lang, pageId, Object.values(router.query));
      router.push(route, route, { locale: lang });
    },
    [pageId, i18n, router]
  );

  // We only want to show the option to switch to the non-current
  // language
  // NOTE: we are currently assuming two Languages here, which is not a
  // permanent thing.
  const availableAlternateLanguages = locales.filter((value) => {
    return value !== locale;
  })[0];

  return (
    <Button
      className={classnames(classes.button, className, textClassName)}
      color="inherit"
      data-testid={`LanguageSwitcher-${availableAlternateLanguages}-${pageId}`}
      onClick={() => {
        handleChangeLocale(availableAlternateLanguages);
      }}
    >
      <Box>
        <Typography className={textClassName}>
          {localeString
            ? t(`common:${localeString}`)
            : availableAlternateLanguages.split('-')[0].toUpperCase()}
        </Typography>
      </Box>
    </Button>
  );
};
