"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSwimmerIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M2,2A1,1,0,0,1,3,1H7.916a5,5,0,0,1,4.1,2.136l5.114,7.317A1,1,0,1,1,15.489,11.6L12.016,6.63,3.593,12.807a1,1,0,0,1-1.184-1.614l8.461-6.2-.495-.707A3,3,0,0,0,7.916,3H3A1,1,0,0,1,2,2ZM22.333,20.49A1.991,1.991,0,0,1,21,21a2.248,2.248,0,0,1-2.057-1.333,1,1,0,0,0-1.885,0,2.254,2.254,0,0,1-4.115,0,1,1,0,0,0-1.885,0,2.254,2.254,0,0,1-4.115,0,1,1,0,0,0-1.885,0A2.25,2.25,0,0,1,3,21a2,2,0,0,1-1.333-.511A1,1,0,0,0,.332,21.978,4,4,0,0,0,3,23a4.379,4.379,0,0,0,3-1.225,4.286,4.286,0,0,0,6,0,4.286,4.286,0,0,0,6,0A4.375,4.375,0,0,0,21,23a3.981,3.981,0,0,0,2.668-1.023,1,1,0,1,0-1.336-1.487ZM3,18a4.379,4.379,0,0,0,3-1.225,4.286,4.286,0,0,0,6,0,4.286,4.286,0,0,0,6,0A4.375,4.375,0,0,0,21,18a3.981,3.981,0,0,0,2.668-1.023,1,1,0,1,0-1.336-1.487A1.991,1.991,0,0,1,21,16a2.248,2.248,0,0,1-2.057-1.333,1,1,0,0,0-1.885,0,2.254,2.254,0,0,1-4.115,0,1,1,0,0,0-1.885,0,2.254,2.254,0,0,1-4.115,0,1,1,0,0,0-1.885,0A2.25,2.25,0,0,1,3,16a2,2,0,0,1-1.333-.511A1,1,0,0,0,.332,16.978,4,4,0,0,0,3,18ZM18.5,8A2.5,2.5,0,1,0,16,5.5,2.5,2.5,0,0,0,18.5,8Z" })));
exports.default = TruxwebSwimmerIcon;
