import { makeStyles, Theme } from '@truxweb/ux';
import { EPageAlertStatusV1 } from '@truxweb/schemas';

type Props = {
  status: EPageAlertStatusV1;
};

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  displayPurposesContainer: {
    display: 'flex',

    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    width: '100%',
  },
  fullHeight: {
    height: '100%',
    margin: '0',
  },
  infoText: {
    color: '#636363',
    marginLeft: 168,
  },
  status: {
    alignItems: 'center',
    backgroundColor: (props) =>
      props.status === EPageAlertStatusV1.Pending
        ? theme.palette.error.main
        : theme.palette.secondary.main,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    margin: 0,
    minHeight: 50,
    position: 'absolute',
    width: 168,
  },
  statusText: {
    color: '#ffffff',
  },
}));
