import { Box, Button, CheckIcon, Divider, Grid, HighlightOffIcon, Typography } from '@truxweb/ux';
import { Card, Loading } from '..';
import { ELanguageV1, ENotificationTypeV1, TInAppNotificationV1 } from '@truxweb/schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlerts, useNotifications } from '../../hooks';
import { NotificationList } from '../NotificationList';
import { useRouter } from 'next/router';
import { useStyles } from './UserNotifications.styles';
import { useTranslation } from 'next-i18next';

const REQUIRED_NAMESPACES = ['common', 'notifications'];

type TActiveTypeFilterState = {
  isDocumentsFilterActive: boolean;
  isMessagesFilterActive: boolean;
  isSettingsFilterActive: boolean;
};
const INITIAL_TYPE_FILTER_STATE: TActiveTypeFilterState = {
  isDocumentsFilterActive: false,
  isMessagesFilterActive: false,
  isSettingsFilterActive: false,
};
const TYPE_FILTER_SETS = {
  documentsFilter: new Set<ENotificationTypeV1>([ENotificationTypeV1.SHIPMENT_DOCUMENT]),
  messagesFilter: new Set<ENotificationTypeV1>([ENotificationTypeV1.SHIPMENT_COMMENT]),
  settingsFilter: new Set<ENotificationTypeV1>([
    ENotificationTypeV1.ACCOUNT,
    ENotificationTypeV1.COMPANY,
  ]),
};

type TSortCriteria = keyof Pick<TInAppNotificationV1, 'dateCreated' | 'priority' | 'dateRead'>;
const SORT_CRITERIA_INVERTING_NATURAL_ORDERING = new Set<TSortCriteria>([
  'dateCreated',
  'dateRead',
]);

export const UserNotifications = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const { locale } = useRouter();
  const { addAlert } = useAlerts();
  const [hasErrorDisplayed, setHasErrorDisplayed] = useState(false);
  const [shouldRefetchNotifications, setShouldRefetchNotifications] = useState(false);

  // FILTERS START
  const [activeTypeFilters, setActiveTypeFilter] = useState(
    (): TActiveTypeFilterState => INITIAL_TYPE_FILTER_STATE
  );
  const [sortCriteria, setSortCriteria] = useState((): TSortCriteria => 'dateCreated');

  const toggleTypeFilters = useCallback(
    (prop: keyof TActiveTypeFilterState): void => {
      setActiveTypeFilter((prev) => {
        const newFilters = { ...prev };
        newFilters[prop] = !newFilters[prop] ? true : false;
        return newFilters;
      });
    },
    [setActiveTypeFilter]
  );

  // STEP -- Fetch in app notifications addressed to the user from a common context
  const [beNotificationList, hasLoaded, isLoading, hasError] = useNotifications(
    locale.toUpperCase() as ELanguageV1,
    shouldRefetchNotifications
  );

  const onMarkAsReadSuccess = useCallback(async (): Promise<void> => {
    // We need to refetch the notifications from the DB
    setShouldRefetchNotifications(true);
  }, [setShouldRefetchNotifications]);

  useEffect(() => {
    // => Once loaded, sets the shipments to be displayed
    if (hasError && hasLoaded && !hasErrorDisplayed) {
      addAlert({
        message: hasError.message,
        severity: 'error',
      });
      setHasErrorDisplayed(true);
    }

    if (shouldRefetchNotifications) {
      //=> If we refetched, we avoid repeating this action a second time
      setShouldRefetchNotifications(false);
    }
  }, [
    addAlert,
    hasError,
    hasErrorDisplayed,
    hasLoaded,
    isLoading,
    setHasErrorDisplayed,
    shouldRefetchNotifications,
    setShouldRefetchNotifications,
  ]);

  // STEP -- Filter & order the elts to display
  const sortNotifications = useCallback(
    (a: TInAppNotificationV1, b: TInAppNotificationV1, sortCriteria: TSortCriteria) => {
      const A = a[sortCriteria];
      const B = b[sortCriteria];

      if (!A || !B) {
        // If any one is null, what do we return?
        if (!A && !B) return 0;
        return !A ? 1 : -1;
      }
      if (A > B) return 1;
      if (B < A) return -1;
      return 0;
    },
    []
  );

  const notificationsForDisplay = useMemo(() => {
    if (hasLoaded && !isLoading && !hasError) {
      //=> Compute notifications to be displayed
      return beNotificationList
        ?.filter((elt) => {
          let ret = true;
          if (activeTypeFilters.isDocumentsFilterActive) {
            ret = ret && TYPE_FILTER_SETS.documentsFilter.has(elt.notificationType);
          }
          if (activeTypeFilters.isMessagesFilterActive) {
            ret = ret && TYPE_FILTER_SETS.messagesFilter.has(elt.notificationType);
          }
          if (activeTypeFilters.isSettingsFilterActive) {
            ret = ret && TYPE_FILTER_SETS.settingsFilter.has(elt.notificationType);
          }
          return ret;
        })
        ?.sort((a: TInAppNotificationV1, b: TInAppNotificationV1) => {
          let ret = 0;
          if (SORT_CRITERIA_INVERTING_NATURAL_ORDERING.has(sortCriteria)) {
            ret = sortNotifications(b, a, sortCriteria);
          } else {
            ret = sortNotifications(a, b, sortCriteria);
          }
          return ret;
        });
    }
    return [];
  }, [
    beNotificationList,
    activeTypeFilters,
    sortNotifications,
    sortCriteria,
    hasLoaded,
    isLoading,
    hasError,
  ]);

  // Display variable
  const areAllTypeFiltersOff = Object.values(activeTypeFilters).reduce(
    (prev, curr) => prev && !curr,
    true
  );
  return (
    <>
      <Loading isLoading={isLoading} />
      <Grid className={classes.fullWidth} container direction="column">
        <Card className={classes.notificationsCard} topColor="secondary">
          <Grid container direction="row">
            <Grid className={classes.filterColumn} item>
              <Typography color="primary" variant="h6">
                {t('common:filters')}
              </Typography>
              <Grid container direction="column" justifyContent="flex-start">
                {/* TYPE FILTERS... */}
                <Box mb={1} mt={2}>
                  <Typography color="primary" variant="subtitle2">
                    {t('common:type')}
                  </Typography>
                </Box>
                <Grid item>
                  <Button
                    className={classes.filterTypeAllBtn}
                    disabled={isLoading}
                    endIcon={areAllTypeFiltersOff && <HighlightOffIcon color="primary" />}
                    // fullWidth
                    onClick={() => {
                      if (!areAllTypeFiltersOff) setActiveTypeFilter(INITIAL_TYPE_FILTER_STATE);
                    }}
                    startIcon={areAllTypeFiltersOff && <CheckIcon color="primary" />}
                    variant="outlined"
                  >
                    <Typography
                      color={areAllTypeFiltersOff ? 'primary' : undefined}
                      variant={areAllTypeFiltersOff ? 'subtitle2' : undefined}
                    >
                      {t(`notifications:seeAll`)}
                    </Typography>
                  </Button>
                </Grid>
                {Object.keys(activeTypeFilters).map((elt: keyof TActiveTypeFilterState) => {
                  return (
                    <Grid item key={`typeFilter-${elt}`}>
                      <Button
                        className={classes.filterTypeBtn}
                        disabled={isLoading}
                        // fullWidth
                        endIcon={activeTypeFilters[elt] && <HighlightOffIcon color="primary" />}
                        onClick={() => toggleTypeFilters(elt)}
                        startIcon={activeTypeFilters[elt] && <CheckIcon color="primary" />}
                        variant="outlined"
                      >
                        <Typography
                          color={activeTypeFilters[elt] ? 'primary' : undefined}
                          variant={activeTypeFilters[elt] ? 'subtitle2' : undefined}
                        >
                          {t(`notifications:typeFilter-${elt}`)}
                        </Typography>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item>
                <Divider className={classes.filterDivider} />
              </Grid>
              <Grid container direction="column" justifyContent="flex-start">
                {/* SORT BY CRITERIA... */}
                <Box mb={1} mt={2}>
                  <Typography color="primary" variant="subtitle2">
                    {t('common:sortBy')}
                  </Typography>
                </Box>
                {['dateCreated', 'priority', 'dateRead'].map((elt: TSortCriteria) => {
                  return (
                    <Grid item key={`sortBy-${elt}`}>
                      <Button
                        className={classes.sortByBtn}
                        disabled={isLoading}
                        endIcon={sortCriteria === elt && <HighlightOffIcon color="primary" />}
                        // fullWidth
                        onClick={() => {
                          if (sortCriteria !== elt) setSortCriteria(elt);
                        }}
                        startIcon={sortCriteria === elt && <CheckIcon color="primary" />}
                        variant="outlined"
                      >
                        <Typography
                          color={sortCriteria === elt ? 'primary' : undefined}
                          variant={sortCriteria === elt ? 'subtitle2' : undefined}
                        >
                          {t(`notifications:sortBy-${elt}`)}
                        </Typography>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid className={classes.notificationsColumn} item>
              {/* NOTIFICATIONS DISPLAY... */}
              <NotificationList
                noNotificationsToDisplayMsg={t('common:noNotificationsToDisplay-filters')}
                notifications={notificationsForDisplay}
                onMarkAsReadSuccess={onMarkAsReadSuccess}
                shouldDisplayLink={true}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
