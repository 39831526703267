import { makeStyles, Theme } from '@truxweb/ux';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    boxShadow: 'unset',
  },
  description: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));
