"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebSchoolBusIcon = (props) => (React.createElement("svg", Object.assign({ "data-name": "Layer 1", height: 24, id: "Layer_1", viewBox: "0 0 24 24", width: 24, xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "m20 11h-1v-6a4 4 0 0 0 -4-4h-11a4 4 0 0 0 -4 4v11a2.994 2.994 0 0 0 2.071 2.838 3.4 3.4 0 0 0 -.071.662 3.5 3.5 0 0 0 7 0 3.465 3.465 0 0 0 -.041-.5h6.082a3.465 3.465 0 0 0 -.041.5 3.5 3.5 0 0 0 7 0 3.4 3.4 0 0 0 -.071-.662 2.994 2.994 0 0 0 2.071-2.838v-1a4 4 0 0 0 -4-4zm-18 0v-4h2v4zm4-4h2v4h-2zm4 0h3v10h-3zm5 0h2v4h-2zm-11-4h11a2 2 0 0 1 2 2h-15a2 2 0 0 1 2-2zm-2 10h6v4h-5a1 1 0 0 1 -1-1zm5 6.5a1.5 1.5 0 0 1 -3 0 1.41 1.41 0 0 1 .093-.5h2.814a1.41 1.41 0 0 1 .093.5zm11.5 1.5a1.5 1.5 0 0 1 -1.5-1.5 1.41 1.41 0 0 1 .093-.5h2.814a1.41 1.41 0 0 1 .093.5 1.5 1.5 0 0 1 -1.5 1.5zm3.5-5a1 1 0 0 1 -1 1h-6v-4h5a2 2 0 0 1 2 2z" })));
exports.default = TruxwebSchoolBusIcon;
