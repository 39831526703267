import {
  type AppThunk,
  setCompanyLocationListError,
  setCompanyLocationListLoaded,
  setCompanyLocationListLoading,
} from '../../../stores';
import { makeRequestToApiGateway } from '../../../apiUtils';

import { type TCompanyLocationV1 } from '@truxweb/schemas';

export const archiveCompanyLocation =
  (companyLocation: TCompanyLocationV1, successCb: () => void, errorCb: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCompanyLocationListLoading());
      await makeRequestToApiGateway(
        'userPatchV1CompanyLocation',
        null,
        {
          'Content-Type': 'application/json',
        },
        { ...companyLocation, shouldArchive: true }
      );

      dispatch(setCompanyLocationListLoaded({ isProgressiveLoading: false }));
      successCb();
    } catch (error) {
      dispatch(setCompanyLocationListError({ error: error.message }));
      errorCb();
    }
  };
