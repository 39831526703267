"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const TruxwebTrailerIcon = (props) => (React.createElement("svg", Object.assign({ height: 24, id: "Layer_1", style: {}, viewBox: "0 0 512 512", width: 24, x: "0px", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", y: "0px" }, props),
    React.createElement("path", { d: "M491.52,399.344h-40.96v-286.72c-0.068-56.526-45.874-102.332-102.4-102.4H102.4C45.874,10.291,0.068,56.098,0,112.624  v225.28c0.07,49.476,35.444,91.852,84.111,100.762c10.998,44.038,55.613,70.822,99.65,59.824  c28.874-7.211,51.593-29.467,59.397-58.186H491.52c11.311,0,20.48-9.169,20.48-20.48S502.831,399.344,491.52,399.344z M102.4,51.184  h245.76c33.932,0,61.44,27.508,61.44,61.44v143.36h-40.96v-143.36c0-11.311-9.169-20.48-20.48-20.48s-20.48,9.169-20.48,20.48  v143.36h-40.96v-143.36c0-11.311-9.169-20.48-20.48-20.48c-11.311,0-20.48,9.169-20.48,20.48v143.36H204.8v-143.36  c0-11.311-9.169-20.48-20.48-20.48c-11.311,0-20.48,9.169-20.48,20.48v143.36h-40.96v-143.36c0-11.311-9.169-20.48-20.48-20.48  s-20.48,9.169-20.48,20.48v143.36H40.96v-143.36C40.96,78.691,68.468,51.184,102.4,51.184z M40.96,337.904v-40.96H409.6v102.4  H243.159c-11.929-43.818-57.122-69.669-100.94-57.739c-27.232,7.414-48.758,28.275-57.023,55.261  C58.99,389.222,40.969,365.203,40.96,337.904z M163.84,460.784c-22.622,0-40.96-18.338-40.96-40.96s18.338-40.96,40.96-40.96  s40.96,18.338,40.96,40.96S186.462,460.784,163.84,460.784z" })));
exports.default = TruxwebTrailerIcon;
