import { CarrierHeaderLinks, ShipperHeaderLinks, UnauthenticatedHeaderLinks } from '..';
import { useAuth, useHeaderPalette } from '../../hooks';
import { Grid } from '@truxweb/ux';
import React from 'react';
import { TPageId } from '../../types';
import { useStyles } from './DesktopHeaderLinks.styles';

export type TDesktopHeaderLinksProps = {
  isScrolled: boolean;
  isTransparent?: boolean;
  pageId?: TPageId;
};

export const DesktopHeaderLinks = ({
  isScrolled,
  isTransparent,
  pageId,
}: TDesktopHeaderLinksProps): JSX.Element => {
  const palette = useHeaderPalette();
  const auth = useAuth();
  const classes = useStyles({ isScrolled, isTransparent, palette });

  if (!auth.hasLoaded && !auth.hasLoggedOut) return null;
  return (
    <Grid item>
      <UnauthenticatedHeaderLinks
        key={0}
        linkClass={classes.sectionLink}
        pageId={pageId}
        selectedLinkClass={classes.selectedSectionLink}
      />
      <Grid container>
        <Grid item>
          <CarrierHeaderLinks
            key="carrierLinks"
            linkClass={classes.sectionLink}
            pageId={pageId}
            selectedLinkClass={classes.selectedSectionLink}
          />
        </Grid>

        <Grid item>
          <ShipperHeaderLinks
            linkClass={classes.sectionLink}
            pageId={pageId}
            selectedLinkClass={classes.selectedSectionLink}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
