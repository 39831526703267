"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const ux_1 = require("@truxweb/ux");
exports.useStyles = (0, ux_1.makeStyles)((theme) => ({
    characterCount: {
        color: theme.palette.primary.light,
    },
    comment: {
        border: 0,
        width: '100%',
    },
    header: {
        fontWeight: 600,
    },
    refusalSelect: {
        // FIXME: This important declaration sucks, but
        // otherwise this select has errant right padding
        paddingRight: `${theme.spacing(0)} !important`,
        width: 'calc(100% - 40px)',
    },
}));
